/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { forwardRef, useLayoutEffect, useRef, useEffect } from 'react';

import AppliesTo from './applies';
import WorkLog from './worklog';
import YourAttachment from './general/components/your-attachments';
import Icon from '@components/icon-component';
import SectionContainer from '@components/section-container';
import MapFieldComponents from './components/map-field-components';
import TodoAndSyncedButton from './components/todo-and-synced-btn';
import PermissionRestrict from 'hoc/permission-restrict';
import { PERMISSIONS } from 'permissions/constants';

import { WorkTypes } from './helper';
import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './workorder.module.scss';

const FormWrapperContainer = (
  {
    siteIds,
    equipmentIds,
    structureIds,
    pipelineIds,
    workType,
    activeBtn,
    setActiveBtn,
    register,
    control,
    errors,
    setValue,
    watch,
    getValues,
    handleSubmit,
    onSubmitHandler,
    appliesToData,
    menuNames,
    formSectionsWithSubSections,
    dropdownMenuMapList,
    isCheckForm,
    checkType
  },
  ref
) => {
  const { width } = useWindowDimensions();
  let refs = useRef([]);
  let count = 0;

  const { menuItemEls, formWrapperFormRef } = ref;
  const formSectionsWithSubSectionsMap = formSectionsWithSubSections?.reduce((acc, curr) => {
    acc[curr?.formSectionId] = curr;
    return acc;
  }, {});

  useLayoutEffect(() => {
    setTimeout(() => {
      if (formWrapperFormRef?.current) {
        formWrapperFormRef.current.scrollTop =
          document.getElementsByClassName('menu-1')[0]?.offsetTop - 175;
      }
    }, 100); // Adjust the delay as needed
  }, []);

  const mapFieldComponents = ({
    field,
    index,
    noMarginForBoxComponent = false,
    checkPreviousFieldIsBoxed = false,
    questionTypeCheckBox = false,
    grid2 = false,
    gridIndex
  }) => {
    if (
      field?.questionTypeText !== 'Header' &&
      field?.questionTypeText !== 'TextBlock' &&
      field?.questionTypeText !== 'WorkOrder' &&
      field?.questionTypeText !== 'WorkRequest' &&
      field?.questionTypeText !== 'Photos' &&
      field?.questionTypeText !== 'Notes' &&
      field?.questionTypeText !== 'GasSensor' &&
      field?.questionTypeText !== 'ReadableText' &&
      field?.questionTypeText !== 'ReadableArray' &&
      field?.questionTypeText !== 'ReadableImageAndDocumentsArray' &&
      field?.questionTypeText !== 'Label'
    )
      ++count;
    return (
      <MapFieldComponents
        key={field?.formFieldId}
        field={field}
        index={index}
        noMarginForBoxComponent={noMarginForBoxComponent}
        checkPreviousFieldIsBoxed={checkPreviousFieldIsBoxed}
        questionTypeCheckBox={questionTypeCheckBox}
        grid2={grid2}
        gridIndex={gridIndex}
        setValue={setValue}
        watch={watch}
        workType={workType}
        count={count}
        errors={errors}
        appliesToData={appliesToData}
        control={control}
        getValues={getValues}
        register={register}
        width={width}
        dropdownMenuMapList={dropdownMenuMapList}
        ref={{ refs: refs }}
      />
    );
  };
  useEffect(() => {
    if (!_.isEmpty(errors)) {
      if (Object?.keys(errors)[0] === 'reading') {
        const readingSection = document.getElementById('reading-section');
        formWrapperFormRef.current.scrollTop = readingSection?.offsetTop - 300;
      } else if (Object?.keys(errors)[0]?.includes('psvs-')) {
        const key = Object?.keys(errors)[0]?.split('psvs-')[1];
        if (key) {
          let psvsList = getValues(`psvs-${key}`);
          psvsList = psvsList[key];
          const dataFind = psvsList?.find(
            (item) => item.verificationStatus === 'verify' && !item.isDetached
          );
          if (dataFind?.equipmentId) {
            const readingSection = document.getElementById(dataFind?.equipmentId);
            formWrapperFormRef.current.scrollTop = readingSection?.offsetTop - 300;
          }
        }
      } else {
        const offsetTop = refs?.current[Object?.keys(errors)[0]]?.offsetTop;
        refs?.current[Object?.keys(errors)[0]]?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
        formWrapperFormRef.current.scrollTop = offsetTop - 300;
      }
    }
  }, [errors]);

  return (
    <div
      className={`${style.main} ${isCheckForm && style.noPaddingBottom}`}
      id="formWrapperContainer"
    >
      {menuNames?.map((menu, i) => {
        return (
          <React.Fragment key={menu?.id}>
            {menu?.name === 'Applies to' ? (
              <section id={menu?.id} ref={menuItemEls.current[i]} style={{ marginTop: '0px' }}>
                <AppliesTo
                  appliesToData={appliesToData}
                  siteIds={siteIds}
                  equipmentIds={equipmentIds}
                  pipelineIds={pipelineIds}
                  structureIds={structureIds}
                  selectedOrderId={appliesToData?.id || null}
                  ref={{
                    menuItemEls
                  }}
                />
              </section>
            ) : (
              <div ref={menuItemEls.current[i]} id={menu?.id} className={`menu-${i}`}>
                {appliesToData?.type === 'Work Order' &&
                formSectionsWithSubSectionsMap[menu?.id]?.name === 'Work log' ? (
                  <>
                    <WorkLog
                      workType={workType}
                      currentSelectedTasksList={appliesToData}
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      watch={watch}
                      getValues={getValues}
                    />
                    <YourAttachment
                      register={register}
                      control={control}
                      errors={errors}
                      setValue={setValue}
                      watch={watch}
                      getValues={getValues}
                      workType={workType}
                    />
                  </>
                ) : (
                  <>
                    <SectionContainer
                      text={formSectionsWithSubSectionsMap[menu?.id]?.name}
                      noMargin={
                        formSectionsWithSubSectionsMap[menu?.id]?.formFields?.length ? false : true
                      }
                      headerClassName={
                        workType === WorkTypes.NewCheck && checkType === '400002'
                          ? style.hideSection
                          : ''
                      }
                      bodyClassName={
                        workType === WorkTypes.NewCheck && checkType === '400002'
                          ? style.bodyClassName
                          : ''
                      }
                    >
                      <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATE_COMPLETED_WORKORDER}>
                        <TodoAndSyncedButton
                          workType={workType}
                          setActiveBtn={setActiveBtn}
                          activeBtn={activeBtn}
                        />
                      </PermissionRestrict>
                      {formSectionsWithSubSectionsMap[menu?.id]?.formFields?.map((field, index) => {
                        const sortedFields = field?.sort((a, b) => a?.order - b?.order);

                        return field.length === 2 ? (
                          <div
                            className={`${style['grid-2']} 
                                ${
                                  index ===
                                  formSectionsWithSubSectionsMap[menu?.id]?.formFields?.length - 1
                                    ? style['pb-16']
                                    : ''
                                }
                                
                                `}
                            key={index}
                            style={{ width: '100%' }}
                          >
                            {sortedFields?.map((fieldValue) => {
                              return mapFieldComponents({
                                field: fieldValue,
                                index,
                                grid2: field.length === 2 ? true : false
                              });
                            })}
                          </div>
                        ) : (
                          <div
                            className={`${style['grid-1']} ${
                              ['PassFail', 'PassFailNA', 'Scale', 'PhotoQuestion'].includes(
                                sortedFields?.at(0)?.questionTypeText
                              )
                                ? index > 0 &&
                                  !['PassFail', 'PassFailNA', 'Scale', 'PhotoQuestion'].includes(
                                    formSectionsWithSubSectionsMap[menu?.id]?.formFields[
                                      index - 1
                                    ]?.at(0)?.questionTypeText
                                  )
                                  ? style.boxComponentWthMarginTop
                                  : style.boxComponent
                                : index ===
                                  formSectionsWithSubSectionsMap[menu?.id]?.formFields?.length - 1
                                ? style['pb-16']
                                : ''
                            } 
                               
                                    `}
                            key={index}
                            style={{ width: '100%' }}
                          >
                            {mapFieldComponents({
                              field: sortedFields?.at(0),
                              index,
                              checkPreviousFieldIsBoxed: [
                                'PassFail',
                                'PassFailNA',
                                'Scale',
                                'PhotoQuestion'
                              ].includes(
                                formSectionsWithSubSectionsMap[menu?.id]?.formFields[index - 1]?.at(
                                  0
                                )?.questionTypeText
                              )
                            })}
                          </div>
                        );
                      })}
                    </SectionContainer>
                    {/* sub-sections */}
                    {formSectionsWithSubSectionsMap[menu?.id]?.subSections
                      ?.sort((a, b) => a?.order - b?.order)
                      ?.map((subSection, index) => (
                        <React.Fragment key={index}>
                          {workType === WorkTypes?.NewWorkOrder &&
                            activeBtn === 'Synced' &&
                            subSection?.name === 'Your Attachments' && (
                              <section id="work-log" key={'work-log'} ref={menuItemEls.current[2]}>
                                <WorkLog
                                  workType={workType}
                                  currentSelectedTasksList={appliesToData}
                                  register={register}
                                  control={control}
                                  errors={errors}
                                  setValue={setValue}
                                  watch={watch}
                                  getValues={getValues}
                                />
                              </section>
                            )}

                          {subSection?.formFields?.length > 0 ? (
                            <SectionContainer
                              text={subSection?.name}
                              subSection
                              key={subSection?.formFieldId}
                            >
                              {subSection?.formFields?.map((field, index) => {
                                const sortedFields = field?.sort((a, b) => a?.order - b?.order);

                                return field.length === 2 ? (
                                  <div
                                    className={`${style['grid-2']} 
                                       ${
                                         index === subSection?.formFields?.length - 1
                                           ? style['pb-16']
                                           : ''
                                       }
                                      `}
                                    key={index}
                                    style={{ width: '100%' }}
                                  >
                                    {sortedFields?.map((fieldValue) => {
                                      return mapFieldComponents({
                                        field: fieldValue,
                                        index,
                                        grid2: field.length === 2 ? true : false
                                      });
                                    })}
                                  </div>
                                ) : (field[0]?.questionTypeText === 'ReadableText' ||
                                    field[0]?.questionTypeText === 'ReadableArray') &&
                                  field[0]?.value ? (
                                  <div
                                    className={`${style['grid-1']} ${
                                      index === subSection?.formFields?.length - 1
                                        ? style['pb-16']
                                        : ''
                                    } 
                               
                                    `}
                                    key={index}
                                    style={{ width: '100%' }}
                                  >
                                    {mapFieldComponents({
                                      field: field[0],
                                      index
                                    })}
                                  </div>
                                ) : (
                                  <div
                                    className={`${style['grid-1']} ${
                                      ['PassFail', 'PassFailNA', 'Scale', 'PhotoQuestion'].includes(
                                        sortedFields?.at(0)?.questionTypeText
                                      )
                                        ? index > 0 &&
                                          ![
                                            'PassFail',
                                            'PassFailNA',
                                            'Scale',
                                            'PhotoQuestion'
                                          ].includes(
                                            subSection?.formFields[index - 1]?.at(0)
                                              ?.questionTypeText
                                          )
                                          ? style.boxComponentWthMarginTop
                                          : style.boxComponent
                                        : index === subSection?.formFields?.length - 1
                                        ? style['pb-16']
                                        : ''
                                    } 
                               
                                    `}
                                    key={index}
                                    style={{ width: '100%' }}
                                  >
                                    {mapFieldComponents({
                                      field: sortedFields?.at(0),
                                      index,
                                      checkPreviousFieldIsBoxed: [
                                        'new-work-request',
                                        'new-work-order'
                                      ]?.includes(workType)
                                        ? false
                                        : [
                                            'PassFail',
                                            'PassFailNA',
                                            'Scale',
                                            'PhotoQuestion'
                                          ].includes(
                                            subSection?.formFields[index - 1]?.at(0)
                                              ?.questionTypeText
                                          )
                                    })}
                                  </div>
                                );
                              })}
                            </SectionContainer>
                          ) : (
                            <></>
                          )}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}

      <button
        className={`${style.button} ${appliesToData?.status === 'Pending sync' && style.saveBtn}`}
        type="submit"
      >
        {appliesToData?.status === 'Pending sync' ? 'Save' : <Icon name={'completebtn'} />}
      </button>
    </div>
  );
};

export default React.forwardRef(FormWrapperContainer);
