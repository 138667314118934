/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';

import Label from '@components/label';
import Icon from '@components/icon-component';

import style from './input-field.module.scss';
import { useEffect } from 'react';

const InputField = (
  {
    error,
    className,
    value,
    onChange,
    label,
    type,
    placeholder,
    dot,
    register,
    reconciled,
    disabled,
    required,
    defaultValue,
    limit,
    step,
    autoComplete = 'off',
    name,
    onKeyDown,
    isClearable,
    clearFunc,
    searchIcon,
    onClickResetEvent = null,
    isRequired = false,
    isRequiredMsg = '',
    telephone = false,
    showErrorMessage = true,
    showReconciled = false,
    allowPositiveNumber = false,
    setValue = null,
    onInputFocus = () => {},
    onInputBlur = () => {}
  },
  ref
) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsInputFocused(value ? true : false);
  }, [value]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const formatPhone = (input) => {
    const areaCode = input.slice(0, 3);
    const firstPart = input.slice(3, 6);
    const secondPart = input.slice(6, 10);
    let formatted = '';
    if (areaCode) formatted += `(${areaCode}) `;
    if (firstPart) formatted += `${firstPart}-`;
    if (secondPart) formatted += secondPart;
    return formatted;
  };

  useEffect(() => {
    let numberIsDeleted = false;
    if (telephone && setValue) {
      const phoneInput = document.getElementById(`phone-input-${name || label}`);
      if (phoneInput) {
        const handleInput = (event) => {
          const input = event.target.value;
          let value = null;
          if (!numberIsDeleted) {
            const digitsOnly = input.replace(/\D/g, '');
            const formattedInput = formatPhone(digitsOnly);
            value = formattedInput;
          } else {
            value = input;
            numberIsDeleted = false;
          }

          setValue(name, value);
          phoneInput.value = value;
        };

        const handleInputKeyDown = (event) => {
          if (event.key === 'Backspace' || event.key === 'Delete') {
            numberIsDeleted = true;
          }
        };
        phoneInput.addEventListener('keydown', handleInputKeyDown);
        phoneInput.addEventListener('input', handleInput);
        return () => {
          phoneInput.removeEventListener('keydown', handleInputKeyDown);
          phoneInput.removeEventListener('input', handleInput);
        };
      }
    }
  }, [telephone, setValue, name, label]);

  return (
    <div className={style.fieldInput}>
      {label && (
        <Label
          reconciled={reconciled}
          showReconciled={showReconciled}
          label={label}
          dot={dot}
          error={showErrorMessage ? error : ''}
          ref={ref}
          inLineStyle={{ paddingLeft: '8px' }}
        />
      )}
      <div className={style.inputWrapper} onClick={handleInputFocus} onBlur={handleInputBlur}>
        {searchIcon && (
          <svg
            width="24"
            height="24"
            className={style.icon}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5 18C14.6421 18 18 14.6421 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18Z"
              stroke={isInputFocused === true && isActive === false ? '#2f80ed' : '#cbd2d9 '}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21 20.9998L15.8 15.7998"
              stroke={isInputFocused === true && isActive === false ? '#2f80ed' : '#cbd2d9 '}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        <input
          id={`phone-input-${name || label}`}
          autoComplete={autoComplete}
          name={name}
          type={showPassword ? 'text' : type || 'text'}
          {...(!register && { value: value || '' })}
          onChange={(e) => {
            onChange && onChange(e);
          }}
          onInput={(e) => {
            if (allowPositiveNumber) return e.target.validity.valid || (e.target.value = '');
            else return e;
          }}
          min={allowPositiveNumber ? '0' : Infinity}
          required={required}
          placeholder={placeholder}
          step={step}
          className={`${style.inputField} ${className}  ${error && style.errorBorder} 
           ${type === 'password' && style.passwordInput}`}
          {...(register &&
            register(name, {
              ...(type === 'number' && { value: value || null }),
              required: isRequired ? isRequiredMsg || 'Required' : ''
            }))}
          disabled={disabled}
          maxLength={limit}
          onKeyDown={onKeyDown && onKeyDown}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
        {type === 'password' && (
          <button className={style.passwordToggle} type="button" onClick={togglePasswordVisibility}>
            {showPassword ? (
              isInputFocused ? (
                <Icon name={'blueEye'} />
              ) : (
                <Icon name={'greyEye'} />
              )
            ) : isInputFocused ? (
              <Icon name={'eye-hide-blue'} />
            ) : (
              <Icon name={'eye-hide'} />
            )}
          </button>
        )}
        {!_.isEmpty(value) && isClearable && (
          <Icon
            name={'xdefault'}
            height={24}
            width={24}
            className={style.icon2}
            onClick={() => {
              onClickResetEvent ? onClickResetEvent() : onChange('');

              setIsActive(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default React.forwardRef(InputField);
