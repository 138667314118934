import Lottie from 'react-lottie';

import animationData from '@assets/lotties/Oplii_Loading';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
    className: 'kjsdg-dsjbvchkjbsfhvb'
  }
};

const LoadingIcon = ({ height = 100, width = 100 }) => (
  <Lottie height={height} width={width} options={defaultOptions} isClickToPauseDisabled={true} />
);

export default LoadingIcon;
