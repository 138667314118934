import React from 'react';

import Icon from '@components/icon-component';

import style from './chips.module.scss';

const FilterChips = ({ chipText, deleteChip, cross = true }) => {
  return (
    <div className={`${cross ? style.chips : style.chipsMain}`}>
      <p className={style.p}>{chipText}</p>
      {cross && <Icon name={'blue-cross'} onClick={deleteChip} />}
    </div>
  );
};

export default FilterChips;
