/* eslint-disable no-unused-vars */
import { Link, useLocation } from 'react-router-dom';

import '../app-bar.scss';
import style from '../style.module.scss';

const SearchMenu = ({ moreMenuToggle, onClickLink }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`${style['op-navlink']} ${style['label-regular2']} ${
        !moreMenuToggle && pathname === '/search' ? style.active : ''
      }`}
    >
      <Link to={'/search'} onClick={onClickLink}>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.75 18C14.8921 18 18.25 14.6421 18.25 10.5C18.25 6.35786 14.8921 3 10.75 3C6.60786 3 3.25 6.35786 3.25 10.5C3.25 14.6421 6.60786 18 10.75 18Z"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.25 21L16.05 15.8"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Search
      </Link>
    </div>
  );
};

export default SearchMenu;
