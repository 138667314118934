const relatedPsvAndPvAutoPopulate = ({ psv, pv, jurisdictionNumbersMap }) => {
  const { jurisdictionNumber } = jurisdictionNumbersMap[pv?.equipmentId] || {};
  return {
    RelatedPSV: {
      CPE_Number: psv?.fieldTagNumber || null,
      CRN_Number: psv?.crnNumber || null,
      Manufacturer: psv?.manufacturer || null,
      Model: psv?.model || null,
      Name: psv?.name || null,
      SerialNumber: psv?.serialNumber || null,
      SetPressure: null,
      PSVType: psv?.psvType || null,
      Location: psv?.valveLocation || null,
      FlangeInletJointStyle: psv?.flangeInletJointStyle || null,
      FlangeOutletJointStyle: psv?.flangeOutletJointStyle || null,

      InletSize: {
        textBoxValue: psv?.inletSize || null,
        textBoxUnitValue: psv?.inletSizeMeasurement || ''
      },
      OutletSize: {
        textBoxValue: psv?.outletSize || null,
        textBoxUnitValue: psv?.outletSizeMeasurement || ''
      },

      HeadThickness: {
        textBoxValue: psv?.headThickness || null,
        textBoxUnitValue: psv?.headThicknessMeasurement || ''
      },
      Head2Thickness: {
        textBoxValue: psv?.head2Thickness || null,
        textBoxUnitValue: psv?.head2ThicknessMeasurement || ''
      },
      OutsideDiameter: {
        textBoxValue: psv?.outsideDiameter || null,
        textBoxUnitValue: psv?.outsideDiameterMeasurement || ''
      },
      ShellThickness: {
        textBoxValue: psv?.shellThickness || null,
        textBoxUnitValue: psv?.shellThicknessMeasurement || ''
      },
      Diameter: {
        textBoxValue: psv?.diameter || null,
        textBoxUnitValue: psv?.diameterMeasurement || ''
      },
      TankHeight: {
        textBoxValue: psv?.height || null,
        textBoxUnitValue: psv?.heightMeasurement || ''
      },
      Length: {
        textBoxValue: psv?.length || null,
        textBoxUnitValue: psv?.lengthMeasurement || ''
      },
      MAWP: {
        textBoxValue: psv?.mawp || null,
        textBoxUnitValue: psv?.mawpMeasurement || ''
      },
      SetPointPressure: {
        textBoxValue: psv?.setPointPressure || null,
        textBoxUnitValue: psv?.setPointPressureMeasurement || ''
      },
      Pressure: {
        textBoxValue: psv?.pressure || null,
        textBoxUnitValue: psv?.pressureMeasurement || ''
      },
      MAWT: {
        textBoxValue: psv?.mawt || null,
        textBoxUnitValue: psv?.mawtMeasurement || ''
      },
      MDMT: {
        textBoxValue: psv?.mdmt || null,
        textBoxUnitValue: psv?.mdmtMeasurement || ''
      },
      Capacity: {
        textBoxValue: psv?.psvCapacity || null,
        textBoxUnitValue: psv?.psvCapacityMeasurement || ''
      },
      Volume: {
        textBoxValue: psv?.volume || null,
        textBoxUnitValue: psv?.volumeMeasurement || ''
      }
    },
    RelatedPV: {
      JurisdictionNumber: jurisdictionNumber,
      Name: pv?.name
    }
  };
};

export default relatedPsvAndPvAutoPopulate;
