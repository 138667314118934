/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { store } from 'redux/store';

import SearchField from '@components/search-field';
import FilterHeader from '@components/filters/filter-header';
import SiteBookmark from './site-bookmark';
import SiteFilterGroup from '@components/filters/site-filter-group';
import TaskFilterGroup from '@components/filters/task-filter-group';

import useWindowDimensions from '@hooks/useWindowDimensions';
import { mapValues, countFilters, getSiteFilterDefaultValues } from './helper';
import { getAppDispatch } from '@utilities/dispatch.util';
import { checkValueInObjectIsNotEmpty } from '@utilities/helper.util';
import { filterActions } from '@redux/slices/filters';

import style from './style.module.scss';
import ResetAndViewButton from '@components/filters/reset-view-button';

const SiteFilter = ({
  length,
  filtersCount,
  setFilterActive,
  noGap,
  onSearch,
  resetFilters,
  setResetFilters,
  isFilterActive,
  setFiltersCount,
  onChangeFilters,
  sitesSiteTypeDataMap,
  sitesSiteStatusDataMap,
  sitesCheckPriorityDataMap,
  sitesAssignableUsersDataMap,
  sitesWorkOrderPriorityDataMap,
  sitesAreaHierarchyTreeDataMap,
  sites,
  selectedSort,
  bookmarkFilter,
  filterType,
  removeClassOnAssetDetailsFilters = false,
  siteId = null,
  detailPage = false
}) => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { width } = useWindowDimensions();
  const dueDateFilter = searchParams.get('dueDate');
  const downloadFilter = searchParams.get('download');
  const keyNameFilter = searchParams.get('keyName');
  const tabFilter = searchParams.get('tab');
  const { unit, coordinates } = useSelector((state) => state.cache);
  const sitesFilter = useSelector((state) => state?.filters?.sitesFilter) || {};
  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state?.picklists
  );

  const [isActive, setActive] = useState(isFilterActive);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState(getSiteFilterDefaultValues);
  const [initialY, setInitialY] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = getAppDispatch();

  useEffect(() => {
    setActive(isFilterActive);
    if (isFilterActive) document.body.classList.add('filter-open');
    else document.body.classList.remove('filter-open');
  }, [isFilterActive]);

  useEffect(() => {
    return () => {
      if (isFilterActive) {
        setActive(false);
        document.body.classList.remove('filter-open');
      }
    };
  }, [isFilterActive]);

  const {
    siteTypeTags,
    siteStatusTags,
    assignableUsersTags,
    checkPriorityTags,
    workOrderPriorityTags,
    treeData,
    taskTypeTag,
    taskStatusTags,
    taskRecurringTemplateTags,
    workOrderTypeTags,
    inspectionTypeTags,
    inspectionRunTags,
    hseEventsCategoriesTags,
    checkRecurrancePriorityTags,
    checkTypeTags,
    checkTypeTagsMap,
    inspectionTypeTagsMap
  } = useMemo(() => {
    return mapValues(
      sitesSiteTypeDataMap,
      sitesSiteStatusDataMap,
      sitesAssignableUsersDataMap,
      sitesCheckPriorityDataMap,
      sitesWorkOrderPriorityDataMap,
      sitesAreaHierarchyTreeDataMap,
      sites,
      { ...store.getState() }
    );
  }, [
    sitesSiteTypeDataMap,
    sitesSiteStatusDataMap,
    sitesAssignableUsersDataMap,
    sitesCheckPriorityDataMap,
    sitesWorkOrderPriorityDataMap,
    sitesAreaHierarchyTreeDataMap
  ]);

  const startTouch = useCallback((e) => setInitialY(e.touches[0].clientY), []);

  const moveTouch = useCallback((e) => {
    let currentY = e.touches[0].clientY;
    let diffY = initialY - currentY;
    if (diffY < 0) setFilterActive(false);
    setInitialY(e.touches[0].clientY);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(bookmarkFilter)) {
      const filtersInDecode = decodeURIComponent(bookmarkFilter);
      const filterInParse = JSON.parse(filtersInDecode);
      filterInParse?.filters && setFilters(filterInParse?.filters);
      filterInParse?.filters &&
        filterInParse?.filters['searched'] &&
        setInputValue(filterInParse?.filters['searched'] || '');
    } else if (checkValueInObjectIsNotEmpty(sitesFilter) && !detailPage) {
      //TODO
      setFilters(sitesFilter);
      onChangeFilters?.(sitesFilter);
      setInputValue(sitesFilter?.searched || '');
      onSearch(sitesFilter?.searched || '');
    } else {
      setInputValue('');
      setFilters(getSiteFilterDefaultValues);
    }
    if (resetFilters) resetBtnEvent({ filterActive: width < 768 ? false : true });
  }, [resetFilters, state?.resetFilters, bookmarkFilter]);

  useEffect(() => {
    if (downloadFilter) updateFilters('download', 'selected', downloadFilter);
  }, [downloadFilter]);

  useEffect(() => {
    if (dueDateFilter === 'today') {
      updateFilters('dueDate', 'value', moment());
      updateFilters('dueDate', 'selected', 'on');
    } else if (dueDateFilter === 'past') {
      updateFilters('dueDate', 'selected', 'past');
      updateFilters('dueDate', 'value', ['Unlimited']);
    }
  }, [dueDateFilter]);

  useEffect(() => {
    const filterCount = countFilters(filters, inputValue, { ...store.getState() }) || 0;
    !detailPage && dispatch && dispatch?.(filterActions.setSitesFiltersCount(filterCount)); //TODO
    setFiltersCount(filterCount);
  }, [filters, inputValue, detailPage, dispatch]);

  const conditionClass = useMemo(() => {
    return width < 768 && removeClassOnAssetDetailsFilters && !isFilterActive
      ? style.displayNone
      : style.displayBlock;
  }, [isFilterActive]);

  const updateFilters = (name, key, value) => {
    const filtersCopy = { ...filters };
    let newFilter = {
      ...filtersCopy,
      [name]: {
        ...Object.assign({}, filtersCopy[name]),
        [key]: value,
        ...(key === 'selected' && value === 'on' && { value: '' }),
        ...(key === 'selected' && (value === 'past' || value === 'next') && { value: [] }),
        ...(key === 'selected' && value === 'range' && { value: ['', ''] }),
        ...(key === 'selected' && value === 'last' && { value: ['last'] })
      }
    };
    setFilters({ ...newFilter });
    onChangeFilters?.({ ...newFilter });
    !detailPage &&
      dispatch?.(filterActions.setSitesFilters({ ...newFilter, searched: inputValue })); //TODO
  };

  const resetBtnEvent = useCallback(({ filterActive = true }) => {
    setResetFilters(false);
    navigate(`${pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`, {
      replace: true
    });
    setFilterActive(filterActive);
    setInputValue('');
    setFilters(getSiteFilterDefaultValues);
    onChangeFilters?.(getSiteFilterDefaultValues);
    onSearch('');
    !detailPage &&
      dispatch?.(filterActions.setSitesFilters({ ...getSiteFilterDefaultValues, searched: '' })); //TODO
  }, []);

  const leftBtnEvent = useCallback(() => setFilterActive(false));

  const handleUserInput = useCallback((e) => {
    const valueSearched = (e && e.target.value) || '';
    onSearch?.(valueSearched);
    !detailPage &&
      dispatch?.(filterActions.setSitesFilters({ ...filters, searched: valueSearched })); //TODO
    setInputValue(valueSearched);
  }, []);

  const handleKeyDown = useCallback((e) => {
    if (width < 768 && e.key === 'Enter') setFilterActive(false);
  }, []);

  return (
    <>
      <div
        className={`op-sidebarfilter ${isActive && 'open'} ${!noGap && 'gap'} ${conditionClass} ${
          isActive ? style.isActivePadding : style.isInActivePadding
        } `}
      >
        <div onTouchStart={startTouch} onTouchMove={moveTouch}>
          <FilterHeader
            title="Filters"
            filterCount={filtersCount || 0}
            resetBtnEvent={() => resetBtnEvent({})}
            leftBtnEvent={leftBtnEvent}
          />
        </div>
        <div className="op-filter-search" style={{ marginTop: '25px' }}>
          <SearchField
            placeholder="Search"
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleUserInput}
            isClearable
          />
        </div>

        <SiteFilterGroup
          coordinates={coordinates}
          filters={filters}
          unit={unit}
          updateFilters={updateFilters}
          isActive={isActive}
          treeData={treeData}
          areaHierarchyTreeDataMap={areaHierarchyTreeDataMap}
          siteTypeTags={siteTypeTags}
          picklistsMap={picklistsMap}
          siteStatusTags={siteStatusTags}
          siteListing
        />

        {!removeClassOnAssetDetailsFilters && (
          <TaskFilterGroup
            filterType={filterType}
            taskTypeTag={taskTypeTag}
            filters={filters}
            updateFilters={updateFilters}
            taskStatusTags={taskStatusTags}
            workOrderTypeTags={workOrderTypeTags}
            assignableUsersTags={assignableUsersTags}
            picklistsMap={picklistsMap}
            workOrderPriorityTags={workOrderPriorityTags}
            taskRecurringTemplateTags={taskRecurringTemplateTags}
            isActive={isActive}
            inspectionRunTags={inspectionRunTags}
            hseEventsCategoriesTags={hseEventsCategoriesTags}
            checkRecurrancePriorityTags={checkRecurrancePriorityTags}
            checkTypeTags={checkTypeTags}
            checkTypeTagsMap={checkTypeTagsMap}
            inspectionTypeTagsMap={inspectionTypeTagsMap}
          />
        )}

        <ResetAndViewButton
          clickResetButton={() => resetBtnEvent({ filterActive: false })}
          length={length}
          setFilterActiveEvent={(value) => setFilterActive(value)}
          setIsModalVisibleEvent={(value) => setIsModalVisible(value)}
          width={width}
          detailPage={detailPage}
        />
      </div>
      {isModalVisible && (
        <SiteBookmark
          open={isModalVisible}
          setOpen={(value) => {
            setIsModalVisible(value);
          }}
          filters={{ ...filters, searched: inputValue }}
          selectedSort={selectedSort}
          parentSiteId={siteId}
          keyName={keyNameFilter}
          tab={tabFilter}
          filterType={filterType}
        />
      )}
    </>
  );
};

export default React.memo(SiteFilter, _.isEqual);
