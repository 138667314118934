import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';

import PermissionRestrict from 'hoc/permission-restrict';

import { decodeToken } from '@utilities/authentication';
import { PERMISSIONS } from 'permissions/constants';

import style from './see.module.scss';

const WhatCanISee = () => {
  const { areaHierarchyTreeDataMap = {} } = useSelector((state) => state.picklists);
  const { settings = {} } = useSelector((state) => state.settings);

  const assets = settings['mobileAreaIds'] || [];
  const assetsMap = assets.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const runInspectionDownload =
    useSelector((state) => state.settings.settings?.runInspectionDownload) || '';
  const workOrderDownload =
    useSelector((state) => state.settings.settings?.workOrderDownload) || '';

  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className={style.bgColor}>
      <div className={style.seeCard}>
        <h6 className={style.titleClass}>Glad you asked, {decodeToken()?.firstName}</h6>
        <p className={style.para}>
          What you can see and do in Oplii Mobile depends on your permissions and settings. Here’s a
          summary of what you can see and do.
        </p>
        <h6 className={style.heading}>Task-wise: </h6>
        <ul>
          <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS}>
            <li> Work orders due/scheduled in the next {workOrderDownload} day(s) </li>
          </PermissionRestrict>
          <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS}>
            <li>Inspections due/scheduled in the next {runInspectionDownload} day(s)</li>
          </PermissionRestrict>
          <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_CHECKS}>
            <li>Checks due/scheduled in the next 90 day(s)</li>
          </PermissionRestrict>
        </ul>

        <h6 className={style.heading}>Asset data in: </h6>

        <ul>
          {assets.filter((el) => areaHierarchyTreeDataMap[el]).length > 0 ? (
            <div className="op-selected">
              {assets.map((item) => (
                <Fragment key={item}>
                  {areaHierarchyTreeDataMap[item] &&
                    (!areaHierarchyTreeDataMap[item].parentKey ||
                      !assetsMap[areaHierarchyTreeDataMap[item].parentKey]) && (
                      <li>{areaHierarchyTreeDataMap[item].title}</li>
                    )}
                </Fragment>
              ))}
            </div>
          ) : (
            <li>All areas</li>
          )}
        </ul>

        <h6 className={style.heading}>Your abilities include:</h6>
        <ul>
          <PermissionRestrict
            roles={
              PERMISSIONS.MOBILE_CREATE_WORKREQUEST ||
              PERMISSIONS.MOBILE_CREATE_WORKORDER ||
              PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS
            }
          >
            <li>Work orders</li>
          </PermissionRestrict>
          <div className={style.marginDiv}>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS}>
              <li>Complete assigned work orders </li>
            </PermissionRestrict>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATE_WORKORDER}>
              <li>Create new work orders</li>
            </PermissionRestrict>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATE_COMPLETED_WORKORDER}>
              <li>Create completed work orders</li>
            </PermissionRestrict>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATE_WORKREQUEST}>
              <li>Create new work requests</li>
            </PermissionRestrict>
          </div>
          <PermissionRestrict
            roles={
              PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS || PERMISSIONS.MOBILE_ONDEMAND_INSPECTIONS
            }
          >
            <li>Inspections</li>
          </PermissionRestrict>
          <div className={style.marginDiv}>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS}>
              <li>Complete assigned inspections </li>
            </PermissionRestrict>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_ONDEMAND_INSPECTIONS}>
              <li>Create new inspections</li>
            </PermissionRestrict>
          </div>

          <PermissionRestrict
            roles={PERMISSIONS.MOBILE_ASSIGNED_CHECKS || PERMISSIONS.MOBILE_ONDEMAND_CHECKS}
          >
            <li>Checks</li>
          </PermissionRestrict>

          <div className={style.marginDiv}>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_CHECKS}>
              <li>Complete assigned checks</li>
            </PermissionRestrict>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_CHECKS}>
              <li>Create new checks</li>
            </PermissionRestrict>
          </div>

          <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATE_HSE}>
            <li>HSE events</li>
          </PermissionRestrict>

          <div className={style.marginDiv}>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATE_HSE}>
              <li>Create new HSE events</li>
            </PermissionRestrict>
          </div>

          <li>Sites</li>
          <div className={style.marginDiv}>
            <li>View</li>

            <PermissionRestrict roles={PERMISSIONS.MOBILE_SUGGESTCHANGE_SITES}>
              <li>Edit</li>
            </PermissionRestrict>
          </div>

          <li>Structures</li>

          <div className={style.marginDiv}>
            <li>View</li>

            <PermissionRestrict roles={PERMISSIONS.MOBILE_SUGGESTCHANGE_STRUCTURES}>
              <li>Edit</li>
            </PermissionRestrict>
          </div>
          <PermissionRestrict roles={PERMISSIONS.MOBILE_VIEW_EQUIPMENT}>
            <li>Equipment</li>
          </PermissionRestrict>
          <div className={style.marginDiv}>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_VIEW_EQUIPMENT}>
              <li>View</li>
            </PermissionRestrict>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATEEDIT_EQUIPMENT}>
              <li>Edit</li>
            </PermissionRestrict>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATEEDIT_EQUIPMENT}>
              <li>Add new</li>
            </PermissionRestrict>
          </div>
          <PermissionRestrict
            roles={PERMISSIONS.MOBILE_VIEW_PIPELINES || PERMISSIONS.MOBILE_SUGGESTCHANGE_PIPELINES}
          >
            <li>Pipeline</li>
          </PermissionRestrict>
          <div className={style.marginDiv}>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_VIEW_PIPELINES}>
              <li>View</li>
            </PermissionRestrict>
            <PermissionRestrict roles={PERMISSIONS.MOBILE_SUGGESTCHANGE_PIPELINES}>
              <li>Edit</li>
            </PermissionRestrict>
          </div>
        </ul>
        <PermissionRestrict
          roles={
            PERMISSIONS.MOBILE_CHANGE_CACHED_AREAS || PERMISSIONS.MOBILE_CHANGE_DISPLAY_SETTINGS
          }
        >
          <h6 className={style.heading}>Changing settings</h6>
        </PermissionRestrict>
        <ul>
          <PermissionRestrict roles={PERMISSIONS.MOBILE_CHANGE_CACHED_AREAS}>
            <li> You can change the areas for which data is cached. </li>
          </PermissionRestrict>
          <PermissionRestrict roles={PERMISSIONS.MOBILE_CHANGE_DISPLAY_SETTINGS}>
            <li> You can change the type of data displayed in listings and on task cards. </li>
          </PermissionRestrict>
        </ul>
      </div>
    </div>
  );
};

export default WhatCanISee;
