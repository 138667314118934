/* eslint-disable no-unused-vars */

import React, { useMemo, useState, useCallback } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Textarea from '@components/textarea';
import TextBoxUnit from '@components/text-box-unit';
import InputField from '@components/input-field';
import Datepicker from '@components/date-picker-component';
import Portal from '@components/portal';
import Icon from '@components/icon-component';
import Button from '@components/button';
import Select from '@components/select-box-component';
import SaveModal from '@components/modal/variants/save-modal';
import HierarchicalDropdown from '@components/hierarchical-dropdown/hierarchical-dropdown';

import { schema, getFormFields, setDefaultValuesOnForm, defaultValues } from './helper';

import style from './style.module.scss';

const PSVs = ({
  open,
  title = 'Add PSV',
  closed,
  cachedPSVData,
  dropdownMenuMapList,
  openAttachPSVModal,
  openDeAttachPSVModal,
  openDeletePSVModal,
  updateExistingPsv,
  addNewPsv,
  cachedPSVReconcile
}) => {
  const [saveChanges, setSaveChanges] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
    getValues,
    watch,
    reset,
    register
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const onSubmitHandler = (data) => {
    const options =
      dropdownMenuMapList?.EquipmentCategoryTypeSubtypePickList?.filter(
        (item) => item?.allowedForPSVCreation
      ) || [];

    const keys = _.uniq(getParentKeys(options, data?.psvCategoryType));
    const equipmentCategory = keys[0] || '';
    const equipmentType = keys[1] || '';
    const equipmentSubType = keys[2] || '';
    if (cachedPSVData && cachedPSVData?.equipmentId)
      updateExistingPsv({
        data: { ...data, equipmentCategory, equipmentType, equipmentSubType },
        psvId: cachedPSVData?.equipmentId,
        cachedPSVData,
        isNew: cachedPSVData?.isNew ? true : false
      });
    else addNewPsv({ data: { ...data, equipmentCategory, equipmentType, equipmentSubType } });
    reset({
      ...defaultValues
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmitHandler)(event);
  };

  const getParentKeys = (data, targetKey, parentKeys = []) => {
    for (const item of data) {
      if (item.key === targetKey) {
        parentKeys.push(item.key);
        // if (item.parentKey) {
        //   getParentKeys(data, item.parentKey, parentKeys);
        // }
        break;
      } else if (item.child && item.child.length > 0) {
        parentKeys.push(item.key);
        getParentKeys(item.child, targetKey, parentKeys);
        if (parentKeys.includes(targetKey)) {
          break;
        } else {
          parentKeys.pop();
        }
      }
    }
    return parentKeys;
  };

  const goBack = () => {
    if (isDirty && Object.keys(dirtyFields)?.length) setSaveChanges(true);
    else {
      reset({ ...defaultValues });
      closed(false);
    }
  };

  const formFields = useMemo(() => {
    if (cachedPSVData) {
      reset({
        ...setDefaultValuesOnForm({ cachedPSVData, cachedPSVReconcile })
      });
    }
    return getFormFields({
      edit: cachedPSVData && !cachedPSVData?.isNew ? true : false,
      dropdownMenuMapList
    });
  }, [cachedPSVData, dropdownMenuMapList, cachedPSVReconcile]);

  const isInputReconcile = useCallback(
    ({ fieldName, isMasterData }) => {
      return !_.isEmpty(watch(`${fieldName}-reconcile`))
        ? isMasterData && watch(`${fieldName}-reconcile`) !== watch(fieldName)
          ? true
          : false
        : !_.isEmpty(watch(fieldName)) && isMasterData;
    },
    [watch]
  );

  const isSelectBoxReconcile = useCallback(
    ({ fieldName, isMasterData, options }) => {
      return !_.isEmpty(watch(`${fieldName}-reconcile`))
        ? isMasterData &&
          options?.length > 0 &&
          watch(`${fieldName}-reconcile`) !== watch(fieldName)
          ? true
          : false
        : !_.isEmpty(watch(fieldName)) && options?.length > 0 && isMasterData;
    },
    [watch]
  );
  const isTextBoxUnitReconcile = useCallback(
    ({ fieldName, isMasterData }) => {
      return !_.isEmpty(watch(`${fieldName}-reconcile.textBoxUnitValue`))
        ? !_.isNull(watch(`${fieldName}-reconcile.textBoxValue`)) &&
          isMasterData &&
          watch(`${fieldName}.textBoxValue`)?.toString() !==
            watch(`${fieldName}-reconcile.textBoxValue`)?.toString()
          ? true
          : watch(`${fieldName}.textBoxUnitValue`) !==
            watch(`${fieldName}-reconcile.textBoxUnitValue`)
          ? true
          : false
        : !_.isNull(watch(`${fieldName}.textBoxValue`)) &&
            !_.isEmpty(watch(`${fieldName}.textBoxUnitValue`)) &&
            isMasterData;
    },
    [watch]
  );

  const iconName = useMemo(() => {
    return cachedPSVData?.isNew
      ? 'delete'
      : !cachedPSVData?.isDetached
      ? 'de-attached'
      : 'attached';
  }, [cachedPSVData]);

  const psvIconClickEvent = () => {
    if (cachedPSVData?.isNew) openDeletePSVModal();
    else if (!cachedPSVData?.isDetached) openDeAttachPSVModal();
    else if (cachedPSVData?.isDetached) openAttachPSVModal();
  };

  const handleSave = () => {
    handleSubmit((data) => onSubmitHandler(data))();
    setSaveChanges((prev) => !prev);
  };

  const isButtonDisabled = useMemo(() => {
    return watch('equipmentName') && watch('status') && watch('psvCategoryType') ? false : true;
  }, [watch('equipmentName'), watch('status'), watch('psvCategoryType')]);

  return (
    <Portal>
      <form onSubmit={onSubmit} id="addPSVs">
        <div className={open ? style.OpenPSVsModal : style.ClosedPSVsModal}>
          <div className={style.modalHeight}>
            <div className={` px-4 md:px-8 py-7 ${style.header} `}>
              <p className={style.cancelClass} onClick={goBack}>
                Cancel
              </p>
              <h1 className={`h1-semibold ${style.addClass}`}>{title}</h1>
              <div className={style.invisibleDiv}>Cancel</div>
            </div>
            <div className={style.addDocumentForm}>
              {formFields?.map((field, index) => {
                return (
                  <div
                    className={index === formFields?.length - 1 ? '' : style['mb-16']}
                    key={field?.order}
                  >
                    {field?.type === 'Text' && (
                      <div className="op-input-field-wrap">
                        <InputField
                          showErrorMessage={!field?.isMasterData}
                          label={field?.label}
                          dot={field?.required}
                          type={'text'}
                          inputClass="input-field input-regular"
                          labelColor={style.labelColor}
                          className={field?.isReadOnly ? style.disabledColor : ''}
                          register={register}
                          name={field?.name}
                          error={errors[field?.name]?.message ? 'Required' : ''}
                          disabled={field?.isReadOnly}
                          reconciled={field?.isMasterData}
                          showReconciled={isInputReconcile({
                            fieldName: field?.name,
                            isMasterData: field?.isMasterData
                          })}
                        />
                      </div>
                    )}
                    {field?.type == 'Dropdown' && (
                      <Select
                        showErrorMessage={!field?.isMasterData}
                        dot={field?.required}
                        label={field?.label}
                        options={field?.options}
                        labelColor={style.labelColor}
                        control={control}
                        name={field?.name}
                        error={errors[field?.name]?.message ? 'Required' : ''}
                        register={register}
                        isClearable
                        disabled={field?.isReadOnly}
                        reconciled={field?.isMasterData}
                        showReconciled={isSelectBoxReconcile({
                          fieldName: field?.name,
                          isMasterData: field?.isMasterData,
                          options: field?.options
                        })}
                      />
                    )}

                    {field?.type === 'HierarchicalDropdown' && (
                      <HierarchicalDropdown
                        options={field?.options}
                        dot={field?.required}
                        label={field?.label}
                        name={field?.name}
                        error={errors[field?.name]?.message ? 'Required' : ''}
                        disabled={field?.isReadOnly}
                        reconciled={field?.isMasterData}
                        showReconciled={
                          !_.isEmpty(watch(`${field?.name}-reconcile`)) &&
                          field?.isMasterData &&
                          field?.options?.length > 0 &&
                          watch(`${field?.name}-reconcile`) !== watch(field?.name)
                            ? true
                            : false
                        }
                        setValue={setValue}
                        defaultValue={getValues(field?.name)}
                        equipmentCategoryTypeSubtype
                      />
                    )}

                    {field?.type === 'Date' && (
                      <Datepicker
                        backClass={style.datePicker2}
                        id={'psvDate'}
                        label={field?.label}
                        inputClass="input-regular op-datepicker"
                        control={control}
                        name={field?.name}
                        error={errors[field?.name]?.message ? 'Required' : ''}
                        register={register}
                        isClearable
                        readOnly={true}
                        dot={field?.required}
                      />
                    )}

                    {field?.type === 'Readings' && (
                      <TextBoxUnit
                        inputLabel={`${field?.label}`}
                        control={control}
                        register={register}
                        inputFiledRequired={field?.required}
                        setValue={setValue}
                        watch={watch}
                        error={
                          errors[field?.name]?.textBoxValueErrors?.message ||
                          errors[field?.name]?.textBoxUnitValueErrors?.message
                            ? 'Required'
                            : null
                        }
                        name={field?.name}
                        options={field?.options}
                        reconciled={field?.isMasterData}
                        showReconciled={isTextBoxUnitReconcile({
                          fieldName: field?.name,
                          isMasterData: field?.isMasterData
                        })}
                      />
                    )}
                    {field?.type == 'LongText' && (
                      <Textarea
                        dot={field?.required}
                        label={field?.label}
                        inputClass="input-field input-regular"
                        labelColor={style.labelColor}
                        register={register}
                        name={field?.name}
                        error={errors[field?.name]?.message ? 'Required' : ''}
                        isRequiredMsg={'Required'}
                        reconciled={field?.isMasterData}
                        showReconciled={isInputReconcile({
                          fieldName: field?.name,
                          isMasterData: field?.isMasterData
                        })}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className={style.footerBtn}>
            {cachedPSVData && (
              <Icon
                name={iconName}
                alt={iconName}
                style={{ marginRight: cachedPSVData ? '48px' : '0px' }}
                onClick={psvIconClickEvent}
              />
            )}

            <Button
              btnClass="btn-fill button-large"
              type="submit"
              form="addPSVs"
              text="Save + Verify"
              disabled={isButtonDisabled}
            />
          </div>
        </div>
      </form>

      {saveChanges && (
        <SaveModal
          open={saveChanges}
          setOpen={setSaveChanges}
          saveChanges={true}
          onDiscard={() => {
            closed(false);
          }}
          onSave={handleSave}
        />
      )}
    </Portal>
  );
};

export default PSVs;
