import './app.scss';

import { Provider } from 'react-redux';
import PWAPrompt from 'react-ios-pwa-prompt';
import { persistor, store } from '@redux/store';

import Main from '@containers/main';
import Routes from './routes/router';
import RegisterLocation from '@components/register-location';
import RegisterAppDispatch from '@components/register-app-dispatch';
import ToasterComponent from '@components/toaster-component';

import SyncModalEffecting from '@utilities/sync-modal-effecting';
import { PersistGate } from 'redux-persist/integration/react';
import { ToasterContextProvider } from 'context/toaster.context';

function App() {
  return (
    <Main>
      <PWAPrompt
        promptOnVisit={1}
        timesToShow={3}
        copyClosePrompt="Close"
        permanentlyHideOnDismiss={true}
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToasterContextProvider>
            <RegisterAppDispatch />
            <RegisterLocation />
            <SyncModalEffecting />
            <ToasterComponent />
            <Routes />
          </ToasterContextProvider>
        </PersistGate>
      </Provider>
    </Main>
  );
}

export default App;
