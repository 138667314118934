import React from 'react';
import Icon from '@components/icon-component';

import style from './date-picker.module.scss';

const DatePickerIcon = ({ id, isOpen, timePicker }) => {
  return (
    <label htmlFor={id} className={style.labelDate}>
      <Icon
        name={
          isOpen ? (timePicker ? 'clock-blue' : 'calendar-blue') : timePicker ? 'clock' : 'calendar'
        }
        className={style.icon}
      />
    </label>
  );
};

export default DatePickerIcon;
