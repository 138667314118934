/* eslint-disable no-unused-vars */
import React from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';

import InputField from '@components/input-field';
import Select from '@components/select-box-component';
import Textarea from '@components/textarea';
import Portal from '@components/portal';

import style from './style.module.scss';
import TextBoxUnit from '@components/text-box-unit';
import { useEffect } from 'react';

const EditPipeline = ({ open, title = 'Edit pipeline', closedModal, pipelineDetails }) => {
  const { register, control, setValue, watch, reset } = useForm({});

  useEffect(() => {
    reset({
      pipelineNumber: pipelineDetails?.pipelineNumber,
      licenseNumber: pipelineDetails?.licenseNumber,
      lineNumber: pipelineDetails?.lineNumber,
      pipelineType: pipelineDetails?.MoreInfoDataPointsMap?.Type?.value,
      pipelineStatus: pipelineDetails?.MoreInfoDataPointsMap?.Status?.value,
      fromLocation: pipelineDetails?.MoreInfoDataPointsMap?.FromLocation?.value,
      toLocation: pipelineDetails?.MoreInfoDataPointsMap?.ToLocation?.value,
      area: pipelineDetails?.MoreInfoDataPointsMap?.Area?.value,
      material: pipelineDetails?.MoreInfoDataPointsMap?.Material?.value,
      length: {
        textBoxValue: pipelineDetails?.MoreInfoDataPointsMap?.Length?.value,
        textBoxUnitValue: 'km'
      },
      substance: pipelineDetails?.MoreInfoDataPointsMap?.Substance?.value,
      internalProtection: pipelineDetails?.MoreInfoDataPointsMap?.InternalProtection?.value,
      comments: pipelineDetails?.maintenanceComments
    });
  }, [pipelineDetails]);
  return (
    <Portal>
      <div className={open ? style.openModal : style.closedModal}>
        <div className={`${style.header} px-4 md:px-8 py-7`}>
          <p className={style.cancelClass} onClick={() => closedModal()}>
            Cancel
          </p>
          <h1 className={`${style.addClass} h1-semibold`}>{title}</h1>
        </div>
        <div className={style.photoContentBody}>
          <div
            className="op-input-field-wrap"
            style={{
              margin: '16px 0px'
            }}
          >
            <InputField
              label={'Pipeline #'}
              type={'text'}
              inputClass="input-field input-regular"
              labelColor={style.labelColor}
              register={register}
              name={'pipelineNumber'}
              disabled
            />
          </div>

          <div
            className={style.grid2}
            style={{
              margin: '16px 0px'
            }}
          >
            <div className="op-input-field-wrap">
              <InputField
                label={'License #'}
                type={'text'}
                inputClass="input-field input-regular"
                labelColor={style.labelColor}
                register={register}
                name={'licenseNumber'}
              />
            </div>

            <div className="op-input-field-wrap">
              <InputField
                label={'Line #'}
                type={'text'}
                inputClass="input-field input-regular"
                labelColor={style.labelColor}
                register={register}
                name={'lineNumber'}
              />
            </div>
          </div>

          <div
            style={{
              margin: '16px 0px'
            }}
          >
            <Select
              dot={true}
              label={'Type'}
              options={[
                {
                  label: pipelineDetails?.MoreInfoDataPointsMap?.Type?.value,
                  value: pipelineDetails?.MoreInfoDataPointsMap?.Type?.value
                }
              ]}
              labelColor={style.labelColor}
              control={control}
              name={'pipelineType'}
              register={register}
              isClearable
            />
          </div>
          <div
            style={{
              margin: '16px 0px'
            }}
          >
            <Select
              dot={true}
              label={'Status'}
              options={[
                {
                  label: pipelineDetails?.MoreInfoDataPointsMap?.Status?.value,
                  value: pipelineDetails?.MoreInfoDataPointsMap?.Status?.value
                }
              ]}
              labelColor={style.labelColor}
              control={control}
              name={'pipelineStatus'}
              register={register}
              isClearable
            />
          </div>

          <div
            className="op-input-field-wrap"
            style={{
              margin: '16px 0px'
            }}
          >
            <InputField
              label={'From location'}
              type={'text'}
              inputClass="input-field input-regular"
              labelColor={style.labelColor}
              register={register}
              name={'fromLocation'}
            />
          </div>

          <div
            className="op-input-field-wrap"
            style={{
              margin: '16px 0px'
            }}
          >
            <InputField
              label={'To location'}
              type={'text'}
              inputClass="input-field input-regular"
              labelColor={style.labelColor}
              register={register}
              name={'toLocation'}
            />
          </div>

          <div
            style={{
              margin: '16px 0px'
            }}
          >
            <Select
              dot={true}
              label={'Area'}
              options={[
                {
                  label: pipelineDetails?.MoreInfoDataPointsMap?.Area?.value,
                  value: pipelineDetails?.MoreInfoDataPointsMap?.Area?.value
                }
              ]}
              labelColor={style.labelColor}
              control={control}
              name={'area'}
              register={register}
              isClearable
            />
          </div>

          <div
            className="op-input-field-wrap"
            style={{
              margin: '16px 0px'
            }}
          >
            <InputField
              label={'Material'}
              type={'text'}
              inputClass="input-field input-regular"
              labelColor={style.labelColor}
              register={register}
              name={'material'}
            />
          </div>

          <div
            style={{
              margin: '16px 0px'
            }}
          >
            <TextBoxUnit
              inputLabel={`Length`}
              control={control}
              register={register}
              inputFiledRequired={false}
              setValue={setValue}
              watch={watch}
              error={null}
              name={'length'}
              options={[
                {
                  label: 'km',
                  value: 'km'
                }
              ]}
            />
          </div>

          <div
            style={{
              margin: '16px 0px'
            }}
          >
            <Select
              dot={true}
              label={'Substance'}
              options={[
                {
                  label: pipelineDetails?.MoreInfoDataPointsMap?.Substance?.value,
                  value: pipelineDetails?.MoreInfoDataPointsMap?.Substance?.value
                }
              ]}
              labelColor={style.labelColor}
              control={control}
              name={'substance'}
              register={register}
              isClearable
            />
          </div>

          <div
            style={{
              margin: '16px 0px'
            }}
          >
            <Select
              dot={true}
              label={'Internal protection'}
              options={[
                {
                  label: pipelineDetails?.MoreInfoDataPointsMap?.InternalProtection?.value,
                  value: pipelineDetails?.MoreInfoDataPointsMap?.InternalProtection?.value
                }
              ]}
              labelColor={style.labelColor}
              control={control}
              name={'internalProtection'}
              register={register}
              isClearable
            />
          </div>

          <div
            style={{
              margin: '16px 0px'
            }}
          >
            {' '}
            <Textarea
              label={'Comments (for reviewer)'}
              inputClass="input-field input-regular"
              labelColor={style.labelColor}
              register={register}
              name={'comments'}
              isRequiredMsg={'Required'}
            />
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default EditPipeline;
