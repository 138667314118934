/* eslint-disable no-unused-vars */

import React, { useState, forwardRef } from 'react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import Icon from '@components/icon-component';
import Portal from '@components/portal';
import FormWrapperForm from '@components/work-order/form-wrapper-form';
import Applies from '@components/work-order/applies';
import IsBeingChecked from './is-being-checked';
import { WorkTypes } from '@components/work-order/helper';
import DeleteModal from '@components/modal/variants/delete-modal';
import AssignmentBody from '@components/task-card-new/card-body/assignment';
import CheckHeader from './header';

import { getAppDispatch } from '@utilities/dispatch.util';
import ChecksService from '@services/checks.service';
import { useToastContext } from 'context/toaster.context';
import { checkActions } from '@redux/slices/checks';
import { bookmarksActions } from '@redux/slices/bookmarks';

import style from './check.module.scss';
import {
  deleteNewTasksAssetFromIndexDB,
  getPhotosAndDocumentsDataFromSelectedInspection
} from '@services/assets.service';

const CheckFormWrapper = (
  {
    pageHeaderTitle,
    appliesToData,
    siteIds,
    equipmentIds,
    pipelineIds,
    structureIds,
    workType,
    register,
    formSectionsWithSubSections,
    menuNames,
    setValue,
    watch,
    errors,
    control,
    getValues,
    dropdownMenuMapList,
    handleSubmit,
    onSubmitHandler,
    componentsNames,
    autoPopulateData,
    formAppliesTo,
    handleBackClick,
    removeFromTasks,
    selectedTasksList,
    checkType
  },
  ref
) => {
  const dispatch = getAppDispatch();

  const { formWrapperFormRef, menuItemEls, dirtyRef } = ref;
  const { ToasterUtil } = useToastContext();

  const navigate = useNavigate();

  const [info, setInfo] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [showUsers, setShowUsers] = useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit((data) => onSubmitHandler({ data: data, status: 'Pending sync' }))();
  };

  const onKeyDown = (e) => e.key === 'Enter' && e.preventDefault();

  const isCheckIsPendingSync = appliesToData?.status === 'Pending sync' ? true : false;

  const handleDelete = async () => {
    setDeleteButtonLoading(true);

    const { photos, documents } = getPhotosAndDocumentsDataFromSelectedInspection(watch(), true);
    await deleteNewTasksAssetFromIndexDB({ photos, documents, deleteAll: true });

    if (appliesToData?.status === 'To do') removeFromTasks();
    else if (isCheckIsPendingSync && appliesToData?.newCheck) {
      ChecksService.deleteNewCheck({ checkId: appliesToData.id });
    } else {
      // reset assigned check
      dispatch?.(
        checkActions.updateAssignedCheck({
          ...selectedTasksList[0],
          formData: {},
          status: 'To do'
        })
      );

      dispatch?.(bookmarksActions.setBookmarkValidity(true));
    }

    setOpenDeleteModal(false);
    setDeleteButtonLoading(false);

    ToasterUtil.showSuccess(`Task has been reset to its original state.`);
    navigate(-1);
  };

  return (
    <Portal>
      <form id="formWrapper" onKeyDown={onKeyDown} onSubmit={onSubmit}>
        <div className={style.equipmentMain}>
          <>
            <CheckHeader
              handleBackClick={handleBackClick}
              pageHeaderTitle={pageHeaderTitle}
              info={info}
              setInfo={setInfo}
            />

            <div className={style.checkFormWrapper}>
              {info && (
                <>
                  <section
                    className={`${style.appliesTo} ${appliesToData?._id && style.borderBottom} `}
                  >
                    <Applies
                      appliesToData={appliesToData}
                      siteIds={siteIds}
                      equipmentIds={equipmentIds}
                      pipelineIds={pipelineIds}
                      structureIds={structureIds}
                      selectedOrderId={appliesToData?.id || null}
                    />
                  </section>
                  {appliesToData?._id && (
                    <div className={style.moreInfo}>
                      <p className={style.title}>Site check in</p>
                      <p className={style.description}>{appliesToData?.description}</p>

                      <div className={style.dataPoints}>
                        {appliesToData?.dataPoints.map(({ Id, value }, ind) => (
                          <React.Fragment key={ind}>
                            <>
                              {Id === 'ResponsibleUsers' && (
                                <>
                                  {_.size(appliesToData?.users) > 0 && (
                                    <div
                                      onClick={(e) => {
                                        if (!showUsers && appliesToData?.users?.length > 1) {
                                          e.stopPropagation();
                                          setShowUsers((prev) => !prev);
                                        }
                                      }}
                                    >
                                      {showUsers ? (
                                        <div style={{ marginTop: '16px' }}>
                                          <AssignmentBody
                                            iconName={'users'}
                                            assignmentData={appliesToData?.users}
                                            onClick={() => setShowUsers(false)}
                                          />
                                        </div>
                                      ) : (
                                        <div style={{ marginTop: '16px' }}>
                                          <AssignmentBody
                                            iconName={'users'}
                                            text={
                                              appliesToData?.users?.length > 0
                                                ? appliesToData?.users[0]
                                                : ''
                                            }
                                            collapseableValue={
                                              appliesToData?.users.length > 1
                                                ? appliesToData?.users.length - 1
                                                : ''
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                              {Id === 'Recurrence' && value && (
                                <>
                                  {
                                    <div style={{ marginTop: '16px' }}>
                                      <AssignmentBody iconName={'rotate'} text={value} />
                                    </div>
                                  }
                                </>
                              )}

                              {Id === 'Identifier' && value && (
                                <>
                                  {
                                    <div style={{ marginTop: '16px' }}>
                                      <AssignmentBody iconName={'identifier-icon'} text={value} />
                                    </div>
                                  }
                                </>
                              )}

                              {Id === 'Location' && value && (
                                <>
                                  {
                                    <div style={{ marginTop: '16px' }}>
                                      <AssignmentBody iconName={'location-icon'} text={value} />
                                    </div>
                                  }
                                </>
                              )}
                            </>
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}

              {workType === WorkTypes.NewCheck && <IsBeingChecked register={register} />}

              {/* form wrapper forms */}

              <div ref={formWrapperFormRef} id="scrollRoot">
                <FormWrapperForm
                  componentsNames={componentsNames}
                  siteIds={siteIds}
                  equipmentIds={equipmentIds}
                  pipelineIds={pipelineIds}
                  structureIds={structureIds}
                  workType={workType}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  handleSubmit={handleSubmit}
                  onSubmitHandler={onSubmitHandler}
                  dropdownMenuMapList={dropdownMenuMapList}
                  ref={{ menuItemEls, formWrapperFormRef }}
                  appliesToData={appliesToData}
                  menuNames={menuNames}
                  formSectionsWithSubSections={formSectionsWithSubSections}
                  autoPopulateData={autoPopulateData}
                  formAppliesTo={formAppliesTo}
                  isCheckForm
                  checkType={checkType}
                />
              </div>
            </div>
          </>
          <div className={style.footerBtn}>
            <div className={style.container}>
              {isCheckIsPendingSync && (
                <Icon
                  name={'Delete button'}
                  alt=""
                  style={{ marginRight: isCheckIsPendingSync ? '48px' : '0px' }}
                  onClick={() => setOpenDeleteModal(true)}
                />
              )}

              <button className={`btn-fill button-large`} type="submit">
                {isCheckIsPendingSync ? 'Save + close' : 'Complete'}
              </button>
            </div>
          </div>
        </div>
      </form>

      {openDeleteModal && (
        <DeleteModal
          open={openDeleteModal}
          setOpen={deleteButtonLoading ? () => {} : setOpenDeleteModal}
          recordType={'check'}
          handleDelete={handleDelete}
          confirmDeletion={!appliesToData?._id}
          discordChangesAndResetCheck={appliesToData?._id}
          disabledDeleteButton={deleteButtonLoading}
        />
      )}
    </Portal>
  );
};
export default React.memo(forwardRef(CheckFormWrapper, _.isEqual));
