/* eslint-disable no-unused-vars */

import moment from 'moment-timezone';

export const getTaskCardFooter = ({
  status,
  sync,
  dueDate,
  startDate,
  due,
  syncedByText,
  syncedDate,
  syncedBy,
  updatedAt,
  syncDateCustom,
  userId
}) => {
  dueDate = dueDate || due;

  const dates = [];
  let footerColor = '';
  const format = 'YYYY-MM-DD';
  const formatD = 'MMM. DD, YYYY';
  const today = moment().tz('America/Denver').format(format);

  let name = syncedByText;
  if (userId === syncedBy) name = 'you';

  if (status === 'In progress') {
    footerColor = '#E7B820';
    // left
    const overDue = dueDate
      ? moment(moment(dueDate).startOf('day')).isBefore(moment(today).startOf('day'))
      : // moment(dueDate).isBefore(today)
        false;
    let dueText = getRelativeText(dueDate);
    const savedFormatted = moment(updatedAt).isValid ? moment(updatedAt).format(formatD) : '';
    const savedText =
      // getDateText(updatedAt);
      moment(updatedAt).isValid ? moment(new Date(updatedAt)).from(new Date()) : '';

    dates.push(`Saved ${savedText} (${savedFormatted})`);

    if (startDate) {
      const scheduledText = moment(new Date(startDate)).from(
        new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
      );
      // getDateText(startDate);

      const scheduledDate = moment(startDate).format(formatD);
      dates.push(`Scheduled ${scheduledText} (${scheduledDate})`);
    }

    if (moment(dueDate).isValid()) {
      const due = moment(dueDate).format(formatD);
      if (overDue) {
        dates.push(`Overdue by ${dueText.replace(' ago', '')} (${due})`);
      } else {
        dates.push(`Due ${dueText} (${due})`);
      }
    }
  } else if (status === 'Pending sync') {
    footerColor = '#7B8794';
    const overDue = moment(dueDate).isValid()
      ? moment(moment(dueDate).startOf('day')).isBefore(moment(today).startOf('day'))
      : //  moment(dueDate).isBefore(today)
        false;
    let dueText = getRelativeText(dueDate);

    const completedFormatted = moment(updatedAt).format(formatD);

    dates.push(`Completed by ${name || 'you'} (${completedFormatted})`);

    if (moment(dueDate).isValid()) {
      const due = moment(dueDate).isValid() ? moment(dueDate).format(formatD) : '';
      if (overDue) {
        dates.push(`Overdue by ${dueText.replace(' ago', '')} (${due})`);
      } else {
        dates.push(`Due ${dueText} (${due})`);
      }
    }
  } else if (status === 'Synced') {
    footerColor = '#323F4B';
    const overDue = dueDate
      ? moment(moment(dueDate).startOf('day')).isBefore(moment(today).startOf('day'))
      : false;

    // moment(dueDate).isBefore(today) : false;
    let dueText = getRelativeText(dueDate);

    const syncDateText = moment(new Date(syncedDate)).from(
      new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
    );

    const syncDateFormatted = moment(syncedDate).format(formatD);

    dates.push(`Synced by ${name} ${syncDateText} (${syncDateFormatted})`);

    if (dueDate) {
      const due = moment(dueDate).format(formatD);
      if (overDue) {
        dates.push(`Due ${dueText} (${due})`);
      } else {
        dates.push(`Due ${dueText} (${due})`);
      }
    }
  } else if (status === 'To do') {
    let dueText = getRelativeText(dueDate);
    let scheduledText = getRelativeText(startDate);
    const overDue = moment(moment(dueDate).startOf('day')).isBefore(moment(today).startOf('day'));

    // dueDate ? moment(dueDate).isBefore(today) : false;

    if (startDate) {
      const scheduledDate = moment(startDate).format(formatD);
      dates.push(`Scheduled ${scheduledText} (${scheduledDate})`);
    }
    if (sync) {
      footerColor = '#34C369';
      if (dueDate) {
        const due = moment(dueDate).format(formatD);
        dates.push(`Due ${dueText} (${due})`);
      }
    } else {
      if (dueDate) {
        const due = moment(dueDate).format(formatD);
        if (overDue) {
          footerColor = syncDateCustom ? '#34C369' : '#E02B2B';
          dates.push(`Overdue by ${dueText.replace(' ago', '')} (${due})`);
        } else {
          footerColor = syncDateCustom ? '#34C369' : '#2F80ED';
          dates.push(`Due ${dueText} (${due})`);
        }
      }
    }
  }

  return {
    footerColor,
    dates
  };
};

const getRelativeText = (date) => {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const todayInDenver = new Date().toLocaleString('en-US', { timeZone: 'America/Denver' });

  const today = new Date().toLocaleString('en-US', { timeZone: 'America/Denver' });
  const yesterday = new Date(new Date(todayInDenver).getTime() - oneDay);
  const tomorrow = new Date(new Date(todayInDenver).getTime() + oneDay);

  if (moment(date).isSame(today, 'day')) {
    return 'today';
  } else if (moment(date).isSame(yesterday, 'day')) {
    return 'yesterday';
  } else if (moment(date).isSame(tomorrow, 'day')) {
    return 'tomorrow';
  } else {
    return moment(new Date(date)).from(
      new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
    );
  }
};
