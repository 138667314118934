/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import _ from 'lodash';
import { Navigate, useLocation, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

import EquipmentSite from './equipment-site';
import PageHeader from '@components/page-header';
import PageContainer from '@components/page-container';
import EquipmentCard from '@components/equipment-card';
import NewTabs from '@components/new-tabs';
import Icon from '@components/icon-component';
import EquipmentListing from '@components/equipment-listing';
import EquipmentBookmark from '@components/equipment-listing/equipment-bookmark';
import PermissionRestrict from 'hoc/permission-restrict';
import WorkOrder from '../../site-details/tasks/work-order';
import LoadingRound from '@components/loading-round/loading-round';
import EquipmentDetailsMoreInfo from './equipment-details-more-info';

import { PERMISSIONS } from 'permissions/constants';
import { log } from '@utilities/logger.util';
import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { filterActions } from '@redux/slices/filters';
import { initWebWorker } from 'webworker';

import './equipment-site.scss';
import style from './style.module.scss';

const EquipmentSiteDetails = () => {
  const [searchParams] = useSearchParams();
  const dispatch = getAppDispatch();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const swipeAbleMenuRef = useRef(null);
  const mainDivRef = useRef(null);
  const siteCardRef = useRef(null);
  const assetDetailPageHeaderRef = useRef(null);

  const [tab, setTab] = useState('0');
  const [open, setOpen] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editEquipment, setEditEquipment] = useState(false);
  const [allTask, setAllTask] = useState(false);
  const [checks, setChecks] = useState(false);
  const [inspections, setInspections] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [syncComplete, setSyncComplete] = useState(false);
  const [equipmentDetails, setEquipmentDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { sites: cacheSites = [] } = useSelector((state) => state.cache);
  const equipmentDetailsInfo = useSelector((state) => state.backHistory.equipmentDetails);
  const {
    tasksFilterCount = 0,
    psvsFilterCount = 0,
    pvsFilterCount = 0,
    equipmentFilterCount = 0
  } = useSelector((state) => state.filters);
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);
  const [isFilterActiveForChildEquipment, setFilterActiveForChildEquipment] = useState(
    width < 768 ? false : true
  );
  const [isFilterActiveForPSV, setFilterActiveForPSV] = useState(width < 768 ? false : true);
  const [isFilterActiveForPVS, setFilterActiveForPVS] = useState(width < 768 ? false : true);

  const { tasksList: reduxTasksList = [], selectTask } = useSelector((state) => state.tasks);
  const { inspectionsList } = useSelector((state) => state.inspection);
  const { checksList } = useSelector((state) => state.checks);

  const { id } = useParams();
  const { pathname, search } = useLocation();
  const bookmarkName = searchParams.get('bookmarkName');
  const bookmarkTab = searchParams.get('bookmarkTab');
  const currentOpenTaskTab = searchParams.get('currentOpenTaskTab') || '';

  useEffect(() => {
    if (currentOpenTaskTab) {
      if (currentOpenTaskTab === 'allTask') setAllTask(true);
      else if (currentOpenTaskTab === 'workOrder') setWorkOrder(true);
      else if (currentOpenTaskTab === 'inspections') setInspections(true);
      else if (currentOpenTaskTab === 'checks') setChecks(true);
      // else if (currentOpenTaskTab === 'workRequest') setWorkRequest(true);
    }
  }, [currentOpenTaskTab]);

  useEffect(() => {
    if (!_.isEmpty(equipmentDetailsInfo)) {
      equipmentDetailsInfo?.equipmentDetailsTab &&
        setTab(equipmentDetailsInfo?.equipmentDetailsTab || '0');
      equipmentDetailsInfo?.workOrder && setWorkOrder(equipmentDetailsInfo?.workOrder);
    }
  }, [equipmentDetailsInfo]);

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() },
        equipmentId: id
      },
      type: 'get-single-equipment-details'
    })
      .then((data) => {
        setEquipmentDetails(data?.result);
        setIsLoading(false);
        setTab('0');
      })
      .catch((error) => {
        setIsLoading(false);
        setTab('0');
      });
  }, [id, syncComplete, reduxTasksList, inspectionsList, checksList]);

  const {
    equipmentsLoading,
    parentEquipment,
    tasks = [],
    allDoneTasks,
    allChecks,
    allDoneChecks,
    allInspections,
    allDoneInspections,
    allWorkOrders,
    allDoneWorkOrders,
    isCheckOverDue,
    isInspectionOverDue,
    isWorkOrderOverDue,
    isOverDue,
    children = [],
    psv = [],
    psvs = [],
    equipmentsMap,
    pvs = [],
    dataPoints,
    moreInfoDataPointsMap,
    category,
    type,
    sitesMap,
    siteId,

    dataPointsMap,
    data,
    status,
    name,
    cacheEquipments,
    link
  } = React.useMemo(() => {
    return equipmentDetails;
  }, [equipmentDetails]);

  useEffect(() => {
    if (syncComplete) setSyncComplete(false);
  }, [syncComplete]);

  useMemo(() => {
    if (!isLoading) {
      if (!equipmentsLoading && !parentEquipment.equipmentId) return <Navigate to={'/equipment'} />;
      const { children: c, ...rest } = data;
      !dataPoints && log(c);
    }
  }, [equipmentDetails, isLoading]);

  const onParentClickEvent = ({ workOrderTab, workOrderSortTab }) => {
    dispatch?.(
      backHistoryActions.setEquipmentDetailsPageHistory({
        workOrder,
        equipmentDetailsTab: tab,
        workOrderTab,
        workOrderSortTab
      })
    );
  };

  const arrayText = [
    {
      icon: <Icon name={'more-info'} />,
      text: 'More information',
      onClick: () => {
        setMoreInfo(true);
        setOpen(false);
      }
    },
    {
      icon: <Icon name={'bookmark-grey'} />,
      text: 'Bookmark',
      onClick: () => {
        setOpenModal(true);
        setOpen(false);
      }
    },
    {
      icon: <Icon name={'open-in-oplii-desktop'} />,
      text: 'Open in Oplii desktop',
      onClick: () => {
        window.open(link, '_blank');
      }
    }
  ];

  const itemsMap = useMemo(() => {
    if (!isLoading)
      return [
        ...(tasks?.length > 0
          ? [
              {
                label: `Tasks (${tasks?.length})`,
                keyName: 'tasks'
              }
            ]
          : []),
        ...(children?.length > 0
          ? [
              {
                label: `Child equipment (${children.length})`,
                keyName: 'equipment'
              }
            ]
          : []),

        ...(psvs?.length > 0
          ? [
              {
                label: `PSVs (${psvs.length})`,
                keyName: 'psv'
              }
            ]
          : []),

        ...(pvs?.length > 0
          ? [
              {
                keyName: 'vessels'
              }
            ]
          : [])
      ];
    else return [];
  }, [isLoading]);

  const items = useMemo(() => {
    return !isLoading
      ? [
          ...(tasks?.length > 0
            ? [
                {
                  label: `Tasks (${tasks?.length})`,
                  keyName: 'tasks',
                  children: (
                    <PermissionRestrict
                      roles={
                        PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS ||
                        PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS
                      }
                    >
                      <EquipmentSite
                        setChecks={setChecks}
                        setAllTask={setAllTask}
                        setWorkOrder={setWorkOrder}
                        setInspections={setInspections}
                        allWorkOrderForSite={allWorkOrders}
                        allDoneWorkOrder={allDoneWorkOrders}
                        allInspectionForSite={allInspections}
                        allCheckForSite={allChecks}
                        allDoneChecks={allDoneChecks}
                        allDoneInspection={allDoneInspections}
                        allTasksForSite={tasks}
                        allDoneTasks={allDoneTasks}
                        isOverDue={isOverDue}
                        isCheckOverDue={isCheckOverDue}
                        isCheck
                        isInspectionOverDue={isInspectionOverDue}
                        isWorkOrderOverDue={isWorkOrderOverDue}
                      />
                    </PermissionRestrict>
                  )
                }
              ]
            : []),

          ...(children?.length > 0
            ? [
                {
                  label: `Child equipment (${children.length})`,
                  keyName: 'equipment',
                  children: (
                    <EquipmentListing
                      assetDetailsOfEquipmentLists={children}
                      isFilterActiveHeader={isFilterActiveForChildEquipment}
                      setFilterActiveHeader={setFilterActiveForChildEquipment}
                      from={pathname}
                      equipmentId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'equipment'}
                      navLabel="Child equipment"
                    />
                  )
                }
              ]
            : []),

          ...(psvs?.length > 0
            ? [
                {
                  label: `PSVs (${psvs.length})`,
                  keyName: 'psv',
                  children: (
                    <EquipmentListing
                      assetDetailsOfEquipmentLists={psvs}
                      isFilterActiveHeader={isFilterActiveForPSV}
                      setFilterActiveHeader={setFilterActiveForPSV}
                      from={pathname}
                      psvs
                      equipmentId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'psv'}
                      navLabel="PSVs"
                    />
                  )
                }
              ]
            : []),

          ...(pvs?.length > 0
            ? [
                {
                  label: `Vessels (${pvs.length})`,
                  keyName: 'vessels',
                  children: (
                    <EquipmentListing
                      assetDetailsOfEquipmentLists={pvs}
                      isFilterActiveHeader={isFilterActiveForPVS}
                      setFilterActiveHeader={setFilterActiveForPVS}
                      from={pathname}
                      pvs
                      equipmentId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'psvs'}
                      navLabel="Vessels"
                    />
                  )
                }
              ]
            : [])
        ]
      : [];
  }, [
    isLoading,
    itemsMap,
    isFilterActive,
    isFilterActiveForChildEquipment,
    isFilterActiveForPSV,
    isFilterActiveForPVS,
    allDoneTasks,
    tasks
  ]);

  const tabIsActive = useMemo(() => {
    return isFilterActiveForChildEquipment || isFilterActiveForPSV || isFilterActiveForPVS || false;
  }, [isFilterActiveForChildEquipment, isFilterActiveForPSV, isFilterActiveForPVS]);

  useEffect(() => {
    const elements = document.getElementsByClassName('react-swipeable-view-container');

    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];
      if (tasks?.length > 0) {
        if (tab === 1) element.style.transform = tabIsActive ? 'none' : 'translate(-100%, 0px)';
        if (tab === 2) element.style.transform = tabIsActive ? 'none' : 'translate(-200%, 0px)';
        if (tab === 3) element.style.transform = tabIsActive ? 'none' : 'translate(-300%, 0px)';

        if (
          mainDivRef?.current &&
          swipeAbleMenuRef.current &&
          mainDivRef?.current?.scrollTop >= siteCardRef?.current?.offsetHeight
        ) {
          if (tabIsActive) swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
          // else swipeAbleMenuRef.current.className = `assetDetailNavFixed`;
        } else {
          if (parseInt(tab) === 0)
            element.style.transform = tabIsActive ? 'none' : 'translate(0px, 0px)';
          if (tab === 1) element.style.transform = tabIsActive ? 'none' : 'translate(-100%, 0px)';
          if (tab === 2) element.style.transform = tabIsActive ? 'none' : 'translate(-200%, 0px)';

          if (
            mainDivRef?.current &&
            swipeAbleMenuRef.current &&
            mainDivRef?.current?.scrollTop >= siteCardRef?.current?.offsetHeight
          ) {
            if (tabIsActive) swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
            // else swipeAbleMenuRef.current.className = `assetDetailNavFixed`;
          }
        }
      }
    }
  }, [tabIsActive, mainDivRef, swipeAbleMenuRef, siteCardRef]);

  useEffect(() => {
    if (items?.length > 0) {
      const params = new URLSearchParams(search);
      const currentTab = parseInt(tab);
      params.set('tab', currentTab);

      if (items[currentTab]?.keyName === 'tasks') {
        if (allTask) params.set('currentOpenTaskTab', 'allTask');
        else if (workOrder) params.set('currentOpenTaskTab', 'workOrder');
        else if (inspections) params.set('currentOpenTaskTab', 'inspections');
        else if (checks) params.set('currentOpenTaskTab', 'checks');
        // else if (workRequest) params.set('currentOpenTaskTab', 'workRequest');
        else if (!workOrder && !allTask && !inspections && !checks)
          params.delete('currentOpenTaskTab');
      }

      params.set(
        'virtualScrollTab',
        currentTab === 0 && items[currentTab]?.keyName === 'tasks'
          ? -1
          : tasks?.length > 0
          ? currentTab - 1
          : currentTab
      );
      params.set('tab', tab);
      items[tab]?.keyName && params.set('keyName', items[tab]?.keyName);
      const tabParams = '?' + params.toString();
      const prevRoute = `${pathname}${tabParams}`;
      navigate(prevRoute, { replace: true });
    }
  }, [tab, items, workOrder, allTask, inspections, checks]);

  useEffect(() => {
    if (!isLoading && bookmarkTab) setTab(bookmarkTab);
  }, [isLoading, bookmarkTab]);

  const pageHeaderClickEvent = () => {
    workOrder && dispatch?.(filterActions.setTaskFilters(null));
    if (moreInfo) setMoreInfo(false);
    else if (allTask) setAllTask(false);
    else if (checks) setChecks(false);
    else if (inspections) setInspections(false);
    else if (workOrder) setWorkOrder(false);
    else navigate(-1);
    dispatch?.(backHistoryActions.setEquipmentDetailsPageHistory(null));
  };

  const pageHeaderTitle = useMemo(() => {
    return `${
      bookmarkName
        ? bookmarkName
        : moreInfo
        ? 'Equipment Information'
        : allTask
        ? `All tasks (${tasks?.length > 0 ? tasks.length : ''})`
        : checks
        ? `Checks (${allChecks?.length > 0 ? allChecks?.length : ''})`
        : inspections
        ? `Inspections (${allInspections?.length > 0 ? allInspections.length : ''})`
        : workOrder
        ? `Work orders (${allWorkOrders?.length > 0 ? allWorkOrders.length : ''})`
        : searchParams.get('from') === 'parent-equipment'
        ? 'Parent equipment'
        : 'Equipment'
    }`;
  }, [
    bookmarkName,
    moreInfo,
    allTask,
    checks,
    allChecks,
    inspections,
    allInspections,
    workOrder,
    allWorkOrders,
    tasks
  ]);

  const isFilterIconIsRequired = useMemo(() => {
    return (tab !== '0' && !items[tab]?.keyName?.toLowerCase()?.includes('tasks')) ||
      allTask ||
      checks ||
      inspections ||
      workOrder
      ? items[tab]?.keyName === 'equipment'
        ? setFilterActiveForChildEquipment
        : items[tab]?.keyName === 'psv'
        ? setFilterActiveForPSV
        : items[tab]?.keyName === 'vessels'
        ? setFilterActiveForPVS
        : setFilterActive
      : false;
  }, [tab, items, allTask, checks, inspections, workOrder]);

  const filtersCount = useMemo(() => {
    return items[tab]?.keyName === 'equipment'
      ? equipmentFilterCount
      : items[tab]?.keyName === 'psv'
      ? psvsFilterCount
      : items[tab]?.keyName === 'vessels'
      ? pvsFilterCount
      : tasksFilterCount;
  }, [tab, items, equipmentFilterCount, psvsFilterCount, pvsFilterCount, tasksFilterCount]);

  const equipmentDetailsPageClassNames = useMemo(() => {
    return `${moreInfo || selectTask ? style.pageListingsWithMoreInfo : style.pageListings} ${
      style.noScroll
    } ${(workOrder || allTask || checks || inspections) && style.noScroll} 
    ${(workOrder || allTask || checks || inspections) && style.assetDetailsOnSmallScreen}`;
  }, [workOrder, allTask, checks, inspections, moreInfo, selectTask]);

  return (
    <PageContainer moreInfo={moreInfo}>
      <PageHeader
        disableBack={
          moreInfo || allTask || checks || inspections || workOrder || searchParams.get('from')
        }
        title={pageHeaderTitle}
        {...(isFilterIconIsRequired && {
          setFilterActive: isFilterIconIsRequired
        })}
        onClick={pageHeaderClickEvent}
        count={filtersCount}
        ref={assetDetailPageHeaderRef}
      />
      {isLoading ? (
        <LoadingRound className={style.loading} />
      ) : (
        <>
          <div
            ref={mainDivRef}
            id="assetDetailPage"
            className={equipmentDetailsPageClassNames}
            style={{ position: 'relative', paddingBottom: '0px', marginTop: '0px' }}
          >
            <div className="marginClass" ref={siteCardRef}>
              <EquipmentCard
                equipmentDetails
                title={`${category} - ${type}`}
                subtitle={name}
                hideLocation={true}
                dataPoints={dataPoints}
                allTasksForSite={tasks}
                allDoneTasks={allDoneTasks}
                isOverDue={isOverDue}
                status={status}
                sites={{
                  ...(cacheSites[sitesMap[siteId]] || {}),
                  link: `/#/site-details/${siteId}`
                }}
                showChevronIcon={false}
                open={open}
                setOpen={setOpen}
                moreInfo={moreInfo}
                arrayText={arrayText}
                link={''}
              />
            </div>
            {moreInfo ? (
              <EquipmentDetailsMoreInfo
                moreInfoDataPointsMap={moreInfoDataPointsMap}
                setEditEquipment={setEditEquipment}
              />
            ) : (
              <div className="flex relative">
                <div
                  style={{
                    marginTop: '0px',
                    overflow: 'hidden',
                    paddingBottom: '0px'
                  }}
                  className={`${
                    moreInfo ? style.pageListingsWithMoreInfo : style.pageListings
                  } mobileMargin`}
                >
                  {allTask || inspections || workOrder || checks ? (
                    <WorkOrder
                      asset="equipment"
                      setSyncComplete={setSyncComplete}
                      tasks={
                        inspections
                          ? allInspections
                          : allTask
                          ? tasks
                          : workOrder
                          ? allWorkOrders
                          : checks
                          ? allChecks
                          : []
                      }
                      isFilterActiveHeader={isFilterActive}
                      setFilterActiveHeader={setFilterActive}
                      selectTodoOrInProgressStatus={!_.isEmpty(bookmarkName) ? true : false}
                      onParentClickEvent={onParentClickEvent}
                      workOrderSelectedTab={equipmentDetailsInfo?.workOrderTab || null}
                      workOrderSortTab={equipmentDetailsInfo?.workOrderSortTab || null}
                      siteDetails
                      filterType={
                        allTask
                          ? 'all'
                          : inspections
                          ? 'inspection'
                          : workOrder
                          ? 'workOrder'
                          : checks
                          ? 'check'
                          : null
                      }
                    />
                  ) : items?.length > 0 ? (
                    <div
                      className={`assetDetailNavNotFixed`}
                      ref={swipeAbleMenuRef}
                      id="assetDetailsNavigation"
                    >
                      <NewTabs
                        active={tab}
                        tabs={items}
                        setActive={(tab) => {
                          setTab(tab);
                        }}
                        className="mobileMargin"
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className="no-result"
                        style={{ flex: 1, justifyContent: 'flex-start', gap: '20px' }}
                      >
                        <div>No results</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {openModal && (
            <EquipmentBookmark
              open={openModal}
              setOpen={(value) => {
                setOpenModal(value);
              }}
              placeholder="My favourite truck"
              type="Equipment Details"
              equipmentId={id}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default React.memo(EquipmentSiteDetails);
