/* eslint-disable no-unused-vars */
import React from 'react';

import Modal from '@components/modal';
import Icon from '@components/icon-component';

import style from './style.module.scss';

const AttachPSVModal = ({
  open,
  setOpen,
  attached = false,
  deAttached = false,
  unlinkHandleEvent,
  deAttachedHandleEvent
}) => {
  const syncModalIcon = React.useMemo(() => {
    if (attached) return <Icon name={'attached-psv-modal'} />;
    if (deAttached) return <Icon name={'de-attached-psv-modal'} />;
  }, [attached, deAttached]);

  const statusText = React.useMemo(() => {
    if (attached) return <>Reattach PSV?</>;
    if (deAttached) return <>Detach PSV?</>;
  }, [attached, deAttached]);

  const modalTextUpper = React.useMemo(() => {
    if (attached) return <>Are you sure you want to reattach this PSV?</>;
    if (deAttached) return <>Are you sure you want to detach this PSV?</>;
  }, [attached, deAttached]);

  const modalBodyInner = React.useMemo(() => {
    if (attached)
      return (
        <div>
          <div className={style.modalBtn}>
            <button className={`btn-fill button-large`} onClick={unlinkHandleEvent}>
              Reattach
            </button>
          </div>
        </div>
      );
    else if (deAttached)
      return (
        <div>
          <div className={style.modalBtn}>
            <button className={`btn-fill button-large`} onClick={deAttachedHandleEvent}>
              Detach
            </button>
          </div>
        </div>
      );
  }, [deAttached, attached, unlinkHandleEvent, deAttachedHandleEvent]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={syncModalIcon}
      statusText={statusText}
      modalTextUpper={modalTextUpper}
      textBtn={'NeverMind'}
    >
      {modalBodyInner}
    </Modal>
  );
};

export default AttachPSVModal;
