/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import DeleteModal from '@components/modal/variants/delete-modal';
import AuthService from '@services/auth.service';
import { decodeToken } from '@utilities/authentication';
import LocalStorage from '@utilities/local-storage.util';

import style from './style.module.scss';

const ResetAppData = () => {
  const [resetModalOpen, setResetModalOpen] = useState(false);
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  const resetAppEvent = async () => {
    const userId = decodeToken().userId;
    const getAllUserPreviousTaskAssets = (await LocalStorage.getItem(userId)) || [];

    const removeItemsFromList = [
      'applicationVersion',
      `userPendingSyncAndInProgressTasks-${userId}`,
      'cachingFailure',
      'iosPwaPrompt',
      'token',
      ...getAllUserPreviousTaskAssets,
      userId
    ];
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    removeItemsFromList?.forEach((key) => {
      localStorage.removeItem(key);
    });

    for (let key of removeItemsFromList) await LocalStorage.removeItem(key);

    AuthService.logout({ clearCache: true });
    window.location.reload(true);
  };

  return (
    <>
      <div className={style.bgColor}>
        <div className={style.seeCard}>
          <h6 className={style.titleClass}>When all else fails, clear your cache.</h6>
          <p className={style.para}>
            Sometimes the easiest way to solve issues with Oplii Mobile is to start fresh.
          </p>
          <h6 className={style.heading}>Before you do: </h6>
          <ul>
            <li>
              Ensure you don’t have tasks or records ‘In progress’ or ‘Pending sync’ unless you’re
              OK with losing it.
            </li>
            <li>
              Ensure you have a solid internet connection so you can redownload everything after you
              reset.
            </li>
            <li>Be prepared to log in again.</li>
          </ul>

          <h6 className={style.redClass}>
            You will lose any work that isn’t synced as well as your search history. All tasks and
            data will be redownloaded.
          </h6>

          <div className={style.modalBtn}>
            <button
              className={`btn-fill button-large ${style.deleteBtnColor}`}
              onClick={() => {
                setResetModalOpen(true);
              }}
            >
              Reset app data
            </button>
          </div>
        </div>
      </div>

      {resetModalOpen && (
        <DeleteModal
          open={resetModalOpen}
          setOpen={setResetModalOpen}
          resetApp
          resetAppEvent={resetAppEvent}
        />
      )}
    </>
  );
};

export default ResetAppData;
