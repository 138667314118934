/* eslint-disable no-unused-vars */

import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useParams, useSearchParams } from 'react-router-dom';

import BookMarkModal from '@components/modal/variants/bookmark-modal';
import InputField from '@components/input-field/input-field.component';
import Checkbox from '@components/checkbox/checkbox.component';

import BookmarkService from '@services/bookmarks.service';
import { useToastContext } from 'context/toaster.context';

import style from './style.module.scss';

const TaskBookmark = ({
  open,
  setOpen,
  placeholder = 'Flux capacitor repairs',
  filters = {},
  selectedSort = {},
  type = 'Tasks',
  filterType,
  asset = null,
  inspectionRunTags = [],
  taskRecurringTemplateTags = []
}) => {
  const [bookmarkName, setBookmarkName] = useState('');
  const [pinToGlance, setPinToGlance] = useState(false);
  const userId = useSelector((state) => state.auth.userId) || '';

  const { ToasterUtil } = useToastContext();

  const location = useLocation();
  const [searchParams] = useSearchParams();

  const from = searchParams.get('from');
  const keyNameFilter = searchParams.get('keyName') || '';
  const tabFilter = searchParams.get('tab') || '';
  const currentOpenTaskTab = searchParams.get('currentOpenTaskTab') || '';
  const runs = searchParams.get('runs') || '';
  const templates = searchParams.get('templates') || '';

  const { id } = useParams();

  const onSubmit = async () => {
    const changeFilters =
      filterType === 'all'
        ? filters
        : {
            ...filters,
            taskType: {
              value:
                filterType === 'all'
                  ? filters?.taskType?.value?.length > 0
                    ? filters?.taskType?.value
                    : ['Inspections', 'Work orders', 'Work requests', 'HSE events', 'Checks']
                  : filterType === 'inspection' || filterType === 'Inspection'
                  ? ['Inspections']
                  : filterType === 'workOrder' || filterType === 'Work Order'
                  ? ['Work orders']
                  : filterType === 'workRequest'
                  ? ['Work requests']
                  : filterType === 'hseEvents'
                  ? ['HSE event']
                  : filterType === 'Check' || filterType === 'check'
                  ? ['Checks']
                  : filters?.taskType?.value || []
            },
            inspectionRun: {
              value:
                !_.isEmpty(runs) && inspectionRunTags?.length > 0
                  ? inspectionRunTags
                  : filters?.inspectionRun?.value || []
            },
            recurringTemplate: {
              value:
                !_.isEmpty(templates) && taskRecurringTemplateTags?.length > 0
                  ? taskRecurringTemplateTags
                  : filters?.recurringTemplate?.value || []
            }
          };

    const filtersString = {
      filters: changeFilters,
      sort: selectedSort,
      [`${from}Id`]: id
    };

    const taskBookmarkData = {
      userId,
      name: bookmarkName,
      type,
      atAGlance: pinToGlance,
      filters: JSON.stringify(filtersString),
      listing: `${location?.pathname}${location.search}` || '/',
      [`${from}Id`]: id,
      keyName: keyNameFilter,
      tab: tabFilter,
      currentOpenTaskTab
    };
    ToasterUtil.showSuccess(`Bookmark saved`);

    await BookmarkService.addBookmarks(taskBookmarkData);

    resetValue();
  };

  const resetValue = () => {
    setOpen(false);
    setBookmarkName('');
    setPinToGlance(false);
  };

  return (
    <BookMarkModal
      open={open}
      setOpen={() => {
        resetValue();
      }}
      onSubmit={onSubmit}
      statusText={'Name that bookmark.'}
      modalTextUpper={'Give your bookmark a short, descriptive name.'}
      disabledBtn={_.isEmpty(bookmarkName)}
      modalTextLower={() => (
        <>
          <InputField
            placeholder={placeholder}
            limit={40}
            onChange={(e) => {
              const value = e?.target?.value;
              if (value.length === 0 || (value.length >= 1 && value[0] !== ' ')) {
                setBookmarkName(value);
              }
            }}
            value={bookmarkName}
            defaultValue={bookmarkName}
          />
          <p className="label-bold text-right text-grey-400 mt-1">{bookmarkName?.length || 0}/40</p>
        </>
      )}
      progressData={() => (
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <Checkbox
            text={'Pin to ‘At a glance’ on Home'}
            checked={pinToGlance}
            handleChange={(e) => setPinToGlance(e?.target?.checked)}
            textTransformNone
            labelClassName={style.taskBookmarkLabel}
            checkMarkAfter={style.taskBookmarkCheckbox}
          />
        </div>
      )}
      textBtn={'NEVERMIND'}
    />
  );
};

export default TaskBookmark;
