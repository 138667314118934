/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect, memo, useRef } from 'react';
import _ from 'lodash';
import { Navigate, useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

import PageContainer from '@components/page-container';
import PageHeader from '@components/page-header';
import SiteDetailsMoreInfo from './site-details-more-info';
import SiteBookmark from '@components/site-listing/site-bookmark';
import EquipmentListing from '@components/equipment-listing';
import StructureListing from '@components/structure-listing';
import PipelineListing from '@components/pipeline-listing';
import LoadingRound from '@components/loading-round/loading-round';
import NewTabs from '@components/new-tabs';
import SiteCard from '@components/site-card';
import WorkOrder from './tasks/work-order';
import Tasks from '@components/site-parent-site/tasks';
import { moreInfoDropdownList, tabsItemMap } from '@components/site-parent-site/helper';

import useWindowDimensions from '@hooks/useWindowDimensions';
import { log } from '@utilities/logger.util';
import { getAllSiteDetailsData } from './helper';
import { backHistoryActions } from '@redux/slices/back-history';
import { getAppDispatch } from '@utilities/dispatch.util';
import { filterActions } from '@redux/slices/filters';
import { initWebWorker } from 'webworker';

import style from './style.module.scss';
import './assets-details.scss';

const AssetsDetails = () => {
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = getAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const bookmarkName = searchParams.get('bookmarkName');
  const bookmarkTab = searchParams.get('bookmarkTab');
  const currentOpenTaskTab = searchParams.get('currentOpenTaskTab') || '';
  const templateString = searchParams.get('siteTemplates') || '';

  const swipeAbleMenuRef = useRef(null);
  const mainDivRef = useRef(null);
  const siteCardRef = useRef(null);
  const assetDetailPageHeaderRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState('0');
  const [moreInfo, setMoreInfo] = useState(false);
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);

  const [isFilterActiveForEquipments, setFilterActiveForEquipments] = useState(
    width < 768 ? false : true
  );
  const [isFilterActiveForPipeline, setFilterActiveForPipeline] = useState(
    width < 768 ? false : true
  );
  const [isFilterActiveForStructure, setFilterActiveForStructure] = useState(
    width < 768 ? false : true
  );
  const [allTask, setAllTask] = useState(false);
  const [checks, setChecks] = useState(false);
  const [inspections, setInspections] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [workRequest, setWorkRequest] = useState(false);
  const [hseEvent, setHseEvent] = useState(false);
  const [syncComplete, setSyncComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [assetDetailsData, setAssetDetailsData] = useState({});

  useEffect(() => {
    if (currentOpenTaskTab) {
      if (currentOpenTaskTab === 'allTask') setAllTask(true);
      else if (currentOpenTaskTab === 'workOrder') setWorkOrder(true);
      else if (currentOpenTaskTab === 'inspections') setInspections(true);
      else if (currentOpenTaskTab === 'workRequest') setWorkRequest(true);
      else if (currentOpenTaskTab === 'hseEvent') setHseEvent(true);
      else if (currentOpenTaskTab === 'checks') setChecks(true);
    }
  }, [currentOpenTaskTab]);

  const siteDetailsPage = useSelector((state) => state.backHistory.siteDetailsPage);
  const {
    tasksFilterCount = 0,
    structureFilterCount = 0,
    equipmentFilterCount = 0,
    pipelineFilterCount = 0
  } = useSelector((state) => state.filters);
  const { tasksList: reduxTasksList = [], selectTask } = useSelector((state) => state.tasks);
  const { inspectionsList } = useSelector((state) => state.inspection);
  const { HSEEventList } = useSelector((state) => state.hseEvents);
  const { checksList } = useSelector((state) => state.checks);

  useEffect(() => {
    if (!_.isEmpty(siteDetailsPage)) {
      siteDetailsPage?.siteDetailsTab && setTab(siteDetailsPage?.siteDetailsTab || '0');
      siteDetailsPage?.workOrder && setWorkOrder(siteDetailsPage?.workOrder);
      siteDetailsPage?.workRequest && setWorkRequest(siteDetailsPage?.workRequest);
    }
  }, [siteDetailsPage]);

  const templatesIds = useMemo(
    () => templateString.split(',').filter((el) => el),
    [templateString]
  );

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() },
        siteId: id,
        templatesIds
      },
      type: 'get-site-details'
    })
      .then((data) => {
        setAssetDetailsData(data?.result);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id, templatesIds, reduxTasksList, inspectionsList, HSEEventList, checksList]);

  useEffect(() => {
    if (syncComplete) setSyncComplete(false);
  }, [syncComplete]);

  const {
    sitesLoading,
    name,
    tasks = [],
    allDoneTasks,
    allWorkOrders,
    allDoneWorkOrders,
    allInspections,
    allChecks,
    allDoneChecks,
    allDoneInspections,
    isInspectionOverDue,
    isWorkOrderOverDue,
    isCheckOverDue,
    isOverDue,
    status,
    area,
    equipments = [],
    dataPoints,
    unit,
    distance,
    children,
    parentSite,
    MoreInfoDataPointsMap,
    equipmentAllFieldData,
    structures,
    structureAllFieldData,
    pipelines = [],
    equipmentCount,
    link,
    newWorkRequestList = [],
    hseEvents = []
  } = assetDetailsData;

  useMemo(() => {
    if (!isLoading) {
      if (!sitesLoading && !parentSite?.siteId) return <Navigate to={'/sites'} />;
      !dataPoints && log(unit, distance, children);
    }
  }, [assetDetailsData, isLoading]);

  const onParentClickEvent = ({ workOrderTab, workOrderSortTab }) => {
    dispatch?.(
      backHistoryActions.setSiteDetailsPageHistory({
        workOrder,
        workRequest,
        siteDetailsTab: tab,
        workOrderTab,
        workOrderSortTab
      })
    );
  };

  const arrayText = useMemo(() => {
    return moreInfoDropdownList({ setMoreInfo, setOpen, setOpenModal, link });
  }, [link]);

  const itemsMap = useMemo(() => {
    return tabsItemMap({
      isLoading,
      tasks,
      children: [],
      equipments,
      structures,
      pipelines
    });
  }, [isLoading]);

  const items = useMemo(() => {
    return !isLoading
      ? [
          ...(tasks?.length > 0 || hseEvents?.length > 0
            ? [
                {
                  label: `Tasks + records (${tasks.length + hseEvents?.length})`,
                  keyName: 'tasks',
                  children: (
                    <Tasks
                      setChecks={setChecks}
                      setAllTask={setAllTask}
                      setWorkOrder={setWorkOrder}
                      setInspections={setInspections}
                      setWorkRequest={setWorkRequest}
                      setHseEvent={setHseEvent}
                      allWorkOrderForSite={allWorkOrders}
                      allDoneWorkOrder={allDoneWorkOrders}
                      allInspectionForSite={allInspections}
                      allDoneInspection={allDoneInspections}
                      allCheckForSite={allChecks}
                      allDoneChecks={allDoneChecks}
                      allTasksForSite={tasks}
                      allDoneTasks={allDoneTasks}
                      isOverDue={isOverDue}
                      isInspectionOverDue={isInspectionOverDue}
                      isCheckOverDue={isCheckOverDue}
                      isWorkOrderOverDue={isWorkOrderOverDue}
                      newWorkRequestList={newWorkRequestList}
                      hseEvents={hseEvents}
                    />
                  )
                }
              ]
            : []),

          ...(equipments?.length > 0
            ? [
                {
                  label: `Equipment (${equipments.length})`,
                  keyName: 'equipment',
                  children: (
                    <EquipmentListing
                      assetDetailsOfEquipmentLists={equipmentAllFieldData}
                      isFilterActiveHeader={isFilterActiveForEquipments}
                      setFilterActiveHeader={setFilterActiveForEquipments}
                      from={location.pathname}
                      siteId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'equipment'}
                      noSite
                    />
                  )
                }
              ]
            : []),

          ...(structures?.length > 0
            ? [
                {
                  label: `Structure (${structures?.length})`,
                  keyName: 'structure',

                  children: (
                    <StructureListing
                      assetDetailsStructureLists={structureAllFieldData}
                      isFilterActiveHeader={isFilterActiveForStructure}
                      setFilterActiveHeader={setFilterActiveForStructure}
                      from={location.pathname}
                      siteId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'structure'}
                    />
                  )
                }
              ]
            : []),

          ...(pipelines?.length > 0
            ? [
                {
                  label: `Pipeline (${pipelines?.length})`,
                  keyName: 'pipeline',
                  children: (
                    <PipelineListing
                      assetDetailsPipelineLists={pipelines}
                      isFilterActiveHeader={isFilterActiveForPipeline}
                      setFilterActiveHeader={setFilterActiveForPipeline}
                      from={location.pathname}
                      siteId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'pipeline'}
                    />
                  )
                }
              ]
            : [])
        ]
      : [];
  }, [
    isLoading,
    itemsMap,
    isFilterActive,
    isFilterActiveForEquipments,
    isFilterActiveForPipeline,
    isFilterActiveForStructure,
    allDoneTasks,
    tasks,
    allInspections,
    hseEvents,
    structureAllFieldData
  ]);

  useEffect(() => {
    if (items?.length > 0) {
      const params = new URLSearchParams(location.search);
      const currentTab = parseInt(tab);
      params.set('tab', currentTab);
      if (items[currentTab]?.keyName === 'tasks') {
        if (allTask) params.set('currentOpenTaskTab', 'allTask');
        else if (workOrder) params.set('currentOpenTaskTab', 'workOrder');
        else if (inspections) params.set('currentOpenTaskTab', 'inspections');
        else if (workRequest) params.set('currentOpenTaskTab', 'workRequest');
        else if (hseEvent) params.set('currentOpenTaskTab', 'hseEvent');
        else if (checks) params.set('currentOpenTaskTab', 'checks');
        else if (!workOrder && !allTask && !inspections && !workRequest && !hseEvent && !checks)
          params.delete('currentOpenTaskTab');
      }
      params.set(
        'virtualScrollTab',
        currentTab === 0 && items[currentTab]?.keyName === 'tasks'
          ? -1
          : tasks?.length > 0
          ? currentTab - 1
          : currentTab
      );
      params.set('keyName', items[currentTab]?.keyName);
      const tabParams = '?' + params.toString();
      const prevRoute = `${location.pathname}${tabParams}`;
      navigate(prevRoute, { replace: true });
    }
  }, [tab, items, workOrder, allTask, inspections, workRequest, hseEvent, checks]);

  useEffect(() => {
    if (!isLoading && bookmarkTab) setTab(bookmarkTab);
  }, [isLoading, bookmarkTab]);

  const pageHeaderClickEvent = () => {
    (workOrder || workRequest) && dispatch?.(filterActions.setTaskFilters(null));
    if (moreInfo) setMoreInfo(false);
    else if (allTask) setAllTask(false);
    else if (inspections) setInspections(false);
    else if (workOrder) setWorkOrder(false);
    else if (workRequest) setWorkRequest(false);
    else if (hseEvent) setHseEvent(false);
    else if (checks) setChecks(false);

    dispatch?.(backHistoryActions.setSiteDetailsPageHistory(null));
  };

  const pageHeaderTitle = useMemo(() => {
    return `${
      bookmarkName
        ? bookmarkName
        : moreInfo
        ? 'Site Information'
        : allTask
        ? `All tasks (${tasks?.length > 0 ? tasks?.length : ''})`
        : checks
        ? `Checks (${allChecks?.length > 0 ? allChecks?.length : ''})`
        : inspections
        ? `Inspections (${allInspections?.length > 0 ? allInspections?.length : ''})`
        : workOrder
        ? `Work orders (${allWorkOrders?.length > 0 ? allWorkOrders?.length : ''})`
        : workRequest
        ? `Work requests (${newWorkRequestList?.length > 0 ? newWorkRequestList?.length : ''})`
        : hseEvent
        ? `HSE events (${hseEvents?.length > 0 ? hseEvents?.length : ''})`
        : 'Site'
    }`;
  }, [
    bookmarkName,
    moreInfo,
    allTask,
    checks,
    inspections,
    workOrder,
    tasks,
    workRequest,
    hseEvent,
    hseEvents
  ]);

  const isFilterIconIsRequired = useMemo(() => {
    return !items[tab]?.keyName?.toLowerCase()?.includes('tasks') ||
      allTask ||
      checks ||
      inspections ||
      workOrder ||
      workRequest ||
      hseEvent
      ? items[tab]?.keyName === 'equipment'
        ? setFilterActiveForEquipments
        : items[tab]?.keyName === 'structure'
        ? setFilterActiveForStructure
        : items[tab]?.keyName === 'pipeline'
        ? setFilterActiveForPipeline
        : setFilterActive
      : false;
  }, [tab, items, allTask, checks, inspections, workOrder, workRequest, hseEvent]);

  const siteDetailsPageClassNames = useMemo(() => {
    return `${moreInfo || selectTask ? style.pageListingsWithMoreInfo : style.pageListings} ${
      style.noScroll
    } ${
      (workOrder || allTask || checks || inspections || workRequest || hseEvent) && style.noScroll
    }`;
  }, [workOrder, allTask, checks, inspections, workRequest, selectTask, hseEvent]);

  const filtersCount = useMemo(() => {
    return items[tab]?.keyName === 'equipment'
      ? equipmentFilterCount
      : items[tab]?.keyName === 'structure'
      ? structureFilterCount
      : items[tab]?.keyName === 'pipeline'
      ? pipelineFilterCount
      : tasksFilterCount;
  }, [
    tab,
    items,
    equipmentFilterCount,
    structureFilterCount,
    pipelineFilterCount,
    tasksFilterCount
  ]);

  const tabIsActive = useMemo(() => {
    return (
      isFilterActiveForEquipments ||
      isFilterActiveForStructure ||
      isFilterActiveForPipeline ||
      false
    );
  }, [isFilterActiveForEquipments, isFilterActiveForStructure, isFilterActiveForPipeline]);

  useEffect(() => {
    const elements = document.getElementsByClassName('react-swipeable-view-container');

    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];
      if (tasks?.length > 0) {
        if (tab === 1) element.style.transform = tabIsActive ? 'none' : 'translate(-100%, 0px)';
        if (tab === 2) element.style.transform = tabIsActive ? 'none' : 'translate(-200%, 0px)';
        if (tab === 3) element.style.transform = tabIsActive ? 'none' : 'translate(-300%, 0px)';
        if (
          mainDivRef?.current &&
          swipeAbleMenuRef.current &&
          mainDivRef?.current?.scrollTop >= siteCardRef?.current?.offsetHeight
        ) {
          if (tabIsActive) swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
          else swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
        }
      } else {
        if (parseInt(tab) === 0)
          element.style.transform = tabIsActive ? 'none' : 'translate(0px, 0px)';
        if (tab === 1) element.style.transform = tabIsActive ? 'none' : 'translate(-100%, 0px)';
        if (tab === 2) element.style.transform = tabIsActive ? 'none' : 'translate(-200%, 0px)';

        if (
          mainDivRef?.current &&
          swipeAbleMenuRef.current &&
          mainDivRef?.current?.scrollTop >= siteCardRef?.current?.offsetHeight
        ) {
          if (tabIsActive) swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
          else swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
        }
      }
    }
  }, [tabIsActive, mainDivRef, swipeAbleMenuRef]);

  return (
    <PageContainer moreInfo={moreInfo}>
      <PageHeader
        title={pageHeaderTitle}
        {...(isFilterIconIsRequired && {
          setFilterActive: isFilterIconIsRequired
        })}
        disableBack={
          moreInfo || allTask || checks || inspections || workOrder || workRequest || hseEvent
        }
        onClick={pageHeaderClickEvent}
        count={filtersCount}
        ref={assetDetailPageHeaderRef}
      />

      {isLoading ? (
        <LoadingRound className={style.loading} />
      ) : (
        <div>
          <div
            ref={mainDivRef}
            id="assetDetailPage"
            className={siteDetailsPageClassNames}
            style={{
              position: 'relative',
              paddingBottom: 0
            }}
          >
            <div className="marginClass" ref={siteCardRef}>
              <SiteCard
                siteDetailsCard
                chips={false}
                title={area}
                subtitle={name}
                hideLocation={true}
                dataPoints={dataPoints || []}
                allTasksForSite={tasks}
                allDoneTasks={allDoneTasks}
                isOverDue={isOverDue}
                status={status}
                arrayText={arrayText}
                open={open}
                setOpen={setOpen}
                moreInfo={moreInfo}
                equipmentCount={equipmentCount}
                recordIndicatorLength={hseEvents?.length + newWorkRequestList?.length}
              />
            </div>
            {moreInfo ? (
              <SiteDetailsMoreInfo moreInfoDataPointsMap={MoreInfoDataPointsMap} />
            ) : (
              <div className="flex relative">
                <div
                  style={{
                    overflow: 'hidden',
                    marginTop: '0px',
                    width: '100%'
                  }}
                  className={`${
                    moreInfo ? style.pageListingsWithMoreInfo : style.pageListings
                  } mobileMargin`}
                >
                  {allTask || inspections || workOrder || workRequest || hseEvent || checks ? (
                    <WorkOrder
                      tasks={
                        inspections
                          ? allInspections
                          : allTask
                          ? tasks
                          : workOrder
                          ? allWorkOrders
                          : workRequest
                          ? newWorkRequestList
                          : hseEvent
                          ? hseEvents
                          : checks
                          ? allChecks
                          : []
                      }
                      workRequest={workRequest}
                      hseEvent={hseEvent}
                      isFilterActiveHeader={isFilterActive}
                      setFilterActiveHeader={setFilterActive}
                      setSyncComplete={setSyncComplete}
                      selectTodoOrInProgressStatus={!_.isEmpty(bookmarkName) ? true : false}
                      onParentClickEvent={onParentClickEvent}
                      workOrderSelectedTab={siteDetailsPage?.workOrderTab || null}
                      workOrderSortTab={siteDetailsPage?.workOrderSortTab || null}
                      siteDetails
                      filterType={
                        allTask
                          ? 'all'
                          : inspections
                          ? 'inspection'
                          : workOrder
                          ? 'workOrder'
                          : workRequest
                          ? 'workRequest'
                          : hseEvent
                          ? 'hseEvents'
                          : checks
                          ? 'check'
                          : null
                      }
                      asset="sites"
                    />
                  ) : items?.length ? (
                    <div
                      className={`assetDetailNavNotFixed`}
                      ref={swipeAbleMenuRef}
                      id="assetDetailsNavigation"
                    >
                      <NewTabs
                        active={tab}
                        tabs={items}
                        setActive={(tab) => setTab(tab)}
                        className="mobileMargin"
                      />
                    </div>
                  ) : (
                    <div
                      className="no-result"
                      style={{ flex: 1, justifyContent: 'flex-start', gap: '20px' }}
                    >
                      <div>No results</div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {openModal && (
              <SiteBookmark
                open={openModal}
                setOpen={(value) => {
                  setOpenModal(value);
                }}
                placeholder="My favorite truck"
                type="Site Details"
                siteId={id || null}
              />
            )}
          </div>
        </div>
      )}
    </PageContainer>
  );
};

export default memo(AssetsDetails);
