export const distanceOptions = [
  { label: 'kilometers', value: 'kilometers' },
  { label: 'miles', value: 'miles' }
];
export const typeOptions = [
  { label: 'hour(s)', value: 'hours' },
  { label: 'day(s)', value: 'days' }
];
export const whenOptions = [
  { label: 'Never', value: 'never' },
  { label: 'Only when connected to Wi-Fi', value: 'wifi' },
  { label: 'When connected to a mobile network and/or Wi-Fi', value: 'any' }
];

export const SETTINGS = ['Data caching', 'Attachments', 'Sync reminders', 'Display settings'];
export const DISPLAY_SETTINGS = [
  'General',
  'Sites',
  'Structures',
  'Equipment',
  'Pipeline',
  'Inspections',
  'Work Orders',
  'Checks'
];

export const getDataPoints = (
  props = {},
  fields = '',
  name,
  picklistsMap = {},
  IsSortable = false
) => {
  // Create Map for all draggable fields
  const addFieldsMap = displayFields[name]
    .filter((el) => IsSortable || el.IsSortable) // filters only draggable
    .reduce((acc, curr) => {
      // prepare map
      acc[curr.Id] = curr;
      return acc;
    }, {});

  const dataPoints = fields // comma separated fields saved in string
    .split(',')
    .map((field) => {
      const {
        Id = '',
        Key = '',
        Value = '',
        Title = '',
        MeasurementKey = ''
      } = addFieldsMap[field] || {};

      const key = props[Id.toLowerCase()]
        ? Id.toLowerCase()
        : Id === 'OperationsArea'
        ? 'operations_Area'
        : Key || toLowerCaseFirst(Id);

      let value = picklistsMap[props[key]]?.value || props[key] || '';
      if (value === true || value === false) value = value ? 'Yes' : 'No';

      const measurementId = props[`${MeasurementKey || key}Measurement`];

      const measurement = picklistsMap[measurementId]?.value;

      if (measurement) value += ' ' + measurement;

      return { Id, key, value, title: Title || toUpperCaseFirst(Value) };
    })
    .filter(({ Id, value }) => {
      return addFieldsMap[Id] && value;
    });

  return dataPoints;
};

export const getMoreInfoDataPoints = (props = {}, name, picklistsMap = {}, IsSortable = false) => {
  // Create Map for all draggable fields
  const list =
    name === 'pipeline'
      ? [
          ...displayFields[name],
          {
            Id: 'InternalProtection',
            Value: 'Internal Protection',
            IsSortable: true,
            moreInfo: true
          }
        ]
      : displayFields[name];

  const addFieldsMap = list
    ?.filter((el) => (name === 'pipeline' ? el?.moreInfo : IsSortable || el.IsSortable)) // filters only draggable
    .reduce((acc, curr) => {
      // prepare map
      if (
        name !== 'pipeline'
          ? curr.Id !== 'Area' && curr.Id !== 'Name' && curr.id !== 'Category'
          : curr.Id !== 'Name' && curr.id !== 'Category'
      ) {
        acc[curr.Id] = curr;
      }
      return acc;
    }, {});
  const keysString = Object.keys(addFieldsMap).join(',');

  const dataPoints = keysString // comma separated fields saved in string
    .split(',')
    .map((field) => {
      const {
        Id = '',
        Key = '',
        Value = '',
        Title = '',
        MeasurementKey = ''
      } = addFieldsMap[field] || {};
      const key = props[Id.toLowerCase()]
        ? Id.toLowerCase()
        : Id === 'OperationsArea'
        ? 'operations_Area'
        : Key || toLowerCaseFirst(Id);
      let value =
        name === 'pipeline' && key === 'licenseLineNum'
          ? { licenseNum: props['licenseNumber'], lineNum: props['lineNumber'] }
          : picklistsMap[props[key]]?.value || props[key] || '';
      if (value === true || value === false) value = value ? 'Yes' : 'No';

      const measurementId = props[`${MeasurementKey || key}Measurement`];
      const measurement = picklistsMap[measurementId]?.value;

      if (measurement) value += ' ' + measurement;

      return { Id, key, value, title: Title || toUpperCaseFirst(Value) };
    })
    .filter(({ Id, value }) => {
      return addFieldsMap[Id] && value;
    });
  return dataPoints;
};

export const toLowerCaseFirst = (str = '') => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const toUpperCaseFirst = (str) => {
  return str
    .split(' ')
    .map((word, i) => (i === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase()))
    .join(' ');
};
export const toUpperCase = (str) => {
  return str
    .split(' ')
    .map((word, i) => (i === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word.toLowerCase()))
    .join(' ');
};

export const toCamelCase = (str) => {
  return str
    .replace('(', '')
    .replace(')', '')
    .replace('#', 'Number')
    .split(' ')
    .map((word, i) =>
      i == 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
    .join('');
};

export const displayFields = {
  siteInfo:
    'SiteNumber,Name,Type,Area,Status,SurfaceLocation,UWI,Province,LicenseNumber,Wellbore,CostCentreNumber,OperationsArea',
  equipmentInfo:
    'EquipmentNumber,Name,Status,Category,Type,Subtype,Description,Manufacturer,Model,PhysicalLocation,SerialNumber,JurisdictionNumber,FieldTagNumber,YearBuilt',
  structureInfo: 'Type,Name,Status,StructureNumberAuto,StructureNumber,',
  pipelineInfo:
    'LicenseLineNum,Status,FromLocation,ToLocation,Area,Material,Length,Substance,InternalProtection,PipelineNumber,Type,ApprovalNumber',
  site: [
    { Id: 'Area', Value: 'Area' },
    { Id: 'Name', Value: 'Name' },
    { Id: 'CostCentreNumber', Value: 'Cost Centre #', IsSortable: true },
    { Id: 'LicenseNumber', Key: 'lisenseNumber', Value: 'License #', IsSortable: true },
    { Id: 'Province', Value: 'Province/State', IsSortable: true },
    { Id: 'SiteNumber', Value: 'Site #', IsSortable: true },
    { Id: 'SurfaceLocation', Value: 'Surface Location', Title: 'LSD', IsSortable: true },
    { Id: 'Type', Value: 'Type', IsSortable: true },
    { Id: 'UWI', Value: 'UWI', IsSortable: true },
    { Id: 'Wellbore', Value: 'Wellbore', IsSortable: true },
    { Id: 'OperationsArea', Value: 'Operations Area', IsSortable: true },
    {
      Id: '_Badges',
      Value: '_Badges',
      AlignBottom: true,
      Badges: [
        { Text: 'Tasks', Id: 'tasks', Color: '#FCCECE' },
        { Text: 'Status', Id: 'status' },
        { Text: 'Children', Id: 'children' },
        { Text: 'Check in', Id: 'checkin' }
      ]
    }
  ],
  structure: [
    { Id: 'Type', Value: 'Type' }, // structureType
    { Id: 'Name', Value: 'Name' }, //name
    { Id: 'StructureNumberAuto', Value: 'Structure #', IsSortable: true }, // structureNumberAuto
    { Id: 'StructureNumber', Value: 'Structure ID', Title: 'Structure ID', IsSortable: true }, // structureNumber
    {
      Id: '_Badges',
      Value: '_Badges',
      AlignBottom: true,
      Badges: [
        { Text: 'Tasks', Id: 'tasks', Color: '#FCCECE' },
        { Text: 'Status', Id: 'status' },
        { Text: 'EQ', Id: 'eq' }
      ]
    }
  ],
  equipment: [
    { Id: 'Category', Value: 'Category - Type' },
    { Id: 'Name', Value: 'Name' },
    { Id: 'Condition', Value: 'Condition', IsSortable: true },
    { Id: 'CRN', Key: 'crnNumber', Value: 'CRN', IsSortable: true },
    { Id: 'Description', Value: 'Description', IsSortable: true },
    { Id: 'EquipmentNumber', Value: 'Equipment #', IsSortable: true },
    { Id: 'FieldTagNumber', Value: 'Field Tag #', IsSortable: true },
    { Id: 'JurisdictionNumber', Value: 'Jurisdiction #', IsSortable: true },
    { Id: 'Manufacturer', Value: 'Manufacturer', IsSortable: true },
    { Id: 'MAWP', Value: 'MAWP', IsSortable: true },
    {
      IsSortable: true,
      Id: 'MaximumCapacity',
      MeasurementKey: 'capacity',
      Value: 'Maximum Capacity'
    },
    { Id: 'MDMT', Value: 'MDMT', IsSortable: true },
    { Id: 'Model', Value: 'Model', IsSortable: true },
    { Id: 'PhysicalLocation', Value: 'Physical Location', IsSortable: true },
    { Id: 'PSVType', Key: 'psvType', Value: 'PSV type', IsSortable: true },
    { Id: 'PWHT', Key: 'postWeldHeatTreat', Value: 'PWHT', IsSortable: true },
    { Id: 'SerialNumber', Value: 'Serial #', IsSortable: true },
    { Id: 'Service', Value: 'Service', IsSortable: true },
    { Id: 'Subtype', Value: 'Subtype', IsSortable: true },
    { Id: 'Diameter', Value: 'Diameter', IsSortable: true },
    { Id: 'Height', Value: 'Height', IsSortable: true },
    { Id: 'ValveLocation', Value: 'Valve Location', IsSortable: true },
    { Id: 'Length', Value: 'Length', IsSortable: true },
    { Id: 'YearBuilt', Key: 'manufacturedYear', Value: 'Year Built', IsSortable: true },
    {
      Id: '_Badges',
      Value: '_Badges',
      AlignBottom: true,
      Badges: [
        { Text: 'Tasks', Id: 'tasks', Color: '#FCCECE' },
        { Text: 'Status', Id: 'status' },
        { Text: 'Children', Id: 'children' }
      ]
    }
  ],
  pipeline: [
    { Id: 'LicenseLineNum', Value: 'License # - Line #', moreInfo: true },
    { Id: 'FromLocation', Value: 'From Location', moreInfo: true },
    { Id: 'ToLocation', Value: 'To Location', moreInfo: true },
    { Id: 'Area', Value: 'Area', IsSortable: true, moreInfo: true },
    { Id: 'ApprovalNumber', Value: 'Approval #', IsSortable: true, moreInfo: true },
    { Id: 'GDMID', Value: 'GDM ID', IsSortable: true },
    { Id: 'Length', Value: 'Length', IsSortable: true, moreInfo: true },
    { Id: 'Material', Value: 'Material', IsSortable: true, moreInfo: true },
    { Id: 'Substance', Value: 'Substance', IsSortable: true, moreInfo: true },
    { Id: 'Type', Value: 'Type', IsSortable: true, moreInfo: true },
    { Id: 'PipelineNumber', Value: 'Pipeline #', IsSortable: true, moreInfo: true },
    {
      Id: '_Badges',
      Value: '_Badges',
      AlignBottom: true,
      Badges: [
        { Text: 'Tasks', Id: 'tasks', Color: '#FCCECE' },
        { Text: 'Status', Id: 'status' }
      ]
    }
  ],
  inspection: [
    { Id: 'Type', Value: 'Type' },
    { Id: 'RunItemDesc', Value: 'Run item description' },
    { Id: 'Inspectors', Value: 'Inspector(s)', IsSortable: true },
    { Id: 'Interval', Value: 'Interval', IsSortable: true },
    { Id: 'AppliesTo', Value: 'Applies to (asset data)', AlignBottom: true },
    {
      Id: '_Badges',
      Value: '_Badges',
      AlignBottom: true,
      Badges: [{ Text: 'Run #', Text2: 'Name', Id: 'runnum', Color: '#D5E6FB' }]
    },
    { Id: 'ScheduledDate', Value: 'Scheduled/Due Date', AlignBottom: true }
  ],
  workorder: [
    { Id: 'Title', Value: 'Title' },
    { Id: 'Type', Value: 'Type', IsSortable: true },
    { Id: 'Description', Value: 'Description', IsSortable: true },
    { Id: 'Recurrence', Value: 'Recurrence', IsSortable: true },
    { Id: 'ResponsibleUsers', Value: 'Responsible User(s)', IsSortable: true },
    { Id: 'Status', Value: 'Status', IsSortable: true },
    { Id: 'AppliesTo', Value: 'Applies to (asset data)', AlignBottom: true },
    {
      Id: '_Badges',
      Value: '_Badges',
      AlignBottom: true,
      Badges: [
        { Text: 'Priority', Id: 'priority' },
        { Text: 'RT #', Text2: 'Name', Id: 'rtnum', Color: '#D5E6FB' }
      ]
    },
    { Id: 'ScheduledDate', Value: 'Scheduled/Due Date', AlignBottom: true }
  ],
  check: [
    { Id: 'Type', Value: 'Type' },
    { Id: 'Description', Value: 'Description' },
    { Id: 'Identifier', Value: 'Identifier', IsSortable: true },
    { Id: 'Location', Value: 'Location', IsSortable: true },
    { Id: 'Recurrence', Value: 'Recurrence', IsSortable: true },
    { Id: 'ResponsibleUsers', Value: 'Responsible User(s)', IsSortable: true },
    { Id: 'AppliesTo', Value: 'Applies to (asset data)', AlignBottom: true },
    { Id: 'ScheduledDate', Value: 'Scheduled/Due Date', AlignBottom: true }
  ]
};
