/* eslint-disable no-unused-vars */

import React, { memo, useEffect, useCallback } from 'react';

import AppBar from '@components/app-bar';
import Home from '@components/home';

import { filterActions } from '@redux/slices/filters';
import { getAppDispatch } from '@utilities/dispatch.util';
import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './style.module.scss';

const Dashboard = () => {
  const dispatch = getAppDispatch?.(); 

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (dispatch) {
      dispatch?.(filterActions.setTaskFilters(null));
      dispatch?.(filterActions.setSitesFilters(null));
      dispatch?.(filterActions.setEquipmentsFilters(null));
      dispatch?.(filterActions.setPipelinesFilters(null));
      dispatch?.(filterActions.setStructuresFilters(null));
    }
  }, [dispatch]);

  useEffect(() => {
    const indicator = document.querySelector('.onlineOfflineIndicator');
    const header = document.querySelector('#header');
    const appBar = document.querySelector('.appBar');

    if (indicator && header && width < 768) {
      indicator.remove();
      header.appendChild(indicator);
    }
    if (indicator && appBar && width >= 768) {
      indicator.remove();
      appBar.insertBefore(indicator, appBar.firstChild);
    }
  }, [width]);

  const onScrollEvent = useCallback(() => {
    let el = document.querySelector('#dashboard');
    el.classList.add('addScrollbar');
    el.classList.remove('removeScrollbar');
    clearTimeout();
    setTimeout(function () {
      el.classList.remove('addScrollbar');
      el.classList.add('removeScrollbar');
    }, 300);
  }, []);

  useEffect(() => {
    const indicator = document.querySelector('.onlineOfflineIndicator');
    const header = document.querySelector('#header');
    const appBar = document.querySelector('.appBar');

    if (indicator && header && width < 768) {
      // Remove the indicator from its current location
      indicator.remove();
      // Add the indicator to the header element
      header.appendChild(indicator);
    }
    if (indicator && appBar && width >= 768) {
      indicator.remove();
      appBar.insertBefore(indicator, appBar.firstChild);
    }
  }, [width]);

  return (
    <div>
      <AppBar />
      <div
        className={`md:ml-24 ${style.dashboardContainer} removeScrollbar`}
        id="dashboard"
        onScroll={onScrollEvent}
      >
        <Home />
      </div>
    </div>
  );
};

export default memo(Dashboard);
