/* eslint-disable no-unused-vars */
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from '@components/icon-component';
import RunList from './run-list';

import { getAppDispatch } from '@utilities/dispatch.util';
import { filterActions } from '@redux/slices/filters';

import style from './style.module.scss';

const InspectionRunDetails = ({
  checkedLength,
  view,
  setInspectionRun,
  highlight = '',
  setView,
  inspectionRun,
  onParentClick = null
}) => {
  const navigate = useNavigate();
  const dispatch = getAppDispatch?.();

  const clearAllFilterState = useCallback(() => {
    dispatch?.(filterActions.setTaskFilters(null));
    dispatch?.(filterActions.setSitesFilters(null));
    dispatch?.(filterActions.setEquipmentsFilters(null));
  }, []);

  return (
    <div
      className={`page-listing ${checkedLength > 0 ? 'no-appbar' : ''} `}
      style={{
        marginTop: '0px'
      }}
    >
      {!view ? (
        <RunList
          inspectionRun={inspectionRun}
          checkedLength={checkedLength}
          setInspectionRun={setInspectionRun}
          setView={setView}
          highlight={highlight}
        />
      ) : (
        <>
          <div className="rt">
            {inspectionRun
              ?.filter(({ checked }) => checked)
              ?.map(({ id, title }, i) => (
                <div className="rt--run" key={i}>
                  <span className="task-card-run--id">{id}</span>
                  <span>{title} </span>
                </div>
              ))}
          </div>
          <div
            className="task-type link-card"
            onClick={() => {
              clearAllFilterState();
              onParentClick && onParentClick();
              navigate(
                `/task-records?type=${view}&runs=${inspectionRun
                  ?.filter(({ checked }) => checked)
                  ?.map(({ id }) => id)
                  ?.join(',')}&inspectionRuns=${true}`
              );
            }}
          >
            <div className="task-type--left">
              <div className="task-type--text">View all {view.toLowerCase()}s</div>
            </div>
            <div className="task-type--right">
              <Icon name={'chevron-right'} width="10px" />
            </div>
          </div>
          <div
            className="task-type link-card"
            onClick={() => {
              clearAllFilterState();

              onParentClick && onParentClick();
              navigate(
                `/sites?type=${view}&runs=${inspectionRun
                  ?.filter(({ checked }) => checked)
                  ?.map(({ id }) => id)
                  .join(',')}&inspectionRuns=${true}`
              );
            }}
          >
            <div className="task-type--left">
              <div className="task-type--text">View by site</div>
            </div>
            <div className="task-type--right">
              <Icon name={'chevron-right'} width="10px" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default memo(InspectionRunDetails);
