const waitForLocalStorage = (key, timeout = 100) => {
  return new Promise((resolve) => {
    const poll = () => {
      const current = localStorage.getItem(key);
      if (current) {
        resolve();
      } else {
        setTimeout(poll, timeout);
      }
    };

    poll();
  });
};

export default waitForLocalStorage;
