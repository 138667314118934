/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import Label from '@components/label';
import Icon from '@components/icon-component';
import Badge from '@components/badge/badge';
import TextArea from '../text-area/text-area';
import AddPhoto from '@components/add-photo/add-photo';
import PhotoComponent from '@components/photo-component';

import useWindowDimensions from '@hooks/useWindowDimensions';
import { isNewForm } from '@hooks/isNewForm';
import updateTasksAssets from '@utilities/update-tasks-assets';

import styles from './photobox.module.scss';
import {
  deleteAssetInIndexDB,
  getAssetDataBase64InIndexDB,
  setAssetDataInIndexDB,
  updateAssetFile
} from '@utilities/photos-documents.util';

const PhotoBox = ({
  item,
  control,
  watch,
  getValues,
  setValue,
  errors,
  onTrueFalse = null,
  register
}) => {
  const [isNoteOpen, setNoteOpen] = useState(false);
  const [isHelpOpen, setHelpOpen] = useState(false);
  const [addPhoto, setAddPhoto] = useState(false);
  const [updatePhoto, setUpdatePhoto] = useState(null);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);
  const [photosListWithBase64, setPhotosListWithBase64] = useState([]);

  const { width } = useWindowDimensions();
  const { currentTaskId } = useSelector((state) => state.cache);
  const { newForm: isFormIsNew, workType, orderIds, orderIdsLength } = isNewForm();

  const handleClear = () => {
    setNoteOpen((pre) => !pre);
  };

  useEffect(() => {
    if (getValues(`${item.name}.notes`)) setNoteOpen(true);
  }, [getValues(`${item.name}.notes`)]);

  const { photoCategoryData } = useSelector((state) => state?.picklists);

  const photoCategoryDataMap = photoCategoryData?.reduce((acc, curr) => {
    acc[curr.key] = curr || '';
    return acc;
  }, {});

  const onSubmitPhoto = async (data, updateId) => {
    const uniqueId = updateId ? updateId : uuidv4();
    !updateId
      ? await setAssetDataInIndexDB({
          assetId: uniqueId,
          assetFile: data.image,
          status: 'temporary'
        })
      : !data?.image?.id
      ? await updateAssetFile({ assetId: updateId, assetFile: data.image })
      : '';

    let modifyData = {
      ...data,
      image: { id: uniqueId, name: data.image?.name, type: data.image?.type }
    };

    if (updateId) {
      const previousPhotosData = getValues(`${item.name}.photos`) || [];
      const findIndex = previousPhotosData?.findIndex((x) => x.id === updateId);
      previousPhotosData[findIndex] = { ...modifyData, id: updateId };
      setValue(`${item.name}.photos`, [...previousPhotosData], { shouldDirty: true });
    } else {
      let previousPhotoData = getValues(`${item.name}.photos`) || [];
      setValue(`${item.name}.photos`, [...previousPhotoData, { ...modifyData, id: uniqueId }], {
        shouldDirty: true
      });
    }
    setAddPhoto(false);
    setSelectedPhotoId(null);
  };

  const deleteBtnEvent = async (id, imageIdForIndexDB) => {
    return new Promise((resolve, reject) => {
      const handleAsyncFunction = async () => {
        try {
          await deleteAssetInIndexDB({ assetId: imageIdForIndexDB, deleteAll: true });

          if (!isFormIsNew) {
            updateTasksAssets({
              taskId: orderIdsLength > 1 ? currentTaskId : orderIds,
              assetId: id,
              workType,
              photo: true,
              formData: watch(), //copy JSON,
              fieldName: item.name
            });
          }
          const previousPhotosData = getValues(`${item.name}.photos`) || [];
          const newPhotosList = previousPhotosData?.filter((x) => x.id !== id);
          setValue(`${item.name}.photos`, newPhotosList, { shouldDirty: true });
          resolve(true);
        } catch (error) {
          reject(error);
        }
      };

      handleAsyncFunction();
    });
  };

  const editBtnEvent = (id) => {
    const copyPhotos = getValues(`${item.name}.photos`) || [];
    const photo = copyPhotos?.find((p) => p.id === id);
    setSelectedPhotoId(id);
    setUpdatePhoto(photo);
    setAddPhoto(true);
  };

  const showPhotos =
    watch && watch(`${item.name}.photos`) && _.size(watch(`${item.name}.photos`)) > 0;

  useEffect(() => {
    // need loader to load the image
    const getPhotosList = async () => {
      let list = [];
      if (_.size(watch(`${item.name}.photos`)) > 0) {
        for (let photo of watch(`${item.name}.photos`)) {
          const base64 = await getAssetDataBase64InIndexDB({ assetId: photo?.image?.id });
          const image = { ...photo.image, base64 };
          list.push({ ...photo, image });
        }
        setPhotosListWithBase64(list);
      }
    };
    getPhotosList();
  }, [watch(`${item.name}.photos`)]);

  return (
    <>
      {errors && (
        <div>
          <p className={styles.error}>{'Required'}</p>
        </div>
      )}

      <div className={`${styles.container} ${errors ? styles.required : ''}  `}>
        <div className={styles.containerMain}>
          <Label
            label={item.label}
            dot={item.required}
            className={styles.label}
            dangerouslySetInnerHTML
          />

          <div style={{ alignSelf: 'flex-start' }}>
            {item.type === 'passFail' && (
              <div className={styles.iconsDiv}>
                <div
                  style={{
                    backgroundColor:
                      ((watch && watch(`${item.name}.answer`)) ||
                        (getValues && getValues(`${item.name}.answer`))) === 'true'
                        ? '#34C369'
                        : '#F5F7FA'
                  }}
                  className={styles.iconDiv}
                  onClick={() => onTrueFalse('true')}
                >
                  <Icon
                    name={
                      watch && watch(`${item.name}.answer`) === 'true'
                        ? 'green-check'
                        : 'white-check'
                    }
                    className={styles.icon}
                    style={{ color: '#34C369' }}
                  />
                </div>
                <div
                  style={{
                    backgroundColor:
                      watch && watch(`${item.name}.answer`) === 'false' ? '#E02B2B' : '#F5F7FA'
                  }}
                  className={styles.iconDiv}
                  onClick={() => onTrueFalse('false')}
                >
                  <Icon
                    name={
                      watch && watch(`${item.name}.answer`) === 'false' ? 'error-white' : 'error'
                    }
                    className={styles.icon}
                  />
                </div>
                {item.NA && (
                  <div
                    style={{
                      backgroundColor:
                        watch && watch(`${item.name}.answer`) === 'NA' ? '#093269' : '#F5F7FA'
                    }}
                    className={`${styles.iconDiv}`}
                    onClick={() => onTrueFalse('NA')}
                  >
                    <p
                      style={{
                        color:
                          watch && watch(`${item.name}.answer`) === 'NA' ? '#FFFFFF' : '#323F4B'
                      }}
                    >
                      N/A
                    </p>
                  </div>
                )}
              </div>
            )}
            <div className={styles.iconsDiv}>
              {item.type === 'passFail' && item.extraInfo && (
                <div
                  style={{ backgroundColor: isHelpOpen ? '#F9EDC7' : '#F5F7FA' }}
                  className={styles.iconDiv}
                  onClick={() => setHelpOpen((pre) => !pre)}
                >
                  <Icon name={'help'} className={styles.icon} />
                </div>
              )}
              <div
                style={{ backgroundColor: '#F26531' }}
                className={styles.iconDiv}
                onClick={() => {
                  setSelectedPhotoId(null);
                  setUpdatePhoto(null);
                  setAddPhoto((pre) => !pre);
                }}
              >
                <Badge
                  size="small"
                  count={watch && _.size(watch(`${item.name}.photos`))}
                  color={'#ffffff'}
                  textColor={'#F26531'}
                >
                  {' '}
                  <Icon name={'cameraWhite'} className={styles.icon} />
                </Badge>{' '}
              </div>
              <div
                style={{ backgroundColor: isNoteOpen ? '#2F80ED' : '#F5F7FA' }}
                className={styles.iconDiv}
                onClick={handleClear}
              >
                <Icon
                  name={isNoteOpen ? 'file-type-white' : 'file-type-blue'}
                  className={styles.icon}
                />
              </div>
            </div>
          </div>
        </div>

        {isHelpOpen && (
          <div className={styles.help}>
            <Icon name={'help'} className={styles.icon} />

            <p>{item.extraInfo}</p>
          </div>
        )}
        {isNoteOpen && (
          <div className={styles.note}>
            <TextArea
              item={{ ...item, name: `${item.name}.notes`, label: 'Notes', required: false }}
              control={control}
              watch={watch}
              errors={errors}
            />
          </div>
        )}

        {showPhotos && photosListWithBase64?.length > 0 && (
          <div className={styles.body}>
            <div className={styles.photoCard}>
              <h6>{'photos'}</h6>
              <div
                className={styles.grid2}
                style={{
                  margin: '0 auto',
                  marginTop: '0px',
                  display: width >= 768 ? 'flex' : 'block',
                  gap: '20px',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  flexWrap: width >= 768 ? 'wrap' : 'noWrap'
                }}
              >
                {photosListWithBase64?.map((card, index) => {
                  return (
                    <div key={index} style={{ width: width >= 768 ? '48%' : '100%' }}>
                      <PhotoComponent
                        key={index}
                        photoId={card?.id || index}
                        deleteBtnEvent={deleteBtnEvent}
                        editBtnEvent={editBtnEvent}
                        image={card?.image || null}
                        categoryValue={photoCategoryDataMap[card?.category]?.value || ''}
                        captionValue={card?.caption || ''}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}

        {addPhoto && (
          <AddPhoto
            open={addPhoto}
            closed={setAddPhoto}
            title={`${_.isEmpty(selectedPhotoId) ? 'Add' : 'Edit'} photo`}
            onSubmitPhoto={onSubmitPhoto}
            updatePhoto={updatePhoto}
            id={selectedPhotoId}
            deleteBtnEvent={deleteBtnEvent}
            initialValue={
              selectedPhotoId && !_.isEmpty(getValues(`${item.name}.photos`))
                ? getValues(`${item.name}.photos`)?.find((x) => x?.id === selectedPhotoId)
                : null
            }
          />
        )}
      </div>
    </>
  );
};

export default PhotoBox;
