import React from 'react';

import SettingsValueCard from './settings-value-card';
import DragDropFields from './drag-frop-fields';

const StructureDisplaySettings = () => {
  return (
    <div className="settings--values">
      <SettingsValueCard
        title={'Structure cards'}
        description={'This is how I want structure data to appear on listings and task cards:'}
      >
        <DragDropFields text={'structure'} />
      </SettingsValueCard>
    </div>
  );
};

export default StructureDisplaySettings;
