/* eslint-disable no-unused-vars */
import SitesSelector from '../selectors/sites.selector';
import StructureSelector from '../selectors/structure.selector';
import EquipmentSelector from '../selectors/equipment.selector';
import PipelineSelector from '../selectors/pipeline.selector';
import GlanceBookmarkSelector from '../selectors/glance.selector';
import {
  getFilterEquipment,
  getFilterEquipmentWithPromise
} from '../components/equipment-listing/helper';

import { filterSitesWithPromise } from '@components/site-listing/helper';
import { getFilterStructureWithPromise } from '@components/structure-listing/helper';
import { getFilterPipelineWithPromise } from '@components/pipeline-listing/pipeline-listing-inner/helper';
import {
  equipmentMobileData,
  getAllTasksRecordsData,
  getParentEquipmentCompleteDetails,
  getParentSiteCompleteDetails,
  getSearchRelatedData,
  getSelectedSearchData,
  sitesMobileData
} from './helper';
import { taskTypeData } from '@pages/task-type/helper';
import InspectionRunSelector from '../selectors/inspection-run.selector';
import RecurringTemplateSelector from '../selectors/recurring-template.selector';
import { checkOldAndNewArrayWithPromise } from '@services/check-old-and-new-array';
import { filterTasksWithPromise } from '@pages/tasks-records/tasks-records-list/helper';

export const callRelevantSelector = async ({ eventData, type }) => {
  return new Promise((resolve) => {
    switch (type) {
      case 'get-all-equipment-listing':
        EquipmentSelector.getEquipmentsWithAllTasks({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-filtered-equipment-listing':
        getFilterEquipmentWithPromise({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-all-default-bookmarks':
        GlanceBookmarkSelector.getDefaultBookmark({
          store: eventData?.args?.store,
          checkAllBookmarksIsNotAtAGlance: eventData?.args?.checkAllBookmarksIsNotAtAGlance
        })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-single-equipment-details':
        EquipmentSelector.getSingleEquipmentDetails({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-single-structure-details':
        StructureSelector.getStructureWithEquipment({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-parent-equipment-details':
        getParentEquipmentCompleteDetails({ args: eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-all-site-listings':
        SitesSelector.getSitesWithAllTasks({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-site-details':
        SitesSelector.getSiteWithEquipment({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-all-structure-listing':
        StructureSelector.getStructuresWithWO({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-parent-site-details':
        getParentSiteCompleteDetails({ args: eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-parent-site-complete-details':
        SitesSelector.getParentSiteDetailsInfo({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-all-pipeline-listing':
        PipelineSelector.getPipelineWithWO({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-pipeline-details':
        PipelineSelector.getPipelineWithWO({ ...eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-all-tasks-records':
        getAllTasksRecordsData({ args: eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-all-search-data':
        getSearchRelatedData({ args: eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-search-input-data':
        getSelectedSearchData({ args: eventData?.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-searched-sites-listing':
        filterSitesWithPromise({ args: eventData.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-searched-structure-listing':
        getFilterStructureWithPromise({ args: eventData.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-searched-equipment-listing':
        getFilterEquipmentWithPromise({ args: eventData.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-searched-pipelines-listing':
        getFilterPipelineWithPromise({ args: eventData.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-task-type-records':
        taskTypeData({
          store: eventData.args?.store,
          permissions: eventData.args?.permissions
        })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-all-inspection-runs':
        InspectionRunSelector.getInspectionRuns({ ...eventData.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-all-recurring-template':
        RecurringTemplateSelector.getRecurringTemplateTasks({ ...eventData.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'check-old-and-new-array':
        checkOldAndNewArrayWithPromise({ args: eventData.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'equipment-mobile-data-redux':
        equipmentMobileData({
          equipmentPayloadData: eventData.args?.equipmentPayloadData || [],
          isCheckOldAndNewArray: eventData.args?.isCheckOldAndNewArray
        })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      case 'get-searched-tasks-listing':
        filterTasksWithPromise({ args: eventData.args })
          .then((result) => {
            resolve({ result });
          })
          .catch((error) => {
            resolve({ error });
          });
        break;

      default:
        break;
    }
  });
};
