/* eslint-disable no-unused-vars */
import React, { useState, useEffect, memo, useRef } from 'react';
import _ from 'lodash';
import { useMemo } from 'react';

import useWindowDimensions from '@hooks/useWindowDimensions';
import CustomSelect from '@components/select-box-component';

import style from './style.module.scss';

const Range = ({
  options,
  defaultValue,
  onChangeRangeValue,
  name,
  control,
  watch,
  isSelectBoxOpen
}) => {
  const [selected, setSelected] = useState();
  const [assessment, setAssessment] = useState(null);
  const thumbRef = useRef(null);
  const optionRef = useRef(null);
  const rangeVRef = useRef(null);
  const containerRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const selectRef = useRef(null);
  const pillRef = useRef(null);
  const [allOptionWidthMap, setAllOptionWidthMap] = useState([]);
  const { width } = useWindowDimensions();
  const [initialX, setInitialX] = useState(0);
  const [currentX, setCurrentX] = useState(0);

  useEffect(() => {
    if (_.isNull(defaultValue)) {
      setSelected(null);
      setAssessment(null);
    } else {
      setSelected(defaultValue);
      setAssessment(null);
    }
  }, [defaultValue]);

  const gradedArray = useMemo(() => {
    return calculateGrade(options);
  }, [options]);

  const newOptions = useMemo(() => {
    return Array.from(options, (option) => ({ label: option?.toString(), value: option }));
  }, [options]);

  const gradedArrayMap = gradedArray?.reduce((acc, curr) => {
    acc[curr.index] = curr;
    return acc;
  }, {});

  const calculateVariable = ({ index }) => {
    if (_.isNull(defaultValue) && _.isNull(selected)) {
      setAssessment(null);
      return `<div></div>`;
    } else {
      if (parseInt(index) <= 0 || _.isNaN(parseInt(index))) {
        setAssessment('Fail');
        return `<div class=${style.fail} >Fail</div>`;
      } else if (parseInt(index) >= options.length - 1) {
        setAssessment('Pass');
        return `<div class=${style.pass} >Pass</div>`;
      } else {
        setAssessment(gradedArrayMap[index]?.grade);
        return `<div class=${style[gradedArrayMap[index]?.grade.toLowerCase()]} >${
          gradedArrayMap[index]?.grade
        }</div>`;
      }
    }
  };

  useEffect(() => {
    if (selected && assessment) onChangeRangeValue(selected, assessment);
  }, [selected, assessment]);

  useEffect(() => {
    if (!isSelectBoxOpen) {
      const selectElement = selectRef.current;
      const parentDivWidthFromLeft = selectElement.getBoundingClientRect()?.left - 16;
      const childElements = Array.from(selectElement.getElementsByClassName('child'));
      const arr = childElements.map((child, index) => {
        const childWidth = child.getBoundingClientRect();
        return {
          option: options[index],
          width:
            childWidth.left +
            (selected <= 10 ? -11 : selected <= 15 ? 0 : selected <= 30 ? +5 : 0) -
            index -
            parentDivWidthFromLeft,
          index
        };
      });

      const map = arr.reduce((acc, curr) => {
        acc[curr.option] = curr;
        return acc;
      }, {});
      setAllOptionWidthMap(map);
      if (_.isNull(selected)) {
        setAssessment(null);
        thumbRef.current.innerHTML = `<p class=${style.spanAlign} ></p>`;
        thumbRef.current.style.left = `${Math.floor(
          selectElement.getBoundingClientRect()?.width / 2
        )}px`;
        rangeVRef.current.innerHTML = <></>;
        rangeVRef.current.style.display = 'none';
      } else {
        thumbRef.current.innerHTML = `<p class=${style.spanAlign} >${selected}</p>`;
        thumbRef.current.style.left = `${map[selected]?.width}px`;
        rangeVRef.current.innerHTML = calculateVariable({ index: map[selected]?.index });
        rangeVRef.current.style.left = `${
          selected === options[options.length - 1] ? map[selected]?.width - 5 : map[selected]?.width
        }px`;
        rangeVRef.current.style.display = 'revert-layer';
      }
    }
  }, [width, options, selected, defaultValue]);

  const handleDragStart = (event) => {
    const touch = event?.touches && event.touches[0];
    touch && setInitialX(touch.clientX);
    setDragging(true);
  };

  const handleTouchMove1 = (event) => {
    if (initialX === 0) return;
    const touch = event.touches[0];
    const deltaX = touch.clientX - initialX;
    setCurrentX(deltaX);
    let left = false,
      right = true;
    if (deltaX < 0) {
      left = true;
      right = false;
    } else if (deltaX > 0) {
      left = false;
      right = true;
    }
    handleDrag(event.touches[0], true, { right, left });
  };

  const handleTouchEnd1 = () => {
    setInitialX(0);
    setDragging(false);
  };

  const handleDragEnd = () => setDragging(false);

  const handleDrag = (e, touch = false, { left = false, right = true }) => {
    if (dragging) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerLeft = containerRect.left;
      const containerWidth = containerRect.width;
      const clickOffset = getClientX(e) - containerLeft;
      const optionWidth = containerWidth / (options.length - 1);
      let selectedIndex = Math.round(clickOffset / optionWidth);
      let selectedIndex1 = Math.round(clickOffset / optionWidth);
      selectedIndex =
        parseInt(selectedIndex) < 0 || _.isNaN(selectedIndex)
          ? 0
          : selectedIndex > options.length - 1
          ? options.length - 1
          : selectedIndex;
      const selectedValue =
        options[touch && left ? (selectedIndex ? --selectedIndex : 0) : selectedIndex];
      thumbRef.current.innerHTML = `<p class=${style.spanAlign} >${selectedValue}</p>`;
      thumbRef.current.style.left =
        selectedIndex1 < options.length &&
        `${
          Math.round(clickOffset / optionWidth) * optionWidth >= 0
            ? allOptionWidthMap[selectedValue]?.width
            : 0
        }px`;
      rangeVRef.current.innerHTML = calculateVariable({ index: selectedIndex1 });
      rangeVRef.current.style.left =
        selectedIndex1 < options.length &&
        `${
          Math.round(clickOffset / optionWidth) * optionWidth >= 0
            ? selectedValue === options[options.length - 1]
              ? allOptionWidthMap[selectedValue]?.width - 5
              : allOptionWidthMap[selectedValue]?.width
            : 0
        }px`;
      setSelected(selectedValue);
    }
  };

  const getClientX = (e) => {
    if (e.clientX) {
      return e.clientX;
    } else if (e.touches && e.touches.length > 0) {
      return e.touches[0].pageX; // Touch event
    }
  };

  useEffect(() => {
    const handleDragMouseMove = (e) => {
      handleDrag(e, false, { left: false, right: false });
    };

    const handleDragMouseUp = () => {
      handleDragEnd();
      document.removeEventListener('mousemove', handleDragMouseMove);
      document.removeEventListener('mouseup', handleDragMouseUp);
    };

    if (dragging) {
      document.addEventListener('mousemove', handleDragMouseMove);
      document.addEventListener('mouseup', handleDragEnd);
    }

    return () => {
      document.removeEventListener('mousemove', handleDragMouseMove);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [dragging]);

  useEffect(() => {
    if (isSelectBoxOpen) {
      const selectedValue = watch(`${name}.answer`) || 0;
      const IndexOfSelectedValue = gradedArray.find((elem) => elem.value === selectedValue)?.index;
      if (selectedValue) {
        setSelected(selectedValue);
        pillRef.current.innerHTML = calculateVariable({ index: IndexOfSelectedValue });
      } else {
        pillRef.current.innerHTML = `<div class=${style.null}></div>`;
      }
    }
  }, [watch(`${name}.answer`)]);

  return (
    <div className={style.sliderContainer} ref={containerRef}>
      {!isSelectBoxOpen ? (
        <>
          <div className={style.select} ref={selectRef}>
            {options?.map((x, index) => (
              <div
                ref={optionRef}
                style={{
                  visibility: selected === x ? 'hidden' : 'visible'
                }}
                key={x}
                onClick={(e) => {
                  thumbRef.current.innerHTML = `<p class=${style.spanAlign} >${x}</p>`;
                  thumbRef.current.style.left = `${allOptionWidthMap[x]?.width}px`;

                  rangeVRef.current.innerHTML = calculateVariable({ index });
                  rangeVRef.current.style.left = `${
                    x === options[options.length - 1]
                      ? allOptionWidthMap[x]?.width - 5
                      : allOptionWidthMap[x]?.width
                  }px`;
                  setSelected(x);
                }}
                className="child"
              ></div>
            ))}
          </div>
          <div
            className={style.thumb}
            ref={thumbRef}
            id="resizableDiv"
            onMouseDown={handleDragStart}
            onTouchStart={handleDragStart}
            onTouchMove={handleTouchMove1}
            onTouchEnd={handleTouchEnd1}
          ></div>

          <div className={style.rangeValue} id="rangeV" ref={rangeVRef}></div>

          <div className={style.sliderLine}></div>
        </>
      ) : (
        <div className={style.selectBoxContainer}>
          <CustomSelect
            control={control}
            name={`${name}.answer`}
            options={newOptions || []}
            className={style.selectBox}
            isClearable={true}
          />
          <div className={style.pill} ref={pillRef}></div>
        </div>
      )}
    </div>
  );
};

export default memo(Range);

const calculateGrade = (array) => {
  const length = array.length;
  const failLength = Math.floor(length * 0.2); //20%
  const poorLength = Math.floor(length * 0.3); // 30%
  const fairLength = Math.floor(length * 0.3); // 30%
  const passLength = length - failLength - poorLength - fairLength; //20%

  const result = [];
  for (let i = 0; i < length; i++) {
    if (i < failLength) {
      result.push({ value: array[i], grade: 'Fail', index: i });
    } else if (i < failLength + poorLength) {
      result.push({ value: array[i], grade: 'Poor', index: i });
    } else if (i < failLength + poorLength + fairLength) {
      result.push({ value: array[i], grade: 'Fair', index: i });
    } else {
      result.push({ value: array[i], grade: 'Pass', index: i });
    }
  }

  return result;
};
