/* eslint-disable no-unused-vars */
import _ from 'lodash';

import DataSnippets from '@components/data-snippet';

import style from '../task.module.scss';

const DetailInspection = ({
  highlight,
  className,
  showNotIcon = false,
  detailInspectionsInfo = []
}) => {
  return (
    <>
      {detailInspectionsInfo?.length > 0 &&
        detailInspectionsInfo?.map((info, index) => (
          <div className={style['mt-16']} key={index}>
            <DataSnippets
              title={info?.inspectionNumber || ''}
              subtitle={info?.inspectionType || ''}
              iconName={!showNotIcon && 'inspection-grey'}
              backFlex={style.backClass}
              highlightNames={highlight}
              className={`${style.mainClass} ${className} ${style.dataSnippetMargin}`}
              mocHseNcrInspection
            />
          </div>
        ))}
    </>
  );
};

export default DetailInspection;
