import React from 'react';

import InputField from '@components/input-field';
import Label from '@components/label';

import styles from './check.module.scss';

const IsBeingChecked = ({ register }) => {
  const formFields = [
    { label: 'Identifier', name: 'identifier' },
    { label: 'Location', name: 'location' },
    { label: 'Description', name: 'description' }
  ];

  return (
    <div className={styles.main}>
      <Label
        label={'What is being checked?'}
        className={styles.checksLabel}
        inlineTextStyle={{ fontSize: '16px' }}
      />
      <div className={styles.inputFields}>
        {formFields?.map(({ label, name }) => (
          <div className="op-input-field-wrap" key={name}>
            <InputField
              label={label}
              inputClass="input-field input-regular"
              register={register}
              name={name}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default IsBeingChecked;
