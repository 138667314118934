/* eslint-disable no-unused-vars */

import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import { store } from 'redux/store';

import AppBar from '@components/app-bar';
import LoadingRound from '@components/loading-round/loading-round';
import EquipmentListingInner from '@components/equipment-listing/equipment-listing-inner';

import useWindowDimensions from '@hooks/useWindowDimensions';
import { getParentEquipmentData, getParentEquipmentDetails } from './helper';
import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';

import { initWebWorker } from 'webworker';

const ParentEquipment = () => {
  const { id } = useParams();
  const { width } = useWindowDimensions();
  const dispatch = getAppDispatch();
  const selectTask = useSelector((state) => state.tasks.selectTask) || false;
  const equipmentsLoading = useSelector((state) => state.cache.equipmentsLoading);
  const [parentEquipmentDetails, setParentEquipmentDetails] = useState({});
  const [equipmentWorkOrderDetails, setEquipmentWorkOrderDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch?.(backHistoryActions.setEquipmentDetailsPageHistory(null));
    dispatch?.(backHistoryActions.setParentEquipmentDetailsPageHistory(null));
  }, []);

  useEffect(() => {
    initWebWorker({
      args: {
        equipmentId: id,
        store: { ...store.getState() }
      },
      type: 'get-parent-equipment-details'
    })
      .then((data) => {
        const [res1, res2] = data?.result || [];

        setParentEquipmentDetails({ ...res1 });
        setEquipmentWorkOrderDetails({ ...res2 });
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id]);

  const { parentEquipmentWithWorkOrders = {}, childrenEquipmentWithWorkOrders = [] } =
    useMemo(() => {
      if (!isLoading) return parentEquipmentDetails;
      return {};
    }, [parentEquipmentDetails, isLoading]);

  const { equipmentWithWorkOrders } = useMemo(() => {
    if (!isLoading) return equipmentWorkOrderDetails;
    return {};
  }, [equipmentWorkOrderDetails, isLoading]);

  useEffect(() => {
    if (!isLoading)
      if (!equipmentsLoading && !parentEquipmentWithWorkOrders)
        return <Navigate to={'/equipment'} />;
  }, [isLoading, parentEquipmentDetails, equipmentWorkOrderDetails, parentEquipmentWithWorkOrders]);

  return (
    <div className="page-container">
      {selectTask && width <= 767 ? '' : <AppBar />}
      <div className="md:ml-24">
        <div className="relative overflow-x-hidden">
          <div
            className="op-page-name op-listing-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative"
            style={{ position: 'sticky', top: '0px' }}
          >
            <Link className="op-back " to={-1}></Link>
            <h1 className="h1-semibold pl-16">Parent equipment</h1>
            <p>&nbsp;</p>
          </div>
          {isLoading ? (
            <div style={{ backgroundColor: 'transparent', width: '100%' }}>
              <LoadingRound />
            </div>
          ) : (
            <>
              {equipmentsLoading ? (
                ''
              ) : (
                <div>
                  <div
                    className={`page-listing  `}
                    style={{
                      width: `100%`
                    }}
                  >
                    <div className="">
                      <p className="pl-4 label-bold pt-4 pb-2.5 text-primary-500">
                        PARENT AND CHILD EQUIPMENT
                      </p>
                      <div style={{ width: '100%', borderTop: '.5px solid rgb(203, 210, 217)' }} />
                      <EquipmentListingInner parent {...parentEquipmentWithWorkOrders} divider />
                      <p className="pl-4 label-bold pt-8 pb-2.5 text-primary-500">
                        PARENT EQUIPMENT
                      </p>
                      <div style={{ width: '100%', borderTop: '.5px solid rgb(203, 210, 217)' }} />
                      <EquipmentListingInner
                        normal
                        showWOCount
                        {...equipmentWithWorkOrders}
                        divider
                      />
                      <p className="pl-4 label-bold pt-8 pb-2.5 text-primary-500">
                        CHILD EQUIPMENT
                      </p>
                      <div style={{ width: '100%', borderTop: '.5px solid rgb(203, 210, 217)' }} />

                      {childrenEquipmentWithWorkOrders.map((equipment) => (
                        <EquipmentListingInner key={equipment.equipmentId} {...equipment} divider />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ParentEquipment);
