/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import _ from 'lodash';

import CollapseableFilter from './task-status';
import PriorityFilter from './priority';
import DueDateFilter from './due-date';

const TaskFilterGroup = ({
  filterType = null,
  taskTypeTag,
  filters,
  updateFilters,
  taskStatusTags,
  workOrderTypeTags,
  assignableUsersTags,
  picklistsMap,
  workOrderPriorityTags,
  taskRecurringTemplateTags,
  isActive,
  equipmentListing = false,
  siteListing = true,
  pipelineListing = false,
  tasksListing = false,
  asset,
  inspectionRunTags,
  inspectionTypeTags,
  hseEventsCategoriesTags,
  checkTypeTags,
  checkRecurrancePriorityTags,
  checkTypeTagsMap,
  inspectionTypeTagsMap
}) => {
  return (
    <>
      {!tasksListing && (
        <p
          className="label-regular op-filter-label"
          style={{ padding: '0px 0px 32px 0px', marginBottom: '0px' }}
        >
          Tasks
        </p>
      )}

      {filterType !== 'inspection' &&
        filterType !== 'workOrder' &&
        filterType !== 'Inspection' &&
        filterType !== 'Work Order' &&
        filterType !== 'hseEvents' &&
        filterType !== 'check' &&
        filterType !== 'Check' &&
        !_.isEmpty(taskTypeTag) && (
          <div style={{ marginBottom: '40px' }}>
            <CollapseableFilter
              title={'Task type'}
              tags={taskTypeTag || []}
              multi
              value={filters?.taskType.value}
              onChange={(value) => updateFilters('taskType', 'value', value)}
            />
          </div>
        )}

      <div style={{ marginBottom: '40px' }}>
        <CollapseableFilter
          title={'Task status'}
          tags={taskStatusTags || ['To do', 'In progress', 'Pending sync', 'Synced']}
          multi
          value={filters.taskStatus.value}
          onChange={(value) => updateFilters('taskStatus', 'value', value)}
        />
      </div>

      {tasksListing
        ? asset &&
          !_.isEmpty(workOrderTypeTags) && (
            <div style={{ marginBottom: '40px' }}>
              <CollapseableFilter
                title={'Work order type'}
                tags={workOrderTypeTags || []}
                multi
                value={filters?.workOrderType?.value}
                onChange={(value) => updateFilters('workOrderType', 'value', value)}
              />
            </div>
          )
        : !pipelineListing &&
          filterType !== 'check' &&
          !_.isEmpty(workOrderTypeTags) && (
            <div style={{ marginBottom: '40px' }}>
              <CollapseableFilter
                title={'Work order type'}
                tags={workOrderTypeTags || []}
                multi
                value={filters?.workOrderType?.value}
                onChange={(value) => updateFilters('workOrderType', 'value', value)}
              />
            </div>
          )}

      {tasksListing
        ? filterType !== 'inspection' &&
          filterType !== 'workOrder' &&
          filterType !== 'Inspection' &&
          filterType !== 'Work Order' &&
          filterType !== 'hseEvents' &&
          filterType !== 'check' &&
          filterType !== 'Check' &&
          assignableUsersTags?.length > 0 && (
            <div style={{ marginBottom: '40px' }}>
              <CollapseableFilter
                title={'Assigned to'}
                tags={assignableUsersTags}
                multi
                value={filters?.assign.value}
                onChange={(value) => updateFilters('assign', 'value', value)}
              />
            </div>
          )
        : assignableUsersTags?.length > 0 && (
            <div style={{ marginBottom: '40px' }}>
              <CollapseableFilter
                title={'Assigned to'}
                tags={assignableUsersTags}
                multi
                value={filters.assign.value}
                onChange={(value) => updateFilters('assign', 'value', value)}
              />
            </div>
          )}

      {tasksListing
        ? filterType !== 'inspection' &&
          filterType !== 'Inspection' &&
          (_.size(workOrderPriorityTags) > 0 || _.size(checkRecurrancePriorityTags) > 0) && (
            <div style={{ marginBottom: '40px' }}>
              <PriorityFilter
                multi
                isActive={isActive}
                title={'Priority'}
                label1={
                  filterType !== 'Work Order' &&
                  filterType !== 'workOrder' &&
                  checkRecurrancePriorityTags?.length > 0
                    ? 'Checks'
                    : ''
                }
                tagsMap1={picklistsMap}
                tags1={checkRecurrancePriorityTags}
                value1={filters?.checkRecurrancePriority?.value}
                onChange1={(value) => updateFilters('checkRecurrancePriority', 'value', value)}
                label2={
                  filterType?.toLowerCase() !== 'check'
                    ? _.size(workOrderPriorityTags) > 0
                      ? 'Work Orders'
                      : ''
                    : ''
                }
                tagsMap2={picklistsMap}
                tags2={filterType === 'check' ? [] : workOrderPriorityTags}
                value2={filters?.workOrderPriority.value}
                onChange2={(value) => updateFilters('workOrderPriority', 'value', value)}
              />
            </div>
          )
        : (_.size(workOrderPriorityTags) > 0 || _.size(checkRecurrancePriorityTags) > 0) && (
            <div style={{ marginBottom: '40px' }}>
              <PriorityFilter
                multi
                isActive={isActive}
                title={'Priority'}
                label2={
                  filterType === 'check'
                    ? ''
                    : _.size(workOrderPriorityTags) > 0
                    ? 'Work Orders'
                    : ''
                }
                tagsMap2={picklistsMap}
                tags2={filterType === 'check' ? [] : workOrderPriorityTags}
                value2={filters?.workOrderPriority?.value}
                onChange2={(value) => updateFilters('workOrderPriority', 'value', value)}
                label1={checkRecurrancePriorityTags?.length > 0 ? 'Checks' : ''}
                tagsMap1={picklistsMap}
                tags1={checkRecurrancePriorityTags}
                value1={filters?.checkRecurrancePriority?.value}
                onChange1={(value) => updateFilters('checkRecurrancePriority', 'value', value)}
              />
            </div>
          )}

      {tasksListing
        ? filterType !== 'inspection' &&
          filterType !== 'Inspection' &&
          filterType !== 'check' &&
          filterType !== 'Check' &&
          taskRecurringTemplateTags?.length > 0 && (
            <div style={{ marginBottom: '40px' }}>
              <CollapseableFilter
                title={'Recurring template'}
                tags={taskRecurringTemplateTags || []}
                multi
                value={filters?.recurringTemplate.value}
                onChange={(value) => updateFilters('recurringTemplate', 'value', value)}
              />
            </div>
          )
        : !pipelineListing &&
          filterType !== 'check' &&
          filterType !== 'Check' &&
          taskRecurringTemplateTags?.length > 0 && (
            <div style={{ marginBottom: '40px' }}>
              <CollapseableFilter
                title={'Recurring template'}
                tags={taskRecurringTemplateTags || []}
                multi
                value={filters?.recurringTemplate.value}
                onChange={(value) => updateFilters('recurringTemplate', 'value', value)}
              />
            </div>
          )}

      {filterType !== 'workOrder' &&
        filterType !== 'check' &&
        filterType !== 'Check' &&
        !_.isEmpty(inspectionRunTags) && (
          <div style={{ marginBottom: '40px' }}>
            <CollapseableFilter
              title={'Inspection run'}
              tags={inspectionRunTags || []}
              multi
              value={filters?.inspectionRun?.value}
              onChange={(value) => updateFilters('inspectionRun', 'value', value)}
            />
          </div>
        )}

      {filterType !== 'workOrder' && filterType !== 'inspection' && !_.isEmpty(checkTypeTags) && (
        <div style={{ marginBottom: '40px' }}>
          <CollapseableFilter
            title={'Check type'}
            tags={checkTypeTags || []}
            multi
            value={filters?.checkType?.value}
            onChange={(value) => updateFilters('checkType', 'value', value)}
            tagsMap={checkTypeTagsMap}
          />
        </div>
      )}

      {tasksListing ? (
        filterType !== 'workOrder' &&
        filterType !== 'check' &&
        filterType !== 'Check' &&
        !_.isEmpty(inspectionTypeTags) && (
          <div style={{ marginBottom: '40px' }}>
            <CollapseableFilter
              title={'Inspection type'}
              tags={inspectionTypeTags || []}
              multi
              value={filters?.inspectionType?.value}
              onChange={(value) => updateFilters('inspectionType', 'value', value)}
              tagsMap={inspectionTypeTagsMap}
            />
          </div>
        )
      ) : (
        <></>
      )}

      {filterType !== 'workOrder' &&
        filterType?.toLowerCase() !== 'inspection' &&
        filterType?.toLowerCase() !== 'check' &&
        !_.isEmpty(hseEventsCategoriesTags) && (
          <div style={{ marginBottom: '40px' }}>
            <CollapseableFilter
              title={filterType === 'hseEvents' ? 'Category' : 'HSE event category'}
              tags={hseEventsCategoriesTags || []}
              multi
              value={filters?.hseEventCategory?.value}
              onChange={(value) => updateFilters('hseEventCategory', 'value', value)}
            />
          </div>
        )}

      {filterType !== 'hseEvents' && (
        <div style={{ marginBottom: '40px' }}>
          <DueDateFilter
            tagName={'dueDate'}
            header={'Due date'}
            defaultValue={
              filters.dueDate.selected === 'next' ? { value: 'next', label: 'In the next' } : {}
            }
            value={filters.dueDate.value}
            selected={filters.dueDate.selected}
            onChange={(value) => updateFilters('dueDate', 'value', value)}
            onSelect={(selected) => updateFilters('dueDate', 'selected', selected)}
            options={[
              { value: 'next', label: 'In the next' },
              { value: 'on', label: 'On' },
              { value: 'range', label: 'Range' },
              { value: 'past', label: 'In the past' }
            ]}
          />
        </div>
      )}

      {filterType !== 'hseEvents' && (
        <div style={{ marginBottom: '40px' }}>
          <DueDateFilter
            tagName={'dueDate'}
            header={'Scheduled date'}
            defaultValue={
              filters.scheduledDate.selected === 'next'
                ? { value: 'next', label: 'In the next' }
                : {}
            }
            value={filters.scheduledDate.value}
            selected={filters.scheduledDate.selected}
            onChange={(value) => updateFilters('scheduledDate', 'value', value)}
            onSelect={(selected) => updateFilters('scheduledDate', 'selected', selected)}
            options={[
              { value: 'next', label: 'In the next' },
              { value: 'on', label: 'On' },
              { value: 'range', label: 'Range' },
              { value: 'past', label: 'In the past' }
            ]}
          />
        </div>
      )}

      {tasksListing &&
        filterType !== 'Inspection' &&
        filterType !== 'hseEvents' &&
        filterType !== 'Check' && (
          <div style={{ marginBottom: '40px' }}>
            <DueDateFilter
              tagName={'dueDate'}
              header={'Created'}
              defaultValue={
                filters?.createdAt?.selected === 'past'
                  ? { value: 'past', label: 'In the past' }
                  : {}
              }
              value={filters?.createdAt?.value}
              selected={filters?.createdAt?.selected}
              onChange={(value) => updateFilters('createdAt', 'value', value)}
              onSelect={(selected) => updateFilters('createdAt', 'selected', selected)}
              options={[
                { value: 'past', label: 'In the past' },
                { value: 'on', label: 'On' },
                { value: 'range', label: 'Range' }
              ]}
            />
          </div>
        )}
      <div style={{ marginBottom: '40px' }}>
        <DueDateFilter
          tagName={'dueDate'}
          header={'Synced'}
          defaultValue={
            filters?.syncedDate?.selected === 'past' ? { value: 'past', label: 'In the past' } : {}
          }
          value={filters?.syncedDate?.value}
          selected={filters?.syncedDate?.selected}
          onChange={(value) => updateFilters('syncedDate', 'value', value)}
          onSelect={(selected) => updateFilters('syncedDate', 'selected', selected)}
          options={[
            { value: 'past', label: 'In the past' },
            { value: 'on', label: 'On' },
            { value: 'range', label: 'Range' }
          ]}
        />
      </div>
    </>
  );
};

export default memo(TaskFilterGroup);
