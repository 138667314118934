/* eslint-disable  no-unused-vars */
// work request/order
import React, { useEffect, useState, memo } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import AddPhoto from '@components/add-photo';
import AddDocument from '@components/add-document';
import PhotoComponent from '@components/photo-component';
import DocumentsCard from '@components/documents-card';

import useWindowDimensions from '@hooks/useWindowDimensions';
import {
  deleteAssetInIndexDB,
  getAssetDataBase64InIndexDB,
  setAssetDataInIndexDB,
  updateAssetFile
} from '@utilities/photos-documents.util';
import { isNewForm } from '@hooks/isNewForm';
import updateTasksAssets from '@utilities/update-tasks-assets';

import styles from './attachments.module.scss';

const Attachments = ({ item, watch, getValues, setValue, noHeader = false }) => {
  const [addPhoto, setAddPhoto] = useState(false);
  const [updatePhoto, setUpdatePhoto] = useState(null);
  const [addDocument, setAddDocument] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);
  const [selectedDocumentId, setSelectedDcumentId] = useState(null);
  const [selectedDocumentAndFile, setSelectedDcumentAndFile] = useState({
    document: null,
    file: null
  });
  const [photosListWithBase64, setPhotosListWithBase64] = useState([]);

  const { width } = useWindowDimensions();
  const { currentTaskId } = useSelector((state) => state.cache);

  const { newForm: isFormIsNew, workType, orderIds, orderIdsLength } = isNewForm();

  const { photoCategoryData, picklistsMap } = useSelector((state) => state.picklists);

  const photoCategoryDataMap = photoCategoryData?.reduce((acc, curr) => {
    acc[curr.key] = curr || '';
    return acc;
  }, {});

  useEffect(() => {
    if (addPhoto || addDocument) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [addPhoto, addDocument]);

  const onSubmitPhoto = async (data, updateId) => {
    const uniqueId = updateId ? updateId : uuidv4();
    !updateId
      ? await setAssetDataInIndexDB({
          assetId: uniqueId,
          assetFile: data.image
        })
      : !data?.image?.id
      ? await updateAssetFile({ assetId: updateId, assetFile: data.image })
      : '';

    let modifyData = {
      ...data,
      image: { id: uniqueId, name: data.image?.name, type: data.image?.type }
    };

    if (updateId) {
      const previousPhotosData = getValues(`${item.name}.photos`) || [];
      const findIndex = previousPhotosData?.findIndex((x) => x.id === updateId);
      previousPhotosData[findIndex] = { ...modifyData, id: updateId };
      setValue(`${item.name}.photos`, [...previousPhotosData], { shouldDirty: true });
    } else {
      let previousPhotoData = getValues(`${item.name}.photos`) || [];
      setValue(`${item.name}.photos`, [...previousPhotoData, { ...modifyData, id: uniqueId }], {
        shouldDirty: true
      });
    }
    setAddPhoto(false);
    setSelectedPhotoId(null);
    setUpdatePhoto(null);
  };

  const onSubmitDocument = async (data, documentAndFileId) => {
    if (!documentAndFileId?.id) {
      for (let doc of data) {
        await setAssetDataInIndexDB({
          assetId: doc?.file?.id,
          assetFile: doc?.file?.file
        });
      }
    } else {
      for (let doc of data) {
        if (doc?.file?.file instanceof File) {
          await updateAssetFile({
            assetId: documentAndFileId?.file?.id,
            assetFile: doc?.file?.file
          });
        }
      }
    }

    let modifyData = data?.map((doc) => {
      return {
        ...doc,
        file: {
          ...doc.file,
          id: documentAndFileId?.id ? documentAndFileId?.file?.file?.id : doc.file?.id,
          file: {
            name: doc.file?.file?.name,
            type: doc.file?.file?.type,
            id: documentAndFileId?.id ? documentAndFileId?.file?.file?.id : doc?.file?.id
          }
        }
      };
    });

    if (!_.isEmpty(documentAndFileId?.id)) {
      const previousDocumentData = getValues(`${item.name}.documents`) || [];
      const findIndexofDocument = previousDocumentData?.findIndex(
        (x) => x.id === documentAndFileId?.id
      );
      previousDocumentData[findIndexofDocument] = {
        id: documentAndFileId?.id,
        category: modifyData[0]?.category,
        file: modifyData[0]?.file
      };
      setValue(`${item.name}.documents`, [...previousDocumentData], { shouldDirty: true });
    } else {
      let previousDocumentData = getValues(`${item.name}.documents`) || [];
      const newDocumentData = [...previousDocumentData, ...modifyData];
      setValue(`${item.name}.documents`, newDocumentData, { shouldDirty: true });
    }
    setAddDocument(false);
    setSelectedDcumentAndFile(null);
  };

  const deleteBtnEvent = async (id, imageIdForIndexDB) => {
    return new Promise((resolve, reject) => {
      const handleAsyncFunction = async () => {
        try {
          await deleteAssetInIndexDB({ assetId: imageIdForIndexDB, deleteAll: true });
          if (!isFormIsNew) {
            updateTasksAssets({
              taskId: orderIdsLength > 1 ? currentTaskId : orderIds,
              assetId: id,
              workType,
              photo: true,
              formData: watch(),
              fieldName: item.name
            });
          }

          const previousPhotosData = getValues(`${item.name}.photos`) || [];
          const newPhotosList = previousPhotosData?.filter((x) => x.id !== id);
          setValue(`${item.name}.photos`, newPhotosList, { shouldDirty: true });
          setUpdatePhoto(null);
          resolve(true);
        } catch (error) {
          reject(error);
        }
      };

      handleAsyncFunction();
    });
  };
  const editBtnEvent = (id) => {
    const copyPhotos = getValues(`${item.name}.photos`) || [];
    const photo = copyPhotos?.find((p) => p.id === id);
    setSelectedPhotoId(id);
    setUpdatePhoto(photo);
    setAddPhoto(true);
  };

  const editDocumentEvent = (documentId, fileId) => {
    const copyDocument = getValues(`${item.name}.documents`) || [];
    const document = copyDocument?.find((doc) => doc.file.id === fileId);

    setSelectedDcumentAndFile({
      ...document
    });
    setSelectedDcumentId({
      documentId,
      fileId
    });
    setTimeout(() => {
      setAddDocument(true);
    }, 1000);
  };

  const deleteDocumentEvent = async (documentId, fileId) => {
    return new Promise((resolve, reject) => {
      const handleAsyncFunction = async () => {
        try {
          await deleteAssetInIndexDB({ assetId: fileId, deleteAll: true });

          if (!isFormIsNew) {
            updateTasksAssets({
              taskId: orderIdsLength > 1 ? currentTaskId : orderIds,
              assetId: documentId,
              workType,
              document: true,
              formData: watch(),
              fieldName: item.name
            });
          }

          const copy = getValues(`${item.name}.documents`) || [];
          const previousDocumentData = getValues(`${item.name}.documents`) || [];
          const newDocumentList = previousDocumentData?.find((x) => x.id === documentId);
          const files = newDocumentList?.files?.filter((y) => y?.id !== fileId);
          if (_.size(files) === 0) {
            const test = copy?.filter((x) => x.id !== documentId);
            setValue(`${item.name}.documents`, [...test], { shouldDirty: true });
          } else {
            const findIndexofDocument = copy?.findIndex((x) => x.id === documentId);
            previousDocumentData[findIndexofDocument] = {
              id: documentId,
              category: copy[findIndexofDocument]?.category,
              files: files
            };
            setValue(`${item.name}.documents`, previousDocumentData, { shouldDirty: true });
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      };

      handleAsyncFunction();
    });
  };

  const showPhotos =
    watch && watch(`${item.name}.photos`) && _.size(watch(`${item.name}.photos`)) > 0;

  useEffect(() => {
    // need loader to load the image
    const getPhotosList = async () => {
      let list = [];
      if (_.size(watch(`${item.name}.photos`)) > 0) {
        for (let photo of watch(`${item.name}.photos`)) {
          const base64 = await getAssetDataBase64InIndexDB({ assetId: photo?.image?.id });
          const image = { ...photo.image, base64 };
          list.push({ ...photo, image });
        }
        setPhotosListWithBase64(list);
      }
    };
    getPhotosList();
  }, [watch(`${item.name}.photos`)]);

  return (
    <div className={styles.containermain}>
      {!noHeader && (
        <div className={`${styles.titlebar}  ${item.checklist && styles.checklist}`}>
          <h3> Your attachments</h3>
        </div>
      )}

      <div className={styles.picklist}>
        <div className={styles.body}>
          {showPhotos && photosListWithBase64?.length > 0 && (
            <>
              <div className={styles.photoCard}>
                <h6>{'photos'}</h6>
                <div
                  className={styles.grid2}
                  style={{
                    margin: '0 auto',
                    marginTop: '0px',
                    display: width >= 768 ? 'flex' : 'block',
                    gap: '20px',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    flexWrap: width >= 768 ? 'wrap' : 'noWrap'
                  }}
                >
                  {photosListWithBase64?.map((card, index) => {
                    return (
                      <div key={index} style={{ width: width >= 768 ? '48%' : '100%' }}>
                        <PhotoComponent
                          key={index}
                          photoId={card?.id || index}
                          deleteBtnEvent={deleteBtnEvent}
                          editBtnEvent={editBtnEvent}
                          image={card?.image || null}
                          categoryValue={photoCategoryDataMap[card?.category]?.value || ''}
                          captionValue={card?.caption || ''}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {_.size(watch(`${item.name}.documents`)) > 0 && (
            <h6
              style={{
                marginBottom: '8px',
                fontWeight: '700',
                fontSize: '12px',
                letterSpacing: '1.5px',
                textTransform: 'uppercase',
                color: '#093269'
              }}
            >
              {'documents'}
            </h6>
          )}
          {_.size(watch(`${item.name}.documents`)) > 0 &&
            watch(`${item.name}.documents`)?.map((doc) => {
              return (
                <DocumentsCard
                  documentCategory={doc}
                  key={doc?.id}
                  file={doc.file}
                  picklistsMap={picklistsMap}
                  editDocument={editDocumentEvent}
                  deleteDocument={deleteDocumentEvent}
                />
              );
            })}
          <div className={styles.bodyBtns}>
            <button
              className={styles.addImage}
              onClick={() => {
                setAddPhoto(true);
                setSelectedPhotoId(null);
              }}
              type="button"
            >
              ADD PHOTO
            </button>
            <button
              className={styles.addDocument}
              onClick={() => {
                setAddDocument(true);
                setSelectedDcumentId(null);
                setSelectedDcumentAndFile({
                  document: null,
                  file: null
                });
              }}
              style={{ marginBottom: '0px' }}
              type="button"
            >
              ADD DOCUMENT
            </button>
          </div>
        </div>
      </div>

      {addPhoto && (
        <AddPhoto
          open={addPhoto}
          closed={(condition) => {
            setUpdatePhoto(null);
            setAddPhoto(condition);
          }}
          title={`${_.isEmpty(selectedPhotoId) ? 'Add' : 'Edit'} photo`}
          onSubmitPhoto={onSubmitPhoto}
          updatePhoto={updatePhoto}
          id={selectedPhotoId}
          deleteBtnEvent={deleteBtnEvent}
          initialValue={
            selectedPhotoId && !_.isEmpty(getValues(`${item.name}.photos`))
              ? getValues(`${item.name}.photos`)?.find((x) => x?.id === selectedPhotoId)
              : null
          }
        />
      )}
      {addDocument && (
        <AddDocument
          open={addDocument}
          closed={(condition) => {
            setAddDocument(condition);
          }}
          title={`${_.isEmpty(selectedDocumentId) ? 'Add' : 'Edit'} document`}
          onSubmitDocument={onSubmitDocument}
          id={selectedDocumentId}
          selectedDocumentAndFile={selectedDocumentAndFile}
          deleteBtnEvent={deleteDocumentEvent}
          initialValue={
            selectedDocumentId && !_.isEmpty(getValues(`${item.name}.documents`))
              ? getValues(`${item.name}.documents`)?.find((x) => x?.id === selectedDocumentId)
              : null
          }
        />
      )}
    </div>
  );
};

export default memo(Attachments);
