import moment from 'moment';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checkTypes: [],
  checkTypesMap: [],
  checksList: []
};

export const checkSlice = createSlice({
  name: 'check',
  initialState,
  reducers: {
    setCheckTypes: (state, action) => {
      state.checkTypes = action.payload;
      state.checkTypesMap = action.payload.reduce((acc, curr) => {
        acc[curr.key] = {
          key: curr.key,
          value: curr.value,
          frequencyId: curr?.frequencyId
        };
        return acc;
      }, {});
    },

    setChecksList: (state, action) => {
      let checkAll = [
        ...state.checksList.filter((t) => t.status === 'Pending sync' || t.status === 'In progress')
      ];

      (action.payload || []).map((task) => {
        const found = checkAll.findIndex((t) => t._id === task._id || t.id === task.id) + 1;
        if (found) {
          if (!['In progress', 'Pending sync'].includes(checkAll[found - 1].status)) {
            checkAll[found - 1] = { ...task };
          }
        } else {
          checkAll.push({ ...task });
        }
      });

      state.checksList = [...checkAll];
    },

    updateNewCheck: (state, action) => {
      const copyChecksList = [...state.checksList];
      const findIndex = copyChecksList?.findIndex((x) => x.id === action?.payload?.checkId);
      copyChecksList[findIndex] = { ...copyChecksList[findIndex], ...action?.payload?.data } || {};
      state.checksList = [...copyChecksList];
    },

    addNewCheck: (state, action) => {
      state.checksList = [...state.checksList, { ...action.payload }];
    },

    deleteCheck: (state, action) => {
      state.checksList = [
        ...state.checksList.filter((check) => ![check.id].includes(action.payload))
      ];
    },

    updateAssignedCheck: (state, action) => {
      let copy = [...state.checksList];
      state.checksList?.find((inspection, index) => {
        if (inspection.id === action.payload.id) {
          copy[index] = {
            ...copy[index],
            ...action?.payload,
            updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
          };
        }
      });

      state.checksList = [...copy];
    },

    setLocalStorageChecks: (state, action) => {
      state.checksList = [...state.checksList, ...action.payload];
    }
  }
});

const checkReducer = checkSlice.reducer;

export const checkActions = checkSlice.actions;
export default checkReducer;
