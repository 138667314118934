/* eslint-disable no-unused-vars */

import _ from 'lodash';
import moment from 'moment';
import { getDataPoints } from '../../pages/settings/helper';
import { getTaskAssignedUserTags } from '../tasks/helper';
import { getAllWorkOrdersAndInspections } from './all-work-order-inspection-for-site';
import { attachMeta } from './attach-meta';

export const getSitesInfoForSitesListingPage = async ({
  parentSite = [],
  id = '',
  withChild = true,
  templatesIds = '',
  getDataFromCache,
  formWrapper = false,
  store
}) => {
  const { tasks, cache, auth, settings, picklists } = store;
  const { workOrderUsersMap, workOrderUsersGroupsMap } = tasks;
  const siteDisplayFields = settings.settings?.['siteDisplayFields'] || '';
  const { picklistsMap = {} } = picklists;
  const {
    equipmentsMap = {},
    sitesEquipmentsMap = {},
    equipments: cacheEquipments = [],
    sites: cacheSites = []
  } = cache;

  let { assignedTasksList: allTasksList, hseEvents } = !formWrapper
    ? await getAllWorkOrdersAndInspections({
        templatesIds,
        getDataFromCache,
        store
      })
    : {
        assignedTasksList: []
      };
  return new Promise((resolve) => {
    const sitesList =
      _.size(parentSite) > 0
        ? parentSite
        : !_.isEmpty(id)
        ? cacheSites?.filter((x) => x.siteId === id)
        : cacheSites;

    let prepareSites = [];

    const responsibleUsersMap = {
      ...(workOrderUsersMap || {}),
      ...(workOrderUsersGroupsMap || {})
    };

    const userId = auth.userId || '';
    for (let site of sitesList) {
      const { children = [] } = site;
      const parentSiteChildren = children?.map((childIndex) => {
        return { ...cacheSites[childIndex], children: [] };
      });

      let childrenData = [];
      if (site?.children?.length && !formWrapper) {
        childrenData = attachMeta(
          parentSiteChildren,
          sitesEquipmentsMap,
          equipmentsMap,
          allTasksList,
          cacheEquipments
        );
      }

      let allTasksForSite = [
        ...allTasksList.filter(
          (t) =>
            t?.siteId === site?.siteId ||
            t?.relatedAssetId === site.siteId ||
            t?.primaryAssetId === site.siteId ||
            t?.sites?.includes(site?.siteId)
        ),
        ...(withChild ? childrenData?.map((t) => t.allTasksForSite).flat(2) || [] : [])
      ];

      let hseEventsOfChild = hseEvents?.filter((t) => t?.siteId === site?.siteId);

      allTasksForSite = allTasksForSite?.map((tas) => {
        const { responsibleUsers = [], responsibleUserGroups = [] } = tas;
        const customAssignedTaskValue = getTaskAssignedUserTags({
          responsibleUsers,
          responsibleUserGroups,
          workOrderUsersGroupsMap,
          userId,
          responsibleUsersMap
        });

        return {
          customAssignedTaskValue,
          ...tas
        };
      });

      const allDoneTasks = allTasksForSite?.filter((task) => task?.status === 'Synced');
      const allotDoneTasks = allTasksForSite?.filter((task) => task?.status !== 'Synced');

      const today = moment().format('YYYY-MM-DD');
      const isOverDue = allotDoneTasks?.find((task) =>
        task.dueDate ? moment(task.dueDate).isBefore(today) : false
      );

      const dataPoints = getDataPoints(
        { ...site, type: site?.siteType },
        siteDisplayFields,
        'site',
        picklistsMap
      );

      prepareSites.push({
        ...site,
        status: !formWrapper ? site?.status : null,
        equipmentCount: !formWrapper ? sitesEquipmentsMap[site.siteId]?.length : 0,
        dataPoints,
        allDoneTasks: !getDataFromCache ? allDoneTasks : Array(allDoneTasks?.length).fill(1),
        isOverDue: !getDataFromCache ? isOverDue : Array(isOverDue?.length).fill(1),
        allTasksForSite: !getDataFromCache
          ? allTasksForSite
          : Array(allTasksForSite?.length).fill(1),
        allotDoneTasks: !getDataFromCache ? allotDoneTasks : Array(allotDoneTasks?.length).fill(1),
        hseEvents: hseEventsOfChild,
        soonestDueDate: _.min(
          allotDoneTasks.map((t) => t?.due || t?.dueDate).map((date) => new Date(date))
        )
      });
    }

    return resolve(prepareSites);
  });
};
