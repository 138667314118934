import Icon from '@components/icon-component';

const moreInfoDropdownList = ({ setMoreInfo, setOpen, setOpenModal, link }) => {
  return [
    {
      icon: <Icon name={'more-info'} />,
      text: 'More information',
      onClick: () => {
        setMoreInfo(true);
        setOpen(false);
      }
    },
    {
      icon: <Icon name={'bookmark-grey'} />,
      text: 'Bookmark',
      onClick: () => {
        setOpenModal(true);
        setOpen(false);
      }
    },
    {
      icon: <Icon name={'open-in-oplii-desktop'} />,
      text: 'Open in Oplii desktop',
      onClick: () => {
        window.open(link, '_blank');
      }
    }
  ];
};

const tabsItemMap = ({ isLoading, tasks, children, equipments, structures, pipelines }) => {
  if (!isLoading)
    return [
      ...(tasks?.length > 0
        ? [
            {
              label: `Tasks (${tasks?.length})`,
              keyName: 'tasks'
            }
          ]
        : []),
      ...(children?.length > 0
        ? [
            {
              label: `Child sites (${children?.length})`,
              keyName: 'site'
            }
          ]
        : []),

      ...(equipments.length > 0
        ? [
            {
              label: `Equipment (${equipments.length})`,
              keyName: 'equipment'
            }
          ]
        : []),

      ...(structures?.length > 0
        ? [
            {
              label: `Structure (${structures?.length})`,
              keyName: 'structure'
            }
          ]
        : []),

      ...(pipelines?.length > 0
        ? [
            {
              label: `Pipeline (${pipelines?.length})`,
              keyName: 'pipeline'
            }
          ]
        : [])
    ];
  else return [];
};

export { moreInfoDropdownList, tabsItemMap };
