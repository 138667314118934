/* eslint-disable no-unused-vars */

import React, { useCallback, useMemo, memo, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import RefreshModal from '@components/modal/variants/refresh-data-modal';
import UpdateAppModal from '@components/modal/variants/update-app-modal';

import { getAppDispatch } from '@utilities/dispatch.util';
import { cacheActions } from '@redux/slices/cache';
import { settingsActions } from '@redux/slices/settings';
import SettingsService from '@services/settings.service';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
import CancelAndRetryModal from '@components/modal/variants/cancel-and-retry';
import AuthService from '@services/auth.service';
import { homeActions } from '@redux/slices/home';

const RefreshingUpdatingModals = ({
  isCaching = false,
  isCachingComplete = false,
  isCachingFailure = false,
  isSyncingModal,
  initialLogin
}) => {
  const [openCancelAndRetryModal, setOpenCancelAndRetryModal] = useState(false);
  const [titleCount, setTitleCount] = useState(0);

  const dispatch = getAppDispatch?.();
  const {
    isAppUpdatable = false,
    isAppUpdating = false,
    isUpdatingComplete = false,
    isUpdateFailed = false,
    movingTasksAssetsReduxToIndexDBInprogress = false
  } = useSelector((state) => state.settings);
  const location = useLocation();
  const navigate = useNavigate();
  const setOpenModalEvent = useCallback(() => {
    isCachingComplete && dispatch?.(cacheActions.updateRefreshingAndSyncingData(false));
    isCachingComplete && dispatch?.(globalProgressBarAction.setLoadStatus(0));
    dispatch?.(cacheActions.closeCachingComplete());
    navigate(location.pathname, { state: { initialLogin: false } });
    setOpenCancelAndRetryModal(false);
  }, [isCachingComplete, isCachingFailure, dispatch]);

  const openUpdateAllModal = useMemo(() => {
    return (
      (isAppUpdatable || isAppUpdating || isUpdatingComplete || isUpdateFailed) &&
      !isCaching &&
      !isCachingComplete &&
      !isCachingFailure
    );
  }, [
    isAppUpdatable,
    isAppUpdating,
    isUpdatingComplete,
    isUpdateFailed,
    isCaching,
    isCachingComplete,
    isCachingFailure
  ]);
  const setOpenUpdateAppModalEvent = useCallback(
    (openState, reload) => {
      if (!openState && reload) localStorage.setItem('getNewDataAfterUpdate', true);

      dispatch?.(
        settingsActions.setAppUpdateStatus({
          isAppUpdatable: false,
          isAppUpdating: false,
          isUpdatingComplete: false,
          isUpdateFailed: false
        })
      );

      if (reload)
        setTimeout(() => {
          window.location.reload();
        }, 3000);
    },
    [dispatch, isAppUpdatable]
  );

  const cancelForGettingDataEvent = useCallback(() => {
    window.stop();
    dispatch?.(cacheActions.failureCaching());
    dispatch?.(cacheActions.updateRefreshingAndSyncingData(false));
  }, [dispatch]);

  const cancelAndRetryEvent = () => {
    setOpenCancelAndRetryModal(true);
  };
  const logoutUserAndResetDataEvent = () => {
    window.stop();
    isCachingComplete && dispatch?.(cacheActions.updateRefreshingAndSyncingData(false));
    isCachingComplete && dispatch?.(globalProgressBarAction.setLoadStatus(0));
    dispatch?.(homeActions.setInitialCache(false));
    dispatch?.(cacheActions.closeCachingComplete());
    dispatch?.(cacheActions.failureCaching());
    AuthService.logout({ clearCache: false });
    setOpenCancelAndRetryModal(false);
  };
  return (
    <>
      {isCaching && (
        <RefreshModal
          open={isCaching}
          refreshingData
          setOpen={() => {}}
          cancelForGettingDataEvent={cancelForGettingDataEvent}
          initialLogin={initialLogin}
          cancelAndRetryEvent={cancelAndRetryEvent}
          titleCount={titleCount}
          setTitleCount={setTitleCount}
        />
      )}

      {(isCachingComplete || isCachingFailure) && (
        <RefreshModal
          open={isCachingComplete || isCachingFailure}
          refreshComplete={isCachingComplete}
          refreshFailed={isCachingFailure}
          setOpen={setOpenModalEvent}
          titleCount={titleCount}
          setTitleCount={setTitleCount}
        />
      )}
      {!isSyncingModal && (
        <UpdateAppModal
          open={openUpdateAllModal}
          setOpen={setOpenUpdateAppModalEvent}
          updateHandler={async () => {
            await SettingsService.updateApp();
          }}
          updateApp={isAppUpdatable}
          updatingApp={isAppUpdating}
          updateComplete={isUpdatingComplete}
          updateFailed={isUpdateFailed}
          movingTasksAssetsReduxToIndexDBInprogress={movingTasksAssetsReduxToIndexDBInprogress}
        />
      )}

      {openCancelAndRetryModal && !isCachingComplete && !isCachingFailure && (
        <CancelAndRetryModal
          open={openCancelAndRetryModal}
          setOpen={setOpenCancelAndRetryModal}
          logoutHandler={logoutUserAndResetDataEvent}
        />
      )}
    </>
  );
};

export default memo(RefreshingUpdatingModals);
