/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { store } from 'redux/store';
import { getAppDispatch } from './dispatch.util';
import { tasksListsActions } from '@redux/slices/tasks';
import { WorkTypes } from '@components/work-order/helper';
import { getPhotosAndDocumentsDataFromSelectedInspection } from '@services/assets.service';
import { convertToSerializable } from '@components/work-order/services/serializable-non-serializable';
import { inspectionActions } from '@redux/slices/inspection';
import { checkActions } from '@redux/slices/checks';
import { hseEventActions } from '@redux/slices/hse-events';

const updateTasksAssets = async ({
  assetId,
  tasksType,
  taskId,
  photo = true,
  document = false,
  workType,
  formData = null,
  fieldName
}) => {
  const dispatch = getAppDispatch();

  if (
    workType === WorkTypes.NewWorkRequest ||
    workType === WorkTypes.NewWorkOrder ||
    workType === WorkTypes.WorkOrders
  ) {
    const tasksList = store.getState().tasks?.tasksList;
    let currentTask =
      workType === WorkTypes.WorkOrders
        ? tasksList?.find((task) => task?.workOrderNumber === taskId)
        : tasksList?.find((task) => task?.woId === taskId);
    if (currentTask && currentTask?.status !== 'To do') {
      if (currentTask?.workOrderNumber && currentTask?.status !== 'To do') {
        if (fieldName === 'photos' || fieldName === 'documents') {
          let photos = currentTask.photos;
          let documents = currentTask.documents;
          photos = photo ? photos?.filter((photo) => photo.id !== assetId) : photos;
          documents = document
            ? documents?.filter((document) => document.id !== assetId)
            : documents;
          dispatch?.(
            tasksListsActions.updateWorkOrder(
              {
                ...currentTask,
                photos,
                documents
              } || {}
            )
          );
        } else {
          let currentWorkOrderFormData = Object.assign({}, currentTask?.formData);
          const updatedFormData = findAndRemovePhotoOrDocumentFromFormData({
            data: currentWorkOrderFormData,
            photoId: photo ? assetId : null,
            documentId: document ? assetId : null,
            objectKey: fieldName
          });

          dispatch?.(
            tasksListsActions.updateWorkOrder(
              {
                ...currentTask,
                formData: updatedFormData
              } || {}
            )
          );
        }
      } else {
        let photos = currentTask.photos;
        let documents = currentTask.documents;
        photos = photo ? photos?.filter((photo) => photo.id !== assetId) : photos;
        documents = document ? documents?.filter((document) => document.id !== assetId) : documents;

        dispatch?.(
          tasksListsActions.updateNewWorkOrder({
            id: currentTask?.woId,
            data: { ...currentTask, photos, documents }
          })
        );
      }
    }
  } else if (workType === WorkTypes.NewInspection || workType === WorkTypes.Inspection) {
    const inspectionsList = store.getState().inspection?.inspectionsList;
    let currentInspection = inspectionsList?.find((ins) => ins?.id === taskId);

    if (currentInspection && currentInspection?.status !== 'To do') {
      if (currentInspection?._id && currentInspection?.status !== 'To do') {
        let currentInspectionFormData = Object.assign({}, currentInspection?.formData);
        const updatedFormData = findAndRemovePhotoOrDocumentFromFormData({
          data: currentInspectionFormData,
          photoId: photo ? assetId : null,
          documentId: document ? assetId : null,
          objectKey: fieldName
        });

        dispatch?.(
          inspectionActions.updateInspection(
            {
              ...currentInspection,
              formData: updatedFormData
            } || {}
          )
        );
      } else {
        let currentInspectionFormData = Object.assign({}, currentInspection?.formData);
        const updatedFormData = findAndRemovePhotoOrDocumentFromFormData({
          data: currentInspectionFormData,
          photoId: photo ? assetId : null,
          documentId: document ? assetId : null,
          objectKey: fieldName
        });

        dispatch?.(
          inspectionActions.updateNewInspection({
            inspectionId: taskId,
            data: {
              ...currentInspection,
              formData: updatedFormData
            }
          })
        );
      }
    }
  } else if (workType === WorkTypes.NewCheck || workType === WorkTypes.Check) {
    const checksList = store.getState().checks?.checksList;
    let currentCheckList = checksList?.find((check) => check?.id === taskId);
    if (currentCheckList && currentCheckList?.status !== 'To do') {
      if (currentCheckList?._id && currentCheckList?.status !== 'To do') {
        let currentCheckListFormData = Object.assign({}, currentCheckList?.formData);
        const updatedFormData = findAndRemovePhotoOrDocumentFromFormData({
          data: currentCheckListFormData,
          photoId: photo ? assetId : null,
          documentId: document ? assetId : null,
          objectKey: fieldName
        });

        dispatch?.(
          checkActions.updateAssignedCheck(
            {
              ...currentCheckList,
              formData: updatedFormData
            } || {}
          )
        );
      } else {
        let currentCheckListFormData = Object.assign({}, currentCheckList?.formData);
        const updatedFormData = findAndRemovePhotoOrDocumentFromFormData({
          data: currentCheckListFormData,
          photoId: photo ? assetId : null,
          documentId: document ? assetId : null,
          objectKey: fieldName
        });
        dispatch?.(
          checkActions.updateNewCheck({
            checkId: taskId,
            data: {
              ...currentCheckList,
              formData: updatedFormData
            }
          })
        );
      }
    }
  } else if (workType === WorkTypes.NewHSE) {
    const HSEEventList = store.getState().hseEvents?.HSEEventList;
    let currentHSEEventList = HSEEventList?.find((hse) => hse?.id === taskId);
    if (currentHSEEventList) {
      let currentHSEEventFormData = Object.assign({}, currentHSEEventList?.formData);
      const updatedFormData = findAndRemovePhotoOrDocumentFromFormData({
        data: currentHSEEventFormData,
        photoId: photo ? assetId : null,
        documentId: document ? assetId : null,
        objectKey: fieldName
      });

      dispatch?.(
        hseEventActions.updateNewHSEEvent({
          hseEventId: taskId,
          data: {
            ...currentHSEEventList,
            formData: updatedFormData
          }
        })
      );
    }
  } else if (
    workType === WorkTypes.InspectionAndWorkOrder ||
    workType === WorkTypes.WorkOrderAndInspection
  ) {
    const tasksList = store.getState().tasks?.tasksList;
    let currentTask = tasksList?.find((task) => task?.workOrderNumber === taskId);
    if (currentTask) {
      if (currentTask?.workOrderNumber && currentTask?.status !== 'To do') {
        if (fieldName === 'photos' || fieldName === 'documents') {
          let photos = currentTask.photos;
          let documents = currentTask.documents;
          photos = photo ? photos?.filter((photo) => photo.id !== assetId) : photos;
          documents = document
            ? documents?.filter((document) => document.id !== assetId)
            : documents;
          dispatch?.(
            tasksListsActions.updateWorkOrder(
              {
                ...currentTask,
                photos,
                documents
              } || {}
            )
          );
        } else {
          let currentWorkOrderFormData = Object.assign({}, currentTask?.formData);
          const updatedFormData = findAndRemovePhotoOrDocumentFromFormData({
            data: currentWorkOrderFormData,
            photoId: photo ? assetId : null,
            documentId: document ? assetId : null,
            objectKey: fieldName
          });

          dispatch?.(
            tasksListsActions.updateWorkOrder(
              {
                ...currentTask,
                formData: updatedFormData
              } || {}
            )
          );
        }
      }
    } else {
      const inspectionsList = store.getState().inspection?.inspectionsList;
      let currentInspection = inspectionsList?.find((ins) => ins?.id === taskId);
      if (currentInspection) {
        if (currentInspection?._id && currentInspection?.status !== 'To do') {
          let currentInspectionFormData = Object.assign({}, currentInspection?.formData);
          const updatedFormData = findAndRemovePhotoOrDocumentFromFormData({
            data: currentInspectionFormData,
            photoId: photo ? assetId : null,
            documentId: document ? assetId : null,
            objectKey: fieldName
          });

          dispatch?.(
            inspectionActions.updateInspection(
              {
                ...currentInspection,
                formData: updatedFormData
              } || {}
            )
          );
        }
      }
    }
  }
  return;
};

export default updateTasksAssets;

const findAndRemovePhotoOrDocumentFromFormData = ({ data, photoId, documentId, objectKey }) => {
  let newData = _.cloneDeep(data);

  const o = objectKey.split('.');

  let p = o.pop();
  const k = o.join('.');
  if (photoId) newData[k][p].photos = newData[k][p].photos.filter((j) => j.id !== photoId);

  if (documentId)
    newData[k][p].documents = newData[k][p].documents.filter((j) => j.id !== documentId);

  return newData;
};
