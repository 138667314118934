/* eslint-disable no-unused-vars */
import { store } from 'redux/store';
import _ from 'lodash';

import siteAutoPopulate from './site-auto-populate';
import equipmentAutoPopulate from './equipment-auto-populate';
import relatedPsvAndPvAutoPopulate from './related-psv-pv-auto-populate';
import structureAutoPopulate from './structure-auto-populate';
import pipelineAutoPopulate from './pipeline-auto-populate';
import multipleSiteAutoPopulate from './multiple-site-auto-populate';
import { decodeToken } from '@utilities/authentication';

export const getSiteAutoPopulate = ({
  siteId = null,
  equipmentId = null,
  pipelineId = null,
  structureId = null,
  psvsList = [],
  inspectionType = null,
  frequencyId = null
}) => {
  try {
    return new Promise((resolve) => {
      const { picklists, cache, jurisdictionNumbers, structure, pvPsv, pipeline, inspection } =
        store.getState();
      const { areaHierarchyTreeDataMap = {} } = picklists;
      const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;
      const { structureMobileMap = {}, structureMobile = [] } = structure;
      const { pvPsvMap = {} } = pvPsv;
      const { pipelineMobileMap = {}, pipelineMobile = [] } = pipeline;
      const { inspectionLastDate, inspectionTypesMap } = inspection;

      const {
        sitesMap = {},
        equipmentsMap = {},
        equipments: cacheEquipments = [],
        sites: cacheSites = []
      } = cache;
      const intervalId = frequencyId || inspectionTypesMap[inspectionType]?.frequencyId || '';

      const site = cache?.sites?.find((site) => site?.siteId === siteId);
      const equipment = cache?.equipments?.find((equ) => equ?.equipmentId === equipmentId);
      const checkSheetStructureData =
        (structureId && structureMobile[structureMobileMap[structureId]]) || {};

      const { jurisdictionNumber, jurisdiction } =
        jurisdictionNumbersMap[equipment?.equipmentId] || {};
      const structureData = structureMobile[structureMobileMap[equipment?.structureId]] || {};
      const { pv = [], psv = [] } = pvPsvMap[equipmentId] || {};
      const psvs = psv
        .filter((id) => cacheEquipments[equipmentsMap[id]])
        ?.map((x) => cacheEquipments[equipmentsMap[x]]);

      const pipeLineData = pipelineMobile[pipelineMobileMap[pipelineId]] || {};
      const pipelineSiteIds = [
        ...(pipeLineData?.sites || []),
        ...(pipeLineData?.wells || []),
        pipeLineData?.fromSite
      ];

      const object = {
        // appliesToType
        Site: {
          ...siteAutoPopulate({
            intervalId,
            firstName: decodeToken().firstName,
            lastName: decodeToken().lastName,
            site,
            areaHierarchyTreeDataMap,
            inspectionLastDate: inspectionLastDate?.siteLastInspectionDateWithIds || []
          })
        },
        // appliesToType
        Equipment: {
          ...equipmentAutoPopulate({
            intervalId,
            firstName: decodeToken().firstName,
            lastName: decodeToken().lastName,
            equipment,
            jurisdictionNumber,
            jurisdiction,
            inspectionLastDate: inspectionLastDate?.equipmentLastInspectionDateWithIds || []
          }),

          ...relatedPsvAndPvAutoPopulate({
            psv: psvs?.length > 0 && psvs[0],
            pv: psvs?.length > 0 && psvs[0],
            jurisdictionNumbersMap
          }),

          ...siteAutoPopulate({
            intervalId,
            site: cacheSites[sitesMap[equipment?.siteId]],
            areaHierarchyTreeDataMap,
            parent: true,
            inspectionLastDate: inspectionLastDate?.siteLastInspectionDateWithIds || []
          }),
          ...structureAutoPopulate({
            intervalId,
            structure: structureData,
            parent: true
          })
        },
        Structure: {
          ...structureAutoPopulate({
            intervalId,
            firstName: decodeToken().firstName,
            lastName: decodeToken().lastName,
            structure: checkSheetStructureData
          })
        },
        // appliesToType
        Pipeline: {
          // sourceEntity
          ...pipelineAutoPopulate({
            intervalId,
            firstName: decodeToken().firstName,
            lastName: decodeToken().lastName,
            pipeline: pipeLineData,
            inspectionLastDate: inspectionLastDate?.pipelineInspectionDateWithIds || []
          }),

          Site: {
            ...multipleSiteAutoPopulate({
              siteIds: [...pipelineSiteIds],
              cacheSites,
              sitesMap,
              areaHierarchyTreeDataMap,
              inspectionLastDate: inspectionLastDate?.siteLastInspectionDateWithIds || []
            })
          }
        },
        PSVS: {
          data: convertPSVsDataToFormWrapperData({ psvs: psvsList })
        }
      };
      return resolve(object);
    });
  } catch (e) {
    console.log({ e });
  }
};

const convertPSVsDataToFormWrapperData = ({ psvs = [] }) => {
  return psvs?.map((psv) => {
    return {
      name: psv?.name || '',
      equipmentId: psv?.equipmentId,
      verificationStatus: psv?.verificationStatus || 'verify',
      isDetached: psv?.isDetached || false,
      isReattach: psv?.isReattach || false,
      isNew: psv?.isNew || false,

      category: psv?.category || '',
      type: psv?.type || '',
      subType: psv?.subType || '',

      equipmentCategory: psv?.equipmentCategory,
      equipmentType: psv?.equipmentType,
      equipmentSubType: psv?.equipmentSubType,

      serialNumber: psv?.serialNumber || '',
      equipmentNumber: psv?.equipmentNumber,
      status: psv?.status,
      psvCategoryTypeSubType: psv?.psvCategoryTypeSubType || '',
      valveLocation: psv?.valveLocation || '',
      pressure: {
        textBoxValue: psv?.pressure || null,
        textBoxUnitValue: psv?.pressureMeasurement || ''
      },
      manufacturer: psv?.manufacturer || '',
      model: psv?.model,
      fieldTagNumber: psv?.fieldTagNumber,
      crnNumber: psv?.crnNumber || '',
      psvCapacity: {
        textBoxValue: psv?.psvCapacity || null,
        textBoxUnitValue: psv?.psvCapacityMeasurement || ''
      },
      inletSize: {
        textBoxValue: psv?.inletSize || null,
        textBoxUnitValue: psv?.inletSizeMeasurement || ''
      },
      inletJointStyle: psv?.flangeInletJointStyle || '',
      outletSize: {
        textBoxValue: psv?.outletSize || null,
        textBoxUnitValue: psv?.outletSizeMeasurement || ''
      },
      outletJointStyle: psv?.flangeOutletJointStyle || '',
      lastServiceDate: psv?.lastServiceDate || '',
      comments: psv?.comments || '',
      expand: !psv?.isNew,
      dataPoints: psv?.dataPoints || []
    };
  });
};
