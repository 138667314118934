import icons from '@assets/icons';

const Icon = ({ height, width, name, className, onClick, ...restOfProps }) => {
  return (
    <img
      onClick={onClick}
      src={icons[name]}
      height={height}
      width={width}
      style={{ cursor: 'pointer' }}
      className={className}
      {...restOfProps}
    />
  );
};

export default Icon;
