/* eslint-disable no-unused-vars */

import React, { memo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { store } from 'redux/store';

import PermissionRestrict from 'hoc/permission-restrict';
import AppBar from '@components/app-bar';
import ButtonStatus from '@components/button-status/button-status.component';
import Icon from '@components/icon-component';
import LoadingRound from '@components/loading-round/loading-round';

import { getAppDispatch } from '@utilities/dispatch.util';
import { filterActions } from '@redux/slices/filters';
import { PERMISSIONS } from 'permissions/constants';
import { decodeToken } from '@utilities/authentication';
import { initWebWorker } from 'webworker';

import './task-type.scss';
import style from './style.module.scss';

const TaskType = () => {
  const navigate = useNavigate();
  const [view, setView] = useState('');
  const [allTasksData, setAllTasksData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() },
        permissions: decodeToken()?.permissions || {}
      },
      type: 'get-task-type-records'
    })
      .then((data) => {
        setAllTasksData(data?.result);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, []);

  const {
    allWOTasks = [],
    allDoneWOTasks = [],
    allInspectionTasks = [],
    allDoneInspectionTasks = [],
    allCheckTasks,
    allDoneCheckTasks = [],
    allTasks = [],
    allDoneTasks = [],
    isOverDue = false,
    isWOOverDue = false,
    IsInspectionOverDue = false,
    IsCheckOverDue = false
  } = allTasksData;

  const dispatch = getAppDispatch?.();

  const fallback = () => {
    return <></>;
  };

  const pageHeader = () => {
    return (
      <div
        className="op-page-name op-listing-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative"
        style={{
          position: 'sticky',
          top: 0
        }}
      >
        <Link className="op-back " to={view ? '' : -1} onClick={() => view && setView('')}></Link>
        <h1 className="h1-semibold pl-16">Select {view ? 'view' : 'task type'}</h1>
        <div></div>
      </div>
    );
  };

  const permissions = decodeToken()?.permissions || {};

  const availableTaskType = [
    {
      title: 'All Tasks',
      permissions:
        PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS || PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS,
      icon: 'task-grey',
      onClick: () => navigate('/task-records'),
      allTasks: allTasks,
      doneTasks: allDoneTasks,
      chipText: `${allDoneTasks?.length}/${allTasks?.length}`,
      isOverDue: isOverDue,
      dot: !!isOverDue && allDoneTasks?.length !== allTasks?.length
    },
    ...(permissions[PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS]
      ? [
          {
            title: 'Inspections',
            permissions: PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS,
            icon: 'ins',
            onClick: () => setView('Inspection'),
            allTasks: allInspectionTasks,
            doneTasks: allDoneInspectionTasks,
            chipText: `${allDoneInspectionTasks?.length}/${allInspectionTasks?.length}`,
            isOverDue: IsInspectionOverDue,
            dot:
              !!IsInspectionOverDue && allDoneInspectionTasks?.length !== allInspectionTasks?.length
          }
        ]
      : []),
    ...(permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS]
      ? [
          {
            title: 'Work Orders',
            permissions: PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS,
            icon: 'work-orders',
            onClick: () => setView('Work Order'),
            allTasks: allWOTasks,
            doneTasks: allDoneWOTasks,
            chipText: `${allDoneWOTasks?.length}/${allWOTasks?.length}`,
            isOverDue: isWOOverDue,
            dot: !!isWOOverDue && allDoneWOTasks?.length !== allWOTasks?.length
          }
        ]
      : []),
    ...(permissions[PERMISSIONS.MOBILE_ONDEMAND_CHECKS]
      ? [
          {
            title: 'Checks',
            permissions: PERMISSIONS.MOBILE_ONDEMAND_CHECKS,
            icon: 'check-grey',
            onClick: () => setView('Check'),
            allTasks: allCheckTasks,
            doneTasks: allDoneCheckTasks,
            chipText: `${allDoneCheckTasks?.length}/${allCheckTasks?.length}`,
            isOverDue: IsCheckOverDue,
            dot: !!IsCheckOverDue && allDoneCheckTasks?.length !== allCheckTasks?.length
          }
        ]
      : [])
  ];

  const clearAllFilterState = () => {
    dispatch?.(filterActions.setTaskFilters(null));
    dispatch?.(filterActions.setSitesFilters(null));
    dispatch?.(filterActions.setEquipmentsFilters(null));
  };

  return (
    <div className="page-container">
      <AppBar />
      <div className="md:ml-24">
        <div className="relative overflow-x-hidden">
          {pageHeader()}

          {isLoading ? (
            <div style={{ backgroundColor: 'transparent', width: '100%' }}>
              <LoadingRound className={style.loaderHeight} />
            </div>
          ) : (
            <div className="page-listing">
              {!view ? (
                <>
                  {availableTaskType
                    ?.filter((x) => x?.allTasks?.length > 0)
                    ?.map((ele, index) => {
                      return ele.title === 'All Tasks' && availableTaskType?.length === 2 ? (
                        <></>
                      ) : (
                        <PermissionRestrict key={index} roles={ele.permissions} fallback={fallback}>
                          <div className="task-type link-card" onClick={ele.onClick}>
                            <div className="task-type--left">
                              <Icon name={ele.icon} />
                              <div className="task-type--text">{ele.title}</div>
                            </div>
                            <div className="task-type--right">
                              <ButtonStatus
                                classes={`btn-status btn1 ${
                                  ele.doneTasks?.length === 0 && 'btn-overdue'
                                } ${
                                  ele.doneTasks?.length > 0 &&
                                  ele.allTasks?.length > ele.doneTasks?.length &&
                                  'btn-progress'
                                }  ${
                                  ele.doneTasks?.length === ele.allTasks?.length && 'btn-done'
                                } `}
                                Text={ele.chipText}
                                dot={ele.dot}
                              />
                              {/* </div> */}
                              <Icon name={'chevron-right'} width="10px" />
                            </div>
                          </div>
                        </PermissionRestrict>
                      );
                    })}
                </>
              ) : (
                <>
                  <div
                    className="task-type link-card"
                    onClick={() => {
                      clearAllFilterState();
                      navigate(
                        `/task-records?type=${view}&filterType=${
                          view === 'Inspection'
                            ? 'inspection'
                            : view === 'Check'
                            ? 'check'
                            : 'workOrder'
                        }`
                      );
                    }}
                  >
                    <div className="task-type--left">
                      <div className="task-type--text">View all {view.toLowerCase()}s</div>
                    </div>
                    <div className="task-type--right">
                      <Icon name={'chevron-right'} width="10px" />
                    </div>
                  </div>
                  <div
                    className="task-type link-card"
                    onClick={() => {
                      clearAllFilterState();
                      navigate(
                        `/sites?type=${view}&filterType=${
                          view === 'Inspection'
                            ? 'inspection'
                            : view === 'Check'
                            ? 'check'
                            : 'workOrder'
                        }`
                      );
                    }}
                  >
                    <div className="task-type--left">
                      <div className="task-type--text">View by site</div>
                    </div>
                    <div className="task-type--right">
                      <Icon name={'chevron-right'} width="10px" />
                    </div>
                  </div>
                  {['Inspection', 'Work Order'].includes(view) && (
                    <div
                      className="task-type link-card"
                      onClick={() => {
                        clearAllFilterState();
                        navigate(
                          `/${
                            view === 'Inspection' ? 'inspection-run' : 'recurring-template'
                          }?type=${view}`
                        );
                      }}
                    >
                      <div className="task-type--left">
                        <div className="task-type--text">
                          View by {view === 'Inspection' ? 'inspection run' : 'recurring template'}
                        </div>
                      </div>
                      <div className="task-type--right">
                        <Icon name={'chevron-right'} width="10px" />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(TaskType);
