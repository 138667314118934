/* eslint-disable no-unused-vars */

import { store } from 'redux/store';

import { updateApp } from 'serviceWorkerRegistration';
import http from './http.service';
import { whenOptions, distanceOptions, typeOptions, displayFields } from '@pages/settings/helper';
import Promisable from './promisable.service';
import { settingsActions } from '@redux/slices/settings';
import { getAppDispatch } from '@utilities/dispatch.util';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
import LocalStorage from '@utilities/local-storage.util';

const SettingsService = {
  updateApp: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(
      settingsActions.setAppUpdateStatus({
        isAppUpdatable: false,
        isAppUpdating: true,
        isUpdatingComplete: false,
        isUpdateFailed: false
      })
    );

    try {
      const {
        data: { applicationVersion }
      } = await http.get(`/ApplicationVersion`);

      localStorage.setItem('applicationVersion', applicationVersion);

      await LocalStorage.getLocalStorageDataAndSetDataToLocalForage();

      await updateApp(); // TODO: issue here
      dispatch?.(settingsActions.setAppVersionNumber(applicationVersion));
      dispatch?.(
        settingsActions.setAppUpdateStatus({ isAppUpdating: false, isUpdatingComplete: true })
      );
    } catch (error) {
      dispatch?.(
        settingsActions.setAppUpdateStatus({ isAppUpdating: false, isUpdateFailed: true })
      );
    }
  },

  getAndSetAppVersionNumber: async () => {
    const [success, error] = await Promisable.asPromise(http.get(`/ApplicationVersion`));
    return [success, error];
  },

  checkUpdate: async () => {
    const dispatch = getAppDispatch();
    const cachingFailure = localStorage.getItem('cachingFailure');
    if (!JSON.parse(cachingFailure)) {
      const [version] = await SettingsService.getAndSetAppVersionNumber();
      const {
        data: { applicationVersion }
      } = version;
      if (localStorage.getItem('applicationVersion')) {
        if (applicationVersion != (localStorage.getItem('applicationVersion') || 0)) {
          dispatch?.(settingsActions.setAppUpdateStatus({ isAppUpdatable: true }));
          dispatch?.(
            settingsActions.setAppUpdateStatusForWorkOrder({
              movingTasksAssetsReduxToIndexDBCompleted: false
            })
          );
        }
      } else {
        dispatch?.(settingsActions.setAppUpdateStatus({ isAppUpdatable: false }));
        dispatch?.(
          settingsActions.setAppUpdateStatusForWorkOrder({
            movingTasksAssetsReduxToIndexDBCompleted: true
          })
        );
        localStorage.setItem('applicationVersion', applicationVersion);
      }
      dispatch?.(globalProgressBarAction.setLoadStatus(3));
    } else throw new Error('Api failed to update');
  },

  getSettings: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(settingsActions.setLoading(true));
    const cachingFailure = localStorage.getItem('cachingFailure');
    if (!JSON.parse(cachingFailure)) {
      http.setJWT();
      const [success, error] = await Promisable.asPromise(http.get(`/UserMobileSettings`));

      if (success) {
        const { data } = success;
        const settings = Object.keys(data).reduce((acc, curr) => {
          let value = data[curr];
          value = curr.endsWith('DisplayFields') ? (value ? value.join(',') : '') : value;
          acc[curr] = value;
          return acc;
        }, data);
        dispatch?.(settingsActions.setSettings(settings));
      }

      dispatch?.(settingsActions.setLoading(false));
      dispatch?.(globalProgressBarAction.setLoadStatus(12));
      return [success, error];
    }
  },

  updateSettings: async (settings = {}) => {
    const dispatch = getAppDispatch();
    dispatch?.(settingsActions.setLoading(true));

    http.setJWT();
    const res = await Promise.all(
      Object.keys(settings).map((key) => {
        let value = settings[key];
        if (Array.isArray(value)) value = value.join(',');
        else if (key.endsWith('DisplayFields')) {
          const fields = displayFields[key.replace('DisplayFields', '')];
          const addFieldsMap = fields.reduce((acc, curr) => {
            if (curr.IsSortable) acc[curr.Id] = curr;
            return acc;
          }, {});
          value = [
            ...fields.filter((el) => !el.IsSortable && !el.AlignBottom).map(({ Id }) => Id),
            ...value.split(',').filter((el) => addFieldsMap[el]?.IsSortable),
            ...fields.filter((el) => !el.IsSortable && el.AlignBottom).map(({ Id }) => Id)
          ].join(',');
        }

        return Promisable.asPromise(
          http.patch(`/UserMobileSettings/${key}`, { value: { op: 'Replace', value } })
        );
      })
    );

    dispatch?.(settingsActions.setLoading(false));

    const status = res.filter(([success]) => success).length === res.length;
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([status, !status]);
      }, 2000);
    });
  },

  reEvaluateSettings: (settings) => {
    let newSettings = {};
    const dispatch = getAppDispatch();
    const digitsRegex = /^([0-9]{0,4})+$/;
    const typeOptionsMap = typeOptions.reduce((acc, curr) => {
      acc[curr.value] = true;
      return acc;
    }, {});
    const whenOptionsMap = whenOptions.reduce((acc, curr) => {
      acc[curr.value] = true;
      return acc;
    }, {});
    const distanceOptionsMap = distanceOptions.reduce((acc, curr) => {
      acc[curr.value] = true;
      return acc;
    }, {});

    ['distanceUnit'].forEach((setting) => {
      if (!distanceOptionsMap[settings[setting]]) newSettings[setting] = distanceOptions[1].value;
    });

    ['syncReminderTimeframe'].forEach((setting) => {
      if (!typeOptionsMap[settings[setting]]) newSettings[setting] = typeOptions[1].value;
    });

    [
      'autoConnectUpdate',
      'autoConnectBackup',
      'autoConnectWOPhoto',
      'autoConnectWODocument',
      'autoConnectPromptSyncSchedule',
      'autoConnectPromptSyncConnection'
    ].forEach((setting) => {
      if (!whenOptionsMap[settings[setting]]) newSettings[setting] = whenOptions[1].value;
    });

    [
      'maxFileSize'
      // 'checkDownload',
      // 'syncReminderNum'
      // 'workOrderDownload',
      // 'runInspectionDownload',
      // 'keepSyncedTasksThreshold'
    ].forEach((setting) => {
      const inputValue = settings[setting];
      const number = Number(inputValue);

      if (inputValue && (!digitsRegex.test(inputValue) || number < 1 || number > 90))
        newSettings[setting] = '1';
    });

    if (Object.keys(newSettings).length > 0)
      dispatch?.(settingsActions.setSettings({ newSettings }));
  }
};

export default SettingsService;
