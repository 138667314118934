/* eslint-disable no-unused-vars */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import style from './separator.module.scss';

const Separator = ({ text }) => {
  return <div className={style.separator}>{text}</div>;
};

Separator.propTypes = {
  text: PropTypes.string
};

export default memo(Separator);
