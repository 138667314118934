/* eslint-disable no-unused-vars */

import moment from 'moment';
import _ from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  syncedInspectionCache: [],
  syncedInspectionCacheMap: {},
  inspectionCache: [],
  inspectionCacheMap: {},
  inspectionTypes: [],
  inspectionTypesMap: {},
  inspectionFormsUserCache: [],
  inspectionFormsUserCacheMap: {},
  loading: false,
  inspectionsList: [],
  inspectionFrequency: [],
  inspectionInspectorUserGroups: [],
  inspectionInspectorUserGroupsMap: {},
  inspectionInspectorUsers: [],
  inspectionInspectorUsersMap: {},

  pipelineCathodicProtection: [],
  pipelineAssignedBatchingInterval: [],
  pipelineAssignedPiggingInterval: [],
  pipelineStatus: [],
  externalProtection: [],
  internalProtection: [],
  material: [],
  operationsArea: [],
  pipelineReceiverType: [],
  pipelineSenderType: [],
  substance: [],
  equipmentConnectionType: [],
  pipelineType: [],
  province: [],
  siteStatus: [],
  codeOfConstruction: [],
  areaHierarchy: [],

  HSEProactiveEffortType: [],
  HSEFocusAreas: [],
  HSEVendors: [],
  functionalGroup: [],
  species: [],
  wildlifeIncidentType: [],
  incidentStatus: [],

  equipmentCategoryTypeSubtype: [],
  equipmentCategoryTypeAndSubTypeMap: {},
  service: [],
  equipmentStatus: [],
  pSVType: [],
  equipmentValveLocation: [],

  capacity: [],
  concentrationUnits: [],
  largeMeasurement: [],
  smallMeasurement: [],
  pressure: [],
  temperature: [],
  timeUnit: [],
  volume: [],
  failureCriteria: [],
  pipelineArea: [],

  passFailList: [],
  passDeficiencyFail: [],
  equipmentCategory: [],
  psvCategoryTypeAndSubType: [],
  jurisdictionNumbers: [],
  flowRate: [],
  condition: [],
  siteType: [],
  cylinderType: [],
  structureType: [],
  gaugeBoardUOM: [],
  equipmentInsulationMaterial: [],
  incidentCategory: [],
  incidentCategoryMap: {},
  constructionMaterial: [],
  inspectionLastDate: {
    site: [],
    equipment: [],
    structure: [],
    pipeline: []
  },
  inspectionRunMap: {}
};

export const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    setInspectionInspectorUserGroups: (state, action) => {
      state.inspectionInspectorUserGroups = action.payload;
      state.inspectionInspectorUserGroupsMap = action.payload.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
    setInspectionInspectorUsers: (state, action) => {
      state.inspectionInspectorUsers = action.payload;
      state.inspectionInspectorUsersMap = action.payload.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
    setSyncedInspectionCache: (state, action) => {
      state.syncedInspectionCache = action.payload;
      state.syncedInspectionCacheMap = action.payload.reduce((acc, curr) => {
        acc[curr.detailInspectionId] = curr;
        return acc;
      }, {});
    },
    setInspectionList: (state, action) => {
      let inspectionAll = [
        ...state.inspectionsList.filter(
          (t) => t.status === 'Pending sync' || t.status === 'In progress'
        )
      ];

      (action.payload || []).map((task) => {
        const found = inspectionAll.findIndex((t) => t.id === task.id || t._id === task._id) + 1;
        if (found) {
          if (!['In progress', 'Pending sync'].includes(inspectionAll[found - 1].status)) {
            inspectionAll[found - 1] = { ...task };
          }
        } else {
          inspectionAll.push({ ...task });
        }
      });
      const inspectionRunMap = {};

      inspectionAll?.map((inspection) => {
        const { runId, runNumber, RunName, inspectableItemId, inspectionNumber } = inspection;
        if (runId && !inspectionRunMap[runId]) {
          const allInspections = inspectionAll?.filter((x) => x.runId === runId) || [];
          const allDoneInspections = allInspections?.filter((x) => x?.status === 'Synced') || [];
          const allotDoneInspections = allInspections?.filter((x) => x?.status !== 'Synced') || [];

          const today = moment().format('YYYY-MM-DD');
          const isOverDue = allotDoneInspections?.find((ins) =>
            ins?.dueDate ? moment(ins.dueDate).isBefore(today) : false
          );

          inspectionRunMap[runId] = {
            runId,
            title: RunName,
            id: runNumber,
            allInspections: _.size(allInspections),
            allDoneInspections: _.size(allDoneInspections),
            allotDoneInspections: _.size(allotDoneInspections),
            isOverDue: isOverDue
          };
        }
      });

      state.inspectionsList = [...inspectionAll];
      state.inspectionRunMap = inspectionRunMap;
    },
    setInspectionTypes: (state, action) => {
      state.inspectionTypes = action.payload;
      state.inspectionTypesMap = action.payload.reduce((acc, curr) => {
        acc[curr.key] = {
          key: curr.key,
          value: curr.value,
          frequencyId: curr?.frequencyId
        };
        return acc;
      }, {});
    },
    setInspectionFormsUserCache: (state, action) => {
      state.inspectionFormsUserCache = action.payload;
      state.inspectionFormsUserCacheMap = action.payload.reduce((acc, curr) => {
        acc[curr.inspectionFormId] = {
          quickComplete: curr?.quickComplete,
          failureCriteria: curr?.failureCriteria,
          name: curr?.name
        };
        return acc;
      }, {});
    },

    setInspectionFormMenuDropdown: (state, action) => {
      state.pipelineCathodicProtection = sortAndReturnValue({
        list: action?.payload?.pipelineCathodicProtection
      });
      state.inspectionLastDate = assetLastInspectionDateMap({
        inspectionLastDate: action.payload?.inspectionLastDate
      });
      state.flowRate = sortAndReturnValue({
        list: action?.payload?.flowRate
      });
      state.condition = sortAndReturnValue({
        list: action?.payload?.condition
      });
      state.siteType = sortAndReturnValue({
        list: action?.payload?.siteType
      });
      state.structureType = sortAndReturnValue({
        list: action?.payload?.structureType
      });
      state.gaugeBoardUOM = sortAndReturnValue({
        list: action?.payload?.gaugeBoardUOM
      });
      state.equipmentInsulationMaterial = sortAndReturnValue({
        list: action?.payload?.equipmentInsulationMaterial
      });
      state.incidentCategory = sortAndReturnValue({
        list: action?.payload?.incidentCategory
      });
      state.incidentCategoryMap = state.incidentCategory.reduce((acc, curr) => {
        acc[curr?.label] = curr;
        return acc;
      }, {});
      state.cylinderType = sortAndReturnValue({
        list: action?.payload?.cylinderType
      });
      state.constructionMaterial = sortAndReturnValue({
        list: action?.payload?.constructionMaterial
      });
      state.jurisdictionNumbers = sortAndReturnValue({
        list: action?.payload?.jurisdictionNumbers
      });
      state.pipelineAssignedBatchingInterval = sortAndReturnValue({
        list: action?.payload?.pipelineAssignedBatchingInterval
      });
      state.pipelineAssignedPiggingInterval = sortAndReturnValue({
        list: action?.payload?.pipelineAssignedPiggingInterval
      });
      state.externalProtection = sortAndReturnValue({
        list: action?.payload?.externalProtection
      });
      state.internalProtection = sortAndReturnValue({
        list: action?.payload?.internalProtection
      });
      state.material = sortAndReturnValue({
        list: action?.payload?.material
      });
      state.operationsArea = sortAndReturnValue({
        list: action?.payload?.operationsArea
      });
      state.pipelineReceiverType = sortAndReturnValue({
        list: action?.payload?.pipelineReceiverType
      });
      state.pipelineSenderType = sortAndReturnValue({
        list: action?.payload?.pipelineSenderType
      });
      state.pipelineType = sortAndReturnValue({
        list: action?.payload?.pipelineType
      });

      state.equipmentValveLocation = sortAndReturnValue({
        list: action?.payload?.equipmentValveLocation
      });
      state.capacity = sortAndReturnValue({
        list: action?.payload?.capacity
      });
      state.concentrationUnits = sortAndReturnValue({
        list: action?.payload?.concentrationUnits
      });
      state.largeMeasurement = sortAndReturnValue({
        list: action?.payload?.largeMeasurement
      });
      state.timeUnit = sortAndReturnValue({
        list: action?.payload?.timeUnit
      });
      state.failureCriteria = sortAndReturnValue({
        list: action?.payload?.failureCriteria
      });
      state.pipelineArea = sortAndReturnValue({
        list: action?.payload?.pipelineArea
      });
      state.timeUnit = sortAndReturnValue({
        list: action?.payload?.timeUnit
      });

      state.HSEProactiveEffortType = sortAndReturnValue({
        list: action?.payload?.HSEProactiveEffortType
      });
      state.HSEFocusAreas = sortAndReturnValue({
        list: action?.payload?.HSEFocusAreas
      });
      state.HSEVendors = sortAndReturnValue({
        list: action?.payload?.HSEVendors
      });
      state.functionalGroup = sortAndReturnValue({
        list: action?.payload?.functionalGroup
      });
      state.species = sortAndReturnValue({
        list: action?.payload?.species
      });
      state.wildlifeIncidentType = sortAndReturnValue({
        list: action?.payload?.wildlifeIncidentType
      });
      state.incidentStatus = sortAndReturnValue({
        list: action?.payload?.incidentStatus
      });

      state.passFailList = action?.payload?.passFailList?.map((x) => {
        return {
          label: x?.value || '',
          value: x?.key || '',
          lookup: x?.lookup || ''
        };
      });
      state.passDeficiencyFail = action?.payload?.passDeficiencyFail?.map((x) => {
        return {
          label: x?.value || '',
          value: x?.key || '',
          lookup: x?.lookup || ''
        };
      });
    },

    setEquipmentCategoryTypeAndSubTypeDropdown: (state, action) => {
      let equipmentCategoryTypeAndSubType = createNestedArrayForAreaHierarchy(
        action?.payload,
        null,
        true
      );

      equipmentCategoryTypeAndSubType = showValueWithParents(equipmentCategoryTypeAndSubType, null);
      let equipmentCategoryTypeAndSubTypeMap = flattenArray(equipmentCategoryTypeAndSubType, []);
      equipmentCategoryTypeAndSubTypeMap = equipmentCategoryTypeAndSubTypeMap?.reduce(
        (acc, curr) => {
          acc[curr.key] = curr.showValue;
          return acc;
        },
        {}
      );
      state.equipmentCategoryTypeSubtype = equipmentCategoryTypeAndSubType;
      state.equipmentCategoryTypeAndSubTypeMap = equipmentCategoryTypeAndSubTypeMap;
    },

    setInspectionFormMenuDropdownAsPicklists: (state, action) => {
      const { dropdownMap = {} } = action.payload;
      Object.keys(dropdownMap)?.map((key) => {
        if (key === 'areaHierarchy') {
          const list = createNestedArrayForAreaHierarchy(dropdownMap[key], null);
          state[key] = [...list];
        } else {
          const list = dropdownMap[key]
            ?.slice()
            ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
            .map((x) => {
              return {
                label: x?.value || '',
                value: x?.key || ''
              };
            });
          state[key] = [...list];
          if (key === 'equipmentCategory') {
            state.psvCategoryTypeAndSubType = (dropdownMap['equipmentCategory'] || [])
              ?.filter((item) => item?.allowedForPSVCreation)
              .map((item) => {
                return {
                  label: item?.value || '',
                  value: item?.key || ''
                };
              });
          }
        }
      });
    },

    updateInspection: (state, action) => {
      let copy = [...state.inspectionsList];
      state.inspectionsList?.find((inspection, index) => {
        if (inspection.id === action.payload.id) {
          copy[index] = {
            ...copy[index],
            ...action?.payload,
            updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
          };
        }
      });

      state.inspectionsList = [...copy];
    },

    addNewInspection: (state, action) => {
      state.inspectionsList = [...state.inspectionsList, { ...action.payload }];
    },
    updateNewInspection: (state, action) => {
      const copyInspectionList = [...state.inspectionsList];
      const findIndex = copyInspectionList?.findIndex(
        (x) => x.id === action?.payload?.inspectionId
      );
      copyInspectionList[findIndex] =
        { ...copyInspectionList[findIndex], ...action?.payload?.data } || {};
      state.inspectionsList = [...copyInspectionList];
    },
    deleteInspection: (state, action) => {
      state.inspectionsList = [
        ...state.inspectionsList.filter((ins) => ins.id !== action?.payload)
      ];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLocalStorageInspections: (state, action) => {
      state.inspectionsList = [...state.inspectionsList, ...action.payload];
    }
  }
});

const inspectionReducer = inspectionSlice.reducer;

export const inspectionActions = inspectionSlice.actions;
export default inspectionReducer;

const sortAndReturnValue = ({ list = [] }) => {
  return list
    ?.slice()
    ?.sort((a, b) => a?.sortOrder - b?.sortOrder)
    .map((x) => {
      return {
        label: x?.value || '',
        value: x?.key || ''
      };
    });
};

const createNestedArrayForAreaHierarchy = (arr, parentKey = null) => {
  const result = [];

  for (const item of arr) {
    const itemParentKey = item.parentKey || null;
    if (itemParentKey === parentKey) {
      const newItem = { ...item };
      const children = createNestedArrayForAreaHierarchy(arr, item.key);
      newItem.child = children?.length ? children : [];
      result.push(newItem);
    }
  }

  return result?.sort((a, b) => a?.sortOrder - b?.sortOrder);
};

const showValueWithParents = (data, parentName = '') => {
  const result = [];
  for (const item of data) {
    const currentShowValue = parentName ? `${parentName} / ${item.value}` : item.value;
    const newItem = { ...item, showValue: currentShowValue };
    result.push(newItem);

    if (item?.child?.length > 0) newItem.child = showValueWithParents(item.child, currentShowValue);
  }
  return result;
};

const assetLastInspectionDateMap = ({ inspectionLastDate }) => {
  let site = {},
    equipment = {},
    pipeline = {},
    siteLastInspectionDateWithIds = {},
    equipmentLastInspectionDateWithIds = {},
    pipelineInspectionDateWithIds = {};

  for (let item of inspectionLastDate) {
    if (item?.assetType === 'S' && item?.inspectionDate) {
      if (!site[item.assetId])
        site = {
          ...site,
          [item.assetId]: [
            {
              assetId: item.assetId,
              inspectionDate: item.inspectionDate
            }
          ]
        };
      else
        site = {
          ...site,
          [item.assetId]: [
            ...site[item.assetId],
            { assetId: item.assetId, inspectionDate: item.inspectionDate }
          ]
        };
    } else if (item?.assetId === 'E' && item?.inspectionDate) {
      if (!equipment[item.assetId])
        equipment = {
          ...equipment,
          [item.assetId]: [
            {
              assetId: item.assetId,
              inspectionDate: item.inspectionDate
            }
          ]
        };
      else
        equipment = {
          ...equipment,
          [item.assetId]: [
            ...equipment[item.assetId],
            { assetId: item.assetId, inspectionDate: item.inspectionDate }
          ]
        };
    } else if (item?.assetId === 'P' && item?.inspectionDate) {
      if (item.assetType === 'S' && item?.inspectionDate) {
        if (!pipeline[item.assetId])
          pipeline = {
            ...pipeline,
            [item.assetId]: [
              {
                assetId: item.assetId,
                inspectionDate: item.inspectionDate
              }
            ]
          };
        else
          pipeline = {
            ...pipeline,
            [item.assetId]: [
              ...pipeline[item.assetId],
              { assetId: item.assetId, inspectionDate: item.inspectionDate }
            ]
          };
      }
    }
  }

  for (let key in site)
    siteLastInspectionDateWithIds = {
      ...siteLastInspectionDateWithIds,
      [key]:
        (site[key]?.sort((a, b) => new Date(b?.inspectionDate) - new Date(a?.inspectionDate)) ||
          [])[0] || null
    };

  for (let key in equipment)
    equipmentLastInspectionDateWithIds = {
      ...equipmentLastInspectionDateWithIds,
      [key]:
        (equipment[key]?.sort(
          (a, b) => new Date(b?.inspectionDate) - new Date(a?.inspectionDate)
        ) || [])[0] || null
    };

  for (let key in pipeline)
    pipelineInspectionDateWithIds = {
      ...pipelineInspectionDateWithIds,
      [key]:
        (pipeline[key]?.sort((a, b) => new Date(b?.inspectionDate) - new Date(a?.inspectionDate)) ||
          [])[0] || null
    };

  return {
    siteLastInspectionDateWithIds,
    equipmentLastInspectionDateWithIds,
    pipelineInspectionDateWithIds
  };
};

const flattenArray = (arr, result = []) => {
  arr.forEach((item) => {
    const { child, ...rest } = item;
    result && result.push(rest);
    if (child && child.length > 0) {
      flattenArray(child, result);
    }
  });
  return result;
};
