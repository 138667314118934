/* eslint-disable no-unused-vars */

import React, { memo, useCallback } from 'react';
import Checkbox from '@components/checkbox/checkbox.component';

const FormCheckbox = ({ field, count, value, name, setValue, label }) => {
  const handleChangeEvent = useCallback(
    (e) => {
      setValue(name, e?.target?.checked, { shouldDirty: true });
    },
    [field]
  );
  return (
    <Checkbox
      bigCheckbox
      text={label ? label : `${count}. ${field?.label}`}
      count={label ? undefined : count}
      checked={value}
      handleChange={handleChangeEvent}
      formWrapper
    />
  );
};

export default memo(FormCheckbox);
