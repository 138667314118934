import BookmarksList from './bookmarks-list';
import PageContainer from '@components/page-container';

const Bookmarks = () => {
  return (
    <PageContainer>
      <BookmarksList />
    </PageContainer>
  );
};

export default Bookmarks;
