/* eslint-disable no-unused-vars */
import _ from 'lodash';

import { getDataPoints } from '../../pages/settings/helper';
import { getTaskCardUsers, TYPE_ICONS, getTaskAssignedUserTags } from '../tasks/helper';

import { getTaskSiteAsset, filterSiteAssetBasedOnMocHseStructureNcrPipeline } from '../global/site';
import {
  getTaskEquipmentAsset,
  filterEquipmentAssetBasedOnMocHseStructureNcrPipeline
} from '../global/equipment';
import { getTaskPipelineAsset, getPipelineAsset } from '../global/pipeline';
import { getTaskCardFooter } from '../global/footer';

const InspectionSelector = {
  getInspections: ({
    showDataPoints = true,
    id = '',
    removeConfigurableFieldFromAsset = false,
    appliesTo = false,
    newInspection = false,
    siteId = null,
    equipmentId = null,
    showAttachedPSVs = false,
    formWrapper = false,
    store
  }) => {
    return new Promise((resolve) => {
      const {
        cache,
        settings,
        picklists,
        jurisdictionNumbers,
        auth,
        pipeline,
        structure,
        inspection = {}
      } = store;
      const userId = auth.userId || '';
      const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = picklists;
      const {
        equipmentsMap,
        sitesMap = {},
        equipmentsWithoutMobileMap = {},
        equipments: cacheEquipments = [],
        sites: cacheSites = []
      } = cache;
      const {
        equipmentDisplayFields,
        siteDisplayFields,
        workorderDisplayFields,
        inspectionDisplayFields,
        pipelineDisplayFields
      } = settings.settings;
      const { jurisdictionNumbersMap } = jurisdictionNumbers;
      const { pipelineMobileMap = {}, pipelineMobile = [] } = pipeline;
      const { structureMobileMap = {}, structureMobile = [] } = structure;

      let prepareInspections = [];
      let {
        inspectionsList = [],
        inspectionTypesMap = {},
        inspectionInspectorUserGroupsMap = {},
        inspectionInspectorUsersMap = {}
      } = inspection;

      if (siteId) {
        inspectionsList = inspectionsList?.filter(
          (t) =>
            t?.siteId === siteId ||
            t?.relatedAssetId === siteId ||
            t?.primaryAssetId === siteId ||
            t?.sites?.includes(siteId)
        );
      } else if (equipmentId) {
        inspectionsList = inspectionsList?.filter(
          (t) =>
            t?.equipmentId === equipmentId ||
            t?.relatedAssetId === equipmentId ||
            t?.primaryAssetId === equipmentId ||
            t?.equipments?.includes(equipmentId)
        );
      }

      if (id) {
        if (newInspection) inspectionsList = inspectionsList?.filter((t) => t?.id === id);
        else inspectionsList = inspectionsList?.filter((t) => t?.inspectableItemId === id);
      }

      inspectionsList?.map((inspection) => {
        const users = getTaskCardUsers({
          responsibleUserGroups: inspection?.responsibleUserGroups || [],
          responsibleUsers: inspection?.responsibleUsers || [],
          workOrderUsersMap: inspectionInspectorUsersMap || {},
          workOrderUsersGroupsMap: inspectionInspectorUserGroupsMap || {},
          primaryUserId: inspection?.primaryUserId || null
        });

        const dataPoints = showDataPoints
          ? getDataPoints(
              {
                inspectors: users,
                interval:
                  inspection?.frequencyId ||
                  inspection?.intervalId ||
                  inspection?.previousFrequencyId ||
                  null
              },
              inspectionDisplayFields,
              'inspection',
              picklistsMap
            )
          : [];

        const taskHeaderIconName = TYPE_ICONS[inspection.type] || '';
        const inspectionCardHeaderTitle =
          getInspectionHeaderTitle({
            status: inspection?.status || null,
            id: inspection?.inspectionNumber || null
          }) || '';

        const siteAsset = getTaskSiteAsset({
          sitesMap,
          siteId: inspection?.siteId || null,
          sites: inspection?.sites || [],
          areaHierarchyTreeDataMap,
          siteDisplayFields,
          picklistsMap,
          removeConfigurableFieldFromAsset,
          cacheSites,
          appliesTo,
          formWrapper,
          store
        });

        const equipmentAsset = getTaskEquipmentAsset({
          equipmentsWithoutMobileMap,
          equipmentsMap,
          cacheEquipments,
          equipments: inspection?.equipments || [],
          equipmentId: inspection?.equipmentId || null,
          picklistsMap,
          equipmentDisplayFields,
          jurisdictionNumbersMap,
          sitesMap,
          siteDisplayFields,
          areaHierarchyTreeDataMap,
          removeConfigurableFieldFromAsset,
          cacheSites,
          appliesTo,
          showAttachedPSVs,
          formWrapper,
          store
        });
        const pipelineAsset =
          (inspection?.appliesToType === 'pipeline' || inspection?.pipelineId) &&
          getTaskPipelineAsset({
            sitesMap,
            siteDisplayFields,
            cacheSites,
            areaHierarchyTreeDataMap,
            picklistsMap,
            appliesTo,
            task: inspection,
            pipelineMobileMap,
            pipelineMobile,
            pipelineDisplayFields,
            formWrapper,
            store
          });

        const hseData = {};
        const mocData = {};
        const structureData = {};
        const ncrData = {};

        const pipelineData =
          inspection?.appliesToType === 'pipeline'
            ? getAppliesToTypeData({
                equipmentsWithoutMobileMap,
                task: inspection,
                equipmentsMap,
                cacheEquipments,
                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,
                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                pipelineDisplayFields
              })
            : inspection?.pipelineId
            ? getPipelineAsset({
                equipmentsWithoutMobileMap,
                task: inspection,
                equipmentsMap,
                cacheEquipments,

                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,

                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                pipelineDisplayFields
              })
            : {};

        const sitesAssetFilterData = filterSiteAssetBasedOnMocHseStructureNcrPipeline({
          mocSiteId: (mocData && mocData?.siteAsset && mocData?.siteAsset?.siteId) || '',
          hseSiteId: (hseData && hseData?.siteAsset && hseData?.siteAsset?.siteId) || '',
          structureSiteId: '',
          ncrSiteId: (ncrData && ncrData?.siteAsset && ncrData?.siteAsset?.siteId) || '',
          pipelineSiteId:
            (pipelineData && pipelineData?.siteAsset && pipelineData?.siteAsset?.siteId) || '',
          siteAsset
        });

        const equipmentAssetFilterData = filterEquipmentAssetBasedOnMocHseStructureNcrPipeline({
          mocEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          hseEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          structureEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          ncrEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          pipelineEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          equipmentAsset
        });

        const taskFooter = getTaskCardFooter({
          status: inspection?.status || null,
          sync: inspection?.sync || null,
          dueDate: inspection?.dueDate || null,
          startDate: inspection?.startDate || null,
          due: inspection?.due || null,
          syncedByText: inspection.syncedByText || null,
          syncedDate: inspection.syncedDate || null,
          syncedBy: inspection.syncedBy || null,
          updatedAt: inspection.updatedAt || null,
          syncDateCustom: inspection?.syncDateCustom || null,
          userId
        });
        const responsibleUsersMap = {
          ...(inspectionInspectorUsersMap || {}),
          ...(inspectionInspectorUserGroupsMap || {})
        };
        const customAssignedTaskValue = getTaskAssignedUserTags({
          responsibleUsers: inspection?.responsibleUsers || [],
          responsibleUserGroups: inspection?.responsibleUserGroups || [],
          workOrderUsersGroupsMap: inspectionInspectorUserGroupsMap,
          userId: userId,
          responsibleUsersMap
        });

        prepareInspections.push({
          // header start
          taskHeaderIconName,
          taskCardHeaderTitle: inspectionCardHeaderTitle,
          link: inspection?.link || null,
          // header end

          // body start
          inspectionType: inspectionTypesMap[inspection?.inspectionTypeId]?.value || null,
          description: inspection?.description || null,
          dataPoints,
          rtInterval: inspection?.rtInterval || null,
          // body end

          equipmentAsset,
          siteAsset,
          sitesAssetFilterData,
          equipmentAssetFilterData,
          hseData,
          mocData,
          structureData,
          ncrData,
          pipelineData,
          customAssignedTaskValue,
          runData: { id: inspection?.runNumber || null, title: inspection.RunName || null },
          taskFooter,
          users,
          checked: false,
          pipelineAsset,
          ...inspection
        });
      });
      resolve(prepareInspections);
    });
  }
};

export default InspectionSelector;

const getInspectionHeaderTitle = ({ status, id }) => {
  const inspectionCardHeaderTitle = status === 'Synced' ? id : 'Inspection';
  return inspectionCardHeaderTitle;
};

const getAppliesToTypeData = ({
  task,
  pipelineMobileMap,
  areaHierarchyTreeDataMap,
  pipelineDisplayFields,
  picklistsMap,
  pipelineMobile
}) => {
  if (task?.pipelines.length) {
    const taskPipelines = task?.pipelines || [];
    const pipelinesIds = [...taskPipelines] || [];

    const pipeLineDataMap = pipelinesIds?.map((pipelineId) => {
      const pipeLineData = pipelineMobile[pipelineMobileMap[pipelineId]] || {};
      const dataPoints = getDataPoints(
        { ...pipeLineData, area: areaHierarchyTreeDataMap[pipeLineData?.area]?.title },
        pipelineDisplayFields,
        'pipeline',
        picklistsMap
      );

      return {
        fromLocation: pipeLineData.fromLocation || '',
        toLocation: pipeLineData.toLocation || '',
        licenseNumber: pipeLineData.licenseNumber || '',
        lineNumber: pipeLineData?.lineNumber || '',

        dataPoints,
        pipelineId: pipelineId
      };
    });
    return {
      pipelineAsset: pipeLineDataMap
    };
  }
};
