import _ from 'lodash';
import { getFilterEquipment } from '../../components/equipment-listing/helper';

const equipmentBookmarkCount = ({
  responsibleUsersMap,
  responsibleUsersGroupsMap,
  filters,
  sort,
  userId,
  areaHierarchyTreeDataMap,
  siteDisplayFields,
  picklistsMap,
  structureId,
  sitesMap,
  equipments,
  jurisdictionNumbersMap,
  equipmentDisplayFields,
  cacheSites,
  siteId,
  parentEquipmentId,
  store
}) => {
  const responsibleUsersMap1 = {
    ...(responsibleUsersMap || {}),
    ...(responsibleUsersGroupsMap || {})
  };

  const { filteredEquipment = [] } = getFilterEquipment(
    // { ...filters?.filters },
    // sitesMap,
    // equipments,
    // Object.keys(sort)[0],
    // userId,
    // responsibleUsersMap1,
    // jurisdictionNumbersMap,
    // picklistsMap,
    // equipmentDisplayFields,
    // areaHierarchyTreeDataMap,
    // siteDisplayFields,
    // cacheSites,
    // store
    {
      filters: { ...filters?.filters },
      sitesMap,
      equipments,
      SelectedFilter: Object.keys(sort)[0],
      userId,
      responsibleUsersMap: responsibleUsersMap1,
      jurisdictionNumbersMap,
      picklistsMap,
      equipmentDisplayFields,
      areaHierarchyTreeDataMap,
      siteDisplayFields,
      cacheSites,
      store
    }
  );
  const countEquipments =
    filteredEquipment?.filter((t) => {
      if (siteId) return t?.siteId === siteId;
      else if (parentEquipmentId) return t?.parentId === parentEquipmentId;
      else if (structureId) return t?.structureId === structureId;
      else return true;
    }) || filteredEquipment;

  return {
    count: countEquipments.length || 0,
    filters: encodeURIComponent(JSON.stringify(filters))
  };
};

const getEquipmentTodoAndInprogressTasks = ({
  equipmentId = '',
  tasksList = [],
  equipmentsMap = {},
  sitesMap = {},
  cacheEquipments = [],
  cacheSites = []
}) => {
  tasksList = tasksList.filter((task) => !task.newWorkOrder && !task.newWorkRequest && !task.isNew);

  const parentEquipment = cacheEquipments[equipmentsMap[equipmentId]] || {};

  const children = [...(parentEquipment?.children || [])].sort(
    (a, b) =>
      (cacheSites[sitesMap[a?.siteId]]?.distance != null
        ? cacheSites[sitesMap[a?.siteId]]?.distance
        : Infinity) -
        (cacheSites[sitesMap[b?.siteId]]?.distance != null
          ? cacheSites[sitesMap[b?.siteId]]?.distance
          : Infinity) || a.name.localeCompare(b.name)
  );

  const allTasksForSite =
    _.flattenDeep([
      tasksList.filter(
        (t) =>
          t?.equipmentId === equipmentId ||
          t?.equipments?.includes(equipmentId) ||
          t?.relatedAssetId === equipmentId
      ),
      ...(children
        ?.map((t) => t.allTasksForSite)
        .flat(2)
        .filter((y) => y) || [])
    ]) || [];

  const countTodoAndInProgressTasksOfSite = _.size(
    allTasksForSite.filter((task) => task?.status === 'To do' || task.status === 'In progress') ||
      []
  );

  return countTodoAndInProgressTasksOfSite;
};

export { equipmentBookmarkCount, getEquipmentTodoAndInprogressTasks };
