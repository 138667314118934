/* eslint-disable no-unused-vars */

import moment from 'moment';
import _ from 'lodash';

import { removeUnavailableTreeData } from '@components/tree-component';
import { getStructureData } from '@utilities/structure-search';

import { getDataPoints } from '@pages/settings/helper';

export const getFilterStructure = ({
  filters,
  sitesMap,
  structures,
  SelectedFilter,
  jurisdictionNumbersMap,
  picklistsMap,
  structureDisplayFields,
  cacheSites,
  siteIdFor,
  store
}) => {
  const {
    area,
    proximity,
    siteType,
    siteStatus,
    searched = '',
    structureStatus,
    structureType,
    taskType,
    taskStatus,
    priority,
    workOrderPriority,
    assign,
    scheduledDate,
    download,
    dueDate: filterDueDate,
    recurringTemplate,
    workOrderType = [],
    createdAt,
    syncedDate: syncedDateFilter,
    checkRecurrancePriority,
    inspectionType
  } = filters;

  const sitesSiteTypeData = {},
    sitesSiteStatusData = {},
    structuresStructureStatusData = {},
    structuresStructureTypeData = {},
    sitesAreaHierarchyTreeData = {},
    sitesWorkOrderPriorityDataMap = {};

  const selectedWorkOrderRecurringTemplateMap =
    recurringTemplate &&
    recurringTemplate.value &&
    recurringTemplate.value.reduce((acc, curr) => {
      const rtName = (curr && curr.split('-') && curr.split('-').at(-1)).trim();
      acc[rtName] = true;
      return acc;
    }, {});

  const inspectionTypeMap = inspectionType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedRecurrancePriority = checkRecurrancePriority?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedAreaMap = area?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const workOrderTypeMap = workOrderType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const selectedSiteTypeMap = siteType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const selectedSiteStatusMap = siteStatus?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const selectedStructureTypeMap = structureType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const selectedStructureStatusMap = structureStatus?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedTaskType = taskType?.value?.reduce((acc, curr) => {
    acc[
      curr === 'Work orders'
        ? 'Work Order'
        : curr === 'Work requests'
        ? 'Work Request'
        : curr === 'Inspections'
        ? 'Inspection'
        : curr === 'Check'
        ? 'Check'
        : 'Work Order'
    ] = true;
    return acc;
  }, {});

  const selectedTaskStatus = taskStatus?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedWorkOrderPriority = workOrderPriority?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const selectedPriority = priority?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const structureWithDataPoints = structures?.map((props) => {
    const { jurisdictionNumber } = jurisdictionNumbersMap[props.structureId] || {};

    const dataPoints = getDataPoints(
      { ...props, subtype: props.structureType, jurisdictionNumber },
      structureDisplayFields,
      'structure',
      picklistsMap
    );
    return {
      ...props,
      dataPoints
    };
  });
  const filteredStructure = structureWithDataPoints
    .filter((structure) => {
      const { siteId, status, structureType: type, allTasksForStructure } = structure;

      if (siteIdFor && siteId !== siteIdFor) return false;

      const site = cacheSites[sitesMap[siteId]] || {};

      sitesSiteTypeData[site?.siteType] = true;
      sitesSiteStatusData[site?.status] = true;
      sitesAreaHierarchyTreeData[site?.areaHierarchy] = true;

      structuresStructureTypeData[type] = true;
      structuresStructureStatusData[status] = true;

      if (!getStructureData({ inputValue: searched, structure, store })) return false;

      if (area.value.length > 0 && !selectedAreaMap[site?.areaHierarchy]) return false;
      if (siteType.value.length > 0 && !selectedSiteTypeMap[site?.siteType]) return false;
      if (siteStatus.value.length > 0 && !selectedSiteStatusMap[site?.status]) return false;
      if (structureType.value.length > 0 && !selectedStructureTypeMap[type]) return false;
      if (structureStatus.value.length > 0 && !selectedStructureStatusMap[status]) return false;
      if (proximity.value) {
        if (!site?.distance) return false;
        if (site.distance >= proximity.value) return false;
      }
      const dueCon = _.isArray(filterDueDate.value) ? !filterDueDate.value.includes('') : true;
      const scheduleCon = _.isArray(scheduledDate.value) ? !scheduledDate.value.includes('') : true;

      const createAtCon = _.isArray(createdAt?.value) ? !createdAt?.value.includes('') : true;
      const syncedDateCon = _.isArray(syncedDateFilter?.value)
        ? !syncedDateFilter?.value.includes('')
        : true;

      if (
        recurringTemplate.value.length > 0 &&
        !allTasksForStructure.some((x) => selectedWorkOrderRecurringTemplateMap[x.rtName])
      )
        return false;

      if (
        checkRecurrancePriority?.value?.length > 0 &&
        !allTasksForStructure.some(
          (x) => x.type === 'Check' && selectedRecurrancePriority[x.recurrencePriorityId]
        )
      )
        return false;

      if (
        inspectionType?.value?.length > 0 &&
        !allTasksForStructure.some((x) => {
          return x.type === 'Check' && inspectionTypeMap[x.inspectionTypeId?.toString()];
        })
      )
        return false;

      if (
        workOrderType?.value?.length > 0 &&
        !allTasksForStructure.some((x) => workOrderTypeMap[x?.typeId])
      )
        return false;

      if (
        taskType?.value?.length > 0 &&
        !allTasksForStructure?.some(
          (x) => selectedTaskType[x?.newWorkRequest ? 'Work Request' : x.type]
        )
      )
        return false;

      if (
        taskStatus?.value?.length > 0 &&
        !allTasksForStructure?.some((x) => selectedTaskStatus[x.status])
      )
        return false;

      allTasksForStructure?.map((x) => {
        if (!sitesWorkOrderPriorityDataMap[x.priority])
          sitesWorkOrderPriorityDataMap[x.priority] = true;
      });

      if (
        allTasksForStructure?.some((x) => x.type === 'Checks') &&
        priority.value.length > 0 &&
        !allTasksForStructure.some((x) => selectedPriority[x.priority])
      )
        return false;

      if (
        workOrderPriority.value.length > 0 &&
        !allTasksForStructure?.some(
          (x) => x.type === 'Work Order' && selectedWorkOrderPriority[x.priority]
        )
      )
        return false;

      if (
        assign?.value?.length > 0 &&
        !allTasksForStructure.some((y) =>
          assign.value.some((r) => y.customAssignedTaskValue.includes(r))
        )
      )
        return false;

      if (
        !_.isEmpty(filterDueDate.value) &&
        dueCon &&
        !allTasksForStructure.some((x) => dueDateFilter(x.dueDate, filterDueDate, x.id))
      )
        return false;

      if (
        !_.isEmpty(scheduledDate.value) &&
        scheduleCon &&
        !allTasksForStructure.some((x) =>
          scheduledDateFilter(x.startDate, x.syncedDate, scheduledDate)
        )
      )
        return false;

      if (
        !_.isEmpty(createdAt?.value) &&
        createAtCon &&
        !allTasksForStructure.some((x) =>
          createAtOrSyncedDateFilter({
            dateForFilter: x?.createdDate,
            filterSelectedDate: createdAt
          })
        )
      )
        return false;

      if (
        !_.isEmpty(syncedDateFilter?.value) &&
        syncedDateCon &&
        !allTasksForStructure.some((x) =>
          createAtOrSyncedDateFilter({
            dateForFilter: x.syncedDate,
            filterSelectedDate: syncedDateFilter
          })
        )
      )
        return false;

      return true;
    })
    .sort((a, b) => {
      switch (SelectedFilter[0]) {
        case 'DUE':
          return dueDateSort(a, b);

        case 'DISTANCE':
          return distanceFilter(a, b, sitesMap, cacheSites);
        case 'A-Z':
          return a.name.localeCompare(b.name);

        case '# TASKS':
          return b?.allotDoneTasks?.length - a?.allotDoneTasks?.length;

        default:
          return a?.name?.localeCompare(b.name);
      }
    });

  return {
    filteredStructure,
    sitesSiteTypeData,
    sitesSiteStatusData,
    structuresStructureTypeData,
    structuresStructureStatusData,
    sitesAreaHierarchyTreeData,
    sitesWorkOrderPriorityDataMap
  };
};

export const getFilterStructureWithPromise = ({ args }) => {
  return new Promise((resolve) => {
    const response = getFilterStructure({ ...args });
    resolve(response);
  });
};

const dueDateSort = (a, b) => {
  if (!a.soonestDueDate || !b.soonestDueDate) {
    return a.soonestDueDate ? -1 : 1;
  }

  const dateA = new Date(a.soonestDueDate);
  const dateB = new Date(b.soonestDueDate);

  if (dateA < dateB) {
    return -1;
  } else if (dateA > dateB) {
    return 1;
  } else {
    return 0;
  }
};

const distanceFilter = (a, b, sitesMap, cacheSites) => {
  return (
    (cacheSites[sitesMap[a?.siteId]]?.distance != null
      ? cacheSites[sitesMap[a?.siteId]]?.distance
      : Infinity) -
      (cacheSites[sitesMap[b?.siteId]]?.distance != null
        ? cacheSites[sitesMap[b?.siteId]]?.distance
        : Infinity) || a?.name?.localeCompare(b.name)
  );
};

const dueDateFilter = (taskDueDate, dueDateFilter) => {
  const currentDate = moment().format('YYYY-MM-DD');

  const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');
  if (dueDateFilter?.selected === 'next') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateIncreaseByOneDay = moment().add('1', 'd').format('YYYY-MM-DD');
      const currentDate = moment().format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDate))
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateIncreaseBySevenDay = moment().add('1', 'w').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBySevenDay,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateIncreaseBy30Day = moment().add('1', 'M').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy30Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateIncreaseBy90Day = moment().add('90', 'd').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy90Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateIncreaseBy365Day = moment().add('1', 'y').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateIncreaseBy365Day = moment().add('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
  }
  if (dueDateFilter?.selected === 'on') {
    const selectedDate = moment(dueDateFilter.value).format('YYYY-MM-DD');
    if (moment(taskDueDateWithoutTimeZone).isSame(moment(selectedDate))) return true;
  }
  if (dueDateFilter?.selected === 'range') {
    const selectedDateStart = moment(dueDateFilter.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(dueDateFilter.value[1]).format('YYYY-MM-DD');
    if (
      moment(taskDueDateWithoutTimeZone).isBetween(
        selectedDateStart,
        selectedDateEnd,
        undefined,
        '[]'
      )
    )
      return true;
  }
  if (dueDateFilter?.selected === 'past') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');
      const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');
      if (moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)))
        return true;
    }
    if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};

const scheduledDateFilter = (startDate, syncedDate, dueDateFilter) => {
  if (_.isEmpty(startDate) && _.isEmpty(syncedDate)) return false;

  const currentDate = moment().format('YYYY-MM-DD');
  const taskDueDateWithoutTimeZone =
    startDate && moment(startDate).isValid() ? moment(startDate).format('YYYY-MM-DD') : false;
  const syncedDateDateWithoutTimeZone =
    syncedDate && moment(syncedDate).isValid() ? moment(syncedDate).format('YYYY-MM-DD') : false;

  if (dueDateFilter?.selected === 'next') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateIncreaseByOneDay = moment().add('1', 'd').format('YYYY-MM-DD');
      const currentDate = moment().format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
        moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDate)) ||
        moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDate))
      ) {
        return true;
      }
    } else if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateIncreaseBySevenDay = moment().add('1', 'w').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBySevenDay,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBySevenDay,
          undefined,
          '[)'
        )
      )
        return true;
    } else if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateIncreaseBy30Day = moment().add('1', 'M').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy30Day,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy30Day,
          undefined,
          '[)'
        )
      )
        return true;
    } else if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateIncreaseBy90Day = moment().add('90', 'd').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy90Day,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy90Day,
          undefined,
          '[)'
        )
      )
        return true;
    } else if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateIncreaseBy365Day = moment().add('1', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    } else if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateIncreaseBy365Day = moment().add('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else if (dueDateFilter?.selected === 'on') {
    const selectedDate = moment(dueDateFilter.value).format('YYYY-MM-DD');

    if (moment(taskDueDateWithoutTimeZone).isSame(moment(selectedDate))) return true;
  } else if (dueDateFilter?.selected === 'range') {
    const selectedDateStart = moment(dueDateFilter.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(dueDateFilter.value[1]).format('YYYY-MM-DD');
    if (
      moment(taskDueDateWithoutTimeZone).isBetween(
        selectedDateStart,
        selectedDateEnd,
        undefined,
        '[]'
      )
    )
      return true;
  } else if (dueDateFilter?.selected === 'past') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)) ||
        moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay))
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)' ||
            moment(syncedDateDateWithoutTimeZone).isBetween(
              currentDateDecreaseBy30Day,
              currentDate,
              undefined,
              '[)'
            )
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }

    if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};
const createAtOrSyncedDateFilter = ({ dateForFilter, filterSelectedDate }) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const syncDateCustomWithoutTimeZone = moment(dateForFilter).format('YYYY-MM-DD');
  if (filterSelectedDate?.selected === 'last' && !_.isEmpty(dateForFilter)) return true;

  if (filterSelectedDate?.selected === 'on') {
    const selectedDate = moment(filterSelectedDate?.value).format('YYYY-MM-DD');
    if (moment(syncDateCustomWithoutTimeZone).isSame(moment(selectedDate))) return true;
  }

  if (filterSelectedDate?.selected === 'range') {
    const selectedDateStart = moment(filterSelectedDate?.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(filterSelectedDate?.value[1]).format('YYYY-MM-DD');
    if (
      moment(selectedDateStart).isSame(moment(selectedDateEnd)) &&
      moment(syncDateCustomWithoutTimeZone).isSame(moment(selectedDateEnd))
    )
      return true;
    else if (
      moment(syncDateCustomWithoutTimeZone).isBetween(
        selectedDateStart,
        selectedDateEnd,
        undefined,
        '[]'
      )
    )
      return true;
  }

  if (filterSelectedDate?.selected === 'past') {
    if (filterSelectedDate?.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');
      if (moment(syncDateCustomWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)))
        return true;
    }
    if (filterSelectedDate?.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }

    if (filterSelectedDate?.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};

export const tagsDataList = ['DISTANCE', '# TASKS', 'DUE'];

export const mapValues = ({
  sitesSiteTypeDataMap,
  sitesSiteStatusDataMap,
  sitesAssignableUsersDataMap,
  sitesCheckPriorityDataMap,
  sitesWorkOrderPriorityDataMap,
  sitesAreaHierarchyTreeDataMap,
  structuresStructureTypeDataMap,
  structuresStructureStatusDataMap,
  structures = [],
  store
}) => {
  const { picklists, tasks: reduxTasks, checks } = store;
  const { workOrderUsersGroupsMap = {}, tasksList = [] } = reduxTasks;

  const {
    siteTypeData = [],
    siteStatusData = [],
    equipmentStatusData = [],
    structureTypeData = [],
    checkPriorityData = [],
    assignableUsersData = [],
    workOrderPriorityData = [],
    areaHierarchyTreeData = [],
    picklistsMap = {}
  } = picklists;
  const { checkTypesMap } = checks;

  const workOrderTypeTags = getWorkOrderTypeTags(tasksList, picklistsMap) || [];

  const siteTypeTags = sitesSiteTypeDataMap
    ? siteTypeData?.filter(({ key }) => sitesSiteTypeDataMap?.[key])
    : siteTypeData;

  const siteStatusTags = sitesSiteStatusDataMap
    ? siteStatusData?.filter(({ key }) => sitesSiteStatusDataMap[key])
    : siteStatusData;
  const structureStatusTags = structuresStructureStatusDataMap
    ? equipmentStatusData?.filter(({ key }) => structuresStructureStatusDataMap[key])
    : equipmentStatusData;

  const structureTypeTags = structuresStructureTypeDataMap
    ? structureTypeData?.filter(({ key }) => structuresStructureTypeDataMap[key])
    : structureTypeData;

  const assignedUserGroups = getStructureTaskAssignedUserGroups(structures);
  const assignableUsersTags = getTaskAssignedToTags({
    assignedUserGroups,
    workOrderUsersGroupsMap
  });

  const checkPriorityTags = sitesCheckPriorityDataMap
    ? checkPriorityData.filter(({ key }) => sitesCheckPriorityDataMap[key])
    : checkPriorityData;

  const workOrderPriorityTags = sitesWorkOrderPriorityDataMap
    ? workOrderPriorityData.filter(({ key }) => sitesWorkOrderPriorityDataMap[key])
    : workOrderPriorityData;

  const treeData = sitesAreaHierarchyTreeDataMap
    ? removeUnavailableTreeData(
        JSON.parse(JSON.stringify(areaHierarchyTreeData)),
        sitesAreaHierarchyTreeDataMap
      )
    : areaHierarchyTreeData;

  const taskTypeTag = getStructuresTaskTypeTags(structures);
  const taskStatusTags = getStructuresTaskStatusTags(structures);
  const taskRecurringTemplateTags = getTaskRecurringTemplateTags(structures) || [];

  const checkRecurrancePriorityTags = getCheckRecurrancePriorityTags(structures, picklistsMap);
  const { checkTypeTags, checkTypeTagsMap } = getCheckTypeTags(structures, checkTypesMap);
  return {
    siteTypeTags,
    siteStatusTags,
    assignableUsersTags,
    checkPriorityTags,
    workOrderPriorityTags,
    treeData,
    taskTypeTag,
    taskStatusTags,
    taskRecurringTemplateTags,
    workOrderTypeTags,
    structureStatusTags,
    structureTypeTags,
    checkRecurrancePriorityTags,
    checkTypeTags,
    checkTypeTagsMap
  };
};

const getCheckTypeTags = (structures = [], checkTypesMap = {}) => {
  const checkTypeTags = [];

  for (let equipment of structures) {
    const equipmentTask = equipment?.allTasksForStructure || [];
    for (let task of equipmentTask) {
      if (task?.type === 'Check' && checkTypesMap[task?.inspectionTypeId]) {
        if (
          checkTypesMap[task?.inspectionTypeId] &&
          !checkTypeTags.includes(checkTypesMap[task?.inspectionTypeId])
        )
          checkTypeTags.push(checkTypesMap[task?.inspectionTypeId]);
      }
    }
  }

  const checkTypeTagsMap = checkTypeTags?.reduce((acc, curr) => {
    acc[curr.key] = { value: curr.value };
    return acc;
  }, {});

  return { checkTypeTags, checkTypeTagsMap };
};

const getCheckRecurrancePriorityTags = (structures = [], picklistsMap) => {
  const checkRecurrenceTags = [];

  for (let equipment of structures) {
    const equipmentTask = equipment?.allTasksForStructure || [];
    for (let task of equipmentTask) {
      if (
        task?.type === 'Check' &&
        task?.recurrencePriorityId &&
        picklistsMap[task?.recurrencePriorityId]
      ) {
        let recurreanceValue = picklistsMap[task?.recurrencePriorityId] || {};
        let recurreanceValueCopy = { ...recurreanceValue };
        recurreanceValueCopy.value = recurreanceValue?.value?.split(' ')[0] || '';
        if (!checkRecurrenceTags.some((tag) => tag.value === recurreanceValueCopy.value)) {
          checkRecurrenceTags.push(recurreanceValueCopy);
        }
      }
    }
  }

  return checkRecurrenceTags;
};

const getWorkOrderTypeTags = (tasks = [], picklistsMap = {}) => {
  let workOrderTypeTag = [];
  for (let task of tasks)
    if (picklistsMap[task?.typeId] && !workOrderTypeTag.includes(picklistsMap[task?.typeId]))
      workOrderTypeTag.push(picklistsMap[task?.typeId]);
  return workOrderTypeTag;
};

const getStructureTaskAssignedUserGroups = (structures) => {
  let assignedUserGroups = [];
  for (let structure of structures) {
    if (_.size(structure.allTasksForStructure) > 0) {
      assignedUserGroups.push(
        Array.from(
          new Set(structure.allTasksForStructure.map((t) => t?.responsibleUserGroups).flat(2))
        ) || []
      );
    }
  }
  return Array.from(new Set(_.flattenDeep(assignedUserGroups))) || [];
};

const getTaskAssignedToTags = ({ assignedUserGroups, workOrderUsersGroupsMap }) => {
  let data =
    (assignedUserGroups && // responsible users groups
      _.size(assignedUserGroups) > 0 &&
      assignedUserGroups
        .map((key) => workOrderUsersGroupsMap[key] && workOrderUsersGroupsMap[key]?.name)
        .filter((y) => y)) ||
    [];

  const assignableUsersTags = ['Me, directly', 'A group I’m in', ...data];
  return assignableUsersTags;
};

const getStructuresTaskTypeTags = (structures) => {
  let taskTypeTag = [];
  for (let structure of structures) {
    if (
      _.size(structure.allTasksForStructure) > 0 &&
      structure.allTasksForStructure.some((x) => x.type === 'Work Order')
    ) {
      taskTypeTag.push('Work orders');
      break;
    }
  }
  for (let structure of structures) {
    if (
      _.size(structure.allTasksForStructure) > 0 &&
      structure.allTasksForStructure.some((x) => x.type === 'Work Request' || x.newWorkRequest)
    ) {
      taskTypeTag.push('Work requests');
      break;
    }
  }
  for (let structure of structures) {
    if (
      _.size(structure.allTasksForStructure) > 0 &&
      structure.allTasksForStructure.some((x) => x.type === 'Inspection')
    ) {
      taskTypeTag.push('Inspections');
      break;
    }
  }
  for (let structure of structures) {
    if (
      _.size(structure.allTasksForStructure) > 0 &&
      structure.allTasksForStructure.some((x) => x.type === 'Check')
    ) {
      taskTypeTag.push('Check');
      break;
    }
  }

  return taskTypeTag;
};

const getStructuresTaskStatusTags = (structures) => {
  let taskStatusTags = [];
  for (let structure of structures) {
    if (
      _.size(structure.allTasksForStructure) > 0 &&
      structure.allTasksForStructure.some((x) => x.status === 'To do')
    ) {
      taskStatusTags.push('To do');
      break;
    }
  }
  for (let structure of structures) {
    if (
      _.size(structure.allTasksForStructure) > 0 &&
      structure.allTasksForStructure.some((x) => x.status === 'In progress')
    ) {
      taskStatusTags.push('In progress');
      break;
    }
  }
  for (let structure of structures) {
    if (
      _.size(structure.allTasksForStructure) > 0 &&
      structure.allTasksForStructure.some((x) => x.status === 'Pending sync')
    ) {
      taskStatusTags.push('Pending sync');
      break;
    }
  }
  for (let structure of structures) {
    if (
      _.size(structure.allTasksForStructure) > 0 &&
      structure.allTasksForStructure.some((x) => x.status === 'Synced')
    ) {
      taskStatusTags.push('Synced');
      break;
    }
  }
  return taskStatusTags;
};

const getTaskRecurringTemplateTags = (structures = []) => {
  const tags = Array.from(
    new Set(
      structures
        .map(
          (item) =>
            item.allTasksForStructure &&
            item.allTasksForStructure.map((task) => {
              if (task.rtNumber) return `${task.rtNumber} - ${task.rtName}`;
            })
        )
        .flat()
        .filter((name) => name)
    )
  );
  return tags;
};

export const countFilters = (filters, inputValue, store) => {
  let count = 0;
  const { picklists } = store;

  const {
    proximity,
    area,
    siteType,
    siteStatus,
    taskType,
    taskStatus,
    assign,
    priority,
    workOrderPriority,
    structureType,
    structureStatus,

    workOrderType = { value: [] },
    recurringTemplate = { value: [] },
    checkRecurrancePriority = { value: [] },
    inspectionType = { value: [] },
    checkType = { value: [] }
  } = filters;

  if (inputValue?.trim()) ++count;
  if (recurringTemplate?.value?.length > 0) ++count;
  if (proximity.value > 0) ++count;
  if (workOrderType?.value?.length > 0) ++count;
  if (assign?.value?.length > 0) ++count;
  if (siteType?.value?.length > 0) ++count;
  if (taskType?.value?.length > 0) ++count;
  if (checkType?.value?.length > 0) ++count;
  if (taskStatus?.value?.length > 0) ++count;
  if (siteStatus?.value?.length > 0) ++count;
  if (structureType?.value?.length > 0) ++count;
  if (structureStatus?.value?.length > 0) ++count;
  if (checkRecurrancePriority?.value.length > 0) ++count;
  if (inspectionType?.value?.length > 0) ++count;
  if (priority?.value?.length > 0 || workOrderPriority?.value?.length > 0) ++count;
  if (
    area?.value &&
    area?.value.filter((el) => picklists.areaHierarchyTreeDataMap?.[el])?.length > 0
  )
    ++count;

  ['dueDate', 'scheduledDate', 'createdAt', 'syncedDate'].forEach((key) => {
    const { value, selected } = filters[key] || { value: '', selected: '' };
    if (
      selected &&
      !_.isEmpty(value) &&
      (selected === 'on'
        ? value !== ''
        : selected === 'range'
        ? value?.[0] !== '' && value?.[0] !== ''
        : value?.length > 0)
    )
      ++count;
  });
  return count;
};

export const getStructureDefaultFilters = {
  structureType: { value: [] },
  structureStatus: { value: [] },
  proximity: { value: 0 },
  area: { value: [] },
  siteType: { value: [] },
  siteStatus: { value: [] },
  lastSiteCheckIn: { value: [], selected: 'past' },
  taskType: { value: [] },
  taskStatus: { value: [] },
  assign: { value: [] },
  priority: { value: [] },
  workOrderPriority: { value: [] },
  download: { value: '', selected: 'past' },
  createdAt: { value: '', selected: 'past' },
  syncedDate: { value: '', selected: 'past' },
  dueDate: { value: [], selected: 'next' },
  scheduledDate: { value: [], selected: 'next' },
  recurringTemplate: { value: [] },
  workOrderType: { value: [] },
  checkRecurrancePriority: { value: [] },
  inspectionType: { value: [] },
  checkType: { value: [] }
};
