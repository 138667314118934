/* eslint-disable no-unused-vars */

import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import HighlightText from '@components/highlight-text';
import ButtonStatus from '@components/button-status';
import SiteCard from '@components/site-card';
import Icon from '@components/icon-component';

import { getDataPoints } from '@pages/settings/helper';
import useQuery from '@hooks/useQuery';

const SiteListingInner = (props) => {
  const {
    siteId,
    onSelect,
    showChevronIcon = true,
    children: childrenList = [],
    grey,
    name,
    unit,
    parent,
    normal,
    lowGap,
    siteType,
    distance,
    hideLocation,
    highlightName,
    areaHierarchy,
    highlight = '',
    // showCheckInIcon = false,
    allDoneTasks,
    isOverDue,
    allTasksForSite,
    moreInfo,
    showWOCount,
    onParentClick = null,
    divider = false,
    siteDetails = false,
    showTopBorder = false,
    equipmentCount,
    from,
    link,
    noLink = false,
    addNewWizard = false
  } = props;

  const query = useQuery();
  const templateString = query.get('templates') || query.get('siteTemplates') || '';

  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state.picklists
  );
  const siteDisplayFields =
    useSelector((state) => state.settings.settings?.['siteDisplayFields']) || '';

  const area = areaHierarchyTreeDataMap[areaHierarchy]?.title;
  const dataPoints = getDataPoints(
    { ...props, type: siteType },
    siteDisplayFields,
    'site',
    picklistsMap
  );
  const status = picklistsMap[props.status]?.value;

  const to = !onSelect
    ? `/${
        parent
          ? 'parent-site-details'
          : normal || childrenList.length === 0
          ? 'site-details'
          : 'parent-site'
      }/${siteId}${templateString ? `?siteTemplates=${templateString}` : ''}${
        templateString ? '&' : '?'
      }from=${from ? from : 'site'}`
    : '';

  const onClickLink = (e) => {
    onParentClick && onParentClick();
    if (!onSelect) return;
    e.preventDefault();
    onSelect?.(siteId);
  };

  return (
    <>
      <SiteCard
        equipmentCount={equipmentCount}
        chips={true}
        showChevronIcon={showChevronIcon ? true : false}
        onClickLink={onClickLink}
        to={to}
        title={parent ? 'View all work at' : area}
        subtitle={name}
        hideLocation={hideLocation}
        distance={distance}
        unit={unit}
        dataPoints={dataPoints || []}
        grey={grey}
        normal={normal}
        allTasksForSite={allTasksForSite}
        allDoneTasks={allDoneTasks}
        isOverDue={isOverDue}
        status={status}
        childrenList={childrenList}
        highlight={highlight}
        showWOCount={showWOCount}
        highlightName={highlightName}
        moreInfo={moreInfo}
        siteDetailsCard={siteDetails}
        showTopBorder={showTopBorder}
        link={
          !addNewWizard
            ? !noLink
              ? props?.appliesTo
                ? `/#/site-details/${siteId}`
                : link
              : ''
            : ''
        }
        appliesTo={props?.appliesTo}
        siteId={siteId}
      />
      {divider && <div style={{ width: '100%', borderTop: '.5px solid rgb(203, 210, 217)' }} />}
    </>
  );
};

SiteListingInner.propTypes = {};

export default React.memo(SiteListingInner, _.isEqual);

export const SiteInner = (props) => {
  const {
    grey,
    name,
    unit,
    parent,
    normal,
    lowGap,
    siteType,
    distance,
    hideLocation,
    highlightName,
    areaHierarchy,
    children = [],
    highlight = '',
    // showCheckInIcon = false,
    allDoneTasks,
    isOverDue,
    allTasksForSite
  } = props;
  const siteDisplayFields =
    useSelector((state) => state.settings.settings?.['siteDisplayFields']) || '';
  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state.picklists
  );
  const status = picklistsMap[props.status]?.value;
  const dataPoints = getDataPoints(
    { ...props, type: siteType },
    siteDisplayFields,
    'site',
    picklistsMap
  );
  const area = areaHierarchyTreeDataMap[areaHierarchy]?.title;
  let [highlightNames, highlightType] = highlight.split(' ');
  highlightNames = highlightName ? highlight : highlightNames;

  if (!highlightType) highlightType = highlightNames;
  return (
    <>
      <div className={`op-listing-inner ${grey ? (lowGap ? 'mt-4' : 'mt-6') : ''}`}>
        {!hideLocation && (
          <div className="op-distance text-center">
            <div className="inline-block mb-2">
              <Icon name={'locate-grey'} />
            </div>
            {distance != null && (
              <>
                <p className="listing-proximity-caption">{distance.toLocaleString()}</p>
                <p className="listing-proximity-caption">{unit}</p>
              </>
            )}
          </div>
        )}
        <div className="op-listing-text" style={{ paddingLeft: hideLocation ? '0' : '' }}>
          <p className={`listing-deck mb-1 ${grey ? 'text-grey-400' : ''}`}>
            {parent ? 'View all work at' : area}
          </p>
          <p className={`listing-regular mb-1 ${grey ? 'text-grey-400' : 'op-anchor-link'}`}>
            <HighlightText text={name} highlight={highlightNames} />
          </p>
          {dataPoints.length > 0 && (
            <div
              className={`${grey ? '' : 'mb-2.5'}`}
              style={{ gap: '4px', display: 'flex', flexDirection: 'column' }}
            >
              {dataPoints.map(({ key, title, value }) => (
                <p
                  key={key}
                  className={`listing-configurable-datapoint ${grey ? 'text-grey-400' : ''}`}
                >
                  {title}:{' '}
                  <span>
                    {key === 'type' ? (
                      <HighlightText text={value} highlight={highlightType} />
                    ) : (
                      value
                    )}
                  </span>
                </p>
              ))}
            </div>
          )}
          {!grey && (
            <div className="flex flex-wrap gap-2">
              {!normal && allTasksForSite?.length > 0 && (
                <ButtonStatus
                  classes={`btn-status btn1 ${allDoneTasks.length === 0 && 'btn-overdue'} ${
                    allDoneTasks.length > 0 &&
                    allTasksForSite.length > allDoneTasks.length &&
                    'btn-progress'
                  }
                    ${allDoneTasks.length === allTasksForSite.length && 'btn-done'} `}
                  Text={`${allDoneTasks.length}/${allTasksForSite.length}`}
                  dot={!!isOverDue && 'true'}
                />
              )}
              {status && <ButtonStatus classes="btn-status" Text={status} />}
              {children.length > 0 && (
                <ButtonStatus
                  classes="btn-status"
                  ChildrenIcon="true"
                  Text={`${children.length}`}
                />
              )}
              {/* {showCheckInIcon && (
              <ButtonStatus classes="btn-status" Text="3 days" CheckinIcon="true" />
            )} */}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
