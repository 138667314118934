/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import _ from 'lodash';

import CollapseableTree from '@components/collapseable-tree';
import CollapseableFilter from './task-status';

const PipelineFilterGroups = ({
  isActive,
  treeData,
  filters,
  pipelinesAreaHierarchyTreeDataMap,
  updateFilters,
  pipelineStatusTags,
  picklistsMap
}) => {
  return (
    <>
      <p
        className="label-regular op-filter-label"
        style={{ padding: '29px 0px 32px 0px', marginBottom: '0px' }}
      >
        Pipeline
      </p>
      <div style={{ marginBottom: '40px' }}>
        {!_.isEmpty(pipelinesAreaHierarchyTreeDataMap) && (
          <CollapseableTree
            header={'Pipeline area'}
            isActive={isActive}
            treeData={treeData}
            value={filters.pipelineArea.value}
            treeDataMap={pipelinesAreaHierarchyTreeDataMap}
            onChange={(value) => updateFilters('pipelineArea', 'value', value)}
          />
        )}
      </div>
      <div style={{ marginBottom: '40px' }}>
        {pipelineStatusTags?.length > 0 && (
          <CollapseableFilter
            title={'Pipeline status'}
            tags={pipelineStatusTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters?.pipelineStatus?.value}
            onChange={(value) => updateFilters('pipelineStatus', 'value', value)}
          />
        )}
      </div>
    </>
  );
};

export default memo(PipelineFilterGroups);
