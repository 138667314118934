/* eslint-disable no-unused-vars */

//  assigned word order/ modal work request work order
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import AddPhoto from '@components/add-photo';
import AddDocument from '@components/add-document';
import PhotoComponent from '@components/photo-component';
import DocumentsCard from '@components/documents-card';
import { isNewForm } from '@hooks/isNewForm';
import updateTasksAssets from '@utilities/update-tasks-assets';

import style from '../general.module.scss';
import {
  deleteAssetInIndexDB,
  getAssetDataBase64InIndexDB,
  setAssetDataInIndexDB,
  updateAssetFile
} from '@utilities/photos-documents.util';

const YourAttachment = ({
  currentSelectedTasksList,
  setValue,
  watch,
  getValues,
  noSection = false,
  updateTaskAsset = null
}) => {
  const [addPhoto, setAddPhoto] = useState(false);
  const [updatePhoto, setUpdatePhoto] = useState(null);
  const [addDocument, setAddDocument] = useState(false);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);
  const [selectedDocumentId, setSelectedDcumentId] = useState(null);
  const [selectedDocumentAndFile, setSelectedDcumentAndFile] = useState({
    document: null,
    file: null
  });
  const [photosListWithBase64, setPhotosListWithBase64] = useState([]);
  const { newForm: isFormIsNew, workType, orderIds, orderIdsLength } = isNewForm();

  const { photoCategoryData, picklistsMap } = useSelector((state) => state.picklists);
  const { currentTaskId } = useSelector((state) => state.cache);

  const photoCategoryDataMap = photoCategoryData?.reduce((acc, curr) => {
    acc[curr.key] = curr || '';
    return acc;
  }, {});

  useEffect(() => {
    if (addPhoto || addDocument) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [addPhoto, addDocument]);

  const onSubmitPhoto = async (data, updateId) => {
    const uniqueId = updateId ? updateId : uuidv4();
    !updateId
      ? await setAssetDataInIndexDB({
          assetId: uniqueId,
          assetFile: data.image,
          status: 'temporary'
        })
      : !data?.image?.id
      ? await updateAssetFile({ assetId: updateId, assetFile: data.image })
      : '';

    let modifyData = {
      ...data,
      image: { id: uniqueId, name: data.image?.name, type: data.image?.type }
    };

    if (updateId) {
      const previousPhotosData = getValues('photo') || [];
      const findIndex = previousPhotosData?.findIndex((x) => x.id === updateId);
      previousPhotosData[findIndex] = { ...modifyData, id: updateId };
      setValue('photo', [...previousPhotosData], { shouldDirty: true });
    } else {
      let previousPhotoData = getValues('photo') || [];
      setValue('photo', [...previousPhotoData, { ...modifyData, id: uniqueId }], {
        shouldDirty: true
      });
    }
    setAddPhoto(false);
    setSelectedPhotoId(null);
    setUpdatePhoto(null);
  };

  const onSubmitDocument = async (data, documentAndFileId) => {
    if (!documentAndFileId?.id) {
      for (let doc of data) {
        await setAssetDataInIndexDB({
          assetId: doc?.file?.id,
          assetFile: doc?.file?.file
        });
      }
    } else {
      for (let doc of data) {
        if (doc?.file?.file instanceof File) {
          await updateAssetFile({
            assetId: documentAndFileId?.file?.id,
            assetFile: doc?.file?.file
          });
        }
      }
    }

    let modifyData = data?.map((doc) => {
      return {
        ...doc,
        file: {
          ...doc.file,
          id: documentAndFileId?.id ? documentAndFileId?.file?.file?.id : doc.file?.id,
          file: {
            name: doc.file?.file?.name,
            type: doc.file?.file?.type,
            id: documentAndFileId?.id ? documentAndFileId?.file?.file?.id : doc?.file?.id
          }
        }
      };
    });

    if (!_.isEmpty(documentAndFileId?.id)) {
      const previousDocumentData = getValues('document') || [];
      const findIndexofDocument = previousDocumentData?.findIndex(
        (x) => x.id === documentAndFileId?.id
      );

      previousDocumentData[findIndexofDocument] = {
        id: documentAndFileId?.id,
        category: modifyData[0]?.category,
        file: modifyData[0]?.file
      };
      setValue('document', [...previousDocumentData], { shouldDirty: true });
    } else {
      let previousDocumentData = getValues('document') || [];
      const newDocumentData = [...previousDocumentData, ...modifyData];
      setValue('document', newDocumentData, { shouldDirty: true });
    }
    setAddDocument(false);
    setSelectedDcumentAndFile(null);
  };

  const deleteBtnEvent = async (id, imageIdForIndexDB) => {
    return new Promise((resolve, reject) => {
      const handleAsyncFunction = async () => {
        try {
          await deleteAssetInIndexDB({ assetId: imageIdForIndexDB, deleteAll: true });
          if (updateTaskAsset) {
            updateTaskAsset({ photoId: id });
          } else if (!isFormIsNew) {
            updateTasksAssets({
              taskId: orderIdsLength > 1 ? currentTaskId : orderIds,
              assetId: id,
              workType,
              photo: true,
              formData: watch(),
              fieldName: 'photos'
            });
          }

          const previousPhotosData = getValues('photo') || [];
          const newPhotosList = previousPhotosData?.filter((x) => x.id !== id);
          setValue('photo', newPhotosList, { shouldDirty: true });
          resolve(true);
        } catch (error) {
          reject(error);
        }
      };

      handleAsyncFunction();
    });
  };

  const editBtnEvent = (id) => {
    const copyPhotos = getValues('photo') || [];
    const photo = copyPhotos?.find((p) => p.id === id);
    setSelectedPhotoId(id);
    setUpdatePhoto(photo);
    setAddPhoto(true);
  };

  const editDocumentEvent = (documentId, fileId) => {
    const copyDocument = getValues('document') || [];
    const document = copyDocument?.find((doc) => doc.file.id === fileId);

    setSelectedDcumentAndFile({
      ...document
      // file
    });
    setSelectedDcumentId({
      documentId,
      fileId
    });
    setTimeout(() => {
      setAddDocument(true);
    }, 1000);
  };

  const deleteDocumentEvent = async (documentId, fileId) => {
    return new Promise((resolve, reject) => {
      const handleAsyncFunction = async () => {
        try {
          await deleteAssetInIndexDB({ assetId: fileId, deleteAll: true });
          if (updateTaskAsset) {
            updateTaskAsset({ documentId: documentId });
          } else if (!isFormIsNew) {
            updateTasksAssets({
              taskId: orderIdsLength > 1 ? currentTaskId : orderIds,
              assetId: documentId,
              workType,
              document: true,
              formData: watch(),
              fieldName: 'documents'
            });
          }

          const copy = getValues('document') || [];
          const previousDocumentData = getValues('document') || [];
          const newDocumentList = previousDocumentData?.find((x) => x.id === documentId);

          const files = newDocumentList?.files?.filter((y) => y?.id !== fileId);
          if (_.size(files) === 0) {
            const test = copy?.filter((x) => x.id !== documentId);
            setValue('document', [...test], { shouldDirty: true });
          } else {
            const findIndexofDocument = copy?.findIndex((x) => x.id === documentId);
            previousDocumentData[findIndexofDocument] = {
              id: documentId,
              category: copy[findIndexofDocument]?.category,
              files: files
            };
            setValue('document', previousDocumentData, { shouldDirty: true });
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      };

      handleAsyncFunction();
    });
  };

  const showPhotos = watch && watch('photo') && _.size(watch('photo')) > 0;

  useEffect(() => {
    // need loader to load the image
    const getPhotosList = async () => {
      let list = [];
      if (_.size(watch('photo')) > 0) {
        for (let photo of watch('photo')) {
          const base64 = await getAssetDataBase64InIndexDB({ assetId: photo?.image?.id });
          const image = { ...photo.image, base64 };
          list.push({ ...photo, image });
        }
        setPhotosListWithBase64(list);
      }
    };
    getPhotosList();
  }, [watch('photo')]);

  return (
    <>
      <>
        <div
          className={style.attachment}
          style={{ backgroundColor: '#FFFFFF', marginTop: !noSection ? '16px' : '0px' }}
        >
          {!noSection && (
            <div
              className={style.nameDivInner}
              style={{ backgroundColor: '#E3E7EB', marginBottom: '16px' }}
            >
              <p style={{ fontSize: '18px' }}>Your attachments</p>
            </div>
          )}

          <div className={style.body} style={{ padding: '4px 16px' }}>
            {showPhotos && photosListWithBase64?.length > 0 && (
              <>
                <div className={style.photoCard}>
                  <h6>{'photos'}</h6>
                  <div className={style.grid2} style={{ marginTop: '0px' }}>
                    {photosListWithBase64?.map((card, index) => {
                      return (
                        <PhotoComponent
                          key={index}
                          photoId={card?.id || index}
                          deleteBtnEvent={deleteBtnEvent}
                          editBtnEvent={editBtnEvent}
                          image={card?.image || null}
                          categoryValue={photoCategoryDataMap[card?.category]?.value || ''}
                          captionValue={card?.caption || ''}
                        />
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            {_.size(watch('document')) > 0 && (
              <h6 style={{ marginBottom: '8px' }}>{'documents'}</h6>
            )}
            {_.size(watch('document')) > 0 &&
              watch('document')?.map((doc) => {
                return (
                  <DocumentsCard
                    documentCategory={doc}
                    key={doc?.id}
                    file={doc.file}
                    picklistsMap={picklistsMap}
                    editDocument={editDocumentEvent}
                    deleteDocument={deleteDocumentEvent}
                  />
                );
              })}
            <div className={style.bodyBtns}>
              <button
                className={style.addImage}
                onClick={() => {
                  setAddPhoto(true);
                  setSelectedPhotoId(null);
                }}
                type="button"
              >
                ADD PHOTO
              </button>
              <button
                className={style.addDocument}
                onClick={() => {
                  setAddDocument(true);
                  setSelectedDcumentId(null);
                  setSelectedDcumentAndFile({
                    document: null,
                    file: null
                  });
                }}
                style={{ marginBottom: '10px' }}
                type="button"
              >
                ADD DOCUMENT
              </button>
            </div>
          </div>
        </div>
      </>

      {addPhoto && (
        <AddPhoto
          open={addPhoto}
          closed={(condition) => {
            setUpdatePhoto(null);
            setAddPhoto(condition);
          }}
          title={`${_.isEmpty(selectedPhotoId) ? 'Add' : 'Edit'} photo`}
          del={true}
          onSubmitPhoto={onSubmitPhoto}
          updatePhoto={updatePhoto}
          id={selectedPhotoId}
          deleteBtnEvent={deleteBtnEvent}
          initialValue={
            selectedPhotoId && !_.isEmpty(getValues('photo'))
              ? getValues('photo')?.find((x) => x?.id === selectedPhotoId)
              : null
          }
        />
      )}
      {addDocument && (
        <AddDocument
          open={addDocument}
          closed={setAddDocument}
          title={`${_.isEmpty(selectedDocumentId) ? 'Add' : 'Edit'} document`}
          del={true}
          onSubmitDocument={onSubmitDocument}
          id={selectedDocumentId}
          selectedDocumentAndFile={selectedDocumentAndFile}
          deleteBtnEvent={deleteDocumentEvent}
          initialValue={
            selectedDocumentId && !_.isEmpty(getValues('document'))
              ? getValues('document')?.find((x) => x?.id === selectedDocumentId)
              : null
          }
        />
      )}
    </>
  );
};

export default YourAttachment;
