import style from './style.module.scss';

const ResetAndViewButton = ({
  clickResetButton,
  length,
  setFilterActiveEvent,
  setIsModalVisibleEvent,
  width,
  detailPage
}) => {
  return (
    <>
      <div
        className={`${style['op-abs-btn']} flex flex-wrap  justify-center justify-between mt-8 md:flex-col items-center md:items-center lg:flex-row mobile`}
      >
        <button
          className={`${style['task-card-header']} btn-small mb-4 btn-small-grey`}
          onClick={clickResetButton}
        >
          RESET
        </button>

        <button
          className={`${style['task-card-header']} btn-small mb-4`}
          onClick={() => setFilterActiveEvent(false)}
        >
          VIEW {<span>({length?.toLocaleString()})</span>}
        </button>
      </div>

      <div className="text-center">
        <button
          onClick={() => {
            if (width < 768 && !detailPage) setFilterActiveEvent(false);
            setIsModalVisibleEvent(true);
          }}
          className={`${style['task-card-header']} ${style['op-bookmark-filter']} text-center`}
        >
          BOOKMARK FILTER
        </button>
      </div>
    </>
  );
};

export default ResetAndViewButton;
