/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import Icon from '@components/icon-component';

import './wizard-steps.scss';

const WizardSteps = ({
  title,
  total,
  current,
  content,
  setStep,
  breadcrumbs,
  notShowSteps = true
}) => {
  return (
    <div className="wizard-steps">
      <div className="wizard-steps--breadcrumbs">
        {breadcrumbs.slice(0, current + 1).map((item, index, arr) => (
          <div key={index}>
            <span
              onClick={() =>
                index !== current && arr.length > 0 && arr.length <= total && setStep(index)
              }
              style={{
                cursor:
                  index !== current && arr.length > 0 && arr.length <= total ? 'pointer' : 'default'
              }}
            >
              {item}
            </span>
            {arr.length > 1 && index < arr.length - 1 && (
              <Icon name={'chevron-right'} width="10px" />
            )}
          </div>
        ))}
      </div>
      <div className="wizard-steps--title">{title}</div>
      <div className="wizard-steps--content-wrapper">
        <div className="wizard-steps--content">{content}</div>
        <div className="wizard-steps--box-shadow">.</div>
      </div>
      {notShowSteps && (
        <div className="wizard-steps--wrapper">
          <div className="wizard-steps--steps">
            <div>
              {[...Array(total)].map((_, index) => (
                <span
                  key={index}
                  className={`wizard-steps--steps--step ${current === index ? 'current' : ''} ${
                    index < current ? 'completed' : ''
                  }`}
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    if (index < current) setStep(index);
                  }}
                >
                  {index < current ? <Icon name={'check-white'} /> : index + 1}
                </span>
              ))}
            </div>
            <span className="wizard-steps--steps--line" />
          </div>
        </div>
      )}
    </div>
  );
};

WizardSteps.propTypes = {
  content: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  current: PropTypes.number.isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};

export default WizardSteps;
