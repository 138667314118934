/* eslint-disable no-unused-vars */
import { Fragment } from 'react';

import TaskDetailComponent from '@components/task-detail-component';
import Icon from '@components/icon-component';

import { PERMISSIONS } from 'permissions/constants';
import { decodeToken } from '@utilities/authentication';

const Pipeline = ({
  setAllTask,
  setWorkOrder,
  setChecks,
  setInspections,
  allWorkOrder = [],
  allDoneWorkOrder = [],
  allInspection = [],
  allDoneInspection = [],
  allTasks = [],
  allDoneTasks = [],
  isOverDue,
  isWorkOrderOverDue,
  isInspectionOverDue,
  allChecks = [],
  allDoneCheck = [],
  isCheckOverDue
}) => {
  const taskDetail = [
    {
      img: <Icon name={'task'} />,
      taskName: 'All tasks',
      btnText: `${allDoneTasks.length}/${allTasks?.length}`,
      click: () => setAllTask(true),
      allDoneTasks: allDoneTasks,
      allTasks: allTasks,
      isOverDue: isOverDue,
      dot: !!isOverDue && allDoneTasks?.length !== allTasks?.length
    },
    {
      img: <Icon name={'inspection'} />,
      taskName: 'Inspections',
      btnText: `${allDoneInspection?.length}/${allInspection?.length}`,
      click: () => setInspections(true),
      allDoneTasks: allDoneInspection,
      allTasks: allInspection,
      isOverDue: isInspectionOverDue,
      dot: !!isInspectionOverDue && allDoneInspection?.length !== allInspection?.length
    },
    {
      img: <Icon name={'work-order'} />,
      taskName: 'Work orders',
      btnText: `${allDoneWorkOrder?.length}/${allWorkOrder?.length}`,
      click: () => setWorkOrder(true),
      allDoneTasks: allDoneWorkOrder,
      allTasks: allWorkOrder,
      isOverDue: isWorkOrderOverDue,
      dot: !!isWorkOrderOverDue && allDoneWorkOrder?.length !== allWorkOrder?.length
    },
    {
      img: <Icon name={'check-icon'} />,
      taskName: 'Checks',
      btnText: `${allDoneCheck?.length}/${allChecks?.length}`,
      click: () => setChecks(true),
      allDoneTasks: allDoneCheck,
      allTasks: allChecks,
      isOverDue: isCheckOverDue,
      dot: !!isCheckOverDue && allDoneCheck?.length !== allChecks?.length
    }
  ];

  return (
    <div>
      {allTasks?.length > 0 &&
        taskDetail
          ?.filter(({ taskName }) => {
            const permissions = decodeToken()?.permissions || {};
            if (taskName === 'Checks' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS])
              return false;
            if (taskName === 'Inspections' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS])
              return false;
            if (taskName === 'Work orders' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS])
              return false;
            return true;
          })
          .filter((a) => a?.allTasks?.length > 0)
          .map((ele, index) => {
            return ele.taskName === 'All Tasks' &&
              taskDetail?.filter((a) => a.allTasks.length > 0).length === 2 ? (
              <Fragment key={index}></Fragment>
            ) : (
              <TaskDetailComponent
                key={index}
                img={ele.img}
                task={ele.taskName}
                btnText={`${ele.btnText}`}
                onClick={ele.click}
                dot={ele.dot}
                isOverDue={isOverDue}
                allDoneTasks={ele.allDoneTasks}
                allTasksForSite={ele.allTasks}
              />
            );
          })}
    </div>
  );
};

export default Pipeline;
