/* eslint-disable no-unused-vars */
import React from 'react';

import style from './range.module.scss';

const RatingRange = ({ value, onChange, min = 0, max = 100 }) => {
  const settings = { fill: '#2F80ED', background: '#d7dcdf' };
  let percentage = value > 0 ? (max * (value - min)) / (max - min) : 0;
  percentage = (percentage / max) * 100;

  return (
    <>
      <div
        className={style.range_slider}
        style={{
          background: `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${
            settings.background
          } ${percentage + 0.1}%)`
        }}
      >
        <input
          type="range"
          min={min}
          max={max}
          className={style.range_slider__range}
          value={value}
          step={1}
          onChange={(e) => onChange(parseInt(e.target.value))}
          style={{
            background: `linear-gradient(90deg, ${settings.fill} ${percentage}%, ${
              settings.background
            } ${percentage + 0.1}%)`
          }}
        ></input>
      </div>
    </>
  );
};

export default RatingRange;
