/* eslint-disable no-unused-vars */

import _ from 'lodash';

import { getTaskSiteAsset } from '../global/site';
import { getTaskEquipmentAsset } from '../global/equipment';
import { getDataPoints } from '../../pages/settings/helper';

const getTaskHeaderTitle = ({ status, type, isNew, id }) => {
  const taskCardHeaderTitle =
    (status === 'Synced' && type !== 'New Asset' && type !== 'Edited Asset') ||
    type === 'Work Order'
      ? type === 'Work Order' || type === 'Work Request'
        ? isNew
          ? `New - ${id}`
          : id
        : `${TYPE_CODES[type]}-${id}`
      : id === 'New Work order' || id === 'New Work Request'
      ? id
      : type;
  return taskCardHeaderTitle;
};

const getTaskCardUsers = ({
  responsibleUserGroups,
  responsibleUsers,
  workOrderUsersMap,
  workOrderUsersGroupsMap,
  primaryUserId
}) => {
  const allUsers = [...responsibleUserGroups, ...responsibleUsers];
  const responsibleUsersMap = {
    ...(workOrderUsersMap || {}),
    ...(workOrderUsersGroupsMap || {})
  };
  const users =
    [primaryUserId, ...allUsers.filter((user) => user !== primaryUserId)]
      .filter((user) => responsibleUsersMap[user])
      .map((user) =>
        responsibleUsersMap[user]?.name
          ? `${responsibleUsersMap[user].name}`
          : `${responsibleUsersMap[user].firstName} ${responsibleUsersMap[user].lastName}`
      ) || [];
  return users;
};

const getTaskAssignedUserTags = ({
  responsibleUsers,
  responsibleUserGroups,
  workOrderUsersGroupsMap,
  userId,
  responsibleUsersMap
}) => {
  let customAssignedTaskValue = [];
  if (responsibleUsers && responsibleUsers.includes(userId))
    customAssignedTaskValue.push('Me, directly');
  if (responsibleUserGroups && responsibleUserGroups?.length > 0) {
    responsibleUserGroups.map((key) => {
      if (workOrderUsersGroupsMap[key] && workOrderUsersGroupsMap[key].name) {
        customAssignedTaskValue.push(workOrderUsersGroupsMap[key].name);
      }
    });
  }
  if (
    responsibleUserGroups &&
    responsibleUserGroups
      .map(
        (r) =>
          responsibleUsersMap[r] &&
          responsibleUsersMap[r].users &&
          responsibleUsersMap[r].users?.map((u) => u.id)
      )
      .flat(2)
      .includes(userId)
  )
    customAssignedTaskValue.push('A group I’m in');
  return customAssignedTaskValue;
};

const TYPE_CODES = {
  Check: 'CH',
  Inspection: 'INS',
  'Work Order': 'WO',
  'New Work Request': 'WR',
  'New Work Order': 'New - WO'
};

const getDetailsInspectionsInfo = ({ inspectionIds, task }) => {
  const info = inspectionIds?.map((ins) => {
    return {
      inspectionNumber: task?.recordNumber || null,
      inspectionType: task?.recordTitle || null
    };
  });
  return info?.filter((x) => x);
};

const TYPE_ICONS = {
  Check: 'check-task',
  Inspection: 'inspection-blue',
  'New Asset': 'new-asset-task',
  'Work Order': 'work-order-task',
  'Edited Asset': 'new-asset-task',
  'Work Request': 'work-order-task',
  'New Work Order': 'work-order-task',
  'New Work Request': 'work-order-task'
};

const getStructureEquipmentMap = ({ equipments }) => {
  return equipments.reduce((acc, { structureId, equipmentId }) => {
    if (structureId) {
      if (!acc[structureId]) {
        acc[structureId] = [equipmentId];
      } else {
        acc[structureId].push(equipmentId);
      }
    }
    return acc;
  }, {});
};

const getAppliesToTypeData = ({
  equipmentsWithoutMobileMap,
  task,
  equipmentsMap,
  cacheEquipments,
  picklistsMap,
  equipmentDisplayFields,
  jurisdictionNumbersMap,
  sitesMap,
  siteDisplayFields,
  areaHierarchyTreeDataMap,
  pipelineMobileMap,
  pipelineMobile,

  structureMobileMap,
  structureMobile,
  cacheSites,
  appliesTo,
  structureDisplayFields,
  pipelineDisplayFields,
  store
}) => {
  const {
    primaryAssetType = null,
    relatedAssetType,
    relatedAssetId = null,
    primaryAssetId = null,
    recordNumber = '',
    recordTitle = ''
  } = task;
  if (
    (primaryAssetType === 'Site' || relatedAssetType === 'Site') &&
    (primaryAssetId || relatedAssetId)
  ) {
    const siteAsset = getTaskSiteAsset({
      sitesMap,
      siteId: primaryAssetId || relatedAssetId || null,
      areaHierarchyTreeDataMap,
      siteDisplayFields,
      picklistsMap,
      cacheSites,
      store
    });
    return {
      recordNumber: recordNumber,
      recordTitle: recordTitle,
      siteAsset:
        siteAsset && _.size(siteAsset.prepareTasksSites) > 0
          ? { ...siteAsset.prepareTasksSites[0], siteId: primaryAssetId || relatedAssetId }
          : {}
    };
  } else if (
    (primaryAssetType === 'Equipment' || relatedAssetType === 'Equipment') &&
    (primaryAssetId || relatedAssetId)
  ) {
    const equipmentAsset = getTaskEquipmentAsset({
      equipmentsWithoutMobileMap,
      equipmentsMap,
      cacheEquipments,
      equipments: [],
      equipmentId: primaryAssetId || relatedAssetId,
      picklistsMap,
      equipmentDisplayFields,
      jurisdictionNumbersMap,
      sitesMap,
      siteDisplayFields,
      areaHierarchyTreeDataMap,
      cacheSites,
      appliesTo,
      store
    });
    return {
      recordNumber: recordNumber,
      recordTitle: recordTitle,
      equipmentAsset:
        equipmentAsset && _.size(equipmentAsset.prepareTasksEquipments) > 0
          ? {
              ...equipmentAsset.prepareTasksEquipments[0],
              equipmentId: primaryAssetId || relatedAssetId
            }
          : {}
    };
  } else if (
    // ((primaryAssetType === 'Pipeline' || relatedAssetType === 'Pipeline') &&
    //   (!_.isEmpty(primaryAssetId) || !_.isEmpty(relatedAssetId))) ||
    // task?.pipelines
    (primaryAssetType === 'Pipeline' || relatedAssetType === 'Pipeline') &&
    task?.pipelines
  ) {
    const taskPipelines = task?.pipelines || [];
    const id = primaryAssetId || relatedAssetId;
    const pipelinesIds = id
      ? [id, ...taskPipelines.filter((pipeline) => pipeline !== id)] || []
      : taskPipelines;

    const pipeLineDataMap = pipelinesIds?.map((pipelineId) => {
      const pipeLineData = pipelineMobile[pipelineMobileMap[pipelineId]] || {};

      const dataPoints = getDataPoints(
        { ...pipeLineData, area: areaHierarchyTreeDataMap[pipeLineData?.area]?.title },
        pipelineDisplayFields,
        'pipeline',
        picklistsMap
      );

      return {
        fromLocation: pipeLineData?.fromLocation || '',
        toLocation: pipeLineData?.toLocation || '',
        licenseNumber: pipeLineData.licenseNumber || '',
        lineNumber: pipeLineData?.lineNumber || '',
        dataPoints,
        // length: pipeLineData.length || '', //TODO client requirements here
        pipelineId: id
      };
    });
    return {
      recordNumber: recordNumber,
      recordTitle: recordTitle,
      pipelineAsset: pipeLineDataMap
    };
  } else if (
    // ((primaryAssetType === 'Structure' || relatedAssetType === 'Structure') &&
    //   (primaryAssetId || relatedAssetId)) ||
    // task?.structures
    (primaryAssetType === 'Structure' || relatedAssetType === 'Structure') &&
    task?.structures
  ) {
    const taskStructure = task?.structures || [];
    const id = primaryAssetId || relatedAssetId || null;
    const structureIds = id
      ? [id, ...taskStructure.filter((structureId) => structureId !== id)] || []
      : task?.structures;

    const structureDataMap = structureIds?.map((structureId) => {
      const structureData = structureMobile?.[structureMobileMap?.[structureId]] || {};
      const structureSiteId = structureData?.siteId || null;

      const siteAsset = getTaskSiteAsset({
        sitesMap,
        siteId: structureSiteId,
        areaHierarchyTreeDataMap,
        siteDisplayFields,
        picklistsMap,
        cacheSites,
        store
      });

      const dataPoints = getDataPoints(
        { ...structureData },
        structureDisplayFields,
        'structure',
        picklistsMap
      );

      return {
        name: structureData.name || '',
        structureType: picklistsMap[structureData.structureType]?.value || '',
        dataPoints,
        siteAsset:
          siteAsset && _.size(siteAsset.prepareTasksSites) > 0
            ? { ...siteAsset.prepareTasksSites[0], siteId: structureSiteId }
            : {}
      };
    });

    return {
      recordNumber: recordNumber,
      recordTitle: recordTitle,
      structureData: structureDataMap
    };
  } else if (task?.appliesToType === 'moc' && _.size(task?.mocItems) > 0) {
    return {
      recordNumber: recordNumber,
      recordTitle: recordTitle
    };
  }
};

export {
  getTaskHeaderTitle,
  getTaskCardUsers,
  getTaskAssignedUserTags,
  getDetailsInspectionsInfo,
  TYPE_ICONS,
  getStructureEquipmentMap,
  getAppliesToTypeData
};
