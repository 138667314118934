import moment from 'moment';

const EquipmentAutoPopulate = ({
  intervalId,
  firstName,
  lastName,
  equipment,
  jurisdictionNumber,
  jurisdiction,
  inspectionLastDate
}) => {
  const equipmentCategoryTypeAndSubType =
    equipment?.equipmentCategory && equipment?.equipmentType && equipment?.equipmentSubType
      ? equipment?.equipmentSubType
      : equipment?.equipmentCategory && equipment?.equipmentType
      ? equipment?.equipmentType
      : equipment?.equipmentCategory
      ? equipment?.equipmentCategory
      : '';

  return {
    // sourceEntity
    User: {
      //SourceProperty,
      InspectorName: `${firstName} ${lastName}`
    },
    InspectionSchedules: {
      IntervalId: intervalId
    },
    Incident: {
      InitiatorName: `${firstName} ${lastName}`
    },
    Equipment: {
      CylinderType: equipment?.cylinderTypeId || equipment?.cylinderType,
      HeadMaterialId: equipment?.headMaterialId,
      ShellMaterialId: equipment?.shellMaterialId,
      Condition: equipment?.condition || null,
      AssessedCondition: equipment?.assessedCondition || null,
      CRN_Number: equipment?.crnNumber || null,
      ve_inspection: null,
      tank_inspection: null,
      vi_inspection: null,
      ut_inspection: null,
      LastInspectionDate: inspectionLastDate[equipment?.equipmentId]?.inspectionDate
        ? moment(inspectionLastDate[equipment?.equipmentId]?.inspectionDate)
            .format('YYYY-MM-DD')
            ?.toString()
        : null,
      CurrentUserOrPlacement: `${firstName} ${lastName}`,
      CylinderSerialNum: equipment?.cylinderSerialNum,
      MainStationRegSerialNum: equipment?.mainStationRegSerialNum,
      PrimaryRegSerialNum: equipment?.primaryRegSerialNum,
      SuppliedAirHarnessRegSerialNum: equipment?.suppliedAirHarnessRegSerialNum,
      EquipmentNumber: equipment?.equipmentNumber || null,
      FieldTagNumber: equipment?.fieldTagNumber || null,
      GearboxManufacturer: equipment?.gearboxManufacturer || null,
      GearboxSerialNumber: equipment?.gearboxSerialNumber || null,
      JurisdictionNumber: jurisdictionNumber || null,
      Jurisdiction: jurisdiction || null,
      Manufacturer: equipment?.manufacturer || null,
      Model: equipment?.model || null,
      Name: equipment?.name || null,
      PhysicalLocation: equipment?.physicalLocation || null,
      PlateNumber: equipment?.plateNumber || null,
      SerialNumber: equipment?.serialNumber || null,
      GearboxRate: equipment?.gearboxRate || null,
      TargetAmps: equipment?.targetAmps || null,
      UpperTargetAmps: equipment?.upperTargetAmps || null,
      YearBuilt: equipment?.manufacturedYear || null,
      Description: equipment?.description || null,
      TankDiameter: equipment?.diameter || null,
      VesselDiameter: equipment?.diameter || null,
      VesselLength: equipment?.length || null,
      DesignTemperature: equipment?.mdmtMeasurement || null, // equipment?.mdmt

      PWHT: equipment?.postWeldHeatTreat || null,
      GaugeBoardUOM: equipment?.gaugeBoardUom || null,

      Category_Type_SubType: equipmentCategoryTypeAndSubType || null,
      Service: equipment?.service || null,
      Status: equipment?.status || null,
      TargetAmpsText: equipment?.targetAmps || null,
      UpperTargetAmpsText: equipment?.upperTargetAmps || null,
      Code_Of_Construction: equipment?.codeOfConstruction || null,
      UnitNumber: equipment?.unitNumber || null,
      FlangeInletJointStyle: equipment?.flangeInletJointStyle || null,
      FlangeOutletJointStyle: equipment?.flangeOutletJointStyle || null,
      InsulationMaterialId: equipment?.insulationMaterialId || null,

      PSVCapacity: {
        textBoxValue: equipment?.psvCapacity || null,
        textBoxUnitValue: equipment?.psvCapacityMeasurement || ''
      },
      HeadThickness: {
        textBoxValue: equipment?.headThickness || null,
        textBoxUnitValue: equipment?.headThicknessMeasurement || ''
      },
      Head2Thickness: {
        textBoxValue: equipment?.head2Thickness || null,
        textBoxUnitValue: equipment?.head2ThicknessMeasurement || ''
      },

      FlangeInletSize: {
        textBoxValue: equipment?.inletSize || null,
        textBoxUnitValue: equipment?.inletSizeMeasurement || ''
      },
      FlangeOutletSize: {
        textBoxValue: equipment?.outletSize || null,
        textBoxUnitValue: equipment?.outletSizeMeasurement || ''
      },

      OutsideDiameter: {
        textBoxValue: equipment?.outsideDiameter || null,
        textBoxUnitValue: equipment?.outsideDiameterMeasurement || ''
      },
      ShellThickness: {
        textBoxValue: equipment?.shellThickness || null,
        textBoxUnitValue: equipment?.shellThicknessMeasurement || ''
      },
      Diameter: {
        textBoxValue: equipment?.diameter || null,
        textBoxUnitValue: equipment?.diameterMeasurement || ''
      },
      TankHeight: {
        textBoxValue: equipment?.height || null,
        textBoxUnitValue: equipment?.heightMeasurement || ''
      },
      Length: {
        textBoxValue: equipment?.length || null,
        textBoxUnitValue: equipment?.lengthMeasurement || ''
      },
      MAWP: {
        textBoxValue: equipment?.mawp || null,
        textBoxUnitValue: equipment?.mawpMeasurement || ''
      },
      SetPointPressure: {
        textBoxValue: equipment?.setPointPressure || null,
        textBoxUnitValue: equipment?.setPointPressureMeasurement || ''
      },
      Pressure: {
        textBoxValue: equipment?.pressure || null,
        textBoxUnitValue: equipment?.pressureMeasurement || ''
      },
      MAWT: {
        textBoxValue: equipment?.mawt || null,
        textBoxUnitValue: equipment?.mawtMeasurement || ''
      },
      MDMT: {
        textBoxValue: equipment?.mdmt || null,
        textBoxUnitValue: equipment?.mdmtMeasurement || ''
      },
      Capacity: {
        textBoxValue: equipment?.maximumCapacity || null,
        textBoxUnitValue: equipment?.capacityMeasurement || ''
      },
      Volume: {
        textBoxValue: equipment?.volume || null,
        textBoxUnitValue: equipment?.volumeMeasurement || ''
      },
      ShellSideMAWP: {
        textBoxValue: equipment?.shellSideMAWP || null,
        textBoxUnitValue: equipment?.shellSideMAWPMeasurement || ''
      },
      ShellSideMaxAWT: {
        textBoxValue: equipment?.shellSideMaxAWT || null,
        textBoxUnitValue: equipment?.shellSideMaxAWTMeasurement || ''
      },
      ShellSideMDMT: {
        textBoxValue: equipment?.shellSideMDMT || null,
        textBoxUnitValue: equipment?.shellSideMDMTMeasurement || ''
      },
      TubeSideMAWP: {
        textBoxValue: equipment?.tubeSideMAWP || null,
        textBoxUnitValue: equipment?.tubeSideMAWPMeasurement || ''
      },
      TubeSideMaxAWT: {
        textBoxValue: equipment?.tubeSideMaxAWT || null,
        textBoxUnitValue: equipment?.tubeSideMaxAWTMeasurement || ''
      },
      TubeSideMDMT: {
        textBoxValue: equipment?.tubeSideMDMT || null,
        textBoxUnitValue: equipment?.tubeSideMDMTMeasurement || ''
      }
    }
  };
};

export default EquipmentAutoPopulate;
