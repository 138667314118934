/* eslint-disable no-unused-vars */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Modal from '../..';
import Icon from '@components/icon-component';
import { settingsActions } from '@redux/slices/settings';
import { cacheActions } from '@redux/slices/cache';

import style from './style.module.scss';

const TimeToSyncModal = ({
  open,
  setOpen,
  lastSyncTime,
  pendingSync = true,
  limitExceeded = false,
  syncNowEvent = null,
  refreshDataOnlyEvent = null,
  isSettingUpdated = false,
  sendingAgainPhotosAndDocuments
}) => {
  const dispatch = useDispatch();
  const { syncReminderFrequency = 0 } = useSelector((state) => state.settings);
  return (
    <Modal
      open={open}
      setOpen={() => {
        if (syncReminderFrequency) {
          dispatch?.(settingsActions.setSyncReminderFrequency(0));
          dispatch?.(cacheActions.updateSyncingReminder(false));
          dispatch?.(settingsActions.setLastSynReminder(moment().format()));
        }

        setOpen();
      }}
      icon={'sync-time'}
      statusText={pendingSync ? 'Time to sync?' : 'Time to refresh data?'}
      modalTextUpper={() => (
        <>
          It has been{' '}
          <span
            style={{
              color: limitExceeded ? '#E02B2B' : '',
              fontWeight: limitExceeded ? 'bold' : ''
            }}
          >
            {' '}
            {lastSyncTime}
          </span>{' '}
          since you’ve refreshed data. <br />
          {pendingSync ? (
            <>
              <br /> You have <b>{pendingSync} records</b> that are{' '}
              <Link
                className="link"
                onClick={() => setOpen(false)}
                to={'/task-records?status=Pending sync'}
              >
                pending sync
              </Link>
              .<br />
            </>
          ) : (
            ''
          )}
          {isSettingUpdated ? (
            <>
              <br />
              <span
                className={style.settingSync}
                style={{
                  marginTop: '100px'
                }}
              >
                This will also sync your recent settings changes.
              </span>
            </>
          ) : (
            <></>
          )}
          {sendingAgainPhotosAndDocuments ? (
            <>
              <br />
              {sendingAgainPhotosAndDocuments && pendingSync ? (
                <div style={{ marginBottom: '14px' }}>
                  <Icon name={'line'} />
                </div>
              ) : (
                <></>
              )}
              <span
                className={style.settingSync}
                style={{
                  marginTop: '100px'
                }}
              >
                {!pendingSync
                  ? `Some photos/documents didn’t make it through in the last sync. Retry to send these now.`
                  : `This will also retry sending any photos and documents that failed during the last sync.`}
              </span>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      textBtn={'Maybe Later'}
      progressData={
        sendingAgainPhotosAndDocuments && pendingSync
          ? ''
          : 'Ensure your internet connection is stable before syncing.'
      }
      sendingAgainPhotosAndDocuments={sendingAgainPhotosAndDocuments && pendingSync ? true : false}
    >
      <div>
        {pendingSync ? (
          <div className={style.modalBtn}>
            <button className="btn-fill button-large" onClick={syncNowEvent}>
              Sync now
            </button>
          </div>
        ) : (
          ''
        )}

        {sendingAgainPhotosAndDocuments && !pendingSync ? (
          <div className={style.modalBtn}>
            <button className="btn-fill button-large" onClick={syncNowEvent}>
              RETRY SYNC
            </button>
          </div>
        ) : (
          ''
        )}
        <div className={style.modalBtn}>
          <button
            className={`btn-${
              pendingSync || sendingAgainPhotosAndDocuments ? 'border' : 'fill'
            } button-large`}
            onClick={refreshDataOnlyEvent}
          >
            Refresh data only
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TimeToSyncModal;
