/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import InputField from '@components/input-field';
import Button from '@components/button';
import DeleteModal from '@components/modal/variants/delete-modal';
import Icon from '@components/icon-component';
import Portal from '@components/portal';

import style from './style.module.scss';

const AddEquipment = ({
  open,
  closed,
  title,
  editPhoto = false,
  onSubmitPart,
  onEditPart,
  initialValue,
  id,
  deletePartEvent
}) => {
  const [deleteTask, setDeleteTask] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
    getValues
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      'part/materialName': '',
      quantity: '',
      unit: '',
      part: '',
      manufactuer: ''
    }
  });

  useEffect(() => {
    if (initialValue && id) {
      setValue('part/materialName', initialValue['part/materialName'] || '');
      setValue('quantity', parseInt(initialValue?.quantity) || '');
      setValue('unit', initialValue?.unit || '');
      setValue('part', initialValue?.part || '');
      setValue('manufactuer', initialValue?.manufactuer || '');
    }
  }, [initialValue, id]);

  const onSubmitHandler = (data) => {
    if (initialValue && id) onEditPart(data, id);
    else onSubmitPart(data);
    resetValues();
  };

  const onTest = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmitHandler)(event);
  };

  const resetValues = () => {
    setValue('part/materialName', '');
    setValue('quantity', '');
    setValue('unit', '');
    setValue('part', '');
    setValue('manufactuer', '');
  };

  return (
    <Portal>
      <div className={open ? style.equipmentMain : style.equipmentNone}>
        <div className={`${style.header} px-4 md:px-8 py-7`}>
          <p className={style.cancelClass} onClick={() => closed(false)}>
            Cancel
          </p>
          <h1 className={`${style.addClass} h1-semibold`}>{title}</h1>
          <div className={style.invisibleDiv}>Cancel</div>
        </div>
        <form onSubmit={onTest} id="addPart">
          <div className={style.addPartForm}>
            <div className="op-input-field-wrap mt-6">
              <InputField
                label="Part/Material name"
                type="text"
                inputClass="input-field input-regular"
                dot="true"
                register={register}
                name="part/materialName"
              />
            </div>

            <div className={style.grid2}>
              <div className="op-input-field-wrap mt-6">
                <InputField
                  label="Quantity"
                  type="number"
                  inputClass="input-field input-regular"
                  dot="true"
                  register={register}
                  name="quantity"
                />
              </div>
              <div className="op-input-field-wrap mt-6">
                <InputField
                  label="Unit"
                  type="text"
                  inputClass="input-field input-regular"
                  dot="true"
                  register={register}
                  name="unit"
                />
              </div>
            </div>

            <div className="op-input-field-wrap mt-6">
              <InputField
                label="Part #"
                type="text"
                inputClass="input-field input-regular"
                register={register}
                name="part"
              />
            </div>

            <div className="op-input-field-wrap mt-6">
              <InputField
                label="Manufacturer"
                type="text"
                inputClass="input-field input-regular"
                register={register}
                name="manufactuer"
              />
            </div>
          </div>

          <div className={style.footerBtn}>
            {editPhoto && (
              <Icon
                name={'Delete button'}
                alt=""
                style={{ marginRight: editPhoto ? '48px' : '0px' }}
                onClick={() => setDeleteTask(true)}
              />
            )}
            <Button
              btnClass="btn-fill button-large"
              type="submit"
              form="addPart"
              text="SAVE"
              disabled={
                !_.isEmpty(watch('part/materialName')) &&
                watch('quantity') &&
                !_.isEmpty(watch('unit'))
                  ? false
                  : true
              }
            />
          </div>
        </form>
      </div>
      <DeleteModal
        open={deleteTask}
        handleDelete={() => {
          deletePartEvent(id);
          setDeleteTask(false);
          closed(false);
        }}
        setOpen={setDeleteTask}
        confirmDeletion
        recordType="work part"
      />
    </Portal>
  );
};

export default AddEquipment;

const schema = yup.object().shape({
  'part/materialName': yup.string().required(),
  quantity: yup.number().required(),
  unit: yup.string().required(),
  part: yup.string(),
  manufactuer: yup.string()
});
