export const checkOldAndNewArray = ({
  oldArray = [],
  oldArrayMap = {},
  newArray = [],
  idName = ''
}) => {
  const newArrayMap = newArray?.reduce((acc, curr, currIndex) => {
    const id = curr[`${idName}`];
    acc[id] = currIndex;
    return acc;
  }, {});

  const newAndOldArray = oldArray?.map((x) => {
    const newIndex = newArrayMap[x[`${idName}`]];
    return newIndex !== undefined ? newArray[newIndex] : { ...x };
  });
  const newOne = newArray.filter((x) => oldArrayMap[x[`${idName}`]] === undefined);
  const combineArray = newAndOldArray?.concat(newOne);
  return combineArray;
};

export const checkOldAndNewArrayWithPromise = ({ args }) => {
  return new Promise((resolve) => {
    const response = checkOldAndNewArray({ ...args });
    resolve(response);
  });
};
