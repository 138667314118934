import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SettingsValueCard from './settings-value-card';
import SettingsInput from './settings-input';
import RadioGroup from '@components/radio-group';

import { settingsActions } from '@redux/slices/settings';
import { whenOptions } from '../helper';

const AttachmentsSettings = () => {
  const dispatch = useDispatch();
  const { settings = {} } = useSelector((state) => state.settings);

  return (
    <div className="settings-card">
      <div className="settings--values">
        <SettingsValueCard
          title={'Work order photo attachments'}
          description={'Download photo attachments on assigned work orders when data is updated:'}
        >
          <RadioGroup
            options={whenOptions}
            value={settings['autoConnectWOPhoto']}
            onChange={(value) =>
              dispatch?.(
                settingsActions.updateSettingsByName({ name: 'autoConnectWOPhoto', value })
              )
            }
          />
        </SettingsValueCard>
        <SettingsValueCard
          title={'Work order document attachments'}
          description={
            'Download document attachments on assigned work orders when data is updated:'
          }
        >
          <RadioGroup
            options={whenOptions}
            value={settings['autoConnectWODocument']}
            onChange={(value) =>
              dispatch?.(
                settingsActions.updateSettingsByName({ name: 'autoConnectWODocument', value })
              )
            }
          />
        </SettingsValueCard>
        <SettingsValueCard title={'Maximum file size'} description={'Leave blank for unlimited'}>
          <SettingsInput label={'mb(s)'} name={'maxFileSize'} />
        </SettingsValueCard>
      </div>
    </div>
  );
};

export default memo(AttachmentsSettings);
