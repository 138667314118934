/* eslint-disable  no-unused-vars */
import { Controller } from 'react-hook-form';

import Label from '@components/label';

import style from './textarea.module.scss';

const TextArea = ({ item, control, watch, errors }) => {
  return (
    <div className={style.fieldInput}>
      <Label
        reconciled={item.reconciled}
        label={item.label}
        dot={item.required}
        error={errors.name}
      />

      <Controller
        name={item.name}
        control={control}
        defaultValue={item.defaultValue}
        rules={{ ...item.rules }}
        render={({ field: { onChange, value } }) => (
          <textarea
            value={value}
            rows={3}
            onChange={(e) => onChange(e.target.value)}
            placeholder={item.placeholder}
            className={`${style.inputField} ${item.className}  ${
              errors.name && style.errorBorder
            } `}
          />
        )}
      />
    </div>
  );
};

export default TextArea;
