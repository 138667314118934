/* eslint-disable no-unused-vars */
import _ from 'lodash';
import http from './http.service';

import Promisable from './promisable.service';

import { decodeToken, setToken } from '@utilities/authentication';

const SupportService = {
  support: async (data) => {
    const [success, error] = await Promisable.asPromise(
      http.post(`/Support`, {
        emailAddress: decodeToken().email || '',
        phoneNumber: data.phoneNumber || '',
        message: data.message || ''
      })
    );
    return [success, error];
  }
};

export default SupportService;
