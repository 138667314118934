import React from 'react';

import Icon from '@components/icon-component';

import style from './date-picker.module.scss';

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  prevYearButtonDisabled,
  nextYearButtonDisabled,
  increaseYear,
  decreaseYear
}) => {
  return (
    <div className={style.iconsDiv}>
      <div>
        <button
          style={{ marginLeft: '0px' }}
          type="button"
          onClick={decreaseYear}
          disabled={prevYearButtonDisabled}
        >
          <Icon name={'chevrons-left'} alt="" />
        </button>
        <button type={'button'} onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <Icon name={'chevron-left'} alt="" />
        </button>
      </div>
      <p>
        {months[date.getMonth()]} {date.getFullYear()}
      </p>
      <div>
        <button type={'button'} onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <Icon name={'chevron-right'} alt="" width="10px" />
        </button>
        <button
          type={'button'}
          onClick={increaseYear}
          disabled={nextYearButtonDisabled}
          style={{ marginRight: '0px' }}
        >
          <Icon name={'chevrons-rights'} alt="" />
        </button>
      </div>
    </div>
  );
};

export default CustomHeader;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
