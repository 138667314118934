import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getDistance } from '@utilities/get-distance';

const useDistance = ({ longitude = null, latitude = null }) => {
  const [distance, setDistance] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const distanceUnit = useSelector((state) => state.settings.settings?.['distanceUnit']);
  const unit = proximityIndicatorMap[distanceUnit] || '';

  useEffect(() => {
    if ('geolocation' in navigator)
      navigator.geolocation.getCurrentPosition(({ coords }) => {
        setCoordinates({ latitude: coords.latitude, longitude: coords.longitude });
        if (unit && longitude !== null && latitude !== null)
          setDistance(getDistance({ unit, from: coords, to: { longitude, latitude } }));
      });
  }, [unit, longitude, latitude]);

  return { unit, distance, coordinates };
};

useDistance.propTypes = {
  latitude: PropTypes.any,
  longitude: PropTypes.any
};
const proximityIndicatorMap = { miles: 'mi', kilometers: 'km' };

export default useDistance;
