/* eslint-disable no-unused-vars */

import React from 'react';
import _ from 'lodash';

import PageHeader from '@components/page-header/page-header';

const TaskRecordPageHeader = ({
  title,
  length,
  filtersCount,
  checkedLength,
  setFilterActive,
  runs,
  templates,
  templateData,
  tasks,
  setTemplateData,
  data,
  setData,
  notShowCount = false,
  setSelectedOrderIds,
  tasksMap
}) => {
  const pageHeaderCheck = React.useMemo(() => {
    if (checkedLength === 0) return false;
    if (runs?.length > 0 || templates?.length > 0) {
      for (const el of templateData) {
        for (const task of el.tasks) {
          if (task.status === 'To do') return true;
        }
      }
      return false;
    }

    for (const el of tasks) {
      if (el.status === 'To do') return true;
    }

    return false;
  }, []);

  const clickPageHeader = () => {
    if (runs?.length > 0 || templates?.length > 0) {
      const copy = [...templateData];
      for (let i = 0; i < copy.length; i++) {
        copy[i].checked = false;

        for (let j = 0; j < copy[i].tasks.length; j++) {
          if (copy[i].tasks[j].status === 'To do') {
            copy[i].tasks[j].checked = false;
          }
        }
      }
      setTemplateData(copy);
      setSelectedOrderIds([]);
    } else {
      const copy = [...data];

      for (let i = 0; i < copy.length; i++) {
        if (copy[i].status === 'To do') copy[i].checked = false;
      }
      setSelectedOrderIds([]);
      setData([...copy]);
    }
  };
  const onChangePageHeader = (checked) => {
    let ids = [];
    if (runs?.length > 0 || templates?.length > 0) {
      const copy = [...templateData];
      for (let i = 0; i < copy.length; i++) {
        copy[i].checked = checked;

        for (let j = 0; j < copy[i].tasks.length; j++) {
          if (copy[i].tasks[j].status === 'To do') {
            copy[i].tasks[j].checked = checked;
            checked
              ? ids.push(copy[i].tasks[j].id)
              : (ids = ids.filter((x) => x !== copy[i].tasks[j].id));
          }
        }
      }
      setTemplateData(copy);
      setSelectedOrderIds([...ids]);
    } else {
      const copy = [...data];

      for (let i = 0; i < copy.length; i++) {
        if (copy[i].status === 'To do' && tasksMap[copy[i].id]) {
          copy[i].checked = checked;
          checked ? ids.push(copy[i].id) : (ids = ids.filter((x) => x !== copy[i].id));
        }
      }
      setSelectedOrderIds([...ids]);
      setData(copy);
    }
  };
  return (
    <PageHeader
      title={title}
      length={length}
      count={filtersCount}
      checkedLength={checkedLength}
      setFilterActive={setFilterActive}
      checkbox={pageHeaderCheck}
      checked={
        runs?.length > 0 || templates?.length > 0
          ? templateData?.every(({ checked }) => checked)
          : tasks?.every(({ status, checked }) => (status === 'To do' ? checked : true))
      }
      onClick={clickPageHeader}
      onChange={onChangePageHeader}
      notShowCount={notShowCount}
    />
  );
};

export default React.memo(TaskRecordPageHeader, _.isEqual);
