import Refresh from './refresh.svg';
import NewOnline from './newOnline.svg';
import NewOffline from './newOffline.svg';
import Activity from './activity.svg';
import AddDocument from './add-document.svg';
import AddField from './add-field.svg';
import AddGlance from './addGlance.svg';
import AddIcon from './add-icon.svg';
import AirPlay from './air-play.svg';
import Attachment from './attachment.svg';
import BackIcon from './back-icon.svg';
import BlueCross from './blue-cross.svg';
import BlueEye from './blueEye.svg';
import BlueFilter from './blue-filter.svg';
import Bookmark from './book-mark.svg';
import BookmarkGrey from './bookmark-grey.svg';
import BookMarkIcon from './book-mark-icon.svg';
import Calendar from './calendar.svg';
import CalendarBlue from './calendar-blue.svg';
import Camera from './camera.svg';
import CaretGrey from './caret-grey.svg';
import CheckIcon from './check-icon.svg';
import CheckId from './checkId.svg';
import CheckIn from './check-in.svg';
import CheckTask from './check-task.svg';
import CheckWhite from './check-white.svg';
import Chevron from './chevron.svg';
import ChevronDown from './chevron-down.svg';
import ChevronDownBlueCopy from './chevron-down-blue - Copy.svg';
import ChevronDownBlue from './chevron-down-blue.svg';
import ChevronLeft from './chevron-left.svg';
import ChevronRight from './chevron-right.svg';
import ChevronsLeft from './chevrons-left.svg';
import ChevronsRights from './chevrons-rights.svg';
import ChevronUp from './chevron-up.svg';
import Children from './children.svg';
import Close from './close.svg';
import CloseIcon from './close-icon.svg';
import CloseIconGray from './cross-icon-regular.svg';
import Complete from './complete.svg';
import Completebtn from './completebtn.svg';
import DelCircle from './delCircle.svg';
import DeleteButton from './Delete button.svg';
import Delete from './delete.svg';
import DeleteImageIcon from './delete-image-icon.svg';
import DelIcon from './delIcon.svg';
import DemoPic from './demoPic.png';
import DownloadEdit from './download-edit.svg';
import DownloadIcon from './downloadIcon.png';
import Drag from './drag.svg';
import EditBtn from './edit-btn.svg';
import EditCircle from './edit-circle.svg';
import EditIcon from './editIcon.svg';
import EmailGuy from './email-guy.svg';
import Equipment from './equipment.svg';
import EquipmentBlue from './equipment-blue.svg';
import EquipmentWhite from './equipment-white.svg';
import EyeHide from './eye-hide.svg';
import EyeHideBlue from './eye-hide-blue.svg';
import EyeShow from './eye-show.svg';
import SuccessIcon from './success.svg';
import ErrorIcon from './error.svg';
import ErrorWhiteIcon from './error-white.svg';
import TaskGrey from './task-grey.svg';

import Failed from './failed.svg';
import FileType from './File type.svg';
import File from './file.svg';
import Filter from './filter.svg';
import FilterBlack from './filter-black.svg';
import FilterBlue from './filter-blue.png';
import FilterGrey from './filter-grey.svg';
import ForwardIcon from './forward-icon.svg';
import GreyEye from './greyEye.svg';
import Help from './help.svg';
import HelpRed from './help-red.svg';
import HideFilter from './hide-filter.svg';
import HomeIcon from './home-icon.svg';
import HomeWhiteIcon from './home-icon-white.svg';
import Hse from './hse.svg';
import HseEvent from './hse-event.svg';
import Huzzah from './Huzzah.svg';
import IconPng from './Icon.png';
import IconSvg from './Icon.svg';
import Image from './image.svg';
import Ins from './ins.svg';
import Inspection from './inspection.svg';
import InspectionBlack from './inspection-black.svg';
import InspectionGrey from './inspection-grey.svg';
import InspectionBlue from './inspection-blue.svg';
import InspectionHover from './inspection-hover.svg';
import LabelReconciled from './labelReconciled.svg';
import Line from './line.svg';
import Link from './link.svg';
import ListingDetailMenu from './Listing detail menu.svg';
import Loading from './Loading.gif';
import LocateGrey from './locate-grey.svg';
import Location from './location.svg';
import CloseCircleGrey from './close-circle-grey.svg';
import LocationBlack from './location-black.svg';
import LocationHover from './location-hover.svg';
import Logout from './logout.svg';
import MapPin from './map-pin.svg';
import MenuIcon from './menu-icon.svg';
import Message from './message.svg';
import MinusIconTree from './minus-icon-tree.svg';
import Moc from './moc.svg';
import More from './more.svg';
import MoreInfo from './more-info.svg';
import NewAssetTask from './new-asset-task.svg';
import NewEquipment from './new-equipment.svg';
import Next from './next.svg';
import OffSite from './off-site.svg';
import OpliiLogo from './oplii-logo.png';
import PendingSync from './pending-sync.svg';
import Phone from './phone.svg';
import Pipeline from './pipeline.svg';
import Plus from './plus.svg';
import PlusIconTree from './plus-icon-tree.svg';
import PlusWhite from './plus-white.svg';
import Prev from './prev.svg';
import RecentArrow from './recentArrow.svg';
import Rotate from './rotate.svg';
import SaveInProgress from './save-in-progress.svg';
import Schedule from './schedule.svg';
import SearchClear from './search-clear.svg';
import SearchField from './search-field.svg';
import SearchFieldActive from './search-field-active.svg';
import Settings from './settings.svg';
import Shape from './Shape.png';
import ShapeSvg from './shape.svg';
import Site from './site.svg';
import SyncGrey from './sync-grey.svg';
import SyncIndicator from './Sync-indicator.svg';
import SyncModalIcon from './sync-modal-icon.svg';
import SyncTime from './sync-time.svg';
import Task from './task.svg';
import TaskBlue from './task-blue.svg';
import TaskHover from './task-hover.svg';
import UpdateApp from './update-app.svg';
import Users from './users.svg';
import WhiteCheck from './white-check.svg';
import GreenCheck from './green-check.svg';
import WorkOrder from './work-order.svg';
import WorkOrderHover from './work-order-hover.svg';
import WorkOrders from './work-orders.svg';
import WorkOrderTask from './work-order-task.svg';
import WorkStatus from './work-status.svg';
import XDefault from './xdefault.svg';
import BlueFlagIcon from './flag-blue.svg';
import BellRedIcon from './bellred.svg';
import CheckGrey from './checkbox.svg';

import OfflineSync from './offline-sync.svg';
import Mobile from './mobile.svg';
import Mail from './mail.svg';
import ReconciledIcon from './reconciled-icon.svg';

import Clock from './clock-regular.svg';
import FileTypeBlue from './file-type-blue.svg';
import ClockBlue from './clock-regular-blue.svg';
import CameraIcon from './cam-icon.svg';
import NoteIcon from './note-icon.svg';
import NoteBlueIcon from './note-blue-background.svg';
import MenuLogoIcon from './menu-logo.svg';
import cameraWhite from './cameraWhite.svg';
import FileTypeWhite from './file-type-white.svg';
import removeBookmarkIcon from './removeBookmark.svg';
import AddWorkRequestIcon from './add-work-request.svg';
import Up from './up.svg';
import Down from './down.svg';
import PipelineWhiteIcon from './pipeline-white.svg';
import FeatherLink from './link-icon.svg';
import AreaDropdownCross from './area-dropdown-cross.svg';
import OpenInOpliiDesktop from './open-in-oplii-desktop.svg';
import EditPencil from './edit-pencil.svg';
import Attached from './attached.svg';
import DeAttached from './de-attached.svg';
import AttachedPSVModalIcon from './attached-psv-modal-icon.svg';
import DeAttachedPSVModalIcon from './de-attached-psv-modal-icon.svg';
import AssetBundle from './asset-bundle.svg';
import BrokenBulb from './broken-bulb.svg';
import HseEventBlue from './hse-event-blue.svg';
import ResetAppIcon from './reset-app-icon.svg';
import WorkOrderType from './work-order-type.svg';
import CancelAndRetry from './cancel-retry.svg';
import ArrowRight from './arrow-right.svg';
import CircledUser from './circled-user.svg';
import IdentifierIcon from './identifier-icon.svg';
import LocationIcon from './location-icon.svg';

import InfoBlue from './info-blue.svg';
import InfoWhite from './info-white.svg';
import DeSelectIcon from './de-select-icon.svg';

export default {
  'feather-link': FeatherLink,
  'de-select-icon': DeSelectIcon,
  'info-blue': InfoBlue,
  'info-white': InfoWhite,
  'identifier-icon': IdentifierIcon,
  'location-icon': LocationIcon,
  'cancel-retry': CancelAndRetry,
  'work-order-type': WorkOrderType,
  'reset-app-icon': ResetAppIcon,
  'hse-event-blue': HseEventBlue,
  'pipeline-white': PipelineWhiteIcon,
  'area-dropdown-cross': AreaDropdownCross,
  'open-in-oplii-desktop': OpenInOpliiDesktop,
  'arrow-right': ArrowRight,
  'circled-user': CircledUser,

  addWorkRequest: AddWorkRequestIcon,
  up: Up, // reused
  'chevron-up': ChevronUp, //resued
  down: Down, //not used
  reconciled: ReconciledIcon, //not used
  removeBookmark: removeBookmarkIcon, //not used
  menuLogo: MenuLogoIcon,
  cameraOrange: CameraIcon,
  noteBlue: NoteBlueIcon, //not used
  note: NoteIcon, //not used
  failed: Failed,
  file: File, // not used
  'File type': FileType,
  'file-type-blue': FileTypeBlue,
  'file-type-white': FileTypeWhite,
  filter: Filter,
  'filter-black': FilterBlack,
  'filter-blue': FilterBlue,
  'filter-grey': FilterGrey, //not used
  'forward-icon': ForwardIcon,
  greyEye: GreyEye,
  help: Help,
  'help-red': HelpRed,
  'hide-filter': HideFilter,
  'home-icon': HomeIcon,
  'home-icon-white': HomeWhiteIcon,
  hse: Hse,
  'hse-event': HseEvent,
  Huzzah: Huzzah,
  'Icon.png': IconPng, //not used
  Icon: IconSvg,
  image: Image, // not used
  ins: Ins,
  inspection: Inspection, //not used
  'inspection-black': InspectionBlack,
  'inspection-grey': InspectionGrey,
  'inspection-blue': InspectionBlue, //not used
  'inspection-hover': InspectionHover, // not used
  labelReconciled: LabelReconciled,
  line: Line, // not used
  link: Link,
  'Listing detail menu': ListingDetailMenu,
  'Loading.gif': Loading, // not use
  'locate-grey': LocateGrey,
  location: Location, //not used
  mobile: Mobile,
  mail: Mail,
  activity: Activity,

  'add-document': AddDocument, // not used
  'close-circle-grey': CloseCircleGrey,
  'cross-icon-grey': CloseIconGray,
  'offline-sync': OfflineSync, // not used
  'add-field': AddField,

  addGlance: AddGlance,

  'add-icon': AddIcon,

  'air-play': AirPlay,

  attachment: Attachment,

  'back-icon': BackIcon, // not used

  'blue-cross': BlueCross,
  'flag-blue': BlueFlagIcon,

  blueEye: BlueEye,

  'blue-filter': BlueFilter,

  Bookmark: Bookmark, //not used

  'bookmark-grey': BookmarkGrey,

  'book-mark-icon': BookMarkIcon,

  calendar: Calendar,

  'calendar-blue': CalendarBlue,
  clock: Clock,
  'clock-blue': ClockBlue,
  camera: Camera,
  cameraWhite, //not used

  refresh: Refresh,
  newOnline: NewOnline,
  newOffline: NewOffline,
  'work-order-task': WorkOrderTask,
  plus: Plus,
  'plus-icon-tree': PlusIconTree,
  'plus-white': PlusWhite,
  prev: Prev,
  recentArrow: RecentArrow,
  'remove-glance': 'remove-glance', //not used
  rotate: Rotate,
  'save-in-progress': SaveInProgress,
  schedule: Schedule,
  'search-clear': SearchClear,
  'search-field': SearchField, //not used
  'search-field-active': SearchFieldActive, //not used
  settings: Settings,
  shape: Shape,
  'shape-svg': ShapeSvg, //not used
  site: Site, //not used
  Site: Site,
  'sync-grey': SyncGrey,
  'sync-indicator': SyncIndicator, //not used
  'sync-modal-icon': SyncModalIcon,
  'sync-time': SyncTime,
  task: Task,
  'task-blue': TaskBlue,
  'task-hover': TaskHover, //not used
  'update-app': UpdateApp,
  users: Users,
  'white-check': WhiteCheck,
  'green-check': GreenCheck,
  'work-order': WorkOrder,
  'work-order-hover': WorkOrderHover, // not used
  'work-orders': WorkOrders,
  'work-status': WorkStatus,
  xdefault: XDefault,
  'location-black': LocationBlack,
  'location-hover': LocationHover, // not used
  logout: Logout,
  'map-pin': MapPin,
  'menu-icon': MenuIcon,
  message: Message, //not used
  'minus-icon-tree': MinusIconTree,
  moc: Moc,
  more: More,
  'more-info': MoreInfo,
  'new-asset-task': NewAssetTask,
  'new-equipment': NewEquipment,
  next: Next,
  'off-site': OffSite,
  'oplii-logo': OpliiLogo,
  'pending-sync': PendingSync,
  phone: Phone, //not used
  pipeline: Pipeline,
  'task-grey': TaskGrey,

  'caret-grey': CaretGrey, //not used
  'check-icon': CheckIcon,
  'check-grey': CheckGrey, // not used
  checkId: CheckId,
  'check-in': CheckIn,
  'check-task': CheckTask,
  'check-white': CheckWhite,
  chevron: Chevron, //not used
  'chevron-down': ChevronDown,
  'chevron-down-blue - Copy': ChevronDownBlueCopy, // not used
  'chevron-down-blue': ChevronDownBlue,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevrons-left': ChevronsLeft,
  'chevrons-rights': ChevronsRights,

  children: Children,
  close: Close,
  'close-icon': CloseIcon,
  complete: Complete,
  completebtn: Completebtn,
  delCircle: DelCircle,
  'Delete button': DeleteButton,
  delete: Delete,
  'delete-image-icon': DeleteImageIcon,
  delIcon: DelIcon,
  demoPic: DemoPic, // not used
  'download-edit': DownloadEdit,
  downloadIcon: DownloadIcon, // not used
  drag: Drag,
  'edit-btn': EditBtn,
  'edit-circle': EditCircle,
  editIcon: EditIcon,
  'email-guy': EmailGuy,
  Equipment: Equipment,
  equipment: Equipment,
  'equipment-blue': EquipmentBlue,
  'equipment-white': EquipmentWhite,
  'eye-hide': EyeHide,
  'eye-hide-blue': EyeHideBlue,
  'eye-show': EyeShow, // not used
  bellred: BellRedIcon,

  success: SuccessIcon, //not used
  error: ErrorIcon,
  'error-white': ErrorWhiteIcon,
  'edit-pencil': EditPencil,
  attached: Attached,
  'de-attached': DeAttached,
  'attached-psv-modal': AttachedPSVModalIcon,
  'de-attached-psv-modal': DeAttachedPSVModalIcon,
  'asset-bundle': AssetBundle,
  'broken-bulb': BrokenBulb
};
