/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { store } from 'redux/store';

export const WorkTypes = {
  NewWorkRequest: 'new-work-request',
  NewHSE: 'new-hse',
  NewWorkOrder: 'new-work-order',
  WorkOrders: 'work-orders',
  Inspection: 'inspection',
  NewInspection: 'new-inspection',
  InspectionAndWorkOrder: 'inspection,work-orders',
  WorkOrderAndInspection: 'work-orders,inspection',
  NewCheck: 'new-check',
  Check: 'check'
};

export const getWorkOrderOrInspectionFilterDataOnIds = ({
  orderIds = '',
  tasksList = [],
  HSEEventList = [],
  inspectionsList = [],
  checksList = [],
  picklistsMap = {}
}) => {
  // assigned work order map
  const tasksListDataMap = tasksList.reduce((acc, curr) => {
    acc[curr?.workOrderNumber] = curr;
    return acc;
  }, {});
  // new work order map
  const locallyCreatedTasksListDataMap = tasksList.reduce((acc, curr) => {
    acc[curr?.woId] = curr;
    return acc;
  }, {});
  // assigned inspection map
  const inspectionListDataMap = inspectionsList?.reduce((acc, curr) => {
    acc[curr?.id] = curr;
    return acc;
  }, {});
  const newInspectionListDataMap = inspectionsList
    ?.filter((x) => x?.newInspection)
    ?.reduce((acc, curr) => {
      acc[curr?.id] = curr;
      return acc;
    }, {});
  const HSEEventListDataMap = HSEEventList.reduce((acc, curr) => {
    acc[curr?.id] = curr;
    return acc;
  }, {});

  const ChecksListDataMap = checksList.reduce((acc, curr) => {
    acc[curr?.id] = curr;
    return acc;
  }, {});

  const data = orderIds.split(',').map((id) => {
    const assignedInspectionTask = tasksListDataMap[id];
    const assignedInspection = inspectionListDataMap[id];
    const newInspectionTask = locallyCreatedTasksListDataMap[id];
    const newInspection = newInspectionListDataMap[id];
    const newHseEvent = HSEEventListDataMap[id];
    const newCheck = ChecksListDataMap[id];
    if (!_.isEmpty(assignedInspectionTask)) {
      return {
        ...assignedInspectionTask,
        status: ['To do', 'In progress', 'Pending sync'].includes(assignedInspectionTask.status)
          ? assignedInspectionTask?.status
          : 'To do',
        priorityId: assignedInspectionTask.priority,
        priority: picklistsMap[assignedInspectionTask.priority]?.value,
        workStatus: picklistsMap[assignedInspectionTask.status]?.value
      };
    } else if (!_.isEmpty(assignedInspection)) {
      return {
        ...assignedInspection,
        status: ['To do', 'In progress', 'Pending sync'].includes(assignedInspection?.status)
          ? assignedInspection?.status
          : 'To do'
      };
    } else if (!_.isEmpty(newInspectionTask)) {
      return {
        ...newInspectionTask,
        status: ['To do', 'In progress', 'Pending sync'].includes(newInspectionTask?.status)
          ? newInspectionTask?.status
          : 'To do'
      };
    } else if (!_.isEmpty(newInspection)) {
      return {
        ...newInspection,
        status: ['To do', 'In progress', 'Pending sync'].includes(newInspection?.status)
          ? newInspection?.status
          : 'To do'
      };
    } else if (!_.isEmpty(newHseEvent))
      return {
        ...newHseEvent
      };
    else if (!_.isEmpty(newCheck))
      return {
        ...newCheck
      };
  });
  return data;
};

export const getWorkOrderTitle = (selectedTasksList, currentPageOfSelectedTasks) => {
  if (_.size(selectedTasksList) > 0) {
    const workOrderNumber =
      selectedTasksList[currentPageOfSelectedTasks - 1]?.workOrderNumber || '';
    const workOrderTitle = selectedTasksList[currentPageOfSelectedTasks - 1]?.title || '';
    return `${workOrderNumber} - ${workOrderTitle}`;
  }
};

export const getInspectionTitle = (selectedTasksList, currentPageOfSelectedTasks) => {
  const { inspection = {} } = store.getState();
  const { inspectionTypesMap = {} } = inspection;
  return inspectionTypesMap[selectedTasksList[currentPageOfSelectedTasks - 1]?.inspectionTypeId]
    ?.value;
};

export const getWorkOrderAndInspectionTitle = (selectedTasksList, currentPageOfSelectedTasks) => {
  const { inspection = {} } = store.getState();
  const { inspectionTypesMap = {} } = inspection;
  const selectedTaskType = selectedTasksList[currentPageOfSelectedTasks - 1]?.type || '';
  if (selectedTaskType === 'Work Order') {
    const workOrderNumber =
      selectedTasksList[currentPageOfSelectedTasks - 1]?.workOrderNumber || '';
    const workOrderTitle = selectedTasksList[currentPageOfSelectedTasks - 1]?.title || '';
    return `${workOrderNumber} - ${workOrderTitle}`;
  }
  if (selectedTaskType === 'Inspection') {
    const inspectionTypeMap =
      inspectionTypesMap[selectedTasksList[currentPageOfSelectedTasks - 1]?.inspectionTypeId]
        ?.value;
    return inspectionTypeMap;
  }
};

export const pageHeader = (workType, selectedTasksList, currentPageOfSelectedTasks) => {
  if (workType === WorkTypes.NewWorkRequest) return 'add New work request';
  if (workType === WorkTypes.NewWorkOrder) return 'add New work order';
  if (workType === WorkTypes.NewInspection) return 'add New Inspection';
  if (workType === WorkTypes.Inspection)
    return getInspectionTitle(selectedTasksList, currentPageOfSelectedTasks);
  if (
    workType === WorkTypes.InspectionAndWorkOrder ||
    workType === WorkTypes.WorkOrderAndInspection
  )
    return getWorkOrderAndInspectionTitle(selectedTasksList, currentPageOfSelectedTasks);
  if (workType === WorkTypes.WorkOrders)
    return getWorkOrderTitle(selectedTasksList, currentPageOfSelectedTasks);
  else return <></>;
};

export const convertBase64ToFile = (base64, filename, type) => {
  const str = `data:${type};base64,`;
  const file = str.concat(base64);
  const mime = file.split(',')[0].split(':')[1].split(';')[0];
  const binary = atob(file.split(',')[1]);
  const array = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) array[i] = binary.charCodeAt(i);
  const blob = new Blob([array], { type: mime });
  return new File([blob], filename, { type: mime });
};

export const getSitesOfTask = ({ selected }) => {
  const { sites = [], siteId = '' } = selected;
  if (_.size(selected.sites) > 0) return [siteId || '', ...sites.filter((site) => site !== siteId)];
  else return [siteId];
};
export const getEquipmentsOfTask = ({ selected }) => {
  const { equipments = [], equipmentId = '' } = selected;
  if (_.size(equipments) > 0)
    return [equipmentId || '', ...equipments.filter((equipment) => equipment !== equipmentId)];
  else return [equipmentId];
};

export const getStructureOfTask = ({ selected }) => {
  const { structures = [], structureId = '' } = selected;
  if (_.size(structures) > 0)
    return [structureId || '', ...structures.filter((str) => str !== structureId)];
  else return [structureId];
};

export const getPipelineOfTask = ({ selected }) => {
  const { pipelines = [], pipelineId = '' } = selected;
  if (_.size(pipelines) > 0)
    return [pipelineId || '', ...pipelines.filter((pipe) => pipe !== pipelineId)];
  else return [pipelineId];
};

export const getSelectedTaskParts = ({ selectedTask }) => {
  const parts =
    selectedTask?.parts?.map((part) => {
      return {
        'part/materialName': part?.PartName || '',
        quantity: part?.Quantity || '',
        unit: part?.Unit || '',
        part: part?.PartNum || '',
        manufactuer: part?.Manufacturer || '',
        id: part?.Id || ''
      };
    }) || [];
  return parts;
};
export const getSelectedTaskPhotos = ({ selectedTask }) => {
  return selectedTask?.photos
    ?.filter((p) => p?.content?.id)
    ?.map((photo) => {
      return {
        caption: photo?.caption || null,
        category: photo?.photoCategory || null,
        id: photo?.id || uuidv4(),
        image: photo?.content
      };
    });
};
export const getSelectedSignaturePhotos = ({ image }) => {
  return convertBase64ToFile(image?.content, '1', 'image/png');
};

export const getSelectedTaskDocuments = ({ selectedTask }) => {
  return selectedTask?.documents
    ?.filter((p) => p?.content)
    ?.map((document) => {
      return {
        category: document?.documentCategory || null,
        id: document?.id || uuidv4(),
        file: {
          file: { ...document?.content },
          id: document?.content?.id
        }
      };
    });
};

export const getCurrentWorkOrderOrInspectionData = async ({ orderIds = [] }) => {
  const { tasks, picklists, inspection, hseEvents, checks } = store.getState();
  const { tasksList = [] } = tasks;
  const { inspectionsList = [] } = inspection;
  const { HSEEventList = [] } = hseEvents;
  const { checksList = [] } = checks;

  const { picklistsMap } = picklists;

  if (
    orderIds &&
    (tasksList.length || inspectionsList?.length || HSEEventList?.length || checksList?.length)
  ) {
    const localCreatedTasks = getWorkOrderOrInspectionFilterDataOnIds({
      orderIds,
      tasksList,
      inspectionsList,
      HSEEventList,
      checksList,
      picklistsMap
    });
    return { localCreatedTasks };
  } else {
    return {
      formSetData: {},
      localCreatedTasks: {}
    };
  }
};

export const getErrorsOnField = ({ errors }) => {
  const firstError = Object.keys(errors).reduce((field, a) => {
    return errors[field] ? field : a;
  }, null);
  return firstError;
};
