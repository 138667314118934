/* eslint-disable no-unused-vars */
import React from 'react';

import Modal from '../..';
import Icon from '@components/icon-component';

import style from './style.module.scss';

const ReadyToGoModal = ({
  open,
  setOpen,
  syncRecord = false,
  syncCheck = false,
  syncWorkOrder = false,
  syncWorkRequest = false,
  syncInspection = false,
  syncHSEEvent = false,
  syncOffline = null,
  refreshBtn = null,
  syncWorkOrderOrRequestOrInspectionEvent = null,
  showRetryPhotoCount,
  pendingOrderPhotosDocumentsLength,
  showRetryDocumentCount
}) => {
  const syncModalIcon = React.useMemo(() => {
    if (
      syncRecord ||
      syncCheck ||
      syncWorkOrder ||
      syncWorkRequest ||
      syncInspection ||
      syncHSEEvent
    )
      return <Icon name={'sync-time'} />;
    else if (syncOffline) return <Icon name={'sync-modal-icon'} />;
  }, [
    syncRecord,
    syncCheck,
    syncWorkOrder,
    syncWorkRequest,
    syncInspection,
    syncOffline,
    syncHSEEvent
  ]);

  const statusText = React.useMemo(() => {
    if (
      syncRecord ||
      syncCheck ||
      syncWorkOrder ||
      syncWorkRequest ||
      syncInspection ||
      syncHSEEvent
    )
      return <>Is it ready to go?</>;
    else if (syncOffline) return <>{syncOffline.title}</>;
  }, [
    syncRecord,
    syncCheck,
    syncWorkOrder,
    syncWorkRequest,
    syncInspection,
    syncOffline,
    syncHSEEvent
  ]);

  const modalTextUpper = React.useMemo(() => {
    if (syncRecord) return <>Only this one record will be synced.</>;
    else if (syncCheck) return <>Only this one check will be synced.</>;
    else if (syncWorkOrder) return <>Only this one work order will be synced.</>;
    else if (syncWorkRequest) return <>Only this one work request will be synced.</>;
    else if (syncInspection) return <>Only this one inspection will be synced.</>;
    else if (syncHSEEvent) return <>Only this one HSE event will be synced.</>;
    else if (syncOffline) return <>{syncOffline.subtitle}</>;
  }, [
    syncRecord,
    syncCheck,
    syncWorkOrder,
    syncWorkRequest,
    syncInspection,
    syncOffline,
    syncHSEEvent
  ]);

  const textBtn = React.useMemo(() => {
    if (
      syncRecord ||
      syncCheck ||
      syncWorkOrder ||
      syncWorkRequest ||
      syncInspection ||
      syncHSEEvent
    )
      return <>Maybe Later</>;
    else if (syncOffline) return <>{syncOffline.CloseBtnText}</>;
  }, [
    syncRecord,
    syncCheck,
    syncWorkOrder,
    syncWorkRequest,
    syncInspection,
    syncOffline,
    syncHSEEvent
  ]);

  const modalInnerBody = React.useMemo(() => {
    if (syncRecord)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={
                syncWorkOrderOrRequestOrInspectionEvent && syncWorkOrderOrRequestOrInspectionEvent
              }
            >
              Sync record
            </button>
          </div>
          {refreshBtn && (
            <div className={style.modalBtn}>
              <button className="btn-border button-large">Refresh data only</button>
            </div>
          )}
        </div>
      );
    else if (syncCheck)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={
                syncWorkOrderOrRequestOrInspectionEvent && syncWorkOrderOrRequestOrInspectionEvent
              }
            >
              Sync check
            </button>
          </div>
          {refreshBtn && (
            <div className={style.modalBtn}>
              <button className="btn-border button-large">Refresh data only</button>
            </div>
          )}
        </div>
      );
    else if (syncWorkOrder)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={
                syncWorkOrderOrRequestOrInspectionEvent && syncWorkOrderOrRequestOrInspectionEvent
              }
            >
              Sync work order
            </button>
          </div>
          {refreshBtn && (
            <div className={style.modalBtn}>
              <button className="btn-border button-large" onClick={refreshBtn}>
                Refresh data only
              </button>
            </div>
          )}
        </div>
      );
    else if (syncOffline)
      return (
        <div>
          <div className={style.modalBtn}>
            <button className={`${style.disabledBtn} button-large`} disabled>
              {syncOffline.actionbtntext}
            </button>
          </div>
          {refreshBtn && (
            <div className={style.modalBtn}>
              <button className={`${style.disabledBtn} button-large`} disabled>
                Refresh data only
              </button>
            </div>
          )}
        </div>
      );
    else if (syncWorkRequest)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={
                syncWorkOrderOrRequestOrInspectionEvent && syncWorkOrderOrRequestOrInspectionEvent
              }
            >
              Sync work request
            </button>
          </div>
          {refreshBtn && (
            <div className={style.modalBtn}>
              <button className="btn-border button-large">Refresh data only</button>
            </div>
          )}
        </div>
      );
    else if (syncInspection)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={
                syncWorkOrderOrRequestOrInspectionEvent && syncWorkOrderOrRequestOrInspectionEvent
              }
            >
              Sync Inspection
            </button>
          </div>
          {refreshBtn && (
            <div className={style.modalBtn}>
              <button className="btn-border button-large">Refresh data only</button>
            </div>
          )}
        </div>
      );
    else if (syncHSEEvent)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={
                syncWorkOrderOrRequestOrInspectionEvent && syncWorkOrderOrRequestOrInspectionEvent
              }
            >
              SYNC HSE EVENT
            </button>
          </div>
          {refreshBtn && (
            <div className={style.modalBtn}>
              <button className="btn-border button-large">Refresh data only</button>
            </div>
          )}
        </div>
      );
  }, [
    syncRecord,
    syncCheck,
    syncWorkOrder,
    syncWorkRequest,
    syncInspection,
    syncOffline,
    syncHSEEvent,
    showRetryPhotoCount,
    pendingOrderPhotosDocumentsLength,
    showRetryDocumentCount
  ]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={syncModalIcon}
      statusText={statusText}
      modalTextUpper={modalTextUpper}
      textBtn={textBtn}
      progressDataColor={syncOffline && '#F26531'}
      progressData={
        syncOffline
          ? 'You’ll need a stable internet connection before you can sync.'
          : 'Ensure your internet connection is stable before syncing.'
      }
    >
      {modalInnerBody}
    </Modal>
  );
};

export default ReadyToGoModal;
