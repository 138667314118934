/* eslint-disable no-unused-vars */

import React, { memo } from 'react';

import Icon from '@components/icon-component';

import style from './style.module.scss';

const Dropdown = ({ Component, open, items, className, up }) => {
  return (
    <div>
      {open && (
        <div
          className={`${style.dropdownMenu} ${className}`}
          style={{
            top: up ? `${up}px` : '16px'
          }}
        >
          {items?.map((x) => (
            <div className={style.item} key={x?.key} onClick={x?.onClick}>
              <Icon name={x?.iconName} />
              <p>{x?.label}</p>
            </div>
          ))}
        </div>
      )}

      {Component}
    </div>
  );
};
export default memo(Dropdown);
