/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getDataPoints } from '@pages/settings/helper';
import DataSnippets from '@components/data-snippet';
import Icon from '@components/icon-component';

import { getAppDispatch } from '@utilities/dispatch.util';
import { formWrapperActions } from '@redux/slices/form-wrapper';
import { useOpenLink } from '@hooks/useOpenLink';

import style from './site-card.module.scss';

const SiteInnerCard = ({
  sites,
  setShowSites,
  showValue,
  siteIds,
  showSites,
  divider = false,
  appliesTo,
  taskId,
  assetId
}) => {
  const [openLink, setOpenLink] = useOpenLink({
    taskId,
    assetId
  });
  const dispatch = getAppDispatch?.();

  const {
    grey,
    name,
    unit,
    parent,
    normal,
    lowGap,
    siteType,
    distance,
    hideLocation,
    highlightName,
    areaHierarchy,
    highlight = '',
    index = 0,
    link,
    pipelineListing = false
  } = sites;

  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state.picklists
  );

  const siteDisplayFields =
    useSelector((state) => state.settings.settings?.['siteDisplayFields']) || '';

  const area = areaHierarchyTreeDataMap[areaHierarchy]?.title;
  const dataPoints = getDataPoints(
    { ...sites, type: siteType },
    siteDisplayFields,
    'site',
    picklistsMap
  );

  let [highlightNames, highlightType] = !_.isEmpty(highlight) ? highlight.split(' ') : [];
  highlightNames = highlightName ? highlight : highlightNames;

  if (!highlightType) highlightType = highlightNames;

  const onClickOpliiDesktopLinkEvent = (desktopLink) => {
    setOpenLink((prev) => !prev);

    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId,
        assetId
      })
    );
  };

  return (
    <div className={style.cardInner}>
      <div className={style.cardContent}>
        {showValue && (
          <div
            style={{
              position: 'relative'
            }}
          >
            <DataSnippets
              variant="secondary"
              link={link}
              title={parent ? 'View all work at' : area}
              subtitle={name || ''}
              dataPoints={dataPoints}
              iconName={!hideLocation && 'map-pin'}
              kmValue={distance || ''}
              unit={unit || ''}
              highlightNames={highlightNames}
              highlightType={highlightType}
              className={`${pipelineListing && style.paddingOnSiteInfo}`}
              setOpenAll={() => {
                setShowSites && setShowSites((prev) => !prev);
              }}
              value={
                showValue && index === 0 && siteIds.length > 1 ? `+${siteIds.length - 1}` : null
              }
              divider={index === 0 && !showSites ? false : divider}
              onClickOpliiDesktopLinkEvent={onClickOpliiDesktopLinkEvent}
            />
            {openLink && link && (
              <div
                className={style.showLink}
                onClick={(e) => {
                  e?.stopPropagation();

                  appliesTo
                    ? dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(link))
                    : window.open(link, '_self');

                  setOpenLink((prev) => !prev);
                }}
              >
                <div>
                  <Icon name={'map-pin'} />
                  <p>Go to site</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteInnerCard;
