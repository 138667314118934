import _ from 'lodash';

import { decodeToken } from '@utilities/authentication';

const PermissionRestrict = ({ roles = '', children, fallback = null }) => {
  const permissions = decodeToken()?.permissions || {};
  return permissions[roles] ? (
    <>{children}</>
  ) : (
    <>{_.isFunction(fallback) ? fallback() : fallback}</>
  );
};

export default PermissionRestrict;
