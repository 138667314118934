/* eslint-disable no-unused-vars */
import React, { memo, useMemo } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useToastContext } from '@context/toaster.context';

import Icon from '@components/icon-component';
import AssetBundle from './asset-bundle';

import { decodeToken } from '@utilities/authentication';
import AuthService from '@services/auth.service';

import './more-menu.scss';
import style from './style.module.scss';

const MoreMenu = ({ moreMenuToggle, setMoreMenuToggle }) => {
  const { ToasterUtil } = useToastContext();

  const permissions = useMemo(() => {
    return decodeToken()?.permissions || {};
  }, []);

  const menuItems = useMemo(() => {
    return getMenuItems(permissions, ToasterUtil);
  }, [permissions]);

  return (
    <div
      style={{
        borderLeft: '0.5px solid #CBD2D9',
        marginLeft: !moreMenuToggle && '-300px'
      }}
      className={`${
        moreMenuToggle ? 'open' : ''
      } op-more-menu fixed bottom-0 h-full bg-white z-9 w-full md:ml-16 overflow-auto md:pb-0 pb-28 left-0 md:left-8 myMenuHeight `}
    >
      <div className="md:pl-8 pl-4  md:pt-16 pt-8">
        <div className="pb-5">
          <Icon name={'menuLogo'} />
        </div>
        <h3 className="h3 text-grey-800 pb-2">
          {decodeToken().firstName} {decodeToken().lastName}
        </h3>
        <p className="body-regular text-grey-800 pb-8">{decodeToken().email}</p>
      </div>

      {menuItems?.map((x, index) => {
        return (
          <React.Fragment key={index}>
            {x?.bundle?.length > 0 ? (
              <>
                <AssetBundle
                  iconName={x?.iconName}
                  name={x?.name}
                  index={index}
                  menuItems={menuItems}
                  bundle={x?.bundle}
                  setMoreMenuToggle={setMoreMenuToggle}
                />
              </>
            ) : (
              <Link
                to={`/${x?.link}`}
                onClick={() => {
                  setMoreMenuToggle(false);
                  _.isFunction(x?.onClick) && x?.onClick();
                }}
                key={index}
              >
                <div
                  className={`${style.menuItem} ${
                    index === menuItems.length - 1 && style.borderBottom
                  } `}
                >
                  <Icon name={x?.iconName} width="24" height="24" />
                  <span>{x?.name}</span>
                </div>
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const getMenuItems = (permissions, ToasterUtil) => {
  let arr = [
    {
      name: 'Assets',
      iconName: 'asset-bundle',
      bundle: [
        {
          link: 'sites',
          name: 'Sites',
          iconName: 'locate-grey',
          onClick: null
        },
        {
          link: 'equipment',
          name: 'Equipment',
          iconName: 'equipment',
          onClick: null
        },
        {
          link: 'pipeline',
          name: 'Pipeline',
          iconName: 'pipeline',
          onClick: null
        },
        {
          link: 'structures',
          name: 'Structures',
          iconName: 'home-icon',
          onClick: null
        }
      ],
      onClick: null
    },
    {
      link: 'bookmarks',
      name: 'Bookmarks',
      iconName: 'bookmark-grey',
      onClick: null
    },
    {
      link: 'settings',
      name: 'Settings',
      iconName: 'settings',
      onClick: null
    },
    {
      link: 'help',
      name: 'Help',
      iconName: 'help',
      onClick: null
    },
    {
      link: '',
      name: 'Log out',
      iconName: 'logout',
      onClick: async () => {
        try {
          await AuthService.logout({ clearCache: false });
        } catch (err) {
          ToasterUtil.showError(`storage is full`);
        }
      }
    }
  ];
  return arr;
};

export default memo(MoreMenu);
