/* eslint-disable no-unused-vars */
import { useMemo } from 'react';

import Icon from '@components/icon-component';
import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './collapseable.module.scss';

const CollapseableHeader = ({
  titleFirst,
  titleSecond,
  showEllipseOnTitleSecond = false,
  dotColor,
  onClick,
  subTitleClass,
  dot,
  collapse
}) => {
  const { width } = useWindowDimensions();

  const formWrapperWIdth = document.getElementById('formWrapperContainer')?.clientWidth || 0;
  const { titleFirstWidth, titleSecondWidth } = useMemo(() => {
    const totalWidth = width > 500 ? Math.floor(formWrapperWIdth) - 32 - 16 : formWrapperWIdth - 32;
    const titleFirstWidth = totalWidth * 0.7 - 32;
    const titleSecondWidth = totalWidth * 0.3 - 32;
    return { titleFirstWidth, titleSecondWidth };
  }, [width, formWrapperWIdth]);

  return (
    <div className={style.header} onClick={onClick}>
      <div
        className={style.titleFirst}
        title={titleFirst}
        style={{
          width: `${titleFirstWidth}px`
        }}
      >
        {titleFirst}
      </div>
      <div className={`${style.titleSecond} ${subTitleClass && subTitleClass}`}>
        {titleSecond ? (
          <>
            {showEllipseOnTitleSecond ? (
              <span
                style={{
                  width: `${titleSecondWidth}px`
                }}
                className={style.titleSecondWidthEllipse}
              >
                {titleSecond}
              </span>
            ) : (
              titleSecond
            )}
            {dot && (
              <span
                className={style.redDot}
                style={{
                  backgroundColor: dotColor
                }}
              />
            )}
            <div className={style.headerBtn}>
              <Icon
                name={collapse ? 'up' : 'down'}
                color="#CBD2D9"
                className={style.iconWidthAndHeight}
              />
            </div>
          </>
        ) : (
          <div className={style.headerBtn}>
            <Icon
              name={collapse ? 'up' : 'down'}
              color="#CBD2D9"
              className={style.iconWidthAndHeight}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CollapseableHeader;
