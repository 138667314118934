/* eslint-disable no-unused-vars */

import React, { memo, useEffect } from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@components/app-bar';
import Home from '@components/home';
import Icon from '@components/icon-component';

import { filterActions } from '@redux/slices/filters';
import { getAppDispatch } from '@utilities/dispatch.util';
import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './styles.module.scss';

const ErrorPage = () => {
  const dispatch = getAppDispatch?.();
  const { width } = useWindowDimensions();
  useEffect(() => {
    dispatch?.(filterActions.setTaskFilters(null));
    dispatch?.(filterActions.setSitesFilters(null));
    dispatch?.(filterActions.setEquipmentsFilters(null));
    dispatch?.(filterActions.setPipelinesFilters(null));
    dispatch?.(filterActions.setStructuresFilters(null));
  }, []);

  useEffect(() => {
    const indicator = document.querySelector('.onlineOfflineIndicator');
    const header = document.querySelector('#header');
    const appBar = document.querySelector('.appBar');

    if (indicator && header && width < 768) {
      indicator.remove();
      header.appendChild(indicator);
    }
    if (indicator && appBar && width >= 768) {
      indicator.remove();
      appBar.insertBefore(indicator, appBar.firstChild);
    }
  }, [width]);

  return (
    <div>
      <div className={`md:ml-24 ${style.dashboardContainer} removeScrollbar`} id="dashboard">
        <div>
          <div className={style.errorBoundary}>
            <div className={style.center}>
              <Icon name={'broken-bulb'} className={style.brokenBulb} />
              <div className={style.content}>
                <p className={style.oh}>Uh oh.</p>
                <p className={style.wrong}>Something went wrong on our end.</p>
              </div>
              <div className={`op-view-work-by-column ${style.button} `}>
                <Link className={`op-structure button-link`} to={`/dashboard}`}>
                  <span>Go back Home</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ErrorPage);
