import React from 'react';
import PropTypes from 'prop-types';

import Icon from '@components/icon-component';

const ButtonStatus = (props) => {
  return (
    <div className={props.classes}>
      <Icon name={'equipment'} className={props.EquipmentIcon ? 'block' : 'hidden'} />
      <Icon className={props.CheckinIcon ? 'block' : 'hidden'} name={'check-in'} />
      <Icon className={props.ChildrenIcon ? 'block' : 'hidden'} name={'children'} />
      <p className="other-caption">{props.Text}</p>
      <span className={props.dot ? 'block' : 'hidden'}></span>
    </div>
  );
};

ButtonStatus.propTypes = {
  Text: PropTypes.string,
  EquipmentIcon: PropTypes.string,
  CheckinIcon: PropTypes.string,
  ChildrenIcon: PropTypes.string,
  dot: PropTypes.bool,
  classes: PropTypes.string
};

export default ButtonStatus;
