export function GetDayOfWeek() {
  const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const d = new Date();
  return weekday[d.getDay()];
}

export function GetMonth() {
  const month = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const d = new Date();
  return month[d.getMonth()];
}

export function GetDay() {
  return new Date().getDate();
}
