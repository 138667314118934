import Lottie from 'react-lottie';

import animationData from '@assets/lotties/Oplii_AppOpening';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

const Loading = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div style={{ maxHeight: '100vw', height: '100%', width: '100vw' }}>
        <Lottie options={defaultOptions} />
      </div>
    </div>
  );
};

export default Loading;
