import _ from 'lodash';
import { getFilterPipeline } from '../../components/pipeline-listing/pipeline-listing-inner/helper';

const pipelineBookmarkCount = ({
  filters,
  sort,
  sitesMap,
  cacheSites,
  pipelines,
  siteId,
  store
}) => {
  const { filteredPipeline = [] } = getFilterPipeline({
    filters: { ...filters?.filters },
    store,
    sitesMap,
    pipelines,
    SelectedFilter: Object.keys(sort)[0],
    cacheSites
  });

  const countPipeline =
    filteredPipeline?.filter((t) => {
      if (filters?.siteId) {
        return (
          t.fromSite === siteId ||
          t?.toSite === siteId ||
          t?.sites?.includes(siteId) ||
          t?.wells?.includes(siteId)
        );
      } else {
        return true;
      }
    }) || filteredPipeline;

  return {
    count: countPipeline?.length || 0,
    filters: encodeURIComponent(JSON.stringify(filters))
  };
};

const getPipelineTodoAndInprogressTasks = ({ pipelineId = '', tasksList = [] }) => {
  tasksList = tasksList.filter((task) => !task.newWorkOrder && !task.newWorkRequest && !task.isNew);

  const allTasksForPipeline =
    _.flattenDeep([
      tasksList.filter(
        (t) =>
          t?.pipelineId === pipelineId ||
          t?.pipelines?.includes(pipelineId) ||
          t?.relatedAssetId === pipelineId ||
          t?.primaryAssetId === pipelineId
      )
    ]) || [];

  const countTodoAndInProgressTasksOfSite = _.size(
    allTasksForPipeline.filter(
      (task) => task?.status === 'To do' || task.status === 'In progress'
    ) || []
  );

  return countTodoAndInProgressTasksOfSite;
};

export { pipelineBookmarkCount, getPipelineTodoAndInprogressTasks };
