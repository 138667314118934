/* eslint-disable no-unused-vars */

import React, { Suspense } from 'react';
import { Navigate } from 'react-router-dom';

// pages

// public routes
import Login from '@pages/login';
import UserPermission from '@pages/user-permissions';
import PasswordReset from '@pages/password-reset';
import EmailMessageAndPasswordReset from '@pages/email-msg-pass-reset';
import ThankyouMessage from '@pages/thank-you-message';
import SupportMessage from '@pages/support-msg';
import Error from '@pages/error';

// private routes
import Dashboard from '@pages/dashboard';
import WorkOrder from '@pages/work-order';
import Sites from '@pages/sites';
import ParentSite from '@pages/parent-site';
import SiteDetails from '@pages/site-details';
import ParentSiteDetails from '@pages/parent-sites-details/parent-site';
import TaskRecords from '@pages/tasks-records';
import TaskType from '@pages/task-type';
import RecurringTemplate from '@pages/recurring-template';
import InspectionRun from '@pages/inspection-run';
import Structures from '@pages/structures';
import Equipments from '@pages/equipment';
import ParentEquipment from '@pages/parent-equipment';
import Pipelines from '@pages/pipeline';
import Bookmarks from '@pages/bookmarks';
import Settings from '@pages/settings';
import Help from '@pages/help';
import IconsPage from '@pages/icons-page';
import Search from '@pages/search';
import EquipmentSiteDetails from '@pages/parent-sites-details/equipment-site';
import StructureDetails from '@pages/parent-sites-details/structure-site';
import PipelineDetails from '@pages/parent-sites-details/pipeline-site';
import ErrorPage from '@pages/error';

import Loading from '@components/loading/loading.component';
import PrivateRoute from './private-route';
import ErrorBoundary from '@components/error-boundary';

const RouteList = () => {
  return [
    {
      path: '/',
      element: (
        <Suspense fallback={<Loading />}>
          <ErrorBoundary>
            <Login />
          </ErrorBoundary>
        </Suspense>
      )
    },
    {
      path: '*',
      element: <Navigate to="/" />
    },
    {
      path: '/user-permissions',
      element: (
        <Suspense fallback={<Loading />}>
          <UserPermission />
        </Suspense>
      )
    },

    {
      path: '/passwordreset',
      element: (
        <Suspense fallback={<Loading />}>
          <ErrorBoundary>
            <PasswordReset />
          </ErrorBoundary>
        </Suspense>
      )
    },
    {
      path: '/email-msg-pass-reset',
      element: (
        <Suspense fallback={<Loading />}>
          <ErrorBoundary>
            <EmailMessageAndPasswordReset />
          </ErrorBoundary>
        </Suspense>
      )
    },
    {
      path: '/thank-you-message',
      element: (
        <Suspense fallback={<Loading />}>
          <ThankyouMessage />
        </Suspense>
      )
    },
    {
      path: '/support-msg',
      element: (
        <Suspense fallback={<Loading />}>
          <SupportMessage />
        </Suspense>
      )
    },
    {
      path: '/error',
      element: (
        <Suspense fallback={<Loading />}>
          <Error />
        </Suspense>
      )
    },

    {
      path: '/dashboard',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Dashboard />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/work-order',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <WorkOrder />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/sites',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Sites />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/parent-site/:id',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <ParentSite />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/site-details/:id',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <SiteDetails />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/parent-site-details/:id',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <ParentSiteDetails />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/task-records',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <TaskRecords />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/task-type',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <TaskType />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/recurring-template',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <RecurringTemplate />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/inspection-run',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <InspectionRun />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/structures',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Structures />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/equipment',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Equipments />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/parent-equipment/:id',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <ParentEquipment />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/pipeline',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Pipelines />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/bookmarks',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Bookmarks />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/settings',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Settings />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/help',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Help />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/icons-page',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <IconsPage />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/search',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <Search />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/equipment-details/:id',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <EquipmentSiteDetails />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/parent-equipment-details/:id',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <EquipmentSiteDetails />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/structure-details/:id',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <StructureDetails />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    },

    {
      path: '/pipeline-details/:id',
      element: (
        <Suspense fallback={<Loading />}>
          <PrivateRoute>
            <ErrorBoundary>
              <PipelineDetails />
            </ErrorBoundary>
          </PrivateRoute>
        </Suspense>
      )
    }
  ];
};

export default RouteList;
