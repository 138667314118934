/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { getDataPoints } from '../../pages/settings/helper';

export const searchTasks = ({ searched, data = [], store }) => {
  const { picklists } = store;

  const { picklistsMap = {} } = picklists;

  return new Promise((resolve) => {
    const tasks = data?.filter((task) => {
      const {
        id,
        title,
        description,
        users,
        equipmentAsset = {},
        siteAsset = {},
        rtInterval,
        rtData = {},
        taskFooter,
        hseData,
        mocData,
        structureData,
        structureAsset,
        ncrData,
        pipelineData,
        pipelineAsset,
        incidentNumber,
        detailInspectionsInfo,
        recurrence,
        identifier,
        location
      } = task;

      if (searched) {
        const filterObject = filterTaskObject({
          equipmentAsset,
          siteAsset,
          users,
          id,
          title,
          description,
          rtInterval,
          rtData,
          taskFooter,
          hseData,
          mocData,
          structureData,
          structureAsset,
          ncrData,
          pipelineData,
          pipelineAsset,
          incidentNumber,
          detailInspectionsInfo,
          inspectionTitle: task?.inspectionType,

          type:
            task?.typeId && picklistsMap[task?.typeId]?.value
              ? picklistsMap[task?.typeId]?.value
              : '',
          recurrence,
          identifier,
          location
        });

        if (
          !Object.values(filterObject).some((value) => {
            return String(value).toLowerCase().includes(searched.toLowerCase());
          })
        )
          return false;
      }

      return true;
    });
    return resolve(tasks);
  });
};

export const searchSites = ({ searched, data = [], store }) => {
  const { picklists } = store;
  const areaHierarchyTreeDataMap = picklists?.areaHierarchyTreeDataMap || {};
  return new Promise((resolve) => {
    const filteredSites = data?.filter((props) => {
      const {
        siteId: localSiteId,
        name,
        distance,
        status,
        siteType: type,
        areaHierarchy,
        parent,
        dataPoints
      } = props;
      const title = parent
        ? 'View all work at'
        : areaHierarchyTreeDataMap[areaHierarchy]?.title || '';

      if (!_.isEmpty(searched)) {
        const dataPointsObject = dataPoints.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
          {}
        );
        const filterObject = {
          name,
          title,
          ...dataPointsObject
        };

        if (
          !Object.values(filterObject).some((value) => {
            return String(value).toLowerCase().includes(searched?.toLowerCase());
          })
        )
          return false;
      }

      return true;
    });
    return resolve(filteredSites);
  });
};

export const searchEquipments = ({ searched, data = [], store }) => {
  const { picklists, cache, jurisdictionNumbers, settings } = store;
  const { areaHierarchyTreeDataMap = {}, picklistsMap = {} } = picklists;
  const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;
  const equipmentDisplayFields = settings.settings?.['equipmentDisplayFields'] || '';
  const siteDisplayFields = settings.settings?.['siteDisplayFields'] || '';
  const { sitesMap, sites: cacheSites = [] } = cache;
  return new Promise((resolve) => {
    const equipmentsWithDataPoints = data?.map((props) => {
      const { jurisdictionNumber } = jurisdictionNumbersMap[props.equipmentId] || {};

      const dataPoints = getDataPoints(
        { ...props, subtype: props.equipmentSubType, jurisdictionNumber },
        equipmentDisplayFields,
        'equipment',
        picklistsMap
      );
      return {
        ...props,
        dataPoints
      };
    });
    const filteredEquipment = equipmentsWithDataPoints?.filter((equipment) => {
      const {
        equipmentId: localEquipmentId,
        name,
        status,
        siteId,
        equipmentType: type,
        equipmentCategory: category,
        equipmentSubType: subType,
        allTasksForSite,
        dataPoints
      } = equipment;

      const site = cacheSites[sitesMap[siteId]] || {};

      const categoryName = picklistsMap[category]?.value;
      const typeName = picklistsMap[type]?.value;

      const title = `${categoryName}-${typeName}`;
      const siteTitle = site?.parent
        ? 'View all work at'
        : areaHierarchyTreeDataMap[site?.areaHierarchy || '']?.title || '';
      const dataPointsObject = dataPoints.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );
      const dataPointsForSite = getDataPoints(
        { ...site, type: site.siteType },
        siteDisplayFields,
        'site',
        picklistsMap
      );
      const dataPointsObjectFOrSite = dataPointsForSite.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );

      const filterObject = {
        name,
        title,
        siteTitle,
        siteName: site?.name || '',
        ...dataPointsObject,
        ...dataPointsObjectFOrSite
      };
      if (
        !Object.values(filterObject).some((value) => {
          return String(value).toLowerCase().includes(searched?.toLowerCase());
        })
      )
        return false;

      return true;
    });
    return resolve(filteredEquipment);
  });
};

export const searchStructures = ({ searched, data = [], store }) => {
  const { picklists, cache, jurisdictionNumbers, settings } = store;
  const { areaHierarchyTreeDataMap = {}, picklistsMap = {} } = picklists;
  const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;
  const structureDisplayFields = settings.settings?.['structureDisplayFields'] || '';
  const equipmentDisplayFields = settings.settings?.['equipmentDisplayFields'] || '';
  const siteDisplayFields = settings.settings?.['siteDisplayFields'] || '';
  const { sitesMap, sites: cacheSites = [] } = cache;
  return new Promise((resolve) => {
    const structuresWithDataPoints = data?.map((props) => {
      const { jurisdictionNumber } = jurisdictionNumbersMap[props.equipmentId] || {};

      const dataPoints = getDataPoints(
        { ...props, subtype: props.equipmentSubType, jurisdictionNumber },
        structureDisplayFields,
        'structure',
        picklistsMap
      );
      return {
        ...props,
        dataPoints
      };
    });
    const filteredStructure = structuresWithDataPoints?.filter((structure) => {
      const {
        structureId: localEquipmentId,
        name,
        status,
        siteId,
        structureType: type,
        allTasksForStructure,
        dataPoints
      } = structure;

      const site = cacheSites[sitesMap[siteId]] || {};

      const typeName = picklistsMap[type]?.value;

      const title = `${typeName}`;
      const siteTitle = site?.parent
        ? 'View all work at'
        : areaHierarchyTreeDataMap[site?.areaHierarchy || '']?.title || '';
      const dataPointsObject = dataPoints.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );
      const dataPointsForSite = getDataPoints(
        { ...site, type: site.siteType },
        siteDisplayFields,
        'site',
        picklistsMap
      );
      const dataPointsObjectFOrSite = dataPointsForSite.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );

      const filterObject = {
        name,
        title,
        siteTitle,
        siteName: site?.name || '',
        ...dataPointsObject,
        ...dataPointsObjectFOrSite
      };

      if (
        !Object.values(filterObject).some((value) => {
          return String(value).toLowerCase().includes(searched?.toLowerCase());
        })
      )
        return false;

      return true;
    });
    return resolve(filteredStructure);
  });
};

export const searchPipelines = ({ searched, data = [], store }) => {
  const { picklists, cache, settings } = store;
  const { areaHierarchyTreeDataMap = {}, picklistsMap = {} } = picklists;
  const pipelineDisplayFields = settings.settings?.['pipelineDisplayFields'] || '';
  const siteDisplayFields = settings.settings?.['siteDisplayFields'] || '';
  const { sitesMap, sites: cacheSites = [] } = cache;
  return new Promise((resolve) => {
    const pipelinesWithDataPoints = data?.map((props) => {
      const dataPoints = getDataPoints(
        {
          ...props,
          area: areaHierarchyTreeDataMap[props?.area]?.title,
          type: props?.pipelineTypeId || null,
          approvalNumber: props?.ApprovalNumber || null
        },
        pipelineDisplayFields,
        'pipeline',
        picklistsMap
      );
      return {
        ...props,
        dataPoints
      };
    });
    const filteredPipeline = pipelinesWithDataPoints
      ?.filter((pipeline) => {
        const {
          toLocation,
          fromLocation,
          licenseNumber,
          lineNumber,
          siteId = '',

          dataPoints
        } = pipeline;

        const site = cacheSites[sitesMap[siteId]] || {};

        const title = `${licenseNumber} - ${lineNumber}`;
        const title1 = `${licenseNumber}-${lineNumber}`;

        const dataPointsObject = dataPoints.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
          {}
        );

        const filterObject = {
          title,
          title1,
          toLocation,
          fromLocation,

          ...dataPointsObject
        };

        if (
          !Object.values(filterObject).some((value) => {
            return String(value).toLowerCase().includes(searched?.toLowerCase());
          })
        )
          return false;

        return true;
      })
      .sort((a, b) => {
        if (a?.licenseNumber === b?.licenseNumber) return a?.lineNumber - b?.lineNumber;
        else return a?.licenseNumber - b?.licenseNumber;
      });
    return resolve(filteredPipeline);
  });
};

const filterTaskObject = ({
  equipmentAsset,
  siteAsset,
  users,
  id,
  title,
  description,
  rtInterval,
  rtData,
  hseData,
  mocData,
  structureData,
  structureAsset,
  ncrData,
  pipelineData,
  pipelineAsset,
  incidentNumber,
  type,
  detailInspectionsInfo,
  inspectionTitle,
  recurrence,
  identifier,
  location
}) => {
  const equipmentObject =
    equipmentAsset &&
    equipmentAsset.prepareTasksEquipments &&
    equipmentAsset.prepareTasksEquipments.map((x) => {
      const { dataPoints = [], siteAsset = {} } = x;

      const dataPointsEquipmentObject = dataPoints.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );
      const dataPointsSiteObject = siteAsset?.dataPoints.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );

      return {
        title: x?.title || '',
        subtitle: x?.subtitle || '',
        siteTitle: siteAsset?.title || '',
        siteSubtitle: siteAsset?.subtitle || '',
        kmValue: siteAsset?.kmValue || '',
        unit: siteAsset?.unit || '',
        ...dataPointsEquipmentObject,
        ...dataPointsSiteObject
      };
    });

  const siteObject =
    siteAsset &&
    siteAsset.prepareTasksSites &&
    siteAsset.prepareTasksSites.map((x) => {
      const { dataPoints = [] } = x;

      const dataPointsSiteObject = dataPoints?.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );

      return {
        title: x?.title || '',
        subtitle: x?.subtitle || '',
        kmValue: x?.kmValue || '',
        unit: x?.unit || '',
        ...dataPointsSiteObject
      };
    });

  const structureObject =
    structureAsset &&
    structureAsset.prepareTasksStructure &&
    structureAsset.prepareTasksStructure.map((x) => {
      const { dataPoints = [], siteAsset = {} } = x;

      const dataPointsStructureObject = dataPoints.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );
      const dataPointsSiteObject = siteAsset?.dataPoints?.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );

      return {
        title: x?.structureType || '',
        subtitle: x?.name || '',
        siteTitle: siteAsset?.title || '',
        siteSubtitle: siteAsset?.subtitle || '',
        kmValue: siteAsset?.kmValue || '',
        unit: siteAsset?.unit || '',
        ...dataPointsStructureObject,
        ...dataPointsSiteObject
      };
    });

  const pipelineObject = pipelineAsset?.prepareTasksPipeline?.map((x) => {
    const { dataPoints = [], siteAsset = {} } = x;

    const dataPointsPipelineObject = dataPoints.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );
    const dataPointsSiteObject = siteAsset?.dataPoints?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    return {
      title: `${x.licenseNumber}-${x.lineNumber}`,
      to: x?.toLocation || '',
      from: x?.fromLocation || '',
      siteTitle: siteAsset?.title || '',
      siteSubtitle: siteAsset?.subtitle || '',
      kmValue: siteAsset?.kmValue || '',
      unit: siteAsset?.unit || '',
      ...dataPointsPipelineObject,
      ...dataPointsSiteObject
    };
  });

  const transformedObjEquipment = equipmentObject?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-equipment`] = obj[key];
    });
    return acc;
  }, {});

  const transformedObjStructure = structureObject?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-structure`] = obj[key];
    });
    return acc;
  }, {});

  const transformedObjPipeline = pipelineObject?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-pipeline`] = obj[key];
    });
    return acc;
  }, {});

  const transformedObjSite = siteObject?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-site`] = obj[key];
    });
    return acc;
  }, {});

  const hseDataFilterObjects = !_.isEmpty(hseData) ? appliesToTypesDataPoints(hseData, 'hse') : {};
  const mocDataFilterObjects = !_.isEmpty(mocData) ? appliesToTypesDataPoints(mocData, 'moc') : {};

  const structureDataFilterObjects = !_.isEmpty(structureData)
    ? appliesToTypesDataPoints(structureData, 'structure')
    : {};

  const ncrDataFilterObjects = !_.isEmpty(ncrData) ? appliesToTypesDataPoints(ncrData, 'ncr') : {};
  const pipelineDataFilterObjects = !_.isEmpty(pipelineData)
    ? appliesToTypesDataPoints(pipelineData, 'pipeline')
    : {};

  const filterObject = {
    id,
    title,
    description,
    rtInterval,
    incidentNumber,
    type,
    detailInspectionsInfo,
    inspectionTitle,
    users: (users && users.toString()) || '',
    ...transformedObjSite,
    ...transformedObjEquipment,
    rtDataId: rtData?.id || '',
    rtDataTitle: rtData?.title || '',
    recurrence,
    identifier,
    location,
    ...hseDataFilterObjects,
    ...mocDataFilterObjects,
    ...transformedObjStructure,
    ...ncrDataFilterObjects,
    ...transformedObjPipeline
  };
  return filterObject;
};

const appliesToTypesDataPoints = (typeOfData = {}, keyName = 'hse') => {
  const hseSiteAssetDataPoints =
    typeOfData?.siteAsset?.dataPoints &&
    typeOfData?.siteAsset?.dataPoints?.reduce(
      (obj, item) =>
        Object.assign(obj, { [`${item.key}-${keyName}-site`]: `${item.title} : ${item.value}` }),
      {}
    );

  const hseEquipmentAssetDataPoints =
    typeOfData?.equipmentAsset?.dataPoints &&
    typeOfData?.equipmentAsset?.dataPoints?.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [`${item.key}-${keyName}-equipment`]: `${item.title} : ${item.value}`
        }),
      {}
    );

  const hseEquipmentSiteAssetDataPoints =
    typeOfData?.equipmentAsset?.siteAsset &&
    typeOfData?.equipmentAsset?.siteAsset?.dataPoints &&
    typeOfData?.equipmentAsset?.siteAsset?.dataPoints?.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [`${item.key}-${keyName}-equipment-site`]: `${item.title} : ${item.value}`
        }),
      {}
    );

  const typeOfDataFilterObjects = {
    [`recordNumber-${keyName}`]: typeOfData?.recordNumber || '',
    [`recordTitle-${keyName}}`]: typeOfData?.recordTitle || '',
    [`siteAsset-title-${keyName}`]: typeOfData?.siteAsset?.title || '',
    [`siteAsset-subtitle-${keyName}`]: typeOfData?.siteAsset?.subtitle || '',
    [`siteAsset-kmValue-${keyName}`]: typeOfData?.siteAsset?.kmValue || '',
    [`siteAsset-unit-${keyName}`]: typeOfData?.siteAsset?.unit || '',

    [`equipmentAsset-title-${keyName}`]: typeOfData?.equipmentAsset?.title || '',
    [`equipmentAsset-subtitle-${keyName}`]: typeOfData?.equipmentAsset?.subtitle || '',

    [`equipmentAsset-siteAsset-title-${keyName}`]:
      typeOfData?.equipmentAsset?.siteAsset?.title || '',
    [`equipmentAsset-siteAsset-subtitle-${keyName}`]:
      typeOfData?.equipmentAsset?.siteAsset?.subtitle || '',
    [`equipmentAsset-siteAsset-kmValue-${keyName}`]:
      typeOfData?.equipmentAsset?.siteAsset?.kmValue || '',
    [`equipmentAsset-siteAsset-unit-${keyName}`]: typeOfData?.equipmentAsset?.siteAsset?.unit || '',

    [`pipelineAsset-fromLocation-${keyName}`]: typeOfData?.pipelineAsset?.fromLocation || '',
    [`pipelineAsset-toLocation-${keyName}`]: typeOfData?.pipelineAsset?.toLocation || '',
    [`pipelineAsset-licenseNumber-${keyName}`]: typeOfData?.pipelineAsset?.licenseNumber || '',
    [`pipelineAsset-length-${keyName}`]: typeOfData?.pipelineAsset?.length || '',

    [`structureData-name-${keyName}`]: typeOfData?.structureData?.name || '',
    [`structureData-structureNumberAuto-${keyName}`]:
      typeOfData?.structureData?.structureNumberAuto || '',
    [`structureData-structureType-${keyName}`]: typeOfData?.structureData?.structureType || '',

    ...hseEquipmentSiteAssetDataPoints,
    ...hseSiteAssetDataPoints,
    ...hseEquipmentAssetDataPoints
  };
  return typeOfDataFilterObjects;
};

export const searchRts = ({ searched, data = [] }) => {
  return new Promise((resolve) => {
    const filteredRTs = data?.filter((rt) => {
      const { title, id } = rt;
      const filterObject = {
        id,
        title
      };
      if (
        !Object.values(filterObject).some((value) => {
          return String(value).toLowerCase().includes(searched?.toLowerCase());
        })
      )
        return false;
      return true;
    });
    return resolve(filteredRTs);
  });
};

export const searchRuns = ({ searched, data = [] }) => {
  return new Promise((resolve) => {
    const filteredRuns = data?.filter((rt) => {
      const { title, id } = rt;
      const filterObject = {
        id,
        title
      };
      if (
        !Object.values(filterObject).some((value) => {
          return String(value).toLowerCase().includes(searched?.toLowerCase());
        })
      )
        return false;
      return true;
    });
    return resolve(filteredRuns);
  });
};
