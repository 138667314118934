/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { homeActions } from '@redux/slices/home';
import { GetDayOfWeek, GetDay, GetMonth } from '@utilities/date-functions';
import { HomeSelector } from '../../selectors';
import { decodeToken } from '@utilities/authentication';
import useWindowDimensions from '@hooks/useWindowDimensions';

const { getGreetingsText, getImagesName, getMotivationalText } = HomeSelector;
import style from './home.module.scss';
const Greeting = ({ hasError = false }) => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch?.(homeActions.setGreetings());
    setInterval(() => dispatch?.(homeActions.setGreetings()), 1000 * 60 * 1);
  }, []);

  const {
    imageIndex = 0,
    greetingTextIndex = 0,
    motivationalTextIndex = 0
  } = useSelector((state) => state.home?.greetings) || {};
  return (
    <React.Fragment>
      <div
        className={`${style['op-rocketboy']}`}
        style={{ backgroundImage: `url(${getImagesName()[imageIndex]})` }}
      />
      {width < 768 && hasError && (
        <div
          id="header"
          className="op-page-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center dashboard-header"
        >
          {width < 768 && <div></div>}
          <h1 className="h1-semibold" style={{ padding: '0' }}>
            Home
          </h1>
          <div className={`${style['op-day-date']}`}>
            <h4 className="h4">{GetDayOfWeek()}</h4>
            <h3 className="h3">
              {GetMonth()} {GetDay()}
            </h3>
          </div>
        </div>
      )}
      {!hasError && (
        <div
          id="header"
          className="op-page-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center dashboard-header"
        >
          {width < 768 && <div></div>}
          <h1 className="h1-semibold" style={{ padding: '0' }}>
            Home
          </h1>
          <div className={`${style['op-day-date']}`}>
            <h4 className="h4">{GetDayOfWeek()}</h4>
            <h3 className="h3">
              {GetMonth()} {GetDay()}
            </h3>
          </div>
        </div>
      )}

      {!hasError && (
        <div
          className={`${style['op-profile-name']} px-4 md:px-8 pt-8`}
          style={{ maxWidth: 'calc(100% - 148px)' }}
        >
          <h1
            className={`${style['h1-bold']} mb-2`}
            style={{
              lineHeight: '32px',
              marginBottom: '8px'
            }}
          >
            {getGreetingsText()[greetingTextIndex]?.text}, {decodeToken().firstName}
            {getGreetingsText()[greetingTextIndex]?.symbol}
          </h1>
          <p className="input-medium">{getMotivationalText()[motivationalTextIndex]}</p>
        </div>
      )}
    </React.Fragment>
  );
};

export default React.memo(Greeting);
