import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  tasksFilter: {
    searched: '',
    proximity: { value: 0 },
    area: { value: [] },
    workOrderType: { value: [] },
    siteType: { value: [] },
    siteStatus: { value: [] },
    taskType: { value: [] },
    taskStatus: { value: [] },
    recurringTemplate: { value: [] },
    assign: { value: [] },
    workOrderPriority: { value: [] },
    download: { value: '', selected: 'past' },
    createdAt: { value: '', selected: 'past' },
    syncedDate: { value: '', selected: 'past' },
    dueDate: { value: [], selected: 'next' },
    scheduledDate: { value: [], selected: 'next' }
  },
  sitesFilter: {
    searched: '',
    proximity: { value: 0 },
    area: { value: [] },
    workOrderType: { value: [] },
    siteType: { value: [] },
    siteStatus: { value: [] },
    taskType: { value: [] },
    taskStatus: { value: [] },
    assign: { value: [] },
    priority: { value: [] },
    workOrderPriority: { value: [] },
    recurringTemplate: { value: [] },
    download: { value: '', selected: 'past' },
    createdAt: { value: '', selected: 'past' },
    syncedDate: { value: '', selected: 'past' },
    dueDate: { value: [], selected: 'next' },
    scheduledDate: { value: [], selected: 'next' }
  },
  equipmentsFilter: {
    searched: '',
    workOrderType: { value: [] },
    equipmentCategory: { value: [] },
    equipmentType: { value: [] },
    equipmentSubType: { value: [] },
    equipmentStatus: { value: [] },
    proximity: { value: 0 },
    area: { value: [] },
    siteType: { value: [] },
    siteStatus: { value: [] },
    taskType: { value: [] },
    taskStatus: { value: [] },
    assign: { value: [] },
    priority: { value: [] },
    recurringTemplate: { value: [] },
    workOrderPriority: { value: [] },
    download: { value: '', selected: 'past' },
    createdAt: { value: '', selected: 'past' },
    syncedDate: { value: '', selected: 'past' },
    dueDate: { value: [], selected: 'next' },
    scheduledDate: { value: [], selected: 'next' }
  },
  structuresFilter: {
    searched: '',
    workOrderType: { value: [] },
    structureType: { value: [] },
    structureStatus: { value: [] },
    proximity: { value: 0 },
    area: { value: [] },
    siteType: { value: [] },
    siteStatus: { value: [] },
    taskType: { value: [] },
    taskStatus: { value: [] },
    assign: { value: [] },
    priority: { value: [] },
    recurringTemplate: { value: [] },
    workOrderPriority: { value: [] },
    download: { value: '', selected: 'past' },
    createdAt: { value: '', selected: 'past' },
    syncedDate: { value: '', selected: 'past' },
    dueDate: { value: [], selected: 'next' },
    scheduledDate: { value: [], selected: 'next' }
  },
  pipelinesFilter: {
    searched: '',
    pipelineArea: { value: [] },
    pipelineStatus: { value: [] },
    taskType: { value: [] },
    taskStatus: { value: [] },
    assign: { value: [] },
    priority: { value: [] },
    workOrderPriority: { value: [] },
    download: { value: '', selected: 'past' },
    createdAt: { value: '', selected: 'past' },
    syncedDate: { value: '', selected: 'past' },
    dueDate: { value: [], selected: 'next' },
    scheduledDate: { value: [], selected: 'next' }
  },
  tasksFilterCount: 0,
  sitesFilterCount: 0,
  pipelineFilterCount: 0,
  equipmentFilterCount: 0,
  psvsFilterCount: 0,
  pvsFilterCount: 0,
  structureFilterCount: 0
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setTaskFilters: (state, action) => {
      state.tasksFilter = action.payload ? { ...action.payload } : initialState?.tasksFilter;
    },
    setTaskFiltersCount: (state, action) => {
      state.tasksFilterCount = action.payload;
    },
    setPSVFiltersCount: (state, action) => {
      state.psvsFilterCount = action.payload;
    },
    setPVSFiltersCount: (state, action) => {
      state.pvsFilterCount = action.payload;
    },
    setSitesFiltersCount: (state, action) => {
      state.sitesFilterCount = action.payload;
    },
    setEquipmentFiltersCount: (state, action) => {
      state.equipmentFilterCount = action.payload;
    },
    setStructureFiltersCount: (state, action) => {
      state.structureFilterCount = action.payload;
    },
    setPipelineFiltersCount: (state, action) => {
      state.pipelineFilterCount = action.payload;
    },
    setSitesFilters: (state, action) => {
      state.sitesFilter = action.payload ? { ...action.payload } : initialState?.sitesFilter;
    },
    setEquipmentsFilters: (state, action) => {
      state.equipmentsFilter = action.payload
        ? { ...action.payload }
        : initialState?.equipmentsFilter;
    },
    setStructuresFilters: (state, action) => {
      state.structuresFilter = action.payload
        ? { ...action.payload }
        : initialState?.structuresFilter;
    },

    setPipelinesFilters: (state, action) => {
      state.pipelinesFilter = action.payload
        ? { ...action.payload }
        : initialState?.pipelinesFilter;
    }
  }
});

const filterReducer = filtersSlice.reducer;

export const filterActions = filtersSlice.actions;
export default filterReducer;
