import React, { useEffect } from 'react';

import Icon from '@components/icon-component';

import style from './task.module.scss';

const ChangeDisplaySetting = () => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className={style.bgColor}>
      <div className={style.seeCard}>
        <h6 className={style.titleClass}>Choose what you see</h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          If you have the right permissions, you’ll be able to change how asset and task data is
          presented in Oplii Mobile.
        </p>
        <h6 className={style.titleClass} style={{ marginTop: '32px' }}>
          How?
        </h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          Select <span style={{ fontWeight: 700 }}>More </span> from the navigation menu and choose
          <span style={{ fontWeight: 700 }}> Settings, </span>
          then <span style={{ fontWeight: 700 }}>Display settings.</span>
        </p>

        <ul style={{ marginTop: '32px' }}>
          <p className={style.p} style={{ fontWeight: 700 }}>
            Let’s use Equipment as an example.
          </p>
          <p className={style.p} style={{ marginTop: '16px' }}>
            Personally, I use ‘Serial #’ to identify equipment, so I’ll add it to the equipment
            listing card by:
          </p>

          <li>
            From <span style={{ fontWeight: 700 }}>Display settings,</span> selecting{' '}
            <span style={{ fontWeight: 700 }}>Equipment.</span>
          </li>

          <li>
            Using <span style={{ fontWeight: 700 }}>Add field(s) </span> to open the popup menu,
            finding and selecting ‘Serial #’
          </li>
        </ul>
        <div className={style.flex}>
          <div className={style.iconImg}>
            <Icon name="add-field" height="100%" width="100%" />
          </div>
        </div>

        <p className={style.p} style={{ marginTop: '16px' }}>
          Huzzah! Serial # will be visible on cards and listings, whenever it is available in the
          equipment data.
        </p>
        <p className={style.p} style={{ marginTop: '32px' }}>
          I can reorder these display fields by using the move icon.
        </p>
        <div className={style.flex}>
          <div className={style.iconImg}>
            <Icon name="Huzzah" height="100%" width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeDisplaySetting;
