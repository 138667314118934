import moment from 'moment';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  settings: {},
  loading: false,
  oldSettings: {},
  updatedSettings: {},
  isSettingUpdated: false,
  lastSync: moment().format(),
  lastSyncReminder: moment().format(),
  syncReminderFrequency: 0,
  appVersionNumber: 0,
  isAppUpdatable: false,
  isAppUpdating: false,
  isUpdatingComplete: false,
  isUpdateFailed: false,
  movingTasksAssetsReduxToIndexDBInprogress: false,
  movingTasksAssetsReduxToIndexDBCompleted: false
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      const settings = action.payload || {};
      if (settings.newSettings) {
        state.settings = { ...state.settings, ...settings.newSettings };
        state.updatedSettings = { ...state.updatedSettings, ...settings.newSettings };
      } else {
        state.settings = settings;
        state.updatedSettings = {};
        state.oldSettings = settings;
        state.lastSync = moment().format();
        state.lastSyncReminder = moment().format();
        state.syncReminderFrequency = 0;
        state.appVersionNumber = 0;
      }
      state.isSettingUpdated = false;
    },
    updateSettingsByName: (state, action) => {
      state.isSettingUpdated = true;
      const { name, value } = action.payload;
      state.settings[name] = value;
      state.updatedSettings[name] = value;
      if (state.oldSettings[name] == value) {
        delete state.updatedSettings[name];
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLastSync: (state, action) => {
      state.lastSync = action.payload;
    },
    setLastSynReminder: (state, action) => {
      state.lastSyncReminder = action.payload;
    },
    setSyncReminderFrequency: (state, action) => {
      state.syncReminderFrequency = action.payload;
      state.isSettingUpdated = false;
    },
    setAppVersionNumber: (state, action) => {
      state.appVersionNumber = action.payload;
    },
    setAppUpdateStatus: (state, action) => {
      const {
        isAppUpdatable = false,
        isAppUpdating = false,
        isUpdatingComplete = false,
        isUpdateFailed = false,
        movingTasksAssetsReduxToIndexDBInprogress = false
      } = action.payload;
      state.isAppUpdatable = isAppUpdatable;
      state.isAppUpdating = isAppUpdating;
      state.isUpdatingComplete = isUpdatingComplete;
      state.isUpdateFailed = isUpdateFailed;
      state.movingTasksAssetsReduxToIndexDBInprogress = movingTasksAssetsReduxToIndexDBInprogress;
    },
    setAppUpdateStatusForWorkOrder: (state, action) => {
      const { movingTasksAssetsReduxToIndexDBCompleted } = action.payload;
      state.movingTasksAssetsReduxToIndexDBCompleted = movingTasksAssetsReduxToIndexDBCompleted;
    }
  }
});

const settingsReducer = settingsSlice.reducer;

export const settingsActions = settingsSlice.actions;
export default settingsReducer;
