/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react';
import _ from 'lodash';

import Collapseable from '@components/collapseable';
import DeleteModal from '@components/modal/variants/delete-modal';

import { downloadPDF } from '@utilities/attachments';
import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './document-card.module.scss';

const DocumentsCard = ({
  documentCategory,
  file,
  deleteDocument,
  editDocument,
  picklistsMap,
  noAction = false,
  downloaded = false,
  pdfUrl = false
}) => {
  const [open, setOpen] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState(false);
  const { width } = useWindowDimensions();
  const [loadingDelete, setLoadingDelete] = useState(false);

  const formWrapperWIdth =
    document.getElementById('addWorkOrderFormContainer')?.clientWidth ||
    document.getElementById('formWrapperContainer')?.clientWidth ||
    0;

  const headerWidth = useMemo(() => {
    return width > 500 ? Math.floor(formWrapperWIdth) - 32 - 16 : formWrapperWIdth - 32;
  }, [width, formWrapperWIdth]);

  const handleDeleteEvent = async () => {
    setLoadingDelete(true);
    await deleteDocument(documentCategory?.id, file?.id);
    setLoadingDelete(false);

    setDeleteDoc(false);
  };

  return (
    <div
      className={`op-filter-collapse ${open ? 'open' : ''}`}
      style={{
        backgroundColor: 'white',
        width: `100%`,
        minWidth: `${headerWidth}px`
      }}
    >
      <Collapseable
        title={file?.file?.name || documentCategory?.fileName || file?.name || ''}
        subTitle={
          documentCategory?.category ? picklistsMap[documentCategory?.category]?.value || '' : ''
        }
        showEllipseOnTitleSecond
        noAction={noAction}
        downloaded={downloaded}
        onDelete={() => setDeleteDoc(true)}
        onEdit={() => {
          editDocument(documentCategory?.id, file?.id);
        }}
        onDownload={() => {
          downloadPDF({
            pdf: null,
            fileName: file?.file?.name || documentCategory?.fileName || file?.name || '',
            url: pdfUrl
          });
        }}
      >
        {(file?.file?.name || documentCategory?.fileName || file?.name) && (
          <>
            <div className={style.section1}>
              <p
                className={style.title}
                style={{
                  marginTop: '0px'
                }}
              >
                File
              </p>
              <p className={style.p1}>
                {file?.file?.name || documentCategory?.fileName || file?.name || ''}
              </p>
            </div>
          </>
        )}
        {documentCategory?.category && (
          <>
            <div className={style.section1}>
              <p className={style.title}>Category</p>
              <p className={style.p1}>
                {documentCategory?.category
                  ? picklistsMap[documentCategory?.category]?.value || ''
                  : ''}
              </p>
            </div>
          </>
        )}
      </Collapseable>

      <DeleteModal
        open={deleteDoc}
        setOpen={loadingDelete ? () => {} : setDeleteDoc}
        handleDelete={handleDeleteEvent}
        confirmDeletion
        recordType="document"
        disabledDeleteButton={loadingDelete}
      />
    </div>
  );
};

export default DocumentsCard;
