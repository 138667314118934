/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

import AppBar from '@components/app-bar';
import PageHeader from '@components/page-header';
import RecurringTemplateDetails from './recurring-template-details';
import LoadingRound from '@components/loading-round/loading-round';
import { initWebWorker } from 'webworker';

import useWindowDimensions from '@hooks/useWindowDimensions';

import './recurring-template.scss';
import style from './style.module.scss';

const RecurringTemplate = () => {
  const [rt, setRt] = useState([]);
  const { width } = useWindowDimensions();
  const [view, setView] = useState('');
  const [loading, setLoading] = useState(true);

  const { selectTask = false, tasksList: reduxTasksList = [] } = useSelector(
    (state) => state.tasks
  );

  const checkedLength = useMemo(() => {
    return rt.filter(({ checked }) => checked).length;
  }, [rt]);

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() }
      },
      type: 'get-all-recurring-template'
    })
      .then((data) => {
        setRt(data?.result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [reduxTasksList]);

  const pageHeaderClick = React.useCallback(() => {
    if (view) {
      setView('');
    } else if (checkedLength > 0) {
      let rtCopy = [...rt];
      for (let i = 0; i < rtCopy.length; i++) {
        rtCopy[i].checked = false;
      }
      setRt(rtCopy);
    }
  }, [view, checkedLength, setView]);

  const changeHeaderClick = React.useCallback((checked) => {
    let rtCopy = [...rt];
    for (let i = 0; i < rtCopy.length; i++) {
      rtCopy[i].checked = checked;
    }
    setRt(rtCopy);
  });

  return loading ? (
    <div style={{ backgroundColor: 'transparent', width: '100%' }}>
      <LoadingRound />
    </div>
  ) : (
    <>
      {checkedLength > 0 && width <= 767 ? '' : <AppBar />}
      <div className=" md:ml-24">
        <div>
          <PageHeader
            disableBack={view}
            checked={checkedLength === rt.length}
            checkedLength={!view ? checkedLength : 0}
            checkbox={!view && checkedLength !== 0}
            title={`Select ${view ? 'view' : 'RT(s)'}`}
            onClick={pageHeaderClick}
            onChange={changeHeaderClick}
            length={rt?.length}
          />
          <div
            className={`removeScrollbar ${style.recurringTemplateContainer}`}
            id="recurringTemplateContainer"
            onScroll={() => {
              var el = document.querySelector('#recurringTemplateContainer');
              el.classList.add('addScrollbar');
              el.classList.remove('removeScrollbar');
              clearTimeout();
              setTimeout(function () {
                el.classList.remove('addScrollbar');
                el.classList.add('removeScrollbar');
              }, 300);
            }}
          >
            <RecurringTemplateDetails
              checkedLength={checkedLength}
              view={view}
              setRt={setRt}
              highlight={''}
              setView={setView}
              rt={rt}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(RecurringTemplate);
