/* eslint-disable no-unused-vars */

import { searchPipelineData } from '@utilities/pipeline-search';
import { searchEquipmentData } from '@utilities/equipment-search';
import { siteSearchData } from '@utilities/site-search';
import { getStructureData } from '@utilities/structure-search';

export const filterSites = ({ sites = [], inputValue = '' }) => {
  return new Promise((resolve) => {
    const filterSites = sites?.filter((site) => {
      if (inputValue && !siteSearchData({ inputValue, site })) return false;

      return true;
    });
    resolve(filterSites);
  });
};

export const filterEquipments = ({ equipments = [], inputValue = '', store }) => {
  return new Promise((resolve) => {
    const filteredEquipment = equipments?.filter((equipment) => {
      if (!searchEquipmentData({ equipment, inputValue, store })) return false;
      return true;
    });
    resolve(filteredEquipment);
  });
};

export const filterStructure = ({ structures = [], inputValue = '', store }) => {
  return new Promise((resolve) => {
    const filteredStructure = structures.filter((structure) => {
      if (!getStructureData({ inputValue, structure, store })) return false;

      return true;
    });
    resolve(filteredStructure);
  });
};

export const filterPipeline = ({ pipelines = [], inputValue = '', store }) => {
  return new Promise((resolve) => {
    const filteredPipeline = pipelines?.filter((pipeline) => {
      if (!searchPipelineData({ pipeline, inputValue, store })) return false;

      return true;
    });
    resolve(filteredPipeline);
  });
};
