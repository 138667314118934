/* eslint-disable no-unused-vars */
import React from 'react';

import Modal from '../..';
import LoadingIcon from '@components/loading-lottie';
import Icon from '@components/icon-component';

import style from './style.module.scss';

const UpdateAppModal = ({
  open,
  setOpen,
  updateApp = false,
  updatingApp = false,
  updateComplete = false,
  updateFailed = false,
  movingTasksAssetsReduxToIndexDBInprogress = false,
  movingTasksAssetsReduxToIndexDBCompleted = false,
  updateHandler = () => {}
}) => {
  const updateModalIcon = React.useMemo(() => {
    if (updateApp) return <Icon name={'update-app'} />;
    else if (updatingApp)
      return (
        <div style={{ marginBottom: '-32px' }}>
          <LoadingIcon />
        </div>
      );
    else if (updateComplete) return <Icon name={'complete'} />;
    else if (updateFailed) return <Icon name={'failed'} />;
  }, [updateApp, updatingApp, updateFailed, updateComplete]);

  const statusText = React.useMemo(() => {
    if (updateApp) return 'Update app?';
    else if (movingTasksAssetsReduxToIndexDBInprogress && updatingApp)
      return 'Updating app tasks records';
    else if (updatingApp) return 'Updating app';
    else if (updateComplete) return 'App update complete';
    else if (updateFailed) return 'App update failed';
  }, [
    updateApp,
    updatingApp,
    updateFailed,
    updateComplete,
    movingTasksAssetsReduxToIndexDBInprogress
  ]);

  const modalTextUpper = React.useMemo(() => {
    if (updateApp)
      return (
        <>
          There’s a newer version of Oplii Mobile available. <br />
          <br /> Would you like to update now?{' '}
        </>
      );
    else if (updatingApp) return <>Hold on tight. This might take a few minutes.</>;
    else if (movingTasksAssetsReduxToIndexDBInprogress)
      return <>Hold on tight. This might take a few minutes.</>;
    else if (updateComplete)
      return (
        <>
          Thanks for installing the latest version of Oplii Mobile.
          <div className={style.progressBarContainer}>
            <div className={style.info}>The app will restart but you will remain logged in.</div>
          </div>
        </>
      );
    else if (updateFailed) return <>Oh no. If your internet connection isn’t solid, try later.</>;
  }, [
    updateApp,
    updatingApp,
    updateFailed,
    updateComplete,
    movingTasksAssetsReduxToIndexDBInprogress
  ]);

  const textBtn = React.useMemo(() => {
    if (updateApp) return 'Maybe Later';
    else if (updateFailed) return 'I’ll try later';
    else return '';
  }, [updateApp, updatingApp, updateFailed, updateComplete]);
  const modalInnerBody = React.useMemo(() => {
    if (updateApp)
      return (
        <div>
          <div className={style.modalBtn} style={{ marginTop: '32px' }}>
            <button className="btn-fill button-large" onClick={updateHandler}>
              Yes, please!
            </button>
          </div>
        </div>
      );
    else if (updatingApp)
      return (
        <div style={{ marginTop: '32px' }}>
          <div className={style.modalBtn} onClick={() => setOpen(false)}>
            <button className="btn-fill button-large" disabled>
              CLOSE
            </button>
          </div>
        </div>
      );
    else if (updateComplete)
      return (
        <div>
          <div className={style.modalBtn} style={{ marginTop: '32px' }}>
            <button className="btn-fill button-large" onClick={() => setOpen(false, true)}>
              CLOSE
            </button>
          </div>
        </div>
      );
    else if (updateFailed)
      return (
        <div>
          <div className={style.modalBtn} style={{ marginTop: '32px' }}>
            <button className="btn-fill button-large" onClick={updateHandler}>
              Retry
            </button>
          </div>
        </div>
      );
  }, [
    updateApp,
    updatingApp,
    updateFailed,
    updateComplete,
    movingTasksAssetsReduxToIndexDBCompleted
  ]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={updateModalIcon}
      statusText={statusText}
      modalTextUpper={modalTextUpper}
      textBtn={textBtn}
      modalImageClassName={updatingApp && style.modalImage}
    >
      {modalInnerBody}
    </Modal>
  );
};

export default UpdateAppModal;
