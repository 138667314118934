/* eslint-disable no-unused-vars */
import { useMemo } from 'react';

import TaskDetailComponent from '@components/task-detail-component';
import Icon from '@components/icon-component';

import { PERMISSIONS } from 'permissions/constants';
import { decodeToken } from '@utilities/authentication';

const StructureSite = ({
  setAllTask,
  setWorkOrder,
  allWorkOrderForStructure,
  allDoneWorkOrder,
  tasks,
  allDoneTasks,
  isOverDue,
  isWorkOrderOverDue,
  allDoneChecks,
  allChecks,
  isCheckOverDue,
  setChecks
}) => {
  const permissions = useMemo(() => {
    return decodeToken()?.permissions || {};
  }, []);

  const taskDetail = [
    ...(permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS] && tasks?.length > 0
      ? [
          {
            img: <Icon name={'task'} />,
            taskName: 'All tasks',
            btnText: `${allDoneTasks.length}/${tasks?.length}`,
            click: () => setAllTask(true),
            allDoneTasks: allDoneTasks,
            allTasksForStructure: tasks,
            isOverDue: isOverDue,
            dot: !!isOverDue && allDoneTasks?.length !== tasks?.length
          }
        ]
      : []),
    ...(permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS] && allWorkOrderForStructure?.length > 0
      ? [
          {
            img: <Icon name={'work-order'} />,
            taskName: 'Work orders',
            btnText: `${allDoneWorkOrder.length}/${allWorkOrderForStructure.length}`,
            click: () => setWorkOrder(true),
            allDoneTasks: allDoneWorkOrder,
            allTasksForStructure: allWorkOrderForStructure,
            isOverDue: isWorkOrderOverDue,
            dot: !!isOverDue && allDoneWorkOrder?.length !== allWorkOrderForStructure?.length
          }
        ]
      : []),

    ...(permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS] && allChecks?.length
      ? [
          {
            img: <Icon name={'check-icon'} />,
            taskName: 'Checks',
            btnText: `${allDoneChecks.length}/${allChecks.length}`,
            click: () => setChecks(true),
            allDoneTasks: allDoneChecks,
            allTasksForStructure: allChecks,
            isOverDue: isCheckOverDue,
            dot: !!isOverDue && allDoneChecks?.length !== allChecks?.length
          }
        ]
      : [])
  ];

  return (
    <div>
      {tasks?.length &&
        taskDetail?.map((ele, index) => {
          return ele.taskName === 'All Tasks' &&
            taskDetail?.filter((a) => a.allTasksForStructure.length > 0).length === 2 ? (
            <></>
          ) : (
            <TaskDetailComponent
              key={index}
              img={ele.img}
              task={ele.taskName}
              btnText={`${ele.btnText}`}
              onClick={ele.click}
              dot={ele.dot}
              isOverDue={isOverDue}
              allDoneTasks={ele.allDoneTasks}
              allTasksForSite={ele.allTasksForStructure}
            />
          );
        })}
    </div>
  );
};

export default StructureSite;
