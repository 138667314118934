import * as yup from 'yup';

const schema = yup.object().shape({
  equipmentNumber: yup.string().nullable(),
  equipmentName: yup.string().required('name is required'),
  status: yup.string().required('status is required'),
  psvCategoryType: yup.string().required('Category is required'),
  valveLocation: yup.string().nullable(),
  setPressure: yup.object().shape({
    textBoxValue: yup.number()?.nullable(),
    textBoxUnitValue: yup.string()?.nullable()
  }),
  manufacture: yup.string().nullable(),
  model: yup.string().nullable(),
  serial: yup.string().nullable(),
  fieldTag: yup.string().nullable(),
  crn: yup.string().nullable(),
  psvCapacity: yup.object().shape({
    textBoxValue: yup.number()?.nullable(),
    textBoxUnitValue: yup.string()?.nullable()
  }),
  inletSize: yup.object().shape({
    textBoxValue: yup.number()?.nullable(),
    textBoxUnitValue: yup.string()?.nullable()
  }),
  outletSize: yup.object().shape({
    textBoxValue: yup.number()?.nullable(),
    textBoxUnitValue: yup.string()?.nullable()
  }),
  outletJointStyle: yup.string().nullable(),
  inletJointStyle: yup.string().nullable(),
  lastServiceDate: yup.string().nullable(),
  lastServiceBy: yup.string().nullable(),
  comment: yup.string().nullable(),

  'equipmentName-reconcile': yup.string(),
  'status-reconcile': yup.string(),
  'psvCategoryType-reconcile': yup.string(),
  'valveLocation-reconcile': yup.string().nullable(),
  'setPressure-reconcile': yup.object().shape({
    textBoxValue: yup.number()?.nullable(),
    textBoxUnitValue: yup.string()?.nullable()
  }),
  'manufacture-reconcile': yup.string().nullable(),
  'model-reconcile': yup.string().nullable(),
  'serial-reconcile': yup.string().nullable(),
  'fieldTag-reconcile': yup.string().nullable(),
  'crn-reconcile': yup.string().nullable(),
  'psvCapacity-reconcile': yup.object().shape({
    textBoxValue: yup.number()?.nullable(),
    textBoxUnitValue: yup.string()?.nullable()
  }),
  'inletSize-reconcile': yup.object().shape({
    textBoxValue: yup.number()?.nullable(),
    textBoxUnitValue: yup.string()?.nullable()
  }),
  'outletSize-reconcile': yup.object().shape({
    textBoxValue: yup.number()?.nullable(),
    textBoxUnitValue: yup.string()?.nullable()
  }),
  'outletJointStyle-reconcile': yup.string().nullable(),
  'inletJointStyle-reconcile': yup.string().nullable(),
  'lastServiceDate-reconcile': yup.string().nullable()
});

const getFormFields = ({ edit, dropdownMenuMapList }) => {
  const isMasterData = edit ? true : false;
  return [
    ...(edit
      ? [
          {
            label: 'Equipment #',
            name: 'equipmentNumber',
            type: 'Text',
            required: false,
            isReadOnly: true,
            order: 1
          }
        ]
      : []),
    {
      label: 'Name',
      isMasterData,
      name: 'equipmentName',
      required: true,
      type: 'Text',
      order: 2
    },
    {
      isMasterData,

      label: 'Status',
      name: 'status',
      type: 'Dropdown',
      required: true,
      options: dropdownMenuMapList?.EquipmentStatusPickList || [],
      order: 3
    },
    {
      isMasterData,

      label: 'PSV Category / Type / Subtype',
      name: 'psvCategoryType',
      type: 'HierarchicalDropdown',
      required: true,
      order: 4,
      options:
        dropdownMenuMapList?.EquipmentCategoryTypeSubtypePickList?.filter(
          (item) => item?.allowedForPSVCreation
        ) || []
    },
    {
      isMasterData,

      label: 'Valve location',
      name: 'valveLocation',
      type: 'Dropdown',
      options: dropdownMenuMapList?.EquipmentValveLocationPicklist || [],
      required: false,
      order: 5
    },
    {
      isMasterData,

      label: 'Set pressure',
      name: 'setPressure',
      type: 'Readings',
      required: false,
      options: dropdownMenuMapList?.PressurePickList || [],
      order: 6
    },
    {
      isMasterData,

      label: 'Manufacturer',
      name: 'manufacture',
      required: false,
      type: 'Text',
      order: 7
    },
    {
      isMasterData,

      label: 'Model',
      name: 'model',
      required: false,
      type: 'Text',
      order: 8
    },
    {
      isMasterData,

      label: 'Serial #',
      name: 'serial',
      required: false,
      type: 'Text',
      order: 9
    },
    {
      isMasterData,

      label: 'Field tag #',
      name: 'fieldTag',
      required: false,
      type: 'Text',
      order: 10
    },
    {
      isMasterData,

      label: 'CRN',
      name: 'crn',
      required: false,
      type: 'Text',
      order: 11
    },
    {
      isMasterData,

      label: 'PSV Capacity',
      name: 'psvCapacity',
      type: 'Readings',
      required: false,
      options: dropdownMenuMapList?.FlowRatePickList || [],
      order: 12
    },
    {
      isMasterData,

      label: 'Inlet size',
      name: 'inletSize',
      type: 'Readings',
      required: false,
      options: dropdownMenuMapList?.SmallMeasurementPickList || [],
      order: 13
    },
    {
      isMasterData,

      label: 'Inlet Joint Style',
      name: 'inletJointStyle',
      type: 'Dropdown',
      required: false,
      options: dropdownMenuMapList?.EquipmentConnectionTypePickList || [],
      order: 14
    },
    {
      isMasterData,

      label: 'Outlet size',
      name: 'outletSize',
      type: 'Readings',
      required: false,
      options: dropdownMenuMapList?.SmallMeasurementPickList || [],
      order: 15
    },

    {
      isMasterData,
      label: 'Outlet Joint Style',
      name: 'outletJointStyle',
      type: 'Dropdown',
      required: false,
      options: dropdownMenuMapList?.EquipmentConnectionTypePickList || [],
      order: 16
    },
    {
      isMasterData,

      label: 'Last serviced date',
      name: 'lastServiceDate',
      type: 'Date',
      required: false,
      order: 17
    },
    {
      isMasterData: false,
      label: 'Last serviced by',
      name: 'lastServiceBy',
      required: false,
      type: 'Text',
      order: 18
    },
    {
      isMasterData: false,
      label: 'Comments (for reviewer)',
      name: 'comment',
      required: false,
      type: 'LongText',
      order: 19
    }
  ];
};

const setDefaultValuesOnForm = ({ cachedPSVData, cachedPSVReconcile }) => {
  const psvCategoryType =
    cachedPSVData?.equipmentCategory &&
    cachedPSVData?.equipmentType &&
    cachedPSVData?.equipmentSubType
      ? cachedPSVData?.equipmentSubType
      : cachedPSVData?.equipmentCategory && cachedPSVData?.equipmentType
      ? cachedPSVData?.equipmentType
      : cachedPSVData?.equipmentCategory
      ? cachedPSVData?.equipmentCategory
      : '';

  const psvReconcileCategoryType =
    cachedPSVReconcile?.equipmentCategory &&
    cachedPSVReconcile?.equipmentType &&
    cachedPSVReconcile?.equipmentSubType
      ? cachedPSVReconcile?.equipmentSubType
      : cachedPSVReconcile?.equipmentCategory && cachedPSVReconcile?.equipmentType
      ? cachedPSVReconcile?.equipmentType
      : cachedPSVReconcile?.equipmentCategory
      ? cachedPSVReconcile?.equipmentCategory
      : '';

  return {
    equipmentNumber: cachedPSVData?.equipmentNumber || '',
    equipmentName: cachedPSVData?.name || '',
    status: cachedPSVData?.status || '',
    valveLocation: cachedPSVData?.valveLocation || '',
    psvCategoryType: psvCategoryType,

    setPressure: {
      textBoxValue: cachedPSVData?.pressure?.textBoxValue || null,
      textBoxUnitValue: cachedPSVData?.pressure?.textBoxUnitValue || ''
    },
    manufacture: cachedPSVData?.manufacturer || '',
    model: cachedPSVData?.model || '',
    serial: cachedPSVData?.serialNumber || '',
    fieldTag: cachedPSVData?.fieldTagNumber || '',
    crn: cachedPSVData?.crnNumber || '',
    psvCapacity: {
      textBoxValue: cachedPSVData?.psvCapacity?.textBoxValue || null,
      textBoxUnitValue: cachedPSVData?.psvCapacity?.textBoxUnitValue || ''
    },
    inletSize: {
      textBoxValue: cachedPSVData?.inletSize?.textBoxValue || null,
      textBoxUnitValue: cachedPSVData?.inletSize?.textBoxUnitValue || ''
    },
    outletSize: {
      textBoxValue: cachedPSVData?.outletSize?.textBoxValue || null,
      textBoxUnitValue: cachedPSVData?.outletSize?.textBoxUnitValue || ''
    },
    inletJointStyle: cachedPSVData?.inletJointStyle || '',
    outletJointStyle: cachedPSVData?.outletJointStyle || '',
    comment: cachedPSVData?.comments || '',
    lastServiceBy: cachedPSVData?.lastServiceBy || '',
    lastServiceDate: cachedPSVData?.lastServiceDate || null,
    ...(cachedPSVReconcile && {
      'equipmentName-reconcile': cachedPSVReconcile?.name || '',
      'status-reconcile': cachedPSVReconcile?.status || '',
      'psvCategoryType-reconcile': psvReconcileCategoryType,
      'valveLocation-reconcile': cachedPSVReconcile?.valveLocation || '',
      'setPressure-reconcile': {
        textBoxValue: cachedPSVReconcile?.pressure?.textBoxValue || null,
        textBoxUnitValue: cachedPSVReconcile?.pressure?.textBoxUnitValue || ''
      },
      'manufacture-reconcile': cachedPSVReconcile?.manufacturer || '',
      'model-reconcile': cachedPSVReconcile?.model || '',
      'serial-reconcile': cachedPSVReconcile?.serialNumber || '',
      'fieldTag-reconcile': cachedPSVReconcile?.fieldTagNumber || '',
      'crn-reconcile': cachedPSVReconcile?.crnNumber || '',
      'psvCapacity-reconcile': {
        textBoxValue: cachedPSVReconcile?.psvCapacity?.textBoxValue || null,
        textBoxUnitValue: cachedPSVReconcile?.psvCapacity?.textBoxUnitValue || ''
      },
      'inletSize-reconcile': {
        textBoxValue: cachedPSVReconcile?.inletSize?.textBoxValue || null,
        textBoxUnitValue: cachedPSVReconcile?.inletSize?.textBoxUnitValue || ''
      },
      'outletSize-reconcile': {
        textBoxValue: cachedPSVReconcile?.outletSize?.textBoxValue || null,
        textBoxUnitValue: cachedPSVReconcile?.outletSize?.textBoxUnitValue || ''
      },
      'inletJointStyle-reconcile': cachedPSVReconcile?.inletJointStyle || '',
      'outletJointStyle-reconcile': cachedPSVReconcile?.outletJointStyle || '',
      'comment-reconcile': cachedPSVReconcile?.comments || '',
      'lastServiceBy-reconcile': cachedPSVReconcile?.lastServiceBy || '',
      'cachedPSVData-reconcile': cachedPSVReconcile?.lastServiceDate || null
    })
  };
};

const defaultValues = {
  equipmentNumber: '',
  equipmentName: '',
  status: '',
  psvCategoryType: '',
  valveLocation: '',
  setPressure: {
    textBoxValue: null,
    textBoxUnitValue: ''
  },
  manufacture: '',
  model: '',
  serial: '',
  fieldTag: '',
  crn: '',
  psvCapacity: {
    textBoxValue: null,
    textBoxUnitValue: ''
  },
  inletSize: {
    textBoxValue: null,
    textBoxUnitValue: ''
  },
  outletSize: {
    textBoxValue: null,
    textBoxUnitValue: ''
  },
  outletJointStyle: '',
  intelJointStyle: '',
  lastServiceBy: '',
  lastServiceDate: null,
  comment: ''
};

export { getFormFields, setDefaultValuesOnForm, schema, defaultValues };
