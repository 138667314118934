import { useEffect } from 'react';

const WhatsOpliiMobile = ({ goToCanISeePage, goToRefreshingPage, goToSyncPage }) => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className="whats-oplii-mobile" style={{ maxWidth: '768px', width: '100%' }}>
      <div className="whats-oplii-mobile--title">What is Oplii Mobile?</div>
      <div className="whats-oplii-mobile--description">
        Oplii Mobile helps dispersed workforces stay connected.
      </div>
      <div className="whats-oplii-mobile--description">
        Users create and complete work orders, inspections and checks, explore asset data, suggest
        changes, and more—all from their phone, tablet or laptop—with or without internet access.
      </div>
      <div className="whats-oplii-mobile--tidbits">
        <ul className="leastClass">
          Other important tidbits:
          <li>
            Oplii Mobile caches a subset of the organization’s asset database so you can work
            offline.
          </li>
          <li>
            What you can see and do depends on your permissions and settings. Check out{' '}
            <span onClick={goToCanISeePage} className="link">
              What can I see?{' '}
            </span>
            to learn more.
          </li>
          <li>
            You must refresh your data regularly to ensure you’ve got the latest data and
            assignments. Syncing submits completed tasks. Learn more about{' '}
            <span onClick={goToRefreshingPage} className="link">
              refreshing
            </span>{' '}
            and{' '}
            <span className="link" onClick={goToSyncPage}>
              syncing
            </span>
            .
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WhatsOpliiMobile;
