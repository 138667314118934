/* eslint-disable no-unused-vars */

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { getTaskCardFooter } from '../global/footer';
import { getTaskSiteAsset } from '../global/site';

const HSEEventSelector = {
  getHSEEvents: ({
    id = '',
    removeConfigurableFieldFromAsset = false,
    appliesTo = false,
    siteId = null,
    formWrapper = false,
    store
  }) => {
    return new Promise((resolve) => {
      const { cache, settings, picklists, inspection = {}, hseEvents = {}, auth } = store;
      const userId = auth.userId || '';

      const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = picklists;
      const { sitesMap = {}, sites: cacheSites = [] } = cache;

      const { siteDisplayFields } = settings.settings;

      let prepareHSEEvents = [];

      let { HSEEventList = [] } = hseEvents;
      let { inspectionTypesMap = {}, incidentCategory = [] } = inspection;
      const incidentCategoryMap = incidentCategory?.reduce((acc, curr) => {
        acc[curr.value] = curr?.label;
        return acc;
      }, {});

      if (siteId) HSEEventList = HSEEventList?.filter((t) => t?.siteId === siteId);

      if (id) HSEEventList = HSEEventList?.filter((t) => t?.id === id);

      HSEEventList?.map((event) => {
        const taskHeaderIconName = 'hse-event-blue';

        let siteAsset = getTaskSiteAsset({
          sitesMap,
          siteId: event?.siteId || null,
          sites: event?.sites || [],
          areaHierarchyTreeDataMap,
          siteDisplayFields,
          picklistsMap,
          removeConfigurableFieldFromAsset,
          cacheSites,
          appliesTo,
          formWrapper,
          store
        });
        siteAsset =
          _.size(siteAsset?.sitesIds) > 0
            ? siteAsset
            : event?.location || event?.legalLocation
            ? {
                sitesIds: [uuidv4()],
                prepareTasksSites: [
                  {
                    title: areaHierarchyTreeDataMap[event.areaId]?.title || '',
                    subtitle: event?.location || event?.legalLocation,
                    siteId: uuidv4(),
                    hseOffsiteEvent: true
                  }
                ]
              }
            : {};

        const taskFooter = getTaskCardFooter({
          status: event?.status || null,
          sync: event?.sync || null,
          dueDate: event?.dueDate || null,
          startDate: event?.createdDate || null,
          due: event?.due || null,
          syncedByText: event.syncedByText || null,
          syncedDate: event.syncedDate || null,
          syncedBy: event.createdBy || null,
          updatedAt: event.updatedAt || null,
          syncDateCustom: event?.syncDateCustom || null,
          userId
        });

        prepareHSEEvents.push({
          taskHeaderIconName,
          taskCardHeaderTitle: event?.incidentNumber || 'HSE event',
          link: event?.link || null,
          inspectionType: event?.incidentCategory
            ? incidentCategoryMap[event?.incidentCategory]
            : inspectionTypesMap[event?.HSETypeId]?.value || null,
          description: event?.description || event?.title || '',
          siteAsset,
          taskFooter,
          checked: false,
          incidentNumber: event?.incidentNumber || 'HSE event',
          ...event
        });
      });

      resolve(prepareHSEEvents);
    });
  }
};

export default HSEEventSelector;
