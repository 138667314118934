import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: 0,
  totalRequest: 0,
  apisResponseCount: {
    equipmentCount: 0,
    sitesCount: 0,
    pipelineCount: 0,
    structuredCount: 0,
    pvAndPsvsCount: 0,
    JurisdictionNumbersCount: 0,
    bookmarksCount: 0,
    tasksCount: 0,
    inspectionCount: 0
  }
};

export const globalProgressBarSlice = createSlice({
  name: 'globalProgressBar',
  initialState,
  reducers: {
    setLoadStatus: (state, action) => {
      state.loading = action.payload;
    },
    setTotalRequest: (state, action) => {
      state.totalRequest = action.payload;
    },
    setApisResponseCount: (state, action) => {
      state.apisResponseCount = { ...action.payload };
    }
  }
});

const globalProgressBarReducer = globalProgressBarSlice.reducer;

export const globalProgressBarAction = globalProgressBarSlice.actions;
export default globalProgressBarReducer;
