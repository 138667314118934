import { memo } from 'react';
import PropTypes from 'prop-types';

import TagList from '../tag-list/tag-list.elements';
import Icon from '@components/icon-component';

import style from './filter-sort.module.scss';

const FilterSort = ({
  count,
  tagsData,
  isFilterActive,
  setFilterActive,
  SelectedFilter,
  setSelectedFilter,
  white
}) => {
  return (
    <div
      className="op-filter-sort"
      style={{
        backgroundColor: white ? 'white' : '#F5F7FA',
        display: 'flex',
        alignItems: 'center',
        width: '100%'
      }}
    >
      {!isFilterActive && (
        <div className={style.onlyIcon} onClick={() => setFilterActive(true)}>
          <Icon name={count > 0 ? 'filter-blue' : 'filter-black'} />
        </div>
      )}
      <div
        className={`btn-sort-wrap ${isFilterActive ? '' : 'btn-sort-wrap-list'}`}
        style={{
          flex: 1
        }}
      >
        {!isFilterActive && <div className="sort-by">Sort By</div>}
        <TagList
          Selected={true}
          TagItems={tagsData}
          TagsState={SelectedFilter}
          ReturnTags={setSelectedFilter}
        />
      </div>
    </div>
  );
};

FilterSort.propTypes = {
  count: PropTypes.number.isRequired,
  isFilterActive: PropTypes.bool.isRequired,
  setFilterActive: PropTypes.func.isRequired,
  SelectedFilter: PropTypes.object.isRequired,
  setSelectedFilter: PropTypes.func.isRequired,
  tagsData: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default memo(FilterSort);
