import { useEffect } from 'react';
import bookmarksHelp from '@assets/images/bookmarksHelp.svg';

import style from './bookmarks.module.scss';

const BookmarksHelp = () => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className={style.bgColor}>
      <div className={style.seeCard}>
        <h6 className={style.titleClass}>What are bookmarks?</h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          Bookmarks are links to prefiltered listings or specific assets.
        </p>
        <p className={style.p} style={{ marginTop: '10px' }}>
          Access and manage bookmarks from the <b>Bookmarks </b> page (via <b>More </b>
          on the navigation bar.)
        </p>
        <p className={style.p} style={{ marginTop: '10px' }}>
          {' '}
          Bookmarks will appear on <b>Home </b>
          if you pin them to the ‘At a Glance’ section.
        </p>
        <div className="whats-oplii-mobile--tidbits" style={{ marginTop: '16px' }}>
          <ul className="leastClass">
            Bookmarking a filter
            <li>Open a listing page (e.g. Equipment)</li>
            <li>Apply filters and sort, as desired</li>
            <li>
              Scroll to the bottom of the filter and select <b>Bookmark filter</b>
            </li>
            <li>Name and save the filter</li>
          </ul>
        </div>
        <div className={style.myImage} style={{ marginTop: '16px' }}>
          <button
            className="task-card-header op-bookmark-filter-help text-center"
            style={{ marginTop: '16px !important' }}
          >
            BOOKMARK FILTER
          </button>
        </div>
        <div className="whats-oplii-mobile--tidbits" style={{ marginTop: '16px' }}>
          <ul className="leastClass">
            Bookmarking an asset
            <li>Open an asset page (e.g. a certain site)</li>
            <li>On the header, hit the menu button on the right</li>
            <li>
              Select <b>Bookmark</b>
            </li>
            <li>Name and save the filter</li>
          </ul>
        </div>
        <div className={style.myImage} style={{ marginTop: '16px' }}>
          <img src={bookmarksHelp} />
        </div>
        <div className="whats-oplii-mobile--tidbits" style={{ marginTop: '16px' }}>
          <ul className="leastClass">
            Adding bookmarks to Home
            <li>
              Open <b>Bookmarks</b>
            </li>
            <li>Find the filter you’d like to pin on</li>
            <li>
              Select the menu button to the right of the filter and select
              <b> Pin to ‘At a glance’</b>
            </li>
          </ul>
        </div>
        <div className="whats-oplii-mobile--tidbits" style={{ marginTop: '16px' }}>
          <ul className="leastClass">
            Deleting a bookmark
            <li>
              Open <b>Bookmarks</b>
            </li>
            <li>Find the filter you’d like to delete</li>
            <li>
              Select the menu button to the right of the filter and select <b>Delete</b>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default BookmarksHelp;
