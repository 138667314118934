import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setAppDispatch } from '@utilities/dispatch.util';

export default function RegisterAppDispatch() {
  const dispatch = useDispatch();

  useEffect(() => {
    setAppDispatch(dispatch);
  }, [dispatch]);

  return null;
}
