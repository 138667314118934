/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

import LoadingRound from '@components/loading-round/loading-round';

import { initWebWorker } from 'webworker';

import { bookmarksActions } from '@redux/slices/bookmarks';
import { getAppDispatch } from '@utilities/dispatch.util';

import style from './home.module.scss';

const Glance = () => {
  const dispatch = getAppDispatch();
  const { cachingComplete: isCachingComplete, refreshingAndSyncingData } = useSelector(
    (state) => state.cache
  );

  const {
    bookmarks = [],
    bookmarkValidity,
    bookmarksWithCount
  } = useSelector((state) => state.bookmarks);
  const [bookmarkList, setBookmarkList] = useState([]);
  const [loading, setLoading] = useState(!refreshingAndSyncingData);
  const dataFetchedRef = useRef(false);

  const atAGlanceBookmarkList = useMemo(() => {
    const glanceBookMark = bookmarks?.filter((book) => book?.atAGlance);
    if (glanceBookMark?.length !== bookmarksWithCount?.length) {
      dispatch?.(bookmarksActions.setBookmarkValidity(true));
    }
    return glanceBookMark;
  }, [bookmarks, bookmarkValidity, bookmarksWithCount]);

  const glanceLink = (bookmark) => {
    const name = bookmark?.name;
    if (
      bookmark?.type === 'Site Details' ||
      bookmark?.type === 'Parent Site Details' ||
      bookmark?.type === 'Equipment Details' ||
      bookmark?.type === 'Structure Details'
    )
      return `${bookmark?.listing}?bookmarkName=${name}` || '/';
    else
      return (
        `${bookmark?.listing}?bookmarkFilter=${bookmark?.filters}&bookmarkName=${
          bookmark?.name
        }${`&bookmarkTab=${bookmark?.tab}`}${`&bookmarkKeyName=${bookmark?.keyName}`}${`&currentOpenTaskTab=${bookmark?.currentOpenTaskTab}`}` ||
        '/'
      );
  };

  useEffect(() => {
    if (!refreshingAndSyncingData) {
      if (isCachingComplete || !dataFetchedRef.current) {
        setLoading(true);
        const checkAllBookmarksIsNotAtAGlance = atAGlanceBookmarkList;
        if (_.size(checkAllBookmarksIsNotAtAGlance) > 0) {
          try {
            if (bookmarkValidity) {
              initWebWorker({
                type: 'get-all-default-bookmarks',
                args: { store: { ...store.getState() }, checkAllBookmarksIsNotAtAGlance }
              })
                .then((data) => {
                  const list =
                    data?.result?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder) || [];
                  dispatch?.(bookmarksActions.setBookmarksWithCount(list));
                  dispatch?.(bookmarksActions.setBookmarkValidity(false));
                  setBookmarkList(list);
                  setLoading(false);
                })
                .catch((error) => {
                  setLoading(false);
                });
            } else {
              setBookmarkList(bookmarksWithCount);
              setLoading(false);
            }
          } catch (e) {
            console.log({ e });
          }
        } else setLoading(false);
      }
    }
  }, [isCachingComplete, atAGlanceBookmarkList, bookmarkValidity, bookmarksWithCount]);

  return (
    <>
      {loading ? (
        <div style={{ maxWidth: '768px' }}>
          <LoadingRound className={style.glanceLoaderHight} />
        </div>
      ) : (
        <>
          <label className={`${style['label-capital-semibold']} mx-4 md:mx-8  block`}>
            At a Glance{' '}
          </label>

          <div
            className={`${style['op-glance-wrap']} mt-4 pb-24 md:pb-16 md:pl-8 md:pr-24 ${style.myClassScroll}`}
          >
            <div
              className={`${style['op-glance-row']} pb-4 md:pb-0 flex flex-nowrap  gap-4 md:flex-wrap`}
            >
              {bookmarkList?.map(
                (list, index) =>
                  list?.atAGlance && (
                    <div
                      className=""
                      key={list?.bookmarkId || index}
                      style={{ paddingRight: index === bookmarkList.length - 1 ? '15px' : '' }}
                    >
                      <Link to={glanceLink(list)}>
                        <div className={style.box}>
                          <h1>{list?.count > 999 ? '999+' : list?.count}</h1>{' '}
                          <div className={style.ellipsis}>
                            <p className={`${list?.name.length > 15 && style.ellipsisText}`}>
                              {list?.name}
                            </p>{' '}
                            {list?.name.length > 15 && (
                              <div className={style.tooltip}>
                                <p>{list?.name}</p>{' '}
                              </div>
                            )}
                          </div>
                        </div>
                      </Link>
                    </div>
                  )
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(Glance);
