/* eslint-disable no-unused-vars */

import React from 'react';

import style from './checkablePill.module.scss';

const CheckablePill = ({ item, className = '', checked, onChange, SelectTags, children }) => {
  const clickHandler = () => {
    checked ? onChange(false) : onChange(true);
  };

  return (
    <div
      className={
        checked ? `${style.taglistItem} ${style.active}` : `${style.taglistItem} ${className}`
      }
      onClick={clickHandler}
    >
      {children}
    </div>
  );
};

export default CheckablePill;
