/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import { store } from 'redux/store';

import SitesService from './sites.service';
import TasksService from './tasks.service';
import SettingsService from './settings.service';
import PickListService from './picklist.service';
import InspectionService from './inspection.service';
import PipelineService from './pipeline.service';
import StructureService from './structure.service';
import http from './http.service';
import SyncingService from './inspection-work-order-checks-syncing.service';
import EquipmentService from './equipment.service';
import BookmarksService from './bookmarks.service';

import { homeActions } from '@redux/slices/home';
import { cacheActions } from '@redux/slices/cache';
import { getAppDispatch } from '@utilities/dispatch.util';
import { settingsActions } from '@redux/slices/settings';
import { filterActions } from '@redux/slices/filters';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
import MobileSyncCountService from './mobile-sync-count.service';
import waitForLocalStorage from '@utilities/wait-for-local-storage';
import checkTokenIsGoingToExpire from '@utilities/token-expiry-check.util';
import AuthService from './auth.service';
import HSEEventService from './hse-event.service';
import ChecksService from './checks.service';

const indexedDB =
  window.indexedDB ||
  window.mozIndexedDB ||
  window.webkitIndexedDB ||
  window.msIndexedDB ||
  window.shimIndexedDB;

const CacheService = {
  refresh: async ({
    initialCache = false,
    updatedSettings,
    sync,
    syncFailedAssets = false,
    workOrdersPendingSync = [],
    inspectionPendingSync = [],
    pendingHseEvents = [],
    pendingChecks = [],
    pendingSyncLength,
    bookmarks = [],
    updateBookmarks = [],
    deletedBookmarks = [],
    initialLogin = false,
    retrySync = false
  } = {}) => {
    const { cache, auth, settings } = store.getState();
    const { lastSuccessFullRefreshTime } = cache;
    const { isSettingUpdated } = settings;

    const tokenIsExpire = checkTokenIsGoingToExpire();
    if (tokenIsExpire) {
      await AuthService.login(
        {
          emailAddress: auth?.emailAddress || '',
          password: auth?.password || ''
        },
        false
      );
    }

    const dispatch = getAppDispatch?.();

    dispatch?.(cacheActions.updateSyncingReminder(false));

    dispatch?.(filterActions.setTaskFilters(null));
    dispatch?.(filterActions.setSitesFilters(null));
    dispatch?.(filterActions.setEquipmentsFilters(null));

    dispatch?.(cacheActions.updateRefreshingAndSyncingData(true));
    if (sync) {
      dispatch?.(cacheActions.updateSyncing(true));
      dispatch?.(cacheActions.updateSyncingModal(true));
      try {
        const pendingSyncInspectionLength = inspectionPendingSync?.length || 0;
        const pendingSyncWorkOrderLength = workOrdersPendingSync?.length || 0;
        const pendingSyncHSEEventsLength = pendingHseEvents?.length || 0;

        for (let item in inspectionPendingSync) {
          const index = parseInt(item);
          const newRequest = inspectionPendingSync[index];
          await SyncingService.inspectionAndWorkOrderService({
            currentTask: newRequest,
            sync: false,
            doneNumber: index + 1,
            pendingSyncLength,
            type: newRequest?.type === 'Inspection' ? 'Inspection' : 'Work Order',
            retry: retrySync
          });
        }

        for (let item in workOrdersPendingSync) {
          const index = parseInt(item);

          const newRequest = workOrdersPendingSync[index];
          await SyncingService.inspectionAndWorkOrderService({
            currentTask: newRequest,
            sync: false,
            doneNumber:
              pendingSyncInspectionLength > 0 ? pendingSyncInspectionLength + index + 1 : index + 1,
            pendingSyncLength,
            type: newRequest?.type === 'Inspection' ? 'Inspection' : 'Work Order',
            retry: retrySync
          });
        }

        for (let item in pendingHseEvents) {
          const index = parseInt(item);
          const newHse = pendingHseEvents[index];
          await SyncingService.inspectionAndWorkOrderService({
            currentTask: newHse,
            sync: false,
            doneNumber:
              pendingSyncInspectionLength > 0 && pendingSyncWorkOrderLength > 0
                ? pendingSyncInspectionLength + pendingSyncWorkOrderLength + index + 1
                : pendingSyncInspectionLength > 0
                ? pendingSyncInspectionLength + index + 1
                : index + 1,
            pendingSyncLength,
            type: 'HSE',
            retry: retrySync
          });
        }

        for (let item in pendingChecks) {
          const index = parseInt(item);
          const newCheck = pendingChecks[index];
          await SyncingService.inspectionAndWorkOrderService({
            currentTask: newCheck,
            sync: false,
            doneNumber:
              pendingSyncInspectionLength > 0 &&
              pendingSyncWorkOrderLength > 0 &&
              pendingSyncHSEEventsLength > 0
                ? pendingSyncInspectionLength +
                  pendingSyncWorkOrderLength +
                  pendingSyncHSEEventsLength +
                  index +
                  1
                : pendingSyncInspectionLength > 0 && pendingSyncWorkOrderLength > 0
                ? pendingSyncInspectionLength + pendingSyncWorkOrderLength + index + 1
                : pendingSyncInspectionLength > 0
                ? pendingSyncInspectionLength + index + 1
                : index + 1,
            pendingSyncLength,
            type: 'Check',
            retry: retrySync
          });
        }

        if (syncFailedAssets) {
          await SyncingService.inspectionAndWorkOrderService({
            syncAssetsList: syncFailedAssets,
            currentTask: {},
            sync: false,
            doneNumber: null,
            pendingSyncLength: null,
            type: 'syncFailedAsset',
            retry: retrySync
          });
        }
      } catch (err) {
        console.error(err);
      }
      dispatch?.(cacheActions.setWorkOrderInspectionsAndChecksIsSync(true));
    } else {
      // dispatch?.(cacheActions.setWorkOrderInspectionsAndChecksIsSync(false));
      dispatch?.(cacheActions.startCaching());
    }

    if (process.env.NODE_ENV !== 'development') {
      await waitForLocalStorage('isCacheComplete');
      dispatch?.(globalProgressBarAction.setLoadStatus(1)); //1
    } else dispatch?.(globalProgressBarAction.setLoadStatus(1));

    const [settingsUpdated] = await SettingsService.updateSettings(updatedSettings);
    if (settingsUpdated) {
      if (!_.isEmpty(bookmarks)) {
        await Promise.allSettled(
          bookmarks?.map(async (bookmark) => await BookmarksService.createBookmark(bookmark))
        );
      }
      if (!_.isEmpty(updateBookmarks)) {
        await Promise.allSettled(
          updateBookmarks?.map(
            async (bookmark) => await BookmarksService.updateBookmarksDataOnSync(bookmark)
          )
        );
      }
      if (!_.isEmpty(deletedBookmarks)) {
        await Promise.allSettled(
          deletedBookmarks?.map(
            async (bookmark) => await BookmarksService.deleteBookmarksOnSync(bookmark)
          )
        );
      }

      try {
        http.setJWT();

        await MobileSyncCountService.getDataFromMobileSyncCount({
          initialLogin,
          lastSuccessFullRefreshTime: isSettingUpdated ? '' : lastSuccessFullRefreshTime
        }); //2

        await SettingsService.checkUpdate({}); //3
        await EquipmentService.setEquipments({
          initialLogin,
          lastSuccessFullRefreshTime: isSettingUpdated ? '' : lastSuccessFullRefreshTime,
          isSettingUpdated
        }); //4
        await PickListService.getAllPickLists(); //5
        await EquipmentService.getPVPSV(); //6
        await EquipmentService.getJurisdictionNumbers(); //7
        await BookmarksService.getDefaultBookmarks(); //8
        await SitesService.getSites({
          initialLogin,
          lastSuccessFullRefreshTime: isSettingUpdated ? '' : lastSuccessFullRefreshTime,
          isSettingUpdated
        }); //9
        await PipelineService.getPipelineMobile({
          initialLogin,
          lastSuccessFullRefreshTime: isSettingUpdated ? '' : lastSuccessFullRefreshTime,
          isSettingUpdated
        }); //10
        await StructureService.getStructureMobile({
          initialLogin,
          lastSuccessFullRefreshTime: isSettingUpdated ? '' : lastSuccessFullRefreshTime,
          isSettingUpdated
        }); //11
        await TasksService.getAllTasksList(
          sync,
          initialLogin,
          false,
          isSettingUpdated ? null : lastSuccessFullRefreshTime
        ); //12
        await InspectionService.getAllInspectionList(
          sync,
          initialLogin,
          false,
          isSettingUpdated ? null : lastSuccessFullRefreshTime,
          isSettingUpdated
        ); //13
        await HSEEventService.getAllHseEvents({
          sync,
          initialLogin
          // false,
          // isSettingUpdated? null: lastSuccessFullRefreshTime,
          // isSettingUpdated
        }); //14

        await ChecksService.getAllChecksEvents({
          sync,
          initialLogin
        }); //15

        await InspectionService.getInspectionFormUserCacheCache({
          initialLogin,
          lastSuccessFullRefreshTime: isSettingUpdated ? '' : lastSuccessFullRefreshTime,
          isSettingUpdated
        }); //16

        if (settingsUpdated) await SettingsService.getSettings();

        if (!settingsUpdated) {
          if (sync) {
            //syncing error will be dealt here
            return [false, true];
          } else {
            //refreshing error dealt here
            !JSON.parse(localStorage.getItem('cachingFailure')) &&
              dispatch?.(cacheActions.failureCaching());
            return [false, true];
          }
        }
        dispatch?.(settingsActions.setSyncReminderFrequency(0));
        return [true, false];
      } catch (error) {
        dispatch?.(cacheActions.updateRefreshingAndSyncingData(false));

        if (sync) {
          //syncing error will be dealt here
          return [false, true];
        } else {
          !JSON.parse(localStorage.getItem('cachingFailure')) &&
            dispatch?.(cacheActions.failureCaching());
          return [false, true];
        }
      } finally {
        const cachingFailure = localStorage.getItem('cachingFailure');

        if (!JSON.parse(cachingFailure)) {
          !sync && dispatch?.(cacheActions.completeCaching());
          // !sync &&
          dispatch?.(
            cacheActions.setLastSuccessFullRefreshTime(
              moment(new Date().toLocaleString('en-US', { timeZone: 'America/Denver' }))
                .format('YYYY-MM-DD HH:mm:ss')
                .toString()
            )
          );
        } else {
          dispatch?.(globalProgressBarAction.setLoadStatus(0));
        }
        if (initialCache) dispatch?.(homeActions.setInitialCache(true));
        if (initialLogin) await AuthService.successfulLoginUser();
      }
    }
  },

  updateCacheFromIndexedDB: () => {
    if (!indexedDB) return;

    const request = indexedDB.open('OpliiCache', 1);

    request.onerror = (e) => console.error(e);
    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains('cache')) db.createObjectStore('cache', { keyPath: 'id' });
    };
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction('cache', 'readwrite');
      const store = transaction.objectStore('cache');
      const cacheQuery = store.get(1);

      cacheQuery.onsuccess = () => {
        if (!cacheQuery.result?.value) return;

        const { value } = cacheQuery.result;
      };

      transaction.oncomplete = () => db.close();
    };
  },
  setPendingSyncTasksNumber: (tasksLength) => {
    const dispatch = getAppDispatch();
    dispatch?.(cacheActions.setPendingSync(tasksLength));
  },

  saveCacheInIndexedDB: (data) => {
    if (!indexedDB) return;

    const request = indexedDB.open('OpliiCache', 1);

    request.onerror = (e) => console.error(e);
    request.onupgradeneeded = () => {
      const db = request.result;
      if (!db.objectStoreNames.contains('cache')) db.createObjectStore('cache', { keyPath: 'id' });
    };
    request.onsuccess = () => {
      const db = request.result;
      const transaction = db.transaction('cache', 'readwrite');
      const store = transaction.objectStore('cache');
      const { sites = [], equipments = [], pendingSync } = data;
      const value = { pendingSync };

      store.put({ id: 1, value });

      transaction.oncomplete = () => db.close();
    };
  }
};

export default CacheService;
