/* eslint-disable no-unused-vars */
import _ from 'lodash';

const startBtn = ({
  onParentClickEvent,
  tab,
  SelectedFilter,
  runs,
  templates,
  templateData,
  tasks,
  searchParams,
  location,
  selectedOrderIds
}) => {
  const selectedOrdersIds = [],
    selectedTypeIds = [];
  let type = '';
  onParentClickEvent && onParentClickEvent({ workOrderTab: tab, workOrderSortTab: SelectedFilter });
  if (runs?.length > 0 || templates?.length > 0) {
    for (const el of templateData) {
      for (const task of el.tasks) {
        if (task.checked) {
          type = task?.woId
            ? task?.newWorkOrder
              ? 'new-work-order'
              : task?.newWorkRequest
              ? 'new-work-request'
              : ''
            : runs?.length
            ? 'inspection'
            : 'work-orders';
          selectedTypeIds.push(type);

          if (type === 'work-orders' || type === 'inspection') {
            selectedOrdersIds.push(task.id);
          } else {
            selectedOrdersIds.push(task.woId);
          }
        }
      }
    }
  } else {
    for (const task of tasks) {
      if (task?.checked) {
        type = task?.woId
          ? task?.newWorkOrder
            ? 'new-work-order'
            : task?.newWorkRequest
            ? 'new-work-request'
            : ''
          : task?.inspectableItemId
          ? 'inspection'
          : 'work-orders';

        selectedTypeIds.push(type);
        if (type === 'work-orders') {
          selectedOrdersIds.push(task?.id);
        } else if (type === 'inspection') selectedOrdersIds.push(task?.inspectableItemId);
        else {
          selectedOrdersIds.push(task?.woId);
        }
      }
    }
  }

  const allQueryList = Object.fromEntries([...searchParams]);
  let urlType = selectedTypeIds?.includes('inspection') ? 'inspection' : '';
  urlType = selectedTypeIds?.some(
    (x) => x === 'work-orders' || x === 'new-work-order' || x === 'new-work-request'
  )
    ? _.isEmpty(urlType)
      ? 'work-orders'
      : urlType?.concat(',work-orders')
    : urlType;
  let navigateURL = `/work-order/?from=${
    searchParams.get('from')
      ? `${location.pathname}?from=${searchParams.get('from')}`
      : location.pathname
  }&type=${urlType}&orderIds=${selectedOrderIds.join(',')}`;

  navigateURL = allQueryList?.recurringTemplate
    ? navigateURL.concat(`&recurringTemplateQuery=${JSON.stringify(allQueryList)}`)
    : navigateURL;
  return { navigateURL };
};

const openModalEvent = ({ id, type, currentTask, tasksList }) => {
  let selectedTaskIndex = -1;
  if (type === 'Inspection' || type === 'HSE' || type === 'Check')
    selectedTaskIndex = tasksList?.findIndex((x) => x?.id === id);
  else if (type === 'Work Order')
    selectedTaskIndex = tasksList?.findIndex((x) => x?.woId === id || x?.id === id);

  const selectedTask = currentTask || {};
  let modalType = '';
  if (selectedTask?.id === 'New Work request') modalType = 'work request';
  else if (selectedTask?.id === 'New Work order') modalType = 'work order';
  else if (selectedTask?.type === 'Work Order') modalType = 'work order';
  else if (selectedTask?.type === 'Inspection') modalType = 'inspection';
  else if (selectedTask?.type === 'HSE') modalType = 'HSE event';
  else if (selectedTask?.type === 'Check') modalType = 'Check';

  return {
    selectedTaskIndex,
    popupData: {
      actionBtnText: `SYNC ${modalType.toUpperCase()}`,
      subtitle: `Only this one ${modalType} will be synced.`
    }
  };
};

export { startBtn, openModalEvent };
