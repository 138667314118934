/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { store } from 'redux/store';

import AppBar from '@components/app-bar';
import TasksList from './tasks-records-list';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { getTemplates, transformTaskRecords } from './helper';

import { initWebWorker } from 'webworker';

import CheckSelector from 'selectors/checks/check.selector';
import { cacheActions } from '@redux/slices/cache';
import { getAppDispatch } from '@utilities/dispatch.util';
import useDistance from '@hooks/useDistance';

const Tasks = () => {
  const dataFetchedRef = useRef(false);
  let _store = { ...store.getState() };
  const location = useLocation();
  const { width } = useWindowDimensions();
  const dispatch = getAppDispatch();
  const latestDistanceCalculate = useDistance({});
  const coordinates = useSelector((state) => state.cache.coordinates);

  const {
    type = null,
    runs: runString = null,
    templates: templateString = null
  } = Object.fromEntries(new URLSearchParams(location.search));
  const { selectTask = false, tasksList: reduxTasksList = [] } = useSelector(
    (state) => state.tasks
  );
  const picklistsMap = useSelector((state) => state.picklists.picklistsMap) || {};

  const { HSEEventList } = useSelector((state) => state.hseEvents);
  const { inspectionsList } = useSelector((state) => state.inspection);
  const { checksList } = useSelector((state) => state.checks);
  const parentRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const [tasksList, setTasksList] = useState([]);
  const [templateTasksLists, setTemplateTasksLists] = useState([]);

  const runs = useMemo(() => runString?.split(',').filter((el) => el), [runString]);
  const templates = useMemo(() => templateString?.split(',').filter((el) => el), [templateString]);

  useEffect(() => {
    if (latestDistanceCalculate.coordinates && coordinates) {
      dispatch?.(
        cacheActions.setUnitAndCoordinates({
          unit: latestDistanceCalculate?.unit,
          coordinates: latestDistanceCalculate?.coordinates
        })
      );
    }

    dispatch?.(cacheActions.setSitesMobile());

    initWebWorker({
      args: { store: { ..._store } },
      type: 'get-all-tasks-records'
    })
      .then((data) => {
        if ((runs?.length && runString) || (templates?.length && templateString)) {
          let templateTasks = getTemplates({
            type: type ? type : 'Work Order',
            tasks: data?.result,
            runs,
            templates
          });
          setTemplateTasksLists(templateTasks);
          const all = templateTasks?.map((x) => x?.tasks);
          const list = transformTaskRecords({ result: _.flattenDeep(all) || [], picklistsMap });
          setTasksList(list);
          setLoading(false);
        } else {
          const list = transformTaskRecords({ result: data?.result || [], picklistsMap });
          setTasksList(list);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
    // }
  }, [
    // _store,
    reduxTasksList,
    inspectionsList,
    runs,
    templates,
    location?.pathname,
    templateString,
    HSEEventList,
    checksList,
    dispatch,
    coordinates,
    latestDistanceCalculate.coordinates
  ]);

  const moveTaskRecordPageToTop = useCallback(() => {
    parentRef?.current?.moveToTopOnClickTaskIcon();
  }, [parentRef]);

  return (
    <div className="page-container">
      {(!selectTask || width > 767) && <AppBar moveTaskRecordPageToTop={moveTaskRecordPageToTop} />}
      <div className="md:ml-24">
        <TasksList
          loading={loading}
          tasksList={tasksList}
          ref={parentRef}
          runs={runs}
          templates={templates}
          templateTasksLists={templateTasksLists}
        />
      </div>
    </div>
  );
};

export default Tasks;
