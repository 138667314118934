/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';

import CheckablePill from '@components/tags/check-able-pill/checkable-pill';

import style from './tags.module.scss';

const Tags = ({ tags, multi, value, selectValue, onChange }) => {
  const selected = value.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const handleChange = (tag, checked) => {
    if (!multi) onChange(checked ? [tag] : []);
    else {
      if (checked) onChange([...value, tag]);
      else onChange(value.filter((el) => tag !== el));
    }
  };

  return (
    <>
      {tags.length > 0 ? (
        <div className="btn-sort-wrap op-task-status-tag">
          {tags.map((tag, index) => (
            <CheckablePill
              key={index}
              item={tag?.value || tag}
              SelectTags={selected}
              checked={selected[(selectValue ? tag?.value : tag?.key) || tag]}
              className={`${style['other-capitalized-caption']}`}
              onChange={(checked) =>
                handleChange((selectValue ? tag?.value : tag?.key) || tag, checked)
              }
            >
              {tag?.value || tag}
            </CheckablePill>
          ))}
        </div>
      ) : (
        'No Tags Available'
      )}
    </>
  );
};

Tags.propTypes = {
  multi: PropTypes.bool,
  selectValue: PropTypes.bool,
  Selected: PropTypes.bool,
  onChange: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default Tags;
