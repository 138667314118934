/* eslint-disable no-unused-vars */
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { store } from 'redux/store';

import { checkActions } from '@redux/slices/checks';
import { getAppDispatch } from '@utilities/dispatch.util';
import http from './http.service';
import Promisable from './promisable.service';
import { cacheActions } from '@redux/slices/cache';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
import { transformFormWrapperDataToNewCheck } from 'transformers/check';
import LocalStorage from '@utilities/local-storage.util';
import { decodeToken } from '@utilities/authentication';
import { deleteTaskFromLocalStorageAfterSuccessFullSynced } from '@utilities/attachments';

const ChecksService = {
  getAllChecksEvents: async ({ sync, initialLogin }) => {
    const dispatch = getAppDispatch();
    const { cache, settings, checks } = store.getState();
    const changedAfter = settings?.isSettingUpdated ? '' : cache?.lastSuccessFullRefreshTime;
    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      http.setJWT();
      const allChecksMap =
        _.size(checks?.checksList) > 0 && !initialLogin
          ? checks?.checksList?.reduce((acc, checkTask) => {
              acc[checkTask._id] = true;
              return acc;
            }, {})
          : {};

      const res = await Promise.all([
        Promisable.asPromise(http.get(`/AssignedAssetCheckCache/MobileCache`)),
        Promisable.asPromise(http.get(`/SyncedAssetCheckCache/MobileCache`))
      ]);

      let [[checkCache = []] = [], [syncCheckCache = []] = []] = res;

      const checksAll = [
        ...(syncCheckCache?.data?.map((check) => ({
          ...check,
          status: 'Synced',
          type: 'Check',
          id: check?.checkNumber || null,
          _id: check?.checkNumber || null
        })) || []),

        ...(checkCache?.data?.map((check) => ({
          ...check,
          status: 'To do',
          type: 'Check',
          id: uuidv4() || null,
          _id: check?.id || uuidv4()
        })) || [])
      ];

      let newSyncedChecks = [];
      if (!_.isEmpty(allChecksMap)) {
        newSyncedChecks = checksAll.map((check) => {
          if (!allChecksMap[check._id]) {
            return {
              syncDateCustom: moment().format('YYYY-MM-DD'),
              ...check
            };
          } else return check;
        });
      } else newSyncedChecks = checksAll;

      dispatch?.(checkActions.setChecksList(newSyncedChecks || []));

      if (sync) dispatch?.(cacheActions.updateSyncing(false));
      const status = res.filter(([success]) => success).length === res.length;
      dispatch?.(globalProgressBarAction.setLoadStatus(14));

      return [status, !status];
    } else throw new Error('Api failed to update');
  },
  setNewChecksData: async ({ checkData, checkId }) => {
    const dispatch = getAppDispatch();

    const transformData = transformFormWrapperDataToNewCheck({
      checkData,
      checkId
    });

    if (!_.isEmpty(checkId)) {
      dispatch?.(
        checkActions.updateNewCheck({
          checkId,
          data: {
            ...transformData,
            updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
          }
        })
      );
    } else {
      dispatch?.(
        checkActions.addNewCheck({
          createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          ...transformData
        })
      );
    }
  },

  deleteNewCheck: async ({ checkId }) => {
    const dispatch = getAppDispatch();
    dispatch?.(checkActions.deleteCheck(checkId));

    const userId = store.getState().auth.userId;
    await deleteTaskFromLocalStorageAfterSuccessFullSynced({ userId, checks: true, id: checkId });
  },

  getChecksFromStorage: async (userId) => {
    const dispatch = getAppDispatch();

    let pendingChecks = [];
    const pendingSyncAndInProgressChecksOfCurrentUser = (
      (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) ||
      []
    )?.find((item) => item.userId === userId);
    if (!_.isEmpty(pendingSyncAndInProgressChecksOfCurrentUser))
      pendingChecks = !_.isEmpty(pendingSyncAndInProgressChecksOfCurrentUser)
        ? pendingSyncAndInProgressChecksOfCurrentUser?.checks
        : [];
    dispatch?.(checkActions.setLocalStorageChecks(pendingChecks || []));
  }
};

export default ChecksService;
