import React from 'react';
import PropTypes from 'prop-types';

import './assets-kind-list.scss';

const AssetsKindList = ({ list, onSelect }) => {
  return (
    <div className="assets-kind">
      {list.map(({ icon, title }, index) => (
        <div key={index} onClick={() => onSelect(title)}>
          {icon}
          {title}
        </div>
      ))}
    </div>
  );
};

AssetsKindList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired
    })
  ).isRequired
};

export default AssetsKindList;
