import _ from 'lodash';
import Compressor from 'compressorjs';

import LocalStorage from '@utilities/local-storage.util';
import { decodeToken } from '@utilities/authentication';

export const downloadPDF = async ({ pdf, fileName, url }) => {
  const downloadLink = document.createElement('a');

  if (!_.isEmpty(url)) {
    // If the app is online, use the URL or base64 PDF data
    if (navigator.onLine) {
      downloadLink.href = url;
    } else {
      // If the app is offline, try to get the PDF from the cache
      try {
        const cache = await caches.open('documents');
        const response = await cache.match(url);

        if (response) {
          const blob = await response.blob();
          const reader = new FileReader();

          reader.onloadend = () => {
            downloadLink.href = reader.result;
            downloadLink.download = fileName;
            downloadLink.click();
          };

          reader.readAsDataURL(blob);
        } else {
          console.log('PDF not found in cache');
        }
      } catch (error) {
        console.error('Error while downloading PDF:', error);
      }

      return;
    }
  } else {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    downloadLink.href = linkSource;
  }

  downloadLink.download = fileName;
  downloadLink.click();
};

export const b64toBlob = (base64, type = 'application/octet-stream') =>
  fetch(`data:${type};base64,${base64}`).then((res) => res.blob());

export const convertBase64ToFile = (base64, filename) => {
  const mime = base64.split(',')[0].split(':')[1].split(';')[0];
  const binary = atob(base64.split(',')[1]);
  const array = new Uint8Array(binary.length);
  for (let i = 0; i < binary.length; i++) array[i] = binary.charCodeAt(i);
  const blob = new Blob([array], { type: mime });
  return new File([blob], filename, { type: mime });
};

export const compressImage = ({ uploadedFile, compressSize }) => {
  return new Promise((resolve, reject) => {
    const uploadedFileSize = parseInt(uploadedFile.size / (1024 * 1024));
    const reader = new FileReader();
    reader.readAsDataURL(uploadedFile);
    reader.onload = (e) => {
      var image = new Image();
      image.src = e.target.result;
      image.onload = function () {
        new Compressor(uploadedFile, {
          quality: 1,
          width: parseInt(this.width * compressSize),
          height: parseInt(this.height * compressSize),
          success(result) {
            const compressImageSize = parseInt(result.size / (1024 * 1024));

            if (compressImageSize <= uploadedFileSize && compressImageSize <= 5) {
              return resolve(result);
            } else {
              return compressImage({ uploadedFile: result, compressSize })
                .then(resolve)
                .catch(reject);
            }
          },
          error() {
            return reject('error fot compressing a file');
          }
        });
      };
    };
  });
};
export const bytesToMB = (bytes, maxFileSize) => {
  return bytes / (1024 * 1024) > parseInt(maxFileSize);
};

export const deleteTaskFromLocalStorageAfterSuccessFullSynced = async ({
  userId,
  inspections = false,
  tasks = false,
  hseEvents = false,
  checks = false,
  id
}) => {
  const getPreviousUserPendingAndInprogressTasks =
    (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) || [];

  const findIndex = getPreviousUserPendingAndInprogressTasks?.findIndex(
    (x) => x?.userId === userId
  );

  if (findIndex > -1) {
    if (tasks) {
      getPreviousUserPendingAndInprogressTasks[findIndex].tasks =
        getPreviousUserPendingAndInprogressTasks[findIndex].tasks.filter(
          (tas) => ![tas.woId, tas.workorderId].includes(id)
        );
    }

    if (inspections) {
      getPreviousUserPendingAndInprogressTasks[findIndex].inspections =
        getPreviousUserPendingAndInprogressTasks[findIndex].inspections?.filter((x) => {
          return x.id !== id;
        });
    }

    if (hseEvents) {
      getPreviousUserPendingAndInprogressTasks[findIndex].hseEvents =
        getPreviousUserPendingAndInprogressTasks[findIndex].hseEvents?.filter((x) => {
          return x.id !== id;
        });
    }

    if (checks) {
      getPreviousUserPendingAndInprogressTasks[findIndex].checks =
        getPreviousUserPendingAndInprogressTasks[findIndex].checks?.filter((x) => {
          return x.id !== id;
        });
    }

    await LocalStorage.setItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`, [
      getPreviousUserPendingAndInprogressTasks[findIndex]
    ]);
  }
};
