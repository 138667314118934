import { Link } from 'react-router-dom';

import Icon from '@components/icon-component';

const EmailMsgPassReset = () => {
  return (
    <div className="op-login-container">
      <div className="op-login">
        <Icon className="mx-auto mb-9" name={'email-guy'} />
        <p className="body-regular mb-7 px-3">
          Great! We’ve sent an email containing instructions to reset your password to your email
          address.
        </p>
        <p className="body-regular mb-9 px-3">You should receive your request shortly.</p>
        <Link className="btn-fill button-large" to={'/new-password'}>
          Back to login page
        </Link>

        <div className="op-divider mt-9"></div>
        <div className="flex flex-wrap justify-between mt-2">
          <Link className="input-bold op-anchor-link" to={'/'}>
            Log in
          </Link>
          <Link className="input-bold op-anchor-link" to="/support-msg">
            Contact support
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmailMsgPassReset;
