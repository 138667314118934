/* eslint-disable no-unused-vars */

import _ from 'lodash';

const InspectionRunSelector = {
  getInspectionRuns: ({ store }) => {
    return new Promise((resolve) => {
      const { inspection } = store;

      const inspectionRunMap = inspection?.inspectionRunMap || {};

      const runs =
        Object.keys(inspectionRunMap)?.map((el) => ({
          ...inspectionRunMap[el],
          allotDoneInspections: inspectionRunMap[el]?.allotDoneInspections,
          allInspections: inspectionRunMap[el].allInspections,
          checked: false,
          dot: !!el.isOverDue && true,
          total: el.allInspections || 0,
          done: el.allotDoneInspections || 0,
          status: 'To do'
        })) || [];

      const inspectionRuns = runs?.map((inspection) => {
        return {
          ...inspection,
          status: inspection?.status
            ? ['To do', 'Pending sync', 'In progress', 'Synced'].includes(inspection?.status)
              ? inspection?.status
              : inspection?.link
            : 'To do'
        };
      });
      resolve(inspectionRuns);
    });
  }
};

export default InspectionRunSelector;
