/* eslint-disable no-unused-vars */

import moment from 'moment';
import _ from 'lodash';

import { removeUnavailableTreeData } from '@components/tree-component';
import { searchPipelineData } from '@utilities/pipeline-search';

export const getFilterPipeline = ({
  filters,
  store,
  sitesMap,
  pipelines,
  SelectedFilter,
  cacheSites
}) => {
  const {
    searched = '',
    pipelineStatus,
    pipelineArea,
    taskType,
    taskStatus,
    priority,
    assign,
    scheduledDate,
    download,
    dueDate: filterDueDate,
    workOrderPriority,
    createdAt,
    syncedDate: syncedDateFilter,
    checkRecurrancePriority,
    inspectionType
  } = filters;

  const pipelinesPipelineStatusData = {},
    pipelinesAreaHierarchyTreeData = {},
    pipelinesWorkOrderPriorityDataMap = {};

  const inspectionTypeMap = inspectionType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedRecurrancePriority = checkRecurrancePriority?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedAreaMap = pipelineArea.value.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedWorkOrderPriority = workOrderPriority?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedPipelineStatusMap = pipelineStatus.value.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedTaskType = taskType.value.reduce((acc, curr) => {
    acc[
      curr === 'Work orders'
        ? 'Work Order'
        : curr === 'Work requests'
        ? 'Work Request'
        : curr === 'Inspections'
        ? 'Inspection'
        : curr === 'Check'
        ? 'Check'
        : 'Work Order'
    ] = true;
    return acc;
  }, {});

  const selectedTaskStatus = taskStatus.value.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedPriority = priority.value.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const pipelineWithDataPoints = pipelines;

  const filteredPipeline = pipelineWithDataPoints
    .filter((pipeline) => {
      const { status, area, allTasksAndInspectionsForPipeline } = pipeline;

      pipelinesAreaHierarchyTreeData[area] = true;

      pipelinesPipelineStatusData[status] = true;

      if (!searchPipelineData({ pipeline, inputValue: searched, store })) return false;

      if (pipelineArea.value.length > 0 && !selectedAreaMap[area]) return false;
      if (pipelineStatus.value.length > 0 && !selectedPipelineStatusMap[status]) return false;

      const dueCon = _.isArray(filterDueDate.value) ? !filterDueDate.value.includes('') : true;
      const scheduleCon = _.isArray(scheduledDate.value) ? !scheduledDate.value.includes('') : true;
      const createAtCon = _.isArray(createdAt?.value) ? !createdAt?.value.includes('') : true;
      const syncedDateCon = _.isArray(syncedDateFilter?.value)
        ? !syncedDateFilter?.value.includes('')
        : true;

      if (
        taskType.value.length > 0 &&
        !allTasksAndInspectionsForPipeline.some(
          (x) => selectedTaskType[x?.newWorkRequest ? 'Work Request' : x.type]
        )
      )
        return false;

      if (
        taskStatus.value.length > 0 &&
        !allTasksAndInspectionsForPipeline.some((x) => selectedTaskStatus[x.status])
      )
        return false;

      allTasksAndInspectionsForPipeline.map((x) => {
        if (!pipelinesWorkOrderPriorityDataMap[x.priority])
          pipelinesWorkOrderPriorityDataMap[x.priority] = true;
      });

      if (
        priority.value.length > 0 &&
        !allTasksAndInspectionsForPipeline.some(
          (x) => x.type === 'Work Order' && selectedPriority[x.priority]
        )
      )
        return false;
      if (
        assign?.value?.length > 0 &&
        !allTasksAndInspectionsForPipeline.some((y) =>
          assign.value.some((r) => y?.customAssignedTaskValue?.includes(r))
        )
      )
        return false;

      if (
        workOrderPriority.value.length > 0 &&
        !allTasksAndInspectionsForPipeline.some(
          (x) => x.type === 'Work Order' && selectedWorkOrderPriority[x.priority]
        )
      )
        return false;

      if (
        checkRecurrancePriority?.value?.length > 0 &&
        !allTasksAndInspectionsForPipeline.some(
          (x) => x.type === 'Check' && selectedRecurrancePriority[x.recurrencePriorityId]
        )
      )
        return false;

      if (
        inspectionType?.value?.length > 0 &&
        !allTasksAndInspectionsForPipeline.some((x) => inspectionTypeMap[x.inspectionTypeId])
      )
        return false;

      if (
        !_.isEmpty(filterDueDate.value) &&
        dueCon &&
        !allTasksAndInspectionsForPipeline.some((x) =>
          dueDateFilter(x.dueDate, filterDueDate, x.id)
        )
      )
        return false;

      if (
        !_.isEmpty(scheduledDate.value) &&
        scheduleCon &&
        !allTasksAndInspectionsForPipeline.some((x) =>
          scheduledDateFilter(x.startDate, x.syncedDate, scheduledDate)
        )
      )
        return false;

      if (
        !_.isEmpty(createdAt?.value) &&
        createAtCon &&
        !allTasksAndInspectionsForPipeline.some((x) =>
          createAtOrSyncedDateFilter({
            dateForFilter: x?.createdDate,
            filterSelectedDate: createdAt
          })
        )
      )
        return false;

      if (
        !_.isEmpty(syncedDateFilter?.value) &&
        syncedDateCon &&
        !allTasksAndInspectionsForPipeline.some((x) =>
          createAtOrSyncedDateFilter({
            dateForFilter: x.syncedDate,
            filterSelectedDate: syncedDateFilter
          })
        )
      )
        return false;

      return true;
    })
    .sort((a, b) => {
      switch (SelectedFilter[0]) {
        case 'DUE':
          return dueDateSort(a, b);
        case 'DISTANCE':
          return distanceFilter(a, b, sitesMap, cacheSites);
        case 'A-Z':
          return a.title.localeCompare(b.title);
        case '# TASKS':
          return b?.allotDoneTasks?.length - a?.allotDoneTasks?.length;
        default:
          if (a?.licenseNumber === b?.licenseNumber) return a?.lineNumber - b?.lineNumber;
          else return a?.licenseNumber - b?.licenseNumber;
      }
    });

  return {
    filteredPipeline,
    pipelinesPipelineStatusData,
    pipelinesAreaHierarchyTreeData,
    pipelinesWorkOrderPriorityDataMap
  };
};

export const getFilterPipelineWithPromise = ({ args }) => {
  return new Promise((resolve) => {
    const response = getFilterPipeline({ ...args });
    resolve(response);
  });
};

const dueDateSort = (a, b) => {
  if (!a.soonestDueDate || !b.soonestDueDate) {
    return a.soonestDueDate ? -1 : 1;
  }

  const dateA = new Date(a.soonestDueDate);
  const dateB = new Date(b.soonestDueDate);

  if (dateA < dateB) {
    return -1;
  } else if (dateA > dateB) {
    return 1;
  } else {
    return 0;
  }
};

const distanceFilter = (a, b, sitesMap, cacheSites) => {
  return (
    (cacheSites[sitesMap[a?.siteIds[0]]]?.distance != null
      ? cacheSites[sitesMap[a?.siteIds[0]]]?.distance
      : Infinity) -
      (cacheSites[sitesMap[b?.siteIds[0]]]?.distance != null
        ? cacheSites[sitesMap[b?.siteIds[0]]]?.distance
        : Infinity) || a?.name?.localeCompare(b.name)
  );
};

const dueDateFilter = (taskDueDate, dueDateFilter) => {
  const currentDate = moment().format('YYYY-MM-DD');

  const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');
  if (dueDateFilter?.selected === 'next') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateIncreaseByOneDay = moment().add('1', 'd').format('YYYY-MM-DD');
      const currentDate = moment().format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDate))
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateIncreaseBySevenDay = moment().add('1', 'w').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBySevenDay,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateIncreaseBy30Day = moment().add('1', 'M').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy30Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateIncreaseBy90Day = moment().add('90', 'd').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy90Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateIncreaseBy365Day = moment().add('1', 'y').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateIncreaseBy365Day = moment().add('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
  }
  if (dueDateFilter?.selected === 'on') {
    const selectedDate = moment(dueDateFilter.value).format('YYYY-MM-DD');
    if (moment(taskDueDateWithoutTimeZone).isSame(moment(selectedDate))) return true;
  }
  if (dueDateFilter?.selected === 'range') {
    const selectedDateStart = moment(dueDateFilter.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(dueDateFilter.value[1]).format('YYYY-MM-DD');
    if (
      moment(taskDueDateWithoutTimeZone).isBetween(
        selectedDateStart,
        selectedDateEnd,
        undefined,
        '[]'
      )
    )
      return true;
  }
  if (dueDateFilter?.selected === 'past') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');
      const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');
      if (moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)))
        return true;
    }
    if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};

const scheduledDateFilter = (startDate, syncedDate, dueDateFilter) => {
  if (_.isEmpty(startDate) && _.isEmpty(syncedDate)) return false;

  const currentDate = moment().format('YYYY-MM-DD');
  const taskDueDateWithoutTimeZone =
    startDate && moment(startDate).isValid() ? moment(startDate).format('YYYY-MM-DD') : false;
  const syncedDateDateWithoutTimeZone =
    syncedDate && moment(syncedDate).isValid() ? moment(syncedDate).format('YYYY-MM-DD') : false;

  if (dueDateFilter?.selected === 'next') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateIncreaseByOneDay = moment().add('1', 'd').format('YYYY-MM-DD');
      const currentDate = moment().format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
        moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDate)) ||
        moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDate))
      ) {
        return true;
      }
    } else if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateIncreaseBySevenDay = moment().add('1', 'w').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBySevenDay,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBySevenDay,
          undefined,
          '[)'
        )
      )
        return true;
    } else if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateIncreaseBy30Day = moment().add('1', 'M').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy30Day,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy30Day,
          undefined,
          '[)'
        )
      )
        return true;
    } else if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateIncreaseBy90Day = moment().add('90', 'd').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy90Day,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy90Day,
          undefined,
          '[)'
        )
      )
        return true;
    } else if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateIncreaseBy365Day = moment().add('1', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    } else if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateIncreaseBy365Day = moment().add('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else if (dueDateFilter?.selected === 'on') {
    const selectedDate = moment(dueDateFilter.value).format('YYYY-MM-DD');

    if (moment(taskDueDateWithoutTimeZone).isSame(moment(selectedDate))) return true;
  } else if (dueDateFilter?.selected === 'range') {
    const selectedDateStart = moment(dueDateFilter.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(dueDateFilter.value[1]).format('YYYY-MM-DD');
    if (
      moment(taskDueDateWithoutTimeZone).isBetween(
        selectedDateStart,
        selectedDateEnd,
        undefined,
        '[]'
      )
    )
      return true;
  } else if (dueDateFilter?.selected === 'past') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)) ||
        moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay))
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)' ||
            moment(syncedDateDateWithoutTimeZone).isBetween(
              currentDateDecreaseBy30Day,
              currentDate,
              undefined,
              '[)'
            )
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }

    if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        ) ||
        moment(syncedDateDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};

const createAtOrSyncedDateFilter = ({ dateForFilter, filterSelectedDate }) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const syncDateCustomWithoutTimeZone = moment(dateForFilter).format('YYYY-MM-DD');
  if (filterSelectedDate?.selected === 'last' && !_.isEmpty(dateForFilter)) return true;

  if (filterSelectedDate?.selected === 'on') {
    const selectedDate = moment(filterSelectedDate?.value).format('YYYY-MM-DD');
    if (moment(syncDateCustomWithoutTimeZone).isSame(moment(selectedDate))) return true;
  }

  if (filterSelectedDate?.selected === 'range') {
    const selectedDateStart = moment(filterSelectedDate?.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(filterSelectedDate?.value[1]).format('YYYY-MM-DD');
    if (
      moment(selectedDateStart).isSame(moment(selectedDateEnd)) &&
      moment(syncDateCustomWithoutTimeZone).isSame(moment(selectedDateEnd))
    )
      return true;
    else if (
      moment(syncDateCustomWithoutTimeZone).isBetween(
        selectedDateStart,
        selectedDateEnd,
        undefined,
        '[]'
      )
    )
      return true;
  }

  if (filterSelectedDate?.selected === 'past') {
    if (filterSelectedDate?.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');
      if (moment(syncDateCustomWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)))
        return true;
    }
    if (filterSelectedDate?.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }

    if (filterSelectedDate?.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};

export const tagsDataList = ['DISTANCE', '# TASKS', 'DUE'];

export const mapValues = ({
  pipelinesAreaHierarchyTreeDataMap,
  pipelinesPipelinesStatusDataMap,
  pipelinesWorkOrderPriorityDataMap,
  pipelines = [],
  store
}) => {
  const { picklists, tasks: reduxTasks, checks } = store;
  const { workOrderUsersGroupsMap = {}, tasksList = [] } = reduxTasks;
  const { checkTypesMap } = checks;

  const {
    pipelineStatusData = [],

    workOrderPriorityData = [],
    areaHierarchyTreeData = [],
    picklistsMap = {}
  } = picklists;

  const pipelineStatusTags = pipelinesPipelinesStatusDataMap
    ? pipelineStatusData?.filter(({ key }) => pipelinesPipelinesStatusDataMap[key])
    : pipelineStatusData;

  const workOrderPriorityTags = pipelinesWorkOrderPriorityDataMap
    ? workOrderPriorityData.filter(({ key }) => pipelinesWorkOrderPriorityDataMap[key])
    : workOrderPriorityData;

  const assignedUserGroups = getPipelineTaskAssignedUserGroups(pipelines);
  const assignableUsersTags = getTaskAssignedToTags({
    assignedUserGroups,
    workOrderUsersGroupsMap
  });

  const treeData = pipelinesAreaHierarchyTreeDataMap
    ? removeUnavailableTreeData(
        JSON.parse(JSON.stringify(areaHierarchyTreeData)),
        pipelinesAreaHierarchyTreeDataMap
      )
    : areaHierarchyTreeData;

  const taskTypeTag = getPipelinesTaskTypeTags(pipelines);
  const taskStatusTags = getPipelineTaskStatusTags(pipelines);

  const checkRecurrancePriorityTags = getCheckRecurrancePriorityTags(pipelines, picklistsMap);
  const { checkTypeTags, checkTypeTagsMap } = getCheckTypeTags(pipelines, checkTypesMap);

  const inspectionRunTags = getInspectionRunTags(pipelines);
  const taskRecurringTemplateTags = getTaskRecurringTemplateTags(pipelines) || [];

  return {
    assignableUsersTags,
    treeData,
    taskTypeTag,
    taskStatusTags,
    pipelineStatusTags,
    workOrderPriorityTags,
    checkRecurrancePriorityTags,
    checkTypeTags,
    inspectionRunTags,
    taskRecurringTemplateTags,
    checkTypeTagsMap
  };
};

const getInspectionRunTags = (pipelines = []) => {
  const tasks = pipelines.flatMap((x) => x.allTasksAndInspectionsForPipeline);

  const tags = Array.from(
    new Set(
      tasks.map((task) => {
        if (task.runNumber) return `${task.runNumber} - ${task.RunName?.trim()}`;
      })
    )
  ).filter((y) => y);
  return tags;
};

const getTaskRecurringTemplateTags = (pipelines = []) => {
  const tags = Array.from(
    new Set(
      pipelines
        .map(
          (item) =>
            item.allTasksAndInspectionsForPipeline &&
            item.allTasksAndInspectionsForPipeline.map((task) => {
              if (task.rtNumber) return `${task.rtNumber} - ${task.rtName}`;
            })
        )
        .flat()
        .filter((name) => name)
    )
  );
  return tags;
};

const getCheckTypeTags = (equipments = [], checkTypesMap = {}) => {
  const checkTypeTags = [];

  for (let equipment of equipments) {
    const equipmentTask = equipment?.allTasksAndInspectionsForPipeline || [];
    for (let task of equipmentTask) {
      if (task?.type === 'Check' && checkTypesMap[task?.inspectionTypeId]) {
        if (
          checkTypesMap[task?.inspectionTypeId] &&
          !checkTypeTags.includes(checkTypesMap[task?.inspectionTypeId])
        )
          checkTypeTags.push(checkTypesMap[task?.inspectionTypeId]);
      }
    }
  }

  const checkTypeTagsMap = checkTypeTags?.reduce((acc, curr) => {
    acc[curr.key] = { value: curr.value };
    return acc;
  }, {});

  return { checkTypeTags, checkTypeTagsMap };
};

const getCheckRecurrancePriorityTags = (equipments = [], picklistsMap) => {
  const checkRecurrenceTags = [];

  for (let equipment of equipments) {
    const equipmentTask = equipment?.allTasksAndInspectionsForPipeline || [];
    for (let task of equipmentTask) {
      if (
        task?.type === 'Check' &&
        task?.recurrencePriorityId &&
        picklistsMap[task?.recurrencePriorityId]
      ) {
        let recurreanceValue = picklistsMap[task?.recurrencePriorityId] || {};
        let recurreanceValueCopy = { ...recurreanceValue };
        recurreanceValueCopy.value = recurreanceValue?.value?.split(' ')[0] || '';
        if (!checkRecurrenceTags.some((tag) => tag.value === recurreanceValueCopy.value)) {
          checkRecurrenceTags.push(recurreanceValueCopy);
        }
      }
    }
  }

  return checkRecurrenceTags;
};

const getTaskAssignedToTags = ({ assignedUserGroups, workOrderUsersGroupsMap }) => {
  let data =
    (assignedUserGroups && // responsible users groups
      _.size(assignedUserGroups) > 0 &&
      assignedUserGroups
        .map((key) => workOrderUsersGroupsMap[key] && workOrderUsersGroupsMap[key]?.name)
        .filter((y) => y)) ||
    [];

  const assignableUsersTags = ['Me, directly', 'A group I’m in', ...data];
  return assignableUsersTags;
};
const getPipelineTaskStatusTags = (pipelines) => {
  let taskStatusTags = [];
  for (let pipeline of pipelines) {
    if (
      _.size(pipeline.allTasksAndInspectionsForPipeline) > 0 &&
      pipeline.allTasksAndInspectionsForPipeline.some((x) => x.status === 'To do')
    ) {
      taskStatusTags.push('To do');
      break;
    }
  }
  for (let pipeline of pipelines) {
    if (
      _.size(pipeline.allTasksAndInspectionsForPipeline) > 0 &&
      pipeline.allTasksAndInspectionsForPipeline.some((x) => x.status === 'In progress')
    ) {
      taskStatusTags.push('In progress');
      break;
    }
  }
  for (let pipeline of pipelines) {
    if (
      _.size(pipeline.allTasksAndInspectionsForPipeline) > 0 &&
      pipeline.allTasksAndInspectionsForPipeline.some((x) => x.status === 'Pending sync')
    ) {
      taskStatusTags.push('Pending sync');
      break;
    }
  }
  for (let pipeline of pipelines) {
    if (
      _.size(pipeline.allTasksAndInspectionsForPipeline) > 0 &&
      pipeline.allTasksAndInspectionsForPipeline.some((x) => x.status === 'Synced')
    ) {
      taskStatusTags.push('Synced');
      break;
    }
  }
  return taskStatusTags;
};

const getPipelineTaskAssignedUserGroups = (pipelines) => {
  let assignedUserGroups = [];
  for (let pipeline of pipelines) {
    if (_.size(pipeline.allTasksAndInspectionsForPipeline) > 0) {
      assignedUserGroups.push(
        Array.from(
          new Set(
            pipeline.allTasksAndInspectionsForPipeline.map((t) => t?.responsibleUserGroups).flat(2)
          )
        ) || []
      );
    }
  }
  return Array.from(new Set(_.flattenDeep(assignedUserGroups))) || [];
};

const getPipelinesTaskTypeTags = (pipelines) => {
  let taskTypeTag = [];
  for (let pipeline of pipelines) {
    if (
      _.size(pipeline.allTasksAndInspectionsForPipeline) > 0 &&
      pipeline.allTasksAndInspectionsForPipeline.some((x) => x.type === 'Work Order')
    ) {
      taskTypeTag.push('Work orders');
      break;
    }
  }
  for (let pipeline of pipelines) {
    if (
      _.size(pipeline.allTasksAndInspectionsForPipeline) > 0 &&
      pipeline.allTasksAndInspectionsForPipeline.some(
        (x) => x.type === 'Work Request' || x.newWorkRequest
      )
    ) {
      taskTypeTag.push('Work requests');
      break;
    }
  }
  for (let pipeline of pipelines) {
    if (
      _.size(pipeline.allTasksAndInspectionsForPipeline) > 0 &&
      pipeline.allTasksAndInspectionsForPipeline.some((x) => x.type === 'Inspection')
    ) {
      taskTypeTag.push('Inspections');
      break;
    }
  }
  for (let pipeline of pipelines) {
    if (
      _.size(pipeline.allTasksAndInspectionsForPipeline) > 0 &&
      pipeline.allTasksAndInspectionsForPipeline.some((x) => x.type === 'Check')
    ) {
      taskTypeTag.push('Check');
      break;
    }
  }
  return taskTypeTag;
};

export const countFilters = (filters, inputValue, store) => {
  let count = 0;
  const { picklists } = store;

  const {
    taskType,
    taskStatus,
    assign,
    priority,
    pipelineArea,
    pipelineStatus,
    workOrderPriority = {},
    checkRecurrancePriority = { value: [] },
    inspectionType = { value: [] },
    checkType = { value: [] }
  } = filters;

  if (inputValue?.trim()) ++count;

  if (assign?.value?.length > 0) ++count;
  if (taskType?.value?.length > 0) ++count;
  if (checkType?.value?.length > 0) ++count;
  if (taskStatus?.value?.length > 0) ++count;
  if (priority?.value?.length > 0 || workOrderPriority?.value?.length > 0) ++count;
  if (pipelineStatus?.value?.length > 0) ++count;
  if (
    pipelineArea?.value &&
    pipelineArea?.value.filter((el) => picklists.areaHierarchyTreeDataMap?.[el])?.length > 0
  )
    ++count;

  ['dueDate', 'scheduledDate', 'createdAt', 'syncedDate'].forEach((key) => {
    const { value, selected } = filters[key] || { value: '', selected: '' };
    if (
      selected &&
      !_.isEmpty(value) &&
      (selected === 'on'
        ? value !== ''
        : selected === 'range'
        ? value?.[0] !== '' && value?.[0] !== ''
        : value?.length > 0)
    )
      ++count;
  });
  return count;
};

export const getPipelinesDefaultFilters = {
  searched: '',
  pipelineArea: { value: [] },
  pipelineStatus: { value: [] },
  taskType: { value: [] },
  taskStatus: { value: [] },
  assign: { value: [] },
  priority: { value: [] },
  workOrderPriority: { value: [] },

  download: { value: '', selected: 'past' },
  createdAt: { value: '', selected: 'past' },
  syncedDate: { value: '', selected: 'past' },
  dueDate: { value: [], selected: 'next' },
  scheduledDate: { value: [], selected: 'next' },
  checkRecurrancePriority: { value: [] },
  inspectionType: { value: [] },
  checkType: { value: [] }
};
