import moment from 'moment';

const pipelineAutoPopulate = ({
  firstName,
  lastName,
  pipeline,
  inspectionLastDate,
  intervalId
}) => {
  return {
    User: {
      //SourceProperty,
      InspectorName: `${firstName} ${lastName}`
    },
    Incident: {
      InitiatorName: `${firstName} ${lastName}`
    },
    InspectionSchedules: {
      IntervalId: intervalId
    },
    Pipeline: {
      FromLocation: pipeline?.fromLocation || null,
      LicenseNumber: pipeline?.licenseNumber || null,
      PipelineNumber: pipeline?.pipelineNumber || null,
      Status: pipeline?.status || null,
      ToLocation: pipeline?.toLocation || null,
      Area: pipeline?.area || null,
      AssignedBatchingIntervalId: pipeline?.assignedBatchingIntervalId || null,
      AssignedPiggingIntervalId: pipeline?.assignedPiggingIntervalId || null,
      CathodicProtectionId: pipeline?.cathodicProtectionId || null,
      ExternalProtection: pipeline?.externalProtection || null,
      InternalProtection: pipeline?.internalProtection || null,
      Material: pipeline?.material || null,
      OperationsAreaId: pipeline?.operationsAreaId || null,
      ReceiverTypeId: pipeline?.receiverTypeId || null,
      SenderTypeId: pipeline?.senderTypeId || null,
      Substance: pipeline?.substance || null,
      PipelineTypeId: pipeline?.pipelineTypeId || null,
      MaintenanceComments: pipeline?.maintenanceComments || null,
      GDM_ID: pipeline?.gdmId || null,
      Insulated: pipeline?.insulated || null,
      LineNumber: pipeline?.lineNumber || null,
      WaterCrossing: pipeline?.waterCrossing || null,
      PipelineKey: pipeline?.pipelineKey || null,
      PastLicenseLine: null,
      LastInspectionDate: inspectionLastDate[pipeline?.pipelineId]?.inspectionDate
        ? moment(inspectionLastDate[pipeline?.pipelineId]?.inspectionDate)
            .format('YYYY-MM-DD')
            ?.toString()
        : null,

      H2SConcentration: pipeline?.h2SConcentrationMeasurement || null, // h2SConcentration
      // Length: pipeline?.lengthMeasurement || null, // length,
      OutsideDiameter: pipeline?.outsideDiameterMeasurement || null, //outsideDiameter
      RiserDiameter: pipeline?.riserDiameterMeasurement || null,
      RiserWallThickness: pipeline?.riserWallThicknessMeasurement || null, // wallThickness
      WallThickness: pipeline?.wallThicknessMeasurement || null,
      MaxOperatingPressure: pipeline?.maxOperatingPressureMeasurement || null, // maxOperatingPressure,
      Length: {
        textBoxValue: pipeline?.length || null,
        textBoxUnitValue: pipeline?.lengthMeasurement || null
      }
    }
  };
};

export default pipelineAutoPopulate;
