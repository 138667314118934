/* eslint-disable no-unused-vars */
import { store } from 'redux/store';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { WorkTypes, getSelectedTaskPhotos, getSelectedTaskDocuments } from '../helper';
import {
  getWorkOrderFormData,
  getNewWorkRequestFormData,
  getNewWorkOrderTodoFormData
} from './work-order-form';
import {
  TasksSelector,
  SitesSelector,
  EquipmentSelector,
  StructureSelector,
  PipelineSelector,
  HSEEventSelector
} from 'selectors';
import CheckSelector from 'selectors/checks/check.selector';

import InspectionSelector from 'selectors/inspection/inspection.selector';

import { formWrapperSchema } from './form-wrapper-schema';
import { convertSerializableToNonSerialData } from './serializable-non-serializable';

export const getCurrentTaskOrInspectionInfo = async ({
  workType,
  currentPageOfSelectedTasks,
  selectedTasksList = [],
  siteIds,
  equipmentIds,
  structureIds,
  pipelineIds,
  inspectionType,
  hseOffSite,
  activeBtn
}) => {
  const { picklists, inspection, checks } = store.getState();
  const {
    picklistsMap = {},
    tradeData,
    workOrderTypeData,
    workOrderSkillsData,
    areaHierarchyTreeDataMap
  } = picklists;
  const { inspectionFormsUserCache = {}, inspectionTypesMap = {} } = inspection;
  const { checkTypesMap = {} } = checks;

  const currData = selectedTasksList[currentPageOfSelectedTasks - 1] || {};

  const pageHeaderText = getPageHeaderText({
    workType,
    currentPageOfSelectedTasks,
    selectedTasksList,
    currData
  });

  const pageHeaderTitle = getPageHeaderTitle({
    workType,
    currData,
    inspectionType,
    inspectionTypesMap,
    checkTypesMap
  });
  const selectorData = await getWorkOrderOrInspectionDataFromSelector({
    currData,
    picklistsMap,
    workType
  });

  const appliesToDataResponse = await getAppliesToDataOfSiteOrEquipments({
    siteIds,
    equipmentIds,
    structureIds,
    pipelineIds,
    hseOffSite,
    areaHierarchyTreeDataMap,
    store: store.getState()
  });

  const inspectionOrWorkOrderFormsUserCacheMap = getCurrentForm({
    workType,
    currData,
    inspectionFormsUserCache,
    inspectionType,
    workOrderTypeData,
    tradeData,
    workOrderSkillsData,
    picklistsMap
  });

  const {
    currentFormSchema,
    componentsNames,
    setDefaultOrAutoPopulateValuesForFields,
    workLogSchemaForReadingSchema,
    newChecksSchema
  } = formWrapperSchema({
    currentForm: inspectionOrWorkOrderFormsUserCacheMap,
    currData
  });

  const menuNames = getMenuName({
    currentForm: inspectionOrWorkOrderFormsUserCacheMap,
    checkSheets: currData?.workOrderNumber,
    workType
  });

  const formSectionsWithSubSections = getFormSectionWIthSubSections({
    currentForm: inspectionOrWorkOrderFormsUserCacheMap
  });
  const equipmentCategoryAndType =
    equipmentIds?.length && appliesToDataResponse?.length
      ? {
          category: appliesToDataResponse[0]?.equipmentCategory,
          type: appliesToDataResponse[0]?.equipmentType
        }
      : selectorData?.equipmentId && selectorData?.equipmentAsset?.prepareTasksEquipments
      ? {
          category:
            selectorData?.equipmentAsset?.prepareTasksEquipments?.at(0)?.equipmentCategory || null,
          type: selectorData?.equipmentAsset?.prepareTasksEquipments?.at(0)?.equipmentType || null
        }
      : { category: null, type: null };
  const dropdownMenuMapList = dropdownMenuMap({
    inspection,
    ...equipmentCategoryAndType,
    picklistsMap
  });
  return {
    currentFormSchema,
    newChecksSchema,
    workLogSchemaForReadingSchema,
    pageHeaderText,
    pageHeaderTitle,
    selectorData,
    menuNames,
    formSectionsWithSubSections,
    appliesToDataResponse,
    dropdownMenuMapList,
    currentFormId: inspectionOrWorkOrderFormsUserCacheMap?.inspectionFormId || null,
    formAppliesTo:
      inspectionOrWorkOrderFormsUserCacheMap?.appliesTo === 'HSE'
        ? 'Site'
        : inspectionOrWorkOrderFormsUserCacheMap?.appliesTo || 'Site',
    componentsNames,
    setDefaultOrAutoPopulateValuesForFields
  };
};

const dropdownMenuMap = ({ inspection, category = '', type = '', picklistsMap }) => {
  const {
    pipelineCathodicProtection = [],
    pipelineAssignedBatchingInterval = [],
    pipelineAssignedPiggingInterval = [],
    pipelineStatus = [],
    externalProtection = [],
    internalProtection = [],
    material = [],
    operationsArea = [],
    pipelineReceiverType = [],
    pipelineSenderType = [],
    substance = [],
    pipelineType = [],
    province = [],
    siteStatus = [],
    codeOfConstruction = [],
    areaHierarchy = [],
    equipmentCategoryTypeSubtype = [],
    service = [],
    equipmentStatus = [],
    pSVType = [],
    equipmentValveLocation = [],
    capacity = [],
    concentrationUnits = [],
    largeMeasurement = [],
    smallMeasurement = [],
    pressure = [],
    temperature = [],
    timeUnit = [],
    volume = [],
    pipelineArea = [],
    passFailList = [],
    passDeficiencyFail = [],
    equipmentCategory = [],
    flowRate = [],
    condition = [],
    siteType = [],
    cylinderType = [],
    structureType = [],
    gaugeBoardUOM = [],
    equipmentInsulationMaterial = [],
    constructionMaterial = [],
    psvCategoryTypeAndSubType = [],
    equipmentConnectionType,
    HSEProactiveEffortType = [],
    HSEFocusAreas = [],
    HSEVendors = [],
    functionalGroup = [],
    species = [],
    wildlifeIncidentType = [],
    incidentStatus = [],
    inspectionFrequency = []
  } = inspection;
  let list = [];
  list = equipmentCategoryTypeSubtype;

  let dropdownMenuMap = {
    InspFrequencyList: inspectionFrequency,
    PipelineCathodicProtectionPickList: pipelineCathodicProtection,
    ExternalProtectionPickList: externalProtection,
    PipelineAssignedBatchingIntervalPickList: pipelineAssignedBatchingInterval,
    PipelineAssignedPiggingIntervalPickList: pipelineAssignedPiggingInterval,
    PipelineAreaList: areaHierarchy,
    InternalProtectionPickList: internalProtection,
    OperationsAreaPickList: operationsArea,
    MaterialsPickList: material,
    PipelineReceiverTypePickList: pipelineReceiverType,
    PipelineSenderTypePickList: pipelineSenderType,
    PipelineTypePickList: pipelineType,
    PipelineStatusPickList: pipelineStatus,
    SubstancesPickList: substance,
    ProvincesPickList: province,
    SiteTypePickList: siteType,
    AreaList: areaHierarchy,
    SiteStatusPickList: siteStatus,
    EquipmentValveLocationPicklist: equipmentValveLocation,
    ServicesPickList: service,
    EquipmentStatusPickList: equipmentStatus,
    PSVTypePickList: pSVType,
    CodeOfConstructionPickList: codeOfConstruction || [],
    // EquipmentCategoryTypeSubtypePickList: equipmentCategoryTypeSubtype,
    CapacityPickList: capacity,
    ConcentrationUnitsPickList: concentrationUnits,
    LargeMeasurementPickList: largeMeasurement,
    SmallMeasurementPickList: smallMeasurement,
    PressurePickList: pressure,
    TemperaturePickList: temperature,
    TemperaturePicklist: temperature,
    TimeUnitPickList: timeUnit,
    VolumePickList: volume,
    VolumePicklist: volume,
    InspResult_PassFailList: passFailList,
    InspResult_PassDeficiencyFailList: passDeficiencyFail,
    EquipmentCategoryTypeSubtypePickList: list || [],
    FlowRate: flowRate,
    ConditionPickList: condition,
    FlowRatePickList: flowRate,
    CylinderTypePickList: cylinderType,
    ConstructionMaterialPickList: constructionMaterial,
    StructureTypePickList: structureType,
    GaugeBoardUOM: gaugeBoardUOM,
    EquipmentInsulationMaterialPickList: equipmentInsulationMaterial,
    PsvCategoryTypeAndSubTypePickList: psvCategoryTypeAndSubType,
    EquipmentConnectionTypePickList: equipmentConnectionType,
    VendorList: HSEVendors,
    FocusAreasList: HSEFocusAreas,
    FuncGroupList: functionalGroup,
    SpeciesList: species,
    ProactiveEffortTypesList: HSEProactiveEffortType,
    Sighting: wildlifeIncidentType,
    Encounter: wildlifeIncidentType
  };
  return dropdownMenuMap;
};

const getCurrentForm = ({
  workType,
  currData,
  inspectionFormsUserCache,
  inspectionType,
  workOrderTypeData,
  tradeData,
  workOrderSkillsData,
  picklistsMap
}) => {
  if (workType === WorkTypes?.NewInspection) {
    return inspectionFormsUserCache?.find(
      (x) => x?.inspectionType?.toString() === (inspectionType || currData?.inspectionTypeId)
    );
  } else if (workType === WorkTypes?.NewCheck || workType === WorkTypes?.Check) {
    return inspectionFormsUserCache?.find(
      (x) =>
        x?.inspectionType?.toString() === (inspectionType || currData?.inspectionTypeId?.toString())
    );
  } else if (workType === WorkTypes?.NewHSE) {
    return inspectionFormsUserCache?.find(
      (x) => x?.inspectionType?.toString() === (inspectionType || currData?.HSETypeId)
    );
  } else if (workType === WorkTypes?.NewWorkOrder) {
    return getNewWorkOrderTodoFormData();
  } else if (workType === WorkTypes?.NewWorkRequest) {
    return getNewWorkRequestFormData();
  } else if (currData?.type === 'Inspection') {
    return inspectionFormsUserCache?.find(
      (x) => x?.inspectionFormId === currData?.inspectionFormId
    );
  } else if (currData?.workOrderNumber) {
    return getWorkOrderFormData({
      currData,
      workOrderTypeData,
      tradeData,
      workOrderSkillsData,
      picklistsMap,
      inspectionFormsUserCache
    });
  }
};

const getPageHeaderText = ({
  workType,
  currentPageOfSelectedTasks,
  selectedTasksList,
  currData = {}
}) => {
  if (workType === WorkTypes.NewWorkRequest) return <>Work request</>;
  else if (workType === WorkTypes.NewHSE) return <>HSE event</>;
  else if (workType === WorkTypes.NewWorkOrder) return <>Work order</>;
  else if (workType === WorkTypes.NewInspection) return <>Inspection</>;
  else if (workType === WorkTypes.NewCheck || workType === WorkTypes.Check) return <>Check</>;
  else if (workType === WorkTypes.Inspection)
    return (
      <>
        Inspection{' '}
        {selectedTasksList?.length > 1 && (
          <span>({`${currentPageOfSelectedTasks}/${selectedTasksList?.length}`})</span>
        )}
      </>
    );
  else if (workType === WorkTypes.WorkOrders)
    return (
      <>
        Work order{' '}
        {selectedTasksList?.length > 1 && (
          <span>({`${currentPageOfSelectedTasks}/${selectedTasksList?.length}`})</span>
        )}
      </>
    );
  else if (
    workType === WorkTypes.InspectionAndWorkOrder ||
    workType === WorkTypes.WorkOrderAndInspection
  )
    return (
      <>
        {currData?.type === 'Inspection' ? 'Inspection' : 'Work Order'}{' '}
        {selectedTasksList?.length > 1 && (
          <span>({`${currentPageOfSelectedTasks}/${selectedTasksList?.length}`})</span>
        )}
      </>
    );
};

const getPageHeaderTitle = ({
  workType,
  currData,
  inspectionType,
  inspectionTypesMap,
  checkTypesMap
}) => {
  if (workType === WorkTypes.NewWorkRequest) return 'add New work request';
  if (workType === WorkTypes.NewWorkOrder) return 'add New work order';
  if (workType === WorkTypes.NewInspection)
    return (
      inspectionTypesMap[inspectionType || parseInt(currData?.inspectionTypeId)]?.value ||
      'add New Inspection'
    );
  if (workType === WorkTypes.NewCheck || workType === WorkTypes.Check)
    return (
      checkTypesMap[inspectionType || parseInt(currData?.inspectionTypeId)]?.value ||
      'add New Check'
    );
  if (workType === WorkTypes.NewHSE)
    return (
      inspectionTypesMap[inspectionType || parseInt(currData?.HSETypeId)]?.value ||
      'add New Inspection'
    );
  if (workType === WorkTypes.Inspection) return getInspectionTitle({ currData });
  if (
    workType === WorkTypes.InspectionAndWorkOrder ||
    workType === WorkTypes.WorkOrderAndInspection
  )
    return getWorkOrderAndInspectionTitle({ currData });
  if (workType === WorkTypes.WorkOrders) return getWorkOrderTitle({ currData });
  else return <></>;
};

const getInspectionTitle = ({ currData }) => {
  const { inspection = {} } = store.getState();
  const { inspectionTypesMap = {} } = inspection;
  return inspectionTypesMap[currData?.inspectionTypeId]?.value;
};

const getWorkOrderTitle = ({ currData }) => {
  const workOrderNumber = currData?.workOrderNumber || '';
  const workOrderTitle = currData?.title || '';
  return `${workOrderNumber} - ${workOrderTitle}`;
};

const getWorkOrderAndInspectionTitle = ({ currData }) => {
  const { inspection = {} } = store.getState();
  const { inspectionTypesMap = {} } = inspection;
  const selectedTaskType = currData?.type || '';
  if (selectedTaskType === 'Work Order') {
    const workOrderNumber = currData?.workOrderNumber || '';
    const workOrderTitle = currData?.title || '';
    return `${workOrderNumber} - ${workOrderTitle}`;
  }
  if (selectedTaskType === 'Inspection') {
    const inspectionTypeMap = inspectionTypesMap[currData?.inspectionTypeId]?.value;
    return inspectionTypeMap;
  }
};

const getWorkOrderOrInspectionDataFromSelector = async ({ currData, picklistsMap, workType }) => {
  if (currData?.type === 'Inspection') {
    if (workType == WorkTypes.NewInspection) {
      const res = await InspectionSelector.getInspections({
        id: currData?.id,
        appliesTo: true,
        newInspection: true,
        formWrapper: true,
        store: store.getState()
      });
      return res[0] || {};
    } else {
      const res = await InspectionSelector.getInspections({
        id: currData?.inspectableItemId,
        appliesTo: true,
        showAttachedPSVs: true,
        formWrapper: true,
        store: store.getState()
      });

      return res[0] || {};
    }
  } else if (
    currData?.type === 'Work Order' ||
    currData.type === 'New Work Order' ||
    currData.type === 'New Work Request'
  ) {
    const newWorkOrderOrRequest =
      currData?.id === 'New Work order' || currData?.id === 'New Work request' ? true : false;
    const res = await TasksSelector.getTasks({
      id:
        currData?.id === 'New Work order' || currData?.id === 'New Work request'
          ? currData?.woId
          : currData?.id,
      newWorkOrderOrRequest,
      appliesTo: true,
      showAttachedPSVs: !newWorkOrderOrRequest,
      formWrapper: true,
      store: store.getState()
    });
    const selectedTask = res[0] || {};
    return {
      ...selectedTask,
      priority: picklistsMap[selectedTask?.priority]?.value
    };
  } else if (currData?.type === 'HSE') {
    const res = await HSEEventSelector.getHSEEvents({
      id: currData?.id,
      appliesTo: true,
      formWrapper: true,
      store: store.getState()
    });
    return res[0] || {};
  } else if (currData?.type === 'Check') {
    const res = await CheckSelector.getAllChecks({
      id: currData?.id,
      appliesTo: true,
      formWrapper: true,
      store: store.getState(),
      dataPointsCompleted: true
    });
    return res[0] || {};
  }
};

const getMenuName = ({ currentForm, checkSheets = false, workType }) => {
  let name =
    workType === WorkTypes.NewCheck || workType === WorkTypes.Check
      ? []
      : [{ id: uuidv4(), name: 'Applies to' }];
  const formSections = currentForm?.formSections || [];
  for (let formSection of formSections) {
    if (_.isEmpty(formSection?.parentId)) {
      name.push({
        id: formSection?.formSectionId,
        name: formSection?.name,
        order: formSection?.order
      });
    }
  }
  return checkSheets ? name : name?.sort((a, b) => a?.order - b?.order);
};

const getFormSectionWIthSubSections = ({ currentForm }) => {
  const form = [];
  let componentsCount = 0;
  const currentFormSections = currentForm?.formSections || [];
  for (let formSection of currentFormSections) {
    if (_.isEmpty(formSection?.parentId)) {
      const filterItsSubSections = currentForm?.formSections?.filter(
        (x) => x?.parentId === formSection?.formSectionId
      );

      const filterItsSubSectionsWithRow = [];
      for (const formSubsection of filterItsSubSections) {
        const childSectionFieldRows = parentSectionFormFieldsRowCombine({
          formFields: formSubsection.formFields
        });
        filterItsSubSectionsWithRow.push({ ...formSubsection, formFields: childSectionFieldRows });
      }

      const parentSectionFieldRows = parentSectionFormFieldsRowCombine({
        formFields: formSection.formFields
      });
      form.push({
        ...formSection,
        formFields: parentSectionFieldRows,
        subSections: filterItsSubSectionsWithRow || []
      });
    }
  }
  return form;
};

const parentSectionFormFieldsRowCombine = ({ formFields }) => {
  const groupedData = {};
  let uniqueIdCounter = 0;
  for (const item of formFields) {
    const { row, ...rest } = item;
    if (row === null) {
      const uniqueRow = `unique-${uniqueIdCounter++}`;
      if (!groupedData[uniqueRow]) {
        groupedData[uniqueRow] = [];
      }
      groupedData[uniqueRow].push(rest);
    } else {
      if (!groupedData[row]) {
        groupedData[row] = [];
      }
      groupedData[row].push(rest);
    }
  }
  const result = Object.values(groupedData);
  return result;
};

const getAppliesToDataOfSiteOrEquipments = async ({
  siteIds,
  equipmentIds,
  pipelineIds,
  structureIds,
  areaHierarchyTreeDataMap,
  hseOffSite,
  store
}) => {
  if (!_.isEmpty(siteIds)) {
    const res = await SitesSelector.getSitesWithAllTasks({
      id: siteIds && siteIds[0],
      formWrapper: true,
      store
    });
    return res;
  } else if (!_.isEmpty(equipmentIds)) {
    const res = await EquipmentSelector.getEquipmentsWithAllTasks({
      id: equipmentIds && equipmentIds[0],
      showAttachedPSVs: true,
      formWrapper: true,
      store
    });
    return res;
  } else if (!_.isEmpty(pipelineIds)) {
    const res = await PipelineSelector.getPipelineWithWO({
      id: pipelineIds && pipelineIds[0],
      formWrapper: true,
      store
    });
    return res;
  } else if (!_.isEmpty(structureIds)) {
    const res = await StructureSelector.getStructuresWithWO({
      id: structureIds && structureIds[0],
      formWrapper: true,
      store
    });
    return res;
  } else if (!_.isEmpty(hseOffSite)) {
    return [
      {
        ...hseOffSite,
        hseEvent: true,
        area: areaHierarchyTreeDataMap[hseOffSite.areaId]?.title
      }
    ];
  }
};

export const getDataFromObjects = ({ data, workType, workOrder }) => {
  const convertedObj = {};
  for (const key in data) {
    const innerKey = data[key] && Object.keys(data[key]) && Object.keys(data[key])[0];

    convertedObj[key] = innerKey ? data[key][innerKey] : null;
  }
  const requestDueDate = convertedObj?.['requested due date-b12410e3-3fbe-4c9f-8bf3-b00e018ad3f2']
    ? moment(
        new Date(convertedObj?.['requested due date-b12410e3-3fbe-4c9f-8bf3-b00e018ad3f2'])
      ).format('YYYY-MM-DD')
    : convertedObj?.['due date-1b232148-ef85-4206-bf3b-b00f004e4c67']
    ? moment(new Date(convertedObj?.['due date-1b232148-ef85-4206-bf3b-b00f004e4c67'])).format(
        'YYYY-MM-DD'
      )
    : null;
  const obj = {
    title: convertedObj?.['title-0e286da8-90d0-47ac-bf66-b00f004e4c62'] || '',
    priority: convertedObj?.['priority-d84c1fd5-5d10-4e6f-bdd3-b00f004e4c62'] || '',
    type: convertedObj?.['type-307e5c22-15f1-4bae-95c0-b00f004e4c62'] || '',
    trade: convertedObj?.['trade-9fe9daf9-ccc1-40e8-8581-b00f004e4c67'] || '',
    requestDueDate,
    description: convertedObj?.['description-7ee68589-2e35-433d-867e-b00f004e4c6c'] || '',
    photo: convertedObj?.['your attachments-4630a3cb-17ea-4fd8-8fb9-b00f004e4c6c']?.photos || [],
    document:
      convertedObj?.['your attachments-4630a3cb-17ea-4fd8-8fb9-b00f004e4c6c']?.documents || [],
    administrator: convertedObj?.['administrators-7888f7fb-a3dc-44bf-b10b-b00f004e4c6c'],
    responsibleUsers: convertedObj?.['responsible users-1f769f95-a5a8-4fa6-9a99-b00f004e4c6c']
  };
  if (workType === WorkTypes.NewWorkRequest) return obj;
  else if (workType === WorkTypes.NewWorkOrder) {
    return {
      ...obj,
      // administrator: convertedObj?.['administrators-7888f7fb-a3dc-44bf-b10b-b00f004e4c6c'],
      // responsibleUsers: convertedObj?.['responsible users-1f769f95-a5a8-4fa6-9a99-b00f004e4c6c'],

      technicalNotes: data?.technicalNotes || null,
      workStarted: data?.workStarted
        ? moment(new Date(data?.workStarted)).format('YYYY-MM-DD')
        : null,
      workCompletedDate: data?.workCompletedDate
        ? moment(new Date(data?.workCompletedDate)).format('YYYY-MM-DD')
        : null,
      regularHours: data?.regularHours || null,
      overTimeHours: data?.overTimeHours || null,
      parts: data?.parts || [],
      reading: data?.reading || null,
      readingUnit: data?.unit || null
    };
  } else if (
    workType === WorkTypes.WorkOrders ||
    (workType === WorkTypes.InspectionAndWorkOrder && workOrder)
  ) {
    return {
      photo: data?.photo || [],
      document: data?.document || [],
      technicalNotes: data?.technicalNotes || null,
      workStarted: data?.workStarted
        ? moment(new Date(data?.workStarted)).format('YYYY-MM-DD')
        : null,
      workCompletedDate: data?.workCompletedDate
        ? moment(new Date(data?.workCompletedDate)).format('YYYY-MM-DD')
        : null,
      regularHours: data?.regularHours || null,
      overTimeHours: data?.overTimeHours || null,
      parts: data?.parts || [],
      reading: data?.reading || null,
      readingUnit: data?.unit || null,
      ...data
    };
  }
};

export const transformNewWorkRequestDataToDynamicFormData = ({ data, workType, workOrder }) => {
  if (workType === WorkTypes.NewWorkRequest) {
    const photos = getSelectedTaskPhotos({ selectedTask: data });
    const document = getSelectedTaskDocuments({ selectedTask: data });
    return {
      'title-0e286da8-90d0-47ac-bf66-b00f004e4c62': {
        '0e286da8-90d0-47ac-bf66-b00f004e4c62': data?.['title'] || null
      },
      'priority-d84c1fd5-5d10-4e6f-bdd3-b00f004e4c62': {
        'd84c1fd5-5d10-4e6f-bdd3-b00f004e4c62': data?.['priorityId'] || null
      },
      'type-307e5c22-15f1-4bae-95c0-b00f004e4c62': {
        '307e5c22-15f1-4bae-95c0-b00f004e4c62': data['typeId'] || null
      },
      'trade-9fe9daf9-ccc1-40e8-8581-b00f004e4c67': {
        '9fe9daf9-ccc1-40e8-8581-b00f004e4c67': data?.['trade'] || null
      },
      'requested due date-b12410e3-3fbe-4c9f-8bf3-b00e018ad3f2': {
        'b12410e3-3fbe-4c9f-8bf3-b00e018ad3f2': data?.['due'] || null
      },
      'description-7ee68589-2e35-433d-867e-b00f004e4c6c': {
        '7ee68589-2e35-433d-867e-b00f004e4c6c': data?.['description'] || null
      },
      'your attachments-4630a3cb-17ea-4fd8-8fb9-b00f004e4c6c': {
        '4630a3cb-17ea-4fd8-8fb9-b00f004e4c6c': {
          photos: photos || [],
          documents: document || []
        }
      }
    };
  } else if (workType === WorkTypes.NewWorkOrder) {
    const photos = getSelectedTaskPhotos({ selectedTask: data });
    const document = getSelectedTaskDocuments({ selectedTask: data });
    return {
      'title-0e286da8-90d0-47ac-bf66-b00f004e4c62': {
        '0e286da8-90d0-47ac-bf66-b00f004e4c62': data?.['title'] || null
      },
      'priority-d84c1fd5-5d10-4e6f-bdd3-b00f004e4c62': {
        'd84c1fd5-5d10-4e6f-bdd3-b00f004e4c62': data?.['priorityId'] || null
      },
      'type-307e5c22-15f1-4bae-95c0-b00f004e4c62': {
        '307e5c22-15f1-4bae-95c0-b00f004e4c62': data['typeId'] || null
      },
      'trade-9fe9daf9-ccc1-40e8-8581-b00f004e4c67': {
        '9fe9daf9-ccc1-40e8-8581-b00f004e4c67': data?.['trade'] || null
      },
      'due date-1b232148-ef85-4206-bf3b-b00f004e4c67': {
        '1b232148-ef85-4206-bf3b-b00f004e4c67': data?.['due'] || null
      },
      'description-7ee68589-2e35-433d-867e-b00f004e4c6c': {
        '7ee68589-2e35-433d-867e-b00f004e4c6c': data?.['description'] || null
      },
      'administrators-7888f7fb-a3dc-44bf-b10b-b00f004e4c6c': {
        '7888f7fb-a3dc-44bf-b10b-b00f004e4c6c':
          data?.['administrators']?.[0]?.woUserRequestId || null
      },
      'responsible users-1f769f95-a5a8-4fa6-9a99-b00f004e4c6c': {
        '1f769f95-a5a8-4fa6-9a99-b00f004e4c6c': data?.['responsible']?.[0]?.woUserRequestId || null
      },
      technicalNotes: data?.notes || null,
      workCompletedDate: data?.workCompleted
        ? moment(new Date(data?.workCompleted)).format('YYYY-MM-DD')
        : null,
      workStarted: data?.workStarted
        ? moment(new Date(data?.workStarted)).format('YYYY-MM-DD')
        : null,
      regularHours: data?.regularHours || null,
      overTimeHours: data?.overTimeHours || null,
      reading: _.size(data?.counterReadings) > 0 ? data?.counterReadings[0]?.Reading : null,
      parts: data?.parts?.map((x) => {
        return {
          id: x?.Id,
          part: x?.['PartNum'],
          'part/materialName': x?.PartName,
          manufactuer: x?.Manufacturer,
          quantity: x?.Quantity,
          unit: x?.Unit
        };
      }),
      unit: _.size(data?.counterReadings) > 0 ? data?.counterReadings[0]?.ReadingUnit : null,
      'your attachments-4630a3cb-17ea-4fd8-8fb9-b00f004e4c6c': {
        '4630a3cb-17ea-4fd8-8fb9-b00f004e4c6c': {
          photos: photos || [],
          documents: document || []
        }
      }
    };
  } else if (
    workType === WorkTypes.WorkOrders ||
    (workType === WorkTypes.InspectionAndWorkOrder && workOrder)
  ) {
    const photos = getSelectedTaskPhotos({ selectedTask: data });
    const document = getSelectedTaskDocuments({ selectedTask: data });
    const inspectionFormData = convertSerializableToNonSerialData(data?.formData);
    return {
      ...inspectionFormData,
      technicalNotes: data?.notes || null,
      workCompletedDate: data?.workCompleted ? data?.workCompleted : moment().format('YYYY-MM-DD'),
      workStarted: data?.workStarted ? data?.workStarted : moment().format('YYYY-MM-DD'),
      regularHours: data?.regularHours || null,
      overTimeHours: data?.overTimeHours || null,
      reading: _.size(data?.counterReadings) > 0 ? data?.counterReadings[0]?.Reading : null,
      parts: data?.parts?.map((x) => {
        return {
          id: x?.Id,
          part: x?.['PartNum'],
          'part/materialName': x?.PartName,
          manufactuer: x?.Manufacturer,
          quantity: x?.Quantity,
          unit: x?.Unit
        };
      }),
      unit: _.size(data?.counterReadings) > 0 ? data?.counterReadings[0]?.ReadingUnit : null,
      photo: photos || [],
      document: document || []
    };
  } else if (workType === WorkTypes.Inspection || workType === WorkTypes.NewInspection) {
    const inspectionFormData = convertSerializableToNonSerialData(data?.formData);
    return {
      ...inspectionFormData
    };
  } else if (workType === WorkTypes.NewHSE) {
    const inspectionFormData = convertSerializableToNonSerialData(data?.formData);
    return {
      ...inspectionFormData
    };
  } else if (workType === WorkTypes.NewCheck || workType === WorkTypes.Check) {
    const inspectionFormData = convertSerializableToNonSerialData(data?.formData);
    return {
      ...inspectionFormData,
      description: data?.description || '',
      identifier: data?.identifier || '',
      location: data?.location || ''
    };
  }
};
