/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CheckablePill from '@components/tags/check-able-pill/checkable-pill';

function TagList({ TagItems, MultiSelect, ReturnTags, Selected, TagsState }) {
  const [SelectTags, setSelectedTags] = useState(Selected ? { [TagItems[0]]: true } : {});

  React.useEffect(() => {
    if (TagsState === null) setSelectedTags({});
    else {
      if (Object.keys(SelectTags).join(',') !== Object.keys(TagsState).join(','))
        setSelectedTags(TagsState);
      else if (Object.values(SelectTags).join(',') !== Object.values(TagsState).join(','))
        setSelectedTags(TagsState);
    }
  }, [TagsState]);

  const HandleTagChange = (item, isChecked) => {
    if (MultiSelect) {
      setSelectedTags({ ...SelectTags, [item]: isChecked });
      ReturnTags({ [item]: isChecked });
    } else {
      setSelectedTags({ [item]: isChecked });
      ReturnTags({ [item]: isChecked });
    }
  };

  const [locationPermission, setLocationPermission] = useState('prompt');

  useEffect(() => {
    if (navigator?.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
        setLocationPermission(permissionStatus.state);
        permissionStatus.onchange = () => {
          setLocationPermission(permissionStatus.state);
        };
      });
    } else if (navigator?.geolocation) {
      const successCallback = () => {
        setLocationPermission('granted');
      };

      const errorCallback = (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          setLocationPermission('denied');
        } else {
          setLocationPermission('unavailable');
        }
      };

      navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
    } else {
      console.log('Geolocation is not supported');
      setLocationPermission('unavailable');
    }
  }, []);

  const [location, setLocation] = useState(false);

  useEffect(() => {
    if (locationPermission === 'granted') {
      setLocation(true);
      setSelectedTags({ [TagItems[0]]: true });
    } else {
      setLocation(false);
      setSelectedTags({ [TagItems[1]]: true });
    }
  }, [locationPermission]);

  return (
    <>
      {TagItems.length > 0 ? (
        <div className="btn-sort-wrap op-task-status-tag">
          {TagItems.map((i, k) =>
            i === 'DISTANCE' && location === false ? null : (
              <CheckablePill
                key={k}
                item={i}
                checked={SelectTags[i]}
                SelectTags={SelectTags}
                onChange={(checked) => {
                  HandleTagChange(i, checked);
                }}
              >
                {i}
              </CheckablePill>
            )
          )}
        </div>
      ) : (
        'No Tags Available'
      )}
    </>
  );
}

TagList.propTypes = {
  TagItems: PropTypes.array,
  MultiSelect: PropTypes.bool,
  Selected: PropTypes.bool,
  ReturnTags: PropTypes.func,
  TagsState: PropTypes.object
};

export default TagList;
