/* eslint-disable no-unused-vars */
import { useCallback } from 'react';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { store } from 'redux/store';

import PageContainer from '@components/page-container';
import SearchField from '@components/search-field';
import Button from '@components/button/button.component';
import RecentIcon from '@assets/icons/recent-arrow.svg';
import TabsSection from './tabs-section';
import LoadingRound from '@components/loading-round/loading-round';
import { initWebWorker } from 'webworker';

import SearchPageHeader from './search-page-header';

import { useDebounce } from '@hooks/useDebounce';

import { decodeToken } from '@utilities/authentication';
import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';
import { tasksListsActions } from '@redux/slices/tasks';
import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './search-page.module.scss';

const Search = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname, state } = useLocation();
  const dispatch = getAppDispatch();
  const { width } = useWindowDimensions();
  const searchParamsValue = searchParams.get('value') || '';
  const searchParamsBackValue = searchParams.get('back') || '';
  const searchParamsRerender = searchParams.get('rerender') || searchParams.get('back') || '';

  const dataFetchedRef = useRef(false);
  const [tasks, setTasks] = useState([]);
  const [sites, setSites] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [structures, setStructures] = useState([]);
  const [pipelines, setPipelines] = useState([]);
  const [rts, setRts] = useState([]);
  const [runs, setRuns] = useState([]);
  const [rtsSelectedView, setRtsSelectedView] = useState('');
  const [runsSelectedView, setRunsSelectedView] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(true);
  const [inputValue, setInputValue] = useState('');
  const [searchPage, setSearchPage] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [recentSearchesData, setRecentSearchesData] = useState([]);
  const [filterTasks, setFilterTasks] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [filteredStructure, setFilteredStructure] = useState([]);
  const [filteredPipeline, setFilteredPipeline] = useState([]);
  const [filteredRTs, setFilteredRTs] = useState([]);
  const [filteredRuns, setFilteredRuns] = useState([]);

  const { isSyncingModal, refreshingAndSyncingData } = useSelector((state) => state.cache);
  useEffect(() => {
    if (searchParamsValue) {
      setInputValue(searchParamsValue);
    } else if (state?.value) setInputValue(state?.value);
  }, [searchParamsValue]);

  useEffect(() => {
    if (searchParamsValue && inputValue) {
      setRecentSearchesData((prevSearches) => {
        if (!prevSearches?.map((x) => x.toLowerCase()).includes(inputValue.toLowerCase())) {
          const newSearches = [inputValue, ...prevSearches];
          const limitedSearches = newSearches.slice(0, 5);
          localStorage.setItem(
            `recentSearches-${decodeToken().userId}`,
            JSON.stringify(limitedSearches)
          );
          return limitedSearches;
        } else {
          let newSearches = [...prevSearches.map((x) => x.toLowerCase())];
          const index = newSearches?.findIndex((x) => x === inputValue.toLowerCase());
          if (index !== -1) {
            newSearches.splice(index, 1);
            newSearches.unshift(inputValue.toLowerCase());
            return newSearches;
          } else return prevSearches;
        }
      });
      setSearchPage(true);
    }
  }, [searchParamsValue, inputValue]);

  const picklistsMap = useSelector((state) => state.picklists?.picklistsMap);
  const backHistoryForSearchPage = useSelector((state) => state.backHistory?.searchPage) || null;
  const debouncedSearchInputValue = useDebounce(inputValue, 1000);
  const checkedLength = useMemo(() => {
    return filterTasks.reduce((acc, curr) => {
      if (curr.checked) acc++;
      return acc;
    }, 0);
  }, [filterTasks, setFilterTasks]);

  useEffect(() => {
    if (!isSyncingModal && !refreshingAndSyncingData && searchParamsRerender === 'true') {
      setInitialLoading(true);
      setSearchLoading(true);
      getInitialSearchData({ rerender: true });
    }
  }, [isSyncingModal, refreshingAndSyncingData]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    if (backHistoryForSearchPage?.searchedValue && !searchParamsValue) {
      console.log(backHistoryForSearchPage?.searchedValue);
      setInputValue(backHistoryForSearchPage?.searchedValue || '');
    }
    getInitialSearchData({ rerender: false });
  }, []);

  useEffect(() => {
    if (!initialLoading && debouncedSearchInputValue?.trim().length !== 0) {
      (async () => {
        await getSearchedData();
      })();
    } else if (debouncedSearchInputValue?.trim().length === 0) {
      !searchParamsValue && !searchParamsBackValue && setSearchLoading(false);
    }
  }, [initialLoading, debouncedSearchInputValue, searchParamsValue, searchParamsBackValue]);

  const getInitialSearchData = ({ rerender = false }) => {
    initWebWorker({
      args: { store: { ...store.getState() } },
      type: 'get-all-search-data'
    })
      .then((data) => {
        const [
          _tasks,
          _inspections,
          _hseEvents,
          _checks,
          _sites,
          _equipments,
          _structures,
          _pipelines,
          _rts,
          _runs
        ] = data?.result || [];
        const allTask = [..._tasks, ..._inspections, ..._hseEvents, ..._checks];

        if (allTask.length > 0) {
          const tasksCopy = allTask
            ?.map((task) => {
              return {
                ...task,
                status: task?.woId
                  ? task?.status
                  : ['To do', 'Pending sync', 'In progress', 'Synced'].includes(task?.status)
                  ? task?.status
                  : task?.link
                  ? 'Synced'
                  : 'To do',
                priorityId: task?.priority,
                priority: picklistsMap[task?.priority]?.value,
                workStatus: picklistsMap[task?.status]?.value
              };
            })
            ?.sort((a, b) => {
              const statusOrder = { 'To do': 0, 'In progress': 1, 'Pending sync': 2, Synced: 3 };
              return statusOrder[a.status] - statusOrder[b.status];
            });
          setTasks(tasksCopy);
        }
        setEquipments([..._equipments]);
        setStructures([..._structures]);
        setPipelines([..._pipelines]);
        setSites([..._sites]);
        setRts([..._rts]);
        setRuns([..._runs]);
        setInitialLoading(false);
        if (rerender) {
          const prevRoute = `${pathname}`;
          navigate(prevRoute, { replace: true });
        }
      })
      .catch((error) => {
        setInitialLoading(false);
      });
  };

  const getSearchedData = async () => {
    try {
      setSearchLoading(true);

      initWebWorker({
        args: {
          store: { ...store.getState() },
          searched: debouncedSearchInputValue?.trim(),
          tasks,
          sites,
          equipments,
          rts,
          runs,
          structures,
          pipelines
        },
        type: 'get-search-input-data'
      }).then((data) => {
        const [
          _filterTasks,
          _filteredSites,
          _filteredEquipment,
          _filteredRTs,
          _filteredRuns,
          _filteredStructure,
          _filteredPipelines
        ] = data?.result || [];
        setFilterTasks([..._filterTasks]);
        setFilteredSites([..._filteredSites]);
        setFilteredEquipment([..._filteredEquipment]);
        setFilteredRTs([..._filteredRTs]);
        setFilteredRuns([..._filteredRuns]);
        setFilteredStructure([..._filteredStructure]);
        setFilteredPipeline([..._filteredPipelines]);
        setSearchLoading(false);
      });
    } catch (error) {
      console.error('At least one promise rejected with error:', error);
    }
  };

  useEffect(() => {
    const savedSearches = JSON.parse(
      localStorage.getItem(`recentSearches-${decodeToken().userId}`)
    );
    if (savedSearches) {
      setRecentSearchesData(savedSearches);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (debouncedSearchInputValue.trim() !== '') {
        const prevSearches =
          JSON.parse(localStorage.getItem(`recentSearches-${decodeToken().userId}`)) || [];

        if (
          !prevSearches
            ?.map((x) => x.toLowerCase())
            .includes(debouncedSearchInputValue.toLowerCase())
        ) {
          const newSearches = [debouncedSearchInputValue, ...prevSearches];
          const limitedSearches = newSearches.slice(0, 5);
          localStorage.setItem(
            `recentSearches-${decodeToken().userId}`,
            JSON.stringify(limitedSearches)
          );
        } else {
          let newSearches = [...prevSearches.map((x) => x.toLowerCase())];
          const index = newSearches?.findIndex(
            (x) => x === debouncedSearchInputValue.toLowerCase()
          );
          if (index !== -1) {
            newSearches.splice(index, 1);
            newSearches.unshift(debouncedSearchInputValue.toLowerCase());
            localStorage.setItem(
              `recentSearches-${decodeToken().userId}`,
              JSON.stringify(newSearches)
            );
          } else
            localStorage.setItem(
              `recentSearches-${decodeToken().userId}`,
              JSON.stringify(prevSearches)
            );
        }
      }
    };
  }, [debouncedSearchInputValue]);

  const handleChange = React.useCallback((e) => {
    const valueSearched = (e && e.target.value) || '';
    setSearchPage(true);
    valueSearched?.trim().length !== 0 && setSearchLoading(true);
    setInputValue(valueSearched);
    if (!valueSearched) resetStoreSearchValue();
    if (searchPage) setSearchPage(false);
  }, []);

  const handleRecentSearchClick = (search) => {
    setSearchLoading(true);
    setInputValue(search);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && debouncedSearchInputValue) {
      setSearchPage(true);
      setRecentSearchesData((prevSearches) => {
        if (
          !prevSearches
            ?.map((x) => x.toLowerCase())
            .includes(debouncedSearchInputValue.toLowerCase())
        ) {
          const newSearches = [debouncedSearchInputValue, ...prevSearches];
          const limitedSearches = newSearches.slice(0, 5);
          localStorage.setItem(
            `recentSearches-${decodeToken().userId}`,
            JSON.stringify(limitedSearches)
          );
          return limitedSearches;
        } else {
          let newSearches = [...prevSearches.map((x) => x.toLowerCase())];
          const index = newSearches?.findIndex(
            (x) => x === debouncedSearchInputValue.toLowerCase()
          );
          if (index !== -1) {
            newSearches.splice(index, 1);
            newSearches.unshift(debouncedSearchInputValue.toLowerCase());
            return newSearches;
          } else return prevSearches;
        }
      });
    }
  };
  const onClickReset = () => {
    setSearchLoading(true);
    if (searchParamsValue) navigate(pathname, { replace: true });
    if (debouncedSearchInputValue.trim() !== '') {
      setRecentSearchesData((prevSearches) => {
        if (
          !prevSearches
            ?.map((x) => x.toLowerCase())
            .includes(debouncedSearchInputValue.toLowerCase())
        ) {
          const newSearches = [debouncedSearchInputValue, ...prevSearches];
          const limitedSearches = newSearches.slice(0, 5);
          localStorage.setItem(
            `recentSearches-${decodeToken().userId}`,
            JSON.stringify(limitedSearches)
          );
          return limitedSearches;
        } else {
          let newSearches = [...prevSearches.map((x) => x.toLowerCase())];
          const index = newSearches?.findIndex(
            (x) => x === debouncedSearchInputValue.toLowerCase()
          );
          if (index !== -1) {
            newSearches.splice(index, 1);
            newSearches.unshift(debouncedSearchInputValue.toLowerCase());
            return newSearches;
          } else return prevSearches;
        }
      });

      setInputValue('');
      setSearchPage(false);
      resetStoreSearchValue();
      const copy = [...filterTasks];
      for (let i = 0; i < copy.length; i++)
        if (copy[i].type !== 'Check' && copy[i].status === 'To do') copy[i].checked = false;
      setFilterTasks(copy);
      setRts((prev) => {
        const copy = [...prev];
        return copy?.map((x) => {
          return { ...x, checked: false };
        });
      });
      setRuns((prev) => {
        const copy = [...prev];
        return copy?.map((x) => {
          return { ...x, checked: false };
        });
      });
      setRtsSelectedView('');
      setRunsSelectedView('');
    } else setInputValue('');
  };

  const showRecentSearches = () => {
    return _.size(recentSearchesData) > 0 ? (
      <div className={style.recentTab}>
        <span className={style.recentHeading}>Recent</span>
        <div style={{ marginTop: '16px' }}>
          {recentSearchesData?.map((search, index) => (
            <div
              className={style.recentSearch}
              key={index}
              onClick={() => handleRecentSearchClick(search)}
            >
              <span className={style.recentText}>{search}</span>
              <img
                src={RecentIcon}
                height={20}
                width={20}
                onClick={() => handleRecentSearchClick(search)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div className={style.noBookmarkSection}>
        <p className={style.firstDiv}>No recent searches.</p>
      </div>
    );
  };

  const goBackRt = React.useCallback(() => {
    if (rtsSelectedView) setRtsSelectedView('');
    else if (checkedLengthOfRtsTasksAndSites > 0) {
      let rtCopy = [...rts];
      for (let i = 0; i < rtCopy.length; i++) {
        rtCopy[i].checked = false;
      }
      setRts(rtCopy);
    }
  }, [rtsSelectedView, setRtsSelectedView]);

  const gotBackRun = React.useCallback(() => {
    if (runsSelectedView) setRunsSelectedView('');
    else if (checkedLengthOfRunsTasksAndSites > 0) {
      let runsCopy = [...runs];
      for (let i = 0; i < runsCopy.length; i++) {
        runsCopy[i].checked = false;
      }
      setRuns(runsCopy);
    }
  }, [runsSelectedView, setRunsSelectedView]);

  const checkedLengthOfRtsTasksAndSites = useMemo(() => {
    return rts.filter(({ checked }) => checked).length;
  }, [rts]);

  const checkedLengthOfRunsTasksAndSites = useMemo(() => {
    return runs.filter(({ checked }) => checked).length;
  }, [runs]);

  const resetStoreSearchValue = useCallback(() => {
    dispatch?.(
      backHistoryActions.setSearchPageHistory({
        tab: '0',
        searchedValue: ''
      })
    );
  }, [inputValue]);

  const onChangePageHeader = (checked) => {
    const copy = [...tasks];
    for (let i = 0; i < copy.length; i++) if (copy[i].status === 'To do') copy[i].checked = checked;
    setTasks([...copy]);
  };

  useEffect(() => {
    if (width <= 767)
      if (checkedLength > 0) dispatch?.(tasksListsActions.setSelectedTask(true));
      else dispatch?.(tasksListsActions.setSelectedTask(false));
  }, [checkedLength]);

  const onChangeTabEvent = React.useCallback(() => {
    setRts((prev) => {
      const copy = [...prev];
      return copy?.map((x) => {
        return { ...x, checked: false };
      });
    });
  }, []);

  return (
    <PageContainer
      checkedLengthOfRtsTasksAndSites={checkedLengthOfRtsTasksAndSites}
      checkedLengthOfRunsTasksAndSites={checkedLengthOfRunsTasksAndSites}
    >
      <SearchPageHeader
        searchPage={searchPage}
        checkedLength={checkedLength}
        goBack={onClickReset}
        data={filterTasks}
        setData={setFilterTasks}
        setInputValue={setInputValue}
        setSearchPage={setSearchPage}
        rtsSelectedView={rtsSelectedView}
        goBackRt={goBackRt}
        gotBackRun={gotBackRun}
        checkedLengthOfRtsTasksAndSites={checkedLengthOfRtsTasksAndSites}
        checkedLengthOfRunsTasksAndSites={checkedLengthOfRunsTasksAndSites}
        onChangePageHeader={onChangePageHeader}
      />

      <div
        className={`page-listing ${checkedLength > 0 ? 'no-appbar' : ''} `}
        style={{
          position: 'relative ',

          overflowY: 'hidden ',
          marginTop: '0px'
        }}
      >
        <div className="op-filter-search p-4">
          <SearchField
            placeholder="What are you looking for?"
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onClickResetEvent={onClickReset}
            isClearable
          />
        </div>
        {searchLoading ? (
          <div style={{ backgroundColor: 'transparent', width: '100%' }}>
            <LoadingRound />
          </div>
        ) : (
          <>
            {!inputValue?.trim() ? (
              showRecentSearches()
            ) : (
              <>
                {filterTasks?.length <= 0 &&
                filteredSites?.length <= 0 &&
                filteredEquipment?.length <= 0 &&
                filteredStructure?.length <= 0 &&
                filteredPipeline?.length <= 0 &&
                filteredRTs?.length <= 0 &&
                filteredRuns?.length <= 0 &&
                !searchLoading ? (
                  <div className={`no-result ${style.resetSearchBtn}`}>
                    <div>No results.</div>
                    <Button text={'Reset search'} onClick={onClickReset} variant={'border'} />
                  </div>
                ) : (
                  <>
                    <TabsSection
                      searched={inputValue}
                      filterTasks={filterTasks}
                      filterSites={filteredSites}
                      filterEquipments={filteredEquipment}
                      filteredStructures={filteredStructure}
                      filteredPipelines={filteredPipeline}
                      filterRts={filteredRTs}
                      filterRuns={filteredRuns}
                      setTasks={setFilterTasks}
                      tasks={filterTasks}
                      setRts={setRts}
                      rts={rts}
                      runs={runs}
                      setRuns={setRuns}
                      setRtsSelectedView={setRtsSelectedView}
                      setRunsSelectedView={setRunsSelectedView}
                      runsSelectedView={runsSelectedView}
                      rtsSelectedView={rtsSelectedView}
                      checkedLengthOfRtsTasksAndSites={checkedLengthOfRtsTasksAndSites}
                      checkedLengthOfRunsTasksAndSites={checkedLengthOfRunsTasksAndSites}
                      currentTab={backHistoryForSearchPage?.tab || '0'}
                      checkedLength={checkedLength}
                      onChangeTabEvent={onChangeTabEvent}
                    />
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </PageContainer>
  );
};

export default React.memo(Search);
