/* eslint-disable no-unused-vars */
import React, { memo, useState } from 'react';
import _ from 'lodash';

import DataSnippets from '@components/data-snippet';

import style from '../task.module.scss';
import CardSite from './card-site';
import CardEquipment from './card-equipment';
import CardPipeline from './card-pipeline';
import CardStructure from './card-structure';
import DetailInspection from './detail-inspection';

const CardAssetDetails = ({
  equipmentAssetFilterData,
  sitesAssetFilterData,
  mocData,
  hseData,
  structureData,
  structureAsset,
  pipelineAsset,
  ncrData,
  highlight,
  className,
  showNotIcon = false,
  detailInspectionsInfo = [],
  chip = true,
  formWrapper = false,
  appliesTo = false,
  id
}) => {
  const [showEquipments, setShowEquipments] = useState(false);
  const [showSites, setShowSites] = useState(false);
  const [showStructure, setShowStructure] = useState(false);
  const [showPipeline, setShowPipeline] = useState(false);
  const [showPipelineSites, setShowPipelineSites] = useState(false);
  const structureSiteIds = structureData?.structureData?.map((x) => x?.siteAsset?.siteId);
  const equipmentSiteIds = equipmentAssetFilterData?.prepareTasksEquipments?.map((x) => x?.siteId);

  const taskCardMOC = () => {
    return (
      !_.isEmpty(mocData) && (
        <>
          {!_.isEmpty(mocData?.recordNumber) && (
            <div className={style['mt-16']}>
              <DataSnippets
                title={mocData?.recordNumber || ''}
                subtitle={mocData?.recordTitle || ''}
                iconName={!showNotIcon && 'moc'}
                backFlex={style.backClass}
                highlightNames={highlight}
                className={`${style.mainClass} ${className} ${style.dataSnippetMargin}`}
                mocHseNcrInspection
              />
            </div>
          )}
          {!_.isEmpty(mocData.siteAsset) && (
            <div className={style['mt-16']}>
              <CardSite
                highlight={highlight}
                className={className}
                showNotIcon={showNotIcon}
                formWrapper={formWrapper}
                siteData={mocData.siteAsset}
                taskId={id}
                assetId={mocData?.siteAsset?.siteId}
              />
            </div>
          )}

          {!_.isEmpty(mocData.equipmentAsset) && (
            <div className={style['mt-16']}>
              <CardEquipment
                equipmentData={mocData.equipmentAsset}
                equipmentsIds={mocData.equipmentsAsset?.equipmentsIds || []}
                highlight={highlight}
                formWrapper={formWrapper}
                showNotIcon={showNotIcon}
                className={className}
                chip={chip}
                equipmentAssetFilterData={equipmentAssetFilterData}
                taskId={id}
                assetId={mocData?.equipmentAsset?.equipmentId}
              />
            </div>
          )}
          {_.size(mocData.pipelineAsset) > 0 &&
            mocData?.pipelineAsset.map((data, index) => (
              <div className={style['mt-16']} key={index}>
                <DataSnippets
                  backFlex={style.backClass}
                  title={data?.licenseNumber || ''}
                  subtitle={data?.length || ''}
                  iconName={!showNotIcon && 'pipeline'}
                  from={data?.fromLocation || ''}
                  to={data?.toLocation || ''}
                  highlightNames={highlight}
                  type={`Length: ${data?.length} km`}
                  className={` ${style.dataSnippetMargin}`}
                />
              </div>
            ))}

          {_.size(mocData?.structureData) > 0 &&
            mocData?.structureData?.map((data, index) => (
              <div className={style['mt-16']} key={index}>
                <DataSnippets
                  backFlex={style.backClass}
                  title={data?.name || ''}
                  subtitle={data?.structureNumberAuto || ''}
                  iconName={!showNotIcon && 'home-icon'}
                  type={data?.structureType || ''}
                  highlightNames={highlight}
                  className={` ${style.dataSnippetMargin}`}
                />
              </div>
            ))}
        </>
      )
    );
  };
  const taskCardHSE = () => {
    return (
      !_.isEmpty(hseData) && (
        <>
          {!_.isEmpty(hseData?.recordNumber) && (
            <div className={style['mt-16']}>
              <DataSnippets
                title={hseData?.recordNumber || ''}
                subtitle={hseData?.recordTitle || ''}
                iconName={!showNotIcon && 'hse'}
                backFlex={style.backClass}
                highlightNames={highlight}
                className={`${style.mainClass} ${className} {style.dataSnippetMargin}`}
                mocHseNcrInspection
              />
            </div>
          )}
          {!_.isEmpty(hseData.siteAsset) && (
            <div className={style['mt-16']}>
              <CardSite
                highlight={highlight}
                className={className}
                showNotIcon={showNotIcon}
                formWrapper={formWrapper}
                siteData={hseData.siteAsset}
                taskId={id}
                assetId={hseData?.siteAsset?.siteId}
              />
            </div>
          )}
          {!_.isEmpty(hseData.equipmentAsset) && (
            <div className={style['mt-16']}>
              <CardEquipment
                equipmentData={hseData.equipmentAsset}
                equipmentsIds={hseData.equipmentsAsset?.equipmentsIds || []}
                highlight={highlight}
                formWrapper={formWrapper}
                showNotIcon={showNotIcon}
                className={className}
                chip={chip}
                equipmentAssetFilterData={equipmentAssetFilterData}
                taskId={id}
                assetId={hseData?.equipmentAsset?.equipmentId}
              />
            </div>
          )}

          {_.size(hseData.pipelineAsset) > 0 &&
            hseData?.pipelineAsset.map((data, index) => (
              <div className={style['mt-16']} key={index}>
                <DataSnippets
                  backFlex={style.backClass}
                  title={data?.licenseNumber || ''}
                  subtitle={data?.length || ''}
                  iconName={!showNotIcon && 'pipeline'}
                  from={data?.fromLocation || ''}
                  to={data?.toLocation || ''}
                  highlightNames={highlight}
                  type={`Length: ${data?.length} km`}
                  className={`${style.dataSnippetMargin}`}
                />
              </div>
            ))}

          {_.size(hseData?.structureData) > 0 &&
            hseData?.structureData?.map((data, index) => (
              <div className={style['mt-16']} key={index}>
                <DataSnippets
                  backFlex={style.backClass}
                  title={data?.name || ''}
                  subtitle={data?.structureNumberAuto || ''}
                  iconName={!showNotIcon && 'home-icon'}
                  type={data?.structureType || ''}
                  highlightNames={highlight}
                  className={` ${style.dataSnippetMargin}`}
                />
              </div>
            ))}
        </>
      )
    );
  };
  const taskCardNCR = () => {
    return (
      !_.isEmpty(ncrData) && (
        <>
          {!_.isEmpty(ncrData?.recordNumber) && (
            <div className={style['mt-16']}>
              <DataSnippets
                title={ncrData?.recordNumber || ''}
                subtitle={ncrData?.recordTitle || ''}
                iconName={!showNotIcon && 'moc'}
                backFlex={style.backClass}
                className={`${style.mainClass} ${className}`}
                iconWidth="30"
                mocHseNcrInspection
              />
            </div>
          )}
          {!_.isEmpty(ncrData.siteAsset) && (
            <div className={style['mt-16']}>
              <CardSite
                highlight={highlight}
                className={className}
                showNotIcon={showNotIcon}
                formWrapper={formWrapper}
                siteData={ncrData.siteAsset}
                taskId={id}
                assetId={ncrData?.siteAsset?.siteId}
              />
            </div>
          )}
          {!_.isEmpty(ncrData.equipmentAsset) && (
            <div className={style['mt-16']}>
              <CardEquipment
                equipmentData={ncrData.equipmentAsset}
                equipmentsIds={ncrData.equipmentsAsset?.equipmentsIds || []}
                highlight={highlight}
                formWrapper={formWrapper}
                showNotIcon={showNotIcon}
                className={className}
                chip={chip}
                equipmentAssetFilterData={equipmentAssetFilterData}
                taskId={id}
                assetId={ncrData?.equipmentAsset?.equipmentId}
              />
            </div>
          )}

          {_.size(ncrData.pipelineAsset) > 0 &&
            ncrData?.pipelineAsset.map((data, index) => (
              <div className={style['mt-16']} key={index}>
                <DataSnippets
                  backFlex={style.backClass}
                  title={data?.licenseNumber || ''}
                  subtitle={data?.length || ''}
                  iconName={!showNotIcon && 'pipeline'}
                  from={data?.fromLocation || ''}
                  to={data?.toLocation || ''}
                  highlightNames={highlight}
                  type={`Length: ${data?.length} km`}
                  className={`${style.dataSnippetMargin}`}
                />
              </div>
            ))}
          {_.size(ncrData?.structureData) > 0 &&
            ncrData?.structureData?.map((data, index) => (
              <div className={style['mt-16']} key={index}>
                <DataSnippets
                  backFlex={style.backClass}
                  title={data?.name || ''}
                  subtitle={data?.structureNumberAuto || ''}
                  iconName={!showNotIcon && 'home-icon'}
                  type={data?.structureType || ''}
                  highlightNames={highlight}
                  className={` ${style.dataSnippetMargin}`}
                />
              </div>
            ))}
        </>
      )
    );
  };

  return (
    <div
      style={{
        marginTop: showNotIcon && '-16px'
      }}
    >
      {taskCardMOC()}

      {_.size(structureAsset?.prepareTasksStructure) > 0 && (
        <>
          {showStructure ? (
            structureAsset?.prepareTasksStructure?.map((data, index) => (
              <div className={style['mt-16']} key={index}>
                <CardStructure
                  structureAsset={structureAsset}
                  highlight={highlight}
                  className={className}
                  showNotIcon={showNotIcon}
                  formWrapper={formWrapper}
                  data={{
                    ...data,
                    link: !formWrapper
                      ? `/#/structure-details/${
                          structureAsset?.prepareTasksStructure?.at(0)?.structureId
                        }`
                      : ''
                  }}
                  index={index}
                  value={
                    index === 0 &&
                    (structureAsset?.prepareTasksStructure?.length > 1
                      ? `+${structureAsset?.prepareTasksStructure?.length - 1}`
                      : null)
                  }
                  setShowStructure={setShowStructure}
                  taskId={id}
                  assetId={data?.structureId}
                  divider={index < structureAsset?.prepareTasksStructure?.length - 1}
                />
              </div>
            ))
          ) : (
            <div className={style['mt-16']}>
              <CardStructure
                structureAsset={structureAsset}
                highlight={highlight}
                className={className}
                showNotIcon={showNotIcon}
                formWrapper={formWrapper}
                data={{
                  ...structureAsset?.prepareTasksStructure?.at(0),
                  link: !formWrapper
                    ? `/#/structure-details/${
                        structureAsset?.prepareTasksStructure?.at(0)?.structureId
                      }`
                    : ''
                }}
                index={false}
                value={
                  structureAsset?.prepareTasksStructure.length > 1
                    ? `+${structureAsset?.prepareTasksStructure.length - 1}`
                    : null
                }
                setShowStructure={setShowStructure}
                taskId={id}
                assetId={structureAsset?.prepareTasksStructure?.at(0)?.structureId}
              />
            </div>
          )}
        </>
      )}

      <DetailInspection
        highlight={highlight}
        className={className}
        showNotIcon={showNotIcon}
        detailInspectionsInfo={detailInspectionsInfo || []}
      />

      {_.size(equipmentAssetFilterData?.equipmentsIds) > 0 && (
        <>
          {showEquipments
            ? equipmentAssetFilterData?.prepareTasksEquipments.map(
                (equ, index) =>
                  ncrData?.equipmentAsset?.equipmentId !== equ?.equipmentId && (
                    <div className={`${style.gap} ${style['mt-16']}`} key={index}>
                      <CardEquipment
                        showValue={index === 0}
                        equipmentData={equ}
                        equipmentsIds={equipmentAssetFilterData.equipmentsIds || []}
                        highlight={highlight}
                        divider={
                          index < equipmentAssetFilterData?.prepareTasksEquipments.length - 1
                        }
                        formWrapper={formWrapper}
                        showNotIcon={showNotIcon}
                        className={className}
                        chip={chip}
                        equipmentAssetFilterData={equipmentAssetFilterData}
                        setShowEquipments={setShowEquipments}
                        taskId={id}
                        assetId={equ?.equipmentId}
                      />
                    </div>
                  )
              )
            : ncrData?.equipmentAsset?.equipmentId !==
                equipmentAssetFilterData?.prepareTasksEquipments[0]?.equipmentId && (
                <div className={`${style.gap} ${style['mt-16']}`}>
                  <CardEquipment
                    showValue={true}
                    equipmentData={equipmentAssetFilterData?.prepareTasksEquipments[0] || {}}
                    equipmentsIds={equipmentAssetFilterData.equipmentsIds || []}
                    highlight={highlight}
                    formWrapper={formWrapper}
                    showNotIcon={showNotIcon}
                    className={className}
                    chip={chip}
                    equipmentAssetFilterData={equipmentAssetFilterData}
                    setShowEquipments={setShowEquipments}
                    taskId={id}
                    assetId={equipmentAssetFilterData?.prepareTasksEquipments[0]?.equipmentId}
                  />
                </div>
              )}
        </>
      )}

      {_.size(sitesAssetFilterData?.sitesIds) > 0 && (
        <div
          style={{
            marginTop: _.size(equipmentAssetFilterData?.equipmentsIds) > 0 ? '16px' : ''
          }}
        >
          {showSites
            ? sitesAssetFilterData?.prepareTasksSites?.map(
                (equ, index) =>
                  !checkStructureSiteExistsInSite({ structureSiteIds, sitesAssetFilterData }) &&
                  !checkEquipmentSiteExistsInSite({ equipmentSiteIds, sitesAssetFilterData }) && (
                    <div key={index} className={` ${style['mt-16']}`}>
                      <div className={style.gap}>
                        <CardSite
                          highlight={highlight}
                          className={className}
                          showNotIcon={showNotIcon}
                          formWrapper={formWrapper}
                          setShowSites={setShowSites}
                          variant={null}
                          showValue={index === 0}
                          siteData={equ || {}}
                          siteIds={sitesAssetFilterData?.sitesIds || []}
                          appliesTo={appliesTo}
                          taskId={id}
                          assetId={equ?.siteId}
                        />
                      </div>
                    </div>
                  )
              )
            : !checkStructureSiteExistsInSite({ structureSiteIds, sitesAssetFilterData }) &&
              !checkEquipmentSiteExistsInSite({ equipmentSiteIds, sitesAssetFilterData }) && (
                <div className={`${style.gap} ${style['mt-16']}`}>
                  <CardSite
                    highlight={highlight}
                    className={className}
                    showNotIcon={showNotIcon}
                    formWrapper={formWrapper}
                    setShowSites={setShowSites}
                    variant={null}
                    showValue={true}
                    siteData={sitesAssetFilterData?.prepareTasksSites[0] || {}}
                    siteIds={sitesAssetFilterData?.sitesIds || []}
                    appliesTo={appliesTo}
                    taskId={id}
                    assetId={sitesAssetFilterData?.prepareTasksSites[0]?.siteId}
                  />
                </div>
              )}
        </div>
      )}

      {taskCardHSE()}
      {taskCardNCR()}

      {_.size(pipelineAsset?.prepareTasksPipeline) > 0 && (
        <>
          {showPipeline ? (
            pipelineAsset?.prepareTasksPipeline?.map((data, index) => (
              <div className={style['mt-16']} key={index}>
                <CardPipeline
                  pipelineAsset={pipelineAsset}
                  highlight={highlight}
                  showNotIcon={showNotIcon}
                  formWrapper={formWrapper}
                  showPipelineSites={showPipelineSites}
                  className={className}
                  setShowPipelineSites={setShowPipelineSites}
                  setShowPipeline={setShowPipeline}
                  pipelineData={data}
                  value={
                    index === 0 &&
                    (pipelineAsset?.prepareTasksPipeline?.length > 1
                      ? `+${pipelineAsset?.prepareTasksPipeline?.length - 1}`
                      : null)
                  }
                  divider={index < pipelineAsset?.prepareTasksPipeline?.length - 1}
                  taskId={id}
                  assetId={data?.pipelineId}
                />
              </div>
            ))
          ) : (
            <div className={style['mt-16']}>
              <CardPipeline
                pipelineAsset={pipelineAsset}
                highlight={highlight}
                showNotIcon={showNotIcon}
                formWrapper={formWrapper}
                showPipelineSites={showPipelineSites}
                className={className}
                setShowPipelineSites={setShowPipelineSites}
                setShowPipeline={setShowPipeline}
                pipelineData={pipelineAsset?.prepareTasksPipeline?.at(0)}
                value={
                  pipelineAsset?.prepareTasksPipeline?.length > 1
                    ? `+${pipelineAsset?.prepareTasksPipeline?.length - 1}`
                    : null
                }
                taskId={id}
                assetId={pipelineAsset?.prepareTasksPipeline?.at(0)?.pipelineId}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(CardAssetDetails, _.isEqual);

const checkStructureSiteExistsInSite = ({ structureSiteIds, sitesAssetFilterData }) => {
  return structureSiteIds?.some((r) => sitesAssetFilterData?.sitesIds?.includes(r));
};
const checkEquipmentSiteExistsInSite = ({ equipmentSiteIds, sitesAssetFilterData }) => {
  return equipmentSiteIds?.some((r) => sitesAssetFilterData?.sitesIds?.includes(r));
};
