import React, { memo, forwardRef } from 'react';

import Signature from '@components/form-fields/signature/signature';

const FormSignature = (
  {
    errorsMsg,
    removeSpecialCharactersForName,
    field,
    control,
    name,
    watch,
    getValues,
    setValue,
    count,
    label,
    grid2
  },
  ref
) => {
  const { refs } = ref;

  let errorMessage = errorsMsg?.signaturePhoto?.message || '';
  const defaultValue = getValues(`${name}.signaturePhoto`) || null;
  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
      style={{
        marginBottom: '16px'
      }}
    >
      <Signature
        item={{
          label: label ? label : `${count}. ${field?.label}`,
          name,
          required: field?.isRequired,
          defaultValue
        }}
        control={control}
        watch={watch}
        error={errorMessage ? 'Required' : ''}
        setValue={setValue}
        grid2={grid2}
      />
    </div>
  );
};

export default memo(forwardRef(FormSignature));
