/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Button from '@components/button/button.component';
import Textarea from '@components/textarea/textarea.component';
import InputField from '@components/input-field';

import Icon from '@components/icon-component';

import EmailGuy from '@assets/images/email-guy-quality.jpg';
import SupportService from '@services/support.service';

import './Help.scss';

const ContactSupport = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const { handleSubmit } = useForm({
    resolver: yupResolver(supportSchema)
  });

  const onClick = async () => {
    if (message === '') {
      setError('Required');
    }
    if (message) {
      setError('');
      const response = await SupportService.support({ phoneNumber: phone, message: message });
      setSuccess(true);
    }
  };

  return (
    <>
      {!success ? (
        <form onSubmit={handleSubmit(onClick)} style={{ maxWidth: '768px', width: '100%' }}>
          <div className="whats-oplii-mobile">
            <div className="whats-oplii-mobile--title">Get in touch</div>
            <div className="whats-oplii-mobile--description">
              Fill out the form below and we’ll get back to you soon.
            </div>
            <div className="whats-oplii-mobile--description">
              If you prefer a call back, please provide your phone number.
            </div>
          </div>
          <div className="contact-support">
            <div style={{ marginBottom: '16px' }}>
              <Textarea
                onChange={(e) => setMessage(e.target.value)}
                label="Message"
                value={message}
                error={error != '' ? error : ''}
                name="message"
                placeholder={'What seems to be the problem?'}
              />
            </div>

            <InputField
              label={'Phone'}
              name="phone"
              value={phone}
              onChange={(e) => {
                const { value } = e.target;

                setPhone(value);
              }}
            />
            <div style={{ marginTop: '20px', width: '100%' }}>
              <Button type="submit" text={'Send'} btnClass="btnBtn" />
            </div>
            <div className="links">
              <Link
                to={''}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = 'mailto:support@oplii.com';
                }}
              >
                <Icon name={'mail'} />
                support@oplii.com
              </Link>
              <Link
                to={''}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = 'tel:1-888-994-9337';
                }}
              >
                <Icon name={'mobile'} />
                1-888-994-9337
              </Link>
            </div>
          </div>
        </form>
      ) : (
        <div style={{ maxWidth: '768px', width: '100%' }}>
          <div className="thank-you">
            <div className="thank-you--img">
              <img src={EmailGuy} alt="email-guy" />
            </div>
            <div className="thank-you--text">
              <span>Thanks for your message.</span>
              <span>You will hear from us very soon.</span>
            </div>

            <Button
              text={'Back to home'}
              onClick={() => navigate('/dashboard')}
              btnClass="backBtnClass"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ContactSupport;

const supportSchema = yup.object().shape({
  message: yup.mixed().nullable(),
  phone: yup.string().nullable()
});
