import TaskDetailComponent from '@components/task-detail-component/index';
import Icon from '@components/icon-component';

import { PERMISSIONS } from 'permissions/constants';
import { decodeToken } from '@utilities/authentication';

const EquipmentTask = ({
  setAllTask,
  setWorkOrder,
  setInspections,
  setChecks,
  allWorkOrderForSite,
  allDoneWorkOrder,
  allInspectionForSite,
  allDoneInspection,
  allTasksForSite,
  allDoneTasks,
  isOverDue,
  isCheckOverDue,
  isInspectionOverDue,
  isWorkOrderOverDue,
  allCheckForSite,
  allDoneChecks
}) => {
  const taskDetail = [
    {
      img: <Icon name={'task-grey'} />,
      taskName: 'All tasks',
      btnText: `${allDoneTasks.length}/${allTasksForSite.length}`,
      click: () => setAllTask(true),
      allDoneTasks: allDoneTasks,
      allTasksForSite: allTasksForSite,
      isOverDue: isOverDue,
      dot: !!isOverDue && allDoneTasks?.length !== allTasksForSite?.length
    },
    {
      img: <Icon name={'ins'} />,
      taskName: 'Inspections',
      btnText: `${allDoneInspection.length}/${allInspectionForSite.length}`,
      click: () => setInspections(true),
      allDoneTasks: allDoneInspection,
      allTasksForSite: allInspectionForSite,
      isOverDue: isInspectionOverDue,
      dot: !!isInspectionOverDue && allDoneInspection?.length !== allInspectionForSite?.length
    },
    {
      img: <Icon name={'work-orders'} />,
      taskName: 'Work orders',
      btnText: `${allDoneWorkOrder?.length}/${allWorkOrderForSite?.length}`,
      click: () => setWorkOrder(true),
      allDoneTasks: allDoneWorkOrder,
      allTasksForSite: allWorkOrderForSite,
      isOverDue: isWorkOrderOverDue,
      dot: !!isWorkOrderOverDue && allDoneWorkOrder?.length !== allWorkOrderForSite?.length
    },
    {
      img: <Icon name={'check-grey'} />,
      taskName: 'Checks',
      btnText: `${allDoneChecks?.length}/${allCheckForSite?.length}`,
      click: () => setChecks(true),
      allDoneTasks: allDoneChecks,
      allTasksForSite: allCheckForSite,
      isOverDue: isCheckOverDue,
      dot: !!isCheckOverDue && allDoneChecks?.length !== allCheckForSite?.length
    }
  ];

  return (
    <div>
      {taskDetail
        ?.filter(({ taskName }) => {
          const permissions = decodeToken()?.permissions || {};
          if (taskName === 'Checks' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS])
            return false;
          if (taskName === 'Inspections' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS])
            return false;
          if (taskName === 'Work orders' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS])
            return false;
          return true;
        })
        .filter((a) => a.allTasksForSite?.length > 0)
        .map((ele, index) => {
          return ele.taskName === 'All Tasks' &&
            taskDetail?.filter((a) => a.allTasksForSite.length > 0).length === 2 ? (
            <></>
          ) : (
            <TaskDetailComponent
              key={index}
              img={ele.img}
              task={ele.taskName}
              btnText={`${ele.btnText}`}
              onClick={ele.click}
              dot={ele.dot}
              isOverDue={ele.isOverDue}
              allDoneTasks={ele.allDoneTasks}
              allTasksForSite={ele.allTasksForSite}
            />
          );
        })}
    </div>
  );
};

export default EquipmentTask;
