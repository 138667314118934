import React from 'react';

import style from './footer.module.scss';

const CardFooter = ({ footerData, newText, footerColor, syncDateCustom }) => {
  return (
    <div
      className={style.footerDiv}
      style={{
        backgroundColor: footerColor
      }}
    >
      <div>
        {footerData?.map((ele, index) => (
          <p key={index}>{ele}</p>
        ))}
      </div>
      {newText && <p className={style.new}>{newText}</p>}
      {syncDateCustom && <p className={style.new}>New</p>}
    </div>
  );
};

export default CardFooter;
