/* eslint-disable no-unused-vars */
import { store } from 'redux/store';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import Promisable from './promisable.service';
import http from './http.service';
import WorkOrderService from './workorder.service';
import InspectionService from './inspection.service';
import ChecksService from './checks.service';
import TasksService from './tasks.service';

import { tasksListsActions } from '@redux/slices/tasks';
import { getAppDispatch } from '@utilities/dispatch.util';
import checkTokenIsGoingToExpire from '@utilities/token-expiry-check.util';
import { cacheActions } from '@redux/slices/cache';
import { prepareInspectionForBackend } from 'transformers/inspection';
import AuthService from './auth.service';
import { prepareHSEEventsForBackend } from 'transformers/hse-event';
import { prepareWorkOrderForBackend } from 'transformers/work-order';
import HSEEventService from './hse-event.service';
import {
  getPhotosAndDocumentsDataFromSelectedInspection,
  addSignatureId,
  setSyncFailedAssets,
  removeSuccessfullySyncedRetryAsset
} from './assets.service';
import MobileSyncCountService from './mobile-sync-count.service';
import { prepareCheckForBackend } from 'transformers/check';
import {
  deleteAssetInIndexDB,
  getAssetDataBase64InIndexDB
} from '@utilities/photos-documents.util';

let syncPhotos = 0,
  syncDocument = 0,
  workOrderNumber = 0,
  workRequestNumber = 0,
  inspectionNumber = 0,
  hseNumber = 0,
  inspectionPhotoCount = 0,
  workOrderPhotoCount = 0,
  workRequestPhotoCount = 0,
  incidentPhotoCount = 0,
  inspectionDocumentCount = 0,
  workOrderDocumentCount = 0,
  workRequestDocumentCount = 0,
  incidentDocumentCount = 0,
  checkNumber = 0,
  checkPhotoCount = 0,
  checkDocumentCount = 0,
  failedImageCount = 0,
  failedDocumentCount = 0,
  failedImages = [],
  failedDocuments = [];

const SyncingService = {
  inspectionAndWorkOrderService: async ({
    currentTask,
    sync,
    doneNumber,
    pendingSyncLength,
    type,
    singleSync = false,
    syncAssetsList,
    retry = false
  }) => {
    failedImages = [];
    failedDocuments = [];
    const dispatch = getAppDispatch();
    http.setJWT();
    if (doneNumber === 1 || retry) {
      syncPhotos = 0;
      syncDocument = 0;
      workOrderNumber = 0;
      workRequestNumber = 0;
      inspectionNumber = 0;
      hseNumber = 0;
      inspectionPhotoCount = 0;
      workOrderPhotoCount = 0;
      workRequestPhotoCount = 0;
      incidentPhotoCount = 0;
      inspectionDocumentCount = 0;
      workOrderDocumentCount = 0;
      workRequestDocumentCount = 0;
      incidentDocumentCount = 0;
      failedImageCount = 0;
      failedDocumentCount = 0;
      checkNumber = 0;
      checkPhotoCount = 0;
      checkDocumentCount = 0;
      failedImages = [];
      failedDocuments = [];
    }
    const { inspection, auth, cache, tasks, mobileSyncCount } = store.getState();
    const { idToStartSync } = mobileSyncCount;
    const { equipmentsMap, equipments: cacheEquipments = [] } = cache;
    const { syncFailedAssets } = tasks;
    const tokenIsExpire = checkTokenIsGoingToExpire();
    if (tokenIsExpire) {
      await AuthService.login(
        {
          emailAddress: auth?.emailAddress || '',
          password: auth?.password || ''
        },
        false
      );
    }

    const {
      failureCriteria,
      inspectionFormsUserCache,
      passFailList,
      passDeficiencyFail,
      inspectionTypesMap
    } = inspection;
    const { formData, psvs = [] } = addSignatureId(currentTask?.formData || {});
    const { photos, documents, passFailTrue, passFailNA, passFailFalse, inspectionResultAnswer } =
      getPhotosAndDocumentsDataFromSelectedInspection(formData || {});
    currentTask =
      !currentTask?.inspectableItemId && currentTask?.equipmentId
        ? {
            ...currentTask,
            siteId: cacheEquipments[equipmentsMap[currentTask?.equipmentId]]?.siteId || null
          }
        : currentTask;

    if (type === 'Inspection')
      await syncInspection({
        photos,
        documents,
        currentTask,
        formData,
        inspectionFormsUserCache,
        pendingSyncLength,
        doneNumber,
        sync,
        dispatch,
        singleSync,
        failureCriteria,
        passFailTrue,
        passFailNA,
        passFailFalse,
        inspectionResultAnswer,
        passFailList,
        passDeficiencyFail,
        psvs,
        idToStartSync
      });
    else if (type === 'Work Order')
      await syncWorkOrders({
        photos,
        documents,
        currentTask,
        inspectionFormsUserCache,
        pendingSyncLength,
        doneNumber,
        sync,
        dispatch,
        singleSync,
        idToStartSync
      });
    else if (type === 'HSE')
      await syncHseEvents({
        photos,
        documents,
        currentTask,
        formData,
        inspectionFormsUserCache,
        incidentCategoryMap: inspection?.incidentCategoryMap,
        pendingSyncLength,
        doneNumber,
        sync,
        dispatch,
        inspectionTypesMap,
        idToStartSync
      });
    else if (type === 'Check') {
      await syncCheckEvent({
        photos,
        documents,
        currentTask,
        formData,
        inspectionFormsUserCache,
        incidentCategoryMap: inspection?.incidentCategoryMap,
        pendingSyncLength,
        doneNumber,
        sync,
        dispatch,
        inspectionTypesMap,
        idToStartSync
      });
    } else if (type == 'syncFailedAsset') {
      await syncFailedPhotosAndDocuments({
        syncFailedAssets: syncAssetsList,
        dispatch,
        latestSyncFailedAssets: syncFailedAssets
      });
    }

    if (type !== 'syncFailedAsset')
      setSyncFailedAssets({
        syncFailedAssets,
        dispatch,
        photo: failedImages,
        document: failedDocuments
      });
  }
};

export default SyncingService;

const syncHseEvents = async ({
  photos,
  documents,
  currentTask,
  formData,
  inspectionFormsUserCache,
  incidentCategoryMap,
  pendingSyncLength,
  doneNumber,
  sync,
  dispatch,
  inspectionTypesMap,
  idToStartSync
}) => {
  const inspectionAllPhotos = photos?.filter((d) => d?.content) || [];
  const inspectionAllDocuments = documents?.filter((d) => d?.content) || [];
  const transformData = prepareHSEEventsForBackend({
    data: currentTask,
    formData,
    inspectionFormsUserCache,
    incidentCategoryMap,
    inspectionTypesMap
  });
  // console.log({ transformData, inspectionAllPhotos, inspectionAllDocuments });
  const [response] = await Promisable.asPromise(
    http.post(`/Incidents/SubmitIncident`, {
      ...transformData
    })
  );
  const { data = {} } = response;
  if (!_.isEmpty(data)) {
    const id = data;
    ++hseNumber;
    incrementSyncTasksRecordsAndAssets({ dispatch });
    for (let photo of inspectionAllPhotos) {
      const photoBase64 = await getAssetDataBase64InIndexDB({ assetId: photo?.content?.id });

      const photoBody = {
        ...photo,
        content: photoBase64,
        fileName: `${uuidv4()}-${photo?.fileName}`,
        objectId: id,
        childId: id,
        documentType: 'Incident'
      };

      try {
        await http.post(`/Photos`, photoBody, {
          timeout: 60000
        });
        ++syncPhotos;
        ++incidentPhotoCount;
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: photo?.content?.id });
      } catch (error) {
        failedImages = [
          ...failedImages,
          {
            ...photoBody,
            content: { id: photo?.content?.id },
            idToStartSync,
            previousCount: incidentPhotoCount
          }
        ];
        console.error('something wrong to send data');
      }
    }

    for (let document of inspectionAllDocuments) {
      const photoBase64 = await getAssetDataBase64InIndexDB({ assetId: document?.content?.id });

      const documentBody = {
        ...document,
        content: photoBase64,
        fileName: `${uuidv4()}-${document?.fileName}`,
        objectId: id,
        childId: id,
        documentType: 'Incident'
      };
      try {
        await http.post(`/Documents`, documentBody, {
          timeout: 60000
        });
        ++syncDocument;
        ++incidentDocumentCount;
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: document?.content?.id });
      } catch (error) {
        failedDocuments = [
          ...failedDocuments,
          {
            ...documentBody,
            content: { id: document?.content?.id },
            id: uuidv4(),
            idToStartSync,
            previousCount: incidentDocumentCount
          }
        ];
        console.error('something wrong to send data');
      }
    }
    await HSEEventService.deleteNewHSEEvent(id);
    dispatch?.(cacheActions.setPendingSync(pendingSyncLength - doneNumber));
    if (sync) await HSEEventService.getAllHseEvents({ sync, initialLogin: false });
  }

  return true;
};

const syncWorkOrders = async ({
  photos,
  documents,
  currentTask,
  inspectionFormsUserCache,
  pendingSyncLength,
  doneNumber,
  sync,
  dispatch,
  singleSync,
  idToStartSync
}) => {
  const workOrderOrRequestPhotos =
    [...currentTask.photos, ...photos]?.filter((d) => d?.content) || [];
  const workOrderOrPhotosDocuments =
    [...currentTask.documents, ...documents]?.filter((d) => d?.content) || [];
  const woId = currentTask.workorderId || uuidv4();
  const workRequestOrderOrder = prepareWorkOrderForBackend(currentTask, inspectionFormsUserCache);
  // console.log(
  //   {
  //     ...workRequestOrderOrder,
  //     woId
  //   },
  //   { workOrderOrRequestPhotos, workOrderOrPhotosDocuments }
  // );

  const [response] = await Promisable.asPromise(
    http.post(`/WorkOrderOrRequest`, {
      ...workRequestOrderOrder,
      woId
    })
  );
  const { data = {} } = response;
  if (!_.isEmpty(data)) {
    const id = data;
    currentTask.entity !== 'WorkRequest' && ++workOrderNumber;
    currentTask.entity === 'WorkRequest' && ++workRequestNumber;
    incrementSyncTasksRecordsAndAssets({ dispatch });
    for (let photo of workOrderOrRequestPhotos) {
      const photoBase64 = await getAssetDataBase64InIndexDB({ assetId: photo?.content?.id });

      const photoBody = {
        ...photo,
        content: photoBase64,
        fileName: `${uuidv4()}-${photo?.fileName}`,
        objectId: id,
        childId: id
      };

      try {
        await http.post(`/Photos`, photoBody, {
          timeout: 60000
        });
        currentTask.entity === 'WorkRequest' ? workRequestPhotoCount++ : workOrderPhotoCount++;
        ++syncPhotos;
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: photo?.content?.id });
        // throw new Error('fd');
      } catch (error) {
        failedImages = [
          ...failedImages,
          {
            ...photoBody,
            content: { id: photo?.content?.id },
            idToStartSync,
            previousCount:
              currentTask.entity === 'WorkRequest' ? workRequestPhotoCount : workOrderPhotoCount
          }
        ];
        console.error('something wrong to send data');
      }
    }

    for (let document of workOrderOrPhotosDocuments) {
      const photoBase64 = await getAssetDataBase64InIndexDB({ assetId: document?.content?.id });

      const documentBody = {
        ...document,
        fileName: `${uuidv4()}-${document?.fileName}`,
        objectId: id,
        childId: id,
        content: photoBase64
      };

      try {
        await http.post(`/Documents`, documentBody, {
          timeout: 60000
        });
        syncDocument++;
        currentTask.entity === 'WorkRequest'
          ? workRequestDocumentCount++
          : workOrderDocumentCount++;
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: document?.content?.id });
      } catch (error) {
        failedDocuments = [
          ...failedDocuments,
          {
            ...documentBody,
            id: uuidv4(),
            content: { id: document?.content?.id },
            idToStartSync,
            previousCount:
              currentTask.entity === 'WorkRequest'
                ? workRequestDocumentCount
                : workOrderDocumentCount
          }
        ];
        console.error('something wrong to send data');
      }
    }

    await WorkOrderService.deleteWorkOrderAndRequest(id);
    dispatch?.(cacheActions.setPendingSync(pendingSyncLength - doneNumber));

    if (sync) {
      await TasksService.getAllTasksList(sync, false, singleSync);
    }
  }

  return true;
};

const syncInspection = async ({
  photos,
  documents,
  currentTask,
  formData,
  inspectionFormsUserCache,
  pendingSyncLength,
  doneNumber,
  sync,
  dispatch,
  singleSync,
  failureCriteria,
  passFailTrue,
  passFailNA,
  passFailFalse,
  inspectionResultAnswer,
  passFailList,
  passDeficiencyFail,
  psvs,
  idToStartSync
}) => {
  const inspectionAllPhotos = photos?.filter((d) => d?.content) || [];
  const inspectionAllDocuments = documents?.filter((d) => d?.content) || [];
  const transformData = prepareInspectionForBackend(
    currentTask,
    failureCriteria,
    inspectionFormsUserCache,
    passFailTrue,
    passFailNA,
    passFailFalse,
    inspectionResultAnswer,
    passFailList,
    passDeficiencyFail,
    formData,
    psvs
  );
  // console.log({ transformData, inspectionAllPhotos, inspectionAllDocuments });
  const [response] = await Promisable.asPromise(
    http.post(`/DetailInspection`, {
      ...transformData
    })
  );

  const { data = {} } = response;
  if (!_.isEmpty(data)) {
    const id = data;
    ++inspectionNumber;

    incrementSyncTasksRecordsAndAssets({ dispatch });

    for (let photo of inspectionAllPhotos) {
      const photoBase64 = await getAssetDataBase64InIndexDB({ assetId: photo?.content?.id });
      const photoBody = {
        ...photo,
        content: photoBase64,
        fileName: `${uuidv4()}-${photo?.fileName}`,
        objectId: id,
        childId: id,
        documentType: 'Inspection'
      };

      try {
        await http.post(`/Photos`, photoBody, {
          timeout: 60000
        });
        syncPhotos++;
        inspectionPhotoCount++;
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: photo?.content?.id });
        // throw new Error('sd');
      } catch (error) {
        failedImages = [
          ...failedImages,
          {
            ...photoBody,
            content: { id: photo?.content?.id },
            idToStartSync,
            previousCount: inspectionPhotoCount
          }
        ];
        console.error('something wrong to send data');
      }
    }

    for (let document of inspectionAllDocuments) {
      const photoBase64 = await getAssetDataBase64InIndexDB({ assetId: document?.content?.id });

      const documentBody = {
        ...document,
        content: photoBase64,
        fileName: `${uuidv4()}-${document?.fileName}`,
        objectId: id,
        childId: id,
        documentType: 'Inspection'
      };
      try {
        await http.post(`/Documents`, documentBody, {
          timeout: 60000
        });
        syncDocument++;
        inspectionDocumentCount++;
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: document?.content?.id });
      } catch (error) {
        failedDocuments = [
          ...failedDocuments,
          {
            ...documentBody,
            content: { id: document?.content?.id },
            id: uuidv4(),
            idToStartSync,
            previousCount: inspectionDocumentCount
          }
        ];
        console.error('something wrong to send data');
      }
    }

    await InspectionService.deleteNewInspection(id);
    dispatch?.(cacheActions.setPendingSync(pendingSyncLength - doneNumber));
    if (sync) {
      await InspectionService.getAllInspectionList(sync, false, singleSync);
    }
  }
  return true;
};

const syncCheckEvent = async ({
  photos,
  documents,
  currentTask,
  formData,
  inspectionFormsUserCache,
  pendingSyncLength,
  doneNumber,
  sync,
  dispatch,
  singleSync,
  idToStartSync
}) => {
  // Error
  const inspectionAllPhotos = photos?.filter((d) => d?.content) || [];
  const inspectionAllDocuments = documents?.filter((d) => d?.content) || [];
  const transformData = prepareCheckForBackend(currentTask, inspectionFormsUserCache, formData);
  const [response] = await Promisable.asPromise(
    http.post(`/AssetChecks`, {
      ...transformData
    })
  );

  const { data = {} } = response;
  if (!_.isEmpty(data)) {
    const id = data;
    ++checkNumber;

    incrementSyncTasksRecordsAndAssets({ dispatch });

    for (let photo of inspectionAllPhotos) {
      const photoBase64 = await getAssetDataBase64InIndexDB({ assetId: photo?.content?.id });

      const photoBody = {
        ...photo,
        fileName: `${uuidv4()}-${photo?.fileName}`,
        objectId: id,
        childId: id,
        documentType: 'AssetCheck',
        content: photoBase64
      };

      try {
        await http.post(`/Photos`, photoBody, {
          timeout: 60000
        });
        syncPhotos++;
        checkPhotoCount++;
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: photo?.content?.id });

        // throw new Error('sd');
      } catch (error) {
        failedImages = [
          ...failedImages,
          {
            ...photoBody,
            content: { id: photo?.content?.id },
            idToStartSync,
            previousCount: checkPhotoCount
          }
        ];
        console.error('something wrong to send data');
      }
    }

    for (let document of inspectionAllDocuments) {
      const photoBase64 = await getAssetDataBase64InIndexDB({ assetId: document?.content?.id });

      const documentBody = {
        ...document,
        content: photoBase64,
        fileName: `${uuidv4()}-${document?.fileName}`,
        objectId: id,
        childId: id,
        documentType: 'AssetCheck'
      };
      try {
        await http.post(`/Documents`, documentBody, {
          timeout: 60000
        });
        syncDocument++;
        checkDocumentCount++;
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: document?.content?.id });
      } catch (error) {
        failedDocuments = [
          ...failedDocuments,
          {
            ...documentBody,
            content: { id: document?.content?.id },
            id: uuidv4(),
            idToStartSync,
            previousCount: checkDocumentCount
          }
        ];
        console.error('something wrong to send data');
      }
    }

    await ChecksService.deleteNewCheck({ checkId: id });
    dispatch?.(cacheActions.setPendingSync(pendingSyncLength - doneNumber));
    if (sync) {
      await ChecksService.getAllChecksEvents(sync, false, singleSync);
    }
  }
  return true;
};

const syncFailedPhotosAndDocuments = async ({
  syncFailedAssets,
  dispatch,
  latestSyncFailedAssets
}) => {
  const photoIds = [],
    documentIds = [];
  const photos = syncFailedAssets?.photos || [];
  const documents = syncFailedAssets?.documents?.filter((x) => x?.old) || [];

  let groupPhotosByMobileSyncCountId = photos?.reduce((acc, curr) => {
    acc[curr.idToStartSync] = acc[curr.idToStartSync]
      ? [...acc[curr.idToStartSync], { ...curr }]
      : [{ ...curr }];
    return acc;
  }, {});

  let groupDocumentByMobileSyncCountId = documents?.reduce((acc, curr) => {
    acc[curr.idToStartSync] = acc[curr.idToStartSync]
      ? [...acc[curr.idToStartSync], { ...curr }]
      : [{ ...curr }];
    return acc;
  }, {});

  for (let uniqueIdOfMobileSync of Object.keys(groupPhotosByMobileSyncCountId)) {
    let inspectionPhotoRetryCount = 0,
      workOrderPhotoRetryCount = 0,
      workRequestPhotoRetryCount = 0,
      incidentPhotoRetryCount = 0,
      inspectionPhotoPreviousCount = 0,
      workOrderPhotoPreviousCount = 0,
      workRequestPhotoPreviousCount = 0,
      incidentPhotoPreviousCount = 0;
    for (let photo of groupPhotosByMobileSyncCountId[uniqueIdOfMobileSync]) {
      const photoBase64 = await getAssetDataBase64InIndexDB({
        assetId: photo?.content?.id
      });

      try {
        const {
          old: removeOldField,
          previousCount: removeObj1,
          idToStartSync: removeObj2,
          ...body
        } = photo;

        await http.post(
          `/Photos`,
          {
            ...body,
            content: photoBase64
          },
          {
            timeout: 60000
          }
        );
        failedImageCount++;
        photoIds.push(photo.id);
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: photo?.content?.id });

        if (body?.documentType === 'Inspection') {
          ++inspectionPhotoRetryCount;
          inspectionPhotoPreviousCount = photo?.previousCount;
        }
        if (body?.documentType === 'Incident') {
          ++incidentPhotoRetryCount;
          incidentPhotoPreviousCount = photo?.previousCount;
        }
        if (body?.documentType === 'WorkOrder') {
          ++workOrderPhotoRetryCount;
          workOrderPhotoPreviousCount = photo?.previousCount;
        }
        if (body?.documentType === 'WorkRequest') {
          ++workRequestPhotoRetryCount;
          workRequestPhotoPreviousCount = photo?.previousCount;
        }
      } catch (error) {
        const _photos = [...syncFailedAssets.photos];
        const findIndex = _photos.findIndex((photoMap) => photoMap.id === photo.id);
        if (findIndex > -1) {
          _photos[findIndex] = {
            ..._photos[findIndex],
            previousCount:
              photo?.documentType === 'Inspection'
                ? inspectionPhotoRetryCount + inspectionPhotoPreviousCount
                : photo?.documentType === 'Incident'
                ? incidentPhotoRetryCount + incidentPhotoPreviousCount
                : photo?.documentType === 'WorkOrder'
                ? workOrderPhotoRetryCount + workOrderPhotoPreviousCount
                : photo?.documentType === 'WorkRequest'
                ? workRequestPhotoRetryCount + workRequestPhotoPreviousCount
                : 0
          };
          console.log({ _photos });
          dispatch?.(
            tasksListsActions.setSyncFailedAssets({
              photos: _photos,
              documents: documents
            })
          );
        }
        console.error('something wrong to send data');
      }
    }

    const transformData =
      groupPhotosByMobileSyncCountId[uniqueIdOfMobileSync]?.length > 0
        ? {
            ...(inspectionPhotoRetryCount && {
              completedInspectionPhotoCount: {
                op: 'Replace',
                value: inspectionPhotoRetryCount + inspectionPhotoPreviousCount
              }
            }),
            ...(workOrderPhotoRetryCount && {
              completedWorkOrderPhotoCount: {
                op: 'Replace',
                value: workOrderPhotoRetryCount + workOrderPhotoPreviousCount
              }
            }),
            ...(workRequestPhotoRetryCount && {
              completedWorkRequestPhotoCount: {
                op: 'Replace',
                value: workRequestPhotoRetryCount + workRequestPhotoPreviousCount
              }
            }),
            ...(incidentPhotoRetryCount && {
              completedIncidentPhotoCount: {
                op: 'Replace',
                value: incidentPhotoRetryCount + incidentPhotoPreviousCount
              }
            })
          }
        : null;

    if (transformData)
      await MobileSyncCountService.updateRequestToServerAfterSync({
        data: {
          ...transformData
        },
        idToStartSync: uniqueIdOfMobileSync,
        removeSyncId: false
      });
  }

  for (let uniqueIdOfMobileSync of Object.keys(groupDocumentByMobileSyncCountId)) {
    let inspectionDocumentRetryCount = 0,
      workOrderDocumentRetryCount = 0,
      workRequestDocumentRetryCount = 0,
      incidentDocumentRetryCount = 0,
      inspectionDocumentPreviousCount = 0,
      workOrderDocumentPreviousCount = 0,
      workRequestDocumentPreviousCount = 0,
      incidentDocumentPreviousCount = 0;

    for (let document of groupDocumentByMobileSyncCountId[uniqueIdOfMobileSync]) {
      try {
        const {
          id: removeObj,
          old: removeOldField,
          previousCount: removeObj1,
          idToStartSync: removeObj2,
          ...body
        } = document;

        await http.post(`/Documents`, {
          ...body
        });
        failedDocumentCount++;
        documentIds.push(document.id);
        incrementSyncTasksRecordsAndAssets({ dispatch });
        await deleteAssetInIndexDB({ assetId: document?.content?.id });

        if (body?.documentType === 'Inspection') {
          ++inspectionDocumentRetryCount;
          inspectionDocumentPreviousCount = document?.previousCount;
        }
        if (body?.documentType === 'Incident') {
          ++incidentDocumentRetryCount;
          incidentDocumentPreviousCount = document?.previousCount;
        }
        if (body?.documentType === 'WorkOrder') {
          ++workOrderDocumentRetryCount;
          workOrderDocumentPreviousCount = document?.previousCount;
        }
        if (body?.documentType === 'WorkRequest') {
          ++workRequestDocumentRetryCount;
          workRequestDocumentPreviousCount = document?.previousCount;
        }
      } catch (error) {
        const _documents = [...syncFailedAssets.documents];
        const findIndex = _documents.findIndex((documentMap) => documentMap.id === document.id);
        if (findIndex > -1) {
          _documents[findIndex] = {
            ..._documents[findIndex],
            previousCount:
              document?.documentType === 'Inspection'
                ? inspectionDocumentRetryCount + inspectionDocumentPreviousCount
                : document?.documentType === 'Incident'
                ? incidentDocumentRetryCount + incidentDocumentPreviousCount
                : document?.documentType === 'WorkOrder'
                ? workOrderDocumentRetryCount + workOrderDocumentPreviousCount
                : document?.documentType === 'WorkRequest'
                ? workRequestDocumentRetryCount + workRequestDocumentPreviousCount
                : 0
          };
          dispatch?.(
            tasksListsActions.setSyncFailedAssets({
              photos: photos,
              documents: _documents
            })
          );
        }
        console.error('something wrong to send data');
      }
    }

    const transformData =
      groupDocumentByMobileSyncCountId[uniqueIdOfMobileSync]?.length > 0
        ? {
            ...(inspectionDocumentRetryCount && {
              completedInspectionDocumentCount: {
                op: 'Replace',
                value: inspectionDocumentRetryCount + inspectionDocumentPreviousCount
              }
            }),
            ...(workOrderDocumentRetryCount && {
              completedWorkOrderDocumentCount: {
                op: 'Replace',
                value: workOrderDocumentRetryCount + workOrderDocumentPreviousCount
              }
            }),
            ...(workRequestDocumentRetryCount && {
              completedWorkRequestDocumentCount: {
                op: 'Replace',
                value: workRequestDocumentRetryCount + workRequestDocumentPreviousCount
              }
            }),
            ...(incidentDocumentRetryCount && {
              completedIncidentDocumentCount: {
                op: 'Replace',
                value: incidentDocumentRetryCount + incidentDocumentPreviousCount
              }
            })
          }
        : null;

    if (transformData)
      await MobileSyncCountService.updateRequestToServerAfterSync({
        data: {
          ...transformData
        },
        idToStartSync: uniqueIdOfMobileSync,
        removeSyncId: false
      });
  }

  const { tasks } = store.getState();
  const { syncFailedAssets: updatedSyncFailedAssets } = tasks;
  await removeSuccessfullySyncedRetryAsset({
    documentIds,
    photoIds,
    syncFailedAssets: updatedSyncFailedAssets,
    dispatch
  });
};

const incrementSyncTasksRecordsAndAssets = ({ dispatch }) => {
  let data1 = {
    workOrder: workOrderNumber,
    workRequest: workRequestNumber,
    photos: syncPhotos,
    hseEvents: hseNumber,
    documents: syncDocument,
    inspection: inspectionNumber,
    inspectionPhotoCount,
    workOrderPhotoCount,
    workRequestPhotoCount,
    incidentPhotoCount,
    inspectionDocumentCount,
    workOrderDocumentCount,
    workRequestDocumentCount,
    incidentDocumentCount,
    failedDocumentCount,
    failedImageCount,

    checks: checkNumber,
    checkPhotoCount: checkPhotoCount,
    checkDocumentCount: checkDocumentCount
  };
  dispatch?.(tasksListsActions.setSyncDone(data1));
};
