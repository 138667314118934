/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { forwardRef } from 'react';

import FormWrapperContainer from './form-wrapper-container';

const FormWrapperForm = ({ handleSubmit, onSubmitHandler, isCheckForm = false, ...props }, ref) => {
  const onKeyDown = (e) => e.key === 'Enter' && e.preventDefault();

  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit((data) => onSubmitHandler({ data: data, status: 'Pending sync' }))();
  };

  return !isCheckForm ? (
    <form id="formWrapper" onKeyDown={onKeyDown} onSubmit={onSubmit}>
      <FormWrapperContainer {...props} ref={ref} />
    </form>
  ) : (
    <FormWrapperContainer {...props} ref={ref} isCheckForm={isCheckForm} />
  );
};

export default React.memo(forwardRef(FormWrapperForm, _.isEqual));
