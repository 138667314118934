import React from 'react';
import SettingsValueCard from './settings-value-card';
import DragDropFields from './drag-frop-fields';
const InspectionsDisplaySettings = () => {
  return (
    <div className="settings--values">
      <SettingsValueCard
        title={'Inspection cards'}
        description={'This is how I want inspection data to appear on task cards:'}
      >
        <DragDropFields text={'inspection'} />
      </SettingsValueCard>
    </div>
  );
};

export default InspectionsDisplaySettings;
