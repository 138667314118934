/* eslint-disable no-unused-vars */

import React from 'react';
import { useSelector } from 'react-redux';

import AppBar from '@components/app-bar';
import ParentListing from '@components/parent-listing';

import useWindowDimensions from '@hooks/useWindowDimensions';

const ParentSite = () => {
  const { width } = useWindowDimensions();
  const selectTask = useSelector((state) => state.tasks.selectTask) || false;
  return (
    <div className="page-container">
      {selectTask && width <= 767 ? '' : <AppBar />}
      <div className="md:ml-24">
        <ParentListing Title={'Parent site'} />
      </div>
    </div>
  );
};

export default ParentSite;
