import React, { memo } from 'react';

import CollapseableFilter from './task-status';

const StructureFilterGroups = ({
  structureTypeTags,
  picklistsMap,
  filters,
  updateFilters,
  structureStatusTags
}) => {
  return (
    <>
      <p
        className="label-regular op-filter-label"
        style={{ padding: '29px 0px 32px 0px', marginBottom: '0px' }}
      >
        Structure
      </p>
      <div style={{ marginBottom: '40px' }}>
        {structureTypeTags?.length > 0 && (
          <CollapseableFilter
            title={'Structure type'}
            tags={structureTypeTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters?.structureType?.value}
            onChange={(value) => updateFilters('structureType', 'value', value)}
          />
        )}
      </div>
      <div style={{ marginBottom: '40px' }}>
        {structureStatusTags?.length > 0 && (
          <CollapseableFilter
            title={'Structure status'}
            tags={structureStatusTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters?.structureStatus?.value}
            onChange={(value) => updateFilters('structureStatus', 'value', value)}
          />
        )}
      </div>
    </>
  );
};

export default memo(StructureFilterGroups);
