import _ from 'lodash';

const earthRadius = 6378137;
const toRadius = (value) => (value * Math.PI) / 180;
const distanceConversion = { mi: 1 / 1609.344, km: 0.001 };
const convertDistance = (meters, targetUnit) => distanceConversion[targetUnit] * meters;

export const getDistance = ({ from, to, unit }) => {
  const distance =
    Math.acos(
      Math.sin(toRadius(to.latitude)) * Math.sin(toRadius(from.latitude)) +
        Math.cos(toRadius(to.latitude)) *
          Math.cos(toRadius(from.latitude)) *
          Math.cos(toRadius(from.longitude) - toRadius(to.longitude))
    ) * earthRadius;
  const value1 = convertDistance(distance, unit);
  const value = value1 < 100 ? value1?.toFixed(1) : Math.round(value1);
  return value;
};

const getSiteDistance = ({ latitude, longitude, coordinates, unit }) => {
  let distance = null;
  if (unit && coordinates && latitude !== null && longitude !== null)
    distance = getDistance({ unit, from: coordinates, to: { longitude, latitude } });
  return _.isNaN(distance) ? null : distance;
};

export { getSiteDistance };
