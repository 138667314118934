/* eslint-disable no-unused-vars */

import React, { memo, useCallback, useRef, useEffect } from 'react';
import _ from 'lodash';
import { useLocation, useSearchParams } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useVirtualizer } from '@tanstack/react-virtual';

const VirtualList = ({
  data,
  addMarginToLastItem = false,
  component: Component,
  componentProps,
  itemSize = 30,
  divider = false,
  siteListingPage = false,
  gutter = 5,
  isGrid,
  overscan = 10,
  onScrollEvent = null,
  isHidden = false
}) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const parentRef = useRef();
  const count = data.length;
  const assetDetailsNavigation = document.getElementById('assetDetailsNavigation');
  const virtualListScrollAll = document?.querySelectorAll('.cstmScrollerRef');
  const workOrderAndInspectionsListings = document.querySelector(
    '#workOrderAndInspectionsListings'
  );
  useEffect(() => {
    if (pathname.includes('-details')) {
      // if (assetDetailsNavigation?.classList?.value?.includes('assetDetailNavNotFixed')) {
      virtualListScrollAll.forEach((virtualList) => {
        if (virtualList) {
          virtualList.classList.remove('virtualListOverflowOverlay');
          virtualList.classList.add('virtualListOverflowOverlay');
        }
      });
      // } else if (
      //   workOrderAndInspectionsListings?.classList?.value?.includes(
      //     'assetDetailsWorkOrderAndInspectionsNotFixed'
      //   )
      // ) {
      //   virtualListScrollAll.forEach((virtualList) => {
      //     if (virtualList) {
      //       virtualList.classList.remove('virtualListOverflowOverlay');
      //       virtualList.classList.add('virtualListOverflowOverlay');
      //     }
      //   });
      // }
    }
  }, [assetDetailsNavigation, virtualListScrollAll, pathname]);

  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => itemSize,
    overscan
  });
  const items = virtualizer.getVirtualItems();

  const Row = useCallback(
    ({ virtualRow, data }) => {
      return (
        <div
          key={virtualRow.key}
          data-index={virtualRow.index}
          ref={virtualizer.measureElement}
          className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
          style={{
            marginBottom:
              addMarginToLastItem && virtualRow.index === data?.length - 1 ? '40px' : '0px'
          }}
        >
          <Component
            index={data[virtualRow.index]?.key || virtualRow.key}
            {...data[virtualRow.index]}
            {...componentProps}
          />

          {divider && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <div
                style={{
                  width: '100%',

                  // margin: 10px auto;
                  borderTop: '1px solid rgb(203, 210, 217)'
                }}
              />
            </div>
          )}
        </div>
      );
    },
    [data, virtualizer, virtualizer.measureElement]
  );

  const Column = useCallback(
    ({ virtualRow, data }) => {
      return (
        <div
          key={virtualRow.key}
          data-index={virtualRow.index}
          ref={virtualizer.measureElement}
          // className={virtualRow.index % 2 ? 'ListItemOdd' : 'ListItemEven'}
          style={{
            display: 'grid',
            gridTemplateColumns: `repeat(${isGrid}, 1fr)`,
            // gap: gutter,
            gridColumnGap: gutter,
            gridAutoRows: 'minmax(auto, 1fr)'
          }}
        >
          {Array.from({ length: isGrid }).map((_, i) => {
            const dataIndex = virtualRow.index * isGrid + i;
            return dataIndex < data.length ? (
              <Component key={dataIndex} {...data[dataIndex]} {...componentProps} />
            ) : null;
          })}
        </div>
      );
    },
    [data, virtualizer, virtualizer.measureElement]
  );

  const onWheelEvent = (event) => {
    if (onScrollEvent) {
      if (parseInt(searchParams.get('virtualScrollTab')) === -1) {
        const current = virtualListScrollAll[0];
        if (
          virtualizer.getTotalSize() < current.offsetHeight &&
          workOrderAndInspectionsListings?.classList?.value?.includes(
            'assetDetailsWorkOrderAndInspectionsFixed'
          )
        ) {
          if (event?.deltaY < 0) onScrollEvent();
        }
      } else {
        const currentTab = parseInt(searchParams.get('virtualScrollTab') || 0);
        const current = virtualListScrollAll[currentTab];
        if (
          (virtualizer.getTotalSize() < current.offsetHeight ||
            (virtualizer?.scrollOffset == 0 && !isHidden)) &&
          assetDetailsNavigation?.classList?.value?.includes('assetDetailNavFixed')
        ) {
          if (event?.deltaY < 0) onScrollEvent();
        }
      }
    }
  };

  let startY = 0;
  let endY = 0;

  const handleTouchStart = (event) => {
    startY = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    endY = event.touches[0].clientY;
    const deltaY = endY - startY;

    if (onScrollEvent) {
      if (parseInt(searchParams.get('virtualScrollTab')) === -1) {
        const current = virtualListScrollAll[0];
        if (
          virtualizer.getTotalSize() < current.offsetHeight &&
          workOrderAndInspectionsListings?.classList?.value?.includes(
            'assetDetailsWorkOrderAndInspectionsFixed'
          )
        ) {
          if (deltaY < 0) onScrollEvent();
        }
      } else {
        const currentTab = parseInt(searchParams.get('virtualScrollTab') || 0);
        const current = virtualListScrollAll[currentTab];
        if (
          (virtualizer.getTotalSize() < current.offsetHeight ||
            (virtualizer?.scrollOffset == 0 && !isHidden)) &&
          assetDetailsNavigation?.classList?.value?.includes('assetDetailNavFixed')
        ) {
          if (deltaY > 0) onScrollEvent();
        }
      }
    }
  };

  const handleTouchEnd = () => {
    startY = 0;
    endY = 0;
  };

  const test = document.getElementById('react-swipeable-virtual-list');
  test?.addEventListener('touchstart', handleTouchStart);
  test?.addEventListener('touchmove', handleTouchMove);
  test?.addEventListener('touchend', handleTouchEnd);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <div
          id="react-swipeable-virtual-list"
          ref={parentRef}
          className="List cstmScrollerRef"
          style={{
            height: height,
            width: width,
            ...(!pathname.includes('-details') && { overflow: 'overlay' }),
            contain: 'strict',
            padding: isGrid ? '16px' : ''
          }}
          onScroll={onScrollEvent && onScrollEvent}
          onWheel={onWheelEvent}
        >
          <div
            style={{
              height: virtualizer.getTotalSize(),
              width: '100%',
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                // transform: `translateY(${items[0].start}px)`
                ...(items.length > 0 && { transform: `translateY(${items[0].start}px)` })
              }}
            >
              {items.map((virtualRow) =>
                isGrid ? (
                  <Column virtualRow={virtualRow} key={virtualRow.key} data={data} />
                ) : (
                  <Row virtualRow={virtualRow} key={virtualRow.key} data={data} />
                )
              )}
            </div>
          </div>
        </div>
      )}
    </AutoSizer>
  );
};

export default memo(VirtualList, _.isEqual);
