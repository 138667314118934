/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';

import Icon from '@components/icon-component';
import HighlightText from '@components/highlight-text';

import style from './body.module.scss';

const AssignmentBody = ({
  iconName,
  highlight,
  text,
  collapseableValue,
  assignmentData = [],
  onClick = null
}) => {
  return (
    <div
      className={style.assignmentBody}
      style={{
        alignItems: assignmentData.length > 1 ? 'flex-start' : ''
      }}
    >
      <Icon height={24} width={24} name={iconName} />

      {_.isArray(assignmentData) && _.size(assignmentData) > 0 ? (
        <div>
          {assignmentData?.map((ele, index) => (
            <p
              className={style.titleText}
              key={index}
              onClick={(e) => {
                if (index === 0) {
                  e.stopPropagation();
                  onClick();
                }
              }}
            >
              {ele && <HighlightText text={ele} highlight={highlight} />}
              <span className={style.collapseValue}>
                {index == 0 && <HighlightText text={`(Primary)`} highlight={highlight} />}
              </span>
            </p>
          ))}
        </div>
      ) : (
        <p className={style.titleText}>
          {text && <HighlightText text={text} highlight={highlight} />}
          {collapseableValue && (
            <span className={style.collapseValue}>
              <HighlightText text={`(+${collapseableValue})`} highlight={highlight} />
            </span>
          )}
        </p>
      )}
    </div>
  );
};

export default AssignmentBody;
