/* eslint-disable no-unused-vars */

import React, { memo, useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import Label from '@components/label';
import Badge from '@components/badge/badge';
import Icon from '@components/icon-component';
import Textarea from '@components/textarea/textarea.component';
import Range from '@components/input-range';
import AddPhoto from '@components/add-photo/add-photo';
import PhotoComponent from '@components/photo-component';

import useWindowDimensions from '@hooks/useWindowDimensions';
import updateTasksAssets from '@utilities/update-tasks-assets';
import { isNewForm } from '@hooks/isNewForm';

import style from './slider.module.scss';
import {
  deleteAssetInIndexDB,
  getAssetDataBase64InIndexDB,
  setAssetDataInIndexDB,
  updateAssetFile
} from '@utilities/photos-documents.util';

const Slider = ({
  label,
  option,
  sliderDefaultValue,
  name,
  setValue,
  errors,
  control,
  watch,
  isRequired = false,
  getValues,
  register,
  isSelectBoxOpen
}) => {
  const [isNoteOpen, setNoteOpen] = useState(false);
  const [addPhoto, setAddPhoto] = useState(false);
  const [updatePhoto, setUpdatePhoto] = useState(null);
  const [selectedPhotoId, setSelectedPhotoId] = useState(null);
  const [photosListWithBase64, setPhotosListWithBase64] = useState([]);

  const { photoCategoryData } = useSelector((state) => state?.picklists);
  const { width } = useWindowDimensions();
  const { currentTaskId } = useSelector((state) => state.cache);
  const { newForm: isFormIsNew, workType, orderIds, orderIdsLength } = isNewForm();

  const handleClear = () => {
    setNoteOpen((pre) => !pre);
  };

  useEffect(() => {
    if (getValues(`${name}.notes`)) setNoteOpen(true);
  }, [getValues(`${name}.notes`)]);

  const photoCategoryDataMap = photoCategoryData?.reduce((acc, curr) => {
    acc[curr.key] = curr || '';
    return acc;
  }, {});

  const onSubmitPhoto = async (data, updateId) => {
    const uniqueId = updateId ? updateId : uuidv4();
    !updateId
      ? await setAssetDataInIndexDB({
          assetId: uniqueId,
          assetFile: data.image,
          status: 'temporary'
        })
      : !data?.image?.id
      ? await updateAssetFile({ assetId: updateId, assetFile: data.image })
      : '';

    let modifyData = {
      ...data,
      image: { id: uniqueId, name: data.image?.name, type: data.image?.type }
    };

    if (updateId) {
      const previousPhotosData = getValues(`${name}.photos`) || [];
      const findIndex = previousPhotosData?.findIndex((x) => x.id === updateId);
      previousPhotosData[findIndex] = { ...modifyData, id: updateId };
      setValue(`${name}.photos`, [...previousPhotosData], { shouldDirty: true });
    } else {
      let previousPhotoData = getValues(`${name}.photos`) || [];
      setValue(`${name}.photos`, [...previousPhotoData, { ...modifyData, id: uniqueId }], {
        shouldDirty: true
      });
    }
    setAddPhoto(false);
    setSelectedPhotoId(null);
  };

  const deleteBtnEvent = async (id, imageIdForIndexDB) => {
    return new Promise((resolve, reject) => {
      const handleAsyncFunction = async () => {
        try {
          await deleteAssetInIndexDB({ assetId: imageIdForIndexDB, deleteAll: true });

          if (!isFormIsNew) {
            updateTasksAssets({
              taskId: orderIdsLength > 1 ? currentTaskId : orderIds,
              assetId: id,
              workType,
              photo: true,
              formData: watch(),
              fieldName: name
            });
          }
          const previousPhotosData = getValues(`${name}.photos`) || [];
          const newPhotosList = previousPhotosData?.filter((x) => x.id !== id);
          setValue(`${name}.photos`, newPhotosList, { shouldDirty: true });
          resolve(true);
        } catch (error) {
          reject(error);
        }
      };

      handleAsyncFunction();
    });
  };
  const editBtnEvent = (id) => {
    const copyPhotos = getValues(`${name}.photos`) || [];
    const photo = copyPhotos?.find((p) => p.id === id);
    setSelectedPhotoId(id);
    setUpdatePhoto(photo);
    setAddPhoto(true);
  };

  const showPhotos = watch && watch(`${name}.photos`) && _.size(watch(`${name}.photos`)) > 0;

  useEffect(() => {
    // need loader to load the image
    const getPhotosList = async () => {
      let list = [];
      if (_.size(watch(`${name}.photos`)) > 0) {
        for (let photo of watch(`${name}.photos`)) {
          const base64 = await getAssetDataBase64InIndexDB({ assetId: photo?.image?.id });
          const image = { ...photo.image, base64 };
          list.push({ ...photo, image });
        }
        setPhotosListWithBase64(list);
      }
    };
    getPhotosList();
  }, [watch(`${name}.photos`)]);

  const onChangeRangeValue = (value, assessment) => {
    if (!_.isNull(value) && !_.isNull(assessment)) {
      setValue(`${name}.answer`, value, { shouldDirty: true });
      setValue(`${name}.assessment`, assessment, { shouldDirty: true });
    }
  };

  return (
    <>
      {errors && (
        <div>
          <p className={style.error}>{'Required'}</p>
        </div>
      )}
      <div className={`${style.sliderContainer}  ${errors ? style.requiredBorder : ''}`}>
        <div className={style.sliderSection}>
          <div className={style.labelsAndButtonsSection}>
            <Label
              label={label}
              dot={isRequired}
              className={style.sliderLabel}
              dangerouslySetInnerHTML
            />
            <div className={style.btns}>
              <button
                className="filter-btn"
                type="button"
                onClick={() => setAddPhoto((pre) => !pre)}
              >
                <Badge
                  size="xSmall"
                  count={watch && _.size(watch(`${name}.photos`))}
                  color="#ffffff"
                  textColor={'#F26531'}
                  badgePositionClass={style.badgePositionClass}
                  badgeContentClass={style.badgeContentClass}
                >
                  <Icon name={'cameraOrange'} color={'#ffffff'} width="40px" height="40px" />
                </Badge>
              </button>
              <div
                style={{ backgroundColor: isNoteOpen ? '#2F80ED' : '#F5F7FA' }}
                className={style.iconDiv}
                onClick={handleClear}
              >
                <Icon
                  name={isNoteOpen ? 'file-type-white' : 'file-type-blue'}
                  className={style.icon}
                />
              </div>
            </div>
          </div>

          <Range
            options={option}
            defaultValue={sliderDefaultValue}
            onChangeRangeValue={onChangeRangeValue}
            control={control}
            name={name}
            watch={watch}
            isSelectBoxOpen={isSelectBoxOpen}
          />

          {isNoteOpen && (
            <div className="op-input-field-wrap " style={{ marginTop: '29px' }}>
              <Textarea
                label="notes"
                control={control}
                watch={watch}
                errors={errors}
                name={`${name}.notes`}
                register={register}
              />
            </div>
          )}
          <div className={style.body}>
            {showPhotos && photosListWithBase64?.length > 0 && (
              <>
                <div className={style.photoCard}>
                  <h6>{'photos'}</h6>
                  <div
                    className={style.grid2}
                    style={{
                      margin: '0 auto',
                      marginTop: '0px',
                      display: width >= 768 ? 'flex' : 'block',
                      gap: '20px',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      flexWrap: width >= 768 ? 'wrap' : 'noWrap'
                    }}
                  >
                    {photosListWithBase64?.map((card, index) => {
                      return (
                        <div key={index} style={{ width: width >= 768 ? '48%' : '100%' }}>
                          <PhotoComponent
                            key={index}
                            photoId={card?.id || index}
                            deleteBtnEvent={deleteBtnEvent}
                            editBtnEvent={editBtnEvent}
                            image={card?.image || null}
                            categoryValue={photoCategoryDataMap[card?.category]?.value || ''}
                            captionValue={card?.caption || ''}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {addPhoto && (
        <AddPhoto
          open={addPhoto}
          closed={setAddPhoto}
          title={`${_.isEmpty(selectedPhotoId) ? 'Add' : 'Edit'} photo`}
          onSubmitPhoto={onSubmitPhoto}
          updatePhoto={updatePhoto}
          id={selectedPhotoId}
          deleteBtnEvent={deleteBtnEvent}
          initialValue={
            selectedPhotoId && !_.isEmpty(getValues(`${name}.photos`))
              ? getValues(`${name}.photos`)?.find((x) => x?.id === selectedPhotoId)
              : null
          }
        />
      )}
    </>
  );
};

export default memo(Slider);
