/* eslint-disable no-unused-vars */
import React, { memo, useRef } from 'react';
import { store } from 'redux/store';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';

import Label from '@components/label';
import ErrorLabel from '@components/error-label';
import Icon from '@components/icon-component';

import useOutsideClick from '@hooks/useOutsideAlert';

import style from './hierarchical-dropdown.module.scss';

const HierarchicalDropdown = ({
  options,
  label,
  dot = false,
  error,
  labelClassName,
  reconciled,
  showReconciled,
  defaultValue = null,
  setValue,
  name,
  disabled = false,
  placeholder = '',
  equipmentCategoryTypeSubtype = false
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const bookMarkRef = useRef(null);
  const inputRef = useRef(null);
  const { picklists, inspection } = store.getState();
  const { areaHierarchyTreeDataMap = {} } = picklists;
  const { equipmentCategoryTypeAndSubTypeMap } = inspection;
  const filteredOptions = useMemo(() => {
    if (!searchQuery) return options;
    return filterOptionsBySearch(options, searchQuery);
  }, [options, searchQuery]);

  const onChangeSearch = useCallback((e) => {
    setSearchQuery(e?.target?.value);
  }, []);

  useEffect(() => {
    setSelectedValue(defaultValue);
  }, [defaultValue]);

  useOutsideClick(bookMarkRef, () => {
    if (open) {
      setMenuIsOpen(false);
      setSearchQuery('');
    }
  });

  const getParentKeys = useCallback(
    (data, targetKey, parentKeys = []) => {
      for (const item of data) {
        if (item.key === targetKey) {
          parentKeys.push(item.key);
          break;
          // if (item.parentKey) {
          //   getParentKeys(data, item.parentKey, parentKeys);
          // }
        } else if (item.child && item.child.length > 0) {
          parentKeys.push(item.key);
          getParentKeys(item.child, targetKey, parentKeys);
          if (parentKeys.includes(targetKey)) {
            break;
          } else {
            parentKeys.pop();
          }
        }
      }
      return parentKeys;
    },
    [selectedValue]
  );

  const parentKeys = useMemo(() => {
    return getParentKeys(options, selectedValue);
  }, [selectedValue, options]);

  const onChangeSelectedValue = useCallback((selectedDropdownValue) => {
    setSelectedValue(selectedDropdownValue);
    setValue(name, selectedDropdownValue, { shouldDirty: true });
  }, []);

  useEffect(() => {
    if (menuIsOpen && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [menuIsOpen]);

  useEffect(() => {
    const treeSelectInput = document.getElementById('tree-select-input');

    const handleFocus = () => {
      if (treeSelectInput) treeSelectInput.style.borderColor = '#2f80ed';
    };
    const handleBlur = () => {
      if (treeSelectInput) treeSelectInput.style.borderColor = '#7b8794';
    };

    const inputElement = inputRef.current;

    inputElement.addEventListener('focus', handleFocus);
    inputElement.addEventListener('blur', handleBlur);

    return () => {
      inputElement.removeEventListener('focus', handleFocus);
      inputElement.removeEventListener('blur', handleBlur);
    };
  }, []);

  return (
    <div ref={bookMarkRef}>
      {label ? (
        <Label
          label={label}
          dot={dot}
          error={error}
          className={labelClassName}
          reconciled={reconciled}
          showReconciled={showReconciled}
          inLineStyle={{ paddingLeft: '8px' }}
        />
      ) : error ? (
        <ErrorLabel error={error} className={labelClassName} reconciled={reconciled} />
      ) : (
        <></>
      )}

      <div className={style.treeSelect}>
        <div
          className={`${style.treeSelectInput} ${disabled && style.isDisabled} `}
          id="tree-select-input"
          onClick={() => setMenuIsOpen((prev) => !prev)}
        >
          <div className={`${style.input} ${!selectedValue && placeholder && style.placeholder} `}>
            <input
              type={'text'}
              value={
                !_.isNull(selectedValue)
                  ? areaHierarchyTreeDataMap[selectedValue]?.title ||
                    equipmentCategoryTypeAndSubTypeMap[selectedValue] ||
                    ''
                  : !_.isNull(searchQuery)
                  ? searchQuery
                  : ''
              }
              placeholder={placeholder || ''}
              onChange={onChangeSearch}
              ref={inputRef}
            />
          </div>

          <div className={style.operators}>
            <div className={style.delIcon}>
              {selectedValue && (
                <Icon
                  name={'xdefault'}
                  width={'18px'}
                  height={'18px'}
                  onClick={() => onChangeSelectedValue(null)}
                />
              )}
            </div>
            <div className={style.expandIcon}>
              <Icon
                name={`${!menuIsOpen ? 'chevron-down' : 'chevron-down-blue'}`}
                width={'24px'}
                height={'24px'}
                className={menuIsOpen ? style.rotate180 : ''}
              />
            </div>
          </div>
        </div>

        {menuIsOpen && (
          <div className={style.treeSelectList}>
            {filteredOptions?.map((option) => {
              return (
                <DropdownOption
                  key={option.key}
                  option={option}
                  level={0}
                  selectedValue={selectedValue}
                  parentKeys={parentKeys}
                  setSelectedValue={onChangeSelectedValue}
                  setMenuIsOpen={setMenuIsOpen}
                  searchQuery={searchQuery}
                  equipmentCategoryTypeSubtype={equipmentCategoryTypeSubtype}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const DropdownOption = ({
  option,
  level,
  selectedValue = null,
  parentKeys,
  setSelectedValue,
  setMenuIsOpen,
  searchQuery,
  equipmentCategoryTypeSubtype
}) => {
  const indentStyle = {
    paddingLeft: `${level * 20}px`
  };
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (parentKeys?.includes(option.key) && option?.child?.length > 0) {
      setOpen(true);
    }
  }, [parentKeys]);

  const isOptionMatchingSearch = useMemo(() => {
    return option?.value?.toLowerCase()?.includes(searchQuery?.toLowerCase());
  }, [option.value, searchQuery]);

  useEffect(() => {
    if (searchQuery && option?.child?.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isOptionMatchingSearch]);

  useEffect(() => {
    if (selectedValue && parentKeys && parentKeys.includes(option.key)) setOpen(true);
  }, [selectedValue, parentKeys, option]);

  const background = equipmentCategoryTypeSubtype
    ? parentKeys?.includes(option.key)
      ? '#2f80ed'
      : '#fff'
    : parentKeys?.includes(option.key) && option?.child?.length == 0
    ? '#2f80ed'
    : '#fff';

  const color = equipmentCategoryTypeSubtype
    ? parentKeys?.includes(option.key)
      ? 'white'
      : ''
    : parentKeys?.includes(option.key) && option?.child?.length == 0
    ? 'white'
    : '';

  return (
    <div className={style.option} style={indentStyle}>
      <div onClick={() => setOpen((prev) => !prev)}>
        <span
          className={style.singleItem}
          onClick={() => {
            if (equipmentCategoryTypeSubtype && level >= 1) {
              setMenuIsOpen(false);
              setSelectedValue(option?.key);
            } else {
              if (option?.child?.length == 0) {
                setMenuIsOpen(false);
                setSelectedValue(option?.key);
              }
            }
          }}
          style={{
            background,
            color
          }}
        >
          {option?.child?.length > 0 ? (
            <Icon
              name={`chevron-down`}
              className={open ? style.openSingleItem : style.closeSingleItem}
              onClick={(e) => {
                if (equipmentCategoryTypeSubtype) {
                  e.stopPropagation();
                  setOpen((prev) => !prev);
                }
              }}
            />
          ) : (
            <div style={{ marginRight: '24px' }}></div>
          )}
          {isOptionMatchingSearch && searchQuery ? <strong>{option.value}</strong> : option.value}
        </span>
      </div>
      {open &&
        option?.child?.map((childOption) => (
          <DropdownOption
            key={childOption.key}
            option={childOption}
            level={level + 1}
            parentKeys={parentKeys}
            setSelectedValue={setSelectedValue}
            setMenuIsOpen={setMenuIsOpen}
            searchQuery={searchQuery}
            equipmentCategoryTypeSubtype={equipmentCategoryTypeSubtype}
            selectedValue={selectedValue}
          />
        ))}
    </div>
  );
};

const filterOptionsBySearch = (options, searchQuery) => {
  return options.reduce((filtered, option) => {
    const matchingChildOptions = filterOptionsBySearch(option.child, searchQuery);
    if (
      option.value.toLowerCase().includes(searchQuery.toLowerCase()) ||
      matchingChildOptions.length > 0
    ) {
      filtered.push({
        ...option,
        child: matchingChildOptions
      });
    }
    return filtered;
  }, []);
};

const listType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  sortOrder: PropTypes.number.isRequired || PropTypes.string.isRequired
});

const areaPropType = PropTypes.shape({
  ...listType.prototype,
  child: PropTypes.arrayOf(
    PropTypes.shape({
      ...listType.prototype,
      child: PropTypes.array
    })
  )
});

areaPropType.propTypes = {
  child: PropTypes.arrayOf(
    PropTypes.shape({
      ...areaPropType.propTypes
    })
  )
};

HierarchicalDropdown.protoTypes = {
  options: PropTypes.arrayOf(areaPropType).isRequired
};
export default memo(HierarchicalDropdown);
