/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';

import Icon from '@components/icon-component';

import style from './filter-header.module.scss';

const FilterHeader = ({ title, filterCount, resetBtnEvent, leftBtnEvent }) => {
  return (
    <div className={style.header}>
      <div className={style.flex}>
        <div className={style.iconClass}>
          <Icon name={filterCount > 0 ? 'blue-filter' : 'filter-black'} />
          {filterCount > 0 && <span>{filterCount}</span>}
        </div>
        <div className={style.divBar} />
        {title && <p className={style.p}>{title}</p>}
      </div>
      <div className={style.flexRight}>
        {filterCount > 0 && <button onClick={resetBtnEvent}>RESET</button>}
        <Icon name={'hide-filter'} onClick={leftBtnEvent} />
      </div>
    </div>
  );
};

export default React.memo(FilterHeader, _.isEqual);
