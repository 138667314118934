/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import PageHeader from '@components/page-header';
import PageContainer from '@components/page-container';
import useWindowDimensions from '@hooks/useWindowDimensions';
import HelpListItems from './help-list-items';
import HelpListItemsDetails from './help-list-items-details';

import './Help.scss';

const Help = () => {
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const selectedSettingFromQueryParams = searchParams.get('selectedSetting') || '';
  const selectedTipAndGuidesParams = searchParams.get('selectedTipAndGuides') || '';
  // const [success, setSuccess] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState('');
  const [selectedTipAndGuides, setSelectedTipAndGuides] = useState('');
  const [selectedTipAndGuidesGettingStarted, setSelectedTipAndGuidesGettingStarted] = useState('');

  const onClickBack = () => {
    navigate(pathname, { replace: true });
    if (selectedTipAndGuidesGettingStarted) {
      setSelectedTipAndGuidesGettingStarted('');
      if (width > 767) setSelectedTipAndGuides('');
    } else if (selectedTipAndGuides) {
      setSelectedTipAndGuides('');
      if (width > 767) setSelectedSetting('');
    } else if (selectedSetting) setSelectedSetting('');
  };

  useEffect(() => {
    if (width)
      if (width < 767) setSelectedTipAndGuides('');
      else {
        if (selectedSettingFromQueryParams) setSelectedSetting(selectedSettingFromQueryParams);
        if (selectedTipAndGuidesParams) setSelectedTipAndGuides(selectedTipAndGuidesParams);
        else
          setSelectedTipAndGuides(
            selectedSetting === 'Tips and guides' ? 'What’s Oplii Mobile?' : ''
          );
      }
  }, [selectedSetting, selectedSettingFromQueryParams, selectedTipAndGuidesParams]);

  useEffect(() => {
    if (width)
      if (width < 767) setSelectedSetting('');
      else setSelectedSetting('Contact support');
  }, []);

  const goToCanISeePage = () => setSelectedSetting('What can I see?');
  const goToRefreshingPage = () => setSelectedTipAndGuides('Refreshing data');
  const goToContactSupport = () => setSelectedSetting('Contact support');
  const goToSyncPage = () => {
    setSelectedTipAndGuides('Syncing tasks');
  };

  const onScrollEvent = () => {
    var el = document.querySelector('#helpContainer');
    el.classList.add('addScrollbar');
    el.classList.remove('removeScrollbar');
    clearTimeout();
    setTimeout(function () {
      el.classList.remove('addScrollbar');
      el.classList.add('removeScrollbar');
    }, 300);
  };

  return (
    <PageContainer>
      <PageHeader
        onClick={onClickBack}
        disableBack={selectedSetting}
        title={
          selectedTipAndGuidesGettingStarted || selectedTipAndGuides || selectedSetting || 'Help'
        }
      />
      <div className="flex">
        {width > 767 && (
          <HelpListItems
            success={false}
            onScrollEvent={onScrollEvent}
            selectedSetting={selectedSetting}
            selectedTipAndGuides={selectedTipAndGuides}
            onClickTipsAndGuideEvent={(item) => {
              setSelectedTipAndGuides(item);
              setSelectedSetting('Tips and guides');
            }}
            onClickHelpsEvent={(item) => setSelectedSetting(item)}
          />
        )}
        {/* right */}
        <HelpListItemsDetails
          success={false}
          selectedSetting={selectedSetting}
          selectedTipAndGuides={selectedTipAndGuides}
          onScrollEvent={onScrollEvent}
          goToCanISeePage={goToCanISeePage}
          goToRefreshingPage={goToRefreshingPage}
          goToSyncPage={goToSyncPage}
          goToContactSupport={goToContactSupport}
          width={width}
          onClickHelpsEvent={(item) => setSelectedSetting(item)}
          onClickTipsAndGuideEvent={(item) => setSelectedTipAndGuides(item)}
        />
      </div>
    </PageContainer>
  );
};

export default Help;
