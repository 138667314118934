/* eslint-disable no-unused-vars */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import InputField from '@components/input-field';
import Select from '@components/select-box-component';
import Label from '@components/label';

import style from './text-box-unit.module.scss';

const TextBoxUnit = ({
  inputLabel,
  control,
  errors,
  register,
  inputFiledRequired = false,
  disabled = false,
  reconciled = false,
  reconciledError = '',
  options = [],
  name,
  placeholder = '',
  error = null,
  showReconciled = false
}) => {
  return (
    <div>
      <div className={style.textBoxUnit}>
        {inputLabel && (
          <Label
            reconciled={reconciled}
            label={inputLabel}
            dot={inputFiledRequired}
            showReconciled={showReconciled}
            error={error}
            inLineStyle={{ paddingLeft: '8px' }}
          />
        )}
      </div>

      <div className={style.textBoxUnit}>
        <div className={style.textBox}>
          <InputField
            dot={inputFiledRequired}
            disabled={disabled}
            reconciled={reconciled}
            error={error}
            register={register}
            name={`${name}.textBoxValue`}
            placeholder={placeholder}
            showErrorMessage={false}
            type="number"
            step="any"
          />
        </div>

        <div
          id={name}
          className={`${!reconciledError && style.textBoxSelectWithMarginTop} ${style.dropdown}`}
        >
          <Select
            options={options}
            control={control}
            name={`${name}.textBoxUnitValue`}
            error={error}
            showBorderOnError
            reconciled={reconciled}
            register={register}
            isClearable
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

TextBoxUnit.propTypes = {
  inputLabel: PropTypes.string
};

export default TextBoxUnit;
