import ButtonStatus from '@components/button-status';
import HighlightText from '@components/highlight-text';
import Icon from '@components/icon-component';

import style from './style.module.scss';

const TaskDetail = ({
  img,
  task,
  btnText,
  onClick,
  spanText,
  dot,
  highlight,
  allDoneTasks,
  allTasksForSite,
  notShowInProgressCount = false,
  showBtnGrayCount = false
}) => {
  return (
    <div className={style.taskDetail} onClick={onClick}>
      <div className={style.innerDiv}>
        {img && img}
        <p className={style.taskName} style={{ marginLeft: img ? '20px' : '0px' }}>
          {spanText && (
            <span className={style.dull}>
              <HighlightText text={spanText} highlight={highlight} />
            </span>
          )}
          <HighlightText text={task} highlight={highlight} />
        </p>
      </div>
      <div className={style.innerDiv}>
        {!notShowInProgressCount && (
          <ButtonStatus
            classes={`btn-status btn1 ${allDoneTasks?.length === 0 && 'btn-overdue'} ${
              allDoneTasks?.length > 0 &&
              allTasksForSite?.length > allDoneTasks?.length &&
              'btn-progress'
            }  ${allDoneTasks?.length === allTasksForSite?.length && 'btn-done'} `}
            Text={btnText}
            dot={dot}
          />
        )}
        {showBtnGrayCount && (
          <div
            style={{
              marginLeft: '8px'
            }}
          >
            <ButtonStatus classes={`btn-status btn1 btn-count`} Text={btnText} />
          </div>
        )}
        <Icon name={'chevron-left'} alt="" className={style.leftIcon} />
      </div>
    </div>
  );
};

export default TaskDetail;
