/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { store } from 'redux/store';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import CacheService from '@services/cache.service';
import { tasksListsActions } from '@redux/slices/tasks';
import SyncingInProgressModal from '@components/modal/variants/syncing-in-progress-modal';
import OfflineModal from '@components/modal/variants/offline-modal';
import { getPendingTaskAndPhotosLength } from './helper';
import TimeToSyncModal from '@components/modal/variants/time-to-sync-modal';
import { cacheActions } from '@redux/slices/cache';
import MobileSyncCountService from '@services/mobile-sync-count.service';
import { addMobileSyncCount, updateMobileSyncCount } from 'transformers/mobile-sync-count';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
import { checkEveryFieldIsSynced } from '@utilities/sync';
import { bookmarksActions } from '@redux/slices/bookmarks';

const SyncModal = ({ isOpen, setIsOpen, limitExceeded, isSettingUpdated = false, isOnline }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const isCaching = useSelector((state) => state.cache.caching);
  const { workOrderInspectionsAndChecksIsSync, pendingSync } = useSelector((state) => state.cache);
  const { idToStartSync } = useSelector((state) => state.mobileSyncCount);
  const [status, setStatus] = useState(navigator.onLine ? 'sync' : 'offline');
  const [callSync, setCallSync] = useState(false);
  const { lastSync, updatedSettings = {} } = useSelector((state) => state.settings);
  const { bookmarks = [] } = useSelector((state) => state.bookmarks);
  const { tasksList = [], syncDone, syncFailedAssets } = useSelector((state) => state.tasks);
  const { HSEEventList = [] } = useSelector((state) => state.hseEvents);
  const { inspectionsList = [] } = useSelector((state) => state.inspection);
  const { checksList = [] } = useSelector((state) => state.checks);

  const [showRetryPhotoCount, setShowRetryPhotoCount] = useState([]);
  const [showRetryDocumentCount, setShowRetryDocumentCount] = useState([]);
  const [retry, setRetry] = useState(false);

  useEffect(() => {
    setShowRetryPhotoCount(syncFailedAssets?.photos || []);
    setShowRetryDocumentCount(syncFailedAssets?.documents || []);
  }, [retry]);

  const { pending, pendingHseEvents, pendingInspections, pendingChecks } = useMemo(() => {
    const pending = tasksList.filter((task) => task.status === 'Pending sync');
    const pendingHseEvents = HSEEventList.filter((task) => task.status === 'Pending sync');
    const pendingInspections = inspectionsList?.filter((ins) => ins.status === 'Pending sync');
    const pendingChecks = checksList?.filter((ins) => ins.status === 'Pending sync');

    return {
      pending,
      pendingHseEvents,
      pendingInspections,
      pendingChecks
    };
  }, [tasksList, HSEEventList, inspectionsList]);

  useEffect(() => {
    if (isCaching) setIsOpen(false);
  }, [isCaching]);

  const pendingOrderPhotosDocumentsLength = useMemo(() => {
    return getPendingTaskAndPhotosLength({
      pending,
      pendingInspections,
      pendingHseEvents,
      pendingChecks
    });
  }, [retry, isOnline]);

  useEffect(() => {
    if (status === 'offline' && isOnline) setStatus('sync');
    if (!isOnline) {
      setStatus('offline');
      window.stop();
      dispatch?.(globalProgressBarAction.setLoadStatus(0));
      localStorage.setItem('cachingFailure', true);
    }
  }, [status, isOnline]);

  const sendingAgainPhotosAndDocuments = useMemo(() => {
    return showRetryPhotoCount?.length || showRetryDocumentCount?.length ? true : false;
  }, [showRetryPhotoCount]);

  const syncNow = async ({ sync, retrySync = false, syncOnlyFailedAssets = false }) => {
    localStorage.setItem('cachingFailure', false);
    localStorage.setItem('startSyncingOrRefreshing', true);
    setCallSync(true);

    if (navigator.onLine) {
      dispatch?.(
        tasksListsActions.setSyncDone({
          workOrder: 0,
          workRequest: 0,
          photos: 0,
          documents: 0,
          inspection: 0,
          inspectionPhotos: 0,
          equipmentRecords: 0,
          hseEvents: 0,
          checks: 0,
          checkPhotoCount: 0,
          checkDocumentCount: 0,
          inspectionPhotoCount: 0,
          workOrderPhotoCount: 0,
          workRequestPhotoCount: 0,
          incidentPhotoCount: 0,
          inspectionDocumentCount: 0,
          workOrderDocumentCount: 0,
          workRequestDocumentCount: 0,
          incidentDocumentCount: 0,
          failedImageCount: 0,
          failedDocumentCount: 0
        })
      );

      setStatus('progress');
      if (sync && !syncOnlyFailedAssets)
        await MobileSyncCountService.sendRequestToServerToStartSync({
          data: {
            ...addMobileSyncCount({ pendingOrderPhotosDocumentsLength })
          }
        });

      const [success] = await CacheService.refresh({
        sync,
        syncFailedAssets:
          showRetryPhotoCount?.length > 0 || showRetryDocumentCount?.length > 0 || retrySync
            ? syncFailedAssets
            : false,
        updatedSettings,
        workOrdersPendingSync: pending.map((p) => ({ ...p, type: p.typeId, status: p.statusId })),
        inspectionPendingSync: pendingInspections,
        pendingHseEvents: pendingHseEvents,
        pendingChecks: pendingChecks,
        pendingSyncLength:
          pending.length +
          pendingInspections?.length +
          pendingHseEvents?.length +
          pendingChecks?.length,
        bookmarks: bookmarks?.filter((bookmark) => !bookmark?.sync) || [],
        updateBookmarks: bookmarks?.filter((bookmark) => bookmark?.sync && bookmark?.update) || [],
        deletedBookmarks: bookmarks?.filter((bookmark) => bookmark?.isDelete) || [],
        retrySync: retrySync || syncOnlyFailedAssets || false
      });
      const { tasks } = store.getState();
      const { syncDone: syncDone1 } = tasks;

      const _pendingOrderPhotosDocumentsLength = getPendingTaskAndPhotosLength({
        pending,
        pendingInspections,
        pendingHseEvents,
        syncFailedAssets,
        pendingChecks
      });

      const numberOfTaskSynced = checkEveryFieldIsSynced({
        syncDone: syncDone1,
        pendingOrderPhotosDocumentsLength: _pendingOrderPhotosDocumentsLength
      });
      setStatus(numberOfTaskSynced ? 'complete' : !navigator.onLine ? 'offline' : 'failed');
      dispatch?.(cacheActions.setPrevPendingSync(0));
      dispatch?.(cacheActions.setWorkOrderInspectionsAndChecksIsSync(false));
    } else {
      setStatus('offline');
    }
  };

  const syncNowEvent = ({ retrySync = false }) => {
    if (
      status === 'failed'
        ? 'Retry'
        : status === 'progress' || status === 'complete'
        ? ''
        : pendingOrderPhotosDocumentsLength?.pendingSync
    )
      syncNow({ sync: true, retrySync });
    else if (sendingAgainPhotosAndDocuments) {
      syncNow({ sync: true, retrySync, syncOnlyFailedAssets: true });
    } else {
      syncNow({ sync: false });
    }
  };
  const refreshDataOnlyEvent = () => {
    syncNow({ sync: false });
  };

  const retrySyncPhotos = () => {
    console.log('Retrying');
  };

  const retryEvent = () => {
    setRetry(true);
    syncNowEvent({ retrySync: true });
  };

  useEffect(() => {
    const updateData = async () => {
      await MobileSyncCountService.updateRequestToServerAfterSync({
        data: {
          ...updateMobileSyncCount({ syncDone })
        },
        idToStartSync
      });
    };

    if (status === 'complete' || status === 'failed') {
      updateData();
    }
  }, [status]);

  const cancelData = () => {
    window.stop();
    dispatch?.(globalProgressBarAction.setLoadStatus(0));
    localStorage.setItem('cachingFailure', true);
  };

  const completeModalEvent = () => {
    dispatch?.(cacheActions.updateSyncingModal(false));
    dispatch?.(cacheActions.updateRefreshingAndSyncingData(false));
    setIsOpen(false);
    setRetry(false);
    localStorage.setItem('cachingFailure', false);
    const prevRoute = `${location.pathname}?rerender=true`;
    navigate(prevRoute, { replace: true });
    dispatch?.(bookmarksActions.setBookmarkValidity(true));
  };

  return (
    <>
      {status === 'sync' && isOpen && (
        <TimeToSyncModal
          open={isOpen}
          setOpen={() => {
            dispatch?.(
              cacheActions.setPrevPendingSync(
                pending?.length +
                  pendingInspections?.length +
                  pendingHseEvents?.length +
                  pendingChecks?.length || 0
              )
            );
            // dispatch?.(
            //   tasksListsActions.setSyncFailedAssets({
            //     photos: [],
            //     documents: []
            //   })
            // );
            setIsOpen(!open);
          }}
          lastSyncTime={moment(lastSync).fromNow(true)}
          limitExceeded={limitExceeded}
          pendingSync={pendingOrderPhotosDocumentsLength?.pendingSync}
          syncNowEvent={syncNowEvent}
          refreshDataOnlyEvent={refreshDataOnlyEvent}
          retrySyncPhotos={retrySyncPhotos}
          isSettingUpdated={isSettingUpdated}
          pendingOrderPhotosDocumentsLength={pendingOrderPhotosDocumentsLength}
          showRetryPhotoCount={showRetryPhotoCount?.length}
          showRetryDocumentCount={showRetryDocumentCount?.length}
          sendingAgainPhotosAndDocuments={sendingAgainPhotosAndDocuments}
        />
      )}

      {status === 'progress' && isOpen && (
        <SyncingInProgressModal
          open={isOpen}
          setOpen={() => {}}
          inProgress
          pendingOrderPhotosDocumentsLength={pendingOrderPhotosDocumentsLength}
          showRetryPhotoCount={showRetryPhotoCount?.length}
          showRetryDocumentCount={showRetryDocumentCount?.length}
          syncDone={syncDone}
          workOrderInspectionsAndChecksIsSync={workOrderInspectionsAndChecksIsSync}
          cancelData={cancelData}
        />
      )}
      {status === 'complete' && isOpen && (
        <SyncingInProgressModal
          open={isOpen}
          setOpen={() => completeModalEvent()}
          syncingComplete
          showRetryPhotoCount={showRetryPhotoCount?.length}
          showRetryDocumentCount={showRetryDocumentCount?.length}
          pendingOrderPhotosDocumentsLength={pendingOrderPhotosDocumentsLength}
          syncDone={syncDone}
          workOrderInspectionsAndChecksIsSync={workOrderInspectionsAndChecksIsSync}
        />
      )}
      {status === 'failed' && isOpen && (
        <SyncingInProgressModal
          open={isOpen}
          setOpen={() => completeModalEvent()}
          syncingFailed
          showRetryPhotoCount={showRetryPhotoCount?.length}
          showRetryDocumentCount={showRetryDocumentCount?.length}
          pendingOrderPhotosDocumentsLength={pendingOrderPhotosDocumentsLength}
          workOrderInspectionsAndChecksIsSync={workOrderInspectionsAndChecksIsSync}
          syncDone={syncDone}
          retryEvent={retryEvent}
        />
      )}
      {status === 'offline' && isOpen && (
        <OfflineModal
          open={isOpen}
          setOpen={setIsOpen}
          lastSyncTime={moment(lastSync).fromNow(true)}
          pendingSync={pendingSync}
        />
      )}
    </>
  );
};

SyncModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired
};

export default React.memo(SyncModal, _.isEqual);
