/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

import animationData from '@assets/lotties/lottieflow-arrow';

import style from './button.component.module.scss';

function Button({
  text,
  pillButton,
  iconStart,
  buttonValue,
  borderMode,
  onClick,
  type,
  btnClass,
  disabled,
  variant,
  iconEnd,
  ...props
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleStartClick = () => {
    onClick && onClick();
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <>
      <button
        className={` ${variant ? variants[variant] : style.btn} ${btnClass}`}
        style={{ boxShadow: iconStart && !buttonValue && 'none' }}
        type={type || 'button'}
        disabled={disabled ? true : false}
        onClick={handleStartClick}
        {...props}
      >
        {isLoading && variant === 'pill' && buttonValue && <Lottie options={defaultOptions} />}

        {iconStart && !isLoading && iconStart}
        {text && !isLoading && (
          <span className={style.btnTitle}>
            {text} {buttonValue && `(${buttonValue})`}
          </span>
        )}
        {iconEnd && !isLoading && iconEnd}
      </button>
    </>
  );
}

Button.propTypes = {
  text: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string
};

const variants = {
  round: style.roundBtn,
  pill: style.pillBtn,
  border: style.btnBorder,
  standard: style.btn
};

export default Button;
