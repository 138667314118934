import { memo } from 'react';
import PropTypes from 'prop-types';

import AddNewWorkOrder from './add-new-work-order';
import AddNewInspection from './add-new-inspection';
import AddNewWorkRequest from './add-new-work-request';
import AddNewHseEvents from './add-new-hse-events';
import AddNewCheck from './add-new-check';

const AddNewWizards = ({ addNew, setAddNew, setActive }) => {
  return (
    <div>
      {addNew === 'inspection' || addNew === 'inspectionAsset' ? (
        <AddNewInspection
          asset={addNew === 'inspectionAsset'}
          setAddNew={setAddNew}
          setActive={setActive}
        />
      ) : addNew === 'work-order' ? (
        <AddNewWorkOrder setAddNew={setAddNew} setActive={setActive} />
      ) : addNew === 'work-request' ? (
        <AddNewWorkRequest setAddNew={setAddNew} setActive={setActive} />
      ) : addNew === 'hse-event' || addNew === 'hse-eventAsset' ? (
        <AddNewHseEvents
          setAddNew={setAddNew}
          setActive={setActive}
          asset={addNew === 'hse-eventAsset'}
        />
      ) : addNew === 'check' || addNew === 'checkAsset' ? (
        <AddNewCheck setAddNew={setAddNew} setActive={setActive} asset={addNew === 'checkAsset'} />
      ) : (
        ''
      )}
    </div>
  );
};

AddNewWizards.propTypes = {
  addNew: PropTypes.string.isRequired,
  setAddNew: PropTypes.func,
  setActive: PropTypes.func
};

export default memo(AddNewWizards);
