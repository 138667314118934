export const getChildrenKeys = (children, keys = {}) => {
  for (const el of children) {
    keys[el.key] = true;
    if (el.children?.length > 0) keys = getChildrenKeys(el.children, keys);
  }
  return keys;
};

export const setClassNames = (data, level = 1) => {
  for (let i = 0; i < data.length; i++) {
    const el = data[i];
    if (level > 1) data[i].className = `level-${level}-tree`;
    if (el.children.length > 0) data[i].children = setClassNames(data[i].children, level + 1);
  }
  return data;
};

export const removeUnavailableTreeData = (data = [], available = {}) => {
  data = data.filter(({ key, children }) => {
    const childrenKeys = getChildrenKeys(children);
    for (const key in childrenKeys)
      if (Object.hasOwnProperty.call(childrenKeys, key) && available[key]) return true;
    return available[key];
  });

  for (let i = 0; i < data.length; i++) {
    const el = data[i];
    if (el.children.length > 0)
      data[i].children = removeUnavailableTreeData(data[i].children, available);
  }
  return data;
};
