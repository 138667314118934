// import { store } from 'redux/store';
import _ from 'lodash';
import EquipmentSelector from '../../selectors/equipment.selector';

export const getParentEquipmentDetails = async ({ equipmentId, store }) => {
  const { cache } = store;
  const {
    sitesMap = {},
    equipmentsMap = {},
    equipments: cacheEquipments = [],
    sites: cacheSites = []
  } = cache;

  const parentEquipment = cacheEquipments[equipmentsMap[equipmentId]];
  const parentEquipmentChildren = parentEquipment?.children?.map((childIndex) => {
    return { ...cacheEquipments[childIndex], children: [] };
  });

  const children = [...(parentEquipmentChildren || [])].sort(
    (a, b) =>
      (cacheSites[sitesMap[a?.siteId]]?.distance != null
        ? cacheSites[sitesMap[a?.siteId]]?.distance
        : Infinity) -
        (cacheSites[sitesMap[b?.siteId]]?.distance != null
          ? cacheSites[sitesMap[b?.siteId]]?.distance
          : Infinity) || a?.name?.localeCompare(b.name)
  );
  let parentEquipmentWithWorkOrders = await EquipmentSelector.getEquipmentsWithAllTasks({
    parentEquipment: [parentEquipment || ''],
    store
  });

  let childrenEquipmentWithWorkOrders = await EquipmentSelector.getEquipmentsWithAllTasks({
    parentEquipment: children || [],
    store
  });

  parentEquipmentWithWorkOrders =
    _.size(parentEquipmentWithWorkOrders) > 0 ? parentEquipmentWithWorkOrders[0] : {};
  return {
    parentEquipmentWithWorkOrders,
    childrenEquipmentWithWorkOrders
  };
};

export const getParentEquipmentData = async ({ equipmentId, store }) => {
  const { cache } = store;
  const { equipmentsMap = {}, equipments: cacheEquipments = [] } = cache;

  const parentEquipment = cacheEquipments[equipmentsMap[equipmentId]] || {};

  let parentEquipmentWithWorkOrders = await EquipmentSelector.getEquipmentsWithAllTasks({
    parentEquipment: [parentEquipment || ''],
    withChild: false,
    store
  });

  const equipmentWithWorkOrders =
    _.size(parentEquipmentWithWorkOrders) > 0 ? parentEquipmentWithWorkOrders[0] : {};

  return {
    equipmentWithWorkOrders
  };
};
