import React, { memo } from 'react';

import Icon from '@components/icon-component';

const SettingsCards = ({ settings, onClick, selected }) => {
  return (
    <>
      {settings.map((setting, index) => (
        <div key={index} className="settings--card" onClick={() => onClick(setting)}>
          <div className="settings--card--title">{setting}</div>
          <div>
            <Icon name={'chevron-right'} width="10px" />
          </div>
          {((!selected && index === 0) || selected === setting) && <div className="selected"></div>}
        </div>
      ))}
    </>
  );
};

export default memo(SettingsCards);
