import React, { memo, forwardRef } from 'react';

import PassFail from '@components/form-fields/pass-fail/pass-fail';

import style from '../workorder.module.scss';

const FormPassFail = (
  {
    errorsMsg,
    removeSpecialCharactersForName,
    field,
    control,
    name,
    count,
    watch,
    getValues,
    setValue,
    checkPreviousFieldIsBoxed,
    label
  },
  ref
) => {
  const { refs } = ref;
  let errorMessage = errorsMsg?.answer?.message || '';
  const value = watch(`${removeSpecialCharactersForName}`)?.[field?.formFieldId]?.answer || null;

  const defaultValuePassFailAndNA =
    field?.defaultValue === 'true'
      ? 'true'
      : field?.defaultValue === 'false'
      ? 'false'
      : field?.defaultValue === 'NA'
      ? 'NA'
      : null;
  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
      className={`${checkPreviousFieldIsBoxed && style.removeMarginAndBorderTopFromPassFail}`}
    >
      <PassFail
        item={{
          label: label ? label : `${count}. ${field?.label}`,
          type: 'passFail',
          NA: field?.questionTypeText === 'PassFail' ? true : false,
          extraInfo: field?.tooltip ? field?.tooltip : false,
          name: name,
          required: field?.isRequired,
          defaultValuePassFailAndNA,
          field
        }}
        control={control}
        watch={watch}
        errors={!value && errorMessage ? 'Required' : ''}
        getValues={getValues}
        setValue={setValue}
      />
    </div>
  );
};

export default memo(forwardRef(FormPassFail));
