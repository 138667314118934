import moment from 'moment';

const siteAutoPopulate = ({
  firstName,
  intervalId,
  lastName,
  site,
  areaHierarchyTreeDataMap,
  parent = false,
  inspectionLastDate
}) => {
  return {
    // sourceEntity
    ...(!parent && {
      User: {
        //SourceProperty,
        InspectorName: `${firstName} ${lastName}`
      }
    }),
    InspectionSchedules: {
      IntervalId: intervalId
    },
    Incident: {
      InitiatorName: `${firstName} ${lastName}`
    },
    Site: {
      SiteNumber: site?.siteNumber,
      Area: areaHierarchyTreeDataMap[site?.areaHierarchy]?.key || null,
      AreaHierarchy: areaHierarchyTreeDataMap[site?.areaHierarchy]?.key || null,
      Operations_Area: site?.operations_Area,
      AreaName: areaHierarchyTreeDataMap[site?.areaHierarchy]?.title || null,
      Province: site?.province || null,
      Status: site?.status || null,
      CostCentreNumber: site?.costCentreNumber,
      LastInspectionDate: inspectionLastDate[site?.siteId]?.inspectionDate
        ? moment(inspectionLastDate[site?.siteId]?.inspectionDate).format('YYYY-MM-DD')?.toString()
        : null,
      LisenseNumber: site?.lisenseNumber,
      LSD: site?.surfaceLocation || null,
      MaxPressure: site?.maxPressure || null,
      Name: site?.name,
      OffsetProjectNumberC: null,
      OffsetProjectNumberEB0: null,
      OffsetProjectNumberRP: null,
      OffsetProjectNumberSP1: null,
      OffsetProjectNumberW: null,
      ProjectNumberPC: null,
      PlungerSizeUnit: null,
      SiteType: site?.siteType,
      StrokeLengthUnit: null,
      SurfaceLocation: site?.surfaceLocation,
      UWI: site?.uwi,
      Wellbore: site?.wellbore,
      CO2Percent: site?.cO2 || null,
      H2SPercent: site?.h2S || null,
      PlungerSize: null,
      StrokeLength: null,
      SurfaceCasing: site?.surfaceCasing || null,
      ChemicalInjectionRate: {
        textBoxValue: site?.chemicalInjectionRate || null,
        textBoxUnitValue: site?.chemicalInjectionRateUnit || null
      },
      MethanolInjectionRate: {
        textBoxValue: site?.methanolInjectionRate || null,
        textBoxUnitValue: site?.methanolInjectionRateUnit || null
      },
      HighPilotTripCheck: {
        textBoxValue: site?.highPilotTripCheck || null,
        textBoxUnitValue: site?.HighPilotTripCheckUnit || null
      },
      LowPilotTripCheck: {
        textBoxValue: site?.lowPilotTripCheck || null,
        textBoxUnitValue: site?.LowPilotTripCheckUnit || null
      }
    }
  };
};

export default siteAutoPopulate;
