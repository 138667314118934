import React from 'react';
import Lottie from 'react-lottie';

import animationData from '@assets/lotties/Oplii_Loading';

import style from './loading-round.module.scss';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};

function LoadingRound({ modal = false, className }) {
  return (
    <div className={`${style.wrapper} ${className} ${modal ? style.modal : null}`}>
      <div className={style.inner}>
        <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
      </div>
    </div>
  );
}

export default LoadingRound;
