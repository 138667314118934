/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import moment from 'moment';

import AddPart from '@components/add-part';
import Select from '@components/select-box-component';
import InputField from '@components/input-field';
import Textarea from '@components/textarea';
import Datepicker from '@components/date-picker-component';
import Icon from '@components/icon-component';
import DeleteModal from '@components/modal/variants/delete-modal';
import Collapseable from '@components/collapseable';

import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './worklog.module.scss';

const WorkLog = ({
  workType,
  currentSelectedTasksList,
  register,
  control,
  errors,
  setValue,
  getValues,
  watch
}) => {
  const [searchParams] = useSearchParams();
  const equipmentId = searchParams.get('equipmentId') || '';
  const [addHours, setAddHours] = useState(false);
  const [addReading, setAddReading] = useState(
    currentSelectedTasksList?.readingIsRequired ?? false
  );
  const [addPart, setAddPart] = useState(false);
  const [deletePart, setDeletePart] = useState(false);
  const [selectedPartId, setSelectedPartId] = useState(null);

  const { counterReadingUnitsData } = useSelector((state) => state.picklists);
  const { equipmentsMap, equipments: cacheEquipments = [] } = useSelector((state) => state.cache);
  const counterReadingsAvailableOn = useSelector(
    (state) => state?.settings?.settings?.counterReadingsAvailableOn
  );

  useEffect(() => {
    if (
      currentSelectedTasksList?.counterReadings &&
      currentSelectedTasksList.counterReadings?.length > 0 &&
      (getValues('reading') || getValues('readingUnit'))
    )
      setAddReading(true);

    if (watch('overTimeHours') || watch('regularHours')) {
      setAddHours(true);
    }
  }, [currentSelectedTasksList]);

  const onSubmitPart = (data) => {
    let previousPartsData = getValues('parts') || [];
    setValue('parts', [...previousPartsData, { ...data, id: uuidv4() }]);
    setAddPart(false);
  };

  const onEditPart = (data, id) => {
    let previousPartsData = getValues('parts') || [];
    const findIndex = previousPartsData?.findIndex((x) => x.id === id);
    previousPartsData[findIndex] = { ...data, id };
    setValue('parts', [...previousPartsData]);
    setAddPart(false);
  };

  const editPartEvent = (id) => {
    setSelectedPartId(id);
    setAddPart(true);
  };

  const deletePartEvent = (id) => {
    const partsCopy = getValues('parts') || [];
    const filterParts = partsCopy?.filter((part) => part?.id !== id);
    setValue('parts', [...filterParts]);
    setDeletePart(false);
  };
  // pv category not show show reading
  let showReadings = false;
  if (equipmentId || currentSelectedTasksList?.equipmentId) {
    const currentEquipmentCategory = equipmentId
      ? cacheEquipments[equipmentsMap[equipmentId]]?.equipmentCategory
      : currentSelectedTasksList?.equipmentId
      ? cacheEquipments[equipmentsMap[currentSelectedTasksList?.equipmentId]]?.equipmentCategory
      : null;

    showReadings = counterReadingsAvailableOn?.includes(currentEquipmentCategory);
  }

  useEffect(() => {
    if (moment(watch('workStarted')).diff(moment(watch('workCompletedDate'))) > 0) {
      setValue('workStarted', moment(watch('workCompletedDate')));
    }
  }, [watch('workCompletedDate')]);

  return (
    <div>
      <div className={style.colorLightDiv}></div>
      <div className="workLog" style={{ backgroundColor: '#FFFFFF', paddingBottom: '4px' }}>
        <div
          className={style.nameDivInner}
          style={{
            backgroundColor: '#D5E6FB',
            height: '48px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <p style={{ fontSize: '18px' }}>Work log</p>
        </div>

        <div className={style.body}>
          <Textarea
            label="Technician notes"
            inputClass="input-field input-regular"
            labelColor={style.labelColor}
            register={register}
            name="technicalNotes"
            error={errors?.technicalNotes?.message || ''}
          />

          <div className={style.grid2} style={{ paddingBottom: '16px', paddingTop: '16px' }}>
            <div className={style.datePicker}>
              <Datepicker
                readOnly={true}
                id="1"
                label="Work Started"
                inputClass="input-regular op-datepicker"
                control={control}
                name="workStarted"
                error={errors?.workStarted?.message || ''}
                labelColor={style.labelColor}
                isClearable
                maxDate={new Date(watch('workCompletedDate'))}
              />
            </div>

            <div className={style.datePicker}>
              <Datepicker
                readOnly={true}
                id="2"
                label="Work completed"
                inputClass="input-regular op-datepicker"
                control={control}
                name="workCompletedDate"
                error={errors?.workCompletedDate?.message || ''}
                labelColor={style.labelColor}
                isClearable
                maxDate={new Date()}
              />
            </div>
          </div>
          {addHours && (
            <div className={style.addHours}>
              <>
                <InputField
                  label="Regular hours"
                  type="number"
                  inputClass="input-field input-regular"
                  labelColor={style.labelColor}
                  register={register}
                  step="any"
                  name="regularHours"
                  error={errors?.regularHours?.message || ''}
                  allowPositiveNumber
                />

                <InputField
                  label="Overtime hours"
                  type="number"
                  step="any"
                  inputClass="input-field input-regular"
                  labelColor={style.labelColor}
                  register={register}
                  name="overTimeHours"
                  error={errors?.overTimeHours?.message || ''}
                  allowPositiveNumber
                />
              </>
            </div>
          )}
          {addReading && (
            <div className={style.addReading} id="reading-section">
              <>
                <input
                  type="hidden"
                  name="readingIsRequired"
                  ref={register}
                  value={`${currentSelectedTasksList?.readingIsRequired}`}
                  {...register('readingIsRequired')}
                />
                <InputField
                  dot={currentSelectedTasksList?.readingIsRequired}
                  label="Reading"
                  type="number"
                  inputClass="input-field input-regular"
                  labelColor={style.labelColor}
                  register={register}
                  name="reading"
                  error={errors?.reading?.message ? 'Required' : ''}
                  allowPositiveNumber
                  step={'any'}
                />
                <div style={{ marginTop: errors?.unit?.message ? '5px' : '28px' }}>
                  <Select
                    dot={currentSelectedTasksList?.readingIsRequired}
                    options={counterReadingUnitsData?.map((x) => {
                      return {
                        label: x?.value || '',
                        value: x?.key || ''
                      };
                    })}
                    labelColor={style.labelColor}
                    control={control}
                    name="unit"
                    error={errors?.unit?.message ? 'Required' : ''}
                    isClearable
                  />
                </div>
              </>
            </div>
          )}

          {_.size(watch('parts')) > 0 && (
            <label
              className={`label-bold block mb-2 px-2 flex flex-wrap gap-2 items-center justify-between ${style.labelColor} `}
            >
              PART/MATERIAL NAME
            </label>
          )}

          {(watch('parts') || [])?.map((part, index) => {
            return (
              <CollapseableTagsForPart
                key={index}
                part={part}
                editPartEvent={editPartEvent}
                setDeletePart={setDeletePart}
              />
            );
          })}

          <div className={style.workLogActions}>
            {!addReading && showReadings && (
              <p className={style.addButtons} onClick={() => setAddReading((prev) => !prev)}>
                <Icon name={'add-icon'} style={{ marginRight: '4px' }} />
                Reading
              </p>
            )}
            {!addHours && (
              <p className={style.addButtons} onClick={() => setAddHours((prev) => !prev)}>
                <Icon name={'add-icon'} style={{ marginRight: '4px' }} />
                Hours
              </p>
            )}

            {!addPart && (
              <p
                className={style.addButtons}
                onClick={() => {
                  setSelectedPartId(null);
                  setAddPart((prev) => !prev);
                }}
              >
                <Icon name={'add-icon'} style={{ marginRight: '4px' }} />
                Part
              </p>
            )}
          </div>
        </div>
      </div>

      {addPart && (
        <AddPart
          onEditPart={onEditPart}
          open={addPart}
          closed={setAddPart}
          title={selectedPartId ? 'Edit part' : 'Add part'}
          onSubmitPart={onSubmitPart}
          id={selectedPartId}
          deletePartEvent={() => deletePartEvent(selectedPartId)}
          editPhoto={!!selectedPartId}
          initialValue={
            selectedPartId && !_.isEmpty(getValues('parts'))
              ? getValues('parts')?.find((x) => x?.id === selectedPartId)
              : null
          }
        />
      )}
      <DeleteModal
        open={typeof deletePart !== 'boolean'}
        handleDelete={() => {
          deletePartEvent(deletePart);
          setDeletePart(false);
        }}
        setOpen={setDeletePart}
        confirmDeletion
        recordType="work part"
      />
    </div>
  );
};

export default WorkLog;

const CollapseableTagsForPart = ({ part, editPartEvent, setDeletePart }) => {
  const { width } = useWindowDimensions();
  const [open, setOpen] = useState(width > 767 ? true : false);

  const formWrapperWIdth = document.getElementById('formWrapperContainer')?.clientWidth || 0;
  const headerWidth = useMemo(() => {
    return width > 500 ? Math.floor(formWrapperWIdth / 2) - 32 - 16 : formWrapperWIdth - 32;
  }, [width, formWrapperWIdth]);

  return (
    <div
      className={`op-filter-collapse ${open ? 'open' : ''}`}
      style={{
        backgroundColor: 'white',
        padding: '0px 0px',
        width: `100%`,
        minWidth: `${headerWidth}px`
      }}
    >
      <Collapseable
        title={part['part/materialName']}
        subTitle={`${part?.quantity} ${part?.unit}`}
        onDelete={() => setDeletePart(part?.id)}
        onEdit={() => editPartEvent(part?.id)}
      >
        {part['part/materialName'] && (
          <>
            <div className={style.section1}>
              <p className={style.p}>Part/material name</p>
              <p className={style.p1}>{part['part/materialName']}</p>
            </div>
          </>
        )}

        {(part?.quantity || part?.unit) && (
          <>
            <div className={style.sectionFlex}>
              <div className={style.section1}>
                <p className={style.p}>Quantity</p>
                <p className={style.p1}>{part?.quantity}</p>
              </div>
              <div className={style.section1}>
                <p className={style.p}>Unit</p>
                <p className={style.p1}>{part?.unit}</p>
              </div>
            </div>
          </>
        )}

        {part?.part && (
          <>
            <div className={style.section1}>
              <p className={style.p}>Part #</p>
              <p className={style.p1}>{part?.part}</p>
            </div>
          </>
        )}
        {part?.manufactuer && (
          <>
            <div className={style.section1}>
              <p className={style.p}>manufactuer</p>
              <p className={style.p1}>{part?.manufactuer}</p>
            </div>
          </>
        )}
      </Collapseable>
    </div>
  );
};
