/* eslint-disable no-unused-vars */
import moment from 'moment';
import _ from 'lodash';
import { store } from 'redux/store';

import { getAppDispatch } from '@utilities/dispatch.util';
import { tasksListsActions } from '@redux/slices/tasks';
import {
  transformFormWrapperDataToWorkRequestData,
  transformFormWrapperDataToWorkOrderDataTodo,
  transformFormWrapperDataToWorkOrderDataDone,
  transformFormWrapperDataToWorkOrderWorkLog
} from '../transformers/work-order';
import { deleteTaskFromLocalStorageAfterSuccessFullSynced } from '@utilities/attachments';

const WorkOrderService = {
  setNewWorkRequestData: async (newWorkRequestData, id, inspection = false) => {
    const dispatch = getAppDispatch();
    const data = await transformFormWrapperDataToWorkRequestData(
      newWorkRequestData,
      id,
      inspection
    );
    if (!_.isEmpty(id)) {
      dispatch?.(
        tasksListsActions.updateNewWorkOrder({
          id,
          data: { ...data, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss') }
        })
      );
    } else {
      dispatch?.(
        tasksListsActions.addNewWorkRequest({
          ...data,
          createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
        })
      );
    }
  },

  setNewWorkOrderTodoData: async (newWorkOrderData, id, inspection = false) => {
    const dispatch = getAppDispatch();
    let data = await transformFormWrapperDataToWorkOrderDataTodo(newWorkOrderData, id, inspection);
    if (!_.isEmpty(id)) {
      dispatch?.(
        tasksListsActions.updateNewWorkOrder({
          id,
          data: { ...data, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss') }
        })
      );
    } else {
      dispatch?.(
        tasksListsActions.addNewWorkOrder({
          ...data,
          createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
        })
      );
    }
  },

  setNewWorkOrderDoneData: async (newWorkOrderData, id, inspection = false) => {
    const dispatch = getAppDispatch();
    let data = await transformFormWrapperDataToWorkOrderDataDone(newWorkOrderData, id, inspection);
    if (!_.isEmpty(id)) {
      dispatch?.(
        tasksListsActions.updateNewWorkOrder({
          id,
          data: { ...data, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss') }
        })
      );
    } else {
      dispatch?.(
        tasksListsActions.addNewWorkOrder({
          ...data,
          createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
        })
      );
    }
    return inspection ? data?.woId : null;
  },

  addWorkLogInWorkOrder: async (newWorkOrderData) => {
    let data = await transformFormWrapperDataToWorkOrderWorkLog(newWorkOrderData);
    return data;
  },

  deleteWorkOrderAndRequest: async (woId) => {
    const dispatch = getAppDispatch();
    dispatch?.(tasksListsActions.deleteWokOrderRequest(woId));

    const userId = store.getState().auth.userId;
    await deleteTaskFromLocalStorageAfterSuccessFullSynced({ userId, tasks: true, id: woId });
  }
};

export default WorkOrderService;
