import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: {
    siteCount: 0,
    equipmentCount: 0,
    structureCount: 0,
    pipelineCount: 0,
    inspectionCount: 0,
    assetCheckCount: 0,
    workOrderCount: 0,
    inspectionFormCount: 0,
    syncedInspectionCount: 0,
    syncedWorkOrdersCount: 0,
    syncedHSECount: 0
  },
  refreshModalObject: null,
  idToStartSync: null
};
export const mobileSyncCountSlice = createSlice({
  name: 'mobileSyncCount',
  initialState,
  reducers: {
    setMobileSyncCount: (state, action) => {
      const workOrdersCount = action?.payload?.workOrderCount;
      const checksCount = action?.payload?.assetCheckCount + action?.payload?.syncedAssetCheckCount;
      const inspectionsCount =
        action?.payload?.inspectionCount + action?.payload?.syncedInspectionCount;

      const refreshModalObject = {
        0: `Checking out what’s new`,
        1: `Checking out what’s new`,
        2: `Checking out what’s new`,
        3:
          action.payload?.equipmentCount > 0
            ? `Hauling ${action.payload?.equipmentCount?.toLocaleString('en-US') || 0} equipment`
            : 'No new equipment',
        4: `Processing the particulars`,
        5: `Processing the particulars`,
        6: `Processing the particulars`,
        7: `Processing the particulars`,
        8:
          action.payload?.siteCount > 0
            ? `Establishing ${action.payload?.siteCount?.toLocaleString('en-US') || 0} sites`
            : 'No new sites',
        9: action?.payload?.pipelineCount
          ? `Pulling ${action?.payload?.pipelineCount?.toLocaleString('en-US') || 0} pipeline`
          : 'No new pipeline',
        10:
          action?.payload?.structureCount > 0
            ? `Setting up ${
                action?.payload?.structureCount?.toLocaleString('en-US') || 0
              } structures`
            : 'No new structures',
        11:
          workOrdersCount > 0
            ? `Grabbing  ${`${workOrdersCount || 0}`.toLocaleString('en-US')} work orders`
            : 'No new work orders',
        12:
          inspectionsCount > 0
            ? `Picking up ${`${
                action?.payload?.inspectionCount + action?.payload?.syncedInspectionCount || 0
              }`.toLocaleString('en-US')} inspections`
            : 'No new inspections',

        13:
          checksCount > 0
            ? `Loading up ${`${checksCount}`.toLocaleString('en-US')} checks`
            : 'No new checks',
        14:
          action?.payload?.inspectionFormCount > 0
            ? `Snapping up ${
                action?.payload?.inspectionFormCount?.toLocaleString('en-US') || 0
              } forms`
            : 'No new forms'
      };

      state.refreshModalObject = refreshModalObject;
      state.count = { ...action.payload };
    },
    setIdToStartSync: (state, action) => {
      state.idToStartSync = action.payload;
    }
  }
});

const mobileSyncReducer = mobileSyncCountSlice.reducer;

export const mobileSyncActions = mobileSyncCountSlice.actions;
export default mobileSyncReducer;
