import React, { useEffect } from 'react';

import style from './task.module.scss';

const CompleteTask = ({ goToSyncPage }) => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className={style.bgColor}>
      <div className={style.seeCard}>
        <h6 className={style.titleClass}>Find it</h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          You can find tasks assigned to you in multiple ways:
        </p>
        <ul>
          <li>
            Select <span style={{ fontWeight: 700 }}>Tasks</span> on the navigation bar.
          </li>
          <li>Go to an asset details page and view tasks from there.</li>
          <li style={{ marginLeft: '50px' }}>
            Example: From <span style={{ fontWeight: 700 }}>Home,</span> select the
            <span style={{ fontWeight: 700 }}> Sites </span>
            button. Open the site at which the task is assigned.
          </li>
          <li>
            View inspections by run, or work orders by recurring template, via{' '}
            <span style={{ fontWeight: 700 }}>Home.</span>
          </li>
        </ul>

        <h6 className={style.titleClass} style={{ marginTop: '32px' }}>
          Do it
        </h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          Select the task you wish to complete.
        </p>
        <p className={style.p} style={{ marginTop: '8px' }}>
          If you want to do multiple work orders or inspections in a series, select multiple and hit
          the <span style={{ fontWeight: 700 }}>Start</span> button.
        </p>
        <p className={style.p} style={{ marginTop: '8px' }}>
          Launch and fill out the form, then hit the{' '}
          <span style={{ fontWeight: 700 }}>Complete </span>
          button. Your task(s) will be in ‘Pending sync’ status.
        </p>
        <h6 className={style.titleClass} style={{ marginTop: '32px' }}>
          Sync it
        </h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          Finally, you’ll need to sync your completed tasks. Learn more about that{' '}
          <span className="link" onClick={goToSyncPage}>
            {' '}
            here
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default CompleteTask;
