import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  jurisdictionNumbers: [],
  jurisdictionNumbersMap: {}
};

export const jurisdictionNumbersSlice = createSlice({
  name: 'jurisdictionNumbers',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setJurisdictionNumbers: (state, action) => {
      const jurisdictionNumbers = action.payload || [];
      state.jurisdictionNumbers = jurisdictionNumbers;
      state.jurisdictionNumbersMap = jurisdictionNumbers.reduce((acc, curr) => {
        acc[curr.equipmentId] = curr;
        return acc;
      }, {});
    }
  }
});

const jurisdictionNumbersReducer = jurisdictionNumbersSlice.reducer;
export const jurisdictionNumbersActions = jurisdictionNumbersSlice.actions;
export default jurisdictionNumbersReducer;
