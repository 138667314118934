import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';

import Icon from '@components/icon-component';
import Label from '@components/label';

import 'react-datepicker/dist/react-datepicker.css';
import style from './date.module.scss';
import './range.css';

const DateRange = ({
  name,
  className,
  id,
  handleClick,
  control,
  startDate,
  endDate,
  handleChange,
  placeholder,
  isDisabled,
  reconciled,
  label,
  error,
  dot,
  isClearable
}) => {
  const handleChangeDate = (event, onChange, name) => {
    handleChange?.(event, name);
  };
  const [isOpen, setIsOpen] = useState(false); // state variable to track if calendar is open

  return (
    <div>
      <div className={`${style.main} ${className}`}>
        <Label
          reconciled={reconciled}
          label={label}
          dot={dot}
          error={error}
          inLineStyle={{ paddingLeft: '8px' }}
        />
        <div onClick={handleClick} style={{ position: 'relative' }}>
          <Controller
            name={name}
            control={control}
            render={({ onChange, value = null, name }) => {
              return (
                <>
                  <ReactDatePicker
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    isClearable={isClearable}
                    popperClassName="date-range-green"
                    selected={value}
                    disabled={isDisabled}
                    endDate={endDate && endDate}
                    startDate={startDate && startDate}
                    dateFormat={'MMM. d, yyyy'}
                    onChange={(event) => {
                      handleChangeDate(event, onChange, name);
                    }}
                    className={error ? style.borderClass : style.inpDiv}
                    placeholderText={placeholder ? '' : ''}
                    id={id}
                    selectsRange
                    renderCustomHeader={({
                      date,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                      prevYearButtonDisabled,
                      nextYearButtonDisabled,
                      increaseYear,
                      decreaseYear
                    }) => (
                      <div className={style.iconsDiv}>
                        <div>
                          <button
                            style={{ marginLeft: '0px' }}
                            type="button"
                            onClick={decreaseYear}
                            disabled={prevYearButtonDisabled}
                          >
                            <Icon name={'chevrons-left'} alt="" />
                          </button>
                          <button
                            type={'button'}
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            <Icon name={'chevron-left'} alt="" />
                          </button>
                        </div>
                        <p>
                          {months[date.getMonth()]} {date.getFullYear()}
                        </p>
                        <div>
                          <button
                            type={'button'}
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            <Icon name={'chevron-right'} alt="" width="10px" />
                          </button>
                          <button
                            type={'button'}
                            onClick={increaseYear}
                            disabled={nextYearButtonDisabled}
                            style={{ marginRight: '0px' }}
                          >
                            <Icon name={'chevrons-rights'} alt="" />
                          </button>
                        </div>
                      </div>
                    )}
                  />
                </>
              );
            }}
          />
          <label htmlFor={id} className={style.labelDate}>
            <Icon name={isOpen && !endDate ? 'calendar-blue' : 'calendar'} className={style.icon} />
          </label>
        </div>
      </div>
    </div>
  );
};

export default DateRange;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
