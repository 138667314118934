/* eslint-disable no-unused-vars */
import moment from 'moment';
import _ from 'lodash';

import { getAppDispatch } from '@utilities/dispatch.util';
import { transformFormWrapperDataToNewHSEEvent } from 'transformers/hse-event';
import { hseEventActions } from '@redux/slices/hse-events/hse-event-slice';
import LocalStorage from '@utilities/local-storage.util';
import { decodeToken } from '@utilities/authentication';

import { store } from 'redux/store';
import http from './http.service';
import Promisable from './promisable.service';
import { cacheActions } from '@redux/slices/cache';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
import { deleteTaskFromLocalStorageAfterSuccessFullSynced } from '@utilities/attachments';

const HSEEventService = {
  getAllHseEvents: async ({ sync, initialLogin }) => {
    const dispatch = getAppDispatch();
    const { cache, settings, hseEvents } = store.getState();

    const changedAfter = settings?.isSettingUpdated ? '' : cache?.lastSuccessFullRefreshTime;

    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      http.setJWT();
      const allHseEventsMap =
        _.size(hseEvents?.HSEEventList) > 0 && !initialLogin
          ? hseEvents?.HSEEventList.reduce((acc, hseItems) => {
              acc[hseItems.id] = true;
              return acc;
            }, {})
          : {};

      const [success, error] = await Promisable.asPromise(http.get(`/SyncedHSECache/MobileCache`));

      const hseEventsAll = success?.data?.map((hse) => ({
        ...hse,
        status: 'Synced',
        type: 'HSE'
      }));

      let newSyncedHSEEvents = [];
      if (!_.isEmpty(allHseEventsMap)) {
        newSyncedHSEEvents = hseEventsAll.map((ins) => {
          if (!allHseEventsMap[ins.id]) {
            return {
              syncDateCustom: moment().format('YYYY-MM-DD'),
              ...ins
            };
          } else return ins;
        });
      } else newSyncedHSEEvents = hseEventsAll;

      dispatch?.(hseEventActions.setHseEventList(newSyncedHSEEvents || []));

      if (sync) dispatch?.(cacheActions.updateSyncing(false));

      dispatch?.(globalProgressBarAction.setLoadStatus(13));

      return [success, error];
    } else throw new Error('Api failed to update');
  },

  setNewHSEEventData: async ({ hseEventData, hseEventId = null }) => {
    const dispatch = getAppDispatch();
    const transformData = transformFormWrapperDataToNewHSEEvent({
      hseEventData,
      hseEventId
    });

    if (!_.isEmpty(hseEventId)) {
      dispatch?.(
        hseEventActions.updateNewHSEEvent({
          hseEventId,
          data: {
            ...transformData,
            updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
          }
        })
      );
    } else {
      dispatch?.(
        hseEventActions.addNewHSEEvent({
          createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          ...transformData
        })
      );
    }
  },
  geHseEventsFromStorage: async (userId) => {
    const dispatch = getAppDispatch();

    let pendingHSEEvents = [];
    const pendingSyncAndInProgressHSEEventsOfCurrentUser = (
      (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) ||
      []
    )?.find((item) => item.userId === userId);
    if (!_.isEmpty(pendingSyncAndInProgressHSEEventsOfCurrentUser))
      pendingHSEEvents = !_.isEmpty(pendingSyncAndInProgressHSEEventsOfCurrentUser)
        ? pendingSyncAndInProgressHSEEventsOfCurrentUser?.hseEvents
        : [];
    dispatch?.(hseEventActions.setLocalStorageHSEEvents(pendingHSEEvents || []));
  },
  deleteNewHSEEvent: async (id) => {
    const dispatch = getAppDispatch();
    dispatch?.(hseEventActions.deleteHSEEvent(id));

    const userId = store.getState().auth.userId;
    await deleteTaskFromLocalStorageAfterSuccessFullSynced({ userId, hseEvents: true, id });
  }
};

export default HSEEventService;
