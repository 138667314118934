/* eslint-disable no-unused-vars */

import _ from 'lodash';

import { getDataPoints } from '../../pages/settings/helper';
import {
  getTaskHeaderTitle,
  getTaskCardUsers,
  getTaskAssignedUserTags,
  getDetailsInspectionsInfo,
  TYPE_ICONS,
  getStructureEquipmentMap,
  getAppliesToTypeData
} from './helper';
import { getTaskCardFooter } from '../global/footer';
import { getTaskSiteAsset, filterSiteAssetBasedOnMocHseStructureNcrPipeline } from '../global/site';
import {
  getTaskEquipmentAsset,
  filterEquipmentAssetBasedOnMocHseStructureNcrPipeline
} from '../global/equipment';
import { getTaskStructureAsset, getStructureAsset } from '../global/structure';
import { getTaskPipelineAsset, getPipelineAsset } from '../global/pipeline';

const TasksSelector = {
  getTasks: ({
    showDataPoints = true,
    id = '',
    removeConfigurableFieldFromAsset = false,
    appliesTo = false,
    newWorkOrderOrRequest = false,
    siteId = null,
    equipmentId = null,
    structureId = null,
    equipmentIds = [],
    showAttachedPSVs = false,
    formWrapper = false,
    store
  }) => {
    return new Promise((resolve) => {
      const {
        tasks,
        cache,
        settings,
        picklists,
        jurisdictionNumbers,
        auth,
        pipeline,
        structure,
        inspection = {}
      } = store;
      let { tasksList = [], workOrderUsersMap, workOrderUsersGroupsMap } = tasks;
      const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = picklists;
      const {
        equipmentsMap,
        sitesMap = {},
        equipmentsWithoutMobileMap = {},
        equipments: cacheEquipments = [],
        sites: cacheSites = []
      } = cache;
      const {
        equipmentDisplayFields,
        siteDisplayFields,
        workorderDisplayFields,
        structureDisplayFields,
        pipelineDisplayFields
      } = settings.settings;
      const { jurisdictionNumbersMap } = jurisdictionNumbers;
      const { pipelineMobileMap = {}, pipelineMobile = [] } = pipeline;
      const { structureMobileMap = {}, structureMobile = [] } = structure;
      const structureEquipmentMap = getStructureEquipmentMap({ equipments: cacheEquipments });

      const responsibleUsersMap = {
        ...(workOrderUsersMap || {}),
        ...(workOrderUsersGroupsMap || {})
      };

      const userId = auth.userId || '';

      let prepareTasks = [];
      if (siteId) {
        tasksList = tasksList?.filter(
          (t) =>
            t?.siteId === siteId ||
            t?.relatedAssetId === siteId ||
            t?.primaryAssetId === siteId ||
            t?.sites?.includes(siteId)
        );
      } else if (equipmentId) {
        tasksList = tasksList?.filter(
          (t) =>
            t?.equipmentId === equipmentId ||
            t?.relatedAssetId === equipmentId ||
            t?.primaryAssetId === equipmentId ||
            t?.equipments?.includes(equipmentId)
        );
      } else if (structureId && equipmentIds?.length) {
        tasksList = tasksList?.filter(
          (t) =>
            t?.structureId === structureId ||
            t?.relatedAssetId === structureId ||
            t?.structures?.includes(structureId) ||
            equipmentIds?.includes(t?.equipmentId) ||
            equipmentIds?.includes(t?.relatedAssetId) ||
            equipmentIds?.includes(t?.primaryAssetId) ||
            t?.equipments?.some((y) => equipmentIds?.includes(y))
        );
      } else if (structureId) {
        tasksList = tasksList?.filter(
          (t) =>
            t?.structureId === structureId ||
            t?.relatedAssetId === structureId ||
            t?.structures?.includes(structureId)
        );
      }
      if (id) {
        if (newWorkOrderOrRequest) tasksList = tasksList.filter((t) => t.woId === id);
        else tasksList = tasksList.filter((t) => t.id === id);
      }

      tasksList?.map((task) => {
        const { responsibleUsers = [], responsibleUserGroups = [] } = task;
        const taskHeaderIconName = TYPE_ICONS[task.type] || '';

        const dataPoints = showDataPoints
          ? getDataPoints(
              { ...task, recurrence: task.rtInterval || '', type: task?.typeId },
              workorderDisplayFields,
              'workorder',
              picklistsMap
            )
          : [];

        const taskCardHeaderTitle =
          getTaskHeaderTitle({
            status: task.status || '',
            type: task.type || '',
            isNew: task.isNew,
            id: task.id || ''
          }) || '';

        const users = getTaskCardUsers({
          responsibleUserGroups: task.responsibleUserGroups || [],
          responsibleUsers: task.responsibleUsers || [],
          workOrderUsersMap: workOrderUsersMap || {},
          workOrderUsersGroupsMap: workOrderUsersGroupsMap || {},
          primaryUserId: task.primaryUserId || null
        });

        const siteAsset = getTaskSiteAsset({
          sitesMap,
          siteId: task.siteId || '',
          sites: task.sites || [],
          areaHierarchyTreeDataMap,
          siteDisplayFields,
          picklistsMap,
          removeConfigurableFieldFromAsset,
          cacheSites,
          appliesTo,
          formWrapper,
          store
        });

        const equipmentAsset = getTaskEquipmentAsset({
          equipmentsWithoutMobileMap,
          equipmentsMap,
          cacheEquipments,
          equipments: task.equipments || [],
          equipmentId: task.equipmentId || '',
          picklistsMap,
          equipmentDisplayFields,
          jurisdictionNumbersMap,
          sitesMap,
          siteDisplayFields,
          areaHierarchyTreeDataMap,
          removeConfigurableFieldFromAsset,
          cacheSites,
          appliesTo,
          showAttachedPSVs,
          formWrapper,
          store
        });

        const structureAsset =
          (task?.appliesToType === 'structure' || task?.structureId) &&
          getTaskStructureAsset({
            sitesMap,
            areaHierarchyTreeDataMap,
            siteDisplayFields,
            picklistsMap,
            cacheSites,
            appliesTo,
            task,
            structureMobileMap,
            structureMobile,
            structureDisplayFields,
            structureEquipmentMap,
            formWrapper,
            store
          });

        const pipelineAsset =
          (task?.appliesToType === 'pipeline' || task?.pipelineId) &&
          getTaskPipelineAsset({
            sitesMap,
            siteDisplayFields,
            cacheSites,
            areaHierarchyTreeDataMap,
            picklistsMap,
            appliesTo,
            task,
            pipelineMobileMap,
            pipelineMobile,
            pipelineDisplayFields,
            formWrapper,
            store
          });

        const taskFooter = getTaskCardFooter({
          status: task.status || null,
          sync: task.sync || null,
          dueDate: task.dueDate || null,
          startDate: task.startDate || null,
          due: task.due || null,
          syncedByText: task.syncedByText || null,
          syncedDate: task.syncedDate || null,
          syncedBy: task.syncedBy || null,
          updatedAt: task.updatedAt || null,
          syncDateCustom: task?.syncDateCustom || null,
          userId
        });

        const customAssignedTaskValue = getTaskAssignedUserTags({
          responsibleUsers,
          responsibleUserGroups,
          workOrderUsersGroupsMap,
          userId,
          responsibleUsersMap
        });

        const hseData =
          task?.appliesToType === 'incident'
            ? getAppliesToTypeData({
                equipmentsWithoutMobileMap,
                task,
                equipmentsMap,
                cacheEquipments,
                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,
                store,
                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo
              })
            : {};

        const mocData =
          task?.appliesToType === 'moc'
            ? getAppliesToTypeData({
                equipmentsWithoutMobileMap,
                task,
                equipmentsMap,
                cacheEquipments,
                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,

                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                store
              })
            : {};

        const structureData =
          task?.appliesToType === 'structure'
            ? getAppliesToTypeData({
                equipmentsWithoutMobileMap,
                task,
                equipmentsMap,
                cacheEquipments,
                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,

                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                structureDisplayFields,
                store
              })
            : task?.structureId
            ? getStructureAsset({
                task,
                picklistsMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                structureMobileMap,
                structureMobile,
                cacheSites,
                structureDisplayFields,
                appliesTo,
                store
              })
            : {};

        const ncrData =
          task?.appliesToType === 'ncr'
            ? getAppliesToTypeData({
                equipmentsWithoutMobileMap,
                task,
                equipmentsMap,
                cacheEquipments,

                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,

                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                store
              })
            : {};

        const pipelineData =
          task?.appliesToType === 'pipeline'
            ? getAppliesToTypeData({
                equipmentsWithoutMobileMap,
                task,
                equipmentsMap,
                cacheEquipments,

                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,

                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                pipelineDisplayFields,
                store
              })
            : task?.pipelineId
            ? getPipelineAsset({
                equipmentsWithoutMobileMap,
                task,
                equipmentsMap,
                cacheEquipments,

                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,

                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                pipelineDisplayFields,
                store
              })
            : {};
        const detailInspections =
          _.size(task.detailInspections) > 0
            ? getDetailsInspectionsInfo({
                inspectionIds: task.detailInspections,
                task
              })
            : [];

        const sitesAssetFilterData = filterSiteAssetBasedOnMocHseStructureNcrPipeline({
          mocSiteId: (mocData && mocData?.siteAsset && mocData?.siteAsset?.siteId) || '',
          hseSiteId: (hseData && hseData?.siteAsset && hseData?.siteAsset?.siteId) || '',
          structureSiteId:
            // (structureData && structureData?.siteAsset && structureData?.siteAsset?.siteId) || '',
            structureAsset?.sitesIds,

          ncrSiteId: (ncrData && ncrData?.siteAsset && ncrData?.siteAsset?.siteId) || '',
          pipelineSiteId:
            (pipelineData && pipelineData?.siteAsset && pipelineData?.siteAsset?.siteId) || '',
          siteAsset
        });

        const equipmentAssetFilterData = filterEquipmentAssetBasedOnMocHseStructureNcrPipeline({
          mocEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          hseEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          structureEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          ncrEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          pipelineEquipmentId:
            (mocData && mocData?.equipmentAsset && mocData?.equipmentAsset?.equipmentId) || '',
          equipmentAsset
        });

        prepareTasks.push({
          taskHeaderIconName,
          taskCardHeaderTitle,
          status: task.status || '',
          type: task.type || '',
          link: task.link || '',
          title: task.title || '',
          description: task.description || '',
          workStatus: task.workStatus || '',
          users,
          rtInterval: task.rtInterval || '',
          taskCardWorkStatus: picklistsMap[task.statusId]?.value,
          moc: '',
          hse: '',
          equipmentAsset,
          siteAsset,
          priority: task.priority || '',
          rtData: { id: task.rtNumber || '', title: task.rtName || '' },
          taskFooter,
          hseData,
          mocData,
          structureData,
          ncrData,
          pipelineData,
          customAssignedTaskValue,
          dataPoints,
          sitesAssetFilterData,
          equipmentAssetFilterData,
          detailInspectionsInfo: detailInspections,
          checked: false,
          structureAsset,
          pipelineAsset,
          ...task
        });
      });
      resolve(prepareTasks);
    });
  }
};

export default TasksSelector;
