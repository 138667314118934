/* eslint-disable no-unused-vars */

import React from 'react';
import _ from 'lodash';
import HighlightText from '@components/highlight-text';

import ButtonStatus from '@components/button-status';
import CheckBoxComponent from '@components/checkbox/checkbox.component';
import Portal from '@components/portal';

import style from './style.module.scss';

const RtList = ({ rt, setRt, checkedLength, setView, highlight = '' }) => {
  const clickRtRow = (i) => {
    let rtCopy = [...rt];
    rtCopy[i].checked = !rtCopy[i].checked;
    setRt(rtCopy);
  };

  const changeCheckEvent = (e, i) => {
    let rtCopy = [...rt];
    rtCopy[i].checked = !rtCopy[i].checked;
    setRt(rtCopy);
  };

  return (
    <div style={{ position: 'relative' }}>
      {rt.map(({ id, title, checked, allDoneTasks, allWorkOrder, isOverDue }, i) => (
        <div key={i} className="task-type link-card" onClick={() => clickRtRow(i)}>
          <div className="task-type--left">
            <div style={{ marginRight: '24px' }}>
              <CheckBoxComponent checked={checked} onChange={(e) => changeCheckEvent(e, i)} />
            </div>
            <div>
              <div>
                <span className={style.runId}>
                  <HighlightText text={id} highlight={highlight} />
                </span>{' '}
                <span className={style.runTitle}>
                  <HighlightText text={title} highlight={highlight} />
                </span>
              </div>
            </div>
          </div>

          <ButtonStatus
            classes={`btn-status btn1 ${allDoneTasks === 0 && 'btn-overdue'} ${
              allDoneTasks > 0 && allWorkOrder > allDoneTasks && 'btn-progress'
            }
                    ${allDoneTasks === allWorkOrder && 'btn-done'} `}
            Text={`${allDoneTasks}/${allWorkOrder}`}
            dot={(!!isOverDue && true) || false}
          />
        </div>
      ))}
      {checkedLength > 0 && (
        <Portal id="rtView">
          <button className={style.viewBtn} onClick={() => setView('Work Order')}>
            View ({checkedLength})
          </button>
        </Portal>
      )}
    </div>
  );
};

export default React.memo(RtList, _.isEqual);
