/* eslint-disable no-unused-vars */
import { store } from 'redux/store';

import http from './http.service';
import Promisable from './promisable.service';

import { toLowerCaseFirst } from '@pages/settings/helper';
import { getAppDispatch } from '@utilities/dispatch.util';
import { picklistsActions } from '@redux/slices/picklists';
import { picklistSysNames } from '@redux/slices/picklists/picklists-slice';
import { inspectionActions } from '@redux/slices/inspection';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';

let dropdownValue = [
  'SiteStatus',
  'CodeOfConstruction',
  'Province',
  'EquipmentCategory',
  'EquipmentStatus',
  'Service',
  'PSVType',
  'Pressure',
  'Volume',
  'Temperature',
  'SmallMeasurement',
  'PipelineStatus',
  'Substance',
  'AreaHierarchy',
  'EquipmentConnectionType',
  'InspectionFrequency'
];

const equipmentCategoryTypeAndSubTypeDropdownPickListsName = [
  'EquipmentCategory',
  'EquipmentType',
  'EquipmentSubType'
];

const PickListService = {
  getAllPickLists: async () => {
    const picklists = {},
      dropdownMap = {},
      equipmentCategoryTypeAndSubTypeDropdownMap = {};
    const dispatch = getAppDispatch();
    dispatch?.(picklistsActions.setLoading(true));
    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      http.setJWT();
      const res = await Promise.all(
        picklistSysNames.map((sysName) =>
          Promisable.asPromise(http.get(`/Picklists?sysName=${sysName}&orderBy=value&page=0`))
        )
      );

      res.forEach(([success], i) => {
        if (dropdownValue?.includes(picklistSysNames[i]) && success)
          dropdownMap[`${toLowerCaseFirst(picklistSysNames[i])}`] = success.data.items;

        if (
          equipmentCategoryTypeAndSubTypeDropdownPickListsName?.includes(picklistSysNames[i]) &&
          success?.data?.items
        )
          equipmentCategoryTypeAndSubTypeDropdownMap[`${toLowerCaseFirst(picklistSysNames[i])}`] =
            success.data.items;

        if (success) picklists[`${toLowerCaseFirst(picklistSysNames[i])}Data`] = success.data.items;
      });

      if (Object.keys(picklists).length) dispatch?.(picklistsActions.setPicklists(picklists));
      if (Object.keys(dropdownMap).length)
        dispatch?.(inspectionActions.setInspectionFormMenuDropdownAsPicklists({ dropdownMap }));
      if (Object.keys(equipmentCategoryTypeAndSubTypeDropdownMap).length) {
        const list = makeEquipmentCategoryTypeAndSubTypeDropdownMap({
          equipmentCategory: equipmentCategoryTypeAndSubTypeDropdownMap?.equipmentCategory,
          equipmentType: equipmentCategoryTypeAndSubTypeDropdownMap?.equipmentType,
          equipmentSubType: equipmentCategoryTypeAndSubTypeDropdownMap?.equipmentSubType
        });
        dispatch?.(inspectionActions.setEquipmentCategoryTypeAndSubTypeDropdown(list));
      }

      dispatch?.(picklistsActions.setLoading(false));

      const status = res.filter(([success]) => success).length === res.length;
      dispatch?.(globalProgressBarAction.setLoadStatus(5));
      return [status, !status];
    } else {
      throw new Error('Api failed to update');
    }
  }
};

export default PickListService;

const makeEquipmentCategoryTypeAndSubTypeDropdownMap = ({
  equipmentCategory,
  equipmentType,
  equipmentSubType
}) => {
  // const equipmentCategoryMap = equipmentCategory?.reduce((acc, curr) => {
  //   acc[curr.key] = curr;
  //   return acc;
  // }, {});
  // const equipmentTypeMap = equipmentType?.reduce((acc, curr) => {
  //   acc[curr.key] = curr;
  //   return acc;
  // }, {});

  // const equipmentTypeMapWithParent = {};
  // for (let item of equipmentType) {
  //   const parentKey = item?.parentKey;
  //   if (!equipmentTypeMapWithParent[parentKey]) {
  //     equipmentTypeMapWithParent[parentKey] = [];
  //   }
  //   equipmentTypeMapWithParent[parentKey].push(item);
  // }
  // const equipmentSubTypeMapWIthParent = {};
  // for (let item of equipmentSubType) {
  //   const parentKey = item?.parentKey;
  //   if (!equipmentSubTypeMapWIthParent[parentKey]) {
  //     equipmentSubTypeMapWIthParent[parentKey] = [];
  //   }
  //   equipmentSubTypeMapWIthParent[parentKey].push(item);
  // }

  // let equipmentTypesWithItsSubTypes = Object.keys(equipmentTypeMap)?.reduce((acc, key) => {
  //   const subTypeData = equipmentSubTypeMapWIthParent[key];
  //   acc[key] = subTypeData;
  //   return acc;
  // }, {});

  // const data = Object.keys(equipmentCategoryMap)?.reduce((acc, key) => {
  //   const typeData = equipmentTypeMapWithParent[key];
  //   const typeDataMap = typeData?.reduce((acc, item) => {
  //     acc[item.key] = equipmentTypesWithItsSubTypes[item.key];
  //     return acc;
  //   }, {});

  //   acc[key] = typeDataMap;
  //   return acc;
  // }, {});
  // return data;
  return [equipmentCategory, equipmentType, equipmentSubType]?.flat(1);
};
