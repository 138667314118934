/* eslint-disable no-unused-vars */

import React, { useEffect, memo, useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import FilterSort from '@components/filters/filter-sort';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { getAppDispatch } from '@utilities/dispatch.util';
import { tasksListsActions } from '@redux/slices/tasks';
import RtRunHeader from './rt-run-header';
import TaskTemplateRecordList from './task-template-record-list';

const TaskTemplate = ({
  templateData,
  setTemplateData,
  filtersCount,
  tagsDataRT,
  isFilterActive,
  setFilterActive,
  SelectedFilterRT,
  setSelectedFilterRT,
  openModal,
  searched,
  setSelectedOrderIds
}) => {
  const { width } = useWindowDimensions();
  const dispatch = getAppDispatch();

  useEffect(() => {
    let checkedTasksLength = 0;
    if (width <= 767) {
      for (const el of templateData) {
        if (_.size(el?.tasks?.filter((x) => x?.checked)) > 0) {
          ++checkedTasksLength;
          break;
        }
      }
      if (checkedTasksLength) dispatch?.(tasksListsActions.setSelectedTask(true));
      else dispatch?.(tasksListsActions.setSelectedTask(false));
    }
  }, [templateData]);

  const clickTemplate = useCallback(
    (open, id) => {
      let copy = [...templateData];
      copy = copy?.map((a) => {
        if (a?.id === id) {
          return {
            ...a,
            open: !open
          };
        } else {
          return {
            ...a,
            open: false
          };
        }
      });
      setTemplateData(copy);
    },
    [templateData]
  );

  const selectedSingleTaskEvent = useCallback(
    ({ updatedTasks, templateDataIndex }) => {
      let copy = [...templateData];
      const checkAllTodoTasksIsSelected =
        updatedTasks?.filter((x) => x.status === 'To do' && x.checked)?.length ===
        copy[templateDataIndex].tasks?.filter((x) => x.status === 'To do')?.length
          ? true
          : false;
      copy[templateDataIndex].tasks = updatedTasks;
      if (checkAllTodoTasksIsSelected) {
        copy[templateDataIndex] = {
          ...copy[templateDataIndex],
          checked: true
        };
      } else {
        copy[templateDataIndex] = {
          ...copy[templateDataIndex],
          checked: false
        };
      }
      setTemplateData(copy);
    },
    [templateData]
  );

  return (
    <>
      <FilterSort
        count={filtersCount}
        tagsData={tagsDataRT}
        isFilterActive={isFilterActive}
        setFilterActive={setFilterActive}
        SelectedFilter={SelectedFilterRT}
        setSelectedFilter={setSelectedFilterRT}
      />

      {templateData?.map(({ id, title, tasks, open, checked, tasksMap }, i) => (
        <div key={i} className="rts-container">
          <RtRunHeader
            open={open}
            index={i}
            clickTemplate={clickTemplate}
            id={id}
            title={title}
            checked={checked}
            tasks={tasks}
            templateData={templateData}
            setTemplateData={setTemplateData}
            lastElement={templateData?.length - 1 === i}
            setSelectedOrderIds={setSelectedOrderIds}
          />

          {open && (
            <TaskTemplateRecordList
              tasks={tasks}
              tasksMap={tasksMap}
              SelectedFilter={SelectedFilterRT}
              isFilterActive={isFilterActive}
              searched={searched}
              loading={false}
              lastElement={templateData?.length - 1 === i}
              taskTemplateRecords
              setData={(updatedTasks) =>
                selectedSingleTaskEvent({ updatedTasks, templateDataIndex: i })
              }
              openModal={openModal}
              setSelectedOrderIds={setSelectedOrderIds}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default memo(TaskTemplate, _.isEqual);
