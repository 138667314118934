/* eslint-disable no-unused-vars */
import { useEffect } from 'react';

import Icon from '@components/icon-component';

import { useToastContext } from 'context/toaster.context';

import style from './toaster.module.scss';

const ToasterComponent = ({ placement: bottom }) => {
  const { notification, setNotification, notificationMsg, status } = useToastContext();
  useEffect(() => {
    if (notification) {
      setTimeout(() => {
        setNotification && setNotification(false);
      }, 3000);
    }
  }, [notification]);

  return (
    <>
      <div
        className={style.containermain}
        style={{
          top: !bottom && '10px',
          bottom: bottom && '10px',
          width: notification ? '400px' : '0px',
          right: notification ? '0px' : '-100%'
        }}
      >
        {notification && (
          <div
            className={style.toasterDiv}
            style={{
              borderBottom: status === 'success' ? '4px solid #2F80ED' : '4px solid  #E02B2B'
            }}
          >
            {' '}
            <Icon
              className={style.iconDiv}
              name={'cross-icon-grey'}
              style={{ position: 'absolute', right: '10px', cursor: 'pointer' }}
              onClick={() => setNotification(false)}
            />
            <div className={style.mainToast}>
              <Icon
                className={style.iconDiv}
                name={status === 'success' ? 'flag-blue' : 'bellred'}
              />

              <div className={style.content}>
                <h2 style={{ color: status === 'success' ? '#2F80ED' : '#E02B2B' }}>
                  {status === 'success' ? 'Success' : 'Error'}
                </h2>

                <p>{notificationMsg}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ToasterComponent;
