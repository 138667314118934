import React from 'react';

import style from './run-tag.module.scss';

const RunRtTag = ({ rtNumber, rtName, selected = false }) => {
  return (
    <div className={style.statusDiv} style={{ ...(selected && { background: '#ffffff' }) }}>
      <span>{rtNumber}</span>

      <p className={style.pClass}>{rtName}</p>
    </div>
  );
};

export default RunRtTag;
