/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react';
import _ from 'lodash';

import DataSnippets from '@components/data-snippet';
import OpenLink from './open-link';
import CardSite from './card-site';

import { getAppDispatch } from '@utilities/dispatch.util';
import { formWrapperActions } from '@redux/slices/form-wrapper';
import { useOpenLink } from '@hooks/useOpenLink';

import style from '../task.module.scss';

const openLinkDefaultValue = {
  linkValue: '',
  pipeline: false,
  equipment: false,
  site: false,
  structure: false
};

const CardStructure = ({
  structureAsset,
  highlight,
  className,
  showNotIcon = false,
  formWrapper = false,
  data,
  divider,
  value,
  setShowStructure,
  taskId,
  assetId
}) => {
  const [desktopLink, setDesktopLink] = useState(openLinkDefaultValue);
  const [openLink, setOpenLink] = useOpenLink({
    taskId,
    assetId
  });
  const dispatch = getAppDispatch?.();

  const onClickOpliiDesktopLinkEvent = ({
    linkValue,
    pipeline = false,
    equipment = false,
    site = false,
    structure = false
  }) => {
    setDesktopLink({
      linkValue,
      pipeline,
      equipment,
      site,
      structure
    });
    setOpenLink((prev) => !prev);
    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId: taskId,
        assetId: assetId
      })
    );
  };

  return (
    <>
      <div
        style={{
          position: 'relative'
        }}
      >
        <DataSnippets
          divider={divider}
          backFlex={style.backClass}
          title={data?.structureType || ''}
          subtitle={data?.name || ''}
          iconName={!showNotIcon && 'home-icon'}
          dataPoints={data?.dataPoints}
          highlightNames={highlight}
          className={` ${style.dataSnippetMargin}`}
          link={!formWrapper ? data?.link : ''}
          onClickOpliiDesktopLinkEvent={(linkValue) =>
            onClickOpliiDesktopLinkEvent({ linkValue, structure: true })
          }
          value={value}
          {...(!formWrapper && {
            status: data?.status
          })}
          {...(!formWrapper && {
            childrenList: data?.childrenList
          })}
          {...(!formWrapper && {
            allTasksForSite: data?.allTasksForStructure
          })}
          {...(!formWrapper && {
            allDoneTasks: data?.allDoneTasks
          })}
          {...(!formWrapper && {
            isOverDue: data?.isOverDue
          })}
          {...(!formWrapper && {
            childrenIconName: 'equipment'
          })}
          setOpenAll={() => setShowStructure((prev) => !prev)}
        />
        <OpenLink
          openLink={openLink}
          taskId={taskId || ''}
          assetId={assetId || ''}
          desktopLink={desktopLink}
          setOpenLink={(val) => {
            setOpenLink(val);
          }}
          setDesktopLink={setDesktopLink}
          selectedLinkTypeIcon={'home-icon'}
          selectedLinkTypeName={'structure'}
        />
      </div>
      {!_.isEmpty(data?.siteAsset) && (
        <div className={style['mt-16']}>
          <CardSite
            highlight={highlight}
            className={className}
            showNotIcon={showNotIcon}
            formWrapper={formWrapper}
            variant={'secondary'}
            siteData={data?.siteAsset}
            taskId={taskId}
            assetId={data?.siteAsset?.siteId}
          />
        </div>
      )}
    </>
  );
};

export default CardStructure;
