export const siteSearchData = ({ site, inputValue }) => {
  const { dataPoints = [], name, title } = site;

  const dataPointsObject = dataPoints?.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
    {}
  );
  const filterObject = {
    name,
    title,
    ...dataPointsObject
  };
  if (
    !Object.values(filterObject).some((value) => {
      return String(value).toLowerCase().includes(inputValue.toLowerCase());
    })
  )
    return false;

  return true;
};
