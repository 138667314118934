import {
  getParentEquipmentData,
  getParentEquipmentDetails
} from '../pages/parent-equipment/helper';

import { getParentSiteData, getParentSiteDetails } from '../components/parent-listing/helper';

import HSEEventSelector from '../selectors/hse/hse-event.selector';
import TasksSelector from '../selectors/tasks/tasks.selector';
import InspectionSelector from '../selectors/inspection/inspection.selector';
import CheckSelector from '../selectors/checks/check.selector';

import SitesSelector from '../selectors/sites.selector';
import StructureSelector from '../selectors/structure.selector';
import EquipmentSelector from '../selectors/equipment.selector';
import PipelineSelector from '../selectors/pipeline.selector';
import RecurringTemplateSelector from '../selectors/recurring-template.selector';
import InspectionRunSelector from '../selectors/inspection-run.selector';

import {
  searchEquipments,
  searchRts,
  searchRuns,
  searchSites,
  searchTasks,
  searchStructures,
  searchPipelines
} from '../pages/search/helper';

import { checkOldAndNewArray } from '@services/check-old-and-new-array';

const getParentEquipmentCompleteDetails = async ({ args }) => {
  const [res1, res2] = await Promise.all([
    await getParentEquipmentDetails({ ...args }),
    await getParentEquipmentData({ ...args })
  ]);
  return [res1, res2];
};

const getParentSiteCompleteDetails = async ({ args }) => {
  const [res1, res2] = await Promise.all([
    await getParentSiteDetails({ ...args }),
    await getParentSiteData({ ...args })
  ]);
  return [res1, res2];
};

const getAllTasksRecordsData = async ({ args }) => {
  const [res1, res2, res3, res4] = await Promise.all([
    await TasksSelector.getTasks({ ...args }),
    await InspectionSelector.getInspections({ ...args }),
    await HSEEventSelector.getHSEEvents({ ...args }),
    await CheckSelector.getAllChecks({ ...args })
  ]);
  return [...res1, ...res2, ...res3, ...res4];
};

const getSearchRelatedData = async ({ args }) => {
  const allResponse = await Promise.all([
    await TasksSelector.getTasks({ ...args }),
    await InspectionSelector.getInspections({ ...args }),
    await HSEEventSelector.getHSEEvents({ ...args }),
    await CheckSelector.getAllChecks({ ...args }),
    await SitesSelector.getSitesWithAllTasks({ ...args }),
    await EquipmentSelector.getEquipmentsWithAllTasks({ ...args }),
    await StructureSelector.getStructuresWithWO({ ...args }),
    await PipelineSelector.getPipelineWithWO({ ...args }),
    await RecurringTemplateSelector.getRecurringTemplateTasks({ ...args }),
    await InspectionRunSelector.getInspectionRuns({ ...args })
  ]);

  return allResponse;
};

const getSelectedSearchData = async ({ args }) => {
  const allResponse = await Promise.all([
    await searchTasks({ searched: args?.searched, data: args?.tasks, store: args?.store }),
    await searchSites({ searched: args?.searched, data: args?.sites, store: args?.store }),
    await searchEquipments({
      searched: args?.searched,
      data: args?.equipments,
      store: args?.store
    }),
    await searchRts({ searched: args?.searched, data: args?.rts, store: args?.store }),
    await searchRuns({ searched: args?.searched, data: args?.runs, store: args?.store }),
    await searchStructures({
      searched: args?.searched,
      data: args?.structures,
      store: args?.store
    }),
    await searchPipelines({ searched: args?.searched, data: args?.pipelines, store: args?.store })
  ]);

  return allResponse;
};
const equipmentMobileData = ({ equipmentPayloadData = [], isCheckOldAndNewArray = false }) => {
  return new Promise((resolve) => {
    let equipments = [];
    let _equipmentPayloadData = [];

    if (isCheckOldAndNewArray) {
      const combineNewAndOldArray = checkOldAndNewArray({
        ...isCheckOldAndNewArray
      });
      equipments = combineNewAndOldArray;
      _equipmentPayloadData = combineNewAndOldArray;
    } else {
      equipments = equipmentPayloadData;
      _equipmentPayloadData = equipmentPayloadData;
    }
    const arr = [];
    const sitesEquipmentsMap = {};

    const equipmentsMap = equipments?.reduce((acc, curr, currIndex) => {
      const { siteId, equipmentId } = curr;
      if (!sitesEquipmentsMap[siteId]) sitesEquipmentsMap[siteId] = [];
      sitesEquipmentsMap[siteId].push(equipmentId);
      acc[equipmentId] = currIndex;
      return acc;
    }, {});

    for (const key in equipmentsMap) {
      const index = equipmentsMap[key];
      let data = { ..._equipmentPayloadData[equipmentsMap[key]], children: [], index };
      arr.push(data);
    }

    arr.forEach((data) => {
      if (Object.hasOwnProperty.call(equipmentsMap, data.equipmentId)) {
        const { parentId } = data;

        const parentEquipmentIndex = arr.findIndex((d) => d.equipmentId === parentId);

        if (parentEquipmentIndex) arr[parentEquipmentIndex]?.children.push(data?.index);
      }
    });

    resolve({
      equipments: arr,
      equipmentsMap,
      sitesEquipmentsMap
    });
  });
};

export {
  getParentEquipmentCompleteDetails,
  getParentSiteCompleteDetails,
  getAllTasksRecordsData,
  getSearchRelatedData,
  getSelectedSearchData,
  equipmentMobileData
};
