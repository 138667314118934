import style from './style.module.scss';

const InfoPopup = ({ open, setOpen, arrayText, top = 100 }) => {
  return (
    <div>
      {open && <div className={style.popUpClose} onClick={() => setOpen(false)}></div>}
      <div
        className={style.popUpClass}
        style={{
          top: top >= 100 ? `${top}px` : '100px'
        }}
      >
        {arrayText?.map((ele, index) => (
          <div className={style.innerText} key={index} onClick={ele.onClick}>
            {ele.icon}
            <p>{ele.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoPopup;
