/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { store } from 'redux/store';

import NewTabs from '@components/new-tabs';
import Bookmark from './bookmark';
import EditBookmarkModal from './edit-bookmark-modal';
import DeleteModal from '@components/modal/variants/delete-modal';
import PageHeader from '@components/page-header';
import Portal from '@components/portal';

import GlanceBookmarkSelector from 'selectors/glance.selector';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import BookmarksService from '@services/bookmarks.service';
import { bookmarksActions } from '@redux/slices/bookmarks';
import { useToastContext } from 'context/toaster.context';

import style from './style.module.scss';

const BookmarksList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allBookmarks = useSelector((state) =>
    state.bookmarks?.bookmarks?.filter((book) => !book.isDelete)
  );
  const [notificationStatus, setNotificationStatus] = useState('good');
  const [showNotifications, setShowNotifications] = useState(false);
  const [atAGlanceBookmarks, setAtAGlanceBookmarks] = useState([]);
  const [equipmentBookmarks, setEquipmentBookmarks] = useState([]);
  const [structureBookmarks, setStructureBookmarks] = useState([]);
  const [pipelineBookmarks, setPipelineBookmarks] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [filterBookmarks, setFilterBookmarks] = useState([]);
  const [othersBookmarks, setOthersBookmarks] = useState([]);
  const [siteBookmarks, setSiteBookmarks] = useState([]);
  const [disableReactSwipe, setDisableReactSwipe] = useState(false);

  const [tab, setTab] = useState('0');
  const { ToasterUtil } = useToastContext();
  let atAGlanceRef = useRef(null);
  let filterRef = useRef(null);
  let siteRef = useRef(null);
  let equipmentRef = useRef(null);
  let structureRef = useRef(null);
  let pipelineRef = useRef(null);

  useEffect(() => {
    const equipmentBookmark = GlanceBookmarkSelector.getEquipmentsBookmarks({
      store: { ...store.getState() }
    });
    const atAGlanceBookmark = GlanceBookmarkSelector.getAtAGlanceBookmarks({
      store: { ...store.getState() }
    });
    const filterBookmark = GlanceBookmarkSelector.getFilterBookmarks({
      store: { ...store.getState() }
    });
    const othersBookmark = GlanceBookmarkSelector.getOthersBookmarks({
      store: { ...store.getState() }
    });
    const sitesBookmark = GlanceBookmarkSelector.getSitesBookmarks({
      store: { ...store.getState() }
    });
    const structureBookmark = GlanceBookmarkSelector.getStructureBookmarks({
      store: { ...store.getState() }
    });
    const pipelineBookmark = GlanceBookmarkSelector.getPipelineBookmarks({
      store: { ...store.getState() }
    });
    setAtAGlanceBookmarks(atAGlanceBookmark);
    setFilterBookmarks(filterBookmark);
    setSiteBookmarks(sitesBookmark);
    setEquipmentBookmarks(equipmentBookmark);
    setStructureBookmarks(structureBookmark);
    setPipelineBookmarks(pipelineBookmark);
    setOthersBookmarks(othersBookmark);
  }, []);

  const onDragEnd = (result) => {
    setDisableReactSwipe(false);
    if (!result.destination) return;
    const newLists = reorder(atAGlanceBookmarks, result.source.index, result.destination.index);
    setAtAGlanceBookmarks(newLists);
    BookmarksService.updateBookmarks(newLists);
    dispatch?.(bookmarksActions.setBookmarkValidity(true));
  };

  const renameAtAGlanceBookmarkEvent = ({
    name,
    atAGlance,
    bookmarkId,
    equipment = false,
    site = false,
    filter = false,
    atAGlanceBook = false,
    structure = false,
    pipeline = false
  }) => {
    const copy = [...atAGlanceBookmarks];
    const newList = copy?.map((c) => {
      if (c?.bookmarkId === bookmarkId) {
        return {
          ...c,
          name,
          atAGlance
        };
      } else return c;
    });

    if (atAGlanceBook) {
      if (_.size(newList?.filter((x) => x?.atAGlance)) === 0) setTab(0);
      dispatch?.(bookmarksActions.setBookmarkValidity(true));
    }
    setAtAGlanceBookmarks(newList);
    const copy1 = [...filterBookmarks];
    const newList1 = copy1
      ?.map((c) => {
        if (c?.bookmarkId === bookmarkId) {
          return {
            ...c,
            name,
            atAGlance
          };
        } else return c;
      })
      .sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      });
    if (filter) {
      if (_.size(newList1?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setFilterBookmarks(newList1);
    const copy2 = [...siteBookmarks];
    const newList2 = copy2
      ?.map((c) => {
        if (c?.bookmarkId === bookmarkId) {
          return {
            ...c,
            name,
            atAGlance
          };
        } else return c;
      })
      .sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      });
    if (site) {
      if (_.size(newList2?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setSiteBookmarks(newList2);

    const copy3 = [...equipmentBookmarks];
    const newList3 = copy3
      ?.map((c) => {
        if (c?.bookmarkId === bookmarkId) {
          return {
            ...c,
            name,
            atAGlance
          };
        } else return c;
      })
      .sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      });
    if (equipment) {
      if (_.size(newList3?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setEquipmentBookmarks(newList3);
    const copy4 = [...structureBookmarks];
    const newList4 = copy4
      ?.map((c) => {
        if (c?.bookmarkId === bookmarkId) {
          return {
            ...c,
            name,
            atAGlance
          };
        } else return c;
      })
      .sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      });
    if (structure) {
      if (_.size(newList4?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setStructureBookmarks(newList4);
    const copy5 = [...pipelineBookmarks];
    const newList5 = copy5
      ?.map((c) => {
        if (c?.bookmarkId === bookmarkId) {
          return {
            ...c,
            name,
            atAGlance
          };
        } else return c;
      })
      .sort((a, b) => {
        return a?.name && b.name
          ? a?.name?.localeCompare(b?.name)
          : a?.title?.localeCompare(b?.title);
      });
    if (pipeline) {
      if (_.size(newList5?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setPipelineBookmarks(newList5);
    setShowNotifications(true);
    setNotificationStatus('good');
    BookmarksService.updateBookmarksNameAndAtAGlance({
      name,
      atAGlance,
      bookmarkId
    });
  };

  const onDeleteAtAGlance = ({
    bookmarkId,
    site = false,
    filter = false,
    atAGlanceBook = false,
    equipment = false,
    structure = false,
    pipeline = false
  }) => {
    const copy = [...atAGlanceBookmarks];
    const newList = copy?.filter((c) => c.bookmarkId !== bookmarkId);
    if (atAGlanceBook) {
      if (_.size(newList?.filter((x) => x?.atAGlance)) === 0) setTab(0);
      dispatch?.(bookmarksActions.setBookmarkValidity(true));
    }
    setAtAGlanceBookmarks(newList);

    const copy1 = [...filterBookmarks];
    const newList1 = copy1?.filter((c) => c.bookmarkId !== bookmarkId);
    if (filter) {
      if (_.size(newList1?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setFilterBookmarks(newList1);

    const copy2 = [...siteBookmarks];
    const newList2 = copy2?.filter((c) => c.bookmarkId !== bookmarkId);
    if (site) {
      if (_.size(newList2?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setSiteBookmarks(newList2);

    const copy3 = [...equipmentBookmarks];
    const newList3 = copy3?.filter((c) => c.bookmarkId !== bookmarkId);
    if (equipment) {
      if (_.size(newList3?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setEquipmentBookmarks(newList3);
    const copy4 = [...structureBookmarks];
    const newList4 = copy4?.filter((c) => c.bookmarkId !== bookmarkId);
    if (structure) {
      if (_.size(newList3?.filter((x) => !x?.atAGlance)) === 0) setTab(0);
    }
    setStructureBookmarks(newList4);

    setShowNotifications(true);
    setNotificationStatus('bad');

    ToasterUtil.showSuccess(`Bookmark deleted`);

    BookmarksService.deleteBookmarks({
      bookmarkId
    });
  };
  const {
    equipmentBookmarkLength,
    sitesBookmarkLength,
    filterBookmarkLength,
    atAGlanceLength,
    structureBookmarkLength,
    pipelineBookmarkLength
  } = React.useMemo(() => {
    const equipmentBookmarkLength = _.size(equipmentBookmarks?.filter((x) => !x?.atAGlance));
    const sitesBookmarkLength = _.size(siteBookmarks?.filter((x) => !x?.atAGlance));
    const filterBookmarkLength = _.size(filterBookmarks?.filter((x) => !x?.atAGlance));
    const atAGlanceLength = _.size(atAGlanceBookmarks?.filter((x) => x?.atAGlance));
    const structureBookmarkLength = _.size(structureBookmarks?.filter((x) => !x?.atAGlance));
    const pipelineBookmarkLength = _.size(pipelineBookmarks?.filter((x) => !x?.atAGlance));
    return {
      equipmentBookmarkLength,
      sitesBookmarkLength,
      filterBookmarkLength,
      atAGlanceLength,
      structureBookmarkLength,
      pipelineBookmarkLength
    };
  }, [
    equipmentBookmarks,
    siteBookmarks,
    filterBookmarks,
    atAGlanceBookmarks,
    structureBookmarks,
    pipelineBookmarks
  ]);

  const bookmarksLengthIsZero = React.useMemo(() => {
    if (
      !equipmentBookmarkLength &&
      !structureBookmarkLength &&
      !pipelineBookmarkLength &&
      !sitesBookmarkLength &&
      !filterBookmarkLength &&
      !atAGlanceLength
    )
      return false;
    else return true;
  }, [
    sitesBookmarkLength,
    equipmentBookmarkLength,
    filterBookmarkLength,
    atAGlanceLength,
    structureBookmarkLength,
    pipelineBookmarkLength
  ]);

  const [onRenameOpen, setRenameOpen] = useState({});
  const [onDeleteOpen, setDeleteOpen] = useState({});
  const glanceLink = (bookmark) => {
    const name = bookmark?.name;

    if (
      bookmark?.type === 'Site Details' ||
      bookmark?.type === 'Parent Site Details' ||
      bookmark?.type === 'Equipment Details' ||
      bookmark?.type === 'Structure Details' ||
      bookmark?.type === 'Pipeline Details'
    )
      navigate(`${bookmark?.listing}?bookmarkName=${name}` || '/');
    else
      navigate(
        `${bookmark?.listing}?bookmarkFilter=${bookmark?.filters}&bookmarkName=${
          bookmark?.name
        }${`&bookmarkTab=${bookmark?.tab}`}${`&bookmarkKeyName=${bookmark?.keyName}`}${`&currentOpenTaskTab=${bookmark?.currentOpenTaskTab}`}` ||
          '/'
      );
  };

  const draggableComponent = () => {
    return (
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={() => {
          setDisableReactSwipe(true);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <div className={style.bookmarkItemMenu} ref={atAGlanceRef}>
                {atAGlanceBookmarks?.map(
                  (bookmark, index) =>
                    bookmark?.atAGlance && (
                      <Draggable
                        key={bookmark?.bookmarkId || index}
                        draggableId={bookmark?.bookmarkId || index}
                        index={index}
                      >
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps}>
                            <Bookmark
                              ref={atAGlanceRef}
                              selectedTab={'atAGlance'}
                              name={bookmark.name}
                              glance={bookmark.atAGlance}
                              noDrag={true}
                              onDelete={() =>
                                onDeleteAtAGlance({
                                  bookmarkId: bookmark?.bookmarkId,
                                  atAGlanceBook: true
                                })
                              }
                              setDeleteOpen={setDeleteOpen}
                              setRenameOpen={setRenameOpen}
                              onRename={({ name, atAGlance }) => {
                                renameAtAGlanceBookmarkEvent({
                                  name,
                                  atAGlance,
                                  bookmarkId: bookmark?.bookmarkId,
                                  atAGlanceBook: true
                                });
                                setNotificationTitle('Bookmark renamed');
                                ToasterUtil.showSuccess(`Bookmark renamed`);
                              }}
                              dragHandleProps={provided.dragHandleProps}
                              addToOrRemoveFromGlance={() => {
                                renameAtAGlanceBookmarkEvent({
                                  name: bookmark.name,
                                  atAGlance: false,
                                  bookmarkId: bookmark?.bookmarkId,
                                  atAGlanceBook: true
                                });
                                setNotificationTitle('Bookmark removed from ‘At a glance’');
                                ToasterUtil.showSuccess(`Bookmark removed from ‘At a glance’`);
                              }}
                              onClick={() => glanceLink(bookmark)}
                            />
                          </div>
                        )}
                      </Draggable>
                    )
                )}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const items = [
    ...(atAGlanceLength > 0
      ? [
          {
            label: `At a glance (${atAGlanceLength}) `,
            children:
              parseInt(tab) === 0 && atAGlanceLength > 0 ? (
                <Portal>
                  <div className={style.draggableWidth}>{draggableComponent()}</div>
                </Portal>
              ) : (
                draggableComponent()
              )
          }
        ]
      : []),

    ...(filterBookmarkLength > 0
      ? [
          {
            label: `Filters (${filterBookmarkLength}) `,
            children: (
              <div className={style.bookmarkItemMenu} ref={filterRef}>
                {filterBookmarks?.map(
                  (bookmark, index) =>
                    !bookmark?.atAGlance && (
                      <div key={bookmark?.bookmarkId || index}>
                        <Bookmark
                          ref={filterRef}
                          selectedTab={'filter'}
                          name={bookmark.name}
                          glance={bookmark.atAGlance}
                          onDelete={() =>
                            onDeleteAtAGlance({ bookmarkId: bookmark?.bookmarkId, filter: true })
                          }
                          setDeleteOpen={setDeleteOpen}
                          setRenameOpen={setRenameOpen}
                          onRename={({ name, atAGlance }) => {
                            renameAtAGlanceBookmarkEvent({
                              name,
                              atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              filter: true
                            });
                            setNotificationTitle('Bookmark renamed');
                            ToasterUtil.showSuccess(`Bookmark renamed`);
                          }}
                          addToOrRemoveFromGlance={() => {
                            renameAtAGlanceBookmarkEvent({
                              name: bookmark.name,
                              atAGlance: !bookmark?.atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              filter: true
                            });
                            setNotificationTitle('Bookmark added to ‘At a glance’');
                            ToasterUtil.showSuccess('Bookmark added to ‘At a glance’');
                          }}
                          onClick={() => glanceLink(bookmark)}
                        />
                      </div>
                    )
                )}
              </div>
            )
          }
        ]
      : []),

    ...(sitesBookmarkLength > 0
      ? [
          {
            label: `Sites (${sitesBookmarkLength}) `,
            children: (
              <div className={style.bookmarkItemMenu} ref={siteRef}>
                {siteBookmarks?.map(
                  (bookmark, index) =>
                    !bookmark?.atAGlance && (
                      <div key={bookmark?.bookmarkId || index}>
                        <Bookmark
                          ref={siteRef}
                          selectedTab={'site'}
                          key={bookmark?.bookmarkId || index}
                          name={bookmark.name}
                          glance={bookmark.atAGlance}
                          onDelete={() =>
                            onDeleteAtAGlance({ bookmarkId: bookmark?.bookmarkId, site: true })
                          }
                          setDeleteOpen={setDeleteOpen}
                          setRenameOpen={setRenameOpen}
                          onRename={({ name, atAGlance }) => {
                            renameAtAGlanceBookmarkEvent({
                              name,
                              atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              site: true
                            });
                            setNotificationTitle('Bookmark renamed');
                            ToasterUtil.showSuccess(`Bookmark renamed`);
                          }}
                          onClick={() => glanceLink(bookmark)}
                          addToOrRemoveFromGlance={() => {
                            renameAtAGlanceBookmarkEvent({
                              name: bookmark.name,
                              atAGlance: !bookmark?.atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              site: true
                            });
                            setNotificationTitle('Bookmark added to ‘At a glance’');
                            ToasterUtil.showSuccess('Bookmark added to ‘At a glance’');
                          }}
                        />
                      </div>
                    )
                )}
              </div>
            )
          }
        ]
      : []),

    ...(equipmentBookmarkLength > 0
      ? [
          {
            label: `Equipment (${equipmentBookmarkLength}) `,
            children: (
              <div className={style.bookmarkItemMenu} ref={equipmentRef}>
                {equipmentBookmarks?.map(
                  (bookmark, index) =>
                    !bookmark?.atAGlance && (
                      <div key={bookmark?.bookmarkId || index}>
                        <Bookmark
                          ref={equipmentRef}
                          selectedTab={'equipment'}
                          onClick={() => glanceLink(bookmark)}
                          key={bookmark?.bookmarkId || index}
                          name={bookmark.name}
                          glance={bookmark.atAGlance}
                          onDelete={() =>
                            onDeleteAtAGlance({
                              bookmarkId: bookmark?.bookmarkId,
                              equipment: true
                            })
                          }
                          setDeleteOpen={setDeleteOpen}
                          setRenameOpen={setRenameOpen}
                          onRename={({ name, atAGlance }) => {
                            setNotificationTitle('Bookmark renamed');
                            ToasterUtil.showSuccess(`Bookmark renamed`);

                            renameAtAGlanceBookmarkEvent({
                              name,
                              atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              equipment: true
                            });
                          }}
                          addToOrRemoveFromGlance={() => {
                            renameAtAGlanceBookmarkEvent({
                              name: bookmark.name,
                              atAGlance: !bookmark?.atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              equipment: true
                            });
                            setNotificationTitle('Bookmark added to ‘At a glance’');
                            ToasterUtil.showSuccess('Bookmark added to ‘At a glance’');
                          }}
                        />
                      </div>
                    )
                )}
              </div>
            )
          }
        ]
      : []),

    ...(structureBookmarkLength > 0
      ? [
          {
            label: `Structure (${structureBookmarkLength}) `,
            children: (
              <div className={style.bookmarkItemMenu} ref={structureRef}>
                {structureBookmarks?.map(
                  (bookmark, index) =>
                    !bookmark?.atAGlance && (
                      <div key={bookmark?.bookmarkId || index}>
                        <Bookmark
                          ref={structureRef}
                          selectedTab={'structure'}
                          onClick={() => glanceLink(bookmark)}
                          key={bookmark?.bookmarkId || index}
                          name={bookmark.name}
                          glance={bookmark.atAGlance}
                          onDelete={() =>
                            onDeleteAtAGlance({
                              bookmarkId: bookmark?.bookmarkId,
                              structure: true
                            })
                          }
                          setDeleteOpen={setDeleteOpen}
                          setRenameOpen={setRenameOpen}
                          onRename={({ name, atAGlance }) => {
                            setNotificationTitle('Bookmark renamed');
                            ToasterUtil.showSuccess(`Bookmark renamed`);

                            renameAtAGlanceBookmarkEvent({
                              name,
                              atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              structure: true
                            });
                          }}
                          addToOrRemoveFromGlance={() => {
                            renameAtAGlanceBookmarkEvent({
                              name: bookmark.name,
                              atAGlance: !bookmark?.atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              structure: true
                            });
                            setNotificationTitle('Bookmark added to ‘At a glance’');
                            ToasterUtil.showSuccess('Bookmark added to ‘At a glance’');
                          }}
                        />
                      </div>
                    )
                )}
              </div>
            )
          }
        ]
      : []),
    ...(pipelineBookmarkLength > 0
      ? [
          {
            label: `Pipeline (${pipelineBookmarkLength}) `,
            children: (
              <div className={style.bookmarkItemMenu} ref={pipelineRef}>
                {pipelineBookmarks?.map(
                  (bookmark, index) =>
                    !bookmark?.atAGlance && (
                      <div key={bookmark?.bookmarkId || index}>
                        <Bookmark
                          ref={pipelineRef}
                          selectedTab={'pipeline'}
                          onClick={() => glanceLink(bookmark)}
                          key={bookmark?.bookmarkId || index}
                          name={bookmark.name}
                          glance={bookmark.atAGlance}
                          onDelete={() =>
                            onDeleteAtAGlance({
                              bookmarkId: bookmark?.bookmarkId,
                              pipeline: true
                            })
                          }
                          setDeleteOpen={setDeleteOpen}
                          setRenameOpen={setRenameOpen}
                          onRename={({ name, atAGlance }) => {
                            setNotificationTitle('Bookmark renamed');
                            ToasterUtil.showSuccess(`Bookmark renamed`);

                            renameAtAGlanceBookmarkEvent({
                              name,
                              atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              pipeline: true
                            });
                          }}
                          addToOrRemoveFromGlance={() => {
                            renameAtAGlanceBookmarkEvent({
                              name: bookmark.name,
                              atAGlance: !bookmark?.atAGlance,
                              bookmarkId: bookmark?.bookmarkId,
                              pipeline: true
                            });
                            setNotificationTitle('Bookmark added to ‘At a glance’');
                            ToasterUtil.showSuccess('Bookmark added to ‘At a glance’');
                          }}
                        />
                      </div>
                    )
                )}
              </div>
            )
          }
        ]
      : []),
    ...(othersBookmarks.length > 0
      ? [
          {
            label: `Others (${_.size(othersBookmarks)}) `,
            children: <></>
          }
        ]
      : [])
  ];

  (function (timer) {
    let el = document.querySelector('#bookmarksLists');
    el?.addEventListener('scroll', function (e) {
      (function (el) {
        el.classList.add('addScrollbar');
        clearTimeout(timer);
        timer = setTimeout(function () {
          el.classList.remove('addScrollbar');
        }, 100);
      })(el);
    });
  })();

  return (
    <>
      <PageHeader title="Bookmarks" />

      {bookmarksLengthIsZero ? (
        <div id="bookmarksLists" className={`removeScrollbar ${style.bookmarksHeader} `}>
          <NewTabs
            active={tab}
            tabs={items}
            setActive={(tab) => setTab(tab)}
            className="myStyle"
            disableReactSwipe={disableReactSwipe}
          />

          {onRenameOpen?.open && (
            <EditBookmarkModal
              open={onRenameOpen?.open}
              setOpen={onRenameOpen?.setOpen}
              name={onRenameOpen.name}
              atAGlance={onRenameOpen.atAGlance}
              onRename={onRenameOpen.onRename}
            />
          )}
          {onDeleteOpen?.open && (
            <DeleteModal
              open={onDeleteOpen.open}
              setOpen={onDeleteOpen.setOpen}
              handleDelete={onDeleteOpen.handleDelete}
              confirmDeletionBookmark={onDeleteOpen.confirmDeletionBookmark}
            />
          )}
        </div>
      ) : (
        <div className={style.noBookmarkSection}>
          <p className={style.firstDiv}>No bookmarks here.</p>
          <p className={style.secondDiv}>
            Learn more about how to create bookmark{' '}
            <span
              onClick={() => {
                navigate('/help?selectedSetting=Tips and guides&selectedTipAndGuides=Bookmarks');
              }}
            >
              here
            </span>
            .
          </p>
        </div>
      )}
    </>
  );
};

export default BookmarksList;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};
