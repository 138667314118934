/* eslint-disable no-unused-vars */
import React from 'react';

import Modal from '../..';
import Icon from '@components/icon-component';

import style from './style.module.scss';

const DeselectOtherTask = ({ open, setOpen, handleProceed }) => {
  const modalTextUpper = React.useMemo(() => {
    return (
      <>
        Some tasks can only be opened one at a time.
        <br />
        <div style={{ height: '16px' }}></div>
        <span
          style={{
            marginTop: '12px',
            marginBottom: '32px'
          }}
        >
          All other tasks will be deselected.
        </span>
      </>
    );
  }, []);

  const modalBodyInner = React.useMemo(() => {
    return (
      <div>
        <div className={style.modalBtn}>
          <div style={{ height: '19px' }}></div>
          <button className={`btn-fill button-large`} onClick={handleProceed}>
            Proceed
          </button>
        </div>
      </div>
    );
  }, []);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={<Icon name={'de-select-icon'} />}
      statusText={'Deselect other tasks?'}
      modalTextUpper={modalTextUpper}
      textBtn={'NeverMind'}
    >
      {modalBodyInner}
    </Modal>
  );
};

export default DeselectOtherTask;
