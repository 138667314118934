import TaskDetailComponent from '@components/task-detail-component';

import { PERMISSIONS } from 'permissions/constants';
import { decodeToken } from '@utilities/authentication';
import Icon from '@components/icon-component';

const Tasks = ({
  setAllTask,
  setWorkOrder,
  // setWorkRequest,
  setHseEvent,
  setChecks,
  setInspections,
  allWorkOrderForSite,
  allDoneWorkOrder,
  allInspectionForSite,
  allDoneInspection,
  allCheckForSite,
  allDoneChecks = [],
  isCheckOverDue,
  allTasksForSite,
  allDoneTasks = [],
  isOverDue,
  isInspectionOverDue,
  isWorkOrderOverDue,
  // newWorkRequestList,
  hseEvents
}) => {
  const taskDetail = [
    {
      img: <Icon name={'task-grey'} />,
      taskName: 'All tasks',
      btnText: `${allDoneTasks.length}/${allTasksForSite.length}`,
      click: () => setAllTask(true),
      allDoneTasks: allDoneTasks,
      allTasksForSite: allTasksForSite,
      isOverDue: isOverDue,
      dot: !!isOverDue && allDoneTasks?.length !== allTasksForSite?.length
    },
    {
      img: <Icon name={'ins'} />,
      taskName: 'Inspections',
      btnText: `${allDoneInspection.length}/${allInspectionForSite.length}`,
      click: () => setInspections(true),
      allDoneTasks: allDoneInspection,
      allTasksForSite: allInspectionForSite,
      isOverDue: isInspectionOverDue,
      dot: !!isOverDue && allDoneInspection?.length !== allInspectionForSite?.length
    },
    {
      img: <Icon name={'work-orders'} />,
      taskName: 'Work orders',
      btnText: `${allDoneWorkOrder.length}/${allWorkOrderForSite.length}`,
      click: () => setWorkOrder(true),
      allDoneTasks: allDoneWorkOrder,
      allTasksForSite: allWorkOrderForSite,
      isOverDue: isWorkOrderOverDue,
      dot: !!isOverDue && allDoneWorkOrder?.length !== allWorkOrderForSite?.length
    },
    // {
    //   img: <Icon name={'work-orders'} />,
    //   taskName: 'Work requests',
    //   btnText: `${newWorkRequestList?.length}`,
    //   click: () => setWorkRequest(true),
    //   allDoneTasks: [],
    //   allTasksForSite: newWorkRequestList,
    //   isOverDue: false,
    //   dot: false,
    //   notShowInProgressCount: true
    // },
    {
      img: <Icon name={'hse'} />,
      taskName: 'HSE events',
      btnText: `${hseEvents?.length}`,
      click: () => setHseEvent(true),
      allDoneTasks: [],
      allTasksForSite: hseEvents,
      isOverDue: false,
      dot: false,
      notShowInProgressCount: true,
      showBtnGrayCount: true
    },
    {
      img: <Icon name={'check-grey'} />,
      taskName: 'Checks',
      btnText: `${allDoneChecks?.length}/${allCheckForSite?.length}`,
      click: () => setChecks(true),
      allDoneTasks: allDoneChecks,
      allTasksForSite: allCheckForSite,
      isOverDue: isCheckOverDue,
      dot: !!isOverDue && allDoneChecks?.length !== allCheckForSite?.length
    }
  ];
  const permissions = decodeToken()?.permissions || {};

  return (
    <div>
      {taskDetail
        ?.filter(({ taskName }) => {
          if (taskName === 'Checks' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS])
            return false;
          if (taskName === 'Inspections' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS])
            return false;
          if (taskName === 'Work orders' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS])
            return false;
          if (taskName === 'Work requests' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS])
            return false;
          if (taskName === 'HSE events' && !permissions[PERMISSIONS.MOBILE_CREATE_HSE])
            return false;
          return true;
        })
        .filter((a) => a.allTasksForSite?.length > 0)
        .map((ele, index) => {
          return ele.taskName === 'All Tasks' &&
            taskDetail?.filter((a) => a.allTasksForSite?.length > 0).length === 2 ? (
            <></>
          ) : (
            <TaskDetailComponent
              key={index}
              task={ele.taskName}
              onClick={ele.click}
              isOverDue={isOverDue}
              {...ele}
            />
          );
        })}
    </div>
  );
};

export default Tasks;
