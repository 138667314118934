import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { WorkTypes } from '@components/work-order/helper';

export const isNewForm = () => {
  const [searchParams] = useSearchParams();
  const [newForm, setNewForm] = useState(false);
  const [selectedType, setSelectedType] = useState({
    tasksList: false,
    inspection: false,
    checks: false,
    hse: false
  });

  const workType = searchParams.get('type') || '';
  const orderIds = searchParams.get('orderIds') || '';

  useEffect(() => {
    const res =
      [
        WorkTypes.NewWorkRequest,
        WorkTypes.NewWorkOrder,
        WorkTypes.NewInspection,
        WorkTypes.NewHSE,
        WorkTypes.NewCheck
      ].includes(workType) && !orderIds
        ? true
        : false;

    const tasksList =
      WorkTypes.NewWorkRequest ||
      WorkTypes.NewWorkOrder ||
      WorkTypes.WorkOrders ||
      WorkTypes.InspectionAndWorkOrder ||
      WorkTypes.WorkOrderAndInspection;

    const inspection =
      WorkTypes.NewInspection ||
      WorkTypes.Inspection ||
      WorkTypes.InspectionAndWorkOrder ||
      WorkTypes.WorkOrderAndInspection;

    const checks = WorkTypes.NewCheck || WorkTypes.Check;

    const hse = WorkTypes.NewHSE;

    setNewForm(res);
    setSelectedType({
      tasksList,
      inspection,
      checks,
      hse
    });
  }, [workType, orderIds]);

  return {
    newForm,
    workType,
    orderIds,
    selectedType,
    orderIdsLength: orderIds?.split(',')?.length
  };
};
