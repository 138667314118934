/* eslint-disable no-unused-vars */
import React, { useState, memo } from 'react';
import _ from 'lodash';
import { store } from 'redux/store';

import Tree from '@components/tree-component/tree';
import Accordion from '@components/accordion';
import FilterChips from '@components/filters/filter-chips';

import style from './collapseable-tree.module.scss';

const CollapseableTree = ({ header, value, isActive, onChange, treeData, treeDataMap = {} }) => {
  const [open, setOpen] = useState(true);
  React.useEffect(() => setOpen(isActive), [isActive]);
  const { picklists } = store.getState();
  const { areaHierarchyDataMap = {} } = picklists;

  const deleteSelectedChip = (tag) => {
    onChange(value.filter((el) => el !== tag));
  };
  return (
    <Accordion
      open={open}
      setOpen={setOpen}
      title={header}
      isSelected={!open}
      selectedChildren={
        !open &&
        _.size(value) > 0 && (
          <SelectedCollapseableFilter
            chips={value}
            deleteSelectedChip={deleteSelectedChip}
            tagsMap={areaHierarchyDataMap}
          />
        )
      }
    >
      <Tree
        treeData={treeData}
        keys={value}
        className={`op-filter-tree ${header === 'Area' ? style.cstmTree : ''}`}
        onCheck={(e) => {
          onChange(e);
        }}
      />
    </Accordion>
  );
};

export default memo(CollapseableTree, _.isEqual);

export const SelectedCollapseableFilter = ({ chips, deleteSelectedChip, tagsMap }) => {
  return (
    <div className={style.selectFilterDiv}>
      {chips?.map((ele, index) => (
        <FilterChips
          key={index}
          chipText={tagsMap[ele]}
          deleteChip={() => deleteSelectedChip(ele)}
        />
      ))}
    </div>
  );
};
