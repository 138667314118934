import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SettingsValueCard from './settings-value-card';
import RadioGroup from '@components/radio-group';
import SettingsInput from './settings-input';

import { typeOptions, whenOptions } from '../helper';
import { settingsActions } from '@redux/slices/settings';

const SyncRemindersSettings = () => {
  const dispatch = useDispatch();
  const { settings = {} } = useSelector((state) => state.settings);

  return (
    <div className="settings-card">
      <div className="settings--values">
        <SettingsValueCard
          title={'Prompt to sync on connection'}
          description={
            'Upon reconnecting to a mobile network and/or Wi-Fi, Oplii will prompt you to sync completed tasks/records:'
          }
        >
          <RadioGroup
            options={whenOptions}
            value={settings['autoConnectPromptSyncConnection']}
            onChange={(value) =>
              dispatch?.(
                settingsActions.updateSettingsByName({
                  name: 'autoConnectPromptSyncConnection',
                  value
                })
              )
            }
          />
        </SettingsValueCard>
        <SettingsValueCard
          title={'Prompt to refresh data'}
          description={'Remind me to refresh data after:'}
        >
          <SettingsInput
            options={typeOptions}
            name={'syncReminderNum'}
            selectName={'syncReminderTimeframe'}
            disabled={settings['autoConnectPromptSyncSchedule'] === 'never'}
          />
          <div style={{ marginBottom: '8px' }} />
          <RadioGroup
            options={whenOptions}
            value={settings['autoConnectPromptSyncSchedule']}
            onChange={(value) =>
              dispatch?.(
                settingsActions.updateSettingsByName({
                  name: 'autoConnectPromptSyncSchedule',
                  value
                })
              )
            }
          />
        </SettingsValueCard>
      </div>
    </div>
  );
};

export default memo(SyncRemindersSettings);
