/* eslint-disable no-unused-vars */

import moment from 'moment';
import _ from 'lodash';

import InspectionSelector from './inspection/inspection.selector';

import { getDataPoints, getMoreInfoDataPoints } from '../pages/settings/helper';
import TasksSelector from './tasks/tasks.selector';
import { getTaskAssignedUserTags } from './tasks/helper';
import CheckSelector from './checks/check.selector';

const PipelineSelector = {
  getPipelineWithWO: async ({ id = '', formWrapper = false, store }) => {
    const { tasks, auth, settings, picklists, pipeline, inspection = {}, checks = {} } = store;
    let allWOTasks = [],
      allInspections = [],
      allChecks = [];
    if (id) {
      const [tasksResponse, inspectionsResponse, checksResponse] = !formWrapper
        ? await Promise.all([
            await TasksSelector.getTasks({ store }),
            await InspectionSelector.getInspections({ store }),
            await CheckSelector.getAllChecks({ store })
          ])
        : [[], [], []];
      allWOTasks = tasksResponse?.filter((f) => f.id && !f.newWorkOrder && !f.newWorkRequest);

      allInspections = inspectionsResponse;
      allChecks = checksResponse;
    } else {
      allWOTasks = tasks.tasksList?.filter((f) => f.id && !f.newWorkOrder && !f.newWorkRequest);
      allInspections = inspection?.inspectionsList;
      allChecks = checks?.checksList;
    }

    return new Promise((resolve) => {
      const { pipelineMobile = [] } = pipeline;
      const { workOrderUsersMap, workOrderUsersGroupsMap } = tasks;
      const userId = auth.userId || '';

      const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = picklists;

      const pipelineDisplayFields = settings.settings?.['pipelineDisplayFields'] || '';

      const responsibleUsersMap = {
        ...(workOrderUsersMap || {}),
        ...(workOrderUsersGroupsMap || {})
      };

      const pipelines = id ? pipelineMobile?.filter((x) => x?.pipelineId === id) : pipelineMobile;

      const inspectionsAndWorkOrders = [...allWOTasks, ...allInspections, ...allChecks];

      let preparePipelines = [];

      pipelines?.map((pipeline) => {
        let allTasksAndInspectionsForPipeline = inspectionsAndWorkOrders.filter(
          (t) =>
            t.pipelineId === pipeline.pipelineId ||
            t?.relatedAssetId === pipeline.pipelineId ||
            t?.primaryAssetId === pipeline.pipelineId ||
            t?.pipelines?.includes(pipeline.pipelineId)
        );

        allTasksAndInspectionsForPipeline = allTasksAndInspectionsForPipeline.map((tas) => {
          const { responsibleUsers = [], responsibleUserGroups = [] } = tas;
          const customAssignedTaskValue = getTaskAssignedUserTags({
            responsibleUsers,
            responsibleUserGroups,
            workOrderUsersGroupsMap,
            userId,
            responsibleUsersMap
          });
          return {
            customAssignedTaskValue,
            ...tas
          };
        });

        const detailedData = PipelineSelector.getDetailedData(allTasksAndInspectionsForPipeline);

        const today = moment().format('YYYY-MM-DD');
        const allDoneTasks = allTasksAndInspectionsForPipeline.filter(
          (task) => task?.status === 'Synced'
        );
        const allotDoneTasks = allTasksAndInspectionsForPipeline.filter(
          (task) => task?.status !== 'Synced'
        );

        const isOverDue = allotDoneTasks.find((task) =>
          task.dueDate ? moment(task.dueDate).isBefore(today) : false
        );

        const dataPoints = getDataPoints(
          {
            ...pipeline,
            area: areaHierarchyTreeDataMap[pipeline?.area]?.title,
            type: pipeline?.pipelineTypeId || null,
            approvalNumber: pipeline?.ApprovalNumber || null
          },
          pipelineDisplayFields,
          'pipeline',
          picklistsMap
        );

        const MoreInfoDataPoints = getMoreInfoDataPoints(
          {
            ...pipeline,
            area: areaHierarchyTreeDataMap[pipeline?.area]?.title,
            type: pipeline?.pipelineTypeId || null
          },
          'pipeline',
          picklistsMap
        );

        const MoreInfoDataPointsMap = MoreInfoDataPoints.reduce((acc, curr) => {
          acc[curr.Id] = curr;
          return acc;
        }, {});

        const status = picklistsMap[pipeline?.status]?.value || '';
        MoreInfoDataPointsMap['Status'] = { value: status, title: 'Status' };

        const siteIds = new Set([
          ...(pipeline?.fromSite ? [pipeline.fromSite] : []),
          ...(pipeline?.toSite ? [pipeline.toSite] : []),
          ...(pipeline?.sites?.map((x) => x.toLowerCase()) ?? []),
          ...(pipeline?.wells?.map((x) => x.toLowerCase()) ?? [])
        ]);

        const uniqueSiteIds = Array.from(siteIds);

        preparePipelines.push({
          ...pipeline,
          title: `${pipeline?.licenseNumber}-${pipeline?.lineNumber}`,
          status: !formWrapper ? pipeline?.status : null,
          allDoneTasks,
          isOverDue,
          allTasksAndInspectionsForPipeline,
          allotDoneTasks,
          MoreInfoDataPointsMap,
          ...(id && detailedData),
          siteIds: uniqueSiteIds,

          dataPoints,
          soonestDueDate: _.min(
            allotDoneTasks.map((t) => t?.due || t?.dueDate).map((date) => new Date(date))
          )
        });
      });
      resolve(preparePipelines);
    });
  },

  getDetailedData: (allTasks) => {
    const today = moment().format('YYYY-MM-DD');

    const allWorkOrder = allTasks.filter((x) => x.type === 'Work Order');
    const allDoneWorkOrder = allWorkOrder.filter((x) => x.status === 'Synced');
    const allotDoneWorkOrder = allWorkOrder.filter((task) => task?.status !== 'Synced');

    const isWorkOrderOverDue = allotDoneWorkOrder.find((task) =>
      task.dueDate ? moment(task.dueDate).isBefore(today) : false
    );

    const allInspections = allTasks.filter((x) => x.type === 'Inspection');
    const allDoneInspection = allInspections.filter((x) => x.status === 'Synced');
    const allotDoneInspection = allInspections.filter((x) => x.status !== 'Synced');

    const isInspectionOverDue = allotDoneInspection.find((task) =>
      task.dueDate ? moment(task.dueDate).isBefore(today) : false
    );

    const allChecks = allTasks.filter((x) => x.type === 'Check');
    const allDoneCheck = allChecks.filter((x) => x.status === 'Synced');
    const allNotDoneCheck = allChecks.filter((x) => x.status !== 'Synced');

    const isCheckOverDue = allNotDoneCheck.find((task) =>
      task.nextDue ? moment(task.nextDue).isBefore(today) : false
    );

    return {
      allWorkOrder,
      allDoneWorkOrder,
      isWorkOrderOverDue,
      allInspections,
      allDoneInspection,
      isInspectionOverDue,
      allChecks,
      allDoneCheck,
      isCheckOverDue
    };
  }
};

export default PipelineSelector;
