import _ from 'lodash';
import React, { useState } from 'react';

import Accordion from '@components/accordion';
import Divider from '@components/divider';
import FilterChips from '@components/filters/filter-chips';
import PicklistPills from '@components/picklist-pills';

import style from './priority.module.scss';

const PriorityFilter = ({
  isActive = true,
  title,
  multi,
  tags1,
  tagsMap1 = {},
  value1,
  onChange1,
  label1,
  selectValue1,
  tags2,
  tagsMap2 = {},
  value2,
  onChange2,
  label2,
  selectValue2
}) => {
  const [open, setOpen] = useState(true);
  React.useEffect(() => setOpen(isActive), [isActive]);

  const selected1 =
    value1 &&
    value1.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

  const selected2 =
    value2 &&
    value2.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

  const handleChange1 = (tag, checked) => {
    if (!multi) onChange1(checked ? [tag] : []);
    else {
      if (checked) onChange1([...value1, tag]);
      else onChange1(value1.filter((el) => tag !== el));
    }
  };

  const handleChange2 = (tag, checked) => {
    if (!multi) onChange2(checked ? [tag] : []);
    else {
      if (checked) onChange2([...value2, tag]);
      else onChange2(value2.filter((el) => tag !== el));
    }
  };

  const deleteSelectedChip1 = (tag) => {
    onChange1(value1.filter((el) => (tagsMap1[tag]?.key || tag) !== el));
  };

  const deleteSelectedChip2 = (tag) => {
    onChange2(value2.filter((el) => (tagsMap2[tag]?.key || tag) !== el));
  };

  return (
    <div>
      <Accordion
        open={open}
        setOpen={setOpen}
        title={title}
        isSelected={!open}
        selectedChildren={
          !open && (
            <SelectedPriorityFilter
              label1={label1 || ''}
              chip1={value1 || []}
              deleteSelectedChip1={deleteSelectedChip1 || null}
              tagsMap1={tagsMap1 || {}}
              label2={label2 || ''}
              chip2={value2 || []}
              deleteSelectedChip2={deleteSelectedChip2 || null}
              tagsMap2={tagsMap2 || {}}
            />
          )
        }
      >
        {label1 && (
          <>
            <p className={style.p}>{label1}</p>
            <div className={style.selectFilterDiv}>
              {tags1?.map((ele, index) => (
                <PicklistPills
                  key={ele?.key || index}
                  text={ele?.value || ele}
                  selected={
                    selected1?.length > 0
                      ? selected1[(selectValue1 ? ele?.value : ele?.key) || ele]
                      : null
                  }
                  handleChange={(checked) => {
                    handleChange1((selectValue1 ? ele?.value : ele?.key) || ele, checked);
                  }}
                />
              ))}
            </div>
            <div style={{ margin: '16px 0px' }}>{label2 && <Divider />}</div>
          </>
        )}
        {label2 && (
          <>
            <p className={style.p}>{label2}</p>
            <div className={style.selectFilterDiv}>
              {tags2?.map((ele, index) => (
                <PicklistPills
                  key={ele.key || index}
                  text={ele.value || ele}
                  selected={selected2 && selected2[(selectValue2 ? ele?.value : ele?.key) || ele]}
                  handleChange={(checked) => {
                    handleChange2((selectValue2 ? ele?.value : ele?.key) || ele, checked);
                  }}
                />
              ))}
            </div>
          </>
        )}
      </Accordion>
    </div>
  );
};

export default React.memo(PriorityFilter, _.isEqual);

export const SelectedPriorityFilter = ({
  label1,
  chip1,
  deleteSelectedChip1,
  tagsMap1,
  label2,
  chip2,
  deleteSelectedChip2,
  tagsMap2
}) => {
  return (
    <>
      {chip1?.length > 0 && (
        <>
          <p className={style.p}>{label1}</p>
          <div className={style.selectFilterDiv}>
            {chip1 &&
              chip1?.map((ele, index) => (
                <FilterChips
                  key={index}
                  chipText={tagsMap1[ele]?.value || ele}
                  deleteChip={() => deleteSelectedChip1(ele)}
                />
              ))}
          </div>
        </>
      )}
      {chip1?.length > 0 && chip2.length > 0 && (
        <div style={{ margin: '16px 0px' }}>
          <Divider />
        </div>
      )}

      {chip2.length > 0 && (
        <>
          <p className={style.p}>{label2}</p>
          <div className={style.selectFilterDiv}>
            {chip2 &&
              chip2?.map((ele, index) => (
                <FilterChips
                  key={index}
                  chipText={tagsMap2[ele]?.value || ele}
                  deleteChip={() => deleteSelectedChip2(ele)}
                />
              ))}
          </div>
        </>
      )}
    </>
  );
};
