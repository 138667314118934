import React, { memo, useMemo, forwardRef } from 'react';
import { useLocation } from 'react-router-dom';

import TaskTemplate from '../tasks-template-records-list/task-template';
import FilterSort from '@components/filters/filter-sort';
import LoadingRound from '@components/loading-round/loading-round';
import TaskRecordListTabs from './task-record-list-tabs';

import style from './style.module.scss';

const TasksInspectionsTemplateAndRunsLists = (
  {
    checkedLength,
    asset,
    siteDetails,
    parentSiteDetails,
    runs,
    templates,
    isFilterActive,
    templateData,
    setTemplateData,
    filtersCount,
    tagsDataRT,
    setFilterActive,
    SelectedFilterRT,
    setSelectedFilterRT,
    openModal,
    searched,
    loading,
    tagsData,
    SelectedFilter,
    setSelectedFilter,
    bookmarkFilter,
    tab,
    setTab,
    data,
    tasksMap,
    setData,
    setSelectedOrderIds,
    onSelectionNewWorkOrderOrRequest,
    workRequest,
    hseEvent
  },
  ref
) => {
  const { tabsTasksRef } = ref;
  const location = useLocation();

  const { taskRecordListClass } = useMemo(() => {
    const taskRecordListClass = `removeScrollbar ${style['page-listing']} ${
      checkedLength > 0 && 'no-appbar'
    } ${checkedLength > 0 || asset ? 'marginNo' : 'marginYes'} ${
      (siteDetails || parentSiteDetails || !(runs?.length > 0 || templates?.length > 0)) &&
      'no-scroller'
    } ${asset && style.assetDetailsOnSmallScreen} `;

    return { taskRecordListClass };
  }, [checkedLength, asset, siteDetails, parentSiteDetails, runs, templates]);

  const checkWhite = useMemo(() => {
    return loading ? false : location.pathname === '/task-records' ? true : false;
  }, [loading]);

  return (
    <div
      id="tasksRecordsListsTabs1"
      className={taskRecordListClass}
      style={{
        borderLeft: isFilterActive ? '0.5px solid #cbd2d9' : 'none',
        height: siteDetails || parentSiteDetails ? '' : '100%',
        flex: 1
      }}
      ref={tabsTasksRef}
    >
      {runs?.length || templates?.length ? (
        <div className="rts-wrapper">
          <TaskTemplate
            templateData={templateData}
            setTemplateData={setTemplateData}
            filtersCount={filtersCount}
            tagsDataRT={tagsDataRT}
            isFilterActive={isFilterActive}
            setFilterActive={setFilterActive}
            SelectedFilterRT={SelectedFilterRT}
            setSelectedFilterRT={setSelectedFilterRT}
            openModal={openModal}
            searched={searched}
            setSelectedOrderIds={setSelectedOrderIds}
          />
        </div>
      ) : (
        <div
          className="tabs-tasks"
          style={{
            backgroundColor: 'transparent',
            paddingLeft: '0px'
          }}
        >
          <FilterSort
            white={checkWhite}
            count={filtersCount}
            tagsData={tagsData}
            isFilterActive={isFilterActive}
            setFilterActive={setFilterActive}
            SelectedFilter={SelectedFilter}
            setSelectedFilter={setSelectedFilter}
          />

          <div>
            {loading ? (
              <div style={{ backgroundColor: 'transparent', width: '100%' }}>
                <LoadingRound />
              </div>
            ) : (
              <TaskRecordListTabs
                bookmarkFilter={bookmarkFilter}
                tab={tab}
                asset={asset}
                setTab={setTab}
                data={data}
                tasksMap={tasksMap || {}}
                SelectedFilter={SelectedFilter}
                isFilterActive={isFilterActive}
                searched={searched}
                openModal={openModal}
                setData={setData}
                setSelectedOrderIds={setSelectedOrderIds}
                onSelectionNewWorkOrderOrRequest={onSelectionNewWorkOrderOrRequest}
                white={checkWhite}
                runs={runs}
                workRequest={workRequest}
                hseEvent={hseEvent}
                checkedLength={checkedLength}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(forwardRef(TasksInspectionsTemplateAndRunsLists));
