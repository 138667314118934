import style from './label.module.scss';

const ErrorLabel = ({ error, reconciled, className }) => {
  return (
    <div className={`${style.labelWrapper} ${className}`}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span className={style.labelText}></span>
      </div>
      {error && !reconciled && <div>{<span className={style.error}>{error}</span>}</div>}
      {error && reconciled && <div>{<span className={style.reconciled}>{error}</span>}</div>}
    </div>
  );
};

export default ErrorLabel;
