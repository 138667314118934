export { default } from './app-bar.component';

const addToSite = [
  { type: 'equipment', text: 'New Equipment', iconType: 'fill' },
  { type: 'inspection', text: 'Inspection', iconType: 'stroke' },
  { type: 'work-order', text: 'Work order', iconType: 'stroke' },
  { type: 'work-order', text: 'Work request', iconType: 'stroke' },
  { type: 'hse-event', text: 'HSE event', iconType: 'stroke' },
  { type: 'check', text: 'Check', iconType: 'stroke' }
];
const addToStructure = addToSite.filter(({ type }) => type === 'work-order' || type === 'check');
const addToEquipment = addToSite.filter(
  ({ type }) => type === 'inspection' || type === 'work-order' || type === 'check'
);

export const addToOptions = {
  site: addToSite,
  'parent site': addToSite,
  structure: addToStructure,
  equipment: addToEquipment,
  pipeline: addToEquipment,
  default: addToSite.filter(({ type }) => type !== 'equipment')
};
