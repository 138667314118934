/* eslint-disable no-unused-vars */

import React, { useEffect, useState, memo } from 'react';
import _ from 'lodash';

import Icon from '@components/icon-component';
import Checkbox from '@components/checkbox/checkbox.component';

import style from './tree.module.scss';

const TreeNode = ({
  title,
  children,
  id,
  onTreeEvent,
  parentKey,
  info,
  is_open = false,
  is_partial = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const onChangeHandler = (e) => {
    onTreeEvent({ selectedId: id, parentKey: parentKey, info });
  };

  return (
    <li itemID={id}>
      <div className={style.treeItem}>
        <div className={style.check}>
          {children?.length > 0 && (
            <span onClick={handleClick}>
              <Icon name={isOpen ? 'minus-icon-tree' : 'plus-icon-tree'} />
            </span>
          )}

          <span className={style.title}>{title}</span>
        </div>
        <Checkbox
          checked={is_partial ? is_partial : is_open}
          id={id}
          handleChange={() => {
            onChangeHandler();
          }}
          partial={is_partial}
          labelClassName={style.labelClass}
        />
      </div>
      {isOpen && children?.length > 0 && (
        <ul className={style.childtree}>
          {children.map((child) => (
            <TreeNode
              key={child.key}
              {...child}
              id={child.key}
              onTreeEvent={onTreeEvent}
              info={child}
            />
          ))}
        </ul>
      )}
    </li>
  );
};

const Tree = ({ treeData, keys, onCheck }) => {
  const [copyData, setCopyData] = useState([]);

  const treeDataHandler = (treeData) => {
    return treeData?.map((x) => {
      return {
        ...x,
        is_open: false,
        is_partial: false,
        children: x?.children?.length > 0 ? treeDataHandler(x.children) : []
      };
    });
  };
  useEffect(() => {
    const _treeData = treeDataHandler(treeData);

    if (_.size(keys) > 0) {
      keys.forEach((key) => {
        _treeData?.forEach((x) => {
          const obj = findObjectByKey(x, key);
          if (obj) {
            obj.is_open = true;
          }
        });
      });

      for (const node of _treeData) {
        checkOpenStatus(node);
      }
      setCopyData([..._treeData]);
    } else {
      const resetArray = resetValue(_treeData);
      setCopyData([...resetArray]);
    }
  }, [treeData, keys]);

  const onTreeEvent = ({ selectedId }) => {
    const ab = copyData;
    findAndUpdateNode(copyData, selectedId, ab);
    const newArray1 = [...copyData];
    for (const node of newArray1) {
      checkOpenStatus(node);
    }
    setCopyData([...newArray1]);
    const keysWithOpenFlag = [];
    newArray1.forEach((obj) => {
      getKeysWithOpenFlag(obj, keysWithOpenFlag);
    });
    onCheck(keysWithOpenFlag);
  };

  return (
    <ul className={`${style.mainTree} `}>
      {copyData.map((node) => (
        <TreeNode key={node.key} id={node.key} {...node} onTreeEvent={onTreeEvent} info={node} />
      ))}
    </ul>
  );
};

export default memo(Tree);

const getKeysWithOpenFlag = (obj, keys) => {
  if (obj.is_open) {
    keys.push(obj.key);
  }
  if (obj.children) {
    obj.children.forEach((child) => {
      getKeysWithOpenFlag(child, keys);
    });
  }
  return keys;
};
const resetValue = (array) => {
  array.forEach(function (object) {
    object.is_open = false;
    object.is_partial = false;
    if (object.children.length > 0) {
      object.children.forEach(function (child) {
        resetValue(object.children);
      });
    }
  });
  return array;
};

const findAndUpdateObject = (array, key) => {
  array.forEach(function (object) {
    if (object.key === key) {
      object.is_open = true;
      if (object.children.length > 0) {
        object.children.forEach(function (child) {
          findAndUpdateObject(object.children, child.key);
        });
      }
    } else if (object.children.length > 0) {
      findAndUpdateObject(object.children, key);
    }
  });
  return array;
};

const findAndUpdateNode = (arr, key, ab) => {
  for (let i = 0; i < arr.length; i++) {
    let node = arr[i];
    if (node.key === key) {
      node.is_open = !node.is_open;
      updateChildren(node.children, node.is_open);
      return;
    }
    if (node.children) {
      findAndUpdateNode(node.children, key, ab);
    }
  }
};

const updateChildren = (children, allOpen) => {
  for (let i = 0; i < children.length; i++) {
    let child = children[i];
    child.is_open = allOpen;
    if (child.children) {
      updateChildren(child.children, allOpen);
    }
  }
};

const checkOpenStatus = (node) => {
  let allOpen =
    !node.className && node.children.length === 0 && !node.parentKey ? node?.is_open : true;

  let partialOpen = false;

  for (const child of node.children) {
    if (child.children.length > 0) {
      const { allOpen: childAllOpen, partialOpen: childPartialOpen } = checkOpenStatus(child);
      allOpen = allOpen && childAllOpen;
      partialOpen =
        partialOpen || childPartialOpen || (child.is_open === true && childAllOpen === false);
    } else {
      allOpen = allOpen && child.is_open === true;
      // allOpen = allOpen && child.is_open;
      partialOpen = partialOpen || child.is_open === true;
      partialOpen = partialOpen || child.is_open;
    }
  }

  if (allOpen) {
    node.is_open = true;
    delete node.is_partial;
  } else if (partialOpen) {
    node.is_partial = true;
    delete node.is_open;
  } else {
    node.is_open = false;
    delete node.is_partial;
  }
  return { allOpen, partialOpen };
};

const findObjectByKey = (obj, key) => {
  if (obj.key === key) {
    return obj;
  }
  for (let i = 0; i < obj.children.length; i++) {
    const child = obj.children[i];
    const result = findObjectByKey(child, key);
    if (result) {
      return result;
    }
  }
  return null;
};
