import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import WizardSteps from '../wizard-steps';
import SearchTabList from '../search-tab-list';
import AssetsKindList from '../assets-kind-list';
import Icon from '@components/icon-component';


const AddNewWorkOrder = ({ setActive, setAddNew }) => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [assetKind, setAssetKind] = useState('');
  const location = useLocation();

  const steps = [
    {
      title: 'For what kind of asset?',
      content: (
        <AssetsKindList
          list={assetsList}
          onSelect={(item) => {
            setAssetKind(item);
            setStep((prev) => prev + 1);
          }}
        />
      )
    },
    {
      title: `Find and select the ${assetKind.toLowerCase()}.`,
      content: (
        <SearchTabList
          label={assetKind}
          onSelect={(id) => {
            setAddNew('');
            setActive(false);
            setTimeout(() => {
              navigate(
                `/work-order/?from=${
                  location.pathname
                }&type=new-work-order&${assetKind.toLowerCase()}Id=${id}`
              );
            }, 0);
          }}
        />
      )
    },
    {
      title: 'New Work Order Added Successfully.',
      content: ''
    }
  ];

  const onClickStep = (step) => {
    if (step === 0) setAssetKind('');
    setStep(step);
  };

  return (
    <div>
      <WizardSteps
        current={step}
        setStep={onClickStep}
        total={steps.length - 1}
        title={steps[step].title}
        content={steps[step].content}
        breadcrumbs={[assetKind || 'Asset type', `Select ${assetKind}`]}
      />
    </div>
  );
};

const assetsList = [
  { icon: <Icon name={'Site'} />, title: 'Site' },
  { icon: <Icon name={'equipment-white'} />, title: 'Equipment' },
  { icon: <Icon name={'home-icon-white'} />, title: 'Structure' },
  { icon: <Icon name={'pipeline-white'} />, title: 'Pipeline' }
];

export default AddNewWorkOrder;

AddNewWorkOrder.propTypes = {
  setAddNew: PropTypes.func,
  setActive: PropTypes.func
};
