/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import _ from 'lodash';

import DataSnippets from '@components/data-snippet';
import OpenLink from './open-link';

import { getAppDispatch } from '@utilities/dispatch.util';
import { formWrapperActions } from '@redux/slices/form-wrapper';
import { useOpenLink } from '@hooks/useOpenLink';

import style from '../task.module.scss';
import CardSite from './card-site';

const openLinkDefaultValue = {
  linkValue: '',
  pipeline: false,
  equipment: false,
  site: false,
  structure: false
};

const CardPipeline = ({
  highlight,
  showNotIcon = false,
  formWrapper = false,
  showPipelineSites,
  className,
  setShowPipelineSites,
  setShowPipeline,
  pipelineData,
  value,
  divider = false,
  taskId,
  assetId
}) => {
  const [desktopLink, setDesktopLink] = useState(openLinkDefaultValue);
  const [openLink, setOpenLink] = useOpenLink({
    taskId,
    assetId
  });
  const dispatch = getAppDispatch?.();

  const onClickOpliiDesktopLinkEvent = ({
    linkValue,
    pipeline = false,
    equipment = false,
    site = false,
    structure = false
  }) => {
    setDesktopLink({
      linkValue,
      pipeline,
      equipment,
      site,
      structure
    });
    setOpenLink((prev) => !prev);
    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId: taskId,
        assetId: assetId
      })
    );
  };

  const showPipelineMultipleSites = ({ multipleSiteAssets = [], siteIds = [], show = false }) => {
    if (multipleSiteAssets?.length)
      return showPipelineSites ? (
        multipleSiteAssets?.map((pipelineSiteAsset, index) => (
          <div className={style['mt-16']} key={index}>
            <CardSite
              highlight={highlight}
              className={className}
              showNotIcon={showNotIcon}
              formWrapper={formWrapper}
              setShowSites={index === 0 ? setShowPipelineSites : null}
              variant={'secondary'}
              showValue={index === 0 ? true : false}
              siteData={pipelineSiteAsset?.prepareTasksSites?.[0]}
              siteIds={index === 0 ? siteIds : []}
              taskId={taskId}
              assetId={pipelineSiteAsset?.prepareTasksSites?.[0]?.siteId}
            />
          </div>
        ))
      ) : (
        <div className={style['mt-16']}>
          <CardSite
            highlight={highlight}
            className={className}
            showNotIcon={showNotIcon}
            formWrapper={formWrapper}
            setShowSites={setShowPipelineSites}
            variant={'secondary'}
            showValue={true}
            siteData={multipleSiteAssets?.at(0)?.prepareTasksSites?.[0]}
            siteIds={siteIds}
            taskId={taskId}
            assetId={multipleSiteAssets?.at(0)?.prepareTasksSites?.[0]?.siteId}
          />
        </div>
      );
  };

  return (
    <>
      <div
        style={{
          position: 'relative'
        }}
      >
        <DataSnippets
          backFlex={style.backClass}
          title={`${pipelineData?.licenseNumber}-${pipelineData?.lineNumber}`}
          subtitle={pipelineData?.length || ''}
          // link={!formWrapper ? pipelineData?.link || '' : ''}
          link={!formWrapper ? `/#/pipeline-details/${pipelineData?.pipelineId}` : ''}
          onClickOpliiDesktopLinkEvent={(linkValue) =>
            onClickOpliiDesktopLinkEvent({ linkValue, pipeline: true })
          }
          iconName={!showNotIcon && 'pipeline'}
          from={pipelineData?.fromLocation || ''}
          to={pipelineData?.toLocation || ''}
          dataPoints={pipelineData?.dataPoints || ''}
          highlightNames={highlight}
          type={`Length: ${pipelineData?.length} km`}
          className={` ${style.dataSnippetMargin}`}
          setOpenAll={() => setShowPipeline((prev) => !prev)}
          value={value}
          divider={divider}
          {...(!formWrapper && {
            status: pipelineData?.status
          })}
          {...(!formWrapper && {
            allTasksForSite: pipelineData?.allTasksForPipeline
          })}
          {...(!formWrapper && {
            allDoneTasks: pipelineData?.allDoneTasks
          })}
          {...(!formWrapper && {
            isOverDue: pipelineData?.isOverDue
          })}
        />
        <OpenLink
          openLink={openLink}
          taskId={taskId || ''}
          assetId={assetId || ''}
          desktopLink={desktopLink}
          setOpenLink={(val) => {
            setOpenLink(val);
          }}
          setDesktopLink={setDesktopLink}
          selectedLinkTypeIcon={'pipeline'}
          selectedLinkTypeName={'pipeline'}
        />
      </div>

      {showPipelineMultipleSites({
        multipleSiteAssets: pipelineData?.multipleSiteAssets,
        siteIds: pipelineData?.multipleSiteAssets || [],
        show: showPipelineSites
      })}
    </>
  );
};

export default CardPipeline;
