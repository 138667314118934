/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import React, { useMemo, useEffect, useState } from 'react';
import _ from 'lodash';
import { store } from 'redux/store';
import moment from 'moment';

import ReadyToGoModal from '@components/modal/variants/is-ready-to-go-modal';
import SyncingInProgressModal from '@components/modal/variants/syncing-in-progress-modal';
import OfflineModal from '@components/modal/variants/offline-modal';

import { initialPopupStat, popupAction } from '../helper';
import { getAppDispatch } from '@utilities/dispatch.util';
import { cacheActions } from '@redux/slices/cache';
import { tasksListsActions } from '@redux/slices/tasks';
import SettingsService from '@services/settings.service';
import { getPendingTaskAndPhotosLength } from './helper';
import SyncingService from '@services/inspection-work-order-checks-syncing.service';
import MobileSyncCountService from '@services/mobile-sync-count.service';
import { updateMobileSyncCount } from 'transformers/mobile-sync-count';
import { checkEveryFieldIsSynced } from '@utilities/sync';

const TaskCardModals = ({
  popupData,
  openModalIndex,
  setOpenModalIndex,
  selectedTaskId,
  setPopupData,
  tasksList,
  tasks
}) => {
  const dispatch = getAppDispatch();
  const { idToStartSync } = useSelector((state) => state.mobileSyncCount);
  const { pendingSync } = useSelector((state) => state.cache);
  const { lastSync } = useSelector((state) => state.settings);

  const {
    tasksList: reduxTaskList,
    syncDone,
    syncFailedAssets
  } = useSelector((state) => state.tasks);
  const { HSEEventList = [] } = useSelector((state) => state.hseEvents);
  const { inspectionsList = [] } = useSelector((state) => state.inspection);
  const { checksList = [] } = useSelector((state) => state.checks);
  const [taskIsCompletelySync, setTaskIsCompletelySync] = useState(false);
  const [retry, setRetry] = useState(false);
  const [showRetryPhotoCount, setShowRetryPhotoCount] = useState([]);
  const [showRetryDocumentCount, setShowRetryDocumentCount] = useState([]);

  useEffect(() => {
    setShowRetryPhotoCount(syncFailedAssets?.photos || []);
    setShowRetryDocumentCount(syncFailedAssets?.documents || []);
  }, [retry]);

  const { pending, pendingHSEEvents, pendingInspection, pendingChecks } = useMemo(() => {
    const pending = reduxTaskList?.filter((task) => task.status === 'Pending sync');
    const pendingHSEEvents = HSEEventList?.filter((task) => task.status === 'Pending sync');
    const pendingInspection = inspectionsList?.filter((ins) => ins.status === 'Pending sync');
    const pendingChecks = checksList?.filter((ins) => ins.status === 'Pending sync');

    return {
      pending,
      pendingHSEEvents,
      pendingInspection,
      pendingChecks
    };
  }, [reduxTaskList, HSEEventList, inspectionsList, checksList]);

  const pendingOrderPhotosDocumentsLength = useMemo(() => {
    const idExists =
      (tasksList[openModalIndex]?.type === 'Inspection' ||
        tasksList[openModalIndex]?.type === 'HSE' ||
        tasksList[openModalIndex]?.type === 'Check') &&
      tasksList[openModalIndex]?.id === selectedTaskId
        ? true
        : tasksList[openModalIndex]?.woId === selectedTaskId
        ? true
        : tasksList[openModalIndex]?.id === selectedTaskId
        ? true
        : false;

    return tasksList[openModalIndex]?.status === 'Pending sync' && idExists
      ? getPendingTaskAndPhotosLength({
          pending:
            tasksList[openModalIndex]?.type !== 'Inspection' &&
            tasksList[openModalIndex]?.type !== 'HSE' &&
            tasksList[openModalIndex]?.type !== 'Check'
              ? [tasksList[openModalIndex]]
              : [],
          pendingInspections:
            tasksList[openModalIndex]?.type === 'Inspection' ? [tasksList[openModalIndex]] : [],
          pendingHse: tasksList[openModalIndex]?.type === 'HSE' ? [tasksList[openModalIndex]] : [],
          pendingChecks:
            tasksList[openModalIndex]?.type === 'Check' ? [tasksList[openModalIndex]] : [],
          syncFailedAssets
        })
      : {};
  }, [retry, selectedTaskId, openModalIndex]);

  const popupHandler = async ({ retrySync = false }) => {
    if (navigator.onLine) {
      dispatch?.(cacheActions.updateSyncing(true));
      dispatch?.(cacheActions.updateSyncingModal(true));
      dispatch?.(tasksListsActions.setSyncDone(defaultValueOfSyncDone));
      if (retrySync && taskIsCompletelySync) {
        setPopupData({
          Status: 'progress',
          disabled: false
        });
        try {
          const syncFailedAssets1 =
            showRetryPhotoCount?.length > 0 || showRetryDocumentCount?.length > 0 || retrySync
              ? syncFailedAssets
              : false;

          if (syncFailedAssets1) {
            await SyncingService.inspectionAndWorkOrderService({
              syncAssetsList: syncFailedAssets1,
              currentTask: {},
              sync: false,
              doneNumber: null,
              pendingSyncLength: null,
              type: 'syncFailedAsset'
            });
          }

          const { tasks } = store.getState();
          const { syncDone: syncDone1 } = tasks;
          const check =
            syncDone1?.failedImageCount === syncFailedAssets1?.photos?.length &&
            syncDone1?.failedDocumentCount === syncFailedAssets1?.documents?.length
              ? true
              : false;

          setPopupData({
            Status: check ? 'complete' : 'failed',
            disabled: false
          });
        } catch (e) {
          console.log({ e });
          setPopupData(popupAction('failed'));
          dispatch?.(tasksListsActions.setSyncDone(defaultValueOfSyncDone));
        }
      } else {
        setPopupData(popupAction('progress', tasksList, openModalIndex));
        const send = tasksList[openModalIndex];

        const _pendingOrderPhotosDocumentsLength = getPendingTaskAndPhotosLength({
          pending:
            tasksList[openModalIndex]?.type !== 'Inspection' &&
            tasksList[openModalIndex]?.type !== 'HSE' &&
            tasksList[openModalIndex]?.type !== 'Check'
              ? [tasksList[openModalIndex]]
              : [],
          pendingInspections:
            tasksList[openModalIndex]?.type === 'Inspection' ? [tasksList[openModalIndex]] : [],
          pendingHse: tasksList[openModalIndex]?.type === 'HSE' ? [tasksList[openModalIndex]] : [],
          pendingChecks:
            tasksList[openModalIndex]?.type === 'Check' ? [tasksList[openModalIndex]] : [],
          syncFailedAssets
        });

        let numberOfTaskSynced = false;
        try {
          const isInspection = send?.type === 'Inspection';
          const isHSE = send?.type === 'HSE';
          const isCheck = send?.type === 'Check';
          const isWorkOrder =
            send?.type !== 'Inspection' && send?.type !== 'HSE' && !send?.newWorkRequest;
          const isWorkRequest =
            send?.type !== 'Inspection' && send?.type !== 'HSE' && send?.newWorkRequest;

          await MobileSyncCountService.sendRequestToServerToStartSync({
            data: {
              inspectionCount: isInspection ? 1 : 0,
              inspectionPhotoCount: isInspection ? pendingOrderPhotosDocumentsLength?.photos : 0,
              inspectionDocumentCount: isInspection
                ? pendingOrderPhotosDocumentsLength?.documents
                : 0,

              workOrderCount: isWorkOrder ? 1 : 0,
              workOrderPhotoCount: isWorkOrder ? pendingOrderPhotosDocumentsLength?.photos : 0,
              workOrderDocumentCount: isWorkOrder
                ? pendingOrderPhotosDocumentsLength?.documents
                : 0,

              workRequestCount: isWorkRequest ? 1 : 0,
              workRequestPhotoCount: isWorkRequest ? pendingOrderPhotosDocumentsLength?.photos : 0,
              workRequestDocumentCount: isWorkRequest
                ? pendingOrderPhotosDocumentsLength?.documents
                : 0,

              incidentCount: isHSE ? 1 : 0,
              incidentPhotoCount: isHSE ? pendingOrderPhotosDocumentsLength?.photos : 0,
              incidentDocumentCount: isHSE ? pendingOrderPhotosDocumentsLength?.documents : 0,

              checkCount: isCheck ? 1 : 0,
              checkPhotoCount: isCheck ? pendingOrderPhotosDocumentsLength?.photos : 0,
              checkDocumentCount: isCheck ? pendingOrderPhotosDocumentsLength?.documents : 0
            }
          });

          if (isInspection) {
            await SyncingService.inspectionAndWorkOrderService({
              currentTask: { ...send },
              sync: true,
              doneNumber: 1,
              pendingSyncLength: pendingInspection.length,
              type: 'Inspection',
              singleSync: true
            });
          } else if (isHSE) {
            await SyncingService.inspectionAndWorkOrderService({
              currentTask: { ...send },
              sync: true,
              doneNumber: 1,
              pendingSyncLength: pendingHSEEvents.length,
              type: 'HSE',
              singleSync: true
            });
          } else if (isCheck) {
            await SyncingService.inspectionAndWorkOrderService({
              currentTask: { ...send },
              sync: true,
              doneNumber: 1,
              pendingSyncLength: pendingHSEEvents.length,
              type: 'Check',
              singleSync: true
            });
          } else {
            await SyncingService.inspectionAndWorkOrderService({
              currentTask: { ...send, ...{ type: send.typeId } },
              sync: true,
              doneNumber: 1,
              pendingSyncLength: pending.length,
              type: 'Work Order',
              singleSync: true
            });
          }

          const syncFailedAssets1 =
            showRetryPhotoCount?.length > 0 || showRetryDocumentCount?.length > 0 || retrySync
              ? syncFailedAssets
              : false;

          if (syncFailedAssets1) {
            await SyncingService.inspectionAndWorkOrderService({
              syncAssetsList: syncFailedAssets1,
              currentTask: {},
              sync: false,
              doneNumber: null,
              pendingSyncLength: null,
              type: 'syncFailedAsset'
            });
          }

          await SettingsService.checkUpdate();
          const { tasks } = store.getState();
          const { syncDone: syncDone1 } = tasks;

          numberOfTaskSynced = checkEveryFieldIsSynced({
            syncDone: syncDone1,
            pendingOrderPhotosDocumentsLength: {
              ..._pendingOrderPhotosDocumentsLength
            }
          });
          if (
            syncDone1?.workRequest === _pendingOrderPhotosDocumentsLength?.workRequest &&
            syncDone1?.workOrder === _pendingOrderPhotosDocumentsLength?.workOrder &&
            syncDone1?.inspection === _pendingOrderPhotosDocumentsLength?.inspection &&
            syncDone1?.hseEvents === _pendingOrderPhotosDocumentsLength?.hseEvents &&
            syncDone1?.checks === _pendingOrderPhotosDocumentsLength?.checks
          ) {
            setTaskIsCompletelySync(true);
          }

          setPopupData({
            ...popupAction(numberOfTaskSynced ? 'complete' : 'failed', tasksList, openModalIndex),
            disabled: false
          });
        } catch (e) {
          console.log({ e });
          setPopupData(
            popupAction(
              navigator.onLine ? (numberOfTaskSynced ? 'complete' : 'failed') : 'offline',
              tasksList,
              openModalIndex
            )
          );
          dispatch?.(tasksListsActions.setSyncDone(defaultValueOfSyncDone));
        }
      }
    } else setPopupData(popupAction('offline', tasksList, openModalIndex));
  };

  useEffect(() => {
    !navigator.onLine && setPopupData(popupAction('offline', tasksList, openModalIndex));
  }, [navigator.onLine]);

  useEffect(() => {
    const updateData = async () => {
      await MobileSyncCountService.updateRequestToServerAfterSync({
        data: {
          ...updateMobileSyncCount({ syncDone })
        },
        idToStartSync
      });
    };

    if (popupData.Status === 'complete' || popupData.Status === 'failed') {
      updateData();
    }
  }, [popupData.Status]);

  const retryEvent = () => {
    setRetry(true);
    popupHandler({ retrySync: true });
  };
  return (
    <>
      {popupData.Status === 'sync' && (
        <ReadyToGoModal
          open={openModalIndex !== -1}
          setOpen={() => {
            setOpenModalIndex(-1);
            setPopupData(initialPopupStat);
          }}
          syncWorkRequest={
            popupData.actionBtnText && popupData.actionBtnText.includes('SYNC WORK REQUEST')
          }
          syncWorkOrder={
            popupData.actionBtnText && popupData.actionBtnText.includes('SYNC WORK ORDER')
          }
          syncInspection={
            popupData.actionBtnText && popupData.actionBtnText.includes('SYNC INSPECTION')
          }
          syncHSEEvent={
            popupData.actionBtnText && popupData.actionBtnText.includes('SYNC HSE EVENT')
          }
          syncCheck={popupData.actionBtnText && popupData.actionBtnText.includes('SYNC CHECK')}
          syncWorkOrderOrRequestOrInspectionEvent={popupHandler}
          showRetryPhotoCount={showRetryPhotoCount?.length}
          pendingOrderPhotosDocumentsLength={pendingOrderPhotosDocumentsLength}
          showRetryDocumentCount={showRetryDocumentCount?.length}
        />
      )}

      {popupData.Status === 'progress' && (
        <SyncingInProgressModal
          open={openModalIndex !== -1}
          setOpen={() => {}}
          inProgress
          pendingOrderPhotosDocumentsLength={pendingOrderPhotosDocumentsLength}
          syncDone={syncDone}
          showRetryPhotoCount={showRetryPhotoCount?.length}
          showRetryDocumentCount={showRetryDocumentCount?.length}
        />
      )}
      {popupData.Status === 'complete' && (
        <SyncingInProgressModal
          open={openModalIndex !== -1}
          setOpen={() => {
            dispatch?.(cacheActions.updateSyncingModal(false));
            setOpenModalIndex(-1);
            setPopupData(initialPopupStat);
            setRetry(false);
            setTaskIsCompletelySync(false);
          }}
          syncingComplete
          pendingOrderPhotosDocumentsLength={pendingOrderPhotosDocumentsLength}
          syncDone={syncDone}
          showRetryPhotoCount={showRetryPhotoCount?.length}
          showRetryDocumentCount={showRetryDocumentCount?.length}
        />
      )}
      {popupData.Status === 'failed' && (
        <SyncingInProgressModal
          open={openModalIndex !== -1}
          setOpen={() => {
            dispatch?.(cacheActions.updateSyncingModal(false));
            setRetry(false);
            setTaskIsCompletelySync(false);

            setOpenModalIndex(-1);
            setPopupData(initialPopupStat);
          }}
          syncingFailed
          pendingOrderPhotosDocumentsLength={pendingOrderPhotosDocumentsLength}
          syncDone={syncDone}
          retryEvent={retryEvent}
          showRetryPhotoCount={showRetryPhotoCount?.length}
          showRetryDocumentCount={showRetryDocumentCount?.length}
        />
      )}

      {popupData.Status === 'offline' && (
        <OfflineModal
          open={openModalIndex !== -1}
          setOpen={() => setOpenModalIndex(-1)}
          lastSyncTime={moment(lastSync).fromNow(true)}
          pendingSync={pendingSync}
        />
      )}
    </>
  );
};

export default React.memo(TaskCardModals, _.isEqual);

const defaultValueOfSyncDone = {
  workOrder: 0,
  workRequest: 0,
  photos: 0,
  documents: 0,
  inspection: 0,
  inspectionPhotos: 0,
  equipmentRecords: 0,
  hseEvents: 0,
  checks: 0,
  checkPhotoCount: 0,
  checkDocumentCount: 0,
  inspectionPhotoCount: 0,
  workOrderPhotoCount: 0,
  workRequestPhotoCount: 0,
  incidentPhotoCount: 0,
  inspectionDocumentCount: 0,
  workOrderDocumentCount: 0,
  workRequestDocumentCount: 0,
  incidentDocumentCount: 0
};
