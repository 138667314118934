/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { useState } from 'react';

import DataSnippets from '@components/data-snippet';
import TaskTally from '@components/task-tally';
import Icon from '@components/icon-component';
import SiteInnerCard from './site-inner-card';
import InfoPopup from '@components/info-popup/info-popup.component';
import OpenLink from '@components/task-card-new/card-asset/open-link';

import { getAppDispatch } from '@utilities/dispatch.util';
import { formWrapperActions } from '@redux/slices/form-wrapper';
import { useOpenLink } from '@hooks/useOpenLink';

import style from './site-card.module.scss';

const EquipmentCard = (props) => {
  const [desktopLink, setDesktopLink] = useState(null);
  const [openLink, setOpenLink] = useOpenLink({
    taskId: props?.equipmentId,
    assetId: props?.equipmentId
  });
  const dispatch = getAppDispatch?.();

  const {
    equipmentDetails = false,
    equipmentListing = false,
    uwi,
    wellBore,
    typeData,
    showChevronIcon,
    onClickLink,
    to,
    title,
    subtitle,
    distance,
    unit,
    dataPoints,
    normal,
    allTasksForSite,
    allDoneTasks,
    isOverDue,
    status,
    childrenList,
    grey,
    highlight,
    highlightName,
    sites,
    open,
    setOpen,
    moreInfo,
    arrayText,
    noIcon = false,
    appliesTo = false,
    showWOCount,
    link,
    noLink,
    addNewWizard,
    equipmentId
  } = props;

  let [highlightNames, highlightType] = !_.isEmpty(highlight) ? highlight.split(' ') : [];
  highlightNames = highlightName ? highlight : highlightNames;

  if (!highlightType) highlightType = highlightNames;

  const onClickOpliiDesktopLinkEvent = (desktopLink) => {
    setDesktopLink({ linkValue: desktopLink });
    setOpenLink((prev) => !prev);

    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId: equipmentId,
        assetId: equipmentId
      })
    );
  };

  const equipmentInfo = () => {
    return (
      <div className={style.cardInner}>
        <div className={style.cardContent}>
          <div
            style={{
              position: 'relative'
            }}
          >
            <DataSnippets
              title={title || ''}
              link={link}
              subtitle={subtitle || ''}
              dataPoints={dataPoints}
              iconName={noIcon || equipmentDetails ? '' : 'Equipment'}
              kmValue={distance || ''}
              unit={unit || ''}
              highlightNames={highlightNames}
              highlightType={highlightType}
              className={`${style.marginClass} ${equipmentListing && style.paddingOnListingPage} ${
                style['mb-4']
              }`}
              onClickOpliiDesktopLinkEvent={onClickOpliiDesktopLinkEvent}
            />
            <OpenLink
              openLink={openLink}
              desktopLink={desktopLink}
              setOpenLink={setOpenLink}
              setDesktopLink={setDesktopLink}
              selectedLinkTypeIcon={'Equipment'}
              selectedLinkTypeName={'equipment'}
            />
          </div>
          {!grey && (
            <div
              className={`${style.chipsDiv} ${
                !(!normal && !_.isEmpty(sites)) && !appliesTo && style['mb-0']
              } `}
              style={{
                padding: equipmentDetails || noIcon ? '0px' : '0px 20px 0px 60px'
              }}
            >
              {(showWOCount || !normal) && allTasksForSite?.length > 0 && (
                <TaskTally
                  status={
                    allDoneTasks.length === 0
                      ? 'overdue'
                      : allDoneTasks.length > 0 && allTasksForSite.length > allDoneTasks.length
                      ? 'inProgress'
                      : allDoneTasks.length === allTasksForSite.length
                      ? 'done'
                      : ''
                  }
                  statusText={`${allDoneTasks.length}/${allTasksForSite.length}`}
                  dot={!!isOverDue && 'true'}
                />
              )}
              {status && (
                <>
                  <TaskTally statusText={status || ''} />
                </>
              )}
              {childrenList && childrenList.length > 0 && (
                <TaskTally
                  statusText={`${childrenList.length}`}
                  iconName="children"
                  width={14}
                  height={16}
                />
              )}
            </div>
          )}

          {uwi && (
            <DataSnippets
              title={'Title'}
              subtitle={'Sub-Title'}
              type={'Type'}
              iconName={'maps'}
              kmValue={'23'}
              unit={'units'}
              className={style.marginClass}
            />
          )}
          {wellBore && (
            <DataSnippets
              title={'Title'}
              subtitle={'Sub-Title'}
              type={'Type'}
              iconName={'Equipment'}
              kmValue={'23'}
              unit={'units'}
              className={style.marginClass}
            />
          )}
          {typeData && (
            <DataSnippets
              title={'Title'}
              subtitle={'Sub-Title'}
              type={'Type'}
              iconName={'maps'}
              kmValue={'23'}
              unit={'units'}
              className={style.marginClass}
            />
          )}
          {!normal && !_.isEmpty(sites) && (
            <SiteInnerCard
              sites={{
                ...sites,
                link: !addNewWizard ? (!noLink ? `/#/site-details/${sites?.siteId}` : '') : '',
                highlight,
                highlightName,
                hideLocation: equipmentDetails,
                equipmentListing
              }}
              appliesTo={appliesTo}
              taskId={equipmentId}
              assetId={sites?.siteId}
            />
          )}

          {appliesTo && (
            <SiteInnerCard
              sites={{
                ...sites,
                link: !addNewWizard ? (!noLink ? `/#/site-details/${sites?.siteId}` : '') : '',
                highlight,
                highlightName,
                hideLocation: equipmentDetails
              }}
              appliesTo
              taskId={equipmentId}
              assetId={sites?.siteId}
            />
          )}
        </div>

        {showChevronIcon ? (
          <div>
            <div>
              <Icon name={'chevron-right'} width="10px" />
            </div>
          </div>
        ) : !appliesTo ? (
          <div>
            {!moreInfo && (
              <div className="rightDiv">
                <Icon
                  name={'Listing detail menu'}
                  className={style.siteIcon}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                {open && (
                  <div style={{ position: 'absolute', top: '84px', right: '0px' }}>
                    <InfoPopup open={open} setOpen={setOpen} arrayText={arrayText} />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };
  return equipmentDetails ? (
    <div
      className={`${style.cardWrapperNoHover} ${props?.className}`}
      style={{
        padding: equipmentDetails ? '16px' : '',
        border: '0.5px solid #cbd2d9'
      }}
    >
      {equipmentInfo()}
    </div>
  ) : (
    <div
      className={`${style.cardWrapper}`}
      style={{
        cursor: 'pointer'
      }}
      onClick={onClickLink}
    >
      {equipmentInfo()}
    </div>
  );
};

EquipmentCard.propTypes = {};

export default EquipmentCard;
