/* eslint-disable no-unused-vars */
// import { store } from 'redux/store';
import _ from 'lodash';
import moment from 'moment';

// import { getDataPoints, getMoreInfoDataPoints } from '@pages/settings/helper';
import { getDataPoints, getMoreInfoDataPoints } from '../../pages/settings/helper';

import EquipmentSelector from '../equipment.selector';
import StructureSelector from '../structure.selector';
import PipelineSelector from '../pipeline.selector';
import { getAllWorkOrdersAndInspections } from './all-work-order-inspection-for-site';
import { getSitesInfoForSitesListingPage } from './sites-info-for-listing-page';

export const getParentSiteDetails = async ({
  siteId = '',
  equipmentsWithWO = false,
  withChild = true,
  templatesIds = '',
  store
}) => {
  const { cache, picklists, settings, jurisdictionNumbers, structure } = store;
  const {
    sitesMap = {},
    equipmentsMap = {},
    sitesLoading = {},
    sitesEquipmentsMap = {},
    equipments: cacheEquipments = [],
    sites: cacheSites = []
  } = cache;

  const { structure: cacheStructures, sitesStructuresMap, structuresMap } = structure;

  const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;
  const structureDisplayFields = settings?.settings?.['structureDisplayFields'] || '';

  const equipmentDisplayFields = settings.settings?.['equipmentDisplayFields'] || '';
  const siteDisplayFields = settings.settings?.['siteDisplayFields'] || '';

  const {
    assignedTasksList: tasksList,
    newWorkRequestList,
    hseEvents
  } = await getAllWorkOrdersAndInspections({ siteId, store });

  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = picklists;
  const parentSite = cacheSites[sitesMap[siteId]] || {};

  let parentSiteWithWorkOrders = await getSitesInfoForSitesListingPage({
    parentSite: [parentSite || ''],
    templatesIds,
    withChild,
    store
  });

  const parentSiteChildrenCompleteInfo = parentSite?.children?.map((x) => cacheSites[x]);

  const parentSitesWithMeta =
    _.size([...(parentSite?.children || [])]) > 0
      ? await getSitesInfoForSitesListingPage({
          parentSite: [...(parentSiteChildrenCompleteInfo || [])], // indexes,
          templatesIds,
          withChild,
          store
        })
      : [];

  const children = parentSitesWithMeta?.sort(
    (a, b) =>
      (a.distance != null ? a.distance : Infinity) - (b.distance != null ? b.distance : Infinity) ||
      a.name.localeCompare(b.name)
  );

  let equipments = [
    ...(sitesEquipmentsMap[siteId] || []),
    ...children.reduce((acc, { siteId }) => {
      acc = acc.concat(sitesEquipmentsMap[siteId] || []);
      return acc;
    }, [])
  ].filter((id) => cacheEquipments[equipmentsMap[id]]);

  const structures =
    [
      ...(sitesStructuresMap[siteId] || []),
      ...children.reduce((acc, { siteId }) => {
        acc = acc.concat(sitesStructuresMap[siteId] || []);
        return acc;
      }, [])
    ].filter((id) => cacheStructures[structuresMap[id]]) || [];

  const pipelines = await PipelineSelector.getPipelineWithWO({ store });
  const parentPipelines = pipelines.filter((p) => {
    return (
      p?.fromSite === siteId ||
      p?.toSite === siteId ||
      p?.sites?.some((x) => x?.toLowerCase() === siteId) ||
      p?.wells?.some((x) => x?.toLowerCase() === siteId)
    );
  });

  const childrenPipelines = pipelines.filter((p) => {
    const hasChildStructure = children.some((child) => {
      return (
        child.siteId === p.fromSite ||
        child.siteId === p.toSite ||
        (p.sites && p.sites.some((x) => x?.toLowerCase() === child.siteId)) ||
        (p.wells && p.wells.some((x) => x?.toLowerCase() === child.siteId))
      );
    });
    return hasChildStructure;
  });

  const particularPipelines = [...parentPipelines, ...childrenPipelines];

  let equipmentsWithWOData = [];
  if (equipmentsWithWO) {
    equipmentsWithWOData = await EquipmentSelector.getEquipmentsWithAllTasks({
      parentEquipment: equipments.map((id) => cacheEquipments[equipmentsMap[id]]),
      store
    });

    equipmentsWithWOData = equipmentsWithWOData?.map((props) => {
      const { jurisdictionNumber } = jurisdictionNumbersMap[props.equipmentId] || {};

      const dataPoints = getDataPoints(
        { ...props, subtype: props.equipmentSubType, jurisdictionNumber },
        equipmentDisplayFields,
        'equipment',
        picklistsMap
      );

      return {
        ...props,
        dataPoints
      };
    });
  }

  const structureEquipmentMap = await StructureSelector.getStructureEquipmentMap({ store });

  const structureAllFieldData = structures?.map((structure) => {
    const equipments = structureEquipmentMap[structure] || [];

    const structureTasks = tasksList?.filter(
      (t) => t?.structureId === structure || t.structures?.includes(structure)
    );

    const allDoneTasks = structureTasks?.filter((task) => task?.status === 'Synced');
    const allNotDoneTasks = structureTasks?.filter((task) => task?.status !== 'Synced');
    const today = moment().format('YYYY-MM-DD');
    const isOverDue = allNotDoneTasks?.find((task) =>
      task?.dueDate ? moment(task?.dueDate).isBefore(today) : false
    );

    const equData = cacheStructures[structuresMap[structure]] || {};
    const { jurisdictionNumber } = jurisdictionNumbersMap[structure] || {};
    const dataPoints = getDataPoints(
      { ...equData, type: equData?.structureType, jurisdictionNumber },
      structureDisplayFields,
      'structure',
      picklistsMap
    );

    return {
      ...equData,
      allDoneTasks,
      isOverDue,
      equipments,
      allTasksForSite: structureTasks,
      allotDoneTasks: allNotDoneTasks,
      dataPoints
    };
  });

  return new Promise((resolve) => {
    const { name, siteType, areaHierarchy, link } = parentSite;

    const status = picklistsMap[parentSite.status]?.value;
    const area = areaHierarchyTreeDataMap[areaHierarchy]?.title;
    const data = { ...parentSite, area, type: siteType };
    const { unit, distance, children: c, ...rest } = data;

    const allTasksForSite = [
      ...tasksList,
      ...(children?.map((t) => t.allTasksForSite).flat(2) || [])
    ];
    const allHSEEventsForSite = [
      ...hseEvents,
      ...(children?.map((t) => t?.hseEvents).flat(2) || [])
    ];

    const allNotDoneTasks = _.flattenDeep(allTasksForSite).filter(
      (task) => task?.status !== 'Synced'
    );

    const allWorkOrders =
      _.size(parentSiteWithWorkOrders) > 0 &&
      parentSiteWithWorkOrders[0]?.allTasksForSite?.filter((x) => x.type === 'Work Order').length >
        0
        ? parentSiteWithWorkOrders[0]?.allTasksForSite?.filter((x) => x.type === 'Work Order')
        : [];
    const allDoneWorkOrders =
      _.size(parentSiteWithWorkOrders) > 0 &&
      parentSiteWithWorkOrders[0]?.allDoneTasks?.filter((x) => x.type === 'Work Order').length > 0
        ? parentSiteWithWorkOrders[0]?.allDoneTasks?.filter((x) => x.type === 'Work Order')
        : [];

    const allNotDoneWorkOrders = _.flattenDeep(allWorkOrders).filter(
      (task) => task?.status !== 'Synced'
    );
    const allInspections =
      _.size(parentSiteWithWorkOrders) > 0 &&
      parentSiteWithWorkOrders[0]?.allTasksForSite?.filter((x) => x.type === 'Inspection').length >
        0
        ? parentSiteWithWorkOrders[0]?.allTasksForSite?.filter((x) => x.type === 'Inspection')
        : [];
    const allDoneInspections =
      _.size(parentSiteWithWorkOrders) > 0 &&
      parentSiteWithWorkOrders[0]?.allDoneTasks?.filter((x) => x.type === 'Inspection').length > 0
        ? parentSiteWithWorkOrders[0]?.allDoneTasks?.filter((x) => x.type === 'Inspection')
        : [];

    const allNotDoneInspections = _.flattenDeep(allInspections).filter(
      (task) => task?.status !== 'Synced'
    );

    const allChecks =
      _.size(parentSiteWithWorkOrders) > 0 &&
      parentSiteWithWorkOrders[0]?.allTasksForSite?.filter((x) => x.type === 'Check').length > 0
        ? parentSiteWithWorkOrders[0]?.allTasksForSite?.filter((x) => x.type === 'Check')
        : [];

    const allDoneChecks =
      _.size(parentSiteWithWorkOrders) > 0 &&
      parentSiteWithWorkOrders[0]?.allDoneTasks?.filter((x) => x.type === 'Check').length > 0
        ? parentSiteWithWorkOrders[0]?.allDoneTasks?.filter((x) => x.type === 'Check')
        : [];

    const allNotDoneChecks = _.flattenDeep(allChecks).filter((task) => task?.status !== 'Synced');

    const today = moment().format('YYYY-MM-DD');
    const isOverDue = allNotDoneTasks.find((task) =>
      task.dueDate ? moment(task.dueDate).isBefore(today) : false
    );

    const isInspectionOverDue = allNotDoneInspections.find((task) =>
      task.dueDate ? moment(task.dueDate).isBefore(today) : false
    );

    const isCheckOverDue = allNotDoneChecks.find((task) =>
      task.nextDue ? moment(task.nextDue).isBefore(today) : false
    );
    const isWorkOrderOverDue = allNotDoneWorkOrders.find((task) =>
      task.dueDate ? moment(task.dueDate).isBefore(today) : false
    );
    const dataPoints = getDataPoints(
      { ...data, type: data?.siteType },
      siteDisplayFields,
      'site',
      picklistsMap
    );
    const dataPointsMap = dataPoints.reduce((acc, curr) => {
      acc[curr.Id] = curr;
      return acc;
    }, {});
    dataPointsMap['Status'] = { value: status, title: 'Status' };

    const MoreInfoDataPoints = getMoreInfoDataPoints(
      { ...data, type: data?.siteType },
      'site',
      picklistsMap
    );
    const MoreInfoDataPointsMap = MoreInfoDataPoints.reduce((acc, curr) => {
      acc[curr.Id] = curr;
      return acc;
    }, {});
    MoreInfoDataPointsMap['Status'] = { value: status, title: 'Status' };

    return resolve({
      sitesLoading,
      name,
      tasks:
        _.size(parentSiteWithWorkOrders) > 0 && parentSiteWithWorkOrders[0]?.allTasksForSite
          ? parentSiteWithWorkOrders[0]?.allTasksForSite
          : [],
      allDoneTasks:
        _.size(parentSiteWithWorkOrders) > 0 && parentSiteWithWorkOrders[0]?.allDoneTasks
          ? parentSiteWithWorkOrders[0]?.allDoneTasks
          : [],
      allWorkOrders,
      allDoneWorkOrders,
      allInspections,
      allDoneInspections,
      isOverDue,
      allChecks,
      allDoneChecks,
      isCheckOverDue,
      isWorkOrderOverDue,
      isInspectionOverDue,
      status,
      area,
      equipments,
      structures,
      structuresMap,
      equipmentsMap,
      dataPoints,
      unit,
      distance,
      children,
      parentSite,
      rest,
      dataPointsMap,
      MoreInfoDataPointsMap,
      equipmentsWithWO: equipmentsWithWOData,
      structureAllFieldData,
      pipelines: particularPipelines,
      link,
      newWorkRequestList,
      hseEvents: allHSEEventsForSite
    });
  });
};
