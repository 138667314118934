/* eslint-disable no-unused-vars */

import Select from 'react-select';
import { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import Label from '@components/label';
import Icon from '@components/icon-component';
import ErrorLabel from '@components/error-label';

import style from './select-box.module.scss';

const CustomSelect = ({
  showErrorMessage = true,
  options,
  label,
  defaultValue,
  dot,
  control,
  placeholder,
  name,
  isClearable,
  error,
  customFunc = null,
  disabled = false,
  className = '',
  labelClassName = '',
  reconciled = false,
  isRequired = false,
  isRequiredMsg = '',
  showReconciled = false,
  showBorderOnError = false,
  showValue = false,
  isMultiple = false
}) => {
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minHeight: '48px',
      borderColor: error ? '#8D0909' : state.isFocused ? '#2f80ed' : '#7B8794',
      borderRadius: '8px',
      backgroundColor: disabled ? '#f5f7fa' : '#fff',
      cursor: 'pointer',
      paddingRight: '10px',
      borderWidth: '0.5px',
      boxShadow: 'none',
      color: '#7B8794',
      '&:hover': {
        borderColor: state.isFocused ? '#2f80ed' : '#7B8794'
      }
    }),
    placeholder: (base) => ({
      ...base,
      color: '#CBD2D9',
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '125%',
      letterSpacing: '0.2px'
    }),

    //selected Option in input
    singleValue: (base) => ({
      ...base,

      color: '#323f4b',
      fontFamily: 'Noto Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '125%',
      letterSpacing: '0.2px'
    }),
    //line
    indicatorSeparator: (base) => ({
      ...base,
      width: '0px'
    }),

    //container for X
    indicatorContainer: (base) => ({
      ...base,
      padding: '0px',
      color: '#2f80ed'
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: '0px',
      marginLeft: '0px',
      transition: ' all 0.2s ease-in',
      color: '#2f80ed',
      border: 'none !important',
      zIndex: '1 !important',
      pointerEvents: 'none'
    }),

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontWeight: '400',
        fontStyle: 'normal',
        fontFamily: 'Noto Sans',
        fontSize: '16px',

        backgroundColor: isDisabled
          ? 'black'
          : isSelected
          ? '#2f80ed'
          : isFocused
          ? '#D5E6FB'
          : 'white !important',
        color: isDisabled ? '#ccc' : isSelected ? 'white' : data.color,

        '&:active': {
          backgroundColor: '#2f80ed',
          color: 'white'
        },
        '&:hover': {
          backgroundColor: isSelected ? '#2f80ed' : '#D5E6FB'
        }
      };
    },
    menu: (base) => ({
      ...base,
      marginTop: '0',
      borderRadius: '0 0 8px 8px',
      border: '0.5px solid #2f80ed',
      borderTop: 'none',
      overflow: 'hidden'
    }),
    menuList: (provided) => ({
      ...provided,
      '@media (max-width: 768px)': {
        // apply the maxHeight property only in mobile view
        maxHeight: '200px',
        overflowY: 'auto'
      }
    })
  };

  return (
    <div className={`${style.customSelect} ${className}`}>
      {label ? (
        <Label
          label={label}
          dot={dot}
          error={showErrorMessage ? error : ''}
          className={labelClassName}
          reconciled={reconciled}
          showReconciled={showReconciled}
          inLineStyle={{ paddingLeft: '8px' }}
        />
      ) : error && !showBorderOnError ? (
        <ErrorLabel error={error} className={labelClassName} reconciled={reconciled} />
      ) : (
        <></>
      )}
      <div>
        <Controller
          rules={{
            required: isRequired ? isRequiredMsg : ''
          }}
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ field: { onChange, value } }) => {
            return (
              <Select
                isMulti={isMultiple}
                getOptionLabel={(option) =>
                  showValue ? option.showValue : option?.label ? option?.label : option
                }
                isDisabled={disabled}
                menuIsOpen={isMenuOpen}
                closeMenuOnSelect={isMenuOpen}
                menuPlacement={'auto'}
                closeMenuOnScroll={true}
                isOptionDisabled={(option) => option.isdisabled}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={() => setIsMenuOpen(false)}
                ref={ref}
                value={isMultiple ? value : options?.find((c) => c.value === value) || value || ''}
                onChange={(e) => {
                  onChange(isMultiple ? e : e?.value || '');
                  customFunc && customFunc(e);
                }}
                defaultValue={defaultValue?.value || defaultValue}
                options={options}
                styles={customStyles}
                placeholder={placeholder}
                classNamePrefix="react-select"
                isClearable={isClearable}
                className={style.customSelect}
                components={{
                  DropdownIndicator: () => (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transform: isMenuOpen ? 'rotate(-180deg)' : '',
                        transition: ' all 0.2s ease-in',
                        pointerEvents: 'none'
                      }}
                    >
                      <path
                        d="M6 9L12 15L18 9"
                        stroke={isMenuOpen ? '#2f80ed' : '#bfbfbf'}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ),
                  ClearIndicator: (props) => {
                    const {
                      innerProps: { ref, ...restInnerProps }
                    } = props;
                    return (
                      <div {...restInnerProps} ref={ref}>
                        <Icon name={'xdefault'} height={18} width={18} />
                      </div>
                    );
                  }
                }}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

export default CustomSelect;
