/* eslint-disable no-unused-vars */

import { useEffect, useMemo, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { store } from 'redux/store';

import PageContainer from '@components/page-container';
import PageHeader from '@components/page-header';
import NewTabs from '@components/new-tabs';
import MoreInformation from '@components/more-information';
import PipelineSite from './pipeline-site';
import WorkOrder from '../../site-details/tasks/work-order';
import Icon from '@components/icon-component';
import LoadingRound from '@components/loading-round/loading-round';
import PipeLineCard from '@components/pipeline-listing/pipeline-listing-inner/pipeline-card';
import PermissionRestrict from 'hoc/permission-restrict';
import PipelineBookMark from '@components/pipeline-listing/pipeline-bookmark';

import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';
import { getPipelineDetails } from './helper';
import { filterActions } from '@redux/slices/filters';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { PERMISSIONS } from 'permissions/constants';

import './pipeline-site.scss';
import style from './style.module.scss';
import EditPipeline from './edit-pipeline';
import { initWebWorker } from 'webworker';

const PipelineSiteDetails = () => {
  const [searchParams] = useSearchParams();
  const dispatch = getAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { width } = useWindowDimensions();

  const swipeAbleMenuRef = useRef(null);
  const mainDivRef = useRef(null);
  const siteCardRef = useRef(null);
  const assetDetailPageHeaderRef = useRef(null);

  const [tab, setTab] = useState('0');
  const [editPipeline, setEditPipeline] = useState(false);
  const [open, setOpen] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [allTask, setAllTask] = useState(false);
  const [checks, setChecks] = useState(false);
  const [inspections, setInspections] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [syncComplete, setSyncComplete] = useState(false);
  const [pipelineDetails, setPipelineDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { sitesMap = {}, sites: cacheSites = [] } = useSelector((state) => state.cache);
  const { settings = {} } = useSelector((state) => state.settings);
  const pipelineDetailsInfo = useSelector((state) => state?.backHistory?.pipelineDetails);
  const { picklistsMap = {} } = useSelector((state) => state.picklists);

  const { tasksFilterCount = 0 } = useSelector((state) => state.filters);
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);

  const { id } = useParams();
  const bookmarkName = searchParams.get('bookmarkName');
  const currentOpenTaskTab = searchParams.get('currentOpenTaskTab') || '';

  useEffect(() => {
    if (currentOpenTaskTab) {
      if (currentOpenTaskTab === 'allTask') setAllTask(true);
      else if (currentOpenTaskTab === 'workOrder') setWorkOrder(true);
      else if (currentOpenTaskTab === 'inspections') setInspections(true);
      else if (currentOpenTaskTab === 'checks') setChecks(true);
      // else if (currentOpenTaskTab === 'workRequest') setWorkRequest(true);
    }
  }, [currentOpenTaskTab]);

  const { tasksList: reduxTasksList = [], selectTask } = useSelector((state) => state.tasks);
  const { inspectionsList } = useSelector((state) => state.inspection);
  const { checksList } = useSelector((state) => state.checks);

  useEffect(() => {
    if (!_.isEmpty(pipelineDetailsInfo)) {
      pipelineDetailsInfo?.pipelineDetailsTab &&
        setTab(pipelineDetailsInfo?.pipelineDetailsTab || '0');
      pipelineDetailsInfo?.workOrder && setWorkOrder(pipelineDetailsInfo?.workOrder);
    }
  }, [pipelineDetailsInfo]);

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() },
        id
      },
      type: 'get-pipeline-details'
    })
      .then((data) => {
        setPipelineDetails(data?.result[0]);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id, syncComplete, reduxTasksList, inspectionsList, checksList]);

  const {
    allDoneTasks,
    allTasksAndInspectionsForPipeline,
    dataPoints,
    fromLocation,
    MoreInfoDataPointsMap,
    isOverDue,
    licenseNumber,
    lineNumber,
    status: _status,
    toLocation,
    siteIds = [],
    allWorkOrder,
    allDoneWorkOrder,
    isWorkOrderOverDue,
    allInspections,
    allDoneInspection,
    isInspectionOverDue,
    allChecks,
    allDoneCheck,
    isCheckOverDue,
    link
  } = useMemo(() => {
    return pipelineDetails;
  }, [pipelineDetails]);

  useMemo(() => {
    if (!isLoading) {
      if (!id) return <Navigate to={'/pipelines'} />;
    }
  }, [pipelineDetails, isLoading]);

  const status = picklistsMap[_status]?.value || '';

  useEffect(() => {
    if (syncComplete) setSyncComplete(false);
  }, [syncComplete]);

  const arrayText = [
    {
      icon: <Icon name={'more-info'} />,
      text: 'More information',
      onClick: () => {
        setMoreInfo(true);
        setOpen(false);
      }
    },
    {
      icon: <Icon name={'bookmark-grey'} />,
      text: 'Bookmark',
      onClick: () => {
        setOpenModal(true);
        setOpen(false);
      }
    },
    {
      icon: <Icon name={'open-in-oplii-desktop'} />,
      text: 'Open in Oplii desktop',
      onClick: () => {
        window.open(link, '_blank');
      }
    }
  ];

  const items = useMemo(() => {
    return !isLoading
      ? [
          ...(allTasksAndInspectionsForPipeline?.length > 0
            ? [
                {
                  label: `Tasks (${allTasksAndInspectionsForPipeline?.length})`,
                  keyName: 'tasks',
                  children: (
                    <PipelineSite
                      setChecks={setChecks}
                      setAllTask={setAllTask}
                      setWorkOrder={setWorkOrder}
                      setInspections={setInspections}
                      allWorkOrder={allWorkOrder}
                      allDoneWorkOrder={allDoneWorkOrder}
                      allInspection={allInspections}
                      allDoneInspection={allDoneInspection}
                      allTasks={allTasksAndInspectionsForPipeline}
                      allDoneTasks={allDoneTasks}
                      isOverDue={isOverDue}
                      isWorkOrderOverDue={isWorkOrderOverDue}
                      isInspectionOverDue={isInspectionOverDue}
                      allChecks={allChecks}
                      allDoneCheck={allDoneCheck}
                      isCheckOverDue={isCheckOverDue}
                    />
                  )
                }
              ]
            : [])
        ]
      : [];
  }, [
    isLoading,
    isFilterActive,
    allTasksAndInspectionsForPipeline,
    allDoneTasks,
    allTasksAndInspectionsForPipeline
  ]);

  useEffect(() => {
    if (items?.length > 0) {
      const params = new URLSearchParams(location.search);
      const currentTab = parseInt(tab);
      params.set('tab', currentTab);

      if (items[currentTab]?.keyName === 'tasks') {
        if (allTask) params.set('currentOpenTaskTab', 'allTask');
        else if (workOrder) params.set('currentOpenTaskTab', 'workOrder');
        else if (inspections) params.set('currentOpenTaskTab', 'inspections');
        else if (checks) params.set('currentOpenTaskTab', 'checks');
        // else if (workRequest) params.set('currentOpenTaskTab', 'workRequest');
        else if (!workOrder && !allTask && !inspections && !checks)
          params.delete('currentOpenTaskTab');
      }

      params.set(
        'virtualScrollTab',
        currentTab === 0 && items[currentTab]?.keyName === 'tasks'
          ? -1
          : allTasksAndInspectionsForPipeline?.length > 0
          ? currentTab - 1
          : currentTab
      );
      params.set('keyName', items[currentTab]?.keyName);
      const tabParams = '?' + params.toString();
      const prevRoute = `${location.pathname}${tabParams}`;
      navigate(prevRoute, { replace: true });
    }
  }, [tab, items, items, workOrder, allTask, inspections]);

  useEffect(() => {
    if (editPipeline) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [editPipeline]);

  const onParentClickEvent = ({ workOrderTab, workOrderSortTab }) => {
    dispatch?.(
      backHistoryActions.setPipelineDetailsPageHistory({
        workOrder,
        pipelineDetailsTab: tab,
        workOrderTab,
        workOrderSortTab
      })
    );
  };

  const pageHeaderClickEvent = () => {
    workOrder && dispatch?.(filterActions.setTaskFilters(null));
    if (moreInfo) setMoreInfo(false);
    else if (allTask) setAllTask(false);
    else if (checks) setChecks(false);
    else if (inspections) setInspections(false);
    else if (workOrder) setWorkOrder(false);

    dispatch?.(backHistoryActions.setPipelineDetailsPageHistory(null));
  };

  const pageHeaderTitle = useMemo(() => {
    return `${
      bookmarkName
        ? bookmarkName
        : moreInfo
        ? 'Pipeline Information'
        : allTask
        ? `All tasks (${
            allTasksAndInspectionsForPipeline?.length
              ? allTasksAndInspectionsForPipeline.length
              : ''
          })`
        : checks
        ? `Checks (${allChecks?.length ? allChecks.length : ''})`
        : workOrder
        ? `Work orders (${allWorkOrder?.length ? allWorkOrder.length : ''})`
        : inspections
        ? `Inspections (${allInspections?.length ? allInspections.length : ''})`
        : 'Pipeline'
    }`;
  }, [
    bookmarkName,
    moreInfo,
    allTask,
    checks,
    workOrder,
    allTasksAndInspectionsForPipeline,
    inspections
  ]);

  const pipelineMoreInfo = useMemo(() => {
    if (MoreInfoDataPointsMap && moreInfo) {
      const pipelineMoreInfoOrder = [
        'Pipeline #',
        'License # - line #',
        'Type',
        'Status',
        'From location',
        'To location',
        'Area',
        'Material',
        'Length',
        'Substance',
        'Internal protection'
      ];

      const info = Object?.keys(MoreInfoDataPointsMap)?.map((a) => {
        return {
          title:
            MoreInfoDataPointsMap[a]?.title === 'Length'
              ? `${MoreInfoDataPointsMap[a]?.value} ${
                  settings['distanceUnit'] === 'kilometers' ? 'km' : 'mi'
                }`
              : MoreInfoDataPointsMap[a]?.value || null,
          heading: MoreInfoDataPointsMap[a]?.title || null
        };
      });

      return _.sortBy(info, (obj) => {
        return _.indexOf(pipelineMoreInfoOrder, obj.heading);
      });
    }
    return [];
  }, [MoreInfoDataPointsMap, moreInfo]);

  const siteDetailsPageClassNames = useMemo(() => {
    return `${moreInfo || selectTask ? style.pageListingsWithMoreInfo : style.pageListings} ${
      style.noScroll
    } ${(workOrder || allTask || checks || inspections) && style.noScroll} 
`;
  }, [workOrder, allTask, checks, inspections, moreInfo, selectTask]);

  return (
    <PageContainer moreInfo={moreInfo}>
      <PageHeader
        disableBack={moreInfo || allTask || checks || inspections || workOrder}
        title={pageHeaderTitle}
        {...((tab !== '0' || workOrder || inspections || allTask || checks) && {
          setFilterActive
        })}
        onClick={pageHeaderClickEvent}
        count={tasksFilterCount}
        ref={assetDetailPageHeaderRef}
      />

      {isLoading ? (
        <div style={{ backgroundColor: 'transparent', width: '100%' }}>
          <LoadingRound />
        </div>
      ) : (
        <div>
          <div
            ref={mainDivRef}
            id="assetDetailPage"
            className={siteDetailsPageClassNames}
            style={{ position: 'relative', paddingBottom: '0px', marginTop: '0px' }}
          >
            <div className="marginClass" ref={siteCardRef}>
              <PipeLineCard
                toLocation={toLocation}
                title={`${licenseNumber} - ${lineNumber}`}
                pipelineDetails
                fromLocation={fromLocation}
                dataPoints={dataPoints || []}
                allTasks={allTasksAndInspectionsForPipeline}
                allDoneTasks={allDoneTasks}
                isOverDue={isOverDue}
                status={status}
                arrayText={arrayText}
                sites={
                  siteIds?.length > 0
                    ? siteIds?.map((x) => {
                        return {
                          ...cacheSites[sitesMap[x]],
                          link: `/#/site-details/${x}`
                        };
                      })
                    : []
                }
                open={open}
                setOpen={setOpen}
                moreInfo={moreInfo}
                link={''}
              />
            </div>
            {moreInfo ? (
              <>
                <MoreInformation info={pipelineMoreInfo} />
                <PermissionRestrict roles={PERMISSIONS.MOBILE_SUGGESTCHANGE_PIPELINES}>
                  <div className="flex justify-end ">
                    <div className="btnEdit" onClick={() => setEditPipeline((prev) => !prev)}>
                      <Icon name={'edit-btn'} alt="" />
                    </div>
                  </div>
                </PermissionRestrict>
              </>
            ) : (
              <div className="flex relative">
                <div
                  style={{
                    marginTop: '0px',
                    overflow: 'hidden',
                    paddingBottom: '0px'
                  }}
                  className={`${
                    moreInfo ? style.pageListingsWithMoreInfo : style.pageListings
                  } mobileMargin`}
                >
                  {allTask || inspections || workOrder || checks ? (
                    <WorkOrder
                      asset="pipeline"
                      tasks={
                        inspections
                          ? allInspections
                          : allTask
                          ? allTasksAndInspectionsForPipeline
                          : workOrder
                          ? allWorkOrder
                          : checks
                          ? allChecks
                          : []
                      }
                      isFilterActiveHeader={isFilterActive}
                      setFilterActiveHeader={setFilterActive}
                      setSyncComplete={setSyncComplete}
                      selectTodoOrInProgressStatus={!_.isEmpty(bookmarkName) ? true : false}
                      onParentClickEvent={onParentClickEvent}
                      workOrderSelectedTab={pipelineDetails?.workOrderTab || null}
                      workOrderSortTab={pipelineDetails?.workOrderSortTab || null}
                      siteDetails
                      filterType={
                        allTask
                          ? 'all'
                          : inspections
                          ? 'inspection'
                          : workOrder
                          ? 'workOrder'
                          : checks
                          ? 'check'
                          : null
                      }
                    />
                  ) : items?.length > 0 ? (
                    <div
                      className={`assetDetailNavNotFixed`}
                      ref={swipeAbleMenuRef}
                      id="assetDetailsNavigation"
                    >
                      <NewTabs
                        active={tab}
                        tabs={items}
                        setActive={(tab) => setTab(tab)}
                        className={`${style.mobileMargin}`}
                      />
                    </div>
                  ) : (
                    <>
                      <div
                        className="no-result"
                        style={{ flex: 1, justifyContent: 'flex-start', gap: '20px' }}
                      >
                        <div>No results</div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {openModal && (
        <PipelineBookMark
          open={openModal}
          setOpen={(value) => {
            setOpenModal(value);
          }}
          placeholder="My favourite truck"
          type="Pipeline Details"
          siteId={id || null}
        />
      )}

      {editPipeline && (
        <EditPipeline
          open={editPipeline}
          closedModal={() => setEditPipeline((prev) => !prev)}
          pipelineDetails={pipelineDetails}
        />
      )}
    </PageContainer>
  );
};

export default PipelineSiteDetails;
