/* eslint-disable no-unused-vars */

import React, { useState, useMemo } from 'react';

import DataSnippets from '@components/data-snippet';
import OpenLink from './open-link';

import { formWrapperActions } from '@redux/slices/form-wrapper';
import { getAppDispatch } from '@utilities/dispatch.util';
import { useOpenLink } from '@hooks/useOpenLink';

import style from '../task.module.scss';

const openLinkDefaultValue = {
  linkValue: '',
  pipeline: false,
  equipment: false,
  site: false,
  structure: false
};

const CardSite = ({
  className,
  showNotIcon = false,
  formWrapper = false,
  setShowSites = null,
  variant = null,
  showValue = false,
  siteData = {},
  siteIds = [],
  highlight = '',
  divider = false,
  appliesTo = false,
  taskId,
  assetId
}) => {
  const [desktopLink, setDesktopLink] = useState(openLinkDefaultValue);
  const [openLink, setOpenLink] = useOpenLink({
    taskId,
    assetId
  });
  const dispatch = getAppDispatch?.();

  const dataPoints = useMemo(() => {
    return formWrapper
      ? siteData?.dataPoints?.filter((x) => x?.Id === 'Type')
      : siteData?.dataPoints;
  }, [formWrapper, siteData?.dataPoints]);

  const onClickOpliiDesktopLinkEvent = ({
    linkValue,
    pipeline = false,
    equipment = false,
    site = false,
    structure = false
  }) => {
    setDesktopLink({
      linkValue,
      pipeline,
      equipment,
      site,
      structure
    });
    setOpenLink((prev) => !prev);

    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId,
        assetId
      })
    );
  };

  return (
    <div
      style={{
        position: 'relative'
      }}
    >
      <DataSnippets
        variant={variant}
        backFlex={style.backClass}
        title={siteData?.title}
        link={
          !formWrapper && !siteData?.hseOffsiteEvent ? `/#/site-details/${siteData?.siteId}` : ''
        }
        onClickOpliiDesktopLinkEvent={(linkValue) =>
          onClickOpliiDesktopLinkEvent({ linkValue, site: true })
        }
        hseOffsiteEvent={siteData?.hseOffsiteEvent}
        subtitle={siteData?.subtitle}
        className={`${style.mainClass} ${className} ${
          variant === 'secondary' && formWrapper ? style['mt-16'] : ''
        } `}
        dataPoints={dataPoints}
        iconName={!showNotIcon && (siteData?.iconName || 'map-pin')}
        kmValue={siteData?.kmValue || ''}
        unit={siteData?.unit || ''}
        setOpenAll={() => {
          setShowSites && setShowSites((prev) => !prev);
        }}
        value={showValue && siteIds.length > 1 ? `+${siteIds.length - 1}` : null}
        divider={divider}
        highlightNames={highlight}
        {...(!formWrapper && { status: siteData?.status })}
        {...(!formWrapper && { childrenList: siteData?.childrenList })}
        {...(!formWrapper && { allTasksForSite: siteData?.allTasksForSite })}
        {...(!formWrapper && { allDoneTasks: siteData?.allDoneTasks })}
        {...(!formWrapper && { isOverDue: siteData?.isOverDue })}
      />

      <OpenLink
        openLink={openLink}
        desktopLink={desktopLink}
        setOpenLink={(val) => {
          setOpenLink(val);
        }}
        setDesktopLink={setDesktopLink}
        selectedLinkTypeIcon={'map-pin'}
        selectedLinkTypeName={'site'}
        taskId={taskId || ''}
        assetId={assetId || ''}
      />
    </div>
  );
};

export default CardSite;
