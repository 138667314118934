/* eslint-disable no-unused-vars */

import React, { memo, useEffect, useRef, useState, useCallback } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { store } from 'redux/store';

import Badge from '@components/badge/badge';
import Icon from '@components/icon-component';
import Button from '@components/button/button.component';
import StructureFilter from './structure-filter.component';
import LoadingRound from '@components/loading-round/loading-round';
import VirtualList from '@components/virtual-list/virtual-list';
import Tags from '@components/tags/tags';
import StructureListingInner from '@components/structure-listing-inner';

import { getAppDispatch } from '@utilities/dispatch.util';
import { getStructureDefaultFilters, tagsDataList } from './helper';
import { backHistoryActions } from '@redux/slices/back-history';
import { useDebounce } from '@hooks/useDebounce';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { initWebWorker } from 'webworker';
import useDistance from '@hooks/useDistance';

import { cacheActions } from '@redux/slices/cache';

import style from './style.module.scss';

const StructureListing = ({
  Title,
  assetDetailsStructureLists = false,
  isFilterActiveHeader = false,
  setFilterActiveHeader = null,
  from,
  siteId = null,
  detailPage = false,
  detailsPageBookmarks = true
}) => {
  const dispatch = getAppDispatch();
  const isSet = useRef(null);
  const [searchParams] = useSearchParams();
  const latestDistanceCalculate = useDistance({});

  const bookmarkFilter = detailsPageBookmarks ? searchParams.get('bookmarkFilter') : null;

  const bookmarkName = searchParams.get('bookmarkName');

  const dataFetchedRef = useRef(false);
  const { width } = useWindowDimensions();
  const [searched, setSearched] = useState('');
  const [filtersCount, setFiltersCount] = useState(0);
  const [resetFilters, setResetFilters] = useState(false);
  const [SelectedFilter, setSelectedFilter] = useState(['# TASKS']);
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);
  const [isHidden, setIsHidden] = useState(false);

  const [filters, setFilters] = useState(getStructureDefaultFilters);
  const { sitesMap = {}, sites: cacheSites = [] } = useSelector((state) => state.cache);
  const coordinates = useSelector((state) => state.cache.coordinates);
  const { jurisdictionNumbersMap = {} } = useSelector((state) => state.jurisdictionNumbers);

  const { picklistsMap = {} } = useSelector((state) => state.picklists);

  const structureDisplayFields =
    useSelector((state) => state.settings.settings?.['structureDisplayFields']) || '';
  const siteDisplayFields =
    useSelector((state) => state.settings.settings?.['siteDisplayFields']) || '';

  const [sitesAreaHierarchyTreeDataMap, setSitesAreaHierarchyTreeDataMap] = useState({});
  const [sitesSiteTypeDataMap, setSitesSiteTypeDataMap] = useState({});
  const [sitesSiteStatusDataMap, setSitesSiteStatusDataMap] = useState({});
  const [sitesWorkOrderPriorityDataMap, setSitesWorkOrderPriorityDataMap] = useState({});
  const [structuresStructureTypeDataMap, setStructuresStructureTypeDataMap] = useState({});
  const [structuresStructureStatusDataMap, setStructuresStructureStatusDataMap] = useState({});

  const [loading, setLoading] = useState(true);
  const [structures, setStructures] = useState([]);
  const [loadingOnSearch, setLoadingOnSearch] = useState(true);
  const [filterStructureList, setFilterStructureList] = useState([]);

  useEffect(() => {
    dispatch?.(backHistoryActions.clearAllDetailsPage(null));
    if (assetDetailsStructureLists?.length > 0) {
      setStructures(assetDetailsStructureLists);
      setLoading(false);
    } else {
      if (latestDistanceCalculate.coordinates) {
        dispatch?.(
          cacheActions.setUnitAndCoordinates({
            unit: latestDistanceCalculate?.unit,
            coordinates: latestDistanceCalculate?.coordinates
          })
        );
      }

      dispatch?.(cacheActions.setSitesMobile());

      initWebWorker({
        args: {
          store: { ...store.getState() }
        },
        type: 'get-all-structure-listing'
      })
        .then((data) => {
          setStructures(data?.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
      // }
    }
  }, [dispatch, assetDetailsStructureLists, latestDistanceCalculate.coordinates]);

  useEffect(() => {
    if (!_.isEmpty(bookmarkFilter)) {
      const filtersInDecode = decodeURIComponent(bookmarkFilter);
      const filterInParse = JSON.parse(filtersInDecode);
      filterInParse?.filters && setFilters(filterInParse?.filters);
      filterInParse?.filters &&
        filterInParse?.filters['searched'] &&
        setSearched(filterInParse?.filters['searched'] || '');
      if (!_.isEmpty(filterInParse?.sort))
        setSelectedFilter(Object.keys(filterInParse?.sort) || '# TASKS');
    }
    return () => {};
  }, [bookmarkFilter]);

  useEffect(() => {
    setFilterActiveHeader && setFilterActiveHeader(isFilterActive);
  }, [isFilterActive]);

  useEffect(() => {
    assetDetailsStructureLists && setFilterActive(isFilterActiveHeader);
  }, [isFilterActiveHeader]);

  const debouncedSearchInputValue = useDebounce(searched, 1000);

  useEffect(() => {
    if (!loading) {
      !loadingOnSearch && structures?.length > 0 && setLoadingOnSearch(true);
      initWebWorker({
        args: {
          filters: { ...filters, searched: debouncedSearchInputValue?.trim() },
          sitesMap,
          structures,
          SelectedFilter,
          jurisdictionNumbersMap,
          structureDisplayFields,
          picklistsMap,
          cacheSites,
          siteDisplayFields,
          store: { ...store.getState() }
        },
        type: 'get-searched-structure-listing'
      })
        .then((data) => {
          const {
            filteredStructure,
            sitesSiteTypeData,
            sitesSiteStatusData,
            structuresStructureTypeData,
            structuresStructureStatusData,
            sitesAreaHierarchyTreeData,
            sitesWorkOrderPriorityDataMap
          } = data?.result || {};
          setSitesWorkOrderPriorityDataMap(sitesWorkOrderPriorityDataMap || {});
          setSitesSiteTypeDataMap(sitesSiteTypeData || {});
          setSitesSiteStatusDataMap(sitesSiteStatusData || {});
          setSitesAreaHierarchyTreeDataMap(sitesAreaHierarchyTreeData || {});
          setStructuresStructureTypeDataMap(structuresStructureTypeData);
          setStructuresStructureStatusDataMap(structuresStructureStatusData);
          setFilterStructureList([...(filteredStructure || [])]);
          setLoadingOnSearch(false);
        })
        .catch((error) => {
          setLoadingOnSearch(false);
        });
    }
  }, [
    filters,
    debouncedSearchInputValue,
    SelectedFilter,
    isFilterActive,
    width,
    structures,
    loading
  ]);

  const tagsData = tagsDataList.filter((el) => {
    if (el === 'DISTANCE' && !coordinates) return false;
    return true;
  });

  useEffect(() => {
    if (!isSet.current && coordinates && SelectedFilter.includes('# TASKS')) {
      if (!_.isEmpty(bookmarkFilter)) {
        const filtersInDecode = decodeURIComponent(bookmarkFilter);
        const filterInParse = JSON.parse(filtersInDecode);
        if (!_.isEmpty(filterInParse?.sort))
          setSelectedFilter(Object.keys(filterInParse?.sort) || '# TASKS');
      } else {
        setSelectedFilter(['DISTANCE']);
        isSet.current = true;
      }
    }
  }, [coordinates, SelectedFilter, bookmarkFilter]);

  const resetFilter = () => {
    return (
      <div className="no-result">
        <div>No results.</div>

        {filtersCount > 0 && (
          <Button
            variant="border"
            onClick={() => setResetFilters((prev) => !prev)}
            text="Reset filters"
            btnClass="taskBtnClass"
          />
        )}
      </div>
    );
  };

  const handleWheel = (e) => {
    const virtualList = document?.querySelector('.cstmScrollerRef')?.scrollTop;

    if (e.deltaY > 0 && !isHidden) {
      setIsHidden(true);
    } else if (e.deltaY < 0 && virtualList === 0 && isHidden) {
      setIsHidden(false);
    }
  };

  let startY;

  const handleScroll = (e) => {
    const virtualList = document?.querySelector('.cstmScrollerRef')?.scrollTop;

    const deltaY = e.touches[0].clientY - startY;

    if (deltaY < 0 && !isHidden) {
      setIsHidden(true);
    } else if (deltaY > 0 && virtualList === 0 && isHidden) {
      setIsHidden(false);
    }
  };

  const handleTouchStart = (e) => {
    startY = e.touches[0].clientY;
  };

  const structureListingScrollEvent = (e) => {
    const assetDetailsNavigation = document.getElementById('assetDetailsNavigation');
    const siteDetailsPage = document.querySelector('#assetDetailPage');
    const currentTab = parseInt(searchParams.get('virtualScrollTab') || 0);
    const virtualListScroll = document?.querySelectorAll('.cstmScrollerRef')[currentTab];

    if (virtualListScroll.scrollTop === 0) {
      siteDetailsPage.scrollTop = 0;
      assetDetailsNavigation.classList.remove('assetDetailNavFixed');
      assetDetailsNavigation.classList.add('assetDetailNavNotFixed');
    }
    if (assetDetailsNavigation?.classList.value.includes('assetDetailNavNotFixed')) {
      if (virtualListScroll) {
        virtualListScroll.classList.remove('virtualListOverflowOverlay');
        virtualListScroll.classList.add('virtualListOverflowOverlay');
      }
    } else if (virtualListScroll) {
      virtualListScroll.classList.remove('virtualListOverflowOverlay');
      virtualListScroll.classList.add('virtualListOverflowOverlay');
    }
  };

  const structureLists = () => {
    return (
      <div
        className="App"
        onTouchStart={handleTouchStart}
        onTouchMove={handleScroll}
        onWheel={handleWheel}
        style={{ height: '100%' }}
      >
        {
          <div
            className={`op-filter-sort ${style['op-filter-sort']} ${isHidden ? style.hidden : ''}`}
            style={{
              borderBottom: loadingOnSearch ? 'none ' : '1px solid rgb(229, 229, 229)'
            }}
          >
            <div
              className={style['op-filter-sort-content']}
              style={{ position: isHidden ? 'absolute' : 'static' }}
            >
              {!isFilterActive && (
                <div className={style.onlyIcon} onClick={() => setFilterActive(true)}>
                  <Icon name={filtersCount > 0 ? 'filter-blue' : 'filter-black'} />
                </div>
              )}
              <div
                style={{
                  flex: 1
                }}
                className={`btn-sort-wrap ${isFilterActive ? '' : 'btn-sort-wrap-list'}`}
              >
                {!isFilterActive && <div className="sort-by">Sort By</div>}

                <Tags tags={tagsData} value={SelectedFilter} onChange={setSelectedFilter} />
              </div>
            </div>
          </div>
        }
        {loadingOnSearch ? (
          <div style={{ backgroundColor: 'transparent', width: '100%' }}>
            <LoadingRound />
          </div>
        ) : (
          <VirtualList
            divider
            data={filterStructureList}
            component={StructureListingInner}
            onScrollEvent={assetDetailsStructureLists ? structureListingScrollEvent : null}
            isHidden={isHidden}
            componentProps={{
              highlight: searched,
              highlightName: true,
              structureListing: true,
              from,
              noLink: true
            }}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    SelectedFilter.length === 0 && setSelectedFilter(['A-Z']);
  }, [SelectedFilter]);

  useEffect(() => {
    if (detailPage && filterStructureList)
      document.getElementById(
        'assets-counts-in-nav-tabs-structure'
      ).innerText = `Structure (${filterStructureList?.length})`;
  }, [filterStructureList, detailPage]);

  const structureFilterGroups = useCallback(() => {
    return (
      <StructureFilter
        title={Title}
        structures={structures || []}
        onSearch={setSearched}
        length={filterStructureList.length}
        resetFilters={resetFilters}
        setResetFilters={setResetFilters}
        filtersCount={filtersCount}
        onChangeFilters={setFilters}
        isFilterActive={isFilterActive}
        setFiltersCount={setFiltersCount}
        setFilterActive={setFilterActive}
        sitesSiteTypeDataMap={sitesSiteTypeDataMap}
        sitesSiteStatusDataMap={sitesSiteStatusDataMap}
        sitesWorkOrderPriorityDataMap={sitesWorkOrderPriorityDataMap}
        sitesAreaHierarchyTreeDataMap={sitesAreaHierarchyTreeDataMap}
        structuresStructureTypeDataMap={structuresStructureTypeDataMap}
        structuresStructureStatusDataMap={structuresStructureStatusDataMap}
        selectedSort={SelectedFilter}
        bookmarkFilter={bookmarkFilter}
        removeClassOnAssetDetailsFilters={assetDetailsStructureLists}
        siteId={siteId}
        detailPage={detailPage}
        store={{ ...store.getState() }}
      />
    );
  }, [
    isFilterActive,
    Title,
    structures,
    filterStructureList,
    sitesSiteTypeDataMap,
    assetDetailsStructureLists,
    filtersCount,
    resetFilters
  ]);

  const structureFilterGroupsForAssetDetailsPage = () => {
    return structureFilterGroups();
  };

  return (
    <div className="relative overflow-x-hidden">
      {!assetDetailsStructureLists && (
        <div
          className="op-page-name op-listing-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative"
          style={{
            position: 'sticky',
            top: 0
          }}
        >
          <Link className="op-back " to={-1}></Link>
          <h1 className="h1-semibold pl-16">
            {!_.isEmpty(bookmarkName) ? (
              bookmarkName
            ) : (
              <>
                {' '}
                {Title} ({filterStructureList?.length?.toLocaleString()})
              </>
            )}
          </h1>
          <button className="filter-btn" onClick={() => setFilterActive(true)}>
            <Badge size="small" count={filtersCount} color="#ffffff" textColor={'#2f80ed'}>
              <Icon name={'filter'} color={'#ffffff'} width="20px" height="18px" />
            </Badge>
          </button>
        </div>
      )}

      <div
        className="flex"
        style={{
          height: 'calc(100vh - 94px)',
          overflowY: 'auto'
        }}
        id="assetDetailsWorkOrderAndInspections"
      >
        {structureFilterGroupsForAssetDetailsPage()}
        <div
          className={`${
            detailPage ? style['page-listingForAssetDetails'] : style['page-listing']
          }  ${style.paddingClass}`}
          style={{
            width: `calc(100% - ${isFilterActive ? '275' : '0'}px)`,
            flex: 1,
            overflowY: 'clip',
            paddingBottom: '0px'
          }}
        >
          {filterStructureList.length === 0 && loadingOnSearch === false
            ? resetFilter()
            : structureLists()}
        </div>
      </div>
    </div>
  );
};

export default memo(StructureListing);
