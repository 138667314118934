/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';

export const filterTasks = ({ filters, type = '', data = [], notWantTagMaps = false, store }) => {
  const { tasks: reduxTasks, cache, picklists } = store;
  const {
    sitesMap = {},
    equipmentsMap = {},
    equipments: cacheEquipments = [],
    sites: cacheSites = []
  } = cache;

  const { picklistsMap = {} } = picklists;

  const sitesSiteTypeDataMap = {},
    sitesSiteStatusDataMap = {},
    sitesAreaHierarchyTreeDataMap = {},
    sitesWorkOrderPriorityDataMap = {};
  const {
    area,
    proximity,
    siteType,
    siteStatus,
    taskType,
    priority,
    workOrderPriority,
    assign,
    dueDate,
    scheduledDate,
    download,
    searched,
    recurringTemplate,
    taskStatus,
    workOrderType,
    createdAt,
    inspectionRun,
    syncedDate: syncedDateFilter,
    hseEventCategory,
    checkRecurrancePriority
  } = filters;

  const filterDueDate = dueDate;

  const inspectionTypeMap = filters?.inspectionType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const checkTypeMap = filters?.checkType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const hseEventCategoryMap = hseEventCategory?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const inspectionRunMap = inspectionRun?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedAreaMap = area?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const workOrderTypeMap = workOrderType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const selectedSiteTypeMap = siteType?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const selectedSiteStatusMap = siteStatus?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});
  const selectedTaskType = taskType?.value?.reduce((acc, curr) => {
    acc[
      curr === 'Work orders'
        ? 'Work Order'
        : curr === 'Work requests'
        ? 'Work Request'
        : curr === 'Inspections'
        ? 'Inspection'
        : curr === 'HSE events'
        ? 'HSE'
        : curr === 'Checks'
        ? 'Check'
        : 'Work Order'
    ] = true;
    return acc;
  }, {});

  const selectedTaskStatus = taskStatus?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedWorkOrderPriority = workOrderPriority?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedRecurrancePriority = checkRecurrancePriority?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const selectedWorkOrderRecurringTemplateMap = recurringTemplate?.value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const list =
    type && ['Check', 'Inspection', 'Work Order'].includes(type)
      ? data.filter((task) => task.type === type)
      : data;
  const tasks = list?.filter((task) => {
    const {
      id,
      type,
      priorityId,
      title,
      sites = [],
      siteId,
      description,
      incidentNumber,
      dueDate,
      nextDue,
      startDate,
      syncedDate,
      equipmentId,
      equipments = [],
      syncDateCustom = null,
      createdDate = null,
      users,
      equipmentAsset = {},
      siteAsset = {},
      rtInterval,
      rtData = {},
      taskFooter,
      newWorkRequest = false,
      customAssignedTaskValue = [],
      rtName,
      rtNumber,
      hseData,
      mocData,
      structureData,
      ncrData,
      pipelineData,
      status,
      typeId,
      detailInspectionsInfo,
      inspectionTypeId,
      inspectionType,
      RunName,
      pipelineAsset,
      runNumber,
      recurrencePriorityId,
      recurrence,
      identifier,
      location,
      NextScheduled
    } = task;

    !notWantTagMaps && (sitesWorkOrderPriorityDataMap[priorityId] = true);

    if (
      assign?.value?.length > 0 &&
      !assign?.value?.some((r) => customAssignedTaskValue?.includes(r))
    )
      return false;

    const sitesAsset = [siteId, ...sites.filter((site) => site !== siteId)].filter(
      (site) => cacheSites[sitesMap[site]]
    );
    const equipmentsAssets = [
      equipmentId,
      ...equipments.filter((equipment) => equipment !== equipmentId)
    ]?.filter((equipment) => cacheEquipments[equipmentsMap[equipment]]);

    if (taskType.value.length > 0 && !selectedTaskType[newWorkRequest ? 'Work Request' : type])
      return false;

    if (taskStatus?.value?.length > 0 && !selectedTaskStatus[status]) return false;

    if (filters?.inspectionType?.value?.length > 0 && !inspectionTypeMap[inspectionTypeId])
      return false;

    if (filters?.checkType?.value?.length > 0 && !checkTypeMap[inspectionTypeId]) return false;

    if (hseEventCategory?.value?.length > 0 && !hseEventCategoryMap[inspectionType]) return false;

    // if (inspectionRun?.value.length > 0 && !inspectionRunMap[RunName?.trim()]) return false;
    if (inspectionRun?.value.length > 0 && !inspectionRunMap[`${runNumber} - ${RunName}`?.trim()])
      return false;

    if (
      recurringTemplate.value.length > 0 &&
      !selectedWorkOrderRecurringTemplateMap[`${rtNumber} - ${rtName?.trim()}`]
    )
      return false;
    if (workOrderType?.value?.length > 0 && !workOrderTypeMap[typeId]) return false;

    if (searched) {
      const filterObject = filterTaskObject({
        equipmentAsset,
        siteAsset,
        users,
        id,
        title,
        description,
        incidentNumber,
        rtInterval,
        rtData,
        taskFooter,
        hseData,
        mocData,
        structureData,
        ncrData,
        pipelineData,
        detailInspectionsInfo,
        inspectionTitle: task?.inspectionType,
        type:
          task?.typeId && picklistsMap[task?.typeId]?.value
            ? picklistsMap[task?.typeId]?.value
            : '',
        pipelineAsset,
        recurrence,
        identifier,
        location
      });

      if (
        !Object.values(filterObject).some((value) => {
          return String(value).toLowerCase().includes(searched.toLowerCase());
        })
      )
        return false;
    }

    if (workOrderPriority.value.length > 0 && !selectedWorkOrderPriority[priorityId]) return false;

    if (
      checkRecurrancePriority?.value?.length > 0 &&
      !selectedRecurrancePriority[recurrencePriorityId]
    )
      return false;

    let areaCheck = area.value.length === 0;
    let proximityCheck = proximity.value === 0;
    let siteTypeCheck = siteType.value.length === 0;
    let siteStatusCheck = siteStatus.value.length === 0;

    for (const siteId of sitesAsset) {
      const site = cacheSites[sitesMap[siteId]];

      !notWantTagMaps && (sitesSiteTypeDataMap[site?.siteType] = true);
      !notWantTagMaps && (sitesSiteStatusDataMap[site?.status] = true);
      !notWantTagMaps && (sitesAreaHierarchyTreeDataMap[site?.areaHierarchy] = true);

      if (!areaCheck && area.value.length > 0 && selectedAreaMap[site?.areaHierarchy])
        areaCheck = true;
      if (!siteTypeCheck && siteType.value.length > 0 && selectedSiteTypeMap[site?.siteType])
        siteTypeCheck = true;
      if (!siteStatusCheck && siteStatus.value.length > 0 && selectedSiteStatusMap[site?.status])
        siteStatusCheck = true;
      if (!proximityCheck && proximity.value && site?.distance && site.distance < proximity.value)
        proximityCheck = true;
    }

    for (const equipmentsAsset of equipmentsAssets) {
      const equipmentValue = cacheEquipments[equipmentsMap[equipmentsAsset]];

      const equipmentSite = equipmentValue.siteId || '';
      const distance = (equipmentSite && cacheSites[sitesMap[equipmentSite]]?.distance) || 0;
      if (!proximityCheck && proximity.value && distance && distance < proximity.value)
        proximityCheck = true;
    }

    if (!areaCheck) return false;
    if (!siteTypeCheck) return false;
    if (!proximityCheck) return false;
    if (!siteStatusCheck) return false;

    const dueCon = _.isArray(filterDueDate.value) ? !filterDueDate.value.includes('') : true;
    const createAtCon = _.isArray(createdAt?.value) ? !createdAt?.value.includes('') : true;
    const syncedDateCon = _.isArray(syncedDateFilter?.value)
      ? !syncedDateFilter?.value.includes('')
      : true;

    const scheduleCon = _.isArray(scheduledDate.value) ? !scheduledDate.value.includes('') : true;

    if (
      !_.isEmpty(filterDueDate.value) &&
      dueCon &&
      !dueDateFilter(dueDate ? dueDate : nextDue, filterDueDate, id)
    )
      return false;

    if (
      !_.isEmpty(scheduledDate.value) &&
      scheduleCon &&
      !scheduledDateFilter(startDate || NextScheduled, syncedDate, scheduledDate, id)
    )
      return false;

    if (
      !_.isEmpty(createdAt?.value) &&
      createAtCon &&
      !createAtOrSyncedDateFilter({ dateForFilter: createdDate, filterSelectedDate: createdAt })
    )
      return false;
    if (
      !_.isEmpty(syncedDateFilter?.value) &&
      syncedDateCon &&
      !createAtOrSyncedDateFilter({
        dateForFilter: syncedDate,
        filterSelectedDate: syncedDateFilter
      })
    )
      return false;

    return true;
  });

  const tasksMap = !notWantTagMaps
    ? tasks?.reduce((acc, curr) => {
        acc[curr.id] = true;
        return acc;
      }, {})
    : {};
  return {
    tasks,
    tasksMap,
    sitesSiteTypeDataMap,
    sitesSiteStatusDataMap,
    sitesAreaHierarchyTreeDataMap,
    sitesWorkOrderPriorityDataMap
  };
};

export const filterTasksWithPromise = ({ args }) => {
  return new Promise((resolve) => {
    const response = filterTasks({ ...args });
    resolve(response);
  });
};

const filterTaskObject = ({
  equipmentAsset,
  siteAsset,
  users,
  recurrence,
  id,
  title,
  type,
  description,
  incidentNumber,
  rtInterval,
  rtData,
  hseData,
  mocData,
  structureData,
  ncrData,
  pipelineData,
  detailInspectionsInfo,
  inspectionTitle,
  pipelineAsset,
  identifier,
  location
}) => {
  const equipmentObject = equipmentAsset?.prepareTasksEquipments?.map((x) => {
    const { dataPoints = [], siteAsset = {} } = x;

    const dataPointsEquipmentObject = dataPoints.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );
    const dataPointsSiteObject = siteAsset?.dataPoints?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    return {
      title: x?.title || '',
      subtitle: x?.subtitle || '',
      siteTitle: siteAsset?.title || '',
      siteSubtitle: siteAsset?.subtitle || '',
      kmValue: siteAsset?.kmValue || '',
      unit: siteAsset?.unit || '',

      ...dataPointsEquipmentObject,
      ...dataPointsSiteObject
    };
  });

  const siteObject = siteAsset?.prepareTasksSites?.map((x) => {
    const { dataPoints = [] } = x;

    const dataPointsSiteObject = dataPoints?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    return {
      title: x?.title || '',
      subtitle: x?.subtitle || '',
      kmValue: x?.kmValue || '',
      unit: x?.unit || '',
      ...dataPointsSiteObject
    };
  });

  const transformedObjEquipment = equipmentObject?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-equipment`] = obj[key];
    });
    return acc;
  }, {});

  const transformedObjSite = siteObject?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-site`] = obj[key];
    });
    return acc;
  }, {});

  const hseDataFilterObjects = !_.isEmpty(hseData) ? appliesToTypesDataPoints(hseData, 'hse') : {};
  const mocDataFilterObjects = !_.isEmpty(mocData) ? appliesToTypesDataPoints(mocData, 'moc') : {};

  const ncrDataFilterObjects = !_.isEmpty(ncrData) ? appliesToTypesDataPoints(ncrData, 'ncr') : {};

  const structureObject = structureData?.structureData?.map((x) => {
    const { dataPoints = [], siteAsset = {} } = x;

    const dataPointsSiteObject = siteAsset?.dataPoints?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    const dataPointsStructureObject = dataPoints?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    return {
      name: x?.name || '',
      structureType: x?.structureType || '',
      siteTitle: siteAsset?.title || '',
      siteSubtitle: siteAsset?.subtitle || '',
      kmValue: siteAsset?.kmValue || '',
      unit: siteAsset?.unit || '',
      ...dataPointsStructureObject,
      ...dataPointsSiteObject
    };
  });

  const transformedObjStructure = structureObject?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-structure`] = obj[key];
    });
    return acc;
  }, {});

  const pipelineObject = pipelineAsset?.prepareTasksPipeline?.map((x) => {
    const { dataPoints = [], multipleSiteAssets = [] } = x;

    let dataPointsPipelineSiteObject = multipleSiteAssets?.map((x) => {
      const { dataPoints = [] } = x?.prepareTasksSites?.[0] || {};

      const dataPointsSiteObject = dataPoints?.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
        {}
      );

      return {
        title: x?.title || '',
        subtitle: x?.subtitle || '',
        kmValue: x?.kmValue || '',
        unit: x?.unit || '',
        ...dataPointsSiteObject
      };
    });

    const dataPointsForPipelineObject = dataPoints?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    dataPointsPipelineSiteObject = dataPointsPipelineSiteObject?.reduce((acc, obj, index) => {
      Object.keys(obj).forEach((key) => {
        acc[`${key}-pipeline-site`] = obj[key];
      });
      return acc;
    }, {});

    return {
      fromLocation: x?.fromLocation || '',
      licenseNumber: x?.licenseNumber || '',
      fromLocationAndLicenseNumber: `${x?.licenseNumber}-${x?.lineNumber}`,
      toLocation: x?.toLocation || '',
      ...dataPointsForPipelineObject,
      ...dataPointsPipelineSiteObject
    };
  });

  const transformedObjPipeline = pipelineObject?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-pipeline`] = obj[key];
    });
    return acc;
  }, {});

  const detailInspectionsInfoObjects = detailInspectionsInfo?.reduce((acc, obj, index) => {
    Object.keys(obj).forEach((key) => {
      acc[`${key}-inspection-info`] = obj[key];
    });
    return acc;
  }, {});

  const filterObject = {
    id,
    title,
    type,
    description,
    incidentNumber,
    rtInterval,
    inspectionTitle,
    recurrence,
    identifier,
    location,
    users: (users && users.toString()) || '',
    ...transformedObjSite,
    ...transformedObjEquipment,
    rtDataId: rtData?.id || '',
    rtDataTitle: rtData?.title || '',
    ...transformedObjStructure,
    ...hseDataFilterObjects,
    ...mocDataFilterObjects,
    ...ncrDataFilterObjects,
    ...transformedObjPipeline,
    ...detailInspectionsInfoObjects
  };
  return filterObject;
};

const appliesToTypesDataPoints = (typeOfData = {}, keyName = 'hse') => {
  const hseSiteAssetDataPoints =
    typeOfData?.siteAsset?.dataPoints &&
    typeOfData?.siteAsset?.dataPoints?.reduce(
      (obj, item) =>
        Object.assign(obj, { [`${item.key}-${keyName}-site`]: `${item.title} : ${item.value}` }),
      {}
    );

  const hseEquipmentAssetDataPoints =
    typeOfData?.equipmentAsset?.dataPoints &&
    typeOfData?.equipmentAsset?.dataPoints?.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [`${item.key}-${keyName}-equipment`]: `${item.title} : ${item.value}`
        }),
      {}
    );

  const hseEquipmentSiteAssetDataPoints =
    typeOfData?.equipmentAsset?.siteAsset &&
    typeOfData?.equipmentAsset?.siteAsset?.dataPoints &&
    typeOfData?.equipmentAsset?.siteAsset?.dataPoints?.reduce(
      (obj, item) =>
        Object.assign(obj, {
          [`${item.key}-${keyName}-equipment-site`]: `${item.title} : ${item.value}`
        }),
      {}
    );

  const pipeLineDataPoints = typeOfData?.pipelineAsset?.reduce(
    (obj, item, index) =>
      Object.assign(obj, {
        [`pipelineAsset-fromLocation-${index}`]: item?.fromLocation || '',
        [`pipelineAsset-toLocation-${index}`]: item?.toLocation || '',
        [`pipelineAsset-licenseNumber-${index}`]: item?.licenseNumber || ''
      }),
    {}
  );

  const structureDataPoints = typeOfData?.structureData?.map((x, index) => {
    const { dataPoints = [], siteAsset = {} } = x;

    const dataPointsEquipmentObject = dataPoints.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );
    const dataPointsSiteObject = siteAsset?.dataPoints?.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    return {
      [`structureData-structureType-${index}`]: x?.structureType,
      [`structureData-name-${index}`]: x?.name,
      ...dataPointsEquipmentObject,
      ...dataPointsSiteObject
    };
  });

  const typeOfDataFilterObjects = {
    [`recordNumber-${keyName}`]: typeOfData?.recordNumber || '',
    [`recordTitle-${keyName}}`]: typeOfData?.recordTitle || '',
    [`siteAsset-title-${keyName}`]: typeOfData?.siteAsset?.title || '',
    [`siteAsset-subtitle-${keyName}`]: typeOfData?.siteAsset?.subtitle || '',
    [`siteAsset-kmValue-${keyName}`]: typeOfData?.siteAsset?.kmValue || '',
    [`siteAsset-unit-${keyName}`]: typeOfData?.siteAsset?.unit || '',

    [`equipmentAsset-title-${keyName}`]: typeOfData?.equipmentAsset?.title || '',
    [`equipmentAsset-subtitle-${keyName}`]: typeOfData?.equipmentAsset?.subtitle || '',

    [`equipmentAsset-siteAsset-title-${keyName}`]:
      typeOfData?.equipmentAsset?.siteAsset?.title || '',
    [`equipmentAsset-siteAsset-subtitle-${keyName}`]:
      typeOfData?.equipmentAsset?.siteAsset?.subtitle || '',
    [`equipmentAsset-siteAsset-kmValue-${keyName}`]:
      typeOfData?.equipmentAsset?.siteAsset?.kmValue || '',
    [`equipmentAsset-siteAsset-unit-${keyName}`]: typeOfData?.equipmentAsset?.siteAsset?.unit || '',

    ...pipeLineDataPoints,
    ...hseEquipmentSiteAssetDataPoints,
    ...hseSiteAssetDataPoints,
    ...hseEquipmentAssetDataPoints,
    ...structureDataPoints
  };
  return typeOfDataFilterObjects;
};

const dueDateFilter = (taskDueDate, dueDateFilter, id) => {
  const currentDate = moment().format('YYYY-MM-DD');

  const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');
  if (dueDateFilter?.selected === 'next') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateIncreaseByOneDay = moment().add('1', 'd').format('YYYY-MM-DD');
      const currentDate = moment().format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
        moment(taskDueDateWithoutTimeZone).isSame(moment(currentDate))
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateIncreaseBySevenDay = moment().add('1', 'w').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBySevenDay,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateIncreaseBy30Day = moment().add('1', 'M').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy30Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateIncreaseBy90Day = moment().add('90', 'd').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy90Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateIncreaseBy365Day = moment().add('1', 'y').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateIncreaseBy365Day = moment().add('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDate,
          currentDateIncreaseBy365Day,
          undefined,
          '[)'
        )
      )
        return true;
    }
  }
  if (dueDateFilter?.selected === 'on') {
    const selectedDate = moment(dueDateFilter.value).format('YYYY-MM-DD');
    if (moment(taskDueDateWithoutTimeZone).isSame(moment(selectedDate))) return true;
  }
  if (dueDateFilter?.selected === 'range') {
    const selectedDateStart = moment(dueDateFilter.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(dueDateFilter.value[1]).format('YYYY-MM-DD');
    if (
      moment(taskDueDateWithoutTimeZone).isBetween(
        selectedDateStart,
        selectedDateEnd,
        undefined,
        '[]'
      )
    )
      return true;
  }
  if (dueDateFilter?.selected === 'past') {
    if (dueDateFilter.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');
      const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');
      if (moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)))
        return true;
    }
    if (dueDateFilter.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      const taskDueDateWithoutTimeZone = moment(taskDueDate).format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');

      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (dueDateFilter.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};

const scheduledDateFilter = (startDate, syncedDate, dueDateFilter, id) => {
  if (_.isEmpty(startDate) && _.isEmpty(syncedDate)) {
    return false;
  } else {
    const currentDate = moment().format('YYYY-MM-DD');
    const taskDueDateWithoutTimeZone =
      startDate && moment(startDate).isValid() ? moment(startDate).format('YYYY-MM-DD') : false;
    const syncedDateDateWithoutTimeZone =
      syncedDate && moment(syncedDate).isValid() ? moment(syncedDate).format('YYYY-MM-DD') : false;

    if (dueDateFilter?.selected === 'next') {
      if (dueDateFilter.value?.includes('Day')) {
        const currentDateIncreaseByOneDay = moment().add('1', 'd').format('YYYY-MM-DD');
        const currentDate = moment().format('YYYY-MM-DD');

        if (
          moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
          moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDateIncreaseByOneDay)) ||
          moment(taskDueDateWithoutTimeZone).isSame(moment(currentDate)) ||
          moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDate))
        ) {
          return true;
        }
      } else if (dueDateFilter.value?.includes('Week (7 days)')) {
        const currentDateIncreaseBySevenDay = moment().add('1', 'w').format('YYYY-MM-DD');

        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBySevenDay,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBySevenDay,
            undefined,
            '[)'
          )
        )
          return true;
      } else if (dueDateFilter.value?.includes('Month (30 days)')) {
        const currentDateIncreaseBy30Day = moment().add('1', 'M').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBy30Day,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBy30Day,
            undefined,
            '[)'
          )
        )
          return true;
      } else if (dueDateFilter.value?.includes('Quarter (90 days)')) {
        const currentDateIncreaseBy90Day = moment().add('90', 'd').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBy90Day,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBy90Day,
            undefined,
            '[)'
          )
        )
          return true;
      } else if (dueDateFilter.value?.includes('Year (365 days)')) {
        const currentDateIncreaseBy365Day = moment().add('1', 'y').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBy365Day,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBy365Day,
            undefined,
            '[)'
          )
        )
          return true;
      } else if (dueDateFilter.value?.includes('Unlimited')) {
        const currentDateIncreaseBy365Day = moment().add('100', 'y').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBy365Day,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDate,
            currentDateIncreaseBy365Day,
            undefined,
            '[)'
          )
        )
          return true;
      }
    } else if (dueDateFilter?.selected === 'on') {
      const selectedDate = moment(dueDateFilter.value).format('YYYY-MM-DD');

      if (moment(taskDueDateWithoutTimeZone).isSame(moment(selectedDate))) return true;
    } else if (dueDateFilter?.selected === 'range') {
      const selectedDateStart = moment(dueDateFilter.value[0]).format('YYYY-MM-DD');
      const selectedDateEnd = moment(dueDateFilter.value[1]).format('YYYY-MM-DD');
      if (
        moment(taskDueDateWithoutTimeZone).isBetween(
          selectedDateStart,
          selectedDateEnd,
          undefined,
          '[]'
        )
      )
        return true;
    } else if (dueDateFilter?.selected === 'past') {
      if (dueDateFilter.value?.includes('Day')) {
        const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');

        if (
          moment(taskDueDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)) ||
          moment(syncedDateDateWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay))
        )
          return true;
      }
      if (dueDateFilter.value?.includes('Week (7 days)')) {
        const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDateDecreaseBySevenDay,
            currentDate,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDateDecreaseBySevenDay,
            currentDate,
            undefined,
            '[)'
          )
        )
          return true;
      }
      if (dueDateFilter.value?.includes('Month (30 days)')) {
        const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDateDecreaseBy30Day,
            currentDate,
            undefined,
            '[)' ||
              moment(syncedDateDateWithoutTimeZone).isBetween(
                currentDateDecreaseBy30Day,
                currentDate,
                undefined,
                '[)'
              )
          )
        )
          return true;
      }
      if (dueDateFilter.value?.includes('Quarter (90 days)')) {
        const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDateDecreaseBy90Day,
            currentDate,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDateDecreaseBy90Day,
            currentDate,
            undefined,
            '[)'
          )
        )
          return true;
      }
      if (dueDateFilter.value?.includes('Year (365 days)')) {
        const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDateDecreaseBy365Day,
            currentDate,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDateDecreaseBy365Day,
            currentDate,
            undefined,
            '[)'
          )
        )
          return true;
      }

      if (dueDateFilter.value?.includes('Unlimited')) {
        const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
        if (
          moment(taskDueDateWithoutTimeZone).isBetween(
            currentDateDecreaseBy365Day,
            currentDate,
            undefined,
            '[)'
          ) ||
          moment(syncedDateDateWithoutTimeZone).isBetween(
            currentDateDecreaseBy365Day,
            currentDate,
            undefined,
            '[)'
          )
        )
          return true;
      }
    } else {
      return false;
    }
  }
};
const downloadDateFilter = (syncDateCustom, download) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const syncDateCustomWithoutTimeZone = moment(syncDateCustom).format('YYYY-MM-DD');
  if (download?.selected === 'last' && !_.isEmpty(syncDateCustom)) return true;

  if (download?.selected === 'on') {
    const selectedDate = moment(download.value).format('YYYY-MM-DD');
    if (moment(syncDateCustom).isSame(moment(selectedDate))) return true;
  }

  if (download?.selected === 'range') {
    const selectedDateStart = moment(download.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(download.value[1]).format('YYYY-MM-DD');
    if (
      moment(selectedDateStart).isSame(moment(selectedDateEnd)) &&
      moment(syncDateCustom).isSame(moment(selectedDateEnd))
    )
      return true;
    else if (moment(syncDateCustom).isBetween(selectedDateStart, selectedDateEnd)) return true;
  }

  if (download?.selected === 'past') {
    if (download.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');
      if (moment(syncDateCustomWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)))
        return true;
    }
    if (download.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }

    if (download.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (download.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (download.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (download.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};

export const getTasksLength = (runs, templates, templateData, tasks) => {
  if (runs?.length > 0 || templates?.length > 0) {
    let count = 0;
    for (const el of templateData) count += el.tasks.length;
    return count;
  }

  return tasks.length;
};

export const getCheckedLength = (runs, templates, templateData, tasks) => {
  if (runs?.length > 0 || templates?.length > 0) {
    let count = 0;
    for (const el of templateData) {
      for (const task of el.tasks) {
        if (task.checked) count++;
      }
    }
    return count;
  }

  return tasks.reduce((acc, curr) => {
    if (curr.checked) acc++;
    return acc;
  }, 0);
};
export const getQuickCheckedLength = ({ inspections, store }) => {
  const { inspection } = store;
  const { inspectionFormsUserCacheMap = {}, failureCriteria } = inspection;
  const failureCriteriaMap = failureCriteria?.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});
  return inspections.reduce((acc, curr) => {
    if (inspectionFormsUserCacheMap[curr?.inspectionFormId]?.quickComplete && curr.checked) acc++;
    return acc;
  }, 0);
};

export const sortTasks = ({
  a,
  b,
  SelectedFilter,
  sitesMap,
  equipmentsMap,
  item,
  cacheEquipments = [],
  cacheSites = []
}) => {
  switch (Object.keys(SelectedFilter)[0]) {
    case 'RT ORDER': {
      const rtNumberPrev =
        (a.rtNumber && a.rtNumber && a.rtNumber.split('-') && a.rtNumber.split('-')[1]) || null;
      const rtNumberNext =
        (b.rtNumber && b.rtNumber && b.rtNumber.split('-') && b.rtNumber.split('-')[1]) || null;
      return parseInt(rtNumberPrev) - parseInt(rtNumberNext);
    }
    case 'A-Z': {
      return a.title && b.title
        ? a.title?.localeCompare(b.title)
        : a.runName && b.runName
        ? a.runName?.localeCompare(b.runName)
        : -1;
    }
    case 'SCHEDULED': {
      if (a.startDate && b.startDate) return moment(a.startDate).diff(moment(b.startDate), 'days');
      else if (a.startDate) return -1;
      else if (b.startDate) return 1;
      else return moment(a.dueDate).diff(moment(b.dueDate), 'days');
    }
    case 'DUE': {
      return moment(a.dueDate).diff(moment(b.dueDate));
    }
    case 'NEWEST':
    case 'RECENT': {
      if (item === 'To do') {
        if (a?.createdDate && b?.createdDate)
          return moment(a?.createdDate).diff(moment(b?.createdDate), 'days');
        else if (a?.createdDate) return -1;
        else if (b?.createdDate) return 1;
        else return moment(a.updatedAt).diff(moment(b.updatedAt), 'days');
      } else if (item === 'In progress' || item === 'Pending sync') {
        if (a.updatedAt && b.updatedAt) return moment(b.updatedAt).diff(moment(a.updatedAt));
        else if (a.updatedAt) return 1;
        else if (b.updatedAt) return -1;
        else return moment(a.createdAt).diff(moment(b.createdAt));
      } else if (item === 'Synced') {
        if (a.syncedDate && b.syncedDate) return moment(b.syncedDate).diff(moment(a.syncedDate));
        else if (a.syncedDate) return 1;
        else if (b.syncedDate) return -1;
        else return moment(a.dueDate).diff(moment(b.dueDate));
      } else {
        return 1;
      }
    }
    case 'DISTANCE': {
      const sitesAsset1 = [
        a.siteId,
        ...(a.sites ? a.sites.filter((site) => site !== a.siteId) : [])
      ].filter((site) => cacheSites[sitesMap[site]])[0];

      const equipmentsAsset1 = [
        a.equipmentId,
        ...(a.equipments ? a.equipments.filter((equipment) => equipment !== a.equipmentId) : [])
      ].filter((equipment) => cacheEquipments[equipmentsMap[equipment]])[0];

      const equipment1 = cacheEquipments[equipmentsMap[equipmentsAsset1]] || {};
      const equipment1Site = equipment1.siteId || '';

      const sitesAsset2 = [
        b.siteId,
        ...(b.sites ? b.sites.filter((site) => site !== b.siteId) : [])
      ].filter((site) => cacheSites[sitesMap[site]])[0];

      const equipmentsAsset2 = [
        b.equipmentId,
        ...(b.equipments ? b.equipments.filter((equipment) => equipment !== b.equipmentId) : [])
      ].filter((equipment) => cacheEquipments[equipmentsMap[equipment]])[0];

      const equipment2 = cacheEquipments[equipmentsMap[equipmentsAsset2]] || {};
      const equipment2Site = equipment2.siteId || '';

      const distance1 =
        (cacheSites[sitesMap[sitesAsset1]] && cacheSites[sitesMap[sitesAsset1]]?.distance) ||
        (equipment1Site && cacheSites[sitesMap[equipment1Site]]?.distance) ||
        0;

      const distance2 =
        (cacheSites[sitesMap[sitesAsset2]] && cacheSites[sitesMap[sitesAsset2]]?.distance) ||
        (equipment2Site && cacheSites[sitesMap[equipment2Site]]?.distance) ||
        0;

      if (distance1 === null || distance1 === undefined || !distance1) return 1;
      if (distance2 === null || distance2 === undefined || !distance2) return -1;

      return distance1 - distance2;
    }
  }
};

export const getPendingTaskAndPhotosLength = ({
  pending,
  pendingInspections,
  pendingHse,
  pendingChecks,
  syncFailedAssets
}) => {
  let photos = 0,
    documents = 0,
    workOrder = 0,
    workRequest = 0,
    inspection = 0,
    hseEvents = 0,
    checks = 0;
  pending?.map((task) => {
    if (task?.photos?.length)
      photos += task?.photos?.filter((x) => !_.isEmpty(x?.content))?.length || 0;

    if (task?.documents?.length)
      documents += task?.documents?.filter((x) => !_.isEmpty(x?.content))?.length || 0;
    if (!task?.newWorkRequest) ++workOrder;
    if (task?.newWorkRequest) ++workRequest;
    return task;
  });
  pendingInspections?.map((ins) => {
    const inspectionPhotosAndDocuments = getPhotosAndDocumentsDataFromSelectedInspection(
      ins?.formData || {}
    );

    if (ins?.type === 'Inspection') ++inspection;
    if (inspectionPhotosAndDocuments?.photos?.length)
      photos += inspectionPhotosAndDocuments?.photos?.length;
    if (inspectionPhotosAndDocuments?.documents?.length)
      documents += inspectionPhotosAndDocuments?.documents?.length;
    return ins;
  });
  pendingHse?.map((hseItems) => {
    const hsePhotosAndDocuments = getPhotosAndDocumentsDataFromSelectedInspection(
      hseItems?.formData || {}
    );

    if (hseItems?.type === 'HSE') ++hseEvents;
    if (hsePhotosAndDocuments?.photos?.length) photos += hsePhotosAndDocuments?.photos?.length;
    if (hsePhotosAndDocuments?.documents?.length)
      documents += hsePhotosAndDocuments?.documents?.length;
    return hseItems;
  });

  pendingChecks?.map((checkItems) => {
    const checkPhotosAndDocuments = getPhotosAndDocumentsDataFromSelectedInspection(
      checkItems?.formData || {}
    );

    if (checkItems?.type === 'Check') ++checks;
    if (checkPhotosAndDocuments?.photos?.length) photos += checkPhotosAndDocuments?.photos?.length;
    if (checkPhotosAndDocuments?.documents?.length)
      documents += checkPhotosAndDocuments?.documents?.length;
    return checkItems;
  });
  return {
    documents,
    photos,
    workOrder,
    workRequest,
    inspection,
    checks,
    hseEvents,
    inspectionPhotos: 0,
    equipmentRecords: 0,
    failedImageCount: syncFailedAssets?.photos?.length || 0,
    failedDocumentCount: syncFailedAssets?.documents?.length || 0,
    pendingSync: workOrder + workRequest + inspection + hseEvents + checks
  };
};
const createAtOrSyncedDateFilter = ({ dateForFilter, filterSelectedDate }) => {
  const currentDate = moment().format('YYYY-MM-DD');
  const syncDateCustomWithoutTimeZone = moment(dateForFilter).format('YYYY-MM-DD');
  if (filterSelectedDate?.selected === 'last' && !_.isEmpty(dateForFilter)) return true;

  if (filterSelectedDate?.selected === 'on') {
    const selectedDate = moment(filterSelectedDate?.value).format('YYYY-MM-DD');
    if (moment(syncDateCustomWithoutTimeZone).isSame(moment(selectedDate))) return true;
  }

  if (filterSelectedDate?.selected === 'range') {
    const selectedDateStart = moment(filterSelectedDate?.value[0]).format('YYYY-MM-DD');
    const selectedDateEnd = moment(filterSelectedDate?.value[1]).format('YYYY-MM-DD');
    if (
      moment(selectedDateStart).isSame(moment(selectedDateEnd)) &&
      moment(syncDateCustomWithoutTimeZone).isSame(moment(selectedDateEnd))
    )
      return true;
    else if (
      moment(syncDateCustomWithoutTimeZone).isBetween(
        selectedDateStart,
        selectedDateEnd,
        undefined,
        '[]'
      )
    )
      return true;
  }

  if (filterSelectedDate?.selected === 'past') {
    if (filterSelectedDate?.value?.includes('Day')) {
      const currentDateDecreaseByOneDay = moment().subtract('1', 'd').format('YYYY-MM-DD');
      if (moment(syncDateCustomWithoutTimeZone).isSame(moment(currentDateDecreaseByOneDay)))
        return true;
    }
    if (filterSelectedDate?.value?.includes('Week (7 days)')) {
      const currentDateDecreaseBySevenDay = moment().subtract('1', 'w').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBySevenDay,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }

    if (filterSelectedDate?.value?.includes('Month (30 days)')) {
      const currentDateDecreaseBy30Day = moment().subtract('1', 'M').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy30Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Quarter (90 days)')) {
      const currentDateDecreaseBy90Day = moment().subtract('90', 'd').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy90Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Year (365 days)')) {
      const currentDateDecreaseBy365Day = moment().subtract('1', 'y').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
    if (filterSelectedDate?.value?.includes('Unlimited')) {
      const currentDateDecreaseBy365Day = moment().subtract('100', 'y').format('YYYY-MM-DD');
      if (
        moment(syncDateCustomWithoutTimeZone).isBetween(
          currentDateDecreaseBy365Day,
          currentDate,
          undefined,
          '[)'
        )
      )
        return true;
    }
  } else {
    return false;
  }
};

const getPhotosAndDocumentsDataFromSelectedInspection = (obj) => {
  const photos = [],
    documents = [];
  let passFailTrue = 0,
    passFailFalse = 0,
    passFailNA = 0;

  const transformNestedObjects = (nestedObj) => {
    for (let key in nestedObj) {
      if (typeof nestedObj[key] === 'object' && nestedObj[key] !== null) {
        if ('photos' in nestedObj[key] && 'documents' in nestedObj[key]) {
          nestedObj[key].photos?.map((photo) => photos.push(photo));
          nestedObj[key].documents?.map((document) => documents.push(document));
        } else if ('photos' in nestedObj[key]) {
          if (nestedObj[key].photos && nestedObj[key]?.photos?.length) {
            nestedObj[key].photos?.map((photo) => photos.push(photo));
          }
        } else if ('documents' in nestedObj[key]) {
          if (nestedObj[key].documents && nestedObj[key].documents?.length) {
            nestedObj[key].documents?.map((document) => documents.push(document));
          }
        } else if ('answer' in nestedObj[key]) {
          if (nestedObj[key]?.answer === 'true') ++passFailTrue;
          if (nestedObj[key]?.answer === 'false') ++passFailFalse;
          if (nestedObj[key]?.answer === 'NA') ++passFailNA;
        }
      }
    }
  };
  const transformWorkOrder = (a) => {
    for (let i = 0; i < a.length; i++) {
      let item = a[i];
      item?.photo?.map((photo) => photos.push(photo));
      item?.document?.map((document) => documents.push(document));
    }
  };

  for (let key in obj) {
    if (key === 'work order') {
      const a = obj[key];
      for (let key1 in a) {
        transformWorkOrder(a[key1]);
      }
    } else transformNestedObjects(obj[key]);
  }

  return {
    photos,
    documents,
    passFailTrue,
    passFailNA,
    passFailFalse
  };
};
