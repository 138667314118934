/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';

import Accordion from '@components/accordion';
import FilterChips from '@components/filters/filter-chips';
import PicklistPills from '@components/picklist-pills';

import style from './task-status.module.scss';

const CollapseableFilter = ({
  isActive = true,
  title,
  multi,
  tags,
  tagsMap = {},
  selectValue,
  value = [],
  onChange
}) => {
  const [open, setOpen] = useState(true);
  React.useEffect(() => setOpen(isActive), [isActive]);

  const selected = value?.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  const handleChange1 = (tag, checked) => {
    if (!multi) onChange(checked ? [tag] : []);
    else {
      if (checked) onChange([...value, tag]);
      else onChange(value.filter((el) => tag !== el));
    }
  };

  const deleteSelectedChip = (tag) => {
    onChange(value.filter((el) => (tagsMap[tag]?.key || tag) !== el));
  };

  return (
    <Accordion
      open={open}
      setOpen={setOpen}
      title={title}
      isSelected={!open}
      selectedChildren={
        !open &&
        _.size(value) > 0 && (
          <SelectedCollapseableFilter
            chips={value}
            deleteSelectedChip={deleteSelectedChip}
            tagsMap={tagsMap}
          />
        )
      }
    >
      <div className={style.selectFilterDiv}>
        {tags?.map((ele, index) => (
          <PicklistPills
            key={ele?.key || index}
            text={ele?.value || ele}
            selected={selected[(selectValue ? ele?.value : ele?.key) || ele]}
            handleChange={(checked) => {
              handleChange1((selectValue ? ele?.value : ele?.key) || ele, checked);
            }}
          />
        ))}
      </div>
    </Accordion>
  );
};

export default CollapseableFilter;

export const SelectedCollapseableFilter = ({ chips, deleteSelectedChip, tagsMap }) => {
  return (
    <div className={style.selectFilterDiv}>
      {chips?.map((ele, index) => (
        <FilterChips
          key={index}
          chipText={tagsMap[ele]?.value || ele}
          deleteChip={() => deleteSelectedChip(ele)}
        />
      ))}
    </div>
  );
};
