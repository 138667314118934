import React, { useEffect } from 'react';

import style from './task.module.scss';

const FindingTaskAsset = ({ goToCanISeePage, goToRefreshingPage, goToContactSupport }) => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className={style.bgColor}>
      <div className={style.seeCard}>
        <h6 className={style.titleClass}>Search</h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          From <span style={{ fontWeight: 700 }}>Home </span> or{' '}
          <span style={{ fontWeight: 700 }}>Search, </span> type a keyword/identifier for the asset
          or task you’re seeking in the search bar and press enter.
        </p>

        <h6 className={style.titleClass} style={{ marginTop: '32px' }}>
          Go by site
        </h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          From <span style={{ fontWeight: 700 }}>Home, </span> hit the Sites button. Locate the site
          associated to the task or asset you’re seeking and open the site details page.
        </p>
        <p className={style.p} style={{ marginTop: '2px' }}>
          There, you’ll see all tasks and related assets listed.
        </p>
        <h6 className={style.titleClass} style={{ marginTop: '32px' }}>
          Filter tasks or asset listings
        </h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          For tasks, select <span style={{ fontWeight: 700 }}>Tasks </span> on the navigation bar
          and use the filters to narrow the results until you find your assigned work.
        </p>
        <p className={style.p} style={{ marginTop: '8px' }}>
          For assets, select <span style={{ fontWeight: 700 }}>More </span> on the navigation bar,
          then <span style={{ fontWeight: 700 }}> Assets. </span> Select the type of asset you’re
          seeking to open the listing page. From there, use the filter to refine the list.
        </p>

        <h6 className={style.titleClass} style={{ marginTop: '32px' }}>
          Is it just not there?
        </h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          Have you{' '}
          <span style={{ cursor: 'pointer' }} className="link" onClick={goToRefreshingPage}>
            refreshed data
          </span>{' '}
          recently?
        </p>
        <p className={style.p} style={{ marginTop: '8px' }}>
          OK. Then, go to{' '}
          <span style={{ cursor: 'pointer' }} className="link" onClick={goToCanISeePage}>
            What can I see?
          </span>{' '}
          to figure out why a task or asset might not be cached to your device.
        </p>
        <p className={style.p} style={{ marginTop: '8px' }}>
          If you’re stuck, you can always{' '}
          <span style={{ cursor: 'pointer' }} className="link" onClick={goToContactSupport}>
            submit a support request
          </span>{' '}
          .
        </p>
      </div>
    </div>
  );
};

export default FindingTaskAsset;
