import moment from 'moment';
import _ from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  tasksList: [],
  workOrderUsers: [],
  workOrderUsersMap: {},
  workOrderUsersGroupsMap: {},
  workOrderUsersGroups: [],
  workOrderAdministratorGroups: [],
  workOrderAdministrators: [],
  workOrderAdministratorsMap: [],
  workOrderAdministratorGroupsMap: [],
  recurringTemplatesMap: {},
  workOrdersMap: {},
  syncDone: {
    workOrder: 0,
    workRequest: 0,
    photos: 0,
    documents: 0,
    inspection: 0,
    inspectionPhotos: 0,
    equipmentRecords: 0,
    hseEvents: 0,
    checks: 0,
    checkPhotoCount: 0,
    checkDocumentCount: 0,

    inspectionPhotoCount: 0,
    workOrderPhotoCount: 0,
    workRequestPhotoCount: 0,
    incidentPhotoCount: 0,
    inspectionDocumentCount: 0,
    workOrderDocumentCount: 0,
    workRequestDocumentCount: 0,
    incidentDocumentCount: 0,
    failedImageCount: 0,
    failedDocumentCount: 0
  },
  syncFailedAssets: {
    photos: [],
    documents: []
  },
  selectTask: false
};

export const tasksListsSlice = createSlice({
  name: 'tasksLists',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setSelectedTask: (state, action) => {
      state.selectTask = action.payload;
    },
    setSyncDone: (state, action) => {
      state.syncDone = action.payload;
    },
    setSyncFailedAssets: (state, action) => {
      state.syncFailedAssets = action.payload;
    },
    setWorkOrdeUsersrList: (state, action) => {
      const data = action.payload || [];
      state.workOrderUsers = data;
      state.workOrderUsersMap = data.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
    setWorkOrdeUsersrGroupsList: (state, action) => {
      const data = action.payload || [];
      state.workOrderUsersGroups = data;
      state.workOrderUsersGroupsMap = data.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
    },
    setTasksList: (state, action) => {
      const recurringTemplatesMap = {};

      const tasksList = (action.payload || []).map((task) => {
        const { rtId, rtName, rtNumber, workOrderNumber } = task;
        if (rtId && !recurringTemplatesMap[rtId]) {
          const allWorkOrder = (action.payload || []).filter((x) => x.rtId === rtId) || [];
          const allDoneTasks = allWorkOrder.filter((task) => task?.status === 'Synced') || [];
          const allotDoneTasks = allWorkOrder.filter((task) => task?.status !== 'Synced') || [];

          const today = moment().format('YYYY-MM-DD');
          const isOverDue = allotDoneTasks.find((task) =>
            task.dueDate ? moment(task.dueDate).isBefore(today) : false
          );

          recurringTemplatesMap[rtId] = {
            rtId,
            title: rtName,
            id: rtNumber,
            allWorkOrder: _.size(allWorkOrder),
            allDoneTasks: _.size(allDoneTasks),
            allotDoneTasks: _.size(allDoneTasks),
            isOverDue: isOverDue
          };
        }

        return {
          ...task,
          type: task?.entityType === 'WorkRequest' ? 'Work Request' : 'Work Order',
          id: workOrderNumber || ''
        };
      });

      let tasksAll = [
        ...state.tasksList.filter((t) => t.status === 'Pending sync' || t.status === 'In progress')
      ];

      tasksList.map((task) => {
        const found = tasksAll.findIndex((t) => t.workorderId === task.workorderId) + 1;
        if (found) {
          if (!['In progress', 'Pending sync'].includes(tasksAll[found - 1].status)) {
            tasksAll[found - 1] = { ...task };
          }
        } else {
          tasksAll.push({ ...task });
        }
      });

      let map = {};

      for (let i = 0; i < tasksAll.length; i++) {
        const obj = tasksAll[i];

        const id = obj.workorderId || obj.woId;
        map[id] = obj;
      }

      state.workOrdersMap = { ...map };
      state.tasksList = [...tasksAll];
      state.recurringTemplatesMap = recurringTemplatesMap;
    },
    setTasksList1: (state, action) => {
      state.tasksList = [...state.tasksList, ...action.payload];
    },
    addNewWorkOrder: (state, action) => {
      state.tasksList = [
        ...state.tasksList,
        { ...action.payload, type: 'Work Order', id: 'New Work order' }
      ];
    },
    updateNewWorkOrder: (state, action) => {
      const copyTasksList = [...state.tasksList];
      const findIndex = copyTasksList?.findIndex((x) => x.woId === action?.payload?.id);
      copyTasksList[findIndex] = { ...copyTasksList[findIndex], ...action?.payload?.data } || {};
      state.tasksList = [...copyTasksList];
    },

    addNewWorkRequest: (state, action) => {
      state.tasksList = [
        ...state.tasksList,
        { ...action.payload, type: 'Work Order', id: 'New Work request' }
      ];
    },
    setWorkOrderAdministratorGroups: (state, action) => {
      state.workOrderAdministratorGroups = action.payload || [];
    },
    setWorkOrderAdministratorGroupsMap: (state, action) => {
      state.workOrderAdministratorGroupsMap = action.payload || [];
    },
    setWorkOrderAdministrators: (state, action) => {
      state.workOrderAdministrators = action.payload || [];
    },
    setWorkOrderAdministratorsMap: (state, action) => {
      state.workOrderAdministratorsMap = action.payload || [];
    },
    updateWorkOrder: (state, action) => {
      let copy = [...state.tasksList];
      let map = { ...state.workOrdersMap };
      state.tasksList.find((task, index) => {
        if (task.id === action.payload.id) {
          copy[index] = {
            ...copy[index],
            ...action?.payload,
            updatedAt: moment().format('YYYY-MM-DD HH:mm:ss')
          };
          const id = copy[index].workorderId || copy[index].woId;
          map[id] = { ...copy[index] };
        }
      });

      state.tasksList = [...copy];
    },
    deleteWokOrderRequest: (state, action) => {
      state.tasksList = [
        ...state.tasksList.filter((tas) => ![tas.woId, tas.workorderId].includes(action.payload))
      ];
    }
  }
});

const tasksListsReducer = tasksListsSlice.reducer;
export const tasksListsActions = tasksListsSlice.actions;
export default tasksListsReducer;
