import React from 'react';
import _ from 'lodash';
import { store } from 'redux/store';

import DocumentsCard from '@components/documents-card';

import style from '../workorder.module.scss';

const DownloadableDocuments = ({ field }) => {
  const { picklists } = store.getState();
  const { picklistsMap } = picklists;

  return (
    <div className={style.body}>
      {_.size(field?.value?.documents) > 0 && (
        <div
          style={{
            marginTop: field?.value?.photos?.length > 0 ? '16px' : '0px'
          }}
        >
          <h6>documents</h6>
          {field?.value?.documents?.map((doc) => {
            return (
              <DocumentsCard
                documentCategory={doc}
                key={doc?.documentId}
                picklistsMap={picklistsMap}
                noAction={true}
                pdfUrl={doc?.pdfUrl || null}
                downloaded={true}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DownloadableDocuments;
