import React, { memo } from 'react';

import Button from '@components/button/button.component';

const ResetFilter = ({ filtersCount, setResetFilters }) => {
  return (
    <div className="no-result" style={{ flex: 1, justifyContent: 'flex-start', gap: '20px' }}>
      <div>No tasks</div>

      {filtersCount > 0 && (
        <Button
          variant="border"
          onClick={() => setResetFilters((prev) => !prev)}
          text="Reset filters"
          btnClass="taskBtnClass"
        />
      )}
    </div>
  );
};

export default memo(ResetFilter);
