/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { store } from 'redux/store';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';

import SearchList from '../search-list';
import WizardSteps from '../wizard-steps';
import SearchTabList from '../search-tab-list';
import AssetsKindList from '../assets-kind-list';
import Icon from '@components/icon-component';
import styles from './style.module.scss';

const AddNewInspection = ({ asset = false, setActive, setAddNew }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(0);
  const [assetKind, setAssetKind] = useState('');
  const [inspectionTypeId, setInspectionTypeId] = useState('');
  const [inspectionFormName, setInspectionFormName] = useState();
  const [equipment, setEquipment] = useState(null);
  const [idOf, setIdOf] = useState('');
  const { inspection = {}, cache } = store.getState();
  const { equipments = [], equipmentsMap } = cache;
  const pathname = location.pathname;

  useEffect(() => {
    if (asset) {
      setIdOf(pathname?.split('/')[2]);
      setAssetKind(
        _.capitalize(pathname.split('/')[1].replace('parent-', '').replace('-details', ''))
      );
    }
  }, [asset]);

  const inspectionTypeLists = useMemo(() => {
    let equipmentCategory = null,
      _inspectionTypeLists = [];
    if (idOf && assetKind.toLowerCase() === 'equipment') {
      equipmentCategory = equipments[equipmentsMap[idOf]]?.equipmentCategory || null;
      const filterInspectionTypeList = inspection?.inspectionTypes?.filter((x) => {
        return x?.equipmentCategories?.length > 0
          ? x?.equipmentCategories?.includes(equipmentCategory)
          : x?.equipment
          ? x
          : false;
      });
      _inspectionTypeLists = filterInspectionTypeList?.length
        ? filterInspectionTypeList
        : inspection?.inspectionTypes;
    } else {
      _inspectionTypeLists = assetKind
        ? inspection?.inspectionTypes?.filter((x) => {
            return (
              (x[assetKind.toLowerCase()] && x?.availableOnDemand === true) ||
              (x?.equipmentCategory?.includes(equipmentCategory) && x?.availableOnDemand === true)
            );
          })
        : inspection?.inspectionTypes;
    }
    return _inspectionTypeLists?.filter((x) => x.availableOnDemand) || [];
  }, [assetKind, idOf]);

  const equipmentCategories = useMemo(() => {
    return (
      inspection?.inspectionFormsUserCache?.find(
        (form) => form?.inspectionType === inspectionTypeId
      )?.equipmentCategories || []
    );
  }, [inspectionTypeId]);

  const stepForm = [
    {
      title: 'What kind of inspection?',
      content: (
        <SearchList
          list={inspectionTypeLists || []}
          onSelect={(item, formName, props) => {
            if (asset) {
              setAddNew('');
              setActive(false);
              setTimeout(() => {
                navigate(
                  `/work-order/?from=${
                    location.pathname
                  }&type=new-inspection&inspectionType=${item}&${assetKind.toLowerCase()}Id=${idOf}`
                );
              }, 0);
            } else {
              setInspectionTypeId(item);
              setInspectionFormName(formName);
              setStep((prev) => prev + 1);
            }
          }}
          searchClassName={styles.searchClassName}
        />
      )
    }
  ];

  const steps = asset
    ? stepForm
    : [
        {
          title: 'For what kind of asset?',
          content: (
            <AssetsKindList
              list={assetsList}
              onSelect={(item) => {
                setAssetKind(item);
                setStep((prev) => prev + 1);
              }}
            />
          )
        },
        ...stepForm,
        {
          title: `Find and select the ${assetKind.toLowerCase()}.`,
          content: (
            <SearchTabList
              equipmentCategories={equipmentCategories}
              label={assetKind}
              onSelect={(id) => {
                setAddNew('');
                setActive(false);
                setTimeout(() => {
                  navigate(
                    `/work-order/?from=${
                      location.pathname
                    }&type=new-inspection&inspectionType=${inspectionTypeId}&${assetKind.toLowerCase()}Id=${id}`
                  );
                }, 0);
              }}
            />
          )
        },
        {
          title: 'New Inspection Added Successfully.',
          content: ''
        }
      ];

  const onClickStep = (step) => {
    if (step === 0) setAssetKind('');
    if (step <= 1) setInspectionTypeId('');
    if (step <= 1) setInspectionFormName('');
    setStep(step);
  };

  return (
    <div>
      <WizardSteps
        current={step}
        setStep={onClickStep}
        total={steps.length - 1}
        title={steps[step].title}
        content={steps[step].content}
        breadcrumbs={
          asset
            ? [inspectionFormName?.toString() || 'Inspection type']
            : [
                assetKind || 'Asset type',
                inspectionFormName?.toString() || 'Inspection type',
                equipment?.name || `Select ${assetKind}`,
                'Added'
              ]
        }
      />
    </div>
  );
};

const assetsList = [
  { icon: <Icon name={'Site'} />, title: 'Site' },
  { icon: <Icon name={'equipment-white'} />, title: 'Equipment' },
  { icon: <Icon name={'pipeline-white'} />, title: 'Pipeline' }
];

export default AddNewInspection;
