/* eslint-disable no-unused-vars */

import React, { memo } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ id = 'portal', children }) => {
  return ReactDOM.createPortal(children, document.getElementById(id));
};

export default memo(Portal);
