/* eslint-disable no-unused-vars */

import React, { memo } from 'react';

import Select from '@components/select-box-component';
import Textarea from '@components/textarea';

import style from './yes-no.module.scss';

const YesNo = ({ label, control, errors, disabled, register, name, showComment, dot = false }) => {
  return (
    <div className={style.YesNoContainer}>
      <Select
        dot={dot}
        label={label}
        options={[
          { label: 'Yes', value: 'Yes' },
          { label: 'No', value: 'No' }
        ]}
        control={control}
        name={`${name}.value`}
        error={errors?.value?.message || ''}
        isClearable
        disabled={disabled}
      />

      {showComment && (
        <div className={style.comment}>
          <Textarea
            dot={true}
            label="Comment"
            inputClass="input-field input-regular"
            labelColor={style.labelColor}
            register={register}
            name={`${name}.comment`}
            error={errors?.comment?.message || ''}
          />
        </div>
      )}
    </div>
  );
};

export default memo(YesNo);
