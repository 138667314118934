const structureAutoPopulate = ({ firstName, lastName, structure, parent = false, intervalId }) => {
  return {
    ...(!parent && {
      User: {
        //SourceProperty,
        InspectorName: `${firstName} ${lastName}`
      }
    }),
    InspectionSchedules: {
      IntervalId: intervalId
    },
    Incident: {
      InitiatorName: `${firstName} ${lastName}`
    },
    Structure: {
      Name: structure?.name || null,
      StructureNumber: structure?.structureNumber || null,
      Status: structure?.status || null,
      StructureType: structure?.structureType || null,
      StructureID: structure?.structureIdentifier || null
    }
  };
};

export default structureAutoPopulate;
