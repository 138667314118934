export { default } from './Help';

export const HELPS = [
  'Contact support',
  'What can I see?',
  'Tips and guides',
  'About Oplii',
  'Reset app data'
];

export const TIPS_AND_GUIDES = [
  'What’s Oplii Mobile?',
  'Refreshing data',
  'Syncing tasks',
  'Completing a task',
  'Creating a new task',
  'Finding a task or asset',
  'Changing display settings',
  'Bookmarks'
];
