/* eslint-disable no-unused-vars */
import React from 'react';

import Modal from '../..';

import style from './style.module.scss';

const CancelAndRetryModal = ({ open, setOpen, logoutHandler = () => {} }) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={'cancel-retry'}
      statusText={'Feeling stuck?'}
      modalTextUpper={
        'It’s not your fault. If things are taking too long, you can log out and try again.'
      }
      modalTextLower={
        'If the issue persists, contact your system administrator or check your settings in Oplii desktop to see if you’re trying to cache too many assets in your area selection.'
      }
      textBtn={'No Thanks'}
    >
      <div>
        <div className={style.modalBtn} style={{ marginTop: '32px' }}>
          <button className="btn-fill button-large" onClick={logoutHandler}>
            Log out and retry
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelAndRetryModal;
