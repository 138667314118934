/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';

import Accordion from '@components/accordion';
import FilterChips from '@components/filters/filter-chips';
import PicklistPills from '@components/picklist-pills';
import CustomSelect from '@components/select-box-component';
import DatePicker from '@components/date-picker-component';
import DateRange from '@components/date-range';

import style from './due-date.module.scss';

const DueDateFilter = ({
  isActive = true,
  tagName = 'dueDate',
  header,
  defaultValue,
  value,
  onChange,
  selected,
  onSelect,
  options,
  placeHolder = '',
  multi = false
}) => {
  const [open, setOpen] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  React.useEffect(() => setOpen(isActive), [isActive]);

  const { control, watch, setValue, reset } = useForm({
    defaultValues: {
      name: defaultValue
    }
  });

  useEffect(() => {
    if (!_.isEmpty(defaultValue)) reset({ name: defaultValue });
  }, [defaultValue]);

  const customFunc = (selectedMenuValue) => {
    const selectedValue = selectedMenuValue && selectedMenuValue.value;
    if (selectedValue) {
      onSelect(selectedValue);
    }
  };

  const tags = tagName === 'dueDate' ? dueDate : [];

  const selectedValue =
    value && Array.isArray(value)
      ? value.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {})
      : {};

  const handleChange1 = (tag, checked) => {
    if (!multi) onChange(checked ? [tag] : []);
    else {
      if (checked) onChange([...value, tag]);
      else onChange(value.filter((el) => tag !== el));
    }
  };

  const deleteSelectedChip = () => onChange('');

  const onChangeDateRange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end)
      onChange([
        start && moment(start).format('YYYY-MM-DD'),
        end && moment(end).format('YYYY-MM-DD')
      ]);
    else if (!start && !end) onChange(null);
  };

  useEffect(() => {
    if (selected === 'next') setValue('name', { value: 'next', label: 'In the next' });
    if (selected === 'past') setValue('name', { value: 'past', label: 'In the past' });
    if (selected === 'on') setValue('name', { value: 'on', label: 'On' });
    if (selected === 'range') setValue('name', { value: 'range', label: 'Range' });
    if (selected === 'last') setValue('name', { value: 'last', label: 'Last sync' });
  }, [selected]);
  useEffect(() => {
    if (selected === 'on') setValue('on', value);
    if (selected === 'range' && !_.isEmpty(value)) {
      value[0] && setStartDate(new Date(moment(value[0])));
      value[1] && setEndDate(new Date(moment(value[1])));
    }

    if (!value || !value?.[0]) {
      setStartDate(null);
      setEndDate(null);
    }
  }, [value]);

  return (
    <div>
      <Accordion
        open={open}
        setOpen={setOpen}
        title={header}
        isSelected={!open}
        selectedChildren={
          !open &&
          !_.isEmpty(value) && (
            <SelectedDueDateFilter
              selectedChip={selected}
              tag={value}
              deleteSelectedChip={deleteSelectedChip}
            />
          )
        }
      >
        <div style={{ marginBottom: '16px' }}>
          <CustomSelect
            options={options}
            placeHolder={placeHolder || 'Select an option'}
            control={control}
            name="name"
            defaultValue={defaultValue}
            customFunc={customFunc}
          />
        </div>

        {selected === 'on' ? (
          <DatePicker
            value={value}
            placeholder="Select Date"
            name="on"
            control={control}
            isClearable
            customFunc={(value) => {
              onChange(value ? moment(value).format('YYYY-MM-DD') : null);
            }}
          />
        ) : selected === 'range' ? (
          <DateRange
            handleChange={onChangeDateRange}
            startDate={startDate}
            endDate={endDate}
            name="dateRange"
            control={control}
            isClearable
          />
        ) : (
          (selected === 'next' || selected === 'past') && (
            <div className={style.selectFilterDiv}>
              {tags?.map((ele, index) => (
                <PicklistPills
                  key={ele.key || index}
                  text={ele}
                  selected={selectedValue[ele]}
                  handleChange={(checked) => {
                    handleChange1(ele, checked);
                  }}
                />
              ))}
            </div>
          )
        )}
      </Accordion>
    </div>
  );
};

export default DueDateFilter;

export const SelectedDueDateFilter = ({ selectedChip, tag, deleteSelectedChip }) => {
  return (
    <>
      {(selectedChip === 'next' || selectedChip === 'past') && (
        <div className={style.selectFilterDiv}>
          <FilterChips
            chipText={`In the ${selectedChip}: ${tag && tag[0]}`}
            deleteChip={deleteSelectedChip}
          />
        </div>
      )}
      {selectedChip === 'on' && (
        <FilterChips
          chipText={'On: ' + moment(tag).format('MMM DD, YY')}
          deleteChip={deleteSelectedChip}
        />
      )}

      {selectedChip === 'range' && (
        <FilterChips
          chipText={
            'Range: ' +
            moment(tag && tag[0]).format('MMM DD, YY') +
            ' to ' +
            moment(tag && tag[1]).format('MMM DD, YY')
          }
          deleteChip={deleteSelectedChip}
        />
      )}
    </>
  );
};

const dueDate = [
  'Day',
  'Week (7 days)',
  'Month (30 days)',
  'Quarter (90 days)',
  'Year (365 days)',
  'Unlimited'
];
