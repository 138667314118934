import React, { useEffect } from 'react';

import './Help.scss';

const AboutOplii = () => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div>
      <div className="whats-oplii-mobile" style={{ maxWidth: '768px', width: '100%' }}>
        <div className="whats-oplii-mobile--title">About Oplii</div>
        <div className="whats-oplii-mobile--description">
          Oplii is an operations management platform with a product line that includes tools for
          managing assets, maintenance programs, MOC, asset integrity, HSE reporting and more. It’s
          an enterprise system that connects data—and teams—to reduce costs, keep track of
          regulatory requirements, become more efficient, get more value from their data, and get
          work done.
        </div>
        <div className="whats-oplii-mobile--description">
          For more information about Oplii’s products, visit our{' '}
          <a href="https://www.oplii.com/" target="_blank" rel="noreferrer">
            website
          </a>
          .
        </div>
        <div className="whats-oplii-mobile--tidbits" style={{ paddingTop: '16px' }}>
          <div className="whats-oplii-mobile--title">Oplii Mobile</div>
          <div className="whats-oplii-mobile--description">
            Oplii Mobile helps dispersed workforces stay connected.
          </div>
          <div className="whats-oplii-mobile--description">
            Users create and complete work orders, inspections and checks, access and improve asset
            data, and more—all from mobile devices—with or without internet access.
          </div>
          <ul className="leastClass">
            Last but not least:
            <li>
              {' '}
              Big thanks to our client partners and users who shared their ideas and feedback at
              every step—from planning to roll out. Keep those good ideas coming!{' '}
            </li>
            <li>
              Shoutouts to Daresay’s “Daresay Mobile UI Kit” for serving as a jumping-off point for
              the app’s user interface design and Storyset for the fantastic free images on the Home
              page.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutOplii;
