const checkEveryFieldIsSynced = ({ syncDone, pendingOrderPhotosDocumentsLength }) => {
  const keys = [
    'workRequest',
    'workOrder',
    'inspection',
    'hseEvents',
    'checks',
    'photos',
    'documents',
    'failedDocumentCount',
    'failedImageCount'
  ];
  for (let key of keys) {
    if (pendingOrderPhotosDocumentsLength[key] !== syncDone[key]) {
      return false;
    }
  }
  return true;
};

export { checkEveryFieldIsSynced };
