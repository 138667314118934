/* eslint-disable no-unused-vars */

import _ from 'lodash';
import moment from 'moment';
import { store } from 'redux/store';

const checkTokenIsGoingToExpire = () => {
  const { auth } = store.getState();
  const { tokenExpiry } = auth;

  let currentTime = moment(
    new Date().toLocaleString('en-US', { timeZone: 'America/Denver' })
  ).format('YYYY-MM-DD HH:mm:ss a');

  let expiryTime = moment(
    new Date(tokenExpiry).toLocaleString('en-US', { timeZone: 'America/Denver' })
  ).format('YYYY-MM-DD HH:mm:ss a');

  currentTime = moment(currentTime, 'YYYY-MM-DD hh:mm:ss a');
  expiryTime = moment(expiryTime, 'YYYY-MM-DD hh:mm:ss a');

  const duration = moment.duration(expiryTime.diff(currentTime));

  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  if (hours > 0 || minutes > 5) return false;
  else return true;
};

export default checkTokenIsGoingToExpire;
