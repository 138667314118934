/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux';
import React, { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@components/button';
import Icon from '@components/icon-component';
import YourAttachment from '@components/work-order/general/components/your-attachments';
import WorkLog from '@components/work-order/worklog';
import DeleteModal from '@components/modal/variants/delete-modal';
import InputField from '@components/input-field';
import Textarea from '@components/textarea';
import Select from '@components/select-box-component';
import Datepicker from '@components/date-picker-component';
import Portal from '@components/portal';
import { deleteNewTasksAssetFromIndexDB } from '@services/assets.service';

import style from './add-work-order-modal.module.scss';

const WorkRequestModal = ({
  open,
  title,
  closed,
  onSubmitData,
  selectedWorkOrder,
  updateId = null,
  deleteWorkOrderEvent,
  workRequest,
  workOrder
}) => {
  const {
    register,
    control,
    formState: { errors },
    watch,
    getValues,
    handleSubmit,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().required('Required'),
        priority: yup.string().required('Required'),
        type: yup.string().required('Required'),
        trade: workOrder ? yup.string().required('Required') : yup.string(),
        requestDueDate: workOrder ? yup.date().required('Required') : yup.date()?.nullable(),
        description: yup.string().required('Required'),
        ...(workOrder && { administrator: yup.string() }),
        ...(workOrder && { responsibleUsers: yup.string() }),
        photo: yup.array().of(
          yup.object().shape({
            category: yup.string().nullable(),
            caption: yup.string().nullable(),
            image: yup.mixed().required('A file is required')
          })
        ),
        document: yup.array().of(
          yup.object().shape({
            category: '',
            files: yup.mixed()
          })
        )
      })
    ),
    defaultValues: {
      ...selectedWorkOrder
    }
  });

  const [activeBtn, setActiveBtn] = useState('Todo');
  const [deleteWorkOrder, setDeleteWorkOrder] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const {
    workOrderAdministratorGroups,
    workOrderAdministrators,
    workOrderUsers,
    workOrderUsersGroups
  } = useSelector((state) => state.tasks);

  const { tradeData, workOrderPriorityData, workOrderTypeData } = useSelector(
    (state) => state.picklists
  );

  const administratorOptions = [
    ...([...workOrderAdministratorGroups]?.sort(sortAlphabetically) || []),
    ...([...workOrderAdministrators]?.sort(sortAlphabetically) || [])
  ];
  const responsibleUsersOptions = [
    ...([...workOrderUsersGroups]?.sort(sortAlphabetically) || []),
    ...([...workOrderUsers]?.sort(sortAlphabetically) || [])
  ];

  const closedModal = async () => {
    if (_.isEmpty(selectedWorkOrder)) {
      const photos = watch('photo') || [];
      const documents = watch('document') || [];

      await deleteNewTasksAssetFromIndexDB({ photos, documents, deleteAll: true });
    } else {
      const photoIdsInHookFormNow = watch('photo') || [];
      const documentIdsInHookFormNow = watch('document') || [];

      const currentTaskPhotos = selectedWorkOrder?.photo || [];
      const currentTaskDocuments = selectedWorkOrder?.document || [];

      const removeAblePhotoIds = photoIdsInHookFormNow?.filter(
        (item) => !currentTaskPhotos.find((taskPhotoId) => taskPhotoId.id === item.id)
      );

      const removeAbleDocumentIds = documentIdsInHookFormNow?.filter(
        (item) => !currentTaskDocuments.find((taskDocumentId) => taskDocumentId.id === item.id)
      );

      await deleteNewTasksAssetFromIndexDB({
        photos: removeAblePhotoIds,
        documents: removeAbleDocumentIds,
        deleteAll: true
      });
    }
    reset();
    closed(false);
  };

  useEffect(() => {
    if (open) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [open]);

  const onSubmitHandler = (data) => {
    onSubmitData({ ...data, isDone: workRequest || activeBtn === 'Todo' ? false : true }, updateId);
  };

  const onSubmitAddWorkOrder = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmitHandler)(event);
  };

  const updateTaskAsset = ({ photoId, documentId }) => {
    const taskPhotos = selectedWorkOrder?.photo || [];
    const taskDocuments = selectedWorkOrder?.document || [];

    let photo =
      photoId && taskPhotos?.length > 0 ? taskPhotos.filter((x) => x?.id !== photoId) : taskPhotos;

    let document =
      documentId && taskDocuments?.length > 0
        ? taskDocuments.filter((x) => x?.id !== documentId)
        : taskDocuments;

    onSubmitData(
      {
        ...selectedWorkOrder,
        document,
        photo,
        isDone: workRequest || activeBtn === 'Todo' ? false : true
      },
      updateId,
      true
    );
  };

  const handleDeleteEvent = async () => {
    setLoadingDelete(true);
    reset();
    await deleteWorkOrderEvent(updateId);
    setDeleteWorkOrder(false);
    closedModal();
    setLoadingDelete(false);
  };

  return (
    <Portal>
      <form onSubmit={onSubmitAddWorkOrder} id="addWorkOrderForm">
        <div className={open ? style.openModal : style.closeModal}>
          <div className={style.modalHeight}>
            <div className={`${style.header} px-4 md:px-8 py-7`}>
              <p className={style.cancelClass} onClick={() => closedModal()}>
                Cancel
              </p>
              <h1 className={`${style.addClass} h1-semibold`}>{title}</h1>
              <div className={style.invisibleDiv}>Cancel</div>
            </div>
            <div className={style.content_body} id="addWorkOrderFormContainer">
              <div className={style.headerTitle}>
                <p>General</p>
              </div>
              <div className={style.formFields}>
                {workOrder && (
                  <div className={style.selectionOrder}>
                    <p>Log as</p>
                    <div className={style.todoAndDoneBtn}>
                      <button
                        className={`${style.todo} ${
                          activeBtn === 'Todo'
                            ? style.isActiveWorkOrderBtn
                            : style.isInActiveWorkOrderBtn
                        } `}
                        onClick={() => setActiveBtn('Todo')}
                        type="button"
                      >
                        To do
                      </button>
                      <button
                        className={`${style.done} ${
                          activeBtn === 'Synced'
                            ? style.isActiveWorkOrderBtn
                            : style.isInActiveWorkOrderBtn
                        } `}
                        onClick={() => setActiveBtn('Synced')}
                        type="button"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                )}
                <InputField
                  label="Title"
                  dot={true}
                  type="text"
                  inputClass="input-field input-regular"
                  labelColor={style.labelColor}
                  register={register}
                  name="title"
                  error={errors?.title?.message || ''}
                />

                <div className={style.grid2}>
                  <Select
                    dot={true}
                    label="Priority"
                    options={workOrderPriorityData?.map((x) => {
                      return { label: x?.value, value: x?.key };
                    })}
                    labelColor={style.labelColor}
                    control={control}
                    name="priority"
                    error={errors?.priority?.message || ''}
                    register={register}
                    isClearable
                  />

                  <Select
                    dot={true}
                    label="Type"
                    options={workOrderTypeData?.map((x) => {
                      return { label: x?.value, value: x?.key };
                    })}
                    name="type"
                    error={errors?.type?.message || ''}
                    control={control}
                    register={register}
                    isClearable
                  />
                </div>

                <div className={style.grid2}>
                  <Select
                    dot={workOrder}
                    label="Trade"
                    options={tradeData?.map((x) => {
                      return { label: x?.value, value: x?.key };
                    })}
                    labelColor={style.labelColor}
                    name="trade"
                    error={errors?.trade?.message || ''}
                    control={control}
                    register={register}
                    isClearable
                  />

                  <Datepicker
                    backClass={style.datePicker2}
                    id="1"
                    label={workOrder ? 'Due date' : workRequest ? 'REQUESTED DUE DATE' : ''}
                    inputClass="input-regular op-datepicker"
                    control={control}
                    name={'requestDueDate'}
                    error={(errors?.requestDueDate?.message && 'Required') || ''}
                    register={register}
                    isClearable
                    readOnly={true}
                    dot={workOrder}
                  />
                </div>

                <div style={{ marginTop: '16px' }}>
                  <Textarea
                    dot={true}
                    label="description"
                    inputClass="input-field input-regular"
                    labelColor={style.labelColor}
                    register={register}
                    name="description"
                    error={errors?.description?.message || ''}
                  />
                </div>
              </div>
              {workOrder && (
                <>
                  <div className={style.nameDivInner} style={{ backgroundColor: '#E3E7EB' }}>
                    <p style={{ fontSize: '18px' }}>Assignment</p>
                  </div>
                  <div className={style.formFields}>
                    <div
                      className={style.editAssignment}
                      style={{
                        backgroundColor: '#FFFFFF',
                        marginTop: '-16px'
                      }}
                    >
                      <div className={style.grid2}>
                        <div className="op-input-field-wrap">
                          <Select
                            label="Administrator(s)"
                            options={administratorOptions?.map((x) => {
                              return {
                                label: x?.name ? x?.name : x?.firstName + ' ' + x.lastName || '',
                                value: x?.id || ''
                              };
                            })}
                            labelColor={style.labelColor}
                            control={control}
                            name="administrator"
                            error={errors?.administrator?.message || ''}
                            isClearable
                          />
                        </div>
                        <div className="op-input-field-wrap">
                          <Select
                            label="Responsible user(s)"
                            options={responsibleUsersOptions?.map((x) => {
                              return {
                                label: x?.name ? x?.name : x?.firstName + ' ' + x.lastName || '',
                                value: x?.id || ''
                              };
                            })}
                            labelColor={style.labelColor}
                            name="responsibleUsers"
                            error={errors?.responsibleUsers?.message || ''}
                            control={control}
                            isClearable
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {activeBtn === 'Synced' && (
                    <section>
                      <WorkLog
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                        watch={watch}
                        getValues={getValues}
                      />
                    </section>
                  )}
                </>
              )}
              <YourAttachment
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
                watch={watch}
                getValues={getValues}
                updateTaskAsset={selectedWorkOrder ? updateTaskAsset : null}
              />
            </div>
          </div>
          <div className={style.footerBtn}>
            {updateId && (
              <Icon
                name={'Delete button'}
                alt=""
                style={{ marginRight: '48px' }}
                onClick={() => {
                  setDeleteWorkOrder(true);
                }}
              />
            )}

            <Button
              btnClass="btn-fill button-large"
              type="submit"
              form="addWorkOrderForm"
              text="SAVE"
            />
          </div>
        </div>
      </form>

      <DeleteModal
        open={deleteWorkOrder}
        handleDelete={handleDeleteEvent}
        setOpen={loadingDelete ? () => {} : setDeleteWorkOrder}
        confirmDeletion
        recordType="work order"
        disabledDeleteButton={loadingDelete}
      />
    </Portal>
  );
};

export default memo(WorkRequestModal);

const sortAlphabetically = (a, b) => {
  const aName = a?.name ? a?.name : a?.firstName + ' ' + a.lastName || '';
  const bName = b?.name ? b?.name : b?.firstName + ' ' + b.lastName || '';
  const nameA = aName.toUpperCase(); // Convert name to uppercase
  const nameB = bName.toUpperCase(); // Convert name to uppercase

  if (nameA < nameB) {
    return -1; // a should come before b in the sorted order
  }

  if (nameA > nameB) {
    return 1; // a should come after b in the sorted order
  }

  return 0; // Names are equal, no change in the order
};
