import React from 'react';

import SettingsValueCard from './settings-value-card';
import DragDropFields from './drag-frop-fields';

const SitesDisplaySettings = () => {
  return (
    <div className="settings--values">
      <SettingsValueCard
        title={'Site cards'}
        description={'This is how I want site data to appear on listings and task cards:'}
      >
        <DragDropFields text="site" />
      </SettingsValueCard>
    </div>
  );
};

export default SitesDisplaySettings;
