// import { store } from 'redux/store';

import { getDataPoints } from '@pages/settings/helper';

export const searchEquipmentData = ({ equipment, inputValue, store }) => {
  const { settings, picklists, cache } = store;
  const { picklistsMap, areaHierarchyTreeDataMap = {} } = picklists;
  const { sitesMap = {}, sites: cacheSites = [] } = cache;
  const { siteDisplayFields } = settings.settings;

  const { name, siteId, equipmentType: type, equipmentCategory: category, dataPoints } = equipment;
  const site = cacheSites[sitesMap[siteId]] || {};

  const categoryName = picklistsMap[category]?.value;
  const typeName = picklistsMap[type]?.value;
  const title = `${categoryName}-${typeName}`;

  const siteTitle = site?.parent
    ? 'View all work at'
    : areaHierarchyTreeDataMap[site?.areaHierarchy || '']?.title || '';

  if (inputValue) {
    const dataPointsObject = dataPoints.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    const dataPointsForSite = getDataPoints(
      { ...site, type: site.siteType },
      siteDisplayFields,
      'site',
      picklistsMap
    );
    const dataPointsObjectFOrSite = dataPointsForSite.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
      {}
    );

    const filterObject = {
      name,
      title,
      siteTitle,
      siteName: site?.name || '',
      ...dataPointsObject,
      ...dataPointsObjectFOrSite
    };

    if (
      !Object.values(filterObject).some((value) => {
        return String(value).toLowerCase().includes(inputValue.toLowerCase());
      })
    )
      return false;
  }

  return true;
};
