/* eslint-disable no-unused-vars */

import _ from 'lodash';
import { useState } from 'react';

import DataSnippets from '@components/data-snippet';
import TaskTally from '@components/task-tally';
import Icon from '@components/icon-component';
import InfoPopup from '@components/info-popup/info-popup.component';
import SiteInnerCard from '@components/equipment-card/site-inner-card';
import OpenLink from '@components/task-card-new/card-asset/open-link';

import { getAppDispatch } from '@utilities/dispatch.util';
import { formWrapperActions } from '@redux/slices/form-wrapper';
import { useOpenLink } from '@hooks/useOpenLink';

import style from './site-card.module.scss';

const StructureCard = (props) => {
  const [desktopLink, setDesktopLink] = useState(null);
  const [openLink, setOpenLink] = useOpenLink({
    taskId: props?.structureId || '',
    assetId: props?.structureId || ''
  });

  const dispatch = getAppDispatch?.();

  const {
    onClickLink,
    to,
    title,
    subtitle,
    dataPoints,
    highlight,
    highlightName,
    equipments,
    open,
    setOpen,
    allTasksForSite,
    allDoneTasks,
    isOverDue,
    status,
    sites,
    moreInfo,
    arrayText,
    showChevronIcon,
    noIcon = false,
    appliesTo = false,
    structureDetails = false,
    structureListing = false,
    link,
    noLink,
    addNewWizard,
    structureId
  } = props;
  let [highlightNames, highlightType] = !_.isEmpty(highlight) ? highlight.split(' ') : [];
  highlightNames = highlightName ? highlight : highlightNames;

  if (!highlightType) highlightType = highlightNames;

  const onClickOpliiDesktopLinkEvent = (desktopLink) => {
    setDesktopLink({ linkValue: desktopLink });
    setOpenLink((prev) => !prev);

    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId: structureId,
        assetId: structureId
      })
    );
  };

  const equipmentInfo = () => {
    return (
      <div className={style.cardInner}>
        <div className={style.cardContent}>
          <div
            style={{
              position: 'relative'
            }}
          >
            <DataSnippets
              title={title || ''}
              link={link}
              subtitle={subtitle || ''}
              dataPoints={dataPoints}
              iconName={noIcon || structureDetails || appliesTo ? '' : 'home-icon'}
              highlightNames={highlightNames}
              highlightType={highlightType}
              className={`${style.marginClass} ${structureListing && style.paddingOnListingPage} ${
                style['mb-4']
              }`}
              onClickOpliiDesktopLinkEvent={onClickOpliiDesktopLinkEvent}
            />
            <OpenLink
              openLink={openLink}
              desktopLink={desktopLink}
              setOpenLink={setOpenLink}
              setDesktopLink={setDesktopLink}
              selectedLinkTypeIcon={'home-icon'}
              selectedLinkTypeName={'structure'}
            />
          </div>

          <div
            className={`${style.chipsDiv} ${_.isEmpty(sites) && !appliesTo && style['mb-0']} `}
            style={{
              padding: structureDetails || noIcon || appliesTo ? '0px' : '0px 20px 0px 60px'
            }}
          >
            {allTasksForSite?.length > 0 && (
              <TaskTally
                status={
                  allDoneTasks.length === 0
                    ? 'overdue'
                    : allDoneTasks.length > 0 && allTasksForSite.length > allDoneTasks.length
                    ? 'inProgress'
                    : allDoneTasks.length === allTasksForSite.length
                    ? 'done'
                    : ''
                }
                statusText={`${allDoneTasks.length}/${allTasksForSite.length}`}
                dot={!!isOverDue && 'true'}
              />
            )}

            {status && (
              <>
                <TaskTally statusText={status || ''} />
              </>
            )}
            {equipments?.length > 0 && (
              <>
                <TaskTally
                  width={'14px'}
                  height={'16px'}
                  iconName={'equipment'}
                  statusText={equipments.length || ''}
                />
              </>
            )}
          </div>

          {!_.isEmpty(sites) && (
            <SiteInnerCard
              sites={{
                ...sites,
                link: !addNewWizard ? (!noLink ? `/#/site-details/${sites?.siteId}` : '') : null,
                highlight,
                highlightName,
                hideLocation: structureDetails || appliesTo,
                structureListing
              }}
              appliesTo={appliesTo}
              taskId={structureId}
              assetId={sites?.siteId}
            />
          )}
        </div>

        {showChevronIcon && !appliesTo ? (
          <div>
            <div>
              <Icon name={'chevron-right'} width="10px" />
            </div>
          </div>
        ) : !appliesTo ? (
          <div>
            {!moreInfo && (
              <div className="rightDiv">
                <Icon
                  name={'Listing detail menu'}
                  className={style.siteIcon}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                {open && (
                  <div style={{ position: 'absolute', top: '84px', right: '0px' }}>
                    <InfoPopup open={open} setOpen={setOpen} arrayText={arrayText} />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  return structureDetails || appliesTo ? (
    <div
      className={`${style.cardWrapperNoHover} ${props?.className}`}
      style={{
        padding: structureDetails || appliesTo ? '16px' : '',
        border: '0.5px solid #cbd2d9'
      }}
    >
      {equipmentInfo()}
    </div>
  ) : (
    <div
      className={`${style.cardWrapper}`}
      style={{
        cursor: 'pointer'
      }}
      onClick={onClickLink}
    >
      {equipmentInfo()}
    </div>
  );
};

export default StructureCard;
