import React, { memo } from 'react';

import Icon from '@components/icon-component';

import style from './style.module.scss';

const StartAndQuickCompleteButton = ({
  checkedLength,
  startBtnEvent,
  quickCheckedLength,
  quickBtnEvent
}) => {
  const quickCheckedLengthCondition =
    quickCheckedLength > 0 && checkedLength > 0 && checkedLength === quickCheckedLength;
  return (
    <>
      {checkedLength > 0 && (
        <button
          className={style.startBtn}
          onClick={startBtnEvent}
          style={{
            bottom: quickCheckedLengthCondition > 0 ? '102px' : '32px'
          }}
        >
          <Icon name={'forward-icon'} /> Start ({checkedLength})
        </button>
      )}
      {quickCheckedLengthCondition && (
        <button className={style.quickComplete} onClick={quickBtnEvent}>
          <Icon name={'forward-icon'} /> Quick Complete ({quickCheckedLength})
        </button>
      )}
    </>
  );
};
export default memo(StartAndQuickCompleteButton);
