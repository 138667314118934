/* eslint-disable no-unused-vars */

// import { store } from '@redux/store';
import moment from 'moment';
import _ from 'lodash';

import EquipmentSelector from './equipment.selector';

import TasksSelector from './tasks/tasks.selector';
import { getTaskAssignedUserTags } from './tasks/helper';
import { getDataPoints, getMoreInfoDataPoints } from '../pages/settings/helper';
import CheckSelector from './checks/check.selector';

const StructureSelector = {
  getStructuresWithWO: async ({ id = '', ids = [], formWrapper = false, store }) => {
    const { tasks, auth, jurisdictionNumbers, settings, picklists, structure } = store;
    const userId = auth.userId || '';
    const [allWOTasks, structureEquipmentMap] = !formWrapper
      ? await Promise.all([
          await StructureSelector.getAllWorkOrdersAndInspections({ store, userId }),
          await StructureSelector.getStructureEquipmentMap({ store, userId })
        ])
      : [[], []];

    return new Promise((resolve) => {
      const { workOrderUsersMap, workOrderUsersGroupsMap } = tasks;

      const { structureMobile } = structure;
      const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;

      const structureDisplayFields = settings.settings?.['structureDisplayFields'] || '';

      const { picklistsMap = {} } = picklists;

      const responsibleUsersMap = {
        ...(workOrderUsersMap || {}),
        ...(workOrderUsersGroupsMap || {})
      };

      const structures = id
        ? structureMobile?.filter((x) => x.structureId == id)
        : ids?.length
        ? structureMobile?.filter((x) => [...ids]?.includes(x?.structureId))
        : structureMobile;
      let prepareStructures = [];

      structures.map((structure) => {
        const equipments = structureEquipmentMap[structure.structureId] || [];
        let allTasksForStructure = allWOTasks.filter(
          (t) =>
            t.structureId === structure.structureId ||
            t?.relatedAssetId === structure?.structureId ||
            t?.primaryAssetId === structure?.structureId ||
            t?.structures?.includes(structure?.structureId) ||
            equipments?.includes(t?.equipmentId) ||
            equipments?.includes(t?.relatedAssetId) ||
            equipments?.includes(t?.primaryAssetId) ||
            t?.equipments?.some((y) => equipments?.includes(y))
        );

        allTasksForStructure = allTasksForStructure.map((tas) => {
          const { responsibleUsers = [], responsibleUserGroups = [] } = tas;
          const customAssignedTaskValue = getTaskAssignedUserTags({
            responsibleUsers,
            responsibleUserGroups,
            workOrderUsersGroupsMap,
            userId,
            responsibleUsersMap
          });
          return {
            customAssignedTaskValue,
            ...tas
          };
        });

        const allDoneTasks = allTasksForStructure.filter((task) => task?.status === 'Synced');
        const allotDoneTasks = allTasksForStructure.filter((task) => task?.status !== 'Synced');

        const today = moment().format('YYYY-MM-DD');
        const isOverDue = allotDoneTasks.find((task) =>
          task.dueDate ? moment(task.dueDate).isBefore(today) : false
        );

        const { jurisdictionNumber } = jurisdictionNumbersMap[structure?.structure] || {};

        const dataPoints = getDataPoints(
          { ...structure, subtype: structure?.structureType, jurisdictionNumber },
          structureDisplayFields,
          'structure',
          picklistsMap
        );

        prepareStructures.push({
          ...structure,
          status: !formWrapper ? structure?.status : null,
          equipments,
          allDoneTasks,
          isOverDue,
          allTasksForStructure,
          allotDoneTasks,
          dataPoints,
          soonestDueDate: _.min(
            allotDoneTasks.map((t) => t?.due || t?.dueDate).map((date) => new Date(date))
          )
        });
      });
      resolve(prepareStructures);
    });
  },

  getStructureEquipmentMap: async ({ structureId = null, store, userId }) => {
    const equipments = await EquipmentSelector.getEquipmentsWithAllTasks({
      structureId,
      store,
      userId
    });

    return equipments.reduce((acc, { structureId, equipmentId }) => {
      if (structureId) {
        if (!acc[structureId]) {
          acc[structureId] = [equipmentId];
        } else {
          acc[structureId].push(equipmentId);
        }
      }
      return acc;
    }, {});
  },

  getStructureWithEquipment: async ({ structureId = '', store, userId }) => {
    const {
      cache,
      picklists,
      jurisdictionNumbers,
      settings,
      structure,
      tasks: storeTasks,
      checks
    } = store;
    const {
      equipmentsMap = {},
      equipmentsLoading: sitesLoading,
      equipments: cacheEquipments = []
    } = cache;
    let cacheTasksLists = storeTasks?.tasksList;
    let checksList = checks?.checksList;

    const { structure: cacheStructures, structuresMap } = structure;

    const structureDisplayFields = settings?.settings?.['structureDisplayFields'] || '';
    const equipmentDisplayFields = settings?.settings?.['equipmentDisplayFields'] || '';
    const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;
    const { picklistsMap = {} } = picklists;

    const [tasks, structureEquipmentMap] = await Promise.all([
      await StructureSelector.getAllWorkOrdersAndInspections({
        structureId,
        cacheEquipments,
        store,
        userId
      }),
      await StructureSelector.getStructureEquipmentMap({ structureId, store, userId })
    ]);

    const equipments =
      (structureEquipmentMap[structureId] || []).filter(
        (id) => cacheEquipments[equipmentsMap[id]]
      ) || [];

    const parentStructure = cacheStructures[structuresMap[structureId]] || {};

    const allDoneTasks = tasks?.filter((task) => task?.status === 'Synced');
    const allNotDoneTasks = tasks?.filter((task) => task?.status !== 'Synced');

    const allWorkOrders = tasks?.filter((x) => x.type === 'Work Order');
    const allDoneWorkOrders = allDoneTasks?.filter((x) => x.type === 'Work Order');
    const allNotDoneWorkOrders = allNotDoneTasks.filter((task) => task?.status !== 'Synced');

    const allChecks = tasks?.filter((x) => x.type === 'Check');
    const allDoneChecks = allDoneTasks?.filter((x) => x.type === 'Check');
    const allNotDoneChecks = allNotDoneTasks.filter((x) => x.type === 'Check');

    const today = moment().format('YYYY-MM-DD');

    const isOverDue = allNotDoneTasks.find((task) =>
      task.dueDate ? moment(task.dueDate).isBefore(today) : false
    );

    const isWorkOrderOverDue = allNotDoneWorkOrders.find((task) =>
      task.dueDate ? moment(task.dueDate).isBefore(today) : false
    );

    const isCheckOverDue = allNotDoneChecks.find((task) =>
      task.nextDue ? moment(task.nextDue).isBefore(today) : false
    );

    const { name, structureType, siteId, link } = parentStructure;
    const status = picklistsMap[parentStructure.status]?.value;
    const type = picklistsMap[structureType]?.value;
    const data = { ...parentStructure, type: structureType };

    const dataPoints = getDataPoints(
      { ...data },
      structureDisplayFields,
      'structure',
      picklistsMap
    );

    const dataPointsMap = dataPoints.reduce((acc, curr) => {
      acc[curr.Id] = curr;
      return acc;
    }, {});
    dataPointsMap['Status'] = { value: status, title: 'Status' };

    const MoreInfoDataPoints = getMoreInfoDataPoints({ ...data }, 'structure', picklistsMap);
    const MoreInfoDataPointsMap = MoreInfoDataPoints.reduce((acc, curr) => {
      acc[curr.Id] = curr;
      return acc;
    }, {});
    MoreInfoDataPointsMap['Status'] = { value: status, title: 'Status' };
    MoreInfoDataPointsMap['Name'] = { value: name, title: 'Name' };
    MoreInfoDataPointsMap['Type'] = { value: type, title: 'Type' };

    const equipmentAllFieldData = equipments?.map((equipment) => {
      const equipmentsTasks = cacheTasksLists?.filter(
        (t) => t?.equipmentId === equipment || t.equipments?.includes(equipment)
      );

      const allDoneTasks = equipmentsTasks?.filter((task) => task?.status === 'Synced');
      const allNotDoneTasks = equipmentsTasks?.filter((task) => task?.status !== 'Synced');
      const today = moment().format('YYYY-MM-DD');
      const isOverDue = allNotDoneTasks?.find((task) =>
        task?.dueDate ? moment(task?.dueDate).isBefore(today) : false
      );

      const equData = cacheEquipments[equipmentsMap[equipment]] || {};
      const { jurisdictionNumber } = jurisdictionNumbersMap[equipment] || {};
      const dataPoints = getDataPoints(
        { ...equData, subtype: equData?.equipmentSubType, jurisdictionNumber },
        equipmentDisplayFields,
        'equipment',
        picklistsMap
      );

      return {
        ...equData,
        allDoneTasks,
        isOverDue,

        allTasksForSite: equipmentsTasks,
        allotDoneTasks: allNotDoneTasks,
        dataPoints
      };
    });

    return {
      siteId,
      name,
      tasks: _.size(_.flattenDeep(tasks)) > 0 ? _.flattenDeep(tasks) : [],
      allDoneTasks: _.size(_.flattenDeep(allDoneTasks)) > 0 ? _.flattenDeep(allDoneTasks) : [],
      isOverDue,
      allWorkOrders: _.size(_.flattenDeep(allWorkOrders)) > 0 ? _.flattenDeep(allWorkOrders) : [],
      allDoneWorkOrders:
        _.size(_.flattenDeep(allDoneWorkOrders)) > 0 ? _.flattenDeep(allDoneWorkOrders) : [],
      allChecks: _.size(_.flattenDeep(allChecks)) > 0 ? _.flattenDeep(allChecks) : [],
      allDoneChecks: _.size(_.flattenDeep(allDoneChecks)) > 0 ? _.flattenDeep(allDoneChecks) : [],
      isWorkOrderOverDue,
      isCheckOverDue,
      status,
      equipments,
      structuresMap,
      equipmentsMap,
      dataPoints,
      type,
      // siteInfo,
      dataPointsMap,
      MoreInfoDataPointsMap,
      equipmentAllFieldData,
      link
    };
  },

  getAllWorkOrdersAndInspections: async ({
    templatesIds = null,
    structureId = null,
    cacheEquipments = [],
    store,
    userId
  }) => {
    const equipmentIds = structureId
      ? cacheEquipments?.filter((x) => x?.structureId === structureId)?.map((x) => x?.equipmentId)
      : [];
    const tasks = await TasksSelector.getTasks({
      structureId,
      equipmentIds,
      store,
      userId
    });

    const checks = await CheckSelector.getAllChecks({ store, structureId });

    const allAssignedWorkOrders = _.isEmpty(templatesIds)
      ? tasks?.filter((task) => !task.newWorkOrder && !task.newWorkRequest)
      : tasks?.filter(
          (task) =>
            !task.newWorkOrder && !task.newWorkRequest && templatesIds?.includes(task?.rtNumber)
        );

    const allAssignedChecks = checks;
    return [...allAssignedWorkOrders, ...allAssignedChecks];
  }
};

export default StructureSelector;
