import React, { memo } from 'react';

const SettingsValueCard = ({ title, description, secondDescription, children }) => {
  return (
    <div className="settings--values--card">
      <div className="settings--values--card--title">{title}</div>
      <div className="settings--values--card--description">
        {description}
        {secondDescription && (
          <span className="settings--values--card--description--bold">{secondDescription}</span>
        )}
      </div>

      <div style={{ width: '100%' }}>{children}</div>
    </div>
  );
};

export default memo(SettingsValueCard);
