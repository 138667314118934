/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import EquipmentCard from '@components/equipment-card';

const EquipmentListingInner = (props) => {
  const navigate = useNavigate();
  const {
    name,
    normal,
    parent,
    siteId,
    onSelect,
    equipmentId,
    hideLocation,
    equipmentType,
    children: childrenList = [],
    highlight = '',
    equipmentSubType,
    equipmentCategory,
    highlightName = true,
    showChevronIcon = true,
    allDoneTasks,
    isOverDue,
    allTasksForSite,
    dataPoints,
    noIcon = false,
    noSite = false,
    moreMenu,
    appliesTo,
    equipmentDetails,
    showWOCount,
    onParentClick = null,
    equipmentListing = false,
    divider = false,
    className,
    from = '',
    link,
    noLink = false,
    addNewWizard = false
  } = props;

  const to = !onSelect
    ? `/${
        parent
          ? 'parent-equipment-details'
          : normal || childrenList.length === 0
          ? 'equipment-details'
          : 'parent-equipment'
      }/${equipmentId}?from=${
        from
          ? from
          : parent
          ? 'parent-equipment'
          : normal || childrenList.length === 0
          ? 'equipment'
          : ''
      }`
    : '';

  const { sitesMap = {}, sites: cacheSites = [] } = useSelector((state) => state.cache);
  const { picklistsMap = {} } = useSelector((state) => state.picklists);
  const type = picklistsMap[equipmentType]?.value;
  const status = picklistsMap[props.status]?.value;
  const category = picklistsMap[equipmentCategory]?.value;
  let [highlightNames, highlightType] = highlight.split(' ');

  if (!highlightType) highlightType = highlightNames;
  if (highlightName) {
    highlightType = '';
    highlightNames = highlight;
  }

  const onClickLink = (e) => {
    navigate(to);

    onParentClick && onParentClick();
    if (!onSelect) return;
    e.preventDefault();
    onSelect?.(equipmentId);
  };
  return (
    <>
      <EquipmentCard
        chips={true}
        onClickLink={onClickLink}
        to={to}
        title={`${category}-${type}`}
        subtitle={name}
        hideLocation={hideLocation}
        dataPoints={dataPoints || []}
        highlight={highlight}
        highlightName={highlightName}
        normal={normal}
        allTasksForSite={allTasksForSite}
        allDoneTasks={allDoneTasks}
        isOverDue={isOverDue}
        status={status}
        childrenList={childrenList}
        sites={noSite ? {} : cacheSites[sitesMap[siteId]] || {}}
        showChevronIcon={showChevronIcon}
        noIcon={noIcon}
        moreMenu={moreMenu}
        appliesTo={appliesTo}
        equipmentDetails={equipmentDetails}
        showWOCount={showWOCount}
        equipmentListing={equipmentListing}
        className={className}
        link={
          !addNewWizard
            ? !noLink
              ? appliesTo
                ? `/#/equipment-details/${equipmentId}`
                : link
              : null
            : null
        }
        addNewWizard={addNewWizard}
        equipmentId={equipmentId}
      />
      {divider && <div style={{ width: '100%', borderTop: ' 0.5px solid rgb(203, 210, 217)' }} />}
    </>
  );
};

EquipmentListingInner.propTypes = {
  distance: PropTypes.string,
  unit: PropTypes.string,
  venue: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.string,
  jurisdictionNumber: PropTypes.string
};

export default React.memo(EquipmentListingInner, _.isEqual);
