/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSelector } from 'react-redux';

import Select from '@components/select-box-component';
import Button from '@components/button';
import Icon from '@components/icon-component';
import DeleteModal from '@components/modal/variants/delete-modal';
import Portal from '@components/portal';
import { useToastContext } from '@context/toaster.context';
import { MAX_DOCUMENT_UPLOAD } from '@utilities/app-urls';

import style from './add-document.module.scss';

const AddDocument = ({
  open,
  closed,
  title,
  onSubmitDocument,
  updateDocument,
  id,
  initialValue,
  selectedDocumentAndFile = {},
  deleteBtnEvent
}) => {
  const { ToasterUtil } = useToastContext();

  const { documentCategoryData } = useSelector((state) => state.picklists);

  const [deleteTask, setDeleteTask] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [editDocument, setEditDocument] = useState(selectedDocumentAndFile?.id ? true : false);

  const [documentLists, setDocumentLists] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      category: '',
      files: []
    }
  });

  useEffect(() => {
    if (!_.isEmpty(selectedDocumentAndFile)) {
      setValue('category', selectedDocumentAndFile?.category || '');
      setValue('files', selectedDocumentAndFile.file ? [selectedDocumentAndFile?.file] : []);
      const filesName = selectedDocumentAndFile?.file?.file?.name || '';
      if (filesName)
        setDocumentLists([
          { fileName: filesName, id: selectedDocumentAndFile.file?.id || uuidv4() }
        ]);
    }
  }, [selectedDocumentAndFile]);

  const updateFiles = (documentId, fileId, selectedFile) => {
    setValue('files', [
      {
        file: selectedFile,
        id: fileId
      }
    ]);
    setDocumentLists([{ fileName: selectedFile?.name || '', id: fileId }]);
  };

  const createFiles = (previousfiles, selectedFile) => {
    const fileId = uuidv4();
    setValue('files', [
      ...previousfiles,
      {
        file: selectedFile,
        id: fileId
      }
    ]);
    setDocumentLists((prev) => [{ fileName: selectedFile?.name || '', id: fileId }, ...prev]);
  };

  const deleteDcouemt = (id) => {
    let filesCopy = watch('files');
    filesCopy = filesCopy?.filter((x) => x.id !== id);
    setValue('files', filesCopy);
    setDocumentLists((prev) => [...prev.filter((x) => x.id !== id)]);
  };

  const onSubmitHandler = (data) => {
    let filesData = [];
    data?.files?.map((file) => {
      filesData.push({
        id: uuidv4(),
        category: data?.category,
        file: file
      });
    });
    onSubmitDocument(filesData, selectedDocumentAndFile);
  };

  const onTest = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmitHandler)(event);
  };

  const goBack = () => {
    setDocumentLists([]);
    reset();
    closed(false);
  };

  const onCaptureDocument = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      if (bytesToMB(selectedFile?.size, MAX_DOCUMENT_UPLOAD)) {
        ToasterUtil.showError(`File limit exceeded (${MAX_DOCUMENT_UPLOAD} mb)`);
        return;
      } else {
        const _files = getValues('files');
        if (!_.isEmpty(selectedDocumentAndFile?.documentId))
          updateFiles(
            selectedDocumentAndFile?.documentId,
            selectedDocumentAndFile?.fileId,
            selectedFile
          );
        else createFiles(_files, selectedFile);
      }
    }
  };

  const handleDeleteEvent = async () => {
    setLoadingDelete(true);
    await deleteBtnEvent(selectedDocumentAndFile?.id, selectedDocumentAndFile?.file?.id);
    setLoadingDelete(false);
    setDeleteTask(false);
    goBack();
  };

  return (
    <>
      <Portal>
        <form onSubmit={onTest} id="addDocument">
          <div className={open ? style.equipmentMain : style.equipmentNone}>
            <div className={` px-4 md:px-8 py-7 ${style.header} `}>
              <p className={style.cancelClass} onClick={goBack}>
                Cancel
              </p>
              <h1 className={`h1-semibold ${style.addClass}`}>{title || 'Add equipment'}</h1>
              <div className={style.invisibleDiv}>Cancel</div>
            </div>
            <div className={style.addDocumentForm}>
              {_.size(documentLists) > 0 && selectedDocumentAndFile?.id ? (
                <></>
              ) : (
                <label htmlFor="add-document" width={'100%'} className={style.selectFileBtn}>
                  <input
                    type="file"
                    id="add-document"
                    accept="application/pdf"
                    onChange={(e) => {
                      onCaptureDocument(e);
                    }}
                    style={{ display: 'none' }}
                  />
                  <Button
                    iconStart={<Icon name={'File type'} height={16} width={16} />}
                    variant={'pill'}
                    text={'Select file(s)'}
                    className={style.btnBtn}
                  />
                </label>
              )}
              <div className={style.documentLists}>
                {documentLists?.map((ele, ind) => (
                  <div className={style.singleDocument} key={ele?.id || ind}>
                    <div className={style.title}>{ele?.fileName}</div>
                    <div className={style.deleteDocument}>
                      <Icon
                        name={'delete-image-icon'}
                        alt="deleteDcumentIcon"
                        style={{
                          minWidth: '20px',
                          width: '20px'
                        }}
                        onClick={() => deleteDcouemt(ele?.id)}
                      />
                    </div>
                  </div>
                ))}
              </div>

              <div className={style.bar}></div>

              <div className="op-input-field-wrap mt-6">
                <Select
                  label="Category"
                  options={documentCategoryData?.map((x) => {
                    return {
                      label: x?.value || '',
                      value: x?.key || ''
                    };
                  })}
                  name="category"
                  error={errors?.category?.message || ''}
                  control={control}
                  placeholder="Uncategorized"
                  isClearable
                />
              </div>
            </div>

            <div className={style.footerBtn}>
              {editDocument && (
                <Icon
                  name={'delete'}
                  alt=""
                  style={{ marginRight: editDocument ? '48px' : '0px' }}
                  onClick={() => setDeleteTask(true)}
                />
              )}

              <Button
                btnClass="btn-fill button-large"
                type="submit"
                form="addDocument"
                text="SAVE"
                disabled={_.isEmpty(getValues('files')) ? true : false}
              />
            </div>
          </div>
          {deleteTask && (
            <DeleteModal
              open={deleteTask}
              setOpen={loadingDelete ? () => {} : setDeleteTask}
              handleDelete={handleDeleteEvent}
              confirmDeletion
              recordType="document"
              disabledDeleteButton={loadingDelete}
            />
          )}
        </form>
      </Portal>
    </>
  );
};

const schema = yup.object().shape({
  category: yup.string().nullable(),
  files: yup.mixed().nullable()
});

export default AddDocument;

const bytesToMB = (bytes, maxFileSize) => {
  return bytes / (1024 * 1024) > parseInt(maxFileSize);
};
