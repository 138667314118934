import React from 'react';
import _ from 'lodash';

import PhotoComponent from '@components/photo-component';

import style from '../workorder.module.scss';

const ViewableImages = ({ field }) => {
  return (
    <div className={style.body}>
      {_.size(field?.value?.photos) > 0 && (
        <>
          <h6>Photos </h6>
          <div className={style.grid2} style={{ marginTop: '0px' }}>
            {field?.value?.photos?.map((card) => (
              <PhotoComponent
                key={card?.photoId}
                photoId={card?.photoId}
                image={null}
                imageUrl={card?.imageUrl}
                categoryValue={card?.categoryValue}
                captionValue={card?.captionValue}
                editable={false}
                imageName={card?.imageName}
              />
            ))}
          </div>
        </>
      )}
    </div>
    // </div>
  );
};

export default ViewableImages;
