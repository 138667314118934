import React, { memo } from 'react';
import SettingsValueCard from './settings-value-card';
import DragDropFields from './drag-frop-fields';

const ChecksDisplaySettings = () => {
  return (
    <div className="settings--values">
      <SettingsValueCard
        title={'Check cards'}
        description={'This is how I want check data to appear on task cards:'}
      >
        <DragDropFields text={'check'} />
      </SettingsValueCard>
    </div>
  );
};

export default memo(ChecksDisplaySettings);
