/* eslint-disable no-unused-vars */

import React, { memo, forwardRef, useMemo } from 'react';

import Slider from '@components/slider';

import style from '../workorder.module.scss';

const FormSlider = (
  {
    errorsMsg,
    removeSpecialCharactersForName,
    field,
    control,
    name,
    register,
    checkPreviousFieldIsBoxed,
    watch,
    getValues,
    setValue,
    count,
    label
  },
  ref
) => {
  const { refs } = ref;

  const minMaxValue = field?.defaultValue?.split(',');
  const min = parseInt(minMaxValue[0] || 1);
  const max = parseInt(minMaxValue[1] || 10);
  const option = Array.from({ length: max - min + 1 }, (_, index) => index + min);
  const errorMessage = errorsMsg?.answer?.message || '';
  const value = watch(`${removeSpecialCharactersForName}`)?.[field?.formFieldId]?.answer;
  const sliderDefaultValue = getValues(`${name}.answer`) >= 0 ? getValues(`${name}.answer`) : null;

  const isSelectBoxOpen = useMemo(() => {
    return option.length > 15 ? true : false;
  }, [option]);

  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
      className={`${
        checkPreviousFieldIsBoxed && !isSelectBoxOpen && style.removeMarginAndBorderTopFromPassFail
      }`}
    >
      <Slider
        label={label ? label : `${count}. ${field?.label}`}
        option={option}
        isSelectBoxOpen={isSelectBoxOpen}
        name={name}
        setValue={setValue}
        errors={!(value >= 0) && errorMessage}
        sliderDefaultValue={sliderDefaultValue}
        control={control}
        watch={watch}
        isRequired={field?.isRequired}
        getValues={getValues}
        register={register}
      />
    </div>
  );
};

export default memo(forwardRef(FormSlider));
