/* eslint-disable no-unused-vars */
import { Link, useLocation } from 'react-router-dom';

import '../app-bar.scss';
import style from '../style.module.scss';

const HomeLink = ({ moreMenuToggle, onClickLink }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`${style['op-navlink']} ${style['label-regular2']} ${
        !moreMenuToggle && pathname === '/dashboard' ? style.active : ''
      }`}
    >
      <Link to={'/dashboard'} onClick={onClickLink}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 9L12 2L21 9V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V9Z"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9 22V12H15V22"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Home
      </Link>
    </div>
  );
};

export default HomeLink;
