import React, { memo, useMemo } from 'react';
import _ from 'lodash';

import ProximityFilter from './proximity';
import CollapseableTree from '@components/collapseable-tree';
import CollapseableFilter from './task-status';

const SiteFilterGroup = ({
  coordinates,
  filters,
  unit,
  updateFilters,
  isActive,
  treeData,
  areaHierarchyTreeDataMap,
  siteTypeTags,
  picklistsMap,
  siteStatusTags,
  equipmentListing = false,
  siteListing = false,
  structuredListing = false,
  tasksListing = false
}) => {
  const paddingForHeader = useMemo(() => {
    return equipmentListing || structuredListing || tasksListing
      ? '0px 0px 32px 0px'
      : siteListing
      ? '29px 0px 32px 0px'
      : '29px 0px 32px 0px';
  }, [equipmentListing, siteListing]);
  return (
    <>
      <p
        className="label-regular op-filter-label"
        style={{ padding: paddingForHeader, marginBottom: '0px' }}
      >
        SITES
      </p>
      {coordinates && (
        <div style={{ marginBottom: '40px' }}>
          <ProximityFilter
            titleText={`Under ${filters?.proximity?.value || 0} ${unit || 'km'}`}
            value={filters.proximity.value}
            onChange={(value) => updateFilters('proximity', 'value', value)}
            max={500}
            unit={'km'}
          />
        </div>
      )}
      <div style={{ marginBottom: '40px' }}>
        {!_.isEmpty(areaHierarchyTreeDataMap) && (
          <CollapseableTree
            header={'Area'}
            isActive={isActive}
            treeData={treeData}
            value={filters?.area?.value}
            treeDataMap={areaHierarchyTreeDataMap}
            onChange={(value) => {
              updateFilters('area', 'value', value);
            }}
          />
        )}
      </div>

      <div style={{ marginBottom: '40px' }}>
        {siteTypeTags?.length > 0 && (
          <CollapseableFilter
            title={'Site type'}
            tags={siteTypeTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters.siteType.value}
            onChange={(value) => updateFilters('siteType', 'value', value)}
          />
        )}
      </div>

      <div style={{ marginBottom: '40px' }}>
        {siteStatusTags?.length > 0 && (
          <CollapseableFilter
            title={'Site status'}
            tags={siteStatusTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters?.siteStatus?.value}
            onChange={(value) => updateFilters('siteStatus', 'value', value)}
          />
        )}
      </div>
    </>
  );
};

export default memo(SiteFilterGroup);
