/* eslint-disable no-unused-vars */
import { useCallback } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import Icon from '@components/icon-component';
import HighlightText from '@components/highlight-text';
import TaskTally from '@components/task-tally';

import style from './data-snippet.module.scss';

const DataSnippets = ({
  variant,
  title,
  from,
  to,
  subtitle,
  type,
  iconHeight,
  iconWidth,
  iconName,
  value,
  kmValue,
  setOpenAll,
  unit,
  dataPoints,
  highlightNames,
  chips,
  siteIcon,
  normal,
  allTasksForSite,
  allDoneTasks,
  isOverDue,
  status,
  childrenList,
  grey,
  className,
  siteDetailsCard,
  backFlex,
  showWOCount,
  divider,
  showNotIcon,
  childrenIconName = 'children',
  pipelineTitle = false,
  equipmentCount = 0,
  link = null,
  onClickOpliiDesktopLinkEvent,
  mocHseNcrInspection = false,
  hseOffsiteEvent = false,
  subTitleClassName = '',
  recordIndicatorLength = 0
}) => {
  const { settings = {} } = useSelector((state) => state.settings);
  const onClickOpliiDesktopLink = (event) => {
    event?.stopPropagation();
    onClickOpliiDesktopLinkEvent(link);
  };
  return (
    <>
      <div
        className={`${style.main} ${className}`}
        style={{
          ...(!showNotIcon && { marginBottom: '-4px' })
        }}
      >
        <div className={style.moreFlex}>
          {iconName && (
            <div className={`${style.iconDiv} ${backFlex}`}>
              <Icon
                height={iconHeight ? iconHeight : 24}
                width={iconWidth ? iconWidth : 24}
                name={iconName}
                className={siteIcon}
              />
              {kmValue >= 0 && !_.isUndefined(kmValue) && kmValue !== '' && !_.isNull(kmValue) && (
                <p>
                  {kmValue}
                  <span>{unit}</span>
                </p>
              )}
            </div>
          )}
          <div className={style.innerDiv}>
            {title && (
              <p
                className={hseOffsiteEvent ? style.hseOffsiteEventTitle : style.title}
                style={{
                  color: variant === 'secondary' ? '#7B8794' : ''
                }}
              >
                <HighlightText text={title} highlight={highlightNames} trim={pipelineTitle} />
              </p>
            )}

            {subtitle && !_.includes(type, 'Length') && (
              <h6
                className={hseOffsiteEvent ? style.hseOffsiteEventSubTitle : style.subtitle}
                style={{
                  color: mocHseNcrInspection
                    ? '#093269'
                    : variant === 'secondary'
                    ? link
                      ? '#2f80ed'
                      : '#7B8794'
                    : '',
                  marginTop: title ? '2px' : '0px'
                }}
              >
                {link ? (
                  <div className={style.opliiDesktopLink} onClick={onClickOpliiDesktopLink}>
                    <HighlightText
                      text={subtitle}
                      highlight={highlightNames}
                      onClick={onClickOpliiDesktopLink}
                    />
                    <span onClick={onClickOpliiDesktopLink}>
                      <Icon name={'feather-link'} />
                    </span>
                  </div>
                ) : subTitleClassName ? (
                  <div className={subTitleClassName}>
                    <HighlightText text={subtitle} highlight={highlightNames} link={link} />
                  </div>
                ) : (
                  <HighlightText text={subtitle} highlight={highlightNames} link={link} />
                )}
              </h6>
            )}
            {from && (
              <h6
                className={style.subtitle}
                style={{
                  color: variant === 'secondary' ? ' #7B8794' : '',
                  marginTop: title ? '2px' : '0px'
                }}
              >
                From: <HighlightText text={from} highlight={highlightNames} />
              </h6>
            )}
            {to && (
              <h6
                className={style.subtitle}
                style={{
                  color: variant === 'secondary' ? ' #7B8794' : '',
                  marginTop: title ? '2px' : '0px'
                }}
              >
                {link ? (
                  <div className={style.opliiDesktopLink}>
                    To:{' '}
                    <HighlightText
                      text={to}
                      highlight={highlightNames}
                      onClick={onClickOpliiDesktopLink}
                    />
                    <span onClick={onClickOpliiDesktopLink}>
                      <Icon name={'feather-link'} />
                    </span>
                  </div>
                ) : (
                  <>
                    To: <HighlightText text={to} highlight={highlightNames} />
                  </>
                )}
              </h6>
            )}
            {type && !_.includes(type, 'Length') && (
              <p
                className={style.typeText}
                style={{
                  color: variant === 'secondary' ? ' #7B8794' : ''
                }}
              >
                <HighlightText text={type} highlight={highlightNames} />
              </p>
            )}

            {dataPoints &&
              dataPoints.map(({ key, title, value }, ind) => (
                <p
                  key={ind}
                  className={style.typeText}
                  style={{
                    color: variant === 'secondary' ? ' #7B8794' : ''
                  }}
                >
                  <HighlightText
                    text={`${title} : ${
                      key === 'type'
                        ? value
                        : key === 'length'
                        ? `${value} ${settings['distanceUnit'] === 'kilometers' ? 'km' : 'mi'}`
                        : value
                    }`}
                    highlight={highlightNames}
                  />
                </p>
              ))}

            {!grey && (
              <div
                className={status && style.chipsDiv}
                style={{
                  padding: siteDetailsCard ? '0px 20px 0px 0px' : '0px 20px 0px 0px'
                }}
              >
                {(showWOCount || !normal) && allTasksForSite?.length > 0 && (
                  <TaskTally
                    status={
                      allDoneTasks?.length === 0
                        ? 'overdue'
                        : allDoneTasks?.length > 0 && allTasksForSite?.length > allDoneTasks?.length
                        ? 'inProgress'
                        : allDoneTasks?.length === allTasksForSite.length
                        ? 'done'
                        : ''
                    }
                    statusText={`${allDoneTasks?.length}/${allTasksForSite.length}`}
                    dot={!!isOverDue && 'true'}
                  />
                )}
                {recordIndicatorLength > 0 && (
                  <TaskTally
                    statusText={`${recordIndicatorLength}`}
                    width={14}
                    height={16}
                    recordIndicator
                  />
                )}
                {status && (
                  <>
                    <TaskTally statusText={status || ''} />
                  </>
                )}
                {childrenList && childrenList.length > 0 && (
                  <TaskTally
                    statusText={`${childrenList.length}`}
                    iconName={childrenIconName}
                    width={14}
                    height={16}
                  />
                )}

                {equipmentCount > 0 && (
                  <TaskTally
                    statusText={`${equipmentCount}`}
                    iconName={'equipment'}
                    width={14}
                    height={16}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {value && (
          <p
            className={style.value}
            onClick={(e) => {
              e.stopPropagation();
              setOpenAll((prevOpenAll) => !prevOpenAll);
            }}
          >
            ({value})
          </p>
        )}
      </div>
      {divider && <div className={style.dividerDiv} />}
    </>
  );
};

export default DataSnippets;
