/* eslint-disable no-unused-vars */

import React from 'react';

import style from './tags.module.scss';

const GeneralTag = ({ children, closable, closeIcon, className, onClose }) => {
  return (
    <div className={style.mainTag}>
      {children}
      {closable && <span onClick={onClose}>{closeIcon}</span>}
    </div>
  );
};

export default GeneralTag;
