import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { transformJSON } from './inspection';

export const transformFormWrapperDataToWorkRequestData = async (
  data,
  id,
  inspection,
  updateApp = false
) => {
  const photoData = inspection
    ? data?.photo
    : await transformPhotoData(data?.photo, 'WorkRequest', updateApp);
  const documentList = inspection
    ? data?.document
    : await transformDocumentData(data?.document, 'WorkRequest', updateApp);

  const uniqueId = !_.isEmpty(id) ? id : uuidv4();
  return {
    newWorkRequest: true,
    title: data?.title || null,
    priorityId: data?.priority || null,
    typeId: data?.type || null,
    trade: data?.trade || null,
    due: data?.requestDueDate || undefined,
    description: data?.description || null,
    detailInspections: data?.detailInspections || [],
    recordNumber: data?.recordNumber || null,
    recordTitle: data?.recordTitle || null,
    ...(data?.responsibleUsers && {
      responsible: [
        {
          woUserRequestId: data?.responsibleUsers || null
        }
      ]
    }),
    ...(data?.administrator && {
      administrators: [
        {
          woUserRequestId: data?.administrator || null
        }
      ]
    }),
    status: data?.status,
    photos: photoData || [],
    documents: documentList || [],
    siteId: data?.siteId || null,
    woId: uniqueId,
    workorderId: uniqueId,
    result: 'completed',
    entity: 'WorkRequest',
    counterReadings: null,
    type: 'New Work Request',
    labours: null,
    partEstimates: null,
    failureModes: null,
    parts: null,
    outcomeId: null,
    completed: null,

    originId: null,
    pipelineId: data?.pipelineId || null,
    equipmentId: data?.equipmentId || null,
    structureId: data?.structureId || null,
    inspId: data?.inspId || null,

    incidentId: null,
    primaryAssetType: data?.structureId ? 'Structure' : null,
    relatedAssetType: data?.structureId ? 'Structure' : null,
    primaryAssetId: data?.structureId || null,
    relatedAssetId: data?.structureId || null,
    startLatitude: `${data.startLatitude}`,
    startLongitude: `${data.startLongitude}`,
    completionLatitude: `${data.completionLatitude}`,
    completionLongitude: `${data.completionLongitude}`
  };
};

export const transformFormWrapperDataToWorkOrderDataTodo = async (
  data,
  id,
  inspection,
  updateApp = false
) => {
  const photoData = inspection
    ? data?.photo
    : await transformPhotoData(data?.photo, 'WorkOrder', updateApp);
  const documentList = inspection
    ? data?.document
    : await transformDocumentData(data?.document, 'WorkOrder', updateApp);
  const uniqueId = id ? id : uuidv4();

  return {
    //general
    newWorkOrder: true,
    detailInspections: data?.detailInspections || [],
    recordNumber: data?.recordNumber || null,
    recordTitle: data?.recordTitle || null,
    title: data?.title || null,
    priorityId: data?.priority || null,
    status: data?.status,
    // type: data?.type || null,
    typeId: data?.type || null,
    trade: data?.trade || null,
    due: data?.requestDueDate,
    description: data?.description || null,
    //Assignment
    ...(data?.responsibleUsers && {
      responsible: [
        {
          woUserRequestId: data?.responsibleUsers || null
        }
      ]
    }),
    ...(data?.administrator && {
      administrators: [
        {
          woUserRequestId: data?.administrator || null
        }
      ]
    }),
    //Your attachments
    photos: photoData || [],
    documents: documentList || [],
    siteId: data?.siteId || null,
    woId: uniqueId,
    workorderId: uniqueId,
    type: 'New Work Order',
    result: 'completed',
    entity: 'WorkOrder',
    isDone: false,
    counterReadings: null,
    labours: null,
    partEstimates: null,
    failureModes: null,
    outcomeId: null,
    completed: null,
    originId: null,
    pipelineId: data?.pipelineId || null,
    equipmentId: data?.equipmentId || null, //Id of the equipment the Work Request is for
    structureId: data?.structureId || null,
    inspId: data?.inspId || null,
    incidentId: null,
    primaryAssetType: data?.structureId ? 'Structure' : null,
    relatedAssetType: data?.structureId ? 'Structure' : null,
    primaryAssetId: data?.structureId || null,
    relatedAssetId: data?.structureId || null,
    startLatitude: `${data?.startLatitude}`,
    startLongitude: `${data?.startLongitude}`,
    completionLatitude: `${data?.completionLatitude}`,
    completionLongitude: `${data?.completionLongitude}`
  };
};

export const transformFormWrapperDataToWorkOrderDataDone = async (
  data,
  id,
  inspection = false,
  updateApp = false
) => {
  const photoData = inspection
    ? data?.photo
    : await transformPhotoData(data?.photo, 'WorkOrder', updateApp);
  const documentList = inspection
    ? data?.document
    : await transformDocumentData(data?.document, 'WorkOrder', updateApp);
  const parts = transformPartData(data?.parts);
  const counterReadings = transformCounterReadingData(data);
  const uniqueId = !_.isEmpty(id) ? id : uuidv4();

  return {
    //general
    detailInspections: data?.detailInspections || [],
    recordNumber: data?.recordNumber || null,
    recordTitle: data?.recordTitle || null,
    newWorkOrder: true,
    type: 'New Work Order',
    status: data?.status,

    title: data?.title || null,
    priorityId: data?.priority || null,
    // type: data?.type || null,
    typeId: data?.type || null,
    trade: data?.trade || null,
    due: data?.requestDueDate,
    description: data?.description || null,
    //Assignment
    ...(data?.responsibleUsers && {
      responsible: [
        {
          woUserRequestId: data?.responsibleUsers || null
        }
      ]
    }),
    ...(data?.administrator && {
      administrators: [
        {
          woUserRequestId: data?.administrator || null
        }
      ]
    }),
    //Your attachments
    photos: photoData || [],
    documents: documentList || [],
    siteId: data?.siteId || null,

    result: 'completed',
    entity: 'WorkOrder',
    isDone: true,
    woId: uniqueId,
    workorderId: uniqueId,
    labours: null,
    partEstimates: null,
    failureModes: null,

    outcomeId: null,
    completed: null,
    originId: null,
    pipelineId: data?.pipelineId || null,
    equipmentId: data?.equipmentId || null,
    structureId: data?.structureId || null,
    inspId: data?.inspId || null,
    // incidentId: null,

    // worklog part
    notes: data?.technicalNotes || null,
    workStarted: data?.workStarted || null,
    workCompleted: data?.workCompletedDate || null,
    counterReadings,
    regularHours: data?.regularHours || null,
    overTimeHours: data?.overTimeHours || null,
    actualLabour: data?.actualLabour || null,
    actualParts: data?.actualParts || null,
    parts,
    primaryAssetType: data?.structureId ? 'Structure' : null,
    relatedAssetType: data?.structureId ? 'Structure' : null,
    primaryAssetId: data?.structureId || null,
    relatedAssetId: data?.structureId || null,
    startLatitude: `${data?.startLatitude}`,
    startLongitude: `${data?.startLongitude}`,
    completionLatitude: `${data?.completionLatitude}`,
    completionLongitude: `${data?.completionLongitude}`
  };
};

export const transformFormWrapperDataToWorkOrderWorkLog = async (data, updateApp = false) => {
  const photoData = await transformPhotoData(data?.photo, 'WorkOrder', updateApp);
  const documentList = await transformDocumentData(data?.document, 'WorkOrder', updateApp);
  const parts = transformPartData(data?.parts);
  const counterReadings = transformCounterReadingData(data);

  return {
    photos: photoData || [],
    documents: documentList || [],
    // worklog part
    notes: data?.technicalNotes || null,
    workStarted: moment(data?.workStarted).format('YYYY-MM-DD') || null,
    workCompleted: moment(data?.workCompletedDate).format('YYYY-MM-DD') || null,
    counterReadings,
    regularHours: data?.regularHours || null,
    overTimeHours: data?.overTimeHours || null,
    actualLabour: data?.actualLabour || null,
    actualParts: data?.actualParts || null,
    parts,
    formData: data?.formData || null
  };
};

export const transformPhotoData = async (
  photoData = [],
  documentType = 'WorkRequest',
  updateApp = false
) => {
  const transformedData = [];

  if (_.size(photoData) > 0) {
    for (const doc of photoData) {
      const file = updateApp ? { id: doc?.id, name: doc.fileName, type: doc?.mimeType } : '';
      const transformedDoc = {
        caption: doc?.caption || null,
        fileName: doc?.image?.name || null,
        photoCategory: doc?.category || null,
        content: updateApp ? file : doc.image,
        documentType,
        mimeType: doc?.image?.type || 'image/png',
        id: doc?.id || null
      };

      transformedData.push(transformedDoc);
    }
  }

  return transformedData;
};
export const transformDocumentData = async (
  documentData = [],
  documentType = 'WorkRequest',
  updateApp = false
) => {
  const transformedData = [];

  if (_.size(documentData) > 0) {
    for (const doc of documentData) {
      const file = updateApp
        ? {
            name: doc?.fileName,
            type: doc?.mimeType,
            id: doc?.id
          }
        : '';
      const transformedDoc = {
        fileName: doc?.file?.file?.name || null,
        documentCategory: doc?.category || null,
        content: updateApp ? file : doc?.file?.file,
        documentType,
        mimeType: doc?.file?.file?.type || 'application/pdf',
        id: doc?.id || null
      };

      transformedData.push(transformedDoc);
    }
  }

  return transformedData;
};
const transformPartData = (partsData = []) => {
  const parts =
    _.size(partsData) > 0
      ? partsData?.map((x) => {
          return {
            Id: x.id || null,
            PartNum: x?.part || null,
            PartName: x['part/materialName'] || null,
            Manufacturer: x?.manufactuer || null,
            Quantity: x?.quantity || null,
            Unit: x?.unit || null
          };
        })
      : null;
  return parts;
};
const transformCounterReadingData = (data) => {
  return data?.readingUnit || data?.reading
    ? [
        {
          id: uuidv4(),
          //   EquipmentId: data?.equipmentId || null,
          ...(data?.equipmentId && { EquipmentId: data?.equipmentId }),
          ReadingUnit: data?.readingUnit,
          Reading: data?.reading || null,
          ReadingDate: moment().format('YYYY-MM-DD'),
          ReadingTime: moment().format('HH:mm:ss')
        }
      ]
    : null;
};

export const prepareWorkOrderForBackend = (newWorkRequestOrderOrder, inspectionFormsUserCache) => {
  const {
    title,
    woId,
    priorityId,
    type,
    trade,
    due,
    description,
    result,
    entity,
    preparation,
    permitsReq,
    ToolsReq,
    safetyConcerns,
    enviroConcerns,
    isShutdownRequired,
    estimatedDowntime,
    originId,
    pipelineId,
    equipmentId,
    structureId,
    responsible,
    administrators,
    counterReadings,
    labours,
    partEstimates,
    failureModes,
    parts,
    outcomeId,
    completed,
    inspId,
    incidentId,
    isDone,
    regularHours,
    overTimeHours,
    actualLabour,
    actualParts,
    notes,
    siteId,
    workStarted,
    detailInspections,
    recordNumber,
    recordTitle,
    workCompleted,
    startLatitude,
    startLongitude,
    completionLatitude,
    completionLongitude
  } = newWorkRequestOrderOrder;

  const workOrderLocation = {
    startLatitude: `${startLatitude}`,
    startLongitude: `${startLongitude}`,
    completionLatitude: `${completionLatitude}`,
    completionLongitude: `${completionLongitude}`
  };

  if (newWorkRequestOrderOrder.entity === 'WorkRequest') {
    // Work Request
    return {
      detailInspections,
      recordNumber,
      recordTitle,
      title,
      woId,
      priorityId,
      type,
      trade,
      due,
      description,
      responsible,
      administrators,
      result,
      entity,
      preparation,
      permitsReq,
      ToolsReq,
      safetyConcerns,
      enviroConcerns,
      isShutdownRequired,
      estimatedDowntime,
      originId,
      pipelineId,
      equipmentId,
      structureId,
      siteId,
      inspId,
      overTimeHours,
      actualStartDate: workStarted,
      primaryAssetId: structureId ? 'Structure' : '',
      primaryAssetType: structureId ? 'Structure' : '',
      relatedAssetId: structureId ? structureId : '',
      relatedAssetType: structureId ? 'Structure' : '',
      appliesToType: structureId ? 'structure' : '',
      structures: structureId ? [structureId] : [],
      ...workOrderLocation
    };
  } else {
    // Work orders
    if (newWorkRequestOrderOrder.woId) {
      if (newWorkRequestOrderOrder.isDone) {
        // Done
        return {
          detailInspections,
          recordNumber,
          recordTitle,
          title,
          woId,
          priorityId,
          type,
          trade,
          due,
          description,
          result,
          entity,
          preparation,
          permitsReq,
          ToolsReq,
          safetyConcerns,
          enviroConcerns,
          isShutdownRequired,
          estimatedDowntime,
          originId,
          pipelineId,
          equipmentId,
          structureId,
          responsible,
          administrators,
          counterReadings,
          labours,
          partEstimates,
          failureModes,
          parts,
          outcomeId,
          completed,
          inspId,
          incidentId,
          isDone,
          regHours: regularHours,
          otHours: overTimeHours,
          actualLabour,
          actualParts,
          notes,
          siteId,
          actualStartDate: workStarted,
          workCompleted: workCompleted,
          primaryAssetId: structureId ? 'Structure' : '',
          primaryAssetType: structureId ? 'Structure' : '',
          relatedAssetId: structureId ? structureId : '',
          relatedAssetType: structureId ? 'Structure' : '',
          appliesToType: structureId ? 'structure' : '',
          structures: structureId ? [structureId] : [],
          ...workOrderLocation
        };
      } else {
        // To do
        return {
          detailInspections,
          recordNumber,
          recordTitle,
          title,
          woId,
          priorityId,
          type,
          trade,
          due,
          description,
          result,
          entity,
          preparation,
          permitsReq,
          ToolsReq,
          safetyConcerns,
          enviroConcerns,
          isShutdownRequired,
          estimatedDowntime,
          originId,
          pipelineId,
          equipmentId,
          structureId,
          responsible,
          administrators,
          counterReadings,
          labours,
          partEstimates,
          failureModes,
          parts,
          outcomeId,
          completed,
          inspId,
          incidentId,
          isDone,
          regularHours,
          overTimeHours,
          actualLabour,
          actualParts,
          notes,
          siteId,
          actualStartDate: workStarted,
          workCompleted: workCompleted,
          primaryAssetId: structureId ? 'Structure' : '',
          primaryAssetType: structureId ? 'Structure' : '',
          relatedAssetId: structureId ? structureId : '',
          relatedAssetType: structureId ? 'Structure' : '',
          appliesToType: structureId ? 'structure' : '',
          structures: structureId ? [structureId] : [],
          ...workOrderLocation
        };
      }
    } else {
      // Assigned WO
      const formData = newWorkRequestOrderOrder?.formData || {};

      const currentFrom =
        inspectionFormsUserCache?.find(
          (x) => x?.inspectionFormId === newWorkRequestOrderOrder?.formId
        ) || {};

      const json = transformJSON({ currentFrom, formData });

      const administratorsUsers = [
        ...([
          ...(newWorkRequestOrderOrder?.administrators || []),
          ...(newWorkRequestOrderOrder?.administratorUserGroups || [])
        ]?.map((a) => ({ woUserRequestId: a })) || {})
      ];

      const responsibleUsers = [
        ...([
          ...(newWorkRequestOrderOrder?.responsibleUsers || []),
          ...(newWorkRequestOrderOrder?.responsibleUserGroups || [])
        ]?.map((a) => ({ woUserRequestId: a })) || {})
      ];

      return {
        title,
        responsible: responsibleUsers,
        administrators: administratorsUsers,
        ...(!['Critical', 'Medium', 'High', 'Low'].includes(priorityId) && { priorityId }),
        type,
        trade,
        due,
        description,
        result,
        entity: 'WorkOrder',
        counterReadings,
        labours,
        partEstimates,
        failureModes,
        parts,
        outcomeId,
        completed,
        preparation,
        permitsReq,
        ToolsReq,
        safetyConcerns,
        enviroConcerns,
        isShutdownRequired,
        estimatedDowntime,
        originId,
        pipelineId,
        equipmentId,
        structureId,
        inspId,
        incidentId,
        isDone,
        regHours: regularHours,
        otHours: overTimeHours,
        actualLabour,
        actualParts,
        notes,
        siteId,
        actualStartDate: workStarted,

        workCompleted: workCompleted,
        primaryAssetId: structureId ? 'Structure' : pipelineId ? 'Pipeline' : '',
        primaryAssetType: structureId ? 'Structure' : pipelineId ? 'Pipeline' : '',
        relatedAssetId: structureId ? structureId : pipelineId ? pipelineId : '',
        relatedAssetType: structureId ? 'Structure' : pipelineId ? pipelineId : '',
        appliesToType: structureId ? 'Structure' : pipelineId ? 'Pipeline' : '',
        structures: newWorkRequestOrderOrder?.structures.length
          ? newWorkRequestOrderOrder?.structures
          : [],
        pipelines: newWorkRequestOrderOrder?.pipelines.length
          ? newWorkRequestOrderOrder?.pipelines
          : [],
        formdata: json,
        formId: newWorkRequestOrderOrder?.formId,
        ...workOrderLocation
      };
    }
  }
};
