const agent = typeof navigator !== 'undefined' ? navigator.userAgent : '';

const isMobile = {
  android: () => /android/i.test(agent),
  blackBerry: () => /blackberry/i.test(agent),
  iOS: () => /iphone|ipod|ipad/i.test(agent),
  opera: () => /opera mini/i.test(agent),
  windows: () => /iemobile/i.test(agent),
  any: () =>
    isMobile.android() ||
    isMobile.blackBerry() ||
    isMobile.iOS() ||
    isMobile.opera() ||
    isMobile.windows(),

  ipadPro: () => {
    /Macintosh/i.test(agent); //this is only for ipads using Mac OS in them
  },

  tablet: () =>
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/i.test(
      agent
    ),
  standaloneMode: () => window.matchMedia('(display-mode: standalone)').matches
};

export default isMobile;
