import React, { useEffect } from 'react';

import Icon from '@components/icon-component';

import style from './sync.module.scss';

const SyncTasks = ({ status, count }) => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className={style.bgColor}>
      <div className={style.seeCard}>
        <h6 className={style.titleClass}>Syncing = submitting</h6>
        <div className={style.para}>
          <div className={style.p}>
            What’s the use of doing all this work if you don’t send it in?
            <p className={style.redClass} style={{ marginTop: '16px' }}>
              Syncing data sends any ‘Pending sync’ tasks or records into the main database and
              changes their status to Synced.’
            </p>
          </div>
          <div className={style.iconDiv}>
            <div className={style.iconCircle} style={{ borderColor: '#2F80ED' }}>
              <Icon name={'newOnline'} height={20} width={20} className={style.roundIcon} />
            </div>
            {count && (
              <span
                className={style.count}
                style={{
                  background: '#2F80ED'
                }}
              >
                {count}
              </span>
            )}
            {status && <p className={style.title}>Online</p>}
          </div>
        </div>

        <h6 className={style.titleClass}>How?</h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          On a phone, go to Home and hit the sync indicator button, shown above. On the popup,
          select Sync now.
          <span style={{ fontWeight: 700 }}>Refresh data.</span>
        </p>
        <p className={style.p} style={{ marginTop: '8px' }}>
          (On a tablet, you can do this from any screen because the button is on the navigation
          bar.)
        </p>
        <h6 className={style.sync}>Syncing also refreshes your data</h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          When you sync completed tasks and records, your task and asset data is automatically
          refreshed.
        </p>
      </div>
    </div>
  );
};

export default SyncTasks;
