/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import SearchField from '@components/search-field';
import FilterHeader from '@components/filters/filter-header';
import StructureFilterGroups from '@components/filters/structure-filter-group';
import StructureBookmark from './structure-bookmark';
import SiteFilterGroups from '@components/filters/site-filter-group';
import TasksFilterGroups from '@components/filters/task-filter-group';

import { getAppDispatch } from '@utilities/dispatch.util';
import { countFilters, getStructureDefaultFilters, mapValues } from './helper';
import { checkValueInObjectIsNotEmpty } from '@utilities/helper.util';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { filterActions } from '@redux/slices/filters';

import style from './style.module.scss';
import ResetAndViewButton from '@components/filters/reset-view-button';

const FilterComponent = ({
  length,
  filtersCount,
  setFilterActive,
  setResetFilters,
  noGap,
  onSearch,
  resetFilters,
  isFilterActive,
  setFiltersCount,
  onChangeFilters,
  sitesSiteTypeDataMap,
  sitesSiteStatusDataMap,
  sitesCheckPriorityDataMap,
  sitesAssignableUsersDataMap,
  sitesWorkOrderPriorityDataMap,
  sitesAreaHierarchyTreeDataMap,
  structuresStructureTypeDataMap,
  structuresStructureStatusDataMap,
  structures,
  selectedSort,
  bookmarkFilter,
  removeClassOnAssetDetailsFilters,
  siteId,
  detailPage,
  store
}) => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { width } = useWindowDimensions();
  const dispatch = getAppDispatch();
  const dueDateFilter = searchParams.get('dueDate');
  const downloadFilter = searchParams.get('download');
  const keyNameFilter = searchParams.get('keyName');
  const tabFilter = searchParams.get('tab');

  const { unit, coordinates } = useSelector((state) => state.cache);
  const structuresFilter = useSelector((state) => state?.filters?.structuresFilter) || {};

  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state?.picklists
  );

  const [isActive, setActive] = useState(isFilterActive);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState(getStructureDefaultFilters);
  const [initialY, setInitialY] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setActive(isFilterActive);
  }, [isFilterActive]);

  const {
    siteTypeTags,
    siteStatusTags,
    assignableUsersTags,
    checkPriorityTags,
    workOrderPriorityTags,
    treeData,
    taskTypeTag,
    taskStatusTags,
    taskRecurringTemplateTags,
    workOrderTypeTags,
    structureStatusTags,
    structureTypeTags,
    checkRecurrancePriorityTags,
    checkTypeTags,
    checkTypeTagsMap
  } = React.useMemo(() => {
    return mapValues({
      sitesSiteTypeDataMap,
      sitesSiteStatusDataMap,
      sitesAssignableUsersDataMap,
      sitesCheckPriorityDataMap,
      sitesWorkOrderPriorityDataMap,
      sitesAreaHierarchyTreeDataMap,
      structuresStructureTypeDataMap,
      structuresStructureStatusDataMap,
      structures,
      store
    });
  }, [
    sitesSiteTypeDataMap,
    sitesSiteStatusDataMap,
    sitesAssignableUsersDataMap,
    sitesCheckPriorityDataMap,
    sitesWorkOrderPriorityDataMap,
    sitesAreaHierarchyTreeDataMap,
    structuresStructureTypeDataMap,
    structuresStructureStatusDataMap
  ]);

  const startTouch = (e) => setInitialY(e.touches[0].clientY);

  const moveTouch = (e) => {
    let currentY = e.touches[0].clientY;
    let diffY = initialY - currentY;
    if (diffY < 0) setFilterActive(false);
    setInitialY(e.touches[0].clientY);
  };

  useEffect(() => {
    if (!_.isEmpty(bookmarkFilter)) {
      const filtersInDecode = decodeURIComponent(bookmarkFilter);
      const filterInParse = JSON.parse(filtersInDecode);
      filterInParse?.filters && setFilters(filterInParse?.filters);
      filterInParse?.filters &&
        filterInParse?.filters['searched'] &&
        setInputValue(filterInParse?.filters['searched'] || '');
    } else if (checkValueInObjectIsNotEmpty(structuresFilter) && !detailPage) {
      setFilters(structuresFilter);
      onChangeFilters?.(structuresFilter);
      setInputValue(structuresFilter?.searched || '');
      onSearch(structuresFilter?.searched || '');
    } else {
      setInputValue('');
      setFilters(getStructureDefaultFilters);
    }

    if (resetFilters) resetBtnEvent({ filterActive: width < 768 ? false : true });
  }, [resetFilters, state?.resetFilters, bookmarkFilter]);

  useEffect(() => {
    if (downloadFilter) updateFilters('download', 'selected', downloadFilter);
  }, [downloadFilter]);

  useEffect(() => {
    if (dueDateFilter === 'today') {
      updateFilters('dueDate', 'value', moment());
      updateFilters('dueDate', 'selected', 'on');
    } else if (dueDateFilter === 'past') {
      updateFilters('dueDate', 'selected', 'past');
      updateFilters('dueDate', 'value', ['Unlimited']);
    }
  }, [dueDateFilter]);

  useEffect(() => {
    const selectedFiltersCount = countFilters(filters, inputValue, store) || 0;
    !detailPage && dispatch?.(filterActions.setStructureFiltersCount(selectedFiltersCount));
    setFiltersCount(selectedFiltersCount);
  }, [filters, inputValue, detailPage]);

  useEffect(() => {
    if (isActive) document.body.classList.add('filter-open');
    else document.body.classList.remove('filter-open');
  }, [isActive]);

  useEffect(() => {
    return () => {
      if (isActive) {
        setActive(false);
        document.body.classList.remove('filter-open');
      }
    };
  }, [isActive]);

  const updateFilters = (name, key, value) => {
    const filtersCopy = { ...filters };
    let newFilter = {
      ...filtersCopy,
      [name]: {
        ...Object.assign({}, filtersCopy[name]),
        [key]: value,
        ...(key === 'selected' && value === 'on' && { value: '' }),
        ...(key === 'selected' && (value === 'past' || value === 'next') && { value: [] }),
        ...(key === 'selected' && value === 'range' && { value: ['', ''] }),
        ...(key === 'selected' && value === 'last' && { value: ['last'] })
      }
    };
    setFilters({ ...newFilter });
    onChangeFilters?.({ ...newFilter });
    !detailPage &&
      dispatch?.(filterActions.setStructuresFilters({ ...newFilter, searched: inputValue }));
  };

  const resetBtnEvent = ({ filterActive = true }) => {
    setResetFilters(false);
    navigate(pathname, { replace: true });
    setFilterActive(filterActive);
    setInputValue('');
    setFilters(getStructureDefaultFilters);
    onChangeFilters?.(getStructureDefaultFilters);
    onSearch('');
    !detailPage &&
      dispatch?.(
        filterActions.setStructuresFilters({ ...getStructureDefaultFilters, searched: '' })
      );
  };

  const leftBtnEvent = () => setFilterActive(false);

  const handleUserInput = (e) => {
    const valueSearched = (e && e.target.value) || '';
    onSearch?.(valueSearched);
    !detailPage &&
      dispatch?.(filterActions.setStructuresFilters({ ...filters, searched: valueSearched }));
    setInputValue(valueSearched);
  };

  const handleKeyDown = (e) => {
    if (width < 768 && e.key === 'Enter') setFilterActive(false);
  };

  const conditionClass = useMemo(() => {
    return width < 768 && removeClassOnAssetDetailsFilters && !isFilterActive
      ? style.displayNone
      : style.displayBlock;
  }, [isFilterActive]);

  return (
    <>
      <div
        className={`op-sidebarfilter ${isActive && 'open'} ${!noGap && 'gap'} ${conditionClass} ${
          isActive ? style.isActivePadding : style.isInActivePadding
        } `}
      >
        <div onTouchStart={startTouch} onTouchMove={moveTouch}>
          <FilterHeader
            title="Filters"
            filterCount={filtersCount || 0}
            resetBtnEvent={() => resetBtnEvent({})}
            leftBtnEvent={leftBtnEvent}
          />
        </div>
        <div className="op-filter-search" style={{ marginTop: '25px' }}>
          <SearchField
            placeholder="Search"
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleUserInput}
            isClearable
          />
        </div>

        <StructureFilterGroups
          structureTypeTags={structureTypeTags}
          picklistsMap={picklistsMap}
          filters={filters}
          updateFilters={updateFilters}
          structureStatusTags={structureStatusTags}
        />

        {!removeClassOnAssetDetailsFilters && (
          <SiteFilterGroups
            coordinates={coordinates}
            filters={filters}
            unit={unit}
            updateFilters={updateFilters}
            isActive={isActive}
            treeData={treeData}
            areaHierarchyTreeDataMap={areaHierarchyTreeDataMap}
            siteTypeTags={siteTypeTags}
            picklistsMap={picklistsMap}
            siteStatusTags={siteStatusTags}
            structuredListing
          />
        )}

        {!removeClassOnAssetDetailsFilters && (
          <TasksFilterGroups
            taskTypeTag={taskTypeTag}
            filters={filters}
            updateFilters={updateFilters}
            taskStatusTags={taskStatusTags}
            workOrderTypeTags={workOrderTypeTags}
            assignableUsersTags={assignableUsersTags}
            picklistsMap={picklistsMap}
            workOrderPriorityTags={workOrderPriorityTags}
            taskRecurringTemplateTags={taskRecurringTemplateTags}
            isActive={isActive}
            checkRecurrancePriorityTags={checkRecurrancePriorityTags}
            checkTypeTags={checkTypeTags}
            checkTypeTagsMap={checkTypeTagsMap}
          />
        )}

        <ResetAndViewButton
          clickResetButton={() => resetBtnEvent({ filterActive: false })}
          length={length}
          setFilterActiveEvent={(value) => setFilterActive(value)}
          setIsModalVisibleEvent={(value) => setIsModalVisible(value)}
          width={width}
          detailPage={detailPage}
        />
      </div>

      {isModalVisible && (
        <StructureBookmark
          open={isModalVisible}
          setOpen={(value) => {
            setIsModalVisible(value);
          }}
          selectedSort={selectedSort}
          filters={{ ...filters, searched: inputValue }}
          siteId={siteId}
          keyName={keyNameFilter}
          tab={tabFilter}
        />
      )}
    </>
  );
};

export default FilterComponent;
