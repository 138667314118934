/* eslint-disable no-unused-vars */

import TasksRecords from '@pages/tasks-records/tasks-records-list';

import './work-order.scss';

const WorkOrderPage = ({
  tasks,
  setSyncComplete = null,
  parentSiteDetailsBackFilter = false,
  equipmentDetailsBackFilter = false,
  onParentClickEvent = null,
  workOrderSelectedTab = null,
  workOrderSortTab = null,
  asset = 'sites',
  isFilterActiveHeader = false,
  setFilterActiveHeader = () => {},
  siteDetails = false,
  parentSiteDetails = false,
  filterType,
  workRequest = false,
  hseEvent = false
}) => {
  return (
    <TasksRecords
      tasksList={tasks}
      selectTodoOrInProgressStatusOnBookmarkSelection={false}
      asset={asset}
      workOrderSelectedTab={workOrderSelectedTab}
      workOrderSortTab={workOrderSortTab}
      setSyncComplete={setSyncComplete}
      parentSiteDetailsBackFilter={parentSiteDetailsBackFilter}
      equipmentDetailsBackFilter={equipmentDetailsBackFilter}
      onParentClickEvent={onParentClickEvent}
      isFilterActiveHeader={isFilterActiveHeader}
      setFilterActiveHeader={setFilterActiveHeader}
      siteDetails={siteDetails}
      hseEvent={hseEvent}
      parentSiteDetails={parentSiteDetails}
      filterType={filterType}
      workRequest={workRequest}
    />
  );
};

export default WorkOrderPage;
