import React, { memo, forwardRef } from 'react';

import YesNo from '@components/yes-no';

const FormYesNoComponent = (
  {
    errorsMsg,
    removeSpecialCharactersForName,
    field,
    control,
    name,
    register,
    watch,
    count,
    label
  },
  ref
) => {
  const { refs } = ref;
  let errorsMessage = errorsMsg || '';
  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
    >
      <YesNo
        dot={field?.isRequired}
        label={label ? label : `${count}. ${field?.label}`}
        control={control}
        errors={errorsMessage}
        name={name}
        showComment={watch(name)?.value === 'No' ? true : false}
        register={register}
      />
    </div>
  );
};

export default memo(forwardRef(FormYesNoComponent));
