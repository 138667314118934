import React, { memo } from 'react';

import AddNewWorkOrder from '@components/add-work-order-modal';

const FormWorkOrder = ({
  getValues,
  name,
  setValue,
  watch,
  appliesToData,
  workOrder = false,
  workRequest = false
}) => {
  return (
    <>
      <AddNewWorkOrder
        noHeader
        getValues={getValues}
        name={name}
        setValue={setValue}
        watch={watch}
        showInfo={appliesToData?.containWorkOrder}
        workOrder={workOrder}
        workRequest={workRequest}
      />
    </>
  );
};

export default memo(FormWorkOrder);
