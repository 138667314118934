/* eslint-disable no-unused-vars */

import React, { memo, useState } from 'react';

import Icon from '@components/icon-component';

import CollapseableHeader from './collapseable-header';
import style from './collapseable.module.scss';

const Collapseable = ({
  children,
  title,
  subTitle,
  noAction,
  downloaded,
  onDelete,
  onEdit,
  onDownload,
  subTitleClass = '',
  subTitleErrorClass = '',
  dot = false,
  dotColor = '#e02b2b',
  openCollapse = false,
  showEllipseOnTitleSecond = false,
  error = false
}) => {
  const [collapse, setCollapse] = useState(openCollapse);
  return (
    <div className={`${style.container} ${error && style.redBorder}`}>
      <CollapseableHeader
        titleFirst={title}
        titleSecond={subTitle}
        dotColor={dotColor}
        onClick={() => setCollapse((prev) => !prev)}
        subTitleClass={`${subTitleClass} ${error && subTitleErrorClass}`}
        dot={dot}
        collapse={collapse}
        showEllipseOnTitleSecond={showEllipseOnTitleSecond}
      />

      {collapse ? (
        <div
          className={style.collapseBody}
          style={{
            transition: 'all 1.5s ease-in'
          }}
        >
          <div className={style.showPart}>
            {children}
            {!noAction && (
              <div
                className={style.sectionLast}
                style={{
                  marginTop: '16px'
                }}
              >
                <Icon name={'delete'} alt="delete" onClick={onDelete} />
                <div className={style.sectionLast} style={{ gap: 10 }}>
                  <Icon name={'edit-circle'} alt="edit" onClick={onEdit} />
                  {downloaded && (
                    <Icon name={'inspection-black'} alt="edit" onDownLoad={onDownload} />
                  )}
                </div>
              </div>
            )}
            {downloaded && (
              <div
                className={style.sectionLast}
                style={{
                  marginTop: '16px'
                }}
              >
                {/* <div className={style.sectionLast} style={{ gap: 10 }}>
                  {downloaded && <Icon name={'inspection-black'} alt="edit" onClick={onDownload} />}
                </div> */}
                <a
                  href="https://opliidevstorage.blob.core.windows.net/documents/ad4d228e-9970-47cc-bccf-ec2ba347eb6d/workorders/06361e6c-f150-49de-b4fa-b17e00645619/hwllo1.pdf?sv=2024-05-04&se=2024-07-13T12%3A07%3A33Z&sr=b&sp=r&sig=Yu7vL%2BjKEUmpf8PgZjrZkapz8OeejmUsmGj%2FTTxVSEo%3D"
                  // target="_blank"
                  download
                  // rel="noreferrer"
                >
                  kjkjk
                </a>
              </div>
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default memo(Collapseable);
