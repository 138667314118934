/* eslint-disable no-unused-vars */
import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../..';
import LoadingRound from '@components/loading-round/loading-round';
import Icon from '@components/icon-component';

import SettingsService from '@services/settings.service';
import { cacheActions } from '@redux/slices/cache';
import { tasksListsActions } from '@redux/slices/tasks';
import { bookmarksActions } from '@redux/slices/bookmarks';
import CacheService from '@services/cache.service';

import style from './style.module.scss';

const localProgressBar = {
  0: 'Checking out what’s new',
  1: 'Checking out what’s new',
  2: 'Checking out what’s new',
  3: 'Hauling 100 equipment',
  4: 'Processing the particulars',
  5: 'Processing the particulars',
  6: 'Processing the particulars',
  7: 'Processing the particulars',
  8: 'Establishing 100 sites',
  9: 'Pulling 100 pipeline',
  10: 'Setting up 100 structures',
  11: 'Grabbing 100 work orders',
  12: 'Picking up 100 inspections',
  13: 'Loading up 100 checks',
  14: 'Snapping up 100 forms'
};

const RefreshModal = ({
  open,
  setOpen,
  refreshingData = false,
  refreshComplete = false,
  refreshFailed = false,
  cancelForGettingDataEvent,
  initialLogin,
  cancelAndRetryEvent,
  titleCount,
  setTitleCount
}) => {
  const [retryCount, setRetryCount] = useState(1);
  const dispatch = useDispatch();
  const { updatedSettings = {} } = useSelector((state) => state.settings);
  const { count = {}, refreshModalObject } = useSelector((state) => state.mobileSyncCount);
  const isCacheCompleteForAssets = localStorage.getItem('isCacheComplete') || false;

  const isOffline = useMemo(() => {
    if (!navigator.onLine) {
      window.stop();
      dispatch?.(cacheActions.failureCaching());
    }
    return !navigator.onLine;
  }, [navigator.onLine]);

  const staticProgressBar = useMemo(() => {
    return refreshModalObject ? refreshModalObject : localProgressBar;
  }, [count, refreshModalObject]);

  const { loading } = useSelector((state) => state.globalProgressBar);
  const { bookmarks = [] } = useSelector((state) => state.bookmarks);

  const syncAgain = async () => {
    localStorage.setItem('startSyncingOrRefreshing', true);

    if (navigator.onLine) {
      dispatch?.(
        tasksListsActions.setSyncDone({
          workOrder: 0,
          workRequest: 0,
          photos: 0,
          documents: 0,
          inspection: 0,
          inspectionPhotos: 0,
          equipmentRecords: 0,
          hseEvents: 0,
          checks: 0,
          checkPhotoCount: 0,
          checkDocumentCount: 0,
          inspectionPhotoCount: 0,
          workOrderPhotoCount: 0,
          workRequestPhotoCount: 0,
          incidentPhotoCount: 0,
          inspectionDocumentCount: 0,
          workOrderDocumentCount: 0,
          workRequestDocumentCount: 0,
          incidentDocumentCount: 0,
          failedImageCount: 0,
          failedDocumentCount: 0
        })
      );
      const [success] = await CacheService.refresh({
        sync: false,
        updatedSettings,
        workOrdersPendingSync: [],
        inspectionPendingSync: [],
        pendingSyncLength: 0,
        bookmarks: bookmarks?.filter((bookmark) => !bookmark?.sync) || [],
        updateBookmarks: bookmarks?.filter((bookmark) => bookmark?.sync && bookmark?.update) || [],
        deletedBookmarks: bookmarks?.filter((bookmark) => bookmark?.isDelete) || []
      });
      if (success) await SettingsService.checkUpdate();

      dispatch?.(cacheActions.setPrevPendingSync(0));
      localStorage.setItem('startSyncingOrRefreshing', false);
    } else {
      dispatch?.(cacheActions.updateRefreshingAndSyncingData(false));
      setOpen(false);
      localStorage.setItem('startSyncingOrRefreshing', false);
    }
  };

  const refreshModalIcon = React.useMemo(() => {
    if (isOffline) return <Icon name={'failed'} />;
    else if (refreshingData) return <LoadingRound modal={true} className={style.lottie} />;
    else if (refreshComplete) return <Icon name={'complete'} />;
    else if (refreshFailed) return <Icon name={'failed'} />;
  }, [refreshingData, refreshComplete, refreshFailed, isOffline]);

  const statusText = React.useMemo(() => {
    if (isOffline) return <>Refresh failed</>;
    else if (refreshingData) return <>Refreshing data</>;
    else if (refreshComplete) return <>Data refresh complete</>;
    else if (refreshFailed) return <>Refresh failed</>;
  }, [refreshingData, refreshComplete, refreshFailed, isOffline]);

  useEffect(() => {
    const titleCountTimer = setInterval(() => {
      handleTitleCountUpdate();
    }, 6000);

    return () => {
      clearInterval(titleCountTimer);
    };
  }, [titleCount]);

  const handleTitleCountUpdate = () => {
    if (titleCount >= titles.length - 1) {
      setTitleCount(0);
    } else {
      setTitleCount((prev) => prev + 1);
    }
  };

  const modalTextUpper = React.useMemo(() => {
    if (isOffline) return <>Oh no. If your internet connection isn’t solid, try later.</>;
    else if (refreshingData)
      return (
        <>
          Hold on. We’re getting the latest and greatest data for you.
          <div className={style.progressBarContainer}>
            <progress max={14} value={loading}></progress>
            <p>{staticProgressBar[loading]}</p>

            <div className={style.info} onClick={handleTitleCountUpdate}>
              <div key={titleCount}>{titles[titleCount]?.render}</div>
            </div>
          </div>
        </>
      );
    else if (refreshComplete)
      return (
        <>
          Rest assured, your app is up to date with the latest task and asset information.
          <div className={style.progressBarContainer} style={{ paddingTop: '0px' }}>
            <div className={style.info} onClick={handleTitleCountUpdate}>
              <div key={titleCount}>{titles[titleCount]?.render}</div>
            </div>
          </div>
        </>
      );
    else if (refreshFailed) return <>Oh no. If your internet connection isn’t solid, try later.</>;
  }, [refreshingData, refreshComplete, refreshFailed, loading, titleCount, isOffline]);

  const modalInnerBody = React.useMemo(() => {
    if (isOffline)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={() => {
                syncAgain();
              }}
            >
              Retry
            </button>
          </div>
        </div>
      );
    else if (refreshingData)
      return !initialLogin ? (
        <div className={style.modalBtn}>
          <button
            className={`${!initialLogin ? style.modalBtnCancel : `btn-fill button-large `}`}
            onClick={cancelForGettingDataEvent}
            disabled={initialLogin}
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className={style.cancelAndRetry} onClick={cancelAndRetryEvent}>
          <p>Cancel and retry</p>
        </div>
      );
    else if (refreshComplete)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={() => {
                setOpen();
                dispatch?.(bookmarksActions.setBookmarkValidity(true));
              }}
            >
              CLOSE
            </button>
          </div>
        </div>
      );
    else if (refreshFailed)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className="btn-fill button-large"
              onClick={() => {
                syncAgain();
              }}
            >
              Retry
            </button>
          </div>
        </div>
      );
  }, [refreshingData, refreshComplete, refreshFailed, initialLogin, dispatch, isOffline]);

  return (
    refreshModalIcon && (
      <Modal
        open={open}
        setOpen={setOpen && setOpen}
        icon={refreshModalIcon}
        statusText={statusText}
        modalTextUpper={modalTextUpper}
        modalImageClassName={refreshingData && style.modalImage}
        textBtn={(refreshFailed || isOffline) && 'I’ll try later'}
      >
        {modalInnerBody}
      </Modal>
    )
  );
};

export default RefreshModal;

const titles = [
  {
    render: (
      <>
        <p className={style.fadeInOut}>Oplii downloads this stuff so you can work offline.</p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>All of your task and asset data is stored on this device.</p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>Think of it as your own little data capsule.</p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>
          This means poor or no internet service won’t slow you down.
        </p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>
          It also means you’ll need to submit (sync) completed work.
        </p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>
          Completing a task changes its status to{' '}
          <span className={style.highlightText}>Pending sync</span>.
        </p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>When you have good internet, you should sync your tasks.</p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>
          If you don’t sync, no one will get to see your awesome work.
        </p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>
          Tasks in <span className={style.highlightText}>Synced </span> status have been
          successfully submitted.
        </p>
      </>
    )
  },
  {
    render: (
      <>
        <p className={style.fadeInOut}>
          And that’s it! You can find additional tips in More{' '}
          <Icon name={'arrow-right'} width={'14px'} className={style.titleIcon} /> Help.
        </p>
      </>
    )
  }
];
