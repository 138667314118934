/* eslint-disable no-unused-vars */

import _ from 'lodash';
import SitesSelector from '../../selectors/sites.selector';

export const getParentSiteDetails = async ({ siteId, templatesIds = '', store }) => {
  const { cache } = store;
  const { sitesMap = {}, sites: cacheSites = [] } = cache;

  const parentSite = cacheSites[sitesMap[siteId]] || {};
  const parentSitesChildren = parentSite?.children?.map((childIndex) => {
    return { ...cacheSites[childIndex], children: [] };
  });

  const children = [...(parentSitesChildren || [])].sort(
    (a, b) =>
      (a.distance != null ? a.distance : Infinity) - (b.distance != null ? b.distance : Infinity) ||
      a?.name?.localeCompare(b.name)
  );

  let parentSiteWithWorkOrders = await SitesSelector.getSitesWithAllTasks({
    parentSite: [parentSite || ''],
    templatesIds,
    getDataFromCache: true,
    store
  });

  let childrenSiteWithWorkOrders = await SitesSelector.getSitesWithAllTasks({
    parentSite: children || [],
    templatesIds,
    getDataFromCache: true,
    store
  });
  parentSiteWithWorkOrders =
    _.size(parentSiteWithWorkOrders) > 0 ? parentSiteWithWorkOrders[0] : {};

  return {
    parentSiteWithWorkOrders,
    childrenSiteWithWorkOrders
  };
};

export const getParentSiteData = async ({ siteId, templatesIds = '', store }) => {
  const { cache } = store;
  const { sitesMap, sites: cacheSites = [] } = cache;

  const parentSite = cacheSites[sitesMap[siteId]] || {};

  let parentSiteWithWorkOrders = await SitesSelector.getSitesWithAllTasks({
    parentSite: [parentSite || ''],
    withChild: false,
    templatesIds,
    getDataFromCache: true,
    store
  });

  const siteWithWorkOrders =
    _.size(parentSiteWithWorkOrders) > 0 ? parentSiteWithWorkOrders[0] : {};

  return {
    siteWithWorkOrders
  };
};
