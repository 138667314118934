/* eslint-disable no-unused-vars */

import _ from 'lodash';
import { store } from 'redux/store';
import { v4 as uuidv4 } from 'uuid';

import { decodeToken } from '@utilities/authentication';
import LocalStorage from '@utilities/local-storage.util';
import { toBase64 } from '@components/work-order/services/serializable-non-serializable';
import { convertBase64ToFile } from '@components/work-order/helper';
import { convertToSerializable } from '@components/work-order/services/serializable-non-serializable';
import { inspectionActions } from '@redux/slices/inspection';
import { hseEventActions } from '@redux/slices/hse-events';
import { tasksListsActions } from '@redux/slices/tasks';
import { getPhotosAndDocumentsDataFromSelectedInspection } from '@services/assets.service';
import { getAppDispatch } from './dispatch.util';
import { checkActions } from '@redux/slices/checks';
import { transformPhotoData, transformDocumentData } from 'transformers/work-order';

export const setAssetDataInIndexDB = async ({ assetId, assetFile, updateApp = false }) => {
  const base64AssetFile = updateApp ? assetFile : await toBase64(assetFile);
  const newId = assetId;

  const userId = decodeToken().userId;
  const getCurrentUserAllAssetsIds = (await LocalStorage.getItem(userId)) || [];
  await LocalStorage.setItem(newId, {
    base64AssetFile,
    name: assetFile?.name,
    type: assetFile?.type
  });
  await LocalStorage.setItem(userId, [...getCurrentUserAllAssetsIds, newId]);

  return true;
};

export const getAssetDataBase64InIndexDB = async ({ assetId, file = false }) => {
  const response = await LocalStorage.getItem(assetId);

  if (file && response)
    return convertBase64ToFile(response?.base64AssetFile, response?.name, response?.type);
  else return response?.base64AssetFile;
};

export const deleteAssetInIndexDB = async ({ assetId }) => {
  const userId = decodeToken().userId;
  const getCurrentUserAllAssetsIds = (await LocalStorage.getItem(userId)) || [];
  await LocalStorage.removeItem(assetId);
  const newList = getCurrentUserAllAssetsIds?.filter((x) => x !== assetId);
  await LocalStorage.setItem(userId, newList);
  return true;
};

export const updateAssetFile = async ({ assetId, assetFile }) => {
  const base64AssetFile = await toBase64(assetFile);
  await LocalStorage.setItem(assetId, {
    base64AssetFile,
    name: assetFile?.name,
    type: assetFile?.type
  });
};

export const UpdateAssetData = async () => {
  const dispatch = getAppDispatch();

  const getAllUserPreviousTaskAssets =
    (await LocalStorage.getItem(`asset-${decodeToken().userId}`)) || {};

  const assetsIdsBelongsToUser = (await LocalStorage.getItem(decodeToken().userId)) || [];

  if (!_.isEmpty(getAllUserPreviousTaskAssets)) {
    // user already update the app, we need to update only indexDB
    await moveAssetsIds();
  } else if (!assetsIdsBelongsToUser?.length > 0) {
    const inspectionsList = store.getState().inspection?.inspectionsList;
    const tasksList = store.getState().tasks?.tasksList;
    const syncFailedAssets = store.getState().tasks?.syncFailedAssets;
    const HSEEventList = store.getState().hseEvents?.HSEEventList || [];
    const checksList = store.getState().checks?.checksList || [];

    const pendingSyncAndInProgressTasks = tasksList?.filter(
      (task) => task.status === 'In progress' || task.status === 'Pending sync'
    );
    const pendingSyncAndInProgressInspections = inspectionsList?.filter(
      (ins) => ins.status === 'In progress' || ins.status === 'Pending sync'
    );
    const pendingSyncAndInProgressHSEEvents = HSEEventList?.filter(
      (ins) => ins.status === 'In progress' || ins.status === 'Pending sync'
    );
    const pendingSyncAndInProgressChecks = checksList?.filter(
      (ins) => ins.status === 'In progress' || ins.status === 'Pending sync'
    );

    // Done
    try {
      for (let ins of pendingSyncAndInProgressInspections) {
        const { photos, documents } = getPhotosAndDocumentsDataFromSelectedInspection(
          ins.formData,
          true
        );
        for (let photo of photos) {
          await setAssetDataInIndexDB({
            assetId: photo?.id,
            assetFile: photo?.content,
            updateApp: true
          });
        }
        for (let document of documents) {
          await setAssetDataInIndexDB({
            assetId: document?.id,
            assetFile: document?.content,
            updateApp: true
          });
        }
        const { res } = await convertToSerializable(ins.formData, 'AssignedInspection', true);
        dispatch?.(
          inspectionActions.updateNewInspection({
            inspectionId: ins?.id,
            data: {
              ...ins,
              formData: res
            }
          })
        );
      }
    } catch (error) {
      console.log({ error: 'error for for converting old inspection data to new inspection data' });
    }

    try {
      for (let hse of pendingSyncAndInProgressHSEEvents) {
        const { photos, documents } = getPhotosAndDocumentsDataFromSelectedInspection(
          hse.formData,
          true
        );

        for (let photo of photos) {
          await setAssetDataInIndexDB({
            assetId: photo?.id,
            assetFile: photo?.content,
            updateApp: true
          });
        }
        for (let document of documents) {
          await setAssetDataInIndexDB({
            assetId: document?.id,
            assetFile: document?.content,
            updateApp: true
          });
        }
        const { res } = await convertToSerializable(hse.formData, 'HSE', true);
        dispatch?.(
          hseEventActions.updateNewHSEEvent({
            hseEventId: hse?.id,
            data: {
              ...hse,
              formData: res
            }
          })
        );
      }
    } catch (error) {
      console.log({ error: 'error for for converting old hse event data to new hse event data' });
    }

    try {
      for (let check of pendingSyncAndInProgressChecks) {
        const { photos, documents } = getPhotosAndDocumentsDataFromSelectedInspection(
          check.formData,
          true
        );
        for (let photo of photos) {
          await setAssetDataInIndexDB({
            assetId: photo?.id,
            assetFile: photo?.content,
            updateApp: true
          });
        }
        for (let document of documents) {
          await setAssetDataInIndexDB({
            assetId: document?.id,
            assetFile: document?.content,
            updateApp: true
          });
        }
        const { res } = await convertToSerializable(check.formData, 'AssetCheck', true);
        dispatch?.(
          checkActions.updateNewCheck({
            checkId: check?.id,
            data: {
              ...check,
              formData: res
            }
          })
        );
      }
    } catch (error) {
      console.log({ error: 'error for for converting old checks data to new checks data' });
    }

    try {
      for (let task of pendingSyncAndInProgressTasks) {
        if (task?.workOrderNumber) {
          let { photos, documents } = getPhotosAndDocumentsDataFromSelectedInspection(
            task?.formData || {},
            true
          );

          let taskPhotos = task?.photos || [];
          let taskDocuments = task?.documents || [];

          taskPhotos = taskPhotos?.map((photo) => {
            return { ...photo, id: photo?.id || uuidv4() };
          });
          taskDocuments = taskDocuments?.map((document) => {
            return { ...document, id: document?.id || uuidv4() };
          });

          photos = [...photos, ...taskPhotos];
          documents = [...documents, ...taskDocuments];

          for (let photo of photos) {
            await setAssetDataInIndexDB({
              assetId: photo?.id,
              assetFile: photo?.content,
              updateApp: true
            });
          }
          for (let document of documents) {
            await setAssetDataInIndexDB({
              assetId: document?.id,
              assetFile: document?.content,
              updateApp: true
            });
          }

          const { res } = task.formData
            ? await convertToSerializable(task.formData, 'WorkOrder', true)
            : null;

          const photoData = await transformPhotoData(taskPhotos, 'WorkOrder', true);
          const documentList = await transformDocumentData(taskDocuments, 'WorkOrder', true);

          dispatch?.(
            tasksListsActions.updateWorkOrder(
              {
                ...task,
                photos: photoData,
                documents: documentList,
                formData: task.formData ? res : null
              } || {}
            )
          );
        } else if (task?.newWorkRequest) {
          // new work order and request
          let photos = task?.photos || [];
          let documents = task?.documents || [];

          photos = photos?.map((photo) => {
            return { ...photo, id: photo?.id || uuidv4() };
          });
          documents = documents?.map((document) => {
            return { ...document, id: document?.id || uuidv4() };
          });

          for (let photo of photos) {
            await setAssetDataInIndexDB({
              assetId: photo?.id,
              assetFile: photo?.content,
              updateApp: true
            });
          }
          for (let document of documents) {
            await setAssetDataInIndexDB({
              assetId: document?.id,
              assetFile: document?.content,
              updateApp: true
            });
          }

          const photoData = await transformPhotoData(photos, 'WorkRequest', true);
          const documentList = await transformDocumentData(documents, 'WorkRequest', true);

          dispatch?.(
            tasksListsActions.updateNewWorkOrder({
              id: task?.woId,
              data: { ...task, photos: photoData, documents: documentList }
            })
          );
        } else if (task?.newWorkOrder) {
          let photos = task?.photos || [];
          let documents = task?.documents || [];

          photos = photos?.map((photo) => {
            return { ...photo, id: photo?.id || uuidv4() };
          });
          documents = documents?.map((document) => {
            return { ...document, id: document?.id || uuidv4() };
          });

          for (let photo of photos) {
            await setAssetDataInIndexDB({
              assetId: photo?.id,
              assetFile: photo?.content,
              updateApp: true
            });
          }
          for (let document of documents) {
            await setAssetDataInIndexDB({
              assetId: document?.id,
              assetFile: document?.content,
              updateApp: true
            });
          }

          const photoData = await transformPhotoData(photos, 'WorkOrder', true);
          const documentList = await transformDocumentData(documents, 'WorkOrder', true);
          console.log({ photoData, documentList });

          dispatch?.(
            tasksListsActions.updateNewWorkOrder({
              id: task?.woId,
              data: { ...task, photos: photoData, documents: documentList }
            })
          );
        }
      }
    } catch (error) {
      console.log({ error: 'error for for converting old work order data to new work order data' });
    }

    if (syncFailedAssets?.photos?.length > 0) {
      let updatedPhotos = [];
      try {
        for (let photo of syncFailedAssets.photos) {
          await setAssetDataInIndexDB({
            assetId: photo?.id,
            assetFile: photo?.content,
            updateApp: true
          });
          updatedPhotos.push({
            ...photo,
            content: { id: photo?.id }
          });
        }

        dispatch?.(
          tasksListsActions.setSyncFailedAssets({
            ...syncFailedAssets,
            photos: updatedPhotos
          })
        );
      } catch (error) {
        console.log({
          error: 'error for for converting old failed photos data to new failed photos data'
        });
      }
    }

    if (syncFailedAssets?.documents?.length > 0) {
      let updatedDocuments = [];
      try {
        for (let document of syncFailedAssets.documents) {
          await setAssetDataInIndexDB({
            assetId: document?.id,
            assetFile: document?.content,
            updateApp: true
          });
          updatedDocuments.push({
            ...document,
            content: { id: document?.id }
          });
        }

        dispatch?.(
          tasksListsActions.setSyncFailedAssets({
            ...syncFailedAssets,
            documents: updatedDocuments
          })
        );
      } catch (error) {
        console.log({
          error: 'error for for converting old failed document data to new failed document data'
        });
      }
    }
  } else {
    console.log('already update');
  }

  return true;
};

// (async () => {
//   const getAllUserPreviousTaskAssets =
//     (await LocalStorage.getItem(`asset-${decodeToken().userId}`)) || {};

//   console.log({ getAllUserPreviousTaskAssets });
// })();

const moveAssetsIds = async () => {
  const getAllUserPreviousTaskAssets =
    (await LocalStorage.getItem(`asset-${decodeToken().userId}`)) || {};

  const userId = decodeToken().userId;
  const allAssetKeys = Object.keys(getAllUserPreviousTaskAssets) || [];

  for (let assetKey of allAssetKeys) {
    const data = getAllUserPreviousTaskAssets[assetKey];
    const getCurrentUserAllAssetsIds = (await LocalStorage.getItem(userId)) || [];

    await LocalStorage.setItem(assetKey, {
      base64AssetFile: data?.base64AssetFile,
      name: data?.name,
      type: data?.type
    });
    await LocalStorage.setItem(userId, [...getCurrentUserAllAssetsIds, assetKey]);
  }

  await LocalStorage.removeItem(`asset-${decodeToken().userId}`);
};
