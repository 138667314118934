import React, { memo, forwardRef } from 'react';
import _ from 'lodash';

import HierarchicalDropdown from '@components/hierarchical-dropdown/hierarchical-dropdown';
import Select from '@components/select-box-component';

import style from '../workorder.module.scss';

const FormDropdown = (
  {
    errorsMsg,
    removeSpecialCharactersForName,
    field,
    control,
    name,
    register,
    label,
    watch,
    getValues,
    setValue,
    dropdownMenuMapList
  },
  ref
) => {
  const { refs } = ref;

  const options =
    field?.questionTypeText === 'Dropdown_PL' ||
    field?.questionTypeText === 'InspectionResult' ||
    field?.questionTypeText === 'CheckResult' ||
    field?.questionTypeText === 'Dropdown_PL_Multiple'
      ? dropdownMenuMapList[field?.defaultValue]
      : field?.options?.length
      ? field?.options
      : field?.defaultValue
      ? field?.defaultValue?.split(',')?.map((x) => {
          return {
            label: x,
            value: x
          };
        })
      : [];

  const errorMessage =
    errorsMsg?.message ||
    errorsMsg?.inspectionResultAnswer?.message ||
    errorsMsg?.checkResultAnswer?.message ||
    '';

  const showHierarchicalDropdown =
    (field?.defaultValue === 'AreaList' ||
      field?.defaultValue === 'PipelineAreaList' ||
      field?.SourceProperty === 'Category_Type_SubType') &&
    field?.questionTypeText === 'Dropdown_PL';

  return (
    <div
      className="op-input-field-wrap"
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
    >
      {showHierarchicalDropdown ? (
        <HierarchicalDropdown
          options={options}
          dot={field?.isRequired}
          label={label}
          name={name}
          error={errorMessage}
          disabled={field?.isReadOnly}
          placeholder={field?.placeholderText}
          reconciled={field?.isMasterData}
          showReconciled={
            !_.isEmpty(watch(`${name}-reconcile`)) &&
            field?.isMasterData &&
            options?.length > 0 &&
            watch(`${name}-reconcile`) !== watch(name)
              ? true
              : false
          }
          setValue={setValue}
          defaultValue={getValues(name)}
          equipmentCategoryTypeSubtype={
            field?.SourceProperty === 'Category_Type_SubType' ? true : false
          }
        />
      ) : (
        <Select
          isMultiple={field?.questionTypeText === 'Dropdown_PL_Multiple' ? true : false}
          showValue={field?.SourceProperty === 'Category_Type_SubType' ? true : false}
          dot={field?.isRequired}
          label={label}
          options={options}
          labelColor={style.labelColor}
          control={control}
          name={name}
          error={errorMessage}
          register={register}
          isClearable
          disabled={field?.isReadOnly}
          placeholder={field?.placeholderText}
          reconciled={field?.isMasterData}
          // showReconciled={!_.isEmpty(watch(name)) && options?.length > 0 && field?.isMasterData}
          showReconciled={
            !_.isEmpty(watch(`${name}-reconcile`))
              ? field?.isMasterData &&
                options?.length > 0 &&
                watch(`${name}-reconcile`) !== watch(name)
                ? true
                : false
              : !_.isEmpty(watch(name)) && options?.length > 0 && field?.isMasterData
          }
        />
      )}
    </div>
  );
};

export default memo(forwardRef(FormDropdown));
