/* eslint-disable no-unused-vars */
import React from 'react';

import Icon from '@components/icon-component';
import HighlightText from '@components/highlight-text';

import style from './task-status.module.scss';

const TaskStatusAndPriority = ({ status, highlight, openModal, formWrapper }) => {
  return (
    <div
      className={style.statusDiv}
      style={{
        backgroundColor:
          status === 'To do'
            ? ' #FCCECE'
            : status === 'In progress'
            ? '#F9EDC7'
            : status === 'Pending sync'
            ? ' #CBF1D9'
            : status === 'Synced'
            ? '#F5F7FA'
            : '#ffffff',
        cursor: formWrapper ? 'default' : 'pointer'
      }}
      onClick={(e) => {
        if (status === 'Pending sync') {
          e.stopPropagation();
          openModal();
        }
      }}
    >
      <p className={style.p} style={{ marginRight: status === 'Pending sync' ? '4px' : '0px' }}>
        <HighlightText text={status} highlight={highlight} />
      </p>

      {status === 'Pending sync' && <Icon name="pending-sync" />}
    </div>
  );
};

export default TaskStatusAndPriority;
