/* eslint-disable no-unused-vars */
import style from './checkbox.module.scss';

const Checkbox = ({
  id,
  text,
  handleChange,
  checked,
  name,
  register,
  containerClass,
  onClick,
  checkMarkAfter,
  disabled,
  partial,
  textTransformNone = false,
  labelClassName = '',
  mainClassName = '',
  bigCheckbox = false,
  formWrapper = false,
  ...props
}) => {
  return (
    <div className={`${mainClassName} ${formWrapper && style.formWrapperCheckbox} `}>
      {formWrapper && props?.count && (
        <p style={{ width: 'auto', display: 'flex', minWidth: '32px' }}>
          <span>{props?.count}</span>
          <span>.</span>
        </p>
      )}
      <label
        className={`${disabled && style.disabledContainer} ${style.container} ${labelClassName}`}
        htmlFor={id}
        onClick={onClick}
      >
        {text && (
          <p
            style={{
              textTransform: textTransformNone
                ? 'none'
                : text?.includes('@')
                ? 'lowercase'
                : text?.includes('(s)')
                ? ''
                : 'capitalize'
            }}
          >
            {textTransformNone ? text : text?.includes('@') ? text?.toLowerCase() : text}
          </p>
        )}
        <input
          name={name}
          ref={register}
          type="checkbox"
          id={id}
          onChange={handleChange}
          checked={checked}
          disabled={disabled}
          {...props}
        />

        <span
          className={`${style.checkMark} ${bigCheckbox && style.bigCheckMark}  ${checkMarkAfter}`}
        ></span>
        {partial && <span className={`${style.checkMark1} ${checkMarkAfter}`}></span>}
      </label>
    </div>
  );
};

export default Checkbox;
