import AppBar from '@components/app-bar';
import EquipmentListing from '@components/equipment-listing';

const Listing = () => {
  return (
    <div className="page-container">
      <AppBar />
      <div className="md:ml-24">
        <EquipmentListing Title={'Equipment'} />
      </div>
    </div>
  );
};

export default Listing;
