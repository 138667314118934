if (!Array.prototype.at) {
  Object.defineProperty(Array.prototype, 'at', {
    value: function (index) {
      const positiveIndex = index < 0 ? this.length + index : index;
      return this[positiveIndex];
    },
    writable: true,
    configurable: true
  });
}
