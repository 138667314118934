/* eslint-disable no-unused-vars */
import React, { memo, useState, useEffect } from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';

import Icon from '@components/icon-component';
import OrderCollapseable from './order-collapseable';
import WorkOrderModal from './modal';

import style from './add-work-order-modal.module.scss';
import { deleteNewTasksAssetFromIndexDB } from '@services/assets.service';

const AddWorkOrder = ({
  noHeader = false,
  getValues,
  name,
  setValue,
  watch,
  showInfo = false,
  workOrder,
  workRequest
}) => {
  const [openAddWorkOrder, setOpenAddWorkOrder] = useState(false);
  const [selectedWorkOrderId, setSelectedWorkOrderId] = useState(null);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState({});
  const { workOrderTypeData } = useSelector((state) => state.picklists);
  const [workOrderList, setWorkOrderList] = useState([]);

  useEffect(() => {
    if (watch(name)?.length) {
      setWorkOrderList(watch(name));
    }
  }, [watch(name)]);

  const onSubmitData = (data, updateId, closedModal = false) => {
    if (updateId) {
      const previousPhotosData = getValues(`${name}`) || [];
      const findIndex = previousPhotosData?.findIndex((x) => x.id === updateId);
      previousPhotosData[findIndex] = { ...data, id: updateId };
      setValue(`${name}`, [...previousPhotosData], { shouldDirty: true });
    } else {
      let previousWorkOrderData = getValues(`${name}`) || [];
      setValue(`${name}`, [...previousWorkOrderData, { ...data, id: uuidv4() }], {
        shouldDirty: true
      });
    }
    if (!closedModal) {
      setOpenAddWorkOrder(false);
      setSelectedWorkOrderId(null);
    }
  };

  const onEditDoc = (id) => {
    const copyDocument = getValues(`${name}`) || [];
    const document = copyDocument?.find((doc) => doc.id === id);
    setSelectedWorkOrderId(id);
    setSelectedWorkOrder({ ...document });
    setTimeout(() => {
      setOpenAddWorkOrder(true);
    }, 1000);
  };

  const deleteWorkOrder = async (workOrderId) => {
    return new Promise((resolve, reject) => {
      const handleAsyncFunction = async () => {
        try {
          const copy = getValues(`${name}`) || [];
          const currentTask = copy?.find((task) => task.id === workOrderId);
          const photos = currentTask?.photo || [];
          const documents = currentTask?.document || [];
          await deleteNewTasksAssetFromIndexDB({ photos, documents, deleteAll: true });
          const newDocumentList = copy?.filter((x) => x.id !== workOrderId);
          setValue(`${name}`, newDocumentList, { shouldDirty: true });
          setWorkOrderList(newDocumentList);
          setSelectedWorkOrderId(null);
          setSelectedWorkOrder({});
          resolve(true);
        } catch (error) {
          reject(error);
        }
      };

      handleAsyncFunction();
    });
  };

  return (
    <div className={style.container}>
      {showInfo && (
        <div className={style.help}>
          <Icon name={'help'} className={style.icon} />

          <p>
            Any previously created work {workOrder ? 'order' : workRequest ? 'request' : ''} will
            appear on the ‘Tasks, records’ page.
          </p>
        </div>
      )}

      {!noHeader && (
        <section className={style.workRequestSection}>
          Work {workOrder ? 'order' : workRequest ? 'request' : ''}
        </section>
      )}

      {workOrderList?.map((doc) => {
        return (
          <OrderCollapseable
            key={doc?.id}
            id={doc.id}
            title={`Work ${workOrder ? 'order' : workRequest ? 'request' : ''}`}
            subTitle={doc?.title}
            onEditDoc={onEditDoc}
            deleteWorkOrder={deleteWorkOrder}
          >
            {doc?.title && (
              <>
                <div className={style.section1}>
                  <p className={style.p}>TITLE</p>
                  <p className={style.p1}>{doc?.title}</p>
                </div>
              </>
            )}
            {doc?.title && (
              <>
                <div className={style.section1}>
                  <p className={style.p}>TYPE</p>
                  <p>{workOrderTypeData.find(({ key }) => doc?.type === key).value}</p>
                </div>
              </>
            )}
          </OrderCollapseable>
        );
      })}

      <div
        className={style.addBtn}
        style={{
          ...(noHeader && { padding: '0px' })
        }}
      >
        <Icon
          name={'addWorkRequest'}
          className={style.iconWidthAndHeight}
          onClick={() => setOpenAddWorkOrder((prev) => !prev)}
        />
        <p onClick={() => setOpenAddWorkOrder((prev) => !prev)}>
          Add work {workOrder ? 'order' : workRequest ? 'request' : ''}
        </p>
      </div>

      {openAddWorkOrder && (
        <WorkOrderModal
          open={openAddWorkOrder}
          title={`${selectedWorkOrderId ? 'Edit' : 'Add'} work ${
            workOrder ? 'order' : workRequest ? 'request' : ''
          }`}
          closed={(value) => {
            setOpenAddWorkOrder(false);
            setSelectedWorkOrderId(null);
            setSelectedWorkOrder(null);
          }}
          onSubmitData={onSubmitData}
          selectedWorkOrder={selectedWorkOrder}
          updateId={selectedWorkOrderId}
          deleteWorkOrderEvent={deleteWorkOrder}
          workRequest={workRequest}
          workOrder={workOrder}
          name={name}
        />
      )}
    </div>
  );
};

export default memo(AddWorkOrder);
