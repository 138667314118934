/* eslint-disable  no-unused-vars */
import React, { useState, useMemo } from 'react';
import useWindowDimensions from '@hooks/useWindowDimensions';

import Collapseable from '@components/collapseable';
import DeleteModal from '@components/modal/variants/delete-modal';

const OrderCollapseable = ({ title, subTitle, onEditDoc, id, children, deleteWorkOrder }) => {
  const [deleteDoc, setDeleteDoc] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { width } = useWindowDimensions();
  const open = width > 767 ? true : false;

  const formWrapperWIdth = document.getElementById('formWrapperContainer')?.clientWidth || 0;
  const headerWidth = useMemo(() => {
    return width > 500 ? Math.floor(formWrapperWIdth) - 32 - 16 : formWrapperWIdth - 32;
  }, [width, formWrapperWIdth]);

  const handleDeleteEvent = async () => {
    setLoadingDelete(true);
    await deleteWorkOrder(id);
    setDeleteDoc(false);
    setLoadingDelete(false);
  };
  return (
    <>
      <div
        className={`op-filter-collapse ${open ? 'open' : ''}`}
        style={{
          backgroundColor: 'white',
          padding: '0px 0px',
          width: `100%`,
          minWidth: `${headerWidth}px`
        }}
      >
        <Collapseable
          title={title}
          subTitle={subTitle}
          onDelete={() => setDeleteDoc(true)}
          onEdit={() => {
            onEditDoc(id);
          }}
          showEllipseOnTitleSecond
        >
          {children}
        </Collapseable>
      </div>

      <DeleteModal
        open={deleteDoc}
        setOpen={loadingDelete ? () => {} : setDeleteDoc}
        handleDelete={handleDeleteEvent}
        confirmDeletion
        recordType="work order"
        disabledDeleteButton={loadingDelete}
      />
    </>
  );
};

export default OrderCollapseable;
