/* eslint-disable no-unused-vars */

import React from 'react';

import PageHeader from '@components/page-header';

const SearchPageHeader = ({
  searchPage,
  checkedLength,
  data,
  setData,
  setInputValue,
  setSearchPage,
  checkedLengthOfRtsTasksAndSites = [],
  checkedLengthOfRunsTasksAndSites = [],
  goBackRt,
  gotBackRun,
  goBack
}) => {
  const checked = React.useMemo(() => {
    return data.every(({ type, status, checked }) =>
      type !== 'Check' && status === 'To do' ? checked : true
    );
  }, [data]);

  const checkbox = React.useMemo(() => {
    if (checkedLength === 0) return false;
    for (const el of data) if (el.status === 'To do') return true;

    return false;
  }, [data, checkedLength]);

  const onChangeEvent = React.useCallback(
    (checked) => {
      const copy = [...data];
      for (let i = 0; i < copy.length; i++)
        if (copy[i].type !== 'Check' && copy[i].status === 'To do') copy[i].checked = checked;
      setData(copy);
    },
    [checked, setData, data]
  );

  const onClickPageHeaderEvent = React.useCallback(() => {
    if (checkedLengthOfRtsTasksAndSites > 0) goBackRt();
    else if (checkedLengthOfRunsTasksAndSites) gotBackRun();
    else {
      if (searchPage) {
        setInputValue('');
        setSearchPage(false);
      }

      const copy = [...data];
      for (let i = 0; i < copy.length; i++) if (copy[i].status === 'To do') copy[i].checked = false;
      setData(copy);
    }
  }, [
    data,
    searchPage,
    setInputValue,
    setSearchPage,
    checkedLengthOfRtsTasksAndSites,
    goBackRt,
    gotBackRun,
    checkedLengthOfRunsTasksAndSites
  ]);
  return (
    <PageHeader
      title="Search"
      disableBack={searchPage}
      checkedLength={checkedLength}
      checkedLengthOfRtsTasksAndSites={checkedLengthOfRtsTasksAndSites}
      checkedLengthOfRunsTasksAndSites={checkedLengthOfRunsTasksAndSites}
      checked={checked}
      checkbox={checkbox}
      onChange={onChangeEvent}
      onClick={() => {
        goBack();
        onClickPageHeaderEvent();
      }}
    />
  );
};

export default React.memo(SearchPageHeader);
