import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  structure: [],
  structureMap: {},
  structureMobile: [],
  structureMobileMap: {},
  loading: false
};

export const structureSlice = createSlice({
  name: 'structure',
  initialState,
  reducers: {
    setStructure: (state, action) => {
      state.structure = action.payload;
      state.structureMap = action.payload.reduce((acc, curr) => {
        acc[curr.structureId] = curr;
        return acc;
      }, {});
    },
    setStructureMobile: (state, action) => {
      const { structures, structuresMap, sitesStructureMap } = structureMobileData(
        action.payload || []
      );
      state.structureMobile = action.payload;
      state.structure = structures;
      state.sitesStructuresMap = sitesStructureMap;
      state.structuresMap = structuresMap;
      state.structureMobileMap = action.payload.reduce((acc, curr, currIndex) => {
        acc[curr.structureId] = currIndex;
        return acc;
      }, {});
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

const structureReducer = structureSlice.reducer;

export const structureActions = structureSlice.actions;
export default structureReducer;

const structureMobileData = (structurePayloadData = []) => {
  const arr = [];
  const sitesStructureMap = {};
  const structures = structurePayloadData;

  const structuresMap = structures.reduce((acc, curr, currIndex) => {
    const { siteId, structureId } = curr;
    if (!sitesStructureMap[siteId]) sitesStructureMap[siteId] = [];
    sitesStructureMap[siteId].push(structureId);
    acc[structureId] = currIndex;
    return acc;
  }, {});

  for (const key in structuresMap) {
    const index = structuresMap[key];
    let data = { ...structurePayloadData[(structuresMap[key], index)] };
    arr.push(data);
  }

  return {
    structures: arr,
    structuresMap,
    sitesStructureMap
  };
};
