import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  userId: null,
  clientId: null,
  loading: false,
  retryingRegistration: null,
  tokenExpiry: null,
  emailAddress: null,
  password: null
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {},
    setUser: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        const { token, userId, clientId, tokenExpiry, emailAddress, password } = action.payload;
        state.user = token;
        state.userId = userId;
        state.clientId = clientId;
        state.tokenExpiry = tokenExpiry;
        state.emailAddress = emailAddress;
        state.password = password;
      }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setRetryingRegistration: (state, action) => {
      state.retryingRegistration = action.payload;
    }
  }
});

const authReducer = authSlice.reducer;

export const authActions = authSlice.actions;
export default authReducer;
