import React from 'react';

import Icon from '@components/icon-component';

const ListCards = ({ list, onClick, selected }) => {
  return (
    <>
      {list.map((item, index) => (
        <div key={index} onClick={() => onClick(item)} className={`help--card`}>
          <div className="help--card--title">{item}</div>
          <div>
            <Icon name={'chevron-right'} width="10px" />
          </div>
          {((!selected && index === 0) || selected === item) && <div className="selected"></div>}
        </div>
      ))}
    </>
  );
};

export default ListCards;
