/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import TaskRecordListTabs from '../tasks-records-list/task-record-list-tabs';

const TaskTemplateRecordList = ({
  tasks = [],
  tasksMap,
  SelectedFilter,
  isFilterActive,
  searched,
  onSelectionNewWorkOrderOrRequest,
  loading,
  lastElement,
  taskTemplateRecords,
  setData,
  openModal,
  setSelectedOrderIds
}) => {
  const [tab, setTab] = useState('0');

  return (
    <TaskRecordListTabs
      tab={tab}
      setTab={setTab}
      tasks={tasks || []}
      data={tasks}
      tasksMap={tasksMap || {}}
      SelectedFilter={SelectedFilter}
      isFilterActive={isFilterActive}
      searched={searched}
      openModal={openModal}
      setData={setData}
      onSelectionNewWorkOrderOrRequest={onSelectionNewWorkOrderOrRequest}
      white={loading ? false : location.pathname === '/task-records' ? true : false}
      lastElement={lastElement}
      taskTemplateRecords={taskTemplateRecords}
      setSelectedOrderIds={setSelectedOrderIds}
    />
  );
};

export default TaskTemplateRecordList;
