import React, { useEffect } from 'react';

import Icon from '@components/icon-component';

import style from './refresh.module.scss';

const RefreshingData = ({ status, count, goToSyncPage }) => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className={style.bgColor}>
      <div className={style.seeCard}>
        <h6 className={style.titleClass}>Refreshing data is key.</h6>
        <div className={style.para}>
          <p className={style.p}>
            When you refresh your data, Oplii Mobile downloads the latest task and asset information
            to your device, which allows you to work offline in remote areas.{' '}
          </p>
          <div className={style.iconDiv}>
            <div className={style.iconCircle} style={{ borderColor: '#2F80ED' }}>
              <Icon name={'newOnline'} height={20} width={20} className={style.roundIcon} />
            </div>
            {count && (
              <span
                className={style.count}
                style={{
                  background: '#2F80ED'
                }}
              >
                {count}
              </span>
            )}
            {status && <p className={style.title}>Online</p>}
          </div>
        </div>
        <p className={style.p} style={{ marginTop: '16px' }}>
          In other words:
        </p>
        <p className={style.redClass}>
          Refresh data before heading to a remote work location without internet access.
        </p>
        <h6 className={style.titleClass}>How?</h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          On a phone, go to <span style={{ fontWeight: 700 }}>Home</span> and hit the sync indicator
          button, shown above. On the popup, select{' '}
          <span style={{ fontWeight: 700 }}>Refresh data.</span>
        </p>
        <p className={style.p} style={{ marginTop: '8px' }}>
          (On a tablet, you can do this from any screen because the button is on the navigation
          bar.)
        </p>
        <h6 className={style.sync}>Syncing also refreshes your data</h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          When you{' '}
          <span
            className="link"
            onClick={() => {
              goToSyncPage();
            }}
          >
            sync tasks and records
          </span>{' '}
          , your data is automatically refreshed.
        </p>
      </div>
    </div>
  );
};

export default RefreshingData;
