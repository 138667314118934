/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import Compressor from 'compressorjs';
import _ from 'lodash';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Webcam from 'react-webcam';
import heic2any from 'heic2any';
import { yupResolver } from '@hookform/resolvers/yup';

import InputField from '@components/input-field';
import Select from '@components/select-box-component';
import Button from '@components/button';
import Icon from '@components/icon-component';
import DeleteModal from '@components/modal/variants/delete-modal';
import Portal from '@components/portal';
import LoadingRound from '@components/loading-round/loading-round';

import { convertBase64ToFile, compressImage, bytesToMB } from '@utilities/attachments';
import { MAX_FILE_UPLOAD } from '@utilities/app-urls';
import useWindowDimensions from '@hooks/useWindowDimensions';
import isMobile from '@hooks/isMobile';
import { useToastContext } from '@context/toaster.context';

import './add-photo.scss';
import style from './style.module.scss';
import { getAssetDataBase64InIndexDB } from '@utilities/photos-documents.util';

const AddPhoto = ({
  open,
  closed,
  title,
  onSubmitPhoto,
  updatePhoto = {},
  id,
  initialValue,
  deleteBtnEvent
}) => {
  const { ToasterUtil } = useToastContext();
  const { width } = useWindowDimensions();
  const [deletePic, setDeletePic] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [loadingDelete, setLoadingDelete] = useState(false);

  const webcamRef = useRef(null);

  const [preview, setPreview] = useState();
  const [editPhoto, setEditPhoto] = useState(_.isEmpty(updatePhoto) ? false : true);
  const [openWebCamera, setOpenWebCamera] = useState(false);
  const [mediaStream, setMediaStream] = useState({});
  const [loading, setLoading] = useState(false);
  const { photoCategoryData } = useSelector((state) => state.picklists);

  const { register, control, handleSubmit, setValue, watch, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      category: initialValue?.category || '',
      caption: initialValue?.caption || '',
      image: initialValue?.image || null
    }
  });

  useEffect(() => {
    const event = async () => {
      if (!_.isEmpty(initialValue)) {
        const fileFromIndexDB = await getAssetDataBase64InIndexDB({
          assetId: initialValue?.image?.id,
          file: true
        });
        setValue('image', initialValue?.image || null);
        setValue('category', initialValue?.category || null);
        setValue('caption', initialValue?.caption || null);
        setSelectedFile(fileFromIndexDB || null);
      }
    };
    event();
  }, [initialValue]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const deleteImage = () => {
    setValue('image', null);
    setSelectedFile('');
  };

  const onSubmitHandler = (data) => {
    onSubmitPhoto(data, id);
  };

  const onTest = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmitHandler)(event);
  };

  const closedModal = () => {
    reset();
    setValue('caption', '');
    setValue('category', '');
    setValue('image', null);
    closed(false);
  };

  const captureMobilePhoto = async (event) => {
    event.preventDefault();
    const accept = event.target.accept;
    let fileUploaded = event.target.files[0];
    const allowedMimeTypes = accept.split(';');
    if (bytesToMB(fileUploaded?.size, MAX_FILE_UPLOAD)) {
      ToasterUtil.showError(`File limit exceeded`);
      return;
    }
    //  else if (
    //   !allowedMimeTypes.some(
    //     (mimeType) =>
    //       fileUploaded.type === mimeType ||
    //       (mimeType.endsWith('/*') && fileUploaded.type.startsWith(mimeType.slice(0, -1)))
    //   )
    // ) {
    //   return;
    // }

    if (
      fileUploaded?.type === 'image/heic' ||
      fileUploaded?.type === 'image/heif' ||
      fileUploaded?.name?.split('.')?.pop() === 'heic' ||
      fileUploaded?.name?.split('.')?.pop() === 'heif'
    ) {
      try {
        setLoading(true);
        const jpegBlob = await heic2any({
          blob: fileUploaded,
          toType: 'image/jpeg'
        });
        fileUploaded = new File([jpegBlob], fileUploaded.name.replace(/\.[^.]+$/, '.jpg'), {
          type: 'image/jpeg'
        });
      } catch (error) {
        console.error('Error converting HEIC to JPEG:', error);
      }
    }

    const sizeInMbs = parseInt(fileUploaded?.size / (1024 * 1024));
    if (sizeInMbs > 1) {
      try {
        setLoading(true);
        const compressSize = sizeInMbs > 1 && sizeInMbs <= 5 ? 0.5 : 0.3;
        const data = await compressImage({ uploadedFile: fileUploaded, compressSize });
        saveImage({ fileUploaded: data });
        setLoading(false);
      } catch (err) {
        ToasterUtil.showError(`Error for uploading an image`);
      }
    } else {
      saveImage({ fileUploaded });
      setLoading(false);
    }
  };

  const saveImage = ({ fileUploaded }) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onload = () => {
      const base64 = reader.result;
      const filename = 'captureImage.png';
      const file = convertBase64ToFile(base64, filename);
      setSelectedFile(file);
      setValue('image', file);
    };
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const filename = 'captureImage.png';
    const file = convertBase64ToFile(imageSrc, filename);
    setSelectedFile(file);
    setValue('image', file);
  }, [webcamRef]);

  const WebcamComponent = () => (
    <div
      style={{
        marginTop: '12px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          width: width >= 700 ? '700px' : `${width - 32}px`,
          maxHeight: 520,
          position: 'relative'
        }}
      >
        <Webcam
          audio={false}
          style={{
            maxHeight: 520
          }}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          width={width}
          videoConstraints={{
            width: width,
            maxHeight: 520,
            facingMode: 'user'
          }}
          wrap="div"
        />

        <Icon
          name={'delete-image-icon'}
          alt=""
          className={'positionIcon'}
          onClick={closeCameraEvent}
          style={{ position: 'absolute', cursor: 'pointer', top: 0, right: 0, zIndex: 9 }}
        />
      </div>
      <div
        style={{
          marginTop: '32px'
        }}
      >
        <Button onClick={capture} text={'Capture photo'} className="addPhotoBtn" />
      </div>
    </div>
  );
  const openCameraEvent = async () => {
    const stream = await navigator?.mediaDevices?.getUserMedia({ video: true });
    if (stream?.active) {
      setOpenWebCamera(true);
      setMediaStream(stream);
    }
  };
  const closeCameraEvent = async () => {
    setOpenWebCamera(false);
  };

  useEffect(() => {
    return () => {
      if (mediaStream?.active) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [mediaStream, openWebCamera]);

  const handleDeleteEvent = async () => {
    setLoadingDelete(true);
    await deleteBtnEvent(initialValue?.id, initialValue?.image?.id);
    setDeletePic(false);
    setLoadingDelete(false);
    closedModal();
  };

  return (
    <Portal>
      <form onSubmit={onTest} id="addPhoto">
        <div className={open ? style.equipmentMain : style.equipmentNone}>
          <>
            <div className={`${style.header} px-4 md:px-8 py-7`}>
              <p className={style.cancelClass} onClick={() => closedModal()}>
                Cancel
              </p>
              <h1 className={` ${style.addClass} h1-semibold`}>{title}</h1>
              <div className={style.invisibleDiv}>Cancel</div>
            </div>

            <div className={style.photoContentBody}>
              <input
                type="file"
                id="add-photo"
                // accept="image/*;capture=camera"
                accept="image/*,.heic,.heif,capture=camera"
                onChange={(e) => {
                  captureMobilePhoto(e);
                }}
                style={{ display: 'none' }}
              />

              {selectedFile ? (
                <div className={style.photoForm}>
                  <div className={style.selectedImage}>
                    <img src={preview} className={style.imgIcon} />

                    <Icon
                      name={'delete-image-icon'}
                      alt=""
                      className={style.positionIcon}
                      onClick={deleteImage}
                    />
                  </div>
                </div>
              ) : openWebCamera ? (
                WebcamComponent()
              ) : loading ? (
                <LoadingRound className={style.loaderHeight} />
              ) : (
                <>
                  <div className={style.photoBtnGrid}>
                    {!(isMobile.any() || (isMobile.ipadPro && width <= 1024)) && (
                      <Button
                        iconStart={<Icon name={'camera'} height={16} width={16} />}
                        onClick={openCameraEvent}
                        variant={'pill'}
                        text={'Open camera'}
                        className={style.addPhotoBtn}
                      />
                    )}
                    <label
                      htmlFor="add-photo"
                      className={style.addDocumentBtn}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon name={'image'} height={16} width={16} /> ATTACH A PHOTO
                    </label>
                  </div>
                </>
              )}

              <div className={style.photoGrid}>
                <div className="op-input-field-wrap ">
                  <Select
                    label="Category"
                    options={photoCategoryData?.map((x) => {
                      return {
                        label: x?.value,
                        value: x?.key
                      };
                    })}
                    register={register}
                    name="category"
                    control={control}
                    isClearable
                  />
                </div>
                <div className="op-input-field-wrap ">
                  <InputField
                    label="Caption"
                    type="text"
                    inputClass="input-field input-regular"
                    register={register}
                    name="caption"
                    onInputFocus={(e) => {
                      const element = e.target;
                      element.classList.add('keyboard');
                    }}
                    onInputBlur={(e) => {
                      const element = e.target;
                      element.classList.remove('keyboard');
                    }}
                    limit={250}
                  />
                  <p className="label-bold text-right text-grey-400 mt-1">
                    {watch('caption')?.length || 0}/250
                  </p>
                </div>
              </div>
            </div>
          </>
          <div className="footerBtn">
            {editPhoto && (
              <Icon
                name={'Delete button'}
                alt=""
                style={{ marginRight: editPhoto ? '48px' : '0px' }}
                onClick={() => {
                  setDeletePic(true);
                }}
              />
            )}

            <Button
              btnClass="btn-fill button-large"
              type="submit"
              form="addPhoto"
              text="SAVE"
              disabled={_.isEmpty(watch('image')?.name) ? true : false}
            />
          </div>
        </div>
      </form>

      <DeleteModal
        open={deletePic}
        handleDelete={handleDeleteEvent}
        setOpen={loadingDelete ? () => {} : setDeletePic}
        confirmDeletion
        recordType="photo"
        disabledDeleteButton={loadingDelete}
      />
    </Portal>
  );
};
export default AddPhoto;

const schema = yup.object().shape({
  category: yup.string().nullable(),
  caption: yup.string().nullable(),
  image: yup.mixed().required('A file is required')
});
