/* eslint-disable no-unused-vars */

import _ from 'lodash';
import React, { useState } from 'react';

import BookMarkModal from '@components/modal/variants/bookmark-modal';
import InputField from '@components/input-field/input-field.component';
import Checkbox from '@components/checkbox/checkbox.component';

import style from './style.module.scss';

const EditBookmarkModal = ({
  open,
  setOpen,
  placeholder = 'Flux capacitor repairs',
  name = '',
  atAGlance = false,
  onRename
}) => {
  const [bookmarkName, setBookmarkName] = useState(name);
  const [pinToGlance, setPinToGlance] = useState(atAGlance);

  const onSubmit = async () => {
    onRename({
      name: bookmarkName,
      atAGlance: pinToGlance
    });
    resetValue();
  };

  const resetValue = () => {
    setOpen(false);
    setBookmarkName('');
    setPinToGlance(false);
  };

  return (
    <BookMarkModal
      open={open}
      setOpen={() => {
        resetValue();
      }}
      onSubmit={onSubmit}
      statusText={'Name that bookmark.'}
      modalTextUpper={'Give your bookmark a short, descriptive name.'}
      disabledBtn={_.isEmpty(bookmarkName)}
      modalTextLower={() => (
        <>
          <InputField
            placeholder={placeholder}
            limit={40}
            onChange={(e) => {
              const value = e?.target?.value;
              if (value.length === 0 || (value.length >= 1 && value[0] !== ' ')) {
                setBookmarkName(value);
              }
            }}
            value={bookmarkName}
            defaultValue={bookmarkName}
          />
          <p className="label-bold text-right text-grey-400 mt-1">{bookmarkName?.length || 0}/40</p>
        </>
      )}
      progressData={() => (
        <div className={style.pinToGlanceText}>
          <Checkbox
            text={'Pin to ‘At a glance’ on Home'}
            checked={pinToGlance}
            handleChange={(e) => setPinToGlance(e?.target?.checked)}
            textTransformNone
            labelClassName={style.siteBookmarkLabel}
            checkMarkAfter={style.siteBookmarkCheckbox}
          />
        </div>
      )}
      textBtn={'NEVERMIND'}
    />
  );
};

export default React.memo(EditBookmarkModal);
