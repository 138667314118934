/* eslint-disable no-unused-vars */

import React from 'react';
import { useMemo } from 'react';
import AboutOplii from './about-oplii';
import BookmarksHelp from './bookmarks';
import CompleteTask from './complete-task';
import ContactSupport from './contact-support';
import CreateNewTask from './crete-new-task';
import ChangeDisplaySetting from './display-settings';
import FindingTaskAsset from './find-task-asset';
import RefreshingData from './refreshing-data';
import SyncTasks from './sync-tasks';
import WhatCanISee from './what-can-see';
import WhatsOpliiMobile from './whats-oplii-mobile';
import ListCards from './list-cards';
import { HELPS, TIPS_AND_GUIDES } from '.';
import ResetAppData from './reset-app-data';

const HelpListItemsDetails = ({
  success,
  selectedSetting,
  selectedTipAndGuides,
  onScrollEvent,
  goToCanISeePage,
  goToRefreshingPage,
  goToSyncPage,
  goToContactSupport,
  width,
  onClickHelpsEvent,
  onClickTipsAndGuideEvent
}) => {
  const backGroundColor = useMemo(() => {
    return selectedSetting === 'About Oplii' ||
      selectedSetting === 'What can I see?' ||
      selectedTipAndGuides === 'What’s Oplii Mobile?' ||
      selectedTipAndGuides === 'Refreshing data' ||
      selectedTipAndGuides === 'Syncing tasks' ||
      selectedTipAndGuides === 'Completing a task' ||
      selectedTipAndGuides === 'Creating a new task' ||
      selectedTipAndGuides === 'Finding a task or asset' ||
      selectedTipAndGuides === 'Changing display settings' ||
      selectedTipAndGuides === 'Bookmarks' ||
      selectedSetting === 'Reset app data' ||
      (!success && (!selectedSetting || selectedSetting === 'Contact support'))
      ? 'white'
      : '';
  }, [selectedSetting, selectedTipAndGuides, success]);

  return (
    <div
      className={`page-listing ${success ? 'no-header' : ''} removeScrollbar`}
      style={{
        background: backGroundColor
      }}
      id="helpContainer"
      onScroll={onScrollEvent}
    >
      <div className="help">
        {width < 767 && !selectedSetting ? (
          <ListCards list={HELPS} selected={selectedSetting} onClick={onClickHelpsEvent} />
        ) : width < 767 && selectedSetting === 'Contact support' ? (
          <ContactSupport />
        ) : width < 767 && selectedSetting === 'Tips and guides' && !selectedTipAndGuides ? (
          <ListCards
            list={TIPS_AND_GUIDES}
            selected={selectedTipAndGuides}
            onClick={onClickTipsAndGuideEvent}
          />
        ) : !selectedSetting || selectedSetting === 'Contact support' ? (
          <ContactSupport />
        ) : selectedSetting === 'What can I see?' ? (
          <WhatCanISee />
        ) : selectedSetting === 'Reset app data' ? (
          <ResetAppData />
        ) : selectedSetting === 'About Oplii' ? (
          <AboutOplii />
        ) : selectedTipAndGuides === 'What’s Oplii Mobile?' ? (
          <WhatsOpliiMobile
            goToCanISeePage={goToCanISeePage}
            goToRefreshingPage={goToRefreshingPage}
            goToSyncPage={goToSyncPage}
          />
        ) : selectedTipAndGuides === 'Refreshing data' ? (
          <RefreshingData count={'3'} status="offline" goToSyncPage={goToSyncPage} />
        ) : selectedTipAndGuides === 'Syncing tasks' ? (
          <SyncTasks count={'3'} status="offline" />
        ) : selectedTipAndGuides === 'Completing a task' ? (
          <CompleteTask goToSyncPage={goToSyncPage} />
        ) : selectedTipAndGuides === 'Creating a new task' ? (
          <CreateNewTask />
        ) : selectedTipAndGuides === 'Finding a task or asset' ? (
          <FindingTaskAsset
            goToCanISeePage={goToCanISeePage}
            goToRefreshingPage={goToRefreshingPage}
            goToContactSupport={goToContactSupport}
          />
        ) : selectedTipAndGuides === 'Changing display settings' ? (
          <ChangeDisplaySetting />
        ) : selectedTipAndGuides === 'Bookmarks' ? (
          <BookmarksHelp />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default HelpListItemsDetails;
