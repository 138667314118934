import React from 'react';
import ListCards from './list-cards';
import { HELPS, TIPS_AND_GUIDES } from '.';

const HelpListItems = ({
  success,
  onScrollEvent,
  selectedSetting,
  selectedTipAndGuides,
  onClickTipsAndGuideEvent,
  onClickHelpsEvent
}) => {
  return (
    <div
      style={{ maxWidth: '325px', borderRight: '0.5px solid #CBD2D9' }}
      className={`page-listing show-selected ${success ? 'no-header' : ''} removeScrollbar`}
      id="helpContainer"
      onScroll={onScrollEvent}
    >
      <div className="help">
        {selectedSetting === 'Tips and guides' ? (
          <ListCards
            selected={selectedTipAndGuides}
            list={TIPS_AND_GUIDES}
            onClick={onClickTipsAndGuideEvent}
          />
        ) : (
          <ListCards list={HELPS} selected={selectedSetting} onClick={onClickHelpsEvent} />
        )}
      </div>
    </div>
  );
};

export default HelpListItems;
