/* eslint-disable no-unused-vars */

import { store } from '@redux/store';

export const equipmentAssociatedOrNewPsvsTransformer = ({ formDataPSVs, equipmentId }) => {
  const { cache } = store.getState();

  const { equipmentsMap = {}, equipments: cacheEquipments = [] } = cache;
  const equipmentAssociatedPsvs = [];
  for (let item of formDataPSVs) {
    const currentEquipment = !(item?.isNew || false)
      ? cacheEquipments[equipmentsMap[item?.equipmentId]]
      : {};
    const object = {
      pvId: equipmentId,
      ...(!item?.isNew && item?.equipmentId && { psvId: item?.equipmentId }),
      isDetached: item?.isDetached || false,
      ...(!item?.isNew && item?.equipmentNumber && { equipmentNumber: item?.equipmentNumber }),

      ...(item?.name && { name: item?.name }),
      nameChanged:
        item?.isNew && item?.name ? true : item?.name !== currentEquipment?.name ? true : false,

      ...(item?.status && { status: item?.status }),
      statusChanged:
        item?.isNew && item?.status
          ? true
          : item?.status !== currentEquipment?.status
          ? true
          : false,

      ...(item?.equipmentCategory && { equipmentCategory: item?.equipmentCategory }),
      equipmentCategoryChanged:
        item?.isNew && item?.equipmentCategory
          ? true
          : item?.equipmentCategory !== currentEquipment?.equipmentCategory
          ? true
          : false,

      ...(item?.equipmentType && { equipmentType: item?.equipmentType }),
      equipmentTypeChanged:
        item?.isNew && item?.equipmentType
          ? true
          : item?.equipmentType !== currentEquipment?.equipmentType
          ? true
          : false,

      ...(item?.equipmentSubType && { equipmentSubType: item?.equipmentSubType }),

      equipmentSubTypeChanged:
        item?.isNew && item?.equipmentSubType
          ? true
          : (item?.equipmentSubType || '') !== (currentEquipment?.equipmentSubType || '')
          ? true
          : false,

      ...(item?.valveLocation && { valveLocation: item?.valveLocation }),

      valveLocationChanged:
        item?.isNew && item?.valveLocation
          ? true
          : (item?.valveLocation || '') !== (currentEquipment?.valveLocation || '')
          ? true
          : false,

      pressure: item?.pressure?.textBoxValue || null,
      ...(item?.pressure?.textBoxUnitValue && {
        pressureMeasurement: item?.pressure?.textBoxUnitValue
      }),

      pressureChanged:
        item?.isNew && item?.pressure?.textBoxValue
          ? true
          : (item?.pressure?.textBoxValue || '') !== (currentEquipment?.pressure || '')
          ? true
          : false,
      pressureMeasurementChanged:
        item?.isNew && item?.pressure?.textBoxUnitValue
          ? true
          : (item?.pressure?.textBoxUnitValue || '') !==
            (currentEquipment?.pressureMeasurement || '')
          ? true
          : false,

      ...(item?.manufacturer && { manufacturer: item?.manufacturer }),
      manufacturerChanged:
        item?.isNew && item?.manufacturer
          ? true
          : (item?.manufacturer || '') !== (currentEquipment?.manufacturer || '')
          ? true
          : false,

      ...(item?.model && { model: item?.model }),
      modelChanged:
        item?.isNew && item?.model
          ? true
          : (item?.model || '') !== (currentEquipment?.model || '')
          ? true
          : false,

      ...(item?.serialNumber && { serialNumber: item?.serialNumber }),
      serialNumberChanged:
        item?.isNew && item?.serialNumber
          ? true
          : (item?.serialNumber || '') !== (currentEquipment?.serialNumber || '')
          ? true
          : false,

      ...(item?.fieldTagNumber && { fieldTagNumber: item?.fieldTagNumber }),
      fieldTagNumberChanged:
        item?.isNew && item?.fieldTagNumber
          ? true
          : (item?.fieldTagNumber || '') !== (currentEquipment?.fieldTagNumber || '')
          ? true
          : false,

      ...(item?.crnNumber && { crnNumber: item?.crnNumber }),

      crnNumberChanged:
        item?.isNew && item?.crnNumber
          ? true
          : (item?.crnNumber || '') !== (currentEquipment?.crnNumber || '')
          ? true
          : false,

      psvCapacity: item?.psvCapacity?.textBoxValue || null,
      ...(item?.psvCapacity?.textBoxUnitValue && {
        psvCapacityMeasurement: item?.psvCapacity?.textBoxUnitValue
      }),
      psvCapacityChanged:
        item?.isNew && item?.psvCapacity?.textBoxValue
          ? true
          : (item?.psvCapacity?.textBoxValue || '') !== (currentEquipment?.psvCapacity || '')
          ? true
          : false,

      psvCapacityMeasurementChanged:
        item?.isNew && item?.psvCapacity?.textBoxUnitValue
          ? true
          : (item?.psvCapacity?.textBoxUnitValue || '') !==
            (currentEquipment?.psvCapacityMeasurement || '')
          ? true
          : false,

      flangeInletSize: item?.inletSize?.textBoxValue || null,
      ...(item?.inletSize?.textBoxUnitValue && { flangeUnit: item?.inletSize?.textBoxUnitValue }),

      flangeInletSizeChanged:
        item?.isNew && item?.inletSize?.textBoxValue
          ? true
          : (item?.inletSize?.textBoxValue || '') !== (currentEquipment?.inletSize || '')
          ? true
          : false,

      flangeUnitChanged:
        item?.isNew && item?.inletSize?.textBoxUnitValue
          ? true
          : (item?.inletSize?.textBoxUnitValue || '') !==
            (currentEquipment?.inletSizeMeasurement || '')
          ? true
          : false,

      ...(item?.inletJointStyle && { flangeInletJointStyle: item?.inletJointStyle }),
      flangeInletJointStyleChanged:
        item?.isNew && item?.inletJointStyle
          ? true
          : (item?.inletJointStyle || '') !== (currentEquipment?.flangeInletJointStyle || '')
          ? true
          : false,

      flangeOutletSize: item?.outletSize?.textBoxValue || null,
      ...(item?.outletSize?.textBoxUnitValue && {
        flangeOutletUnit: item?.outletSize?.textBoxUnitValue
      }),

      flangeOutletSizeChanged:
        item?.isNew && item?.outletSize?.textBoxValue
          ? true
          : (item?.outletSize?.textBoxValue || '') !== (currentEquipment?.outletSize || '')
          ? true
          : false,
      flangeOutletUnitChanged:
        item?.isNew && item?.outletSize?.textBoxUnitValue
          ? true
          : (item?.outletSize?.textBoxUnitValue || '') !==
            (currentEquipment?.outletSizeMeasurement || '')
          ? true
          : false,

      ...(item?.outletJointStyle && {
        flangeOutletJointStyle: item?.outletJointStyle
      }),
      flangeOutletJointStyleChanged:
        item?.isNew && item?.outletJointStyle
          ? true
          : (item?.outletJointStyle || '') !== (currentEquipment?.flangeOutletJointStyle || '')
          ? true
          : false,

      ...(item?.lastServiceDate && {
        lastServicedDate: item?.lastServiceDate
      }),
      ...(item?.lastServiceBy && {
        lastServicedBy: item?.lastServiceBy
      }),
      ...(item?.comments && {
        comments: item?.comments
      })
    };

    equipmentAssociatedPsvs.push(object);
  }

  return equipmentAssociatedPsvs;
};
