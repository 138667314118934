/* eslint-disable no-unused-vars */
import _ from 'lodash';

import { getDataPoints } from '../../pages/settings/helper';
import {
  getTaskCardUsers,
  TYPE_ICONS,
  getTaskAssignedUserTags,
  getStructureEquipmentMap,
  getAppliesToTypeData
} from '../tasks/helper';

import { getTaskSiteAsset, filterSiteAssetBasedOnMocHseStructureNcrPipeline } from '../global/site';
import { getTaskEquipmentAsset } from '../global/equipment';
import { getTaskPipelineAsset, getPipelineAsset } from '../global/pipeline';
import { getTaskCardFooter } from '../global/footer';

import { getTaskStructureAsset, getStructureAsset } from '../global/structure';

const CheckSelector = {
  getAllChecks: ({
    showDataPoints = true,
    id = '',
    removeConfigurableFieldFromAsset = false,
    appliesTo = false,
    newCheck = false,
    siteId = null,
    equipmentId = null,
    structureId = null,
    equipmentIds = [],
    showAttachedPSVs = false,
    formWrapper = false,
    store,
    dataPointsCompleted = false
  }) => {
    return new Promise((resolve) => {
      const {
        cache,
        settings,
        picklists,
        jurisdictionNumbers,
        auth,
        pipeline,
        structure,
        checks = {},
        tasks,
        inspection
      } = store;

      const userId = auth.userId || '';
      let { workOrderUsersMap, workOrderUsersGroupsMap } = tasks;

      const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = picklists;
      const {
        equipmentsMap,
        sitesMap = {},
        equipmentsWithoutMobileMap = {},
        equipments: cacheEquipments = [],
        sites: cacheSites = []
      } = cache;
      const structureEquipmentMap = getStructureEquipmentMap({ equipments: cacheEquipments });

      const {
        equipmentDisplayFields,
        siteDisplayFields,
        checkDisplayFields,
        pipelineDisplayFields,
        structureDisplayFields
      } = settings.settings;

      const { jurisdictionNumbersMap } = jurisdictionNumbers;
      const { pipelineMobileMap = {}, pipelineMobile = [] } = pipeline;
      const { structureMobileMap = {}, structureMobile = [] } = structure;

      let prepareChecks = [];
      let { checksList = [], checkTypes = [], checkTypesMap = {} } = checks;

      const responsibleUsersMap = {
        ...(workOrderUsersMap || {}),
        ...(workOrderUsersGroupsMap || {})
      };

      if (siteId) {
        checksList = checksList?.filter(
          (t) =>
            t?.siteId === siteId ||
            t?.relatedAssetId === siteId ||
            t?.primaryAssetId === siteId ||
            t?.sites?.includes(siteId)
        );
      } else if (equipmentId) {
        checksList = checksList?.filter(
          (t) =>
            t?.equipmentId === equipmentId ||
            t?.relatedAssetId === equipmentId ||
            t?.primaryAssetId === equipmentId ||
            t?.equipments?.includes(equipmentId)
        );
      } else if (structureId && equipmentIds?.length) {
        checksList = checksList?.filter(
          (t) =>
            t?.structureId === structureId ||
            t?.relatedAssetId === structureId ||
            t?.structures?.includes(structureId) ||
            equipmentIds?.includes(t?.equipmentId) ||
            equipmentIds?.includes(t?.relatedAssetId) ||
            equipmentIds?.includes(t?.primaryAssetId) ||
            t?.equipments?.some((y) => equipmentIds?.includes(y))
        );
      } else if (structureId) {
        checksList = checksList?.filter(
          (t) =>
            t?.structureId === structureId ||
            t?.relatedAssetId === structureId ||
            t?.structures?.includes(structureId)
        );
      }

      if (id) {
        if (newCheck) checksList = checksList?.filter((t) => t?.id === id);
        else checksList = checksList?.filter((t) => t?.id === id);
      }

      checksList?.map((check) => {
        const { responsibleUserGroups, responsibleUsers, primaryUserId } = check;

        const taskHeaderIconName = TYPE_ICONS[check.type] || '';
        const valueOfRecurrenceTypeId = picklistsMap[check?.recurrenceTypeId]?.value || '';
        const valueOfRecurrencePriorityId = picklistsMap[check?.recurrencePriorityId]?.value || '';
        const splitBySpace = valueOfRecurrencePriorityId?.split(' ') || [];
        const recurrence = valueOfRecurrencePriorityId
          ? `${splitBySpace[0]} - ${splitBySpace?.splice(1).join(' ')}`
          : '';
        const dataPoints = showDataPoints
          ? getDataPoints(
              {
                ...check,
                recurrence: recurrence
              },
              dataPointsCompleted
                ? 'Recurrence,Identifier,Location,ResponsibleUsers'
                : checkDisplayFields,
              'check',
              picklistsMap
            )
          : [];

        const users = getTaskCardUsers({
          responsibleUserGroups: responsibleUserGroups || [],
          responsibleUsers: responsibleUsers || [],
          workOrderUsersMap: workOrderUsersMap || {},
          workOrderUsersGroupsMap: workOrderUsersGroupsMap || {},
          primaryUserId: primaryUserId || null
        });

        const taskCardHeaderTitle =
          getCheckHeaderTitle({
            status: check?.status || null,
            id: check?.checkNumber || null
          }) || '';

        const siteAsset = getTaskSiteAsset({
          sitesMap,
          siteId: check.siteId || '',
          sites: check.sites || [],
          areaHierarchyTreeDataMap,
          siteDisplayFields,
          picklistsMap,
          removeConfigurableFieldFromAsset,
          cacheSites,
          appliesTo,
          formWrapper,
          store
        });

        const equipmentAsset = getTaskEquipmentAsset({
          equipmentsWithoutMobileMap,
          equipmentsMap,
          cacheEquipments,
          equipments: check.equipments || [],
          equipmentId: check.equipmentId || '',
          picklistsMap,
          equipmentDisplayFields,
          jurisdictionNumbersMap,
          sitesMap,
          siteDisplayFields,
          areaHierarchyTreeDataMap,
          removeConfigurableFieldFromAsset,
          cacheSites,
          appliesTo,
          showAttachedPSVs,
          formWrapper,
          store
        });

        const structureAsset =
          (check?.appliesToType === 'structure' || check?.structureId) &&
          getTaskStructureAsset({
            sitesMap,
            areaHierarchyTreeDataMap,
            siteDisplayFields,
            picklistsMap,
            cacheSites,
            appliesTo,
            task: check,
            structureMobileMap,
            structureMobile,
            structureDisplayFields,
            structureEquipmentMap,
            formWrapper,
            store
          });

        const pipelineAsset =
          (check?.appliesToType === 'pipeline' || check?.pipelineId) &&
          getTaskPipelineAsset({
            sitesMap,
            siteDisplayFields,
            cacheSites,
            areaHierarchyTreeDataMap,
            picklistsMap,
            appliesTo,
            task: check,
            pipelineMobileMap,
            pipelineMobile,
            pipelineDisplayFields,
            formWrapper,
            store
          });

        const taskFooter = getTaskCardFooter({
          status: check.status || null,
          sync: check.sync || null,
          dueDate: check.dueDate || null,
          startDate: check.NextScheduled || null,
          due: check.nextDue || null, // Added nextDue
          syncedByText: check.syncedByText || null,
          syncedDate: check.syncedDate || null,
          syncedBy: check.syncedBy || null,
          updatedAt: check.updatedAt || null,
          syncDateCustom: check?.syncDateCustom || null,
          userId
        });

        const customAssignedTaskValue = getTaskAssignedUserTags({
          responsibleUsers: responsibleUsers || [],
          responsibleUserGroups: responsibleUserGroups || [],
          workOrderUsersGroupsMap: workOrderUsersGroupsMap || {},
          userId,
          responsibleUsersMap: responsibleUsersMap || {}
        });

        const structureData =
          check?.appliesToType === 'structure'
            ? getStructureAsset({
                task: check,
                picklistsMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                structureMobileMap,
                structureMobile,
                cacheSites,
                structureDisplayFields,
                appliesTo,
                store
              })
            : check?.structureId
            ? getStructureAsset({
                task: check,
                picklistsMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                structureMobileMap,
                structureMobile,
                cacheSites,
                structureDisplayFields,
                appliesTo,
                store
              })
            : {};

        const pipelineData =
          check?.appliesToType === 'pipeline'
            ? getPipelineAsset({
                equipmentsWithoutMobileMap,
                task: check,
                equipmentsMap,
                cacheEquipments,

                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,

                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                pipelineDisplayFields,
                store
              })
            : check?.pipelineId
            ? getPipelineAsset({
                equipmentsWithoutMobileMap,
                task: check,
                equipmentsMap,
                cacheEquipments,

                picklistsMap,
                equipmentDisplayFields,
                jurisdictionNumbersMap,
                sitesMap,
                siteDisplayFields,
                areaHierarchyTreeDataMap,
                pipelineMobileMap,
                pipelineMobile,

                structureMobileMap,
                structureMobile,
                cacheSites,
                appliesTo,
                pipelineDisplayFields,
                store
              })
            : {};

        const sitesAssetFilterData = filterSiteAssetBasedOnMocHseStructureNcrPipeline({
          mocSiteId: '',
          hseSiteId: '',
          structureSiteId: structureAsset?.sitesIds,
          ncrSiteId: '',
          pipelineSiteId: pipelineAsset?.sitesIds,
          siteAsset
        });

        const equipmentAssetFilterData = equipmentAsset;

        prepareChecks.push({
          taskHeaderIconName,
          taskCardHeaderTitle,
          status: check.status || '',
          type: check?.type || '',
          link: check?.link || '',
          title: checkTypesMap[check?.inspectionTypeId]?.value || null,
          description: check.description || '',
          users,
          equipmentAsset,
          siteAsset,
          taskFooter,
          structureData,
          pipelineData,
          customAssignedTaskValue,
          dataPoints,
          sitesAssetFilterData,
          equipmentAssetFilterData,
          checked: false,
          structureAsset,
          pipelineAsset,
          recurrence,
          ...check
        });
      });
      resolve(prepareChecks);
    });
  }
};

export default CheckSelector;

const getCheckHeaderTitle = ({ status, id }) => {
  const taskCardHeaderTitle = status === 'Synced' ? id : 'Check';
  return taskCardHeaderTitle;
};
