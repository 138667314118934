import React, { memo } from 'react';

import AppBar from '@components/app-bar';
import PipelineListing from '@components/pipeline-listing';

const Listing = () => {
  return (
    <div className="page-container">
      <AppBar />
      <div className="md:ml-24">
        <PipelineListing Title={'Pipeline'} />
      </div>
    </div>
  );
};

export default memo(Listing);
