import { memo } from 'react';

import Label from '@components/label';

import style from './textarea.module.scss';

const Textarea = ({
  reconciled,
  label,
  dot,
  error,
  value,
  onChange,
  className,
  placeholder,
  register,
  name,
  showReconciled = false
}) => {
  return (
    <div className={style.fieldInput}>
      <Label
        reconciled={reconciled}
        label={label}
        dot={dot}
        error={error}
        showReconciled={showReconciled}
        inLineStyle={{ paddingLeft: '8px' }}
      />
      <textarea
        value={value}
        rows={3}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        onChange={onChange}
        placeholder={placeholder}
        className={`${style.inputField} ${className}  ${error && style.errorBorder} `}
        {...(register && register(name))}
      ></textarea>
    </div>
  );
};

export default memo(Textarea);
