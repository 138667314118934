/* eslint-disable no-unused-vars */

import React, { memo } from 'react';
import _ from 'lodash';
import HighlightText from '@components/highlight-text';

import ButtonStatus from '@components/button-status';
import CheckBoxComponent from '@components/checkbox/checkbox.component';
import Portal from '@components/portal';

import style from './style.module.scss';

const RunList = ({ inspectionRun, setInspectionRun, checkedLength, setView, highlight = '' }) => {
  const clickRtRow = (i) => {
    let rtCopy = [...inspectionRun];
    rtCopy[i].checked = !rtCopy[i].checked;
    setInspectionRun(rtCopy);
  };

  const changeCheckEvent = (e, i) => {
    let rtCopy = [...inspectionRun];
    rtCopy[i].checked = !rtCopy[i].checked;
    setInspectionRun(rtCopy);
  };

  return (
    <div style={{ position: 'relative' }}>
      {inspectionRun?.map(
        ({ id, title, checked, allDoneInspections, allInspections, isOverDue }, i) => (
          <div key={i} className="task-type link-card" onClick={() => clickRtRow(i)}>
            <div className="task-type--left">
              <div style={{ marginRight: '24px' }}>
                <CheckBoxComponent checked={checked} onChange={(e) => changeCheckEvent(e, i)} />
              </div>

              <div>
                <div>
                  <span className={style.runId}>
                    <HighlightText text={id} highlight={highlight} />
                  </span>{' '}
                  <span className={style.runTitle}>
                    <HighlightText text={title} highlight={highlight} />
                  </span>
                </div>
              </div>
            </div>

            <ButtonStatus
              classes={`btn-status btn1 ${allDoneInspections === 0 && 'btn-overdue'} ${
                allDoneInspections > 0 && allInspections > allDoneInspections && 'btn-progress'
              }
                    ${allDoneInspections === allInspections && 'btn-done'} `}
              Text={`${allDoneInspections}/${allInspections}`}
              dot={(!!isOverDue && true) || false}
            />
          </div>
        )
      )}
      {checkedLength > 0 && (
        <Portal id="rtView">
          <button className={style.viewBtn} onClick={() => setView('Inspection')}>
            View ({checkedLength})
          </button>
        </Portal>
      )}
    </div>
  );
};

export default memo(RunList, _.isEqual);
