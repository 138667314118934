/* eslint-disable no-unused-vars */
import { useRef, useState, useEffect, memo } from 'react';

import PageContainer from '@components/page-container';
import SettingsCards from './settings-components/settings-cards';
import PageHeader from '@components/page-header';
import DataCachingSettings from './settings-components/data-caching-settings';
import AttachmentsSettings from './settings-components/attachement-settings';
import SyncRemindersSettings from './settings-components/sync-reminder-setting';
import GeneralDisplaySettings from './settings-components/general-display-settings';
import ChecksDisplaySettings from './settings-components/check-display-settings';
import WorkOrdersDisplaySettings from './settings-components/work-order-display-fields';
import InspectionsDisplaySettings from './settings-components/inspection-display-fields';
import PipelineDisplaySettings from './settings-components/pipeline-display-fields';
import EquipmentDisplaySettings from './settings-components/equipment-display-fields';
import StructureDisplaySettings from './settings-components/structure-display-fields';
import SitesDisplaySettings from './settings-components/site-display-settings';

import { PERMISSIONS } from 'permissions/constants';
import { decodeToken } from '@utilities/authentication';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { SETTINGS, DISPLAY_SETTINGS } from './helper';

import './settings.scss';

const Settings = () => {
  const permissions = decodeToken()?.permissions || {};
  const settingsList = SETTINGS.filter((setting) => {
    if (setting === 'Attachments' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS])
      return false;
    return true;
  });
  const displaySettingsList = DISPLAY_SETTINGS.filter((setting) => {
    if (setting === 'Checks' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS]) return false;

    if (setting === 'Work Orders' && !permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS])
      return false;
    return true;
  });
  const listingRef = useRef(null);
  const { width } = useWindowDimensions();
  const [selectedSetting, setSelectedSetting] = useState('');
  const [selectedDisplaySetting, setSelectedDisplaySetting] = useState('');

  useEffect(() => {
    listingRef.current?.scroll({ top: 0 });
  }, [selectedSetting, selectedDisplaySetting]);

  const onClickBack = () => {
    if (selectedDisplaySetting) {
      setSelectedDisplaySetting('');
      if (width > 767) setSelectedSetting('');
    } else if (selectedSetting) setSelectedSetting('');
  };
  const onScrollEvent = () => {
    var el = document.querySelector('#settingContainer');
    el.classList.add('addScrollbar');
    el.classList.remove('removeScrollbar');
    clearTimeout();
    setTimeout(function () {
      el.classList.remove('addScrollbar');
      el.classList.add('removeScrollbar');
    }, 300);
  };

  return (
    <PageContainer>
      <PageHeader
        onClick={onClickBack}
        disableBack={selectedSetting}
        title={selectedDisplaySetting || selectedSetting || 'Settings'}
      />
      <div className="flex">
        {width > 767 ? (
          <>
            <div
              className="page-listing show-selected removeScrollbar"
              style={{ maxWidth: '325px', borderRight: '0.5px solid #CBD2D9' }}
              id="settingContainer"
              onScroll={onScrollEvent}
            >
              <div className="settings">
                {selectedSetting === 'Display settings' ? (
                  <SettingsCards
                    settings={displaySettingsList}
                    selected={selectedDisplaySetting}
                    onClick={(setting) => setSelectedDisplaySetting(setting)}
                  />
                ) : (
                  <SettingsCards
                    settings={settingsList}
                    selected={selectedSetting}
                    onClick={(setting) => setSelectedSetting(setting)}
                  />
                )}
              </div>
            </div>
            <div
              ref={listingRef}
              className="page-listing removeScrollbar"
              style={{ background: 'white', marginTop: '0px' }}
              id="settingContainer"
              onScroll={onScrollEvent}
            >
              <div className="settings" style={{ maxWidth: '768px', width: '100%' }}>
                {!selectedSetting || selectedSetting === 'Data caching' ? (
                  <DataCachingSettings />
                ) : selectedSetting === 'Attachments' ? (
                  <AttachmentsSettings />
                ) : selectedSetting === 'Sync reminders' ? (
                  <SyncRemindersSettings />
                ) : (
                  selectedSetting === 'Display settings' &&
                  (!selectedDisplaySetting || selectedDisplaySetting === 'General' ? (
                    <GeneralDisplaySettings />
                  ) : selectedDisplaySetting === 'Sites' ? (
                    <SitesDisplaySettings />
                  ) : selectedDisplaySetting === 'Structures' ? (
                    <StructureDisplaySettings />
                  ) : selectedDisplaySetting === 'Equipment' ? (
                    <EquipmentDisplaySettings />
                  ) : selectedDisplaySetting === 'Pipeline' ? (
                    <PipelineDisplaySettings />
                  ) : selectedDisplaySetting === 'Inspections' ? (
                    <InspectionsDisplaySettings />
                  ) : selectedDisplaySetting === 'Work Orders' ? (
                    <WorkOrdersDisplaySettings />
                  ) : (
                    selectedDisplaySetting === 'Checks' && <ChecksDisplaySettings />
                  ))
                )}
              </div>
            </div>
          </>
        ) : (
          <div
            ref={listingRef}
            className="page-listing removeScrollbar"
            style={{
              background:
                selectedSetting === 'Display settings' &&
                selectedDisplaySetting &&
                selectedDisplaySetting !== 'General'
                  ? 'white'
                  : '',
              marginTop: '0px',
              maxWidth: '768px !important',
              width: '100%'
            }}
            id="settingContainer"
            onScroll={onScrollEvent}
          >
            <div className="settings">
              {!selectedSetting ? (
                <SettingsCards
                  settings={settingsList}
                  onClick={(setting) => setSelectedSetting(setting)}
                />
              ) : selectedSetting === 'Data caching' ? (
                <DataCachingSettings />
              ) : selectedSetting === 'Attachments' ? (
                <AttachmentsSettings />
              ) : selectedSetting === 'Sync reminders' ? (
                <SyncRemindersSettings />
              ) : (
                selectedSetting === 'Display settings' &&
                (!selectedDisplaySetting ? (
                  <SettingsCards
                    settings={displaySettingsList}
                    onClick={(setting) => setSelectedDisplaySetting(setting)}
                  />
                ) : selectedDisplaySetting === 'General' ? (
                  <GeneralDisplaySettings />
                ) : selectedDisplaySetting === 'Sites' ? (
                  <SitesDisplaySettings />
                ) : selectedDisplaySetting === 'Structures' ? (
                  <StructureDisplaySettings />
                ) : selectedDisplaySetting === 'Equipment' ? (
                  <EquipmentDisplaySettings />
                ) : selectedDisplaySetting === 'Pipeline' ? (
                  <PipelineDisplaySettings />
                ) : selectedDisplaySetting === 'Inspections' ? (
                  <InspectionsDisplaySettings />
                ) : selectedDisplaySetting === 'Work Orders' ? (
                  <WorkOrdersDisplaySettings />
                ) : (
                  selectedDisplaySetting === 'Checks' && <ChecksDisplaySettings />
                ))
              )}
            </div>
          </div>
        )}
      </div>
    </PageContainer>
  );
};
export default memo(Settings);
