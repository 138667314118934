import React from 'react';

import Icon from '@components/icon-component';
import HighlightText from '@components/highlight-text';

import style from './task-tally.module.scss';

const TaskTally = ({
  dot,
  status,
  highlight,
  iconName,
  statusText,
  height,
  width,
  recordIndicator
}) => {
  return (
    <>
      <div
        className={style.statusDiv}
        style={{
          backgroundColor:
            status === 'done'
              ? '#CBF1D9'
              : status === 'inProgress'
              ? '#F9EDC7'
              : status === 'overdue'
              ? '#FCCECE'
              : recordIndicator
              ? 'rgba(213, 230, 251, 0.75)'
              : '#F5F7FA'
        }}
      >
        <div className={style.p}>
          {iconName && (
            <Icon name={iconName} height={height} width={width} className={style.chipsIcon} />
          )}
          <p style={{ marginLeft: iconName ? '4px' : '0px' }}>
            <HighlightText text={statusText} highlight={highlight} />
          </p>
        </div>
        {dot && <div className={style.dot}></div>}
      </div>
    </>
  );
};

export default TaskTally;
