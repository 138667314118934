/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams, useNavigate, useBlocker } from 'react-router-dom';
import * as yup from 'yup';
import { store } from 'redux/store';
import { useSelector } from 'react-redux';

import PageHeader from '@components/work-order/page-header';
import SaveModal from '@components/modal/variants/save-modal';
import LoadingRound from '@components/loading-round/loading-round';
import ScrollNavigation from '@components/work-order/scroll-navigation';
import FormWrapperForm from '@components/work-order/form-wrapper-form';
import Sidebar from '@components/work-order/sidebar';
import DeleteModal from '@components/modal/variants/delete-modal';

import {
  WorkTypes,
  getSitesOfTask,
  getEquipmentsOfTask,
  getCurrentWorkOrderOrInspectionData,
  getStructureOfTask,
  getPipelineOfTask
} from '@components/work-order/helper';
import { getAppDispatch } from '@utilities/dispatch.util';
import LocalStorage from '@utilities/local-storage.util';
import { decodeToken } from '@utilities/authentication';
import { tasksListsActions } from '@redux/slices/tasks';
import { inspectionActions } from '@redux/slices/inspection';
import InspectionService from '@services/inspection.service';
import WorkOrderService from '@services/workorder.service';
import {
  getCurrentTaskOrInspectionInfo,
  getDataFromObjects,
  transformNewWorkRequestDataToDynamicFormData
} from '@components/work-order/services/task-inspection';
import { convertToSerializable } from '@components/work-order/services/serializable-non-serializable';
import { getSiteAutoPopulate } from '@components/work-order/services/auto-populate';
import CheckFormWrapper from '@components/work-order/check-form/check-form';
import { useToastContext } from 'context/toaster.context';
import useDistance from '@hooks/useDistance';

import useWindowDimensions from '@hooks/useWindowDimensions';
import { formWrapperActions } from '@redux/slices/form-wrapper';
import HSEEventService from '@services/hse-event.service';
import ChecksService from '@services/checks.service';
import { cacheActions } from '@redux/slices/cache';
import { bookmarksActions } from '@redux/slices/bookmarks';
import { checkActions } from '@redux/slices/checks';

import style from './workorder.module.scss';
import {
  deleteNewTasksAssetFromIndexDB,
  getPhotosAndDocumentsDataFromSelectedInspection
} from '@services/assets.service';

const WorkOrder = () => {
  const { width } = useWindowDimensions();

  const navigate = useNavigate();
  const dispatch = getAppDispatch();
  const refToTop = useRef();
  const menuItemEls = useRef([]);
  const formWrapperFormRef = useRef(null);
  const [searchParams] = useSearchParams();
  const { ToasterUtil } = useToastContext();

  const workType = searchParams.get('type') || '';
  const inspectionType = searchParams.get('inspectionType') || '';
  const checkType = searchParams.get('checkType') || '';
  const siteId = searchParams.get('siteId') || '';
  const structureId = searchParams.get('structureId') || '';
  const pipelineId = searchParams.get('pipelineId') || '';
  const orderIds = searchParams.get('orderIds') || '';
  const equipmentId = searchParams.get('equipmentId') || '';
  const location = searchParams.get('location') || '';
  const areaId = searchParams.get('areaId') || '';

  const [autoPopulateData, setAutoPopulateData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeBtn, setActiveBtn] = useState('Todo');
  const [moreMenuToggle, setMoreMenuToggle] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);
  const [discordCheck, setDiscordCheck] = useState(false);
  const [selectedTasksList, setSelectedTasksList] = useState([]);
  const [currFormData, setCurrFormData] = useState({});
  const [orderIdsLength, setOrderIdsLength] = useState(0);
  const [isLoading1, setIsLoading1] = useState(true);
  const [isLoading2, setIsLoading2] = useState(true);
  const [isLoading3, setIsLoading3] = useState(true);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [discordButtonLoading, setDiscordButtonLoading] = useState(false);
  const [currentPageOfSelectedTasks, setCurrentPageOfSelectedTasks] = useState(1);
  const [active, setActive] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { cache } = store.getState();
  const { startTaskCoordinates } = cache;
  const { coordinates } = useDistance({});

  const prevScrollPosRef = useRef(0);
  const tabs = ['applies-to', 'general', 'work-log'];

  const { clickAssetOpliiDesktopLink } = useSelector((state) => state.formWrapper);

  useEffect(() => {
    setOrderIdsLength(orderIds?.split(',')?.length);
  }, []);

  const selected = selectedTasksList?.[currentPageOfSelectedTasks - 1];

  const siteIds = useMemo(() => {
    return !_.isEmpty(orderIds) && !_.isEmpty(selected)
      ? getSitesOfTask({ selected })
      : siteId
      ? [siteId]
      : [];
  }, [siteId, selected]);

  const structureIds = useMemo(() => {
    return !_.isEmpty(orderIds) && !_.isEmpty(selected)
      ? getStructureOfTask({ selected })
      : structureId
      ? [structureId]
      : [];
  }, [structureId, selected]);

  const pipelineIds = useMemo(() => {
    return !_.isEmpty(orderIds) && !_.isEmpty(selected)
      ? getPipelineOfTask({ selected })
      : pipelineId
      ? [pipelineId]
      : [];
  }, [pipelineId, selected]);

  const equipmentIds = useMemo(() => {
    return !_.isEmpty(orderIds) && !_.isEmpty(selected)
      ? getEquipmentsOfTask({ selected })
      : equipmentId
      ? [equipmentId]
      : [];
  }, [equipmentId, selected]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { localCreatedTasks } = await getCurrentWorkOrderOrInspectionData({
          orderIds
        });
        if (orderIdsLength > 1) goBackToHome({ localCreatedTasks });
        setSelectedTasksList(localCreatedTasks);
      } catch (e) {
        console.log({ e });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [orderIds, workType, currentPageOfSelectedTasks]);

  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      try {
        setIsLoading1(true);
        const res = await getCurrentTaskOrInspectionInfo({
          workType,
          inspectionType: inspectionType ? inspectionType : checkType,
          currentPageOfSelectedTasks,
          selectedTasksList: selectedTasksList?.length ? selectedTasksList : [],
          siteIds: siteId ? siteIds : '',
          equipmentIds: equipmentId ? equipmentIds : '',
          structureIds: structureId ? structureIds : '',
          pipelineIds: pipelineId ? pipelineIds : [],
          hseOffSite: location
            ? {
                location,
                areaId
              }
            : null,
          activeBtn
        });
        if (isMounted && !_.isEmpty(res)) {
          setCurrFormData(res);
          setIsLoading1(false);
        }
      } catch (e) {
        console.log({ e });
      }
    };
    if (!isLoading) {
      fetchData();
    }

    return () => {
      isMounted = false;
    };
  }, [orderIds, workType, selectedTasksList, currentPageOfSelectedTasks, isLoading]);

  const {
    pageHeaderText = '',
    pageHeaderTitle = '',
    selectorData = {},
    menuNames = [],
    formSectionsWithSubSections,
    appliesToDataResponse,
    currentFormSchema,
    newChecksSchema,
    dropdownMenuMapList,
    currentFormId,
    formAppliesTo,
    componentsNames,
    setDefaultOrAutoPopulateValuesForFields,
    workLogSchemaForReadingSchema
  } = useMemo(() => {
    if (!_.isEmpty(currFormData) && !isLoading1) {
      return currFormData;
    } else return {};
  }, [isLoading1, currFormData]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    getValues,
    watch,
    setValue,
    reset,
    trigger
  } = useForm({
    resolver: yupResolver(
      yup.object().shape({
        ...currentFormSchema,
        ...newChecksSchema,
        ...workLogSchemaForReadingSchema
      })
    ),
    defaultValues: {},
    shouldFocusError: false
  });
  useEffect(() => {
    if (_.size(menuNames) > 0 && !isLoading1) {
      (async () => {
        reset();
        const data = await getSiteAutoPopulate({
          inspectionType: inspectionType || '',
          frequencyId: selectorData?.frequencyId || '',
          siteId: siteId || selectorData?.siteId || null,
          equipmentId: equipmentId || selectorData?.equipmentId || null,
          pipelineId: pipelineId || selectorData?.pipelineId || null,
          structureId: structureId || selectorData?.structureId || null,
          psvsList: !_.isEmpty(selectorData)
            ? selectorData?.equipmentAsset?.prepareTasksEquipments
                ?.map((x) => x?.equipmentPSVs)
                ?.flat(1)
            : !_.isEmpty(appliesToDataResponse)
            ? appliesToDataResponse?.at(0)?.equipmentPSVs
            : []
        });
        setAutoPopulateData(data);
      })();
    }
  }, [menuNames, isLoading1]);

  const dirtyRef = useRef({
    isDirty: false,
    dirtyFields: {}
  });

  useEffect(() => {
    dirtyRef.current = {
      isDirty,
      dirtyFields
    };
  }, [isDirty, dirtyFields]);

  let blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      ((dirtyRef.current.isDirty && Object.keys(dirtyRef.current.dirtyFields)?.length > 0) ||
        dirtyRef.current.navigated ||
        dirtyRef?.current?.assetClick) &&
      currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (dirtyRef.current.navigated) {
        blocker.proceed();
      } else if (dirtyRef?.current?.assetClick) {
        dirtyRef.current = {
          isDirty,
          dirtyFields,
          assetClick: false,
          navigated: false
        };
        blocker.proceed();
      } else {
        workType === WorkTypes.NewCheck || workType === WorkTypes.Check
          ? pageHeaderBackClickOnCheckForm()
          : pageHeaderBackClick(
              dirtyRef.current.isDirty && Object.keys(dirtyRef.current.dirtyFields)?.length
            );
      }
    }
  }, [blocker, dirtyRef]);

  useEffect(() => {
    if (_.size(selectedTasksList) > 0) {
      const data = transformNewWorkRequestDataToDynamicFormData({
        data: selectedTasksList[currentPageOfSelectedTasks - 1],
        workType,
        workOrder: selectorData?.type !== 'Inspection'
      });
      reset({
        ...data
      });
      setIsLoading2(false);
    } else {
      setIsLoading2(false);
    }
  }, [orderIds, workType, selectedTasksList, currentPageOfSelectedTasks, autoPopulateData]);

  useEffect(() => {
    if (clickAssetOpliiDesktopLink) {
      if (isDirty && Object.keys(dirtyFields)?.length) setSaveInProgress(true);
      else {
        dirtyRef.current = {
          isDirty,
          dirtyFields,
          assetClick: true,
          navigated: false
        };
        dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
        window.open(`${clickAssetOpliiDesktopLink}`, '_self');
      }
    }
  }, [clickAssetOpliiDesktopLink]);
  // Is Loading 3 add
  useEffect(() => {
    setAutoPopulateDataToStateAndRemovePreviousValues();
  }, [autoPopulateData, isLoading2]);

  const setAutoPopulateDataToStateAndRemovePreviousValues = () => {
    if (setDefaultOrAutoPopulateValuesForFields && !isLoading2 && autoPopulateData) {
      reset({});
      for (let field of setDefaultOrAutoPopulateValuesForFields) {
        if (field?.questionTypeText === 'Readings' || field?.questionTypeText === 'Readings_PL') {
          if (
            _.isEmpty(getValues(`${field?.name}.textBoxUnitValue`)) &&
            field?.sourceEntity &&
            field?.SourceProperty
          ) {
            let autoPopulateValue = autoPopulateData[formAppliesTo]?.[field?.sourceEntity]?.[
              field?.SourceProperty
            ] || {
              textBoxValue: null,
              textBoxUnitValue: null
            };
            setValue(`${field?.name}.textBoxUnitValue`, autoPopulateValue?.textBoxUnitValue || '');
            setValue(
              `${field?.name}.textBoxUnitValue-reconcile`,
              autoPopulateValue?.textBoxUnitValue || ''
            );

            setValue(`${field?.name}.textBoxValue`, autoPopulateValue?.textBoxValue || null);
            setValue(
              `${field?.name}.textBoxValue-reconcile`,
              autoPopulateValue?.textBoxValue || null
            );
          }
        } else if (field?.questionTypeText === 'PSV_Section') {
          let autoPopulateValue = autoPopulateData['PSVS']?.data || [];
          if (_.isEmpty(getValues(field?.name))) setValue(field?.name, autoPopulateValue || []);
          setValue(`${field?.name}-reconcile`, autoPopulateValue || []);
        } else if (
          (field?.questionTypeText === 'Date' || field?.questionTypeText === 'Time') &&
          _.isEmpty(getValues(field?.name))
        ) {
          setValue(
            field?.name,
            field?.questionTypeText === 'Time' ? new Date() : moment().format('YYYY-MM-DD')
          );
        } else {
          if (_.isEmpty(getValues(field?.name)) && field?.sourceEntity && field?.SourceProperty) {
            let autoPopulateValue =
              autoPopulateData[formAppliesTo]?.[field?.sourceEntity]?.[field?.SourceProperty] ||
              null;
            const dateAndTimeField =
              field?.questionTypeText === 'Date' ||
              field?.questionTypeText === 'Date_Nullable' ||
              field?.questionTypeText === 'Time'
                ? true
                : false;

            autoPopulateValue =
              formAppliesTo === 'Pipeline' &&
              field?.SourceProperty === 'Length' &&
              field?.questionTypeText === 'Numeric' &&
              autoPopulateValue?.textBoxValue
                ? autoPopulateValue?.textBoxValue
                : autoPopulateValue;
            setValue(
              field?.name,
              autoPopulateValue ? autoPopulateValue : dateAndTimeField ? null : ''
            );
            setValue(
              `${field?.name}-reconcile`,
              autoPopulateValue ? autoPopulateValue : dateAndTimeField ? null : ''
            );
          }
          if (
            field?.questionTypeText === 'InspectionResult' &&
            field?.defaultResponseValue &&
            _.isEmpty(getValues(field?.name))
          ) {
            const options = dropdownMenuMapList[field?.defaultValue] || [];
            const inspectionResultDefaultValue =
              options?.find((value) => value?.lookup === field?.defaultResponseValue)?.value ||
              null;
            setValue(field?.name, inspectionResultDefaultValue);
          }
        }
      }

      setIsLoading3(false);
    }
  };

  useEffect(() => {
    prevScrollPosRef.current = scrollPosition;
  }, [scrollPosition]);

  useEffect(() => {
    if (menuNames?.length) {
      let menuNames1 =
        workType === WorkTypes.NewWorkOrder
          ? [...menuNames, { id: 'work-log', name: 'Work log' }]
          : menuNames;
      const refsArray = menuNames1.map((_, i) => menuItemEls.current[i] || React.createRef());
      menuItemEls.current = refsArray;
      if (formWrapperFormRef.current)
        formWrapperFormRef.current.scrollTop =
          refsArray[1]?.current?.offsetTop - (width < 768 ? 160 : 177);
    }
  }, [menuNames, activeBtn]);

  const onClickTab = (e, ref, selectedTab) => {
    e.preventDefault();
    setActive(selectedTab);
    formWrapperFormRef.current.scrollTop = ref?.current?.offsetTop - (width < 768 ? 160 : 178);
  };

  const isNewForm = React.useMemo(() => {
    return [
      WorkTypes.NewWorkRequest,
      WorkTypes.NewWorkOrder,
      WorkTypes.NewInspection,
      WorkTypes.NewHSE,
      WorkTypes.NewCheck
    ].includes(workType) && !orderIds
      ? true
      : false;
  }, [workType, orderIds]);

  useEffect(() => {
    if (
      workType === WorkTypes.InspectionAndWorkOrder ||
      workType === WorkTypes.WorkOrderAndInspection ||
      orderIdsLength > 1
    ) {
      const currentTaskId = selectorData?.workOrderNumber || selectorData?.id;
      dispatch?.(cacheActions.setCurrentTaskId(currentTaskId));
    }
  }, [workType, selectorData, orderIdsLength]);

  const moveToInProgress =
    workType === WorkTypes.NewCheck
      ? false
      : isNewForm || selectedTasksList?.[currentPageOfSelectedTasks - 1]?.status === 'To do';

  const onSubmitHandler = async ({
    data,
    status = 'Pending sync',
    saveAndClose = false,
    noCount = false,
    currentPage = null,
    bulk = false
  }) => {
    const submitData = getDataFromObjects({
      data,
      workType,
      workOrder: selectorData?.type !== 'Inspection'
    });

    if (workType === WorkTypes.NewWorkRequest)
      await addOrUpdateNewWOrkRequest({ status, submitData });
    else if (workType === WorkTypes.NewWorkOrder && activeBtn == 'Todo')
      await addOrUpdateNewWorkOrderTodo({ status, submitData });
    else if (workType === WorkTypes.NewWorkOrder && activeBtn == 'Synced')
      await addOrUpdateNewWorkOrderSynced({ status, submitData });
    else if (workType === WorkTypes.WorkOrders)
      await addOrUpdateAssignedWorkOrders({
        status,
        submitData,
        saveAndClose,
        noCount,
        currentPage,
        bulk
      });
    else if (workType === WorkTypes.Inspection) {
      await addOrUpdateAssignedInspections({
        submitData: data,
        status,
        noCount,
        currentPage,
        bulk,
        saveAndClose
      });
      reset();
    } else if (workType === WorkTypes.NewInspection) {
      await addOrUpdateNewInspections({ submitData: data, status });
    } else if (workType === WorkTypes.NewCheck) {
      await addOrUpdateNewChecks({ submitData: data, status });
    } else if (workType === WorkTypes.InspectionAndWorkOrder) {
      if (selectorData?.type === 'Inspection') {
        await addOrUpdateAssignedInspections({
          submitData: data,
          status,
          noCount,
          currentPage,
          saveAndClose
        });
      } else {
        await addOrUpdateAssignedWorkOrders({
          status,
          submitData,
          saveAndClose,
          noCount,
          currentPage
        });
      }
      reset();
    } else if (workType === WorkTypes.NewHSE)
      await addOrUpdateNewHSEEvent({ submitData: data, status });
    else if (workType === WorkTypes.Check)
      await addOrUpdateAssignedChecks({ submitData: data, status });
    dispatch?.(cacheActions.setStartTaskCoordinates({}));
    return true;
  };

  const addOrUpdateNewChecks = async ({ submitData, status }) => {
    let { description, identifier, location, ...newSubmitData } = submitData;
    const { res } = await convertToSerializable(newSubmitData);
    const isEditMode = !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.id : null;

    const startAndEndTaskLocation = {
      startLatitude: isEditMode ? selectedTasksList[0]?.startLatitude : coordinates?.latitude || '',
      startLongitude: isEditMode
        ? selectedTasksList[0]?.startLongitude
        : coordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };

    const checkedData = {
      description: submitData?.description || '',
      identifier: submitData?.identifier || '',
      location: submitData?.location || ''
    };

    const inspectionFormId = !_.isEmpty(currentFormId)
      ? currentFormId
      : !_.isEmpty(selectedTasksList[0])
      ? selectedTasksList[0]?.inspectionFormId
      : null;

    const inspectionTypeId = !_.isEmpty(checkType)
      ? checkType
      : !_.isEmpty(selectedTasksList[0])
      ? selectedTasksList[0]?.inspectionTypeId
      : null;

    await ChecksService.setNewChecksData({
      checkData: {
        ...(!_.isEmpty(selectedTasksList) && { ...selectedTasksList[0] }),
        ...newSubmitData,
        ...checkedData,
        status,
        ...(siteId && { siteId }),
        ...(equipmentId && { equipmentId }),
        ...(pipelineId && { pipelineId }),
        ...(structureId && { structureId }),
        inspectionFormId: inspectionFormId,
        inspectionTypeId: inspectionTypeId,
        formData: res,
        ...startAndEndTaskLocation
      },
      checkId: !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.id : null
    });

    dispatch?.(bookmarksActions.setBookmarkValidity(true));
    ToasterUtil.showSuccess(`Task saved as ${status}`);
    dirtyRef.current = {
      isDirty,
      dirtyFields,
      navigated: true
    };
    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    } else {
      navigate(-1);
      dirtyRef.current = {
        isDirty: false,
        dirtyFields: {}
      };
    }
  };

  const addOrUpdateAssignedChecks = async ({ submitData, status }) => {
    const { res } = await convertToSerializable(submitData);
    const isEditMode = !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.id : null;

    const startAndEndTaskLocation = {
      startLatitude: selectedTasksList[0]?.startLatitude || coordinates?.latitude || '',
      startLongitude: selectedTasksList[0]?.startLongitude || coordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };

    const inspectionFormId = !_.isEmpty(currentFormId)
      ? currentFormId
      : !_.isEmpty(selectedTasksList[0])
      ? selectedTasksList[0]?.inspectionFormId
      : null;

    const inspectionTypeId = !_.isEmpty(checkType)
      ? checkType
      : !_.isEmpty(selectedTasksList[0])
      ? selectedTasksList[0]?.inspectionTypeId
      : null;

    dispatch?.(
      checkActions.updateAssignedCheck({
        ...selectedTasksList[0],
        status,
        inspectionFormId: inspectionFormId,
        inspectionTypeId: inspectionTypeId,
        formData: res,
        ...startAndEndTaskLocation
      })
    );

    dispatch?.(bookmarksActions.setBookmarkValidity(true));
    ToasterUtil.showSuccess(`Task saved as ${status}`);
    dirtyRef.current = {
      isDirty,
      dirtyFields,
      navigated: true
    };
    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    } else {
      navigate(-1);
      dirtyRef.current = {
        isDirty: false,
        dirtyFields: {}
      };
    }
  };

  const addOrUpdateNewWOrkRequest = async ({ submitData, status }) => {
    const isEditMode = !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.woId : null;

    const startAndEndTaskLocation = {
      startLatitude: isEditMode ? selectedTasksList[0]?.startLatitude : coordinates?.latitude || '',
      startLongitude: isEditMode
        ? selectedTasksList[0]?.startLongitude
        : coordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };
    await WorkOrderService.setNewWorkRequestData(
      {
        ...(!_.isEmpty(selectedTasksList) && { ...selectedTasksList[0] }),
        ...submitData,
        status: status,
        ...(siteId && { siteId }),
        ...(equipmentId && { equipmentId }),
        ...(pipelineId && { pipelineId }),
        ...(structureId && { structureId }),
        ...startAndEndTaskLocation
      },
      !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.woId : null
    );
    ToasterUtil.showSuccess(`Task saved as ${status}`);
    dispatch?.(bookmarksActions.setBookmarkValidity(true));

    dirtyRef.current = {
      isDirty,
      dirtyFields,
      navigated: true
    };
    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    } else {
      navigate(-1);
      dirtyRef.current = {
        isDirty: false,
        dirtyFields: {}
      };
    }
  };
  const addOrUpdateNewWorkOrderTodo = async ({ submitData, status, inspection = false }) => {
    const isEditMode = !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.woId : null;

    const startAndEndTaskLocation = {
      startLatitude: isEditMode ? selectedTasksList[0]?.startLatitude : coordinates?.latitude || '',
      startLongitude: isEditMode
        ? selectedTasksList[0]?.startLongitude
        : coordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };

    await WorkOrderService.setNewWorkOrderTodoData(
      {
        ...(!_.isEmpty(selectedTasksList) && { ...selectedTasksList[0] }),
        ...submitData,
        ...startAndEndTaskLocation,
        status,

        ...(siteId && { siteId }),
        ...(equipmentId && { equipmentId }),
        ...(pipelineId && { pipelineId }),
        ...(structureId && { structureId })
      },
      !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.woId : null,
      inspection
    );
    dispatch?.(bookmarksActions.setBookmarkValidity(true));
    ToasterUtil.showSuccess(`Task saved as ${status}`);
    dirtyRef.current = {
      isDirty,
      dirtyFields,
      navigated: true
    };
    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    } else {
      navigate(-1);
      dirtyRef.current = {
        isDirty: false,
        dirtyFields: {}
      };
    }
  };
  const addOrUpdateNewWorkOrderSynced = async ({ submitData, status, inspection = false }) => {
    const isEditMode = !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.woId : null;

    const startAndEndTaskLocation = {
      startLatitude: isEditMode ? selectedTasksList[0]?.startLatitude : coordinates?.latitude || '',
      startLongitude: isEditMode
        ? selectedTasksList[0]?.startLongitude
        : coordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };

    await WorkOrderService.setNewWorkOrderDoneData(
      {
        ...(!_.isEmpty(selectedTasksList) && { ...selectedTasksList[0] }),
        ...submitData,
        status,
        ...startAndEndTaskLocation,
        ...(siteId && { siteId }),
        ...(equipmentId && { equipmentId }),
        ...(pipelineId && { pipelineId }),
        ...(structureId && { structureId })
      },
      !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.woId : null,
      inspection
    );
    dispatch?.(bookmarksActions.setBookmarkValidity(true));
    ToasterUtil.showSuccess(`Task saved as ${status}`);
    dirtyRef.current = {
      isDirty,
      dirtyFields,
      navigated: true
    };
    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    } else {
      navigate(-1);
      dirtyRef.current = {
        isDirty: false,
        dirtyFields: {}
      };
    }
  };
  const addOrUpdateAssignedWorkOrders = async ({
    submitData,
    status,
    reset = false,
    saveAndClose = false,
    noCount = false,
    currentPage = null,
    bulk = false
  }) => {
    const workOrder = !_.isNull(currentPage)
      ? selectedTasksList[currentPage]
      : selectedTasksList[currentPageOfSelectedTasks - 1];
    if (reset) {
      const getPreviousUserPendingAndInprogressTasks =
        (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) ||
        [];
      let find = getPreviousUserPendingAndInprogressTasks?.find(
        (x) => x?.userId === decodeToken().userId
      );
      if (!_.isEmpty(find)) {
        find = {
          ...find,
          tasks: find?.tasks?.filter((task) => task?.id !== workOrder?.id)
        };
      }
      await LocalStorage.setItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`, [
        {
          ...find
        }
      ]);
    }
    const {
      photo,
      document,
      technicalNotes,
      workStarted,
      workCompletedDate,
      regularHours,
      overTimeHours,
      reading,
      readingUnit,
      parts,
      readingIsRequired,
      unit,
      ...formDataResponse
    } = submitData;
    const { res } = await convertToSerializable(formDataResponse, 'WorkOrder');

    const workLog = await WorkOrderService.addWorkLogInWorkOrder({
      ...workOrder,
      ...submitData,
      formData: res
    });
    const workLogAddOnWorkOrder = {
      ...workOrder,
      ...workLog,
      photos: [
        ...((workOrder?.photos && workOrder?.photos.filter((p) => p?.url)) || []),
        ...(workLog?.photos || [])
      ],
      documents: [
        ...((workOrder?.documents && workOrder?.documents.filter((p) => p?.url)) || []),
        ...(workLog?.documents || [])
      ],
      priority: workOrder?.priorityId,
      startLatitude: workOrder?.startLatitude
        ? workOrder?.startLatitude
        : startTaskCoordinates?.latitude || '',
      startLongitude: workOrder?.startLongitude
        ? workOrder?.startLongitude
        : startTaskCoordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };
    dispatch?.(
      tasksListsActions.updateWorkOrder(
        {
          ...workLogAddOnWorkOrder,
          status
        } || {}
      )
    );
    dispatch?.(bookmarksActions.setBookmarkValidity(true));

    if (!noCount) {
      if (saveAndClose) {
        let page = 0;
        if (selectedTasksList?.length > 1) {
          if (currentPageOfSelectedTasks === 1) page = 1;
          else page = currentPageOfSelectedTasks - 1;
        } else if (selectedTasksList?.length === 1) page = 0;

        setCurrentPageOfSelectedTasks(page);
        const selectedOrdersIds = orderIds
          .split(',')
          .filter((a) => {
            return workType === WorkTypes.NewWorkRequest || workType === WorkTypes.NewWorkOrder
              ? a !== selectedTasksList[currentPageOfSelectedTasks - 1]?.woId
              : a !== selectedTasksList[currentPageOfSelectedTasks - 1]?.id;
          })
          .join(',');
        if (_.isEmpty(selectedOrdersIds)) goBack();
        else {
          const allQueryList = Object.fromEntries([...searchParams]);
          let navigateURL = `/work-order/?from=${searchParams.get(
            'from'
          )}&type=${workType}&orderIds=${selectedOrdersIds}`;
          if (allQueryList?.recurringTemplateQuery) {
            const recurringQuery = JSON.parse(allQueryList?.recurringTemplateQuery);
            navigateURL = navigateURL?.concat(
              `&type=${recurringQuery?.type}&templates=${recurringQuery?.templates}&recurringTemplate=${recurringQuery?.recurringTemplate}`
            );
          }
          navigate(navigateURL, {
            state: {
              from: 'back'
            },
            replace: true
          });
        }
        ToasterUtil.showSuccess(`Task saved as ${status}`);
      } else {
        if (currentPageOfSelectedTasks < selectedTasksList?.length) {
          resetWorkLog();
          setCurrentPageOfSelectedTasks((prev) => prev + 1);
          reset
            ? ToasterUtil.showSuccess(`Task has been reset to its original state`)
            : ToasterUtil.showSuccess(`Task saved as ${status}`);
        } else if (currentPageOfSelectedTasks === selectedTasksList?.length) {
          goBack();
          reset
            ? ToasterUtil.showSuccess(`Task has been reset to its original state`)
            : ToasterUtil.showSuccess(`Task saved as ${status}`);
        }
      }
    }
  };
  const addOrUpdateAssignedInspections = async ({
    submitData,
    status,
    reset = false,
    noCount = false,
    currentPage = null,
    bulk,
    saveAndClose
  }) => {
    const { inspection } = store.getState();
    const { res, workOrderMap, workRequestMap } = await convertToSerializable(submitData);
    const currInspection = !_.isNull(currentPage)
      ? selectedTasksList[currentPage]
      : selectedTasksList[currentPageOfSelectedTasks - 1];

    if (reset) {
      const getPreviousUserPendingAndInprogressTasks =
        (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) ||
        [];
      let find = getPreviousUserPendingAndInprogressTasks?.find(
        (x) => x?.userId === decodeToken().userId
      );
      if (!_.isEmpty(find)) {
        find = {
          ...find,
          inspections: find?.inspections?.filter(
            (inspection) => inspection?.id !== currInspection?.id
          )
        };
      }
      await LocalStorage.setItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`, [
        {
          ...find
        }
      ]);
    }

    let workOrderIds =
      workOrderMap?.length > 0
        ? workOrderMap?.map((x) => x?.id)
        : workRequestMap?.length > 0
        ? workRequestMap?.map((x) => x?.id)
        : [];

    const previousIds =
      currInspection?.workOrderIds?.length > 0 ? currInspection?.workOrderIds : [];
    workOrderIds = [...workOrderIds, ...previousIds];

    const startAndEndTaskLocation = {
      startLatitude: currInspection?.startLatitude
        ? currInspection?.startLatitude
        : startTaskCoordinates?.latitude || '',
      startLongitude: currInspection?.startLongitude
        ? currInspection?.startLongitude
        : startTaskCoordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };

    dispatch?.(
      inspectionActions.updateInspection(
        {
          ...currInspection,
          inspectionDate: currInspection?.inspectionDate
            ? currInspection.inspectionDate
            : moment().format('YYYY-MM-DD'),
          containWorkOrder: currInspection?.containWorkOrder
            ? true
            : workOrderMap?.length
            ? true
            : workRequestMap?.length
            ? true
            : false,
          ...(workOrderIds && {
            workOrderIds
          }),
          formData: res,
          ...startAndEndTaskLocation,
          status
        } || {}
      )
    );
    dispatch?.(bookmarksActions.setBookmarkValidity(true));

    if (workOrderMap?.length) {
      workOrderMap?.map(async (workOrder) => {
        const submitData = {
          ...workOrder,
          detailInspections: [currInspection?.id],
          inspId: currInspection?.id,
          recordNumber: null,
          recordTitle:
            inspection?.inspectionFormsUserCacheMap[currInspection?.inspectionFormId]?.name || null
        };
        if (workOrder?.isDone) {
          await WorkOrderService.setNewWorkOrderDoneData(
            {
              ...submitData,
              status: 'Pending sync',
              siteId: currInspection?.siteId || null,
              equipmentId: currInspection?.equipmentId || null,
              pipelineId: currInspection?.pipelineId || null
            },
            null,
            true
          );
        } else {
          await WorkOrderService.setNewWorkOrderTodoData(
            {
              ...submitData,
              status: 'Pending sync',
              siteId: currInspection?.siteId || null,
              equipmentId: currInspection?.equipmentId || null,
              pipelineId: currInspection?.pipelineId || null
            },
            null,
            true
          );
        }
      });
    }
    if (workRequestMap?.length) {
      workRequestMap?.map(async (workOrder) => {
        const submitData = {
          ...workOrder,
          detailInspections: [currInspection?.id],
          inspId: currInspection?.id,
          recordNumber: null,
          recordTitle:
            inspection?.inspectionFormsUserCacheMap[currInspection?.inspectionFormId]?.name || null
        };

        await WorkOrderService.setNewWorkRequestData(
          {
            ...submitData,
            status: 'Pending sync',
            siteId: currInspection?.siteId || null,
            equipmentId: currInspection?.equipmentId || null,
            pipelineId: currInspection?.pipelineId || null
          },
          null,
          true
        );
      });
    }
    if (!noCount) {
      if (saveAndClose) {
        let page = 0;
        if (selectedTasksList?.length > 1) {
          if (currentPageOfSelectedTasks === 1) page = 1;
          else page = currentPageOfSelectedTasks - 1;
        } else if (selectedTasksList?.length === 1) page = 0;

        setCurrentPageOfSelectedTasks(page);
        const selectedOrdersIds = orderIds
          .split(',')
          .filter((a) => {
            return workType === WorkTypes.NewWorkRequest || workType === WorkTypes.NewWorkOrder
              ? a !== selectedTasksList[currentPageOfSelectedTasks - 1]?.woId
              : a !== selectedTasksList[currentPageOfSelectedTasks - 1]?.id;
          })
          .join(',');
        if (_.isEmpty(selectedOrdersIds)) goBack();
        else {
          const allQueryList = Object.fromEntries([...searchParams]);
          let navigateURL = `/work-order/?from=${searchParams.get(
            'from'
          )}&type=${workType}&orderIds=${selectedOrdersIds}`;
          if (allQueryList?.recurringTemplateQuery) {
            const recurringQuery = JSON.parse(allQueryList?.recurringTemplateQuery);
            navigateURL = navigateURL?.concat(
              `&type=${recurringQuery?.type}&templates=${recurringQuery?.templates}&recurringTemplate=${recurringQuery?.recurringTemplate}`
            );
          }
          navigate(navigateURL, {
            state: {
              from: 'back'
            },
            replace: true
          });
        }
        ToasterUtil.showSuccess(`Task saved as ${status}`);
      } else {
        if (currentPageOfSelectedTasks < selectedTasksList?.length) {
          resetWorkLog();
          setCurrentPageOfSelectedTasks((prev) => prev + 1);
          reset
            ? ToasterUtil.showSuccess(`Task has been reset to its original state`)
            : ToasterUtil.showSuccess(`Task saved as ${status}`);
        } else if (currentPageOfSelectedTasks === selectedTasksList?.length) {
          goBack();
          reset
            ? ToasterUtil.showSuccess(`Task has been reset to its original state`)
            : ToasterUtil.showSuccess(`Task saved as ${status}`);
        }
      }
    }
    return true;
  };
  const addOrUpdateNewInspections = async ({ submitData, status }) => {
    const { res, workOrderMap, workRequestMap } = await convertToSerializable(submitData);

    const isEditMode = !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.id : null;

    const startAndEndTaskLocation = {
      startLatitude: isEditMode ? selectedTasksList[0]?.startLatitude : coordinates?.latitude || '',
      startLongitude: isEditMode
        ? selectedTasksList[0]?.startLongitude
        : coordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };

    const inspectionFormId = !_.isEmpty(currentFormId)
      ? currentFormId
      : !_.isEmpty(selectedTasksList[0])
      ? selectedTasksList[0]?.inspectionFormId
      : null;
    const inspectionTypeId = !_.isEmpty(inspectionType)
      ? inspectionType
      : !_.isEmpty(selectedTasksList[0])
      ? selectedTasksList[0]?.inspectionTypeId
      : null;
    await InspectionService.setNewWorkInspectionData({
      inspectionData: {
        ...(!_.isEmpty(selectedTasksList) && { ...selectedTasksList[0] }),
        ...submitData,
        status,
        ...(siteId && { siteId }),
        ...(equipmentId && { equipmentId }),
        ...(pipelineId && { pipelineId }),
        inspectionFormId: inspectionFormId,
        inspectionTypeId: inspectionTypeId,
        formData: res,
        inspectionWorkOrder: workOrderMap,
        inspectionWorkRequest: workRequestMap,
        ...startAndEndTaskLocation
      },
      inspectionId: !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.id : null
    });
    dispatch?.(bookmarksActions.setBookmarkValidity(true));
    ToasterUtil.showSuccess(`Task saved as ${status}`);
    dirtyRef.current = {
      isDirty,
      dirtyFields,
      navigated: true
    };
    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    } else {
      navigate(-1);
      dirtyRef.current = {
        isDirty: false,
        dirtyFields: {}
      };
    }
  };
  const addOrUpdateNewHSEEvent = async ({ submitData, status }) => {
    const { res, HSEEventTitle } = await convertToSerializable(submitData, 'HSE');

    const isEditMode = !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.id : null;

    const inspectionFormId = !_.isEmpty(currentFormId)
      ? currentFormId
      : !_.isEmpty(selectedTasksList[0])
      ? selectedTasksList[0]?.HSEFormId
      : null;
    const inspectionTypeId = !_.isEmpty(inspectionType)
      ? inspectionType
      : !_.isEmpty(selectedTasksList[0])
      ? selectedTasksList[0]?.HSETypeId
      : null;

    const startAndEndTaskLocation = {
      startLatitude: isEditMode ? selectedTasksList[0]?.startLatitude : coordinates?.latitude || '',
      startLongitude: isEditMode
        ? selectedTasksList[0]?.startLongitude
        : coordinates?.longitude || '',
      completionLatitude: coordinates?.latitude || '',
      completionLongitude: coordinates?.longitude || ''
    };

    await HSEEventService.setNewHSEEventData({
      hseEventData: {
        ...(!_.isEmpty(selectedTasksList) && { ...selectedTasksList[0] }),
        ...submitData,
        status,
        ...(siteId && { siteId }),
        HSEEventTitle,
        inspectionFormId: inspectionFormId,
        inspectionTypeId: inspectionTypeId,
        ...(location && {
          location,
          areaId
        }),

        formData: res,
        ...startAndEndTaskLocation
      },
      hseEventId: !_.isEmpty(selectedTasksList) ? selectedTasksList[0]?.id : null
    });
    dispatch?.(bookmarksActions.setBookmarkValidity(true));
    ToasterUtil.showSuccess(`Record saved as ${status}`);
    dirtyRef.current = {
      isDirty,
      dirtyFields,
      navigated: true
    };
    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    } else {
      navigate(-1);
      dirtyRef.current = {
        isDirty: false,
        dirtyFields: {}
      };
    }
  };

  const resetWorkLog = () => {
    refToTop.current &&
      refToTop.current.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    reset();
  };

  const goBackToHome = ({ localCreatedTasks }) => {
    const a = localCreatedTasks?.filter((x) => x?.status === 'Pending sync') || [];
    if (a?.length === localCreatedTasks?.length) {
      goBack();
    }
  };

  const moveMultipleTasksOrInspectionsToInProgressAndCloseForm = async () => {
    if (workType === WorkTypes.Inspection) {
      for (let index = 0; index < selectedTasksList.length; index++) {
        await onSubmitHandler({
          data: index === currentPageOfSelectedTasks - 1 ? getValues() : {},
          status: 'In progress',
          noCount: true,
          currentPage: index,
          bulk: true
        });
        reset();
      }
      ToasterUtil.showSuccess(`Task saved as In progress`);
      goBack();
    } else if (workType === WorkTypes.WorkOrders) {
      for (let index = 0; index < selectedTasksList.length; index++) {
        const task = selectedTasksList[index];
        await onSubmitHandler({
          data: index === currentPageOfSelectedTasks - 1 ? getValues() : task,
          status: 'In progress',
          noCount: true,
          currentPage: index,
          bulk: true
        });
      }

      ToasterUtil.showSuccess(`Task saved as In progress`);
      goBack();
    } else if (workType === WorkTypes.InspectionAndWorkOrder) {
      for (
        let index = 0;
        index < [selectedTasksList[currentPageOfSelectedTasks - 1]]?.length;
        index++
      ) {
        const task = selectedTasksList[index];

        if (task?.type === 'Inspection') {
          await onSubmitHandler({
            data: index === currentPageOfSelectedTasks - 1 ? getValues() : {},
            status: 'In progress',
            noCount: true,
            currentPage: index
          });
          reset();
        } else if (task?.type === 'Work Order') {
          await onSubmitHandler({
            data: index === currentPageOfSelectedTasks - 1 ? getValues() : task,
            status: 'In progress',
            noCount: true,
            currentPage: index,
            bulk: true
          });
        }
      }
      ToasterUtil.showSuccess(`Task saved as In progress`);

      goBack();
    }
  };

  const moveSingleTaskToInProgressAndRemoveItsIdFromURL = async () => {
    if (
      workType === WorkTypes.Inspection ||
      workType === WorkTypes.NewInspection ||
      workType === WorkTypes.NewHSE
    ) {
      await onSubmitHandler({
        data: getValues(),

        status:
          selectedTasksList[currentPageOfSelectedTasks - 1]?.status === 'To do'
            ? 'In progress'
            : selectedTasksList[currentPageOfSelectedTasks - 1]?.status,

        saveAndClose: true
      });
    } else if (
      workType === WorkTypes.WorkOrders ||
      workType === WorkTypes.NewWorkRequest ||
      workType === WorkTypes.NewWorkOrder
    ) {
      await onSubmitHandler({
        data: getValues(),
        status:
          selectedTasksList[currentPageOfSelectedTasks - 1]?.status === 'Pending sync'
            ? 'Pending sync'
            : 'In progress',
        saveAndClose: true
      });
    } else if (workType === WorkTypes.InspectionAndWorkOrder) {
      if (selectorData?.type === 'Work Order') {
        await onSubmitHandler({
          data: getValues(),
          status:
            selectedTasksList[currentPageOfSelectedTasks - 1]?.status === 'Pending sync'
              ? 'Pending sync'
              : 'In progress',
          saveAndClose: true
        });
      } else {
        await onSubmitHandler({
          data: getValues(),

          status:
            selectedTasksList[currentPageOfSelectedTasks - 1]?.status === 'To do'
              ? 'In progress'
              : selectedTasksList[currentPageOfSelectedTasks - 1]?.status,
          // noCount: true,
          saveAndClose: true
        });
      }
    }
  };

  const moveSingleFormToInProgress = async () => {
    if (workType === WorkTypes.NewInspection || workType === WorkTypes.NewHSE) {
      await onSubmitHandler({
        data: getValues(),
        status: 'In progress'
      });
    } else if (workType === WorkTypes.NewWorkOrder || workType === WorkTypes.NewWorkRequest) {
      handleSubmit((data) =>
        onSubmitHandler({
          data: data,
          status: 'In progress'
        })
      )();
    }
  };
  const moveSingleTasksOrInspectionsToInProgressAndCloseForm = async () => {
    if (
      workType === WorkTypes.NewInspection ||
      workType === WorkTypes.Inspection ||
      workType === WorkTypes.NewHSE
    ) {
      await onSubmitHandler({
        data: getValues(),
        status: selectedTasksList[currentPageOfSelectedTasks - 1].status
      });
    } else if (
      workType === WorkTypes.NewWorkOrder ||
      workType === WorkTypes.NewWorkRequest ||
      workType === WorkTypes.WorkOrders
    ) {
      await onSubmitHandler({
        data: getValues(),
        status: selectedTasksList[currentPageOfSelectedTasks - 1].status
      });
    }
  };

  const handleSave = ({ bulk = false }) => {
    if (moveToInProgress) {
      if (isNewForm) moveSingleFormToInProgress();
      else {
        if (bulk) moveMultipleTasksOrInspectionsToInProgressAndCloseForm();
        else {
          moveSingleTaskToInProgressAndRemoveItsIdFromURL();
        }
      }
    } else {
      if (isNewForm) {
        handleSubmit((data) =>
          onSubmitHandler({
            data: data,
            status: 'Pending sync'
          })
        )();
      } else {
        if (bulk) moveSingleTasksOrInspectionsToInProgressAndCloseForm();
        else {
          moveSingleTaskToInProgressAndRemoveItsIdFromURL();
        }
      }
    }

    setSaveInProgress(false);
  };

  const getPhotosAndDocumentsIdsListThatRemoveFroIndexDB = ({
    photoIdsInHookFormNow = [],
    documentIdsInHookFormNow = []
  }) => {
    const { photos, documents } =
      workType !== WorkTypes.NewWorkRequest || workType !== WorkTypes.NewWorkOrder
        ? getPhotosAndDocumentsDataFromSelectedInspection(selectorData?.formData, true)
        : { photos: [], documents: [] };

    let currentTaskPhotos =
      (workType === WorkTypes.NewWorkRequest || workType === WorkTypes.NewWorkOrder) &&
      selectorData?.photos?.length > 0
        ? selectorData?.photos
        : photos;
    let currentTaskDocuments =
      workType === (WorkTypes.NewWorkRequest || workType === WorkTypes.NewWorkOrder) &&
      selectorData?.documents?.length > 0
        ? selectorData?.documents
        : documents;

    currentTaskPhotos = selectorData?.workOrderNumber
      ? [...currentTaskPhotos, ...(selectorData?.photos || [])]
      : currentTaskPhotos;
    currentTaskDocuments = selectorData?.workOrderNumber
      ? [...currentTaskDocuments, ...(selectorData?.documents || [])]
      : currentTaskDocuments;

    const removeAblePhotoIds = photoIdsInHookFormNow?.filter(
      (item) => !currentTaskPhotos.find((taskPhotoId) => taskPhotoId.id === item.id)
    );

    const removeAbleDocumentIds = documentIdsInHookFormNow?.filter(
      (item) => !currentTaskDocuments.find((taskDocumentId) => taskDocumentId.id === item.id)
    );

    return {
      removeAblePhotoIds,
      removeAbleDocumentIds
    };
  };

  const removeAssetsFromIndexDB = async () => {
    let { photos, documents } =
      workType !== WorkTypes.NewWorkRequest || workType !== WorkTypes.NewWorkOrder
        ? getPhotosAndDocumentsDataFromSelectedInspection(watch(), true)
        : { photos: [], documents: [] };

    photos = [...photos, ...(watch('photo') || [])];
    documents = [...documents, ...(watch('document') || [])];

    if (isNewForm || selectorData?.status === 'To do') {
      await deleteNewTasksAssetFromIndexDB({ photos, documents, deleteAll: true });

      setDiscordButtonLoading(false);
    } else {
      const { removeAblePhotoIds, removeAbleDocumentIds } =
        getPhotosAndDocumentsIdsListThatRemoveFroIndexDB({
          photoIdsInHookFormNow: photos,
          documentIdsInHookFormNow: documents
        });

      await deleteNewTasksAssetFromIndexDB({
        photos: removeAblePhotoIds,
        documents: removeAbleDocumentIds,
        deleteAll: true
      });
      setDiscordButtonLoading(false);
    }
  };

  const handleDiscard = async () => {
    setDiscordButtonLoading(true);
    await removeAssetsFromIndexDB();

    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    }
    setSaveInProgress(false);
    setDiscordCheck(false);
    ToasterUtil.showSuccess(`Task changes were discarded`);
    goBack();
  };
  const goBack = () => {
    const allQueryList = Object.fromEntries([...searchParams]);
    let navigateURL = searchParams.get('from').includes('?from=')
      ? `${searchParams.get('from')}&back=true`
      : `${searchParams.get('from')}?back=true`;
    if (allQueryList?.recurringTemplateQuery) {
      const recurringQuery = JSON.parse(allQueryList?.recurringTemplateQuery);
      navigateURL = navigateURL?.concat(
        `&type=${recurringQuery?.type}&templates=${recurringQuery?.templates}&recurringTemplate=${recurringQuery?.recurringTemplate}`
      );
    }
    if (clickAssetOpliiDesktopLink) {
      dirtyRef.current = {
        isDirty,
        dirtyFields,
        assetClick: true,
        navigated: false
      };
      dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
      window.open(clickAssetOpliiDesktopLink, '_self');
    } else {
      blocker.state === 'blocked' ? blocker.proceed() : navigate(-1);
      dirtyRef.current = {
        isDirty: false,
        dirtyFields: {}
      };
    }
  };

  const removeFromTasks = () => {
    if (selectedTasksList?.length > 1) {
      const copy = [...selectedTasksList.filter((task) => task?.id !== selectorData?.id)];
      if (currentPageOfSelectedTasks === selectedTasksList?.length)
        setCurrentPageOfSelectedTasks((prev) => prev - 1);

      setSelectedTasksList([...copy]);
      const selectedOrdersIds = orderIds
        .split(',')
        .filter((a) => a !== selectorData?.id)
        .join(',');

      navigate(
        `/work-order/?from=${searchParams.get(
          'from'
        )}&type=${workType}&orderIds=${selectedOrdersIds}`,
        {
          state: {
            from: 'back'
          },
          replace: true
        }
      );
    }
  };

  const nextTask = async () => {
    let dataChange = isDirty && Object.keys(dirtyFields)?.length;
    dataChange && (await removeAssetsFromIndexDB());
    reset();
    if (currentPageOfSelectedTasks - 1 < selectedTasksList?.length - 1) {
      let copy = [...selectedTasksList];
      copy[currentPageOfSelectedTasks - 1] = {
        ...copy[currentPageOfSelectedTasks - 1],
        ...{
          technicalNotes: watch('technicalNotes'),
          workStarted: watch('workStarted'),
          workCompletedDate: watch('workCompletedDate'),
          regularHours: watch('regularHours'),
          overTimeHours: watch('overTimeHours'),
          actualLabour: watch('actualLabour'),
          actualParts: watch('actualParts'),

          reading: watch('reading'),
          unit: watch('unit'),
          parts: watch('parts')
        }
      };
      setSelectedTasksList([...copy]);
      setCurrentPageOfSelectedTasks((prev) => prev + 1);
    }
  };

  const previousTask = async () => {
    let dataChange = isDirty && Object.keys(dirtyFields)?.length;
    dataChange && (await removeAssetsFromIndexDB());
    if (currentPageOfSelectedTasks - 1 > 0) {
      let copy = [...selectedTasksList];
      setSelectedTasksList([...copy]);
      setCurrentPageOfSelectedTasks((prev) => prev - 1);
    }
  };

  const closeDiv = () => {
    const div = document.querySelector(`.${style.openDiv}`);
    div.style.width = '0px';
    div.style.right = '-100%';
    div.style.zIndex = '-1';
    setTimeout(() => {
      setMoreMenuToggle(false);
    }, 300);
  };

  const pageHeaderBackClick = (dirtyCheck) => {
    dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
    if (dirtyCheck || (isDirty && Object.keys(dirtyFields)?.length)) setSaveInProgress(true);
    else goBack();
  };

  const pageHeaderBackClickOnCheckForm = () => {
    dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(false));
    if (isDirty && Object.keys(dirtyFields)?.length) setDiscordCheck(true);
    else goBack();
  };

  const handleTouchStart = (e) => setTouchStartX(e.touches[0].clientX);

  const handleTouchMove = (e) => setTouchEndX(e.touches[0].clientX);

  const handleTouchEnd = () => {
    if (touchStartX && touchEndX) {
      const diff = (touchStartX - touchEndX) / window.innerWidth;
      const threshold = 0.1;
      if (diff > threshold && active < tabs?.length - 1) setActive(active + 1);
      else if (diff < -threshold && active > 0) setActive(active - 1);
    }
    setTouchStartX(null);
    setTouchEndX(null);
  };

  return isLoading3 ? (
    <div style={{ backgroundColor: '#F5F7FA', width: '100%' }}>
      <LoadingRound />
    </div>
  ) : (
    <>
      {workType === WorkTypes.NewCheck || workType === WorkTypes.Check ? (
        <>
          <CheckFormWrapper
            componentsNames={componentsNames}
            siteIds={siteIds}
            equipmentIds={equipmentIds}
            pipelineIds={pipelineIds}
            structureIds={structureIds}
            workType={workType}
            activeBtn={activeBtn}
            setActiveBtn={setActiveBtn}
            register={register}
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            getValues={getValues}
            handleSubmit={handleSubmit}
            onSubmitHandler={onSubmitHandler}
            dropdownMenuMapList={dropdownMenuMapList}
            ref={{ menuItemEls, formWrapperFormRef, dirtyRef }}
            appliesToData={_.isEmpty(appliesToDataResponse) ? selectorData : appliesToDataResponse}
            menuNames={menuNames}
            formSectionsWithSubSections={formSectionsWithSubSections}
            autoPopulateData={autoPopulateData}
            formAppliesTo={formAppliesTo}
            pageHeaderTitle={pageHeaderTitle}
            handleBackClick={pageHeaderBackClickOnCheckForm}
            removeFromTasks={removeFromTasks}
            selectedTasksList={selectedTasksList}
            checkType={checkType || selectorData?.inspectionTypeId}
          />
        </>
      ) : (
        <div
          className={`${style.tabsMain}`}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className={style.mainMin} ref={refToTop}>
            <div className={style.relativeClass}>
              <div className={style.fixedHeader}>
                <PageHeader
                  moreMenuToggle={moreMenuToggle}
                  setMoreMenuToggle={setMoreMenuToggle}
                  workType={workType}
                  selectedTasksList={selectedTasksList}
                  handleBackClick={pageHeaderBackClick}
                  currentPageOfSelectedTasks={currentPageOfSelectedTasks}
                  pageHeaderText={pageHeaderText}
                />
                <div className={style.header}>{pageHeaderTitle}</div>
                <ScrollNavigation
                  onClickLink={onClickTab}
                  ref={{ menuItemEls, formWrapperFormRef }}
                  menuNames={
                    workType === WorkTypes.NewWorkOrder && activeBtn === 'Synced'
                      ? [...menuNames, { id: 'work-log', name: 'Work log' }]
                      : menuNames
                  }
                />
              </div>

              <div
                className={`${style.formHeight} removeScrollbar`}
                ref={formWrapperFormRef}
                id="scrollRoot"
              >
                <FormWrapperForm
                  componentsNames={componentsNames}
                  siteIds={siteIds}
                  equipmentIds={equipmentIds}
                  pipelineIds={pipelineIds}
                  structureIds={structureIds}
                  workType={workType}
                  activeBtn={activeBtn}
                  setActiveBtn={setActiveBtn}
                  register={register}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  getValues={getValues}
                  handleSubmit={handleSubmit}
                  onSubmitHandler={onSubmitHandler}
                  dropdownMenuMapList={dropdownMenuMapList}
                  ref={{ menuItemEls, formWrapperFormRef }}
                  appliesToData={
                    _.isEmpty(appliesToDataResponse) ? selectorData : appliesToDataResponse
                  }
                  menuNames={menuNames}
                  formSectionsWithSubSections={formSectionsWithSubSections}
                  autoPopulateData={autoPopulateData}
                  formAppliesTo={formAppliesTo}
                />
              </div>

              {moreMenuToggle && <div className={style.backdrop} onClick={closeDiv}></div>}

              <div
                className={style.openDiv}
                style={{
                  width: moreMenuToggle ? '346px' : '0px',
                  right: moreMenuToggle ? '0px' : '-100%',
                  zIndex: moreMenuToggle ? 2000 : -1
                }}
              >
                {moreMenuToggle && (
                  <Sidebar
                    ref={{ dirtyRef }}
                    closeDiv={closeDiv}
                    setMoreMenuToggle={setMoreMenuToggle}
                    selectedTasksList={selectedTasksList}
                    current={currentPageOfSelectedTasks - 1}
                    nextTask={nextTask}
                    previousTask={previousTask}
                    saveAndClose={() => handleSave({ bulk: false })}
                    removeFromTasks={removeFromTasks}
                    trigger={trigger}
                    workType={workType}
                    selectorData={selectorData}
                    orderIdsLength={orderIdsLength}
                    watch={watch}
                    updateAssignedWorkOrder={async ({ status }) => {
                      await addOrUpdateAssignedWorkOrders({ submitData: getValues(), status });
                    }}
                    resetAssignedWorkOrder={async () => {
                      await addOrUpdateAssignedWorkOrders({
                        submitData: {},
                        status: 'To do',
                        reset: true
                      });
                    }}
                    updateNewWorkRequest={async ({ status }) => {
                      const submitData = getDataFromObjects({ data: getValues(), workType });
                      addOrUpdateNewWOrkRequest({
                        submitData,
                        status
                      });
                    }}
                    resetNewWorkRequest={async () => {
                      addOrUpdateNewWOrkRequest({ submitData: {}, status: 'To do', reset: true });
                    }}
                    updateNewWorkOrderToDoOrSynced={async ({ status }) => {
                      const submitData = getDataFromObjects({ data: getValues(), workType });
                      if (activeBtn === 'To do')
                        await addOrUpdateNewWorkOrderTodo({
                          submitData,
                          status
                        });
                      else
                        await addOrUpdateNewWorkOrderSynced({
                          submitData,
                          status
                        });
                    }}
                    updateAssignedInspection={async ({ status }) => {
                      await addOrUpdateAssignedInspections({ submitData: getValues(), status });
                    }}
                    resetAssignedWorkInspection={async () => {
                      await addOrUpdateAssignedInspections({
                        submitData: {},
                        status: 'To do',
                        reset: true
                      });
                    }}
                    updateNewInspection={async ({ status }) => {
                      await addOrUpdateNewInspections({ submitData: getValues(), status });
                    }}
                    updateNewHSEEvent={async ({ status }) => {
                      await addOrUpdateNewHSEEvent({ submitData: getValues(), status });
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {saveInProgress && (
        <SaveModal
          open={saveInProgress}
          setOpen={discordButtonLoading ? () => {} : setSaveInProgress}
          inProgress={moveToInProgress}
          saveChanges={!moveToInProgress}
          onDiscard={handleDiscard}
          onSave={() => handleSave({ bulk: true })}
          disabledDeleteButton={discordButtonLoading}
        />
      )}

      {discordCheck && (
        <DeleteModal
          open={discordCheck}
          setOpen={discordButtonLoading ? () => {} : setDiscordCheck}
          discordChangesAndResetCheck
          recordType={'check'}
          handleDelete={handleDiscard}
          disabledDeleteButton={discordButtonLoading}
        />
      )}
    </>
  );
};

export default React.memo(WorkOrder);
