import React from 'react';

import MoreInformation from '@components/more-information';
import Icon from '@components/icon-component';

import PermissionRestrict from 'hoc/permission-restrict';
import { PERMISSIONS } from 'permissions/constants';

const EquipmentDetailsMoreInfo = ({ moreInfoDataPointsMap, setEditEquipment }) => {
  return (
    <>
      <MoreInformation
        info={Object?.keys(moreInfoDataPointsMap)?.map((a) => {
          return {
            title: moreInfoDataPointsMap[a]?.value || null,
            heading: moreInfoDataPointsMap[a]?.title || null
          };
        })}
      />
      <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATEEDIT_EQUIPMENT}>
        <div className="flex justify-end ">
          <div className="btnEdit" onClick={() => setEditEquipment(true)}>
            <Icon name={'edit-btn'} alt="" />
          </div>
        </div>
      </PermissionRestrict>
    </>
  );
};

export default EquipmentDetailsMoreInfo;
