import { filterTasks } from '../../pages/tasks-records/tasks-records-list/helper';

const taskBookMarkCount = ({
  filters,
  tasksList,
  siteId,
  equipmentId,
  pipelineId,
  structureId,
  store
}) => {
  let { tasks = [] } = filterTasks({
    filters: filters?.filters || {},
    type: null,
    data: tasksList || [],
    store
  });

  const filteredTasks =
    tasks?.filter((t) => {
      if (siteId) {
        // Filter tasks by siteId
        return (
          t.siteId === siteId ||
          t?.relatedAssetId === siteId ||
          t?.primaryAssetId === siteId ||
          t?.sites?.includes(siteId)
        );
      } else if (equipmentId) {
        // Filter tasks by equipmentId
        return (
          t.equipmentId === equipmentId ||
          t?.relatedAssetId === equipmentId ||
          t?.primaryAssetId === equipmentId ||
          t?.equipments?.includes(equipmentId)
        );
      } else if (pipelineId) {
        // Filter tasks by pipelineId
        return (
          t.pipelineId === pipelineId ||
          t?.relatedAssetId === pipelineId ||
          t?.primaryAssetId === pipelineId ||
          t?.pipelines?.includes(pipelineId)
        );
      } else if (structureId) {
        // Filter tasks by structureId
        return (
          t.structureId === structureId ||
          t?.relatedAssetId === structureId ||
          t?.primaryAssetId === structureId ||
          t?.structures?.includes(structureId)
        );
      } else return true;
    }) || tasks;

  return {
    count: filteredTasks.length || 0,
    filters: encodeURIComponent(JSON.stringify(filters))
  };
};

export default taskBookMarkCount;
