/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import LoadingIcon from '@components/loading-lottie';
import Modal from '../..';
import Icon from '@components/icon-component';

import style from './style.module.scss';

const localProgressBar = {
  0: 'Checking out what’s new',
  1: 'Checking out what’s new',
  2: 'Checking out what’s new',
  3: 'Hauling 100 equipment',
  4: 'Processing the particulars',
  5: 'Processing the particulars',
  6: 'Processing the particulars',
  7: 'Processing the particulars',
  8: 'Establishing 100 sites',
  9: 'Pulling 100 pipeline',
  10: 'Setting up 100 structures',
  11: 'Grabbing 100 work orders',
  12: 'Picking up 100 inspections',
  13: 'Loading up 100 checks',
  14: 'Snapping up 100 forms'
};

const SyncingInProgressModal = ({
  open,
  setOpen,
  inProgress = false,
  syncingComplete = false,
  syncingFailed = false,
  pendingOrderPhotosDocumentsLength,
  showRetryPhotoCount,
  showRetryDocumentCount,
  syncDone,
  retryEvent,
  workOrderInspectionsAndChecksIsSync = undefined,
  cancelData
}) => {
  const { loading } = useSelector((state) => state.globalProgressBar);
  const { count = {}, refreshModalObject } = useSelector((state) => state.mobileSyncCount);

  const staticProgressBar = useMemo(() => {
    return refreshModalObject ? refreshModalObject : localProgressBar;
  }, [count, refreshModalObject]);

  const syncingModalIcon = React.useMemo(() => {
    if (inProgress) return <LoadingIcon />;
    else if (syncingComplete) return <Icon name={'complete'} />;
    else if (syncingFailed) return <Icon name={'failed'} />;
  }, [inProgress, syncingComplete, syncingFailed]);

  const statusText = React.useMemo(() => {
    if (inProgress) return <>Syncing in progress</>;
    else if (syncingComplete) return <>Syncing complete</>;
    else if (syncingFailed) return <>Syncing failed</>;
  }, [inProgress, syncingComplete, syncingFailed]);

  const modalTextUpper = React.useMemo(() => {
    if (inProgress)
      return (
        <>
          {workOrderInspectionsAndChecksIsSync ? (
            <>
              And now for a quick refresh ...
              <div className={style.progressBarContainer}>
                <progress max={14} value={loading}></progress>
                <p>{staticProgressBar[loading]}</p>
              </div>
            </>
          ) : (
            'Hold on. We’re sending in all of your hard work.'
          )}
        </>
      );
    else if (syncingComplete) return <>Oh yeah!</>;
    else if (syncingFailed) return <>Oh no. If your internet connection isn’t solid, try later.</>;
  }, [
    inProgress,
    syncingComplete,
    syncingFailed,
    workOrderInspectionsAndChecksIsSync,
    loading,
    staticProgressBar
  ]);

  const textBtn = React.useMemo(() => {
    if (syncingFailed) return <>I’ll try later</>;
  }, [inProgress, syncingComplete, syncingFailed]);

  const modalBodyInner = React.useMemo(() => {
    return (
      <div>
        <div
          style={{
            padding: workOrderInspectionsAndChecksIsSync ? '0px' : '14px 16px 16px 16px',
            marginTop: workOrderInspectionsAndChecksIsSync ? '32px' : '0px'
          }}
        >
          {!workOrderInspectionsAndChecksIsSync && (
            <ul className={`${style['op-check-list']}`}>
              {pendingOrderPhotosDocumentsLength?.inspection > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.inspection > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : pendingOrderPhotosDocumentsLength?.inspection === syncDone.inspection
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.inspection || 0}/{pendingOrderPhotosDocumentsLength.inspection}{' '}
                  inspections
                </li>
              )}

              {pendingOrderPhotosDocumentsLength?.checks > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.checks > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : pendingOrderPhotosDocumentsLength?.checks === syncDone.checks
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.checks || 0}/{pendingOrderPhotosDocumentsLength.checks} checks
                </li>
              )}
              {pendingOrderPhotosDocumentsLength?.inspectionPhotos > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.inspectionPhotos > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : pendingOrderPhotosDocumentsLength?.inspectionPhotos ===
                        syncDone.inspectionPhotos
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.inspectionPhotos || 0}/
                  {pendingOrderPhotosDocumentsLength.inspectionPhotos} inspection photos
                </li>
              )}
              {pendingOrderPhotosDocumentsLength?.workOrder > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.workOrder > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : pendingOrderPhotosDocumentsLength?.workOrder === syncDone.workOrder
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.workOrder || 0}/{pendingOrderPhotosDocumentsLength?.workOrder} work
                  orders
                </li>
              )}
              {pendingOrderPhotosDocumentsLength?.workRequest > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.workRequest > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : pendingOrderPhotosDocumentsLength?.workRequest === syncDone.workRequest
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.workRequest || 0}/{pendingOrderPhotosDocumentsLength?.workRequest} Work
                  requests
                </li>
              )}
              {pendingOrderPhotosDocumentsLength?.hseEvents > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.hseEvents > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : pendingOrderPhotosDocumentsLength?.hseEvents === syncDone.hseEvents
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.hseEvents || 0}/{pendingOrderPhotosDocumentsLength?.hseEvents} HSE
                  events
                </li>
              )}
              {pendingOrderPhotosDocumentsLength?.photos > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.photos > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : pendingOrderPhotosDocumentsLength?.photos === syncDone.photos
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.photos || 0}/{pendingOrderPhotosDocumentsLength.photos} photos
                </li>
              )}
              {pendingOrderPhotosDocumentsLength?.documents > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.documents > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : pendingOrderPhotosDocumentsLength?.documents === syncDone.documents
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.documents || 0}/{pendingOrderPhotosDocumentsLength.documents} documents
                </li>
              )}

              {showRetryPhotoCount > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.failedImageCount > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : showRetryPhotoCount === syncDone.failedImageCount
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.failedImageCount || 0}/{showRetryPhotoCount} photos (retry)
                </li>
              )}

              {showRetryDocumentCount > 0 && (
                <li
                  className={`input-medium-bullet ${
                    inProgress
                      ? syncDone.failedDocumentCount > 0
                        ? style.successSync
                        : style.syncNotComplete
                      : showRetryDocumentCount === syncDone.failedDocumentCount
                      ? style.successSync
                      : style.failedSync
                  }`}
                >
                  {syncDone.failedDocumentCount || 0}/{showRetryDocumentCount} documents (retry)
                </li>
              )}
            </ul>
          )}
          {!_.isUndefined(workOrderInspectionsAndChecksIsSync) && syncingComplete && (
            <p className={style.refreshInfo}>and a fresh cut of data!</p>
          )}
        </div>

        <div className={style.modalBtn} style={{ marginTop: '16px' }}>
          <button
            className="btn-fill button-large"
            onClick={
              syncingFailed
                ? () => retryEvent()
                : inProgress
                ? () => cancelData()
                : () => setOpen(false)
            }
            disabled={!workOrderInspectionsAndChecksIsSync && !syncingFailed && !syncingComplete}
          >
            {syncingFailed ? 'Retry' : syncingComplete ? 'CLOSE' : 'CANCEL'}
          </button>
        </div>
      </div>
    );
  }, [
    inProgress,
    syncingComplete,
    syncingFailed,
    pendingOrderPhotosDocumentsLength,
    workOrderInspectionsAndChecksIsSync,
    showRetryPhotoCount,
    showRetryDocumentCount,
    syncDone
  ]);
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={syncingModalIcon}
      statusText={statusText}
      modalTextUpper={modalTextUpper}
      textBtn={textBtn}
      modalImageClassName={inProgress && style.modalImage}
      showLine={!workOrderInspectionsAndChecksIsSync}
    >
      {modalBodyInner}
    </Modal>
  );
};

export default SyncingInProgressModal;
