/* eslint-disable no-unused-vars */
import { store } from 'redux/store';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import http from './http.service';
import Promisable from './promisable.service';

import { bookmarksActions } from 'redux/slices/bookmarks';
import { getAppDispatch } from '@utilities/dispatch.util';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';

const BookmarksService = {
  getDefaultBookmarks: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(bookmarksActions.setLoading(true));
    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      const [success, error] = await Promisable.asPromise(http.get(`/Bookmarks?page=0`));

      if (_.size(success?.data?.items) > 0)
        dispatch?.(
          bookmarksActions.setBookmarks({
            bookmarks:
              success?.data?.items?.map((x) => {
                return { ...x, sync: true };
              }) || []
          })
        );
      dispatch?.(bookmarksActions.setLoading(false));
      dispatch?.(globalProgressBarAction.setLoadStatus(8));

      return [success, error];
    } else throw new Error('Api failed to update');
  },

  getBookmarks: async (data) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookmarksActions.setLoading(true));

    const [success, error] = await Promisable.asPromise(http.get(`/Bookmarks?page=0`));

    if (success?.data?.items)
      dispatch?.(bookmarksActions.setBookmarks({ bookmarks: success?.data?.items || [] }));
    dispatch?.(bookmarksActions.setLoading(false));
    return [success, error];
  },
  addBookmarks: async (data) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookmarksActions.setLoading(true));
    const { bookmarks } = store.getState();
    const bookmarksList = bookmarks?.bookmarks || [];

    dispatch?.(
      bookmarksActions.setBookmarks({
        bookmarks:
          [
            ...bookmarksList,
            {
              bookmarkId: uuidv4(),
              sync: false,
              atAGlanceOrder: bookmarksList?.length + 1,
              ...data
            }
          ] || []
      })
    );
    dispatch?.(bookmarksActions.setLoading(false));
    return true;
  },

  createBookmark: async (data) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookmarksActions.setLoading(true));
    await Promisable.asPromise(http.post(`/Bookmarks`, data));
    dispatch?.(bookmarksActions.setLoading(false));
    return true;
  },
  updateBookmarks: (updateBookmark) => {
    const dispatch = getAppDispatch();
    const { bookmarks } = store.getState();

    dispatch?.(bookmarksActions.setLoading(true));
    const updateBookmarkMap = updateBookmark?.reduce((acc, curr, index) => {
      const id = (curr?.bookmarkId && curr?.bookmarkId?.toString()) || '';
      if (id) {
        acc[id || index] = index + 1;
        return acc;
      }
    }, {});

    const visibleBookmarks = bookmarks?.bookmarks?.filter((x) => x?.atAGlance) || [];
    const unVisibleBookmarks = bookmarks?.bookmarks?.filter((x) => !x?.atAGlance) || [];

    const visibleBookmarksOrderChange = visibleBookmarks?.map((bookmark) => {
      const id = (bookmark?.bookmarkId && bookmark?.bookmarkId?.toString()) || '';
      return {
        ...bookmark,
        atAGlanceOrder: updateBookmarkMap[id],
        update: true
      };
    });

    dispatch?.(
      bookmarksActions.setBookmarks({
        bookmarks: [...visibleBookmarksOrderChange, ...unVisibleBookmarks]
      })
    );

    dispatch?.(bookmarksActions.setLoading(false));
  },

  updateBookmarksNameAndAtAGlance: ({ name, atAGlance, bookmarkId }) => {
    const dispatch = getAppDispatch();
    const { bookmarks } = store.getState();
    dispatch?.(bookmarksActions.setLoading(true));
    const copy = [...bookmarks.bookmarks];
    const newList = copy?.map((c) => {
      if (c?.bookmarkId === bookmarkId) {
        return {
          ...c,
          name,
          atAGlance,
          update: true
        };
      } else return c;
    });

    dispatch?.(
      bookmarksActions.setBookmarks({
        bookmarks: [...newList]
      })
    );

    dispatch?.(bookmarksActions.setLoading(false));
  },
  deleteBookmarks: async ({ bookmarkId }) => {
    const dispatch = getAppDispatch();
    const { bookmarks } = store.getState();
    dispatch?.(bookmarksActions.setLoading(true));
    const copy = [...bookmarks.bookmarks];
    const newList = copy?.map((c) => {
      if (c?.bookmarkId === bookmarkId) {
        return {
          ...c,
          atAGlance: false,
          isDelete: true
        };
      } else return c;
    });

    dispatch?.(
      bookmarksActions.setBookmarks({
        bookmarks: [...newList]
      })
    );
    dispatch?.(bookmarksActions.setLoading(false));
    return true;
  },
  updateBookmarksDataOnSync: async ({ name, atAGlance, bookmarkId, atAGlanceOrder }) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookmarksActions.setLoading(true));
    await Promisable.asPromise(
      http.patch(`/Bookmarks/${bookmarkId}`, {
        name: {
          op: 'Replace',
          value: name
        },
        atAGlance: {
          op: 'Replace',
          value: atAGlance
        },
        atAGlanceOrder: {
          op: 'Replace',
          value: atAGlanceOrder
        }
      })
    );
    dispatch?.(bookmarksActions.setLoading(false));
    return true;
  },
  deleteBookmarksOnSync: async ({ bookmarkId }) => {
    const dispatch = getAppDispatch();
    dispatch?.(bookmarksActions.setLoading(true));
    await Promisable.asPromise(http.delete(`/Bookmarks/${bookmarkId}`));
    dispatch?.(bookmarksActions.setLoading(false));
    return true;
  }
};

export default BookmarksService;

const defaultBookmarksList = [
  {
    userId: '4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
    name: 'New this week',
    type: 'default bookmark',
    atAGlance: true,
    filters:
      '{"filters":{"proximity":{"value":0},"area":{"value":[]},"siteType":{"value":[]},"siteStatus":{"value":[]},"taskType":{"value":[]},"recurringTemplate":{"value":[]},"assign":{"value":[]},"workOrderPriority":{"value":[]},"download":{"value":["Week (7 days)"],"selected":"past"},"dueDate":{"value":[],"selected":"next"},"scheduledDate":{"value":[],"selected":"next"}},"sort":{"NEWEST":true},"tab":"To do"}',
    atAGlanceOrder: 1,
    listing: '/task-records'
  },

  {
    userId: '4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
    name: 'To do',
    type: 'default bookmark',
    atAGlance: true,
    filters:
      '{"filters":{"proximity":{"value":0},"area":{"value":[]},"siteType":{"value":[]},"siteStatus":{"value":[]},"taskType":{"value":[]},"taskStatus":{"value":["To do"]},"recurringTemplate":{"value":[]},"assign":{"value":[]},"workOrderPriority":{"value":[]},"download":{"value":[],"selected":"past"},"dueDate":{"value":[],"selected":"next"},"scheduledDate":{"value":[],"selected":"next"}},"sort":{"DISTANCE":true},"tab":"To do"}',
    atAGlanceOrder: 2,
    listing: '/task-records'
  },

  {
    userId: '4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
    name: 'In progress',
    type: 'default bookmark',
    atAGlance: true,
    filters:
      '{"filters":{"proximity":{"value":0},"area":{"value":[]},"siteType":{"value":[]},"siteStatus":{"value":[]},"taskType":{"value":[]},"taskStatus":{"value":["In progress"]},"recurringTemplate":{"value":[]},"assign":{"value":[]},"workOrderPriority":{"value":[]},"download":{"value":[],"selected":"past"},"dueDate":{"value":[],"selected":"next"},"scheduledDate":{"value":[],"selected":"next"}},"sort":{"DISTANCE":true},"tab":"In progress"}',
    atAGlanceOrder: 3,
    listing: '/task-records'
  },

  {
    userId: '4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
    name: 'Pending sync',
    type: 'default bookmark',
    atAGlance: true,
    filters:
      '{"filters":{"proximity":{"value":0},"area":{"value":[]},"siteType":{"value":[]},"siteStatus":{"value":[]},"taskType":{"value":[]},"taskStatus":{"value":["Pending sync"]},"recurringTemplate":{"value":[]},"assign":{"value":[]},"workOrderPriority":{"value":[]},"download":{"value":[],"selected":"past"},"dueDate":{"value":[],"selected":"next"},"scheduledDate":{"value":[],"selected":"next"}},"sort":{"DISTANCE":true},"tab":"Pending sync"}',
    atAGlanceOrder: 4,
    listing: '/task-records'
  },

  {
    userId: '4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
    name: 'Due today',
    type: 'default bookmark',
    atAGlance: true,
    filters:
      '{"filters":{"proximity":{"value":0},"area":{"value":[]},"siteType":{"value":[]},"siteStatus":{"value":[]},"taskType":{"value":[]},"taskStatus":{"value":["To do"]},"recurringTemplate":{"value":[]},"assign":{"value":[]},"workOrderPriority":{"value":[]},"download":{"value":[],"selected":"past"},"dueDate":{"value":[],"selected":"next"},"scheduledDate":{"value":[],"selected":"next"}},"sort":{"DISTANCE":true},"tab":"To do"}',
    atAGlanceOrder: 5,
    listing: '/task-records'
  },

  {
    userId: '4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
    name: 'Overdue',
    type: 'default bookmark',
    atAGlance: true,
    filters:
      '{"filters":{"proximity":{"value":0},"area":{"value":[]},"siteType":{"value":[]},"siteStatus":{"value":[]},"taskType":{"value":[]},"taskStatus":{"value":["To do"]},"recurringTemplate":{"value":[]},"assign":{"value":[]},"workOrderPriority":{"value":[]},"download":{"value":["Unlimited"],"selected":"past"},"dueDate":{"value":[],"selected":"next"},"scheduledDate":{"value":[],"selected":"next"}},"sort":{"DISTANCE":true},"tab":"To do"}',
    atAGlanceOrder: 6,
    listing: '/task-records'
  },

  {
    userId: '4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
    name: 'Sync this week',
    type: 'default bookmark',
    atAGlance: true,
    filters:
      '{"filters":{"proximity":{"value":0},"area":{"value":[]},"siteType":{"value":[]},"siteStatus":{"value":[]},"taskType":{"value":[]},"taskStatus":{"value":["Pending sync"]},"recurringTemplate":{"value":[]},"assign":{"value":[]},"workOrderPriority":{"value":[]},"download":{"value":["Week (7 days)"],"selected":"past"},"dueDate":{"value":[],"selected":"next"},"scheduledDate":{"value":[],"selected":"next"}},"sort":{"DISTANCE":true},"tab":"To do"}',
    atAGlanceOrder: 7,
    listing: '/task-records'
  }
];
defaultBookmarksList?.filter;
