import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Icon from '@components/icon-component';

import style from './style.module.scss';

const AssetBundle = ({ iconName, name, index, menuItems, bundle, setMoreMenuToggle }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div
        className={`${style.assetBundler} ${index === menuItems.length - 1 && style.borderBottom} `}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Icon name={iconName} width="24" height="24" />
        <span>{name}</span>

        <Icon name={open ? 'up' : 'down'} width="24" height="24" className={style.icon} />
      </div>

      {open &&
        bundle?.map((asset, assetIndex) => (
          <div key={asset?.name}>
            <Link
              to={`/${asset?.link}`}
              onClick={() => {
                setMoreMenuToggle(false);
              }}
            >
              <div
                style={{
                  paddingLeft: '41px'
                }}
                className={`${style.menuItem} ${style.noBorder} ${
                  assetIndex === asset.length - 1 && style.borderBottom
                } `}
              >
                <span>{asset?.name}</span>
              </div>
            </Link>
          </div>
        ))}
    </>
  );
};

export default AssetBundle;
