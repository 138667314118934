import React, { memo, forwardRef } from 'react';

import Datepicker from '@components/date-picker-component';
import style from '../workorder.module.scss';

const FormDatepicker = (
  {
    errorsMsg,
    removeSpecialCharactersForName,
    field,
    label,
    control,
    name,
    register,
    timePicker = false
  },
  ref
) => {
  const errorName = errorsMsg?.message || '';
  const { refs } = ref;
  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
    >
      <Datepicker
        backClass={style.datePicker2}
        id={field?.formFieldId || '1'}
        label={label}
        inputClass="input-regular op-datepicker"
        control={control}
        name={name}
        error={errorName ? 'Required' : ''}
        register={register}
        isClearable
        readOnly={true}
        dot={field?.isRequired}
        timePicker={timePicker}
      />
    </div>
  );
};

export default memo(forwardRef(FormDatepicker));
