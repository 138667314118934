/* eslint-disable no-unused-vars */

import _ from 'lodash';
import moment from 'moment-timezone';

import { getTaskSiteAsset } from './site';
import { getDataPoints } from '../../pages/settings/helper';

export const getTaskPipelineAsset = ({
  sitesMap,
  areaHierarchyTreeDataMap,
  siteDisplayFields,
  cacheSites = [],
  picklistsMap,
  appliesTo = false,
  task,
  pipelineMobileMap,
  pipelineMobile,
  pipelineDisplayFields,
  formWrapper,
  store
}) => {
  const taskPipelines = task?.pipelines || [];
  const id = task?.primaryAssetId || task?.relatedAssetId || task?.pipelineId || null;
  const pipelineIds = id
    ? [id, ...taskPipelines.filter((pipelineId) => pipelineId !== id)] || []
    : task?.pipelines;

  let sitesIds = [];
  const prepareTasksPipeline = pipelineIds?.map((pipelineId) => {
    const pipelineData = pipelineMobile[pipelineMobileMap[pipelineId]] || {};

    const sites = pipelineData?.sites || [];
    const fromSite = pipelineData?.fromSite ? [pipelineData?.fromSite] : [];
    const toSite = pipelineData?.toSite ? [pipelineData?.toSite] : [];
    const wells = pipelineData?.wells || [];

    sitesIds = Array.from(new Set([...sites, ...fromSite, ...toSite, ...wells]));

    const multipleSiteAssets = sitesIds?.map((id) => {
      const siteAsset = getTaskSiteAsset({
        sitesMap,
        siteId: id,
        areaHierarchyTreeDataMap,
        siteDisplayFields,
        picklistsMap,
        cacheSites,
        store
      });
      return siteAsset;
    });

    const dataPoints = getDataPoints(
      {
        ...pipelineData,
        area: areaHierarchyTreeDataMap[pipelineData?.area]?.title,
        type: pipelineData?.pipelineTypeId || null,
        approvalNumber: pipelineData?.ApprovalNumber || null
      },
      pipelineDisplayFields,
      'pipeline',
      picklistsMap
    );
    const completeData = appliesTo
      ? getPipelineCompleteData({
          picklistsMap,
          pipelineId,
          status: pipelineData?.status,
          formWrapper,
          store
        })
      : {};

    return {
      ...pipelineData,
      pipelineId,
      fromLocation: pipelineData?.fromLocation || '',
      toLocation: pipelineData?.toLocation || '',
      licenseNumber: pipelineData?.licenseNumber || '',
      lineNumber: pipelineData?.lineNumber || '',
      dataPoints,
      status: '',
      multipleSiteAssets,
      ...completeData
    };
  });
  return {
    sitesIds,
    prepareTasksPipeline
  };
};

export const getPipelineAsset = ({
  task,
  picklistsMap,
  sitesMap,
  pipelineDisplayFields,
  areaHierarchyTreeDataMap,
  pipelineMobileMap,
  cacheSites,
  siteDisplayFields
}) => {
  const pipelineId = task?.pipelineId;
  const pipelineData = pipelineMobileMap[pipelineId] || {};
  const pipelineSiteId = pipelineData?.siteId || null;

  // const siteAsset = getTaskSiteAsset({
  //   sitesMap,
  //   siteId: pipelineSiteId,
  //   areaHierarchyTreeDataMap,
  //   siteDisplayFields,
  //   picklistsMap,
  //   cacheSites
  // });

  const dataPoints = getDataPoints(
    { ...pipelineData, area: areaHierarchyTreeDataMap[pipelineData?.area]?.title },
    pipelineDisplayFields,
    'pipeline',
    picklistsMap
  );

  return {
    pipelineAsset: [
      {
        fromLocation: pipelineData?.fromLocation || '',
        toLocation: pipelineData?.toLocation || '',
        licenseNumber: pipelineData?.licenseNumber || '',
        lineNumber: pipelineData?.lineNumber || '',
        dataPoints,
        // length: pipelineData.length || '', //TODO client requirements here
        pipelineId: pipelineId
      }
    ]
  };
};
const getPipelineCompleteData = ({ pipelineId, picklistsMap, status, formWrapper, store }) => {
  const { tasks, inspection } = store;

  const allWOTasks = !formWrapper
    ? tasks?.tasksList?.filter((task) => !task.newWorkOrder && !task.newWorkRequest)
    : [];
  const allInspections = !formWrapper
    ? inspection?.inspectionsList?.filter((t) => !t?.newInspection)
    : [];
  const inspectionsAndWorkOrders = [...allWOTasks, ...allInspections];

  let allTasksAndInspectionsForPipeline = inspectionsAndWorkOrders?.filter(
    (t) =>
      t.pipelineId === pipelineId ||
      t?.relatedAssetId === pipelineId ||
      t?.primaryAssetId === pipelineId ||
      t?.pipelines?.includes(pipelineId)
  );
  const today = moment().format('YYYY-MM-DD');
  const allDoneTasks = allTasksAndInspectionsForPipeline.filter(
    (task) => task?.status === 'Synced'
  );
  const allotDoneTasks = allTasksAndInspectionsForPipeline.filter(
    (task) => task?.status !== 'Synced'
  );

  const isOverDue = allotDoneTasks.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  return {
    status: !formWrapper ? picklistsMap[status]?.value : null,
    allDoneTasks,
    isOverDue,
    allTasksForPipeline: allTasksAndInspectionsForPipeline,
    allotDoneTasks,
    soonestDueDate: _.min(
      allotDoneTasks.map((t) => t?.due || t?.dueDate).map((date) => new Date(date))
    )
  };
};
