/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, Navigate, useParams } from 'react-router-dom';
import { store } from 'redux/store';

import SiteListingInner from '@components/site-listing-inner';
import LoadingRound from '@components/loading-round/loading-round';

import { backHistoryActions } from '@redux/slices/back-history';
import { getAppDispatch } from '@utilities/dispatch.util';
import useQuery from '@hooks/useQuery';

import { initWebWorker } from 'webworker';

import style from './style.module.scss';

const ParentListing = ({ Title }) => {
  const { id } = useParams();
  const dispatch = getAppDispatch();
  const query = useQuery();
  const templateString = query.get('siteTemplates') || '';
  const sitesLoading = useSelector((state) => state.cache.sitesLoading);
  const [parentSiteWithWorkOrdersAndChildren, setParentSiteWithWorkOrdersAndChildren] = useState(
    {}
  );
  const [siteWithWorkOrdersData, setSiteWithWorkOrdersData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const templatesIds = React.useMemo(
    () => templateString.split(',').filter((el) => el),
    [templateString]
  );

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() },
        siteId: id,
        templatesIds: templatesIds || ''
      },
      type: 'get-parent-site-details'
    })
      .then((data) => {
        const [res1, res2] = data?.result || [[], []];
        setParentSiteWithWorkOrdersAndChildren(res1);
        setSiteWithWorkOrdersData(res2);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    dispatch?.(backHistoryActions.setParentSiteDetailsPageHistory(null));
    dispatch?.(backHistoryActions.setSiteDetailsPageHistory(null));
  }, []);

  const { parentSiteWithWorkOrders, childrenSiteWithWorkOrders } = React.useMemo(() => {
    const { parentSiteWithWorkOrders, childrenSiteWithWorkOrders } =
      parentSiteWithWorkOrdersAndChildren;
    return {
      parentSiteWithWorkOrders: { ...parentSiteWithWorkOrders, link: '' },
      childrenSiteWithWorkOrders
    };
  }, [parentSiteWithWorkOrdersAndChildren]);
  const { siteWithWorkOrders } = React.useMemo(() => {
    return {
      siteWithWorkOrders: { ...siteWithWorkOrdersData?.siteWithWorkOrders, link: '' }
    };
  }, [siteWithWorkOrdersData]);

  useEffect(() => {
    if (!isLoading)
      if (!sitesLoading && !parentSiteWithWorkOrders) return <Navigate to={'/sites'} />;
  }, [isLoading, siteWithWorkOrdersData, parentSiteWithWorkOrdersAndChildren]);

  return (
    <div className="relative overflow-x-hidden">
      <div
        className="op-page-name op-listing-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative"
        style={{
          position: 'sticky',
          top: 0
        }}
      >
        <Link className="op-back " to={-1}></Link>
        <h1 className="h1-semibold pl-16">{Title}</h1>
        <p>&nbsp;</p>
      </div>
      {isLoading ? (
        <div style={{ backgroundColor: 'transparent', width: '100%' }}>
          <LoadingRound className={style.loadingHeight} />
        </div>
      ) : (
        <div>
          <div
            className="page-listing removeScrollbar"
            style={{ width: `100%` }}
            id="parentSite"
            onScroll={() => {
              var el = document.querySelector('#parentSite');

              el.classList.add('addScrollbar');
              el.classList.remove('removeScrollbar');
              clearTimeout();
              setTimeout(function () {
                el.classList.remove('addScrollbar');
                el.classList.add('removeScrollbar');
              }, 300);
            }}
          >
            <>
              <p className="pl-4 label-bold pt-4 pb-2.5 text-primary-500">PARENT AND CHILD SITE</p>
              <div style={{ width: '100%', borderTop: '.5px solid rgb(203, 210, 217)' }} />
              <SiteListingInner parent {...parentSiteWithWorkOrders} showTopBorder divider />
              <p className="pl-4 label-bold pt-8 pb-2.5 text-primary-500">PARENT SITE</p>
              <div style={{ width: '100%', borderTop: '.5px solid rgb(203, 210, 217)' }} />
              <SiteListingInner normal showWOCount {...siteWithWorkOrders} showTopBorder divider />
              <p className="pl-4 label-bold pt-8 pb-2.5 text-primary-500">CHILD SITES</p>
              <div style={{ width: '100%', borderTop: '.5px solid rgb(203, 210, 217)' }} />
              {childrenSiteWithWorkOrders?.map((site, index) => (
                <SiteListingInner
                  key={site.siteId}
                  {...site}
                  showTopBorder={index === 0 ? true : false}
                  divider
                  link={''}
                />
              ))}
            </>
          </div>
        </div>
      )}
    </div>
  );
};
ParentListing.propTypes = {
  Title: PropTypes.string
};

export default React.memo(ParentListing);
