import React, { memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

import Icon from '@components/icon-component';
import AddFieldsModal from '../add-fields-modal';
import Button from '@components/button/button.component';

import { decodeToken } from '@utilities/authentication';
import { PERMISSIONS } from 'permissions/constants';
import { displayFields } from '../helper';
import { settingsActions } from '@redux/slices/settings';

const DragDropFields = ({ text }) => {
  const dispatch = useDispatch();
  const name = text.replace(' ', '');
  const fieldName = `${name}DisplayFields`;
  const permissions = decodeToken()?.permissions || {};
  const disabled = !permissions[PERMISSIONS.MOBILE_CHANGE_DISPLAY_SETTINGS];
  const addFieldsMap = displayFields[name].reduce((acc, curr) => {
    if (curr.IsSortable) acc[curr.Id] = curr;
    return acc;
  }, {});
  const addFields = displayFields[name].filter((el) => el.IsSortable);
  const topFields = displayFields[name].filter((el) => !el.IsSortable && !el.AlignBottom);
  const bottomFields = displayFields[name].filter((el) => !el.IsSortable && el.AlignBottom);
  const middleFields = (useSelector((state) => state.settings.settings?.[fieldName]) || '')
    .split(',')
    .filter((el) => addFieldsMap[el]?.IsSortable)
    .map((el) => addFieldsMap[el]);

  const [isOpen, setIsOpen] = useState(false);
  const [newFieldId, setNewFieldId] = useState(middleFields.length);
  const [fields, setFields] = useState(middleFields.map((el, i) => ({ ...el, id: `${i}` })));
  const fieldsMap = fields.reduce((acc, curr) => {
    acc[curr.Id] = curr;
    return acc;
  }, {});

  const onDragEnd = (result) => {
    if (disabled) return;
    if (!result.destination) return;

    const newFields = reorder(fields, result.source.index, result.destination.index);

    setFields(newFields);
    dispatch?.(
      settingsActions.updateSettingsByName({
        name: fieldName,
        value: newFields.map(({ Id }) => Id).join(',')
      })
    );
  };

  const removeField = (index) => {
    if (disabled) return;

    const copy = [...fields];
    copy.splice(index, 1);
    setFields(copy);
    dispatch?.(
      settingsActions.updateSettingsByName({
        name: fieldName,
        value: copy.map(({ Id }) => Id).join(',')
      })
    );
  };

  const onSubmit = (data) => {
    if (disabled) return;

    let id = newFieldId;
    const copy = [...fields];

    for (const el of data) copy.push({ ...addFieldsMap[el], id: `${id++}` });

    setFields(copy);
    setNewFieldId(id);
    dispatch?.(
      settingsActions.updateSettingsByName({
        name: fieldName,
        value: copy.map(({ Id }) => Id).join(',')
      })
    );
  };
  return (
    <>
      <div className="settings--values--fields" style={{ marginTop: '8px', borderBottom: 'none' }}>
        {topFields.map(({ Value, Badges = [] }, index) => (
          <div
            key={index}
            style={{ background: '#F5F7FA' }}
            className="settings--values--fields--field"
          >
            <div className="settings--values--fields--field--title">
              <div>
                <Icon name={'close-circle-grey'} color={'#CBD2D9'} />
              </div>
              <div>
                {Badges.length === 0
                  ? Value
                  : Badges.map(({ Text, Text2, Color }, i) => (
                      <div key={i} style={{ background: Color || '#F5F7FA' }}>
                        {Text} {Text2}
                      </div>
                    ))}
              </div>
            </div>

            <div className="settings--values--fields--field--icon">
              <Icon name={'drag'} color={'#CBD2D9'} />
            </div>
          </div>
        ))}
      </div>

      {fields.length > 0 && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  background: snapshot.isDraggingOver ? '#F4F8FE' : '#CBD2D9'
                }}
              >
                <div
                  className="settings--values--fields"
                  style={{ borderTop: 'none', borderBottom: 'none' }}
                >
                  {fields.map(({ id, Value, Badges = [] }, index) => (
                    <Draggable
                      key={`${id}`}
                      index={index}
                      draggableId={`${id}`}
                      isDragDisabled={disabled}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps}>
                          <div
                            className="settings--values--fields--field"
                            style={{
                              background: !disabled ? 'white' : '#F5F7FA'
                            }}
                          >
                            <div className="settings--values--fields--field--title">
                              <div
                                onClick={() => removeField(index)}
                                style={{ cursor: !disabled ? 'pointer' : '' }}
                              >
                                <Icon
                                  name={'close-circle-grey'}
                                  color={!disabled ? '#7B8794' : '#CBD2D9'}
                                />
                              </div>
                              <div>
                                {Badges.length === 0
                                  ? Value
                                  : Badges.map(({ Text, Text2, Color }, i) => (
                                      <div key={i} style={{ background: Color || '#F5F7FA' }}>
                                        {Text} {Text2}
                                      </div>
                                    ))}
                              </div>
                            </div>

                            <div
                              {...provided.dragHandleProps}
                              className="settings--values--fields--field--icon"
                            >
                              <Icon name={'drag'} color={!disabled ? '#7B8794' : '#CBD2D9'} />
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}

      <div className="settings--values--fields" style={{ borderTop: 'none' }}>
        {bottomFields.map(({ Value, Badges = [] }, index) => (
          <div
            key={index}
            style={{ background: '#F5F7FA' }}
            className="settings--values--fields--field"
          >
            <div className="settings--values--fields--field--title">
              <div>
                <Icon name={'close-circle-grey'} color={'#CBD2D9'} />
              </div>
              <div>
                {Badges.length === 0
                  ? Value
                  : Badges.map(({ Text, Text2, Color }, i) => (
                      <div key={i} style={{ background: Color || '#F5F7FA' }}>
                        <span style={{ color: Text2 ? '#7B8794' : '' }}>{Text}</span> {Text2}
                      </div>
                    ))}
              </div>
            </div>

            <div className="settings--values--fields--field--icon">
              <Icon name={'drag'} color={'#CBD2D9'} />
            </div>
          </div>
        ))}
      </div>
      <Button
        className="settings--values--add-fields"
        onClick={() => !disabled && setIsOpen(true)}
        disabled={
          disabled || addFields.length === fields.filter(({ Id }) => addFieldsMap[Id]).length
        }
        text={'Add field(s)'}
      />

      {disabled && (
        <div className="no-permission mt-4">
          <div>
            <Icon name={'question'} />
          </div>
          <div>You do not have permission to change this setting.</div>
        </div>
      )}

      <AddFieldsModal
        text={text}
        openModal={isOpen}
        onSubmit={onSubmit}
        setOpenModal={setIsOpen}
        fields={addFields
          .filter(({ Id }) => !fieldsMap[Id])
          .sort((a, b) => a.Value?.localeCompare(b.Value))}
      />
    </>
  );
};

export default memo(DragDropFields);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
