/* eslint-disable no-unused-vars */
import React from 'react';

import LoadingIcon from '@components/loading-lottie';
import Modal from '../..';
import Icon from '@components/icon-component';

import style from './style.module.scss';

const SaveModal = ({
  open,
  setOpen,
  inProgress = false,
  saveChanges = false,
  saving = false,
  onDiscard,
  onSave,
  disabledDeleteButton = false
}) => {
  const saveModalIcon = React.useMemo(() => {
    if (inProgress) return <Icon name={'save-in-progress'} />;
    else if (saveChanges) return <Icon name={'save-in-progress'} />;
    else if (saving) return <LoadingIcon />;
  }, [inProgress, saveChanges, saving]);

  const statusText = React.useMemo(() => {
    if (inProgress) return 'Save ‘In progress’?';
    else if (saveChanges) return 'Save changes?';
    else if (saving) return 'Saving';
  }, [inProgress, saveChanges, saving]);

  const modalTextUpper = React.useMemo(() => {
    if (inProgress) return 'Would you like to save this task as ‘In progress’?';
    else if (saveChanges) return 'Would you like to save your changes?';
  }, [inProgress, saveChanges]);

  const textBtn = React.useMemo(() => {
    if (inProgress) return 'Back to form';
    else if (saveChanges) return 'Back to form';
  }, [inProgress, saveChanges]);

  const modalBodyInner = React.useMemo(() => {
    if (inProgress)
      return (
        <div>
          <div className={style.modalBtn}>
            <button type="button" className="btn-fill button-large" onClick={onSave}>
              Save
            </button>
          </div>
          <div className={style.modalBtn}>
            <button
              className={`btn-fill button-large ${!disabledDeleteButton && style.discardBtn}`}
              onClick={onDiscard}
              disabled={disabledDeleteButton}
            >
              Discard
            </button>
          </div>
        </div>
      );
    else if (saveChanges)
      return (
        <div>
          <div className={style.modalBtn}>
            <button type="button" className="btn-fill button-large" onClick={onSave}>
              Save
            </button>
          </div>
          <div className={style.modalBtn}>
            <button className={`btn-fill button-large ${style.discardBtn}`} onClick={onDiscard}>
              discard changes
            </button>
          </div>
        </div>
      );
  }, [inProgress, saveChanges, onSave, onDiscard]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={saveModalIcon}
      statusText={statusText}
      modalTextUpper={modalTextUpper}
      textBtn={textBtn}
      modalImageClassName={saving && style.modalImage}
    >
      {modalBodyInner}
    </Modal>
  );
};

export default SaveModal;
