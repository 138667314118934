import _ from 'lodash';
import moment from 'moment';

import { getSelectedTaskPhotos, getSelectedTaskDocuments } from '../helper';

export const convertToSerializable = async (obj, type, updateApp = false) => {
  const inspectionWorkOrder = [];
  const inspectionWorkRequest = [];
  let psvs = [];
  const transformNestedObjects = async (nestedObj) => {
    let updatedObj = {};
    for (let key in nestedObj) {
      if (typeof nestedObj[key] === 'object' && nestedObj[key] !== null) {
        if ('photos' in nestedObj[key] && 'documents' in nestedObj[key]) {
          const imageResponse =
            nestedObj[key].photos && nestedObj[key]?.photos?.length
              ? await transformPhotoData(nestedObj[key]?.photos, type, updateApp)
              : [];
          const documentResponse =
            nestedObj[key].documents && nestedObj[key].documents?.length
              ? await transformDocumentData(nestedObj[key].documents, type, updateApp)
              : [];
          updatedObj[key] = {
            ...nestedObj[key],
            photos: imageResponse,
            documents: documentResponse
          };
        } else if ('photo' in nestedObj[key] && 'document' in nestedObj[key]) {
          const imageResponse =
            nestedObj[key].photo && nestedObj[key]?.photo?.length
              ? await transformPhotoData(nestedObj[key]?.photo, type, updateApp)
              : [];
          const documentResponse =
            nestedObj[key].document && nestedObj[key].document?.length
              ? await transformDocumentData(nestedObj[key].document, type, updateApp)
              : [];
          updatedObj[key] = {
            ...nestedObj[key],
            photo: imageResponse,
            document: documentResponse
          };
        } else if ('photos' in nestedObj[key]) {
          if (nestedObj[key].photos && nestedObj[key]?.photos?.length) {
            const res = await transformPhotoData(nestedObj[key]?.photos, type, updateApp);
            if (res?.length) {
              updatedObj[key] = {
                ...nestedObj[key],
                photos: res
              };
            }
          } else {
            updatedObj[key] = nestedObj[key];
          }
        } else if ('documents' in nestedObj[key]) {
          if (nestedObj[key].documents && nestedObj[key].documents?.length) {
            const res = await transformDocumentData(nestedObj[key].documents, type, updateApp);
            if (res?.length) {
              updatedObj[key] = {
                ...nestedObj[key],
                documents: res
              };
            }
          } else {
            updatedObj[key] = nestedObj[key];
          }
        } else if (_.isDate(nestedObj[key])) {
          const date = nestedObj[key]
            ? moment(new Date(nestedObj[key])).format('YYYY-MM-DD')
            : null;
          updatedObj[key] = date;
        } else {
          // updatedObj[key] = await transformNestedObjects(nestedObj[key]);
          updatedObj[key] = Array.isArray(nestedObj[key])
            ? nestedObj[key]
            : await transformNestedObjects(nestedObj[key]);
        }
      } else {
        updatedObj[key] = nestedObj[key];
      }
    }
    return updatedObj;
  };

  const transformWorkOrder = async (a) => {
    let updatedWorkOrder = [];
    for (let i = 0; i < a?.length; i++) {
      let item = a[i];
      const resPhoto =
        item?.photo?.length > 0 ? await transformPhotoData(item?.photo, 'WorkOrder') : [];
      const resDoc =
        item?.document?.length > 0 ? await transformDocumentData(item.document, 'WorkOrder') : [];
      const requestDueDate = item?.requestDueDate
        ? moment(new Date(item.requestDueDate)).format('YYYY-MM-DD')
        : null;
      let updatedItem = { ...item, photo: resPhoto, document: resDoc, requestDueDate };
      updatedWorkOrder.push(updatedItem);
    }
    return updatedWorkOrder;
  };
  const transformWorkRequest = async (a) => {
    let updatedWorkOrder = [];
    for (let i = 0; i < a?.length; i++) {
      let item = a[i];
      const resPhoto =
        item?.photo?.length > 0 ? await transformPhotoData(item?.photo, 'WorkRequest') : [];
      const resDoc =
        item?.document?.length > 0 ? await transformDocumentData(item.document, 'WorkRequest') : [];
      const requestDueDate = item?.requestDueDate
        ? moment(new Date(item.requestDueDate)).format('YYYY-MM-DD')
        : null;
      let updatedItem = { ...item, photo: resPhoto, document: resDoc, requestDueDate };
      updatedWorkOrder.push(updatedItem);
    }
    return updatedWorkOrder;
  };

  let updatedObj = {};
  let HSEEventTitle = '';
  for (let key in obj) {
    const labelId = obj[key] ? Object.keys(obj[key])[0] : null;
    if (labelId) {
      if (`title-${labelId}` === key && type === 'HSE') {
        HSEEventTitle = obj[key];
        HSEEventTitle = HSEEventTitle[labelId] || '';
      }

      if (key === `work order-${labelId}`) {
        const a = obj[key];
        for (let key1 in a) {
          const res1 = await transformWorkOrder(a[key1]);
          inspectionWorkOrder.push(res1);
        }
        updatedObj[key] = null;
      } else if (key === `psvs-${labelId}`) {
        const a = obj[key];
        for (let key1 in a) {
          if (key1 !== `${labelId}-reconcile`) psvs = a[key1];
        }
        psvs =
          psvs?.length > 0
            ? {
                [`psvs-${labelId}`]: {
                  [labelId]: psvs
                }
              }
            : null;
      } else if (key === `work request-${labelId}`) {
        const a = obj[key];
        for (let key1 in a) {
          const res1 = await transformWorkRequest(a[key1]);
          inspectionWorkRequest.push(res1);
        }
        updatedObj[key] = null;
      } else updatedObj[key] = await transformNestedObjects(obj[key]);
    }
  }
  const workOrderMap = _.flattenDeep(inspectionWorkOrder);
  const workRequestMap = _.flattenDeep(inspectionWorkRequest);

  return {
    res: psvs ? { ...updatedObj, ...psvs } : updatedObj,
    workOrderMap,
    workRequestMap,
    HSEEventTitle
  };
};

export const convertSerializableToNonSerialData = (obj) => {
  const transformNestedObjects = (nestedObj) => {
    let updatedObj = {};
    for (let key in nestedObj) {
      if (typeof nestedObj[key] === 'object' && nestedObj[key] !== null) {
        if ('photos' in nestedObj[key] && 'documents' in nestedObj[key]) {
          const imageResponse =
            nestedObj[key].photos && nestedObj[key]?.photos?.length
              ? getSelectedTaskPhotos({
                  selectedTask: {
                    photos: nestedObj[key].photos
                  }
                })
              : [];
          const documentResponse =
            nestedObj[key].documents && nestedObj[key].documents?.length
              ? getSelectedTaskDocuments({
                  selectedTask: {
                    documents: nestedObj[key].documents
                  }
                })
              : [];
          updatedObj[key] = {
            ...nestedObj[key],
            photos: imageResponse,
            documents: documentResponse
          };
        } else if ('photos' in nestedObj[key]) {
          if (nestedObj[key].photos && nestedObj[key]?.photos?.length) {
            const res = getSelectedTaskPhotos({
              selectedTask: {
                photos: nestedObj[key].photos
              }
            });
            if (res?.length) {
              updatedObj[key] = {
                ...nestedObj[key],
                photos: res
              };
            }
          } else {
            updatedObj[key] = nestedObj[key];
          }
        } else if ('documents' in nestedObj[key]) {
          if (nestedObj[key].documents && nestedObj[key].documents?.length) {
            const res = getSelectedTaskDocuments({
              selectedTask: {
                documents: nestedObj[key].documents
              }
            });
            if (res?.length) {
              updatedObj[key] = {
                ...nestedObj[key],
                documents: res
              };
            }
          }
        } else if (_.isDate(nestedObj[key])) {
          const date = nestedObj[key]
            ? moment(new Date(nestedObj[key])).format('YYYY-MM-DD')
            : null;
          updatedObj[key] = date;
        } else {
          updatedObj[key] = Array.isArray(nestedObj[key])
            ? nestedObj[key]
            : transformNestedObjects(nestedObj[key]);
        }
      } else updatedObj[key] = nestedObj[key];
    }
    return updatedObj;
  };
  const transformWorkOrder = (a) => {
    let updatedWorkOrder = [];
    for (let i = 0; i < a.length; i++) {
      let item = a[i];
      const resPhoto =
        item?.photo?.length > 0
          ? getSelectedTaskPhotos({
              selectedTask: {
                photos: item?.photo
              }
            })
          : [];
      const resDoc =
        item?.document?.length > 0
          ? getSelectedTaskDocuments({
              selectedTask: {
                documents: item?.document
              }
            })
          : [];
      const requestDueDate = item?.requestDueDate
        ? moment(new Date(item.requestDueDate)).format('YYYY-MM-DD')
        : null;
      let updatedItem = {
        ...item,
        photo: resPhoto,
        document: resDoc,
        requestDueDate
      };
      updatedWorkOrder.push(updatedItem);
    }
    return updatedWorkOrder;
  };

  let updatedObj = {};
  let psvs = [];
  for (let key in obj) {
    const labelId = obj[key] ? Object.keys(obj[key])[0] : null;
    if (labelId) {
      if (key === 'work order') {
        const a = obj[key];
        for (let key1 in a) {
          const res1 = transformWorkOrder(a[key1]);
          updatedObj[key] = {
            [key1]: res1
          };
        }
      } else if (key === `psvs-${labelId}`) {
        const a = obj[key];
        for (let key1 in a) {
          psvs = a[key1];
        }
        psvs =
          psvs?.length > 0
            ? {
                [`psvs-${labelId}`]: {
                  [labelId]: psvs
                }
              }
            : null;
      } else updatedObj[key] = transformNestedObjects(obj[key]);
    }
  }

  return psvs ? { ...updatedObj, ...psvs } : updatedObj;
};

const transformPhotoData = async (
  photoData = [],
  documentType = 'AssignedInspection',
  updateApp = false
) => {
  const transformedData = [];

  if (_.size(photoData) > 0) {
    for (const doc of photoData) {
      const file = updateApp ? { id: doc?.id, name: doc.fileName, type: doc?.mimeType } : '';
      const transformedDoc = {
        caption: doc?.caption || null,
        fileName: doc?.image?.name || null,
        photoCategory: doc?.category || null,
        content: updateApp ? file : doc.image,
        documentType,
        mimeType: doc?.image?.type || 'image/png',
        id: doc?.id || null
      };

      transformedData.push(transformedDoc);
    }
  }

  return transformedData;
};
const transformDocumentData = async (
  documentData = [],
  documentType = 'AssignedInspection',
  updateApp = false
) => {
  const transformedData = [];

  if (_.size(documentData) > 0) {
    for (const doc of documentData) {
      const file = updateApp
        ? {
            name: doc?.fileName,
            type: doc?.mimeType,
            id: doc?.id
          }
        : '';
      const transformedDoc = {
        fileName: doc?.file?.file?.name || null,
        documentCategory: doc?.category || null,
        content: updateApp ? file : doc?.file?.file,
        documentType,
        mimeType: doc?.file?.file?.type || 'application/pdf',
        id: doc?.id || null
      };

      transformedData.push(transformedDoc);
    }
  }

  return transformedData;
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
  });
