/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Icon from '@components/icon-component';

import style from './picklist.module.scss';

const PicklistPills = ({ text, iconName, selected = false, handleChange, className }) => {
  const [check, setCheck] = useState(true);

  useEffect(() => {
    setCheck(selected);
  }, [selected]);

  return (
    <div
      className={`${style.picklist} ${className}`}
      style={{
        backgroundColor: selected ? '#2F80ED' : '',
        border: selected ? '1px solid #ffffff' : ''
      }}
      onClick={() => {
        const copy = check;
        handleChange(!copy);
        setCheck(!copy);
      }}
    >
      {text && <p style={{ color: selected ? '#ffffff' : '' }}>{text}</p>}
      {iconName && <Icon height={16} width={16} name={iconName} />}
    </div>
  );
};

export default React.memo(PicklistPills, _.isEqual);
