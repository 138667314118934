/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { store } from 'redux/store';
import moment from 'moment';

export const getWorkOrderFormData = ({
  currData,
  workOrderTypeData,
  tradeData,
  workOrderSkillsData,
  picklistsMap,
  inspectionFormsUserCache
}) => {
  const { tasks, picklists } = store.getState();

  const responsibleUsersMap = {
    ...(tasks.workOrderUsersGroupsMap || {}),
    ...(tasks.workOrderUsersMap || {})
  };
  const workOrderMap = {
    ...tasks?.workOrderAdministratorGroupsMap,
    ...tasks?.workOrderAdministratorsMap
  };
  const allUsers = [
    ...(currData?.responsibleUserGroups || []),
    ...(currData?.responsibleUsers || [])
  ];
  const allResponsibleUsers =
    [currData.primaryUserId, ...allUsers.filter((user) => user !== currData.primaryUserId)]
      .filter((user) => responsibleUsersMap[user])
      .map((user) =>
        responsibleUsersMap[user]?.name
          ? `${responsibleUsersMap[user].name}`
          : `${responsibleUsersMap[user].firstName} ${responsibleUsersMap[user].lastName}`
      ) || [];

  const formId = uuidv4();
  const generalFormSectionId = uuidv4();
  const workDescriptionId = uuidv4();
  const requesterId = uuidv4();
  const schedulingId = uuidv4();
  const assignmentId = uuidv4();
  const attachmentsId = uuidv4();
  const workLogId = uuidv4();

  const generalSectionReadableFields = generalSectionReadableFieldsList({
    currData,
    workOrderSkillsData,
    workOrderTypeData,
    tradeData,
    picklistsMap
  });

  const workDescriptionReadableFields = workDescriptionReadableFieldsList({
    currData
  });

  const requesterReadableFields = requesterReadableFieldsList({
    currData,
    responsibleUsersMap
  });

  const schedulingReadableFields = schedulingReadableFieldsList({
    currData
  });
  const assignmentReadableFields = assignmentReadableFieldsList({
    currData,
    workOrderMap,
    allResponsibleUsers
  });

  const attachmentsReadableFields = attachmentsReadableFieldsList({
    currData,
    workOrderMap,
    allResponsibleUsers,
    photoCategoryData: picklists?.photoCategoryData,
    picklistsMap
  });

  // formId;
  const workOrderCheckSheetForm = currData?.formId
    ? inspectionFormsUserCache?.find((x) => x?.inspectionFormId === currData?.formId)
    : {};
  const checkSheetsSectionAndSubSections = convertAssignedWorkOrderCheckSheet({
    checkSheetForm: workOrderCheckSheetForm
  });

  return {
    workOrderCheckSheetForm,
    inspectionFormId: formId,
    name: 'assigned work order form',
    sections: 2,
    fields: 62,
    clientId: null,
    inspectionType: null,
    inspectionCategory: null,
    module: 'WorkOrder',
    appliesTo: workOrderCheckSheetForm?.appliesTo || 'All',
    hasSensor: false,
    deficiencyProcessingEnabled: false,
    hasMasterData: false,
    clientSideSpecialHandling: null,
    quickComplete: false,
    number: null,
    failureCriteria: null,
    numberOfFailedFields: null,
    editable: true,
    availableOnDemand: false,
    formSections: [
      //   first section
      {
        formSectionId: generalFormSectionId,
        name: 'General',
        formId,
        parentId: null,
        childId: null,
        order: 1000,
        enable: 'Y',
        menuName: 'General',
        panel: generalFormSectionId,
        hidden: null,
        isDeleted: false,
        formFields: generalSectionReadableFields
      },
      {
        formSectionId: workDescriptionId,
        name: 'Work description',
        formId,
        parentId: generalFormSectionId,
        childId: null,
        order: 2000,
        enable: 'Y',
        menuName: 'Work description',
        panel: generalFormSectionId,
        hidden: null,
        isDeleted: false,
        formFields: workDescriptionReadableFields
      },
      {
        formSectionId: requesterId,
        name: 'Requester',
        formId,
        parentId: generalFormSectionId,
        childId: null,
        order: 2000,
        enable: 'Y',
        menuName: 'Requester',
        panel: generalFormSectionId,
        hidden: null,
        isDeleted: false,
        formFields: requesterReadableFields
      },
      {
        formSectionId: schedulingId,
        name: 'Scheduling',
        formId,
        parentId: generalFormSectionId,
        childId: null,
        order: 2000,
        enable: 'Y',
        menuName: 'Scheduling',
        panel: generalFormSectionId,
        hidden: null,
        isDeleted: false,
        formFields: schedulingReadableFields
      },
      {
        formSectionId: assignmentId,
        name: 'Assignment',
        formId,
        parentId: generalFormSectionId,
        childId: null,
        order: 2000,
        enable: 'Y',
        menuName: 'Assignment',
        panel: generalFormSectionId,
        hidden: null,
        isDeleted: false,
        formFields: assignmentReadableFields
      },

      {
        formSectionId: attachmentsId,
        name: 'Attachments',
        formId,
        parentId: generalFormSectionId,
        childId: null,
        order: 2000,
        enable: 'Y',
        menuName: 'Attachments',
        panel: generalFormSectionId,
        hidden: null,
        isDeleted: false,
        formFields: attachmentsReadableFields
      },

      // checks section
      ...checkSheetsSectionAndSubSections,
      // second section
      {
        formSectionId: workLogId,
        name: 'Work log',
        formId,
        parentId: null,
        childId: null,
        order: 1000,
        enable: 'Y',
        menuName: 'Work log',
        panel: workLogId,
        hidden: null,
        isDeleted: false,
        formFields: []
      }
    ]
  };
};

const generalSectionReadableFieldsList = ({
  currData,
  workOrderSkillsData,
  workOrderTypeData,
  tradeData,
  picklistsMap
}) => {
  const allSkills = workOrderSkillsData
    ?.filter((ts) => currData?.skills?.includes(ts.key))
    ?.map((ts) => ts.value);

  const generalSectionReadableFields = [];
  currData.title &&
    generalSectionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Title',
      value: currData?.title,
      questionTypeText: 'ReadableText',
      row: 0
    });
  currData.priority &&
    generalSectionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Priority',
      value: currData?.priority,
      questionTypeText: 'ReadableText',
      row: 1
    });

  currData.typeId &&
    generalSectionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Type',
      value: workOrderTypeData.find(({ key }) => currData?.typeId === key).value,
      questionTypeText: 'ReadableText',
      row: 2
    });

  currData.trade &&
    generalSectionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Trade',
      value: tradeData.find(({ key }) => currData?.trade === key).value,
      questionTypeText: 'ReadableText',
      row: 3
    });

  _.size(currData.skills) > 0 &&
    allSkills?.length > 0 &&
    generalSectionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Skill(s)',
      value: _.isEmpty(currData?.skills) ? '' : allSkills?.toString(),
      questionTypeText: 'ReadableText',
      row: 4
    });

  currData.afe &&
    generalSectionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'AFE #',
      value: currData?.afe,
      questionTypeText: 'ReadableText',
      row: 5
    });

  _.size(currData.costCenters) > 0 &&
    generalSectionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Cost centre(s)',
      value: currData?.costCenters.map((x) => picklistsMap[x]?.value),
      questionTypeText: 'ReadableArray',
      row: 6
    });

  return generalSectionReadableFields;
};

const workDescriptionReadableFieldsList = ({ currData }) => {
  const workDescriptionReadableFields = [];
  currData.description &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Description',
      value: currData?.description,
      questionTypeText: 'ReadableText',
      row: 0
    });
  _.isBoolean(currData?.isShutdownRequired) &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'SHUTDOWN REQUIRED',
      value: currData?.isShutdownRequired ? 'Yes' : 'No',
      questionTypeText: 'ReadableText',
      row: 1
    });

  _.isBoolean(currData?.isLockoutTagoutRequired) &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'LOCKOUT/TAGOUT REQUIRED',
      value: currData?.isLockoutTagoutRequired ? 'Yes' : 'No',
      questionTypeText: 'ReadableText',
      row: 2
    });

  currData?.estimatedDowntime &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'ESTIMATED DOWNTIME (HOURS)',
      value: currData?.estimatedDowntime,
      questionTypeText: 'ReadableText',
      row: 3
    });
  currData?.permitsRequired &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'PERMIT(S) REQUIRED',
      value: currData?.permitsRequired,
      questionTypeText: 'ReadableText',
      row: 4
    });
  currData?.toolsRequired &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'TOOL(S) REQUIRED',
      value: currData?.toolsRequired,
      questionTypeText: 'ReadableText',
      row: 5
    });

  currData?.safetyConcerns &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'SAFETY CONCERNS',
      value: currData?.safetyConcerns,
      questionTypeText: 'ReadableText',
      row: 6
    });
  currData?.environmentalConcerns &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'ENVIRONMENTAL CONCERNS',
      value: currData?.environmentalConcerns,
      questionTypeText: 'ReadableText',
      row: 7
    });

  currData?.preparation &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'PREPARATION',
      value: currData?.preparation,
      questionTypeText: 'ReadableText',
      row: 8
    });

  currData?.comments &&
    workDescriptionReadableFields.push({
      formFieldId: uuidv4(),
      label: 'COMMENTS',
      value: currData?.comments,
      questionTypeText: 'ReadableText',
      row: 9
    });
  return workDescriptionReadableFields;
};

const requesterReadableFieldsList = ({ currData, responsibleUsersMap }) => {
  const requesterReadableFields = [];
  (currData.requestedBy || responsibleUsersMap[currData?.requestedById]) &&
    requesterReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Requested by',
      value:
        currData?.requestedBy ||
        `${responsibleUsersMap[currData?.requestedById]?.firstName} ${
          responsibleUsersMap[currData?.requestedById]?.lastName
        }`,
      questionTypeText: 'ReadableText',
      row: 0
    });

  currData.requestedByEmail &&
    requesterReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Requested by - Email address',
      value: currData?.requestedByEmail,
      questionTypeText: 'ReadableText',
      mailto: true,
      row: 1
    });
  currData.requestedByPhone &&
    requesterReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Requested by - Phone number',
      value: currData?.requestedByPhone,
      questionTypeText: 'ReadableText',
      tel: true,
      row: 2
    });
  return requesterReadableFields;
};

const schedulingReadableFieldsList = ({ currData }) => {
  const schedulingReadableFields = [];
  !_.isEmpty(currData?.dueDate) &&
    schedulingReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Due date',
      value: moment(currData?.dueDate).format('YYYY-MM-DD'),
      questionTypeText: 'ReadableText',
      row: 0
    });
  !_.isEmpty(currData?.startDate) &&
    schedulingReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Scheduled date',
      value: moment(currData?.startDate).format('YYYY-MM-DD'),
      questionTypeText: 'ReadableText',
      row: 1
    });
  return schedulingReadableFields;
};

const assignmentReadableFieldsList = ({ currData, workOrderMap, allResponsibleUsers }) => {
  const assignmentReadableFields = [];
  _.size(currData?.administrators) > 0 &&
    assignmentReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Administrator(s)',
      value: [
        ...(currData?.administratorUserGroups || []),
        ...(currData?.administrators || [])
      ]?.map((user) => workOrderMap[user]),
      questionTypeText: 'ReadableArray',
      row: 0
    });
  _.size(allResponsibleUsers) > 0 &&
    assignmentReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Responsible user(s)',
      value: allResponsibleUsers,
      questionTypeText: 'ReadableArray',
      row: 1
    });
  return assignmentReadableFields;
};

const attachmentsReadableFieldsList = ({ currData, photoCategoryData }) => {
  const attachmentsReadableFields = [];

  const photoCategoryDataMap = photoCategoryData?.reduce((acc, curr) => {
    acc[curr.key] = curr || '';
    return acc;
  }, {});

  const photos =
    currData?.photos
      ?.filter((x) => _.isEmpty(x?.content))
      ?.map((photo) => {
        return {
          photoId: photo?.id || uuidv4(),
          imageUrl: photo?.url || null,
          categoryValue: photoCategoryDataMap[photo?.category]?.value || null,
          captionValue: photo?.caption || null,
          imageName: photo?.fileName || null
        };
      }) || [];
  const documents =
    currData?.documents
      ?.filter((x) => _.isEmpty(x?.content))
      ?.map((doc) => {
        return {
          documentCategory: doc?.category || null,
          fileName: doc?.fileName || null,
          documentId: doc?.documentId || uuidv4(),
          pdfUrl: doc?.url || null,
          doc
        };
      }) || [];

  (_.size(photos) > 0 || _.size(documents) > 0) &&
    attachmentsReadableFields.push({
      formFieldId: uuidv4(),
      label: 'Photos',
      value: {
        photos,
        documents
      },
      questionTypeText: 'ReadableImageAndDocumentsArray',
      row: 0
    });

  return attachmentsReadableFields;
};

export const getNewWorkRequestFormData = () => {
  const { picklists, settings, tasks } = store.getState();
  const tradeRequired = settings?.settings?.tradeRequired || false;
  const allowAssignmentOnWorkRequest = settings?.settings?.allowAssignmentOnWorkRequest || false;
  const { tradeData, workOrderPriorityData, workOrderTypeData } = picklists;

  const {
    workOrderAdministratorGroups,
    workOrderAdministrators,
    workOrderUsers,
    workOrderUsersGroups
  } = tasks;

  const formSections = [
    {
      formSectionId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
      name: 'General',
      formId: '0e515a20-842d-4846-b083-b00e018976a0',
      parentId: null,
      childId: null,
      order: 1000,
      enable: 'Y',
      menuName: 'General',
      panel: '7cbed9fd-b464-419f-bcd6-b00e01897812',
      hidden: null,
      isDeleted: false,
      formFields: [
        {
          formFieldId: '7ee68589-2e35-433d-867e-b00f004e4c6c',
          questionTypeId: '844c7c4a-97df-416a-b57c-9478ca81aa1c',
          isRequired: true,
          label: 'DESCRIPTION',
          sectionId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
          conditionValue: null,
          targetSectionId: null,
          order: 0,
          defaultValue: '',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 1,
          row: 1003,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'LongText'
        },
        {
          formFieldId: 'b12410e3-3fbe-4c9f-8bf3-b00e018ad3f2',
          questionTypeId: '725c248a-929b-47b6-8b93-a49f00ae7ab4',
          isRequired: false,
          label: 'REQUESTED DUE DATE',
          sectionId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
          conditionValue: null,
          targetSectionId: null,
          order: 1,
          defaultValue: '',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 1,
          row: 1002,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'Date_Nullable'
        },
        {
          formFieldId: '307e5c22-15f1-4bae-95c0-b00f004e4c62',
          questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
          isRequired: true,
          label: 'TYPE',
          sectionId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
          conditionValue: null,
          targetSectionId: null,
          order: 1,
          defaultValue: 'TYPE',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 1,
          row: 1001,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'Dropdown',
          options: workOrderTypeData?.map((x) => {
            return { label: x?.value, value: x?.key };
          })
        },
        {
          formFieldId: 'd84c1fd5-5d10-4e6f-bdd3-b00f004e4c62',
          questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
          isRequired: true,
          label: 'PRIORITY',
          sectionId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
          conditionValue: null,
          targetSectionId: null,
          order: 0,
          defaultValue: 'test',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 1,
          row: 1001,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'Dropdown',
          options: workOrderPriorityData?.map((x) => {
            return { label: x?.value, value: x?.key };
          })
        },
        {
          formFieldId: '9fe9daf9-ccc1-40e8-8581-b00f004e4c67',
          questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
          isRequired: tradeRequired,
          label: 'TRADE',
          sectionId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
          conditionValue: null,
          targetSectionId: null,
          order: 0,
          defaultValue: 'TRADE',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 1,
          row: 1002,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'Dropdown',
          options: tradeData?.map((x) => {
            return { label: x?.value, value: x?.key };
          })
        },
        {
          formFieldId: '0e286da8-90d0-47ac-bf66-b00f004e4c62',
          questionTypeId: 'c8a9d704-9023-4bbf-b719-a49f00ae7ab4',
          isRequired: true,
          label: 'TITLE',
          sectionId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
          conditionValue: null,
          targetSectionId: null,
          order: 0,
          defaultValue: '',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 1,
          row: 1000,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'Text'
        }
      ]
    },

    {
      formSectionId: '51d20345-a583-46b2-8332-b00f00231e74',
      name: 'Your Attachments',
      formId: '0e515a20-842d-4846-b083-b00e018976a0',
      parentId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
      childId: null,
      order: 3000,
      enable: 'Y',
      menuName: 'Your Attachments',
      panel: '51d20345-a583-46b2-8332-b00f00231e74',
      hidden: null,
      isDeleted: false,
      formFields: [
        {
          formFieldId: '4630a3cb-17ea-4fd8-8fb9-b00f004e4c6c',
          questionTypeId: '833db834-a0f7-41f7-ae4d-50d71464d4b7',
          isRequired: false,
          label: 'Your Attachments',
          sectionId: '51d20345-a583-46b2-8332-b00f00231e74',
          conditionValue: null,
          targetSectionId: null,
          order: null,
          defaultValue: '',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 2,
          row: 3000,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'Photos'
        }
      ]
    }
  ];

  const administratorOptions = allowAssignmentOnWorkRequest
    ? [
        ...([...workOrderAdministratorGroups]?.sort(sortAlphabetically) || []),
        ...([...workOrderAdministrators]?.sort(sortAlphabetically) || [])
      ]
    : []; // Administrator(s)
  const responsibleUsersOptions = allowAssignmentOnWorkRequest
    ? [
        ...([...workOrderUsersGroups]?.sort(sortAlphabetically) || []),
        ...([...workOrderUsers]?.sort(sortAlphabetically) || [])
      ]
    : []; // Responsible user(s)

  allowAssignmentOnWorkRequest &&
    formSections.push({
      formSectionId: '8776906c-4d71-4375-b813-b00f004e4c6c',
      name: 'Assignment',
      formId: '0e515a20-842d-4846-b083-b00e018976a0',
      parentId: '7cbed9fd-b464-419f-bcd6-b00e01897812',
      childId: null,
      order: 2,
      enable: 'Y',
      menuName: 'Assignment',
      panel: '7cbed9fd-b464-419f-bcd6-b00e01897812',
      hidden: null,
      isDeleted: false,
      formFields: [
        {
          formFieldId: '1f769f95-a5a8-4fa6-9a99-b00f004e4c6c',
          questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
          isRequired: false,
          label: 'RESPONSIBLE USER(S)',
          sectionId: '8776906c-4d71-4375-b813-b00f004e4c6c',
          conditionValue: null,
          targetSectionId: null,
          order: 1,
          defaultValue: 'RESPONSIBLE USER(S)',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 1,
          row: 2000,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'Dropdown',

          options: responsibleUsersOptions?.map((x) => {
            return {
              label: x?.name ? x?.name : x?.firstName + ' ' + x.lastName || '',
              value: x?.id || ''
            };
          })
        },
        {
          formFieldId: '7888f7fb-a3dc-44bf-b10b-b00f004e4c6c',
          questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
          isRequired: false,
          label: 'ADMINISTRATOR(S)',
          sectionId: '8776906c-4d71-4375-b813-b00f004e4c6c',
          conditionValue: null,
          targetSectionId: null,
          order: 0,
          defaultValue: 'ADMINISTRATOR(S)',
          severity: 1,
          isReadOnly: false,
          sysName: '',
          triggerWorkOrderCreation: false,
          passThreshold: 0,
          deficiencyProcessingEnabled: false,
          tooltip: '',
          sourceEntity: '',
          SourceProperty: '',
          isMasterData: false,
          isDeleted: false,
          columnsRequired: 1,
          row: 2000,
          placeholderText: '',
          customValidation: null,
          questionTypeText: 'Dropdown',
          options: administratorOptions?.map((x) => {
            return {
              label: x?.name ? x?.name : x?.firstName + ' ' + x.lastName || '',
              value: x?.id || ''
            };
          })
        }
      ]
    });

  return {
    inspectionFormId: '0e515a20-842d-4846-b083-b00e018976a0',
    name: 'inspection form 01',
    sections: null,
    fields: null,
    clientId: 'ad4d228e-9970-47cc-bccf-ec2ba347eb6d',
    inspectionType: 400020,
    inspectionCategory: null,
    module: 'Inspection',
    appliesTo: 'Site',
    hasSensor: null,
    deficiencyProcessingEnabled: false,
    hasMasterData: false,
    clientSideSpecialHandling: null,
    quickComplete: false,
    number: 'FORM-0014',
    failureCriteria: '559f6283-3b27-4a9b-bf17-c6ca3318e64b',
    numberOfFailedFields: null,
    editable: true,
    availableOnDemand: true,
    formSections: formSections,
    failureCriteriaText: null,
    site: true,
    equipment: false,
    pipeline: false,
    links: [
      {
        href: 'https://oplii-dev-api.azurewebsites.net/inspectionform/0e515a20-842d-4846-b083-b00e018976a0',
        rel: 'self',
        method: 'GET'
      },
      {
        href: 'https://oplii-dev-api.azurewebsites.net/users/4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
        rel: 'createdby',
        method: 'GET'
      },
      {
        href: 'https://oplii-dev-api.azurewebsites.net/users/4f1b46ff-e9a6-4bcc-a7ed-af7300a69c63',
        rel: 'updatedby',
        method: 'GET'
      }
    ]
  };
};

export const getNewWorkOrderTodoFormData = () => {
  const { picklists, tasks, settings } = store.getState();
  const tradeRequired = settings?.settings?.tradeRequired || false;
  const {
    tradeData,
    workOrderPriorityData,
    workOrderTypeData,
    tradeSkillsData,
    picklistsMap,
    workOrderSkillsData
  } = picklists;

  const {
    workOrderAdministratorGroups,
    workOrderAdministrators,
    workOrderUsers,
    workOrderUsersGroups
  } = tasks;

  const administratorOptions = [
    ...([...workOrderAdministratorGroups]?.sort(sortAlphabetically) || []),
    ...([...workOrderAdministrators]?.sort(sortAlphabetically) || [])
  ]; // Administrator(s)
  const responsibleUsersOptions = [
    ...([...workOrderUsersGroups]?.sort(sortAlphabetically) || []),
    ...([...workOrderUsers]?.sort(sortAlphabetically) || [])
  ]; // Responsible user(s)

  return {
    inspectionFormId: '1010909c-7c79-4b63-a4e0-b00f004d35d5',
    name: 'work order todo form',
    sections: null,
    fields: null,
    clientId: 'ad4d228e-9970-47cc-bccf-ec2ba347eb6d',
    inspectionType: 400021,
    inspectionCategory: null,
    module: 'Inspection',
    appliesTo: 'Site',
    hasSensor: null,
    deficiencyProcessingEnabled: false,
    hasMasterData: false,
    clientSideSpecialHandling: null,
    quickComplete: false,
    number: 'FORM-0015',
    failureCriteria: '559f6283-3b27-4a9b-bf17-c6ca3318e64b',
    numberOfFailedFields: null,
    editable: true,
    availableOnDemand: true,
    formSections: [
      {
        formSectionId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
        name: 'General',
        formId: '1010909c-7c79-4b63-a4e0-b00f004d35d5',
        parentId: null,
        childId: null,
        order: 1,
        enable: 'Y',
        menuName: 'General',
        panel: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
        hidden: null,
        isDeleted: false,
        formFields: [
          {
            formFieldId: '7ee68589-2e35-433d-867e-b00f004e4c6c',
            questionTypeId: '844c7c4a-97df-416a-b57c-9478ca81aa1c',
            isRequired: true,
            label: 'DESCRIPTION',
            sectionId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
            conditionValue: null,
            targetSectionId: null,
            order: 0,
            defaultValue: '',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 1,
            row: 1003,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'LongText'
          },
          {
            formFieldId: '1b232148-ef85-4206-bf3b-b00f004e4c67',
            questionTypeId: '725c248a-929b-47b6-8b93-a49f00ae7ab4',
            isRequired: true,
            label: 'DUE DATE',
            sectionId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
            conditionValue: null,
            targetSectionId: null,
            order: 1,
            defaultValue: '',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 1,
            row: 1002,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'Date_Nullable'
          },
          {
            formFieldId: '307e5c22-15f1-4bae-95c0-b00f004e4c62',
            questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
            isRequired: true,
            label: 'TYPE',
            sectionId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
            conditionValue: null,
            targetSectionId: null,
            order: 1,
            defaultValue: 'TYPE',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 1,
            row: 1001,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'Dropdown',
            options: workOrderTypeData?.map((x) => {
              return { label: x?.value, value: x?.key };
            })
          },
          {
            formFieldId: 'd84c1fd5-5d10-4e6f-bdd3-b00f004e4c62',
            questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
            isRequired: true,
            label: 'PRIORITY',
            sectionId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
            conditionValue: null,
            targetSectionId: null,
            order: 0,
            defaultValue: 'PRIORITY',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 1,
            row: 1001,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'Dropdown',
            options: workOrderPriorityData?.map((x) => {
              return { label: x?.value, value: x?.key };
            })
          },
          {
            formFieldId: '9fe9daf9-ccc1-40e8-8581-b00f004e4c67',
            questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
            isRequired: tradeRequired,
            label: 'TRADE',
            sectionId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
            conditionValue: null,
            targetSectionId: null,
            order: 0,
            defaultValue: 'TRADE',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 1,
            row: 1002,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'Dropdown',
            options: tradeData?.map((x) => {
              return { label: x?.value, value: x?.key };
            })
          },
          {
            formFieldId: '0e286da8-90d0-47ac-bf66-b00f004e4c62',
            questionTypeId: 'c8a9d704-9023-4bbf-b719-a49f00ae7ab4',
            isRequired: true,
            label: 'TITLE',
            sectionId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
            conditionValue: null,
            targetSectionId: null,
            order: 0,
            defaultValue: '',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 1,
            row: 1000,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'Text'
          }
        ]
      },
      {
        formSectionId: '72d14fab-0b75-4030-955e-b00f004e4c6c',
        name: 'Your Attachments',
        formId: '1010909c-7c79-4b63-a4e0-b00f004d35d5',
        parentId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
        childId: null,
        order: 3,
        enable: 'Y',
        menuName: 'Your Attachments',
        panel: '72d14fab-0b75-4030-955e-b00f004e4c6c',
        hidden: null,
        isDeleted: false,
        formFields: [
          {
            formFieldId: '4630a3cb-17ea-4fd8-8fb9-b00f004e4c6c',
            questionTypeId: '833db834-a0f7-41f7-ae4d-50d71464d4b7',
            isRequired: false,
            label: 'Your Attachments',
            sectionId: '72d14fab-0b75-4030-955e-b00f004e4c6c',
            conditionValue: null,
            targetSectionId: null,
            order: null,
            defaultValue: '',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 2,
            row: 3000,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'Photos'
          }
        ]
      },
      {
        formSectionId: '8776906c-4d71-4375-b813-b00f004e4c6c',
        name: 'Assignment',
        formId: '1010909c-7c79-4b63-a4e0-b00f004d35d5',
        parentId: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
        childId: null,
        order: 2,
        enable: 'Y',
        menuName: 'Assignment',
        panel: '44f9bcea-ebb6-40ea-a3e3-b00f004d362a',
        hidden: null,
        isDeleted: false,
        formFields: [
          {
            formFieldId: '1f769f95-a5a8-4fa6-9a99-b00f004e4c6c',
            questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
            isRequired: false,
            label: 'RESPONSIBLE USER(S)',
            sectionId: '8776906c-4d71-4375-b813-b00f004e4c6c',
            conditionValue: null,
            targetSectionId: null,
            order: 1,
            defaultValue: 'RESPONSIBLE USER(S)',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 1,
            row: 2000,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'Dropdown',

            options: responsibleUsersOptions?.map((x) => {
              return {
                label: x?.name ? x?.name : x?.firstName + ' ' + x.lastName || '',
                value: x?.id || ''
              };
            })
          },
          {
            formFieldId: '7888f7fb-a3dc-44bf-b10b-b00f004e4c6c',
            questionTypeId: 'fbb64c50-f90b-439a-8dbb-a49f00ae7ab4',
            isRequired: false,
            label: 'ADMINISTRATOR(S)',
            sectionId: '8776906c-4d71-4375-b813-b00f004e4c6c',
            conditionValue: null,
            targetSectionId: null,
            order: 0,
            defaultValue: 'ADMINISTRATOR(S)',
            severity: 1,
            isReadOnly: false,
            sysName: '',
            triggerWorkOrderCreation: false,
            passThreshold: 0,
            deficiencyProcessingEnabled: false,
            tooltip: '',
            sourceEntity: '',
            SourceProperty: '',
            isMasterData: false,
            isDeleted: false,
            columnsRequired: 1,
            row: 2000,
            placeholderText: '',
            customValidation: null,
            questionTypeText: 'Dropdown',
            options: administratorOptions?.map((x) => {
              return {
                label: x?.name ? x?.name : x?.firstName + ' ' + x.lastName || '',
                value: x?.id || ''
              };
            })
          }
        ]
      }
    ],
    failureCriteriaText: null,
    site: true,
    equipment: false,
    pipeline: false
  };
};

export const getNewWorkOrderDoneFormData = () => {
  const formId = uuidv4();
  const generalFormSectionId = uuidv4();
  const assignmentId = uuidv4();
  const workLogId = uuidv4();
  const yourAttachmentsId = uuidv4();

  return {
    inspectionFormId: formId,
    name: 'new work order done form',
    sections: 2,
    fields: 62,
    clientId: null,
    inspectionType: null,
    inspectionCategory: null,
    module: 'WorkOrder',
    appliesTo: 'All',
    hasSensor: false,
    deficiencyProcessingEnabled: false,
    hasMasterData: false,
    clientSideSpecialHandling: null,
    quickComplete: false,
    number: null,
    failureCriteria: null,
    numberOfFailedFields: null,
    editable: true,
    availableOnDemand: false,
    formSections: [
      //   first section
      {
        formSectionId: generalFormSectionId,
        name: 'General',
        formId,
        parentId: null,
        childId: null,
        order: 1000,
        enable: 'Y',
        menuName: 'General',
        panel: generalFormSectionId,
        hidden: null,
        isDeleted: false,
        formFields: []
      },
      {
        formSectionId: assignmentId,
        name: 'Assignment',
        formId,
        parentId: generalFormSectionId,
        childId: null,
        order: 2000,
        enable: 'Y',
        menuName: 'Assignment',
        panel: generalFormSectionId,
        hidden: null,
        isDeleted: false,
        formFields: []
      },
      // second section
      {
        formSectionId: workLogId,
        name: 'Work log',
        formId,
        parentId: null,
        childId: null,
        order: 1000,
        enable: 'Y',
        menuName: 'Work log',
        panel: workLogId,
        hidden: null,
        isDeleted: false,
        formFields: []
      },
      {
        formSectionId: yourAttachmentsId,
        name: 'Your attachments',
        formId,
        parentId: workLogId,
        childId: null,
        order: 1000,
        enable: 'Y',
        menuName: 'Work log',
        panel: workLogId,
        hidden: null,
        isDeleted: false,
        formFields: []
      }
    ]
  };
};

const sortAlphabetically = (a, b) => {
  const aName = a?.name ? a?.name : a?.firstName + ' ' + a.lastName || '';
  const bName = b?.name ? b?.name : b?.firstName + ' ' + b.lastName || '';
  const nameA = aName.toUpperCase(); // Convert name to uppercase
  const nameB = bName.toUpperCase(); // Convert name to uppercase

  if (nameA < nameB) {
    return -1; // a should come before b in the sorted order
  }

  if (nameA > nameB) {
    return 1; // a should come after b in the sorted order
  }

  return 0; // Names are equal, no change in the order
};

const convertAssignedWorkOrderCheckSheet = ({ checkSheetForm }) => {
  const checkSheetSection = [];

  for (let checks = 0; checks < checkSheetForm?.formSections?.length; checks++) {
    const formSection = checkSheetForm?.formSections;
    if (!checks && formSection[checks]?.parentId === null)
      checkSheetSection.push(formSection[checks]);
    else if (formSection[checks]?.parentId)
      checkSheetSection.push({
        ...formSection[checks],
        parentId: formSection?.at(0)?.formSectionId
      });
    else if (!formSection[checks]?.parentId) {
      checkSheetSection.push({
        ...formSection[checks],
        parentId: formSection?.at(0)?.formSectionId
      });
    }
  }
  return checkSheetSection;
};
