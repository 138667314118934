/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AppBar from '@components/app-bar';

import useWindowDimensions from '@hooks/useWindowDimensions';

const PageContainer = ({
  children,
  moreInfo = false,
  checkedLengthOfRtsTasksAndSites = 0,
  checkedLengthOfRunsTasksAndSites = 0
}) => {
  const { width } = useWindowDimensions();
  const selectTask = useSelector((state) => state.tasks.selectTask) || false;
  return (
    <div className="page-container">
      {(selectTask ||
        moreInfo ||
        checkedLengthOfRtsTasksAndSites ||
        checkedLengthOfRunsTasksAndSites) &&
      width <= 767 ? (
        ''
      ) : (
        <AppBar />
      )}

      <div className="md:ml-24">
        <div className="relative ">{children}</div>
      </div>
    </div>
  );
};

PageContainer.protoTypes = {
  children: PropTypes.node.isRequired
};

export default PageContainer;
