import React, { useState } from 'react';

import HighlightText from '@components/highlight-text';

import style from './body.module.scss';

const BodyDescription = ({ description, highlight }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = (e) => {
    if (description?.length > 169) {
      e.stopPropagation();
      setExpanded(!expanded);
    }
  };

  return (
    <div className={style.description}>
      <p className={expanded ? style.expanded : style.collapsed} onClick={handleExpandClick}>
        <HighlightText text={description} highlight={highlight} />
      </p>
    </div>
  );
};

export default BodyDescription;
