const multipleSiteAutoPopulate = ({ siteIds, cacheSites, sitesMap, areaHierarchyTreeDataMap }) => {
  let obj = {};
  //LastInspectionDate missing
  for (let id of siteIds) {
    const site = cacheSites[sitesMap[id]];
    if (site?.HighPilotTripCheckUnit) {
      obj = {
        ...obj,
        HighPilotTripCheck: site?.HighPilotTripCheckUnit
      };
    }
    if (site?.siteNumber) {
      obj = {
        ...obj,
        SiteNumber: site?.siteNumber
      };
    }
    if (site?.surfaceLocation) {
      obj = {
        ...obj,
        LSD: site?.surfaceLocation
      };
    }
    if (site?.operations_Area) {
      obj = {
        ...obj,
        Operations_Area: site?.operations_Area
      };
    }
    if (site?.LowPilotTripCheckUnit)
      obj = {
        ...obj,
        LowPilotTripCheck: site?.LowPilotTripCheckUnit
      };

    if (site?.methanolInjectionRateUnit)
      obj = {
        ...obj,
        MethanolInjectionRate: site?.methanolInjectionRateUnit
      };

    if (site?.chemicalInjectionRateUnit)
      obj = {
        ...obj,
        ChemicalInjectionRate: site?.chemicalInjectionRateUnit
      };

    if (site?.maxPressure)
      obj = {
        ...obj,
        MaxPressure: site?.maxPressure
      };

    if (site?.surfaceCasing)
      obj = {
        ...obj,
        SurfaceCasing: site?.surfaceCasing
      };

    if (site?.costCentreNumber)
      obj = {
        ...obj,
        CostCentreNumber: site?.costCentreNumber
      };

    if (site?.wellbore)
      obj = {
        ...obj,
        Wellbore: site?.wellbore
      };
    if (site?.cO2)
      obj = {
        ...obj,
        CO2Percent: site?.cO2
      };

    if (site?.h2S)
      obj = {
        ...obj,
        H2SPercent: site?.h2S
      };

    if (site?.siteType)
      obj = {
        ...obj,
        SiteType: site?.siteType
      };

    if (areaHierarchyTreeDataMap[site?.areaHierarchy]?.key)
      obj = {
        ...obj,
        Area: areaHierarchyTreeDataMap[site?.areaHierarchy]?.key
      };

    if (site?.province)
      obj = {
        ...obj,
        Province: site?.province
      };

    if (site?.status)
      obj = {
        ...obj,
        Status: site?.status
      };

    if (site?.lisenseNumber)
      obj = {
        ...obj,
        LisenseNumber: site?.lisenseNumber
      };

    if (site?.uwi)
      obj = {
        ...obj,
        UWI: site?.uwi
      };

    if (site?.surfaceLocation)
      obj = {
        ...obj,
        SurfaceLocation: site?.surfaceLocation
      };

    if (site?.name)
      obj = {
        ...obj,
        Name: site?.name
      };

    if (areaHierarchyTreeDataMap[site?.areaHierarchy]?.title)
      obj = {
        ...obj,
        AreaName: areaHierarchyTreeDataMap[site?.areaHierarchy]?.title
      };
  }

  return obj;
};

export default multipleSiteAutoPopulate;
