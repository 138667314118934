import React from 'react';

import Icon from '@components/icon-component';

import style from './check.module.scss';

const header = ({ handleBackClick, pageHeaderTitle, info, setInfo }) => {
  return (
    <div>
      <div className={`px-4 md:px-8 py-7 ${style.header} `}>
        <p className={style.cancelClass} onClick={() => handleBackClick()}>
          Cancel
        </p>
        <h1 className={` ${style.addClass} h1-semibold`}>{pageHeaderTitle}</h1>

        <div className={style.invisibleDiv}>
          <Icon name={info ? 'info-blue' : 'info-white'} onClick={() => setInfo((prev) => !prev)} />
        </div>
      </div>
    </div>
  );
};

export default header;
