/* eslint-disable no-unused-vars */

import { Link } from 'react-router-dom';

import Modal from '../..';

import style from './style.module.scss';

const OfflineModal = ({ open, setOpen, lastSyncTime, pendingSync = true, offline1 = false }) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={'sync-modal-icon'}
      statusText="You're offline"
      modalTextUpper={() => (
        <>
          {offline1 ? (
            <>Sorry. No can do</>
          ) : (
            <>
              It has been {lastSyncTime} since you’ve refreshed data. <br />
              {pendingSync ? (
                <>
                  <br /> You have <b>{pendingSync} records</b> that are{' '}
                  <Link
                    className="link"
                    onClick={() => setOpen(false)}
                    to={'/task-records?status=Pending sync'}
                  >
                    pending sync
                  </Link>
                  .
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
      textBtn={'Close'}
      progressData="You’ll need a stable internet connection before you can sync."
      progressDataColor={'#F26531'}
    >
      <div>
        <div className={style.modalBtn}>
          <button className={`${style.disabledBtn} button-large`} disabled>
            Sync now
          </button>
        </div>

        <div className={style.modalBtn}>
          <button className={`${style.disabledBtn} button-large`} disabled>
            Refresh data only
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default OfflineModal;
