/* eslint-disable no-unused-vars */

import moment from 'moment';

import { PERMISSIONS } from 'permissions/constants';
import TasksSelector from 'selectors/tasks/tasks.selector';
import InspectionSelector from 'selectors/inspection/inspection.selector';
import CheckSelector from 'selectors/checks/check.selector';

export const taskTypeData = async ({ store, permissions }) => {
  const allWOTasks = (await TasksSelector.getTasks({ store })) || [];
  const allDoneWOTasks = allWOTasks.filter((task) => task?.status === 'Synced');
  const allNotDoneWOTasks = allWOTasks.filter((task) => task?.status !== 'Synced');

  const allInspectionTasks = (await InspectionSelector.getInspections({ store })) || [];
  const allDoneInspectionTasks = allInspectionTasks.filter((task) => task?.status === 'Synced');
  const allNotDoneInspectionTasks = allInspectionTasks.filter((task) => task?.status !== 'Synced');

  const allCheckTasks = (await CheckSelector.getAllChecks({ store })) || [];
  const allDoneCheckTasks = allCheckTasks.filter((task) => task?.status === 'Synced');
  const allNotDoneCheckTasks = allCheckTasks.filter((task) => task?.status !== 'Synced');

  const allTasksList = allTasksWithPermissions({
    permissions,
    allWOTasks,
    allInspectionTasks,
    allCheckTasks
  });
  const allDoneTasks = allTasksList.filter((task) => task?.status === 'Synced');
  const allNotDoneTasks = allTasksList.filter((task) => task?.status !== 'Synced');

  const today = moment().format('YYYY-MM-DD');
  const isOverDue = allNotDoneTasks.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  const isWOOverDue = allNotDoneWOTasks.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  const IsInspectionOverDue = allNotDoneInspectionTasks.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  const IsCheckOverDue = allCheckTasks.find((task) =>
    task.dueDate ? moment(task.nextDue).isBefore(today) : false
  );

  return {
    allWOTasks,
    allDoneWOTasks,
    allInspectionTasks,
    allDoneInspectionTasks,
    allDoneCheckTasks,
    allNotDoneCheckTasks,
    allCheckTasks,
    allTasks: allTasksList,
    allDoneTasks,
    isOverDue,
    isWOOverDue,
    IsInspectionOverDue,
    IsCheckOverDue
  };
};

const allTasksWithPermissions = ({
  permissions,
  allWOTasks,
  allInspectionTasks,
  allCheckTasks
}) => {
  let allTasks = [];
  if (
    permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS] &&
    permissions[PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS] &&
    permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS]
  )
    allTasks = [...allWOTasks, ...allInspectionTasks, ...allCheckTasks];
  else if (
    permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS] &&
    permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS]
  )
    allTasks = [...allCheckTasks, ...allWOTasks];
  else if (
    permissions[PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS] &&
    permissions[PERMISSIONS.allCheckTasks]
  )
    allTasks = [...allInspectionTasks, ...allCheckTasks];
  else if (
    permissions[PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS] &&
    permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS]
  )
    allTasks = [...allInspectionTasks, ...allWOTasks];
  else if (permissions[PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS]) allTasks = [...allInspectionTasks];
  else if (permissions[PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS]) allTasks = [...allWOTasks];
  else if (permissions[PERMISSIONS.MOBILE_ASSIGNED_CHECKS]) allTasks = [...allCheckTasks];
  return allTasks;
};
