/* eslint-disable no-unused-vars */
import React, { memo, useState, useEffect, useMemo, forwardRef } from 'react';
import _ from 'lodash';
import { store } from 'redux/store';

import SearchInput from '../search-input';
import NewTabs from '@components/new-tabs';
import StructureListingInner from '@components/structure-listing-inner';
import SiteListingInner from '@components/site-listing-inner';
import EquipmentListingInner from '@components/equipment-listing/equipment-listing-inner';
import VirtualList from '@components/virtual-list/virtual-list';
import LoadingRound from '@components/loading-round/loading-round';
import PipelineListingInner from '@components/pipeline-listing/pipeline-listing-inner/pipeline-listing-inner.component';

import { useDebounce } from '@hooks/useDebounce';
import { filterEquipments, filterSites, filterStructure, filterPipeline } from './helper';
import { initWebWorker } from 'webworker';

import './search-tab-list.scss';

const components = {
  site: SiteListingInner,
  equipment: EquipmentListingInner,
  structure: StructureListingInner,
  pipeline: PipelineListingInner
};

const SearchList = (
  { label = '', onSelect, setIsLoadingOnParent, equipmentCategories = [] },
  ref
) => {
  const [active, setActive] = useState('0');
  const key = label.toLowerCase();
  const Component = components[key];
  const [inputValue, setInputValue] = useState('');
  const [sites, setSites] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [structures, setStructures] = useState([]);
  const [pipelines, setPipelines] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);

  const debouncedSearchInputValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    const type =
      key === 'site'
        ? 'get-all-site-listings'
        : key === 'equipment'
        ? 'get-all-equipment-listing'
        : key === 'pipeline'
        ? 'get-all-pipeline-listing'
        : 'get-all-structure-listing';

    initWebWorker({ type, args: { store: { ...store.getState() }, equipmentCategories } })
      .then((data) => {
        key === 'site'
          ? setSites(data?.result)
          : key === 'equipment'
          ? setEquipments(data?.result)
          : key === 'pipeline'
          ? setPipelines(data?.result)
          : setStructures(data?.result);
        setInitialLoading(false);
      })
      .catch((error) => {
        setInitialLoading(false);
      });
  }, [key]);

  useEffect(() => {
    if (!initialLoading) {
      (async () => {
        try {
          setIsLoading(true);
          if (key === 'site' && _.size(sites) > 0) {
            const filterSitesData = await filterSites({
              sites,
              inputValue: debouncedSearchInputValue
            });
            setFilterData([...filterSitesData]);
          } else if (key === 'equipment' && _.size(equipments) > 0) {
            const filterEquipmentData = await filterEquipments({
              equipments,
              inputValue: debouncedSearchInputValue,
              store: { ...store.getState() }
            });
            setFilterData([...filterEquipmentData]);
          } else if (key === 'structure' && _.size(structures) > 0) {
            const filterStructureList = await filterStructure({
              structures,
              inputValue: debouncedSearchInputValue,
              store: { ...store.getState() }
            });
            setFilterData([...filterStructureList]);
          } else if (key === 'pipeline' && _.size(pipelines) > 0) {
            const filterPipelineList = await filterPipeline({
              pipelines,
              inputValue: debouncedSearchInputValue,
              store: { ...store.getState() }
            });
            setFilterData([...filterPipelineList]);
          }
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [debouncedSearchInputValue, initialLoading]);
  const filtered = useMemo(() => {
    return filterData;
  }, [filterData]);

  if (!Component) return '';

  const items = [
    {
      label: `${label} (${filtered.length.toLocaleString()})`,
      children: (
        <div className="scroll-list-content">
          <VirtualList
            divider
            data={filtered}
            component={Component}
            componentProps={{
              onSelect,
              highlight: inputValue,
              highlightName: true,
              addNewWizard: true
            }}
          />
        </div>
      )
    }
  ];

  useEffect(() => {
    const parent = document.querySelector('.wizard-steps--content');
    const parent1 = document.querySelector('.wizard-steps');
    const parent2 = document.querySelector('.wizard-steps--title');

    parent.style.overflow = isLoading ? 'hidden' : '';
    parent1.style.backgroundColor = isLoading ? '#ffffff' : '';
    parent2.style.backgroundColor = isLoading ? '#ffffff' : '';
  }, [isLoading]);

  return (
    <>
      {isLoading ? (
        <div style={{ backgroundColor: '#ffffff', width: '100%' }}>
          <LoadingRound />
        </div>
      ) : (
        <>
          <div className="search-tab-list">
            <SearchInput value={inputValue} onChange={setInputValue} />
            <div className="scroll-list with-search">
              <NewTabs
                active={active}
                tabs={items}
                setActive={(tab) => setActive(tab)}
                white
                leftMenu
              >
                {items[active]?.children}
              </NewTabs>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default memo(forwardRef(SearchList));
