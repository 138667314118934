import React, { memo, forwardRef } from 'react';
import _ from 'lodash';

import InputField from '@components/input-field';

import style from '../workorder.module.scss';

const FormTextField = (
  { errorsMsg, removeSpecialCharactersForName, field, name, register, watch, label, setValue },
  ref
) => {
  const { refs } = ref;

  const errorsMessage = errorsMsg?.message || '';
  return (
    <div
      className="op-input-field-wrap"
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
    >
      <InputField
        setValue={setValue}
        label={label}
        dot={field?.isRequired}
        type={
          field?.questionTypeText === 'Text' || field?.questionTypeText === 'PhoneNumber'
            ? 'text'
            : field?.questionTypeText === 'Numeric'
            ? 'number'
            : 'text'
        }
        step={field?.questionTypeText === 'Numeric' ? 'any' : undefined}
        inputClass="input-field input-regular"
        labelColor={style.labelColor}
        register={register}
        name={name}
        error={errorsMessage}
        placeholder={field?.placeholderText}
        disabled={field?.isReadOnly}
        telephone={field?.questionTypeText === 'PhoneNumber'}
        reconciled={field?.isMasterData}
        showReconciled={
          !_.isEmpty(watch(`${name}-reconcile`))
            ? field?.isMasterData && watch(`${name}-reconcile`) !== watch(name)
              ? true
              : false
            : !_.isEmpty(watch(name)) && field?.isMasterData
        }
      />
    </div>
  );
};

export default memo(forwardRef(FormTextField));
