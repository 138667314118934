/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle
} from 'react';
import { store } from 'redux/store';
import _ from 'lodash';
import { useDebounce } from '@hooks/useDebounce';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

import TaskFilter from '../filters/tasks-records-filter.component';
import TaskRecordPageHeader from './task-record-list-page-header';
import TaskCardModals from './task-card-modals';
import TasksInspectionsTemplateAndRunsLists from './tasks-inspections-template-runs-lists';
import StartAndQuickCompleteButton from './start-and-quick-complete-btn';
import ResetFilter from './reset-filter';

import {
  statusTabs,
  tagsData,
  tagsDataRT,
  initialPopupStat,
  tasksDefaultFilterValues
} from '../helper';
import { filterTasks, getTasksLength, getCheckedLength, getQuickCheckedLength } from './helper';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { moveInspectionToInProgress } from './inspection-quick-complete';
import { startBtn, openModalEvent } from './service';
import { useToastContext } from 'context/toaster.context';
import { getAppDispatch } from '@utilities/dispatch.util';
import useDistance from '@hooks/useDistance';
import { cacheActions } from '@redux/slices/cache';
import { tasksListsActions } from '@redux/slices/tasks';
import { initWebWorker } from 'webworker';

import './tasks-records-list.scss';

const TasksList = (
  {
    tasksList,
    asset,
    loading,
    setSyncComplete = null,
    selectTodoOrInProgressStatusOnBookmarkSelection = false,
    onParentClickEvent = null,
    workOrderSelectedTab = null,
    workOrderSortTab = null,
    isFilterActiveHeader = false,
    setFilterActiveHeader = () => {},
    siteDetails = false,
    parentSiteDetails = false,
    filterType,
    runs,
    templates,
    templateTasksLists,
    workRequest = false,
    hseEvent = false
  },
  ref
) => {
  let _store = { ...store.getState() };

  const navigate = useNavigate();
  const dispatch = getAppDispatch();
  const location = useLocation();
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const { ToasterUtil } = useToastContext();
  const { coordinates } = useDistance({});

  const {
    type = null,
    // filterType: filterGroup = null,
    type: filterGroup = null,
    bookmarkFilter = null,
    bookmarkName = null,
    status = null
  } = Object.fromEntries(new URLSearchParams(location.search));

  const picklistsMap = useSelector((state) => state.picklists.picklistsMap) || {};
  const { inspectionFormsUserCache } = useSelector((state) => state.inspection) || {};

  const [resetFilters, setResetFilters] = useState(false);
  const [tab, setTab] = useState('0');
  const [data, setData] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [searched, setSearched] = useState('');
  const [filtersCount, setFiltersCount] = useState(0);
  const [templateData, setTemplateData] = useState([]);
  const [openModalIndex, setOpenModalIndex] = useState(-1);
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [popupData, setPopupData] = useState(initialPopupStat);
  const [SelectedFilter, setSelectedFilter] = useState({ DISTANCE: true });
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);
  const [SelectedFilterRT, setSelectedFilterRT] = useState({ 'RT ORDER': true });
  const [filters, setFilters] = useState(tasksDefaultFilterValues);
  const [sitesAreaHierarchyTreeDataMap, setSitesAreaHierarchyTreeDataMap] = useState({});
  const [sitesSiteTypeDataMap, setSitesSiteTypeDataMap] = useState({});
  const [sitesWorkOrderPriorityDataMap, setSitesWorkOrderPriorityDataMap] = useState({});
  const [sitesSiteStatusDataMap, setSitesSiteStatusDataMap] = useState({});
  const [tasksMap, setTasksMap] = useState({});
  const [assignedUserGroups, setAssignedUserGroups] = useState([]);
  const [tasksLists, setTasksLists] = useState([]);
  const [loadingOnSearch, setLoadingOnSearch] = useState(true);
  const [tasks, setTasks] = useState([]);

  const tabsTasksRef = useRef(null);
  const taskFilterRef = useRef(null);

  useEffect(() => {
    if (tasksList?.length) setTasksLists(tasksList);
  }, [tasksList]);

  useEffect(() => {
    setFilterActiveHeader(isFilterActive);
  }, [isFilterActive]);

  useEffect(() => {
    setFilterActive(isFilterActiveHeader);
  }, [isFilterActiveHeader]);

  useEffect(() => {
    if (asset) {
      setFilters(tasksDefaultFilterValues);
      setSearched('');
    }
  }, [asset]);

  useEffect(() => {
    if (width > 767) setFilterActive(true);
    setTab('0');
    setSelectedFilter({ DISTANCE: true });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(bookmarkFilter)) {
      const filtersInDecode = decodeURIComponent(bookmarkFilter);
      const filterInParse = JSON.parse(filtersInDecode);
      if (filterInParse?.filters) {
        setFilters(filterInParse?.filters);
        filterInParse?.filters['searched'] &&
          setSearched(filterInParse?.filters['searched']?.trim() || '');
      }
      if (!_.isEmpty(filterInParse?.sort)) setSelectedFilter(filterInParse?.sort);
      filterInParse?.tab && setTab(statusTabs[filterInParse?.tab]);
    }
    return () => {};
  }, [bookmarkFilter]);

  useEffect(() => {
    if (workOrderSelectedTab) setTab(workOrderSelectedTab);
    if (workOrderSortTab) setSelectedFilter(workOrderSortTab);
  }, [workOrderSelectedTab, workOrderSortTab]);

  useEffect(() => {
    if (popupData.Status === 'complete' && setSyncComplete) setSyncComplete(true);
  }, [popupData.Status]);

  useEffect(() => {
    setData(
      tasksList.map((task) => {
        return {
          ...task,
          status: task?.woId
            ? task?.status
            : ['To do', 'Pending sync', 'In progress', 'Synced'].includes(task?.status)
            ? task?.status
            : task?.link
            ? 'Synced'
            : 'To do',
          priorityId: task?.priority,
          priority: picklistsMap[task?.priority]?.value,
          workStatus: picklistsMap[task?.status]?.value
        };
      })
    );
  }, [tasksList, picklistsMap]);

  useEffect(() => {
    if (!loading && status) status && setTab(statusTabs[status]);
  }, [status, loading]);

  useEffect(() => {
    const bookmarkFiltersParsed = bookmarkFilter ? JSON.parse(bookmarkFilter) : {};
    bookmarkFiltersParsed?.filters?.taskStatus?.value?.length > 0
      ? setTab(statusTabs[bookmarkFiltersParsed?.filters?.taskStatus?.value[0]])
      : setTab('0');
  }, [bookmarkFilter]);

  useEffect(() => {
    if (resetFilters && !_.isEmpty(bookmarkFilter)) navigate(location.pathname);
  }, [resetFilters]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [type, status]);

  const debouncedSearchInputValue = useDebounce(searched, 1000);

  useEffect(() => {
    if (!loading && tasksLists?.length > 0) {
      !loadingOnSearch && tasksLists?.length > 0 && setLoadingOnSearch(true);
      initWebWorker({
        args: {
          filters: !_.isEmpty(templateTasksLists)
            ? tasksDefaultFilterValues
            : { ...filters, searched: debouncedSearchInputValue?.trim() },
          type,
          data: tasksLists,
          store: { ..._store }
        },
        type: 'get-searched-tasks-listing'
      })
        .then((data1) => {
          const {
            tasks = [],
            tasksMap = {},
            sitesSiteTypeDataMap = {},
            sitesSiteStatusDataMap = {},
            sitesAreaHierarchyTreeDataMap = {},
            sitesWorkOrderPriorityDataMap = {}
          } = data1?.result || {};
          setSitesWorkOrderPriorityDataMap(sitesWorkOrderPriorityDataMap || {});
          setSitesSiteTypeDataMap(sitesSiteTypeDataMap || {});
          setSitesSiteStatusDataMap(sitesSiteStatusDataMap || {});
          setSitesAreaHierarchyTreeDataMap(sitesAreaHierarchyTreeDataMap || {});
          setTasksMap(tasksMap || {});

          setAssignedUserGroups(
            Array.from(new Set(data.map((t) => t?.responsibleUserGroups).flat(2))) || []
          );
          setTasks(tasks);
          setLoadingOnSearch(false);
        })
        .catch((error) => {
          setLoadingOnSearch(false);
        });
    }
  }, [
    loading,
    tasksLists,
    type,
    filters,
    debouncedSearchInputValue,
    isFilterActive,
    templateTasksLists
  ]);

  useEffect(() => {
    if (!_.isEmpty(templateTasksLists)) {
      console.log('Hello World');
      const templateDataMap = templateData?.reduce((acc, curr) => {
        acc[curr.id] = { open: curr.open };
        return acc;
      }, {});
      const filterTemplateTasksLists = templateTasksLists?.map((tempTasks) => {
        const temModifyTasks = tempTasks?.tasks.map((x) => {
          return {
            ...x,
            priorityId: x?.priority,
            priority: picklistsMap[x?.priority]?.value
          };
        });

        const { tasks = [] } = filterTasks({
          filters: { ...filters, searched: debouncedSearchInputValue?.trim() },
          type,
          data: temModifyTasks || [],
          notWantTagMaps: true,
          store: { ..._store }
        });
        const open = templateDataMap ? templateDataMap[tempTasks?.id]?.open : tempTasks?.open;
        return {
          ...tempTasks,
          open,
          tasks
        };
      });
      if (Object.keys(SelectedFilterRT)[0] === 'RT ORDER') {
        const sortedTasks = filterTemplateTasksLists?.sort((a, b) => {
          const rtNumberPrev = (a?.id?.split('-') && a?.id.split('-')[1]) || null;
          const rtNumberNext = (b?.id?.split('-') && b?.id?.split('-')[1]) || null;
          return parseInt(rtNumberPrev) - parseInt(rtNumberNext);
        });
        setTemplateData(sortedTasks);
      } else setTemplateData(filterTemplateTasksLists);
    }
  }, [
    type,
    tasks,
    runs,
    templates,
    templateTasksLists,
    filters,
    debouncedSearchInputValue,
    isFilterActive,
    SelectedFilterRT
  ]);

  useEffect(() => {
    Object.values(SelectedFilter).some((value) => value === false) &&
      setSelectedFilter({ ['A-Z']: true });
  }, [SelectedFilter]);

  useEffect(() => {
    const parent = document.querySelector('.page-listing');
    const child = document.querySelector('.taskHeight');
    const handleScroll = (e) => {
      parent.scrollTop = e.target.scrollTop;
    };

    if (child) {
      child.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (child) {
        child.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading]);

  const title = useMemo(() => {
    if (!_.isEmpty(bookmarkName)) return bookmarkName;
    else
      return type && ['Check', 'Inspection', 'Work Order'].includes(type)
        ? type === 'Work Order'
          ? 'Work orders'
          : `${type}s`
        : 'Tasks, records';
  }, [bookmarkFilter, type]);

  const { length, checkedLength, quickCheckedLength } = useMemo(() => {
    const length = getTasksLength(runs, templates, templateData, tasks);
    const checkedLength = getCheckedLength(runs, templates, templateData, data);
    const quickCheckedLength = getQuickCheckedLength({ inspections: tasks, store: { ..._store } });
    return { length, checkedLength, quickCheckedLength };
  }, [tasks, templateData, runs?.length, templates?.length, data]);

  const openModal = ({ id, type, currentTask }) => {
    const { selectedTaskIndex, popupData } = openModalEvent({
      id,
      type,
      currentTask,
      tasksList
    });
    setOpenModalIndex(selectedTaskIndex);
    setSelectedTaskId(id);
    setPopupData((prev) => ({
      ...prev,
      ...popupData,
      Status: navigator.onLine ? 'sync' : 'offline'
    }));
  };

  const startBtnEvent = useCallback(() => {
    const { navigateURL } = startBtn({
      onParentClickEvent,
      tab,
      SelectedFilter,
      runs,
      templates,
      templateData,
      tasks: data,
      searchParams,
      location,
      selectedOrderIds
    });

    navigate(navigateURL, {
      state: {
        from: 'task-records'
      }
    });
    dispatch?.(cacheActions.setStartTaskCoordinates({ coordinates }));
    dispatch?.(tasksListsActions.setSelectedTask(false));
  }, [tasks, tab, runs, templates, templateData, data]);

  const quickBtnEvent = async () => {
    await moveInspectionToInProgress({
      inspections: tasks?.filter((x) => x?.type === 'Inspection' && x?.checked),
      inspectionFormsUserCache
    });
    ToasterUtil.showSuccess(`The inspection(s) are now in “Pending sync”`);
  };

  const onSelectionNewWorkOrderOrRequest = useCallback(() => {
    onParentClickEvent &&
      onParentClickEvent({ workOrderTab: tab, workOrderSortTab: SelectedFilter });
  }, [onParentClickEvent]);

  useImperativeHandle(ref, () => ({
    moveToTopOnClickTaskIcon() {
      tabsTasksRef?.current &&
        tabsTasksRef?.current?.scrollTo({
          behavior: 'smooth',
          top: 0
        });
      setFilters(tasksDefaultFilterValues);
      taskFilterRef?.current && taskFilterRef?.current?.clearFilterOnTaskFilterPage();
    }
  }));

  return (
    <div className="relative overflow-x-hidden" id="1122">
      {!asset && (
        <TaskRecordPageHeader
          title={title}
          length={length}
          filtersCount={filtersCount}
          checkedLength={checkedLength}
          setFilterActive={setFilterActive}
          isFilterActive={isFilterActive}
          runs={runs}
          templates={templates}
          templateData={templateData}
          tasks={tasks}
          tasksMap={tasksMap}
          setTemplateData={setTemplateData}
          data={data}
          setData={setData}
          notShowCount={!_.isEmpty(bookmarkFilter)}
          setSelectedOrderIds={setSelectedOrderIds}
        />
      )}

      <div
        className="flex assetDetailsWorkOrderAndInspectionsNotFixed"
        style={{
          height: asset ? 'auto' : `calc(100vh - ${width < 768 ? 84 : 94}px)`,
          overflowY: 'auto'
        }}
        id="workOrderAndInspectionsListings"
      >
        <div className={`cstmSideFilter ${!isFilterActive && 'noWidth'}`}>
          <TaskFilter
            noGap
            length={length || 0}
            onSearch={setSearched}
            filtersCount={filtersCount}
            setFiltersCount={setFiltersCount}
            onChangeFilters={setFilters}
            isFilterActive={isFilterActive}
            setFilterActive={setFilterActive}
            sitesSiteTypeDataMap={sitesSiteTypeDataMap}
            sitesSiteStatusDataMap={sitesSiteStatusDataMap}
            sitesAreaHierarchyTreeDataMap={sitesAreaHierarchyTreeDataMap}
            assignedUserGroups={assignedUserGroups}
            resetFilters={resetFilters}
            setResetFilters={setResetFilters}
            tasks={tasksLists}
            sitesWorkOrderPriorityDataMap={sitesWorkOrderPriorityDataMap}
            selectedSort={SelectedFilter}
            bookmarkFilter={bookmarkFilter}
            ref={taskFilterRef}
            selectTodoOrInProgressStatus={selectTodoOrInProgressStatusOnBookmarkSelection}
            asset={asset}
            filterType={filterGroup ? filterGroup : filterType}
          />
        </div>

        {!length && !loadingOnSearch ? (
          <ResetFilter filtersCount={filtersCount} setResetFilters={setResetFilters} />
        ) : (
          <>
            <TasksInspectionsTemplateAndRunsLists
              checkedLength={checkedLength}
              asset={asset}
              siteDetails={siteDetails}
              parentSiteDetails={parentSiteDetails}
              runs={runs}
              templates={templates}
              isFilterActive={isFilterActive}
              templateData={templateData}
              setTemplateData={setTemplateData}
              filtersCount={filtersCount}
              tagsDataRT={tagsDataRT}
              setFilterActive={setFilterActive}
              SelectedFilterRT={SelectedFilterRT}
              setSelectedFilterRT={setSelectedFilterRT}
              openModal={openModal}
              searched={searched}
              loading={loadingOnSearch}
              tagsData={tagsData}
              SelectedFilter={SelectedFilter}
              setSelectedFilter={setSelectedFilter}
              bookmarkFilter={bookmarkFilter}
              tab={tab}
              setTab={setTab}
              data={data}
              tasksMap={tasksMap}
              setData={setData}
              setSelectedOrderIds={setSelectedOrderIds}
              onSelectionNewWorkOrderOrRequest={onSelectionNewWorkOrderOrRequest}
              ref={{ tabsTasksRef }}
              workRequest={workRequest}
              hseEvent={hseEvent}
            />
            <StartAndQuickCompleteButton
              checkedLength={checkedLength}
              startBtnEvent={startBtnEvent}
              quickCheckedLength={quickCheckedLength}
              quickBtnEvent={quickBtnEvent}
            />
          </>
        )}
      </div>

      {openModalIndex >= 0 && (
        <TaskCardModals
          popupData={popupData}
          openModalIndex={openModalIndex}
          setOpenModalIndex={setOpenModalIndex}
          selectedTaskId={selectedTaskId}
          setPopupData={setPopupData}
          tasksList={tasksList}
          tasks={tasks}
        />
      )}
    </div>
  );
};

export default memo(forwardRef(TasksList), _.isEqual);
