/* eslint-disable no-unused-vars */

import localForage from 'localforage';

import { decodeToken } from '@utilities/authentication';

const LocalStorage = {
  getItem: async (key) => {
    let value = (await localForage.getItem(key)) || '';
    return value ? (value = JSON.parse(value)) : value;
  },

  setItem: async (key, value) => {
    try {
      await localForage.setItem(key, JSON.stringify(value));
    } catch (err) {
      throw new Error(err);
    }
  },

  removeItem: async (key) => {
    try {
      await localForage.removeItem(key);
    } catch (err) {
      throw new Error(err);
    }
  },

  getLocalStorageDataAndSetDataToLocalForage: async () => {
    const key = `userPendingSyncAndInProgressTasks-${decodeToken().userId}`;
    const getPreviousUserPendingAndInprogressTasks = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : '';

    if (getPreviousUserPendingAndInprogressTasks?.length > 0) {
      await localForage.setItem(key, JSON.stringify(getPreviousUserPendingAndInprogressTasks));
      localStorage.removeItem(key);
    }
  }
};

export default LocalStorage;
