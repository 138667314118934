/* eslint-disable no-unused-vars */

import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import FilterHeader from '@components/filters/filter-header';
import SearchField from '@components/search-field';
import PipelineBookmark from './pipeline-bookmark';
import PipelineFilterGroups from '@components/filters/pipeline-filter-group';
import TasksFilterGroups from '@components/filters/task-filter-group';

import useWindowDimensions from '@hooks/useWindowDimensions';
import {
  countFilters,
  getPipelinesDefaultFilters,
  mapValues
} from './pipeline-listing-inner/helper';
import { filterActions } from '@redux/slices/filters';
import { checkValueInObjectIsNotEmpty } from '@utilities/helper.util';
import { getAppDispatch } from '@utilities/dispatch.util';

import style from './style.module.scss';
import ResetAndViewButton from '@components/filters/reset-view-button';

const PipeLineFilter = ({
  length,
  filtersCount,
  setFilterActive,
  noGap,
  onSearch,
  resetFilters,
  isFilterActive,
  setFiltersCount,
  onChangeFilters,
  pipelinesAreaHierarchyTreeDataMap,
  pipelinesPipelinesStatusDataMap,
  pipelinesWorkOrderPriorityDataMap,
  pipelines,
  selectedSort,
  bookmarkFilter,
  removeClassOnAssetDetailsFilters,
  siteId,
  detailPage = false,
  setResetFilters,
  store
}) => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { width } = useWindowDimensions();
  const dispatch = getAppDispatch();
  const dueDateFilter = searchParams.get('dueDate');
  const downloadFilter = searchParams.get('download');
  const keyNameFilter = searchParams.get('keyName');
  const tabFilter = searchParams.get('tab');

  const pipelinesFilter = useSelector((state) => state?.filters?.pipelinesFilter) || {};

  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state?.picklists
  );

  const [isActive, setActive] = useState(isFilterActive);
  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState(getPipelinesDefaultFilters);
  const [initialY, setInitialY] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    setActive(isFilterActive);
  }, [isFilterActive]);

  const {
    assignableUsersTags,
    treeData,
    pipelineStatusTags,
    taskTypeTag,
    taskStatusTags,
    workOrderPriorityTags,
    checkRecurrancePriorityTags,
    checkTypeTags,
    inspectionRunTags,
    taskRecurringTemplateTags,
    checkTypeTagsMap
  } = React.useMemo(() => {
    return mapValues({
      pipelinesAreaHierarchyTreeDataMap,
      pipelinesPipelinesStatusDataMap,
      pipelinesWorkOrderPriorityDataMap,
      pipelines,
      store
    });
  }, [pipelinesAreaHierarchyTreeDataMap, pipelinesPipelinesStatusDataMap, pipelines]);

  const startTouch = (e) => setInitialY(e.touches[0].clientY);

  const moveTouch = (e) => {
    let currentY = e.touches[0].clientY;
    let diffY = initialY - currentY;
    if (diffY < 0) setFilterActive(false);
    setInitialY(e.touches[0].clientY);
  };

  useEffect(() => {
    if (!_.isEmpty(bookmarkFilter)) {
      const filtersInDecode = decodeURIComponent(bookmarkFilter);
      const filterInParse = JSON.parse(filtersInDecode);
      filterInParse?.filters && setFilters(filterInParse?.filters);
      filterInParse?.filters &&
        filterInParse?.filters['searched'] &&
        setInputValue(filterInParse?.filters['searched'] || '');
    } else if (checkValueInObjectIsNotEmpty(pipelinesFilter) && !detailPage) {
      setFilters(pipelinesFilter);
      onChangeFilters?.(pipelinesFilter);
      setInputValue(pipelinesFilter?.searched || '');
      onSearch(pipelinesFilter?.searched || '');
    } else {
      setInputValue('');
      setFilters(getPipelinesDefaultFilters);
    }
    if (resetFilters) resetBtnEvent({ filterActive: width < 768 ? false : true });
  }, [resetFilters, state?.resetFilters, bookmarkFilter]);

  useEffect(() => {
    if (downloadFilter) updateFilters('download', 'selected', downloadFilter);
  }, [downloadFilter]);

  useEffect(() => {
    if (dueDateFilter === 'today') {
      updateFilters('dueDate', 'value', moment());
      updateFilters('dueDate', 'selected', 'on');
    } else if (dueDateFilter === 'past') {
      updateFilters('dueDate', 'selected', 'past');
      updateFilters('dueDate', 'value', ['Unlimited']);
    }
  }, [dueDateFilter]);

  useEffect(() => {
    const filterCount = countFilters(filters, inputValue, store) || 0;
    setFiltersCount(filterCount);
    !detailPage && dispatch?.(filterActions.setPipelineFiltersCount(filterCount));
  }, [filters, inputValue, detailPage]);

  useEffect(() => {
    if (isActive) document.body.classList.add('filter-open');
    else document.body.classList.remove('filter-open');
  }, [isActive]);

  useEffect(() => {
    return () => {
      if (isActive) {
        setActive(false);
        document.body.classList.remove('filter-open');
      }
    };
  }, [isActive]);

  const updateFilters = (name, key, value) => {
    const filtersCopy = { ...filters };
    let newFilter = {
      ...filtersCopy,
      [name]: {
        ...Object.assign({}, filtersCopy[name]),
        [key]: value,
        ...(key === 'selected' && value === 'on' && { value: '' }),
        ...(key === 'selected' && (value === 'past' || value === 'next') && { value: [] }),
        ...(key === 'selected' && value === 'range' && { value: ['', ''] }),
        ...(key === 'selected' && value === 'last' && { value: ['last'] })
      }
    };
    setFilters({ ...newFilter });
    onChangeFilters?.({ ...newFilter });
    !detailPage &&
      dispatch?.(filterActions.setPipelinesFilters({ ...newFilter, searched: inputValue }));
  };

  const resetBtnEvent = ({ filterActive = true }) => {
    setResetFilters(false);
    navigate(pathname, { replace: true });
    setFilterActive(filterActive);
    setInputValue('');
    setFilters(getPipelinesDefaultFilters);
    onChangeFilters?.(getPipelinesDefaultFilters);
    onSearch('');
    !detailPage &&
      dispatch?.(
        filterActions.setPipelinesFilters({ ...getPipelinesDefaultFilters, searched: '' })
      );
  };

  const leftBtnEvent = () => setFilterActive(false);

  const handleUserInput = (e) => {
    const valueSearched = (e && e.target.value) || '';
    onSearch?.(valueSearched);
    !detailPage &&
      dispatch?.(filterActions.setPipelinesFilters({ ...filters, searched: valueSearched }));
    setInputValue(valueSearched);
  };

  const handleKeyDown = (e) => {
    if (width < 768 && e.key === 'Enter') setFilterActive(false);
  };

  const conditionClass = useMemo(() => {
    return width < 768 && removeClassOnAssetDetailsFilters && !isFilterActive
      ? style.displayNone
      : style.displayBlock;
  }, [isFilterActive]);

  return (
    <>
      <div
        className={`op-sidebarfilter ${isActive && 'open'} ${!noGap && 'gap'} ${conditionClass} ${
          isActive ? style.isActivePadding : style.isInActivePadding
        } `}
      >
        <div onTouchStart={startTouch} onTouchMove={moveTouch}>
          <FilterHeader
            title="Filters"
            filterCount={filtersCount || 0}
            resetBtnEvent={() => resetBtnEvent({})}
            leftBtnEvent={leftBtnEvent}
          />
        </div>
        <div className="op-filter-search" style={{ marginTop: '25px' }}>
          <SearchField
            placeholder="Search"
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleUserInput}
            isClearable
          />
        </div>

        <PipelineFilterGroups
          isActive={isActive}
          treeData={treeData}
          filters={filters}
          pipelinesAreaHierarchyTreeDataMap={pipelinesAreaHierarchyTreeDataMap}
          updateFilters={updateFilters}
          pipelineStatusTags={pipelineStatusTags}
          picklistsMap={picklistsMap}
        />

        {!removeClassOnAssetDetailsFilters && (
          <TasksFilterGroups
            taskTypeTag={taskTypeTag}
            filters={filters}
            updateFilters={updateFilters}
            taskStatusTags={taskStatusTags}
            assignableUsersTags={assignableUsersTags}
            picklistsMap={picklistsMap}
            workOrderPriorityTags={workOrderPriorityTags}
            isActive={isActive}
            pipelineListing
            checkRecurrancePriorityTags={checkRecurrancePriorityTags}
            checkTypeTags={checkTypeTags}
            inspectionRunTags={inspectionRunTags}
            taskRecurringTemplateTags={taskRecurringTemplateTags}
            checkTypeTagsMap={checkTypeTagsMap}
          />
        )}

        <ResetAndViewButton
          clickResetButton={() => resetBtnEvent({ filterActive: false })}
          length={length}
          setFilterActiveEvent={(value) => setFilterActive(value)}
          setIsModalVisibleEvent={(value) => setIsModalVisible(value)}
          width={width}
          detailPage={detailPage}
        />
      </div>

      {isModalVisible && (
        <PipelineBookmark
          open={isModalVisible}
          setOpen={(value) => {
            setIsModalVisible(value);
          }}
          selectedSort={selectedSort}
          filters={{ ...filters, searched: inputValue }}
          siteId={siteId}
          keyName={keyNameFilter}
          tab={tabFilter}
        />
      )}
    </>
  );
};

export default PipeLineFilter;
