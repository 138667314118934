/* eslint-disable no-unused-vars */
import _ from 'lodash';

import TasksSelector from './tasks/tasks.selector';
import SitesSelector from './sites.selector';
import EquipmentSelector from './equipment.selector';
import StructureSelector from './structure.selector';
import PipelineSelector from './pipeline.selector';
import InspectionSelector from './inspection/inspection.selector';
import HSEEventSelector from './hse/hse-event.selector';

import taskBookMarkCount from './bookmark/tasks-bookmark-count';
import {
  siteBookMarkCount,
  getSiteTodoAndInprogressTasks,
  getParentSiteTodoAndInprogressTasks
} from './bookmark/site-bookmark-count';
import {
  equipmentBookmarkCount,
  getEquipmentTodoAndInprogressTasks
} from './bookmark/equipment-bookmark-count';
import {
  structureBookmarkCount,
  getStructureTodoAndInprogressTasks
} from './bookmark/structure-bookmark-count';
import {
  pipelineBookmarkCount,
  getPipelineTodoAndInprogressTasks
} from './bookmark/pipeline-bookmark-count';
import { checkAllBookmarkIsSpecificType, getCompleteTasksList } from './bookmark/helper';
import CheckSelector from './checks/check.selector';

const GlanceBookmarkSelector = {
  getDefaultBookmark: async ({ store, checkAllBookmarksIsNotAtAGlance }) => {
    const { tasks, bookmarks, picklists, auth, settings, cache, jurisdictionNumbers } = store;
    const {
      tasksList: reduxTasks = [],
      responsibleUsersMap = {},
      responsibleUsersGroupsMap = {}
    } = tasks;
    const userId = auth.userId || '';

    const {
      sitesMap = {},
      equipmentsMap,
      equipments: cacheEquipments = [],
      sites: cacheSites = [],
      sitesEquipmentsMap
    } = cache;
    const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = picklists;
    const siteDisplayFields = settings?.settings?.['siteDisplayFields'] || '';
    const equipmentDisplayFields = settings.settings?.['equipmentDisplayFields'] || '';
    const structureDisplayFields = settings.settings?.['structureDisplayFields'] || '';
    const pipelineDisplayFields = settings.settings?.['pipelineDisplayFields'] || '';
    const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;

    const selectorName = checkAllBookmarkIsSpecificType({
      bookmarks: checkAllBookmarksIsNotAtAGlance
    });

    let [tasksList, inspections, sites, equipments, structures, pipelines, hseEvent, checks] =
      selectorName === 'site'
        ? await Promise.all([
            [],
            [],
            await SitesSelector.getSitesWithAllTasks({ store, bookmark: true }),
            [],
            [],
            [],
            [],
            []
          ])
        : selectorName === 'equipment'
        ? await Promise.all([
            [],
            [],
            [],
            await EquipmentSelector.getEquipmentsWithAllTasks({ store }),
            [],
            [],
            [],
            []
          ])
        : selectorName === 'pipeline'
        ? await Promise.all([
            [],
            [],
            [],
            [],
            [],
            await PipelineSelector.getPipelineWithWO({ store }),
            [],
            []
          ])
        : selectorName === 'structure'
        ? await Promise.all([
            [],
            [],
            [],
            [],
            await StructureSelector.getStructuresWithWO({ store }),
            [],
            [],
            []
          ])
        : selectorName === 'tasks'
        ? await Promise.all([
            await TasksSelector.getTasks({ store }),
            await InspectionSelector.getInspections({ store }),
            [],
            [],
            [],
            [],
            await HSEEventSelector.getHSEEvents({ store }),
            await CheckSelector.getAllChecks({ store })
          ])
        : await Promise.all([
            await TasksSelector.getTasks({ store }),
            await InspectionSelector.getInspections({ store }),
            await SitesSelector.getSitesWithAllTasks({ store }),
            await EquipmentSelector.getEquipmentsWithAllTasks({ store }),
            await StructureSelector.getStructuresWithWO({ store }),
            await PipelineSelector.getPipelineWithWO({ store }),
            await HSEEventSelector.getHSEEvents({ store }),
            await CheckSelector.getAllChecks({ store })
          ]);

    tasksList = getCompleteTasksList({ tasksList, inspections, hseEvent, checks, picklistsMap });

    return new Promise((resolve) => {
      const defaultBookmark = [];

      checkAllBookmarksIsNotAtAGlance?.map(async (bookmark) => {
        const filters = (bookmark?.filters && JSON.parse(bookmark?.filters)) || null;
        const sort = filters?.sort || {};
        const siteId = bookmark?.siteId || filters?.siteId || null;
        const parentSiteId = bookmark?.parentSiteId || filters?.parentSiteId || null;
        const equipmentId = bookmark?.equipmentId || filters?.equipmentId || null;
        const structureId = bookmark?.structureId || filters?.structureId || null;
        const pipelineId = bookmark?.pipelineId || filters?.pipelineId || null;
        const psvs = bookmark?.psvs || false;
        const pvs = bookmark?.pvs || false;
        const parentEquipmentId = bookmark?.parentEquipmentId || filters?.parentEquipmentId || null;

        //tasks or default bookmarks
        if (
          !_.isEmpty(filters?.filters) &&
          (bookmark?.type === 'default bookmark' ||
            bookmark?.type === 'default' ||
            bookmark?.type === 'Tasks')
        ) {
          const res = taskBookMarkCount({
            filters,
            tasksList,
            siteId,
            equipmentId,
            pipelineId,
            structureId,
            store
          });

          defaultBookmark.push({
            ...bookmark,
            ...res
          });
        }

        //sites bookmarks
        else if (!_.isEmpty(filters?.filters) && bookmark?.type === 'Sites') {
          const res = siteBookMarkCount({
            responsibleUsersMap,
            responsibleUsersGroupsMap,
            filters,
            sites,
            sort,
            userId,
            areaHierarchyTreeDataMap,
            siteDisplayFields,
            picklistsMap,
            equipmentId,
            pipelineId,
            structureId,
            parentSiteId
          });

          defaultBookmark.push({
            ...bookmark,
            ...res
          });
        } else if (!_.isEmpty(filters?.filters) && bookmark?.type === 'Equipment') {
          const res = equipmentBookmarkCount({
            responsibleUsersMap,
            responsibleUsersGroupsMap,
            filters,
            sort,
            userId,
            areaHierarchyTreeDataMap,
            siteDisplayFields,
            picklistsMap,
            structureId,
            sitesMap,
            equipments,
            jurisdictionNumbersMap,
            equipmentDisplayFields,
            cacheSites,
            siteId,
            parentEquipmentId,
            store
          });
          defaultBookmark.push({
            ...bookmark,
            ...res
          });
        } else if (!_.isEmpty(filters?.filters) && bookmark?.type === 'Structure') {
          const res = structureBookmarkCount({
            responsibleUsersMap,
            responsibleUsersGroupsMap,
            filters,
            sort,
            userId,
            areaHierarchyTreeDataMap,
            siteDisplayFields,
            picklistsMap,
            sitesMap,
            jurisdictionNumbersMap,
            cacheSites,
            structures,
            structureDisplayFields,
            store
          });

          defaultBookmark.push({
            ...bookmark,
            ...res
          });
        } else if (!_.isEmpty(filters?.filters) && bookmark?.type === 'Pipeline') {
          const res = pipelineBookmarkCount({
            responsibleUsersMap,
            responsibleUsersGroupsMap,
            filters,
            sort,
            userId,
            areaHierarchyTreeDataMap,
            siteDisplayFields,
            picklistsMap,
            sitesMap,
            jurisdictionNumbersMap,
            cacheSites,
            pipelines,
            pipelineDisplayFields,
            siteId,
            store
          });

          defaultBookmark.push({
            ...bookmark,
            ...res
          });
        }
        // site details
        else if (bookmark?.type === 'Site Details') {
          const count = getSiteTodoAndInprogressTasks({
            siteId,
            tasksList
          });
          defaultBookmark.push({
            ...bookmark,
            count
          });
        } else if (bookmark?.type === 'Parent Site Details') {
          const count = await getParentSiteTodoAndInprogressTasks({
            siteId,
            sitesMap,
            cacheSites,
            tasksList,
            sitesEquipmentsMap,
            equipmentsMap,
            cacheEquipments
          });
          defaultBookmark.push({
            ...bookmark,
            count
          });
        } else if (bookmark?.type === 'Equipment Details') {
          const count = getEquipmentTodoAndInprogressTasks({
            equipmentId,
            tasksList,
            sitesMap,
            equipmentsMap,
            cacheEquipments,
            cacheSites
          });
          defaultBookmark.push({
            ...bookmark,
            count
          });
        } else if (bookmark?.type === 'Structure Details') {
          const count = getStructureTodoAndInprogressTasks({
            structureId,
            tasksList,
            cacheEquipments
          });
          defaultBookmark.push({
            ...bookmark,
            count
          });
        } else if (bookmark?.type === 'Pipeline Details') {
          const count = getPipelineTodoAndInprogressTasks({
            pipelineId,
            tasksList
          });
          defaultBookmark.push({
            ...bookmark,
            count
          });
        }
      });
      const sortedData = defaultBookmark?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder);
      resolve(sortedData);
    });
  },
  getAtAGlanceBookmarks: ({ store }) => {
    const { bookmarks } = store;
    return (
      (bookmarks?.bookmarks &&
        bookmarks?.bookmarks
          .filter((bookmark) => !bookmark?.isDelete)
          ?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder)) ||
      []
    );
  },
  getFilterBookmarks: ({ store }) => {
    const { bookmarks } = store;
    const bookmarkFilterTypes = [
      'Sites',
      'Equipment',
      'Tasks',
      'Structure',
      'Pipeline',
      'default bookmark'
    ];
    return (
      (
        bookmarks?.bookmarks &&
        bookmarks?.bookmarks
          ?.filter(
            (bookmark) =>
              bookmarkFilterTypes.includes(bookmark?.type) &&
              // !bookmark?.atAGlance &&
              !bookmark?.isDelete
          )
          ?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder)
      )?.sort((a, b) => {
        return a?.name && b.name
          ? a?.name?.localeCompare(b?.name)
          : a?.title?.localeCompare(b?.title);
      }) || []
    );
  },
  getSitesBookmarks: ({ store }) => {
    const { bookmarks } = store;
    return (
      (
        bookmarks?.bookmarks &&
        bookmarks?.bookmarks
          ?.filter(
            (bookmark) =>
              (bookmark?.type === 'Site Details' || bookmark?.type === 'Parent Site Details') &&
              // !bookmark?.atAGlance &&
              !bookmark?.isDelete
          )
          ?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder)
      )?.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      }) || []
    );
  },
  getStructureBookmarks: ({ store }) => {
    const { bookmarks } = store;
    return (
      (
        bookmarks?.bookmarks &&
        bookmarks?.bookmarks
          ?.filter(
            (bookmark) =>
              bookmark?.type === 'Structure Details' &&
              // !bookmark?.atAGlance &&
              !bookmark?.isDelete
          )
          ?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder)
      )?.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      }) || []
    );
  },
  getPipelineBookmarks: ({ store }) => {
    const { bookmarks } = store;
    return (
      (
        bookmarks?.bookmarks &&
        bookmarks?.bookmarks
          ?.filter(
            (bookmark) =>
              bookmark?.type === 'Pipeline Details' &&
              // !bookmark?.atAGlance &&
              !bookmark?.isDelete
          )
          ?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder)
      )?.sort((a, b) => {
        return a?.name && b.name
          ? a?.name?.localeCompare(b?.name)
          : a?.title?.localeCompare(b?.title);
      }) || []
    );
  },
  getEquipmentsBookmarks: ({ store }) => {
    const { bookmarks } = store;
    return (
      (
        bookmarks?.bookmarks &&
        bookmarks?.bookmarks
          ?.filter(
            (bookmark) => bookmark?.type === 'Equipment Details' && !bookmark?.isDelete
            // && !bookmark?.atAGlance
          )
          ?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder)
      )?.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
      }) || []
    );
  },

  getOthersBookmarks: ({ store }) => {
    const { bookmarks } = store;
    return (
      (bookmarks?.bookmarks &&
        bookmarks?.bookmarks
          ?.filter((bookmark) => bookmark?.type === 'Other' && !bookmark?.isDelete)
          ?.sort((a, b) => a?.atAGlanceOrder - b?.atAGlanceOrder)) ||
      []
    );
  }
};

export default GlanceBookmarkSelector;
