/* eslint-disable no-unused-vars */
import moment from 'moment';
import { store } from 'redux/store';

import http from './http.service';
import Promisable from './promisable.service';
import { structureActions } from '@redux/slices/structure';
import { getAppDispatch } from '@utilities/dispatch.util';
import { checkOldAndNewArray } from './check-old-and-new-array';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';

const StructureService = {
  getStructure: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(structureActions.setLoading(true));
    http.setJWT();
    const res = await Promise.all([Promisable.asPromise(http.get(`/Structures`))]);
    const [[structure]] = res;

    if (structure.data && structure.data.items.length > 0)
      dispatch?.(structureActions.setStructure(structure.data.items));

    const status = res.filter(([success]) => success).length === res.length;
    return [status, !status];
  },

  getStructureMobile: async ({ initialLogin, lastSuccessFullRefreshTime, isSettingUpdated }) => {
    const { structure, cache } = store.getState();
    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      const { structureMobileMap = {}, structureMobile = [] } = structure;
      const changedAfter = lastSuccessFullRefreshTime;

      const dispatch = getAppDispatch();
      dispatch?.(structureActions.setLoading(true));

      http.setJWT();
      const [success, error] = await Promisable.asPromise(
        http.get(
          `/Structures/Mobile?page=0&changedAfter=${
            !initialLogin ? (changedAfter ? changedAfter : '') : ''
          }`
        )
      );
      if (
        !initialLogin &&
        success?.data?.items?.length &&
        structureMobile?.length &&
        !isSettingUpdated
      ) {
        const combineNewAndOldArray = checkOldAndNewArray({
          oldArray: structureMobile,
          oldArrayMap: structureMobileMap,
          newArray: success?.data?.items,
          idName: 'structureId'
        });
        dispatch?.(structureActions.setStructureMobile(combineNewAndOldArray));
      } else {
        if (success?.data?.items?.length) {
          dispatch?.(structureActions.setStructureMobile(success?.data?.items));
        }
      }
      dispatch?.(globalProgressBarAction.setLoadStatus(11));
      return [success, error];
    } else throw new Error('Api failed to update');
  }
};

export default StructureService;
