import React from 'react';
import style from '../workorder.module.scss';

const ReadableArray = ({ field }) => {
  return (
    <div className={style.body}>
      <h6>{field?.label}</h6>
      {field?.value?.map((user, index) => (
        <p key={index}>{user}</p>
      ))}
    </div>
  );
};

export default ReadableArray;
