/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export const transformFormWrapperDataToNewCheck = ({ checkData, checkId }) => {
  const uniqueId = !_.isEmpty(checkId) ? checkId : uuidv4();

  return {
    newCheck: true,
    status: checkData?.status,
    type: 'Check',
    id: uniqueId,
    inspectionFormId: checkData?.inspectionFormId,
    inspectionTypeId: checkData?.inspectionTypeId,
    siteId: checkData?.siteId || null,
    equipmentId: checkData?.equipmentId || null,
    pipelineId: checkData?.pipelineId || null,
    structureId: checkData?.structureId || null,
    formData: checkData?.formData || null,

    startLatitude: checkData?.startLatitude,
    startLongitude: checkData?.startLongitude,
    completionLatitude: checkData?.completionLatitude,
    completionLongitude: checkData?.completionLongitude,

    description: checkData?.description || '',
    identifier: checkData?.identifier || '',
    location: checkData?.location || ''
  };
};

export const prepareCheckForBackend = (data, inspectionFormsUserCache, formData) => {
  const currentFrom =
    inspectionFormsUserCache?.find((x) => x?.inspectionFormId === data?.inspectionFormId) || {};
  const json = transformJSON({ currentFrom, formData });

  let siteId = null,
    equipmentId = null,
    pipelineId = null,
    structureId = null;

  if (currentFrom?.appliesTo === 'Site' && data?.siteId) siteId = data?.siteId;
  else if (currentFrom?.appliesTo === 'Equipment' && data?.equipmentId)
    equipmentId = data?.equipmentId;
  else if (currentFrom?.appliesTo === 'Structure' && data?.structureId)
    structureId = data?.structureId;
  else if (currentFrom?.appliesTo === 'Pipeline' && data?.pipelineId) pipelineId = data?.pipelineId;
  return {
    assetCheckId: data?.id, // unique Id generated from mobile,
    ...(data?._id && { checkScheduleId: data?._id }),
    siteId,
    structureId,
    equipmentId,
    pipelineId,
    inspectionTypeId: data?.inspectionTypeId || null,
    json: null,
    formdata: json,
    // checkedBy: '',
    checkedDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
    // previousNextDue: '',
    // previousNextScheduled: '',
    identifier: data?.identifier || '',
    description: data?.description || '',
    location: data?.location || '',
    comment: data?.comment || '',
    inspectionFormId: data?.inspectionFormId || null,
    // ondemandId: '',
    startLatitude: `${data?.startLatitude}`,
    startLongitude: `${data?.startLongitude}`,
    completionLatitude: `${data?.completionLatitude}`,
    completionLongitude: `${data?.completionLongitude}`
  };
};

const getDefaultValueWhenInspectionResultSelected = ({ form }) => {
  let defaultValue = null;
  for (const section of form.formSections) {
    for (const field of section.formFields) {
      if (field.questionTypeText === 'InspectionResult') {
        defaultValue = field.defaultValue;
        break;
      }
    }
    if (defaultValue) {
      break;
    }
  }
  return defaultValue;
};

export const transformJSON = ({ currentFrom, formData }) => {
  const formDataAnswers = [];
  currentFrom?.formSections?.map((section) => {
    section?.formFields?.map((field) => {
      let removeSpecialCharactersForName = removeSpecialCharacters(
        field?.label?.toString()?.toLowerCase()
      );
      removeSpecialCharactersForName = `${removeSpecialCharactersForName}-${field?.formFieldId}`;
      const answer =
        field?.questionTypeText === 'PhotoQuestion'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.notes
          : field?.questionTypeText === 'PassFailNA' ||
            field?.questionTypeText === 'PassFail' ||
            field?.questionTypeText === 'Scale'
          ? field?.questionTypeText === 'Scale'
            ? parseInt(formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.answer) === 0
              ? 0
              : formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.answer
            : formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.answer || null
          : field?.questionTypeText === 'YNComment'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.value
          : field?.questionTypeText === 'Readings' || field?.questionTypeText === 'Readings_PL'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.textBoxValue
          : field?.questionTypeText === 'Photos'
          ? null
          : field?.questionTypeText === 'InspectionResult'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.inspectionResultAnswer
          : field?.questionTypeText === 'Signature'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.signaturePhoto
          : field?.questionTypeText === 'Time'
          ? moment(formData[removeSpecialCharactersForName]?.[field?.formFieldId]).format('hh:mm')
          : field?.questionTypeText === 'CheckResult'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.checkResultAnswer
          : formData[removeSpecialCharactersForName]?.[field?.formFieldId];
      const assessment =
        field?.questionTypeText === 'Scale'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.assessment
          : null;
      const comment =
        field?.questionTypeText === 'YNComment'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.comment
          : null;

      const notes = ['PhotoQuestion', 'PassFailNA', 'PassFail', 'Scale']?.includes(
        field?.questionTypeText
      )
        ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.notes
        : field?.questionTypeText === 'YNComment'
        ? formData[removeSpecialCharactersForName]?.[field?.form]?.comment
        : null;
      const unit =
        field?.questionTypeText === 'Readings' || field?.questionTypeText === 'Readings_PL'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.textBoxUnitValue
          : null;
      const photos =
        field?.questionTypeText === 'Photos' ||
        ['PhotoQuestion', 'PassFailNA', 'PassFail', 'Scale']?.includes(field?.questionTypeText)
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.photos?.map((x) => {
              return {
                photoId: x?.id,
                caption: x?.caption
              };
            })
          : null;
      const documentId =
        field?.questionTypeText === 'Photos'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.documents?.at(0)?.id
          : null;
      const documentObj = documentId
        ? {
            fieldKey: field?.label,
            fieldId: field?.formFieldId,
            documentId
          }
        : null;
      const multipleDropdownAnswer =
        field?.questionTypeText === 'Dropdown_PL_Multiple' &&
        formData[removeSpecialCharactersForName]?.[field?.formFieldId]
          ? Object.values(formData[removeSpecialCharactersForName]?.[field?.formFieldId])?.map(
              (x) => x?.value
            )
          : null;

      documentObj && formDataAnswers.push(documentObj);
      formDataAnswers.push({
        fieldKey: field?.label,
        fieldId: field?.formFieldId,
        ...(answer &&
          !multipleDropdownAnswer && {
            answer: answer?.toString()
          }),
        ...(multipleDropdownAnswer && {
          listOfMultiValueIds: multipleDropdownAnswer
        }),
        ...(field?.questionTypeText === 'Scale' &&
          assessment && {
            assessment
          }),
        ...(field?.questionTypeText === 'Scale' &&
          answer === 0 && {
            answer: answer?.toString()
          }),
        ...(notes && {
          notes
        }),
        ...(unit && {
          unit
        }),
        ...(photos && {
          photos
        }),
        ...(comment && {
          comment
        })
      });
    });
  });
  return formDataAnswers;
};

const removeSpecialCharacters = (str) => {
  const pattern = /[^a-zA-Z0-9 ]/g;
  const result = str.replace(pattern, '');
  return result;
};
