/* eslint-disable no-unused-vars */
import React, { useState, useEffect, memo } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import TaskCard from '@components/task-card';
import Icon from '@components/icon-component';
import Button from '@components/button/button.component';
import DeleteModal from '@components/modal/variants/delete-modal';

import WorkOrderService from '@services/workorder.service';
import { getAppDispatch } from '@utilities/dispatch.util';
import LocalStorage from '@utilities/local-storage.util';
import { decodeToken } from '@utilities/authentication';
import { tasksListsActions } from '@redux/slices/tasks';
import { useToastContext } from 'context/toaster.context';
import InspectionService from '@services/inspection.service';
import { WorkTypes } from '../helper';

import style from './sidebar.module.scss';
import HSEEventService from '@services/hse-event.service';
import {
  deleteNewTasksAssetFromIndexDB,
  getPhotosAndDocumentsDataFromSelectedInspection
} from '@services/assets.service';

const Sidebar = (
  {
    current,
    setMoreMenuToggle,
    selectedTasksList,
    nextTask,
    previousTask,
    removeFromTasks,
    saveAndClose,
    trigger,
    workType,
    closeDiv,
    selectorData,
    orderIdsLength,
    updateAssignedWorkOrder,
    resetAssignedWorkOrder,
    updateNewWorkRequest,
    updateNewWorkOrderToDoOrSynced,
    updateAssignedInspection,
    resetAssignedWorkInspection,
    updateNewInspection,
    updateNewHSEEvent,
    watch
  },
  ref
) => {
  const { dirtyRef } = ref;
  const dispatch = getAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { ToasterUtil } = useToastContext();

  const [initialX, setInitialX] = useState(null);
  const [initialY, setInitialY] = useState(null);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteTask, setDeleteTask] = useState(false);
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');
  const orderIds = searchParams.get('orderIds');

  useEffect(() => {
    (async () => {
      try {
        //TODO improve
        setIsLoading(true);
        let taskCopy = [];
        if (type && ['Check', 'Inspection', 'Work Order'].includes(type))
          taskCopy = [...selectedTasksList.filter((task) => task.type === type)];
        taskCopy = [...selectedTasksList];
        setTasks([...taskCopy]);
      } catch (e) {
        console.log({ e });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [selectedTasksList, type]);

  const isReset =
    type === 'work-orders' ||
    type === 'inspection' ||
    type === 'inspection,work-orders' ||
    type === 'work-orders,inspection'
      ? true
      : false;

  const startTouch = (e) => {
    setInitialX(e.touches[0].clientX);
    setInitialY(e.touches[0].clientY);
  };

  const moveTouch = (e) => {
    let { clientX, clientY } = e.touches[0];
    setInitialX(clientX);
    setInitialY(clientY);
    if (clientX - initialX >= 0 || initialY - clientY >= 0) closeDiv();
  };

  const removeAssetsFromIndexDB = async () => {
    let { photos, documents } = getPhotosAndDocumentsDataFromSelectedInspection(watch(), true);
    photos = [...photos, ...(watch('photo') || [])];
    documents = [...documents, ...(watch('document') || [])];
    await deleteNewTasksAssetFromIndexDB({ photos, documents, deleteAll: true });
  };

  const deleteAction = async () => {
    setDeleteButtonLoading(true);
    const currentSelectedTaskStatus = selectorData?.status;
    await removeAssetsFromIndexDB();
    setDeleteButtonLoading(false);

    if (isReset) {
      // RESET flow

      if (selectedTasksList.length > 1) {
        if (currentSelectedTaskStatus === 'To do') {
          // remove from series
          removeFromTasks();
        } else if (['In progress', 'Pending sync'].includes(currentSelectedTaskStatus)) {
          dispatch?.(
            tasksListsActions.updateWorkOrder({
              ...selectedTasksList[current],
              // worklog part
              photos: [],
              documents: [],
              notes: null,
              workStarted: null,
              workCompleted: null,
              counterReadings: [],
              regularHours: null,
              overTimeHours: null,
              actualLabour: null,
              actualParts: null,
              parts: [],
              status: 'To do'
            })
          );
          removeFromTasks();
          ToasterUtil.showSuccess(`Task has been reset to its original state`);
        }
      } else {
        // Only One items is selected

        if (currentSelectedTaskStatus === 'To do') {
          let back = -1;
          if (location.state?.from === 'back') {
            back = -orderIdsLength;
          }
          navigate(back, {
            state: {
              from: 'back'
            }
          });
        } else if (['In progress', 'Pending sync'].includes(currentSelectedTaskStatus)) {
          // Move task to "To do"
          if (workType === WorkTypes.WorkOrders) {
            await resetAssignedWorkOrder();
          }
          if (workType === WorkTypes.Inspection) {
            await resetAssignedWorkInspection();
          }
        }
      }
    } else {
      if (orderIds && orderIds.split(',').length == 1) {
        removeTaskFromListingAndStorage({ orderIds });
      }
    }
    setDeleteTask(false);
  };

  const moveToInProgress = async () => {
    if (workType === WorkTypes.NewInspection || selectorData?.type === 'Inspection') {
      if (!isReset) {
        if (workType === WorkTypes.NewInspection) {
          await updateNewInspection({ status: 'In progress' });
        }
      } else if (selectorData?.type === 'Inspection') {
        await updateAssignedInspection({ status: 'In progress' });
      }
    } else if (workType === WorkTypes.NewHSE) {
      await updateNewHSEEvent({ status: 'In progress' });
    } else {
      const result = await trigger();
      if (!result) {
        setMoreMenuToggle(false);
        ToasterUtil.showError('error');
      } else {
        if (!isReset) {
          if (workType === WorkTypes.NewWorkOrder)
            await updateNewWorkOrderToDoOrSynced({ status: 'In progress' });

          if (workType === WorkTypes.NewWorkRequest)
            await updateNewWorkRequest({ status: 'In progress' });
        } else if (selectorData?.type === 'Work Order')
          await updateAssignedWorkOrder({ status: 'In progress' });
      }
    }
  };

  const removeTaskFromListingAndStorage = async ({ orderIds }) => {
    const getPreviousUserPendingAndInprogressTasks =
      (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) ||
      [];
    let find = getPreviousUserPendingAndInprogressTasks?.find(
      (x) => x?.userId === decodeToken().userId
    );

    if (workType === WorkTypes.NewWorkRequest || workType === WorkTypes.NewWorkOrder) {
      if (!_.isEmpty(find)) {
        find = {
          ...find,
          tasks: find?.tasks?.filter((task) => task?.woId !== orderIds)
        };
      }
      await LocalStorage.setItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`, [
        {
          ...find
        }
      ]);

      WorkOrderService.deleteWorkOrderAndRequest(orderIds);
    }
    if (workType === WorkTypes.NewInspection) {
      if (!_.isEmpty(find)) {
        find = {
          ...find,
          inspections: find?.inspections?.filter((inspection) => inspection?.id !== orderIds)
        };
      }
      await LocalStorage.setItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`, [
        {
          ...find
        }
      ]);

      InspectionService.deleteNewInspection(orderIds);
    }
    if (workType === WorkTypes.NewHSE) {
      if (!_.isEmpty(find)) {
        find = {
          ...find,
          hseEvents: find?.hseEvents?.filter((hse) => hse?.id !== orderIds)
        };
      }
      await LocalStorage.setItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`, [
        {
          ...find
        }
      ]);

      HSEEventService.deleteNewHSEEvent(orderIds);
    }

    ToasterUtil.showSuccess(`Task was deleted successfully`);
    dirtyRef.current = {
      isDirty: false,
      dirtyFields: {}
    };
    navigate(-1);
  };

  return (
    <>
      {!isLoading && (
        <div className={style.main}>
          <div
            onTouchMove={moveTouch}
            onTouchStart={startTouch}
            style={{
              width: '20px',
              height: '100%',
              marginLeft: '-15px',
              marginRight: '-25px'
            }}
          ></div>
          <div
            onTouchMove={moveTouch}
            onTouchStart={startTouch}
            style={{
              width: '15px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <div className={style.positionMain}></div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
              width: '100%'
            }}
          >
            <div>
              <div className={style.taskDiv}>
                <p className={style.taskTitle}>Task menu </p>
                <Icon name={'xdefault'} onClick={closeDiv} />
              </div>

              <div>
                <TaskCard
                  {...selectorData}
                  cardCheckbox={false}
                  noSelection={false}
                  chip={false}
                  formWrapper
                />
              </div>
              {/* </div> */}

              {tasks.length > 1 && (
                <div className={style.pagination}>
                  <div className={style.prev} onClick={previousTask}>
                    <Icon name={'prev'} />
                  </div>
                  <div className={style.page}>
                    {' '}
                    {current + 1}/{tasks.length || 0}
                  </div>
                  <div className={style.next} onClick={nextTask}>
                    <Icon name={'next'} />
                  </div>
                </div>
              )}
            </div>
            <div>
              {tasks[current]?.status === 'Pending sync' && (
                <Button
                  text={'move to ‘in progress’'}
                  variant="border"
                  btnClass={style.btn}
                  onClick={moveToInProgress}
                />
              )}
              <div className={style.action}>
                <Icon name={'delete'} onClick={() => setDeleteTask(!deleteTask)} />
                <Button
                  text={' Save + Close'}
                  onClick={() => {
                    setMoreMenuToggle(false);
                    saveAndClose();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {deleteTask && (
        <DeleteModal
          open={deleteTask}
          setOpen={deleteButtonLoading ? () => {} : setDeleteTask}
          discordChangesAndResetWorkOrder={isReset && selectorData?.type !== 'Inspection'}
          discordChangesAndResetInspection={isReset && selectorData?.type === 'Inspection'}
          discordChangesAndResetHseEvent={selectorData?.type === 'HSE'}
          confirmDeletion={!isReset}
          recordType={
            selectorData?.type === 'Inspection'
              ? 'inspection'
              : selectorData?.type === 'HSE'
              ? 'HSE event'
              : 'work order'
          }
          handleDelete={deleteAction}
          disabledDeleteButton={deleteButtonLoading}
        />
      )}
    </>
  );
};

export default memo(React.forwardRef(Sidebar));
