/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useState, useRef } from 'react';

import DataSnippets from '@components/data-snippet';
import Icon from '@components/icon-component';
import InfoPopup from '@components/info-popup/info-popup.component';
import TaskTally from '@components/task-tally';
import SiteInnerCard from './site-card-pipeline';
import OpenLink from '@components/task-card-new/card-asset/open-link';

import { formWrapperActions } from '@redux/slices/form-wrapper';
import { getAppDispatch } from '@utilities/dispatch.util';
import { useOpenLink } from '@hooks/useOpenLink';

import style from './site-card.module.scss';

const PipeLineCard = (props) => {
  const [showSites, setShowSites] = useState(false);
  const [desktopLink, setDesktopLink] = useState(null);
  const [openLink, setOpenLink] = useOpenLink({
    taskId: props?.pipelineId || '',
    assetId: props?.pipelineId || ''
  });
  const detailsMenuRef = useRef(null);
  const dispatch = getAppDispatch?.();

  const {
    onClickLink,
    to,
    title,
    fromLocation,
    toLocation,
    dataPoints,
    highlight,
    highlightName,
    normal,
    allTasks,
    grey,
    allDoneTasks,
    isOverDue,
    status,
    sites = [],
    noSite = false,
    moreInfo,
    appliesTo,
    arrayText,
    open,
    setOpen,
    showChevronIcon,
    noIcon,
    pipelineDetails,
    showWOCount,
    pipelineListing,
    link,
    addNewWizard,
    pipelineId
  } = props;

  let [highlightNames, highlightType] = !_.isEmpty(highlight) ? highlight.split(' ') : [];

  highlightNames = highlightName ? highlight : highlightNames;

  if (!highlightType) highlightType = highlightNames;
  const onClickOpliiDesktopLinkEvent = (desktopLink) => {
    setDesktopLink({ linkValue: desktopLink });
    setOpenLink((prev) => !prev);
    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId: pipelineId,
        assetId: pipelineId
      })
    );
  };

  const pipelineInfo = () => {
    return (
      <div className={style.cardInner}>
        <div className={style.cardContent}>
          <div
            style={{
              position: 'relative'
            }}
          >
            <DataSnippets
              link={link}
              title={title || ''}
              pipelineTitle
              dataPoints={dataPoints}
              iconName={noIcon || pipelineDetails || appliesTo ? '' : 'pipeline'}
              from={fromLocation}
              to={toLocation}
              highlightNames={highlightNames}
              className={`${style.marginClass} ${pipelineListing && style.paddingOnListingPage} ${
                style['mb-4']
              }`}
              onClickOpliiDesktopLinkEvent={onClickOpliiDesktopLinkEvent}
            />
            <OpenLink
              openLink={openLink}
              taskId={pipelineId}
              assetId={pipelineId}
              desktopLink={desktopLink}
              setOpenLink={setOpenLink}
              setDesktopLink={setDesktopLink}
              selectedLinkTypeIcon={'pipeline'}
              selectedLinkTypeName={'pipeline'}
            />
          </div>

          {!grey && (
            <div
              className={`${style.chipsDiv} ${
                !(!normal && !_.isEmpty(sites)) && !appliesTo && style['mb-0']
              } `}
              style={{
                padding: pipelineDetails || noIcon || appliesTo ? '0px' : '0px 20px 0px 60px'
              }}
            >
              {(showWOCount || !normal) && allTasks?.length > 0 && (
                <TaskTally
                  status={
                    allDoneTasks.length === 0
                      ? 'overdue'
                      : allDoneTasks.length > 0 && allTasks.length > allDoneTasks.length
                      ? 'inProgress'
                      : allDoneTasks.length === allTasks.length
                      ? 'done'
                      : ''
                  }
                  statusText={`${allDoneTasks.length}/${allTasks.length}`}
                  dot={!!isOverDue && 'true'}
                />
              )}
              {status && (
                <>
                  <TaskTally statusText={status || ''} />
                </>
              )}
            </div>
          )}

          {!noSite && !_.isEmpty(sites) && (
            <div
              style={{
                marginTop: '24px'
              }}
            >
              {sites?.map((x, index) => {
                return (
                  <SiteInnerCard
                    link={!addNewWizard ? `/#/site-details/${x?.siteId}` : null}
                    key={index}
                    showValue={!showSites ? index === 0 : true}
                    setShowSites={setShowSites}
                    showSites={showSites}
                    siteIds={sites}
                    divider={index === sites?.length - 1 ? false : true}
                    appliesTo={appliesTo}
                    sites={{
                      ...x,

                      link: !addNewWizard ? `/#/site-details/${x?.siteId}` : null,
                      index,
                      highlight,
                      highlightName,
                      hideLocation: pipelineDetails || appliesTo,
                      pipelineListing
                    }}
                    taskId={pipelineId}
                    assetId={x?.siteId}
                  />
                );
              })}
            </div>
          )}
        </div>

        {showChevronIcon && !appliesTo ? (
          <div>
            <div>
              <Icon name={'chevron-right'} width="10px" />
            </div>
          </div>
        ) : !appliesTo ? (
          <div>
            {!moreInfo && (
              <div className="rightDiv" ref={detailsMenuRef}>
                <Icon
                  name={'Listing detail menu'}
                  className={style.siteIcon}
                  onClick={() => {
                    setOpen(true);
                  }}
                />
                {open && (
                  <div style={{ position: 'absolute', top: '84px', right: '0px' }}>
                    <InfoPopup
                      open={open}
                      setOpen={setOpen}
                      arrayText={arrayText}
                      top={detailsMenuRef?.current?.offsetTop - 130}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  };

  return pipelineDetails || appliesTo ? (
    <div
      className={`${style.cardWrapperNoHover} ${props?.className}`}
      style={{
        padding: pipelineDetails || appliesTo ? '16px' : '',
        border: '0.5px solid #cbd2d9'
      }}
    >
      {pipelineInfo()}
    </div>
  ) : (
    <div className={`${style.cardWrapper}`} style={{ cursor: 'pointer' }} onClick={onClickLink}>
      {pipelineInfo()}
    </div>
  );
};

export default PipeLineCard;
