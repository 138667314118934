import React, { memo, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SettingsValueCard from './settings-value-card';
import SettingsInput from './settings-input';
import GeneralTag from '@components/tags/general-tag';
import RadioGroup from '@components/radio-group';
import Tree from '@components/tree-component/tree';
import { getChildrenKeys } from '@components/tree-component';
import Icon from '@components/icon-component';

import { PERMISSIONS } from 'permissions/constants';
import { decodeToken } from '@utilities/authentication';
import PermissionRestrict from 'hoc/permission-restrict';
import { settingsActions } from '@redux/slices/settings';
import { whenOptions } from '../helper';

const DataCachingSettings = () => {
  const dispatch = useDispatch();
  const permissions = decodeToken()?.permissions || {};
  const { settings = {} } = useSelector((state) => state.settings);
  const disabled = !permissions[PERMISSIONS.MOBILE_CHANGE_CACHED_AREAS];
  const { areaHierarchyTreeData = [], areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state.picklists
  );

  const assets = settings['mobileAreaIds'] || [];
  const assetsMap = assets.reduce((acc, curr) => {
    acc[curr] = true;
    return acc;
  }, {});

  return (
    <div className="settings-card">
      <div className="settings--values">
        <SettingsValueCard
          title={'Keep synced tasks'}
          description={'Keep a record of synced (completed) tasks for:'}
        >
          <SettingsInput label={'day(s)'} name={'keepSyncedTasksThreshold'} />
        </SettingsValueCard>
        <SettingsValueCard
          title={'Area-based asset caching'}
          description={
            'The areas selected determine what assets are downloaded (cached). Select only the areas in which you work. The fewer the areas, the better Oplii Mobile will perform.'
          }
          secondDescription={
            ' You will also receive any asset data related to tasks assigned to you.'
          }
        >
          {!disabled ? (
            <>
              {assets.filter((el) => areaHierarchyTreeDataMap[el]).length > 0 && (
                <div className="op-selected">
                  {assets.map((item) => (
                    <Fragment key={item}>
                      {areaHierarchyTreeDataMap[item] &&
                        (!areaHierarchyTreeDataMap[item].parentKey ||
                          !assetsMap[areaHierarchyTreeDataMap[item].parentKey]) && (
                          <>
                            <GeneralTag
                              closable
                              closeIcon={<Icon name={'close-icon'} />}
                              className="op-filter-tag other-caption mb-2"
                              onClose={() => {
                                const childrenKeys = getChildrenKeys(
                                  areaHierarchyTreeDataMap[item].children
                                );
                                dispatch?.(
                                  settingsActions.updateSettingsByName({
                                    name: 'mobileAreaIds',
                                    value: assets.filter((i) => !(i === item || childrenKeys[i]))
                                  })
                                );
                              }}
                            >
                              {areaHierarchyTreeDataMap[item].title}
                            </GeneralTag>
                          </>
                        )}
                    </Fragment>
                  ))}
                </div>
              )}
              <Tree
                treeData={areaHierarchyTreeData}
                keys={assets}
                className={`op-filter-tree`}
                onCheck={(e) => {
                  dispatch?.(
                    settingsActions.updateSettingsByName({ name: 'mobileAreaIds', value: e })
                  );
                }}
              />
            </>
          ) : (
            <>
              {assets.filter((el) => areaHierarchyTreeDataMap[el]).length > 0 && (
                <div className="selected-tree-values mb-2">
                  {assets.map((item) => (
                    <Fragment key={item}>
                      {areaHierarchyTreeDataMap[item] &&
                        (!areaHierarchyTreeDataMap[item].parentKey ||
                          !assetsMap[areaHierarchyTreeDataMap[item].parentKey]) && (
                          <div>{areaHierarchyTreeDataMap[item].title}</div>
                        )}
                    </Fragment>
                  ))}
                </div>
              )}
              <div className="no-permission">
                <div>
                  <Icon name={'question'} />
                </div>
                <div>You do not have permission to change this setting.</div>
              </div>
            </>
          )}
        </SettingsValueCard>
        <SettingsValueCard
          title={'Inspection downloads'}
          description={
            'Limit run-based inspection downloads to those due or scheduled in the next:'
          }
        >
          <SettingsInput label={'day(s)'} name={'runInspectionDownload'} />
        </SettingsValueCard>
        <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_WORKORDERS}>
          <SettingsValueCard
            title={'Work order downloads'}
            description={'Limit work order downloads to those due or scheduled in the next:'}
          >
            <SettingsInput label={'day(s)'} name={'workOrderDownload'} />
          </SettingsValueCard>
        </PermissionRestrict>
        <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_CHECKS}>
          <SettingsValueCard
            title={'Check downloads'}
            description={'Limit check downloads to those due or scheduled in the next:'}
          >
            <SettingsInput label={'day(s)'} name={'checkDownload'} />
          </SettingsValueCard>
        </PermissionRestrict>
        {/* <SettingsValueCard
          title={'Background data updates'}
          description={
            'Oplii will update data and download new tasks (but won’t automatically sync completed tasks):'
          }
        >
          <RadioGroup
            options={whenOptions}
            value={settings['autoConnectUpdate']}
            onChange={(value) =>
              dispatch?.(settingsActions.updateSettingsByName({ name: 'autoConnectUpdate', value }))
            }
          />
        </SettingsValueCard> */}
        <PermissionRestrict roles={PERMISSIONS.MOBILE_ASSIGNED_INSPECTIONS}>
          <SettingsValueCard
            title={'Back up in-progress inspections'}
            description={'In-progress inspections will be backed up to the Oplii server:'}
          >
            <RadioGroup
              options={whenOptions}
              value={settings['autoConnectBackup']}
              onChange={(value) =>
                dispatch?.(
                  settingsActions.updateSettingsByName({ name: 'autoConnectBackup', value })
                )
              }
            />
          </SettingsValueCard>
        </PermissionRestrict>
      </div>
    </div>
  );
};

export default memo(DataCachingSettings);
