export const PERMISSIONS = {
  MOBILE_SUGGESTCHANGE_PIPELINES: 'MOBILE_SUGGESTCHANGE_PIPELINES',
  MOBILE_SUGGESTCHANGE_SITES: 'MOBILE_SUGGESTCHANGE_SITES',
  MOBILE_SUGGESTCHANGE_STRUCTURES: 'MOBILE_SUGGESTCHANGE_STRUCTURES',
  WORKORDER_CREATE_ATTACHMENTS: 'WORKORDER_CREATE_ATTACHMENTS',
  CHECK_EDIT_COMPLETED_DATE: 'CHECK_EDIT_COMPLETED_DATE',
  WORKORDER_BULKCHANGE: 'WORKORDER_BULKCHANGE',
  MOC_EDIT_TRIGGER: 'MOC_EDIT_TRIGGER',
  WORKORDER_EDIT_GENERAL: 'WORKORDER_EDIT_GENERAL',
  MOC_CREATE_ATTACHMENTS: 'MOC_CREATE_ATTACHMENTS',
  INSPECTION_EDIT_ADMIN_COMMENT: 'INSPECTION_EDIT_ADMIN_COMMENT',
  CHECK_ACCESS_BULK_DATA_REPORT: 'CHECK_ACCESS_BULK_DATA_REPORT',
  MOC_VIEW_CLOSEOUT: 'MOC_VIEW_CLOSEOUT',
  MT_VIEW_TRANSPORT: 'MT_VIEW_TRANSPORT',
  WORKORDER_VIEW_ASSIGNMENT: 'WORKORDER_VIEW_ASSIGNMENT',
  WORKORDER_EDIT_REQUESTER: 'WORKORDER_EDIT_REQUESTER',
  WORKORDER_EDIT_WORKLOG: 'WORKORDER_EDIT_WORKLOG',
  WORKREQUEST_CREATE_ATTACHMENTS: 'WORKREQUEST_CREATE_ATTACHMENTS',
  MT_VIEW_STATUS: 'MT_VIEW_STATUS',
  MOC_DELETE_OWNATTACHMENTS: 'MOC_DELETE_OWNATTACHMENTS',
  MT_VIEW_EQUIPMENTSTRUCTURELISTING: 'MT_VIEW_EQUIPMENTSTRUCTURELISTING',
  MOBILE_CREATE_WORKORDER: 'MOBILE_CREATE_WORKORDER',
  WORKREQUEST_VIEW_REQUESTER: 'WORKREQUEST_VIEW_REQUESTER',
  INSPECTION_BROWSE: 'INSPECTION_BROWSE',
  CHECK_VIEW_GENERAL: 'CHECK_VIEW_GENERAL',
  MOC_VIEW_AFFECTED: 'MOC_VIEW_AFFECTED',
  CHECK_DELETE_ALLATTACHMENTS: 'CHECK_DELETE_ALLATTACHMENTS',
  INSPECTION_VIEW_STATUS: 'INSPECTION_VIEW_STATUS',
  WORKREQUEST_EDIT_APPLICABLEITEM: 'WORKREQUEST_EDIT_APPLICABLEITEM',
  MOBILE_ONDEMAND_INSPECTIONS: 'MOBILE_ONDEMAND_INSPECTIONS',
  INSPECTION_CREATE_RUNS: 'INSPECTION_CREATE_RUNS',
  WORKREQUEST_VIEW_ESTIMATES: 'WORKREQUEST_VIEW_ESTIMATES',
  MT_EDIT_DESTINATION: 'MT_EDIT_DESTINATION',
  INSPECTION_CREATE: 'INSPECTION_CREATE',
  INSPECTION_EDIT_FOLLOWUP: 'INSPECTION_EDIT_FOLLOWUP',
  WORKORDER_VIEW_EXPENSEBREAKDOWN: 'WORKORDER_VIEW_EXPENSEBREAKDOWN',
  MT_DELETE: 'MT_DELETE',
  MOBILE_ASSIGNED_INSPECTIONS: 'MOBILE_ASSIGNED_INSPECTIONS',
  INSPECTION_BROWSE_RUNS: 'INSPECTION_BROWSE_RUNS',
  CHECK_ASSIGNABLE_RESPONSIBLE_USER: 'CHECK_ASSIGNABLE_RESPONSIBLE_USER',
  CHECK_EDIT_APPLIES_TO: 'CHECK_EDIT_APPLIES_TO',
  CHECK_ASSIGNABLE_USER: 'CHECK_ASSIGNABLE_USER',
  WORKORDER_EDIT_ASSIGNMENT: 'WORKORDER_EDIT_ASSIGNMENT',
  CHECK_CREATE_EDIT_SCHEDULE: 'CHECK_CREATE_EDIT_SCHEDULE',
  INSPECTION_EDIT_INTERVALS: 'INSPECTION_EDIT_INTERVALS',
  MOBILE_VIEW_STRUCTURES: 'MOBILE_VIEW_STRUCTURES',
  CHECK_BROWSE: 'CHECK_BROWSE',
  INSPECTION_VIEW_INTERVALS: 'INSPECTION_VIEW_INTERVALS',
  WORKORDER_VIEW_SERVICEHISTORY: 'WORKORDER_VIEW_SERVICEHISTORY',
  MOBILE_CHANGE_CACHED_AREAS: 'MOBILE_CHANGE_CACHED_AREAS',
  WORKORDER_VIEW_COUNTERREADINGS: 'WORKORDER_VIEW_COUNTERREADINGS',
  WORKORDER_DELETE_ALLATTACHMENTS: 'WORKORDER_DELETE_ALLATTACHMENTS',
  INSPECTION_BROWSE_UPCOMING: 'INSPECTION_BROWSE_UPCOMING',
  WORKREQUEST_EDIT_STATUS: 'WORKREQUEST_EDIT_STATUS',
  INSPECTION_BROWSE_SCHEDULE: 'INSPECTION_BROWSE_SCHEDULE',
  WORKREQUEST_BROWSE: 'WORKREQUEST_BROWSE',
  CHECK_DELETE_SCHEDULE: 'CHECK_DELETE_SCHEDULE',
  CHECK_CREATE_ATTACHMENTS: 'CHECK_CREATE_ATTACHMENTS',
  MT_DELETE_ALLATTACHMENTS: 'MT_DELETE_ALLATTACHMENTS',
  WORKREQUEST_EDIT_REQUESTER: 'WORKREQUEST_EDIT_REQUESTER',
  MOC_EDIT_TEMPREMINDER: 'MOC_EDIT_TEMPREMINDER',
  MOC_EDIT_CHANGE: 'MOC_EDIT_CHANGE',
  WORKORDER_EDIT_FAILUREMODES: 'WORKORDER_EDIT_FAILUREMODES',
  WORKORDER_VIEW_GENERAL: 'WORKORDER_VIEW_GENERAL',
  WORKORDER_CREATE: 'WORKORDER_CREATE',
  WORKREQUEST_EDIT_WORKDESCRIPTION: 'WORKREQUEST_EDIT_WORKDESCRIPTION',
  CHECK_BROWSE_DELETED_SCHEDULE: 'CHECK_BROWSE_DELETED_SCHEDULE',
  INSPECTION_DELETE: 'INSPECTION_DELETE',
  MOC_VIEW_TRIGGER: 'MOC_VIEW_TRIGGER',
  MOBILE_ASSIGNED_WORKORDERS: 'MOBILE_ASSIGNED_WORKORDERS',
  INSPECTION_EDIT_INSPECTION_SUMMARY: 'INSPECTION_EDIT_INSPECTION_SUMMARY',
  CHECK_BROWSE_UPCOMING_AND_SCHEDULE: 'CHECK_BROWSE_UPCOMING_AND_SCHEDULE',
  WORKORDER_BROWSE: 'WORKORDER_BROWSE',
  INSPECTION_VIEW_FOLLOWUP: 'INSPECTION_VIEW_FOLLOWUP',
  MOC_CREATE: 'MOC_CREATE',
  WORKREQUEST_EDIT_ESTIMATES: 'WORKREQUEST_EDIT_ESTIMATES',
  WORKORDER_EDIT_WORKDESCRIPTION: 'WORKORDER_EDIT_WORKDESCRIPTION',
  WORKORDER_EDIT_ACTIVITIES: 'WORKORDER_EDIT_ACTIVITIES',
  WORKORDER_EDIT_APPLICABLEITEM: 'WORKORDER_EDIT_APPLICABLEITEM',
  CHECK_DELETE_OWNATTACHMENTS: 'CHECK_DELETE_OWNATTACHMENTS',
  MOC_EDIT_STATUS: 'MOC_EDIT_STATUS',
  MOBILE_ACCESS: 'MOBILE_ACCESS',
  MT_DELETE_OWNATTACHMENTS: 'MT_DELETE_OWNATTACHMENTS',
  WORKREQUEST_DELETE_ALLATTACHMENTS: 'WORKREQUEST_DELETE_ALLATTACHMENTS',
  WORKORDER_EDIT_COUNTERREADINGS: 'WORKORDER_EDIT_COUNTERREADINGS',
  INSPECTION_VIEW_INSPECTION_SUMMARY: 'INSPECTION_VIEW_INSPECTION_SUMMARY',
  INSPECTION_ASSIGNABLE_INSPECTION_USER: 'INSPECTION_ASSIGNABLE_INSPECTION_USER',
  MT_VIEW_DESTINATION: 'MT_VIEW_DESTINATION',
  INSPECTION_CREATE_SCHEDULE: 'INSPECTION_CREATE_SCHEDULE',
  CHECK_ADMINISTER: 'CHECK_ADMINISTER',
  INSPECTION_ADMINISTER: 'INSPECTION_ADMINISTER',
  WORKORDER_EDIT_STATUS: 'WORKORDER_EDIT_STATUS',
  MOBILE_CREATE_WORKREQUEST: 'MOBILE_CREATE_WORKREQUEST',
  INSPECTION_VIEW_APPLIESTO: 'INSPECTION_VIEW_APPLIESTO',
  MT_BROWSE: 'MT_BROWSE',
  MT_EDIT_STATUS: 'MT_EDIT_STATUS',
  WORKORDER_VIEW_ACTIVITIES: 'WORKORDER_VIEW_ACTIVITIES',
  INSPECTION_IMPORT: 'INSPECTION_IMPORT',
  CHECK_EDIT_STATUS: 'CHECK_EDIT_STATUS',
  MT_EDIT_TRANSPORT: 'MT_EDIT_TRANSPORT',
  CHECK_VIEW_STATUS: 'CHECK_VIEW_STATUS',
  WORKORDER_VIEW_SUMMARY: 'WORKORDER_VIEW_SUMMARY',
  MOC_BROWSE: 'MOC_BROWSE',
  INSPECTION_VIEW_FORM: 'INSPECTION_VIEW_FORM',
  WORKREQUEST_VIEW_STATUS: 'WORKREQUEST_VIEW_STATUS',
  WORKORDER_PROJECTED: 'WORKORDER_PROJECTED',
  MT_EDIT_GENERAL: 'MT_EDIT_GENERAL',
  MT_ADMINISTER: 'MT_ADMINISTER',
  MOC_DELETE_ALLATTACHMENTS: 'MOC_DELETE_ALLATTACHMENTS',
  WORKORDER_VIEW_CHECKSHEETS: 'WORKORDER_VIEW_CHECKSHEETS',
  WORKORDER_MOBILE: 'WORKORDER_MOBILE',
  INSPECTION_DELETE_SCHEDULE: 'INSPECTION_DELETE_SCHEDULE',
  WORKORDER_VIEW_ESTIMATES: 'WORKORDER_VIEW_ESTIMATES',
  WORKORDER_DELETE_OWNATTACHMENTS: 'WORKORDER_DELETE_OWNATTACHMENTS',
  MOBILE_CREATE_COMPLETED_WORKORDER: 'MOBILE_CREATE_COMPLETED_WORKORDER',
  MOBILE_VIEW_PIPELINES: 'MOBILE_VIEW_PIPELINES',
  MOC_EDIT_AFFECTED: 'MOC_EDIT_AFFECTED',
  WORKREQUEST_EDIT_GENERAL: 'WORKREQUEST_EDIT_GENERAL',
  CHECK_VIEW_APPLIES_TO: 'CHECK_VIEW_APPLIES_TO',
  WORKORDER_EDIT_PARTSMATERIALSCONSUMED: 'WORKORDER_EDIT_PARTSMATERIALSCONSUMED',
  WORKREQUEST_ADMINISTER: 'WORKREQUEST_ADMINISTER',
  INSPECTION_BROWSE_MOBILE: 'INSPECTION_BROWSE_MOBILE',
  WORKREQUEST_DELETE_OWNATTACHMENTS: 'WORKREQUEST_DELETE_OWNATTACHMENTS',
  WORKREQUEST_DELETE: 'WORKREQUEST_DELETE',
  WORKORDER_VIEW_PARTSMATERIALSCONSUMED: 'WORKORDER_VIEW_PARTSMATERIALSCONSUMED',
  INSPECTION_BROWSE_DELETED: 'INSPECTION_BROWSE_DELETED',
  MOC_EDIT_CLOSEOUT: 'MOC_EDIT_CLOSEOUT',
  WORKREQUEST_VIEW_WORKDESCRIPTION: 'WORKREQUEST_VIEW_WORKDESCRIPTION',
  MOBILE_ONDEMAND_CHECKS: 'MOBILE_ONDEMAND_CHECKS',
  INSPECTION_DELETE_RUNS: 'INSPECTION_DELETE_RUNS',
  WORKORDER_VIEW_FAILUREMODES: 'WORKORDER_VIEW_FAILUREMODES',
  MOC_EDIT_RISK: 'MOC_EDIT_RISK',
  WORKORDER_VIEW_STATUS: 'WORKORDER_VIEW_STATUS',
  WORKORDER_VIEW_SCHEDULING: 'WORKORDER_VIEW_SCHEDULING',
  CHECK_IMPORT_SCHEDULE: 'CHECK_IMPORT_SCHEDULE',
  WORKORDER_VIEW_WORKDESCRIPTION: 'WORKORDER_VIEW_WORKDESCRIPTION',
  MOBILE_CREATE_HSE: 'MOBILE_CREATE_HSE',
  MOC_VIEW_STATUS: 'MOC_VIEW_STATUS',
  MOC_VIEW_RISK: 'MOC_VIEW_RISK',
  MOBILE_CHANGE_DISPLAY_SETTINGS: 'MOBILE_CHANGE_DISPLAY_SETTINGS',
  CHECK_EDIT_GENERAL: 'CHECK_EDIT_GENERAL',
  MOBILE_VIEW_EQUIPMENT: 'MOBILE_VIEW_EQUIPMENT',
  INSPECTION_BULKCHANGE: 'INSPECTION_BULKCHANGE',
  INSPECTION_DELETE_ALLATTACHMENTS: 'INSPECTION_DELETE_ALLATTACHMENTS',
  MOBILE_CREATEEDIT_EQUIPMENT: 'MOBILE_CREATEEDIT_EQUIPMENT',
  CHECK_DELETE: 'CHECK_DELETE',
  CHECK_VIEW_FORM: 'CHECK_VIEW_FORM',
  INSPECTION_BROWSE_PROJECTED_CALENDAR: 'INSPECTION_BROWSE_PROJECTED_CALENDAR',
  MOC_EDIT_GENERAL: 'MOC_EDIT_GENERAL',
  MOC_VIEW_GENERAL: 'MOC_VIEW_GENERAL',
  INSPECTION_DELETE_OWNATTACHMENTS: 'INSPECTION_DELETE_OWNATTACHMENTS',
  WORKORDER_ADMINISTER: 'WORKORDER_ADMINISTER',
  WORKORDER_DELETE: 'WORKORDER_DELETE',
  MT_CREATE_ATTACHMENTS: 'MT_CREATE_ATTACHMENTS',
  INSPECTION_CREATE_ATTACHMENTS: 'INSPECTION_CREATE_ATTACHMENTS',
  WORKORDER_EDIT_CHECKSHEETS: 'WORKORDER_EDIT_CHECKSHEETS',
  WORKORDER_EDIT_ESTIMATES: 'WORKORDER_EDIT_ESTIMATES',
  MT_EDIT_ASSET: 'MT_EDIT_ASSET',
  WORKREQUEST_CREATE: 'WORKREQUEST_CREATE',
  MT_VIEW_ASSET: 'MT_VIEW_ASSET',
  MOBILE_VIEW_SITES: 'MOBILE_VIEW_SITES',
  WORKORDER_VIEW_APPLICABLEITEM: 'WORKORDER_VIEW_APPLICABLEITEM',
  CHECK_BROWSE_MOBILE: 'CHECK_BROWSE_MOBILE',
  WORKREQUEST_VIEW_APPLICABLEITEM: 'WORKREQUEST_VIEW_APPLICABLEITEM',
  INSPECTION_ASSIGNABLE_RUN_USER: 'INSPECTION_ASSIGNABLE_RUN_USER',
  WORKREQUEST_VIEW_GENERAL: 'WORKREQUEST_VIEW_GENERAL',
  RECURRING_TEMPLATE_ADMINISTER: 'RECURRING_TEMPLATE_ADMINISTER',
  MOBILE_ADMINISTER: 'MOBILE_ADMINISTER',
  MT_CREATE: 'MT_CREATE',
  INSPECTION_EDIT_STATUS: 'INSPECTION_EDIT_STATUS',
  WORKORDER_EDIT_SCHEDULING: 'WORKORDER_EDIT_SCHEDULING',
  MOC_VIEW_TEMPREMINDER: 'MOC_VIEW_TEMPREMINDER',
  MOC_BROWSE_DELETED: 'MOC_BROWSE_DELETED',
  WORKORDER_VIEW_WORKLOG: 'WORKORDER_VIEW_WORKLOG',
  MT_VIEW_GENERAL: 'MT_VIEW_GENERAL',
  MOC_DELETE: 'MOC_DELETE',
  MOBILE_ASSIGNED_CHECKS: 'MOBILE_ASSIGNED_CHECKS',
  MOC_VIEW_CHANGE: 'MOC_VIEW_CHANGE',
  CHECK_BROWSE_DELETED: 'CHECK_BROWSE_DELETED',
  WORKORDER_VIEW_REQUESTER: 'WORKORDER_VIEW_REQUESTER'
};
