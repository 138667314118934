/* eslint-disable no-unused-vars */
import moment from 'moment';
import { store } from 'redux/store';

import http from './http.service';
import Promisable from './promisable.service';
import { cacheActions } from '@redux/slices/cache';
import { getAppDispatch } from '@utilities/dispatch.util';
import { jurisdictionNumbersActions } from '@redux/slices/jurisdiction-numbers';
import { pvPsvActions } from '@redux/slices/pv-psv';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
import { initWebWorker } from 'webworker';

const EquipmentService = {
  getJurisdictionNumbers: async () => {
    const dispatch = getAppDispatch();
    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      dispatch?.(jurisdictionNumbersActions.setLoading(true));

      http.setJWT();
      const [success, error] = await Promisable.asPromise(http.get(`/JurisdictionNumbers?page=0`));

      if (success)
        dispatch?.(jurisdictionNumbersActions.setJurisdictionNumbers(success.data.items));

      dispatch?.(jurisdictionNumbersActions.setLoading(false));
      dispatch?.(globalProgressBarAction.setLoadStatus(7));

      return [success, error];
    } else throw new Error('Api failed to update');
  },

  getPVPSV: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(pvPsvActions.setLoading(true));

    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      http.setJWT();
      const [success, error] = await Promisable.asPromise(http.get(`/PVPSV/mobile?page=0`));

      if (success) dispatch?.(pvPsvActions.setPVPSV(success.data.items));

      dispatch?.(pvPsvActions.setLoading(false));
      dispatch?.(globalProgressBarAction.setLoadStatus(6));

      return [success, error];
    } else throw new Error('Api failed to update');
  },
  setEquipments: async ({ initialLogin, lastSuccessFullRefreshTime, isSettingUpdated }) => {
    const { cache } = store.getState();
    const { equipmentsMap = {}, equipments = [] } = cache;
    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      const changedAfter = lastSuccessFullRefreshTime;
      const dispatch = getAppDispatch();
      http.setJWT();
      const [success, error] = await Promisable.asPromise(
        http.get(
          `/Equipment/mobile?page=0&changedAfter=${
            !initialLogin
              ? changedAfter
                ? equipments?.length === 0
                  ? ''
                  : changedAfter
                : ''
              : ''
          }`
        )
      );

      const isCheckOldAndNewArray =
        !initialLogin && success?.data?.items?.length && equipments?.length && !isSettingUpdated;

      const data = await initWebWorker({
        args: {
          equipmentPayloadData: !isCheckOldAndNewArray ? success?.data?.items || [] : [],
          isCheckOldAndNewArray: isCheckOldAndNewArray
            ? {
                oldArray: equipments,
                oldArrayMap: equipmentsMap,
                newArray: success?.data?.items,
                idName: 'equipmentId'
              }
            : false
        },
        type: 'equipment-mobile-data-redux',
        timeout: 1000000
      });

      dispatch?.(cacheActions.setEquipments(data?.result));

      dispatch?.(globalProgressBarAction.setLoadStatus(4));
      return [success, error];
    } else throw new Error('Api failed to update');
  }
};

export default EquipmentService;
