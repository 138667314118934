import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  searchPage: null,
  siteDetailsPage: null,
  parentSiteDetails: null,
  equipmentDetails: null,
  parentEquipmentDetails: null,
  structureDetails: null,
  pipelineDetails: null
};

export const backHistorySlice = createSlice({
  name: 'backHistory',
  initialState,
  reducers: {
    setSearchPageHistory: (state, action) => {
      state.searchPage = action.payload;
    },
    setSiteDetailsPageHistory: (state, action) => {
      state.siteDetailsPage = action.payload;
    },
    setParentSiteDetailsPageHistory: (state, action) => {
      state.parentSiteDetails = action.payload;
    },
    setEquipmentDetailsPageHistory: (state, action) => {
      state.equipmentDetails = action.payload;
    },
    setParentEquipmentDetailsPageHistory: (state, action) => {
      state.parentEquipmentDetails = action.payload;
    },
    setStructureDetailsPageHistory: (state, action) => {
      state.structureDetails = action.payload;
    },
    setPipelineDetailsPageHistory: (state, action) => {
      state.pipelineDetails = action.payload;
    },
    clearAllDetailsPage: (state, action) => {
      state.siteDetailsPage = action.payload;
      state.parentSiteDetails = action.payload;
      state.equipmentDetails = action.payload;
      state.parentEquipmentDetails = action.payload;
      state.structureDetails = action.payload;
      state.pipelineDetails = action.payload;
    }
  }
});

const backHistoryReducer = backHistorySlice.reducer;

export const backHistoryActions = backHistorySlice.actions;
export default backHistoryReducer;
