/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import Row from '@components/grid-1/Row';
import NewTabs from '@components/new-tabs';
import SiteListingInner from '@components/site-listing-inner';
import TaskCard from '@components/task-card';
import VirtualList from '@components/virtual-list/virtual-list';
import equipmentListingInner from '@components/equipment-listing/equipment-listing-inner';
import RecurringTemplateDetails from '@pages/recurring-template/recurring-template-details';
import Icon from '@components/icon-component';
import StructureListingInner from '@components/structure-listing-inner/structure-listing-inner.component';
import PipelineListingInner from '@components/pipeline-listing/pipeline-listing-inner/pipeline-listing-inner.component';
import InspectionRunDetails from '@pages/inspection-run/inspection-run-details';

import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';
import useDistance from '@hooks/useDistance';
import { cacheActions } from '@redux/slices/cache';

import './tabs-section.scss';
import style from '../search-page.module.scss';

const TabsSection = ({
  searched = '',
  filterSites = [],
  filterEquipments = [],
  filteredStructures = [],
  filteredPipelines = [],
  filterTasks = [],
  filterRts = [],
  filterRuns = [],
  setTasks,
  tasks,
  setRts,
  setRuns,
  setRtsSelectedView,
  runsSelectedView,
  setRunsSelectedView,
  rtsSelectedView,
  checkedLengthOfRtsTasksAndSites,
  checkedLengthOfRunsTasksAndSites,
  currentTab,
  checkedLength = 0,
  onChangeTabEvent = null
}) => {
  const [tab, setTab] = useState(currentTab || '0');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = getAppDispatch();
  const { coordinates } = useDistance({});

  const startBtnEvent = () => {
    const selectedOrdersIds = [],
      selectedTypeIds = [];

    let type = '';

    dispatch?.(cacheActions.setStartTaskCoordinates({ coordinates }));

    for (const task of tasks) {
      if (task.checked) {
        type = task?.woId
          ? task?.newWorkOrder
            ? 'new-work-order'
            : task?.newWorkRequest
            ? 'new-work-request'
            : ''
          : task?.inspectableItemId
          ? 'inspection'
          : 'work-orders';
        selectedTypeIds.push(type);
        if (type === 'work-orders') {
          selectedOrdersIds.push(task?.id);
        } else if (type === 'inspection') selectedOrdersIds.push(task?.id);
        else {
          selectedOrdersIds.push(task?.woId);
        }
      }
    }
    onParentClick && onParentClick();
    let urlType = selectedTypeIds?.includes('inspection') ? 'inspection' : '';
    urlType = selectedTypeIds?.some(
      (x) => x === 'work-orders' || x === 'new-work-order' || x === 'new-work-request'
    )
      ? _.isEmpty(urlType)
        ? 'work-orders'
        : urlType?.concat(',work-orders')
      : urlType;

    let navigateURL = `/work-order/?from=${
      location.pathname
    }&type=${urlType}&orderIds=${selectedOrdersIds.join(',')}`;

    navigate(navigateURL, {
      state: {
        from: 'task-records'
      }
    });
  };

  const onParentClick = React.useCallback(() => {
    dispatch?.(
      backHistoryActions.setSearchPageHistory({
        tab,
        searchedValue: searched
      })
    );
  }, [tab, searched]);

  const items = [
    ...(filterTasks?.length > 0
      ? [
          {
            label: `Tasks (${filterTasks?.length}) `,
            children: (
              <>
                <div
                  className="removeScrollbar"
                  style={{
                    padding: '16px',
                    height: 'calc(100vh - 211px)',
                    overflowY: 'overlay',
                    overflowX: 'hidden'
                  }}
                  id="searchTaskCardContainer"
                  onScroll={() => {
                    var el = document.querySelector('#searchTaskCardContainer');
                    el.classList.add('addScrollbar');
                    el.classList.remove('removeScrollbar');
                    clearTimeout();
                    setTimeout(function () {
                      el.classList.remove('addScrollbar');
                      el.classList.add('removeScrollbar');
                    }, 300);
                  }}
                >
                  <Row gutter={16} xs={24} md={12} lg={8} xxl={6}>
                    {filterTasks?.map(({ status, checked, ...task }, index) => (
                      <div key={index}>
                        <TaskCard
                          {...task}
                          selection={checkedLength > 0 ? checked : false}
                          status={status}
                          searched={searched}
                          onSelection={(checked) => {
                            const copy = [...tasks];
                            if (copy[index]?.status === 'To do' && copy[index]?.type !== 'Check') {
                              copy[index].checked = checked;
                              setTasks([...copy]);
                            }
                          }}
                        />
                      </div>
                    ))}
                  </Row>
                </div>
              </>
            )
          }
        ]
      : []),

    ...(filterSites?.length > 0
      ? [
          {
            label: `Sites (${filterSites?.length}) `,
            children: (
              <div style={{ height: 'calc( 100vh - 218px)', overflowX: 'hidden' }}>
                <VirtualList
                  divider
                  data={filterSites}
                  component={SiteListingInner}
                  componentProps={{
                    highlight: searched,
                    highlightName: true,
                    onParentClick: onParentClick,
                    noLink: true
                  }}
                  overscan={25}
                />
              </div>
            )
          }
        ]
      : []),
    ...(filterEquipments?.length > 0
      ? [
          {
            label: `Equipment (${filterEquipments.length}) `,
            children: (
              <div style={{ height: ' calc( 100vh - 218px)', overflowX: 'hidden' }}>
                <VirtualList
                  divider
                  data={filterEquipments}
                  component={equipmentListingInner}
                  componentProps={{
                    highlight: searched,
                    highlightName: true,
                    onParentClick: onParentClick,
                    noLink: true
                  }}
                  overscan={25}
                />
              </div>
            )
          }
        ]
      : []),

    ...(filteredStructures?.length > 0
      ? [
          {
            label: `Structures (${filteredStructures.length}) `,
            children: (
              <div style={{ height: ' calc( 100vh - 218px)', overflowX: 'hidden' }}>
                <VirtualList
                  divider
                  data={filteredStructures}
                  component={StructureListingInner}
                  componentProps={{
                    highlight: searched,
                    highlightName: true,
                    onParentClick: onParentClick,
                    noLink: true
                  }}
                  overscan={25}
                />
              </div>
            )
          }
        ]
      : []),
    ...(filteredPipelines?.length > 0
      ? [
          {
            label: `Pipeline (${filteredPipelines.length}) `,
            children: (
              <div style={{ height: ' calc( 100vh - 218px)', overflowX: 'hidden' }}>
                <VirtualList
                  divider
                  data={filteredPipelines}
                  component={PipelineListingInner}
                  componentProps={{
                    highlight: searched,
                    highlightName: true,
                    onParentClick: onParentClick,
                    noLink: true
                  }}
                  overscan={25}
                />
              </div>
            )
          }
        ]
      : []),

    ...(filterRts?.length > 0
      ? [
          {
            label: `RTs (${filterRts.length}) `,
            children: (
              <RecurringTemplateDetails
                checkedLength={checkedLengthOfRtsTasksAndSites}
                view={rtsSelectedView}
                setRt={setRts}
                highlight={searched}
                setView={setRtsSelectedView}
                rt={filterRts}
                onParentClick={onParentClick}
              />
            )
          }
        ]
      : []),
    ...(filterRuns?.length > 0
      ? [
          {
            label: `Runs (${filterRuns.length}) `,
            children: (
              <InspectionRunDetails
                checkedLength={checkedLengthOfRunsTasksAndSites}
                view={runsSelectedView}
                setInspectionRun={setRuns}
                highlight={searched}
                setView={setRunsSelectedView}
                inspectionRun={filterRuns}
              />
            )
          }
        ]
      : [])
  ];

  return (
    <>
      {checkedLength > 0 && tab.toString() === '0' && filterTasks.length > 0 && (
        <button className={style.startBtn} onClick={startBtnEvent}>
          <Icon name={'forward-icon'} /> Start ({checkedLength})
        </button>
      )}
      <NewTabs
        active={tab}
        tabs={items}
        setActive={(tab) => {
          setTab(tab);
          onChangeTabEvent && onChangeTabEvent();
        }}
        className={`myStyle ${style.tabsForSearch} `}
      ></NewTabs>
    </>
  );
};

export default React.memo(TabsSection);
