import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDistance from '@hooks/useDistance';
import { cacheActions } from '@redux/slices/cache';

function RegisterLocation() {
  const dispatch = useDispatch();
  const { unit, coordinates } = useDistance({});
  const sitesUnit = useSelector((state) => state.cache.unit);

  useEffect(() => {
    dispatch?.(cacheActions.setUnitAndCoordinates({ unit, coordinates }));
  }, [unit, coordinates]);

  useEffect(() => {
    if (sitesUnit && dispatch) dispatch?.(cacheActions.setSitesMobile());
  }, [coordinates, sitesUnit, dispatch]);

  return null;
}

RegisterLocation.propTypes = {};

export default RegisterLocation;
