import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useOpenLink = ({ taskId = '', assetId = '' }) => {
  const [openLink, setOpenLink] = useState(false);
  const { linkIsOpen } = useSelector((state) => state.formWrapper);

  const openLinkModal = useMemo(() => {
    const cond =
      taskId === linkIsOpen?.taskId && assetId === linkIsOpen?.assetId && openLink ? true : false;
    return cond;
  }, [taskId, linkIsOpen?.taskId, linkIsOpen?.assetId, assetId, openLink]);

  useEffect(() => {
    setOpenLink(openLinkModal);
  }, [openLinkModal]);

  return [openLink, setOpenLink];
};
