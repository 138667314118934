/* eslint-disable no-unused-vars */

import { useEffect, useMemo, useState, useRef } from 'react';
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { store } from 'redux/store';

import PageContainer from '@components/page-container';
import PageHeader from '@components/page-header';
import NewTabs from '@components/new-tabs';
import MoreInformation from '@components/more-information';
import StructureSite from './structure-site';
import Icon from '@components/icon-component';
import LoadingRound from '@components/loading-round/loading-round';
import StructureCard from '@components/structure-listing-inner/structure-card';
import WorkOrder from '../../site-details/tasks/work-order';
import StructureBookmark from '@components/structure-listing/structure-bookmark';
import EquipmentListing from '@components/equipment-listing';

import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';
import { filterActions } from '@redux/slices/filters';
import { initWebWorker } from 'webworker';

import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './style.module.scss';
import './structure-site.scss';

const StructureSiteDetails = () => {
  const [searchParams] = useSearchParams();
  const dispatch = getAppDispatch();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  const swipeAbleMenuRef = useRef(null);
  const mainDivRef = useRef(null);
  const siteCardRef = useRef(null);
  const assetDetailPageHeaderRef = useRef(null);

  const [tab, setTab] = useState('0');
  const [open, setOpen] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [allTask, setAllTask] = useState(false);
  const [checks, setChecks] = useState(false);
  const [inspections, setInspections] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [syncComplete, setSyncComplete] = useState(false);
  const [structureDetails, setStructureDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { sitesMap = {}, sites: cacheSites = [] } = useSelector((state) => state.cache);
  const structureDetailsInfo = useSelector((state) => state?.backHistory?.structureDetails);
  const { tasksFilterCount = 0, equipmentFilterCount = 0 } = useSelector((state) => state.filters);
  const userId = useSelector((state) => state.auth.userId) || '';
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);
  const [isFilterActiveForEquipments, setFilterActiveForEquipments] = useState(
    width < 768 ? false : true
  );
  const { tasksList: reduxTasksList = [], selectTask } = useSelector((state) => state.tasks);
  const { inspectionsList } = useSelector((state) => state.inspection);
  const { checksList } = useSelector((state) => state.checks);

  const { id } = useParams();
  const { pathname, search } = useLocation();
  const bookmarkName = searchParams.get('bookmarkName');
  const bookmarkTab = searchParams.get('bookmarkTab');
  const currentOpenTaskTab = searchParams.get('currentOpenTaskTab') || '';

  useEffect(() => {
    if (currentOpenTaskTab) {
      if (currentOpenTaskTab === 'allTask') setAllTask(true);
      else if (currentOpenTaskTab === 'workOrder') setWorkOrder(true);
      else if (currentOpenTaskTab === 'inspections') setInspections(true);
      else if (currentOpenTaskTab === 'checks') setChecks(true);
      // else if (currentOpenTaskTab === 'workRequest') setWorkRequest(true);
    }
  }, [currentOpenTaskTab]);

  useEffect(() => {
    if (!_.isEmpty(structureDetailsInfo)) {
      structureDetailsInfo?.structureDetailsTab &&
        setTab(structureDetailsInfo?.structureDetailsTab || '0');
      structureDetailsInfo?.workOrder && setWorkOrder(structureDetailsInfo?.workOrder);
    }
  }, [structureDetailsInfo]);

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() },
        structureId: id,
        userId
      },
      type: 'get-single-structure-details'
    })
      .then((data) => {
        setStructureDetails(data?.result);
        setIsLoading(false);
        setTab('0');
      })
      .catch((error) => {
        setIsLoading(false);
        setTab('0');
      });
  }, [id, syncComplete, reduxTasksList, inspectionsList, checksList]);

  const {
    name,
    type,
    tasks,
    allDoneTasks,
    isOverDue,
    allWorkOrders,
    allDoneWorkOrders,
    isWorkOrderOverDue,
    status,
    equipments,
    allChecks,
    allDoneChecks,
    structuresMap,
    equipmentsMap,
    dataPoints,
    siteId,
    dataPointsMap,
    MoreInfoDataPointsMap,
    equipmentAllFieldData,
    sitesLoading,
    link,
    isCheckOverDue
  } = useMemo(() => {
    return structureDetails;
  }, [structureDetails]);

  useEffect(() => {
    if (syncComplete) setSyncComplete(false);
  }, [syncComplete]);

  const arrayText = [
    {
      icon: <Icon name={'more-info'} />,
      text: 'More information',
      onClick: () => {
        setMoreInfo(true);
        setOpen(false);
      }
    },
    {
      icon: <Icon name={'bookmark-grey'} />,
      text: 'Bookmark',
      onClick: () => {
        setOpenModal(true);
        setOpen(false);
      }
    },
    {
      icon: <Icon name={'open-in-oplii-desktop'} />,
      text: 'Open in Oplii desktop',
      onClick: () => {
        window.open(link, '_blank');
      }
    }
  ];

  const itemsMap = useMemo(() => {
    if (!isLoading)
      return [
        ...(tasks?.length > 0
          ? [
              {
                label: `Tasks (${tasks?.length})`,
                keyName: 'tasks'
              }
            ]
          : []),

        ...(equipments.length > 0
          ? [
              {
                label: `Equipment (${equipments.length})`,
                keyName: 'equipment'
              }
            ]
          : [])
      ];
    else return [];
  }, [isLoading]);

  const items = useMemo(() => {
    return !isLoading
      ? [
          ...(tasks?.length > 0
            ? [
                {
                  label: `Tasks (${tasks?.length})`,
                  keyName: 'tasks',
                  children: (
                    <StructureSite
                      setAllTask={setAllTask}
                      setWorkOrder={setWorkOrder}
                      allWorkOrderForStructure={allWorkOrders}
                      allDoneWorkOrder={allDoneWorkOrders}
                      tasks={tasks}
                      allDoneTasks={allDoneTasks}
                      isOverDue={isOverDue}
                      isWorkOrderOverDue={isWorkOrderOverDue}
                      allDoneChecks={allDoneChecks}
                      allChecks={allChecks}
                      isCheckOverDue={isCheckOverDue}
                      setChecks={setChecks}
                    />
                  )
                }
              ]
            : []),

          ...(equipments?.length > 0
            ? [
                {
                  label: `Equipment (${equipments.length})`,
                  keyName: 'equipment',
                  children: (
                    <EquipmentListing
                      assetDetailsOfEquipmentLists={equipmentAllFieldData}
                      isFilterActiveHeader={isFilterActiveForEquipments}
                      setFilterActiveHeader={setFilterActiveForEquipments}
                      from={pathname}
                      structureId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'equipment'}
                    />
                  )
                }
              ]
            : [])
        ]
      : [];
  }, [isLoading, itemsMap, isFilterActive, isFilterActiveForEquipments, allDoneTasks, tasks]);

  const tabIsActive = useMemo(() => {
    return isFilterActiveForEquipments || false;
  }, [isFilterActiveForEquipments]);

  useEffect(() => {
    const elements = document.getElementsByClassName('react-swipeable-view-container');
    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];
      if (tasks?.length > 0) {
        if (tab === 1) element.style.transform = tabIsActive ? 'none' : 'translate(-100%, 0px)';

        if (
          mainDivRef?.current &&
          swipeAbleMenuRef.current &&
          mainDivRef?.current?.scrollTop >= siteCardRef?.current?.offsetHeight
        ) {
          if (tabIsActive) swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
          else swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
        }
      } else {
        if (parseInt(tab) === 0)
          element.style.transform = tabIsActive ? 'none' : 'translate(0px, 0px)';

        if (
          mainDivRef?.current &&
          swipeAbleMenuRef.current &&
          mainDivRef?.current?.scrollTop >= siteCardRef?.current?.offsetHeight
        ) {
          if (tabIsActive) swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
          else swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
        }
      }
    }
  }, [tabIsActive, mainDivRef, swipeAbleMenuRef, siteCardRef]);

  useMemo(() => {
    if (!isLoading) {
      if (!sitesLoading && !id) return <Navigate to={'/structures'} />;
    }
  }, [structureDetails, isLoading]);

  const onParentClickEvent = ({ workOrderTab, workOrderSortTab }) => {
    dispatch?.(
      backHistoryActions.setStructureDetailsPageHistory({
        workOrder,
        structureDetailsTab: tab,
        workOrderTab,
        workOrderSortTab
      })
    );
  };

  useEffect(() => {
    if (items?.length > 0) {
      const params = new URLSearchParams(search);
      const currentTab = parseInt(tab);
      params.set('tab', currentTab);

      if (items[currentTab]?.keyName === 'tasks') {
        if (allTask) params.set('currentOpenTaskTab', 'allTask');
        else if (workOrder) params.set('currentOpenTaskTab', 'workOrder');
        else if (inspections) params.set('currentOpenTaskTab', 'inspections');
        else if (checks) params.set('currentOpenTaskTab', 'checks');
        // else if (workRequest) params.set('currentOpenTaskTab', 'workRequest');
        else if (!workOrder && !allTask && !inspections && !checks)
          params.delete('currentOpenTaskTab');
      }

      params.set(
        'virtualScrollTab',
        currentTab === 0 && items[currentTab]?.keyName === 'tasks'
          ? -1
          : tasks?.length > 0
          ? currentTab - 1
          : currentTab
      );
      params.set('keyName', items[tab]?.keyName);
      const tabParams = '?' + params.toString();
      const prevRoute = `${pathname}${tabParams}`;
      navigate(prevRoute, { replace: true });
    }
  }, [tab, items, items, workOrder, allTask, inspections, checks]);

  useEffect(() => {
    if (!isLoading && bookmarkTab) setTab(bookmarkTab);
  }, [isLoading, bookmarkTab]);

  const pageHeaderClickEvent = () => {
    workOrder && dispatch?.(filterActions.setTaskFilters(null));
    if (moreInfo) setMoreInfo(false);
    else if (allTask) setAllTask(false);
    else if (checks) setChecks(false);
    else if (workOrder) setWorkOrder(false);

    dispatch?.(backHistoryActions.setStructureDetailsPageHistory(null));
  };

  const pageHeaderTitle = useMemo(() => {
    return `${
      bookmarkName
        ? bookmarkName
        : moreInfo
        ? 'Structure Information'
        : allTask
        ? `All tasks (${tasks?.length > 0 ? tasks.length : ''})`
        : checks
        ? `Checks (${allChecks?.length > 0 ? allChecks?.length : ''})`
        : workOrder
        ? `Work orders (${allWorkOrders?.length > 0 ? allWorkOrders.length : ''})`
        : 'Structure'
    }`;
  }, [bookmarkName, moreInfo, allTask, checks, workOrder, tasks]);

  const filtersCount = useMemo(() => {
    return items[tab]?.keyName === 'equipment' ? equipmentFilterCount : tasksFilterCount;
  }, [tab, items, equipmentFilterCount, tasksFilterCount]);

  const isFilterIconIsRequired = useMemo(() => {
    return (tab !== '0' && !items[tab]?.keyName?.toLowerCase()?.includes('tasks')) ||
      allTask ||
      checks ||
      inspections ||
      workOrder
      ? items[tab]?.keyName === 'equipment'
        ? setFilterActiveForEquipments
        : setFilterActive
      : false;
  }, [tab, items, allTask, checks, inspections, workOrder]);

  const siteDetailsPageClassNames = useMemo(() => {
    return `${moreInfo || selectTask ? style.pageListingsWithMoreInfo : style.pageListings} ${
      style.noScroll
    } ${(workOrder || allTask || checks || inspections) && style.noScroll}`;
  }, [workOrder, allTask, checks, inspections, moreInfo, selectTask]);
  return (
    <PageContainer moreInfo={moreInfo}>
      <PageHeader
        disableBack={moreInfo || allTask || checks || inspections || workOrder}
        title={pageHeaderTitle}
        {...(isFilterIconIsRequired && {
          setFilterActive: isFilterIconIsRequired
        })}
        onClick={pageHeaderClickEvent}
        count={filtersCount}
        ref={assetDetailPageHeaderRef}
      />

      {isLoading ? (
        <div style={{ backgroundColor: 'transparent', width: '100%' }}>
          <LoadingRound />
        </div>
      ) : (
        <div
          ref={mainDivRef}
          id="assetDetailPage"
          className={siteDetailsPageClassNames}
          style={{ position: 'relative', paddingBottom: '0px', marginTop: '0px' }}
        >
          <div className="marginClass" ref={siteCardRef}>
            <StructureCard
              structureDetails
              chips={false}
              title={type}
              subtitle={name}
              hideLocation={true}
              dataPoints={dataPoints || []}
              allTasksForSite={tasks}
              allDoneTasks={allDoneTasks}
              isOverDue={isOverDue}
              status={status}
              arrayText={arrayText}
              sites={{
                ...cacheSites[sitesMap[siteId]],
                link: `/#/site-details/${siteId}`
              }}
              open={open}
              setOpen={setOpen}
              moreInfo={moreInfo}
              equipments={equipments}
              link={''}
            />
          </div>
          {moreInfo ? (
            <>
              <MoreInformation
                info={Object?.keys(MoreInfoDataPointsMap)?.map((a) => {
                  return {
                    title: MoreInfoDataPointsMap[a]?.value || null,
                    heading: MoreInfoDataPointsMap[a]?.title || null
                  };
                })}
              />
            </>
          ) : (
            <div className="flex relative">
              <div
                style={{
                  marginTop: '0px',
                  overflow: 'hidden',
                  paddingBottom: '0px'
                }}
                className={`${style.pageListings} mobileMargin`}
              >
                {allTask || workOrder || checks ? (
                  <WorkOrder
                    asset="structure"
                    tasks={allTask ? tasks : workOrder ? allWorkOrders : checks ? allChecks : []}
                    isFilterActiveHeader={isFilterActive}
                    setFilterActiveHeader={setFilterActive}
                    setSyncComplete={setSyncComplete}
                    selectTodoOrInProgressStatus={!_.isEmpty(bookmarkName) ? true : false}
                    onParentClickEvent={onParentClickEvent}
                    workOrderSelectedTab={structureDetails?.workOrderTab || null}
                    workOrderSortTab={structureDetails?.workOrderSortTab || null}
                    siteDetails
                    filterType={allTask ? 'all' : checks ? 'check' : null}
                  />
                ) : items?.length > 0 ? (
                  <div
                    className={`assetDetailNavNotFixed`}
                    ref={swipeAbleMenuRef}
                    id="assetDetailsNavigation"
                  >
                    <NewTabs
                      active={tab}
                      tabs={items}
                      setActive={(tab) => setTab(tab)}
                      className="mobileMargin"
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className="no-result"
                      style={{ flex: 1, justifyContent: 'flex-start', gap: '20px' }}
                    >
                      <div>No results</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {openModal && (
        <StructureBookmark
          open={openModal}
          setOpen={(value) => {
            setOpenModal(value);
          }}
          placeholder="My favourite truck"
          type="Structure Details"
          structureId={id || null}
        />
      )}
    </PageContainer>
  );
};

export default StructureSiteDetails;
