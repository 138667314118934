import React from 'react';
import { WorkTypes } from '../helper';
import style from '../workorder.module.scss';

const TodoAndSyncedButton = ({ workType, setActiveBtn, activeBtn }) => {
  return (
    <>
      {workType === WorkTypes.NewWorkOrder && (
        <div className={style.todoAndDoneBtn}>
          <p className={style.logAs}>Log as</p>
          <button
            className={`${style.todo} ${
              activeBtn === 'Todo' ? style.isActiveWorkOrderBtn : style.isInActiveWorkOrderBtn
            } `}
            onClick={() => setActiveBtn('Todo')}
            type="button"
          >
            To do
          </button>
          <button
            className={`${style.done} ${
              activeBtn === 'Synced' ? style.isActiveWorkOrderBtn : style.isInActiveWorkOrderBtn
            } `}
            onClick={() => setActiveBtn('Synced')}
            type="button"
          >
            Done
          </button>
        </div>
      )}
    </>
  );
};

export default TodoAndSyncedButton;
