/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { useSelector } from 'react-redux';

import DataSnippets from '@components/data-snippet';
import Icon from '@components/icon-component';

import { getDataPoints } from '@pages/settings/helper';
import { formWrapperActions } from '@redux/slices/form-wrapper';
import { getAppDispatch } from '@utilities/dispatch.util';
import { useOpenLink } from '@hooks/useOpenLink';

import style from './site-card.module.scss';

const SiteInnerCard = ({ sites, appliesTo, taskId, assetId }) => {
  const [openLink, setOpenLink] = useOpenLink({
    taskId,
    assetId
  });
  const dispatch = getAppDispatch?.();

  const {
    name,
    unit,
    parent,
    siteType,
    distance,
    hideLocation,
    highlightName,
    areaHierarchy,
    highlight = '',
    equipmentListing = false,
    link
  } = sites;

  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state.picklists
  );

  const siteDisplayFields =
    useSelector((state) => state.settings.settings?.['siteDisplayFields']) || '';

  const area = areaHierarchyTreeDataMap[areaHierarchy]?.title;
  const dataPoints = getDataPoints(
    { ...sites, type: siteType },
    siteDisplayFields,
    'site',
    picklistsMap
  );

  let [highlightNames, highlightType] = !_.isEmpty(highlight) ? highlight.split(' ') : [];
  highlightNames = highlightName ? highlight : highlightNames;

  if (!highlightType) highlightType = highlightNames;

  const onClickOpliiDesktopLinkEvent = (desktopLink) => {
    setOpenLink((prev) => !prev);
    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId,
        assetId
      })
    );
  };

  return (
    <div
      className={style.cardInner}
      style={{
        position: 'relative'
      }}
    >
      <div className={style.cardContent}>
        <DataSnippets
          variant="secondary"
          link={link}
          title={parent ? 'View all work at' : area}
          subtitle={name || ''}
          dataPoints={dataPoints}
          iconName={!hideLocation && 'map-pin'}
          kmValue={distance || ''}
          unit={unit || ''}
          highlightNames={highlightNames}
          highlightType={highlightType}
          className={`${equipmentListing && style.paddingOnSiteInfo}`}
          onClickOpliiDesktopLinkEvent={onClickOpliiDesktopLinkEvent}
        />
        {openLink && link && (
          <div
            className={style.showLink}
            onClick={(e) => {
              e?.stopPropagation();
              appliesTo
                ? dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(link))
                : window.open(link, '_self');

              setOpenLink((prev) => !prev);
            }}
          >
            <div>
              <Icon name={'map-pin'} />
              <p>Go to site</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SiteInnerCard;
