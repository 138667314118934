/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { store } from 'redux/store';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

import SearchList from '../search-list';
import WizardSteps from '../wizard-steps';
import Button from '@components/button';
import SearchTabList from '../search-tab-list';
import AssetsKindList from '../assets-kind-list';
import Icon from '@components/icon-component';
import InputField from '@components/input-field';
import HierarchicalDropdown from '@components/hierarchical-dropdown/hierarchical-dropdown';

import styles from './style.module.scss';

const AddNewHSEEvents = ({ asset = false, setActive, setAddNew }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(0);
  const [assetKind, setAssetKind] = useState('');
  const [hseCategoryId, setHSECategoryId] = useState('');
  const [inspectionFormName, setInspectionFormName] = useState();
  const { inspection = {} } = store.getState();
  const pathname = location.pathname;
  const { areaHierarchy, inspectionTypes } = inspection;

  useEffect(() => {
    if (asset) {
      setAssetKind(
        _.capitalize(pathname.split('/')[1].replace('parent-', '').replace('-details', ''))
      );
    }
  }, [asset]);

  const assetsList = useMemo(() => {
    return [
      { icon: <Icon name={'Site'} />, title: 'On site' },
      { icon: <Icon name={'off-site'} />, title: 'Off site' }
    ];
  }, []);

  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      location: '',
      area: ''
    }
  });

  useEffect(() => {
    if (asset) {
      setAssetKind(
        _.capitalize(pathname.split('/')[1].replace('parent-', '').replace('-details', ''))
      );
    }
  }, [asset]);

  const hseCategoriesList = useMemo(() => {
    return inspection?.incidentCategory?.map((item) => {
      return {
        value: item?.label,
        key: item?.value
      };
    });
  }, [inspection?.incidentCategory]);

  const disabledBtn = useMemo(() => {
    return watch('location') ? false : true;
  }, [watch('location')]);

  const formId = useMemo(() => {
    if (inspectionFormName && inspectionTypes)
      return (
        inspectionTypes?.find((item) => item?.value === inspectionFormName && item.incident)?.key ||
        ''
      );
    else return '';
  }, [inspectionFormName, inspectionTypes]);

  const onSubmitHandler = (data) => {
    setAddNew('');
    setActive(false);
    setTimeout(() => {
      navigate(
        `/work-order/?from=${location.pathname}&type=new-hse&inspectionType=${formId}&location=${data?.location}&areaId=${data?.area}`
      );
    }, 0);
  };

  const onSubmitOffSiteForm = (event) => {
    event.preventDefault();
    event.stopPropagation();
    handleSubmit(onSubmitHandler)(event);
  };

  const onSiteForm =
    assetKind === 'On site'
      ? [
          {
            title: `Find and select the site.`,
            content: (
              <SearchTabList
                label={'Site'}
                onSelect={(id) => {
                  setAddNew('');
                  setActive(false);
                  setTimeout(() => {
                    navigate(
                      `/work-order/?from=${location.pathname}&type=new-hse&inspectionType=${formId}&siteId=${id}`
                    );
                  }, 0);
                }}
              />
            )
          }
        ]
      : assetKind === 'Off site'
      ? [
          {
            title: `Gotcha. It took place off-site.`,
            content: (
              <>
                <div className={`wizard-steps--title ${styles.subTitle}`}>Where?</div>
                <form onSubmit={onSubmitOffSiteForm} id="addPhoto">
                  <div className={styles.offSiteForm}>
                    <div className="op-input-field-wrap">
                      <InputField
                        label={'Other location (description)'}
                        dot={true}
                        type={'text'}
                        inputClass="input-field input-regular"
                        register={register}
                        name={'location'}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: '16px'
                      }}
                    >
                      <HierarchicalDropdown
                        options={areaHierarchy || []}
                        label={'Area'}
                        name={'area'}
                        setValue={setValue}
                      />
                    </div>
                    <div className={styles.confirmBtn}>
                      <Button
                        btnClass="btn-fill button-large"
                        type="submit"
                        form="addPhoto"
                        text="CONFIRM"
                        disabled={disabledBtn}
                      />
                    </div>
                  </div>
                </form>
              </>
            )
          }
        ]
      : [{}];

  const hseSelectEvent = (item, formName) => {
    if (asset && pathname?.split('/')[2]) {
      const hseFormId =
        inspectionTypes?.find((item) => item?.value === formName && item.incident)?.key || '';
      const siteId = pathname?.split('/')[2];
      setAddNew('');
      setActive(false);
      setTimeout(() => {
        navigate(
          `/work-order/?from=${location.pathname}&type=new-hse&inspectionType=${hseFormId}&siteId=${siteId}`
        );
      }, 0);
    } else {
      setHSECategoryId(item);
      setInspectionFormName(formName);
      setStep((prev) => prev + 1);
    }
  };

  const steps = [
    {
      title: 'What kind of HSE event?',
      content: (
        <SearchList
          list={hseCategoriesList || []}
          onSelect={hseSelectEvent}
          searchClassName={styles.searchClassName}
          search={false}
        />
      )
    },
    ...(!asset
      ? [
          {
            title: 'Where did the HSE event take place?',
            content: (
              <AssetsKindList
                list={assetsList}
                onSelect={(item) => {
                  setAssetKind(item);
                  setStep((prev) => prev + 1);
                }}
              />
            )
          }
        ]
      : []),

    ...onSiteForm,
    {
      title: 'New HSE Added Successfully.',
      content: ''
    }
  ];

  const onClickStep = (step) => {
    if (step === 0) {
      setHSECategoryId('');
      setInspectionFormName('');
    }
    if (step == 1) setAssetKind('');
    setStep(step);
  };

  return (
    <div>
      <WizardSteps
        current={step}
        setStep={onClickStep}
        total={steps.length - 1}
        title={steps[step].title}
        content={steps[step].content}
        breadcrumbs={
          hseCategoryId && inspectionFormName
            ? [inspectionFormName?.toString(), assetKind || 'Asset type']
            : ['HSE Event']
        }
        notShowSteps={!asset}
      />
    </div>
  );
};

export default AddNewHSEEvents;
