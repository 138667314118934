/* eslint-disable no-unused-vars */

import _ from 'lodash';
export const getPendingTaskAndPhotosLength = ({
  pending,
  pendingInspections,
  pendingHseEvents,
  pendingChecks,
  syncFailedAssets
}) => {
  let photos = 0,
    documents = 0,
    workOrder = 0,
    workRequest = 0,
    inspection = 0,
    hseEvents = 0,
    checks = 0,
    inspectionPhotoCount = 0,
    workOrderPhotoCount = 0,
    workRequestPhotoCount = 0,
    incidentPhotoCount = 0,
    inspectionDocumentCount = 0,
    workOrderDocumentCount = 0,
    workRequestDocumentCount = 0,
    incidentDocumentCount = 0,
    checksPhotoCount = 0,
    checksDocumentCount = 0;

  pending.map((task) => {
    if (task?.photos?.length)
      photos += task?.photos?.filter((x) => !_.isEmpty(x?.content))?.length || 0;

    if (task?.documents?.length)
      documents += task?.documents?.filter((x) => !_.isEmpty(x?.content))?.length || 0;

    if (!task?.newWorkRequest) {
      ++workOrder;

      workOrderPhotoCount += task?.photos?.length;

      workOrderDocumentCount += task?.documents?.length;
    }
    if (task?.newWorkRequest) {
      ++workRequest;
      workRequestPhotoCount += task?.photos?.length;

      workRequestDocumentCount += task?.documents?.length;
    }
    return task;
  });
  pendingInspections?.map((ins) => {
    const inspectionPhotosAndDocuments = getPhotosAndDocumentsDataFromSelectedInspection(
      ins?.formData || {}
    );
    if (ins?.type === 'Inspection') ++inspection;
    if (inspectionPhotosAndDocuments?.photos?.length) {
      photos += inspectionPhotosAndDocuments?.photos?.length;
      inspectionPhotoCount += inspectionPhotosAndDocuments?.photos?.length;
    }
    if (inspectionPhotosAndDocuments?.documents?.length) {
      documents += inspectionPhotosAndDocuments?.documents?.length;
      inspectionDocumentCount += inspectionPhotosAndDocuments?.documents?.length;
    }
    return ins;
  });

  pendingChecks?.map((check) => {
    const checksPhotosAndDocuments = getPhotosAndDocumentsDataFromSelectedInspection(
      check?.formData || {}
    );

    if (check?.type === 'Check') ++checks;
    if (checksPhotosAndDocuments?.photos?.length) {
      photos += checksPhotosAndDocuments?.photos?.length;
      checksPhotoCount += checksPhotosAndDocuments?.photos?.length;
    }
    if (checksPhotosAndDocuments?.documents?.length) {
      documents += checksPhotosAndDocuments?.documents?.length;
      checksDocumentCount += checksPhotosAndDocuments?.documents?.length;
    }
    return check;
  });

  pendingHseEvents?.map((hse) => {
    const hsePhotosAndDocuments = getPhotosAndDocumentsDataFromSelectedInspection(
      hse?.formData || {}
    );

    if (hse?.type === 'HSE') ++hseEvents;
    if (hsePhotosAndDocuments?.photos?.length) {
      photos += hsePhotosAndDocuments?.photos?.length;
      incidentPhotoCount += hsePhotosAndDocuments?.photos?.length;
    }
    if (hsePhotosAndDocuments?.documents?.length) {
      documents += hsePhotosAndDocuments?.documents?.length;
      incidentDocumentCount += hsePhotosAndDocuments?.documents?.length;
    }
    return hse;
  });

  return {
    documents,
    photos,
    workOrder,
    workRequest,
    inspection,
    inspectionPhotos: 0,
    hseEvents,
    checks,
    inspectionPhotoCount,
    workOrderPhotoCount,
    workRequestPhotoCount,
    incidentPhotoCount,
    inspectionDocumentCount,
    workOrderDocumentCount,
    workRequestDocumentCount,
    incidentDocumentCount,
    checksPhotoCount,
    checksDocumentCount,
    failedImageCount: syncFailedAssets?.photos?.length || 0,
    failedDocumentCount: syncFailedAssets?.documents?.length || 0,

    pendingSync: workOrder + workRequest + inspection + hseEvents + checks
  };
};

const getPhotosAndDocumentsDataFromSelectedInspection = (obj) => {
  const photos = [],
    documents = [];
  let passFailTrue = 0,
    passFailFalse = 0,
    passFailNA = 0;

  const transformNestedObjects = (nestedObj) => {
    for (let key in nestedObj) {
      if (typeof nestedObj[key] === 'object' && nestedObj[key] !== null) {
        if ('photos' in nestedObj[key] && 'documents' in nestedObj[key]) {
          nestedObj[key].photos?.map((photo) => photos.push(photo));
          nestedObj[key].documents?.map((document) => documents.push(document));
        } else if ('photos' in nestedObj[key]) {
          if (nestedObj[key].photos && nestedObj[key]?.photos?.length) {
            nestedObj[key].photos?.map((photo) => photos.push(photo));
          }
        } else if ('documents' in nestedObj[key]) {
          if (nestedObj[key].documents && nestedObj[key].documents?.length) {
            nestedObj[key].documents?.map((document) => documents.push(document));
          }
        } else if ('answer' in nestedObj[key]) {
          if (nestedObj[key]?.answer === 'true') ++passFailTrue;
          if (nestedObj[key]?.answer === 'false') ++passFailFalse;
          if (nestedObj[key]?.answer === 'NA') ++passFailNA;
        }
      }
    }
  };
  const transformWorkOrder = (a) => {
    for (let i = 0; i < a.length; i++) {
      let item = a[i];
      item?.photo?.map((photo) => photos.push(photo));
      item?.document?.map((document) => documents.push(document));
    }
  };

  for (let key in obj) {
    if (key === 'work order') {
      const a = obj[key];
      for (let key1 in a) {
        transformWorkOrder(a[key1]);
      }
    } else transformNestedObjects(obj[key]);
  }

  return {
    photos,
    documents,
    passFailTrue,
    passFailNA,
    passFailFalse
  };
};
