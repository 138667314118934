import moment from 'moment';

import { HomeSelector } from 'selectors';
import { createSlice } from '@reduxjs/toolkit';

const { getGreetingsText, getImagesName, getMotivationalText } = HomeSelector;

const initialState = {
  initialCache: false,
  greetings: {
    date: null,
    imageIndex: 0,
    greetingTextIndex: 0,
    motivationTextIndex: 0
  }
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setInitialCache: (state, action) => {
      state.initialCache = action.payload;
    },
    setGreetings: (state) => {
      const today = moment().format('DD/MM/YYYY');
      const { date, greetingTextIndex, imageIndex, motivationTextIndex } = state.greetings;

      if (date !== today) {
        let randomImage = Math.floor(Math.random() * getImagesName().length);
        let randomGreeting = Math.floor(Math.random() * getGreetingsText().length);
        let randomMotivation = Math.floor(Math.random() * getMotivationalText().length);

        while (imageIndex === randomImage)
          randomImage = Math.floor(Math.random() * getImagesName().length);
        while (greetingTextIndex === randomGreeting)
          randomGreeting = Math.floor(Math.random() * getGreetingsText().length);
        while (motivationTextIndex === randomMotivation)
          randomMotivation = Math.floor(Math.random() * getMotivationalText().length);

        state.greetings = {
          date: today,
          imageIndex: randomImage,
          greetingTextIndex: randomGreeting,
          motivationalTextIndex: randomMotivation
        };
      }
    }
  }
});

const homeReducer = homeSlice?.reducer;

export const homeActions = homeSlice.actions;
export default homeReducer;
