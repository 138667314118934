import React from 'react';

import InputField from '@components/input-field/input-field.component';

import style from './search.module.scss';

const SearchField = ({
  placeholder,
  onKeyDown,
  onChange,
  value,
  isClearable,
  clearFunc,
  register,
  name,
  ...restOfProps
}) => {
  return (
    <div className={style.positionClass}>
      <InputField
        type={'text'}
        className={style.field}
        placeholder={placeholder || ''}
        {...(value && { value })}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyDown}
        isClearable={isClearable}
        searchIcon={true}
        clearFunc={clearFunc}
        register={register}
        {...restOfProps}
      />
    </div>
  );
};

export default React.memo(SearchField);
