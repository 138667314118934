import React from 'react';

import { decodeToken } from '@utilities/authentication';

const UserPermissions = () => {
  const userPermissionsList = decodeToken()?.permissions || {};
  return (
    <div>
      {Object.keys(userPermissionsList)?.map((x, index) => (
        <div key={index}>
          {x}- {userPermissionsList[x] ? 'yes' : 'no'}
        </div>
      ))}
    </div>
  );
};

export default UserPermissions;
