/* eslint-disable no-unused-vars */

import rootReducer from './root-reducer';
import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist-indexeddb-storage';
import compressTransform from 'redux-persist-transform-compress';

import {
  FLUSH,
  PAUSE,
  PURGE,
  PERSIST,
  REGISTER,
  REHYDRATE,
  persistStore,
  persistReducer
} from 'redux-persist';

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage('my-oplii-test')
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      },

      immutableCheck: false
    })
});

export const persistor = persistStore(store);
