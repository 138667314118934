import PropTypes from 'prop-types';

import style from './style.module.scss';

const RadioGroup = ({ value = '', options, onChange, disabled }) => {
  return (
    <>
      <div className={style.radioitems}>
        {options.map(({ label, value: optionValue }) => (
          <div key={label} className={style['radio-circle']}>
            <label className={style['label-text']}>
              <input
                type="radio"
                value={optionValue}
                checked={optionValue === value}
                disabled={disabled}
                onChange={(e) => onChange(e.target.value)}
              />
              {label}
              <span className={style['radio-circle-inner']}></span>
            </label>
          </div>
        ))}
      </div>
    </>
  );
};

RadioGroup.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

export default RadioGroup;
