/* eslint-disable no-unused-vars */

import _ from 'lodash';
import moment from 'moment-timezone';

import { getTaskSiteAsset } from './site';
import { getDataPoints } from '../../pages/settings/helper';

export const getTaskStructureAsset = ({
  sitesMap,
  areaHierarchyTreeDataMap,
  siteDisplayFields,
  picklistsMap,
  cacheSites = [],
  appliesTo = false,
  task,
  structureMobileMap,
  structureMobile,
  structureDisplayFields,
  structureEquipmentMap,
  formWrapper,
  store
}) => {
  const taskStructure = task?.structures || [];
  const id = task?.primaryAssetId || task?.relatedAssetId || task?.structureId || null;
  const structureIds = id
    ? [id, ...taskStructure.filter((structureId) => structureId !== id)] || []
    : task?.structures;

  const sitesIds = [];
  const prepareTasksStructure = [];
  for (let structureId of structureIds) {
    const structureData = structureMobile?.[structureMobileMap?.[structureId]] || null;
    if (structureData) {
      const structureSiteId = structureData?.siteId || null;
      sitesIds.push(structureSiteId);
      const siteAsset = getTaskSiteAsset({
        sitesMap,
        siteId: structureSiteId,
        areaHierarchyTreeDataMap,
        siteDisplayFields,
        picklistsMap,
        cacheSites
      });
      const dataPoints = getDataPoints(
        { ...structureData },
        structureDisplayFields,
        'structure',
        picklistsMap
      );
      const completeData = appliesTo
        ? getStructureCompleteData({
            picklistsMap,
            structureId,
            status: structureData?.status,
            structureEquipmentMap,
            formWrapper,
            store
          })
        : {};

      prepareTasksStructure.push({
        ...structureData,
        structureId,
        name: structureData.name || '',
        structureType: picklistsMap[structureData.structureType]?.value || '',
        dataPoints,
        siteAsset:
          siteAsset && _.size(siteAsset.prepareTasksSites) > 0
            ? { ...siteAsset.prepareTasksSites[0], siteId: structureSiteId }
            : {},
        status: '',

        ...completeData
      });
    }
  }

  return {
    sitesIds,
    prepareTasksStructure
  };
};

export const getStructureAsset = ({
  task,
  picklistsMap,
  sitesMap,
  siteDisplayFields,
  areaHierarchyTreeDataMap,
  structureMobileMap,
  structureMobile,
  cacheSites,
  structureDisplayFields,
  store
}) => {
  const structureId = task?.structureId;
  const structureData = structureMobile?.[structureMobileMap?.[structureId]] || {};
  const structureSiteId = structureData?.siteId || null;

  const siteAsset = getTaskSiteAsset({
    sitesMap,
    siteId: structureSiteId,
    areaHierarchyTreeDataMap,
    siteDisplayFields,
    picklistsMap,
    cacheSites,
    store
  });

  const dataPoints = getDataPoints(
    { ...structureData },
    structureDisplayFields,
    'structure',
    picklistsMap
  );

  return {
    structureData: [
      {
        name: structureData.name || '',
        structureType: picklistsMap[structureData.structureType]?.value || '',
        dataPoints,
        siteAsset:
          siteAsset && _.size(siteAsset.prepareTasksSites) > 0
            ? { ...siteAsset.prepareTasksSites[0], siteId: structureSiteId }
            : {}
      }
    ]
  };
  // }
};

const getStructureCompleteData = ({
  structureId,
  picklistsMap,
  status,
  structureEquipmentMap,
  formWrapper,
  store
}) => {
  const { tasks } = store;

  const allWOTasks = !formWrapper
    ? tasks?.tasksList?.filter((task) => !task.newWorkOrder && !task.newWorkRequest)
    : [];
  const equipments = !formWrapper ? structureEquipmentMap[structureId] || [] : [];

  let allTasksForStructure = allWOTasks?.filter(
    (t) =>
      t.structureId === structureId ||
      t?.relatedAssetId === structureId ||
      t?.structures?.includes(structureId)
  );
  const allDoneTasks = allTasksForStructure?.filter((task) => task?.status === 'Synced');
  const allotDoneTasks = allTasksForStructure?.filter((task) => task?.status !== 'Synced');

  const today = moment().format('YYYY-MM-DD');
  const isOverDue = allotDoneTasks.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  return {
    childrenList: equipments,
    status: !formWrapper ? picklistsMap[status]?.value : null,
    allDoneTasks,
    isOverDue,
    allTasksForStructure,
    allotDoneTasks,
    soonestDueDate: _.min(
      allotDoneTasks.map((t) => t?.due || t?.dueDate).map((date) => new Date(date))
    )
  };
};
