/* eslint-disable no-unused-vars */
import React, { useEffect, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import TaskCard from '@components/task-card';
import NewTabs from '@components/new-tabs';
import VirtualList from '@components/virtual-list/virtual-list';

import { sortTasks } from './helper';
import { tasksListsActions as tasksListsActions } from '@redux/slices/tasks';
import { getAppDispatch } from '@utilities/dispatch.util';
import useWindowDimensions from '@hooks/useWindowDimensions';
import gridSystem from '@utilities/grid-system';

import './tasks-records-list.scss';
import style from './style.module.scss';

const TaskRecordListTabs = ({
  tab,
  asset,
  setTab,
  data,
  tasksMap,
  SelectedFilter,
  isFilterActive,
  searched,
  openModal,
  setData,
  setSelectedOrderIds,
  onSelectionNewWorkOrderOrRequest = null,
  white = false,
  lastElement,
  taskTemplateRecords = false,
  runs,
  workRequest = false,
  hseEvent = false,
  checkedLength = 0
}) => {
  const dispatch = getAppDispatch();
  const { width } = useWindowDimensions();
  const ref = React.useRef(null);

  const {
    sitesMap,
    equipmentsMap,
    equipments: cacheEquipments = [],
    sites: cacheSites = []
  } = useSelector((state) => state.cache) || {};
  const { selectTask } = useSelector((state) => state.tasks) || false;

  useEffect(() => {
    if (width <= 767) {
      if (_.size(data?.filter((x) => x?.checked)) > 0)
        dispatch?.(tasksListsActions.setSelectedTask(true));
      else dispatch?.(tasksListsActions.setSelectedTask(false));
    } else {
      return;
    }
  }, [data, width]);

  const tabsMenu = workRequest
    ? ['In progress', 'Pending sync']
    : hseEvent
    ? ['In progress', 'Pending sync', 'Synced']
    : ['To do', 'In progress', 'Pending sync', 'Synced'];

  const dataMap = useMemo(() => {
    if (data?.length > 0) {
      return data?.reduce((acc, curr, currIndex) => {
        acc[curr.id] = currIndex;
        return acc;
      }, {});
    }
  }, [data]);

  const onSelectionCard = (checked, i1, id, dataMap1) => {
    const copy = [...data];
    const i = dataMap1[id];
    copy[i].checked = checked;

    const type = copy[i]?.woId
      ? copy[i]?.newWorkOrder
        ? 'new-work-order'
        : copy[i]?.newWorkRequest
        ? 'new-work-request'
        : ''
      : runs?.length
      ? 'inspection'
      : 'work-orders';
    if (type === 'work-orders' || type === 'inspection') {
      if (checked) {
        setSelectedOrderIds((prev) => {
          return [...prev, copy[i].id];
        });
      } else {
        setSelectedOrderIds((prev) => {
          let _prev = [...prev];
          return _prev?.filter((x) => x !== copy[i].id);
        });
      }
    } else {
      if (checked) {
        setSelectedOrderIds((prev) => {
          return [...prev, copy[i].woId];
        });
      } else {
        setSelectedOrderIds((prev) => {
          let _prev = [...prev];
          return _prev?.filter((x) => x !== copy[i].woId);
        });
      }
    }

    setData([...copy]);
  };

  const taskMap = ({ task, i, item, checked, lastItem = false }) => {
    const woId = task.woId || task.workorderId || task.inspectableItemId || task?.id;
    const findIndexNumber = data?.findIndex(
      (d) =>
        d?.workorderId === woId || d?.woId === woId || d?.inspectableItemId == woId || d?.id == woId
    );
    if (findIndexNumber >= 0) i = i = findIndexNumber;
    const fragKey = 'frag-' + woId;
    const colKey = 'col-' + woId;
    return (
      <div key={fragKey}>
        {tasksMap[task.id] && task.status === item && (
          <div
            key={colKey}
            style={{
              width: '100%',
              marginBottom: lastItem ? '85px' : ''
            }}
            ref={ref}
          >
            <TaskCard
              {...task}
              checkedLength={checkedLength}
              selection={checked}
              searched={searched}
              openModal={() => {
                const id =
                  task?.type === 'Inspection'
                    ? task?.id || task?.inspectableItemId
                    : task?.woId || task?.id;
                openModal({
                  id,
                  type:
                    task?.type === 'New Work Request' ||
                    task?.type === 'New Work order' ||
                    task?.type === 'New Work Order' ||
                    task?.type === 'Work Order'
                      ? 'Work Order'
                      : task?.type === 'HSE'
                      ? 'HSE'
                      : 'Inspection',
                  currentTask: task
                });
              }}
              onSelection={(checked) =>
                onSelectionCard(
                  checked,
                  i,
                  task?.type === 'Inspection'
                    ? task?.id || task?.inspectableItemId
                    : task?.woId || task?.id,
                  dataMap
                )
              }
              tab={tab}
              SelectedFilter={SelectedFilter}
              onSelectionNewWorkOrderOrRequest={onSelectionNewWorkOrderOrRequest}
            />
          </div>
        )}
      </div>
    );
  };

  const handleScroll = (e) => {
    let virtualListScroll = document.querySelectorAll('.cstmScrollerRef');
    const siteDetailsPage = document.querySelector('#assetDetailPage');
    const assetDetailsWorkOrderAndInspections = document.querySelector(
      '#workOrderAndInspectionsListings'
    );

    for (let i = 0; i < virtualListScroll.length; i++) {
      virtualListScroll[i]?.classList.remove('removeScrollbar');
      virtualListScroll[i]?.classList.add('addScrollbar');
      clearTimeout();
      setTimeout(function () {
        virtualListScroll[i]?.classList.remove('addScrollbar');
        virtualListScroll[i]?.classList.add('removeScrollbar');
      }, 1000);
    }

    if (
      virtualListScroll[0].scrollTop === 0 &&
      siteDetailsPage &&
      assetDetailsWorkOrderAndInspections
    ) {
      siteDetailsPage.scrollTop = 0;
      assetDetailsWorkOrderAndInspections.classList.remove(
        'assetDetailsWorkOrderAndInspectionsFixed'
      );
      assetDetailsWorkOrderAndInspections.classList.add(
        'assetDetailsWorkOrderAndInspectionsNotFixed'
      );
    }
  };

  const isGrid = gridSystem({
    xs: 24,
    md: isFilterActive ? 24 : 12,
    lg: isFilterActive ? 12 : 8,
    xxl: isFilterActive ? 8 : 6
  });

  const items =
    tabsMenu &&
    tabsMenu?.map((item) => {
      const listForRendering =
        item === 'To do'
          ? data?.filter((task) => tasksMap[task.id] && task.status === 'To do')
          : item === 'In progress'
          ? data?.filter((task) => tasksMap[task.id] && task.status === 'In progress')
          : item === 'Pending sync'
          ? data?.filter((task) => tasksMap[task.id] && task.status === 'Pending sync')
          : item === 'Synced'
          ? data?.filter((task) => tasksMap[task.id] && task.status === 'Synced')
          : data?.filter((task) => tasksMap[task.id] && task.status === 'To do');

      return {
        label: `${item} (${listForRendering.length})`,
        children: (
          <div
            id="tasksRecordsListsTabs"
            className={`${asset ? 'no-scroller1' : `removeScrollbar`} ${
              selectTask ? '' : 'taskHeight'
            } ${taskTemplateRecords && style.templateScrollbar} `}
            style={{
              backgroundColor: 'transparent',
              height: asset
                ? 'calc(100vh - 162px)'
                : taskTemplateRecords && !lastElement
                ? 'calc(100vh - 94px)'
                : taskTemplateRecords && lastElement
                ? 'calc(100vh - 94px)'
                : 'calc(100vh - 94px)'
            }}
          >
            {listForRendering?.length > 0 ? (
              <VirtualList
                data={listForRendering
                  ?.sort((a, b) => {
                    return sortTasks({
                      a,
                      b,
                      SelectedFilter,
                      sitesMap,
                      equipmentsMap,
                      item,
                      cacheEquipments,
                      cacheSites
                    });
                  })
                  .map(({ checked, ...task }, i) => {
                    const lastItem = i === listForRendering?.length - 1;
                    return { task, i, item, checked, lastItem };
                  })}
                component={taskMap}
                componentProps={{}}
                isGrid={isGrid}
                onScrollEvent={handleScroll}
                gutter={16}
              />
            ) : (
              <div
                className="no-result"
                style={{ flex: 1, justifyContent: 'flex-start', gap: '20px' }}
              >
                <div>No results</div>
              </div>
            )}
          </div>
        )
      };
    });
  return (
    <NewTabs
      active={tab}
      tabs={items}
      setActive={(tab) => setTab(tab)}
      navClassName="taskNavbarBackground"
      isFilterActive={isFilterActive}
      white={white}
    />
  );
};

export default TaskRecordListTabs;
