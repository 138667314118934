import _ from 'lodash';
import { filterSites } from '../../components/site-listing/helper';
import { attachMeta } from 'selectors/sites/attach-meta';

const siteBookMarkCount = ({
  filters,
  sites,
  sort,
  areaHierarchyTreeDataMap,
  equipmentId,
  pipelineId,
  structureId,
  parentSiteId
}) => {
  const { filtered: filterSitesList = [] } = filterSites({
    filters: { ...filters?.filters },
    sites,
    SelectedFilter: Object.keys(sort)[0],
    areaHierarchyTreeDataMap
  });
  const countSites =
    filterSitesList.filter((t) => {
      if (equipmentId) {
        // Filter tasks by equipmentId
        return (
          t.equipmentId === equipmentId ||
          t?.relatedAssetId === equipmentId ||
          t?.primaryAssetId === equipmentId ||
          t?.equipments?.includes(equipmentId)
        );
      } else if (pipelineId) {
        // Filter tasks by pipelineId
        return (
          t.pipelineId === pipelineId ||
          t?.relatedAssetId === pipelineId ||
          t?.primaryAssetId === pipelineId ||
          t?.pipelines?.includes(pipelineId)
        );
      } else if (structureId) {
        // Filter tasks by structureId
        return (
          t.structureId === structureId ||
          t?.relatedAssetId === structureId ||
          t?.primaryAssetId === structureId ||
          t?.structures?.includes(structureId)
        );
      } else if (parentSiteId) return t.parentSiteId === parentSiteId;
      else {
        return true;
      }
    }) || filterSitesList;

  return {
    count: countSites?.length || 0,
    filters: encodeURIComponent(JSON.stringify(filters))
  };
};

const getSiteTodoAndInprogressTasks = ({ siteId = '', tasksList = [] }) => {
  let tasks = tasksList.filter(
    (t) => t?.siteId === siteId || t?.relatedAssetId === siteId || t?.sites?.includes(siteId)
  );

  tasks = _.size(_.flattenDeep(tasks)) > 0 ? _.flattenDeep(tasks) : [];
  const countTodoAndInProgressTasksOfSite = _.size(
    tasks.filter((task) => task?.status === 'To do' || task.status === 'In progress') || []
  );

  return countTodoAndInProgressTasksOfSite;
};

const getParentSiteTodoAndInprogressTasks = async ({
  siteId = '',
  sitesMap = {},
  cacheSites = [],
  tasksList = [],
  sitesEquipmentsMap,
  equipmentsMap,
  cacheEquipments
}) => {
  const parentSite = cacheSites[sitesMap[siteId]] || {};
  const { children = [] } = parentSite;
  const parentSiteChildren = children?.map((childIndex) => {
    return { ...cacheSites[childIndex], children: [] };
  });

  let childrenData = [];
  if (parentSite?.children?.length) {
    childrenData = attachMeta(
      parentSiteChildren,
      sitesEquipmentsMap,
      equipmentsMap,
      tasksList,
      cacheEquipments
    );
  }

  let allTasksForSite = [
    ...tasksList.filter(
      (t) =>
        t?.siteId === parentSite?.siteId ||
        t?.relatedAssetId === parentSite?.siteId ||
        t?.primaryAssetId === parentSite?.siteId ||
        t?.sites?.includes(parentSite?.siteId)
    ),
    ...(childrenData?.map((t) => t.allTasksForSite).flat(2) || [])
  ];
  const countTodoAndInProgressTasksOfSite = _.size(
    allTasksForSite?.filter((task) => task?.status === 'To do' || task.status === 'In progress') ||
      []
  );

  return countTodoAndInProgressTasksOfSite;
};

export { siteBookMarkCount, getSiteTodoAndInprogressTasks, getParentSiteTodoAndInprogressTasks };
