/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import BookMarkModal from '@components/modal/variants/bookmark-modal';
import InputField from '@components/input-field/input-field.component';
import Checkbox from '@components/checkbox/checkbox.component';

import BookmarkService from '@services/bookmarks.service';
import { useToastContext } from 'context/toaster.context';

import style from './style.module.scss';

const StructureBookmark = ({
  open,
  setOpen,
  placeholder = 'Flux capacitor repairs',
  filters = {},
  selectedSort = {},
  type = 'Structure',
  structureId = null,
  siteId,
  keyName,
  tab
}) => {
  const [bookmarkName, setBookmarkName] = useState('');
  const [pinToGlance, setPinToGlance] = useState(false);
  const userId = useSelector((state) => state?.auth?.userId) || '';
  const location = useLocation();

  const { ToasterUtil } = useToastContext();

  const onSubmit = async () => {
    const sort = !_.isEmpty(selectedSort)
      ? _.isArray
        ? { [selectedSort[0]]: true }
        : selectedSort
      : {};

    const filtersString = {
      filters,
      sort,
      structureId,
      siteId
    };

    const siteBookmarkData = {
      userId,
      name: bookmarkName,
      type,
      atAGlance: pinToGlance,
      filters: JSON.stringify(filtersString),
      listing: location?.pathname || '/',
      structureId,
      keyName,
      tab
    };
    ToasterUtil.showSuccess(`Bookmark saved`);
    await BookmarkService.addBookmarks(siteBookmarkData);
    resetValue();
  };

  const resetValue = () => {
    setOpen(false);
    setBookmarkName('');
    setPinToGlance(false);
  };

  return (
    <BookMarkModal
      open={open}
      setOpen={setOpen}
      onSubmit={onSubmit}
      statusText={'Name that bookmark.'}
      disabledBtn={_.isEmpty(bookmarkName)}
      modalTextUpper={'Give your bookmark a short, descriptive name.'}
      modalTextLower={() => (
        <>
          <InputField
            placeholder={placeholder}
            limit={40}
            onChange={(e) => {
              const value = e?.target?.value;
              if (value.length === 0 || (value.length >= 1 && value[0] !== ' ')) {
                setBookmarkName(value);
              }
            }}
            value={bookmarkName}
            defaultValue={bookmarkName}
          />
          <p className="label-bold text-right text-grey-400 mt-1">{bookmarkName?.length || 0}/40</p>
        </>
      )}
      progressData={() => (
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
          <Checkbox
            text={'Pin to ‘At a glance’ on Home'}
            checked={pinToGlance}
            handleChange={(e) => setPinToGlance(e?.target?.checked)}
            textTransformNone
            labelClassName={style.equipmentBookmarkLabel}
            checkMarkAfter={style.equipmentBookmarkCheckbox}
          />
        </div>
      )}
      textBtn={'NEVERMIND'}
    />
  );
};

export default StructureBookmark;
