import React, { memo, forwardRef } from 'react';
import Textarea from '@components/textarea';
import style from '../workorder.module.scss';

const FormNotes = ({ errorsMsg, removeSpecialCharactersForName, field, name, register }, ref) => {
  let errorMessage = errorsMsg?.message || '';
  const { refs } = ref;

  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
    >
      <Textarea
        dot={field?.isRequired}
        label={`${field?.label}`}
        inputClass="input-field input-regular"
        labelColor={style.labelColor}
        register={register}
        name={name}
        error={errorMessage}
      />
    </div>
  );
};

export default memo(forwardRef(FormNotes));
