/* eslint-disable no-unused-vars */
import React, { memo, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Icon from '@components/icon-component';

import { getAppDispatch } from '@utilities/dispatch.util';
import { formWrapperActions } from '@redux/slices/form-wrapper';

import style from '../task.module.scss';

const OpenLink = ({
  openLink,
  taskId,
  assetId,
  desktopLink,
  setOpenLink,
  setDesktopLink,
  selectedLinkTypeIcon,
  selectedLinkTypeName
}) => {
  const location = useLocation();
  const dispatch = getAppDispatch?.();

  const { linkIsOpen } = useSelector((state) => state.formWrapper);

  const openLinkModal = useMemo(() => {
    const cond =
      taskId && assetId
        ? taskId === linkIsOpen?.taskId && assetId === linkIsOpen?.assetId && openLink
          ? true
          : false
        : openLink;
    return cond;
  }, [taskId, assetId, linkIsOpen?.taskId, linkIsOpen?.assetId, desktopLink, setOpenLink]);

  useEffect(() => {
    setOpenLink(openLinkModal);
  }, [openLinkModal]);

  return (
    <>
      {openLinkModal && desktopLink?.linkValue && (
        <div
          id="openLink"
          className={style.showLink}
          onClick={(e) => {
            e?.stopPropagation();

            if (location.pathname === '/work-order/') {
              dispatch?.(formWrapperActions.setAssetOpliiDesktopLink(desktopLink?.linkValue));
            } else {
              desktopLink?.linkValue?.includes('site-details') ||
              desktopLink?.linkValue?.includes('equipment-details') ||
              desktopLink?.linkValue?.includes('pipeline-details') ||
              desktopLink?.linkValue?.includes('structure-details')
                ? window.open(desktopLink?.linkValue, '_self')
                : window.open(desktopLink?.linkValue, '_blank');
            }

            setOpenLink((prev) => !prev);
            setDesktopLink(null);
          }}
        >
          <div>
            <Icon name={selectedLinkTypeIcon} />
            <p>Go to {selectedLinkTypeName}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(OpenLink);
