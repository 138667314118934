/* eslint-disable no-unused-vars */

import _ from 'lodash';

import { createSlice } from '@reduxjs/toolkit';
import { getDistance } from '@utilities/get-distance';
import { checkOldAndNewArray } from '@services/check-old-and-new-array';

const initialState = {
  unit: '',
  loading: true,
  pendingSync: 0,
  prevPendingSync: 0,
  coordinates: null,
  sites: [],
  sitesMap: {},
  sitesLoading: true,
  equipments: [],
  equipmentsMap: {},
  sitesEquipmentsMap: {},
  equipmentsLoading: false,
  caching: false,
  cachingComplete: false,
  cachingFailure: false,
  isSyncing: false,
  isSyncingModal: false,
  workOrderInspectionsAndChecksIsSync: false,

  sitesWithoutMobile: [],
  sitesWithoutMobileMap: {},
  equipmentsWithoutMobile: [],
  equipmentsWithoutMobileMap: {},
  equipmentsCache: [],
  equipmentsCacheMap: {},
  equipmentElectrical: [],
  equipmentElectricalMap: {},
  equipmentPsvs: [],
  equipmentPsvsMap: {},
  equipmentPumpJacks: [],
  equipmentPumpJacksMap: {},
  equipmentVehicles: [],
  equipmentVehiclesMap: {},
  refreshingAndSyncingData: true,
  syncReminder: false,
  lastSuccessFullRefreshTime: '',
  startTaskCoordinates: {},
  routes: [],
  currentTaskId: ''
};

export const cacheSlice = createSlice({
  name: 'cache',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setCurrentTaskId: (state, action) => {
      state.currentTaskId = action.payload;
    },
    setWorkOrderInspectionsAndChecksIsSync: (state, action) => {
      state.workOrderInspectionsAndChecksIsSync = action.payload;
    },
    setSitesLoading: (state, action) => {
      state.sitesLoading = action.payload;
    },
    startCaching: (state) => {
      state.caching = true;
      state.cachingComplete = false;
      state.cachingFailure = false;
      localStorage.setItem('cachingFailure', false);
    },
    completeCaching: (state) => {
      state.caching = false;
      state.cachingComplete = true;
      state.cachingFailure = false;
    },
    failureCaching: (state) => {
      state.caching = false;
      state.cachingComplete = false;
      state.cachingFailure = true;
      localStorage.setItem('cachingFailure', true);
    },
    setLastSuccessFullRefreshTime: (state, action) => {
      state.lastSuccessFullRefreshTime = action.payload;
    },
    closeCachingComplete: (state) => {
      state.caching = false;
      state.cachingComplete = false;
      state.cachingFailure = false;
    },
    updateSyncing: (state, action) => {
      state.isSyncing = action.payload;
    },
    updateSyncingModal: (state, action) => {
      state.isSyncingModal = action.payload;
    },
    updateSyncingReminder: (state, action) => {
      state.syncReminder = action.payload;
    },
    updateRefreshingAndSyncingData: (state, action) => {
      state.refreshingAndSyncingData = action.payload;
    },
    setEquipmentLoading: (state, action) => {
      state.equipmentsLoading = action.payload;
    },
    setUnitAndCoordinates: (state, action) => {
      const { unit, coordinates } = action.payload;
      state.unit = unit;
      state.coordinates = coordinates;
    },
    setPendingSync: (state, action) => {
      state.pendingSync = action.payload;
    },
    setPrevPendingSync: (state, action) => {
      state.prevPendingSync = action.payload;
    },
    setSitesMobile: (state, action) => {
      const { sites, sitesMap } = sitesMobileData(action.payload || state.sites || [], state);
      state.sites = sites;
      state.sitesMap = sitesMap;
      if (action.payload) state.sitesLoading = false;
    },

    setSites: (state, action) => {
      state.sitesWithoutMobile = action.payload || [];
      state.sitesWithoutMobileMap = action.payload.reduce((acc, curr) => {
        acc[curr.siteId] = curr;
        return acc;
      }, {});
    },
    setEquipments: (state, action) => {
      const { equipments, equipmentsMap, sitesEquipmentsMap } = action.payload || {};
      state.equipments = equipments;
      state.equipmentsLoading = false;
      state.equipmentsMap = equipmentsMap;
      state.sitesEquipmentsMap = sitesEquipmentsMap;
    },

    setEquipmentsWithoutMobile: (state, action) => {
      state.equipmentsWithoutMobile = action.payload;
      state.equipmentsWithoutMobileMap = action.payload.reduce((acc, curr) => {
        acc[curr.equipmentId] = curr;
        return acc;
      }, {});
    },

    setEquipmentsCache: (state, action) => {
      state.equipmentsCache = action.payload || [];
      state.equipmentsCacheMap = action.payload.reduce((acc, curr) => {
        acc[curr.equipmentId] = curr;
        return acc;
      }, {});
    },

    setEquipmentsElectrical: (state, action) => {
      state.equipmentElectrical = action.payload || [];
      state.equipmentElectricalMap = action.payload.reduce((acc, curr) => {
        acc[curr.equipmentId] = curr;
        return acc;
      }, {});
    },
    setEquipmentsPsvs: (state, action) => {
      state.equipmentPsvs = action.payload || [];
      state.equipmentPsvsMap = action.payload.reduce((acc, curr) => {
        acc[curr.equipmentPsvId] = curr;
        return acc;
      }, {});
    },
    setEquipmentsPumpJacks: (state, action) => {
      state.equipmentPumpJacks = action.payload || [];
      state.equipmentPumpJacksMap = action.payload.reduce((acc, curr) => {
        acc[curr.equipmentId] = curr;
        return acc;
      }, {});
    },
    setEquipmentsVehicles: (state, action) => {
      state.equipmentVehicles = action.payload || [];
      state.equipmentVehiclesMap = action.payload.reduce((acc, curr) => {
        acc[curr.equipmentId] = curr;
        return acc;
      }, {});
    },
    setStartTaskCoordinates: (state, action) => {
      const { coordinates } = action.payload;
      state.startTaskCoordinates = coordinates;
    }
  }
});

const cacheReducer = cacheSlice.reducer;

export const cacheActions = cacheSlice.actions;
export default cacheReducer;

export const sitesMobileData = (sitePayloadData = [], state) => {
  const arr = [];
  const { unit, coordinates } = state;
  let sites = sitePayloadData;

  const sitesMap = sites.reduce((acc, curr, currIndex) => {
    const { siteId } = curr;
    acc[siteId] = currIndex;
    return acc;
  }, {});

  for (const key in sitesMap) {
    let distance = null;
    const { latitude, longitude } = sites[sitesMap[key]] || {};
    if (unit && coordinates && latitude !== null && longitude !== null)
      distance = getDistance({ unit, from: coordinates, to: { longitude, latitude } });
    const index = sitesMap[key];
    const data = {
      ...sites[index],
      unit,
      distance: _.isNaN(distance) ? null : distance,
      children: [],
      index: index
    };
    arr.push(data);
  }

  arr.forEach((data) => {
    if (Object.hasOwnProperty.call(sitesMap, data.siteId)) {
      const { parentSiteId } = data;

      const parentSiteIndex = arr.findIndex((d) => d.siteId === parentSiteId);

      if (parentSiteIndex) arr[parentSiteIndex]?.children.push(data?.index);
    }
  });

  return {
    sites: arr,
    sitesMap
  };
};
