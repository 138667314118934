/* eslint-disable no-unused-vars */

import React, { useMemo, useState } from 'react';
import _ from 'lodash';

import DataSnippets from '@components/data-snippet';
import OpenLink from './open-link';
import TaskTally from '@components/task-tally';
import CardSite from './card-site';

import { getAppDispatch } from '@utilities/dispatch.util';
import { formWrapperActions } from '@redux/slices/form-wrapper';
import { useOpenLink } from '@hooks/useOpenLink';

import style from '../task.module.scss';

const openLinkDefaultValue = {
  linkValue: '',
  pipeline: false,
  equipment: false,
  site: false,
  structure: false
};

const CardEquipment = ({
  showValue = false,
  equipmentData,
  equipmentsIds,
  highlight = '',
  variant = '',
  divider = false,
  formWrapper,
  showNotIcon = false,
  className,
  chip = true,
  equipmentAssetFilterData,
  setShowEquipments,
  taskId,
  assetId
}) => {
  const [desktopLink, setDesktopLink] = useState(openLinkDefaultValue);
  const [openLink, setOpenLink] = useOpenLink({
    taskId,
    assetId
  });

  const dispatch = getAppDispatch?.();

  let {
    title,
    subtitle,
    iconName,
    siteId,
    siteAsset,
    equipmentId,
    status,
    children,
    allTasksForSite,
    allDoneTasks,
    isOverDue
  } = equipmentData;

  const dataPoints = useMemo(() => {
    return formWrapper
      ? equipmentData?.dataPoints?.filter((x) => x?.Id === 'SerialNumber')
      : equipmentData?.dataPoints;
  }, [formWrapper, equipmentData?.dataPoints]);

  const onClickOpliiDesktopLinkEvent = ({
    linkValue,
    pipeline = false,
    equipment = false,
    site = false,
    structure = false
  }) => {
    dispatch?.(
      formWrapperActions.checkTheLinkIsOpen({
        taskId: taskId,
        assetId: assetId
      })
    );
    setDesktopLink({
      linkValue,
      pipeline,
      equipment,
      site,
      structure
    });

    setOpenLink((prev) => !prev);
  };

  return (
    <>
      <div
        style={{
          position: 'relative'
        }}
      >
        <DataSnippets
          variant={variant}
          backFlex={style.backClass}
          title={title}
          subtitle={subtitle}
          dataPoints={dataPoints}
          iconName={!showNotIcon && (iconName || 'Equipment')}
          value={showValue && equipmentsIds.length > 1 ? `+${equipmentsIds.length - 1}` : null}
          setOpenAll={setShowEquipments}
          highlightNames={highlight}
          className={`${className}`}
          showNotIcon={showNotIcon}
          link={!formWrapper ? `/#/equipment-details/${equipmentData?.equipmentId}` : ''}
          onClickOpliiDesktopLinkEvent={(linkValue) =>
            onClickOpliiDesktopLinkEvent({ linkValue, equipment: true })
          }
        />
        <OpenLink
          openLink={openLink}
          taskId={taskId || ''}
          assetId={assetId || ''}
          desktopLink={desktopLink}
          setOpenLink={(val) => {
            setOpenLink(val);
          }}
          setDesktopLink={setDesktopLink}
          selectedLinkTypeIcon={'Equipment'}
          selectedLinkTypeName={'equipment'}
        />
      </div>

      {chip && (
        <div
          className={`${status && style.chipsDiv} ${
            _.size(equipmentAssetFilterData?.equipmentsIds) == 1
              ? siteId
                ? style['mb-16']
                : ''
              : style['mb-16']
          }  `}
        >
          {allTasksForSite?.length > 0 && (
            <TaskTally
              status={
                allDoneTasks.length === 0
                  ? 'overdue'
                  : allDoneTasks.length > 0 && allTasksForSite.length > allDoneTasks.length
                  ? 'inProgress'
                  : allDoneTasks.length === allTasksForSite.length
                  ? 'done'
                  : ''
              }
              statusText={`${allDoneTasks.length}/${allTasksForSite.length}`}
              dot={!!isOverDue && 'true'}
            />
          )}
          {status && (
            <>
              <TaskTally statusText={status || ''} />
            </>
          )}
          {children && children.length > 0 && (
            <TaskTally
              statusText={`${children.length}`}
              iconName="children"
              width={14}
              height={16}
            />
          )}
        </div>
      )}

      {_.size(equipmentAssetFilterData?.equipmentsIds) == 1 ? (
        siteId && (
          <CardSite
            highlight={highlight}
            className={className}
            showNotIcon={showNotIcon}
            formWrapper={formWrapper}
            variant={'secondary'}
            siteData={{ ...siteAsset, siteId }}
            taskId={taskId}
            assetId={siteId}
          />
        )
      ) : (
        <CardSite
          highlight={highlight}
          className={className}
          showNotIcon={showNotIcon}
          formWrapper={formWrapper}
          variant={'secondary'}
          siteData={{ ...siteAsset, siteId }}
          taskId={taskId}
          assetId={siteId}
        />
      )}
      {divider && (
        <div
          style={{
            width: '100%',
            borderTop: ' 0.5px solid #e5e5e5',
            marginTop: showNotIcon ? '16px' : '4px'
          }}
        />
      )}
    </>
  );
};

export default CardEquipment;
