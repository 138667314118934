import React, { memo, forwardRef } from 'react';
import _ from 'lodash';

import Textarea from '@components/textarea';

import style from '../workorder.module.scss';

const FormTextarea = (
  { errorsMsg, removeSpecialCharactersForName, field, name, register, watch, label },
  ref
) => {
  const { refs } = ref;

  let errorsMessage = errorsMsg?.message || '';

  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
    >
      <Textarea
        dot={field?.isRequired}
        label={label}
        inputClass="input-field input-regular"
        labelColor={style.labelColor}
        register={register}
        name={name}
        value={watch(name)}
        error={errorsMessage}
        isRequired={field?.isRequired}
        isRequiredMsg={'Required'}
        reconciled={field?.isMasterData}
        // showReconciled={!_.isEmpty(watch(name)) && field?.isMasterData}
        showReconciled={
          !_.isEmpty(watch(`${name}-reconcile`))
            ? field?.isMasterData && watch(`${name}-reconcile`) !== watch(name)
              ? true
              : false
            : !_.isEmpty(watch(name)) && field?.isMasterData
        }
      />
    </div>
  );
};

export default memo(forwardRef(FormTextarea));
