import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '@components/logo';
import Button from '@components/button';
import Textarea from '@components/textarea';
import InputField from '@components/input-field';

import AuthService from '@services/auth.service';

const SupportMsg = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [username, setUserName] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !message || !emailRegex.test(username)) return setErrorMessage('error');

    const [success] = await AuthService.contactSupport({ emailAddress: username, message });

    if (success) navigate('/thank-you-message');
  };

  return (
    <div className="op-login-container">
      <div className="op-login">
        <div className="text-center mb-14">
          <Logo />
        </div>
        <form>
          <div className="op-input-field-wrap mb-6">
            <InputField
              label="Email"
              type="email"
              inputClass="input-field input-regular"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              error={
                errorMessage
                  ? !username
                    ? 'Required'
                    : !emailRegex.test(username)
                    ? 'Not an email address'
                    : ''
                  : ''
              }
              className={
                errorMessage
                  ? !username ||
                    !emailRegex.test(username) ||
                    errorMessage === 'incorrect' ||
                    errorMessage === 'no-access'
                    ? 'error'
                    : ''
                  : ''
              }
            />
          </div>
          <div className="op-input-field-wrap relative mb-6">
            <Textarea
              label="Message"
              value={message}
              placeholder="What seems to be the problem?"
              onChange={(e) => setMessage(e.target.value)}
              error={errorMessage ? (!message ? 'Required' : '') : ''}
              className={errorMessage ? (!message ? 'error' : '') : ''}
            />
          </div>
          <div className="mt-9">
            <Link to={''} onClick={handleSubmit}>
              <Button text="Send" />
            </Link>
          </div>
          <ul className="mt-9 px-3 op-support">
            <li className="mb-2">
              <a
                className="input-underline-link op-anchor-link op-mail"
                href="mailto:support@oplii.com"
              >
                support@oplii.com
              </a>
            </li>
            <li>
              <a className="input-underline-link op-anchor-link op-phone" href="tel:18889949337">
                1-888-994-9337
              </a>
            </li>
          </ul>
          <div className="op-divider mt-9"></div>
          <div className="flex flex-wrap justify-between mt-2">
            <Link className="input-bold op-anchor-link" to={'/'}>
              Log in
            </Link>
            <Link className="input-bold op-anchor-link" to="/PasswordReset">
              Password reset
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SupportMsg;
