/* eslint-disable no-unused-vars */
// import { store } from 'redux/store';
import _ from 'lodash';
import moment from 'moment';

import { getDataPoints, getMoreInfoDataPoints } from '../../pages/settings/helper';

import StructureSelector from '../structure.selector';
import PipelineSelector from '../pipeline.selector';
import { getAllWorkOrdersAndInspections } from './all-work-order-inspection-for-site';

export const getSiteDetailInfo = async ({ siteId = '', getDataFromCache = false, store }) => {
  const { cache, picklists, jurisdictionNumbers, settings, structure } = store;
  const {
    sitesMap = {},
    equipmentsMap = {},
    sitesLoading,
    sitesEquipmentsMap = {},
    equipments: cacheEquipments = [],
    sites: cacheSites = []
  } = cache;

  const { structure: cacheStructures, sitesStructuresMap = {}, structuresMap = {} } = structure;

  const structureDisplayFields = settings?.settings?.['structureDisplayFields'] || '';
  const equipmentDisplayFields = settings?.settings?.['equipmentDisplayFields'] || '';
  const pipelineDisplayFields = settings?.settings?.['pipelineDisplayFields'] || '';
  const siteDisplayFields = settings.settings?.['siteDisplayFields'] || '';
  const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;
  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = picklists;

  const pipelines = await PipelineSelector?.getPipelineWithWO({ store });
  const {
    assignedTasksList: tasksList,
    newWorkRequestList,
    hseEvents
  } = await getAllWorkOrdersAndInspections({
    siteId,
    getDataFromCache,
    getWorkRequestData: true,
    store
  });
  const equipments =
    (sitesEquipmentsMap[siteId] || []).filter((id) => cacheEquipments[equipmentsMap[id]]) || [];
  const structures =
    (sitesStructuresMap[siteId] || []).filter((id) => cacheStructures[structuresMap[id]]) || [];

  const particularPipelines = pipelines.filter(
    (p) =>
      p?.fromSite === siteId ||
      p?.toSite === siteId ||
      p?.sites?.some((x) => x?.toLowerCase() === siteId) ||
      p?.wells?.some((x) => x?.toLowerCase() === siteId)
  );

  const parentSite = cacheSites[sitesMap[siteId]] || {};
  // const tasks = tasksList?.filter(
  //   (t) => t?.siteId === siteId || t?.relatedAssetId === siteId || t?.sites?.includes(siteId)
  // );
  const tasks = tasksList;

  const allDoneTasks = tasks.filter((task) => task?.status === 'Synced');
  const allNotDoneTasks = tasks.filter((task) => task?.status !== 'Synced');

  const allWorkOrders = tasks?.filter((x) => x.type === 'Work Order');
  const allDoneWorkOrders = allDoneTasks?.filter((x) => x.type === 'Work Order');
  const allNotDoneWorkOrders = allNotDoneTasks.filter((task) => task?.status !== 'Synced');

  const allInspections = tasks?.filter((x) => x.type === 'Inspection');
  const allDoneInspections = allDoneTasks?.filter((x) => x.type === 'Inspection');
  const allNotDoneInspections = allNotDoneTasks.filter((task) => task?.status !== 'Synced');

  const allChecks = tasks?.filter((x) => x.type === 'Check');
  const allDoneChecks = allDoneTasks?.filter((x) => x.type === 'Check');
  const allNotDoneChecks = allNotDoneTasks.filter((task) => task?.status !== 'Synced');

  const today = moment().format('YYYY-MM-DD');

  const isOverDue = allNotDoneTasks.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  const isInspectionOverDue = allNotDoneInspections.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  const isWorkOrderOverDue = allNotDoneWorkOrders.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  const isCheckOverDue = allNotDoneChecks.find((task) =>
    task.dueDate ? moment(task.nextDue).isBefore(today) : false
  );

  const { name, siteType, areaHierarchy, link } = parentSite;
  const status = picklistsMap[parentSite.status]?.value;
  const area = areaHierarchyTreeDataMap[areaHierarchy]?.title;
  const data = { ...parentSite, area, type: siteType };
  const { unit, distance, children, ...rest } = data;

  const dataPoints = getDataPoints(
    { ...data, type: data?.siteType },
    siteDisplayFields,
    'site',
    picklistsMap
  );

  const dataPointsMap = dataPoints.reduce((acc, curr) => {
    acc[curr.Id] = curr;
    return acc;
  }, {});
  dataPointsMap['Status'] = { value: status, title: 'Status' };

  const MoreInfoDataPoints = getMoreInfoDataPoints(
    { ...data, type: data?.siteType },
    'site',
    picklistsMap
  );
  const MoreInfoDataPointsMap = MoreInfoDataPoints.reduce((acc, curr) => {
    acc[curr.Id] = curr;
    return acc;
  }, {});
  MoreInfoDataPointsMap['Status'] = { value: status, title: 'Status' };

  const structureEquipmentMap = await StructureSelector.getStructureEquipmentMap({ store });

  const structureAllFieldData = structures?.map((structure) => {
    const equipments = structureEquipmentMap[structure] || [];

    const structureTasks = tasksList?.filter(
      (t) => t?.structureId === structure || t.structures?.includes(structure)
    );

    const allDoneTasks = structureTasks?.filter((task) => task?.status === 'Synced');
    const allNotDoneTasks = structureTasks?.filter((task) => task?.status !== 'Synced');
    const today = moment().format('YYYY-MM-DD');
    const isOverDue = allNotDoneTasks?.find((task) =>
      task?.dueDate ? moment(task?.dueDate).isBefore(today) : false
    );

    const equData = cacheStructures[structuresMap[structure]] || {};
    const { jurisdictionNumber } = jurisdictionNumbersMap[structure] || {};
    const dataPoints = getDataPoints(
      { ...equData, type: equData?.structureType, jurisdictionNumber },
      structureDisplayFields,
      'structure',
      picklistsMap
    );

    return {
      ...equData,
      equipmentCount: sitesEquipmentsMap[siteId]?.length || 0,
      allDoneTasks,
      isOverDue,
      equipments,
      allTasksForSite: structureTasks,
      allotDoneTasks: allNotDoneTasks,

      dataPoints
    };
  });

  const equipmentAllFieldData = equipments?.map((equipment) => {
    const equipmentsTasks = tasksList?.filter(
      (t) => t?.equipmentId === equipment || t.equipments?.includes(equipment)
    );

    const allDoneTasks = equipmentsTasks?.filter((task) => task?.status === 'Synced');
    const allNotDoneTasks = equipmentsTasks?.filter((task) => task?.status !== 'Synced');
    const today = moment().format('YYYY-MM-DD');
    const isOverDue = allNotDoneTasks?.find((task) =>
      task?.dueDate ? moment(task?.dueDate).isBefore(today) : false
    );

    const equData = cacheEquipments[equipmentsMap[equipment]] || {};
    const { jurisdictionNumber } = jurisdictionNumbersMap[equipment] || {};
    const dataPoints = getDataPoints(
      { ...equData, subtype: equData?.equipmentSubType, jurisdictionNumber },
      equipmentDisplayFields,
      'equipment',
      picklistsMap
    );

    return {
      ...equData,
      allDoneTasks,
      isOverDue,

      allTasksForSite: equipmentsTasks,
      allotDoneTasks: allNotDoneTasks,
      dataPoints
    };
  });

  const parentSiteChildren = children?.map((childIndex) => {
    return { ...cacheSites[childIndex], children: [] };
  });

  return {
    sitesLoading,
    name,
    tasks: _.size(_.flattenDeep(tasks)) > 0 ? _.flattenDeep(tasks) : [],
    allDoneTasks: _.size(_.flattenDeep(allDoneTasks)) > 0 ? _.flattenDeep(allDoneTasks) : [],
    isOverDue,
    allWorkOrders: _.size(_.flattenDeep(allWorkOrders)) > 0 ? _.flattenDeep(allWorkOrders) : [],
    allDoneWorkOrders:
      _.size(_.flattenDeep(allDoneWorkOrders)) > 0 ? _.flattenDeep(allDoneWorkOrders) : [],
    allInspections: _.size(_.flattenDeep(allInspections)) > 0 ? _.flattenDeep(allInspections) : [],
    allDoneInspections:
      _.size(_.flattenDeep(allDoneInspections)) > 0 ? _.flattenDeep(allDoneInspections) : [],
    allChecks: _.size(_.flattenDeep(allChecks)) > 0 ? _.flattenDeep(allChecks) : [],
    allDoneChecks: _.size(_.flattenDeep(allDoneChecks)) > 0 ? _.flattenDeep(allDoneChecks) : [],
    isInspectionOverDue,
    isCheckOverDue,
    isWorkOrderOverDue,
    status,
    area,
    equipments,
    structures,
    dataPoints,
    unit,
    distance,
    children: parentSiteChildren,
    parentSite,
    rest,
    dataPointsMap,
    MoreInfoDataPointsMap,
    equipmentAllFieldData,
    structureAllFieldData,
    pipelines: particularPipelines,
    equipmentCount: sitesEquipmentsMap[siteId]?.length || 0,
    link,
    newWorkRequestList,
    hseEvents
  };
};
