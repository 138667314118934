/* eslint-disable no-unused-vars */

import React, { forwardRef, useMemo } from 'react';
import { store } from 'redux/store';

import Attachments from '@components/form-fields/attachment/attachment';
import FormCheckbox from './checkbox';
import Datepicker from './datapicker';
import FormNotes from './notes';
import FormPassFail from './pass-fail';
import FormPhotoBox from './photo-box';
import FormReading from './readings';
import FormSignature from './signature';
import FormSlider from './slider';
import FormTextField from './text-field';
import FormTextarea from './textarea';
import FormWorkOrder from './work-order';
import FormYesNoComponent from './yes-no-component';
import FormDropdown from './dropdown';
import ReadableText from './readable-text';
import ReadableArray from './readable-array';
import Label from '@components/label';
import TextBlock from '@components/text-block';
import Separator from '@components/separator';
import ViewableImages from './viewable-images';
import DownloadableDocuments from './downloadable-documents';
import Psvs from './psvs';
import Icon from '@components/icon-component';
import Divider from '@components/divider';
import styles from './map-filed.module.scss';

const MapFieldComponents = (
  {
    field,
    checkPreviousFieldIsBoxed = false,
    setValue,
    watch,
    workType,
    count,
    errors,
    appliesToData,
    control,
    getValues,
    register,
    grid2,
    dropdownMenuMapList
  },
  ref
) => {
  const { refs } = ref;
  const { sitesMap, sites = [] } = store.getState().cache;

  let removeSpecialCharactersForName = removeSpecialCharacters(
    field?.label?.toString()?.toLowerCase()
  );
  removeSpecialCharactersForName = `${removeSpecialCharactersForName}-${field?.formFieldId}`;

  const name =
    field?.questionTypeText === 'InspectionResult'
      ? `${removeSpecialCharactersForName}.${field?.formFieldId}.inspectionResultAnswer`
      : field?.questionTypeText === 'CheckResult'
      ? `${removeSpecialCharactersForName}.${field?.formFieldId}.checkResultAnswer`
      : `${removeSpecialCharactersForName}.${field?.formFieldId}`;

  const errorsMsg = errors[removeSpecialCharactersForName]?.[field?.formFieldId];

  const label =
    workType === 'new-inspection' ||
    workType === 'inspection' ||
    appliesToData?.type === 'Inspection' ||
    (appliesToData?.type === 'Work Order' && appliesToData?.formId)
      ? `${count}. ${field?.label}`
      : `${field?.label}`;

  const labelInsertCenteredValue = useMemo(() => {
    if (field?.questionTypeText === 'Label_Insert_Centered') {
      let lastCheckInDays =
        appliesToData[0]?.siteId && appliesToData[0]?.lastCheckInDays
          ? appliesToData[0]?.lastCheckInDays
          : appliesToData?.siteId
          ? sites[sitesMap[appliesToData?.siteId]]?.lastCheckInDays || ''
          : '';
      lastCheckInDays = lastCheckInDays > 99 ? `99+ days` : `${lastCheckInDays} days`;
      return field?.label?.replace(/{.*?}/g, lastCheckInDays);
    } else return '';
  }, [field, appliesToData, sites]);

  return (
    <div key={field?.formFieldId}>
      {(field?.questionTypeText == 'PassFail' || field?.questionTypeText === 'PassFailNA') && (
        <FormPassFail
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          control={control}
          name={name}
          count={count}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          checkPreviousFieldIsBoxed={checkPreviousFieldIsBoxed}
          ref={{ refs }}
          label={label}
        />
      )}

      {field?.questionTypeText == 'WorkOrder' && (
        <FormWorkOrder
          getValues={getValues}
          name={name}
          setValue={setValue}
          watch={watch}
          appliesToData={appliesToData}
          workOrder
        />
      )}
      {field?.questionTypeText == 'WorkRequest' && (
        <FormWorkOrder
          getValues={getValues}
          name={name}
          setValue={setValue}
          watch={watch}
          appliesToData={appliesToData}
          workRequest
        />
      )}
      {(field?.questionTypeText === 'Text' ||
        field?.questionTypeText === 'Numeric' ||
        field?.questionTypeText === 'PhoneNumber') && (
        <FormTextField
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          name={name}
          register={register}
          watch={watch}
          label={label}
          setValue={setValue}
          ref={{ refs }}
        />
      )}
      {(field?.questionTypeText === 'Dropdown' ||
        field?.questionTypeText === 'Dropdown_PL' ||
        field?.questionTypeText === 'Dropdown_PL_Multiple' ||
        field?.questionTypeText === 'InspectionResult' ||
        field?.questionTypeText === 'CheckResult') && (
        <FormDropdown
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          control={control}
          name={name}
          register={register}
          label={label}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          dropdownMenuMapList={dropdownMenuMapList}
          ref={{ refs }}
        />
      )}
      {(field?.questionTypeText === 'Date' || field?.questionTypeText === 'Date_Nullable') && (
        <Datepicker
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          label={label}
          control={control}
          name={name}
          register={register}
          ref={{ refs }}
          count={count}
        />
      )}
      {field?.questionTypeText === 'Header' && <Separator text={field?.label} />}
      {field?.questionTypeText === 'LongText' && (
        <FormTextarea
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          name={name}
          register={register}
          watch={watch}
          label={label}
          ref={{ refs }}
        />
      )}
      {field?.questionTypeText === 'Photos' && (
        <Attachments
          item={{
            name
          }}
          control={control}
          watch={watch}
          errors={errors[name]}
          getValues={getValues}
          setValue={setValue}
          noHeader={true}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
        />
      )}
      {field?.questionTypeText === 'Notes' && (
        <FormNotes
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          name={name}
          register={register}
          ref={{ refs }}
        />
      )}
      {field?.questionTypeText == 'PhotoQuestion' && (
        <FormPhotoBox
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          control={control}
          name={name}
          register={register}
          checkPreviousFieldIsBoxed={checkPreviousFieldIsBoxed}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          count={count}
          ref={{ refs }}
        />
      )}
      {field?.questionTypeText === 'ReadableText' && field?.value && <ReadableText field={field} />}
      {field?.questionTypeText === 'ReadableArray' && <ReadableArray field={field} />}
      {field?.questionTypeText === 'ReadableImageAndDocumentsArray' && (
        <ViewableImages field={field} />
      )}
      {field?.questionTypeText === 'ReadableImageAndDocumentsArray' && (
        <DownloadableDocuments field={field} />
      )}
      {field?.questionTypeText == 'YN' && (
        <FormCheckbox
          field={field}
          count={count}
          value={watch(name) || false}
          name={name}
          label={label}
          setValue={setValue}
        />
      )}
      {field?.questionTypeText === 'Label' && <Label label={field?.label} />}
      {field?.questionTypeText === 'Signature' && (
        <FormSignature
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          control={control}
          name={name}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          count={count}
          label={label}
          grid2={grid2}
          ref={{ refs }}
        />
      )}
      {field?.questionTypeText === 'TextBlock' && <TextBlock text={field?.label} />}
      {(field?.questionTypeText === 'Readings' || field?.questionTypeText === 'Readings_PL') && (
        <FormReading
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          control={control}
          name={name}
          label={label}
          register={register}
          watch={watch}
          setValue={setValue}
          count={count}
          dropdownMenuMapList={dropdownMenuMapList}
          ref={{ refs }}
        />
      )}
      {field?.questionTypeText === 'Time' && (
        <Datepicker
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          label={label}
          control={control}
          name={name}
          register={register}
          ref={{ refs }}
          count={count}
          timePicker
        />
      )}
      {field?.questionTypeText === 'YNComment' && (
        <FormYesNoComponent
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          label={label}
          control={control}
          name={name}
          register={register}
          watch={watch}
          count={count}
          ref={{ refs }}
        />
      )}
      {field?.questionTypeText === 'Scale' && (
        <FormSlider
          errorsMsg={errorsMsg}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          field={field}
          control={control}
          label={label}
          name={name}
          register={register}
          checkPreviousFieldIsBoxed={checkPreviousFieldIsBoxed}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          count={count}
          ref={{ refs }}
        />
      )}
      {field?.questionTypeText === 'PSV_Section' && (
        <Psvs
          psvsData={getValues(name) || []}
          dropdownMenuMapList={dropdownMenuMapList}
          setValue={setValue}
          watch={watch}
          getValues={getValues}
          name={name}
          field={field}
          removeSpecialCharactersForName={removeSpecialCharactersForName}
          errors={errors}
        />
      )}
      {field?.questionTypeText === 'Heading_Centered' && (
        <div className={styles.headingCentered}>
          <Icon name={'circled-user'} width={'50px'} />
          <Label
            label={field?.label}
            className={styles.checksLabel}
            inlineTextStyle={{ fontSize: '20px', fontWeight: '600' }}
          />
        </div>
      )}
      {field?.questionTypeText === 'Label_Insert_Centered' && (
        <Label
          label={labelInsertCenteredValue}
          className={styles.checksLabel}
          inlineTextStyle={{ fontSize: '16px' }}
          dangerouslySetInnerHTML
        />
      )}
      {field?.questionTypeText === 'Divider' && <Divider />}
      {field?.questionTypeText === 'Label_Centered' && (
        <Label label={field?.label} className={styles.checksLabel} />
      )}
    </div>
  );
};

export default forwardRef(MapFieldComponents);

const removeSpecialCharacters = (str) => {
  const pattern = /[^a-zA-Z0-9 ]/g;
  const result = str.replace(pattern, '');
  return result;
};
