import React, { memo } from 'react';

import CollapseableFilter from './task-status';

const EquipmentFilterGroups = ({
  equipmentCategoryTags,
  picklistsMap,
  filters,
  updateFilters,
  equipmentTypeTags,
  equipmentSubTypeTags,
  equipmentStatusTags
}) => {
  return (
    <>
      <p
        className="label-regular op-filter-label"
        style={{ padding: '29px 0px 32px 0px', marginBottom: '0px' }}
      >
        Equipment
      </p>

      <div style={{ marginBottom: '40px' }}>
        {equipmentCategoryTags?.length > 0 && (
          <CollapseableFilter
            title={'Equipment category'}
            tags={equipmentCategoryTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters?.equipmentCategory?.value}
            onChange={(value) => updateFilters('equipmentCategory', 'value', value)}
          />
        )}
      </div>

      <div style={{ marginBottom: '40px' }}>
        {equipmentTypeTags?.length > 0 && (
          <CollapseableFilter
            title={'Equipment type'}
            tags={equipmentTypeTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters?.equipmentType?.value}
            onChange={(value) => updateFilters('equipmentType', 'value', value)}
          />
        )}
      </div>

      <div style={{ marginBottom: '40px' }}>
        {equipmentSubTypeTags?.length > 0 && (
          <CollapseableFilter
            title={'Equipment sub type'}
            tags={equipmentSubTypeTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters?.equipmentSubType?.value}
            onChange={(value) => updateFilters('equipmentSubType', 'value', value)}
          />
        )}
      </div>

      <div style={{ marginBottom: '40px' }}>
        {equipmentStatusTags?.length > 0 && (
          <CollapseableFilter
            title={'Equipment status'}
            tags={equipmentStatusTags || []}
            tagsMap={picklistsMap}
            multi
            value={filters?.equipmentStatus?.value}
            onChange={(value) => updateFilters('equipmentStatus', 'value', value)}
          />
        )}
      </div>
    </>
  );
};

export default memo(EquipmentFilterGroups);
