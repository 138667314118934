/* eslint-disable no-unused-vars */

import _ from 'lodash';

import Icon from '@components/icon-component';
import Portal from '@components/portal';
import style from './modal.module.scss';

const Modal = ({
  open,
  icon,
  statusText,
  modalTextUpper,
  modalTextLower,
  children,
  progressData,
  textBtn,
  setOpen,
  progressDataColor = '#323F4B',
  modalImageClassName,
  showLine,
  Action,
  CloseBtnText,
  CloseBtnClass,
  ActionbtnClass,
  sendingAgainPhotosAndDocuments = false
}) => {
  return (
    <>
      {open && (
        <Portal>
          <div
            className={style.bgDiv}
            onClick={(e) => {
              setOpen(false);
              e?.stopImmediatePropagation && e.stopImmediatePropagation();
            }}
          >
            <div className={style.mainDiv} onClick={(e) => e.stopPropagation()}>
              <div className={`${style.iconDiv} ${modalImageClassName}`}>
                {typeof icon === 'string' ? <Icon name={icon} /> : icon}
              </div>
              <div className={style.statusDiv}>
                <span>{statusText}</span>
              </div>
              <div
                className={style.textDivWrap}
                style={{
                  marginBottom: sendingAgainPhotosAndDocuments ? '32px' : '14px'
                }}
              >
                {modalTextUpper && (
                  <div className={style.modalTextDiv}>
                    <span>{_.isFunction(modalTextUpper) ? modalTextUpper() : modalTextUpper}</span>
                  </div>
                )}
                {modalTextLower && (
                  <div
                    style={{ marginTop: modalTextUpper && '16px' }}
                    className={style.modalTextDiv}
                  >
                    <span>{_.isFunction(modalTextLower) ? modalTextLower() : modalTextLower}</span>
                  </div>
                )}
              </div>
              {showLine && (
                <>
                  <div>
                    <Icon name={'line'} />
                  </div>
                </>
              )}
              {progressData && (
                <>
                  {!sendingAgainPhotosAndDocuments && (
                    <div style={{ marginTop: '14px' }}>
                      <Icon name={'line'} />
                    </div>
                  )}
                  <div className={style.progressDataDiv} style={{ color: progressDataColor }}>
                    {_.isFunction(progressData) ? progressData() : progressData}
                  </div>
                </>
              )}
              <div>{children}</div>
              {textBtn && (
                <div className={`${style.textBtn} ${ActionbtnClass}`}>
                  <span
                    onClick={() => {
                      Action && Action();
                      setOpen(false);
                    }}
                  >
                    {textBtn}
                  </span>
                </div>
              )}
              {CloseBtnText && (
                <div className={`${style.textBtn} ${CloseBtnClass}`}>
                  <span
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    {CloseBtnText}
                  </span>
                </div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};
export default Modal;
