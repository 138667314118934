/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import TaskCardNew from '@components/task-card-new';
import DeselectOtherTask from '@components/modal/variants/deselect-other-task';

import style from './style.module.scss';

const TaskCard = (props) => {
  const {
    type,
    status,
    onSelection,
    check = true,
    selection = false,
    openModal = () => {},
    searched,
    dataPoints,
    cardCheckbox = true,
    primaryAssetType,
    tab = null,
    SelectedFilter = null,
    onSelectionNewWorkOrderOrRequest = null,
    detailInspectionsInfo,
    chip = true,
    formWrapper = false,
    checkedLength = 0
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [selected, setSelected] = useState(false);
  const [discardTaskModal, setDiscordModal] = useState('');

  useEffect(() => {
    setSelected(selection);
  }, [selection]);

  const onChangeSelection = (value) => {
    if (cardCheckbox) setSelected(value);
    onSelection?.(value);
  };

  const onClickCard = () => {
    if (!formWrapper) {
      const backFilters = {
        taskNavigationTab: tab,
        taskSelectedSort: SelectedFilter
      };
      const allQueryList = Object.fromEntries([...searchParams]);

      if (check && status === 'To do' && type !== 'Check') onChangeSelection(!selected);
      else if (props?.newWorkOrder) {
        let navigateURL = `/work-order/?from=${location.pathname}&type=new-work-order&orderIds=${props?.woId}`;
        navigateURL = allQueryList?.recurringTemplate
          ? navigateURL.concat(`&recurringTemplateQuery=${JSON.stringify(allQueryList)}`)
          : navigateURL;
        navigate(navigateURL);
        onSelectionNewWorkOrderOrRequest && onSelectionNewWorkOrderOrRequest();
      } else if (props?.newWorkRequest) {
        let navigateURL = `/work-order/?from=${location.pathname}&type=new-work-request&orderIds=${props?.woId}`;
        navigateURL = allQueryList?.recurringTemplate
          ? navigateURL.concat(`&recurringTemplateQuery=${JSON.stringify(allQueryList)}`)
          : navigateURL;
        navigate(navigateURL);
        onSelectionNewWorkOrderOrRequest && onSelectionNewWorkOrderOrRequest();
      } else if (props?.newInspection) {
        let navigateURL = `/work-order/?from=${location.pathname}&type=new-inspection&orderIds=${props?.id}`;
        navigate(navigateURL);
      } else if (props?.newCheck || props.type === 'Check') {
        if ((status === 'To do' || props?._id) && status !== 'Synced') {
          // assigned check
          if (checkedLength > 0) setDiscordModal(props?.id);
          else {
            let navigateURL = `/work-order/?from=${location.pathname}&type=check&orderIds=${props?.id}`;
            navigate(navigateURL);
          }
        } else if (status === 'Pending sync' || status === 'In progress') {
          let navigateURL = `/work-order/?from=${location.pathname}&type=new-check&orderIds=${props?.id}`;
          navigate(navigateURL);
        }
      } else if (status === 'Pending sync' || status === 'In progress') {
        if (type === 'Inspection') {
          let navigateURL = `/work-order/?from=${location.pathname}&type=inspection&orderIds=${
            props?.id
          }&}&backFilterForTask=${JSON.stringify(backFilters)}`;
          navigate(navigateURL);
        } else if (type === 'HSE') {
          let navigateURL = `/work-order/?from=${location.pathname}&type=new-hse&orderIds=${
            props?.id
          }&}&backFilterForTask=${JSON.stringify(backFilters)}`;
          navigate(navigateURL);
        } else {
          let navigateURL = `/work-order/?from=${location.pathname}&type=work-orders&orderIds=${
            props?.id
          }&}&backFilterForTask=${JSON.stringify(backFilters)}`;
          navigateURL = allQueryList?.recurringTemplate
            ? navigateURL.concat(`&recurringTemplateQuery=${JSON.stringify(allQueryList)}`)
            : navigateURL;
          navigate(navigateURL);
          onSelectionNewWorkOrderOrRequest && onSelectionNewWorkOrderOrRequest();
        }
      }
    }
  };

  const handleProceedEvent = () => {
    let navigateURL = `/work-order/?from=${location.pathname}&type=check&orderIds=${discardTaskModal}`;
    navigate(navigateURL);
    setDiscordModal('');
  };
  return (
    <>
      <div
        onClick={onClickCard}
        className={style['task-card-wrapper']}
        style={{
          ...(selected && { background: '#D5E6FB', borderColor: '#CBD2D9' }),
          ...(status === 'Synced' && { background: '#e3e7eb' }),
          ...((status === 'Pending sync' ||
            status === 'In progress' ||
            ((status === 'To do' || type?.includes('Asset')) && type !== 'Check')) &&
            !formWrapper && {
              cursor: 'pointer'
            })
        }}
      >
        <TaskCardNew
          taskHeaderIconName={props?.taskHeaderIconName || ''}
          taskCardHeaderTitle={props?.taskCardHeaderTitle || ''}
          taskStatus={props?.status || ''}
          type={props?.type || ''}
          link={props?.link || ''}
          setSelected={onChangeSelection}
          selected={selected}
          openModal={openModal}
          title={props?.title || ''}
          taskDescription={props?.description}
          taskCardWorkStatus={props?.taskCardWorkStatus || ''}
          taskCardUsers={props?.users || []}
          rtInterval={props?.rtInterval || ''}
          hse={null}
          equipmentsAsset={props?.equipmentAsset}
          sitesAsset={props?.siteAsset}
          priority={props?.priority}
          rtData={props?.rtData || {}}
          runData={props?.runData || {}}
          mocData={props?.mocData}
          hseData={props?.hseData || {}}
          structureData={props?.structureData}
          ncrData={props?.ncrData}
          pipelineData={props?.pipelineData}
          taskFooter={props?.taskFooter}
          highlight={searched}
          dataPoints={dataPoints || []}
          cardCheckbox={cardCheckbox}
          primaryAssetType={primaryAssetType}
          detailInspectionsInfo={detailInspectionsInfo}
          chip={chip}
          formWrapper={formWrapper}
          structureAsset={props?.structureAsset}
          pipelineAsset={props?.pipelineAsset}
          {...props}
        />
      </div>

      {discardTaskModal && (
        <DeselectOtherTask
          open={discardTaskModal}
          setOpen={() => setDiscordModal('')}
          handleProceed={handleProceedEvent}
        />
      )}
    </>
  );
};

export default React.memo(TaskCard, _.isEqual);
