import React from 'react';

import Icon from '@components/icon-component';

import RtList from './rt-list';
import { useNavigate } from 'react-router-dom';
import { getAppDispatch } from '@utilities/dispatch.util';
import { filterActions } from '@redux/slices/filters';
const RecurringTemplateDetails = ({
  checkedLength,
  view,
  setRt,
  highlight = '',
  setView,
  rt,
  onParentClick = null
}) => {
  const navigate = useNavigate();
  const dispatch = getAppDispatch?.();

  const clearAllFilterState = () => {
    dispatch?.(filterActions.setTaskFilters(null));
    dispatch?.(filterActions.setSitesFilters(null));
    dispatch?.(filterActions.setEquipmentsFilters(null));
  };
  return (
    <div
      className={`page-listing ${checkedLength > 0 ? 'no-appbar' : ''} `}
      style={{
        marginTop: '0px'
      }}
    >
      {!view ? (
        <RtList
          rt={rt}
          checkedLength={checkedLength}
          setRt={setRt}
          setView={setView}
          highlight={highlight}
        />
      ) : (
        <>
          <div className="rt">
            {rt
              .filter(({ checked }) => checked)
              .map(({ id, title }, i) => (
                <div className="rt--run" key={i}>
                  <span className="task-card-run--id">{id}</span>
                  <span>{title} </span>
                </div>
              ))}
          </div>
          <div
            className="task-type link-card"
            onClick={() => {
              clearAllFilterState();
              onParentClick && onParentClick();
              navigate(
                `/task-records?type=${view}&templates=${rt
                  .filter(({ checked }) => checked)
                  .map(({ id }) => id)
                  .join(',')}&recurringTemplate=${true}`
              );
            }}
          >
            <div className="task-type--left">
              <div className="task-type--text">View all {view.toLowerCase()}s</div>
            </div>
            <div className="task-type--right">
              <Icon name={'chevron-right'} width="10px" />
            </div>
          </div>
          <div
            className="task-type link-card"
            onClick={() => {
              clearAllFilterState();

              onParentClick && onParentClick();
              navigate(
                `/sites?type=${view}&templates=${rt
                  .filter(({ checked }) => checked)
                  .map(({ id }) => id)
                  .join(',')}&recurringTemplate=${true}`
              );
            }}
          >
            <div className="task-type--left">
              <div className="task-type--text">View by site</div>
            </div>
            <div className="task-type--right">
              <Icon name={'chevron-right'} width="10px" />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RecurringTemplateDetails;
