/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';
import DataSnippets from '@components/data-snippet';
import RunRtTag from '@components/run-rt';
import TaskStatusAndPriority from '@components/task-status-priority';
import AssignmentBody from './card-body/assignment';
import BodyDescription from './card-body/body-description';
import CardFooter from './card-footer';
import CardHeader from './card-header';
import HighlightText from '@components/highlight-text';
import CardAsset from './card-asset';

import style from './task.module.scss';

const TaskCardNew = ({
  id,
  taskHeaderIconName,
  taskCardHeaderTitle,
  taskStatus,
  cardCheckbox = true,
  type,
  link,
  taskDescription,
  taskCardWorkStatus,
  taskCardUsers,
  rtInterval,
  moc,
  hse = false,
  equipmentsAsset,
  sitesAsset,
  priority,
  rtData,
  runData,
  taskFooter,
  syncedIcon,
  title,
  selected,
  setSelected,
  highlight,
  openModal,
  mocData,
  hseData,
  structureData,
  ncrData,
  pipelineData,
  primaryAssetType,
  dataPoints = [],
  sitesAssetFilterData,
  equipmentAssetFilterData,
  syncDateCustom = null,
  detailInspectionsInfo = {},
  inspectionType,
  chip,
  formWrapper = false,
  structureAsset,
  pipelineAsset
}) => {
  const [showUsers, setShowUsers] = useState(false);
  return (
    <div
      className={style.taskCardMain}
      style={{
        backgroundColor:
          taskStatus === 'Synced'
            ? '#E3E7EB'
            : taskStatus === 'Done'
            ? '#E3E7EB'
            : selected && '#D5E6FB'
      }}
    >
      <div className={style.taskCardHeader}>
        <CardHeader
          selected={selected}
          setSelected={setSelected}
          iconName={taskHeaderIconName || 'check-task'}
          title={taskCardHeaderTitle || 'title'}
          status={taskStatus}
          checkbox={cardCheckbox}
          syncedIcon={syncedIcon ? true : false}
          type={type}
          link={link}
          highlight={highlight}
          openModal={openModal}
          formWrapper={formWrapper}
        />
      </div>

      <div className={style.taskCardTitle}>
        <span>
          <HighlightText
            text={type === 'Inspection' || type === 'HSE' ? inspectionType : title}
            highlight={highlight}
          />
        </span>
      </div>

      {(type === 'Inspection' || type === 'HSE' || type === 'Check') && taskDescription && (
        <div className={style.taskCardInner}>
          <div style={{ margin: '10px 0' }}>
            <BodyDescription description={taskDescription} highlight={highlight} />
          </div>
        </div>
      )}

      <div className={style.taskCardInner}>
        {/* dynamic */}
        {dataPoints &&
          dataPoints.map(({ Id, value }, ind) => (
            <React.Fragment key={ind}>
              {Id === 'Description' && taskDescription && (
                <div style={{ margin: '10px 0' }}>
                  <BodyDescription description={taskDescription} highlight={highlight} />
                </div>
              )}

              {!formWrapper && (
                <>
                  {Id === 'Status' && taskCardWorkStatus && (
                    <>
                      {
                        <div style={{ marginTop: '16px' }}>
                          <AssignmentBody iconName={'activity'} text={taskCardWorkStatus} />
                        </div>
                      }
                    </>
                  )}
                  {(Id === 'ResponsibleUsers' || Id === 'Inspectors') && (
                    <>
                      {_.size(taskCardUsers) > 0 && (
                        <div
                          onClick={(e) => {
                            if (!showUsers && taskCardUsers.length > 1) {
                              e.stopPropagation();
                              setShowUsers((prev) => !prev);
                            }
                          }}
                        >
                          {showUsers ? (
                            <div style={{ marginTop: '16px' }}>
                              <AssignmentBody
                                iconName={'users'}
                                assignmentData={taskCardUsers}
                                onClick={() => setShowUsers(false)}
                                highlight={highlight}
                              />
                            </div>
                          ) : (
                            <div style={{ marginTop: '16px' }}>
                              <AssignmentBody
                                iconName={'users'}
                                text={taskCardUsers?.length > 0 ? taskCardUsers[0] : ''}
                                collapseableValue={
                                  taskCardUsers.length > 1 ? taskCardUsers.length - 1 : ''
                                }
                                highlight={highlight}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {(Id === 'Recurrence' || Id === 'Interval') && (rtInterval || value) && (
                    <>
                      {
                        <div style={{ marginTop: '16px' }}>
                          <AssignmentBody
                            iconName={'rotate'}
                            text={rtInterval || value}
                            highlight={highlight}
                          />
                        </div>
                      }
                    </>
                  )}
                  {Id === 'Type' && value && (
                    <>
                      {
                        <div style={{ marginTop: '16px' }}>
                          <AssignmentBody
                            iconName={'work-order-type'}
                            text={value}
                            highlight={highlight}
                          />
                        </div>
                      }
                    </>
                  )}

                  {Id === 'Identifier' && value && (
                    <>
                      {
                        <div style={{ marginTop: '16px' }}>
                          <AssignmentBody
                            iconName={'identifier-icon'}
                            text={value}
                            highlight={highlight}
                          />
                        </div>
                      }
                    </>
                  )}

                  {Id === 'Location' && value && (
                    <>
                      {
                        <div style={{ marginTop: '16px' }}>
                          <AssignmentBody
                            iconName={'location-icon'}
                            text={value}
                            highlight={highlight}
                          />
                        </div>
                      }
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        {/* dynamic */}
        {hse && (
          <DataSnippets
            title={hse?.title || ''}
            subtitle={hse?.subtitle || ''}
            iconName={'hse'}
            backFlex={style.backClass}
            highlight={highlight}
            className={style.mainClass}
          />
        )}
        {/* asset details section start */}

        <CardAsset
          equipmentAssetFilterData={equipmentAssetFilterData}
          sitesAssetFilterData={type === 'HSE' ? sitesAsset : sitesAssetFilterData}
          mocData={mocData}
          hseData={hseData}
          structureData={structureData}
          ncrData={ncrData}
          pipelineData={pipelineData}
          highlight={highlight}
          detailInspectionsInfo={detailInspectionsInfo}
          chip={chip}
          formWrapper={formWrapper}
          structureAsset={structureAsset}
          pipelineAsset={pipelineAsset}
          id={id}
        />
        {/* asset details section start */}
      </div>

      {(priority || rtData?.id || runData?.id) && (
        <div className={style.chipsDiv}>
          {priority && <TaskStatusAndPriority status={priority} />}
          {rtData?.id && (
            <RunRtTag
              rtNumber={rtData?.id}
              rtName={rtData?.title ? rtData?.title : ''}
              selected={selected}
            />
          )}
          {runData?.id && (
            <RunRtTag
              rtNumber={runData?.id}
              rtName={runData?.title ? runData?.title : ''}
              selected={selected}
            />
          )}
        </div>
      )}

      <div className={style.taskCardFooter}>
        <CardFooter
          footerColor={taskFooter?.footerColor || '#2f80ed'}
          footerData={taskFooter?.dates || []}
          syncDateCustom={syncDateCustom}
        />
      </div>
    </div>
  );
};

export default React.memo(TaskCardNew, _.isEqual);
