/* eslint-disable no-unused-vars */

import style from './badge.module.scss';

const Badge = ({
  children,
  title = '',
  size = 'default',
  count = 0,
  text = '',
  status,
  color,
  textColor,
  overflowCount,
  dot = false,
  badgePositionClass = '',
  badgeContentClass = ''
}) => {
  return (
    <div className={`${style.mainContanier}`}>
      {(count > 0 || text || dot) && (
        <div
          title={title}
          className={`${style.badge} ${badgePositionClass && badgePositionClass}  ${
            dot
              ? style.dot
              : size === 'small'
              ? style.small
              : size === 'xSmall'
              ? style.xSmall
              : style.default
          }`}
          style={{
            backgroundColor: dot ? (color ? color : '#FF4D4F') : color,
            border: ''
          }}
        >
          <span
            className={`${style.badgeContent} ${badgeContentClass && badgeContentClass}`}
            style={{
              color: textColor ? textColor : '#ffffff'
            }}
          >
            {!dot &&
              `${count > 0 && (+count > overflowCount ? overflowCount + '+' : count)} ${
                text && text
              } `}
          </span>
        </div>
      )}

      {children}
    </div>
  );
};

export default Badge;
