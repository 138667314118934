/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import StructureCard from './structure-card';

const StructureListingInner = (props) => {
  const navigate = useNavigate();
  const {
    divider,
    allDoneTasks,
    allTasksForStructure,
    dataPoints,
    onSelect,
    equipments,
    highlight = '',
    highlightName = true,
    showChevronIcon = true,
    moreMenu,
    appliesTo,
    isOverDue,
    noSite = false,
    noIcon = false,
    name,
    siteId,
    structureId,
    structureDetails,
    structureListing,
    structureType,
    from,
    link,
    noLink = false,
    addNewWizard = false
  } = props;

  const to = !onSelect ? `/structure-details/${structureId}?from=${from ? from : 'structure'}` : '';

  const { sitesMap = {}, sites: cacheSites = [] } = useSelector((state) => state.cache);
  const { picklistsMap = {} } = useSelector((state) => state.picklists);

  const type = picklistsMap[structureType]?.value;
  const status = picklistsMap[props.status]?.value;

  let [highlightNames, highlightType] = highlight.split(' ');

  if (!highlightType) highlightType = highlightNames;
  if (highlightName) {
    highlightType = '';
    highlightNames = highlight;
  }

  const onClickLink = (e) => {
    navigate(to);
    if (!onSelect) return;
    e.preventDefault();
    onSelect?.(structureId);
  };

  return (
    <>
      <StructureCard
        chips={true}
        onClickLink={onClickLink}
        to={to}
        title={`${type}`}
        subtitle={name}
        dataPoints={dataPoints || []}
        highlight={highlight}
        highlightName={highlightName}
        allTasksForSite={allTasksForStructure}
        allDoneTasks={allDoneTasks}
        isOverDue={isOverDue}
        status={status}
        sites={noSite ? {} : cacheSites[sitesMap[siteId]] || {}}
        showChevronIcon={showChevronIcon}
        noIcon={noIcon}
        moreMenu={moreMenu}
        appliesTo={appliesTo}
        structureDetails={structureDetails}
        structureListing={structureListing}
        equipments={equipments}
        link={
          !addNewWizard
            ? !noLink
              ? appliesTo
                ? `/#/structure-details/${structureId}`
                : link
              : null
            : null
        }
        addNewWizard={addNewWizard}
        structureId={structureId}
      />
      {divider && <div style={{ width: '100%', borderTop: ' 0.5px solid rgb(203, 210, 217)' }} />}
    </>
  );
};

StructureListingInner.propTypes = {
  distance: PropTypes.string,
  unit: PropTypes.string,
  venue: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.string
};

export default StructureListingInner;
