import PropTypes from 'prop-types';

import './search-input.scss';

const SearchInput = ({ value, onChange, className = '' }) => {
  return (
    <div className={`op-filter-search p-4 md:px-8  ${className}`}>
      <div className="relative">
        <input
          required
          type="text"
          value={value}
          placeholder="What are you looking for?"
          onChange={(e) => onChange(e.target.value)}
          className="input-field input-field-search input-regular"
        />
        <span className="op-clear-search" onClick={() => onChange('')}></span>
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default SearchInput;
