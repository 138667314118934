/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';
import Label from '@components/label';
import DatePickerIcon from './date-picker-icon';
import CustomHeader from './render-cusom-header';
import './date.css';

import style from './date-picker.module.scss';

const DatePicker = ({
  readOnly,
  name,
  control,
  className,
  id,
  defaultVal,
  isDisabled,
  handleClick,
  maxDate,
  minDate,
  placeholder,
  reconciled,
  label,
  error,
  dot,
  isClearable,
  customFunc,
  isRequired = false,
  isRequiredMsg = '',
  timePicker = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);
  const handleInputClick = (value) => {
    setDatePickerOpen(value);
  };

  return (
    <>
      <div className={`${style.main} ${className}`}>
        <Label
          reconciled={reconciled}
          label={label}
          dot={dot}
          error={error}
          inLineStyle={{ paddingLeft: '8px' }}
        />
        <div onClick={handleClick} style={{ position: 'relative' }}>
          <Controller
            rules={{
              required: isRequired ? isRequiredMsg : ''
            }}
            name={name}
            control={control}
            defaultValue={defaultVal || null}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <ReactDatePicker
                    onFocus={() => setIsOpen(true)}
                    onBlur={() => setIsOpen(false)}
                    autoComplete="off"
                    isClearable={isClearable}
                    shouldRenderSuggestions={false}
                    previousMonthAriaLabel={style.classRed}
                    shouldCloseOnSelect
                    readOnly={timePicker ? false : readOnly}
                    onInputClick={() => {
                      handleInputClick(!isDatePickerOpen);
                    }}
                    onClickOutside={() => {
                      handleInputClick(!isDatePickerOpen);
                    }}
                    open={isDatePickerOpen}
                    onChange={(e) => {
                      onChange(e);
                      setIsOpen(false);
                      handleInputClick(!isDatePickerOpen);
                      customFunc && customFunc(e);
                    }}
                    className={error ? style.borderClass : style.inpDiv}
                    placeholderText={placeholder ? '' : ''}
                    disabled={isDisabled}
                    showTimeSelect={timePicker}
                    showTimeSelectOnly={timePicker}
                    timeIntervals={timePicker ? '1' : '0'}
                    timeCaption={timePicker ? 'Time' : ''}
                    selected={
                      value == 'Invalid Date' || (moment.isMoment(value) && !value.isValid())
                        ? null
                        : value
                        ? new Date(moment(value))
                        : null || null
                    }
                    maxDate={maxDate && maxDate}
                    minDate={minDate && minDate}
                    dateFormat={timePicker ? 'h:mm aa' : 'MMM. d, yyyy'}
                    id={id}
                    renderCustomHeader={(props) => (
                      <CustomHeader
                        {...{
                          ...props
                        }}
                      />
                    )}
                  />
                </>
              );
            }}
          />

          <DatePickerIcon
            {...{
              id,
              isOpen,
              timePicker
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DatePicker;
