/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import CheckboxComponnet from '@components/checkbox/checkbox.component';
import Modal from '@components/modal';

import './add-fields-modal.scss';

const AddFieldsModal = ({ text, fields, onSubmit, openModal, setOpenModal }) => {
  const [selectedFields, setSelectedFields] = useState(
    fields.map((el) => ({ ...el, checked: false }))
  );

  useEffect(() => {
    setSelectedFields(fields.map((el) => ({ ...el, checked: false })));
  }, [fields]);

  const onClick = () => {
    const data = selectedFields.filter(({ checked }) => checked).map(({ Id }) => Id);
    onSubmit(data);
    setOpenModal(false);
  };

  return (
    <div>
      <Modal
        open={openModal}
        setOpen={setOpenModal}
        statusText={'Add field(s)'}
        textBtn={'Add field(s)'}
        CloseBtnText={'Cancel'}
        CloseBtnClass={'op-anchor-link'}
        ActionbtnClass={'btn-fill button-large actionButton'}
        Action={onClick}
      >
        {' '}
        <PopupSubTitle
          text={text}
          selectedFields={selectedFields}
          setSelectedFields={setSelectedFields}
        />{' '}
      </Modal>
    </div>
  );
};

const PopupSubTitle = ({ text, selectedFields, setSelectedFields }) => {
  const onChange = (e, index) => {
    const copy = [...selectedFields];
    copy[index].checked = e.target.checked;
    setSelectedFields(copy);
  };

  return (
    <div>
      <p className="body-regular mb-8" style={{ textAlign: 'center' }}>
        Select the fields you want to add to your {text.toLowerCase()} cards. Oplii only caches
        certain fields for offline use, which is why this list is limited.
      </p>
      <div className="fields">
        {selectedFields.map(({ Value, checked }, index) => (
          <React.Fragment key={index}>
            <CheckboxComponnet
              mainClassName="mainCheckbox"
              text={Value}
              labelClassName={'labelCheckbox'}
              checked={checked}
              onChange={(e) => onChange(e, index)}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

AddFieldsModal.propTypes = {
  text: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default AddFieldsModal;
