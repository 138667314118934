/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { store } from 'redux/store';
import { useNavigate, useLocation } from 'react-router-dom';
import _ from 'lodash';

import SearchList from '../search-list';
import WizardSteps from '../wizard-steps';
import SearchTabList from '../search-tab-list';
import AssetsKindList from '../assets-kind-list';
import Icon from '@components/icon-component';
import styles from './style.module.scss';

const AddNewCheck = ({ asset = false, setActive, setAddNew }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(0);
  const [assetKind, setAssetKind] = useState('');
  const [checkTypeId, setCheckTypeId] = useState('');
  const [checkFormName, setCheckFormName] = useState();
  const [equipment, setEquipment] = useState(null);
  const [idOf, setIdOf] = useState('');
  const { checks = {}, cache, inspection } = store.getState();
  const { equipments = [], equipmentsMap } = cache;
  const pathname = location.pathname;

  useEffect(() => {
    if (asset) {
      setIdOf(pathname?.split('/')[2]);
      setAssetKind(
        _.capitalize(pathname.split('/')[1].replace('parent-', '').replace('-details', ''))
      );
    }
  }, [asset]);

  const checkTypeLists = useMemo(() => {
    let _checkTypeLists = [];

    _checkTypeLists = assetKind
      ? checks.checkTypes?.filter((x) => {
          return x[assetKind.toLowerCase()] && x?.module === 'AssetCheck';
        })
      : checks.checkTypes;
    return _checkTypeLists;
    // return _checkTypeLists?.filter((x) => x.availableOnDemand) || [];
  }, [assetKind, idOf]);

  const stepForm = [
    {
      title: 'What kind of check?',
      content: (
        <SearchList
          list={checkTypeLists || []}
          onSelect={(item, formName, props) => {
            if (asset) {
              setAddNew('');
              setActive(false);
              setTimeout(() => {
                navigate(
                  `/work-order/?from=${
                    location.pathname
                  }&type=new-check&checkType=${item}&${assetKind.toLowerCase()}Id=${idOf}`
                );
              }, 0);
            } else {
              setCheckTypeId(item);
              setCheckFormName(formName);
              setStep((prev) => prev + 1);
            }
          }}
          searchClassName={styles.searchClassName}
        />
      )
    }
  ];

  const steps = asset
    ? stepForm
    : [
        {
          title: 'For what kind of asset?',
          content: (
            <AssetsKindList
              list={assetsList}
              onSelect={(item) => {
                setAssetKind(item);
                setStep((prev) => prev + 1);
              }}
            />
          )
        },
        ...stepForm,
        {
          title: `Find and select the ${assetKind.toLowerCase()}.`,
          content: (
            <SearchTabList
              label={assetKind}
              onSelect={(id) => {
                setAddNew('');
                setActive(false);
                setTimeout(() => {
                  navigate(
                    `/work-order/?from=${
                      location.pathname
                    }&type=new-check&checkType=${checkTypeId}&${assetKind.toLowerCase()}Id=${id}`
                  );
                }, 0);
              }}
            />
          )
        },
        {
          title: 'New Check Added Successfully.',
          content: ''
        }
      ];

  const onClickStep = (step) => {
    if (step === 0) setAssetKind('');
    if (step <= 1) setCheckTypeId('');
    if (step <= 1) setCheckFormName('');
    setStep(step);
  };

  return (
    <div>
      <WizardSteps
        current={step}
        setStep={onClickStep}
        total={steps.length - 1}
        title={steps[step].title}
        content={steps[step].content}
        breadcrumbs={
          asset
            ? [checkFormName?.toString() || 'Check type']
            : [
                assetKind || 'Asset type',
                checkFormName?.toString() || 'Check type',
                equipment?.name || `Select ${assetKind}`,
                'Added'
              ]
        }
      />
    </div>
  );
};

const assetsList = [
  { icon: <Icon name={'Site'} />, title: 'Site' },
  { icon: <Icon name={'home-icon-white'} />, title: 'Structure' },
  { icon: <Icon name={'equipment-white'} />, title: 'Equipment' },
  { icon: <Icon name={'pipeline-white'} />, title: 'Pipeline' }
];

export default AddNewCheck;
