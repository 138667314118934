/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import DataSnippets from '@components/data-snippet';
import Icon from '@components/icon-component';
import InfoPopup from '@components/info-popup/info-popup.component';
import OpenLink from '@components/task-card-new/card-asset/open-link';
import { useOpenLink } from '@hooks/useOpenLink';

import style from './site-card.module.scss';

const SiteCard = (props) => {
  const {
    siteDetailsCard = false,
    uwi,
    wellBore,
    typeData,
    showChevronIcon,
    onClickLink,
    to,
    title,
    subtitle,
    hideLocation,
    distance,
    unit,
    dataPoints,
    normal,
    allTasksForSite,
    allDoneTasks,
    isOverDue,
    status,
    childrenList,
    grey,
    highlight,
    highlightName,
    arrayText,
    open,
    moreInfo,
    setOpen,
    addChild,
    showWOCount,
    equipmentCount,
    link,
    recordIndicatorLength = 0,
    siteId
  } = props;
  const [desktopLink, setDesktopLink] = useState(null);
  const [openLink, setOpenLink] = useOpenLink({
    taskId: siteId,
    assetId: siteId
  });

  let [highlightNames, highlightType] = !_.isEmpty(highlight) ? highlight.split(' ') : [];
  highlightNames = highlightName ? highlight : highlightNames;

  if (!highlightType) highlightType = highlightNames;

  const onClickOpliiDesktopLinkEvent = (desktopLink) => {
    setDesktopLink({ linkValue: desktopLink });
    setOpenLink((prev) => !prev);
  };

  const siteCardInfo = () => {
    return (
      <>
        <div className={style.cardInner}>
          <div className={style.cardContent}>
            <div
              style={{
                position: 'relative'
              }}
            >
              <DataSnippets
                title={title || ''}
                subtitle={subtitle || ''}
                dataPoints={dataPoints}
                iconName={!hideLocation && 'map-pin'}
                kmValue={distance}
                unit={unit || ''}
                highlightNames={highlightNames}
                highlightType={highlightType}
                grey={grey}
                siteDetailsCard={siteDetailsCard}
                normal={normal}
                allTasksForSite={allTasksForSite}
                allDoneTasks={allDoneTasks}
                isOverDue={isOverDue}
                status={status}
                childrenList={childrenList}
                className={`${siteDetailsCard && style.noMargin}`}
                showWOCount={showWOCount}
                equipmentCount={equipmentCount}
                link={link}
                onClickOpliiDesktopLinkEvent={onClickOpliiDesktopLinkEvent}
                recordIndicatorLength={recordIndicatorLength}
              />

              <OpenLink
                openLink={openLink}
                desktopLink={desktopLink}
                setOpenLink={setOpenLink}
                setDesktopLink={setDesktopLink}
                selectedLinkTypeIcon={'map-pin'}
                selectedLinkTypeName={'site'}
              />
            </div>

            {uwi && (
              <DataSnippets
                title={'Title'}
                subtitle={'Sub-Title'}
                type={'Type'}
                iconName={'maps'}
                kmValue={'23'}
                unit={'units'}
                siteIcon={style.siteIcon}
                className={style.marginClass}
              />
            )}
            {wellBore && (
              <DataSnippets
                title={'Title'}
                subtitle={'Sub-Title'}
                type={'Type'}
                iconName={'Equipment'}
                kmValue={'23'}
                unit={'units'}
                siteIcon={style.siteIcon}
                className={style.marginClass}
              />
            )}
            {typeData && (
              <DataSnippets
                title={'Title'}
                subtitle={'Sub-Title'}
                type={'Type'}
                iconName={'maps'}
                kmValue={'23'}
                unit={'units'}
                siteIcon={style.siteIcon}
                className={style.marginClass}
              />
            )}
            {addChild && <p className={`${style.moreChild}`}>{addChild}</p>}
          </div>
          {showChevronIcon ? (
            <div>
              <div>
                <Icon name={'chevron-right'} className={style.siteIcon} width="10px" />
              </div>
            </div>
          ) : (
            <div>
              {!moreInfo && (
                <div className="rightDiv">
                  <Icon
                    name={'Listing detail menu'}
                    className={style.siteIcon}
                    onClick={() => {
                      setOpen(true);
                    }}
                  />
                  {open && (
                    <div style={{ position: 'absolute', top: '20px', right: '0px' }}>
                      <InfoPopup open={open} setOpen={setOpen} arrayText={arrayText} />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  return siteDetailsCard ? (
    <div
      className={style.cardWrapperNoHover}
      style={{
        padding: siteDetailsCard ? '16px' : '',
        border: '0.5px solid #cbd2d9'
      }}
    >
      {siteCardInfo()}
    </div>
  ) : (
    <Link className={`${style.cardWrapper}`} onClick={onClickLink} to={to}>
      {siteCardInfo()}
    </Link>
  );
};

SiteCard.propTypes = {};

export default SiteCard;
