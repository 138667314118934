/* eslint-disable no-unused-vars */
import React, { forwardRef, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SiteListingInner from '@components/site-listing-inner';
import EquipmentListingInner from '@components/equipment-listing/equipment-listing-inner';
import CardAssetDetails from '@components/task-card-new/card-asset';
import StructureListingInner from '@components/structure-listing-inner';
import PipelineListingInner from '@components/pipeline-listing/pipeline-listing-inner/pipeline-listing-inner.component';

import style from './style.module.scss';

const AppliesTo = (
  {
    siteIds = [],
    equipmentIds = [],
    selectedOrderId = '',
    appliesToData,
    structureIds = [],
    pipelineIds = []
  },
  ref
) => {
  const taskData = useMemo(() => {
    if (!_.isEmpty(selectedOrderId)) return appliesToData;
  }, [appliesToData, selectedOrderId]);

  const siteData = React.useMemo(() => {
    if (!_.isEmpty(siteIds)) return appliesToData;
  }, [appliesToData]);

  const equipmentData = React.useMemo(() => {
    if (!_.isEmpty(equipmentIds)) return appliesToData;
  }, [appliesToData]);

  const structureData = React.useMemo(() => {
    if (!_.isEmpty(structureIds)) return appliesToData;
  }, [appliesToData]);
  const pipelineData = React.useMemo(() => {
    if (!_.isEmpty(pipelineIds)) return appliesToData;
  }, [appliesToData]);

  return (
    <div>
      {_.isEmpty(selectedOrderId) ? (
        <div className={style.mainClassBg}>
          <>
            {_.size(equipmentIds.filter((y) => y))
              ? _.size(equipmentData) > 0 && (
                  <div>
                    {equipmentData.map((data, index) => (
                      <EquipmentListingInner
                        appliesTo
                        noIcon
                        normal
                        {...data}
                        onSelect
                        showCheckInIcon
                        hideLocation={true}
                        showChevronIcon={false}
                        key={index}
                        moreInfo={true}
                        equipmentDetails
                        showWOCount
                      />
                    ))}
                  </div>
                )
              : ''}
          </>
          {_.size(siteIds.filter((y) => y)) > 0
            ? _.size(siteData) > 0 && (
                <div>
                  {siteData?.map((data, index) => (
                    <SiteListingInner
                      appliesTo
                      normal
                      {...data}
                      onSelect
                      showCheckInIcon
                      hideLocation={true}
                      showChevronIcon={false}
                      key={data.siteId || index}
                      moreInfo={true}
                      siteDetails
                      showWOCount
                    />
                  ))}
                </div>
              )
            : ''}
          {_.size(structureIds?.filter((y) => y)) > 0
            ? _.size(structureData) > 0 && (
                <div>
                  {structureData?.map((data, index) => (
                    <StructureListingInner appliesTo {...data} key={data.structureId || index} />
                  ))}
                </div>
              )
            : ''}
          {_.size(pipelineIds.filter((y) => y)) > 0
            ? _.size(pipelineData) > 0 && (
                <div>
                  {pipelineData?.map((data, index) => (
                    <PipelineListingInner appliesTo {...data} key={data.pipelineId || index} />
                  ))}
                </div>
              )
            : ''}

          {appliesToData?.length > 0 && appliesToData?.at(0)?.hseEvent && (
            <>
              <div className={style.hseEventAppliesTo}>
                <p className={style.area}>{appliesToData?.at(0)?.area}</p>
                <p className={style.location}>{appliesToData?.at(0)?.location}</p>
              </div>
            </>
          )}
        </div>
      ) : (
        <div
          className={style.mainClassBg}
          style={{
            padding: '16px'
          }}
        >
          <CardAssetDetails
            className={''}
            equipmentAssetFilterData={taskData?.equipmentAssetFilterData || {}}
            sitesAssetFilterData={
              taskData?.type === 'HSE' ? taskData?.siteAsset : taskData?.sitesAssetFilterData || {}
            }
            mocData={taskData?.mocData || {}}
            hseData={taskData?.hseData || {}}
            structureData={taskData?.structureData || {}}
            ncrData={taskData?.ncrData || {}}
            pipelineData={taskData?.pipelineData || {}}
            structureAsset={taskData?.structureAsset || {}}
            pipelineAsset={taskData?.pipelineAsset || {}}
            detailInspectionsInfo={taskData?.detailInspectionsInfo}
            showNotIcon
            appliesTo
            selectedOrderId={selectedOrderId}
            id={selectedOrderId}
          />
        </div>
      )}
    </div>
  );
};

AppliesTo.prototype = {
  siteIds: PropTypes.arrayOf(PropTypes.string),
  equipmentIds: PropTypes.arrayOf(PropTypes.string)
};

export default memo(forwardRef(AppliesTo));
