/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { transformJSON } from './inspection';

export const transformFormWrapperDataToNewHSEEvent = ({ hseEventData, hseEventId }) => {
  const uniqueId = !_.isEmpty(hseEventId) ? hseEventId : uuidv4();
  return {
    newHSEEvent: true,
    status: hseEventData?.status,
    description: hseEventData?.HSEEventTitle,
    type: 'HSE',
    id: uniqueId,
    HSEFormId: hseEventData?.inspectionFormId,
    HSETypeId: hseEventData?.inspectionTypeId,
    siteId: hseEventData?.siteId || null,
    location: hseEventData?.location || null,
    areaId: hseEventData?.areaId || null,
    formData: hseEventData?.formData || null,
    startLatitude: hseEventData?.startLatitude,
    startLongitude: hseEventData?.startLongitude,
    completionLatitude: hseEventData?.completionLatitude,
    completionLongitude: hseEventData?.completionLongitude
  };
};

export const prepareHSEEventsForBackend = ({
  data,
  formData,
  inspectionFormsUserCache,
  incidentCategoryMap,
  inspectionTypesMap
}) => {
  const currentFrom =
    inspectionFormsUserCache?.find((x) => x?.inspectionFormId === data?.HSEFormId) || {};
  const json = transformJSON({ currentFrom, formData });
  const incidentCategoryId = data?.inspectionType
    ? incidentCategoryMap[data?.inspectionType]?.value
    : data?.HSETypeId
    ? incidentCategoryMap[inspectionTypesMap[data?.HSETypeId]?.value]?.value
    : '';
  return {
    incidentId: data?.id,
    inspectionFormId: data?.HSEFormId,
    formData: json,
    incidentCategoryId,
    ...(data?.siteId && {
      siteId: data?.siteId
    }),
    ...(data?.location && {
      otherLocation: data?.location
    }),
    ...(data?.areaId && {
      areaId: data?.areaId
    }),
    title: data?.description || '',
    startLatitude: `${data?.startLatitude}`,
    startLongitude: `${data?.startLongitude}`,
    completionLatitude: `${data?.completionLatitude}`,
    completionLongitude: `${data?.completionLongitude}`
  };
};
