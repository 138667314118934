/* eslint-disable no-unused-vars */

import moment from 'moment';
import _ from 'lodash';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  HSEEventList: []
};

export const hseEventSlice = createSlice({
  name: 'hseEvents',
  initialState,
  reducers: {
    setHseEventList: (state, action) => {
      let pendingSyncAndInProgressHSEEvents = [
        ...state.HSEEventList.filter(
          (t) => t.status === 'Pending sync' || t.status === 'In progress'
        )
      ];

      state.HSEEventList = [...action.payload, ...pendingSyncAndInProgressHSEEvents];
    },
    addNewHSEEvent: (state, action) => {
      state.HSEEventList = [...state.HSEEventList, { ...action.payload }];
    },
    updateNewHSEEvent: (state, action) => {
      const copyHSEEventList = [...state.HSEEventList];
      const findIndex = copyHSEEventList?.findIndex((x) => x.id === action?.payload?.hseEventId);
      copyHSEEventList[findIndex] =
        { ...copyHSEEventList[findIndex], ...action?.payload?.data } || {};
      state.HSEEventList = [...copyHSEEventList];
    },
    setLocalStorageHSEEvents: (state, action) => {
      state.HSEEventList = [...state.HSEEventList, ...action.payload];
    },
    deleteHSEEvent: (state, action) => {
      state.HSEEventList = [...state.HSEEventList.filter((hse) => hse.id !== action?.payload)];
    }
  }
});

const hseEventReducer = hseEventSlice.reducer;

export const hseEventActions = hseEventSlice.actions;
export default hseEventReducer;
