import _ from 'lodash';

import HSEEventSelector from '../hse/hse-event.selector';
import TasksSelector from '../tasks/tasks.selector';
import InspectionSelector from '../inspection/inspection.selector';
import CheckSelector from 'selectors/checks/check.selector';

export const getAllWorkOrdersAndInspections = async ({
  templatesIds = null,
  siteId = null,
  getDataFromCache = false,
  getWorkRequestData = false,
  store
}) => {
  if (!getDataFromCache) {
    const [tasks, inspections, hseEvents, checks] = await Promise.all([
      await TasksSelector.getTasks({ siteId, store }),
      await InspectionSelector.getInspections({ siteId, store }),
      await HSEEventSelector.getHSEEvents({ siteId, store }),
      await CheckSelector.getAllChecks({ siteId, store })
    ]);

    const allAssignedWorkOrders = _.isEmpty(templatesIds)
      ? tasks?.filter((task) => !task.newWorkOrder && !task.newWorkRequest)
      : tasks?.filter(
          (task) =>
            !task.newWorkOrder && !task.newWorkRequest && templatesIds?.includes(task?.rtNumber)
        );

    const allAssignedInspections = inspections;
    const allAssignedChecks = checks;

    const newWorkRequestList = getWorkRequestData
      ? _.isEmpty(templatesIds)
        ? tasks?.filter((task) => task.newWorkRequest)
        : tasks?.filter((task) => task.newWorkRequest && templatesIds?.includes(task?.rtNumber))
      : [];

    return {
      assignedTasksList: [
        ...allAssignedWorkOrders,
        ...allAssignedInspections,
        ...allAssignedChecks
      ],
      newWorkRequestList: newWorkRequestList || [],
      hseEvents
    };
  } else {
    const { tasks, inspection, checks } = store;
    let { tasksList = [] } = tasks;
    let { inspectionsList = [] } = inspection;
    let { checksList = [] } = checks;

    tasksList = siteId
      ? tasksList?.filter(
          (t) =>
            t?.siteId === siteId ||
            t?.relatedAssetId === siteId ||
            t?.primaryAssetId === siteId ||
            t?.sites?.includes(siteId)
        )
      : tasksList;

    inspectionsList = siteId
      ? inspectionsList?.filter(
          (t) =>
            t?.siteId === siteId ||
            t?.relatedAssetId === siteId ||
            t?.primaryAssetId === siteId ||
            t?.sites?.includes(siteId)
        )
      : inspectionsList;

    checksList = siteId
      ? checksList?.filter(
          (t) =>
            t?.siteId === siteId ||
            t?.relatedAssetId === siteId ||
            t?.primaryAssetId === siteId ||
            t?.sites?.includes(siteId)
        )
      : checksList;

    const allAssignedWorkOrders = _.isEmpty(templatesIds)
      ? tasksList?.filter((task) => !task.newWorkOrder && !task.newWorkRequest)
      : tasksList?.filter(
          (task) =>
            !task.newWorkOrder && !task.newWorkRequest && templatesIds?.includes(task?.rtNumber)
        );
    const allAssignedInspections = inspectionsList;
    const allAssignedChecks = checksList;

    const newWorkRequestList = getWorkRequestData
      ? _.isEmpty(templatesIds)
        ? tasks?.filter((task) => task.newWorkRequest)
        : tasks?.filter((task) => task.newWorkRequest)
      : [];

    return {
      assignedTasksList: [
        ...allAssignedWorkOrders,
        ...allAssignedInspections,
        ...allAssignedChecks
      ],
      newWorkRequestList: newWorkRequestList || []
    };
  }
};
