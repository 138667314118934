/* eslint-disable no-unused-vars */
import { store } from 'redux/store';
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import http from './http.service';
import Promisable from './promisable.service';
import WorkOrderService from './workorder.service';
import { checkOldAndNewArray } from './check-old-and-new-array';

import { inspectionActions } from '@redux/slices/inspection';
import { getAppDispatch } from '@utilities/dispatch.util';
import { transformFormWrapperDataToNewInspection } from '../transformers/inspection';
import LocalStorage from '@utilities/local-storage.util';
import { decodeToken } from '@utilities/authentication';
import { cacheActions } from '@redux/slices/cache';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
import { dropdownPicklistsNames } from '@redux/slices/picklists/picklists-slice';
import { checkActions } from '@redux/slices/checks';
import { deleteTaskFromLocalStorageAfterSuccessFullSynced } from '@utilities/attachments';

const InspectionService = {
  setNewWorkInspectionData: async ({ inspectionData, inspectionId }) => {
    const dispatch = getAppDispatch();
    const { inspection } = store.getState();
    const transformData = transformFormWrapperDataToNewInspection({
      inspectionData,
      inspectionId
    });

    if (!_.isEmpty(inspectionId)) {
      dispatch?.(
        inspectionActions.updateNewInspection({
          inspectionId,
          data: {
            ...transformData,
            updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            containWorkOrder: inspectionData?.containWorkOrder
              ? true
              : inspectionData?.inspectionWorkOrder?.length
              ? true
              : inspectionData?.inspectionWorkRequest?.length
              ? true
              : false
          }
        })
      );
    } else {
      dispatch?.(
        inspectionActions.addNewInspection({
          createdAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
          ...transformData,
          containWorkOrder: inspectionData?.containWorkOrder
            ? true
            : inspectionData?.inspectionWorkOrder?.length
            ? true
            : inspectionData?.inspectionWorkRequest?.length
            ? true
            : false
        })
      );
    }
    if (inspectionData?.inspectionWorkOrder?.length) {
      inspectionData?.inspectionWorkOrder?.map(async (workOrder) => {
        const submitData = {
          ...workOrder,
          detailInspections: [transformData?.id],
          inspId: transformData?.id,
          recordNumber: null,
          recordTitle:
            inspection?.inspectionFormsUserCacheMap[transformData?.inspectionFormId]?.name || null
        };
        if (workOrder?.isDone) {
          await WorkOrderService.setNewWorkOrderDoneData(
            {
              ...submitData,
              status: 'Pending sync',
              siteId: transformData?.siteId || null,
              equipmentId: transformData?.equipmentId || null,
              pipelineId: transformData?.pipelineId || null
            },
            null,
            true
          );
        } else {
          await WorkOrderService.setNewWorkOrderTodoData(
            {
              ...submitData,
              status: 'Pending sync',
              siteId: transformData?.siteId || null,
              equipmentId: transformData?.equipmentId || null,
              pipelineId: transformData?.pipelineId || null
            },
            null,
            true
          );
        }
      });
    }
    if (inspectionData?.inspectionWorkRequest?.length) {
      inspectionData?.inspectionWorkRequest?.map(async (workRequest) => {
        const submitData = {
          ...workRequest,
          detailInspections: [transformData?.id],
          inspId: transformData?.id,
          recordNumber: null,
          recordTitle:
            inspection?.inspectionFormsUserCacheMap[transformData?.inspectionFormId]?.name || null
        };

        await WorkOrderService.setNewWorkRequestData(
          {
            ...submitData,
            status: 'Pending sync',
            siteId: transformData?.siteId || null,
            equipmentId: transformData?.equipmentId || null,
            pipelineId: transformData?.pipelineId || null
          },
          null,
          true
        );
      });
    }
  },

  getInspectionFormUserCacheCache: async ({
    initialLogin = false,
    lastSuccessFullRefreshTime,
    isSettingUpdated
  }) => {
    const dispatch = getAppDispatch();
    const { inspection } = store.getState();
    dispatch?.(inspectionActions.setLoading(true));
    const cachingFailure = localStorage.getItem('cachingFailure');
    const changedAfter = lastSuccessFullRefreshTime;

    if (!JSON.parse(cachingFailure)) {
      http.setJWT();
      const res = await Promise.all([
        Promisable.asPromise(
          http.get(
            `/InspectionForms/UserCache?page=0&changedAfter=${
              !initialLogin
                ? changedAfter
                  ? inspection?.inspectionFormsUserCache?.length === 0
                    ? ''
                    : changedAfter
                  : ''
                : ''
            }` //=${!initialLogin ? changedAfter : ''}
          )
        )
      ]);
      const [[inspectionFormUserCache]] = res;

      if (
        !initialLogin &&
        // inspectionFormUserCache?.data?.length &&
        inspection?.inspectionFormsUserCache?.length &&
        !isSettingUpdated
      ) {
        const combineNewAndOldArray = checkOldAndNewArray({
          oldArray: inspection?.inspectionFormsUserCache,
          oldArrayMap: inspection?.inspectionFormsUserCacheMap,
          newArray: inspectionFormUserCache?.data,
          idName: 'inspectionFormId'
        });

        dispatch?.(inspectionActions.setInspectionFormsUserCache(combineNewAndOldArray));
      } else {
        if (inspectionFormUserCache.data && inspectionFormUserCache.data.length > 0)
          dispatch?.(inspectionActions.setInspectionFormsUserCache(inspectionFormUserCache.data));
      }

      const status = res.filter(([success]) => success).length === res.length;
      dispatch?.(globalProgressBarAction.setLoadStatus(15));

      return [status, !status];
    } else throw new Error('Api failed to update');
  },

  getAllInspectionList: async (
    sync,
    initialLogin,
    singleSync = false,
    lastSuccessFullRefreshTime,
    isSettingUpdated
  ) => {
    const dispatch = getAppDispatch();
    const { inspection, cache, settings } = store.getState();

    const changedAfter = settings?.isSettingUpdated ? '' : cache?.lastSuccessFullRefreshTime;

    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      dispatch?.(inspectionActions.setLoading(true));
      http.setJWT();
      const allInspectionMap =
        _.size(inspection?.inspectionsList) > 0 && !initialLogin
          ? inspection?.inspectionsList.reduce((acc, ins) => {
              acc[ins._id] = true;
              return acc;
            }, {})
          : {};

      const dropdownStaticPicklistsNames = ['pass_fail_list', 'pass_deficiency_fail'];

      const inspectionAndSyncedInspectionAPI = [
        Promisable.asPromise(http.get(`/InspectionCache/MobileCache?page=0`)),
        Promisable.asPromise(http.get(`/SyncedInspectionCache/MobileCache?page=0`))
      ];

      const picklistsAPIs = dropdownPicklistsNames?.map((sysName) =>
        Promisable.asPromise(http.get(`/Picklists?sysName=${sysName}&orderBy=value&page=0`))
      );
      const staticPicklistsAPIs = dropdownStaticPicklistsNames?.map((sysName) =>
        Promisable.asPromise(http.get(`/Picklists/staticLists?type=${sysName}`))
      );

      const res = await Promise.all([
        ...(singleSync
          ? [...inspectionAndSyncedInspectionAPI]
          : [
              ...inspectionAndSyncedInspectionAPI,
              Promisable.asPromise(http.get(`/InspectionTypes/?page=0`)),
              Promisable.asPromise(http.get(`/InspectionInspectorUserGroups/?page=0`)),
              Promisable.asPromise(http.get(`/InspectionInspectorUsers/?page=0`)),

              Promisable.asPromise(http.get(`/JurisdictionNumbers?page=0`)),
              Promisable.asPromise(http.get(`/InspectionSchedules/mobile`)),

              ...picklistsAPIs,
              ...staticPicklistsAPIs
            ])
      ]);

      let [
        [inspectionCache = []] = [],
        [syncedInspectionCache = []] = [],

        [inspectionTypes = []] = [],
        [inspectionInspectorUserGroups = []] = [],
        [inspectionInspectorUsers = []] = [],

        [jurisdictionNumbers = []] = [],
        [inspectionLastDate = []] = [],

        [pipelineCathodicProtection = []] = [],
        [pipelineAssignedBatchingInterval = []] = [],
        [pipelineAssignedPiggingInterval = []] = [],
        [externalProtection = []] = [],
        [internalProtection = []] = [],
        [material = []] = [],
        [operationsArea = []] = [],
        [pipelineReceiverType = []] = [],
        [pipelineSenderType = []] = [],
        [pipelineType = []] = [],
        [equipmentCategoryTypeSubtype = []] = [],
        [equipmentValveLocation = []] = [],
        [capacity = []] = [],
        [concentrationUnits = []] = [],
        [largeMeasurement = []] = [],
        [timeUnit = []] = [],
        [failureCriteria = []] = [],
        [flowRate = []] = [],
        [condition = []] = [],
        [siteType = []] = [],
        [cylinderType = []] = [],
        [constructionMaterial = []] = [],
        [structureType = []] = [],
        [gaugeBoardUOM = []] = [],
        [equipmentInsulationMaterial = []] = [],
        [incidentCategory = []] = [],

        [HSEProactiveEffortType = []] = [],
        [HSEFocusAreas = []] = [],
        [HSEVendors = []] = [],
        [functionalGroup = []] = [],
        [species = []] = [],
        [wildlifeIncidentType = []] = [],
        [incidentStatus = []] = [],

        [passFailList = []] = [],
        [passDeficiencyFail = []] = []
      ] = res;

      const inspectionAll = [
        ...(syncedInspectionCache?.data?.map((ins) => ({
          ...ins,
          status: 'Synced',
          type: 'Inspection',
          id: ins?.inspectionNumber || null,
          _id: ins?.inspectionNumber || null,
          RunName: ins?.runName || null
        })) || []),

        ...(inspectionCache?.data?.map((ins) => ({
          ...ins,
          status: 'To do',
          type: 'Inspection',

          id: uuidv4() || null,
          _id: ins?.inspectableItemId || uuidv4()
        })) || [])
      ];

      let newSyncedInspections = [];
      if (!_.isEmpty(allInspectionMap)) {
        newSyncedInspections = inspectionAll.map((ins) => {
          if (!allInspectionMap[ins._id]) {
            return {
              syncDateCustom: moment().format('YYYY-MM-DD'),
              ...ins
            };
          } else return ins;
        });
      } else newSyncedInspections = inspectionAll;
      dispatch?.(inspectionActions.setSyncedInspectionCache(syncedInspectionCache?.data || []));

      if (!singleSync) {
        dispatch?.(
          inspectionActions.setInspectionTypes(
            inspectionTypes?.data?.items?.filter(
              (x) => x?.isActive && (x?.module === 'Inspection' || x?.module === 'Incident')
            ) || []
          )
        );
        dispatch?.(
          checkActions.setCheckTypes(
            inspectionTypes?.data?.items?.filter(
              (x) => x?.isActive && x?.module === 'AssetCheck'
            ) || []
          )
        );
        dispatch?.(
          inspectionActions.setInspectionInspectorUserGroups(
            inspectionInspectorUserGroups?.data || []
          )
        );
        dispatch?.(
          inspectionActions.setInspectionInspectorUsers(inspectionInspectorUsers?.data || [])
        );
        dispatch?.(
          inspectionActions.setInspectionFormMenuDropdown({
            pipelineCathodicProtection: pipelineCathodicProtection?.data?.items || [],
            pipelineAssignedBatchingInterval: pipelineAssignedBatchingInterval?.data?.items || [],
            pipelineAssignedPiggingInterval: pipelineAssignedPiggingInterval?.data?.items || [],
            externalProtection: externalProtection?.data?.items || [],
            internalProtection: internalProtection?.data?.items || [],
            material: material?.data?.items || [],
            operationsArea: operationsArea?.data?.items || [],
            pipelineReceiverType: pipelineReceiverType?.data?.items || [],
            pipelineSenderType: pipelineSenderType?.data?.items || [],
            pipelineType: pipelineType?.data?.items || [],
            equipmentCategoryTypeSubtype: equipmentCategoryTypeSubtype?.data?.items || [],
            equipmentValveLocation: equipmentValveLocation?.data?.items || [],
            capacity: capacity?.data?.items || [],
            concentrationUnits: concentrationUnits?.data?.items || [],
            largeMeasurement: largeMeasurement?.data?.items || [],
            timeUnit: timeUnit?.data?.items || [],
            failureCriteria: failureCriteria?.data?.items || [],
            passFailList: passFailList?.data || [],
            passDeficiencyFail: passDeficiencyFail?.data || [],
            jurisdictionNumbers: jurisdictionNumbers?.data?.items || [],
            flowRate: flowRate?.data?.items || [],
            condition: condition?.data?.items || [],
            siteType: siteType?.data?.items || [],
            cylinderType: cylinderType?.data?.items || [],
            constructionMaterial: constructionMaterial?.data?.items || [],
            structureType: structureType?.data?.items || [],
            gaugeBoardUOM: gaugeBoardUOM?.data?.items || [],
            equipmentInsulationMaterial: equipmentInsulationMaterial?.data?.items || [],
            incidentCategory: incidentCategory?.data?.items || [],
            HSEProactiveEffortType: HSEProactiveEffortType?.data?.items || [],
            HSEFocusAreas: HSEFocusAreas?.data?.items || [],
            HSEVendors: HSEVendors?.data?.items || [],
            functionalGroup: functionalGroup?.data?.items || [],
            species: species?.data?.items || [],
            wildlifeIncidentType: wildlifeIncidentType?.data?.items || [],
            incidentStatus: incidentStatus?.data?.items || [],
            inspectionLastDate: inspectionLastDate?.data?.items || []
          })
        );
      }

      // if (
      //   !initialLogin &&
      //   inspection?.inspectionsList?.length &&
      //   !settings?.isSettingUpdated
      //   // &&
      //   // newSyncedInspections?.length
      // ) {
      //   const combineNewAndOldArray = checkOldAndNewArray({
      //     oldArray: inspection?.inspectionsList,
      //     oldArrayMap: allInspectionMap,
      //     newArray: newSyncedInspections,
      //     idName: '_id'
      //   });

      //   dispatch?.(inspectionActions.setInspectionList(combineNewAndOldArray || []));
      // } else {
      dispatch?.(inspectionActions.setInspectionList(newSyncedInspections || []));

      dispatch?.(inspectionActions.setLoading(false));
      if (sync) dispatch?.(cacheActions.updateSyncing(false));

      const status = res.filter(([success]) => success).length === res.length;
      dispatch?.(globalProgressBarAction.setLoadStatus(13));

      return [status, !status];
    } else throw new Error('Api failed to update');
  },

  deleteNewInspection: async (id) => {
    const dispatch = getAppDispatch();
    dispatch?.(inspectionActions.deleteInspection(id));

    const userId = store.getState().auth.userId;
    await deleteTaskFromLocalStorageAfterSuccessFullSynced({ userId, inspections: true, id });
  },

  geInspectionFromStorage: async (userId) => {
    const dispatch = getAppDispatch();
    let pendingInspections = [];
    const pendingSyncAndInProgressTasksOfCurrentUser = (
      (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) ||
      []
    )?.find((item) => item.userId === userId);
    if (!_.isEmpty(pendingSyncAndInProgressTasksOfCurrentUser))
      pendingInspections = !_.isEmpty(pendingSyncAndInProgressTasksOfCurrentUser)
        ? pendingSyncAndInProgressTasksOfCurrentUser?.inspections
        : [];

    dispatch?.(inspectionActions.setLocalStorageInspections(pendingInspections || []));
  }
};

export default InspectionService;
