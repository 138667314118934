import React from 'react';
import { Link } from 'react-router-dom';

import Button from '@components/button';
import Icon from '@components/icon-component';

const ThankyouMessage = () => {
  return (
    <div className="op-login-container">
      <div className="op-login">
        <Icon className="mx-auto mb-9" name={'email-guy'} />
        <p className="body-regular mb-7 px-3">Thanks for your message.</p>
        <p className="body-regular mb-9 px-3">You will hear from us very soon.</p>
        <Link to={'/'}>
          <Button text="Back to login page" />
        </Link>
        <div className="op-divider mt-9"></div>
        <div className="flex flex-wrap justify-between mt-2">
          <Link className="input-bold op-anchor-link" to={'/'}>
            Log in
          </Link>
          <Link className="input-bold op-anchor-link" to="/PasswordReset">
            Password reset
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ThankyouMessage;
