const checkAllBookmarkIsSpecificType = ({ bookmarks }) => {
  let siteListing = 0,
    equipmentListing = 0,
    structureListing = 0,
    pipelineListing = 0,
    tasksListing = 0;

  for (let bookmark of bookmarks) {
    switch (bookmark.type) {
      case 'Sites':
        ++siteListing;
        break;
      case 'Equipment':
        ++equipmentListing;
        break;

      case 'Structure':
        ++structureListing;
        break;

      case 'Pipeline':
        ++pipelineListing;
        break;

      case 'Site Details':
      case 'Parent Site Details':
      case 'Equipment Details':
      case 'Structure Details':
      case 'Pipeline Details':
      case 'default bookmark':
      case 'default':
      case 'Tasks':
        ++tasksListing;
        break;
    }
  }
  const bookmarksLength = bookmarks?.length || 0;
  if (siteListing === bookmarksLength) return 'site';
  else if (equipmentListing === bookmarksLength) return 'equipment';
  else if (structureListing === bookmarksLength) return 'structure';
  else if (pipelineListing === bookmarksLength) return 'pipeline';
  else if (tasksListing === bookmarksLength) return 'tasks';
  else return 'all';
};

const getCompleteTasksList = ({ tasksList, hseEvent, inspections, checks, picklistsMap }) => {
  let tasksLists = [...tasksList, ...inspections, ...hseEvent, ...checks];

  tasksLists = tasksLists.map((task) => {
    return {
      ...task,
      status: task?.woId
        ? task?.status
        : ['To do', 'Pending sync', 'In progress', 'Synced'].includes(task?.status)
        ? task?.status
        : task?.link
        ? 'Synced'
        : 'To do',
      priorityId: task?.priority,
      priority: picklistsMap[task?.priority]?.value,
      workStatus: picklistsMap[task?.status]?.value
    };
  });
  return tasksLists;
};

export { checkAllBookmarkIsSpecificType, getCompleteTasksList };
