import * as yup from 'yup';

export const formWrapperSchema = ({ currentForm, currData }) => {
  const schemaArray = [];
  const componentsNames = [];
  const formSectionsList =
    (currentForm?.formSections &&
      currentForm?.formSections?.slice()?.sort((a, b) => a?.order - b?.order)) ||
    [];
  const setDefaultOrAutoPopulateValuesForFields = [];
  for (let a of formSectionsList) {
    const { formFields = [] } = a;
    const sortedFormFields = formFields?.slice()?.sort((a, b) => a?.row - b?.row);
    sortedFormFields?.forEach((x) => {
      let removeSpecialCharactersForName = removeSpecialCharacters(
        x?.label?.toString()?.toLowerCase()
      );
      removeSpecialCharactersForName = `${removeSpecialCharactersForName}-${x?.formFieldId}`;
      x?.isRequired && componentsNames.push(removeSpecialCharactersForName);

      if (x?.questionTypeText === 'PhotoQuestion') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.object().shape({
              notes: yup.string()?.nullable(),
              photos: yup
                .array()
                .of(
                  yup.object().shape({
                    category: yup.string().nullable(),
                    caption: yup.string().nullable(),
                    image: yup.mixed()
                  })
                )
                .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
                .min(1)
            })
          })
        };

        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'YN') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.bool().default(false)
          })
        };

        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Date_Nullable' || x?.questionTypeText === 'Date') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .date()
              ?.nullable()
              .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
          })
        };

        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText
        });

        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Dropdown' || x?.questionTypeText === 'Dropdown_PL') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .string()
              ?.nullable()
              .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
          })
        };
        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText
        });
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Dropdown_PL_Multiple') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .array()
              .of(
                yup.object().shape({
                  label: yup.string(),
                  value: yup.string()
                })
              )
              .nullable()
              .test('isRequired', 'Required', (value) => {
                if (x.isRequired) {
                  return Array.isArray(value) && value.length > 0;
                }
                return true;
              })
          })
        };
        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText
        });
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'InspectionResult') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.object().shape({
              inspectionResultAnswer: yup
                .string()
                ?.nullable()
                .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
            })
          })
        };
        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}.inspectionResultAnswer`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText,
          defaultResponseValue: x?.defaultResponseValue || null,
          defaultValue: x?.defaultValue || null
        });
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'CheckResult') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.object().shape({
              checkResultAnswer: yup
                .string()
                ?.nullable()
                .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
            })
          })
        };
        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}.checkResultAnswer`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText,
          defaultResponseValue: x?.defaultResponseValue || null,
          defaultValue: x?.defaultValue || null
        });
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Signature') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.object().shape({
              signaturePhoto: yup
                .mixed()
                .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
            })
          })
        };

        schemaArray.push(z);
      }
      if (
        x?.questionTypeText === 'LongText' ||
        x?.questionTypeText === 'Text' ||
        x?.questionTypeText === 'PhoneNumber'
      ) {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .string()
              ?.nullable()
              .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
          })
        };
        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText
        });
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Numeric') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.lazy((value) =>
              value === ''
                ? yup
                    .string()
                    .test('isRequired', 'Required', (value) =>
                      x.isRequired ? Boolean(value) : true
                    )
                : yup
                    .number()
                    .nullable()
                    .test('isRequired', 'Required', (value) => {
                      const { isRequired } = x;
                      return !isRequired || (isRequired && value !== undefined && value !== null);
                    })
            )
          })
        };
        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText
        });
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Readings' || x?.questionTypeText === 'Readings_PL') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.object().shape({
              textBoxValue: yup
                .number()
                ?.nullable()
                .transform((value, originalValue) => {
                  if (!originalValue) return null;
                  return parseFloat(originalValue);
                })
                .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true)),

              textBoxUnitValue: yup
                .string()
                ?.nullable()
                .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
            })
          })
        };
        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText
        });
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Time') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .string()
              ?.nullable()
              .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
          })
        };

        schemaArray.push(z);
        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText
        });
      }
      if (x?.questionTypeText === 'YNComment') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.object().shape({
              value: yup
                .string()
                .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true)),
              comment: yup.string().when('value', {
                is: 'No',
                then: yup.string().required('Required'),
                otherwise: yup.string().nullable()
              })
            })
          })
        };

        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'PassFail' || x?.questionTypeText === 'PassFailNA') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.object().shape({
              answer: yup
                .mixed()
                .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true)),
              assessment: yup.string()?.nullable(),

              notes: yup.string()?.nullable(),
              photos: yup.array().of(
                yup.object().shape({
                  category: yup.string().nullable(),
                  caption: yup.string().nullable(),
                  image: yup.mixed()
                })
              )
            })
          })
        };

        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Scale') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.object().shape({
              answer: yup
                .number()
                .test('isRequired', 'Required', (value) => {
                  const { isRequired } = x;
                  return !isRequired || (isRequired && value !== undefined && value !== null);
                })
                .moreThan(-1, 'should not be negative or less than negative'),

              notes: yup.string()?.nullable(),
              photos: yup.array().of(
                yup.object().shape({
                  category: yup.string().nullable(),
                  caption: yup.string().nullable(),
                  image: yup.mixed()
                })
              )
            })
          })
        };
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Notes') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .string()
              ?.nullable()
              .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
          })
        };
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'WorkOrder') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .array()
              .of(
                yup.object().shape({
                  id: yup.string(),
                  title: yup.string().required('Required'),
                  priority: yup.string().required('Required'),
                  type: yup.string().required('Required'),
                  trade: yup.string().when('tradeRequired', {
                    is: true,
                    then: yup.string().required('Required'),
                    otherwise: yup.string()
                  }),
                  requestDueDate: yup.date().required('Required'),
                  description: yup.string().required('Required'),
                  administrator: yup.string(),
                  responsibleUsers: yup.string(),
                  photo: yup.array().of(
                    yup.object().shape({
                      category: yup.string().nullable(),
                      caption: yup.string().nullable(),
                      image: yup.mixed().required('A file is required')
                    })
                  ),
                  document: yup.array().of(
                    yup.object().shape({
                      category: '',
                      files: yup.mixed()
                    })
                  )
                })
              )
              .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
          })
        };
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'Photos') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .object()
              .shape({
                photos: yup.array().of(
                  yup.object().shape({
                    category: yup.string().nullable(),
                    caption: yup.string().nullable(),
                    image: yup.mixed().required('A file is required')
                  })
                ),
                documents: yup.array().of(
                  yup.object().shape({
                    category: '',
                    files: yup.mixed()
                  })
                )
              })

              .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
          })
        };
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'WorkRequest') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup
              .array()
              .of(
                yup.object().shape({
                  id: yup.string(),
                  title: yup.string().required('Required'),
                  priority: yup.string().required('Required'),
                  type: yup.string().required('Required'),
                  trade: yup.string(),
                  requestDueDate: yup.date()?.nullable(),
                  description: yup.string().required('Required'),
                  photo: yup.array().of(
                    yup.object().shape({
                      category: yup.string().nullable(),
                      caption: yup.string().nullable(),
                      image: yup.mixed().required('A file is required')
                    })
                  ),
                  document: yup.array().of(
                    yup.object().shape({
                      category: '',
                      files: yup.mixed()
                    })
                  )
                })
              )
              .test('isRequired', 'Required', (value) => (x.isRequired ? Boolean(value) : true))
          })
        };
        schemaArray.push(z);
      }
      if (x?.questionTypeText === 'PSV_Section') {
        let z = {
          [`${removeSpecialCharactersForName}`]: yup.object().shape({
            [x?.formFieldId]: yup.array().of(
              yup.object().shape({
                name: yup.string(),
                equipmentId: yup.string(),
                verificationStatus: yup.string(),
                isDetached: yup.boolean(),
                isReattach: yup.boolean(),
                isNew: yup.boolean(),
                category: yup.string(),
                equipmentCategory: yup.string(),
                equipmentType: yup.string(),
                equipmentSubType: yup.string(),
                type: yup.string(),
                subType: yup.string(),
                serialNumber: yup.string(),
                equipmentNumber: yup.string(),
                status: yup.string(),
                psvCategoryTypeSubType: yup.string(),
                valveLocation: yup.string(),
                pressure: yup.object().shape({
                  textBoxValue: yup.number().nullable(),
                  textBoxUnitValue: yup.string()
                }),
                manufacturer: yup.string(),
                model: yup.string(),
                fieldTagNumber: yup.string(),
                crnNumber: yup.string(),
                psvCapacity: yup.object().shape({
                  textBoxValue: yup.number().nullable(),
                  textBoxUnitValue: yup.string()
                }),
                inletSize: yup.object().shape({
                  textBoxValue: yup.number().nullable(),
                  textBoxUnitValue: yup.string()
                }),
                inletJointStyle: yup.string(),
                outletSize: yup.object().shape({
                  textBoxValue: yup.number().nullable(),
                  textBoxUnitValue: yup.string()
                }),
                outletJointStyle: yup.string(),
                lastServiceDate: yup.string()?.nullable(),
                lastServiceBy: yup.string(),
                comments: yup.string(),
                dataPoints: yup.array().of(
                  yup.object().shape({
                    Id: yup.string(),
                    key: yup.string(),
                    value: yup.string(),
                    title: yup.string()
                  })
                ),
                errorExists: yup.string()?.required('true')
              })
            )
          })
        };

        setDefaultOrAutoPopulateValuesForFields.push({
          removeSpecialCharactersForName: `${removeSpecialCharactersForName}-${x?.formFieldId}`,
          name: `${removeSpecialCharactersForName}.${x?.formFieldId}`,
          sourceEntity: x?.sourceEntity,
          SourceProperty: x?.SourceProperty,
          questionTypeText: x?.questionTypeText
        });

        schemaArray.push(z);
      }
    });
  }
  const object = schemaArray?.reduce((result, item) => {
    const key = Object.keys(item)[0];
    const value = item[key];
    result[key] = value;
    return result;
  }, {});

  const workLogSchemaForReadingSchema =
    currData?.type === 'Work Order' && currData?.readingIsRequired
      ? {
          readingIsRequired: yup.boolean().nullable(),
          reading: yup.number().when('readingIsRequired', {
            is: true,
            then: yup.number().required('Required'),
            otherwise: yup.string().nullable()
          }),
          unit: yup.string().when('readingIsRequired', {
            is: true,
            then: yup.string().required('Required'),
            otherwise: yup.string().nullable()
          })
        }
      : {};

  const newChecksSchema =
    currData?.type === 'new-check'
      ? {
          identifier: yup.string()?.nullable(),
          location: yup.string()?.nullable(),
          description: yup.string()?.nullable()
        }
      : {};

  return {
    currentFormSchema: object,
    workLogSchemaForReadingSchema,
    componentsNames,
    setDefaultOrAutoPopulateValuesForFields,
    newChecksSchema
  };
};

const removeSpecialCharacters = (str) => {
  const pattern = /[^a-zA-Z0-9 ]/g;
  const result = str.replace(pattern, '');
  return result;
};
