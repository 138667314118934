/* eslint-disable no-unused-vars */
import React from 'react';

import Modal from '../..';
import Icon from '@components/icon-component';

import style from './style.module.scss';

const DeleteModal = ({
  open,
  setOpen,
  confirmDeletion = false,
  confirmDeletionBookmark = false,
  discordChangesAndResetWorkOrder = false,
  discordChangesAndResetInspection = false,
  discordChangesAndResetHseEvent = false,
  discordChangesAndResetCheck = false,
  resetApp = false,
  recordType = '',
  handleDelete,
  resetAppEvent,
  disabledDeleteButton = false
}) => {
  const syncModalIcon = React.useMemo(() => {
    if (confirmDeletion || confirmDeletionBookmark) return <Icon name={'delCircle'} />;
    else if (
      discordChangesAndResetWorkOrder ||
      discordChangesAndResetInspection ||
      discordChangesAndResetHseEvent ||
      discordChangesAndResetCheck
    )
      return <Icon name={'delCircle'} />;
    else if (resetApp) return <Icon name={'reset-app-icon'} />;
  }, [
    confirmDeletion,
    discordChangesAndResetWorkOrder,
    discordChangesAndResetInspection,
    confirmDeletionBookmark,
    discordChangesAndResetHseEvent,
    resetApp
  ]);

  const statusText = React.useMemo(() => {
    if (confirmDeletion || confirmDeletionBookmark) return <>Confirm deletion</>;
    // if (discordChangesAndResetCheck) return <>Discard changes h</>;
    else if (discordChangesAndResetWorkOrder) return <>Discard changes and reset work order?</>;
    else if (discordChangesAndResetInspection) return <>Discard changes and reset inspection?</>;
    else if (discordChangesAndResetCheck) return <>Discard changes and reset check?</>;
    else if (resetApp) return <>You sure you want to reset all app data?</>;
  }, [
    confirmDeletion,
    discordChangesAndResetWorkOrder,
    discordChangesAndResetInspection,
    discordChangesAndResetCheck,
    confirmDeletionBookmark,
    resetApp
  ]);

  const modalTextUpper = React.useMemo(() => {
    if (confirmDeletion) return <>Are you sure you want to delete this {recordType}?</>;
    if (confirmDeletionBookmark) return <>Are you sure you want to delete this bookmark?</>;
    else if (discordChangesAndResetWorkOrder)
      return <>Are you sure you want to discard all changes made to this work order?</>;
    else if (discordChangesAndResetInspection)
      return <>Are you sure you want to discard all changes made to this inspection?</>;
    else if (discordChangesAndResetHseEvent)
      return <>Are you sure you want to discard all changes made to this inspection?</>;
    else if (discordChangesAndResetCheck)
      return <>Are you sure you want to discard all changes made to this check?</>;
    else if (resetApp)
      return (
        <>
          You will be logged out, lose all work that has not been synced and need to redownload all
          of your tasks and assets
          <p
            style={{
              marginTop: '16px',
              marginBottom: '32px'
            }}
          >
            No take backs.
          </p>
        </>
      );
  }, [
    confirmDeletion,
    discordChangesAndResetWorkOrder,
    discordChangesAndResetInspection,
    discordChangesAndResetHseEvent,
    confirmDeletionBookmark,
    resetApp
  ]);

  const modalBodyInner = React.useMemo(() => {
    if (confirmDeletion)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className={`btn-fill button-large ${style.deleteBtnColor}`}
              onClick={handleDelete}
              disabled={disabledDeleteButton}
            >
              Delete
            </button>
          </div>
        </div>
      );
    else if (confirmDeletionBookmark)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className={`btn-fill button-large ${style.deleteBtnColor}`}
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
      );
    else if (discordChangesAndResetWorkOrder)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className={`btn-fill button-large ${style.deleteBtnColor}`}
              onClick={handleDelete}
            >
              Discard changes
            </button>
          </div>
        </div>
      );
    else if (discordChangesAndResetInspection)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className={`btn-fill button-large ${style.deleteBtnColor}`}
              onClick={handleDelete}
            >
              Discard changes
            </button>
          </div>
        </div>
      );
    else if (discordChangesAndResetCheck)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className={`btn-fill button-large ${style.deleteBtnColor}`}
              onClick={handleDelete}
            >
              Discard changes
            </button>
          </div>
        </div>
      );
    else if (resetApp)
      return (
        <div>
          <div className={style.modalBtn}>
            <button
              className={`btn-fill button-large ${style.deleteBtnColor}`}
              onClick={resetAppEvent}
            >
              Yes, reset app data
            </button>
          </div>
        </div>
      );
  }, [
    confirmDeletionBookmark,
    confirmDeletion,
    discordChangesAndResetWorkOrder,
    discordChangesAndResetInspection,
    handleDelete,
    resetApp,
    resetAppEvent
  ]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={syncModalIcon}
      statusText={statusText}
      modalTextUpper={modalTextUpper}
      textBtn={resetApp ? 'no thanks' : 'NeverMind'}
    >
      {modalBodyInner}
    </Modal>
  );
};

export default DeleteModal;
