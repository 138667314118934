import useWindowDimensions from '@hooks/useWindowDimensions';
import { useMemo } from 'react';

const gridSystem = (grid) => {
  const { width } = useWindowDimensions();

  const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
  };

  function getBreakpointKey(width) {
    if (width < breakpoints.sm) {
      return 'xs';
    } else if (width < breakpoints.md) {
      return 'sm';
    } else if (width < breakpoints.lg) {
      return 'md';
    } else if (width < breakpoints.xl) {
      return 'lg';
    } else if (width < breakpoints.xxl) {
      return 'xl';
    } else {
      return 'xxl';
    }
  }
  const breakpointKey = getBreakpointKey(width);

  let output = () => {
    if (breakpointKey in grid) {
      return grid[breakpointKey];
    } else {
      const breakpointKeys = Object.keys(breakpoints);
      const index = breakpointKeys.indexOf(breakpointKey);
      let closestKey = null;

      for (let i = index; i >= 0; i--) {
        if (breakpointKeys[i] in grid) {
          closestKey = breakpointKeys[i];
          break;
        }
      }

      if (closestKey in grid) {
        return grid[closestKey];
      } else {
        if (!closestKey) {
          // no breakpoint in rest that's closer
          const keysInRest = Object.keys(grid);
          const closestKeys = breakpointKeys.filter(
            (key) => breakpoints[key] > breakpoints[breakpointKey] && keysInRest.includes(key)
          );
          closestKey = closestKeys.length ? closestKeys[0] : null;
        }

        return grid[closestKey];
      }
    }
  };

  const gridTemplateColumns = useMemo(() => 24 / output(), [grid]);

  return gridTemplateColumns;
};

export default gridSystem;
