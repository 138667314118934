/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import useNetworkStatus from '@hooks/useNetworkStatus';
import { settingsActions } from '@redux/slices/settings';

const SyncModalEffecting = () => {
  const dispatch = useDispatch();
  const [isOnline] = useNetworkStatus();
  const {
    lastSyncReminder,
    settings = {},
    syncReminderFrequency,
    isSettingUpdated
  } = useSelector((state) => state.settings);
  const { syncReminderNum, syncReminderTimeframe } = settings;
  const { syncReminder } = useSelector((state) => state.cache);

  useEffect(() => {
    const timeLimit =
      syncReminderNum * (syncReminderTimeframe === 'days' ? 24 : 1) * 60 * 60 * 1000;

    const timer = setInterval(() => {
      const isFormOpen = location.pathname.includes('/work-order') ? true : false;
      const lastSyncReminderTime = moment(lastSyncReminder);

      const timePassed = moment().diff(lastSyncReminderTime);

      let connection = 'wifi';

      if ('connection' in navigator) {
        connection = navigator.connection.type || 'wifi';
      }

      if (
        ((settings.autoConnectPromptSyncSchedule === 'wifi' && connection === 'wifi') ||
          settings.autoConnectPromptSyncSchedule === 'any') && // both of these 2 will happen to have on connectivity
        isOnline === true && //check if online
        isFormOpen === false && //if form
        timePassed >= timeLimit && // timer logic would be implemented here
        syncReminder !== true && // loading home page, would not show 2nd modal if page loaded
        syncReminderFrequency !== 1 // loading home page, would not show 2nd modal if page loaded
      ) {
        dispatch?.(settingsActions.setSyncReminderFrequency(1));
      }
    }, 10000);

    // const timer = setInterval(() => {
    //   dispatch?.(settingsActions.setSyncReminderFrequency(1));
    // }, 4000);

    return () => clearInterval(timer);
  }, [lastSyncReminder, syncReminderNum, isSettingUpdated]);

  return null;
};

export default SyncModalEffecting;
