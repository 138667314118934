import React, { memo, forwardRef, useCallback } from 'react';
import _ from 'lodash';

import TextBoxUnit from '@components/text-box-unit';

const FormReading = (
  {
    errorsMsg,
    removeSpecialCharactersForName,
    field,
    control,
    name,
    register,
    watch,
    setValue,
    count,
    dropdownMenuMapList,
    label
  },
  ref
) => {
  const { refs } = ref;

  const options =
    field?.questionTypeText === 'Readings_PL'
      ? dropdownMenuMapList[field?.defaultValue]
      : field?.options?.length
      ? field?.options
      : field?.defaultValue
      ? field?.defaultValue?.split(',')?.map((x) => {
          return {
            label: x,
            value: x
          };
        })
      : [];

  const textBoxValueErrors = errorsMsg?.textBoxValue?.message || '';
  const textBoxUnitValueErrors = errorsMsg?.textBoxUnitValue?.message || '';

  const isTextBoxUnitReconcile = useCallback(
    ({ fieldName, isMasterData }) => {
      return !_.isEmpty(watch(`${fieldName}.textBoxUnitValue-reconcile`))
        ? !_.isNull(watch(`${fieldName}.textBoxValue-reconcile`)) &&
          isMasterData &&
          watch(`${fieldName}.textBoxValue`)?.toString() !==
            watch(`${fieldName}.textBoxValue-reconcile`)?.toString()
          ? true
          : watch(`${fieldName}.textBoxUnitValue`) !==
            watch(`${fieldName}.textBoxUnitValue-reconcile`)
          ? true
          : false
        : !_.isNull(watch(`${fieldName}.textBoxValue`)) &&
            !_.isEmpty(watch(`${fieldName}.textBoxUnitValue`)) &&
            isMasterData;
    },
    [watch(`${name}.textBoxUnitValue`), watch(`${name}.textBoxValue`)]
  );

  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
    >
      <TextBoxUnit
        inputLabel={label ? label : `${count}. ${field?.label}`}
        control={control}
        register={register}
        inputFiledRequired={field?.isRequired}
        setValue={setValue}
        watch={watch}
        error={textBoxValueErrors || textBoxUnitValueErrors ? 'Required' : null}
        name={name}
        options={options}
        placeholder={field?.placeholderText}
        reconciled={field?.isMasterData}
        showReconciled={isTextBoxUnitReconcile({
          fieldName: name,
          isMasterData: field?.isMasterData
        })}
      />
    </div>
  );
};

export default memo(forwardRef(FormReading));
