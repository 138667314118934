import React from 'react';
import SettingsValueCard from './settings-value-card';
import DragDropFields from './drag-frop-fields';
const WorkOrdersDisplaySettings = () => {
  return (
    <div className="settings--values">
      <SettingsValueCard
        title={'Work order cards'}
        description={'This is how I want work order data to appear on task cards:'}
      >
        <DragDropFields text={'work order'} />
      </SettingsValueCard>
    </div>
  );
};

export default WorkOrdersDisplaySettings;
