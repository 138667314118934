import React, { memo } from 'react';

import AppBar from '@components/app-bar';
import SiteListing from '@components/site-listing';

const Listing = () => {
  return (
    <div className="page-container">
      <AppBar />
      <div className="md:ml-24">
        <SiteListing Title={'Sites'} />
      </div>
    </div>
  );
};

export default memo(Listing);
