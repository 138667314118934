/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState, memo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

import Icon from '@components/icon-component';
import Badge from '@components/badge/badge';
import Button from '@components/button/button.component';
import VirtualList from '@components/virtual-list/virtual-list';
import PipelineListingInner from './pipeline-listing-inner/pipeline-listing-inner.component';
import PipelineFilter from './pipeline-filter';
import Tags from '@components/tags/tags';
import LoadingRound from '@components/loading-round/loading-round';

import useWindowDimensions from '@hooks/useWindowDimensions';
import { getAppDispatch } from '@utilities/dispatch.util';
import {
  getFilterPipeline,
  getPipelinesDefaultFilters,
  tagsDataList
} from './pipeline-listing-inner/helper';
import { useDebounce } from '@hooks/useDebounce';
import { backHistoryActions } from '@redux/slices/back-history';
import { cacheActions } from '@redux/slices/cache';
import { initWebWorker } from 'webworker';
import useDistance from '@hooks/useDistance';

import style from './style.module.scss';

const PipelineListing = ({
  Title,
  assetDetailsPipelineLists = false,
  isFilterActiveHeader = false,
  setFilterActiveHeader = null,
  from,
  detailPage = false,
  siteId = null,
  detailsPageBookmarks = true
}) => {
  const dispatch = getAppDispatch();
  const isSet = useRef(null);
  const latestDistanceCalculate = useDistance({});
  const [searchParams] = useSearchParams();
  const bookmarkFilter = detailsPageBookmarks ? searchParams.get('bookmarkFilter') : null;

  const bookmarkName = searchParams.get('bookmarkName');

  const dataFetchedRef = useRef(false);
  const { width } = useWindowDimensions();
  const [searched, setSearched] = useState('');
  const [filtersCount, setFiltersCount] = useState(0);
  const [resetFilters, setResetFilters] = useState(false);
  const [SelectedFilter, setSelectedFilter] = useState(['# TASKS']);
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);
  const [isHidden, setIsHidden] = useState(false);

  const [filters, setFilters] = useState(getPipelinesDefaultFilters);

  const { sitesMap = {}, sites: cacheSites = [] } = useSelector((state) => state.cache);
  const coordinates = useSelector((state) => state.cache.coordinates);

  const [pipelinesAreaHierarchyTreeDataMap, setPipelinesAreaHierarchyTreeDataMap] = useState({});
  const [pipelinesPipelinesStatusDataMap, setPipelinesPipelinesStatusDataMap] = useState({});
  const [pipelinesWorkOrderPriorityDataMap, setPipelinesWorkOrderPriorityDataMap] = useState({});

  const [loading, setLoading] = useState(true);
  const [pipelines, setPipelines] = useState([]);
  const [loadingOnSearch, setLoadingOnSearch] = useState(true);
  const [filterPipelineList, setFilterPipelineList] = useState([]);

  useEffect(() => {
    dispatch && dispatch?.(backHistoryActions.clearAllDetailsPage(null));
    if (assetDetailsPipelineLists?.length > 0) {
      setPipelines(assetDetailsPipelineLists);
      setLoading(false);
    } else {
      if (latestDistanceCalculate.coordinates) {
        dispatch?.(
          cacheActions.setUnitAndCoordinates({
            unit: latestDistanceCalculate?.unit,
            coordinates: latestDistanceCalculate?.coordinates
          })
        );
      }

      dispatch?.(cacheActions.setSitesMobile());
      initWebWorker({
        args: {
          store: { ...store.getState() }
        },
        type: 'get-all-pipeline-listing'
      })
        .then((data) => {
          setPipelines(data?.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
      // }
    }
  }, [dispatch, assetDetailsPipelineLists, latestDistanceCalculate.coordinates]);

  useEffect(() => {
    if (!_.isEmpty(bookmarkFilter)) {
      const filtersInDecode = decodeURIComponent(bookmarkFilter);
      const filterInParse = JSON.parse(filtersInDecode);
      filterInParse?.filters && setFilters(filterInParse?.filters);
      filterInParse?.filters &&
        filterInParse?.filters['searched'] &&
        setSearched(filterInParse?.filters['searched'] || '');
      if (!_.isEmpty(filterInParse?.sort))
        setSelectedFilter(Object.keys(filterInParse?.sort) || '# TASKS');
    }
    return () => {};
  }, [bookmarkFilter]);

  useEffect(() => {
    setFilterActiveHeader && setFilterActiveHeader(isFilterActive);
  }, [isFilterActive]);

  useEffect(() => {
    assetDetailsPipelineLists && setFilterActive(isFilterActiveHeader);
  }, [isFilterActiveHeader]);

  const debouncedSearchInputValue = useDebounce(searched, 1000);

  useEffect(() => {
    if (!loading) {
      !loadingOnSearch && pipelines?.length > 0 && setLoadingOnSearch(true);

      initWebWorker({
        args: {
          filters: { ...filters, searched: searched?.trim() },
          store: { ...store.getState() },
          sitesMap,
          pipelines,
          SelectedFilter,
          cacheSites
        },
        type: 'get-searched-pipelines-listing'
      })
        .then((data) => {
          const {
            filteredPipeline,
            pipelinesPipelineStatusData,
            pipelinesAreaHierarchyTreeData,
            pipelinesWorkOrderPriorityDataMap
          } = data?.result || {};
          setPipelinesAreaHierarchyTreeDataMap(pipelinesAreaHierarchyTreeData);
          setPipelinesPipelinesStatusDataMap(pipelinesPipelineStatusData);
          setPipelinesWorkOrderPriorityDataMap(pipelinesWorkOrderPriorityDataMap);
          setFilterPipelineList([...filteredPipeline]);
          setLoadingOnSearch(false);
        })
        .catch((error) => {
          setLoadingOnSearch(false);
        });
    }
  }, [
    filters,
    debouncedSearchInputValue,
    SelectedFilter,
    isFilterActive,
    width,
    pipelines,
    loading
  ]);

  const tagsData = tagsDataList.filter((el) => {
    if (el === 'DISTANCE' && !coordinates) return false;
    return true;
  });

  useEffect(() => {
    if (!isSet.current && coordinates && SelectedFilter.includes('# TASKS')) {
      if (!_.isEmpty(bookmarkFilter)) {
        const filtersInDecode = decodeURIComponent(bookmarkFilter);
        const filterInParse = JSON.parse(filtersInDecode);
        if (!_.isEmpty(filterInParse?.sort))
          setSelectedFilter(Object.keys(filterInParse?.sort) || '# TASKS');
      } else {
        setSelectedFilter(['DISTANCE']);
        isSet.current = true;
      }
    }
  }, [coordinates, SelectedFilter, bookmarkFilter]);

  const resetFilter = () => {
    return (
      <div className="no-result">
        <div>No results.</div>

        {filtersCount > 0 && (
          <Button
            variant="border"
            onClick={() => setResetFilters((prev) => !prev)}
            text="Reset filters"
            btnClass="taskBtnClass"
          />
        )}
      </div>
    );
  };

  const handleWheel = (e) => {
    const virtualList = document?.querySelector('.cstmScrollerRef')?.scrollTop;

    if (e.deltaY > 0 && !isHidden) {
      setIsHidden(true);
    } else if (e.deltaY < 0 && virtualList === 0 && isHidden) {
      setIsHidden(false);
    }
  };

  let startY;

  const handleScroll = (e) => {
    const virtualList = document?.querySelector('.cstmScrollerRef')?.scrollTop;

    const deltaY = e.touches[0].clientY - startY;

    if (deltaY < 0 && !isHidden) {
      setIsHidden(true);
    } else if (deltaY > 0 && virtualList === 0 && isHidden) {
      setIsHidden(false);
    }
  };

  const handleTouchStart = (e) => {
    startY = e.touches[0].clientY;
  };

  const pipelineFilterGroups = () => {
    return (
      <PipelineFilter
        setResetFilters={setResetFilters}
        title={Title}
        pipelines={pipelines || []}
        onSearch={setSearched}
        length={filterPipelineList?.length}
        resetFilters={resetFilters}
        filtersCount={filtersCount}
        onChangeFilters={setFilters}
        isFilterActive={isFilterActive}
        setFiltersCount={setFiltersCount}
        setFilterActive={setFilterActive}
        pipelinesWorkOrderPriorityDataMap={pipelinesWorkOrderPriorityDataMap}
        pipelinesAreaHierarchyTreeDataMap={pipelinesAreaHierarchyTreeDataMap}
        pipelinesPipelinesStatusDataMap={pipelinesPipelinesStatusDataMap}
        selectedSort={SelectedFilter}
        bookmarkFilter={bookmarkFilter}
        removeClassOnAssetDetailsFilters={assetDetailsPipelineLists}
        siteId={siteId}
        detailPage={detailPage}
        store={{ ...store.getState() }}
      />
    );
  };
  const pipelineFilterGroupsForAssetDetailsPage = () => {
    return pipelineFilterGroups();
  };

  const pipelineListingScrollEvent = (e) => {
    const assetDetailsNavigation = document.getElementById('assetDetailsNavigation');
    const siteDetailsPage = document.querySelector('#assetDetailPage');
    const currentTab = parseInt(searchParams.get('virtualScrollTab') || 0);
    const virtualListScroll = document?.querySelectorAll('.cstmScrollerRef')[currentTab];

    if (virtualListScroll.scrollTop === 0) {
      siteDetailsPage.scrollTop = 0;
      assetDetailsNavigation.classList.remove('assetDetailNavFixed');
      assetDetailsNavigation.classList.add('assetDetailNavNotFixed');
    }
    if (assetDetailsNavigation.classList.value.includes('assetDetailNavNotFixed')) {
      if (virtualListScroll) {
        virtualListScroll.classList.remove('virtualListOverflowOverlay');
        virtualListScroll.classList.add('virtualListOverflowOverlay');
      }
    } else if (virtualListScroll) {
      virtualListScroll.classList.remove('virtualListOverflowOverlay');
      virtualListScroll.classList.add('virtualListOverflowOverlay');
    }
  };

  const pipelineLists = () => {
    return (
      <>
        <div
          className="App"
          onTouchStart={handleTouchStart}
          onTouchMove={handleScroll}
          onWheel={handleWheel}
          style={{ height: '100%' }}
        >
          {
            <div
              className={`op-filter-sort ${style['op-filter-sort']} ${
                isHidden ? style.hidden : ''
              }`}
              style={{
                borderBottom: loadingOnSearch ? 'none ' : '1px solid rgb(229, 229, 229)'
              }}
            >
              <div
                className={style['op-filter-sort-content']}
                style={{ position: isHidden ? 'absolute' : 'static' }}
              >
                {!isFilterActive && (
                  <div className={style.onlyIcon} onClick={() => setFilterActive(true)}>
                    <Icon name={filtersCount > 0 ? 'filter-blue' : 'filter-black'} />
                  </div>
                )}
                <div
                  style={{
                    flex: 1
                  }}
                  className={`btn-sort-wrap ${isFilterActive ? '' : 'btn-sort-wrap-list'}`}
                >
                  {!isFilterActive && <div className="sort-by">Sort By</div>}

                  <Tags tags={tagsData} value={SelectedFilter} onChange={setSelectedFilter} />
                </div>
              </div>
            </div>
          }
          {loadingOnSearch ? (
            <div style={{ backgroundColor: 'transparent', width: '100%' }}>
              <LoadingRound />
            </div>
          ) : (
            <VirtualList
              divider
              data={filterPipelineList}
              component={PipelineListingInner}
              onScrollEvent={assetDetailsPipelineLists ? pipelineListingScrollEvent : null}
              isHidden={isHidden}
              componentProps={{
                highlight: searched,
                highlightName: true,
                pipelineListing: true,
                from,
                noLink: true
              }}
            />
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    SelectedFilter.length === 0 && setSelectedFilter(['A-Z']);
  }, [SelectedFilter]);

  useEffect(() => {
    if (detailPage && filterPipelineList)
      document.getElementById(
        'assets-counts-in-nav-tabs-pipeline'
      ).innerText = `Pipeline (${filterPipelineList?.length})`;
  }, [filterPipelineList, detailPage]);

  return (
    <div className="relative overflow-x-hidden">
      {!assetDetailsPipelineLists && (
        <div
          className="op-page-name op-listing-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative"
          style={{
            position: 'sticky',
            top: 0
          }}
        >
          <Link className="op-back " to={-1}></Link>
          <h1 className="h1-semibold pl-16">
            {!_.isEmpty(bookmarkName) ? (
              bookmarkName
            ) : (
              <>
                {' '}
                {Title} ({filterPipelineList?.length?.toLocaleString()})
              </>
            )}
          </h1>
          <button className="filter-btn" onClick={() => setFilterActive(true)}>
            <Badge size="small" count={filtersCount} color="#ffffff" textColor={'#2f80ed'}>
              <Icon name={'filter'} color={'#ffffff'} width="20px" height="18px" />
            </Badge>
          </button>
        </div>
      )}
      <div
        className="flex"
        style={{
          height: 'calc(100vh - 94px)',
          overflowY: 'auto'
        }}
        id="assetDetailsWorkOrderAndInspections"
      >
        {pipelineFilterGroupsForAssetDetailsPage()}
        <div
          className={`${
            detailPage ? style['page-listingForAssetDetails'] : style['page-listing']
          }  ${style.paddingClass}`}
          style={{
            width: `calc(100% - ${isFilterActive ? '275' : '0'}px)`,
            flex: 1,
            overflowY: 'clip',
            paddingBottom: '0px'
          }}
        >
          {filterPipelineList?.length === 0 && loadingOnSearch === false
            ? resetFilter()
            : pipelineLists()}
        </div>
      </div>
    </div>
  );
};
PipelineListing.propTypes = {
  Title: PropTypes.string
};

export default memo(PipelineListing);
