/* eslint-disable no-unused-vars */
import { useLocation } from 'react-router-dom';

import '../app-bar.scss';
import style from '../style.module.scss';

const MenuBarLink = ({ moreMenuToggle, setMoreMenuToggle }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`${
        pathname.includes('help') || pathname.includes('settings') || moreMenuToggle
          ? style.open
          : ''
      } 
        ${style['op-navlink']} ${style['label-regular2']} ${style['op-more-link']}`}
    >
      <button onClick={() => setMoreMenuToggle(!moreMenuToggle)}>
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 12H21.5"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 6H21.5"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.5 18H21.5"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        More
      </button>
    </div>
  );
};

export default MenuBarLink;
