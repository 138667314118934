/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';

import Accordion from '@components/accordion';
import FilterChips from '@components/filters/filter-chips';
import RatingRange from '@components/rating-range';

import style from './proximity.module.scss';

const ProximityFilter = ({
  isActive = true,
  value = 0,
  max,
  onChange,
  titleText,
  unit,
  backClass
}) => {
  const [open, setOpen] = useState(true);

  React.useEffect(() => setOpen(isActive), [isActive]);

  const chips = React.useMemo(() => {
    if (value) return { chipText: `Under ${value || 0} ${unit || 'km'}` };
    else return {};
  }, [value]);

  const deleteChip = () => {
    onChange(0);
    setOpen(true);
  };

  return (
    <div>
      <Accordion
        open={open}
        setOpen={setOpen}
        title="Proximity"
        isSelected={!open}
        selectedChildren={
          value > 0 && <SelectedProximityFilter chips={chips} deleteChip={deleteChip} />
        }
      >
        <p className={`${style.p} ${backClass}`}>{titleText}</p>
        <RatingRange value={value} onChange={onChange} max={max} />
      </Accordion>
    </div>
  );
};

export default React.memo(ProximityFilter, _.isEqual);

export const SelectedProximityFilter = ({ chips, deleteChip }) => {
  return (
    <div className={style.selectFilterDiv}>
      <FilterChips chipText={chips.chipText} deleteChip={deleteChip} />
    </div>
  );
};
