import { useEffect } from 'react';
import useWindowDimensions from './useWindowDimensions';

const useOutsideClick = (ref, callback) => {
  const { width } = useWindowDimensions();

  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    if (width < 500) document.addEventListener('touchstart', handleClick);
    else document.addEventListener('mousedown', handleClick);

    return () => {
      if (width < 500) document.removeEventListener('touchstart', handleClick);
      else document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, callback]);
};

export default useOutsideClick;
