import _ from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookmarks: [],
  defaultBookmark: [],
  bookmarksMap: {},
  loading: false,
  bookmarkValidity: true,
  bookmarksWithCount: []
};

export const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    setBookmarks: (state, action) => {
      if (!_.isEmpty(action.payload)) {
        const bookmarks = action.payload?.bookmarks || [];
        state.bookmarks = bookmarks;
      }
    },
    setDefaultBookmarks: (state, action) => {
      state.defaultBookmark = action?.payload || [];
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBookmarkValidity: (state, action) => {
      state.bookmarkValidity = action.payload;
    },
    setBookmarksWithCount: (state, action) => {
      state.bookmarksWithCount = action.payload;
    }
  }
});

const bookmarksReducer = bookmarksSlice.reducer;

export const bookmarksActions = bookmarksSlice.actions;
export default bookmarksReducer;
