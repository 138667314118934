import React, { createContext, useState } from 'react';

const ToasterContext = createContext(null);

export const ToasterContextProvider = ({ children }) => {
  const [notification, setNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState();
  const [status, setStatus] = useState('success');

  const showSuccess = (message) => {
    setNotificationMsg(message);
    setStatus('success');
    setNotification(true);
  };

  const showError = (message) => {
    setNotificationMsg(message);
    setStatus('error');
    setNotification(true);
  };

  return (
    <ToasterContext.Provider
      value={{
        notification,
        setNotification,
        notificationMsg,
        setNotificationMsg,
        status,
        ToasterUtil: { showSuccess, showError }
      }}
    >
      {children}
    </ToasterContext.Provider>
  );
};

export const useToastContext = () => {
  const context = React.useContext(ToasterContext);
  if (context === undefined) {
    throw new Error('useToastContext can only be used inside ToasterProvider');
  }
  return context;
};
