const updateMobileSyncCount = ({ syncDone }) => {
  return {
    completedInspectionCount: {
      op: 'Replace',
      value: syncDone?.inspection || 0
    },
    completedInspectionPhotoCount: {
      op: 'Replace',
      value: syncDone?.inspectionPhotoCount || 0
    },
    completedInspectionDocumentCount: {
      op: 'Replace',
      value: syncDone?.inspectionDocumentCount || 0
    },

    completedWorkOrderCount: {
      op: 'Replace',
      value: syncDone?.workOrder || 0
    },
    completedWorkOrderPhotoCount: {
      op: 'Replace',
      value: syncDone?.workOrderPhotoCount || 0
    },
    completedWorkOrderDocumentCount: {
      op: 'Replace',
      value: syncDone?.workOrderDocumentCount || 0
    },

    completedWorkRequestCount: {
      op: 'Replace',
      value: syncDone?.workRequest || 0
    },
    completedWorkRequestPhotoCount: {
      op: 'Replace',
      value: syncDone?.workRequestPhotoCount || 0
    },
    completedWorkRequestDocumentCount: {
      op: 'Replace',
      value: syncDone?.workRequestDocumentCount || 0
    },

    completedIncidentCount: {
      op: 'Replace',
      value: syncDone?.hseEvents || 0
    },
    completedIncidentPhotoCount: {
      op: 'Replace',
      value: syncDone?.incidentPhotoCount || 0
    },
    completedIncidentDocumentCount: {
      op: 'Replace',
      value: syncDone?.incidentDocumentCount || 0
    },

    completedCheckCount: {
      op: 'Replace',
      value: syncDone?.checks || 0
    },
    completedCheckPhotoCount: {
      op: 'Replace',
      value: syncDone?.checkPhotoCount || 0
    },
    completedCheckDocumentCount: {
      op: 'Replace',
      value: syncDone?.checkDocumentCount || 0
    }
  };
};

const addMobileSyncCount = ({ pendingOrderPhotosDocumentsLength }) => {
  return {
    inspectionCount: pendingOrderPhotosDocumentsLength?.inspection || 0,
    inspectionPhotoCount: pendingOrderPhotosDocumentsLength?.inspectionPhotoCount || 0,
    inspectionDocumentCount: pendingOrderPhotosDocumentsLength?.inspectionDocumentCount || 0,
    workOrderCount: pendingOrderPhotosDocumentsLength?.workOrder || 0,
    workOrderPhotoCount: pendingOrderPhotosDocumentsLength?.workOrderPhotoCount || 0,
    workOrderDocumentCount: pendingOrderPhotosDocumentsLength?.workOrderDocumentCount || 0,
    workRequestCount: pendingOrderPhotosDocumentsLength?.workRequest || 0,
    workRequestPhotoCount: pendingOrderPhotosDocumentsLength?.workRequestPhotoCount || 0,
    workRequestDocumentCount: pendingOrderPhotosDocumentsLength?.workRequestDocumentCount || 0,
    incidentCount: pendingOrderPhotosDocumentsLength?.hseEvents || 0,
    incidentPhotoCount: pendingOrderPhotosDocumentsLength?.incidentPhotoCount || 0,
    incidentDocumentCount: pendingOrderPhotosDocumentsLength?.incidentDocumentCount || 0,

    checkCount: pendingOrderPhotosDocumentsLength?.checks || 0,
    checkPhotoCount: pendingOrderPhotosDocumentsLength?.checksPhotoCount || 0,
    checkDocumentCount: pendingOrderPhotosDocumentsLength?.checksDocumentCount || 0
  };
};

export { updateMobileSyncCount, addMobileSyncCount };
