/* eslint-disable no-unused-vars */
import React from 'react';

import Icon from '@components/icon-component';
import TaskStatusAndPriority from '@components/task-status-priority';
import Checkbox from '@components/checkbox/checkbox.component';
import HighlightText from '@components/highlight-text';

import style from './header.module.scss';

const CardHeader = ({
  iconName,

  title,
  status,
  highlight,
  checkbox,
  syncedIcon,
  type,
  link,
  selected,
  setSelected,
  openModal,
  formWrapper
}) => {
  return (
    <>
      <div className={style.headerClass}>
        <div className={style.leftDiv}>
          <Icon height={15} width={15} name={iconName} />
          <p>
            <HighlightText text={title} highlight={highlight} />
          </p>

          {link && (
            <div className={`${status === 'To do' && style.link}`}>
              <Icon
                height={18}
                width={18}
                name="link"
                onClick={(e) => {
                  e?.stopPropagation();
                  window.open(link, '_blank');
                }}
              />
            </div>
          )}
          {syncedIcon && <Icon height={20} width={20} name="attachment" />}
        </div>
        <div
          className={style.rightDiv}
          style={{
            gap: status === 'To do' && type !== 'Check' ? '5px' : '',
            minWidth: status === 'To do' && type !== 'Check' ? '70px' : ''
          }}
        >
          <TaskStatusAndPriority status={status} openModal={openModal} formWrapper={formWrapper} />
          {checkbox && status === 'To do' && type !== 'Check' ? (
            <Checkbox
              checked={selected}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => setSelected(e.target.checked)}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default CardHeader;
