/* eslint-disable no-unused-vars */

import _ from 'lodash';
import React from 'react';

import Modal from '@components/modal';

import style from './style.module.scss';

const BookMarkModal = ({
  open,
  setOpen,
  statusText,
  modalTextUpper,
  modalTextLower,
  textBtn,
  progressData,
  onSubmit,
  disabledBtn = false
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      icon={'book-mark-icon'}
      statusText={statusText}
      modalTextUpper={() => <>{modalTextUpper}</>}
      modalTextLower={modalTextLower}
      textBtn={textBtn}
      progressData={progressData}
    >
      <div>
        <div className={style.modalBtn}>
          <button className={'btn-fill button-large'} onClick={onSubmit} disabled={disabledBtn}>
            Save BookMark
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(BookMarkModal, _.isEqual);
