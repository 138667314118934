/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { Fragment, useState, useMemo } from 'react';

import HighlightText from '@components/highlight-text';
import VirtualList from '@components/virtual-list/virtual-list';
import SearchInput from '../search-input';
import './search-list.scss';
import style from './style.module.scss';

const SearchList = ({ list, onSelect, search = true, comingSoon, searchClassName = '' }) => {
  const [inputValue, setInputValue] = useState('');

  const searchLists = useMemo(() => {
    return list?.filter((lsItem) => {
      if (
        inputValue &&
        !String(lsItem?.value || lsItem?.name)
          .toLowerCase()
          .includes(inputValue?.toLowerCase())
      )
        return false;
      return true;
    });
  }, [inputValue, list]);
  return (
    <div className="search-list">
      {search ? (
        <SearchInput value={inputValue} onChange={setInputValue} className={searchClassName} />
      ) : (
        <div className="no-search" />
      )}
      <div className={`scroll-list ${search ? 'with-search' : style.withoutSearch}`}>
        <VirtualList
          data={searchLists}
          overscan={25}
          addMarginToLastItem={true}
          component={(props) => {
            return (
              <div className={`search-list--cards`}>
                <Fragment>
                  <div
                    onClick={() =>
                      onSelect(
                        props?.index || props?.inspectionType,
                        props?.value || props?.name,
                        props
                      )
                    }
                  >
                    <HighlightText text={props?.value || props?.name} highlight={inputValue} />
                  </div>
                </Fragment>
              </div>
            );
          }}
        />

        {comingSoon && (
          <div className="coming-soon" style={{ textAlign: 'center' }}>
            {comingSoon}
          </div>
        )}
      </div>
    </div>
  );
};

SearchList.propTypes = {
  search: PropTypes.bool,
  comingSoon: PropTypes.string,
  onSelect: PropTypes.func.isRequired
};

export default SearchList;
