import jwt from 'jwt-decode';

let decodedToken = '';

export function setToken(userToken) {
  decodedToken = '';
  localStorage.setItem('token', JSON.stringify(userToken));
  localStorage.setItem('cachingFailure', false);
}

export function getToken() {
  if (localStorage['token']) {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  }
  return null;
}
export function decodeToken() {
  if (decodedToken) return decodedToken;

  const token = getToken();

  if (token !== null) {
    const user = jwt(token);
    decodedToken = {
      ...user,
      permissions: user.permissions.reduce((acc, curr) => {
        acc[curr.toUpperCase()] = true;
        return acc;
      }, {})
    };
    return decodedToken;
  }

  return {};
}
