/* eslint-disable no-unused-vars */
import _ from 'lodash';
import moment from 'moment';

export const attachMeta = (
  sitesList,
  sitesEquipmentsMap,
  equipmentsMap,
  allWOTasks,
  cacheEquipments = []
) => {
  let prepareSites = [];
  sitesList.map((site) => {
    const equipments = (sitesEquipmentsMap[site?.siteId] || []).filter(
      (id) => cacheEquipments[equipmentsMap[id]]
    );
    const allTasksForSite = allWOTasks.filter(
      (t) =>
        t?.siteId === site?.siteId ||
        t?.sites?.includes(site?.siteId) ||
        t?.relatedAssetId === site.siteId ||
        t?.primaryAssetId === site.siteId ||
        equipments?.includes(t.equipmentId) ||
        _.intersection(equipments, t?.equipments).length > 0
    );
    const allDoneTasks = allTasksForSite.filter((task) => task?.status === 'Synced');
    const allotDoneTasks = allTasksForSite.filter((task) => task?.status !== 'Synced');

    const today = moment().format('YYYY-MM-DD');
    const isOverDue = allotDoneTasks.find((task) =>
      task.dueDate ? moment(task.dueDate).isBefore(today) : false
    );

    prepareSites.push({
      allDoneTasks,
      isOverDue,
      allTasksForSite,
      allotDoneTasks,
      soonestDueDate: _.min(
        allotDoneTasks.map((t) => t?.due || t?.dueDate).map((date) => new Date(date))
      )
    });
  });

  return prepareSites;
};
