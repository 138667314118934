/* eslint-disable no-unused-vars */
import { Link, useLocation } from 'react-router-dom';

import '../app-bar.scss';
import style from '../style.module.scss';

const TaskLink = ({ moreMenuToggle, onClickLink }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`${style['op-navlink']} ${style['label-regular2']} ${
        !moreMenuToggle && pathname === '/task-records' ? style.active : ''
      }`}
    >
      <Link
        to={'/task-records'}
        state={{
          resetFilters: true
        }}
        onClick={onClickLink}
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.75 9C2.75 7.89543 3.64543 7 4.75 7H20.75C21.8546 7 22.75 7.89543 22.75 9V19C22.75 20.1046 21.8546 21 20.75 21H4.75C3.64543 21 2.75 20.1046 2.75 19V9Z"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.75 21V5C16.75 3.89543 15.8546 3 14.75 3H10.75C9.64543 3 8.75 3.89543 8.75 5V21"
            stroke="#7B8794"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Tasks
      </Link>
    </div>
  );
};

export default TaskLink;
