import React, { useEffect } from 'react';

import Button from '@components/button/button.component';
import Icon from '@components/icon-component';

import style from './task.module.scss';

const CreateNewTask = () => {
  useEffect(() => {
    const appBar = document.getElementById('appBar');
    const helpContainer = document.getElementById('helpContainer');
    if (appBar) {
      appBar.classList.add('hideAppBar');
      helpContainer.classList.add('pageHeightOnRemoveAppBar');
    }
    return () => {
      appBar.classList.remove('hideAppBar');
      helpContainer.classList.remove('pageHeightOnRemoveAppBar');
    };
  }, []);

  return (
    <div className={style.bgColor}>
      <div className={style.seeCard}>
        <h6 className={style.titleClass}>From Home</h6>
        <div className={style.para}>
          <div>
            <p className={style.p} style={{ marginTop: '16px' }}>
              You can create a new task by:
            </p>
            <ul>
              <li>
                Hitting on the <span style={{ fontWeight: 700 }}>+</span> button on the navigation
                bar.
              </li>
              <li>Selecting the type of task you want to create.</li>
              <li>
                Following the steps in the wizard to find and select the applicable asset in the
                list.
              </li>
              <li>Filling out the form, completing it and syncing it.</li>
            </ul>
            <p className={style.p} style={{ marginTop: '32px' }}>
              Downside is, you have to search for the asset. So, we recommend doing this ...
            </p>
          </div>
          <div>
            <Button
              iconStart={<Icon name={'plus-white'} height={16} width={16} />}
              variant={'pill'}
              btnClass={style.plusBlue}
              disabled
            />
          </div>
        </div>
        <h6 className={style.titleClass} style={{ marginTop: '32px' }}>
          From the asset details page
        </h6>
        <p className={style.p} style={{ marginTop: '16px' }}>
          You can create a new task by:
        </p>
        <ul>
          <li>Opening the details page for the asset to which the new task will relate.</li>
          <li>Hitting the + button on the navigation bar.</li>
          <li>Following any steps in the wizard.</li>
          <li>Filling out the form, completing it and syncing it.</li>
        </ul>
      </div>
    </div>
  );
};

export default CreateNewTask;
