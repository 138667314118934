/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import Icon from '@components/icon-component';

import { WorkTypes } from './helper';

import style from './workorder.module.scss';
const PageHeader = ({
  workType,
  moreMenuToggle,
  setMoreMenuToggle,
  selectedTasksList,
  currentPageOfSelectedTasks,
  handleBackClick,
  pageHeaderText
}) => {
  const [showMenu, setShowMenu] = useState(true);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    moreMenuToggle && (document.body.style.overflow = 'hidden');
    !moreMenuToggle && (document.body.style.overflow = '');
  }, [moreMenuToggle]);

  useEffect(() => {
    const isNewForm =
      [
        WorkTypes.NewWorkRequest,
        WorkTypes.NewWorkOrder,
        WorkTypes.NewInspection,
        WorkTypes.NewHSE,
        WorkTypes.NewCheck
      ].includes(workType) && !searchParams.get('orderIds')
        ? true
        : false;

    if (isNewForm) setShowMenu(false);
  }, [workType]);

  return (
    <div>
      <div
        className="px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative open"
        style={{
          transition: 'all 0.6s'
        }}
      >
        <div className={style.pageHeader}>
          <div
            style={{
              cursor: 'pointer',
              width: '26px'
            }}
            onClick={() => handleBackClick()}
          >
            <Icon
              style={{
                marginLeft: '8px',
                cursor: 'pointer',
                height: '20px'
              }}
              // onClick={() => handleBackClick()}
              name={'shape'}
            />
          </div>
          <h3 className={style.title}>{pageHeaderText}</h3>
        </div>
        <h3 className={style.title1}>{pageHeaderText}</h3>

        {showMenu ? (
          <button>
            <Icon
              onClick={() => setMoreMenuToggle(true)}
              name={'menu-icon'}
              style={{
                transition: 'all 2.5s',
                pointerEvents: 'auto'
              }}
            />
          </button>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;

PageHeader.prototype = {
  workType: PropTypes.string
};

PageHeader.defaultProps = {};
