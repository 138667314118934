/* eslint-disable no-unused-vars */
import moment from 'moment';
import { store } from 'redux/store';

import http from './http.service';
import Promisable from './promisable.service';
import { pipelineActions } from '@redux/slices/pipeline';
import { getAppDispatch } from '@utilities/dispatch.util';
import { checkOldAndNewArray } from './check-old-and-new-array';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';

const PipelineService = {
  getPipeline: async () => {
    const dispatch = getAppDispatch();
    dispatch?.(pipelineActions.setLoading(true));
    http.setJWT();
    const res = await Promise.all([Promisable.asPromise(http.get(`/Pipelines?page=0`))]);
    const [[pipeline]] = res;

    if (pipeline.data && pipeline.data.items.length > 0)
      dispatch?.(pipelineActions.setPipeline(pipeline.data.items));

    const status = res.filter(([success]) => success).length === res.length;
    return [status, !status];
  },

  getPipelineMobile: async ({ initialLogin, lastSuccessFullRefreshTime, isSettingUpdated }) => {
    const { pipeline, cache } = store.getState();
    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      const { pipelineMobileMap = {}, pipelineMobile = [] } = pipeline;
      const changedAfter = lastSuccessFullRefreshTime;
      const dispatch = getAppDispatch();
      dispatch?.(pipelineActions.setLoading(true));

      http.setJWT();
      const [success, error] = await Promisable.asPromise(
        http.get(`/Pipelines/Mobile?page=0&changedAfter=${!initialLogin ? changedAfter ? changedAfter : '' : ''}`)
      );
      if (
        !initialLogin &&
        success?.data?.items?.length &&
        pipelineMobile?.length &&
        !isSettingUpdated
      ) {
        const combineNewAndOldArray = checkOldAndNewArray({
          oldArray: pipelineMobile,
          oldArrayMap: pipelineMobileMap,
          newArray: success?.data?.items,
          idName: 'pipelineId'
        });
        dispatch?.(pipelineActions.setPipelineMobile(combineNewAndOldArray));
      } else {
        if (success?.data?.items?.length)
          dispatch?.(pipelineActions.setPipelineMobile(success?.data?.items));
      }

      dispatch?.(globalProgressBarAction.setLoadStatus(10));
      return [success, error];
    } else throw new Error('Api failed to update');
  }
};

export default PipelineService;
