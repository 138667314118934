import AuthService from '@services/auth.service';
import MobileSyncCountService from '@services/mobile-sync-count.service';
import { retryCountEvent } from '@utilities/retry-count-event';
import moment from 'moment';

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    window.location.hostname === '[::1]' ||
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export function register(config) {
  if ('serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'This web app is being served cache-first by a service' +
              'worker. To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  showLoader();
  let registration;
  retryCountEvent({ count: 1 });

  localStorage.setItem('retrying-registration', 1);

  function handleInstall(installingWorker) {
    if (installingWorker) {
      installingWorker.onstatechange = () => {
        if (installingWorker.state === 'installed') {
          hideLoader();
          if (navigator.serviceWorker.controller) {
            console.log(
              'New content is available and will be used when all ' +
                'tabs for this page are closed. See https://cra.link/PWA.'
            );
            if (config && config.onUpdate) {
              config.onUpdate(registration);
            }
          } else {
            console.log('Content is cached for offline use.');

            MobileSyncCountService.sendRequestToServerCacheIsCompleted();

            if (config && config.onSuccess) {
              config.onSuccess(registration);
            }
          }
        }
        if (installingWorker.state === 'redundant') {
          retry();
        }
      };

      installingWorker.onerror = () => {
        console.log('error getting content');
      };
    }
  }

  function tryRegister() {
    navigator.serviceWorker
      .register(swUrl)
      .then((reg) => {
        registration = reg;
        if (registration) {
          handleInstall(reg.installing);
        } else {
          console.error('Service worker registration failed: registration is undefined.');
          retry();
        }
      })
      .catch((error) => {
        console.error('Error during service worker registration:', error);
        retry();
      });
  }
  // Retry logic
  let retryCount = 0;
  const maxRetries = 3;

  function retry() {
    if (retryCount < maxRetries) {
      console.log(`Retrying registration, attempt ${retryCount + 1}`);
      retryCount += 1;
      retryCountEvent({ count: retryCount + 1 });
      localStorage.setItem('retrying-registration', retryCount + 1);

      tryRegister();
    } else {
      console.error('Max retries reached. Service worker registration failed.');
      hideLoader(); // Hide loader even if registration fails after retries
      AuthService.logout({ clearCache: false });
    }
  }

  tryRegister();

  navigator.serviceWorker.addEventListener('message', (event) => {
    if (event?.data?.type === 'UPDATE_APP') {
      console.log('update available. ', config);
      if (config?.onUpdate) config.onUpdate();
    }
  });
  navigator.serviceWorker.addEventListener('controllerchange', () => {
    if (navigator.serviceWorker.controller) {
      console.log('Controller changed. Refreshing the page.');
      // window.location.reload();
    }
  });
  // registration.onerror = () => {
  //   console.error('Service worker registration failed.');
  //   retry();
  // };
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, {
    headers: { 'Service-Worker': 'script' }
  })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.');
    });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}

const showLoader = () => {
  console.log('caching starts');
  localStorage.setItem('caching-starts-time', moment().format('YYYY-MM-DD hh:mm:ss A'));

  console.time('caching');
};

const hideLoader = () => {
  console.timeEnd('caching');
  console.log('caching end');
  localStorage.setItem('caching-end-time', moment().format('YYYY-MM-DD hh:mm:ss A'));

  localStorage.setItem('isCacheComplete', true);
};

export function updateApp() {
  try {
    return new Promise((resolve) => {
      if ('serviceWorker' in navigator) {
        navigator?.serviceWorker
          .getRegistration()
          .then((registration) => {
            if (registration) {
              registration.update().then(() => {
                console.log('registration.waiting', registration.waiting);
                if (registration.waiting) {
                  registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                  navigator.serviceWorker.addEventListener('controllerchange', () => {
                    resolve();
                  });
                } else {
                  resolve();
                }
              });
            } else {
              resolve();
            }
          })
          .catch((error) => {
            console.log('nice catch', { error });
          });
      } else {
        console.log('service worker not found');
      }
    });
  } catch (err) {
    console.log('catched weird error', { err });
  }
}

window.addEventListener('beforeinstallprompt', (e) => {
  e.preventDefault();
  window.deferredPrompt = e;
});
