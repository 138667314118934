/* eslint-disable no-unused-vars */

import _ from 'lodash';
import moment from 'moment-timezone';

import { getDataPoints } from '../../pages/settings/helper';
import { attachMeta } from '../sites/attach-meta';

export const getTaskSiteAsset = ({
  sitesMap,
  siteId,
  sites = [],
  areaHierarchyTreeDataMap,
  siteDisplayFields,
  picklistsMap,
  mocSiteId = '',
  removeConfigurableFieldFromAsset = false,
  cacheSites = [],
  appliesTo = false,
  formWrapper = false,
  store
}) => {
  let prepareTasksSites = [];
  let sitesIds =
    [siteId, ...sites.filter((site) => site !== siteId)].filter(
      (site) => cacheSites[sitesMap[site]]
    ) || [];
  sitesIds = mocSiteId ? sitesIds.filter((x) => x !== mocSiteId) : sitesIds;
  sitesIds.map((siteId) => {
    const site = cacheSites[sitesMap[siteId]] || {};
    const { name, unit, siteType, distance, areaHierarchy, children = [], link } = site;
    const area = areaHierarchyTreeDataMap[areaHierarchy]?.title;
    const dataPoints = getDataPoints(
      { ...site, type: siteType },
      removeConfigurableFieldFromAsset ? 'Name,Area,Status' : siteDisplayFields,
      'site',
      picklistsMap
    );
    //condition
    const siteAppliesToData = appliesTo
      ? getSiteCompleteData({ picklistsMap, site, cacheSites, formWrapper, store })
      : {};

    prepareTasksSites.push({
      title: area,
      subtitle: name || '',
      dataPoints: dataPoints,
      iconName: 'map-pin',
      kmValue: distance || '',
      unit: unit || '',
      siteId,
      link,
      ...siteAppliesToData
    });
  });

  return {
    sitesIds,
    prepareTasksSites
  };
};

export const filterSiteAssetBasedOnMocHseStructureNcrPipeline = ({
  mocSiteId,
  hseSiteId,
  structureSiteId,
  ncrSiteId,
  pipelineSiteId,
  siteAsset
}) => {
  if (
    (mocSiteId ||
      hseSiteId ||
      structureSiteId?.length ||
      pipelineSiteId?.length ||
      ncrSiteId ||
      pipelineSiteId) &&
    !_.isEmpty(siteAsset)
  ) {
    const { sitesIds = [], prepareTasksSites = [] } = siteAsset;
    const filterId = _.isArray(pipelineSiteId)
      ? mocSiteId || hseSiteId || ncrSiteId || ''
      : mocSiteId || hseSiteId || ncrSiteId || pipelineSiteId || '';

    return {
      sitesIds: filterId
        ? sitesIds?.filter((x) => x !== filterId)
        : structureSiteId
        ? sitesIds?.filter((x) => !structureSiteId?.includes(x))
        : pipelineSiteId?.length > 0
        ? sitesIds?.filter((x) => !pipelineSiteId?.includes(x))
        : sitesIds,
      prepareTasksSites: filterId
        ? prepareTasksSites?.filter((x) => x?.siteId !== filterId)
        : structureSiteId
        ? prepareTasksSites?.filter((x) => !structureSiteId?.includes(x?.siteId))
        : pipelineSiteId?.length > 0
        ? prepareTasksSites?.filter((x) => !pipelineSiteId?.includes(x?.siteId))
        : prepareTasksSites
    };
  } else return siteAsset;
};

const getSiteCompleteData = ({ picklistsMap, site, cacheSites, formWrapper, store }) => {
  const { tasks, cache, inspection } = store;

  const { equipmentsMap = {}, sitesEquipmentsMap = {}, equipments: cacheEquipments = [] } = cache;
  const allWOTasks = !formWrapper
    ? tasks?.tasksList?.filter((task) => !task.newWorkOrder && !task.newWorkRequest)
    : [];
  const allInspections = !formWrapper
    ? inspection?.inspectionsList?.filter((t) => !t?.newInspection)
    : [];
  const allInspectionsAndTasks = [...allWOTasks, ...allInspections];
  const status = !formWrapper ? picklistsMap[site?.status]?.value : null;
  const parentSiteChildren = !formWrapper
    ? site?.children?.map((childIndex) => {
        return { ...cacheSites[childIndex], children: [] };
      })
    : [];
  const childrenList = formWrapper ? [] : site?.children;
  let childrenData = [];
  if (site?.children?.length) {
    childrenData = attachMeta(
      parentSiteChildren,
      sitesEquipmentsMap,
      equipmentsMap,
      allInspectionsAndTasks,
      cacheEquipments
    );
  }

  let allTasksForSite = [
    ...allInspectionsAndTasks.filter(
      (t) =>
        t?.siteId === site?.siteId ||
        t?.relatedAssetId === site.siteId ||
        t?.primaryAssetId === site.siteId ||
        t?.sites?.includes(site?.siteId)
    ),
    ...(childrenData?.map((t) => t.allTasksForSite).flat(2) || [])
  ];
  const allDoneTasks = allTasksForSite.filter((task) => task?.status === 'Synced');
  const allotDoneTasks = allTasksForSite.filter((task) => task?.status !== 'Synced');

  const today = moment().format('YYYY-MM-DD');
  const isOverDue = allotDoneTasks.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );
  return {
    status,
    childrenList,
    allDoneTasks,
    isOverDue,
    allTasksForSite: allTasksForSite || []
  };
};
