/* eslint-disable no-unused-vars */
import { store } from 'redux/store';
import moment from 'moment';
import _ from 'lodash';

import { getSiteAutoPopulate } from '@components/work-order/services/auto-populate';

import { inspectionActions } from '@redux/slices/inspection';
import { getAppDispatch } from '@utilities/dispatch.util';

export const moveInspectionToInProgress = async ({ inspections, inspectionFormsUserCache }) => {
  const dispatch = getAppDispatch();

  const { inspection } = store.getState();
  const { inspectionFormsUserCacheMap = {}, failureCriteria } = inspection;
  const failureCriteriaMap = failureCriteria?.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  for (const inspection of inspections) {
    if (
      inspectionFormsUserCacheMap[inspection?.inspectionFormId]?.quickComplete &&
      inspection.checked
    ) {
      const currentFormInspection = inspectionFormsUserCache?.find(
        (x) => x?.inspectionFormId?.toString() === inspection?.inspectionFormId
      );
      const autoPopulateJSON = await getSiteAutoPopulate({
        frequencyId: inspection?.frequencyId || null,
        siteId: inspection.siteId || null,
        equipmentId: inspection.equipmentId || null,
        pipelineId: inspection.pipelineId || null,
        psvsList: !_.isEmpty(inspection)
          ? inspection?.equipmentAsset?.prepareTasksEquipments
              ?.map((x) => x?.equipmentPSVs)
              ?.flat(1)
          : []
      });
      const formSchema = formWrapperSchema({
        currentForm: currentFormInspection,
        autoPopulateJSON
      });
      const inspectionObject = {
        runId: inspection?.runId || null,
        RunName: inspection?.RunName || null,
        runNumber: inspection?.runNumber || null,
        primaryUserId: inspection?.primaryUserId || null,
        primaryUserGroupId: inspection?.primaryUserGroupId || null,
        responsibleUserGroups: inspection?.responsibleUserGroups || null,
        responsibleUsers: inspection?.responsibleUsers || null,
        inspectableItemId: inspection?.inspectableItemId || null,
        inspectableItemNumber: inspection?.inspectableItemNumber || null,
        siteId: inspection?.siteId || null,
        equipmentId: inspection?.equipmentId || null,
        structureId: inspection?.structureId || null,
        pipelineId: inspection?.pipelineId || null,
        pipelines: inspection?.pipelines || [],
        inspectionTypeId: inspection?.inspectionTypeId || null,
        inspectionFormId: inspection?.inspectionFormId || null,
        frequencyId: inspection?.frequencyId || null,
        intervalText: inspection?.intervalText || null,
        dueDate: inspection?.dueDate || null,
        startDate: inspection?.startDate || null,
        appliesToType: inspection?.appliesTo || null,
        type: 'Inspection',
        id: inspection?.id || null,
        formData: formSchema,
        status: 'Pending sync',
        checked: false
      };

      dispatch?.(
        inspectionActions.updateInspection(
          {
            ...inspectionObject
          } || {}
        )
      );
    }
  }
};

const formWrapperSchema = ({ currentForm, autoPopulateJSON }) => {
  let schemaArray = {};
  const formSectionsList = currentForm?.formSections || [];
  for (let a of formSectionsList) {
    a?.formFields?.forEach((x) => {
      let removeSpecialCharactersForName = removeSpecialCharacters(
        x?.label?.toString()?.toLowerCase()
      );
      removeSpecialCharactersForName = `${removeSpecialCharactersForName}-${x?.formFieldId}`;

      if (x?.questionTypeText === 'PhotoQuestion') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: {
              notes: null,
              photos: []
            }
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'YN') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: false
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'Date_Nullable' || x?.questionTypeText === 'Date') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: x?.questionTypeText === 'Date' ? moment().format('YYYY-MM-DD') : null
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'Dropdown' || x?.questionTypeText === 'Dropdown_PL') {
        const autoPopulateValue =
          autoPopulateJSON[currentForm?.appliesTo]?.[x?.sourceEntity]?.[x?.SourceProperty] || null;

        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: autoPopulateValue || null
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'InspectionResult') {
        const autoPopulateValue =
          autoPopulateJSON[currentForm?.appliesTo]?.[x?.sourceEntity]?.[x?.SourceProperty] || null;
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: {
              inspectionResultAnswer: autoPopulateValue || null
            }
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }

      if (x?.questionTypeText === 'Signature') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: {
              signaturePhoto: null
            }
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (
        x?.questionTypeText === 'LongText' ||
        x?.questionTypeText === 'Text' ||
        x?.questionTypeText === 'PhoneNumber'
      ) {
        const autoPopulateValue =
          autoPopulateJSON[currentForm?.appliesTo]?.[x?.sourceEntity]?.[x?.SourceProperty] || null;

        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: autoPopulateValue || null
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'Numeric') {
        const autoPopulateValue =
          autoPopulateJSON[currentForm?.appliesTo]?.[x?.sourceEntity]?.[x?.SourceProperty] || null;
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: autoPopulateValue || null
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'Readings' || x?.questionTypeText === 'Readings_PL') {
        const autoPopulateValue = autoPopulateJSON[currentForm?.appliesTo]?.[x?.sourceEntity]?.[
          x?.SourceProperty
        ] || {
          textBoxValue: null,
          textBoxUnitValue: null
        };

        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: {
              textBoxValue: autoPopulateValue?.textBoxValue || null,
              textBoxUnitValue: autoPopulateValue?.textBoxUnitValue || null
            }
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'Time') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: null
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'YNComment') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: {
              value: null,
              comment: null
            }
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'PassFail' || x?.questionTypeText === 'PassFailNA') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: {
              answer:
                x?.defaultValue === 'true'
                  ? 'true'
                  : x?.defaultValue === 'false'
                  ? 'false'
                  : x?.defaultValue === 'NA'
                  ? 'NA'
                  : null,

              notes: null,
              photos: []
            }
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'Scale') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: {
              answer: null,
              notes: null,
              photos: []
            }
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'Notes') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: null
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'WorkOrder') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: []
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
      if (x?.questionTypeText === 'Photos') {
        let z = {
          [`${removeSpecialCharactersForName}`]: {
            [x?.formFieldId]: {
              photos: [],
              documents: []
            }
          }
        };
        schemaArray = { ...schemaArray, ...z };
      }
    });
  }
  return schemaArray;
};

const removeSpecialCharacters = (str) => {
  const pattern = /[^a-zA-Z0-9 ]/g;
  const result = str.replace(pattern, '');
  return result;
};
