/* eslint-disable no-unused-vars */

import { store } from 'redux/store';
import moment from 'moment';
import _ from 'lodash';

import http from './http.service';
import Promisable from './promisable.service';
import { checkOldAndNewArray } from './check-old-and-new-array';

import LocalStorage from '@utilities/local-storage.util';
import { getAppDispatch } from '@utilities/dispatch.util';
import { tasksListsActions } from '@redux/slices/tasks';
import { cacheActions } from '@redux/slices/cache';
import { decodeToken } from '@utilities/authentication';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';

const TasksService = {
  getAllTasksList: async (sync, initialLogin, singleSync = false) => {
    const { tasks, cache, settings } = store.getState();
    const { lastSuccessFullRefreshTime } = cache;
    const { isSettingUpdated } = settings;

    const dispatch = getAppDispatch();
    dispatch?.(tasksListsActions.setLoading(true));
    const cachingFailure = localStorage.getItem('cachingFailure');
    const changedAfter = isSettingUpdated ? '' : lastSuccessFullRefreshTime;
    if (!JSON.parse(cachingFailure)) {
      http.setJWT();
      const allWOTasksMap =
        tasks.tasksList && _.size(tasks.tasksList) > 0 && !initialLogin
          ? tasks.tasksList.reduce((acc, task) => {
              acc[task.workorderId] = true;
              return acc;
            }, {})
          : {};

      const res = await Promise.all([
        ...(singleSync
          ? [
              Promisable.asPromise(http.get(`/WorkOrderCache/MobileCache?page=0`)),
              Promisable.asPromise(http.get(`/SyncedWorkOrderCache/MobileCache?page=0`))
            ]
          : [
              Promisable.asPromise(http.get(`/WorkOrderCache/MobileCache?page=0`)),
              Promisable.asPromise(http.get(`/SyncedWorkOrderCache/MobileCache?page=0`)),
              Promisable.asPromise(http.get(`/WorkOrderUsers`)),
              Promisable.asPromise(http.get(`/WorkOrderUserGroups`)),
              Promisable.asPromise(http.get(`/WorkOrderAdministrators`)),
              Promisable.asPromise(http.get(`/WorkOrderAdministratorGroups`))
            ])
      ]);
      let [
        [workOrderCache = []] = [],
        [syncWorkOrderCache = []] = [],
        [workOrderUsers = []] = [],
        [WorkOrderUserGroups = []] = [],
        [workOrderAdministrators = []] = [],
        [workOrderAdministratorGroups = []] = []
      ] = res;

      await cacheWorkOrderPhotosAndDocuments({ workOrderCache });

      const workOrdersAll = [
        ...(syncWorkOrderCache?.data?.map((wo) => ({
          ...wo,
          status: 'Synced',
          statusId: wo.status,
          type: wo.entityType === 'WorkRequest' ? 'Work Request' : 'Work Order',
          id: wo.workOrderNumber
        })) || []),

        ...(workOrderCache?.data?.map((wo) => ({
          ...wo,
          status: 'To do',
          statusId: wo.status,
          type: 'Work Order',
          id: wo.workOrderNumber
        })) || [])
      ];

      let newSyncedTask = [];
      if (!_.isEmpty(allWOTasksMap)) {
        newSyncedTask = workOrdersAll.map((task) => {
          if (!allWOTasksMap[task.workorderId]) {
            return {
              syncDateCustom: moment().format('YYYY-MM-DD'),
              ...task
            };
          } else return task;
        });
      } else newSyncedTask = workOrdersAll;

      // if (!initialLogin && tasks?.tasksList?.length && !isSettingUpdated) {
      //   const combineNewAndOldArray = checkOldAndNewArray({
      //     oldArray: tasks?.tasksList,
      //     oldArrayMap: allWOTasksMap,
      //     newArray: newSyncedTask,
      //     idName: 'workorderId'
      //   });

      //   dispatch?.(tasksListsActions.setTasksList(combineNewAndOldArray || []));
      // } else
      dispatch?.(tasksListsActions.setTasksList(newSyncedTask || []));

      if (!singleSync) {
        dispatch?.(tasksListsActions.setWorkOrdeUsersrList(workOrderUsers?.data || []));
        dispatch?.(tasksListsActions.setWorkOrdeUsersrGroupsList(WorkOrderUserGroups?.data || []));
        dispatch?.(
          tasksListsActions.setWorkOrderAdministrators(workOrderAdministrators?.data || [])
        );
        dispatch?.(
          tasksListsActions.setWorkOrderAdministratorsMap(
            workOrderAdministrators?.data?.reduce((acc, curr) => {
              acc[curr.id] = curr?.firstName + ' ' + curr.lastName;
              return acc;
            }, {}) || []
          )
        );
        dispatch?.(
          tasksListsActions.setWorkOrderAdministratorGroups(
            workOrderAdministratorGroups?.data || []
          )
        );
        if (_.size(workOrderAdministratorGroups?.data) > 0) {
          dispatch?.(
            tasksListsActions.setWorkOrderAdministratorGroupsMap(
              workOrderAdministratorGroups?.data.reduce((acc, curr) => {
                acc[curr.id] = curr?.name || '';
                return acc;
              }, {}) || []
            )
          );
        }
      }

      dispatch?.(tasksListsActions.setLoading(false));
      if (!sync) dispatch?.(cacheActions.updateSyncing(false));

      const status = res.filter(([success]) => success).length === res.length;
      dispatch?.(globalProgressBarAction.setLoadStatus(12));
      return [status, !status];
    } else throw new Error('Api failed to update');
  },
  geTasksFromStorage: async (userId) => {
    const dispatch = getAppDispatch();

    let pendingTasks = [];
    const pendingSyncAndInProgressTasksOfCurrentUser = (
      (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) ||
      []
    )?.find((item) => item.userId === userId);
    if (!_.isEmpty(pendingSyncAndInProgressTasksOfCurrentUser))
      pendingTasks = !_.isEmpty(pendingSyncAndInProgressTasksOfCurrentUser)
        ? pendingSyncAndInProgressTasksOfCurrentUser?.tasks
        : [];

    dispatch?.(tasksListsActions.setTasksList1(pendingTasks || []));
  },
  getFailedAssetFromStorage: async (userId) => {
    const dispatch = getAppDispatch();

    let data = {
      photos: [],
      documents: []
    };

    const pendingSyncAndInProgressTasksOfCurrentUser = (
      (await LocalStorage.getItem(`userFailedAssets-${decodeToken().userId}`)) || []
    )?.find((item) => item.userId === userId);

    if (!_.isEmpty(pendingSyncAndInProgressTasksOfCurrentUser)) {
      data = {
        photos: pendingSyncAndInProgressTasksOfCurrentUser?.photos,
        documents: pendingSyncAndInProgressTasksOfCurrentUser?.documents
      };
    }
    dispatch?.(tasksListsActions.setSyncFailedAssets(data));
  }
};

export default TasksService;

const cacheWorkOrderPhotosAndDocuments = async ({ workOrderCache }) => {
  try {
    caches.delete('task-images');
    const { settings } = store.getState();
    if (navigator.onLine && settings?.settings?.autoConnectWOPhoto !== 'never') {
      let connection = 'wifi';

      if ('connection' in navigator) {
        connection = navigator.connection.type || 'wifi';
      }
      if (
        (settings?.settings?.autoConnectWOPhoto === 'wifi' && connection !== 'cellular') ||
        settings?.settings?.autoConnectWOPhoto === 'any'
      ) {
        console.log('Work Order Images Caching start');

        let images = [];
        workOrderCache?.data?.map((data) => data?.photos?.map((p) => images.push(p?.url)));
        await Promise.all(images.map((link) => fetch(link)));
      }
    }
  } catch (err) {
    console.log('Work Order Images Caching failed');
  }

  try {
    const { settings } = store.getState();
    caches.delete('documents');
    if (navigator.onLine && settings?.settings?.autoConnectWODocument !== 'never') {
      let connection = 'wifi';

      if ('connection' in navigator) {
        connection = navigator.connection.type || 'wifi';
      }

      if (
        (settings?.settings?.autoConnectWODocument === 'wifi' && connection !== 'cellular') ||
        settings?.settings?.autoConnectWODocument === 'any'
      ) {
        console.log('Work Order Documents Caching start');

        let documents = [];
        workOrderCache?.data?.map((data) => data?.documents?.map((p) => documents.push(p?.url)));
        await Promise.all(documents.map((link) => fetch(link)));
      }
    }
  } catch (err) {
    console.log('Work Order Documents Caching failed');
  }
};

//  const res = await Promise.all([
//    ...(singleSync
//      ? [
//          Promisable.asPromise(
//            http.get(
//              `/WorkOrderCache/MobileCache?page=0&changedAfter=${!initialLogin ? changedAfter : ''}`
//            )
//          ),
//          Promisable.asPromise(
//            http.get(
//              `/SyncedWorkOrderCache/MobileCache?page=0&changedAfter=${
//                !initialLogin ? changedAfter : ''
//              }`
//            )
//          )
//        ]
//      : [
//          Promisable.asPromise(
//            http.get(
//              `/WorkOrderCache/MobileCache?page=0&changedAfter=${
//                !initialLogin
//                  ? changedAfter
//                    ? tasks?.tasksList?.length == 0
//                      ? ''
//                      : changedAfter
//                    : ''
//                  : ''
//              }`
//            )
//          ),
//          Promisable.asPromise(
//            http.get(
//              `/SyncedWorkOrderCache/MobileCache?page=0&changedAfter=${
//                !initialLogin
//                  ? changedAfter
//                    ? tasks?.tasksList?.length == 0
//                      ? ''
//                      : changedAfter
//                    : ''
//                  : ''
//              }`
//            )
//          ),
//          Promisable.asPromise(http.get(`/WorkOrderUsers`)),
//          Promisable.asPromise(http.get(`/WorkOrderUserGroups`)),
//          Promisable.asPromise(http.get(`/WorkOrderAdministrators`)),
//          Promisable.asPromise(http.get(`/WorkOrderAdministratorGroups`))
//        ])
//  ]);
