import axios from 'axios';

import AuthService from './auth.service';

import { AppUrl } from '@utilities/app-urls';
import { getToken } from '@utilities/authentication';
import { log } from '@utilities/logger.util';

const abortController = new AbortController();
const CancelToken = axios.CancelToken;

let source = CancelToken.source();

axios.defaults.baseURL = AppUrl;
axios.defaults.timeout = 2500000;

axios.interceptors.request.use(
  (config) => {
    const { url, data, method } = config;

    log(`http ${method} request`, url, '\n', data);

    return { ...config, cancelToken: source.token, signal: abortController.signal };
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (res) => {
    const { config } = res;
    const { data } = res;
    const { url, method } = config;

    log(`http ${method} response`, url, '\n', data);

    return res;
  },
  (err) => {
    const { config, message: msg, response } = err;
    const message = response?.statusText;
    const { url, method } = config;

    log(`http ${method} error`, url, message || msg, { err });

    if (!response) throw err;

    const { code } = response.data;
    if (
      (code === 401 || response?.status === 401) &&
      (message === 'Unauthorized' ||
        response?.data?.message === 'Unauthorized' ||
        message === 'Password has been changed, Login again' ||
        message === 'Login session has been expired, Login again')
    ) {
      window.stop();
      AuthService.logout({ clearCache: false });
      source.cancel(message);

      setTimeout(() => {
        source = CancelToken.source();

        if (window.location.pathname !== '/') window.location.assign('/');
      }, 300);
    }

    throw err;
  }
);

const http = {
  get: axios.get,
  put: axios.put,
  post: axios.post,
  patch: axios.patch,
  delete: axios.delete,
  setJWT: () => {
    axios.defaults.headers.common['Authorization'] = getToken();
  },
  setMultiPart: () => {
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
  }
};
export default http;
