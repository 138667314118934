/* eslint-disable no-unused-vars */

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//component
import PipeLineCard from './pipeline-card';

const PipelineListingInner = (props) => {
  const {
    divider,
    allDoneTasks,
    allTasksAndInspectionsForPipeline,
    dataPoints,
    onSelect,
    fromLocation,
    isOverDue,
    licenseNumber,
    lineNumber,
    highlight = '',
    highlightName = true,
    showChevronIcon = true,
    showWOCount,
    noIcon = false,
    pipelineId,
    noSite = false,
    status: _status,
    toLocation,
    siteIds = [],
    pipelineDetails,
    pipelineListing,
    appliesTo,
    from,
    link,
    noLink = false,
    addNewWizard = false
  } = props;

  const navigate = useNavigate();

  const to = !onSelect ? `/pipeline-details/${pipelineId}?from=${from ? from : 'pipeline'}` : '';

  const { sitesMap = {}, sites: cacheSites = [] } = useSelector((state) => state.cache);
  const { picklistsMap = {} } = useSelector((state) => state.picklists);

  const status = picklistsMap[_status]?.value;

  let [highlightNames, highlightType] = highlight.split(' ');

  if (!highlightType) highlightType = highlightNames;
  if (highlightName) {
    highlightType = '';
    highlightNames = highlight;
  }

  const onClickLink = (e) => {
    navigate(to);
    if (!onSelect) return;
    e.preventDefault();
    onSelect?.(pipelineId);
  };
  const onSelectPipeline = (e) => {
    e.preventDefault();
    onSelect?.(pipelineId);
  };
  return (
    <>
      <PipeLineCard
        onClickLink={onClickLink}
        to={onSelect ? onSelectPipeline : () => navigate(to)}
        toLocation={toLocation}
        title={`${licenseNumber}-${lineNumber}`}
        fromLocation={fromLocation}
        dataPoints={dataPoints || []}
        highlight={highlight}
        highlightName={highlightName}
        allTasks={allTasksAndInspectionsForPipeline}
        allDoneTasks={allDoneTasks}
        isOverDue={isOverDue}
        status={status}
        sites={siteIds?.length > 0 ? siteIds?.map((x) => cacheSites[sitesMap[x]]) : []}
        showChevronIcon={showChevronIcon}
        noIcon={noIcon}
        noSite={noSite}
        pipelineDetails={pipelineDetails}
        showWOCount={showWOCount}
        pipelineListing={pipelineListing}
        appliesTo={appliesTo}
        from={from}
        link={
          !addNewWizard
            ? !noLink
              ? appliesTo
                ? `/#/pipeline-details/${pipelineId}`
                : link
              : null
            : null
        }
        addNewWizard={addNewWizard}
        pipelineId={pipelineId}
      />
      {divider && <div style={{ width: '100%', borderTop: ' 0.5px solid rgb(203, 210, 217)' }} />}
    </>
  );
};

PipelineListingInner.propTypes = {
  distance: PropTypes.string,
  unit: PropTypes.string,
  venue: PropTypes.string,
  date: PropTypes.string,
  type: PropTypes.string
};

export default PipelineListingInner;
