import { callRelevantSelector } from 'web-workers/handle-selector';

export function initWebWorker({ type, args, timeout = 10000 }) {
  return new Promise((resolve) => {
    let resolved = false;

    if (window.Worker) {
      try {
        const myWorker = new Worker(new URL('./web-workers/complex.webworker', import.meta.url));

        myWorker.onmessage = function (e) {
          if (!resolved) {
            resolved = true;
            resolve(e.data.result);
          }
        };

        myWorker.onerror = function (error) {
          console.error('Worker error: ', error);
          console.log('Falling back to main thread due to worker error.');
          if (!resolved) {
            resolved = true;
            resolve(callRelevantSelector({ type, eventData: { args } }));
          }
        };

        myWorker.postMessage({ args, type });

        setTimeout(() => {
          if (!resolved) {
            console.log('Worker response timeout. Falling back to main thread.');
            resolved = true;
            resolve(callRelevantSelector({ type, eventData: { args } }));
          }
        }, timeout);
      } catch (error) {
        console.error('Failed to create worker: ', error);
        if (!resolved) {
          resolved = true;
          resolve(callRelevantSelector({ type, eventData: { args } }));
        }
      }
    } else {
      console.log('Web Workers not supported. Running in main thread.');
      resolve(callRelevantSelector({ type, eventData: { args } }));
    }
  });
}
