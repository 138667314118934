import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SettingsValueCard from './settings-value-card';
import RadioGroup from '@components/radio-group';

import { settingsActions } from '@redux/slices/settings';

import { distanceOptions } from '../helper';
const GeneralDisplaySettings = () => {
  const dispatch = useDispatch();
  const { settings = {} } = useSelector((state) => state.settings);

  return (
    <div className="settings-card">
      <div className="settings--values">
        <SettingsValueCard
          title={'Distance unit'}
          description={'I’d like to see distance presented in:'}
        >
          <RadioGroup
            options={distanceOptions}
            value={settings['distanceUnit']}
            onChange={(value) => {
              dispatch?.(settingsActions.updateSettingsByName({ name: 'distanceUnit', value }));
            }}
          />
        </SettingsValueCard>
      </div>
    </div>
  );
};

export default memo(GeneralDisplaySettings);
