/* eslint-disable no-unused-vars */
import { store } from 'redux/store';
import Charts from '@assets/images/Charts.svg';
import CloudSync from '@assets/images/Cloudsync.svg';
import Construction from '@assets/images/Construction.svg';
import Data from '@assets/images/Data.svg';
import Devices from '@assets/images/Devices.svg';
import Engineer from '@assets/images/Engineer.svg';
import GearCollab from '@assets/images/GearCollab.svg';
import Internetguy from '@assets/images/Internetguy.svg';
import Maintenance from '@assets/images/Maintenance.svg';
import Markerguy from '@assets/images/Markerguy.svg';
import Meditation from '@assets/images/Meditation.svg';
import MissionImpossible from '@assets/images/MissionImpossible.svg';
import Mountaintop from '@assets/images/Mountaintop.svg';
import Processing from '@assets/images/Processing.svg';
import RocketBoy from '@assets/images/Rocketboy.svg';
import Share from '@assets/images/Share.svg';
import Spaceman from '@assets/images/Spaceman.svg';
import Superman from '@assets/images/Superman.svg';
import Todo from '@assets/images/Todo.svg';
import Uploadguy from '@assets/images/Uploadguy.svg';

const HomeSelector = {
  getImagesName: () => {
    return [
      RocketBoy,
      Meditation,
      Charts,
      CloudSync,
      Construction,
      Data,
      Devices,
      Engineer,
      GearCollab,
      Internetguy,
      Maintenance,
      MissionImpossible,
      Mountaintop,
      Processing,
      Share,
      Spaceman,
      Superman,
      Todo,
      Uploadguy,
      Markerguy
    ];
  },
  getGreetingsText: () => {
    return [
      { text: 'Howdy', symbol: '!' },
      { text: 'Hey there', symbol: '!' },
      { text: 'Hi', symbol: '!' },
      { text: 'Aloha', symbol: '!' },
      { text: 'Ciao', symbol: '!' },
      { text: 'Hello', symbol: '!' },
      { text: 'Greetings', symbol: '!' },
      { text: 'Ahoy there', symbol: '!' },
      { text: 'Salutations', symbol: '!' },
      { text: 'Hiya', symbol: '!' },
      { text: 'Welcome', symbol: '!' },
      { text: 'Bonjour', symbol: '!' },
      { text: '‘Sup', symbol: '?' },
      { text: 'What’s kickin’', symbol: '?' },
      { text: 'Konnichiwa', symbol: '!' },
      { text: 'Guten tag', symbol: '!' },
      { text: 'What’s cookin’', symbol: '?' },
      { text: 'Lookin’ good', symbol: '!' },
      { text: 'Aye aye', symbol: '!' }
    ];
  },
  getMotivationalText: () => {
    return [
      'Let’s do this.',
      'Get ‘er done.',
      'You can do this.',
      'Have a great day.',
      'Make it happen.',
      'Let’s see what today brings.',
      'Pitter-patter. Let’s get at ‘er.',
      'Y’all ready for this?',
      "You're a sight for sore eyes.",
      'Time to swing into action.',
      'Get the ball rolling.',
      'Buckle up, buttercup.',
      'Got work?',
      'Let’s get this show on the road.',
      'Go confidently.',
      'Focus on do-ing, not don’t-ing.',
      'Life is tough but so are you.',
      'If not now, then when?',
      'One step at a time.'
    ];
  }
};

export default HomeSelector;
