/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, memo, useRef } from 'react';
import { Navigate, useParams, useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { store } from 'redux/store';

import PageContainer from '@components/page-container';
import PageHeader from '@components/page-header';
import PatentSiteDetailsInfo from '../parent-site-details-info';
import SiteCard from '@components/site-card';
import NewTabs from '@components/new-tabs';
import SiteListing from '@components/site-listing';
import SiteBookmark from '@components/site-listing/site-bookmark';
import LoadingRound from '@components/loading-round/loading-round';
import EquipmentListing from '@components/equipment-listing';
import StructureListing from '@components/structure-listing';
import PipelineListing from '@components/pipeline-listing';
import WorkOrder from '../../site-details/tasks/work-order';
import ParentSite from '@components/site-parent-site/tasks';
import PermissionRestrict from 'hoc/permission-restrict';
import { moreInfoDropdownList, tabsItemMap } from '@components/site-parent-site/helper';

import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { filterActions } from '@redux/slices/filters';
import { PERMISSIONS } from 'permissions/constants';
import { log } from '@utilities/logger.util';

import './parent-site.scss';
import style from './style.module.scss';

import { initWebWorker } from 'webworker';

const ParentSiteDetails = () => {
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = getAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const bookmarkName = searchParams.get('bookmarkName');
  const bookmarkTab = searchParams.get('bookmarkTab');
  const templateString = searchParams.get('siteTemplates') || '';
  const currentOpenTaskTab = searchParams.get('currentOpenTaskTab') || '';

  const swipeAbleMenuRef = useRef(null);
  const mainDivRef = useRef(null);
  const siteCardRef = useRef(null);
  const assetDetailPageHeaderRef = useRef(null);

  const [tab, setTab] = useState('0');
  const [open, setOpen] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const [allTask, setAllTask] = useState(false);
  const [checks, setChecks] = useState(false);
  const [inspections, setInspections] = useState(false);
  const [workOrder, setWorkOrder] = useState(false);
  const [workRequest, setWorkRequest] = useState(false);
  const [hseEvent, setHseEvent] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [parentSiteDetails, setParentSiteDetails] = useState({});
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);
  const [isFilterActiveForEquipments, setFilterActiveForEquipments] = useState(
    width < 768 ? false : true
  );
  const [isFilterActiveForPipeline, setFilterActiveForPipeline] = useState(
    width < 768 ? false : true
  );
  const [isFilterActiveForStructure, setFilterActiveForStructure] = useState(
    width < 768 ? false : true
  );
  const [isFilterActiveForChildSites, setFilterActiveForChildSites] = useState(
    width < 768 ? false : true
  );
  const [isLoading, setIsLoading] = useState(true);
  const parentSiteDetailsInfo = useSelector((state) => state.backHistory.parentSiteDetails);
  const {
    tasksFilterCount = 0,
    structureFilterCount = 0,
    equipmentFilterCount,
    pipelineFilterCount = 0,
    sitesFilterCount = 0
  } = useSelector((state) => state.filters);
  const { tasksList: reduxTasksList = [], selectTask } = useSelector((state) => state.tasks);
  const { inspectionsList } = useSelector((state) => state.inspection);
  const { HSEEventList } = useSelector((state) => state.hseEvents);
  const { checksList } = useSelector((state) => state.checks);

  useEffect(() => {
    if (currentOpenTaskTab) {
      if (currentOpenTaskTab === 'allTask') setAllTask(true);
      else if (currentOpenTaskTab === 'workOrder') setWorkOrder(true);
      else if (currentOpenTaskTab === 'inspections') setInspections(true);
      else if (currentOpenTaskTab === 'workRequest') setWorkRequest(true);
      else if (currentOpenTaskTab === 'hseEvent') setHseEvent(true);
      else if (currentOpenTaskTab === 'check') setChecks(true);
    }
  }, [currentOpenTaskTab]);

  const templatesIds = useMemo(
    () => templateString.split(',').filter((el) => el),
    [templateString]
  );

  useEffect(() => {
    if (!_.isEmpty(parentSiteDetailsInfo)) {
      parentSiteDetailsInfo?.parentSiteDetailsTab &&
        setTab(parentSiteDetailsInfo?.parentSiteDetailsTab || '0');
      parentSiteDetailsInfo?.workOrder && setWorkOrder(parentSiteDetailsInfo?.workOrder);
    }
  }, [parentSiteDetailsInfo]);

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() },
        siteId: id,
        templatesIds,
        equipmentsWithWO: true
      },
      type: 'get-parent-site-complete-details'
    })
      .then((data) => {
        setParentSiteDetails(data?.result);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }, [id, templatesIds, reduxTasksList, inspectionsList, HSEEventList, checksList]);

  useEffect(() => {
    if (!isLoading) {
      !dataPoints && log(unit, distance, children);
      if (!sitesLoading && !parentSite?.siteId) return <Navigate to={'/sites'} />;
    }
  }, [isLoading, parentSiteDetails]);

  const {
    sitesLoading,
    name,
    tasks,
    allDoneTasks,
    allWorkOrders,
    allDoneWorkOrders,
    allInspections,
    allDoneInspections,
    allChecks,
    allDoneChecks,
    isCheckOverDue,
    isOverDue,
    isWorkOrderOverDue,
    isInspectionOverDue,
    status,
    area,
    equipments,
    equipmentsMap,
    dataPoints,
    unit,
    distance,
    children,
    parentSite,

    dataPointsMap,
    MoreInfoDataPointsMap,
    equipmentsWithWO,
    structures,
    structuresMap,
    structureAllFieldData,
    pipelines = [],
    link,
    newWorkRequestList,
    hseEvents
  } = useMemo(() => {
    return parentSiteDetails;
  }, [parentSiteDetails]);

  const arrayText = useMemo(() => {
    return moreInfoDropdownList({ setMoreInfo, setOpen, setOpenModal, link });
  }, [link]);

  const itemsMap = useMemo(() => {
    return tabsItemMap({
      isLoading,
      tasks,
      children,
      equipments,
      structures,
      pipelines
    });
  }, [isLoading]);

  const tabIsActive = useMemo(() => {
    return (
      isFilterActiveForEquipments ||
      isFilterActiveForStructure ||
      isFilterActiveForPipeline ||
      isFilterActiveForChildSites ||
      false
    );
  }, [
    isFilterActiveForEquipments,
    isFilterActiveForStructure,
    isFilterActiveForPipeline,
    isFilterActiveForChildSites
  ]);

  useEffect(() => {
    const elements = document.getElementsByClassName('react-swipeable-view-container');

    for (let i = 0; i < elements.length; i++) {
      let element = elements[i];
      if (tasks?.length > 0) {
        if (tab === 1) element.style.transform = tabIsActive ? 'none' : 'translate(-100%, 0px)';
        if (tab === 2) element.style.transform = tabIsActive ? 'none' : 'translate(-200%, 0px)';
        if (tab === 3) element.style.transform = tabIsActive ? 'none' : 'translate(-300%, 0px)';
        if (tab === 4) element.style.transform = tabIsActive ? 'none' : 'translate(-400%, 0px)';

        if (
          mainDivRef?.current &&
          swipeAbleMenuRef.current &&
          mainDivRef?.current?.scrollTop >= siteCardRef?.current?.offsetHeight
        ) {
          if (tabIsActive) swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
          // else swipeAbleMenuRef.current.className = `assetDetailNavFixed`;
        }
      } else {
        if (parseInt(tab) === 0)
          element.style.transform = tabIsActive ? 'none' : 'translate(0px, 0px)';
        if (tab === 1) element.style.transform = tabIsActive ? 'none' : 'translate(-100%, 0px)';
        if (tab === 2) element.style.transform = tabIsActive ? 'none' : 'translate(-200%, 0px)';
        if (tab === 3) element.style.transform = tabIsActive ? 'none' : 'translate(-300%, 0px)';

        if (
          mainDivRef?.current &&
          swipeAbleMenuRef.current &&
          mainDivRef?.current?.scrollTop >= siteCardRef?.current?.offsetHeight
        ) {
          if (tabIsActive) swipeAbleMenuRef.current.className = `assetDetailNavNotFixed`;
          // else swipeAbleMenuRef.current.className = `assetDetailNavFixed`;
        }
      }
    }
  }, [tabIsActive, mainDivRef, swipeAbleMenuRef, siteCardRef]);

  const items = useMemo(() => {
    return !isLoading
      ? [
          ...(tasks?.length > 0 || hseEvents?.length > 0
            ? [
                {
                  label: `Tasks + records (${tasks.length + hseEvents?.length})`,
                  keyName: 'tasks',
                  children: (
                    <ParentSite
                      setChecks={setChecks}
                      setAllTask={setAllTask}
                      setWorkOrder={setWorkOrder}
                      setInspections={setInspections}
                      allWorkOrderForSite={allWorkOrders}
                      allDoneWorkOrder={allDoneWorkOrders}
                      allInspectionForSite={allInspections}
                      allDoneInspection={allDoneInspections}
                      allCheckForSite={allChecks}
                      allDoneChecks={allDoneChecks}
                      allTasksForSite={tasks}
                      allDoneTasks={allDoneTasks}
                      isOverDue={isOverDue}
                      isWorkOrderOverDue={isWorkOrderOverDue}
                      isInspectionOverDue={isInspectionOverDue}
                      isCheckOverDue={isCheckOverDue}
                      setWorkRequest={setWorkRequest}
                      setHseEvent={setHseEvent}
                      newWorkRequestList={newWorkRequestList}
                      hseEvents={hseEvents}
                    />
                  )
                }
              ]
            : []),

          ...(children?.length > 0
            ? [
                {
                  label: `Child sites (${children?.length})`,
                  keyName: 'site',
                  children: (
                    <SiteListing
                      assetDetailsSiteLists={children}
                      isFilterActiveHeader={isFilterActiveForChildSites}
                      setFilterActiveHeader={setFilterActiveForChildSites}
                      from={location.pathname}
                      siteId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'site'}
                      navLabel="Child sites"
                    />
                  )
                }
              ]
            : []),

          ...(equipments?.length > 0
            ? [
                {
                  label: `Equipment (${equipments?.length})`,
                  keyName: 'equipment',
                  children: (
                    <EquipmentListing
                      assetDetailsOfEquipmentLists={equipmentsWithWO}
                      isFilterActiveHeader={isFilterActiveForEquipments}
                      setFilterActiveHeader={setFilterActiveForEquipments}
                      from={location.pathname}
                      siteId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'equipment'}
                    />
                  )
                }
              ]
            : []),

          ...(structures?.length > 0
            ? [
                {
                  label: `Structure (${structures?.length})`,
                  keyName: 'structure',
                  children: (
                    <StructureListing
                      assetDetailsStructureLists={structureAllFieldData}
                      isFilterActiveHeader={isFilterActiveForStructure}
                      setFilterActiveHeader={setFilterActiveForStructure}
                      from={location.pathname}
                      siteId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'structure'}
                    />
                  )
                }
              ]
            : []),
          ...(pipelines?.length > 0
            ? [
                {
                  label: `Pipeline (${pipelines?.length})`,
                  keyName: 'pipeline',
                  children: (
                    <PipelineListing
                      assetDetailsPipelineLists={pipelines}
                      isFilterActiveHeader={isFilterActiveForPipeline}
                      setFilterActiveHeader={setFilterActiveForPipeline}
                      from={location.pathname}
                      siteId={id}
                      detailPage
                      detailsPageBookmarks={searchParams.get('bookmarkKeyName') === 'pipeline'}
                    />
                  )
                }
              ]
            : [])
        ]
      : [];
  }, [
    isLoading,
    itemsMap,
    isFilterActive,
    isFilterActiveForEquipments,
    isFilterActiveForPipeline,
    isFilterActiveForStructure,
    isFilterActiveForChildSites,
    allDoneTasks,
    tasks
  ]);

  useEffect(() => {
    if (items?.length > 0) {
      const params = new URLSearchParams(location.search);
      const currentTab = parseInt(tab);
      if (items[currentTab]?.keyName === 'tasks') {
        if (allTask) params.set('currentOpenTaskTab', 'allTask');
        else if (workOrder) params.set('currentOpenTaskTab', 'workOrder');
        else if (inspections) params.set('currentOpenTaskTab', 'inspections');
        else if (workRequest) params.set('currentOpenTaskTab', 'workRequest');
        else if (hseEvent) params.set('currentOpenTaskTab', 'hseEvent');
        else if (checks) params.set('currentOpenTaskTab', 'checks');
        else if (!workOrder && !allTask && !inspections && !workRequest && !hseEvent && !checks)
          params.delete('currentOpenTaskTab');
      }

      params.set('tab', tab);
      params.set(
        'virtualScrollTab',
        currentTab === 0 && items[currentTab]?.keyName === 'tasks'
          ? -1
          : tasks?.length > 0
          ? currentTab - 1
          : currentTab
      );
      params.set('keyName', items[tab]?.keyName);
      const tabParams = '?' + params.toString();
      const prevRoute = `${location.pathname}${tabParams}`;
      navigate(prevRoute, { replace: true });
    }
  }, [tab, items, workOrder, allTask, inspections, workRequest, hseEvent]);

  useEffect(() => {
    if (!isLoading && bookmarkTab) setTab(bookmarkTab);
  }, [isLoading, bookmarkTab]);

  const onParentClickEvent = ({ workOrderTab, workOrderSortTab }) => {
    dispatch?.(
      backHistoryActions.setParentSiteDetailsPageHistory({
        workOrder,
        parentSiteDetailsTab: tab,
        workOrderTab,
        workOrderSortTab
      })
    );
  };

  const pageHeaderTitle = useMemo(() => {
    return `${
      bookmarkName
        ? bookmarkName
        : moreInfo
        ? 'Site Information'
        : allTask
        ? `All tasks (${tasks?.length > 0 ? tasks?.length : ''})`
        : checks
        ? `Checks (${allChecks?.length > 0 ? allChecks?.length : ''})`
        : inspections
        ? `Inspections (${allInspections?.length > 0 ? allInspections?.length : ''})`
        : workOrder
        ? `Work orders (${allWorkOrders?.length > 0 ? allWorkOrders?.length : ''})`
        : workRequest
        ? `Work requests (${newWorkRequestList?.length > 0 ? newWorkRequestList.length : ''})`
        : hseEvent
        ? `HSE events (${hseEvents?.length > 0 ? hseEvents.length : ''})`
        : 'Parent site'
    }`;
  }, [
    bookmarkName,
    moreInfo,
    allTask,
    checks,
    inspections,
    workOrder,
    tasks,
    workRequest,
    hseEvent
  ]);

  const pageHeaderClickEvent = () => {
    workOrder && dispatch?.(filterActions.setTaskFilters(null));
    if (moreInfo) setMoreInfo(false);
    else if (allTask) setAllTask(false);
    else if (checks) setChecks(false);
    else if (inspections) setInspections(false);
    else if (workOrder) setWorkOrder(false);
    else if (workRequest) setWorkRequest(false);
    else if (hseEvent) setHseEvent(false);

    dispatch?.(backHistoryActions.setParentSiteDetailsPageHistory(null));
  };

  const isFilterIconIsRequired = useMemo(() => {
    return (tab !== '0' && !items[tab]?.keyName?.toLowerCase()?.includes('tasks')) ||
      moreInfo ||
      allTask ||
      checks ||
      inspections ||
      workOrder ||
      workRequest ||
      hseEvent
      ? items[tab]?.keyName === 'equipment'
        ? setFilterActiveForEquipments
        : items[tab]?.keyName === 'structure'
        ? setFilterActiveForStructure
        : items[tab]?.keyName === 'pipeline'
        ? setFilterActiveForPipeline
        : items[tab]?.keyName === 'site'
        ? setFilterActiveForChildSites
        : setFilterActive
      : false;
  }, [tab, items, allTask, checks, inspections, workOrder, workRequest, hseEvent]);

  const filtersCount = useMemo(() => {
    return items[tab]?.keyName === 'equipment'
      ? equipmentFilterCount
      : items[tab]?.keyName === 'structure'
      ? structureFilterCount
      : items[tab]?.keyName === 'pipeline'
      ? pipelineFilterCount
      : items[tab]?.keyName === 'site'
      ? sitesFilterCount
      : tasksFilterCount;
  }, [
    tab,
    items,
    equipmentFilterCount,
    structureFilterCount,
    pipelineFilterCount,
    sitesFilterCount,
    tasksFilterCount
  ]);

  const siteDetailsPageClassNames = useMemo(() => {
    return `${moreInfo || selectTask ? style.pageListingsWithMoreInfo : style.pageListings} ${
      style.noScroll
    } ${
      (workOrder || allTask || checks || inspections || workRequest || hseEvent) && style.noScroll
    }`;
  }, [workOrder, allTask, checks, inspections, moreInfo, selectTask, workRequest, hseEvent]);

  return (
    <PageContainer moreInfo={moreInfo}>
      <PageHeader
        disableBack={
          moreInfo ||
          allTask ||
          checks ||
          inspections ||
          workOrder ||
          workRequest ||
          hseEvent ||
          checks
        }
        {...(isFilterIconIsRequired && {
          setFilterActive: isFilterIconIsRequired
        })}
        title={pageHeaderTitle}
        showFilter={pageHeaderTitle?.toLowerCase()?.includes('information')}
        onClick={pageHeaderClickEvent}
        count={filtersCount}
        ref={assetDetailPageHeaderRef}
      />
      {isLoading ? (
        <LoadingRound className={style.loading} />
      ) : (
        <div
          className={siteDetailsPageClassNames}
          style={{ position: 'relative', paddingBottom: '0px', marginTop: '0px' }}
          id="assetDetailPage"
          ref={mainDivRef}
        >
          <div className="marginClass" ref={siteCardRef}>
            <SiteCard
              siteDetailsCard
              chips={true}
              title={area}
              subtitle={name}
              hideLocation={true}
              dataPoints={dataPoints || []}
              allTasksForSite={tasks}
              allDoneTasks={allDoneTasks}
              isOverDue={isOverDue}
              status={status}
              arrayText={arrayText}
              open={open}
              setOpen={setOpen}
              moreInfo={moreInfo}
              childrenList={children}
              addChild={!moreInfo && '+ child sites'}
              recordIndicatorLength={hseEvents?.length + newWorkRequestList?.length}
            />
          </div>
          {moreInfo ? (
            <PatentSiteDetailsInfo MoreInfoDataPointsMap={MoreInfoDataPointsMap} />
          ) : (
            <div className="flex relative">
              <div
                style={{
                  marginTop: '0px',
                  overflow: 'hidden',
                  paddingBottom: '0px'
                }}
                className={`${
                  moreInfo ? style.pageListingsWithMoreInfo : style.pageListings
                } mobileMargin`}
              >
                {allTask || inspections || workOrder || hseEvent || workRequest || checks ? (
                  <WorkOrder
                    tasks={
                      inspections
                        ? allInspections
                        : allTask
                        ? tasks
                        : workOrder
                        ? allWorkOrders
                        : workRequest
                        ? newWorkRequestList
                        : hseEvent
                        ? hseEvents
                        : checks
                        ? allChecks
                        : []
                    }
                    workRequest={workRequest}
                    isFilterActiveHeader={isFilterActive}
                    setFilterActiveHeader={setFilterActive}
                    selectTodoOrInProgressStatus={!_.isEmpty(bookmarkName) ? true : false}
                    workOrderSelectedTab={parentSiteDetailsInfo?.workOrderTab || null}
                    workOrderSortTab={parentSiteDetailsInfo?.workOrderSortTab || null}
                    onParentClickEvent={onParentClickEvent}
                    parentSiteDetails
                    hseEvent={hseEvent}
                    filterType={
                      allTask
                        ? 'all'
                        : inspections
                        ? 'inspection'
                        : workOrder
                        ? 'workOrder'
                        : workRequest
                        ? 'workRequest'
                        : hseEvent
                        ? 'hseEvents'
                        : checks
                        ? 'checks'
                        : null
                    }
                    asset="sites"
                  />
                ) : items?.length > 0 ? (
                  <div
                    ref={swipeAbleMenuRef}
                    id="assetDetailsNavigation"
                    className={`assetDetailNavNotFixed`}
                  >
                    <NewTabs
                      active={tab}
                      tabs={items}
                      setActive={(tab) => {
                        setTab(tab);
                      }}
                      className={style.mobileMargin}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      className="no-result"
                      style={{ flex: 1, justifyContent: 'flex-start', gap: '20px' }}
                    >
                      <div>No results</div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {openModal && (
        <SiteBookmark
          open={openModal}
          setOpen={(value) => {
            setOpenModal(value);
          }}
          placeholder="My favourite truck"
          type="Parent Site Details"
          siteId={id || null}
        />
      )}
    </PageContainer>
  );
};

export default memo(ParentSiteDetails);
