import React from 'react';
import SettingsValueCard from './settings-value-card';
import DragDropFields from './drag-frop-fields';
const EquipmentDisplaySettings = () => {
  return (
    <div className="settings--values">
      <SettingsValueCard
        title={'Equipment cards'}
        description={'This is how I want equipment data to appear on listings and task cards:'}
      >
        <DragDropFields text={'equipment'} />
      </SettingsValueCard>
    </div>
  );
};

export default EquipmentDisplaySettings;
