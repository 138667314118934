import { store } from 'redux/store';

import http from './http.service';
import Promisable from './promisable.service';
import { cacheActions } from '@redux/slices/cache';
import { getAppDispatch } from '@utilities/dispatch.util';
import { checkOldAndNewArray } from './check-old-and-new-array';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';

const SitesService = {
  getSites: async ({ initialLogin, lastSuccessFullRefreshTime, isSettingUpdated }) => {
    const { cache } = store.getState();
    const { sitesMap = {}, sites = [] } = cache;
    const cachingFailure = localStorage.getItem('cachingFailure');

    if (!JSON.parse(cachingFailure)) {
      const changedAfter = lastSuccessFullRefreshTime;

      const dispatch = getAppDispatch();
      dispatch?.(cacheActions.setSitesLoading(true));

      http.setJWT();

      const [success, error] = await Promisable.asPromise(
        http.get(
          `/Sites/mobile?page=0&changedAfter=${
            !initialLogin ? (changedAfter ? (sites?.length === 0 ? '' : changedAfter) : '') : ''
          }`
        )
      );

      if (!initialLogin && success?.data?.items?.length && sites?.length && !isSettingUpdated) {
        const combineNewAndOldArray = checkOldAndNewArray({
          oldArray: sites,
          oldArrayMap: sitesMap,
          newArray: success?.data?.items,
          idName: 'siteId'
        });
        dispatch?.(cacheActions.setSitesMobile(combineNewAndOldArray));
      } else {
        if (success?.data?.items?.length)
          dispatch?.(cacheActions.setSitesMobile(success?.data?.items));
      }

      dispatch?.(globalProgressBarAction.setLoadStatus(9));
      dispatch?.(cacheActions.setSitesLoading(false));
      return [success, error];
    } else throw new Error('Api failed to update');
  }
};

export default SitesService;
