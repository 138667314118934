/* eslint-disable no-unused-vars */

import _ from 'lodash';
import React, { useEffect, useState, useRef, memo, forwardRef } from 'react';

import Icon from '@components/icon-component';
import DropDown from '@components/drop-down';

import useOutsideClick from '@hooks/useOutsideAlert';

import './bookmark.scss';

const Bookmark = (
  {
    name,
    onRename,
    onDelete,
    glance = false,
    dragHandleProps,
    className,
    addToOrRemoveFromGlance,
    noDrag = false,
    setRenameOpen = () => {},
    setDeleteOpen = () => {},
    onClick
  },
  ref
) => {
  const [open, setOpen] = useState(false);
  const [up, setUp] = useState('');
  const [renameModal, setRenameModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const bookMarkRef = useRef(null);

  useEffect(() => {
    renameModal
      ? setRenameOpen({
          open: renameModal,
          setOpen: setRenameModal,
          name,
          atAGlance: glance,
          onRename
        })
      : setRenameOpen({});
  }, [renameModal]);

  useEffect(() => {
    delModal
      ? setDeleteOpen({
          open: delModal,
          setOpen: () => setDelModal(false),
          handleDelete: () => {
            onDelete();
            setDelModal(false);
            setDeleteOpen({});
          },
          confirmDeletionBookmark: true
        })
      : setDeleteOpen({});
  }, [delModal]);

  useOutsideClick(bookMarkRef, () => {
    if (open) setOpen(false);
  });

  const items = [
    {
      key: '1',
      iconName: glance ? 'removeBookmark' : 'addGlance',
      label: `${glance ? 'Remove from' : 'Add to'} ‘At a glance’`,
      onClick: (e) => {
        setOpen(false);
        addToOrRemoveFromGlance();
        e?.stopPropagation();
      }
    },
    {
      key: '2',
      iconName: 'editIcon',
      label: 'Rename',
      onClick: (e) => {
        setRenameModal(true);
        setOpen(false);
        e?.stopPropagation();
      }
    },
    {
      key: '3',
      iconName: 'delIcon',
      label: 'Delete',
      onClick: (e) => {
        setDelModal(true);
        setOpen(false);
        e?.stopPropagation();
      }
    }
  ];
  return (
    <div
      className="bookmark"
      style={{ paddingLeft: noDrag ? '58px' : '16px', cursor: 'pointer' }}
      ref={bookMarkRef}
      onClick={onClick}
    >
      <div className="bookmark--title">
        <div className="bookmark--icon" {...dragHandleProps}>
          {noDrag && <Icon name={'drag'} />}
        </div>
        <span style={{ fontSize: '16px' }}>{name}</span>
      </div>
      <div style={{ position: 'relative' }}>
        <DropDown
          up={up}
          open={open}
          setOpen={setOpen}
          items={items}
          className={className}
          Component={
            <Icon
              name={'more'}
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                const clickOffset = e.clientY - ref?.current?.getBoundingClientRect()?.top;

                if (ref?.current) {
                  const { scrollTop, clientHeight, scrollHeight } = ref.current;
                  if (scrollTop > 0) {
                    if (scrollHeight - Math.round(scrollTop) === clientHeight) {
                      setUp('-164');
                    } else {
                      setUp('');
                    }
                  } else if (
                    scrollTop === 0 &&
                    clientHeight === scrollHeight &&
                    scrollHeight - clickOffset < 168
                  ) {
                    setUp('-164');
                  } else {
                    setUp('');
                  }
                }
                setOpen((prev) => !prev);
                e?.stopPropagation();
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default memo(forwardRef(Bookmark, _.isEqual));
