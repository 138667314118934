import React from 'react';

import style from '../workorder.module.scss';

const ReadableText = ({ field }) => {
  return (
    <div className={style.body}>
      <h6>{field?.label}</h6>

      {field?.mailto ? (
        <a href={`mailto:${field?.value}`}>
          <p className={style.requestedByEmail}>{field?.value}</p>
        </a>
      ) : field?.tel ? (
        <a href={`tel:${field?.value}`}>
          <p className={style.requestedByPhone}>{field?.value}</p>
        </a>
      ) : (
        <p>{field?.value}</p>
      )}
    </div>
  );
};

export default ReadableText;
