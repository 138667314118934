/* eslint-disable no-unused-vars */

// import { store } from 'redux/store';

import { getDataPoints } from '@pages/settings/helper';

export const searchPipelineData = ({ pipeline = {}, inputValue = '', store }) => {
  const { settings, picklists, cache } = store;
  const { picklistsMap, areaHierarchyTreeDataMap = {} } = picklists;
  const { sitesMap = {}, sites: cacheSites = [] } = cache;
  const { siteDisplayFields } = settings.settings;

  const { siteIds, licenseNumber, lineNumber, dataPoints, toLocation, fromLocation } = pipeline;
  const sites = siteIds?.map((x) => cacheSites[sitesMap[x]] || {});

  const sitesTitle =
    sites
      ?.map((x) =>
        x?.parent
          ? 'View all work at'
          : areaHierarchyTreeDataMap[x?.areaHierarchy || '']?.title || ''
      )
      .reduce((acc, name, index) => {
        acc[`siteTitle${index}`] = name;
        return acc;
      }, {}) || [];
  const siteName =
    sites
      ?.map((x) => {
        return x.name;
      })
      ?.reduce((acc, name, index) => {
        acc[`siteName${index}`] = name;
        return acc;
      }, {}) || [];

  const title = `${licenseNumber} - ${lineNumber}`;
  const title1 = `${licenseNumber}-${lineNumber}`;

  const dataPointsObject = dataPoints.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
    {}
  );

  const dataPointsForSite = sites.flatMap((x) => {
    const dataPoints = getDataPoints(
      { ...x, type: x.siteType },
      siteDisplayFields,
      'site',
      picklistsMap
    );

    return dataPoints;
  });

  const dataPointsObjectFOrSite = dataPointsForSite.reduce((acc, item, index) => {
    acc[`${[item.key]}${index}`] = `${item.title} : ${item.value}`;
    return acc;
  }, {});

  const filterObject = {
    title,
    title1,
    toLocation,
    fromLocation,
    ...sitesTitle,
    ...siteName,
    ...dataPointsObject,
    ...dataPointsObjectFOrSite
  };

  if (
    !Object.values(filterObject).some((value) => {
      return String(value).toLowerCase().includes(inputValue?.toLowerCase());
    })
  )
    return false;

  return true;
};
