/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { store } from 'redux/store';
import { removeUnavailableTreeData } from '@components/tree-component';

export const statusTabs = {
  'To do': '0',
  'In progress': '1',
  'Pending sync': '2',
  Synced: '3'
};

export const tagsData = ['DISTANCE', 'DUE', 'SCHEDULED', 'RECENT'];
export const tagsDataRT = ['RT ORDER', 'DUE', 'SCHEDULED', 'RECENT'];

export const getTemplates = ({ type = 'Work Order', tasks = [], runs = [], templates = [] }) => {
  if (!tasks.length) return [];
  if (type === 'Work Order') {
    if (!templates.length) return [];

    const data = [];

    templates
      ?.map((t, index) => {
        const filtered = tasks
          .filter((el) => el.rtNumber === t && el.type === type)
          .map((task) => ({ ...task, checked: false }));

        const tasksMap = filtered?.reduce((acc, curr) => {
          acc[curr.id] = true;
          return acc;
        }, {});

        if (filtered.length)
          data.push({
            id: t,
            open: false,
            checked: false,
            tasks: filtered,
            title: filtered[0].rtName,
            tasksMap
          });
      })
      ?.sort((a, b) => {
        const rtNumberPrev = (a?.id?.split('-') && a?.id.split('-')[1]) || null;
        const rtNumberNext = (b?.id?.split('-') && b?.id?.split('-')[1]) || null;
        return parseInt(rtNumberPrev) - parseInt(rtNumberNext);
      });

    return data;
  }

  if (type === 'Inspection') {
    if (!runs.length) return [];

    const data = [];
    runs
      ?.map((run, index) => {
        const filtered = tasks
          .filter((el) => el?.runNumber === run && el.type === type)
          .map((task) => ({ ...task, checked: false }));

        const tasksMap = filtered?.reduce((acc, curr) => {
          acc[curr.id] = true;
          return acc;
        }, {});

        if (filtered.length)
          data.push({
            id: run,
            open: false,
            checked: false,
            tasks: filtered,
            title: filtered[0]?.RunName || '',
            tasksMap
          });
      })
      ?.sort((a, b) => {
        const rtNumberPrev = (a?.id?.split('-') && a?.id.split('-')[1]) || null;
        const rtNumberNext = (b?.id?.split('-') && b?.id?.split('-')[1]) || null;
        return parseInt(rtNumberPrev) - parseInt(rtNumberNext);
      });
    return data;
  }

  return [];
};

export const initialPopupStat = {
  title: 'Is it ready to go?',
  content: `<p class='other-caption mt-3.5' >Ensure your internet connection is stable before syncing.</p>`,
  actionbtnClass: 'btn-fill button-large mt-8',
  CloseBtnText: 'Maybe Later',
  CloseBtnClass: 'op-anchor-link',
  Status: 'sync',
  disabled: false
};

export const popupAction = (State, tasks, openModalIndex) => {
  let popupData = {};

  const currData = tasks[openModalIndex];
  const type = currData.workorderId
    ? 'Work Order'
    : currData?.type === 'Inspection'
    ? 'Inspection'
    : 'Work request';

  switch (State) {
    case 'offline':
      popupData = {
        title: 'You’re offline.',
        subtitle: `Sorry. No can do.`,
        content: `<p class='body-regular text-[#F26531] mt-2' >You’ll need a stable internet connection before you can sync.</p>`,
        actionbtntext: 'SYNC NOW',
        actionbtnClass: 'btn-fill button-large mt-8',
        CloseBtnText: 'CLOSE',
        CloseBtnClass: 'op-anchor-link',
        Status: 'offline',
        disabled: true
      };
      break;
    case 'progress':
      popupData = {
        title: 'Syncing in progress',
        subtitle: `Hold on. We’re sending in all of your hard work.`,
        content: `<ul class='op-check-list mt-2.5'>
       <li class='input-medium-bullet'>1/1 ${type.toLowerCase()}</li>
     </ul>`,
        actionbtntext: '',
        actionbtnClass: 'hidden',
        CloseBtnClass: 'btn-fill button-large',
        CloseBtnText: 'Close',
        Status: 'progress',
        disabled: false
      };
      break;
    case 'complete':
      popupData = {
        title: 'Syncing complete',
        subtitle: `Oh yeah!`,
        content: `<ul class='op-check-list mt-4'>
       <li class='input-medium-bullet'>1/1 ${type.toLowerCase()}</li>
     </ul>`,
        actionbtntext: '',
        actionbtnClass: 'hidden',
        CloseBtnClass: 'btn-fill button-large',
        CloseBtnText: 'Close',
        Status: 'complete',
        disabled: false
      };
      break;
    case 'failed':
      popupData = {
        title: 'You’re offline.',
        subtitle: `It has been 2 days since you’ve synced and updated data. <br/> You have 3 records that are pending sync.`,
        content: `<p class='body-regular text-[#F26531] mt-2' >You’ll need a stable internet connection before you can sync.</p>`,
        actionbtntext: 'SYNC NOW',
        actionbtnClass: 'btn-fill button-large mt-8',
        CloseBtnClass: 'op-anchor-link',
        CloseBtnText: 'CLOSE',
        Status: 'failed',
        disabled: true
      };
      break;
    default:
      popupData = initialPopupStat;
  }
  return popupData;
};

export const mapValues = ({
  sitesSiteTypeDataMap,
  sitesSiteStatusDataMap,
  assignedUserGroups,
  sitesCheckPriorityDataMap,
  sitesWorkOrderPriorityDataMap,
  sitesAreaHierarchyTreeDataMap,
  tasks
}) => {
  const { picklists, tasks: reduxTasks, inspection, checks } = store.getState();
  const { checkTypesMap } = checks;
  const {
    siteTypeData = [],
    siteStatusData = [],
    checkPriorityData = [],
    workOrderPriorityData = [],
    areaHierarchyTreeData = [],
    picklistsMap = {}
  } = picklists;

  const { workOrderUsersGroupsMap } = reduxTasks;
  const {
    inspectionTypesMap = {},
    incidentCategoryMap = {},
    inspectionInspectorUserGroupsMap = {}
  } = inspection;

  const siteTypeTags = sitesSiteTypeDataMap
    ? siteTypeData?.filter(({ key }) => sitesSiteTypeDataMap?.[key])
    : siteTypeData;

  const siteStatusTags = sitesSiteStatusDataMap
    ? siteStatusData?.filter(({ key }) => sitesSiteStatusDataMap[key])
    : siteStatusData;

  const assignableUsersTags = getTaskAssignedToTags({
    assignedUserGroups,
    workOrderUsersGroupsMap,
    inspectionInspectorUserGroupsMap
  });

  const checkPriorityTags = sitesCheckPriorityDataMap
    ? checkPriorityData.filter(({ key }) => sitesCheckPriorityDataMap[key])
    : checkPriorityData;

  const workOrderPriorityTags = sitesWorkOrderPriorityDataMap
    ? workOrderPriorityData.filter(({ key }) => sitesWorkOrderPriorityDataMap[key])
    : workOrderPriorityData;
  const treeData = sitesAreaHierarchyTreeDataMap
    ? removeUnavailableTreeData(
        JSON.parse(JSON.stringify(areaHierarchyTreeData)),
        sitesAreaHierarchyTreeDataMap
      )
    : areaHierarchyTreeData;

  const taskTypeTag = getTaskTypeTags(tasks);
  const taskStatusTags = getTaskStatusTags(tasks);
  const { inspectionTypeTags, inspectionTypeTagsMap } = getInspectionTypeTags(
    tasks,
    inspectionTypesMap
  );
  const { checkTypeTags, checkTypeTagsMap } = getCheckTypeTags(tasks, checkTypesMap);
  const inspectionRunTags = getInspectionRunTags(tasks);
  const hseEventsCategoriesTags = getHSEEventCategory(tasks, incidentCategoryMap);
  const taskRecurringTemplateTags = getTaskRecurringTemplateTags(tasks);
  const workOrderTypeTags = getWorkOrderTypeTags(tasks, picklistsMap) || [];
  const checkRecurrancePriorityTags = getCheckRecurrancePriorityTags(tasks, picklistsMap);
  return {
    siteTypeTags,
    siteStatusTags,
    assignableUsersTags,
    checkPriorityTags,
    workOrderPriorityTags,
    treeData,
    taskTypeTag,
    taskStatusTags,
    taskRecurringTemplateTags,
    workOrderTypeTags,
    inspectionTypeTags,
    inspectionRunTags,
    hseEventsCategoriesTags,
    checkTypeTags,
    checkRecurrancePriorityTags,
    checkTypeTagsMap,
    inspectionTypeTagsMap
  };
};

const getTaskRecurringTemplateTags = (tasks = []) => {
  const tags = Array.from(
    new Set(
      tasks.map((task) => {
        if (task.rtNumber) return `${task.rtNumber} - ${task.rtName?.trim()}`;
      })
    )
  ).filter((y) => y);
  return tags;
};

const getCheckRecurrancePriorityTags = (tasks = [], picklistsMap) => {
  const checkRecurrenceTags = [];
  for (let task of tasks) {
    if (task?.type === 'Check') {
      if (task?.recurrencePriorityId && picklistsMap[task?.recurrencePriorityId]) {
        let recurreanceValue = picklistsMap[task?.recurrencePriorityId] || {};
        let recurreanceValueCopy = { ...recurreanceValue };
        recurreanceValueCopy.value = recurreanceValue?.value?.split(' ')[0] || '';
        // Object.assign(recurreanceValue, recurreanceValueCopy);
        // if (!checkRecurrenceTags?.includes(recurreanceValue)) {
        //   checkRecurrenceTags.push(recurreanceValue);
        // }

        if (!checkRecurrenceTags.some((tag) => tag.value === recurreanceValueCopy.value)) {
          checkRecurrenceTags.push(recurreanceValueCopy);
        }
      }
    }
  }
  return checkRecurrenceTags;
};

const getTaskAssignedToTags = ({
  assignedUserGroups,
  workOrderUsersGroupsMap,
  inspectionInspectorUserGroupsMap
}) => {
  let data =
    (assignedUserGroups && // responsible users groups
      _.size(assignedUserGroups) > 0 &&
      assignedUserGroups
        .map((key) => {
          if (workOrderUsersGroupsMap[key] && workOrderUsersGroupsMap[key]?.name)
            return workOrderUsersGroupsMap[key] && workOrderUsersGroupsMap[key]?.name;
          if (inspectionInspectorUserGroupsMap[key] && inspectionInspectorUserGroupsMap[key]?.name)
            return (
              inspectionInspectorUserGroupsMap[key] && inspectionInspectorUserGroupsMap[key]?.name
            );
        })
        .filter((y) => y)) ||
    [];

  const assignableUsersTags = ['Me, directly', 'A group I’m in', ...data];
  return assignableUsersTags;
};

const getTaskTypeTags = (tasks = []) => {
  let taskTypeTag = [];
  for (let task of tasks) {
    if (task.type === 'Work Order') {
      taskTypeTag.push('Work orders');
      break;
    }
  }
  for (let task of tasks) {
    if (task.type === 'Work Request' || task.newWorkRequest) {
      taskTypeTag.push('Work requests');
      break;
    }
  }
  for (let task of tasks) {
    if (task.type === 'Inspection') {
      taskTypeTag.push('Inspections');
      break;
    }
  }
  for (let task of tasks) {
    if (task.type === 'HSE') {
      taskTypeTag.push('HSE events');
      break;
    }
  }
  for (let task of tasks) {
    if (task.type === 'Check') {
      taskTypeTag.push('Checks');
      break;
    }
  }
  return taskTypeTag;
};

const getWorkOrderTypeTags = (tasks = [], picklistsMap = {}) => {
  let workOrderTypeTag = [];
  for (let task of tasks)
    if (picklistsMap[task?.typeId] && !workOrderTypeTag.includes(picklistsMap[task?.typeId]))
      workOrderTypeTag.push(picklistsMap[task?.typeId]);
  return workOrderTypeTag;
};

const getTaskStatusTags = (tasks = [], picklistsMap) => {
  let taskStatusTag = [];
  for (let task of tasks) {
    if (task.status === 'To do') {
      taskStatusTag.push('To do');
      break;
    }
  }
  for (let task of tasks) {
    if (task.status === 'In progress') {
      taskStatusTag.push('In progress');
      break;
    }
  }

  for (let task of tasks) {
    if (task.status === 'Pending sync') {
      taskStatusTag.push('Pending sync');
      break;
    }
  }

  for (let task of tasks) {
    if (task.status === 'Synced') {
      taskStatusTag.push('Synced');
      break;
    }
  }
  return taskStatusTag;
};

const getInspectionTypeTags = (tasks = [], inspectionTypesMap = {}) => {
  let inspectionTypeTags = [];
  for (let task of tasks)
    if (
      inspectionTypesMap[task?.inspectionTypeId] &&
      !inspectionTypeTags.includes(inspectionTypesMap[task?.inspectionTypeId])
    )
      inspectionTypeTags.push(inspectionTypesMap[task?.inspectionTypeId]);

  const inspectionTypeTagsMap = inspectionTypeTags?.reduce((acc, curr) => {
    acc[curr.key] = { value: curr.value };
    return acc;
  }, {});
  return { inspectionTypeTags, inspectionTypeTagsMap };
};
const getCheckTypeTags = (tasks = [], checkTypesMap = {}) => {
  let checkTypeTags = [];
  for (let task of tasks)
    if (
      checkTypesMap[task?.inspectionTypeId] &&
      !checkTypeTags.includes(checkTypesMap[task?.inspectionTypeId])
    )
      checkTypeTags.push(checkTypesMap[task?.inspectionTypeId]);

  const checkTypeTagsMap = checkTypeTags?.reduce((acc, curr) => {
    acc[curr.key] = { value: curr.value };
    return acc;
  }, {});
  return { checkTypeTags, checkTypeTagsMap };
};

const getInspectionRunTags = (tasks = []) => {
  const tags = Array.from(
    new Set(
      tasks.map((task) => {
        if (task.runNumber) return `${task.runNumber} - ${task.RunName?.trim()}`;
      })
    )
  ).filter((y) => y);
  return tags;
};

const getHSEEventCategory = (tasks = [], incidentCategoryMap = {}) => {
  let hseEventsCategoriesTags = [];
  for (let task of tasks) {
    if (task?.type === 'HSE') {
      if (
        incidentCategoryMap[task?.inspectionType] &&
        !hseEventsCategoriesTags?.includes(task?.inspectionType)
      )
        hseEventsCategoriesTags.push(task?.inspectionType);
    }
  }

  return hseEventsCategoriesTags;
};

export const countFilters = (filters, inputValue) => {
  let count = 0;
  const { picklists } = store.getState();

  const {
    proximity,
    area,
    siteType,
    siteStatus,
    taskType,
    taskStatus,
    assign,
    priority,
    workOrderPriority,
    recurringTemplate = { value: [] },
    workOrderType = { value: [] },
    inspectionRun = { value: [] },
    inspectionType = { value: [] },
    checkType = { value: [] },
    hseEventCategory = { value: [] },
    checkRecurrancePriority = { value: [] }
  } = filters;
  if (inputValue?.trim()) ++count;
  if (proximity.value > 0) ++count;
  if (workOrderType?.value?.length > 0) ++count;
  if (assign?.value?.length > 0) ++count;
  if (siteType?.value?.length > 0) ++count;
  if (taskType?.value?.length > 0) ++count;
  if (taskStatus?.value?.length > 0) ++count;
  if (siteStatus?.value?.length > 0) ++count;
  if (recurringTemplate?.value?.length > 0) ++count;
  if (inspectionRun?.value?.length > 0) ++count;
  if (inspectionType?.value?.length > 0) ++count;
  if (checkType?.value?.length > 0) ++count;
  if (hseEventCategory?.value?.length > 0) ++count;
  if (checkRecurrancePriority?.value.length > 0) ++count;
  if (priority?.value?.length > 0 || workOrderPriority?.value?.length > 0) ++count;
  if (
    area?.value &&
    area?.value.filter((el) => picklists.areaHierarchyTreeDataMap?.[el])?.length > 0
  )
    ++count;

  ['dueDate', 'scheduledDate', 'createdAt', 'syncedDate'].forEach((key) => {
    const { value, selected } = filters[key] || { value: '', selected: '' };
    if (
      selected &&
      !_.isEmpty(value) &&
      (selected === 'on'
        ? value !== '' || value !== {}
        : selected === 'range'
        ? value?.[0] !== '' && value?.[1] !== ''
        : value?.length > 0)
    )
      ++count;
  });
  return count;
};

export const transformTaskRecords = ({ result, picklistsMap }) => {
  const list = _.isArray(result)
    ? (result || [])?.map((task) => {
        return {
          ...task,
          status: task?.woId
            ? task?.status
            : ['To do', 'Pending sync', 'In progress', 'Synced'].includes(task?.status)
            ? task?.status
            : task?.link
            ? 'Synced'
            : 'To do',
          priorityId: task?.priority || task?.priorityId,
          priority: picklistsMap[task?.priority]?.value,
          workStatus: picklistsMap[task?.status]?.value
        };
      })
    : [];
  return list;
};

export const tasksDefaultFilterValues = {
  proximity: { value: 0 },
  area: { value: [] },
  workOrderType: { value: [] },
  siteType: { value: [] },
  siteStatus: { value: [] },
  taskType: { value: [] },
  taskStatus: { value: [] },
  recurringTemplate: { value: [] },
  assign: { value: [] },
  hseEventCategory: { value: [] },
  workOrderPriority: { value: [] },
  checkType: { value: [] },
  checkRecurrancePriority: { value: [] },
  download: { value: '', selected: 'past' },
  createdAt: { value: '', selected: 'past' },
  syncedDate: { value: '', selected: 'past' },
  dueDate: { value: [], selected: 'next' },
  scheduledDate: { value: [], selected: 'next' }
};
