import React from 'react';

import Icon from '@components/icon-component';

import style from './accordion.module.scss';

const Accordion = ({ title, setOpen, children, open, selectedChildren, isSelected }) => {
  return (
    <div className={style.accordion}>
      <div className={style.accordionDiv} onClick={() => setOpen(!open)}>
        <p className={style.titleDiv}>{title}</p>
        <Icon name={'chevron-down'} className={open ? style.iconIcon : style.iconMain} />
      </div>
      {isSelected && <div className={style.selectDiv}>{selectedChildren}</div>}
      <div style={{ transition: 'all 0.3s', marginTop: open ? '18px' : '0px' }}>
        {open ? <div className={style.childrenDiv}>{children}</div> : ''}
      </div>
    </div>
  );
};

export default Accordion;
