/* eslint-disable no-unused-vars */
import _ from 'lodash';

import http from './http.service';
import Promisable from './promisable.service';
import { getAppDispatch } from '@utilities/dispatch.util';
import { mobileSyncActions } from '@redux/slices/mobile-sync-count';
import { globalProgressBarAction } from '@redux/slices/global-progress-bar';
const MobileSyncCountService = {
  getDataFromMobileSyncCount: async ({ initialLogin, lastSuccessFullRefreshTime }) => {
    const dispatch = getAppDispatch();
    const cachingFailure = localStorage.getItem('cachingFailure');

    const changedAfter = lastSuccessFullRefreshTime;

    if (!JSON.parse(cachingFailure)) {
      http.setJWT();
      const [success, error] = await Promisable.asPromise(
        http.get(
          `/MobileSync/SyncCount?changedAfter=${
            !initialLogin ? (changedAfter ? changedAfter : '') : ''
          }`
        )
      );

      if (success?.data) dispatch?.(mobileSyncActions.setMobileSyncCount(success?.data));

      dispatch?.(globalProgressBarAction.setLoadStatus(2));

      return [success, error];
    } else throw new Error('Api failed to update');
  },
  sendRequestToServerToStartSync: async ({ data }) => {
    const dispatch = getAppDispatch();

    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.post(`MobileSync`, {
        ...data
      })
    );

    if (success?.data) dispatch?.(mobileSyncActions.setIdToStartSync(success?.data));
    return [success, error];
  },
  // Submit a request after sync to indicate how many of the expected items to sync were completed successfully.
  updateRequestToServerAfterSync: async ({ data, idToStartSync, removeSyncId = true }) => {
    const dispatch = getAppDispatch();
    if (idToStartSync) {
      http.setJWT();
      const [success, error] = await Promisable.asPromise(
        http.patch(`MobileSync/${idToStartSync}`, {
          ...data
        })
      );
      removeSyncId && dispatch?.(mobileSyncActions.setIdToStartSync(null));
      return [success, error];
    }
  },
  sendRequestToServerCacheIsCompleted: async () => {
    http.setJWT();
    const [success, error] = await Promisable.asPromise(
      http.patch(`ApplicationManifests/`, {
        cacheComplete: new Date().toISOString()
      })
    );
    return [success, error];
  }
};

export default MobileSyncCountService;
