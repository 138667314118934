/* eslint-disable no-unused-vars */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import style from './section-container.module.scss';

const SectionContainer = ({
  children,
  text,
  footer,
  className = '',
  bodyClassName = '',
  headerClassName = '',
  footerClassName = '',
  subSection = false,
  noMargin = false,
  addDefaultPadding = false
}) => {
  return (
    <div className={`${style.sectionContainer} ${className}  `}>
      <div className={`${style.header} ${headerClassName}  ${subSection && style.subSection}   `}>
        {text}
      </div>
      <div
        className={`${style.body} ${addDefaultPadding && style.defaultPadding}  ${bodyClassName} ${
          noMargin && style.noMargin
        }`}
      >
        {children}
      </div>
      <div className={`${style.footer} ${footerClassName}`}>{footer}</div>
    </div>
  );
};

SectionContainer.propTypes = {
  text: PropTypes.string
};

export default memo(SectionContainer);
