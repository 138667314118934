/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import SyncModal from './sync-modal';
import Icon from '@components/icon-component';

import useNetworkStatus from '@hooks/useNetworkStatus';
import CacheService from '@services/cache.service';
import { cacheActions } from '@redux/slices/cache';
import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './indicator.module.scss';

const OnlineOfflineIndicator = ({ helpIcon, isCaching }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOnline] = useNetworkStatus();
  const { width } = useWindowDimensions();

  const [isOpen, setIsOpen] = useState(false);
  const [scheduleTimeLimitExceeds, setScheduleTimeLimitExceeds] = useState(false);

  const { pendingSync = 0, prevPendingSync = 0 } = useSelector((state) => state.cache);
  const { tasksList = [], syncFailedAssets } = useSelector((state) => state.tasks);
  const HSEEventList = useSelector((state) => state.hseEvents.HSEEventList) || [];
  const checksList = useSelector((state) => state.checks.checksList) || [];
  const inspectionsList = useSelector((state) => state.inspection.inspectionsList) || [];
  const { syncReminder } = useSelector((state) => state.cache);
  const {
    lastSyncReminder,
    syncReminderFrequency,
    lastSync,
    settings = {},
    isSettingUpdated
  } = useSelector((state) => state.settings);

  const {
    pendingSyncTasks,
    pendingSyncInspection,
    pendingSyncHSEEventList,
    pendingSyncChecksList
  } = useMemo(() => {
    const pendingSyncTasks = tasksList?.filter((task) => task.status === 'Pending sync');
    const pendingSyncInspection = inspectionsList?.filter((ins) => ins.status === 'Pending sync');
    const pendingSyncHSEEventList = HSEEventList?.filter((ins) => ins.status === 'Pending sync');
    const pendingSyncChecksList = checksList?.filter((ins) => ins.status === 'Pending sync');
    return {
      pendingSyncTasks,
      pendingSyncInspection,
      pendingSyncHSEEventList,
      pendingSyncChecksList
    };
  }, [tasksList, inspectionsList, HSEEventList, pendingSync]);

  useEffect(() => {
    const startSyncingOrRefreshing = localStorage.getItem('startSyncingOrRefreshing');
    // check if we syncing  or refresh then  network is lost, then show modal of offline
    if (isOnline !== true && startSyncingOrRefreshing === 'true') {
      setIsOpen(true);
    }
  }, [isOnline, localStorage.getItem('startSyncingOrRefreshing')]);

  useEffect(() => {
    if (!isOpen) {
      localStorage.setItem('startSyncingOrRefreshing', false);
    }
  }, [isOpen]);

  useEffect(() => {
    CacheService.setPendingSyncTasksNumber(
      _.size(pendingSyncTasks) +
        _.size(pendingSyncInspection) +
        _.size(pendingSyncHSEEventList) +
        _.size(pendingSyncChecksList)
    );
  }, [
    pendingSyncTasks,
    pendingSyncInspection,
    pendingSyncHSEEventList,
    pendingSync,
    pendingSyncChecksList
  ]);

  useEffect(() => {
    const startSyncingOrRefreshing = localStorage.getItem('startSyncingOrRefreshing');

    let isFormOpen = false;
    if (location.pathname.includes('/work-order')) isFormOpen = true;
    else isFormOpen = false;
    let connection = 'wifi';

    if ('connection' in navigator) connection = navigator.connection.type || 'wifi';

    if (isOnline !== true && startSyncingOrRefreshing === 'false') {
      setIsOpen(false);
    } else if (
      ((settings.autoConnectPromptSyncConnection === 'wifi' && connection === 'wifi') ||
        settings.autoConnectPromptSyncConnection === 'any') &&
      isFormOpen === false &&
      _.size(pendingSyncTasks) +
        _.size(pendingSyncInspection) +
        _.size(pendingSyncHSEEventList) +
        _.size(pendingSyncChecksList) >
        0 >
        prevPendingSync &&
      syncReminder !== true &&
      !isCaching
    ) {
      setIsOpen(true);
    }
  }, [
    isOnline,
    pendingSyncTasks,
    pendingSyncInspection,
    pendingSyncHSEEventList,
    pendingSyncChecksList
  ]);

  useEffect(() => {
    if (isOpen) {
      dispatch?.(cacheActions?.updateSyncingReminder(true));
      dispatch?.(cacheActions?.closeCachingComplete(false));
    } else {
      dispatch?.(cacheActions?.updateSyncingReminder(false));
    }
  }, [isOpen]);

  useEffect(() => {
    const lastSyncReminderTime = moment(lastSync);
    const { syncReminderNum, syncReminderTimeframe } = settings;

    let timePassedByDaysOrHours;
    if (syncReminderTimeframe === 'days') {
      timePassedByDaysOrHours = moment().subtract(syncReminderNum, 'days');
    } else {
      timePassedByDaysOrHours = moment().subtract(syncReminderNum, 'hours');
    }

    const exceedTimeLimit = lastSyncReminderTime.isBefore(timePassedByDaysOrHours);
    lastSync && setScheduleTimeLimitExceeds(exceedTimeLimit);
    if (syncReminderFrequency > 0 && syncReminder !== true && !isOpen && !isCaching) {
      dispatch?.(cacheActions?.updateSyncingReminder(true));
      setIsOpen(true);
    }
  }, [syncReminderFrequency, lastSync]);

  const dashboard = useMemo(() => {
    return width < 768 && (location.pathname === '/dashboard' || location.pathname === '/error');
  }, [width]);

  const showRedIcon = useMemo(() => {
    return scheduleTimeLimitExceeds ||
      isSettingUpdated ||
      syncFailedAssets?.photos?.length > 0 ||
      syncFailedAssets?.documents?.length > 0
      ? true
      : false;
  }, [syncFailedAssets, scheduleTimeLimitExceeds, isSettingUpdated]);

  return (
    <>
      <div
        className={`${style.iconDiv} ${dashboard ? style.dashboard : style.notDashboard}`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className={style.icon}>
          <div
            className={style.iconCircle}
            style={{ borderColor: isOnline ? '#2F80ED' : '#CBD2D9' }}
          >
            <Icon
              name={isOnline ? 'newOnline' : 'newOffline'}
              height={20}
              width={20}
              className={style.roundIcon}
            />
          </div>
          {helpIcon && <Icon name={'help-red'} height={14} width={14} className={style.helpIcon} />}
          {showRedIcon ? (
            <span
              className={style.exclamationMark}
              style={{
                background: isOnline ? '#E02B2B' : ''
              }}
            >
              !
            </span>
          ) : (
            ''
          )}
          {pendingSync ? (
            <span
              className={style.count}
              style={{
                background: isOnline ? '#2F80ED' : ''
              }}
            >
              {pendingSync}
            </span>
          ) : (
            ''
          )}
        </div>
        <p className={style.title}> {isOnline ? 'ONLINE' : 'OFFLINE'}</p>
      </div>

      {(isOpen || isCaching) && (
        <SyncModal
          isOnline={isOnline}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          limitExceeded={scheduleTimeLimitExceeds}
          isSettingUpdated={isSettingUpdated}
        />
      )}
    </>
  );
};

export default React.memo(OnlineOfflineIndicator, _.isEqual);
