/* eslint-disable no-unused-vars */
import React, { useRef, memo, useCallback } from 'react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MoreMenu from '@components/more-menu';
import HomeLink from './components/home-link';
import TaskLink from './components/task-link';
import MenuBarOptions from './components/menu-bar-options';
import SearchMenu from './components/search-menu';
import MenuBarLink from './components/menu-bar-link';
import OnlineOfflineIndicator from '@components/online-offline-indicators';
import RefreshingUpdatingModals from './refreshing-updating-modals';

import SettingsService from '@services/settings.service';
import CacheService from '@services/cache.service';
import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';
import { filterActions } from '@redux/slices/filters';
import useWindowDimensions from '@hooks/useWindowDimensions';

import './app-bar.scss';
import style from './style.module.scss';

const AppBar = ({ moveTaskRecordPageToTop = null }) => {
  const dataFetchedRef = useRef(false);
  const location = useLocation();
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const dispatch = getAppDispatch?.();
  let [searchParams, setSearchParams] = useSearchParams();
  const { settings = {} } = useSelector((state) => state.settings);

  const { initialCache = false } = useSelector((state) => state.home);
  const {
    caching: isCaching,
    cachingComplete: isCachingComplete,
    cachingFailure: isCachingFailure,
    isSyncingModal
  } = useSelector((state) => state.cache);

  const [newEquipment, setNewEquipment] = useState(false);
  const moreMenuToggle = searchParams.get('openMenu');

  useEffect(() => {
    if (Object.keys(settings).length > 0) SettingsService.reEvaluateSettings(settings);
  }, [settings]);

  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;

    if (initialCache) return;
    else {
      (async () => {
        localStorage.setItem('startSyncingOrRefreshing', true);
        await CacheService.refresh({
          initialCache: true,
          initialLogin: location?.state?.initialLogin || false
        });
        localStorage.setItem('startSyncingOrRefreshing', false);
      })();
    }
  }, [initialCache]);

  const onClickLink = ({ searchMenu = false, searchParm = true }) => {
    if (searchParams.get('openMenu') && searchParm) setSearchParams({});
    document.getElementById('1122') && document.getElementById('1122').scrollIntoView(true);
    if (searchMenu) {
      dispatch?.(
        backHistoryActions.setSearchPageHistory({
          tab: '0',
          searchedValue: ''
        })
      );
    }
    dispatch?.(backHistoryActions.clearAllDetailsPage(null));
    clearAllFilterState();
  };
  const clearAllFilterState = () => {
    dispatch?.(filterActions.setTaskFilters(null));
    dispatch?.(filterActions.setSitesFilters(null));
    dispatch?.(filterActions.setEquipmentsFilters(null));
    dispatch?.(filterActions.setPipelinesFilters(null));
    dispatch?.(filterActions.setStructuresFilters(null));
  };

  const onlineOfflineIndicatorComponent = useCallback(() => {
    return (
      <div className="onlineOfflineIndicator">
        {width < 768 ? (
          !moreMenuToggle ? (
            <OnlineOfflineIndicator isCaching={isCaching} />
          ) : (
            <></>
          )
        ) : (
          <OnlineOfflineIndicator isCaching={isCaching} />
        )}
      </div>
    );
  }, [width, moreMenuToggle, isCaching]);

  return (
    <div id="appBar">
      <div
        className={`${style['op-fixedbar']} bg-white fixed bottom-0 w-full h-20 md:w-24 md:left-0 md:top-0 md:h-full z-10`}
      >
        <div
          style={{ borderRight: moreMenuToggle ? '0.5px solid #CBD2D9' : '' }}
          className={`appBar ${style['op-fixedbar-inner']} bg-white md:overflow-auto md:h-full md:pt-9 md:pb-9`}
        >
          {onlineOfflineIndicatorComponent()}

          <div className="op-nav flex flex-wrap justify-evenly md:block  md:pt-16">
            <HomeLink
              moreMenuToggle={moreMenuToggle}
              onClickLink={() => onClickLink({ searchParm: false })}
            />
            <TaskLink
              moreMenuToggle={moreMenuToggle}
              onClickLink={() => {
                moveTaskRecordPageToTop && moveTaskRecordPageToTop();
                onClickLink({ searchParm: false });
              }}
            />
            <MenuBarOptions setNewEquipment={setNewEquipment} />
            <SearchMenu
              moreMenuToggle={moreMenuToggle}
              onClickLink={() => onClickLink({ searchMenu: true, searchParm: false })}
            />
            <MenuBarLink
              moreMenuToggle={moreMenuToggle}
              setMoreMenuToggle={(val) => {
                dispatch?.(backHistoryActions.clearAllDetailsPage(null));
                if (val)
                  setSearchParams({
                    openMenu: true
                  });
                else {
                  navigate(-1);
                }
              }}
            />
          </div>
        </div>

        <MoreMenu
          moreMenuToggle={searchParams.get('openMenu')}
          setMoreMenuToggle={(val) => {
            if (val)
              setSearchParams({
                openMenu: true
              });
          }}
          clearAllFilterState={clearAllFilterState}
        />
      </div>

      <RefreshingUpdatingModals
        isCaching={isCaching}
        isCachingComplete={isCachingComplete}
        isCachingFailure={isCachingFailure}
        isSyncingModal={isSyncingModal}
        initialLogin={location?.state?.initialLogin || false}
      />
    </div>
  );
};

export default memo(AppBar);
