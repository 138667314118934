/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from 'react';
import SwipeableViews from 'react-swipeable-views';
import _ from 'lodash';

import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './tabs.module.scss';

const Tabs = ({
  tabs,
  active,
  setActive,
  children,
  className,
  navClassName,
  bookmark = false,
  isFilterActive = false,
  disableReactSwipe = false,
  white = false,
  leftMenu = false,
  assetDetailPageScrollEvent = null
}) => {
  const tabsRef = useRef(null);
  const ref1 = useRef(null);
  const activeNum = parseInt(active);
  const swipeableActions = useRef(null);

  useEffect(() => {
    if (tabsRef?.current) {
      const activeTab = tabsRef?.current?.children[0]?.children[activeNum];

      const tabContainerWidth = tabsRef?.current?.offsetWidth;
      const tabOffset = Math.max(
        0,
        activeTab?.offsetLeft - tabContainerWidth / 2 + activeTab?.offsetWidth / 2
      );
      tabsRef.current.scrollTo({
        left: tabOffset,
        behavior: 'smooth'
      });
    }
  }, [tabsRef.current, activeNum]);

  const { width } = useWindowDimensions();
  const updateSwipeableViewHeight = () => {
    if (swipeableActions.current) {
      swipeableActions.current.updateHeight();
    }
  };

  let startY = 0;
  let endY = 0;

  const handleTouchStart = (event) => {
    startY = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    console.log('handleTouchStart');
    endY = event.touches[0].clientY;
    const deltaY = endY - startY;
    if (deltaY > 0 && assetDetailPageScrollEvent) {
      assetDetailPageScrollEvent({ navTabs: true });
    }
  };

  const handleTouchEnd = () => {
    startY = 0;
    endY = 0;
  };
  // const test = document.getElementById('react-swipeable-view-container');
  // test?.addEventListener('touchstart', handleTouchStart);
  // test?.addEventListener('touchmove', handleTouchMove);
  // test?.addEventListener('touchend', handleTouchEnd);

  return (
    <div className={`${style.tabsMain} ${className} ${style.yes}`}>
      <div
        className={`${style.tabs} ${navClassName} ${tabs?.length > 2 && style.NoJustify} ${
          width >= 768 && isFilterActive && style.NoJustifyInTabs
        }`}
        style={{
          backgroundColor: white ? '#ffffff' : '#F5F7FA',
          ...(leftMenu && { justifyContent: 'left' })
        }}
        ref={tabsRef}
      >
        <div
          className={style.innerTab}
          style={{
            ...(leftMenu && { marginLeft: '16px' })
          }}
        >
          {tabs?.map((ele, index) => (
            <p
              onClick={() => setActive(index, ele?.keyName)}
              key={index}
              className={style.p}
              style={{
                color: activeNum === index ? '#323F4B' : '#CBD2D9',
                borderBottom: activeNum === index ? '4px solid #2F80ED' : ''
              }}
              id={`assets-counts-in-nav-tabs-${ele?.keyName}`}
            >
              {ele?.label}

              {ele?.count && (
                <span style={{ color: activeNum === index ? '#323F4B' : '' }}>({ele?.count})</span>
              )}
            </p>
          ))}
        </div>
      </div>
      <SwipeableViews
        id="react-swipeable-view-container"
        containerclassname={disableReactSwipe ? style.disableSwipe : {}}
        index={activeNum}
        onChangeIndex={(index) => {
          setActive(index, tabs[index]?.keyName);
          updateSwipeableViewHeight();
        }}
        // enableMouseEvents

        ref={ref1}
        containerStyle={{
          willChange: 'unset !important',
          transform: disableReactSwipe ? 'none' : 'initial'
        }}
        draggable={false}
        animateHeight={true}
        action={(actions) => (swipeableActions.current = actions)}
        // onWheel={(event) => {
        //   if (event?.deltaY < 0 && assetDetailPageScrollEvent)
        //     assetDetailPageScrollEvent({ navTabs: true });
        // }}
      >
        {tabs.map((item, index) => {
          return <div key={index}>{item.children}</div>;
        })}
      </SwipeableViews>
    </div>
  );
};

export default React.memo(Tabs, _.isEqual);
