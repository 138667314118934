import React, { useMemo } from 'react';
import _ from 'lodash';
import { Link, useNavigate } from 'react-router-dom';

import SearchField from '@components/search-field';
import style from './home.module.scss';

const ViewWorkBy = ({ inputValue, handleUserInput, resetInputField }) => {
  const navigate = useNavigate();

  const homeMenuLink = useMemo(() => {
    return [
      {
        link: 'sites',
        name: 'Sites',
        className: 'op-site'
      },
      {
        link: 'structures',
        name: 'Structures',
        className: 'op-structure'
      },

      {
        link: 'equipment',
        name: 'Equipment',
        className: 'op-equipment'
      },
      {
        link: 'pipeline',
        name: 'Pipeline',
        className: 'op-pipeline'
      }
    ];
  }, []);

  return (
    <div className="px-4 md:pl-8 md:pr-60">
      <div className={`${style['op-view-work-by-wrap']} relative mt-10`}>
        <label className={`${style['label-capital-semibold']} mb-4 block`}>View Work by </label>
        <div
          className="op-view-work-by-row grid grid-cols-2 gap-4 xl:grid-cols-4 xl:gap-4"
          style={{
            marginBottom: '16px'
          }}
        >
          <div className="op-view-work-by-column">
            <Link className={`${style['op-tasktype']} ${style['button-link']}`} to={'/task-type'}>
              <span>Task type</span>
            </Link>
          </div>
        </div>
        <div className="op-view-work-by-row grid grid-cols-2 gap-4 xl:grid-cols-4 xl:gap-4">
          {homeMenuLink?.map((menuLink) => (
            <div className="op-view-work-by-column" key={menuLink?.name}>
              <Link
                className={`${menuLink?.className} ${style['button-link']}`}
                to={`/${menuLink?.link}`}
              >
                <span>{menuLink?.name}</span>
              </Link>
            </div>
          ))}
        </div>
      </div>

      <div
        className={`${style['op-serach-task']} mt-10 relative ${style['op-search-margin']}`}
        style={{ marginBottom: '40px' }}
      >
        <label className={`${style['label-capital-semibold']} mb-4 block`}>
          SEARCH TASKS or assets
        </label>
        <SearchField
          placeholder="What are you looking for?"
          value={inputValue}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inputValue)
              navigate(`/search?value=${inputValue}`, {
                state: {
                  value: inputValue
                }
              });
          }}
          onChange={handleUserInput}
          onClickResetEvent={resetInputField}
          isClearable
        />
      </div>
    </div>
  );
};

export default React.memo(ViewWorkBy, _.isEqual);
