/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { store } from 'redux/store';

import Tags from '@components/tags';
import VirtualList from '@components/virtual-list/virtual-list';
import SiteListingInner from '@components/site-listing-inner';
import Icon from '@components/icon-component';
import LoadingRound from '@components/loading-round/loading-round';
import Button from '@components/button/button.component';
import Badge from '@components/badge/badge';
import SiteFilter from './site-filter.component';

import useWindowDimensions from '@hooks/useWindowDimensions';
import { tagsDataList, getSiteFilterDefaultValues } from './helper';
import useQuery from '@hooks/useQuery';
import { useDebounce } from '@hooks/useDebounce';
import { getAppDispatch } from '@utilities/dispatch.util';
import { backHistoryActions } from '@redux/slices/back-history';
import { initWebWorker } from 'webworker';
import useDistance from '@hooks/useDistance';

import { cacheActions } from '@redux/slices/cache';

import style from './style.module.scss';

const SiteListing = ({
  assetDetailsSiteLists = false,
  isFilterActiveHeader = false,
  setFilterActiveHeader = null,
  from,
  siteId = null,
  detailPage = false,
  detailsPageBookmarks = true,
  navLabel = 'Site'
}) => {
  const dispatch = getAppDispatch();
  const isSet = useRef(null);
  const query = useQuery();
  const [searchParams] = useSearchParams();
  const bookmarkFilter = detailsPageBookmarks ? searchParams.get('bookmarkFilter') : null;
  const bookmarkName = searchParams.get('bookmarkName');

  const dataFetchedRef = useRef(false);
  const { width } = useWindowDimensions();
  const [searched, setSearched] = useState('');
  const [filtersCount, setFiltersCount] = useState(0);
  const [resetFilters, setResetFilters] = useState(false);
  const [SelectedFilter, setSelectedFilter] = useState(['# TASKS']);
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);
  const [filters, setFilters] = useState(getSiteFilterDefaultValues);
  const [loading, setLoading] = useState(true);
  const [loadingOnSearch, setLoadingOnSearch] = useState(true);
  const [sites, setSites] = useState([]);
  const [sitesAreaHierarchyTreeDataMap, setSitesAreaHierarchyTreeDataMap] = useState({});
  const [sitesSiteTypeDataMap, setSitesSiteTypeDataMap] = useState({});
  const [sitesSiteStatusDataMap, setSitesSiteStatusDataMap] = useState({});
  const [sitesWorkOrderPriorityDataMap, setSitesWorkOrderPriorityDataMap] = useState({});
  const [isHidden, setIsHidden] = useState(false);
  const [filterSitesList, setFilterSitesList] = useState([]);
  const latestDistanceCalculate = useDistance({});

  const coordinates = useSelector((state) => state.cache.coordinates);
  const { areaHierarchyTreeDataMap = {} } = useSelector((state) => state.picklists);

  const siteTypeQuery = query.get('type') || '';
  const siteFilterType = query.get('filterType') || '';
  const templateString = query.get('templates') || '';
  const runsString = query.get('runs') || '';

  const templates = useMemo(() => templateString.split(',').filter((el) => el), [templateString]);
  const runs = useMemo(() => runsString?.split(',').filter((el) => el), [runsString]);

  useEffect(() => {
    dispatch?.(backHistoryActions.clearAllDetailsPage(null));

    if (assetDetailsSiteLists?.length > 0) {
      setSites(assetDetailsSiteLists);
      setLoading(false);
    } else {
      if (latestDistanceCalculate.coordinates) {
        dispatch?.(
          cacheActions.setUnitAndCoordinates({
            unit: latestDistanceCalculate?.unit,
            coordinates: latestDistanceCalculate?.coordinates
          })
        );
      }

      dispatch?.(cacheActions.setSitesMobile());

      initWebWorker({
        args: { store: { ...store.getState() } },
        type: 'get-all-site-listings'
      })
        .then((data) => {
          setSites(data?.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
      // }
    }
  }, [dispatch, latestDistanceCalculate.coordinates, assetDetailsSiteLists]);

  useEffect(() => {
    setFilterActiveHeader && setFilterActiveHeader(isFilterActive);
  }, [isFilterActive]);

  useEffect(() => {
    assetDetailsSiteLists && setFilterActive(isFilterActiveHeader);
  }, [isFilterActiveHeader]);

  useEffect(() => {
    if (!isSet.current && coordinates && SelectedFilter.includes('# TASKS')) {
      setSelectedFilter(['DISTANCE']);
      isSet.current = true;
    }
  }, [coordinates, SelectedFilter]);

  useEffect(() => {
    if (!_.isEmpty(bookmarkFilter)) {
      const filtersInDecode = decodeURIComponent(bookmarkFilter);
      const filterInParse = JSON.parse(filtersInDecode);
      filterInParse?.filters && setFilters(filterInParse?.filters);
      filterInParse?.filters &&
        filterInParse?.filters['searched'] &&
        setSearched(filterInParse?.filters['searched'] || '');
      if (!_.isEmpty(filterInParse?.sort))
        setSelectedFilter(Object.keys(filterInParse?.sort) || '# TASKS');
    }
    return () => {};
  }, [bookmarkFilter]);

  useEffect(() => {
    SelectedFilter.length === 0 && setSelectedFilter(['A-Z']);
  }, [SelectedFilter]);

  const debouncedSearchInputValue = useDebounce(searched, 1000);

  useEffect(() => {
    if (!loading) {
      !loadingOnSearch && sites?.length > 0 && setLoadingOnSearch(true);

      initWebWorker({
        args: {
          store: { ...store.getState() },
          filters: { ...filters, searched: debouncedSearchInputValue?.trim() },
          sites: sites,
          SelectedFilter,
          areaHierarchyTreeDataMap,
          siteTypeQuery,
          templates,
          runs
        },
        type: 'get-searched-sites-listing'
      })
        .then((data) => {
          const {
            filtered,
            sitesSiteTypeData,
            sitesSiteStatusData,
            sitesAreaHierarchyTreeData,
            sitesWorkOrderPriorityDataMap
          } = data?.result || {};
          setSitesWorkOrderPriorityDataMap(sitesWorkOrderPriorityDataMap || {});
          setSitesSiteTypeDataMap(sitesSiteTypeData || {});
          setSitesSiteStatusDataMap(sitesSiteStatusData || {});
          setSitesAreaHierarchyTreeDataMap(sitesAreaHierarchyTreeData || {});
          setFilterSitesList([...filtered]);
          setLoadingOnSearch(false);
        })
        .catch((error) => {
          setLoadingOnSearch(false);
        });
    }
  }, [
    filters,
    debouncedSearchInputValue,
    SelectedFilter,
    isFilterActive,
    width,
    siteTypeQuery,
    templates,
    sites,
    loading,
    runs
  ]);

  useEffect(() => {
    if (detailPage && filterSitesList)
      document.getElementById('assets-counts-in-nav-tabs-site').innerText = `${navLabel} (${
        filterSitesList?.length || 0
      })`;
  }, [filterSitesList, detailPage, navLabel]);

  const tagsData = tagsDataList.filter((el) => {
    if (el === 'DISTANCE' && !coordinates) return false;
    return true;
  });

  const resetFilter = () => {
    return (
      <div className="no-result">
        <div>No results.</div>

        {filtersCount > 0 && (
          <Button
            variant="border"
            onClick={() => setResetFilters((prev) => !prev)}
            text="Reset filters"
            btnClass="taskBtnClass"
          />
        )}
      </div>
    );
  };

  const handleWheel = (e) => {
    const virtualList = document?.querySelector('.cstmScrollerRef')?.scrollTop;

    if (e.deltaY > 0 && !isHidden) {
      setIsHidden(true);
    } else if (e.deltaY < 0 && virtualList === 0 && isHidden) {
      setIsHidden(false);
    }
  };

  let startY;

  const handleScroll = (e) => {
    const virtualList = document?.querySelector('.cstmScrollerRef')?.scrollTop;

    const deltaY = e.touches[0].clientY - startY;

    if (deltaY < 0 && !isHidden) {
      setIsHidden(true);
    } else if (deltaY > 0 && virtualList === 0 && isHidden) {
      setIsHidden(false);
    }
  };

  const handleTouchStart = (e) => {
    startY = e.touches[0].clientY;
  };

  const equipmentListingScrollEvent = (e) => {
    const assetDetailsNavigation = document.getElementById('assetDetailsNavigation');
    const siteDetailsPage = document.querySelector('#assetDetailPage');
    const currentTab = parseInt(searchParams.get('virtualScrollTab') || 0);
    const virtualListScroll = document?.querySelectorAll('.cstmScrollerRef')[currentTab];

    if (virtualListScroll?.scrollTop === 0) {
      siteDetailsPage.scrollTop = 0;
      assetDetailsNavigation.classList.remove('assetDetailNavFixed');
      assetDetailsNavigation.classList.add('assetDetailNavNotFixed');
    }
    if (assetDetailsNavigation.classList.value.includes('assetDetailNavNotFixed')) {
      if (virtualListScroll) {
        virtualListScroll.classList.remove('virtualListOverflowOverlay');
        virtualListScroll.classList.add('virtualListOverflowOverlay');
      }
    } else if (virtualListScroll) {
      virtualListScroll.classList.remove('virtualListOverflowOverlay');
      virtualListScroll.classList.add('virtualListOverflowOverlay');
    }
  };

  const sitesLists = () => {
    return (
      <>
        <div
          className="App"
          onWheel={handleWheel}
          onTouchStart={handleTouchStart}
          onTouchMove={handleScroll}
          style={{ height: '100%' }}
        >
          {
            <div
              className={`op-filter-sort ${style['op-filter-sort']} ${
                isHidden ? style.hidden : ''
              }`}
              style={{
                borderBottom: loadingOnSearch ? 'none ' : '1px solid rgb(229, 229, 229)'
              }}
            >
              <div
                className={style['op-filter-sort-content']}
                style={{ position: isHidden ? 'absolute' : 'static' }}
              >
                {!isFilterActive && (
                  <div className={style.onlyIcon} onClick={() => setFilterActive(true)}>
                    <Icon name={filtersCount > 0 ? 'filter-blue' : 'filter-black'} />
                  </div>
                )}

                <div
                  style={{ flex: 1 }}
                  className={`btn-sort-wrap ${isFilterActive ? '' : 'btn-sort-wrap-list'}`}
                >
                  {!isFilterActive && <div className="sort-by">Sort By</div>}
                  <Tags tags={tagsData} value={SelectedFilter} onChange={setSelectedFilter} />
                </div>
              </div>
            </div>
          }
          {loadingOnSearch ? (
            <div style={{ backgroundColor: 'transparent', width: '100%' }}>
              <LoadingRound />
            </div>
          ) : (
            <>
              <VirtualList
                data={filterSitesList}
                divider={true}
                siteListingPage
                component={SiteListingInner}
                onScrollEvent={assetDetailsSiteLists ? equipmentListingScrollEvent : null}
                isHidden={isHidden}
                componentProps={{
                  highlight: searched,
                  highlightName: true,
                  from,
                  noLink: true
                }}
              />
            </>
          )}
        </div>
      </>
    );
  };

  const headerTitle = React.useMemo(() => {
    return siteTypeQuery && ['Check', 'Inspection', 'Work Order'].includes(siteTypeQuery)
      ? `${
          siteTypeQuery === 'Inspection'
            ? 'Inspections'
            : siteTypeQuery === 'Work Order'
            ? 'Work orders'
            : siteTypeQuery === 'Check'
            ? 'Checks'
            : siteTypeQuery
        } by site (${filterSitesList.length.toLocaleString()})`
      : `Sites (${filterSitesList.length.toLocaleString()})`;
  }, [filterSitesList, siteTypeQuery]);

  const siteFilterGroups = () => {
    return (
      <SiteFilter
        sites={sites || []}
        onSearch={setSearched}
        length={filterSitesList.length}
        resetFilters={resetFilters}
        setResetFilters={setResetFilters}
        filtersCount={filtersCount}
        onChangeFilters={setFilters}
        isFilterActive={isFilterActive}
        setFiltersCount={setFiltersCount}
        setFilterActive={setFilterActive}
        sitesSiteTypeDataMap={sitesSiteTypeDataMap}
        sitesSiteStatusDataMap={sitesSiteStatusDataMap}
        sitesWorkOrderPriorityDataMap={sitesWorkOrderPriorityDataMap}
        sitesAreaHierarchyTreeDataMap={sitesAreaHierarchyTreeDataMap}
        selectedSort={SelectedFilter}
        bookmarkFilter={bookmarkFilter}
        filterType={siteFilterType}
        removeClassOnAssetDetailsFilters={assetDetailsSiteLists}
        siteId={siteId}
        detailPage={detailPage}
      />
    );
  };
  const siteFilterGroupsForAssetDetailsPage = () => {
    return siteFilterGroups();
  };

  return (
    <div className="relative overflow-x-hidden">
      {!assetDetailsSiteLists && (
        <div
          className="op-page-name op-listing-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative"
          style={{
            position: 'sticky',
            top: 0
          }}
        >
          <Link className="op-back " to={-1}></Link>
          <h1 className="h1-semibold pl-16">
            {!_.isEmpty(bookmarkName) ? bookmarkName : <>{headerTitle}</>}
          </h1>
          <button className="filter-btn" onClick={() => setFilterActive(true)}>
            <Badge size="small" count={filtersCount} color="#ffffff" textColor={'#2f80ed'}>
              <Icon name={'filter'} color={'#ffffff'} width="20px" height="18px" />
            </Badge>
          </button>
        </div>
      )}

      <div
        className="flex"
        style={{
          height: 'calc(100vh - 94px)',
          overflowY: 'auto'
        }}
        id="assetDetailsWorkOrderAndInspections"
      >
        {siteFilterGroupsForAssetDetailsPage()}
        <div
          className={`${
            detailPage ? style['page-listingForAssetDetails'] : style['page-listing']
          }  ${style.paddingClass} ${filterSitesList.length > 0 ? 'no-appbar' : ''}
          ${filterSitesList.length > 0 ? style.marginNo : style.marginYes}
            `}
          style={{
            borderLeft: isFilterActive ? '0.5px solid #cbd2d9' : 'none',
            paddingBottom: '0px',
            flex: 1,
            overflow: 'hidden'
          }}
        >
          {filterSitesList.length === 0 && loadingOnSearch === false ? resetFilter() : sitesLists()}
        </div>
        <div style={{ height: '80px' }}></div>
      </div>
    </div>
  );
};

export default React.memo(SiteListing, _.isEqual);
