/* eslint-disable no-unused-vars */

import _ from 'lodash';
import moment from 'moment-timezone';

import { getDataPoints } from '../../pages/settings/helper';

export const getEquipmentAttachedPSV = ({
  equipmentId,
  pvPsvMap,
  cacheEquipments,
  equipmentsMap,
  jurisdictionNumbersMap,
  equipmentDisplayFields,
  picklistsMap
}) => {
  const { psv = [] } = pvPsvMap[equipmentId] || {};
  const psvs = psv
    .filter((id) => cacheEquipments[equipmentsMap[id]])
    ?.map((x) => cacheEquipments[equipmentsMap[x]]);

  const psvsData = psvs
    ?.filter((y) => y?.equipmentId)
    ?.map((x) => {
      const { jurisdictionNumber } = jurisdictionNumbersMap[x?.equipmentId] || {};
      const data = {
        ...x,
        category: x?.equipmentCategory,
        subtype: x?.equipmentSubType,
        jurisdictionNumber
      };

      const dataPoints = getDataPoints(
        { ...data, subtype: data?.equipmentSubType, jurisdictionNumber },
        equipmentDisplayFields,
        'equipment',
        picklistsMap
      );
      const category = picklistsMap[x?.equipmentCategory]?.value;
      const type = picklistsMap[x?.equipmentType]?.value;
      const subType = picklistsMap[x?.equipmentSubType]?.value;

      return {
        ...x,
        category,
        type,
        subType,
        dataPoints,
        verificationStatus: 'verify',
        isDetached: false,
        isReattach: false
      };
    });
  return {
    psvsData
  };
};

export const getTaskEquipmentAsset = ({
  equipmentsWithoutMobileMap,
  equipmentsMap,
  cacheEquipments,
  equipments,
  equipmentId,
  picklistsMap,
  equipmentDisplayFields,
  jurisdictionNumbersMap,
  sitesMap,
  siteDisplayFields,
  areaHierarchyTreeDataMap,
  removeConfigurableFieldFromAsset = false,
  cacheSites,
  appliesTo = false,
  showAttachedPSVs = false,
  formWrapper = false,
  store
}) => {
  const { pvPsv } = store;
  let prepareTasksEquipments = [];
  const equipmentsIds =
    [equipmentId, ...equipments.filter((equipment) => equipment !== equipmentId)].filter(
      (equipment) => {
        return cacheEquipments?.[equipmentsMap?.[equipment]];
      }
    ) || [];

  equipmentsIds.map((equipmentId) => {
    const { jurisdictionNumber } = jurisdictionNumbersMap[equipmentId] || {};
    const equipment =
      cacheEquipments[equipmentsMap[equipmentId]] || equipmentsWithoutMobileMap[equipmentId] || {};
    const { name, siteId, equipmentType, equipmentSubType, equipmentCategory, link } = equipment;
    const type = picklistsMap?.[equipmentType]?.value;
    const category = picklistsMap?.[equipmentCategory]?.value;

    const dataPoints = getDataPoints(
      { ...equipment, subtype: equipmentSubType, jurisdictionNumber },
      removeConfigurableFieldFromAsset ? 'Category,Type,Name,Status' : equipmentDisplayFields,
      'equipment',
      picklistsMap
    );
    const equipmentSiteAsset = siteId
      ? getEquipmentSiteAsset({
          siteId,
          sitesMap,
          siteDisplayFields,
          picklistsMap,
          areaHierarchyTreeDataMap,
          removeConfigurableFieldFromAsset,
          cacheSites
        })
      : {};
    const appliesToDataOfEquipment = appliesTo
      ? getEquipmentCompleteData({ equipment, picklistsMap, formWrapper, store })
      : {};

    let equipmentPSVs = [];
    if (showAttachedPSVs && equipmentId) {
      const { pvPsvMap = {} } = pvPsv;
      const { psvsData = [] } = getEquipmentAttachedPSV({
        equipmentId: equipmentId,
        pvPsvMap,
        cacheEquipments,
        equipmentsMap,
        jurisdictionNumbersMap,
        equipmentDisplayFields,
        picklistsMap
      });
      equipmentPSVs = psvsData;
    }

    prepareTasksEquipments.push({
      title: `${category} - ${type}`,
      subtitle: name,
      dataPoints,
      siteId,
      equipmentId,
      siteAsset: equipmentSiteAsset,
      link,
      equipmentCategory,
      equipmentType,
      equipmentPSVs,
      ...appliesToDataOfEquipment
    });
  });

  return {
    equipmentsIds,
    prepareTasksEquipments
  };
};

export const filterEquipmentAssetBasedOnMocHseStructureNcrPipeline = ({
  mocEquipmentId,
  hseEquipmentId,
  structureEquipmentId,
  ncrEquipmentId,
  pipelineEquipmentId,
  equipmentAsset
}) => {
  if (
    (mocEquipmentId ||
      hseEquipmentId ||
      structureEquipmentId ||
      ncrEquipmentId ||
      pipelineEquipmentId) &&
    !_.isEmpty(equipmentAsset)
  ) {
    const { equipmentsIds = [], prepareTasksEquipments = [] } = equipmentAsset;
    const filterId =
      mocEquipmentId ||
      hseEquipmentId ||
      structureEquipmentId ||
      ncrEquipmentId ||
      pipelineEquipmentId ||
      '';
    return {
      equipmentsIds: equipmentsIds?.filter((x) => x !== filterId),
      prepareTasksEquipments: prepareTasksEquipments?.filter((x) => x?.equipmentId !== filterId)
    };
  } else return equipmentAsset;
};

const getEquipmentSiteAsset = ({
  siteId,
  sitesMap,
  siteDisplayFields,
  picklistsMap,
  areaHierarchyTreeDataMap,
  removeConfigurableFieldFromAsset = false,
  cacheSites
}) => {
  const site = (siteId && cacheSites?.[sitesMap?.[siteId]]) || {};
  const { name, unit, siteType, distance, areaHierarchy, link } = site;
  const area = areaHierarchyTreeDataMap?.[areaHierarchy]?.title;
  const dataPoints = getDataPoints(
    { ...site, type: siteType },
    removeConfigurableFieldFromAsset ? 'Name,Area,Status' : siteDisplayFields,
    'site',
    picklistsMap
  );

  return {
    title: area,
    subtitle: name || '',
    dataPoints: dataPoints,
    iconName: 'map-pin',
    kmValue: distance || '',
    unit: unit || '',
    link
  };
};

const getEquipmentCompleteData = ({ equipment, picklistsMap, formWrapper, store }) => {
  const { tasks, cache, inspection } = store;

  const allWOTasks = !formWrapper
    ? tasks?.tasksList?.filter((task) => !task.newWorkOrder && !task.newWorkRequest)
    : [];
  const allInspections = !formWrapper
    ? inspection?.inspectionsList?.filter((t) => !t?.newInspection)
    : [];
  const allInspectionsAndTasks = [...allWOTasks, ...allInspections];
  const status = !formWrapper ? picklistsMap[equipment?.status]?.value : null;
  const equipmentChildren = !formWrapper
    ? equipment?.children?.map((childIndex) => {
        return { ...cache?.equipments[childIndex], children: [] };
      })
    : [];

  let allTasksForSite = allInspectionsAndTasks?.filter(
    (t) =>
      t?.equipmentId === equipment?.equipmentId ||
      t?.relatedAssetId === equipment?.equipmentId ||
      t?.equipments?.includes(equipment?.equipmentId) ||
      t?.parentEquipment?.includes(equipment?.equipmentId)
  );
  const allDoneTasks = allTasksForSite.filter((task) => task?.status === 'Synced');
  const allotDoneTasks = allTasksForSite.filter((task) => task?.status !== 'Synced');

  const today = moment().format('YYYY-MM-DD');
  const isOverDue = allotDoneTasks.find((task) =>
    task.dueDate ? moment(task.dueDate).isBefore(today) : false
  );

  return {
    status,
    children: equipmentChildren,
    allDoneTasks,
    isOverDue,
    allTasksForSite: allTasksForSite || []
  };
};
