/* eslint-disable no-unused-vars */
import React, { memo, useCallback, useMemo } from 'react';

import Icon from '@components/icon-component';
import CheckBoxComponent from '@components/checkbox/checkbox.component';

import useWindowDimensions from '@hooks/useWindowDimensions';

import style from '../tasks-records-list/style.module.scss';

const RtRunHeader = ({
  open,
  index,
  clickTemplate,
  id,
  title,
  checked,
  tasks,
  templateData,
  setTemplateData,
  lastElement,
  setSelectedOrderIds
}) => {
  const { width } = useWindowDimensions();
  const onChange = useCallback(
    (e) => {
      const { checked } = e.target;
      let copy = [...templateData];
      copy = copy?.map((template) => {
        if (template?.id === id) {
          return {
            ...template,
            checked
          };
        } else return template;
      });

      for (let j = 0; j < copy[index]?.tasks?.length; j++)
        if (copy[index]?.tasks[j]?.status === 'To do') {
          copy[index].tasks[j].checked = checked;

          if (checked) {
            setSelectedOrderIds((prev) => {
              return [...prev, copy[index].tasks[j].id];
            });
          } else {
            setSelectedOrderIds((prev) => {
              let _prev = [...prev];
              return _prev?.filter((x) => x !== copy[index].tasks[j].id);
            });
          }
        }
      setTemplateData(copy);
    },
    [tasks, templateData]
  );
  const checkedLength = useMemo(() => {
    return tasks?.filter(({ checked }) => checked)?.length;
  }, [tasks, templateData]);

  return (
    <div
      className="rts"
      style={{
        cursor: 'pointer',
        marginBottom: lastElement && width < '768' && !open ? '84px' : '16px'
      }}
      onClick={() => clickTemplate(open, id)}
    >
      <div className={style.rtLeft}>
        <div>
          {open ? (
            <Icon name={'minus-icon-tree'} className={style.iconClass} />
          ) : (
            <Icon name={'plus-icon-tree'} className={style.iconClass} />
          )}
        </div>
        <div>
          <span className={style.rtHeader}>{id}</span>
          <div className={style.rtSubHeader}>
            {title}
            {tasks?.length > 0 && <span className={style.totalTasks}> ({tasks?.length})</span>}
          </div>
        </div>
      </div>
      <div className="rts--right">
        <CheckBoxComponent
          checked={checkedLength > 0 || checked}
          partial={checkedLength > 0 && !checked}
          onClick={(e) => e?.stopPropagation()}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default RtRunHeader;
