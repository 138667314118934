/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import { useEffect } from 'react';

import PhotoBox from '../photo-box/photo-box';

const PassFail = ({ item, control, watch, getValues, setValue, errors }) => {
  const onTrueHandler = (value) => {
    getValues(`${item.name}.answer`) !== value
      ? setValue(`${item.name}.answer`, value, { shouldDirty: true })
      : setValue(`${item.name}.answer`, '', { shouldDirty: true });
  };

  useEffect(() => {
    if (item?.defaultValuePassFailAndNA && _.isEmpty(getValues(`${item.name}.answer`))) {
      setValue(`${item.name}.answer`, item?.defaultValuePassFailAndNA);
    }
  }, [item.defaultValuePassFailAndNA, getValues(`${item.name}.answer`), setValue, item]);

  return (
    <PhotoBox
      item={{ ...item }}
      control={control}
      watch={watch}
      errors={errors}
      getValues={getValues}
      setValue={setValue}
      onTrueFalse={onTrueHandler}
    />
  );
};

export default PassFail;
