import { setClassNames } from '@components/tree-component';
import { createSlice } from '@reduxjs/toolkit';

export const toLowerCaseFirst1 = (str = '') => {
  return str && str.charAt(0).toLowerCase() + str && str.slice(1);
};

export const picklistSysNames = [
  'CheckRecurrenceType',
  'CheckPriority',
  'CostCenters',
  'WorkOrderSkills',
  'AreaHierarchy',
  'SiteStatus',
  'CodeOfConstruction',
  'SiteType',
  'Province',
  'CheckPriority',
  'WorkOrderPriority',
  'AssignableUsers',
  'WorkOrderStatus',
  'EquipmentCategory', // TODO
  'EquipmentType',
  'EquipmentSubType',
  'EquipmentStatus', // TODO
  'Service',
  'WorkOrderType',
  'Trade',
  'PhotoCategory',
  'DocumentCategory',
  'Condition',
  'EquipmentValveLocation', // Todo
  'PSVType',
  'Pressure', // TODO
  'Volume',
  'Temperature',
  'SmallMeasurement',
  'TradeSkills',
  'CounterReadingUnits',
  'StructureType',
  'WorkRequestStatus',
  'InspectionFrequency',
  'PipelineStatus', // TODO
  'Material',
  'Substance',
  'InternalProtection',
  'OperationsArea',
  'PipelineType',
  'EquipmentConnectionType'
];
export const dropdownPicklistsNames = [
  'PipelineCathodicProtection',
  'PipelineAssignedBatchingInterval',
  'PipelineAssignedPiggingInterval',
  'ExternalProtection',
  'InternalProtection',
  'Material',
  'OperationsArea',
  'PipelineReceiverType',
  'PipelineSenderType',
  'PipelineType',
  'EquipmentCategoryTypeSubtype',
  'EquipmentValveLocation',
  'Capacity',
  'ConcentrationUnits',
  'LargeMeasurement',
  'TimeUnit',
  'FailureCriteria',
  'FlowRate',
  'Condition',
  'SiteType',
  'CylinderType',
  'ConstructionMaterial',
  'StructureType',
  'GaugeBoardUOM',
  'EquipmentInsulationMaterial',
  // HSE
  'IncidentCategory',
  'HSEProactiveEffortType',
  'HSEFocusAreas',
  'HSEVendors',
  'FunctionalGroup',
  'Species',
  'WildlifeIncidentType',
  'IncidentStatus'
];
const initialState = {
  loading: false,
  picklistsMap: {},
  ...picklistSysNames.reduce((acc, curr) => {
    acc[`${toLowerCaseFirst1(curr)}${curr === 'AreaHierarchy' ? 'Tree' : ''}Data`] = [];
    return acc;
  }, {}),
  areaHierarchyData: {},
  areaHierarchyDataMap: {}
};

export const picklistsSlice = createSlice({
  name: 'picklists',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPicklists: (state, action) => {
      const arr = [];
      let picklistsMap = {};
      const { payload = {} } = action;
      const { areaHierarchyData = [] } = payload;
      const areaHierarchyTreeDataMap = areaHierarchyData
        .map(({ key, value, parentKey }) => ({ key, title: value, parentKey }))
        .reduce((acc, curr) => {
          acc[curr.key] = { ...curr, children: [] };
          return acc;
        }, {});

      for (const key in areaHierarchyTreeDataMap) {
        if (Object.hasOwnProperty.call(areaHierarchyTreeDataMap, key)) {
          const curr = areaHierarchyTreeDataMap[key];
          if (areaHierarchyTreeDataMap[curr.parentKey])
            areaHierarchyTreeDataMap[curr.parentKey].children.push(areaHierarchyTreeDataMap[key]);
        }
      }

      for (const key in areaHierarchyTreeDataMap) {
        if (Object.hasOwnProperty.call(areaHierarchyTreeDataMap, key)) {
          const curr = areaHierarchyTreeDataMap[key];
          if (!curr.parentKey) arr.push(curr);
        }
      }

      for (const key in payload) {
        if (
          Object.hasOwnProperty.call(payload, key) &&
          key !== 'areaHierarchyData' &&
          payload[key]
        ) {
          const data = (payload[key] || []).map(({ key, value }) => ({ key, value }))

          if (key === 'workOrderPriorityData') {
            let sort = (payload[key] || [])
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map(({ key, value }) => ({ key, value }));

            state[key] = sort;
          } else state[key] = data;
          for (const el of data) picklistsMap[el.key] = el;
        }
      }

      const areaHierarchyDataMap = areaHierarchyData?.reduce((acc, curr) => {
        acc[curr?.key] = curr?.value;
        return acc;
      }, {});
      state.areaHierarchyTreeData = setClassNames(arr);
      state.areaHierarchyData = areaHierarchyData;
      state.areaHierarchyDataMap = areaHierarchyDataMap;
      state.areaHierarchyTreeDataMap = areaHierarchyTreeDataMap;
      if (Object.keys(picklistsMap).length > 0) state.picklistsMap = picklistsMap;
    }
  }
});

const picklistsReducer = picklistsSlice.reducer;

export const picklistsActions = picklistsSlice.actions;
export default picklistsReducer;
