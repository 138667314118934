/* eslint-disable  no-unused-vars */

import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import SignaturePad from 'react-signature-canvas';
import Label from '@components/label';
import Icon from '@components/icon-component';

import useWindowDimensions from '@hooks/useWindowDimensions';

import styles from './signature.module.scss';

const Signature = ({ item, control, watch, error, setValue, grid2 }) => {
  const [canvasRef, setCanvasRef] = React.useState(null);
  const { width } = useWindowDimensions();

  const handleClear = () => {
    canvasRef.clear();
    setValue(`${item.name}.signaturePhoto`, '', { shouldDirty: true });
  };

  useEffect(() => {
    if (item?.defaultValue) canvasRef?.fromDataURL(item?.defaultValue);
  }, [item?.defaultValue, canvasRef]);

  useEffect(() => {
    if (!watch(`${item.name}.signaturePhoto`) && canvasRef) canvasRef.clear();
  }, [watch(`${item.name}.signaturePhoto`), canvasRef]);

  return (
    <div>
      <Label
        label={item.label}
        reconciled={item.reconciled}
        dot={item.required}
        error={error}
        inLineStyle={{
          width: width < 500 ? `${width - 32}px` : !grid2 ? '50%' : '100%',
          paddingLeft: '8px'
        }}
      />
      <Controller
        control={control}
        name={`${item.name}.signaturePhoto`}
        defaultValue={item.defaultValue}
        render={({ field }) => (
          <div>
            <div
              className={styles.container}
              style={{
                width: width < 500 ? `${width - 32}px` : !grid2 ? '50%' : '100%',
                height: width < 500 ? `278px` : '400px'
              }}
            >
              <div
                className={styles.sigContainer}
                style={{
                  width: width < 500 ? `${width - 32}px` : !grid2 ? '100%' : '100%',
                  height: width < 500 ? `278px` : '400px'
                }}
              >
                <SignaturePad
                  canvasProps={{
                    className: error ? styles.sigPadWithError : styles.sigPad
                  }}
                  ref={(ref) => setCanvasRef(ref)}
                  onEnd={() => field.onChange(canvasRef.toDataURL())}
                />
              </div>
              {watch && watch(`${item.name}.signaturePhoto`) && (
                <Icon name={'delete-image-icon'} className={styles.icon} onClick={handleClear} />
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default Signature;
