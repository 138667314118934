import { combineReducers } from 'redux';

import authReducer from './slices/auth';
import homeReducer from './slices/home';
import cacheReducer from './slices/cache';
import tasksReducer from './slices/tasks';
import pvPsvReducer from './slices/pv-psv';
import settingsReducer from './slices/settings';
import picklistsReducer from './slices/picklists';
import jurisdictionNumbersReducer from './slices/jurisdiction-numbers';
import bookmarksReducer from './slices/bookmarks';
import inspectionReducer from './slices/inspection';
import structureReducer from './slices/structure';
import pipelineReducer from './slices/pipeline';
import photosAndDocumentsReducer from './slices/photos-document';
import filterReducer from './slices/filters';
import backHistoryReducer from './slices/back-history';
import globalProgressBarReducer from './slices/global-progress-bar';
import formWrapperReducer from './slices/form-wrapper';
import mobileSyncCountReducer from './slices/mobile-sync-count';
import hseEventReducer from './slices/hse-events';
import ChecksReducer from './slices/checks';

const appReducer = combineReducers({
  home: homeReducer,
  auth: authReducer,
  cache: cacheReducer,
  tasks: tasksReducer,
  pvPsv: pvPsvReducer,
  settings: settingsReducer,
  picklists: picklistsReducer,
  jurisdictionNumbers: jurisdictionNumbersReducer,
  bookmarks: bookmarksReducer,
  inspection: inspectionReducer,
  hseEvents: hseEventReducer,
  structure: structureReducer,
  pipeline: pipelineReducer,
  photosAndDocuments: photosAndDocumentsReducer,
  filters: filterReducer,
  backHistory: backHistoryReducer,
  globalProgressBar: globalProgressBarReducer,
  formWrapper: formWrapperReducer,
  mobileSyncCount: mobileSyncCountReducer,
  checks: ChecksReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/logout') state = {};
  return appReducer(state, action);
};

export default rootReducer;
