import React from 'react';

import Icon from '@components/icon-component';

import style from './label.module.scss';

const Label = (
  {
    label,
    dot,
    reconciled,
    error,
    className,
    showReconciled = false,
    inLineStyle,
    inlineTextStyle,
    dangerouslySetInnerHTML = false
  },
  ref
) => {
  return (
    <div className={`${style.labelWrapper} ${className}`} style={{ ...inLineStyle }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}
        ref={ref}
      >
        <span className={style.labelText} style={{ ...inlineTextStyle }}>
          {label && dangerouslySetInnerHTML ? (
            <div dangerouslySetInnerHTML={{ __html: label }}></div>
          ) : (
            label
          )}{' '}
          {dot && <span className={style.redDot} />}
          {reconciled && <Icon name={'labelReconciled'} className={style.iconClass} />}
        </span>
      </div>
      {error && (
        <div
          style={{
            overflowWrap: 'break-word'
          }}
        >
          {<span className={style.error}>{error}</span>}
        </div>
      )}
      {showReconciled && (
        <div
          style={{
            overflowWrap: 'break-word'
          }}
        >
          {<span className={style.reconciled}>RECONCILED</span>}
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(Label);
