/* eslint-disable no-unused-vars */

import { useEffect, useState, useRef } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/loading/loading.component';
import { settingsActions } from '@redux/slices/settings';
import { UpdateAssetData } from '@utilities/photos-documents.util';
import { decodeToken } from '@utilities/authentication';

import routeLists from './route-list';
import CacheService from '@services/cache.service';

export default function MyRoutes() {
  const [loading, setLoading] = useState(process.env.NODE_ENV !== 'development');
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const caching = useSelector((state) => state.cache.caching);
  const { movingTasksAssetsReduxToIndexDBCompleted } = settings;
  const hasRun = useRef(false);
  const userId = decodeToken().userId;

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);

      if (localStorage.getItem('getNewDataAfterUpdate') === 'true' && !caching) {
        localStorage.setItem('getNewDataAfterUpdate', false);

        CacheService.refresh({
          initialCache: true,
          initialLogin: true
        });
      }
    }, 4000);

    return () => {
      clearTimeout();
    };
  }, [localStorage.getItem('getNewDataAfterUpdate'), caching]);

  // useEffect(() => {
  //   if (hasRun.current) return;

  //   (async () => {
  //     if (!movingTasksAssetsReduxToIndexDBCompleted && userId) {
  //       dispatch?.(
  //         settingsActions.setAppUpdateStatus({
  //           isAppUpdatable: false,
  //           isAppUpdating: true,
  //           isUpdatingComplete: false,
  //           isUpdateFailed: false,
  //           movingTasksAssetsReduxToIndexDBInprogress: true
  //         })
  //       );
  //       await UpdateAssetData();
  //       setTimeout(() => {
  //         dispatch?.(
  //           settingsActions.setAppUpdateStatus({
  //             isAppUpdatable: false,
  //             isAppUpdating: false,
  //             isUpdatingComplete: true,
  //             isUpdateFailed: false
  //           })
  //         );
  //       }, 2000);

  //       dispatch?.(
  //         settingsActions.setAppUpdateStatusForWorkOrder({
  //           movingTasksAssetsReduxToIndexDBCompleted: true
  //         })
  //       );
  //     }
  //   })();

  //   hasRun.current = true;
  // }, [dispatch, movingTasksAssetsReduxToIndexDBCompleted, userId]);

  const router = createBrowserRouter(routeLists());

  return (
    <>{loading ? <Loading /> : <RouterProvider router={router} fallbackElement={<Loading />} />}</>
  );
}
