import style from './style.module.scss';
const MoreInformation = ({ info = [] }) => {
  return (
    <div className={style.moreInformationClass}>
      {info?.map(({ title, heading, before, after }, index) => (
        <div key={index} style={{ display: 'flex', gap: '24px' }}>
          {before?.title && before?.heading && (
            <div>
              {before.heading && <p className={style.heading}>{before.heading}</p>}
              {before.title && <p className={style.title}>{before.title}</p>}
            </div>
          )}
          <div
            style={{
              width: '100%'
            }}
          >
            {heading === 'License # - line #' ? (
              <div className={style['grid-2']} style={{ marginTop: '0px' }}>
                <div>
                  {title?.licenseNum && <p className={style.heading}>License #</p>}
                  {title?.licenseNum && <p className={style.title}>{title?.licenseNum}</p>}
                </div>

                <div>
                  {title?.lineNum && <p className={style.heading}>line #</p>}
                  {title?.lineNum && <p className={style.title}>{title?.lineNum}</p>}
                </div>
              </div>
            ) : (
              <>
                {heading && <p className={style.heading}>{heading}</p>}
                {title && <p className={style.title}>{title}</p>}
              </>
            )}
          </div>
          {after?.title && after?.heading && (
            <div>
              {after.heading && <p className={style.heading}>{after.heading}</p>}
              {after.title && <p className={style.title}>{after.title}</p>}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MoreInformation;
