// import { store } from 'redux/store';

import { getDataPoints } from '@pages/settings/helper';

export const getStructureData = ({ inputValue, structure, store }) => {
  const { settings, picklists, cache } = store;
  const { picklistsMap, areaHierarchyTreeDataMap = {} } = picklists;
  const { sitesMap = {}, sites: cacheSites = [] } = cache;
  const { siteDisplayFields } = settings.settings;

  const { name, siteId, structureType: type, dataPoints } = structure;

  const site = cacheSites[sitesMap[siteId]] || {};

  const typeName = picklistsMap[type]?.value;

  const title = `${typeName}`;
  const siteTitle = site?.parent
    ? 'View all work at'
    : areaHierarchyTreeDataMap[site?.areaHierarchy || '']?.title || '';
  const dataPointsObject = dataPoints.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
    {}
  );
  const dataPointsForSite = getDataPoints(
    { ...site, type: site.siteType },
    siteDisplayFields,
    'site',
    picklistsMap
  );
  const dataPointsObjectFOrSite = dataPointsForSite.reduce(
    (obj, item) => Object.assign(obj, { [item.key]: `${item.title} : ${item.value}` }),
    {}
  );

  const filterObject = {
    name,
    title,
    siteTitle,
    siteName: site?.name || '',
    ...dataPointsObject,
    ...dataPointsObjectFOrSite
  };
  if (
    !Object.values(filterObject).some((value) => {
      return String(value).toLowerCase().includes(inputValue?.toLowerCase());
    })
  )
    return false;

  return true;
};
