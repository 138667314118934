/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import _ from 'lodash';

const HighlightText = ({ text = '', highlight = '', trim = false, onClick = null }) => {
  const textNumberToString = text?.toString() || '';
  const value =
    textNumberToString &&
    (typeof textNumberToString === 'string'
      ? trim
        ? textNumberToString?.replace(/\s/g, '')?.toLowerCase()
        : textNumberToString?.toLowerCase()
      : '');

  const indexNum =
    highlight?.trim() &&
    _.isString(highlight?.trim()) &&
    value?.indexOf(highlight?.trim().toLowerCase());

  return (
    <span onClick={onClick}>
      {indexNum >= 0 ? (
        <span>
          {_.isString(textNumberToString) && (
            <span>
              {trim
                ? textNumberToString?.substring(0, indexNum)
                : textNumberToString?.substring(0, indexNum)}
            </span>
          )}

          {_.isString(textNumberToString) &&
            !_.isEmpty(
              trim
                ? textNumberToString
                    ?.replace(/\s/g, '')
                    .substring(indexNum, indexNum + highlight?.trim().length)
                : textNumberToString.substring(indexNum, indexNum + highlight?.trim().length)
            ) && (
              <span style={{ background: '#F9EDC7' }}>
                {trim
                  ? textNumberToString.substring(indexNum, indexNum + highlight?.trim().length) // Remove whitespace if 'trim' is true.
                  : textNumberToString.substring(indexNum, indexNum + highlight?.trim().length)}
              </span>
            )}

          {_.isString(textNumberToString) && (
            <span>
              {trim
                ? textNumberToString.substring(indexNum + highlight?.trim().length)
                : textNumberToString.substring(indexNum + highlight?.trim().length)}
            </span>
          )}
        </span>
      ) : (
        textNumberToString
      )}
    </span>
  );
};

HighlightText.propTypes = {
  text: PropTypes.any,
  highlight: PropTypes.string
};

export default HighlightText;
