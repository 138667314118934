/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import Lightbox from 'react-awesome-lightbox';
import _ from 'lodash';

import DeleteModal from '@components/modal/variants/delete-modal';
import Icon from '@components/icon-component';

import style from './photo.module.scss';
import 'react-awesome-lightbox/build/style.css';

const PhotoCardComponent = ({
  image,
  imageUrl = null,
  categoryValue,
  captionValue,
  deleteBtnEvent,
  editBtnEvent,
  photoId,
  editable = true,
  imageName
}) => {
  const [showFullImage, setShowFullImage] = useState(false);
  const [deletePic, setDeletePic] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    showFullImage && (document.body.style.overflow = 'hidden');
    !showFullImage && (document.body.style.overflow = 'unset');
  }, [showFullImage]);

  const openImageInFullScreen = (imageUrl, imageName) => {
    return (
      <Lightbox
        allowZoom={false}
        allowRotate={false}
        showTitle={false}
        image={imageUrl}
        title={imageName}
        onExit={() => {
          setShowFullImage(false);
        }}
        onClose={() => {
          setShowFullImage(false);
        }}
      />
    );
  };

  const handleDeleteEvent = async () => {
    setLoading(true);
    await deleteBtnEvent(photoId, image?.id);
    setDeletePic(false);
    setLoading(false);
  };

  return (
    <>
      {showFullImage &&
        openImageInFullScreen(
          !_.isEmpty(imageUrl)
            ? imageUrl
            : image && image?.base64 && `data:image/png;base64,${image.base64}`,
          imageName ? imageName : 'no-image'
        )}
      <div className={style.cardDiv}>
        <div
          className={`${style.imgDiv} ${
            _.isEmpty(categoryValue) && _.isEmpty(captionValue) && style.noBorderBottom
          } `}
        >
          <img
            className={style.img}
            src={
              !_.isEmpty(imageUrl)
                ? imageUrl
                : image && image?.base64 && `data:image/png;base64,${image.base64}`
            }
            alt={imageName ? imageName : 'no-image'}
            onClick={() => setShowFullImage(true)}
          />
        </div>

        {!_.isEmpty(categoryValue) || !_.isEmpty(captionValue) ? (
          <div className={style.bottomDiv}>
            {!_.isEmpty(categoryValue) && (
              <>
                <h1 className={style.category}>Category</h1>
                <p>{categoryValue}</p>
              </>
            )}

            {!_.isEmpty(captionValue) && (
              <>
                <h1 className={style.category}>Caption</h1>
                <p>{captionValue}</p>
              </>
            )}
          </div>
        ) : (
          <div></div>
        )}
        {editable && (
          <div className={style.sectionLast}>
            <Icon name={'delete'} alt="delete" onClick={() => setDeletePic(true)} />
            <Icon name={'edit-circle'} alt="edit" onClick={() => editBtnEvent(photoId)} />
          </div>
        )}
      </div>
      <DeleteModal
        open={deletePic}
        setOpen={loading ? () => {} : setDeletePic}
        handleDelete={handleDeleteEvent}
        confirmDeletion
        recordType="photo"
        disabledDeleteButton={loading}
      />
    </>
  );
};

export default PhotoCardComponent;
