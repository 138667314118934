/* eslint-disable no-unused-vars */

import _ from 'lodash';
const RecurringTemplateSelector = {
  getRecurringTemplateTasks: ({ store }) => {
    return new Promise((resolve) => {
      const { tasks, picklists } = store;
      const picklistsMap = picklists?.picklistsMap || {};

      const recurringTemplatesMap = tasks.recurringTemplatesMap || {};
      const rts =
        Object.keys(recurringTemplatesMap).map((el) => ({
          ...recurringTemplatesMap[el],
          allDoneTasks: _.isArray(recurringTemplatesMap[el].allDoneTasks)
            ? _.size(recurringTemplatesMap[el].allDoneTasks)
            : recurringTemplatesMap[el].allDoneTasks,
          allWorkOrder: _.isArray(recurringTemplatesMap[el].allWorkOrder)
            ? _.size(recurringTemplatesMap[el].allWorkOrder)
            : recurringTemplatesMap[el].allWorkOrder,
          checked: false,
          dot: !!el.isOverDue && true,
          total: el.allWorkOrder || 0,
          done: el.allDoneTasks || 0,
          status: 'To do'
        })) || [];

      const tasksRts = rts?.map((task) => {
        return {
          ...task,
          status: task?.woId
            ? task?.status
            : ['To do', 'Pending sync', 'In progress', 'Synced'].includes(task?.status)
            ? task?.status
            : task?.link
            ? 'Synced'
            : 'To do',
          priorityId: task?.priority,
          priority: picklistsMap[task?.priority]?.value,
          workStatus: picklistsMap[task?.status]?.value
        };
      });

      resolve(tasksRts);
    });
  }
};

export default RecurringTemplateSelector;
