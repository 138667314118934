import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pipeline: [],
  pipelineMap: {},
  pipelineMobile: [],
  pipelineMobileMap: {},
  loading: false
};

export const pipelineSlice = createSlice({
  name: 'pipeline',
  initialState,
  reducers: {
    setPipelineMobile: (state, action) => {
      state.pipelineMobile = action.payload;
      state.pipelineMobileMap = action.payload.reduce((acc, curr, currIndex) => {
        acc[curr.pipelineId] = currIndex;
        return acc;
      }, {});
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

const pipelineReducer = pipelineSlice.reducer;

export const pipelineActions = pipelineSlice.actions;
export default pipelineReducer;
