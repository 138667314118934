/* eslint-disable no-unused-vars */

import React, { memo } from 'react';
import PropTypes from 'prop-types';

import style from './text-block.module.scss';

const TextBlock = ({ text }) => {
  return <div className={style.textBlock}>{text}</div>;
};

TextBlock.propTypes = {
  text: PropTypes.string
};

export default memo(TextBlock);
