import _ from 'lodash';
import { getFilterStructure } from '../../components/structure-listing/helper';

const structureBookmarkCount = ({
  responsibleUsersMap,
  responsibleUsersGroupsMap,
  filters,
  sort,
  userId,
  areaHierarchyTreeDataMap,
  siteDisplayFields,
  picklistsMap,
  sitesMap,
  jurisdictionNumbersMap,
  cacheSites,
  structures,
  structureDisplayFields,
  store
}) => {
  const responsibleUsersMap1 = {
    ...(responsibleUsersMap || {}),
    ...(responsibleUsersGroupsMap || {})
  };

  const { filteredStructure = [] } = getFilterStructure({
    filters: { ...filters?.filters },
    sitesMap,
    structures,
    SelectedFilter: Object.keys(sort)[0],
    userId,
    responsibleUsersMap: responsibleUsersMap1,
    jurisdictionNumbersMap,
    picklistsMap,
    structureDisplayFields,
    areaHierarchyTreeDataMap,
    siteDisplayFields,
    cacheSites,
    store
  });
  const countStructure =
    filteredStructure?.filter((t) => {
      if (filters?.siteId) {
        // Filter tasks by siteId
        return (
          t.siteId === filters?.siteId ||
          t?.relatedAssetId === filters?.siteId ||
          t?.primaryAssetId === filters?.siteId ||
          t?.sites?.includes(filters?.siteId)
        );
      } else return true;
    }) || filteredStructure;

  return {
    count: countStructure?.length || 0,
    filters: encodeURIComponent(JSON.stringify(filters))
  };
};

const getStructureTodoAndInprogressTasks = ({
  structureId = '',
  tasksList = [],
  cacheEquipments
}) => {
  const equipmentIds = structureId
    ? cacheEquipments?.filter((x) => x?.structureId === structureId)?.map((x) => x?.equipmentId)
    : [];

  tasksList = tasksList.filter((task) => !task.newWorkOrder && !task.newWorkRequest && !task.isNew);

  const allTasksForStructure =
    _.flattenDeep([
      tasksList.filter(
        (t) =>
          t?.structureId === structureId ||
          t?.structures?.includes(structureId) ||
          t?.relatedAssetId === structureId ||
          t?.primaryAssetId === structureId ||
          equipmentIds?.includes(t?.equipmentId) ||
          equipmentIds?.includes(t?.relatedAssetId) ||
          equipmentIds?.includes(t?.primaryAssetId) ||
          t?.equipments?.some((y) => equipmentIds?.includes(y))
      )
    ]) || [];

  const countTodoAndInProgressTasksOfSite = _.size(
    allTasksForStructure.filter(
      (task) => task?.status === 'To do' || task.status === 'In progress'
    ) || []
  );

  return countTodoAndInProgressTasksOfSite;
};

export { structureBookmarkCount, getStructureTodoAndInprogressTasks };
