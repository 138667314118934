/* eslint-disable  no-unused-vars */
import useWindowDimensions from '@hooks/useWindowDimensions';

import styles from './grid.module.scss';

const Row = ({ children, gutter = 0, GridclassName = '', ...rest }) => {
  const { width } = useWindowDimensions();

  const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
  };

  const getBreakpointKey = (width) => {
    if (width < breakpoints.sm) {
      return 'xs';
    } else if (width < breakpoints.md) {
      return 'sm';
    } else if (width < breakpoints.lg) {
      return 'md';
    } else if (width < breakpoints.xl) {
      return 'lg';
    } else if (width < breakpoints.xxl) {
      return 'xl';
    } else {
      return 'xxl';
    }
  };
  const breakpointKey = getBreakpointKey(width);

  let output = null;
  if (breakpointKey in rest) {
    output = rest[breakpointKey];
  } else {
    const breakpointKeys = Object.keys(breakpoints);
    const index = breakpointKeys.indexOf(breakpointKey);
    let closestKey = null;

    for (let i = index; i >= 0; i--) {
      if (breakpointKeys[i] in rest) {
        closestKey = breakpointKeys[i];
        break;
      }
    }

    if (closestKey in rest) {
      output = rest[closestKey];
    } else {
      if (!closestKey) {
        const keysInRest = Object.keys(rest);
        const closestKeys = breakpointKeys.filter(
          (key) => breakpoints[key] > breakpoints[breakpointKey] && keysInRest.includes(key)
        );
        closestKey = closestKeys.length ? closestKeys[0] : null;
      }

      output = rest[closestKey];
    }
  }

  return (
    <div
      className={`${styles.gridClass} ${GridclassName}`}
      style={{
        gridTemplateColumns: `repeat(${24 / (output || 1)}, 1fr)`
      }}
    >
      {children}
    </div>
  );
};

export default Row;
