/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import _ from 'lodash';

import Greetings from './greeting.component';
import ViewWorkComponent from './view-work.component';
import Glance from './glance.component';

import style from './home.module.scss';

const Home = ({ hasError = false }) => {
  const [inputValue, setInputValue] = useState('');

  const handleUserInput = (e) => setInputValue(e.target.value);

  const resetInputField = () => setInputValue('');
  return (
    <div className={`relative overflow-x-hidden ${style.myClass}`}>
      <Greetings hasError={hasError} />
      {!hasError && (
        <>
          <ViewWorkComponent
            inputValue={inputValue}
            handleUserInput={handleUserInput}
            resetInputField={resetInputField}
          />
          <Glance />
        </>
      )}
    </div>
  );
};

export default React.memo(Home);
