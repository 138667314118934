/* eslint-disable react/jsx-key */
import { useState } from 'react';

import PageContainer from '@components/page-container';
import PageHeader from '@components/page-header';

import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './icons-page.module.scss';
const IconsPage = () => {
  const icons = [
    { size: 40, title: 'air-play', src: 'air-play' },
    { size: 40, title: 'alert-circle', src: 'alert-circle' },
    { size: 40, title: 'alertOtagon', src: 'alertOtagon' },
    { size: 40, title: 'alert-triangle', src: 'alert-triangle' },
    { size: 40, title: 'align-center', src: 'align-center' },
    { size: 40, title: 'align-Justify', src: 'align-Justify' },
    { size: 40, title: 'AlignLeft', src: 'AlignLeft' },
    { size: 40, title: 'align-right', src: 'align-right' },
    { size: 40, title: 'anchor', src: 'anchor' },
    { size: 40, title: 'aperture', src: 'aperture' },
    { size: 40, title: 'arrow-down-left', src: 'arrow-down-left' },
    { size: 40, title: 'arrow-down-right', src: 'arrow-down-right' },
    { size: 40, title: 'arrow-down', src: 'arrow-down' },
    { size: 40, title: 'arrow-up-left', src: 'arrow-up-left' },
    { size: 40, title: 'arrow-up', src: 'arrow-up' },
    { size: 40, title: 'at-sign', src: 'at-sign' },
    { size: 40, title: 'award', src: 'award' },
    { size: 40, title: 'bar-chart', src: 'bar-chart' },
    { size: 40, title: 'bar-chart-2', src: 'bar-chart-2' },
    { size: 40, title: 'battery-charging', src: 'battery-charging' },
    { size: 40, title: 'battery', src: 'battery' },
    { size: 40, title: 'bell-off', src: 'bell-off' },
    { size: 40, title: 'bell', src: 'bell' },
    { size: 40, title: 'bluetooth', src: 'bluetooth' },
    { size: 40, title: 'bold', src: 'bold' },
    { size: 40, title: 'book', src: 'book' },
    { size: 40, title: 'box', src: 'box' },
    { size: 40, title: 'camera-off', src: 'camera-off' },
    { size: 40, title: 'cast', src: 'cast' },
    { size: 40, title: 'check-circle', src: 'check-circle' },
    { size: 40, title: 'check-square', src: 'check-square' },
    { size: 40, title: 'check', src: 'check' },
    { size: 40, title: 'chevron-right', src: 'chevron-right' },
    { size: 40, title: 'chevron-double-down', src: 'chevron-double-down' },
    { size: 40, title: 'chevrons-left', src: 'chevrons-left' },
    { size: 40, title: 'chevrons-rights', src: 'chevrons-rights' },
    { size: 40, title: 'chevrons-up', src: 'chevrons-up' },
    { size: 40, title: 'chrome', src: 'chrome' },
    { size: 40, title: 'circle', src: 'circle' },
    { size: 40, title: 'clipboard', src: 'clipboard' },
    { size: 40, title: 'cloud-drizzle', src: 'cloud-drizzle' },
    { size: 40, title: 'cloud-lightning', src: 'cloud-lightning' },
    { size: 40, title: 'cloud-off', src: 'cloud-off' },
    { size: 40, title: 'cloud-rain', src: 'cloud-rain' },
    { size: 40, title: 'cloud-snow', src: 'cloud-snow' },
    { size: 40, title: 'cloud', src: 'cloud' },
    { size: 40, title: 'codepen', src: 'codepen' },
    { size: 40, title: 'command', src: 'command' },
    { size: 40, title: 'compass', src: 'compass' },
    { size: 40, title: 'copy', src: 'copy' },
    { size: 40, title: 'corner-down-left', src: 'corner-down-left' },
    { size: 40, title: 'corner-down-right', src: 'corner-down-right' },
    { size: 40, title: 'corner-left-down', src: 'corner-left-down' },
    { size: 40, title: 'corner-left-up', src: 'corner-left-up' },
    { size: 40, title: 'corner-right-down', src: 'corner-right-down' },
    { size: 40, title: 'corner-right-up', src: 'corner-right-up' },
    { size: 40, title: 'corner-up-right', src: 'corner-up-right' },
    { size: 40, title: 'cpu', src: 'cpu' },
    { size: 40, title: 'credit-card', src: 'credit-card' },
    { size: 40, title: 'crop', src: 'crop' },
    { size: 40, title: 'crosshair', src: 'crosshair' },
    { size: 40, title: 'disc', src: 'disc' },
    { size: 40, title: 'download', src: 'download' },
    { size: 40, title: 'droplet', src: 'droplet' },
    { size: 40, title: 'edit', src: 'edit' },
    { size: 40, title: 'edit-3', src: 'edit-3' },
    { size: 40, title: 'external-link', src: 'external-link' },
    { size: 40, title: 'facebook', src: 'facebook' },
    { size: 40, title: 'fast-forward', src: 'fast-forward' },
    { size: 40, title: 'file', src: 'file' },
    { size: 40, title: 'file-minus', src: 'file-minus' },
    { size: 40, title: 'file-plus', src: 'file-plus' },
    { size: 40, title: 'film', src: 'film' },
    { size: 40, title: 'filter', src: 'filter' },
    { size: 40, title: 'flag', src: 'flag' },
    { size: 40, title: 'folder', src: 'folder' },
    { size: 40, title: 'github', src: 'github' },
    { size: 40, title: 'gitlab', src: 'gitlab' },
    { size: 40, title: 'globe', src: 'globe' },
    { size: 40, title: 'grid', src: 'grid' },
    { size: 40, title: 'hash', src: 'hash' },
    { size: 40, title: 'headphones', src: 'headphones' },
    { size: 40, title: 'heart', src: 'heart' },
    { size: 40, title: 'inbox', src: 'inbox' },
    { size: 40, title: 'info', src: 'info' },
    { size: 40, title: 'instagram', src: 'instagram' },
    { size: 40, title: 'italic', src: 'italic' },
    { size: 40, title: 'layout', src: 'layout' },
    { size: 40, title: 'life-buoy', src: 'life-buoy' },
    { size: 40, title: 'link-2', src: 'link-2' },
    { size: 40, title: 'list', src: 'list' },
    { size: 40, title: 'lock', src: 'lock' },
    { size: 40, title: 'log-in', src: 'log-in' },
    { size: 40, title: 'map-pin', src: 'map-pin' },
    { size: 40, title: 'minimize-2', src: 'minimize-2' },
    { size: 40, title: 'maximize', src: 'maximize' },
    { size: 40, title: 'message-circle', src: 'message-circle' },
    { size: 40, title: 'message-square', src: 'message-square' },
    { size: 40, title: 'mic-off', src: 'mic-off' },
    { size: 40, title: 'mic', src: 'mic' },
    { size: 40, title: 'minimize-3', src: 'minimize-3' },
    { size: 40, title: 'minimize-4', src: 'minimize-4' },
    { size: 40, title: 'minus-circle', src: 'minus-circle' },
    { size: 40, title: 'monitor', src: 'monitor' },
    { size: 40, title: 'moon', src: 'moon' },
    { size: 40, title: 'more-horizontal', src: 'more-horizontal' },
    { size: 40, title: 'music', src: 'music' },
    { size: 40, title: 'navigation', src: 'navigation' },
    { size: 40, title: 'navigation-2', src: 'navigation-2' },
    { size: 40, title: 'octagon', src: 'octagon' },
    { size: 40, title: 'package', src: 'package' },
    { size: 40, title: 'pause', src: 'pause' },
    { size: 40, title: 'pause-circle', src: 'pause-circle' },
    { size: 40, title: 'percent', src: 'percent' },
    { size: 40, title: 'phone-call', src: 'phone-call' },
    { size: 40, title: 'phone-forwarded', src: 'phone-forwarded' },
    { size: 40, title: 'phone-incoming', src: 'phone-incoming' },
    { size: 40, title: 'phone-missed', src: 'phone-missed' },
    { size: 40, title: 'phone-off', src: 'phone-off' },
    { size: 40, title: 'phone-outgoing', src: 'phone-outgoing' },
    { size: 40, title: 'pie-chart', src: 'pie-chart' },
    { size: 40, title: 'play', src: 'play' },
    { size: 40, title: 'play-circle', src: 'play-circle' },
    { size: 40, title: 'plus-circle', src: 'plus-circle' },
    { size: 40, title: 'pocket', src: 'pocket' },
    { size: 40, title: 'power', src: 'power' },
    { size: 40, title: 'printer', src: 'printer' },
    { size: 40, title: 'refresh-ccw', src: 'refresh-ccw' },
    { size: 40, title: 'repeat', src: 'repeat' },
    { size: 40, title: 'rewind', src: 'rewind' },
    { size: 40, title: 'rotate-ccw', src: 'rotate-ccw' },
    { size: 40, title: 'rotate-cw', src: 'rotate-cw' },
    { size: 40, title: 'scissors', src: 'scissors' },
    { size: 40, title: 'server', src: 'server' },
    { size: 40, title: 'settings', src: 'settings' },
    { size: 40, title: 'share', src: 'share' },
    { size: 40, title: 'share-2', src: 'share-2' },
    { size: 40, title: 'shield', src: 'shield' },
    { size: 40, title: 'shopping-cart', src: 'shopping-cart' },
    { size: 40, title: 'shuffle', src: 'shuffle' },
    { size: 40, title: 'sidebar', src: 'sidebar' },
    { size: 40, title: 'skip-back', src: 'skip-back' },
    { size: 40, title: 'skip-forward', src: 'skip-forward' },
    { size: 40, title: 'slack', src: 'slack' },
    { size: 40, title: 'slash', src: 'slash' },
    { size: 40, title: 'sliders', src: 'sliders' },
    { size: 40, title: 'smartphone', src: 'smartphone' },
    { size: 40, title: 'speaker', src: 'speaker' },
    { size: 40, title: 'square', src: 'square' },
    { size: 40, title: 'star', src: 'star' },
    { size: 40, title: 'stop-circle', src: 'stop-circle' },
    { size: 40, title: 'sun', src: 'sun' },
    { size: 40, title: 'sunrise', src: 'sunrise' },
    { size: 40, title: 'sunset', src: 'sunset' },
    { size: 40, title: 'tablet', src: 'tablet' },
    { size: 40, title: 'tag', src: 'tag' },
    { size: 40, title: 'target', src: 'target' },
    { size: 40, title: 'thermometer', src: 'thermometer' },
    { size: 40, title: 'toggle-left', src: 'toggle-left' },
    { size: 40, title: 'toggle-right', src: 'toggle-right' },
    { size: 40, title: 'trash', src: 'trash' },
    { size: 40, title: 'trending-down', src: 'trending-down' },
    { size: 40, title: 'trending-up', src: 'trending-up' },
    { size: 40, title: 'triangle', src: 'triangle' },
    { size: 40, title: 'tv', src: 'tv' },
    { size: 40, title: 'twitter', src: 'twitter' },
    { size: 40, title: 'type', src: 'type' },
    { size: 40, title: 'umbrella', src: 'umbrella' },
    { size: 40, title: 'underline', src: 'underline' },
    { size: 40, title: 'unlock', src: 'unlock' },
    { size: 40, title: 'upload', src: 'upload' },
    { size: 40, title: 'upload-cloud', src: 'upload-cloud' },
    { size: 40, title: 'user-check', src: 'user-check' },
    { size: 40, title: 'user-minus', src: 'user-minus' },
    { size: 40, title: 'user-plus', src: 'user-plus' },
    { size: 40, title: 'users', src: 'users' },
    { size: 40, title: 'user-x', src: 'user-x' },
    { size: 40, title: 'video', src: 'video' },
    { size: 40, title: 'video-off', src: 'video-off' },
    { size: 40, title: 'voicemail', src: 'voicemail' },
    { size: 40, title: 'volume', src: 'volume' },
    { size: 40, title: 'volume-1', src: 'volume-1' },
    { size: 40, title: 'volume-2', src: 'volume-2' },
    { size: 40, title: 'volume-x', src: 'volume-x' },
    { size: 40, title: 'watch', src: 'watch' },
    { size: 40, title: 'wifi', src: 'wifi' },
    { size: 40, title: 'wifi-off', src: 'wifi-off' },
    { size: 40, title: 'wind', src: 'wind' },
    { size: 40, title: 'x-square', src: 'x-square' },
    { size: 40, title: 'zap', src: 'zap' },
    { size: 40, title: 'zoom-in', src: 'zoom-in' },
    { size: 40, title: 'zoom-out', src: 'zoom-out' },
    { size: 40, title: 'Equipment', src: 'Equipment' },
    { size: 40, title: 'Checkedit', src: 'Checkedit' },
    { size: 40, title: 'HSE', src: 'HSE' },
    { size: 40, title: 'saveProperty ', src: 'saveProperty ' },
    { size: 40, title: 'Site', src: 'Site' },
    { size: 40, title: 'Structure', src: 'Structure' },
    { size: 40, title: 'Task', src: 'Task' },
    { size: 40, title: 'NCR', src: 'NCR' },
    { size: 40, title: 'targetpro', src: 'targetpro' },
    { size: 40, title: 'Pipeline', src: 'Pipeline' },
    { size: 40, title: 'Check in', src: 'Check in' },
    { size: 40, title: 'Layers', src: 'Layers' },
    { size: 40, title: 'Work order', src: 'Work order' },
    { size: 40, title: 'Link', src: 'Link' },
    { size: 40, title: 'File type', src: 'File type' },
    { size: 40, title: 'trash box', src: 'trash box' },
    { size: 40, title: 'xdefault', src: 'xdefault' },
    { size: 40, title: 'plus', src: 'plus' },
    { size: 40, title: 'Shape', src: 'Shape' },
    { size: 40, title: 'plussquare', src: 'plussquare' },
    { size: 40, title: 'menudefault', src: 'menudefault' },
    { size: 40, title: 'download-cloud default', src: 'download-cloud default' },
    { size: 40, title: 'feather', src: 'feather' },
    { size: 40, title: 'Sync', src: 'Sync' },
    { size: 40, title: 'thumbs-down', src: 'thumbs-down' },
    { size: 40, title: 'mail', src: 'mail' },
    { size: 40, title: 'check2', src: 'check2' },
    { size: 40, title: 'eye-off', src: 'eye-off' },
    { size: 40, title: 'eye', src: 'eye' },
    { size: 40, title: 'minus-square default', src: 'minus-square default' },
    { size: 40, title: 'rotate', src: 'rotate' },
    { size: 40, title: 'Pop up', src: 'Pop up' },
    { size: 40, title: 'pencil-shape', src: 'pencil-shape' },
    { size: 40, title: 'Listing detail menu', src: 'Listing detail menu' },
    { size: 40, title: 'activity', src: 'activity' },
    { size: 40, title: 'Bookmark', src: 'Bookmark' },
    { size: 40, title: 'camera', src: 'camera' },
    { size: 40, title: 'image', src: 'image' },
    { size: 40, title: 'menu 2', src: 'menu 2' },
    { size: 40, title: 'new', src: 'new' },
    { size: 40, title: 'paperclip', src: 'paperclip' },
    { size: 40, title: 'tag', src: 'tag' },
    { size: 40, title: 'clock default', src: 'clock default' },
    { size: 40, title: 'briefcase', src: 'briefcase' },
    { size: 40, title: 'calendar', src: 'calendar' },
    { size: 40, title: 'corner-up-left default', src: 'corner-up-left default' },
    { size: 40, title: 'info Property 275', src: 'info Property 275' },
    { size: 40, title: 'phone', src: 'phone' },
    { size: 40, title: 'search', src: 'search' },
    { size: 40, title: 'Step', src: 'Step' },
    { size: 40, title: 'Variant58', src: 'Variant58' },
    { size: 40, title: 'log-out', src: 'log-out' },
    { size: 40, title: 'chevron-down', src: 'chevron-down' },
    { size: 40, title: 'chevron-up', src: 'chevron-up' },
    { size: 40, title: 'help-circle', src: 'help-circle' },
    { size: 40, title: 'home', src: 'home' },
    { size: 40, title: 'loader', src: 'loader' },
    { size: 40, title: 'maps', src: 'maps' },
    { size: 40, title: 'Minus', src: 'Minus' },
    { size: 40, title: 'timescircle', src: 'timescircle' },
    { size: 40, title: 'user', src: 'user' },
    { size: 40, title: 'map-pin', src: 'map-pin' },
    { size: 40, title: 'arrow-left', src: 'arrow-left' },
    { size: 40, title: 'right-arrow', src: 'right-arrow' },
    { size: 40, title: 'arrow-up-right', src: 'arrow-up-right' },
    { size: 40, title: 'chevron-left', src: 'chevron-left' },
    { size: 40, title: 'chevron-right', src: 'chevron-right' },
    { size: 40, title: 'move', src: 'move' },
    { size: 40, title: 'timesback', src: 'timesback' },
    { size: 40, title: 'search2', src: 'search2' },
    { size: 40, title: 'children', src: 'children' },
    { size: 40, title: 'Equipment', src: 'Equipment' },
    { size: 40, title: 'filter', src: 'filter' },
    { size: 40, title: 'filter-blue', src: 'blue-filter' },
    { size: 40, title: 'hide-filter', src: 'hide-filter' },
    { size: 40, title: 'syncc', src: 'syncc' },
    { size: 40, title: '88.8 km', src: '88.8 km' }
  ];
  const { width } = useWindowDimensions();
  const [selectedSetting, setSelectedSetting] = useState('');
  const [selectedTipAndGuides, setSelectedTipAndGuides] = useState('');
  const [selectedTipAndGuidesGettingStarted, setSelectedTipAndGuidesGettingStarted] = useState('');

  const onClickBack = () => {
    if (selectedTipAndGuidesGettingStarted) {
      setSelectedTipAndGuidesGettingStarted('');
      if (width > 767) setSelectedTipAndGuides('');
    } else if (selectedTipAndGuides) {
      setSelectedTipAndGuides('');
      if (width > 767) setSelectedSetting('');
    } else if (selectedSetting) setSelectedSetting('');
  };

  return (
    <PageContainer>
      <PageHeader onClick={onClickBack} disableBack={selectedSetting} title={'Icons'} />
      <p className={style.p}> icons</p>
      <div className={style.wrapper}>
        {icons.map((item) => (
          <img
            height={item.size}
            width={item.size}
            title={item.title}
            src={`assets/icons/${item.src}.svg`}
          />
        ))}
      </div>
    </PageContainer>
  );
};

export default IconsPage;
