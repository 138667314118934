/* eslint-disable no-unused-vars */

import { v4 as uuidv4 } from 'uuid';

import { tasksListsActions } from '@redux/slices/tasks';
import { decodeToken } from '@utilities/authentication';
import LocalStorage from '@utilities/local-storage.util';
import { deleteAssetInIndexDB } from '@utilities/photos-documents.util';

const getPhotosAndDocumentsDataFromSelectedInspection = (obj, photoAndDocumentsIds = false) => {
  const photos = [],
    documents = [];
  let passFailTrue = 0,
    passFailFalse = 0,
    passFailNA = 0,
    inspectionResultAnswer = null;

  const transformNestedObjects = (nestedObj) => {
    for (let key in nestedObj) {
      if (typeof nestedObj[key] === 'object' && nestedObj[key] !== null) {
        if ('photos' in nestedObj[key] && 'documents' in nestedObj[key]) {
          nestedObj[key].photos?.map((photo) => photos.push(photo));
          nestedObj[key].documents?.map((document) => documents.push(document));
        } else if ('photos' in nestedObj[key]) {
          if (nestedObj[key].photos && nestedObj[key]?.photos?.length) {
            nestedObj[key].photos?.map((photo) => photos.push(photo));
          }
        } else if ('documents' in nestedObj[key]) {
          if (nestedObj[key].documents && nestedObj[key].documents?.length) {
            nestedObj[key].documents?.map((document) => documents.push(document));
          }
        } else if ('answer' in nestedObj[key] && !photoAndDocumentsIds) {
          if (nestedObj[key]?.answer === 'true') ++passFailTrue;
          if (nestedObj[key]?.answer === 'false') ++passFailFalse;
          if (nestedObj[key]?.answer === 'NA') ++passFailNA;
        } else if ('inspectionResultAnswer' in nestedObj[key] && !photoAndDocumentsIds) {
          inspectionResultAnswer = nestedObj[key]?.inspectionResultAnswer || null;
        }
      }
    }
  };
  const transformWorkOrder = (a) => {
    for (let i = 0; i < a.length; i++) {
      let item = a[i];
      item?.photo?.map((photo) => photos.push(photo));
      item?.document?.map((document) => documents.push(document));
    }
  };

  for (let key in obj) {
    if (
      key === 'work order' ||
      key.includes('work order') ||
      key === 'work request' ||
      key.includes('work request')
    ) {
      const a = obj[key];
      for (let key1 in a) {
        transformWorkOrder(a[key1]);
      }
    } else transformNestedObjects(obj[key]);
  }

  return {
    photos,
    documents,
    passFailTrue,
    passFailNA,
    passFailFalse,
    inspectionResultAnswer
  };
};

const addSignatureId = (obj) => {
  let form = {};
  let psvs = [];
  const transformNestedObjects = (nestedObj, key1) => {
    for (let key in nestedObj) {
      if (typeof nestedObj[key] === 'object' && nestedObj[key] !== null) {
        if ('signaturePhoto' in nestedObj[key]) {
          if (nestedObj[key].signaturePhoto && nestedObj[key]?.signaturePhoto) {
            let a = nestedObj[key]?.signaturePhoto?.replace(/^data:image\/\w+;base64,/, '');
            const decodedData = atob(a);
            const svgContent = `
                      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                        <image width="100%" height="100%" xlink:href="data:image/png;base64,${btoa(
                          decodedData
                        )}" />
                      </svg>
                        `;
            form = {
              ...form,
              [key1]: {
                [key]: {
                  signaturePhoto: svgContent,
                  id: uuidv4()
                }
              }
            };
          }
        } else {
          if (key && key?.split('-')?.at(-1) !== 'reconcile') {
            psvs = key1 === `psvs-${key}` && nestedObj[key]?.length > 0 ? nestedObj[key] : [];
            form = {
              ...form,
              [key1]: {
                [key]: {
                  ...nestedObj[key]
                }
              }
            };
          }
        }
      } else {
        if (key && key?.split('-')?.at(-1) !== 'reconcile') {
          form = {
            ...form,
            [key1]: {
              [key]: nestedObj[key]
            }
          };
        }
      }
    }
  };

  for (let key in obj) {
    transformNestedObjects(obj[key], key);
  }

  return { formData: form, psvs };
};

const setSyncFailedAssets = ({ syncFailedAssets, photo = [], document = [], dispatch }) => {
  let _syncFailedAssets = { ...syncFailedAssets };

  _syncFailedAssets = {
    ..._syncFailedAssets,
    photos: [...(_syncFailedAssets?.photos || []), ...photo],
    documents: [...(_syncFailedAssets?.documents || []), ...document]
  };

  dispatch?.(tasksListsActions.setSyncFailedAssets(_syncFailedAssets));
};

const removeSuccessfullySyncedRetryAsset = async ({
  documentIds,
  photoIds,
  syncFailedAssets,
  dispatch
}) => {
  const userId = decodeToken()?.userId || '';
  let _syncFailedAssets = { ...syncFailedAssets };

  const photos =
    photoIds?.length > 0
      ? _syncFailedAssets?.photos?.filter((photo) => {
          return !photoIds.includes(photo.id);
        })
      : _syncFailedAssets?.photos;

  const documents =
    documentIds?.length > 0
      ? _syncFailedAssets?.documents?.filter((document) => {
          return !documentIds.includes(document.id);
        })
      : _syncFailedAssets?.documents;

  _syncFailedAssets = {
    photos,
    documents
  };

  const getPreviousUserFailedAssets =
    (await LocalStorage.getItem(`userFailedAssets-${decodeToken().userId}`)) || [];

  const findIndex = getPreviousUserFailedAssets?.findIndex((x) => x?.userId === userId);
  if (findIndex > -1) {
    getPreviousUserFailedAssets[findIndex].photos = (
      getPreviousUserFailedAssets[findIndex].photos || []
    )?.filter((photo) => {
      return !photoIds.includes(photo.id);
    });
    getPreviousUserFailedAssets[findIndex].documents = (
      getPreviousUserFailedAssets[findIndex].documents || []
    )?.filter((document) => {
      return !documentIds.includes(document.id);
    });

    await LocalStorage.setItem(
      `userFailedAssets-${decodeToken().userId}`,
      getPreviousUserFailedAssets
    );
  }

  dispatch?.(tasksListsActions.setSyncFailedAssets(_syncFailedAssets));
};

const deleteNewTasksAssetFromIndexDB = async ({ photos, documents, deleteAll = false }) => {
  for (let photo of photos) await deleteAssetInIndexDB({ assetId: photo?.image?.id, deleteAll });
  for (let document of documents)
    await deleteAssetInIndexDB({ assetId: document?.file?.id, deleteAll });
};

export {
  getPhotosAndDocumentsDataFromSelectedInspection,
  deleteNewTasksAssetFromIndexDB,
  addSignatureId,
  setSyncFailedAssets,
  removeSuccessfullySyncedRetryAsset
};
