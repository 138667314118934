import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clickAssetOpliiDesktopLink: false,
  linkIsOpen: {
    taskId: null,
    assetId: null
  }
};

export const formWrapperSlice = createSlice({
  name: 'formWrapper',
  initialState,
  reducers: {
    setAssetOpliiDesktopLink: (state, action) => {
      state.clickAssetOpliiDesktopLink = action.payload;
    },
    checkTheLinkIsOpen: (state, action) => {
      state.linkIsOpen = action.payload;
    }
  }
});

const formWrapperReducer = formWrapperSlice.reducer;

export const formWrapperActions = formWrapperSlice.actions;
export default formWrapperReducer;
