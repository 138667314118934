/* eslint-disable no-unused-vars */

import React, { useMemo, useCallback, useEffect, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

import AppBar from '@components/app-bar';
import PageHeader from '@components/page-header';
import InspectionRunDetails from './inspection-run-details';
import LoadingRound from '@components/loading-round/loading-round';

import useWindowDimensions from '@hooks/useWindowDimensions';
import { initWebWorker } from 'webworker';

import './inspection-run.scss';
import style from './style.module.scss';

const InspectionRun = () => {
  const [inspectionRun, setInspectionRun] = useState([]);
  const { width } = useWindowDimensions();
  const [view, setView] = useState('');
  const [loading, setLoading] = useState(true);

  const { inspectionsList } = useSelector((state) => state.inspection);

  useEffect(() => {
    initWebWorker({
      args: {
        store: { ...store.getState() }
      },
      type: 'get-all-inspection-runs'
    })
      .then((data) => {
        setInspectionRun(data?.result);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, [inspectionsList]);

  const checkedLength = useMemo(() => {
    return inspectionRun?.filter(({ checked }) => checked).length;
  }, [inspectionRun]);

  const pageHeaderClick = useCallback(() => {
    if (view) setView('');
    else if (checkedLength) {
      let inspectionRunCopy = [...inspectionRun];
      setInspectionRun(
        inspectionRunCopy?.map((x) => {
          return {
            ...x,
            checked: false
          };
        })
      );
    }
  }, [view, checkedLength, setView]);

  const changeHeaderClick = useCallback((checked) => {
    let inspectionRunCopy = [...inspectionRun];
    setInspectionRun(
      inspectionRunCopy?.map((x) => {
        return {
          ...x,
          checked: checked
        };
      })
    );
  });
  const onScrollEvent = useCallback(() => {
    let el = document.querySelector('#recurringTemplateContainer');
    el.classList.add('addScrollbar');
    el.classList.remove('removeScrollbar');
    clearTimeout();
    setTimeout(function () {
      el.classList.remove('addScrollbar');
      el.classList.add('removeScrollbar');
    }, 300);
  }, [inspectionRun]);

  return loading ? (
    <div style={{ backgroundColor: 'transparent', width: '100%' }}>
      <LoadingRound />
    </div>
  ) : (
    <>
      {checkedLength > 0 && width <= 767 ? '' : <AppBar />}
      <div className=" md:ml-24">
        <div>
          <PageHeader
            disableBack={view}
            checked={checkedLength === inspectionRun?.length}
            checkedLength={!view ? checkedLength : 0}
            checkbox={!view && checkedLength !== 0}
            title={`Select ${view ? 'view' : 'run(s)'}`}
            onClick={pageHeaderClick}
            onChange={changeHeaderClick}
            length={inspectionRun?.length}
          />
          <div
            className={`removeScrollbar ${style.recurringTemplateContainer}`}
            id="recurringTemplateContainer"
            onScroll={onScrollEvent}
          >
            <InspectionRunDetails
              checkedLength={checkedLength}
              view={view}
              setInspectionRun={setInspectionRun}
              highlight={''}
              setView={setView}
              inspectionRun={inspectionRun}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(InspectionRun);
