import _ from 'lodash';
export const checkValueInObjectIsNotEmpty = (data = {}) => {
  if (
    !_.isEmpty(
      Object.values(data)?.filter((x) =>
        _.isString(x) && !_.isEmpty(x) ? x : _.isArray(x?.value) && !_.isEmpty(x?.value) ? x : null
      )
    )
  )
    return true;
  else return false;
};
