/* eslint-disable no-unused-vars */
import React, { forwardRef, useRef, useEffect, useState, useCallback, memo } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import useWindowDimensions from '@hooks/useWindowDimensions';

import style from './workorder.module.scss';

const ScrollNavigation = ({ menuNames, onClickLink }, ref) => {
  const navListRef = useRef(null);
  const ulRef = useRef(null);
  const { menuItemEls, formWrapperFormRef } = ref;

  const { width } = useWindowDimensions();
  const [activeIndexes, setActiveIndexes] = useState([]);

  useEffect(() => {
    formWrapperFormRef?.current?.addEventListener('scroll', onScrollEvent);
    return () => formWrapperFormRef?.current?.removeEventListener('scroll', onScrollEvent);
  }, [width]);

  const onScrollEvent = useCallback(
    (e) => {
      const scrollPosition = formWrapperFormRef?.current?.scrollTop;
      const activeIndexes = menuItemEls?.current.reduce((indexes, el, index) => {
        if (el?.current?.offsetTop <= scrollPosition + 180) {
          indexes.push(index);
        }
        return indexes;
      }, []);

      setActiveIndexes(activeIndexes);
      maxLinksToFit(activeIndexes, width);
    },
    [width, formWrapperFormRef, menuItemEls]
  );
  const maxLinksToFit = useCallback(
    (activeIndexes, width) => {
      if (!navListRef.current) return 0;
      const linkElements = navListRef.current.querySelectorAll('a');
      const activeTabWidths = activeIndexes.map((index) => linkElements?.[index]?.offsetWidth);
      const totalActiveWidth = activeTabWidths.reduce((total, width) => total + width, 0);
      if (totalActiveWidth > width) {
        navListRef.current.scrollLeft =
          linkElements?.[activeIndexes?.at(-1)]?.offsetWidth > 200 &&
          linkElements?.[activeIndexes?.at(-1)]?.offsetWidth < 250
            ? `${totalActiveWidth - width + 150}`
            : linkElements?.[activeIndexes?.at(-1)]?.offsetWidth > 250 &&
              linkElements?.[activeIndexes?.at(-1)]?.offsetWidth < 300
            ? `${totalActiveWidth - width + 120}`
            : linkElements?.[activeIndexes?.at(-1)]?.offsetWidth > width
            ? `${
                totalActiveWidth -
                width -
                (linkElements?.[activeIndexes?.at(-1)]?.offsetWidth - width - 9)
              }`
            : linkElements?.[activeIndexes?.at(-1)]?.offsetWidth > 230
            ? `${totalActiveWidth - width}`
            : `${totalActiveWidth - width + Math.round(width / 2)}`;
      } else navListRef.current.scrollLeft = 0;
    },
    [width, activeIndexes]
  );

  return (
    <div className={style.fixedTabs} ref={navListRef} id="fixedTabs">
      <div className={style.antTabNavs}>
        <div className={style.antTabNavWrap}>
          <div className={style.antTabNavList}>
            <ul ref={ulRef}>
              {menuNames?.map((menu, index) => (
                <Link
                  to=""
                  key={menu?.id}
                  onClick={(e) => {
                    onClickLink(e, menuItemEls?.current[index], index);
                  }}
                  className={activeIndexes.includes(index) ? 'is-current' : ''}
                >
                  <div className={`${style.tab}`}>{menu?.name}</div>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(forwardRef(ScrollNavigation));
