/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { store } from 'redux/store';

import Tags from '@components/tags';
import VirtualList from '@components/virtual-list/virtual-list';
import EquipmentListingInner from './equipment-listing-inner';
import EquipmentFilter from './equipment-filter.component';
import Icon from '@components/icon-component';
import LoadingRound from '@components/loading-round/loading-round';
import Badge from '@components/badge/badge';
import Button from '@components/button/button.component';

import { backHistoryActions } from '@redux/slices/back-history';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { getAppDispatch } from '@utilities/dispatch.util';
import { tagsDataList, getEquipmentDefaultFilters } from './helper';
import { useDebounce } from '@hooks/useDebounce';
import { initWebWorker } from 'webworker';
import useDistance from '@hooks/useDistance';

import { cacheActions } from '@redux/slices/cache';

import style from './style.module.scss';

const EquipmentListing = ({
  Title,
  assetDetailsOfEquipmentLists = false,
  isFilterActiveHeader = false,
  setFilterActiveHeader = null,
  from,
  psvs = false,
  pvs = false,
  detailPage = false,
  siteId = null,
  equipmentId = null,
  structureId = null,
  detailsPageBookmarks = true,
  noSite = false,
  navLabel = 'Equipment'
}) => {
  const dispatch = getAppDispatch();

  const isSet = useRef(null);
  const dataFetchedRef = useRef(false);
  const equipmentListingRef = useRef(null);
  const [searchParams] = useSearchParams();
  const bookmarkFilter = detailsPageBookmarks ? searchParams.get('bookmarkFilter') : null;
  const bookmarkName = searchParams.get('bookmarkName');
  const latestDistanceCalculate = useDistance({});

  const { width } = useWindowDimensions();
  const [searched, setSearched] = useState('');
  const [filtersCount, setFiltersCount] = useState(0);
  const [resetFilters, setResetFilters] = useState(false);
  const [SelectedFilter, setSelectedFilter] = useState(['# TASKS']);
  const [isFilterActive, setFilterActive] = useState(width < 768 ? false : true);
  const [isHidden, setIsHidden] = useState(false);

  const [sitesSiteTypeDataMap, setSitesSiteTypeDataMap] = useState({});
  const [sitesSiteStatusDataMap, setSitesSiteStatusDataMap] = useState({});
  const [equipmentsEquipmentTypeDataMap, setEquipmentsEquipmentTypeDataMap] = useState({});
  const [equipmentsEquipmentStatusDataMap, setEquipmentsEquipmentStatusDataMap] = useState({});
  const [equipmentsEquipmentSubTypeDataMap, setEquipmentsEquipmentSubTypeDataMap] = useState({});
  const [equipmentsEquipmentCategoryDataMap, setEquipmentsEquipmentCategoryDataMap] = useState({});
  const [sitesAreaHierarchyTreeDataMap, setSitesAreaHierarchyTreeDataMap] = useState({});
  const [sitesWorkOrderPriorityDataMap, setSitesWorkOrderPriorityDataMap] = useState({});

  const [filters, setFilters] = useState(getEquipmentDefaultFilters);
  const { sitesMap = {}, sites: cacheSites = [] } = useSelector((state) => state.cache);
  const coordinates = useSelector((state) => state.cache.coordinates);
  const { jurisdictionNumbersMap = {} } = useSelector((state) => state.jurisdictionNumbers);
  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state.picklists
  );
  const equipmentDisplayFields =
    useSelector((state) => state.settings.settings?.['equipmentDisplayFields']) || '';
  const [loading, setLoading] = useState(true);
  const [loadingOnSearch, setLoadingOnSearch] = useState(true);
  const [filterEquipmentList, setFilterEquipmentList] = useState([]);
  const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    dispatch && dispatch?.(backHistoryActions.clearAllDetailsPage(null));
    if (assetDetailsOfEquipmentLists?.length > 0) {
      setEquipments(assetDetailsOfEquipmentLists);
      setLoading(false);
    } else {
      if (latestDistanceCalculate.coordinates) {
        dispatch?.(
          cacheActions.setUnitAndCoordinates({
            unit: latestDistanceCalculate?.unit,
            coordinates: latestDistanceCalculate?.coordinates
          })
        );
      }
      dispatch?.(cacheActions.setSitesMobile());

      initWebWorker({
        type: 'get-all-equipment-listing',
        args: { store: { ...store.getState() } }
      })
        .then((data) => {
          setEquipments(data?.result);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    // }
  }, [assetDetailsOfEquipmentLists, dispatch, latestDistanceCalculate.coordinates]);

  useEffect(() => {
    if (!_.isEmpty(bookmarkFilter)) {
      const filtersInDecode = decodeURIComponent(bookmarkFilter);
      const filterInParse = JSON.parse(filtersInDecode);
      filterInParse?.filters && setFilters(filterInParse?.filters);
      filterInParse?.filters &&
        filterInParse?.filters['searched'] &&
        setSearched(filterInParse?.filters['searched'] || '');
      if (!_.isEmpty(filterInParse?.sort))
        setSelectedFilter(Object.keys(filterInParse?.sort) || '# TASKS');
    }
    return () => {};
  }, [bookmarkFilter]);

  const debouncedSearchInputValue = useDebounce(searched, 1000);
  useEffect(() => {
    if (!loading) {
      !loadingOnSearch && equipments?.length > 0 && setLoadingOnSearch(true);
      initWebWorker({
        type: 'get-searched-equipment-listing',
        args: {
          filters: { ...filters, searched: debouncedSearchInputValue?.trim() },
          sitesMap,
          equipments,
          SelectedFilter,
          jurisdictionNumbersMap,
          picklistsMap,
          equipmentDisplayFields,
          cacheSites,
          store: { ...store.getState() }
        }
      })
        .then((data) => {
          const {
            filteredEquipment,
            sitesSiteTypeData,
            sitesSiteStatusData,
            equipmentsEquipmentTypeData,
            equipmentsEquipmentStatusData,
            equipmentsEquipmentSubTypeData,
            equipmentsEquipmentCategoryData,
            sitesAreaHierarchyTreeData,
            sitesWorkOrderPriorityDataMap
          } = data?.result || {};
          setSitesWorkOrderPriorityDataMap(sitesWorkOrderPriorityDataMap || {});
          setSitesSiteTypeDataMap(sitesSiteTypeData || {});
          setSitesSiteStatusDataMap(sitesSiteStatusData || {});
          setEquipmentsEquipmentTypeDataMap(equipmentsEquipmentTypeData || {});
          setEquipmentsEquipmentStatusDataMap(equipmentsEquipmentStatusData || {});
          setEquipmentsEquipmentSubTypeDataMap(equipmentsEquipmentSubTypeData || {});
          setEquipmentsEquipmentCategoryDataMap(equipmentsEquipmentCategoryData || {});
          setSitesAreaHierarchyTreeDataMap(sitesAreaHierarchyTreeData || {});
          setFilterEquipmentList([...filteredEquipment]);
          setLoadingOnSearch(false);
        })
        .catch((error) => {
          setLoadingOnSearch(false);
        });
    }
  }, [filters, debouncedSearchInputValue, SelectedFilter, isFilterActive, width, equipments]);

  const tagsData = tagsDataList.filter((el) => {
    if (el === 'DISTANCE' && !coordinates) return false;
    return true;
  });

  useEffect(() => {
    if (!isSet.current && coordinates && SelectedFilter.includes('# TASKS')) {
      if (!_.isEmpty(bookmarkFilter)) {
        const filtersInDecode = decodeURIComponent(bookmarkFilter);
        const filterInParse = JSON.parse(filtersInDecode);
        if (!_.isEmpty(filterInParse?.sort))
          setSelectedFilter(Object.keys(filterInParse?.sort) || '# TASKS');
      } else {
        setSelectedFilter(['DISTANCE']);
        isSet.current = true;
      }
    }
  }, [coordinates, SelectedFilter, bookmarkFilter]);

  useEffect(() => {
    setFilterActiveHeader && setFilterActiveHeader(isFilterActive);
  }, [isFilterActive]);

  useEffect(() => {
    assetDetailsOfEquipmentLists && setFilterActive(isFilterActiveHeader);
  }, [isFilterActiveHeader]);

  const resetFilter = () => {
    return (
      <div className="no-result">
        <div>No results.</div>

        {filtersCount > 0 && (
          <Button
            variant="border"
            onClick={() => setResetFilters((prev) => !prev)}
            text="Reset filters"
            btnClass="taskBtnClass"
          />
        )}
      </div>
    );
  };

  const handleWheel = (e) => {
    const virtualList = document?.querySelector('.cstmScrollerRef')?.scrollTop;

    if (e.deltaY > 0 && !isHidden) {
      setIsHidden(true);
    } else if (e.deltaY < 0 && virtualList === 0 && isHidden) {
      setIsHidden(false);
    }
  };

  let startY;

  const handleScroll = (e) => {
    const virtualList = document?.querySelector('.cstmScrollerRef')?.scrollTop;
    const deltaY = e.touches[0].clientY - startY;
    if (deltaY < 0 && !isHidden) {
      setIsHidden(true);
    } else if (deltaY > 0 && virtualList === 0 && isHidden) {
      setIsHidden(false);
    }
  };

  const handleTouchStart = (e) => {
    startY = e.touches[0].clientY;
  };

  const equipmentListingScrollEvent = (e) => {
    const assetDetailsNavigation = document.getElementById('assetDetailsNavigation');
    const siteDetailsPage = document.querySelector('#assetDetailPage');
    const currentTab = parseInt(searchParams.get('virtualScrollTab') || 0);
    const virtualListScroll = document?.querySelectorAll('.cstmScrollerRef')[currentTab];

    if (virtualListScroll?.scrollTop === 0) {
      siteDetailsPage.scrollTop = 0;
      assetDetailsNavigation.classList.remove('assetDetailNavFixed');
      assetDetailsNavigation.classList.add('assetDetailNavNotFixed');
    }
    if (assetDetailsNavigation.classList.value.includes('assetDetailNavNotFixed')) {
      if (virtualListScroll) {
        virtualListScroll.classList.remove('virtualListOverflowOverlay');
        virtualListScroll.classList.add('virtualListOverflowOverlay');
      }
    } else if (virtualListScroll) {
      virtualListScroll.classList.remove('virtualListOverflowOverlay');
      virtualListScroll.classList.add('virtualListOverflowOverlay');
    }
  };

  const equipmentLists = () => {
    return (
      <>
        <div
          className="App"
          onTouchStart={handleTouchStart}
          onTouchMove={handleScroll}
          onWheel={handleWheel}
          style={{ height: '100%' }}
        >
          {
            <div
              className={`op-filter-sort ${style['op-filter-sort']} ${
                isHidden ? style.hidden : ''
              }`}
              style={{
                borderBottom: loadingOnSearch ? 'none ' : '1px solid rgb(229, 229, 229)'
              }}
            >
              <div
                className={style['op-filter-sort-content']}
                style={{ position: isHidden ? 'absolute' : 'static' }}
              >
                {!isFilterActive && (
                  <div className={style.onlyIcon} onClick={() => setFilterActive(true)}>
                    <Icon name={filtersCount > 0 ? 'filter-blue' : 'filter-black'} />
                  </div>
                )}
                <div
                  style={{
                    flex: 1
                  }}
                  className={`btn-sort-wrap ${isFilterActive ? '' : 'btn-sort-wrap-list'}`}
                >
                  {!isFilterActive && <div className="sort-by">Sort By</div>}

                  <Tags tags={tagsData} value={SelectedFilter} onChange={setSelectedFilter} />
                </div>
              </div>
            </div>
          }
          {loadingOnSearch ? (
            <div style={{ backgroundColor: 'transparent', width: '100%' }}>
              <LoadingRound />
            </div>
          ) : (
            <VirtualList
              divider
              data={filterEquipmentList}
              component={EquipmentListingInner}
              onScrollEvent={assetDetailsOfEquipmentLists ? equipmentListingScrollEvent : null}
              isHidden={isHidden}
              componentProps={{
                highlight: searched,
                highlightName: true,
                equipmentListing: true,
                from,
                noLink: true,
                noSite
              }}
            />
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    SelectedFilter.length === 0 && setSelectedFilter(['A-Z']);
  }, [SelectedFilter]);

  return (
    <div className="relative overflow-x-hidden" ref={equipmentListingRef}>
      {!assetDetailsOfEquipmentLists && (
        <div
          className="op-page-name op-listing-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative"
          style={{
            position: 'sticky',
            top: 0
          }}
        >
          <Link className="op-back " to={-1}></Link>
          <h1 className="h1-semibold pl-16">
            {!_.isEmpty(bookmarkName) ? (
              bookmarkName
            ) : (
              <>
                {' '}
                {Title} ({filterEquipmentList.length.toLocaleString()})
              </>
            )}
          </h1>
          <button className="filter-btn" onClick={() => setFilterActive(true)}>
            <Badge size="small" count={filtersCount} color="#ffffff" textColor={'#2f80ed'}>
              <Icon name={'filter'} color={'#ffffff'} width="20px" height="18px" />
            </Badge>
          </button>
        </div>
      )}

      <div
        className="flex"
        style={{
          height: 'calc(100vh - 94px)',
          overflowY: 'auto'
        }}
        id="assetDetailsWorkOrderAndInspections"
      >
        <EquipmentFilter
          title={Title}
          onSearch={setSearched}
          length={filterEquipmentList.length}
          resetFilters={resetFilters}
          setResetFilters={setResetFilters}
          filtersCount={filtersCount}
          onChangeFilters={setFilters}
          isFilterActive={isFilterActive}
          setFiltersCount={setFiltersCount}
          setFilterActive={setFilterActive}
          sitesSiteTypeDataMap={sitesSiteTypeDataMap}
          sitesSiteStatusDataMap={sitesSiteStatusDataMap}
          sitesAreaHierarchyTreeDataMap={sitesAreaHierarchyTreeDataMap}
          equipmentsEquipmentTypeDataMap={equipmentsEquipmentTypeDataMap}
          equipmentsEquipmentStatusDataMap={equipmentsEquipmentStatusDataMap}
          equipmentsEquipmentSubTypeDataMap={equipmentsEquipmentSubTypeDataMap}
          equipmentsEquipmentCategoryDataMap={equipmentsEquipmentCategoryDataMap}
          equipments={equipments || []}
          sitesWorkOrderPriorityDataMap={sitesWorkOrderPriorityDataMap}
          selectedSort={SelectedFilter}
          bookmarkFilter={bookmarkFilter}
          removeClassOnAssetDetailsFilters={assetDetailsOfEquipmentLists}
          psvs={psvs}
          pvs={pvs}
          detailPage={detailPage}
          siteId={siteId}
          equipmentId={equipmentId}
          structureId={structureId}
        />

        <div
          className={`${
            detailPage ? style['page-listingForAssetDetails'] : style['page-listing']
          }  ${style.paddingClass}`}
          style={{
            width: `calc(100% - ${isFilterActive ? '275' : '0'}px)`,
            flex: 1,
            overflowY: 'clip',
            paddingBottom: '0px'
          }}
        >
          {filterEquipmentList.length === 0 && loadingOnSearch === false
            ? resetFilter()
            : equipmentLists()}
        </div>
      </div>
    </div>
  );
};

EquipmentListing.propTypes = {
  Title: PropTypes.string
};

export default React.memo(EquipmentListing, _.isEqual);
