/* eslint-disable no-unused-vars */
import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useSearchParams } from 'react-router-dom';
import _ from 'lodash';

import CheckBoxComponent from '@components/checkbox/checkbox.component';
import Icon from '@components/icon-component';
import Badge from '@components/badge/badge';

import './page-header.scss';

const PageHeader = forwardRef(
  (
    {
      count,
      title,
      length,
      checked,
      onClick = () => {
        console.error('onClick Function not defiend ');
      },
      showFilter = false,
      onChange,
      disableBack,
      checkedLength,
      checkedLengthOfRtsTasksAndSites = 0,
      checkedLengthOfRunsTasksAndSites = 0,
      setFilterActive,
      notShowCount = false
      // checkbox = true,
    },
    ref
  ) => {
    const pageTitle = !notShowCount
      ? `${title} ${length !== undefined ? `(${length})` : ''}`
      : title;
    const [searchParams] = useSearchParams();

    return (
      <div
        className="op-page-name op-listing-name px-4 md:px-8 py-7 flex flex-wrap justify-between items-center relative"
        style={{
          position: 'sticky',
          top: 0
        }}
        ref={ref}
      >
        <Link
          to={-1}
          // to={navigate(location.pathname, { replace: true })}
          className="op-back"
          {...((checkedLength > 0 ||
            disableBack ||
            checkedLengthOfRtsTasksAndSites > 0 ||
            checkedLengthOfRunsTasksAndSites > 0) && {
            onClick: (e) => {
              e.preventDefault();
              onClick();
            }
          })}
        >
          {checkedLength > 0 && <span className="checked-count">{checkedLength}</span>}
        </Link>
        <h1
          title={pageTitle}
          className={`h1-semibold ${checkedLength > 0 ? 'with-number' : 'without-number'}`}
        >
          {pageTitle}
        </h1>
        <div
          style={{
            gap: '16px',
            display: 'flex',
            minWidth: '25px',
            alignItems: 'center'
          }}
        >
          {checkedLength > 0 ? (
            <>
              <CheckBoxComponent
                checked={checkedLength > 0}
                partial={checkedLength > 0 && !checked}
                handleChange={(e) => (checked ? onChange?.(false) : onChange?.(true))}
              />
            </>
          ) : setFilterActive ? (
            !showFilter && (
              <button className="filter-btn" onClick={() => setFilterActive(true)}>
                <Badge size="small" count={count} color="#ffffff" textColor={'#2f80ed'}>
                  <Icon name={'filter'} color={'#ffffff'} width="20px" height="18px" />
                </Badge>
              </button>
            )
          ) : (
            <div style={{ height: '1px', overflow: 'hidden' }}>.</div>
          )}
        </div>
      </div>
    );
  }
);

PageHeader.propTypes = {
  count: PropTypes.number,
  length: PropTypes.number,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  checkbox: PropTypes.bool,
  onChange: PropTypes.func,
  checkedLength: PropTypes.number,
  setFilterActive: PropTypes.func,
  title: PropTypes.string.isRequired
};
PageHeader.displayName = 'PageHeader';

export default PageHeader;
