import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  photos: [],
  documents: [],
  photosMap: {},
  documentsMap: {},
  loading: false
};

export const photosAndDocumentsSlice = createSlice({
  name: 'photosAndDocuments',
  initialState,
  reducers: {
    setPhotos: (state, action) => {
      state.photos = action.payload;
      state.photosMap = action.payload.reduce((acc, curr) => {
        acc[curr.photoId] = curr;
        return acc;
      }, {});
    },
    setDocuments: (state, action) => {
      state.documents = action.payload;
      state.documentsMap = action.payload.reduce((acc, curr) => {
        acc[curr.documentId] = curr;
        return acc;
      }, {});
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

const photosAndDocumentsReducer = photosAndDocumentsSlice.reducer;

export const photosAndDocumentsActions = photosAndDocumentsSlice.actions;
export default photosAndDocumentsReducer;
