import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pvPsv: [],
  pvPsvMap: {},
  loading: false
};

export const pvPsvSlice = createSlice({
  name: 'pvPsv',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setPVPSV: (state, action) => {
      const pvPsv = action.payload || [];
      state.pvPsv = pvPsv;
      state.pvPsvMap = pvPsv.reduce((acc, { pvId, psvId }) => {
        if (!acc[pvId]) acc[pvId] = { pv: [], psv: [] }; // for uniqueness
        if (!acc[psvId]) acc[psvId] = { pv: [], psv: [] }; // for uniqueness
        acc[pvId].psv.push(psvId);
        acc[psvId].pv.push(pvId);
        return acc;
      }, {});
    }
  }
});

const pvPsvReducer = pvPsvSlice.reducer;
export const pvPsvActions = pvPsvSlice.actions;
export default pvPsvReducer;
