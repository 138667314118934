import React, { memo, forwardRef } from 'react';

import PhotoBox from '@components/form-fields/photo-box/photo-box';

import style from '../workorder.module.scss';

const FormPhotoBox = (
  {
    errorsMsg,
    removeSpecialCharactersForName,
    field,
    control,
    name,
    register,
    checkPreviousFieldIsBoxed,
    watch,
    getValues,
    setValue,
    count
  },
  ref
) => {
  let errorMessage = errorsMsg?.photos?.message || '';
  const { refs } = ref;

  const value =
    watch(`${removeSpecialCharactersForName}`)?.[field?.formFieldId]?.photos?.length || null;

  return (
    <div
      id={removeSpecialCharactersForName}
      {...(field &&
        field?.isRequired && {
          ref: (el) => (refs.current[removeSpecialCharactersForName] = el || React.createRef())
        })}
      className={`${checkPreviousFieldIsBoxed && style.removeMarginAndBorderTopFromPassFail}`}
    >
      <PhotoBox
        item={{
          label: `${count}. ${field?.label}` || null,
          required: field?.isRequired,
          name: name
        }}
        control={control}
        register={register}
        watch={watch}
        errors={!value && errorMessage ? 'Required' : ''}
        getValues={getValues}
        setValue={setValue}
      />
    </div>
  );
};

export default memo(forwardRef(FormPhotoBox));
