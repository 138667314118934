import React from 'react';

const Divider = () => {
  return (
    <div
      style={{
        borderBottom: '0.5px solid #CBD2D9',
        width: '100%'
      }}
    ></div>
  );
};

export default Divider;
