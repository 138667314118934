import AppBar from '@components/app-bar';
import StructureListing from '@components/structure-listing';

const Structures = () => {
  return (
    <div className="page-container">
      <AppBar />

      <div className="md:ml-24">
        <StructureListing Title={'Structures'} />
      </div>
    </div>
  );
};

export default Structures;
