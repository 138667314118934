/* eslint-disable no-unused-vars */

import _ from 'lodash';
import moment from 'moment';

import TasksSelector from './tasks/tasks.selector';
import InspectionSelector from './inspection/inspection.selector';
import { getTaskAssignedUserTags } from './tasks/helper';
import { getDataPoints, getMoreInfoDataPoints } from '../pages/settings/helper';
import CheckSelector from './checks/check.selector';

const EquipmentSelector = {
  getEquipmentsWithAllTasks: async ({
    parentEquipment = [],
    id = '',
    childrenMapIds = false,
    withChild = true,
    templatesIds = '',
    structureId = null,
    equipmentCategories = [],
    showAttachedPSVs = false,
    formWrapper = false,
    store
  }) => {
    const { tasks, cache, auth, jurisdictionNumbers, settings, picklists, pvPsv } = store;
    const { workOrderUsersMap, workOrderUsersGroupsMap } = tasks;
    const userId = auth.userId || '';
    const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;
    const equipmentDisplayFields = settings.settings?.['equipmentDisplayFields'] || '';
    const { picklistsMap = {} } = picklists;
    const allTasksList = !formWrapper
      ? await EquipmentSelector?.getAllWorkOrdersAndInspections({ templatesIds, store })
      : [];

    return new Promise((resolve) => {
      const responsibleUsersMap = {
        ...(workOrderUsersMap || {}),
        ...(workOrderUsersGroupsMap || {})
      };

      const {
        equipmentsMap = {},
        sitesEquipmentsMap = {},
        equipments: cacheEquipments = [],
        sites: cacheSites = []
      } = cache;

      const equipmentsList =
        _.size(parentEquipment) > 0
          ? parentEquipment
          : !_.isEmpty(id)
          ? cacheEquipments?.filter((x) => x.equipmentId == id)
          : structureId
          ? cacheEquipments?.filter((x) => x?.structureId === structureId)
          : _.size(equipmentCategories) > 0
          ? cacheEquipments?.filter((item) =>
              new Set(equipmentCategories)?.has(item.equipmentCategory)
            )
          : childrenMapIds
          ? cacheEquipments?.filter((x) => childrenMapIds[x.equipmentId])
          : cacheEquipments;

      let prepareEquipments = [];

      equipmentsList.map((equipment) => {
        const { children = [] } = equipment;
        const parentEquipmentChildren = children?.map((childIndex) => {
          return { ...cacheEquipments[childIndex], children: [] };
        });

        let childrenData = [];
        if (equipment?.children?.length) {
          childrenData = EquipmentSelector.attachMeta(
            parentEquipmentChildren,
            sitesEquipmentsMap,
            equipmentsMap,
            allTasksList,
            cacheEquipments
          );
        }

        let allTasksForSite = [
          ...allTasksList.filter(
            (t) =>
              t?.equipmentId === equipment?.equipmentId ||
              t?.relatedAssetId === equipment?.equipmentId ||
              t?.equipments?.includes(equipment?.equipmentId)
          ),
          ...(withChild ? childrenData?.map((t) => t.allTasksForSite).flat(2) || [] : [])
        ];

        allTasksForSite = allTasksForSite.map((tas) => {
          const { responsibleUsers = [], responsibleUserGroups = [] } = tas;
          const customAssignedTaskValue = getTaskAssignedUserTags({
            responsibleUsers,
            responsibleUserGroups,
            workOrderUsersGroupsMap,
            userId,
            responsibleUsersMap
          });

          return {
            customAssignedTaskValue,
            ...tas
          };
        });

        const allDoneTasks = allTasksForSite.filter((task) => task?.status === 'Synced');
        const allotDoneTasks = allTasksForSite.filter((task) => task?.status !== 'Synced');

        const today = moment().format('YYYY-MM-DD');
        const isOverDue = allotDoneTasks.find((task) =>
          task.dueDate ? moment(task.dueDate).isBefore(today) : false
        );

        const { jurisdictionNumber } = jurisdictionNumbersMap[equipment?.equipmentId] || {};

        const dataPoints = getDataPoints(
          { ...equipment, subtype: equipment?.equipmentSubType, jurisdictionNumber },
          equipmentDisplayFields,
          'equipment',
          picklistsMap
        );
        const equipmentChildren = !formWrapper
          ? equipment?.children?.map((childIndex) => {
              return { ...cacheEquipments[childIndex], children: [] };
            })
          : [];
        let equipmentPSVs = [];
        if (showAttachedPSVs && id) {
          const { pvPsvMap = {} } = pvPsv;
          const { psvsData = [] } = getEquipmentAttachedPSV({
            equipmentId: id,
            pvPsvMap,
            cacheEquipments,
            equipmentsMap,
            jurisdictionNumbersMap,
            equipmentDisplayFields,
            picklistsMap
          });
          equipmentPSVs = psvsData;
        }

        prepareEquipments.push({
          ...equipment,
          status: !formWrapper ? equipment?.status : null,
          equipmentPSVs,
          children: equipmentChildren,
          allDoneTasks,
          isOverDue,
          allTasksForSite,
          allotDoneTasks,
          dataPoints,
          soonestDueDate: _.min(
            allotDoneTasks.map((t) => t?.due || t?.dueDate).map((date) => new Date(date))
          )
        });
      });
      resolve(prepareEquipments);
    });
  },
  getSingleEquipmentDetails: async ({ equipmentId = '', store }) => {
    const { cache, picklists, jurisdictionNumbers, pvPsv, settings } = store;
    const {
      sitesMap = {},
      equipmentsMap = {},
      equipmentsLoading = false,
      equipments: cacheEquipments = [],
      sites: cacheSites = []
    } = cache;
    const equipmentDisplayFields = settings.settings?.['equipmentDisplayFields'] || '';

    const tasksList = await EquipmentSelector?.getAllWorkOrdersAndInspections({
      equipmentId,
      store
    });

    const parentEquipment = cacheEquipments[equipmentsMap[equipmentId]] || {};

    const parentEquipmentChildren = parentEquipment?.children?.map((childIndex) => {
      return { ...cache.equipments[childIndex], children: [] };
    });

    const children = [...(parentEquipmentChildren || [])].sort(
      (a, b) =>
        (cacheSites[sitesMap[a?.siteId]]?.distance != null
          ? cacheSites[sitesMap[a?.siteId]]?.distance
          : Infinity) -
          (cacheSites[sitesMap[b?.siteId]]?.distance != null
            ? cacheSites[sitesMap[b?.siteId]]?.distance
            : Infinity) || a.name.localeCompare(b.name)
    );

    const childrenMapIds =
      children &&
      children?.reduce((acc, curr) => {
        acc[curr.equipmentId] = true;
        return acc;
      }, {});
    let childrenDetails =
      _.size(childrenMapIds) > 0
        ? await EquipmentSelector.getEquipmentsWithAllTasks({ childrenMapIds, store })
        : [];

    return new Promise((resolve) => {
      const { picklistsMap = {} } = picklists;
      const { jurisdictionNumbersMap = {} } = jurisdictionNumbers;
      const { pvPsvMap = {} } = pvPsv;

      const { name, siteId, equipmentType, equipmentSubType, equipmentCategory, link } =
        parentEquipment;
      const { pv = [], psv = [] } = pvPsvMap[equipmentId] || {};

      const pvs = pv
        .filter((id) => cacheEquipments?.[equipmentsMap?.[id]])
        .map((x) => cacheEquipments[equipmentsMap[x]]);

      const { jurisdiction, jurisdictionNumber } = jurisdictionNumbersMap[equipmentId] || {};
      const jurisdictionProvince = picklistsMap[jurisdiction]?.value;
      const type = picklistsMap[equipmentType]?.value;
      const category = picklistsMap[equipmentCategory]?.value;
      const status = picklistsMap[parentEquipment.status]?.value;
      const data = {
        ...parentEquipment,
        category: equipmentCategory,
        subtype: equipmentSubType,
        jurisdictionNumber
      };

      const moreInfoDataPoints = getMoreInfoDataPoints(
        { ...data, subtype: data?.equipmentSubType, jurisdictionNumber },
        'equipment',
        picklistsMap
      );

      const moreInfoDataPointsMap = moreInfoDataPoints.reduce((acc, curr) => {
        acc[curr.Id] = curr;
        return acc;
      }, {});
      moreInfoDataPointsMap['Type'] = { value: type, title: 'Type', key: 'type' };
      moreInfoDataPointsMap['Status'] = { value: status, title: 'Status', key: 'status' };
      if (moreInfoDataPointsMap['JurisdictionNumber'])
        moreInfoDataPointsMap['JurisdictionNumber'].before = {
          heading: 'Jurisdiction',
          title: jurisdictionProvince
        };

      const dataPoints = getDataPoints(
        { ...data, subtype: data?.equipmentSubType, jurisdictionNumber },
        equipmentDisplayFields,
        'equipment',
        picklistsMap
      );

      const dataPointsMap = dataPoints.reduce((acc, curr) => {
        acc[curr.Id] = curr;
        return acc;
      }, {});
      dataPointsMap['Type'] = { value: type, title: 'Type' };
      dataPointsMap['Status'] = { value: status, title: 'Status' };
      if (dataPointsMap['JurisdictionNumber'])
        dataPointsMap['JurisdictionNumber'].before = {
          heading: 'Jurisdiction',
          title: jurisdictionProvince
        };

      const tasks =
        _.flattenDeep([
          ...tasksList,
          ...(childrenDetails
            ?.map((t) => t.allTasksForSite)
            .flat(2)
            .filter((y) => y) || [])
        ]) || [];

      const allDoneTasks = tasks.filter((task) => task?.status === 'Synced');
      const allNotDoneTasks = tasks.filter((task) => task?.status !== 'Synced');

      const allInspections = tasks.filter((task) => task?.type === 'Inspection');
      const allDoneInspections = allInspections.filter((task) => task?.status === 'Synced');
      const allNotDoneInspections = allInspections.filter((task) => task?.status !== 'Synced');

      const allChecks = tasks.filter((task) => task?.type === 'Check');
      const allDoneChecks = allChecks.filter((task) => task?.status === 'Synced');
      const allNotDoneChecks = allChecks.filter((task) => task?.status !== 'Synced');

      const allWorkOrders = tasks.filter((task) => task?.type === 'Work Order');
      const allDoneWorkOrders = allWorkOrders.filter((task) => task?.status === 'Synced');
      const allNotDoneWorkOrders = allWorkOrders.filter((task) => task?.status !== 'Synced');

      const today = moment().format('YYYY-MM-DD');
      const isOverDue = allNotDoneTasks?.find((task) =>
        task?.dueDate ? moment(task?.dueDate).isBefore(today) : false
      );
      const isInspectionOverDue = allNotDoneInspections?.find((task) =>
        task?.dueDate ? moment(task?.dueDate).isBefore(today) : false
      );
      const isCheckOverDue = allNotDoneChecks?.find((task) =>
        task?.nextDue ? moment(task?.nextDue).isBefore(today) : false
      );
      const isWorkOrderOverDue = allNotDoneWorkOrders?.find((task) =>
        task?.dueDate ? moment(task?.dueDate).isBefore(today) : false
      );

      const { psvsData } = getEquipmentAttachedPSV({
        equipmentId,
        pvPsvMap,
        cacheEquipments,
        equipmentsMap,
        jurisdictionNumbersMap,
        equipmentDisplayFields,
        picklistsMap
      });
      const psvData = pvs
        ?.filter((y) => y?.equipmentId)
        ?.map((x) => {
          const { jurisdictionNumber } = jurisdictionNumbersMap[x?.equipmentId] || {};
          const data = {
            ...x,
            category: x?.equipmentCategory,
            subtype: x?.equipmentSubType,
            jurisdictionNumber
          };

          const dataPoints = getDataPoints(
            { ...data, subtype: data?.equipmentSubType, jurisdictionNumber },
            equipmentDisplayFields,
            'equipment',
            picklistsMap
          );
          return {
            ...x,
            dataPoints
          };
        });

      resolve({
        equipmentsLoading,
        parentEquipment,
        tasks,
        allDoneTasks,
        allInspections,
        allDoneInspections,
        allWorkOrders,
        allDoneWorkOrders,
        allChecks,
        allDoneChecks,
        isCheckOverDue,
        isInspectionOverDue,
        isWorkOrderOverDue,
        isOverDue,
        children: childrenDetails,
        psv,
        psvs: psvsData,
        equipmentsMap,
        pvs: psvData,
        dataPoints,
        category,
        type,
        sitesMap,
        siteId,
        dataPointsMap,
        moreInfoDataPointsMap,
        data,
        status,
        name,
        cacheEquipments,
        cacheSites,
        link
      });
    });
  },
  attachMeta: (
    equipmentList,
    sitesEquipmentsMap,
    equipmentsMap,
    allWOTasks,
    cacheEquipments = []
  ) => {
    let prepareSites = [];
    equipmentList.map((equipment) => {
      const allTasksForSite = allWOTasks.filter(
        (t) =>
          t?.equipmentId === equipment?.equipmentId ||
          t?.equipments?.includes(equipment?.equipmentId)
      );
      const allDoneTasks = allTasksForSite.filter((task) => task?.status === 'Synced');
      const allotDoneTasks = allTasksForSite.filter((task) => task?.status !== 'Synced');

      const today = moment().format('YYYY-MM-DD');
      const isOverDue = allotDoneTasks.find((task) =>
        task.dueDate ? moment(task.dueDate).isBefore(today) : false
      );

      prepareSites.push({
        allDoneTasks,
        isOverDue,
        allTasksForSite,
        allotDoneTasks,
        soonestDueDate: _.min(
          allotDoneTasks.map((t) => t?.due || t?.dueDate).map((date) => new Date(date))
        )
      });
    });

    return prepareSites;
  },
  getAllWorkOrdersAndInspections: async ({ templatesIds = null, equipmentId = null, store }) => {
    const [tasks, inspections, checks] = await Promise.all([
      await TasksSelector.getTasks({ equipmentId, store }),
      await InspectionSelector.getInspections({ equipmentId, store }),
      await CheckSelector.getAllChecks({ equipmentId, store })
    ]);
    const allAssignedWorkOrders = _.isEmpty(templatesIds)
      ? tasks?.filter((task) => !task.newWorkOrder && !task.newWorkRequest)
      : tasks?.filter(
          (task) =>
            !task.newWorkOrder && !task.newWorkRequest && templatesIds?.includes(task?.rtNumber)
        );
    const allAssignedInspections = inspections;
    const AllAssignedChecks = checks;
    return [...allAssignedWorkOrders, ...allAssignedInspections, ...AllAssignedChecks];
  }
};

export default EquipmentSelector;

export const getEquipmentAttachedPSV = ({
  equipmentId,
  pvPsvMap,
  cacheEquipments,
  equipmentsMap,
  jurisdictionNumbersMap,
  equipmentDisplayFields,
  picklistsMap
}) => {
  const { psv = [] } = pvPsvMap[equipmentId] || {};
  const psvs = psv
    .filter((id) => cacheEquipments[equipmentsMap[id]])
    ?.map((x) => cacheEquipments[equipmentsMap[x]]);

  const psvsData = psvs
    ?.filter((y) => y?.equipmentId)
    ?.map((x) => {
      const { jurisdictionNumber } = jurisdictionNumbersMap[x?.equipmentId] || {};
      const data = {
        ...x,
        category: x?.equipmentCategory,
        subtype: x?.equipmentSubType,
        jurisdictionNumber
      };

      const dataPoints = getDataPoints(
        { ...data, subtype: data?.equipmentSubType, jurisdictionNumber },
        equipmentDisplayFields,
        'equipment',
        picklistsMap
      );
      const category = picklistsMap[x?.equipmentCategory]?.value;
      const type = picklistsMap[x?.equipmentType]?.value;
      const subType = picklistsMap[x?.equipmentSubType]?.value;

      return {
        ...x,
        category,
        type,
        subType,
        dataPoints,
        verificationStatus: 'verify',
        isDetached: false,
        isReattach: false
      };
    });
  return {
    psvsData
  };
};
