/* eslint-disable no-unused-vars */

import _ from 'lodash';
import { useSelector } from 'react-redux';
import React, { forwardRef, useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';

import SearchField from '@components/search-field';
import FilterHeader from '@components/filters/filter-header';
import TaskBookmark from '../task-bookmark';
import TasksFilterGroups from '@components/filters/task-filter-group';

import { mapValues, countFilters, tasksDefaultFilterValues } from '../helper';
import { checkValueInObjectIsNotEmpty } from '@utilities/helper.util';
import { filterActions } from '@redux/slices/filters';
import useWindowDimensions from '@hooks/useWindowDimensions';
import { getAppDispatch } from '@utilities/dispatch.util';

import SiteFilterGroup from '@components/filters/site-filter-group';
import ResetAndViewButton from '@components/filters/reset-view-button';

const TaskFilter = (
  {
    length,
    filtersCount,
    setFilterActive,
    noGap,
    onSearch,
    resetFilters,
    setResetFilters,
    isFilterActive,
    setFiltersCount,
    onChangeFilters,
    sitesSiteTypeDataMap,
    sitesSiteStatusDataMap,
    sitesCheckPriorityDataMap,
    assignedUserGroups,
    sitesWorkOrderPriorityDataMap,
    sitesAreaHierarchyTreeDataMap,
    tasks = [],
    selectedSort,
    selectTodoOrInProgressStatus,
    asset = null,
    filterType = 'all'
  },
  ref
) => {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = getAppDispatch();
  const { width } = useWindowDimensions();

  const bookmarkFilter = searchParams.get('bookmarkFilter');
  const dueDateFilter = searchParams.get('dueDate');
  const downloadFilter = searchParams.get('download');

  const { unit, coordinates } = useSelector((state) => state.cache);
  const tasksFilter = useSelector((state) => state?.filters?.tasksFilter) || {};
  const { picklistsMap = {}, areaHierarchyTreeDataMap = {} } = useSelector(
    (state) => state?.picklists
  );

  const [inputValue, setInputValue] = useState('');
  const [filters, setFilters] = useState(tasksDefaultFilterValues);

  const [initialY, setInitialY] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    siteTypeTags,
    siteStatusTags,
    assignableUsersTags,
    checkPriorityTags,
    workOrderPriorityTags,
    treeData,
    taskTypeTag,
    taskStatusTags,
    taskRecurringTemplateTags,
    workOrderTypeTags,
    inspectionTypeTags,
    inspectionRunTags,
    hseEventsCategoriesTags,
    checkTypeTags,
    checkRecurrancePriorityTags,
    checkTypeTagsMap,
    inspectionTypeTagsMap
  } = React.useMemo(() => {
    return mapValues({
      sitesSiteTypeDataMap,
      sitesSiteStatusDataMap,
      sitesCheckPriorityDataMap,
      assignedUserGroups,
      sitesWorkOrderPriorityDataMap,
      sitesAreaHierarchyTreeDataMap,
      tasks
    });
  }, [
    sitesSiteTypeDataMap,
    sitesSiteStatusDataMap,
    sitesCheckPriorityDataMap,
    assignedUserGroups,
    sitesWorkOrderPriorityDataMap,
    sitesAreaHierarchyTreeDataMap
  ]);

  useEffect(() => {
    if (selectTodoOrInProgressStatus) {
      setFilters({ ...tasksDefaultFilterValues, taskStatus: { value: ['To do', 'In progress'] } });
    } else {
      if (!_.isEmpty(bookmarkFilter)) {
        const filtersInDecode = decodeURIComponent(bookmarkFilter);
        const filtersInParse = JSON.parse(filtersInDecode);
        filtersInParse?.filters && setFilters(filtersInParse?.filters);
        filtersInParse?.filters &&
          filtersInParse?.filters['searched'] &&
          setInputValue(filtersInParse?.filters['searched'] || '');
      } else if (checkValueInObjectIsNotEmpty(tasksFilter)) {
        setFilters(tasksFilter);
        onChangeFilters?.(tasksFilter);
        setInputValue(tasksFilter?.searched || '');
        onSearch(tasksFilter?.searched || '');
      } else {
        setInputValue('');
        setFilters(tasksDefaultFilterValues);
      }
    }
    if (resetFilters) resetBtnEvent({ filterActive: width < 768 ? false : true });
    return () => {};
  }, [resetFilters, state?.resetFilters, bookmarkFilter]);

  useEffect(() => {
    if (downloadFilter) updateFilters('download', 'selected', downloadFilter);
    if (dueDateFilter === 'today') {
      updateFilters('dueDate', 'value', moment());
      updateFilters('dueDate', 'selected', 'on');
    } else if (dueDateFilter === 'past') {
      updateFilters('dueDate', 'selected', 'past');
      updateFilters('dueDate', 'value', ['Unlimited']);
    }
  }, [downloadFilter, dueDateFilter]);

  useEffect(() => {
    const filterCount = countFilters(filters, inputValue) || 0;
    setFiltersCount(filterCount);
    dispatch && dispatch?.(filterActions.setTaskFiltersCount(filterCount));
  }, [filters, inputValue, dispatch]);

  useEffect(() => {
    if (isFilterActive) document.body.classList.add('filter-open');
    else document.body.classList.remove('filter-open');
  }, [isFilterActive]);

  useEffect(() => {
    return () => {
      if (isFilterActive) {
        setFilterActive(false);
        document.body.classList.remove('filter-open');
      }
    };
  }, [isFilterActive]);

  const startTouch = (e) => setInitialY(e.touches[0].clientY);

  const moveTouch = (e) => {
    let currentY = e.touches[0].clientY;
    let diffY = initialY - currentY;
    if (diffY < 0) setFilterActive(false);
    setInitialY(e.touches[0].clientY);
  };

  const resetBtnEvent = ({ filterActive = true }) => {
    setResetFilters((pre) => !pre);
    navigate(`${pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`, {
      replace: true
    });
    setFilterActive(filterActive);
    setInputValue('');
    setFilters(tasksDefaultFilterValues);
    onChangeFilters?.(tasksDefaultFilterValues);
    onSearch('');
    dispatch?.(filterActions.setTaskFilters({ ...tasksDefaultFilterValues, searched: '' }));
  };

  const leftBtnEvent = () => setFilterActive(false);

  const handleUserInput = (e) => {
    const valueSearched = (e && e.target.value) || '';
    onSearch?.(valueSearched);
    dispatch?.(filterActions.setTaskFilters({ ...filters, searched: valueSearched }));
    setInputValue(valueSearched);
  };

  const handleKeyDown = (e) => {
    if (width < 768 && e.key === 'Enter') setFilterActive(false);
  };
  const updateFilters = (name, key, value) => {
    const filtersCopy = { ...filters };
    let newFilter = {
      ...filtersCopy,
      [name]: {
        ...Object.assign({}, filtersCopy[name]),
        [key]: value,
        ...(key === 'selected' && value === 'on' && { value: '' }),
        ...(key === 'selected' && (value === 'past' || value === 'next') && { value: [] }),
        ...(key === 'selected' && value === 'range' && { value: ['', ''] }),
        ...(key === 'selected' && value === 'last' && { value: ['last'] })
      }
    };
    setFilters({ ...newFilter });
    onChangeFilters?.({ ...newFilter });
    dispatch?.(filterActions.setTaskFilters({ ...newFilter, searched: inputValue }));
  };

  React.useImperativeHandle(ref, () => ({
    clearFilterOnTaskFilterPage() {
      setFilters(tasksDefaultFilterValues);
    }
  }));

  useEffect(() => {
    if (asset) {
      setInputValue('');
      setFilters(tasksDefaultFilterValues);
    }
  }, [asset]);

  return (
    <>
      <div
        className={`op-sidebarfilter ${isFilterActive ? 'open' : ''} ${noGap ? '' : 'gap'}`}
        style={{
          padding: isFilterActive ? '10px 16px' : '10px 0px'
        }}
      >
        <div onTouchStart={startTouch} onTouchMove={moveTouch}>
          <FilterHeader
            title="Filters"
            filterCount={filtersCount || 0}
            resetBtnEvent={() => resetBtnEvent({})}
            leftBtnEvent={leftBtnEvent}
          />
        </div>
        <div className="op-filter-search " style={{ marginTop: '25px' }}>
          <SearchField
            placeholder="Search"
            value={inputValue}
            onKeyDown={handleKeyDown}
            onChange={handleUserInput}
            isClearable
          />
        </div>
        <p
          className="label-regular op-filter-label"
          style={{ padding: '29px 0px 32px 0px', marginBottom: '0px' }}
        >
          {filterType === 'inspection' || filterType === 'Inspection'
            ? 'Inspection'
            : filterType === 'workOrder' || filterType === 'Work Order'
            ? 'Work orders'
            : filterType === 'check' || filterType === 'Check'
            ? 'Check'
            : 'Tasks'}
        </p>

        <TasksFilterGroups
          filterType={filterType}
          taskTypeTag={taskTypeTag}
          filters={filters}
          updateFilters={updateFilters}
          taskStatusTags={taskStatusTags}
          asset={asset}
          workOrderTypeTags={workOrderTypeTags}
          assignableUsersTags={assignableUsersTags}
          workOrderPriorityTags={workOrderPriorityTags}
          isActive={isFilterActive}
          picklistsMap={picklistsMap}
          taskRecurringTemplateTags={taskRecurringTemplateTags}
          inspectionRunTags={inspectionRunTags}
          inspectionTypeTags={inspectionTypeTags}
          hseEventsCategoriesTags={hseEventsCategoriesTags}
          checkTypeTags={checkTypeTags}
          checkTypeTagsMap={checkTypeTagsMap}
          inspectionTypeTagsMap={inspectionTypeTagsMap}
          checkRecurrancePriorityTags={checkRecurrancePriorityTags}
          tasksListing
        />

        {(filterType !== 'Inspection' || filterType !== 'Work Order') && _.isEmpty(asset) && (
          <SiteFilterGroup
            coordinates={coordinates}
            filters={filters}
            updateFilters={updateFilters}
            isActive={isFilterActive}
            treeData={treeData}
            areaHierarchyTreeDataMap={areaHierarchyTreeDataMap}
            siteTypeTags={siteTypeTags}
            picklistsMap={picklistsMap}
            siteStatusTags={siteStatusTags}
            unit={unit}
            tasksListing
          />
        )}

        <ResetAndViewButton
          clickResetButton={() => resetBtnEvent({ filterActive: false })}
          length={length}
          setFilterActiveEvent={(value) => setFilterActive(value)}
          setIsModalVisibleEvent={(value) => setIsModalVisible(value)}
          width={width}
        />
      </div>
      {isModalVisible && (
        <TaskBookmark
          open={isModalVisible}
          setOpen={(value) => setIsModalVisible(value)}
          selectedSort={selectedSort}
          filters={{ ...filters, searched: inputValue }}
          filterType={filterType}
          asset={asset}
          inspectionRunTags={inspectionRunTags}
          taskRecurringTemplateTags={taskRecurringTemplateTags}
        />
      )}
    </>
  );
};

export default React.memo(forwardRef(TaskFilter), _.isEqual);
