/* eslint-disable no-unused-vars */

import React, { memo } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import SelectBox from '@components/select-box-component';

import { settingsActions } from '@redux/slices/settings';

const SettingsInput = ({ name, label, options, selectName, disabled }) => {
  const dispatch = useDispatch();

  const { control } = useForm();
  const digitsRegex = /^[0-9]+$/;
  let inputValue = useSelector((state) => state.settings.settings?.[name]);
  const selectValue = useSelector((state) => state.settings.settings?.[selectName]) || '';

  const handleChange = (e) => {
    const { value } = e.target;
    const number = Number(value);

    if (!value || (digitsRegex.test(value) && number >= 0))
      dispatch?.(settingsActions.updateSettingsByName({ name, value }));
  };

  return (
    <div className="settings--values--card--input">
      <input
        value={inputValue}
        disabled={disabled}
        onChange={handleChange}
        className="settings--values--card--input--value"
      />

      {label ? (
        <div className="settings--values--card--input--label">{label}</div>
      ) : (
        <>
          <SelectBox
            control={control}
            name={selectName}
            options={options}
            disabled={disabled}
            defaultValue={selectValue}
            className="selectFullWidth"
            customFunc={(e) =>
              dispatch?.(
                settingsActions.updateSettingsByName({ name: selectName, value: e?.value })
              )
            }
          />
        </>
      )}
    </div>
  );
};

export default memo(SettingsInput);
