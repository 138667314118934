/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '@components/logo';
import InputField from '@components/input-field';
import AuthService from '@services/auth.service';
import { homeActions } from '@redux/slices/home';
import { getAppDispatch } from '@utilities/dispatch.util';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = getAppDispatch?.();

  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const { user, loading } = useSelector((state) => state.auth);
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !password || !emailRegex.test(username)) return setErrorMessage('error');

    setErrorMessage('');

    try {
      const [success, error] = await AuthService.login({
        emailAddress: username,
        password: password
      });

      if (error?.response) {
        const { status } = error.response;
        if (status === 401) setErrorMessage('incorrect');
        else if (status === 403) setErrorMessage('no-access');
      } else if (success?.data?.token) dispatch?.(homeActions.setInitialCache(false));
    } catch (error) {
      if (error?.message === 'Permission denied') setErrorMessage('no-access');
    }
  };

  useEffect(() => {
    if (user) return navigate('/dashboard', { state: { initialLogin: true } });
  }, [user]);

  return (
    <>
      <div className="op-login-container">
        <div className="op-login">
          <div className="text-center mb-14">
            <Logo />
          </div>
          <form onSubmit={handleSubmit}>
            <div className="op-input-field-wrap mb-6">
              <InputField
                label="Email"
                type="email"
                onChange={(e) => setUserName(e.target.value)}
                value={username}
                error={
                  errorMessage
                    ? !username
                      ? 'Required'
                      : !emailRegex.test(username)
                      ? 'Not an email address'
                      : ''
                    : ''
                }
                className={
                  errorMessage
                    ? !username ||
                      !emailRegex.test(username) ||
                      errorMessage === 'incorrect' ||
                      errorMessage === 'no-access'
                      ? 'error'
                      : ''
                    : ''
                }
              />
            </div>
            <div className="op-input-field-wrap relative mb-9">
              <InputField
                label="Password"
                type={'password'}
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                error={errorMessage ? (!password ? 'Required' : '') : ''}
                className={`password-field
                ${
                  errorMessage
                    ? !password || errorMessage === 'incorrect' || errorMessage === 'no-access'
                      ? 'error'
                      : ''
                    : ''
                }
                    `}
              />
            </div>
            {errorMessage && errorMessage !== 'error' && (
              <div className="form-error mb-9">
                {errorMessage === 'no-access'
                  ? 'You don’t have access Oplii Mobile. Please contact your administrator.'
                  : errorMessage === 'incorrect' && (
                      <span>
                        Sorry—this login information didn’t work. Try again or{' '}
                        <Link to={'passwordreset'} className="link">
                          reset your password.
                        </Link>
                      </span>
                    )}
              </div>
            )}
            <button className="btn-fill button-large" type="submit" disabled={loading}>
              Log in
            </button>
            <div className="op-divider mt-9"></div>
            <div className="flex flex-wrap justify-between mt-2">
              <Link className="input-bold op-anchor-link" to={'/passwordreset'}>
                Password reset
              </Link>
              <Link className="input-bold op-anchor-link" to={'/support-msg'}>
                Contact support
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
