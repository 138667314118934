/* eslint-disable no-unused-vars */

import React, { memo, useMemo, useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { store } from '@redux/store';

import Collapseable from '@components/collapseable';
import DeleteModal from '@components/modal/variants/delete-modal';
import Icon from '@components/icon-component';
import PSVsModal from '@components/psvs-modal';
import AttachPSVModal from '@components/modal/variants/attach-psv-modal';
import DataSnippets from '@components/data-snippet';
import { getDataPoints } from '@pages/settings/helper';

import useWindowDimensions from '@hooks/useWindowDimensions';
import style from '../workorder.module.scss';

const PSVs = ({ psvsData, dropdownMenuMapList, setValue, getValues, name, errors }) => {
  const [openPSVModal, setOpenPSVModal] = useState(false);
  const [editPSVId, setEditPSVId] = useState(null);
  const [deAttachPSV, setDeAttachPSV] = useState(false);
  const [attachPSV, setAttachPSV] = useState(false);
  const [deletePSV, setDeletePSV] = useState(false);
  const [psvsReconcileMap, setPsvsReconcileMap] = useState({});
  const { picklists, settings, cache } = store.getState();
  const { picklistsMap = {} } = picklists;
  const { equipmentsMap = {}, equipments: cacheEquipments = [] } = cache;

  const equipmentDisplayFields = settings.settings?.['equipmentDisplayFields'] || '';

  const { addStatusToPSVS, psvsMap } = useMemo(() => {
    const addStatusToPSVS = getValues(name) || [];
    const psvsMap = addStatusToPSVS?.reduce((acc, curr) => {
      acc[curr?.equipmentId] = { ...curr };
      return acc;
    }, {});

    return { psvsMap, addStatusToPSVS };
  }, [psvsData, getValues(name)]);

  useEffect(() => {
    if ((getValues(`${name}-reconcile`) || [])?.length > 0) {
      const data = getValues(`${name}-reconcile`) || [];

      const psvsReconcileMapData = data?.reduce((acc, curr) => {
        acc[curr?.equipmentId] = { ...curr };
        return acc;
      }, {});

      setPsvsReconcileMap(psvsReconcileMapData);
    }
  }, [getValues(`${name}-reconcile`)]);

  const deAttachedOrAttachedSelectedPSV = useCallback(
    ({ deAttached = false, reattach = false }) => {
      let copyPsvDataList = [...getValues(name)];
      const findIndex = copyPsvDataList?.findIndex((item) => item?.equipmentId === editPSVId);
      if (findIndex !== -1) {
        copyPsvDataList[findIndex] = {
          ...copyPsvDataList[findIndex],
          isDetached: deAttached,
          isReattach: reattach,
          verificationStatus: copyPsvDataList[findIndex].verificationStatus,
          errorExists: deAttached ? 'No' : ''
        };
        setValue(name, copyPsvDataList, { shouldDirty: true });
        setOpenPSVModal(false);
        deAttached && setDeAttachPSV(false);
        reattach && setAttachPSV(false);
        setEditPSVId('');
      }
    },
    [psvsData, editPSVId]
  );

  const updateExistingPsvEvent = ({
    data,
    psvId,
    status = 'Verified',
    isNew = false,
    cachedPSVData
  }) => {
    let copyPsvDataList = [...getValues(name)];
    const findIndex = copyPsvDataList?.findIndex((item) => item?.equipmentId === psvId);
    if (findIndex !== -1) {
      const updatedData = {
        name: data?.equipmentName || '',
        equipmentId: psvId,
        verificationStatus: status,
        isDetached: cachedPSVData?.isDetached,
        isReattach: cachedPSVData?.isReattach,
        isNew,
        category: cachedPSVData?.category,
        equipmentCategory: data?.equipmentCategory,
        equipmentType: data?.equipmentType,
        equipmentSubType: data?.equipmentSubType,
        type: cachedPSVData?.type,
        serialNumber: data?.serial,
        equipmentNumber: data?.equipmentNumber,
        status: data?.status,
        psvCategoryTypeSubType: data?.psvCategoryType,
        valveLocation: data?.valveLocation,
        pressure: {
          textBoxValue: data?.setPressure?.textBoxValue,
          textBoxUnitValue: data?.setPressure?.textBoxUnitValue
        },
        manufacturer: data?.manufacture,
        model: data?.model,
        fieldTagNumber: data?.fieldTag,
        crnNumber: data?.crn,
        psvCapacity: {
          textBoxValue: data?.psvCapacity?.textBoxValue,
          textBoxUnitValue: data?.psvCapacity?.textBoxUnitValue
        },
        inletSize: {
          textBoxValue: data?.inletSize?.textBoxValue,
          textBoxUnitValue: data?.inletSize?.textBoxUnitValue
        },
        inletJointStyle: data?.inletJointStyle,
        outletSize: {
          textBoxValue: data?.outletSize?.textBoxValue,
          textBoxUnitValue: data?.outletSize?.textBoxUnitValue
        },
        outletJointStyle: data?.outletJointStyle,
        lastServiceDate: data?.lastServiceDate
          ? moment(data?.lastServiceDate).format('YYYY-MM-DD')
          : null,
        comments: data?.comment || '',
        lastServiceBy: data?.lastServiceBy,
        errorExists: status === 'Verified' ? 'No' : ''
      };

      // dataPoints
      const cachedPSVDataPoints =
        !copyPsvDataList[findIndex]?.isNew && cacheEquipments[equipmentsMap[psvId]]
          ? {
              condition: cacheEquipments[equipmentsMap[psvId]]?.condition || '',
              description: cacheEquipments[equipmentsMap[psvId]]?.description || '',
              mawp: cacheEquipments[equipmentsMap[psvId]]?.mawp || '',
              maximumCapacity: cacheEquipments[equipmentsMap[psvId]]?.maximumCapacity || '',
              mdmt: cacheEquipments[equipmentsMap[psvId]]?.mdmt || '',
              physicalLocation: cacheEquipments[equipmentsMap[psvId]]?.physicalLocation || '',
              psvType: cacheEquipments[equipmentsMap[psvId]]?.psvType || '',
              service: cacheEquipments[equipmentsMap[psvId]]?.service || '',
              manufacturedYear: cacheEquipments[equipmentsMap[psvId]]?.manufacturedYear || ''
            }
          : {};

      const dataPoints = getDataPoints(
        { ...updatedData, subtype: updatedData?.equipmentSubType, ...cachedPSVDataPoints },
        equipmentDisplayFields,
        'equipment',
        picklistsMap
      );
      console.log('call');
      copyPsvDataList[findIndex] = { ...updatedData, dataPoints };
      setValue(name, copyPsvDataList, { shouldDirty: true });
      setOpenPSVModal((prev) => !prev);
      setEditPSVId('');
    }
  };

  const addNewPsvEvent = ({ data }) => {
    let copyPsvDataList = [...getValues(name)];
    const newData = {
      name: data?.equipmentName || '',
      equipmentId: uuidv4(),
      verificationStatus: 'Verified',
      isDetached: false,
      isReattach: false,
      isNew: true,
      equipmentCategory: data?.equipmentCategory,
      equipmentType: data?.equipmentType,
      equipmentSubType: data?.equipmentSubType,
      serialNumber: data?.serial,
      equipmentNumber: data?.equipmentNumber,
      status: data?.status,
      psvCategoryTypeSubType: data?.psvCategoryType,
      valveLocation: data?.valveLocation,
      pressure: {
        textBoxValue: data?.setPressure?.textBoxValue,
        textBoxUnitValue: data?.setPressure?.textBoxUnitValue
      },
      manufacturer: data?.manufacture,
      model: data?.model,
      fieldTagNumber: data?.fieldTag,
      crnNumber: data?.crn,
      psvCapacity: {
        textBoxValue: data?.psvCapacity?.textBoxValue,
        textBoxUnitValue: data?.psvCapacity?.textBoxUnitValue
      },
      inletSize: {
        textBoxValue: data?.inletSize?.textBoxValue,
        textBoxUnitValue: data?.inletSize?.textBoxUnitValue
      },
      inletJointStyle: data?.inletJointStyle,
      outletSize: {
        textBoxValue: data?.outletSize?.textBoxValue,
        textBoxUnitValue: data?.outletSize?.textBoxUnitValue
      },
      outletJointStyle: data?.outletJointStyle,
      lastServiceDate: data?.lastServiceDate
        ? moment(data?.lastServiceDate).format('YYYY-MM-DD')
        : null,
      comments: data?.comment || '',
      lastServiceBy: data?.lastServiceBy,
      errorExists: 'No'
    };

    const dataPoints = getDataPoints(
      { ...newData, subtype: data?.equipmentSubType },
      equipmentDisplayFields,
      'equipment',
      picklistsMap
    );

    setValue(name, [...copyPsvDataList, { ...newData, dataPoints }], { shouldDirty: true });
    setOpenPSVModal(false);
    setEditPSVId('');
  };

  const deleteSelectedPSV = () => {
    let copyPsvDataList = [...getValues(name)];
    copyPsvDataList = copyPsvDataList?.filter((item) => item?.equipmentId !== editPSVId);
    setValue(name, copyPsvDataList, { shouldDirty: true });
    setDeletePSV(false);
    setOpenPSVModal(false);
    setEditPSVId('');
  };

  const { width } = useWindowDimensions();
  const formWrapperWIdth = document.getElementById('formWrapperContainer')?.clientWidth || 0;
  const headerWidth = useMemo(() => {
    return width > 500 ? Math.floor(formWrapperWIdth / 2) - 32 - 16 : formWrapperWIdth - 32;
  }, [width, formWrapperWIdth]);

  return (
    <div>
      <div className={style.grid2}>
        {addStatusToPSVS?.map((psvs, index) => (
          <div
            key={psvs?.equipmentId || index}
            id={psvs?.equipmentId}
            style={{
              width: `100%`,
              minWidth: `${headerWidth}px`
            }}
          >
            <Collapseable
              title={psvs?.name || ''}
              subTitle={psvs?.isDetached ? 'Detached' : psvs?.verificationStatus}
              subTitleClass={style.psvsSubTitle}
              subTitleErrorClass={style.psvsSubTitleError}
              dot
              dotColor={
                psvs?.verificationStatus === 'verify' && !psvs?.isDetached ? '#e02b2b' : '#34C369'
              }
              noAction={true}
              openCollapse={psvs?.expand || false}
              error={
                Object?.keys(errors)[0]?.includes('psvs-') &&
                psvs.verificationStatus === 'verify' &&
                !psvs.isDetached
              }
            >
              <>
                <DataSnippets
                  title={`${picklistsMap[psvs?.equipmentCategory]?.value} - ${
                    picklistsMap[psvs?.equipmentType]?.value
                  }`}
                  subtitle={psvs?.name || ''}
                  dataPoints={psvs?.dataPoints}
                  subTitleClassName={style.subTitleClassName}
                />
                <div className={style.action}>
                  <button
                    type="button"
                    onClick={() => {
                      setEditPSVId(psvs?.equipmentId);
                      setOpenPSVModal((prev) => !prev);
                    }}
                  >
                    <span>
                      {' '}
                      <Icon name={'edit-pencil'} /> Edit / Verify
                    </span>
                  </button>
                </div>
              </>
            </Collapseable>
          </div>
        ))}
      </div>

      <p
        className={style.addPSVs}
        onClick={() => {
          setOpenPSVModal((prev) => !prev);
        }}
        style={{
          marginTop: addStatusToPSVS?.length > 0 ? '16px' : '0px'
        }}
      >
        <Icon name={'add-icon'} style={{ marginRight: '4px' }} />
        Add PSV
      </p>
      {openPSVModal && (
        <PSVsModal
          open={openPSVModal}
          closed={(value) => {
            setEditPSVId('');
            setOpenPSVModal(value);
          }}
          title={editPSVId ? 'Edit / Verify PSV' : 'Add PSV'}
          cachedPSVData={editPSVId ? psvsMap[editPSVId] || false : false}
          cachedPSVReconcile={editPSVId ? psvsReconcileMap[editPSVId] || false : false}
          dropdownMenuMapList={dropdownMenuMapList}
          updateExistingPsv={updateExistingPsvEvent}
          addNewPsv={addNewPsvEvent}
          openAttachPSVModal={() => setAttachPSV((prev) => !prev)}
          openDeAttachPSVModal={() => setDeAttachPSV((prev) => !prev)}
          openDeletePSVModal={() => setDeletePSV((prev) => !prev)}
        />
      )}

      {deAttachPSV && (
        <AttachPSVModal
          open={deAttachPSV}
          setOpen={setDeAttachPSV}
          deAttached={true}
          deAttachedHandleEvent={() => {
            deAttachedOrAttachedSelectedPSV({ deAttached: true, reattach: false });
          }}
        />
      )}

      {attachPSV && (
        <AttachPSVModal
          open={attachPSV}
          setOpen={setAttachPSV}
          attached={true}
          unlinkHandleEvent={() => {
            deAttachedOrAttachedSelectedPSV({ deAttached: false, reattach: true });
          }}
        />
      )}

      {deletePSV && (
        <DeleteModal
          open={deletePSV}
          setOpen={setDeletePSV}
          confirmDeletion={true}
          recordType={'PSV'}
          handleDelete={() => {
            deleteSelectedPSV();
          }}
        />
      )}
    </div>
  );
};

export default memo(PSVs);
