import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Logo from '@components/logo';
import InputField from '@components/input-field';

import AuthService from '@services/auth.service';

function PasswordReset() {
  const navigate = useNavigate();
  const [username, setUserName] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username || !emailRegex.test(username)) return setErrorMessage('error');

    const [success] = await AuthService.resetPassword({ email: username });

    if (success) navigate('/email-msg-pass-reset');
  };

  return (
    <div className="op-login-container">
      <div className="op-login">
        <div className="text-center mb-14">
          <Logo />
        </div>
        <form>
          <div className="op-input-field-wrap mb-9">
            <InputField
              label="Email"
              type="email"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              error={
                errorMessage
                  ? !username
                    ? 'Required'
                    : !emailRegex.test(username)
                    ? 'Not an email address'
                    : ''
                  : ''
              }
              className={
                errorMessage
                  ? !username ||
                    !emailRegex.test(username) ||
                    errorMessage === 'incorrect' ||
                    errorMessage === 'no-access'
                    ? 'error'
                    : ''
                  : ''
              }
            />
          </div>
          <Link className="btn-fill button-large" to={''} onClick={handleSubmit}>
            Request password reset
          </Link>

          <div className="op-divider mt-9"></div>
          <div className="flex flex-wrap justify-between mt-2">
            <Link className="input-bold op-anchor-link" to={'/'}>
              Log in
            </Link>
            <Link className="input-bold op-anchor-link" to={'/support-msg'}>
              Contact support
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PasswordReset;
