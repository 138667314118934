import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export default function PrivateRoute({ children, roles = [] }) {
  let authenticated = true;
  const user = useSelector((state) => state.auth.user);

  if (!user) authenticated = false;
  if (roles.length > 0 && !roles.includes(user.role)) authenticated = false;

  return <>{authenticated ? children : <Navigate to="/" />}</>;
}
