/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState, memo } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AddNewWizards from '../add-new-wizards';
import Icon from '@components/icon-component';

import { PERMISSIONS } from 'permissions/constants';
import { addToOptions } from '..';
import { decodeToken } from '@utilities/authentication';

import '../app-bar.scss';
import style from '../style.module.scss';

const MenuBarOptions = ({ setNewEquipment }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const wrapperRef = useRef(null);

  const addTo = pathname.replace('/', '').replace('-details', '').replace('-', ' ').split('/')[0];
  const options = pathname.includes('-details')
    ? addToOptions[addTo] || addToOptions['default']
    : addToOptions['default'];

  const [addNew, setAddNew] = useState('');
  const [isActive, setActive] = useState(false);
  const [initialX, setInitialX] = useState(null);
  const [initialY, setInitialY] = useState(null);
  const [menuBarOptionsList, setMenuBarOptionsList] = useState([]);

  useEffect(() => {
    const permissions = decodeToken()?.permissions || {};
    const filteredOptions = options.filter(({ text }) => {
      if (text === 'New Equipment' && !permissions[PERMISSIONS.MOBILE_CREATEEDIT_EQUIPMENT])
        return false;
      if (text === 'Inspection' && !permissions[PERMISSIONS.MOBILE_ONDEMAND_INSPECTIONS])
        return false;
      if (text === 'Work order' && !permissions[PERMISSIONS.MOBILE_CREATE_WORKORDER]) return false;
      if (text === 'Work request' && !permissions[PERMISSIONS.MOBILE_CREATE_WORKREQUEST])
        return false;
      if (text === 'HSE event' && !permissions[PERMISSIONS.MOBILE_CREATE_HSE]) return false;
      if (text === 'Check' && !permissions[PERMISSIONS.MOBILE_ONDEMAND_CHECKS]) return false;

      return true;
    });

    setMenuBarOptionsList(filteredOptions);
  }, [options]);

  useEffect(() => {
    if (!isActive || !addNew) document.body.classList.remove('op-addnew-filter-open');
    else document.body.classList.add('op-addnew-filter-open');
  }, [addNew, isActive]);

  useEffect(() => {
    setTimeout(() => setAddNew(''), 600);
  }, [isActive]);

  const startTouch = (e) => {
    setInitialX(e.touches[0].clientX);
    setInitialY(e.touches[0].clientY);
  };

  const moveTouch = (e) => {
    let { clientX, clientY } = e.touches[0];

    setInitialX(clientX);
    setInitialY(clientY);

    if (clientX - initialX < 0 || initialY - clientY < 0) setActive(false);
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) setActive(isActive);
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  const onClickEvent = ({ type, text }) => {
    if (type === 'equipment') setNewEquipment(true);
    else if (type === 'work-order' && pathname.includes('-details') && id) {
      const workType = `new-${text.toLowerCase().replace(' ', '-')}`;
      const idOf = pathname.split('/')[1].replace('parent-', '').replace('-details', '');
      navigate(`/work-order/?from=${pathname}&type=${workType}&${idOf}Id=${id}`);
    } else if ((type === 'inspection' || type === 'HSE') && pathname.includes('-details') && id)
      setAddNew(`${text.toLowerCase().replace(' ', '-')}Asset`);
    else setAddNew(text.toLowerCase().replace(' ', '-'));
  };

  return (
    <>
      {menuBarOptionsList.length > 0 && (
        <div ref={wrapperRef} className={`op-addnew ${isActive ? 'open' : ''}`}>
          <div className="op-addnew-circle" onClick={() => setActive((prev) => !prev)}>
            <svg
              className="op-addnew-svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 5V19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 12H19"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <div
            onTouchMove={moveTouch}
            onTouchStart={startTouch}
            className={`op-addnew-dropdown ${addNew ? 'op-addnew-dropdown-tablet' : ''} ${
              isActive ? 'open' : ''
            }`}
          >
            <div className={'op-addnew-dropdown-header'}>
              <h5 className="h5">
                <>
                  Add new
                  {addNew
                    ? addNew === 'inspectionAsset'
                      ? ` inspection`
                      : addNew === 'hse-eventAsset'
                      ? ' HSE event'
                      : ` ${addNew.toLowerCase().replace('-', ' ').replace('hse', 'HSE')}`
                    : pathname.includes('-details') && (
                        <span className={'spanClass'}> to {addTo}</span>
                      )}
                </>
              </h5>
              {!addNew && (
                <ul className={'ulClass'}>
                  {menuBarOptionsList.map(({ type, text, iconType }, i) => (
                    <li
                      key={i}
                      onTouchMove={(e) => e.stopPropagation()}
                      onTouchStart={(e) => e.stopPropagation()}
                      onClick={() => {
                        if (type === 'equipment') setNewEquipment(true);
                        else if (type === 'work-order' && pathname.includes('-details') && id) {
                          const workType = `new-${text.toLowerCase().replace(' ', '-')}`;
                          const idOf = pathname
                            .split('/')[1]
                            .replace('parent-', '')
                            .replace('-details', '');

                          navigate(
                            `/work-order/?from=${pathname}&type=${workType}&${idOf}Id=${id}`
                          );
                        } else if (
                          (type === 'inspection' || type === 'hse-event' || type === 'check') &&
                          pathname.includes('-details') &&
                          id
                        ) {
                          setAddNew(`${text.toLowerCase().replace(' ', '-')}Asset`);
                        } else setAddNew(text.toLowerCase().replace(' ', '-'));
                      }}
                    >
                      <a href="/" onClick={(e) => e.preventDefault()} className={`input-medium`}>
                        <span className={`svg-${iconType}`}>{optionsIcons[type]}</span>
                        {text}
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div
              onTouchMove={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
              className={'op-addnew-dropdown-tablet-header'}
            >
              <div className="header">
                <p className="cancelClass" onClick={() => setActive(false)}>
                  Cancel
                </p>
                <h1
                  className="addClass h1-semibold"
                  style={{
                    color: '#ffffff'
                  }}
                >
                  <>
                    Add new
                    {addNew
                      ? addNew === 'inspectionAsset'
                        ? ` inspection`
                        : addNew === 'checkAsset'
                        ? ` check`
                        : addNew === 'hse-eventAsset'
                        ? ` HSE event`
                        : ` ${addNew.toLowerCase().replace('-', ' ').replace('hse', 'HSE')}`
                      : pathname.includes('-details') && (
                          <span className={'spanClass'}> to {addTo}</span>
                        )}
                  </>
                </h1>
              </div>
            </div>
            <div onTouchMove={(e) => e.stopPropagation()} onTouchStart={(e) => e.stopPropagation()}>
              {addNew && (
                <AddNewWizards addNew={addNew} setAddNew={setAddNew} setActive={setActive} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(MenuBarOptions);

const optionsIcons = {
  'hse-check': <Icon name={'hse'} />,
  'hse-event': <Icon name={'hse-event'} />,
  equipment: <Icon name={'new-equipment'} />,
  inspection: <Icon name={'inspection'} />,
  'work-order': <Icon name={'work-order'} />,
  check: <Icon name={'check-icon'} />
};
