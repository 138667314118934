/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { equipmentAssociatedOrNewPsvsTransformer } from './psvs';

export const transformFormWrapperDataToNewInspection = ({ inspectionData, inspectionId }) => {
  const uniqueId = !_.isEmpty(inspectionId) ? inspectionId : uuidv4();
  let workOrderIds =
    inspectionData?.inspectionWorkOrder?.length > 0
      ? inspectionData?.inspectionWorkOrder?.map((x) => x?.id)
      : inspectionData?.inspectionWorkRequest?.length > 0
      ? inspectionData?.inspectionWorkRequest?.map((x) => x?.id)
      : [];

  const previousIds = inspectionData?.workOrderIds?.length > 0 ? inspectionData?.workOrderIds : [];
  workOrderIds = [...workOrderIds, ...previousIds];
  return {
    newInspection: true,
    status: inspectionData?.status,
    type: 'Inspection',
    id: uniqueId,
    inspectionFormId: inspectionData?.inspectionFormId,
    inspectionTypeId: inspectionData?.inspectionTypeId,
    siteId: inspectionData?.siteId || null,
    equipmentId: inspectionData?.equipmentId || null,
    pipelineId: inspectionData?.pipelineId || null,
    formData: inspectionData?.formData || null,
    startLatitude: inspectionData?.startLatitude,
    startLongitude: inspectionData?.startLongitude,
    completionLatitude: inspectionData?.completionLatitude,
    completionLongitude: inspectionData?.completionLongitude,
    inspectionDate: inspectionData?.inspectionDate
      ? inspectionData?.inspectionDate
      : moment().format('YYYY-MM-DD'),
    ...(workOrderIds && {
      workOrderIds
    })
  };
};

export const prepareInspectionForBackend = (
  data,
  failureCriteria,
  inspectionFormsUserCache,
  passFailTrue,
  passFailNA,
  passFailFalse,
  inspectionResultAnswer,
  passFailList,
  passDeficiencyFail,
  formData,
  psvs
) => {
  const currentFrom =
    inspectionFormsUserCache?.find((x) => x?.inspectionFormId === data?.inspectionFormId) || {};
  const equipmentAssociatedPsvs = equipmentAssociatedOrNewPsvsTransformer({
    formDataPSVs: psvs || [],
    equipmentId: data?.equipmentId || ''
  });
  const json = transformJSON({ currentFrom, formData });

  const failureCriteriaMap = failureCriteria?.reduce((acc, curr) => {
    acc[curr.value] = curr.label;
    return acc;
  }, {});

  let inspectionPassed = false;

  if (failureCriteriaMap[currentFrom?.failureCriteria] === 'Auto pass') inspectionPassed = true;
  else if (failureCriteriaMap[currentFrom?.failureCriteria] === 'No pass/fail outcome')
    inspectionPassed = null;
  else if (failureCriteriaMap[currentFrom?.failureCriteria] === '‘Inspection Result’ section') {
    const defaultValue = getDefaultValueWhenInspectionResultSelected({ form: currentFrom });
    if (defaultValue === 'InspResult_PassFailList') {
      const condition =
        inspectionResultAnswer || (currentFrom?.quickComplete ? passFailList[0]?.value : null);
      inspectionPassed = condition === 'true' ? true : false;
    } else if (defaultValue === 'InspResult_PassDeficiencyFailList') {
      const condition =
        inspectionResultAnswer ||
        (currentFrom?.quickComplete ? passDeficiencyFail[0]?.value : null);
      inspectionPassed = condition === 'pass' || condition === 'pass_minor' ? true : false;
    } else {
      inspectionPassed = false;
    }
  } else if (
    failureCriteriaMap[currentFrom?.failureCriteria] === 'A number of Pass-Fail failures'
  ) {
    const numberOfFailedFields = currentFrom?.numberOfFailedFields || 0;
    if (passFailFalse >= numberOfFailedFields) inspectionPassed = false;
    else inspectionPassed = true;
  }

  return {
    runId: data?.runId || null,
    RunName: data?.RunName || null,
    runNumber: data?.runNumber || null,
    inspectableItemId: data?.inspectableItemId || null,
    siteId: data?.siteId || null,
    equipmentId: data?.equipmentId || null,
    pipelineId: data?.pipelineId || null,
    pipelines: data?.pipelines?.length
      ? data?.pipelines
      : data?.pipelineId
      ? [data?.pipelineId]
      : [],
    inspectionTypeId: data?.inspectionTypeId || null,
    inspectionFormId: data?.inspectionFormId || null,
    frequencyId: data?.frequencyId || null,
    nextDue: data?.nextDue || null,
    nextScheduled: data?.nextScheduled || null,
    inspectionId: data?.id || uuidv4(),
    json: null,
    uniqueDeviceId: null,
    types: null,
    structureSelectInfo: null,
    inspectionDate: data?.inspectionDate || moment().format('YYYY-MM-DD'),
    inspectionPassed: inspectionPassed,
    onDemandItem: null,
    sensors: [],
    ...(data?.workOrderIds && { workOrderIds: data?.workOrderIds }),
    ...(equipmentAssociatedPsvs?.length > 0 && {
      equipmentAssociatedPsvs: equipmentAssociatedPsvs
    }),
    formdata: json,
    startLatitude: `${data?.startLatitude}`,
    startLongitude: `${data?.startLongitude}`,
    completionLatitude: `${data?.completionLatitude}`,
    completionLongitude: `${data?.completionLongitude}`
  };
};

const getDefaultValueWhenInspectionResultSelected = ({ form }) => {
  let defaultValue = null;
  for (const section of form.formSections) {
    for (const field of section.formFields) {
      if (field.questionTypeText === 'InspectionResult') {
        defaultValue = field.defaultValue;
        break;
      }
    }
    if (defaultValue) {
      break;
    }
  }
  return defaultValue;
};

export const transformJSON = ({ currentFrom, formData }) => {
  const formDataAnswers = [];
  currentFrom?.formSections?.map((section) => {
    section?.formFields?.map((field) => {
      let removeSpecialCharactersForName = removeSpecialCharacters(
        field?.label?.toString()?.toLowerCase()
      );
      removeSpecialCharactersForName = `${removeSpecialCharactersForName}-${field?.formFieldId}`;
      const answer =
        field?.questionTypeText === 'PhotoQuestion'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.notes
          : field?.questionTypeText === 'PassFailNA' ||
            field?.questionTypeText === 'PassFail' ||
            field?.questionTypeText === 'Scale'
          ? field?.questionTypeText === 'Scale'
            ? parseInt(formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.answer) === 0
              ? 0
              : formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.answer
            : formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.answer || null
          : field?.questionTypeText === 'YNComment'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.value
          : field?.questionTypeText === 'Readings' || field?.questionTypeText === 'Readings_PL'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.textBoxValue
          : field?.questionTypeText === 'Photos'
          ? null
          : field?.questionTypeText === 'InspectionResult'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.inspectionResultAnswer
          : field?.questionTypeText === 'Signature'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.signaturePhoto
          : field?.questionTypeText === 'Time'
          ? moment(formData[removeSpecialCharactersForName]?.[field?.formFieldId]).format('hh:mm')
          : formData[removeSpecialCharactersForName]?.[field?.formFieldId];
      const assessment =
        field?.questionTypeText === 'Scale'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.assessment
          : null;
      const comment =
        field?.questionTypeText === 'YNComment'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.comment
          : null;

      const notes = ['PhotoQuestion', 'PassFailNA', 'PassFail', 'Scale']?.includes(
        field?.questionTypeText
      )
        ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.notes
        : field?.questionTypeText === 'YNComment'
        ? formData[removeSpecialCharactersForName]?.[field?.form]?.comment
        : null;
      const unit =
        field?.questionTypeText === 'Readings' || field?.questionTypeText === 'Readings_PL'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.textBoxUnitValue
          : null;
      const photos =
        field?.questionTypeText === 'Photos' ||
        ['PhotoQuestion', 'PassFailNA', 'PassFail', 'Scale']?.includes(field?.questionTypeText)
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.photos?.map((x) => {
              return {
                photoId: x?.id,
                caption: x?.caption
              };
            })
          : null;
      const documentId =
        field?.questionTypeText === 'Photos'
          ? formData[removeSpecialCharactersForName]?.[field?.formFieldId]?.documents?.at(0)?.id
          : null;
      const documentObj = documentId
        ? {
            fieldKey: field?.label,
            fieldId: field?.formFieldId,
            documentId
          }
        : null;
      const multipleDropdownAnswer =
        field?.questionTypeText === 'Dropdown_PL_Multiple' &&
        formData[removeSpecialCharactersForName]?.[field?.formFieldId]
          ? Object.values(formData[removeSpecialCharactersForName]?.[field?.formFieldId])?.map(
              (x) => x?.value
            )
          : null;

      documentObj && formDataAnswers.push(documentObj);
      if (field?.questionTypeText !== 'PSV_Section')
        formDataAnswers.push({
          fieldKey: field?.label,
          fieldId: field?.formFieldId,
          ...(answer &&
            !multipleDropdownAnswer && {
              answer: answer?.toString()
            }),
          ...(multipleDropdownAnswer && {
            listOfMultiValueIds: multipleDropdownAnswer
          }),
          ...(field?.questionTypeText === 'Scale' &&
            assessment && {
              assessment
            }),
          ...(field?.questionTypeText === 'Scale' &&
            answer === 0 && {
              answer: answer?.toString()
            }),
          ...(notes && {
            notes
          }),
          ...(unit && {
            unit
          }),
          ...(photos && {
            photos
          }),
          ...(comment && {
            comment
          })
        });
    });
  });
  return formDataAnswers;
};

const removeSpecialCharacters = (str) => {
  const pattern = /[^a-zA-Z0-9 ]/g;
  const result = str.replace(pattern, '');
  return result;
};
