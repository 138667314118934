/* eslint-disable no-unused-vars */
import { getSitesInfoForSitesListingPage } from './sites/sites-info-for-listing-page';
import { getSiteDetailInfo } from './sites/site-detail-info';
import { getParentSiteDetails } from './sites/parent-site-details-info';

const SitesSelector = {
  getSitesWithAllTasks: async ({
    parentSite = [],
    id = '',
    withChild = true,
    templatesIds = '',
    getDataFromCache = false,
    formWrapper = false,
    store
  }) => {
    const response = await getSitesInfoForSitesListingPage({
      parentSite,
      id,
      withChild,
      templatesIds,
      getDataFromCache,
      formWrapper,
      store
    });
    return response;
  },
  getSiteWithEquipment: async ({ siteId = '', getDataFromCache = false, store }) => {
    const response = await getSiteDetailInfo({ siteId, getDataFromCache, store });
    return response;
  },

  getParentSiteDetailsInfo: async ({
    siteId = '',
    equipmentsWithWO = false,
    withChild = true,
    templatesIds = '',
    store
  }) => {
    const response = await getParentSiteDetails({
      siteId,
      equipmentsWithWO,
      withChild,
      templatesIds,
      store
    });
    return response;
  }
};
export default SitesSelector;
