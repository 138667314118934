/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { store } from 'redux/store';

import http from './http.service';
import Promisable from './promisable.service';
import * as serviceWorkerRegistration from '../serviceWorkerRegistration';
import TasksService from './tasks.service';
import InspectionService from './inspection.service';

import { authActions } from 'redux/slices/auth';
import { PERMISSIONS } from 'permissions/constants';
import { getAppDispatch } from '@utilities/dispatch.util';
import LocalStorage from '@utilities/local-storage.util';
import { decodeToken, setToken } from '@utilities/authentication';
import HSEEventService from './hse-event.service';
import ChecksService from './checks.service';

const AuthService = {
  login: async (data, getTaskFormStorage = true) => {
    window.mySpecialCount = window.mySpecialCount ? window.mySpecialCount + 1 : 1;
    const dispatch = getAppDispatch();

    dispatch?.(authActions.setLoading(true));

    const [success, error] = await Promisable.asPromise(http.post(`/Token`, data));

    if (success?.data?.token) {
      const token = success.data;
      const tokenExpiry = success?.data?.expiry;
      setToken(token);
      const permissions = decodeToken()?.permissions || {};
      const userId = decodeToken()?.userId || '';
      const clientId = decodeToken()?.clientId || '';
      if (permissions[PERMISSIONS.MOBILE_ACCESS])
        dispatch?.(
          authActions.setUser({
            token,
            userId,
            clientId,
            tokenExpiry,
            emailAddress: data?.emailAddress,
            password: data?.password
          })
        );
      else {
        dispatch?.(authActions.setLoading(false));
        throw new Error('Permission denied');
      }
      if (getTaskFormStorage) {
        TasksService.geTasksFromStorage(userId);
        TasksService.getFailedAssetFromStorage(userId);
        InspectionService.geInspectionFromStorage(userId);
        HSEEventService.geHseEventsFromStorage(userId);
        ChecksService.getChecksFromStorage(userId);
      }
    }

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  resetPassword: async (data) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error] = await Promisable.asPromise(http.post(`/ForgotPassword`, data));

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },

  contactSupport: async (data) => {
    const dispatch = getAppDispatch();
    dispatch?.(authActions.setLoading(true));

    const [success, error] = await Promisable.asPromise(http.post(`/Support`, data));

    dispatch?.(authActions.setLoading(false));
    return [success, error];
  },
  logout: async ({ clearCache = false }) => {
    !clearCache && (await setTasksToLocalStorage());

    caches.delete('images');
    indexedDB.deleteDatabase('my-oplii-test');
    serviceWorkerRegistration.unregister();

    localStorage.removeItem('token');

    const dispatch = getAppDispatch();
    dispatch?.(authActions.logout());
    dispatch?.(authActions.setUser(null));
  },

  successfulLoginUser: async () => {
    const currentDate = new Date().toLocaleString('en-US', { timeZone: 'America/Denver' });
    const isoStringDate = new Date(currentDate).toISOString().replace(/Z$/, '');

    const [success, error] = await Promisable.asPromise(
      http.post(`/ApplicationManifests`, {
        cacheComplete: isoStringDate
      })
    );
    return [success, error];
  }
};

const setTasksToLocalStorage = async () => {
  const tasksList = store.getState().tasks?.tasksList;
  const syncFailedAssets = store.getState().tasks?.syncFailedAssets;
  const inspectionsList = store.getState().inspection?.inspectionsList;
  const HSEEventList = store.getState().hseEvents?.HSEEventList || [];
  const checksList = store.getState().checks?.checksList || [];
  const userId = store.getState().auth?.userId;
  const pendingSyncAndInProgressTasks = tasksList?.filter(
    (task) => task.status === 'In progress' || task.status === 'Pending sync'
  );
  const pendingSyncAndInProgressInspections = inspectionsList?.filter(
    (ins) => ins.status === 'In progress' || ins.status === 'Pending sync'
  );
  const pendingSyncAndInProgressHSEEvents = HSEEventList?.filter(
    (ins) => ins.status === 'In progress' || ins.status === 'Pending sync'
  );
  const pendingSyncAndInProgressChecks = checksList?.filter(
    (ins) => ins.status === 'In progress' || ins.status === 'Pending sync'
  );
  const failedImages = syncFailedAssets?.photos;
  const failedDocuments = syncFailedAssets?.documents;
  if (
    _.size(pendingSyncAndInProgressTasks) > 0 ||
    _.size(pendingSyncAndInProgressInspections) > 0 ||
    _.size(pendingSyncAndInProgressHSEEvents) > 0 ||
    _.size(pendingSyncAndInProgressChecks) > 0 ||
    _.size(failedImages) > 0 ||
    _.size(failedDocuments) > 0
  ) {
    const getPreviousUserPendingAndInprogressTasks =
      (await LocalStorage.getItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`)) ||
      [];

    const getPreviousUserFailedAssets =
      (await LocalStorage.getItem(`userFailedAssets-${decodeToken().userId}`)) || [];
    const find = getPreviousUserPendingAndInprogressTasks?.find((x) => x?.userId === userId);
    const findFailedAssets = getPreviousUserFailedAssets?.find((x) => x?.userId === userId);
    if (_.isEmpty(find)) {
      // create a new
      await LocalStorage.setItem(`userPendingSyncAndInProgressTasks-${decodeToken().userId}`, [
        ...getPreviousUserPendingAndInprogressTasks,
        {
          userId,
          tasks: pendingSyncAndInProgressTasks,
          inspections: pendingSyncAndInProgressInspections,
          hseEvents: pendingSyncAndInProgressHSEEvents,
          checks: pendingSyncAndInProgressChecks
        }
      ]);
    } else {
      const findIndex = getPreviousUserPendingAndInprogressTasks?.findIndex(
        (x) => x?.userId === userId
      );
      if (findIndex > -1) {
        getPreviousUserPendingAndInprogressTasks[findIndex].tasks = pendingSyncAndInProgressTasks;
        getPreviousUserPendingAndInprogressTasks[findIndex].inspections =
          pendingSyncAndInProgressInspections;
        getPreviousUserPendingAndInprogressTasks[findIndex].hseEvents =
          pendingSyncAndInProgressHSEEvents;
        getPreviousUserPendingAndInprogressTasks[findIndex].checks = pendingSyncAndInProgressChecks;
      }
      await LocalStorage.setItem(
        `userPendingSyncAndInProgressTasks-${decodeToken().userId}`,
        getPreviousUserPendingAndInprogressTasks
      );
    }

    if (_.isEmpty(findFailedAssets)) {
      await LocalStorage.setItem(`userFailedAssets-${decodeToken().userId}`, [
        ...getPreviousUserFailedAssets,
        {
          userId,
          photos: failedImages,
          documents: failedDocuments
        }
      ]);
    } else {
      const findIndex = getPreviousUserFailedAssets?.findIndex((x) => x?.userId === userId);
      if (findIndex > -1) {
        getPreviousUserFailedAssets[findIndex].photos = failedImages;
        getPreviousUserFailedAssets[findIndex].documents = failedDocuments;
      }
      await LocalStorage.setItem(
        `userFailedAssets-${decodeToken().userId}`,
        getPreviousUserFailedAssets
      );
    }
  }
};

export default AuthService;
