import React from 'react';

import MoreInformation from '@components/more-information';
// import Icon from '@components/icon-component';

// import PermissionRestrict from 'hoc/permission-restrict';
// import { PERMISSIONS } from 'permissions/constants';

const PatentSiteDetailsInfo = ({ MoreInfoDataPointsMap }) => {
  return (
    <>
      <MoreInformation
        info={Object?.keys(MoreInfoDataPointsMap)?.map((a) => {
          return {
            title: MoreInfoDataPointsMap[a]?.value || null,
            heading: MoreInfoDataPointsMap[a]?.title || null
          };
        })}
      />
      {/* <PermissionRestrict roles={PERMISSIONS.MOBILE_CREATEEDIT_EQUIPMENT}>
        <div className="flex justify-end ">
          <div className="btnEdit" onClick={() => {}}>
            <Icon name={'edit-btn'} alt="" />
          </div>
        </div>
      </PermissionRestrict> */}
    </>
  );
};

export default PatentSiteDetailsInfo;
